/**
* 減断水情報一覧画面用モジュール。
* @module app/waterworks/DansuiGrid
*/
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/util/DateUtils',
    'idis/view/form/Button',
    'dojo/on',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function(module,  declare, IdisGrid, helper, DateUtils, Button, on) {

    var formatDateTime = function(value) {
        var dateOption = {
            date:'yyyy-MM-dd',
            time:'HH:mm:ss'
        };
        return DateUtils.format(value, dateOption);
    };

    return declare(module.id.replace(/\//g, '.'), IdisGrid, {       

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            {field: 'detail', label:'詳細', sortable: false,
            renderCell: function(item){
                var gridNode = this.grid.domNode;
                var button = new Button({
                    label: '詳細',
                    onClick: function() {
                        on.emit(gridNode, 'detailButtonClick', {item: item});
                    }
                });
                return button.domNode;
            }},

            {field: 'gysNm', label: '行政区名', sortable: false,
                formatter: function(gysNm){
                    return gysNm ? gysNm : '-';
                }
            },            
            
            {field: 'adrChmNm', label: '町丁目名', sortable: false,
                formatter: function(adrChmNm){
                    return adrChmNm ? adrChmNm : '-';
                }
            },                        
            
            {field: 'gendansuistatus', label: '減断水ステータス', sortable: false,
                formatter: function(gendansuistatus){
                    return gendansuistatus ? gendansuistatus : '-';
                }
            },                      
            
            {field: 'ksKosu', label: '給水戸数',
                formatter: function(ksKosu){
                    return ksKosu ? ksKosu : '-';
                }
            },                   
            
            {field: 'hasseinichiji', label: '発生日時',
                formatter: function(hasseinichiji){
                    return hasseinichiji ? formatDateTime(hasseinichiji) : '-';
                }
            },                
            
            {field: 'fukkyunichiji', label: '復旧日時', 
                formatter: function(fukkyunichiji){
                    return fukkyunichiji ? formatDateTime(fukkyunichiji) : '-';
                }
            }
        ]
     });
 });
