/**
 * 職員招集 グループ選択部分の共通モジュール
 * @module app/convocation/view/condition/_ConvoAutoMailPageBase
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dstore/Memory',
    'idis/control/Router',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/model/ConvoGroup',
//    'idis/view/Loader',
    //以下、変数で受けないモジュール
    'dijit/form/CheckBox',
    'app/convocation/view/condition/ConvoAddGroupDialog',
    'app/convocation/view/condition/ConvoAutoMailRegisterGrid',
    'app/convocation/view/mail/ConvoTempInsertDialog'
], function(module, declare, lang, Memory, Router, DialogChain, InfoDialog, _PageBase, ConvoGroup) {
    /**
     * 職員招集 グループ選択部分
     * @class _ConvoAutoMailPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/_ConvoAutoMailPageBase~_ConvoAutoMailPageBase# */
        {
            store: null,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            // テンプレートコード
            templateCd: '',

            /**
             * 変数の初期化
             */
            constructor: function() {
                // 条件対象グループ情報のデータ用オブジェクト
                this.store = new Memory({
                    idProperty: 'groupCd'
                });
                // ダイアログの連鎖関係を取得
                this.chain = DialogChain.get(this);
            },

            /**
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function() {
                this.inherited(arguments);
                this.initConvoAutoMailRegisterGrid();
                this.initSelectGroupDialog();
                this.own(this.selectGroupDialog);
                this.own(this.templateInsertDialog);
                //this.initTempInsertGrid();
                this.initDetailDialog();
            },

            /**
             * グループ選択ダイアログの初期化。
             */
            initSelectGroupDialog: function() {
                // thisは各画面を指す
                var page = this.selectGroupDialog.getChildren()[0];
                // ダイアログのregisterイベントをキャッチする
                page.on('register', lang.hitch(this, function(evt) {
                    // gridのstore内に同じコードのグループがあれば追加しない
                    if (this.store.getSync(evt.groupCd)) {
                        this.chain.info('選択したグループは既に追加されています。', 'エラー');
                        return;
                    }
                    // ツリーのデータを取得
                    var codes = [];
                    if (Array.isArray(evt.groupCd)) {
                        codes = evt.groupCd;
                    } else {
                        codes = [evt.groupCd];
                    }
                    for (var i = 0; i < codes.length; i++) {
                        ConvoGroup.get(codes[i]).then(lang.hitch(this, function(item) {
                            this.addDistGroupInfo({
                                id: '' + item.id,
                                name: item.name,
                                empNum: item.empNum
                            });
                        }));
                    }
                    page.leave();
                    // form情報のリセット
                    page.form.reset();
                }));
            },

            checkBoxItems: [
                'question1CheckBox',      // 質問1
                'question2CheckBox',      // 質問2
                'question3CheckBox',      // 質問3
                'question4CheckBox',      // 質問4
                'question5CheckBox'      // 質問5
            ],
            setCheckBoxValue: function(value) {
                this.checkBoxItems.forEach(function(item) {
                    // if (value[item][0]) {
                    //     value[item] = '01';
                    // } else {
                    //     value[item] = '00';
                    // }
                    console.dir(value[item]);
                });
            },

            parseCheckBoxValue: function(data, value) {
                this.checkBoxItems.forEach(lang.hitch(this, function(item) {
                    if (data[item] === '01') {
                        value[item] = ['on'];
                    } else {
                        value[item] = [];
                    }
                }));

            },

            /**
             * パンくずリストのボタンを押下したときの挙動
             */
            onConvoAutoMailLinkClick: function(evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('convocation/mail');
            },

            /**
             * 対象グループgridの初期化。
             */
            initConvoAutoMailRegisterGrid: function() {
                // gridに設定する
                this.convoAutoMailRegisterGrid.set('collection', this.store);
                // ボタンが押された際の処理を定義
                this.convoAutoMailRegisterGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                    var cd = evt.item.groupCd;
                    //helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                    this.moveConvoGroupDetailPage(cd);
                }));
                this.convoAutoMailRegisterGrid.on('deleteButtonClick', lang.hitch(this, function(evt) {
                    var cd = evt.item.groupCd;
                    // 指定したコードのグループをgridから削除する
                    this.deleteDistGroupInfo(cd);
                }));
            },

            /**
             * @param item 追加対象のグループの情報
             * グループをGridに追加する。
             */
            addDistGroupInfo: function(item) {
                // gridに追加
                this.store.addSync({
                    groupCd: item.id,
                    convoGroup: item.name,
                    // convoStaffNum: item.convoStaffNum
                    convoStaffNum: item.empNum
                });
                // 削除した後のstoreをgridに設定
                this.convoAutoMailRegisterGrid.set('collection', this.store);
                // 設定内容を反映するため、gridを更新
                this.convoAutoMailRegisterGrid.refresh();
            },

            /**
             * @param cd グループコード
             * コードに合致したグループをグリッド上から削除する。
             */
            deleteDistGroupInfo: function(cd) {
                var messege = 'グループを削除します。よろしいですか？<br>' + '※この処理ではデータベース上の情報は削除されません。';
                this.chain.confirm(messege, lang.hitch(this, function(chain) {
                    // storeからグループを削除
                    this.store.remove(cd);
                    // 削除した後のstoreをgridに設定
                    this.convoAutoMailRegisterGrid.set('collection', this.store);
                    // 設定内容を反映するため、gridを更新
                    this.convoAutoMailRegisterGrid.refresh();
                    // 確認ダイアログを閉じる
                    chain.hide();
                }));
            },

            /**
             * gridのstore内のグループコード群をformに設定する。
             */
            addGroupsToForm: function() {
                var cdList = [];
                // storeから取得
                this.store.fetch().forEach(function(item) {
                    // グループコードを1つずつリストに格納する
                    cdList.push(item.groupCd);
                });
                // グループコードリストを画面のformに設定
                this.form.value.groupCd = cdList;
            },

            getGroupCdList: function() {
                var list = [];
                this.convoAutoMailRegisterGrid.collection.data.forEach(lang.hitch(this, function(row) {
                    list.push(row.groupCd);
                }));
                return list;
            },

            /**
             * グループ詳細画面へ遷移する
             */
            moveConvoGroupDetailPage: function(groupCd){
                var url = '?id=' + groupCd;
                url += '&p=convocation/groupDetail';
                window.open(url);
            },

            /**
             * 入力されたデータの妥当性をチェックする。
             */
            validate: function() {
                var formData = this.form.get('value');
                var msg = [];
                // 配信設定名が入力されていない場合
//                if (formData.settingName === '') {
//                    InfoDialog.show('入力エラー', '条件名が入力されていません。');
//                    return false;
//                }

                // 配信テンプレート名が入力されていない場合
                if (formData.settingName === '') {
                    InfoDialog.show('入力エラー', '配信テンプレート名が入力されていません。');
                    return false;
                }

                // 件名が入力されていない場合
                if (formData.distributionTitle === '') {
                    InfoDialog.show('入力エラー', '件名が入力されていません。');
                    return false;
                }

                // 本文が入力されていない場合
                if (formData.distributionBody === '') {
                    InfoDialog.show('入力エラー', '本文が入力されていません。');
                    return false;
                }
                

                // グループが選択されていない場合
                if (!this.store.data[0]) {
                    InfoDialog.show('入力エラー', 'グループが選択されていません。');
                    return false;
                }

                if (msg.length>0) {
                    InfoDialog.show('入力チェックエラー', msg.join('<br>'));
                    return false;
                }

                
                return true;
            },

///**
//             * テンプレート選択用グリッドの初期化
//             */
//            initTempInsertGrid: function() {
//                var page = this.templateInsertDialog.getChildren()[0];
//                page.tempInsertGrid.set('collection', this.tempStore.filter());
//                // グリッドの挿入ボタンクリック時の動作を設定する
//                // helper.buttonColumnでフィールド名に指定した'insert'と'ButtonClick'の結合がボタンクリック時のイベント名
//                page.tempInsertGrid.on('insertButtonClick', lang.hitch(this, function(evt) {
//                    // grid内の件名と本文をformに設定する
//                    this.form.set('value', evt.item);
//                    this.templateCd = evt.item.distributionTmplCd;
//                    this.templateInsertDialog.hide();
//                }));
//                // グリッドの修正ボタンクリック時の動作を設定する
//                // helper.buttonColumnでフィールド名に指定した'list'と'ButtonClick'の結合がボタンクリック時のイベント名
//                page.tempInsertGrid.on('detailButtonClick', lang.hitch(this, function(evt){
//                    // テンプレート詳細ダイアログを表示する
//                    this.showConvoMailTempDetailDialog(evt.item);
//                }));
//            },

//            /**
//             * 詳細ダイアログのformが投稿された際の挙動を設定する。
//             */
//            initDetailDialog: function() {
//                // 詳細ダイアログの最初の子要素が画面の情報
//                var dialog = this.convoMailTempDetailDialog;
//                var page = dialog.getChildren()[0];
//
//                // 詳細ダイアログからのupdateイベントを取得
//                page.on('update', lang.hitch(this, function(evt) {
//                    // ダイアログのform情報に当モジュールで保持しているテンプレートコードを追加
//                    evt.value.distributionTmplCd = this.distributionTmplCd;
//                    // 更新確認ダイアログの表示
//                    this.chain.confirmPut(function(chain) {
//                        // 更新処理完了まではローディング画面を表示
//                        Loader.wait(this.tempStore.put(evt.value)).then(function() {
//                            // 更新処理成功時
//                            // 詳細ダイアログを閉じる
//                            dialog.hide();
//                            // 完了ダイアログの表示
//                            chain.infoComplete();
//                        }, function(error) {
//                            // 更新処理失敗時
//                            // エラーダイアログの表示
//                            chain.infoError(error);
//                        });
//                    });
//                }));
//
//                // 詳細ダイアログからのdeleteイベントを取得
//                page.on('delete', lang.hitch(this, function() {
//                    // 削除確認ダイアログの表示
//                    this.chain.confirmDel(function(chain) {
//                        // 削除処理完了まではローディング画面を表示
//                        Loader.wait(this.tempStore.remove(this.distributionTmplCd))
//                        .then(function() {
//                            // 削除処理成功時
//                            // 詳細ダイアログを閉じる
//                            dialog.hide();
//                            // 完了ダイアログの表示
//                            chain.infoComplete();
//                        }, function(error) {
//                            // 削除処理失敗時
//                            // エラーダイアログの表示
//                            chain.infoError(error);
//                        });
//                    });
//                }));
//            },

//            /**
//             * テンプレートから挿入ボタンが押下された際の処理。
//             */
//            onSelectTempButton: function() {
//                // テンプレート選択ダイアログの表示
//                this.templateInsertDialog.show();
//            },

            /**
            * テンプレート詳細ダイアログを表示する。
            */
            showConvoMailTempDetailDialog: function(item){
                console.log('[詳細]ボタンがクリックされました');
                var page = this.convoMailTempDetailDialog.getChildren()[0];
                // 画面上のフォームをリセット
                // （ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み）
                page.form.reset();
                // テンプレートコードを保持
                this.distributionTmplCd = item.distributionTmplCd;
                // ダイアログを表示
                this.convoMailTempDetailDialog.show();
                page.form.set('value',item);
            }
        });
});
