/**
 * 職員選択用入力パーツ
 * @module app/view/form/EmployeeSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'idis/view/form/TreeChecker',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(module, declare, domStyle, TreeChecker, CacheStoreModel, CacheTreeRest) {
    /**
     * 職員選択用パーツ。
     * @class EmployeeSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:idis/view/tree/EmployeeSelector~EmployeeSelector# */ {
        // 選択ダイアログのタイトル
        title: '水位観測局選択',
        // ツリー・モデル
        model: null,

        constructor: function() {
            this._initModel();
        },

        _initModel: function() {
            // 設置されるたびにモデルを新規生成する
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/observationStation/riverObservation4Tree'
                })
            });
        },

        /**
         * 選択した値の設定
         */
        _setValueAttr: function() {
            // 継承した親クラスの処理
            this.inherited(arguments);
            // 以下、当モジュール固有の処理
            // 選択結果が20文字を超えた場合、・・・(三点リーダ)で省略する
            domStyle.set(this.status, {
                'max-width': '20em',
                'white-space': 'nowrap',
                'overflow': 'hidden',
                'text-overflow': 'ellipsis'
            });
        }
    });
});
