/**
 * 招集メール配信ダイアログ用モジュール。
 * @module app/convocation/view/mail/ConvoSendAutoMailDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'idis/control/Router',
    'dojo/text!./templates/ConvoSendAutoMailDialog.html', // テンプレート文字列
    'idis/store/IdisRest',
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/Loader',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'idis/view/form/Button',
    'app/convocation/view/mail/ConvoSendAutoMailGrid'
], function(module, declare, lang, Router, template, IdisRest, _PageBase,
    Loader, Requester, DialogChain, InfoDialog) {
    /**
     * 招集メール配信ダイアログ。
     * @class 招集メール配信ダイアログ
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/ConvoSendAutoMailDialog~ConvoSendAutoMailDialog# */
        {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            /**
             * 変数の初期化。
             */
            constructor: function() {
                // データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'settingId',
                    target: '/api/convoMails/conditions'
                });
                this.chain = DialogChain.get(this);
            },

            /**
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function() {
                this.inherited(arguments);
                // this.initSendAutoMailGrid();
            },
            onSearch: function() {
                this.initSendAutoMailGrid();
            },

            /**
             * 招集メール配信設定一覧用グリッドの初期化
             */
            initSendAutoMailGrid: function() {
                
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();
                if (this.settingName.value) {
                    filter = filter.eq('settingName', this.settingName.value);
                }
                var collection = this.store.filter(filter);
                this.sendAutoMailGrid.set('collection', collection);
                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.sendAutoMailGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                    this.showDetailPage(evt.item);
                }));
                this.sendAutoMailGrid.on('distributionButtonClick', lang.hitch(this, function(evt) {
                     var formData = {
                       'mailType' : '1',
                       'status' : '0',
                       'distSettingId' : evt.item.settingId
                     };

                    // 送信確認ダイアログの表示
                    this.chain.confirm('参集メールの配信を行います。<br>' + 'よろしいですか？', function(chain) {
                         // リクエストの発行からレスポンス受信後の処理までをpromiseに格納
                         var promise = Requester.post('/api/convoMails/mails', {
                             data : formData
                         }).then(function(){
                            // 完了ダイアログを表示
                            chain.infoComplete(function() {
                                location.reload();
                            });
                         }, function(err) {
                            // 処理失敗時はエラーログを出力し、エラーダイアログを表示
                            if (err.response.status === 404) {
			                    // 配信ダイアログを閉じる
                                chain.hide();
                                InfoDialog.show('送信エラー', '配信対象者が0人であったため、メールは配信されませんでした。<br>対象グループが削除された可能性があります。' );
                            }else if (err.response.status === 400) {
                                // 配信ダイアログを閉じる
                                chain.hide();
                                InfoDialog.show('送信エラー', 'メール配信に失敗しました。' );
                            } else {
                                //console.error(err);
                                chain.infoError(err);
                            }
                         });
                         //ローダーの表示
                         Loader.wait(promise);
                    });
                }));
            },

            /**
             * @param item gridのstore1行分
             * 招集メール配信設定詳細画面に遷移する。
             */
            showDetailPage: function(item) {
                var url = '?id=' + item.settingId;
                url += '&p=convocation/autoMailDetail';
                window.open(url);
            }
        });
});
