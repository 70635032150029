/**
* 体制状況・報告一覧画面用モジュール。
* @module app/disasterprevention/view/DisasterPreventionStatusPage
*/
define([
    'module',
    'app/disaster/model/DisasterType',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/text!./templates/DisasterPreventionStatusPage.html',
    'idis/control/Locator',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/model/UserInfo',
    'idis/control/Router',
    './model/DisasterPreventStatus',
    './model/DistributionType',
    './model/HqSetFlg',
    './_DisasterPreventionPageBase',
    'dojo/dom-style',
    'app/config',
    'idis/consts/USER_TYPE',
    'dojo/_base/array',
    './model/HqType',
    'app/model/DisasterInfo',
    // 以下、変数として受け取らないモジュール
    'app/view/form/OrganizationSelector',
    'app/view/form/DisasterPreventionMunicOrgSelector',
    'app/provide/ProvidePreventionRegisterDialog',
    'dijit/layout/BorderContainer',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'idis/view/form/DateInput',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    './DisasterPreventionStatusGrid',
    './DisasterPreventionRegisterPage',
    './DisasterPreventionDetailPage',
    './DisasterPreventionFormDialog'
], function(module, DisasterType, declare, lang, json, template, Locator, IdisRest,
                Loader, UserInfo, Router, PrevStatus, DistributionType,  HqSetFlg, _PageBase,
                domStyle, config, USER_TYPE, array, HqType, DisasterInfo) {

    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/disasterprevention/view/DisasterPreventionStatusPage~DisasterPreventionStatusPage */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--disasterprevention',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * 市町村コード(デフォルト：所属市町村)
         */
        municipalityCd: null,

        /**
        * 職員参集管理パラメータ格納用オブジェクト
        * @type {Object}
        */
        DisasterPrevInfItems : null,

        /**
         * 新規登録ボタン表示/非表示切り替えフラグ
         * ボタンを表示する場合はtrue
         */
        _buttonFlg: true,

        /**
         * 詳細ダイアログで最後に開いた要素
         * （取消ダイアログで表示するために利用）
         * @type {Object}
         * @private
         */
        _lastDetailItem: null,

        /**
         * ダイアログに表示するメッセージ
         */
        _message: null,
        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },


        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'disasterPrevId',
                target: '/api/disasterPrevention',
                rangeStartParam: 'offset'
            });
        },

        // DOM要素構築後に呼ばれる
        // postCreate: function() {
        //     // 体制状況にモデルをセット
        //     this.status.set('store', PrevStatus);
        // },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.municipalityCd = Locator.getQuery().municipalityCd || UserInfo.getMunicipalityCd();
            this.dpDeptCd = Locator.getQuery().dpDeptCd || this.dpDeptCd;
            this._viewMode = Locator.getQuery().viewMode || '0'; // 閲覧モードフラグ
            this.initForm();
            this.initGrid();
            // this.changeButton();
            this.initDetailPage();
        },

        /**
         * 検索フォームを初期化する。
         */
        initForm: function() {
            //初期値（一覧画面で選択された区市町村または組織）
            // if(this.municipalityCd === config.municInfo.cityMunicCd && 
            //     this.dpDeptCd && this.dpDeptCd.substring(0,1) === 'D'){
            if(this.municipalityCd === config.municInfo.cityMunicCd && 
                this.dpDeptCd && this.dpDeptCd.substring(0,1) === 'D'){
                // this.municipalityCdが  大阪市、かつ、dpDeptCdが設定されている場合、
                // （大阪市の組織の場合）
                // セレクタの初期値は区市町村コードではなく該当する組織カテゴリコードに設定する
                this.municipalitySelector.set('value', this.dpDeptCd);
            }else{
                // 上記以外の場合（大阪市（dpDeptCd未設定）または区の場合）
                this.municipalitySelector.set('value', this.municipalityCd);
            }
            // 市町村ユーザーの場合(政令指定都市を除く)編集不可
            /*if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
                if(UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
                    this.municipalitySelector.set('noAllButton', true);
                } else {
                    // 政令指定都市ユーザーの場合市内を選択
                    this.municipalitySelector.set('prefFlg', '0');
                }
            }*/
            this.municipalitySelector.expandAll();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {

            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.showDetailDialog(evt.item);
            }));

            this.updateGridQuery();
        },

        /**
         * 詳細ダイアログのフォームが投稿された際の動作を設定する。
         */
        initDetailPage: function() {
            // 詳細ダイアログの最初の子要素が詳細画面
            var dialog = this.detailDialog;
            var page = dialog.getChildren()[0];
            // 詳細画面のsendイベントを受け取る
            page.on('put-send', lang.hitch(this, function(evt) {
                var sendData = evt.value;
                var provideFlg = sendData.provideFlg;
                delete sendData.provideFlg;
                // 災害IDをセット
                sendData.disasterId = this._disasterId;
                //添付ファイルIDをセット
                // var attachFileList = sendData.attachFileList;
                // if(attachFileList.length > 0) {
                //     var attachFileIds = [];
                //     for(var i = 0; i < attachFileList.length; i++) {
                //         attachFileIds.push(attachFileList[i].disasterPrevAtcFileId);
                //     }
                //     sendData.attachFileIds = attachFileIds.join(',');
                // } else {
                //     sendData.attachFileIds = '';
                // }

                sendData.attachFileIds = '';
                delete sendData.attachFile;
                delete sendData.attachFileList;

                // 配信種別：更新ボタン（insert）→'04' 訂正ボタン（update）→'05'
                var messg = 'この報告';
                if(sendData.updateFlg===1){
                    this.distributionType = DistributionType.CORRECT_BTN;
                    messg += 'を訂正します。よろしいですか？';
                }else{
                    this.distributionType = DistributionType.UPDATE_BTN;
                    messg += 'を更新します。よろしいですか？';
                }

                // 配信対象であれば配信内容確認画面を表示
                if (provideFlg) {
                        // 登録情報確認ダイアログ
                        this.showConfirmDialog(sendData);
                        return;
                } else {
                    if(DisasterInfo.getExtraDisasterId() === '0' || sendData.extraDisasterPrevId === null) {
                        messg = this.createConfirmMessage(sendData);
                    }
                    sendData.disPrevDistributionType = DistributionType.NO_DISTRIBUTE;
                    delete sendData.isUnsentLalert;
                }

                // 外部配信対象外の場合、共通の登録確認画面
                this.chain.confirm(messg, function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.store.put(sendData)).then(lang.hitch(this, function() {
                        this.initGrid();
                        chain.infoComplete(lang.hitch(this, function() {
                            dialog.hide();
                            chain.hide();
                        }));
                    }), function(err) {
                        // 失敗時
                        if(err.response.data && typeof(err.response.data) === 'string'){
                            err.response.data = json.parse(err.response.data);
                        }
                        chain.infoError(err);
                    });
                });
            }));

            // 市町村ユーザーの場合(政令指定都市を除く)概況リンクを不可視
            /*if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
                UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
                domStyle.set(this.overviewLabel, 'display', 'none');
            }*/
            // 市/行政区ユーザのどちらでも概況画面が見えるよう修正
                domStyle.set(this.overviewLabel, 'display', '');

            // 詳細ダイアログの取消処理を受ける
            page.on('put-revert-send', lang.hitch(this, function(evt) {
                var sendData = evt.value;
                this.distributionType = DistributionType.CANCEL;

                // 配信対象であれば配信内容確認画面を表示
                if (sendData.provideFlg) {
                    // 配信種別：取消→'03'
                    // 体制情報
                    var items = this._lastDetailItem;
                    items.extraDisasterId = DisasterInfo.getExtraDisasterId();
                    // 一括取消かつ配信対象かつ取消対象が本部設置でない場合、廃止の取消を配信
                    if (sendData.allDelete&&sendData.provideFlg &&
                        ((items.hqSetFlg === HqSetFlg.ESTABLISH && items.hqType !== HqType.RESPONSE_HQ) ||
                        items.hqSetFlg!==HqSetFlg.ESTABLISH)) {
                            items.hqSetFlg = HqSetFlg.RELEASE;
                    }
                    // 登録情報確認ダイアログ
                    this.showConfirmDialog(lang.mixin(items, {
                        provideFlg: sendData.provideFlg,
                        allDelete: sendData.allDelete
                    }));
                    return;
                }

                // 外部配信対象外の場合、共通の確認ダイアログを開く
                // var messg = sendData.allDelete ? 'すべての報告' : 'この報告';
                var messg = this.createConfirmMessage(sendData);

//                this.chain.confirmDel(messg, lang.hitch(this, function(chain) {
                this.chain.confirm(messg, function(chain) {
                    // OKなら取消リクエストを実施
                    Loader.wait(this.deleteStore.put(sendData)).then(lang.hitch(this, function() {
                        chain.infoComplete(lang.hitch(this, function() {
                            // 取消成功時
                            this.initGrid();
                            // 各ダイアログを閉じる
                            this.detailDialog.hide();
                            chain.hide();
                        }));
                    }), function(err) {
                        // 失敗時
                        if(err.response.data && typeof(err.response.data) === 'string'){
                            err.response.data = json.parse(err.response.data);
                        }
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 検索ボタンが押されたときに呼び出される。
         * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
         * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
         */
        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function() {
            var value = this.form.get('value');
            this.municipalityCd = value.municipalityCd;
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            // 災害ID
            filter = filter.eq('disasterId', this._disasterId);
           // 市町村コード
            filter = filter.eq('municipalityCd', this.municipalityCd);
            // 組織カテゴリコード
            // ※大阪市の組織の場合は、区市町村コード設定された組織カテゴリコードを設定
            //   大阪市の場合、組織カテゴリーコードが未設定となるため、明示的にnullを設定
            this.dpDeptCd = (this.municipalityCd.substring(0,1) === 'D') ? this.municipalityCd :
                (this.dpDeptCd === undefined ? null : (this.dpDeptCd === '' ? null : this.dpDeptCd ));
            filter = filter.eq('dpDeptCd', this.dpDeptCd);
            //体制状況
            if (value.statusSelect) {
                value.statusName = value.statusSelect === '04' ? '4号動員体制' :
                value.statusSelect === '03' ? '3号動員体制' :    
                value.statusSelect === '02' ? '2号動員体制' :
                value.statusSelect === '01' ? '1号動員体制' : '';
                filter = filter.eq('statusName', value.statusName);
            }
            // 過去報表示
            filter = filter.eq('pastReportMode', this.pastReport.checked);
            // 報告日時FROM
            if (value.reportDateTimeFrom) {
                var reportDateTimeFrom = new Date(value.reportDateTimeFrom).getTime();
                filter = filter.eq('reportDateTimeFrom', reportDateTimeFrom);
            }
            // 報告日時TO
            if (value.reportDateTimeTo) {
                var reportDateTimeTo = new Date(value.reportDateTimeTo).getTime();
                filter = filter.eq('reportDateTimeTo', reportDateTimeTo);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);

            var municipalitySelector = this.municipalityCd;
            var dpDeptCd = this.dpDeptCd;

            collection.fetch().then(lang.hitch(this, function(data) {
                if(data.totalLength !== 0){
                    //職員参集管理画面へのパラメータ
                    this.setDisasterPrevInf(data[0]);
                    // データがあれば新規登録ボタンを非表示にする
                    this._noRecordFlg = false;
                }else{
                    // データがなければ新規登録ボタンを表示にする
                    this._noRecordFlg = true;
                }
                if(!this.municipalityCd){
                    this.municipalityCd = municipalitySelector;
                }
                if(!this.dpDeptCd){
                    this.dpDeptCd = dpDeptCd;
                }
                this.changeButton();
            }));
            // collection.fetch().then(lang.hitch(this, function(data) {
            // var noRecordFlg = true;
            //     if(data.totalLength !== 0){
            //         array.forEach(data, function(row) {
            //             if(row.municipalityCd === municipalitySelector) {
            //                 // 組織選択ダイアログで選択した組織のデータが存在する場合は情報ありとみなす
            //                 noRecordFlg = false;
            //             }
            //         });
            //     }
            //     this._noRecordFlg = noRecordFlg;
            //     this.changeButton();
            // }));
        },

        //パンくずリストのボタンを押下したときの挙動
        onDisasterPreventionListPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('disasterprevention', {viewMode : this._viewMode});
        },

        /**
         * 職員参集管理画面へ遷移
         */
        moveToEmpGatherStatus: function(){
            // Router.moveTo('employgathering', this.DisasterPrevInfItems);
            Router.moveTo('employgathering', 
                {municipalityCd: this.municipalityCd, dpDeptCd: this.dpDeptCd, viewMode : this._viewMode});
        },

        /**
         * 職員参集管理画面へパラメータセット
         */
        setDisasterPrevInf: function(data){
            this.DisasterPrevInfItems = {};
            this.DisasterPrevInfItems.disasterId = data.disasterId;
            this.DisasterPrevInfItems.disasterPrevId = data.disasterPrevId;
            this.DisasterPrevInfItems.seqNum = data.seqNum;
            this.DisasterPrevInfItems.municipalityCd = data.municipalityCd;
            this.DisasterPrevInfItems.municipalityName = data.municipalityName;
            this.DisasterPrevInfItems.dpDeptCd = data.dpDeptCd;
            this.DisasterPrevInfItems.reportTimestamp = data.reportTimestamp.replace(/-/g, '/');
            this.DisasterPrevInfItems.disasterTypeName = DisasterType.get(data.disasterType).label;
            this.DisasterPrevInfItems.statusName = data.statusName;
            this.DisasterPrevInfItems.comment = data.comment === null ? '' : data.comment;
            this.DisasterPrevInfItems.hqName = data.hqName === null ? '' : data.hqName;
            if(data.hqSetTimestamp !== null){
                this.DisasterPrevInfItems.hqSetTimestamp = data.hqSetTimestamp.replace(/-/g, '/');
            }else{
                this.DisasterPrevInfItems.hqSetTimestamp = '-';
            }
            if(data.hqAbolishedTimestamp !== null){
                this.DisasterPrevInfItems.hqAbolishedTimestamp = data.hqAbolishedTimestamp.replace(/-/g, '/');
            }else{
                this.DisasterPrevInfItems.hqAbolishedTimestamp = '-';
            }
            this.DisasterPrevInfItems.hqSetPlace = data.hqSetPlace === null ? '' : data.hqSetPlace;
        }
    });
});
