define({
    '00': 'その他',
    '01': '防災気象情報',
    '02': '国民保護情報',
    '03': '観測情報',
    '22': 'タイムライン',
    '23': '作図情報',
    '24': '避難情報',
    '25': '配備体制',
    '26': '避難所情報',
    '27': '被害情報',
    '28': '要請・措置情報',
    '29': '災害名管理',
    '30': '要請',
    '31': '組織内情報',
    '32': '部隊活動情報',
    '33': '通行規制情報',
    '34': '大阪府連携',
    '41': '定時報告依頼',
    '42': '定時報告',
    '99': 'お知らせ'
});
