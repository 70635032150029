/**
 * 招集状況管理画面用モジュール。
 *
 * @module app/convocation/view/mail/ConvoStatusDetailPage
 */
define(['module',
        'dojo/_base/declare',
        'dojo/_base/lang',
        'dojo/date/locale',
        'dojo/dom-style',
        'dojo/text!./templates/ConvoStatusDetailPage.html',
        'dojox/charting/Chart',
        'dojox/charting/themes/Claro',
        'dojox/charting/axis2d/Default',
        'dojox/charting/plot2d/Grid',
        'dojox/charting/plot2d/Lines',
        'dojox/charting/plot2d/StackedBars',
        'dojox/charting/widget/Legend',
        'idis/view/page/_PageBase',
        'idis/view/dialog/DialogChain',
        'idis/consts/ACL',
        'idis/model/UserInfo',
        'idis/store/IdisRest',
        'idis/control/Locator',
        'idis/service/Requester',
        'idis/control/Router',
        'idis/util/DateUtils',
        'app/view/chart/ChartConfig',
        // 以下、変数で受けないモジュール
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'dijit/form/Form',
        'dijit/form/Select',
        'dijit/form/TextBox',
        'idis/view/form/AclButton',
        'idis/view/form/Button',
        'idis/view/form/DateTimeInput',
        'app/convocation/view/mail/ConvoStatusDetailGrid',
        'app/convocation/view/mail/ConvoDivisionStatusDetailGrid',
        'app/view/form/UserOrganizationSelector'],
    function (module, declare, lang, locale, domStyle, template,
            Chart, themes, Default, Grid, Lines, StackedBars, Legend,
            _PageBase, DialogChain, ACL, UserInfo, IdisRest, Locator, Requester, Router, DateUtils, ChartConfig) {
    /**
     * 招集状況管理画面。
     *
     * @class ConvoStatusDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/convocation/view/mail/ConvoStatusDetailPage# */
    {

        store: null,
        divisionStore: null,

        // グリッド更新したときのDateTime
        // チャートを表示するときに表示するため保持する
        gridUpdDatetime: null,

        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--convocation',

        _groupName: null,

        displayStatus: 0,

        legend: {},

        /**
         * 変数の初期化。
         */
        constructor: function () {
            // URLから配信IDを取得
            this.distributionHistId = Locator.getQuery().id;
            this.distSettingId = Locator.getQuery().settingid;
            // this.grpHistId= Locator.getQuery().grpHistId;
            // this._groupName= Locator.getQuery().groupName;
            var url = '/api/convoMails/mails/list/';
            url += this.distributionHistId;
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'id',
                target: url
            });
            var divisionUrl = '/api/convoMails/mails/list/division/' + this.distributionHistId;
            this.divisionStore = new IdisRest({
                idProperty: 'id',
                target: divisionUrl
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
            this.display = Locator.getQuery().display;
        },

        /**
         * DOMノードを生成するためのメソッド
         */
        buildRendering: function () {
            this.inherited(arguments);
            this.initPage();
            this.initConvoStatusDetailGrid();
            this.initConvoDivisionStatusDetailGrid();
            // ここでdisplayDivisionかdisplayGroupを実行すると、エラーになる
            if (!this.display || this.display === 'group') {
                this.selectGroupCd = '0';
                this.updateChart('全体', 0);
            } else {
                this.selectDivisionCd = '0';
                this.updateDivisionChart('全体', '0');
            }
            this.organizationSelector.restoreRefresh();
        },

        /**
         * ページの初期化。
         */
        initPage: function () {
            var now = new Date();
            var role = UserInfo.getRoleCd();
            // 管理者ユーザ以外は自組織のみ閲覧可能
            if (role !== ACL.ADMIN_USER && role !== ACL.SYSMGR_USER && role !== ACL.SHIHONBU_USER) {
                // 所属組織をセレクタにセット
                var org = UserInfo.getOrganization().sectCd !== null ? 'S' + UserInfo.getOrganization().sectCd :
                    'D' + UserInfo.getOrganization().deptCd;
                this.organizationSelector.set('value', org);
                domStyle.set(this.organizationSelector.selectButton.domNode, 'display', 'none');
            }
            domStyle.set(this.displayGroupButton.domNode, 'display', 'none');
            domStyle.set(this.convoStatusDetailGrid.domNode, 'height', '0px');
            domStyle.set(this.convoDivisionStatusDetailGrid.domNode, 'height', '93%');
            this.aggregateDateTime._date.set('value', now);
            this.aggregateDateTime._time.set('value', now);
            var self = this;
            var url = '/api/convoMails/mails/forms/';
            url += self.distributionHistId;
            Requester.get(url).then(function (data) {
                var date = locale.parse(data.distributeTimeStamp, {
                    datePattern: 'yyyy-MM-dd',
                    timePattern: 'HH:mm:ss'
                });
                self.distributeTimestamp.innerText = locale.format(date, {
                    datePattern: 'yyyy/MM/dd',
                    timePattern: 'HH:mm'
                });
                self.convoTitle.innerText = data.distTitle;
                //self.title.set('value', data.distTitle);
                //self.container.resize();
            }, function (error) {
                console.log(error);
                self.chain.info('情報の取得に失敗しました。', 'エラー');
            });
        },

        /**
         * gridの初期化。
         */
        initConvoStatusDetailGrid: function () {
//            var endDate = new Date();
//            this.sendTimestampTo._setValueAttr(endDate);

//        	var date = new Date();
//        	  var filter = new this.store.Filter();
//            this.sendTimestampTo.set('value', date);
//            filter = filter.eq('convoMailTo', date.getTime());
//            date.setFullYear(date.getFullYear() - 1);
//            this.sendTimestampFrom.set('value', date);
//            filter = filter.eq('convoMailFrom', date.getTime());
//            var value = this.form.get('value');
//            filter = filter.eq('convoTitle', value.Title);
//            var collection = this.store.filter(filter);
//            this.convoStatusDetailGrid.set('collection', collection);

            this.convoStatusDetailGrid.on('.dgrid-row:click', lang.hitch(this, function (evt) {
                var item = this.convoStatusDetailGrid.row(evt).data;
                this.selectGroupCd = String(item.id);
                delete this.selectDivisionCd;
                this.updateChart(item.groupName, item.id);
            }));
            var self = this;
            // グリッドの詳細ボタンクリック時の動作を設定する
            this.convoStatusDetailGrid.on('detailButtonClick', function (evt) {
                self.moveStatusDetailPage(evt.item);
            });
           this.updateConvoStatusDetailGrid();

        },

        /**
         * gridの更新。
         */
        updateConvoStatusDetailGrid: function () {
            // 招集状況をgridにセット
            var filter = new this.store.Filter();
            var datetime = new Date(this.form.get('value').aggregateDateTime);
            datetime.setSeconds(59);
            this.gridUpdDatetime = datetime;
            filter = filter.eq('timestamp', datetime.getTime());
            if (this.form.get('value').organizationSelector) {
                var organizationCd = this.form.get('value').organizationSelector;
                filter = filter.eq('organization', organizationCd.slice(1));
            }
            // var sendTimestampFrom = new Date(this.sendTimestampFrom._getValueAttr()).getTime();
            // filter = filter.eq('convoMailFrom', sendTimestampFrom);
            // var sendTimestampTo = new Date(this.sendTimestampTo._getValueAttr()).getTime();
            // filter = filter.eq('convoMailTo', sendTimestampTo);
            var collection = this.store.filter(filter);
            this.convoStatusDetailGrid.set('collection', collection);
        },

        /**
         * gridの更新。
         */
        initConvoDivisionStatusDetailGrid: function () {
            this.convoDivisionStatusDetailGrid.on('.dgrid-row:click', lang.hitch(this, function (evt) {
                var item = this.convoDivisionStatusDetailGrid.row(evt).data;
                this.selectDivisionCd = String(item.id);
                delete this.selectGroupCd;
                this.updateDivisionChart(item.groupName, item.id);
            }));
            var self = this;
            // グリッドの詳細ボタンクリック時の動作を設定する
            this.convoDivisionStatusDetailGrid.on('detailButtonClick', function (evt) {
                self.moveDivisionStatusDetailPage(evt.item);
            });
            this.updateConvoDivisionStatusDetailGrid();
        },

        /**
         * gridの更新。
         */
        updateConvoDivisionStatusDetailGrid: function () {
            // 招集状況をgridにセット
            var filter = new this.divisionStore.Filter();
            var datetime = new Date(this.form.get('value').aggregateDateTime);
            datetime.setSeconds(59);
            this.gridUpdDatetime = datetime;
            filter = filter.eq('timestamp', datetime.getTime());
            if (this.form.get('value').organizationSelector) {
                var organizationCd = this.form.get('value').organizationSelector;
                filter = filter.eq('organization', organizationCd.slice(1));
            }
            var collection = this.divisionStore.filter(filter);
            this.convoDivisionStatusDetailGrid.set('collection', collection);
        },
        
        createChart: function () {
            this.chartNode0.innerHTML = '';
            this.chartLegend.innerHTML = '';
            this.displayAll();
            var self = this;
            var chart = self.chart;

            var selectGroupCd = self.selectGroupCd;
            var selectDivisionCd = self.selectDivisionCd;

            var url = null;
            if (selectGroupCd) {
                url = '/api/convoMails/mails/list/' + self.distributionHistId + '/' + selectGroupCd;
            } else {
                url = '/api/convoMails/mails/list/' + self.distributionHistId + '/division/' + selectDivisionCd;
            }
            if (this.form.get('value').organizationSelector) {
                var organizationCd = this.form.get('value').organizationSelector;
                url += '/' + organizationCd.slice(1);
            } else {
                url += '/' + 'none';
            }
            Requester.get(url).then(function (data) {
                chart = new Chart(self.chartNode0);
                themes.chart.fill = '#ffffff';
                themes.plotarea.fill = '#ffffff';
                themes.chart.stroke = '#ffffff';
                chart.setTheme(themes);
                chart.titlePos = 'top';
                chart.titleGap = 20;
                chart.titleAlign = 'center';
                chart.titleFont = 'normal normal normal 15pt Arial';
                chart.title = '参集割合';

                var chartData = [];
                chartData.push(['参集割合', self.createChartData(data.items)]);
    
                chart.addPlot('grid', {
                    type: Grid,
                    hMajorLines: true,
                    hMinorLines: true,
                    vMajorLines: true,
                    vMinorLines: false,
                    majorHLine: { color: 'silver', width: 0.5 },
                    majorVLine: { color: 'silver', width: 0.5 }
                });
                chart.addPlot('convo', {
                    type: Lines,
                    markers: true,
                    labels: true
                });
                chart.addAxis('x', { majorTickStep: 1, natural: true, labels: self.addDataTimestampXAxis() });
                chart.addAxis('y', { min: 0, max: 100, vertical: true });

                console.log(chartData);
                for (var i = 0; i < chartData.length; i++) {
                    chart.addSeries(chartData[i][0], chartData[i][1], {
                        plot: chartData[i],
                        marker: ChartConfig.MARKER_SHAPE
                    });
                }
     
                chart.render();

                if (self.legend.domNode) {
                    self.legend.set('chart', chart);
                    self.legend.refresh();
                } else {
                    self.legend = new Legend({ chart: chart, horizontal: true }, self.chartLegend);
                }
            }, function (error) {
                console.log(error);
                self.chain.info('情報の取得に失敗しました。', 'エラー');
            });
        },

        filterGroup: function (data, display) {
            var groupName = null;
            var divisionCd = null;
            var groupList = [];
            data.items.forEach(function (item) {
                if (display === 'group') {
                    if (groupName === null) {
                        groupName = item.groupName;
                        groupList.push(groupName);
                    } else {
                        if (groupName !== item.groupName) {
                            groupName = item.groupName;
                            groupList.push(groupName);
                        }
                    }
                } else {
                    if (divisionCd === null) {
                        divisionCd = item.divisionCd;
                        groupList.push(item.divisionName);
                    } else {
                        if (divisionCd !== item.divisionCd) {
                            divisionCd = item.divisionCd;
                            groupList.push(item.divisionName);
                        }
                    }
                }
            });
            return groupList;
        },

        /**
         * 横軸のラベルをセット
         */
        addDataTimestampXAxis: function () {
            var labelData = [];
            var aggregateDateTime = new Date(this.form.get('value').aggregateDateTime);
            var val = 0;
            for (var i = 0; i < 13; i++) {
                if (i !== 0) {
                    aggregateDateTime.setMinutes(aggregateDateTime.getMinutes() + 10);
                }
                labelData.push({
                    value: val += 1,
                    text: DateUtils.formatTime(aggregateDateTime, 'HH:mm')
                });
            }
            return labelData;
        },

        /**
         * グラフ表示用にデータを集計
         */
        createChartData: function (dataList) {
            var self = this;
            var chartData = Array(13);
            var total = dataList.length;
            var convoCnt = Array(13);
            convoCnt.fill(0);

            dataList.forEach(function (item) {
                var replyTime = null;
                if (item.distA01 !== '対象外') {
                    var aggregateDateTime = new Date(self.form.get('value').aggregateDateTime);
                    replyTime = self.aggregateTime(item.distA01, item.replyTimestamp);
                    if (replyTime !== null) {
                        if (replyTime < aggregateDateTime) {
                            // 0分参集
                            convoCnt[0] += 1;
                        } else if (replyTime < aggregateDateTime.setMinutes(aggregateDateTime.getMinutes() + 10)) {
                            // 10分参集
                            convoCnt[1] += 1;
                        } else if (replyTime < aggregateDateTime.setMinutes(aggregateDateTime.getMinutes() + 10)) {
                            // 20分参集
                            convoCnt[2] += 1;
                        } else if (replyTime < aggregateDateTime.setMinutes(aggregateDateTime.getMinutes() + 10)) {
                            // 30分参集
                            convoCnt[3] += 1;
                        } else if (replyTime < aggregateDateTime.setMinutes(aggregateDateTime.getMinutes() + 10)) {
                            // 40分参集
                            convoCnt[4] += 1;
                        } else if (replyTime < aggregateDateTime.setMinutes(aggregateDateTime.getMinutes() + 10)) {
                            // 50分参集
                            convoCnt[5] += 1;
                        } else if (replyTime < aggregateDateTime.setMinutes(aggregateDateTime.getMinutes() + 10)) {
                            // 60分参集
                            convoCnt[6] += 1;
                        } else if (replyTime < aggregateDateTime.setMinutes(aggregateDateTime.getMinutes() + 10)) {
                            // 70分参集
                            convoCnt[7] += 1;
                        } else if (replyTime < aggregateDateTime.setMinutes(aggregateDateTime.getMinutes() + 10)) {
                            // 80分参集
                            convoCnt[8] += 1;
                        } else if (replyTime < aggregateDateTime.setMinutes(aggregateDateTime.getMinutes() + 10)) {
                            // 90分参集
                            convoCnt[9] += 1;
                        } else if (replyTime < aggregateDateTime.setMinutes(aggregateDateTime.getMinutes() + 10)) {
                            // 100分参集
                            convoCnt[10] += 1;
                        } else if (replyTime < aggregateDateTime.setMinutes(aggregateDateTime.getMinutes() + 10)) {
                            // 110分参集
                            convoCnt[11] += 1;
                        } else if (replyTime < aggregateDateTime.setMinutes(aggregateDateTime.getMinutes() + 10)) {
                            // 120分参集
                            convoCnt[12] += 1;
                        }
                    }
                } else {
                    total -= 1;
                }
            });

            // 参集割合を集計
            for (var i = 0; i < convoCnt.length; i++) {
                if (i === 0) {
                    chartData[i] = (convoCnt[i] / total) * 100;
                } else {
                    if (chartData[i - 1] === 100) {
                        chartData[i] = 100;
                    } else {
                        chartData[i] = chartData[i - 1] + ((convoCnt[i] / total) * 100);
                    }
                }
            }
            return chartData;
        },

        /**
         * 登庁時間の集計
         */
        aggregateTime: function (dist, replyTimestamp) {
            var replyDate = new Date(replyTimestamp);
            if (dist.match('30分以内')) {
                replyDate.setMinutes(replyDate.getMinutes() + 30);
            } else if (dist.match('1時間以内')) {
                replyDate.setHours(replyDate.getHours() + 1);
            } else if (dist.match('2時間以内')) {
                replyDate.setHours(replyDate.getHours() + 2);
            } else if (dist.match('3時間')) {
                replyDate.setHours(replyDate.getHours() + 3);
            } else {
                replyDate = null;
            }
            return replyDate;
        },

        /**
         * 回答状況一覧の更新を行うメソッド
         */
        updateChart: function (groupName, groupCd) {
            this.groupName.innerText = groupName;
            var url = '/api/convoMails/mails/forms/';
            url += this.distributionHistId;
            url += '/' + groupCd;
            if (this.form.get('value').organizationSelector) {
                var organizationCd = this.form.get('value').organizationSelector;
                url += '/' + organizationCd.slice(1);
            } else {
                url += '/' + 'none';
            }
            var self = this;
            Requester.get(url + '?timestamp=' + this.gridUpdDatetime.getTime()
                    ).then(function (data) {
                self.answer.innerText = data.answer;
                self.count.innerText = data.count;
                self.createChart();
            }, function (error) {
                console.log(error);
                self.chain.info('情報の取得に失敗しました。', 'エラー');
            });
        },

        /**
         * 回答状況一覧の更新を行うメソッド
         */
        updateDivisionChart: function (divisionName, divisionCd) {
            this.groupName.innerText = divisionName;
            var url = '/api/convoMails/mails/forms/';
            url += this.distributionHistId;
            url += '/division/' + divisionCd;
            if (this.form.get('value').organizationSelector) {
                var organizationCd = this.form.get('value').organizationSelector;
                url += '/' + organizationCd.slice(1);
            } else {
                url += '/' + 'none';
            }
            var self = this;
            Requester.get(url + '?timestamp=' + this.gridUpdDatetime.getTime()
                    ).then(function (data) {
                self.answer.innerText = data.answer;
                self.count.innerText = data.count;
                self.createChart();
            }, function (error) {
                console.log(error);
                self.chain.info('情報の取得に失敗しました。', 'エラー');
            });
        },

        /**
         * 職員招集状況詳細への遷移を行うメソッド
         */
        moveStatusDetailPage: function (item) {
            // Gridの情報(item)が引数となっている
            var groupCd = item.id;

            var organizationCd = 'none';
            if (this.form.get('value').organizationSelector) {
                organizationCd = this.form.get('value').organizationSelector.slice(1);
            }

            // メールのIDと職員のIDをURLに追加し、管理ページへ遷移
            Router.moveTo('convocation/statusList', {
                mid: this.distributionHistId,
                gcd: groupCd,
                sid: this.distSettingId,
                ocd: organizationCd
            });
        },

        /**
         * 職員招集状況詳細への遷移を行うメソッド
         */
        moveDivisionStatusDetailPage: function (item) {
            // Gridの情報(item)が引数となっている
            var divisionCd = item.id;

            var organizationCd = 'none';
            if (this.form.get('value').organizationSelector) {
                organizationCd = this.form.get('value').organizationSelector.slice(1);
            }

            // メールのIDと職員のIDをURLに追加し、管理ページへ遷移
            Router.moveTo('convocation/statusList', {
                mid: this.distributionHistId,
                dcd: divisionCd,
                sid: this.distSettingId,
                ocd: organizationCd
            });
        },

        /**
         * 検索ボタンが押下された際の挙動。
         */
        onSubmit: function () {
            if (!this.display || this.display === 'group') {
                this.updateConvoStatusDetailGrid();
                this.updateChart('全体', 0);
            } else {
                this.updateConvoDivisionStatusDetailGrid();
                this.updateDivisionChart('全体', '0');
            }
            // this.updateConvoStatusDetailGrid();
            // this.updateChart('全体', 0);
            return false;
        },

        /**
         * 帳票出力ボタンが押された際に呼ばれるメソッド
         */
        outputListExcel: function () {
            console.log('[帳票出力]ボタンがクリックされました。');

            var url = '/api/convoMails/mails/answerState.csv';
            url += '?distributionHistId=' + this.distributionHistId;

            // 確認ダイアログを表示
            this.chain.confirm('一覧に表示されているデータを出力します。<br>' +
                        'よろしいですか？', function (chain) {
                // 確認ダイアログでOKを押した場合
                chain.hide();
                // URLを遷移する
                window.location.href = url;
            });
        },

        /**
         * 再読込ボタンを押下したときの挙動。
         */
        onReloadButton: function () {
            // ページのリロード
            window.location.reload();
        },

        /**
         * パンくずリストのボタンを押下したときの挙動
         */
        onConvocationMailAdminPageLinkClick: function (evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('convocation/mail');
        },

        /**
         * すべてのチャートを表示
         */
        displayAll: function () {
            // ['chartNode0', 'chartNode1', 'chartNode2', 'chartNode3', 'chartNode4'
            // ].forEach(function(id) {
            //     domStyle.set(id, 'display', '');
            // });
            if (!this.display || this.display === 'group') {
                this.displayGroup();
            } else {
                this.displayDivision();
            }
        },

        displayDivision: function () {
            if (this.display !== 'division') {
                this.display = 'division';
                Router.moveTo('convocation/statusDetail', {
                    id: this.distributionHistId,
                    display: this.display
                });
            }
            domStyle.set(this.displayDivisionButton.domNode, 'display', 'none');
            domStyle.set(this.displayGroupButton.domNode, 'display', '');
            // domStyle.set('displayDivisionButton', 'display', 'none');
            // domStyle.set('displayGroupButton', 'display', '');
            domStyle.set('convoStatusDetailGrid', 'height', '0px');
            domStyle.set('convoDivisionStatusDetailGrid', 'height', '93%');
        },

        displayGroup: function () {
            if (this.display !== 'group') {
                this.display = 'group';
                Router.moveTo('convocation/statusDetail', {
                    id: this.distributionHistId,
                    display: this.display
                });
            }
            domStyle.set(this.displayDivisionButton.domNode, 'display', '');
            domStyle.set(this.displayGroupButton.domNode, 'display', 'none');
            // domStyle.set('displayDivisionButton', 'display', '');
            // domStyle.set('displayGroupButton', 'display', 'none');
            domStyle.set('convoStatusDetailGrid', 'height', '93%');
            domStyle.set('convoDivisionStatusDetailGrid', 'height', '0px');
        }
    });
});
