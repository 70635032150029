/**
 * 気象情報画面用モジュール。
 * @module app/weatherinfo/view/WeatherInfoPageRegion
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
	'dojo/_base/array',
    'idis/control/Router',
    'dojo/text!./templates/WeatherInfoPageRegion.html',
    './_WeatherInfoPageBase',
    'idis/store/IdisRest',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    './WeatherInfoGridRegion'
], function(module, declare, lang, array, Router, template, _PageBase, IdisRest) {
    /**
     * 気象情報画面
     * @class WeatherInfoPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/weatherinfo/view/WeatherInfoPage~WeatherInfoPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--weatherinfo',

        // ストア
        store: null,

        constructor: function() {
            this.inherited(arguments);
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'weatherInfoiId',
                target: '/api/WeatherInformations/region'
            });
        },

        /**
        //  * 細分区域を切り替える
        //  */
        switchDivision: function(evt) {
            evt.preventDefault();
            // 気象警報・注意報（市町村）画面へ遷移
            Router.moveTo('weatherinfo');

        }

        /**
        //  * 発令履歴画面に移動する。
        //  * @param {Object} item 参照する行のデータ
        //  */
        // moveHistoryPage: function(item) {
        //     // ダイアログの最初の子要素が詳細画面
        //     Router.moveTo('weatherinfo/history', {areaCode: item.areaCode});
        // },

        // /**
        //  * 気象情報詳細画面に移動する。
        //  * @param {Object} store ストアに含まれる全データ
        //  */
        // showStatementPage: function() {
        //     // 気象情報一覧画面へ遷移
        //     console.debug(this.store.get(0));
        //     Router.moveTo('weatherinfo/detail');
        // },

        // /**
        //  * グリッドを初期化する。
        //  */
        // initGrid: function() {
        //     this.weatherInfoGrid.set('collection', this.store.filter());
        //     this.weatherInfoGrid.on('historyButtonClick', lang.hitch(this, function(evt) {
        //         // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
        //         this.moveHistoryPage(evt.item);
        //     }));
        //     this.setSpecialColumnsDispaly();
        // },

        // onEvacOrderRegisterButtonClick: function(evt) {
        //     console.log('[新規登録]ボタンがクリックされました。');
        //     // ブラウザーの遷移処理をキャンセル
        //     evt.preventDefault();
        //     // 避難状況登録登録画面へ遷移
        //     Router.moveTo('evacorder/register');
        // }
    });
});