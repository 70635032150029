/**
* '浄水場被害情報一覧画面用モジュール。
* @module app/waterworks/JosuihigaiGrid
*/
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/util/DateUtils',
    'idis/view/form/Button',
    'dojo/on',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function(module,  declare, IdisGrid, helper, DateUtils, Button, on) {

    var formatDateTime = function(value) {
        var dateOption = {
            date:'yyyy-MM-dd',
            time:'HH:mm:ss'
        };
        return DateUtils.format(value, dateOption);
    };

    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            {field: 'detail', label:'詳細', sortable: false,
            renderCell: function(item){
                var gridNode = this.grid.domNode;
                var button = new Button({
                    label: '詳細',
                    onClick: function() {
                        on.emit(gridNode, 'detailButtonClick', {item: item});
                    }
                });
                return button.domNode;
            }},

            {field: 'josuijomei', label: '浄水場名', sortable: false,
                formatter: function(josuijomei){
                    return josuijomei ? josuijomei : '-';
                }
            },

            {field: 'higaijokyo00', label: '被害状況', sortable: false,
                formatter: function(v, item){
                    return item ? 
                            (['△', '×'].includes(item.higaijokyo00)) || (['△', '×'].includes(item.higaijokyo01)) ||
                            (['△', '×'].includes(item.higaijokyo02)) || (['△', '×'].includes(item.higaijokyo03)) || 
                            (['△', '×'].includes(item.higaijokyo04)) ? '被害あり': '被害なし' : '-';
                }
            },  

            {field: 'entryDatetime', label: '登録日時', 
                formatter: function(entryDatetime){
                    return entryDatetime ? formatDateTime(entryDatetime) : '-';
                }
            },

            {field: 'updateDatetime', label: '更新日時', 
                formatter: function(updateDatetime){
                    return updateDatetime ? formatDateTime(updateDatetime) : '-';
                }
            }
        ]
     });
 });
