/**
 * 招集メール配信ダイアログ用モジュール。
 * @module app/user/UserMailDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'idis/control/Router',
    'dojo/text!./templates/UserMailDialog.html', // テンプレート文字列
    'idis/store/IdisRest',
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/Loader',
    'idis/service/Requester',
    'idis/util/FilesUtils',
    'idis/view/dialog/DialogChain',
    'app/user/municipalityCdList',
    'dojo/dom-style',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'idis/view/form/Button'
], function(module, declare, lang, Router, template, IdisRest, _PageBase,
    Loader, Requester, FilesUtils, DialogChain, municipalityCdList, domStyle) {
    /**
     * 招集メール配信ダイアログ。
     * @class 招集メール配信ダイアログ
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/user/UserMailDialog~UserMailDialog# */
        {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            _userId: null,

            /**
             * 変数の初期化。
             */
            constructor: function() {
                // データ格納用オブジェクト
                this.mailStore = new IdisRest({
                    idProperty: 'userId',
                    target: '/api/convoMails/users'
                });
                this.chain = DialogChain.get(this);
            },

            initDialog: function (userId) {
                this._userId = userId;
                this.initForm();
            },

            initForm: function () {
                var page = this.form;
                Requester.get('/api/convoMails/users/detail/' + this._userId)
                .then(lang.hitch(this,function(data) {
                    var item = data;
                    page.set('value', item);
                    this.setCheckBoxValues(item);
                    // メール配信条件が未設定の場合コンボボックスの選択をリセット
                    if (item.earthQuake === null) {
                        this.earthQuake.set('value', '');
                    }
                    if (item.tsunami === null) {
                        this.tsunami.set('value', '');
                    }
                    // #12653追加▼
                    // 長周期地震動に関する観測情報
                    if (item.longPeriodGroundMotion === null) {
                        this.longPeriodGroundMotion.set('value', '');
                    }
                    // #12653追加▲
                }));
                this.emergencyWardsMunic.set('store', municipalityCdList);
                this.mostRecentMunic.set('store', municipalityCdList);
            },
            /**
             * 更新ボタン押下時の処理
             */
            onSubmit: function() {
                var value = this.form.get('value');
                value.userId = this._userId;
                this.checkerJudge(value);
	        },

            /**
            * 更新する。
            */
            updateManageMail: function(sendData){
                var message = '更新します。よろしいですか？';
                this.chain.confirm(message, function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.mailStore.put(sendData)).then(function() {
                        var compMessage = '完了しました。';
                        // 成功時（PUT結果はグリッドが自動的に反映）
                        chain.info(compMessage, '完了', function() {
                            chain.hide();
                            this.getParent().hide();
                        });
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }, 

            // 直近参集者のチェックがついているかの判定
            // ついていれば、区のプルダウンを表示
            checkMostRecentValue: function(value) {
                if (value) {
                    domStyle.set(this.mostRecentMunicArea, 'display', '');
                } else {
                    domStyle.set(this.mostRecentMunicArea, 'display', 'none');
                }
            },

            // 各区緊急本部員のチェックがついているかの判定
            // ついていれば、区のプルダウンを表示
            checkEmergencyWardsMunicValue: function(value) {
                if (value) {
                    domStyle.set(this.emergencyWardsMunicArea, 'display', '');
                } else {
                    domStyle.set(this.emergencyWardsMunicArea, 'display', 'none');
                }
            },

            // チェックボックスの項目について、チェックの有無を確認する
            checkerJudge: function(value){
                if (value.emergencyWards.length === 0) {
                    value.emergencyWards = [];
                    value.emergencyWardsMunic = '';
                }
                if (value.mostRecent.length === 0) {
                    value.mostRecent = [];
                    value.mostRecentMunic = '';
                }
                this.updateManageMail(value);
            },

            // チェックボックス項目のフラグに従って画面項目をセットする
            setCheckBoxValues: function(item){
                if(item.heavyRainSpecial === '1'){
                    this.heavyRainSpecial.set('value', 'on');
                }
                if(item.heavySnowSpecial === '1'){
                    this.heavySnowSpecial.set('value', 'on');
                }
                if(item.stormSpecial === '1'){
                    this.stormSpecial.set('value', 'on');
                }
                if(item.stormSnowSpecial === '1'){
                    this.stormSnowSpecial.set('value', 'on');
                }
                if(item.wavesSpecial === '1'){
                    this.wavesSpecial.set('value', 'on');
                }
                if(item.stormSurgeSpecial === '1'){
                    this.stormSurgeSpecial.set('value', 'on');
                }
                if(item.storm === '1'){
                    this.storm.set('value', 'on');
                }
                if(item.waves === '1'){
                    this.waves.set('value', 'on');
                }
                if(item.stormSurge === '1'){
                    this.stormSurge.set('value', 'on');
                }
                if(item.snow === '1'){
                    this.snow.set('value', 'on');
                }
                if(item.flood === '1'){
                    this.flood.set('value', 'on');
                }
                if(item.heavyRain === '1'){
                    this.heavyRain.set('value', 'on');
                }
                if(item.stormSnow === '1'){
                    this.stormSnow.set('value', 'on');
                }
                if(item.warnStormSurge === '1'){
                    this.warnStormSurge.set('value', 'on');
                }
                if(item.warnWaves === '1'){
                    this.warnWaves.set('value', 'on');
                }
                if(item.warnHeavyRain === '1'){
                    this.warnHeavyRain.set('value', 'on');
                }
                if(item.warnSnow === '1'){
                    this.warnSnow.set('value', 'on');
                }
                if(item.lowTemperature === '1'){
                    this.lowTemperature.set('value', 'on');
                }
                if(item.tornado === '1'){
                    this.tornado.set('value', 'on');
                }
                if(item.occuredFlood === '1'){
                    this.occuredFlood.set('value', 'on');
                }
                if(item.dangerFlood === '1'){
                    this.dangerFlood.set('value', 'on');
                }
                if(item.alertFlood === '1'){
                    this.alertFlood.set('value', 'on');
                }
                if(item.warnFlood === '1'){
                    this.warnFlood.set('value', 'on');
                }
                if(item.civil === '1'){
                    this.civil.set('value', 'on');
                }
                if(item.fourthMobilization === '1'){
                    this.fourthMobilization.set('value', 'on');
                }
                if(item.thirdMobilization === '1'){
                    this.thirdMobilization.set('value', 'on');
                }
                if(item.secondMobilization === '1'){
                    this.secondMobilization.set('value', 'on');
                }
                if(item.firstMobilization === '1'){
                    this.firstMobilization.set('value', 'on');
                }
                if(item.moistureProof === '1'){
                    this.moistureProof.set('value', 'on');
                }
                if(item.mostRecent === '1'){
                    domStyle.set(this.mostRecentMunicArea, 'display', '');
                    this.mostRecent.set('value', 'on');
                } else {
                    domStyle.set(this.mostRecentMunicArea, 'display', 'none');
                }
                if(item.emergencyMainOffice === '1'){
                    this.emergencyMainOffice.set('value', 'on');
                }
                if(item.emergencyAbeno === '1'){
                    this.emergencyAbeno.set('value', 'on');
                }
                if(item.emergencyWards === '1'){
                    domStyle.set(this.emergencyWardsMunicArea, 'display', '');
                    this.emergencyWards.set('value', 'on');
                } else {
                    domStyle.set(this.emergencyWardsMunicArea, 'display', 'none');
                }
            }
     });
});
