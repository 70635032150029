/**
 * 市町村選択用入力パーツ
 * @module app/view/form/MunicipalitySelector
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/when',
    'idis/view/form/TreeSelector',
    'idis/view/dialog/InfoDialog',
    'app/model/Municipality',
    'app/model/NoPrefMunicipality',
    'app/config'
], function(module, array, declare, lang, when, TreeSelector, InfoDialog, Municipality,
    NoPrefMunicipality, config) {
    /**
     * 地域選択用パーツ。
     * @class DistrictSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:idis/view/tree/RegionSelector~RegionSelector# */ {
        // 選択ダイアログのタイトル
        title: '市/行政区選択',

        // ツリー・モデル
        model: Municipality,

        // 県を表示しない
        noPrefecture: function(){
            this.model = NoPrefMunicipality;
        },

        /**
         * 選択した値の設定
         */
         // 継承元との差分は以下。
         // cityUnselectable特別市は選択不可。
        _setValueAttr: function(value) {
            // 偽値は数値の0のみ有効とする
            if (value || value === 0) {
                if(this.cityUnselectable){
                    //政令指定都市選択不可
                    if(value === config.municInfo.cityMunicCd){
                        InfoDialog.show('エラー', '政令指定都市は選択することができません。');
                        this.reset();
                        return false;
                    }
                }
                this._set('value', value);
                this.emit('change', {value: value});
                this._initTree().then(lang.hitch(this, function() {
                    var model = this.tree.model;
                    var label;
                    if (this.fullName) {
                        label = model.getFullName(value);
                    } else {
                        label = when(model.store.get(value), function(item) {
                            return model.getLabel(item);
                        });
                    }
                    when(label, lang.hitch(this, function(name) {
                        // 親の情報は不要なので省略する。
                        var lastIndex = name.lastIndexOf('/');
                        if(lastIndex !== -1){
                            var excludeParentName = name.substring(lastIndex + 1);
                            name = excludeParentName;
                        }
                        this.status.innerHTML = name;
                    }));
                }));
            } else {
                this._set('value', '');
                this.emit('change', {value: ''});
                this.status.innerHTML = this.emptyValueLabel;
            }
            // 要素の選択状態をリセットする
            this._initTree().then(lang.hitch(this, function() {
                this.tree.set('selectedItem', null);
            }));
            this.dialog.hide();
        }
    });
});
