/**
* 応急給水情報一覧画面用モジュール。
* @module app/waterworks/DansuiGrid
*/
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/util/DateUtils',
    'idis/view/form/Button',
    'dojo/on',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function(module,  declare, IdisGrid, helper, DateUtils, Button, on) {

    var formatDateTime = function(value) {
        var dateOption = {
            date:'yyyy-MM-dd',
            time:'HH:mm:ss'
        };
        return DateUtils.format(value, dateOption);
    };

    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            {field: 'detail', label:'詳細', sortable: false,
            renderCell: function(item){
                var gridNode = this.grid.domNode;
                var button = new Button({
                    label: '詳細',
                    onClick: function() {
                        on.emit(gridNode, 'detailButtonClick', {item: item});
                    }
                });
                return button.domNode;
            }},

            {field: 'gysNm', label: '行政区名', sortable: false,
                formatter: function(gysNm){
                    return gysNm ? gysNm : '-';
                }
            },

            {field: 'facilityNm', label: '給水拠点名', sortable: false,
                formatter: function(facilityNm){
                    return facilityNm ? facilityNm : '-';
                }
            },

            {field: 'adrChmNm', label: '町丁目名', sortable: false,
                formatter: function(adrChmNm){
                    return adrChmNm ? adrChmNm : '-';
                }
            },

            {field: 'facilityKind', label: '給水拠点種別', sortable: false,
                formatter: function(facilityKind){
                    return facilityKind ? facilityKind === '1' ? '運搬給水': facilityKind === '2' ? '拠点給水': '-' : '-';
                }
            },

            {field: 'okyuhitsuyo', label: '必要状況', sortable: false,
                formatter: function(okyuhitsuyo){
                    return okyuhitsuyo ? okyuhitsuyo : '-';
                }
            },

            {field: 'okyutaio', label: '対応状況', sortable: false,
                formatter: function(okyutaio){
                    return okyutaio ? okyutaio : '-';
                }
            },

            {field: 'kaisetsujikanFrom', label: '給水所開設時間　　開始~終了', 
                formatter: function(v,item){
                    return item ? (item.kaisetsujikanFrom !==null && item.kaisetsujikanFrom.length > 0) || (item.kaisetsujikanTo !==null && item.kaisetsujikanTo.length > 0) ?
                      item.kaisetsujikanFrom + '~' + item.kaisetsujikanTo  : '-': '-';
                }
            },

            {field: 'entryDatetime', label: '登録日時', 
                formatter: function(entryDatetime){
                    return entryDatetime ? formatDateTime(entryDatetime) : '-';
                }
            },

            {field: 'updateDatetime', label: '更新日時', 
                formatter: function(updateDatetime){
                    return updateDatetime ? formatDateTime(updateDatetime) : '-';
                }
            }
        ]
     });
 });
