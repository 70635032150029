define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dijit/form/Select',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'dgrid/Selection',
    'dgrid/Selector',
    'dgrid/Keyboard'
], function (module, declare, lang, locale, Select, IdisGrid, helper, Selection, Selector, Keyboard) {

    // var DAMAGE_ATC_FILE_THUMBNAIL_SUFFIX = '_thumbnail';

    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selection, Selector, Keyboard], {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--damage-human',

        urgencyTypeSelect: [],
        minRowsPerPage: Infinity,
        maxRowsPerPage: Infinity,
        /**
         * 各列の定義
         * @type {Object[]}
         */
        constructor: function () {

            this.columns = [
                helper.column('organization', '所属', {
                    sortable: false
                }),
                // 人的被害詳細ID - 番号
                helper.column('no', 'No', {
                    sortable: false
                }),

                helper.column('damageDate', '発生日', {
                    formatter: function (val) {
                        return new Date(val).toLocaleDateString();
                    },

                    sortable: false
                }),
                helper.column('damageTime', '発生時刻', {
                    formatter: function (val) {
                        var time = new Date(val);
                        time.setHours(time.getHours() - 9);
                        return time.toLocaleTimeString();
                    },

                    sortable: false
                }),
                helper.column('municipalityName', '区', {
                    sortable: false
                }),
                helper.column('damageAddress', '住所', {
                    sortable: false
                }),

                {
                    field: 'urgencyType',
                    label: '深刻度',
                    sortable: false,

                    renderCell: lang.hitch(this, function (item, value) {
                        this.urgencyTypeSelect[item.no] = new Select({
                            name: 'urgencyType_' + item.no,
                            options: [
                                { label: '軽微・被害なし', value: '0' },
                                { label: '低', value: '1' },
                                { label: '中', value: '2' },
                                { label: '高', value: '3' }
                            ]
                        });
                        this.urgencyTypeSelect[item.no].set('value', value);
                        // HTMLとしてウィジェットに紐付くDOMノードを返す
                        return this.urgencyTypeSelect[item.no].domNode;
                    })
                },
                helper.column('facilityName', '施設名称', {
                    sortable: false
                }),
                // 市民利用施設
                helper.column('pubFacilityFlg', '市民利用施設', {
                    formatMap: {
                        '1': 'あり',
                        '0': 'なし'
                    },
                    sortable: false
                }),

                helper.column('damageContent', '被害概要', {
                    sortable: false
                }),
                // 負傷程度
                helper.column('injuredLevel', '負傷程度', {
                    formatMap: {
                        '1': '軽傷',
                        '2': '重症',
                        '3': '行方不明',
                        '4': '死亡'
                    },
                    sortable: false
                }),

                // 年齢
                helper.column('age', '年齢', {
                    sortable: false
                }),

                // 性別
                helper.column('sex', '性別', {
                    formatMap: {
                        '0': '不明',
                        '1': '男',
                        '2': '女'
                    },
                    sortable: false
                }),

                // 職員負傷
                helper.column('empInjured', '職員負傷', {
                    formatMap: {
                        '0': 'なし',
                        '1': 'あり'
                    },
                    sortable: false
                }),

                // 消防・救急出場
                helper.column('rescueDsp', '消防・救急出場', {
                    formatMap: {
                        '0': '不明',
                        '1': 'あり',
                        '2': 'なし'
                    },
                    sortable: false
                }),

                // 警察出場
                helper.column('policeDsp', '警察出場', {
                    formatMap: {
                        '0': '不明',
                        '1': 'あり',
                        '2': 'なし'
                    },
                    sortable: false
                }),

                helper.column('name', '情報元', {
                    sortable: false
                }),

                helper.column('content', '対応状況', {
                    sortable: false
                }),
                // 備考
                helper.column('comment', '備考'),
            ];

            this.inherited(arguments);
        },
    });
});
