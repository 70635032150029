/**
 * 本部設置状況
 * @module app/disasterprevention/view/model/HqSetFlg
 */
define({
    /** 災害対策本部設置・廃止 */
    RESPONSE_HQ:'01',
    RESPONSE_HQ_NAME:'災害対策本部',
    /** 災害警戒本部設置・廃止 */
    ALERT_HQ:'02',
    /** その他本部設置・廃止 */
    OTHER_HQ:'03',
    /** 未設置 */
    NOT_YET:'98',
    /** 体制解除 */
    RELEASE:'99'
});
