/**
 * 利用者管理画面用モジュール。
 * @module app/user/UserAdminPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/text!./templates/UserAdminPage.html',
    'idis/view/page/_PageBase',
    'dojo/dom',
    'dojo/dom-style',
    'dijit/registry',
    'dijit/Dialog',
    'dstore/Memory',
    'dstore/Rest',
    'idis/consts/ACL',
    'idis/model/UserInfo',
    'idis/view/grid/IdisGrid',
    'idis/view/form/AclButton',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/service/Requester',
    'app/config',
    './_UserDialogBase',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/form/CheckBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'app/view/form/CustomizableMunicipalitySelector',
    // 'app/view/form/RegionSelector',
    'app/view/form/UserOrganizationSelector',
    './UserAdminGrid',
    './UserDetailDialog',
    './UserFileUploadDialog',
    './UserRegisterDialog',
    './UserProcessSituationDialog',
    './UserFormDialog',
    'idis/view/form/DateTimeInput',
    'idis/view/form/Button'
], function (module, declare, lang, json, template, _PageBase, dom, domStyle, registry,
    Dialog, Memory, Rest, ACL, UserInfo, IdisGrid, AclButton, IdisRest, Loader,
    DialogChain, Requester, config, _UserDialogBase) {
    /**
     * 利用者管理画面
     * @class UserAdminPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _UserDialogBase,
    //return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/user/UserAdminPage~UserAdminPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--user',

            // システム管理ユーザ
            _ROLESYSTEMADMIN: 'R01001',

            // 市・危機管理・管理ユーザ
            _ROLEADMIN: 'R01011',

            _municipalityCd: null,

            /**
             * ストア
             */
            store: null,

            /**
             * constructor
             */
            constructor: function () {
                // データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'userId',
                    target: '/api/users',
                    sortParam: 'sort',
                    rangeStartParam: 'offset',
                    rangeCountParam: 'count'
                });
                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);
            },

            /**
             * buildRendering
             */
            buildRendering: function () {
                this.inherited(arguments);
                // 自分が削除された時は関連ダイアログも削除する
                this.own(this.innerUserRegisterDialog);
                this.own(this.innerUserDetailDialog);
                this.own(this.formDialog);
                this.organizationSelector.restoreRefresh();
            },

            /**
             * startup
             */
            startup: function () {
                this.inherited(arguments);

                this.initGrid();
                this.initPage();
            },

            /**
             * グリッドを初期化する。
             */
            initGrid: function () {
                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.userAdminGrid.on('detailButtonClick', lang.hitch(this, function (evt) {
                    this.showUpdateDialog(evt.item);
                }));

                this.updateGridQuery();
            },

            /**
             * 利用者管理画面を初期化する。
             */
            initPage: function () {
                // システム管理者、全体管理者以外は新規登録ボタンを非表示
                if (!(UserInfo.getRoleCd() === ACL.ADMIN_USER || UserInfo.getRoleCd() === ACL.SYSMGR_USER)) {
                    domStyle.set(this.registerButton.domNode, 'display', 'none');
                }
                // 一般職員ユーザは帳票出力ボタンを非表示
                if (UserInfo.getRoleCd() === ACL.IPPAN_USER || this._acceptableUser.includes(UserInfo.getRoleCd())){
                    domStyle.set(this.outputButton.domNode, 'display', 'none');
                }
                // 役割セレクトボックスの初期化
                this.initSelectBox();
                this.setMunicipality();

                // 一般職員・応援職員・自主防災・福祉避難所・施設管理者・下水処理事業者・
                // 道路管理事業者の場合、自分自身のみ設定			
                if (UserInfo.getRoleCd() === ACL.IPPAN_USER || this._acceptableUser.includes(UserInfo.getRoleCd())){
                    this.userId.set('value', UserInfo.getId());
                    this.userId.set('disabled', true);
                    this.userName.set('value', UserInfo.getName());
                    this.userName.set('disabled', true);
                }


                // 新規登録ダイアログを初期化
                this.initRegisterDialog();

                // 詳細ダイアログを初期化
                this.initDetailDialog();
            },

            /**
             * 新規登録ダイアログを初期化する。
             */
            initRegisterDialog: function () {
                var dialog = this.userRegisterDialog;
                var page = dialog.getChildren()[0];

                // 登録画面のregisterイベントを受け取る
                page.on('register', lang.hitch(this, function (evt) {
                    console.debug('registerイベント');

                    var isMailAddChk = evt.value.isMailAddChk;
                    var message = evt.value.message;
                    delete evt.value.isMailAddChk;
                    delete evt.value.message;

                    // 追加確認ダイアログを表示
                    this.chain.confirm('利用者情報を登録します。', function (chain) {
                        // OKが押された場合
                        // 追加処理と共にローディング表示
                        Loader.wait(this.store.add(evt.value)).then(lang.hitch(this, function () {
                            // 成功時（POST結果はグリッドが自動的に反映）
                            // メールアドレスが登録された場合
                            if(isMailAddChk === '1'){
                                this.chain.confirm(message, function (chain) {
                                    // メール送信処理を呼び出す
                                    var promise = Requester.get('/api/password/addresscheck/' + evt.value.newUserId);
                                    Loader.wait(promise).then(lang.hitch(this, function () {
                                        // ダイアログを閉じる
                                        // dialog.hide();
                                        // 完了ダイアログを表示
                                        // chain.infoComplete();
                                        var msg = '確認メールを送信しました。<br>';
                                            msg += 'メールが届かない場合は、登録メールアドレスが正しいかどうかご確認ください。<br>';
                                            msg += 'ご不明な点はヘルプデスクまでお問い合わせをお願いいたします。';
                                        chain.info(msg, '登録完了');
                                        // ダイアログを閉じる
                                        dialog.hide();
                                        // 一覧を取得しなおす
                                        this.updateGridQuery();

                                    }), function (err) {
                                        chain.infoError(err);
                                    });
                                },function(){
                                    // onCanselの動作（ダイアログと自画面を閉じる）
                                    dialog.hide();
                                    this.chain.hide();
                                });
                            }else{
                                // 登録ダイアログを閉じる
                                dialog.hide();
                                // 完了ダイアログを表示
                                // chain.infoComplete();
                                chain.info('利用者情報を登録しました。','登録完了');
                                // 一覧を取得しなおす
                                this.updateGridQuery();
                            }
                            // 登録対象が一般職員または下水処理事業者の場合
                            if(evt.value.roleCd === ACL.IPPAN_USER || evt.value.roleCd === ACL.SEW_USER){
                                // 登録ユーザを職員参集へ反映
                                var promise2 = 
                                    Requester.get('/api/convoMails/groups/updateMuser/' + evt.value.newUserId);
                                Loader.wait(promise2).then(lang.hitch(this, function () {
                                    // 登録ユーザをチャットへ反映
                                    // var promise2 =
                                    //     Requester.get('/api/chat/updateMuser/' + evt.value.newUserId);
                                    // Loader.wait(promise2).then(lang.hitch(this, function () {
                                        // 一覧を取得しなおす
                                        this.updateGridQuery();
                                    // }), function (error) {
                                    //     // 失敗時
                                    //     chain.infoError(error);
                                    // });
                                }), function (error) {
                                    // 失敗時
                                    chain.infoError(error);
                                });
                            }
                        }), function (err) {
                            if (err.response.data) {
                                var jsonData = json.parse(err.response.data);
                                // ユーザIDが重複していた場合はDBエラー（E2001）
                                if (jsonData.code && jsonData.code === 'E2001') {
                                    chain.info('ユーザーIDが重複しています。', 'エラー');
                                    return;
                                }
                            }

                            // 失敗時
                            chain.infoError(err);
                        });
                    });
                }));
            },
            /**
             * 詳細ダイアログを初期化する。
             */
            initDetailDialog: function () {
                var dialog = this.userDetailDialog;
                var page = dialog.getChildren()[0];

                // 詳細画面のremoveイベントを受け取る
                page.on('remove', lang.hitch(this, function (evt) {
                    console.debug('removeイベント');
                    this.chain.confirmDel(function (chain) {
                        var promise = this.store.remove(evt.value.userId + '?version=' + evt.value.version);
                        Loader.wait(promise).then(lang.hitch(this, function () {
                            // 削除ユーザをチャットへ反映
                            var promise = Requester.del('/api/chat/muser/' + evt.value.userId);
                            Loader.wait(promise).then(lang.hitch(this, function () {
                                // 削除ユーザを職員参集へ反映
                                var promise = Requester.del('/api/convoMails/groups/muser/' + evt.value.userId);
                                Loader.wait(promise).then(lang.hitch(this, function () {
                                    // 登録フォーム・ダイアログを閉じる
                                    dialog.hide();
                                    chain.infoComplete();
                                    // 一覧を取得しなおす
                                    this.updateGridQuery();
                                }), function (error) {
                                    // 失敗時
                                    chain.infoError(error);
                                });
                            }), function (error) {
                                // 失敗時
                                chain.infoError(error);
                            });
                        }), function (err) {
                            // 失敗時
                            chain.infoError(err);
                        });
                    });
                }));

                // 詳細画面のupdateイベントを受け取る
                page.on('update', lang.hitch(this, function (evt) {
                    console.debug('updateイベント');

                    var isMailAddChk = evt.value.isMailAddChk;
                    var message = evt.value.message;
                    // var isMobileChange = evt.value.isMobileChange;
                    // var isAccountChange = evt.value.isAccountChange;
                    var isChange = evt.value.isChange;
                    delete evt.value.isMailAddChk;
                    delete evt.value.message;
                    // delete evt.value.isMobileChange;
                    // delete evt.value.isAccountChange;
                    delete evt.value.isChange;
                        
                    // 更新確認ダイアログを表示
                    this.chain.confirm('利用者情報を更新します。', function (chain) {
                        var form = evt.value;
                        // 追加処理と共にローディング表示
                        Loader.wait(this.store.put(form)).then(lang.hitch(this, function () {
                            // 成功時（POST結果はグリッドが自動的に反映）
                            // メールアドレスが追加または更新された場合
                            if(isMailAddChk === '1'){
                                var titleMsg = '更新完了';
                                this.chain.confirm(message, function (chain) {
                                    // メール送信処理を呼び出す
                                    var promise = Requester.get('/api/password/addresscheck/' + evt.value.userId);
                                    Loader.wait(promise).then(lang.hitch(this, function () {
                                        // ダイアログを閉じる
                                        dialog.hide();
                                        // 完了ダイアログを表示
                                        // chain.infoComplete();
                                        var msg = '確認メールを送信しました。<br>';
                                            msg += 'メールが届かない場合は、登録メールアドレスが正しいかどうかご確認ください。<br>';
                                            msg += 'ご不明な点はヘルプデスクまでお問い合わせをお願いいたします。';
                                        titleMsg += '/メール送信完了';
                                        chain.info(msg, titleMsg);
                                        // 一覧を取得しなおす
                                        this.updateGridQuery();
                                    }), function (err) {
                                        chain.infoError(err);
                                    });
                                },function(){
                                    // onCanselの動作（ダイアログと自画面を閉じる）
                                    dialog.hide();
                                    this.chain.hide();
                                });
                            }else{
                                // 登録ダイアログを閉じる
                                dialog.hide();
                                // 完了ダイアログを表示
                                // chain.infoComplete();
                                chain.info('利用者情報を更新しました。','更新完了');
                                // 一覧を取得しなおす
                                this.updateGridQuery();
                            }
                            // 登録対象が一般職員または下水処理事業者 かつ 動員・アカウントフラグ・役職・所属組織の変更がある場合、更新ユーザを反映
                            if((evt.value.roleCd === ACL.IPPAN_USER || evt.value.roleCd === ACL.SEW_USER) && isChange === '1'){
                                // 更新ユーザを職員参集へ反映
                                var promise2 = Requester.get('/api/convoMails/groups/updateMuser/' + evt.value.userId);
                                Loader.wait(promise2).then(lang.hitch(this, function () {
                                    // 更新ユーザをチャットへ反映
                                    // var promise2 = 
                                    //     Requester.get('/api/chat/updateMuser/' + evt.value.userId);
                                    // Loader.wait(promise2).then(lang.hitch(this, function () {
                                        // 一覧を取得しなおす
                                        this.updateGridQuery();
                                    // }), function (error) {
                                    //     // 失敗時
                                    //     chain.infoError(error);
                                    // });
                                }), function (error) {
                                    // 失敗時
                                    chain.infoError(error);
                                });
                            }
                        }), function (err) {
                            // 失敗時
                            chain.infoError(err);
                        });
                    });
                }));

                // 詳細画面のissueイベントを受け取る
                page.on('issue', lang.hitch(this, function (evt) {
                    console.debug('issueイベント');
                    this.chain.confirm(evt.value.message, function (chain) {
                        // 引き換えコード取得処理を呼び出す
                        var promise = Requester.get('/api/excode/issue/' + evt.value.userId);
                        Loader.wait(promise).then(lang.hitch(this, function () {
                            // 詳細フォーム・ダイアログを閉じる
                            dialog.hide();
                            // chain.infoComplete();
                            chain.info('iOSアプリ引き換えコードを再発行しました', '処理完了');
                            // 一覧を取得しなおす
                            this.updateGridQuery();
                        }), function (err) {
                            var jsonData;
                            if (err.response.data) {
                                jsonData = err.response.data;
                                // 発行できる引き換えコードが存在しない場合はDBエラー（E2001）
                                if (jsonData.code && jsonData.code === 'E2001') {
                                    chain.info('発行できる引き換えコードがありません。', 'エラー');
                                    return;
                                }
                            }
                            // 失敗時
                            err.response.data = jsonData;
                            chain.infoError(err);
                        });
                    });
                }));

                // 詳細画面のreset-passwordイベントを受け取る
                page.on('reset-password', lang.hitch(this, function (evt) {
                    console.debug('reset-passwordイベント');
                    this.chain.confirm(evt.value.message, function (chain) {
                        delete evt.value.message;
                        // 所属組織名取得処理と共にローディング表示
                        Loader.wait(this.store.put(evt.value)).then(lang.hitch(this, function () {
                            var url = evt.value.organization.substr(0, 1) === 'S' ?
                                'api/organization/sects/' : 'api/organization/depts/';
                            var promise = Requester.get(url + evt.value.organization.substr(1));
                            Loader.wait(promise).then(lang.hitch(this, function (org) {
                                evt.value.stationName = org.municipalityName + ' / ' + org.deptName;
                                evt.value.organizationName = org.deptName;
                                // 組織名が取得できた場合は組織名もセット
                                evt.value.organizationName += !org.sectName ? '' : (' / ' + org.sectName);

                                // メール送信処理を呼び出す
                                var promise = Requester.get('/api/password/sendpass/' +
                                    evt.value.userId + '/' + evt.value.userPassword);
                                Loader.wait(promise).then(lang.hitch(this, function () {
                                    // ダイアログを閉じる
                                    dialog.hide();
                                    // 完了ダイアログを表示
                                    // chain.infoComplete();
                                    var msg = 'ログインパスワードをリセットしました。<br>';
                                    msg += '以降は再発行されたパスワードでログインしてください。';
                                    chain.info(msg, 'パスワードリセット/ダウンロード完了');
                                    // 一覧を取得しなおす
                                    this.updateGridQuery();
                                }), function (err) {
                                    chain.infoError(err);
                                });

                                // アカウント情報のダウンロード
                                this.downloadAccountInfo(evt.value);

                            }), function (error) {
                                // 失敗時
                                chain.infoError(error);
                            });
                        }), function (err) {
                            // 失敗時
                            chain.infoError(err);
                        });
                    });
                }));
            },
            // アカウント情報をダウンロードする
            downloadAccountInfo: function (value) {
                var content = '【大阪市防災情報システム アカウント情報】' + '\n\n' +
                    'ユーザID               ：' + value.userId + '\n' +
                    'ユーザ名（漢字）       ：' + value.userName + '\n' +
                    '平常時所属局           ：' + value.stationName + '\n' +
                    '平常時所属部課         ：' + value.organizationName + '\n' +
                    'パスワード             ：' + value.userPassword + '\n' +
                    'iOSアプリ引き換えコード：' + (value.exchangeCode ? value.exchangeCode : '未発行');

                var a = null;
                var evt = null;
                var blob = null;
                var name = '大阪市防災情報システム_アカウント情報_' + value.userId + '.txt';

                // IE10+
                if (window.navigator.msSaveOrOpenBlob) {
                    blob = new Blob([content], { 'type': 'application/octet-stream' });
                    window.navigator.msSaveOrOpenBlob(blob, name);
                } else {
                    // firefox, chrome
                    blob = new Blob([content], { 'type': 'text/plain' });

                    a = document.createElement('a');
                    a.setAttribute('target', '_blank');
                    a.setAttribute('href', window.URL.createObjectURL(blob));
                    a.setAttribute('download', name);

                    // IE対策
                    a.innerHTML = '<a></a>';
                    var el = a.childNodes[0];
                    a.removeChild(el);

                    evt = document.createEvent('MouseEvents');
                    evt.initEvent('click', false, false);
                    a.dispatchEvent(evt);
                }
            },

            // 役割セレクトボックスの初期化を行う
            initSelectBox: function () {
                var promise = Requester.get('/api/roles');
                Loader.wait(promise).then(lang.hitch(this, function (data) {
                    // セレクトボックスに設定するデータの配列を作成
                    var newOptions = [{
                        label: '&nbsp;',
                        value: ''
                    }];
                    // サーバーレスポンスの各要素をselectへ追加
                    data.items.forEach(function (object) {
                        newOptions.push({
                            label: object.roleName,
                            value: object.roleCd
                        });
                    });

                    var role = UserInfo.getRoleCd();
                    var roleCdOptions = newOptions;
                    // システム管理者もアカウント種類に表示する（検索可）
                    // if(role!==ACL.ADMIN_USER){
                    //     roleCdOptions = newOptions.filter(function(newOptions){
                    //         return newOptions.value !== ACL.ADMIN_USER;
                    //     }) ;
                    // }
                    this.roleCdSelect.set('options', roleCdOptions);	
                    if (role === ACL.IPPAN_USER || this._acceptableUser.includes(UserInfo.getRoleCd())){
                        // アカウント種別はログインユーザの持つアカウントに固定
                        this.roleCdSelect.set('value', UserInfo.getRoleCd());
                        this.roleCdSelect.set('readOnly', true);
                    } else if (role === ACL.XXHONBU_USER &&
                        (UserInfo.getOrganization().deptCd === config.dept.humanResourceDeptCd ||
                            UserInfo.getOrganization().deptCd === config.dept.soumuDeptCd)) {
                        // 人事室および総務局の所属本部ユーザの場合、アカウント種別は応援職員に固定
                        this.roleCdSelect.set('value', ACL.AID_USER);
                        this.roleCdSelect.set('readOnly', true);
                    }
                }));
            },

            // 市町村セレクタの値が変更するときの動作
            onMunicipalityCdChanged: function (municipalityCd) {
                if (this._municipalityCd === municipalityCd.value) {
                    return;
                } else {
                    this._municipalityCd = municipalityCd.value;
                }
                this.organizationSelector.restoreRefresh();
                this.organizationSelector.set('value', municipalityCd.value);
                this.organizationSelector.setMunicipalityCd(municipalityCd.value);
            },

            // 所属セレクタの値が変更するときの動作
            onOrganizationChanged: function (organization) {
                if (!organization.value) {
                    this._municipalityCd = null;
                    this.organizationSelector.set('value', '');
                    return;
                }
                // 所属から市区を逆引き
                var url = organization.value.substr(0, 1) === 'S' ?
                    'api/organization/sects/' : organization.value.substr(0, 1) === 'D' ?
                        'api/organization/depts/' : '';
                if (!url) {
                    return;
                }
                var promise = Requester.get(url + organization.value.substr(1));
                Loader.wait(promise).then(lang.hitch(this, function (org) {
                    if (this._municipalityCd === org.municipalityCd) {
                        return;
                    }
                    // 市区を設定
                    if (organization.value.substr(1) === org.deptCd || organization.value.substr(1) === org.sectCd) {
                        this.municipalityCd.set('value', org.municipalityCd);
                        this._municipalityCd = org.municipalityCd;
                        // 所属を設定
                        this.organizationSelector.set('value', organization.value);
                    }
                }), function (error) {
                    // 失敗時
                    console.error(error);
                });
            },

            // 振興局セレクタの値が変更するときの動作
            // onRegionCdChanged: function (regionCd) {
            //     this.organizationSelector.setRegionCd(regionCd.value);
            // },

            // ログインユーザの市/行政区、組織をセットする
            setMunicipality: function () {
                var role = UserInfo.getRoleCd();
                var munic = UserInfo.getMunicipalityCd();
                // システム管理者・全体管理者・市本部管理者以外は市区選択セレクタを非表示
                if (!(role === ACL.ADMIN_USER || role === ACL.SYSMGR_USER || role === ACL.SHMGR_USER)) {
                    this.municipalityCd.set('value', munic);
                    domStyle.set(this.municipalityCd.selectButton.domNode, 'display', 'none');
                    // 所属管理者・所属本部ユーザ（人事部および総務局以外）・一般職員・応援職員・自主防災・福祉避難所・施設管理者・下水処理事業者・
                    // 道路管理事業者は自組織のみ閲覧可能
                    if (role === ACL.XXMGR_USER ||
                        (role === ACL.XXHONBU_USER &&
                            (UserInfo.getOrganization().deptCd !== config.dept.humanResourceDeptCd ||
                                UserInfo.getOrganization().deptCd !== config.dept.soumuDeptCd )) ||
                                role=== ACL.IPPAN_USER || this._acceptableUser.includes(role)) {
                        // this.organizationSelector.restoreRefresh();
                        // 所属組織をセレクタにセット
                        // this.organizationSelector.set('value', 'D' + UserInfo.getOrganization().deptCd);
                        var org = UserInfo.getOrganization().unitCd !== null ? 'U' + UserInfo.getOrganization().unitCd :
                        UserInfo.getOrganization().sectCd !== null ? 'S' + UserInfo.getOrganization().sectCd :
                            'D' + UserInfo.getOrganization().deptCd;
                            this.organizationSelector.set('value', org);
                        domStyle.set(this.organizationSelector.selectButton.domNode, 'display', 'none');
                    }
                }
            },

            /**
             * 利用者を検索する。
             */
            onSubmit: function () {
                console.debug('[検索]ボタンがクリックされました。');
                // 検索する組織が自分より上位の場合、エラーメッセージが出る
                if (!this.isValidOrg()) {
                    return false;
                }
                try {
                    if (this.form.isValid()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery();
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * 利用者新規登録ダイアログを表示する。
             */
            showRegisterDialog: function () {
                console.debug('[新規登録]ボタンがクリックされました。');
                this.innerUserRegisterDialog.initDialog();
                this.userRegisterDialog.show();
            },

            /**
             * 利用者詳細ダイアログを表示する。
             */
            showUpdateDialog: function (object) {
                console.debug('[詳細]ボタンがクリックされました。');
                this.innerUserDetailDialog.initDialog(object);
                this.userDetailDialog.show();
            },

            /**
             * 取込結果ダイアログを表示する。
             */
            showCheckResultDialog: function () {
                this.userCheckResultDialog.show();
            },

            /**
             * ファイル読み込みダイアログを表示する。
             */
            showFileUpLoadDialog: function () {
                var dialog = this.userFileUploadDialog;
                var page = dialog.getChildren()[0];

                page.initDialog();
                dialog.show();
            },

            /**
             * 処理結果確認ダイアログを表示する。
             */
            showProcessSituationDialog: function () {
                // 詳細ダイアログの最初の子要素が画面の情報
                var dialog = this.userProcessSituationDialog;
                var page = dialog.getChildren()[0];
                page.form.reset();

                // CSVダウンロードボタンを非活性にしておく
                page.csvDownloadButton.setDisabled(true);

                var url = '/api/users/processSituation';
                var promise = Requester.get(url).then(function (data) {
                    page.processSituation.innerHTML = data.processSituation;
                    page.uploadStartDate.innerHTML = data.uploadStartDate;
                    page.uploadEndDate.innerHTML = data.uploadEndDate;
                    page.allNum.innerHTML = data.allNum;
                    page.completeNum.innerHTML = data.completeNum;
                    page.errorNum.innerHTML = data.errorNum;
                    page.form.set('value', data);

                    // 処理状況が完了(一部失敗)の場合、CSVダウンロードボタン活性
                    if (data.processSituation === '完了(一部失敗)') {
                        page.csvDownloadButton.setDisabled(false);
                    }
                }, lang.hitch(this, function (error) {
                    // 取得に失敗した場合は、ログ出力とメッセージのダイアログ表示を行う。
                    console.log(error);
                    this.chain.info('処理状況の取得に失敗しました。', 'エラー');
                }));
                Loader.wait(promise);
                this.userProcessSituationDialog.show();
            },

            filterByType: function (value) {
                alert(value);
            },

            /**
             * グリットを更新する。
             */
            updateGridQuery: function () {
                var filter = new this.store.Filter();
                var value = this.form.get('value');
                console.log(value);
                var role = UserInfo.getRoleCd();
                var org = UserInfo.getOrganization().unitCd !== null ? 'U' + UserInfo.getOrganization().unitCd :
                    UserInfo.getOrganization().sectCd !== null ? 'S' + UserInfo.getOrganization().sectCd :
                        'D' + UserInfo.getOrganization().deptCd;

                // ユーザ種別による検索
                if (value.roleCd && value.roleCd !== '') {
                    filter = filter.eq('roleCd', value.roleCd);
                }

                // 市町村による検索
                if (value.municipalityCd && value.municipalityCd !== '') {
                    filter = filter.eq('municipalityCd', value.municipalityCd);
                }

                // 振興局による検索
                // if (value.regionCd && value.regionCd !== '') {
                //     filter = filter.eq('regionCd', value.regionCd);
                // }

                // 組織による検索
                if (role === ACL.ADMIN_USER || role === ACL.SYSMGR_USER || role === ACL.SHMGR_USER) {
                    // システム管理者、全体管理者、市本部管理者の場合（閲覧組織制約なし）
                    if (value.organization && value.organization !== '') {
                        filter = filter.eq('organization', value.organization);
                    }
                } else if (role === ACL.XXHONBU_USER &&
                    (UserInfo.getOrganization().deptCd === config.dept.humanResourceDeptCd ||
                        UserInfo.getOrganization().deptCd === config.dept.soumuDeptCd )) {
                    // 人事室および総務局の所属本部ユーザの場合（全応援職員ユーザを見せるため閲覧組織制約なし）
                    filter = filter.eq('roleCd', ACL.AID_USER);
                    if (value.organization && value.organization !== '') {
                        filter = filter.eq('organization', value.organization);
                    }
                } else if (role === ACL.IPPAN_USER || this._acceptableUser.includes(role)) {
                    // 一般職員・応援職員・自主防災・福祉避難所・施設管理者・下水処理事業者・
                    // 道路管理事業者自分自身のみ
                    filter = filter.eq('userId', UserInfo.getId());
                } else {
                    // 上記以外の場合、自組織のみ
                    if (value.organization && value.organization !== '') {
                        filter = filter.eq('organization', value.organization);
                    } else {
                        filter = filter.eq('organization', org);
                    }
                }
                filter = filter.eq('userId', value.userId);
                if (value.userName && value.userName !== '') {
                    filter = filter.eq('userName', value.userName);
                }
                if (value.nonLogin.length !== 0) {
                    filter = filter.eq('nonLogin', 1);
                }
                if (value.loginDateTimeFrom) {
                    filter = filter.eq('loginDateTimeFrom', new Date(value.loginDateTimeFrom).getTime());
                }
                if (value.loginDateTimeTo) {
                    filter = filter.eq('loginDateTimeTo', new Date(value.loginDateTimeTo).getTime());
                }
                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);

                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.userAdminGrid.set('collection', collection);
            },


            /**
             * 利用者情報をエクセル形式で出力する。
             */
            onOutputButtonClick: function () {
                var dialog = this.formDialog;
                var page = dialog.getChildren()[0];
                var value = this.form.get('value');
                var municipalityCd = value.municipalityCd;
                var organization = value.organization;
                var roleCd = value.roleCd;

                page.initSelectBox(roleCd);
                page.setInitValue(municipalityCd, organization);
                this.formDialog.show();

            }
        });
});
