/**
 * 施設登録画面用モジュール。
 * @module app/facility/FacilityRegisterPage
 */
define([
    'module',
    'app/config',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-style',
    'dojo/text!./templates/FacilityRegisterPage.html',
    'dojo/on',
    // 'dojo/request/iframe',
    'idis/view/page/_PageBase',
    'idis/control/Router',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/ConfirmDialog',
    'idis/view/dialog/IdisDialog',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'idis/service/GeoService',
    'idis/service/iframe',
    'idis/service/Requester',
    'idis/util/FilesUtils',
    'esri-leaflet-geocoder',
    'leaflet',
    'idis/control/Locator',
    'app/model/DisasterInfo',
    'app/map/baselayer/BaseLayerPane',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/FilteringSelect',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/NumberTextBox',
    'dijit/form/Textarea',
    'dijit/form/CheckBox',
    'dijit/form/RadioButton',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/TitlePane',
    'dijit/layout/TabContainer',
    'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateInput',
    'idis/view/form/TimeInput',
    'idis/view/form/WordCountTextarea',
    'app/view/form/DistrictSelector',
    'app/view/form/ManageRegionMunicipalitySelector'
], function (module, config, array, declare, lang, domClass, domConstruct, domStyle, template, on,
    _PageBase, Router, DialogChain, InfoDialog, ConfirmDialog, IdisDialog, IdisMap, UserInfo,
    IdisRest, Loader, Menu, MenuItem, popup, TooltipDialog, GeoService, iframe, Requester, FilesUtils,
    geocoder, leaflet, Locator, DisasterInfo, BaseLayerPane) {
    // GeoServiceを初期化
    var _geoService = null;
    /**
     * 施設登録画面
     * @class FacilityRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/facility/FacilityRegisterPage~FacilityRegisterPage# */ {
            // テンプレート文字列
            templateString: template,
            confirmDialog: null,

            /**
             * データ格納用オブジェクト
             * @type {module:dstore/Store}
             */
            store: null,

            /**
             * 災害ID
             */
            _disasterId: null,

            /**
             * 市町村コード
             */
            _municipalityCd: null,

            /**
             * 市町村名
             */
            _municipalityName: null,

            /**
             * 住所 緯度
             */
            _addressLat: null,

            /**
             * 住所 経度
             */
            _addressLng: null,

            /**
             * 添付ファイルリスト
             */
            attachFileList: [],

            constructor: function () {
                // データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'facilityId',
                    target: '/api/facility'
                });
                // ダイアログ連鎖を登録
                // 引数に与えたウィジェットのthis.ownを呼び出し、
                // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
                this.chain = DialogChain.get(this);

                // 市町村コードを取得
                var municipalityCd = UserInfo.getMunicipalityCds()[0] !== '' ?
                    UserInfo.getMunicipalityCds()[0] : UserInfo.getMunicipalityCd();
                if (municipalityCd) {
                    this._municipalityCd = municipalityCd;
                } else {
                    this._municipalityCd = config.municInfo.cityMunicCd;
                }
                // 災害IDをDisasterInfoから取得
                this._disasterId = DisasterInfo.getDisasterId();
                if (!this._disasterId) {
                    this._disasterId = 1;
                    console.warn('災害IDが設定されていません。');
                }
                console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
            },

            // HTML上にウィジェットが設置されてから呼ばれる
            startup: function () {
                console.debug('FacilityRegisterPage#startup()');
                this.inherited(arguments);
                // 地図を初期化
                this.initMap();
                // ツールチップをセット
                this.setAvailableTips();
            },

            onSubmit: function () {
                var sendData = this.form.get('value');
                //添付ファイルIDをセット
                if (this.attachFileList.length > 0) {
                    var attachFileIds = [];
                    for (var i = 0; i < this.attachFileList.length; i++) {
                        attachFileIds.push(this.attachFileList[i].facilityAtcFileId);
                    }
                    sendData.attachFileIds = attachFileIds.join(',');
                } else {
                    sendData.attachFileIds = '';
                }
                delete sendData.attachFile;
                delete sendData.organizationName;
                delete sendData.municipalityName;
                // 臨時避難所の場合、災害IDを送る
                if (sendData.temporaryEvacShFlg.length !== 0) {
                    sendData.disasterId = this._disasterId;
                }
                // フォームのバリデーションを行う（共通部品）
                if (!this.form.validate()) {
                    return false;
                }
                // フォームのバリデーションを行う（独自チェック）
                if (!this.validateForm(sendData)) {
                    return false;
                }
                sendData.pointLat = this.pointLat === '' ? null : this.pointLat;
                sendData.pointLng = this.pointLng === '' ? null : this.pointLng;
                // 地図チェック
                if (sendData.pointLat === '' || sendData.pointLng === '' ||
                    sendData.pointLat === null || sendData.pointLng === null) {
                    // 地図マーカが無いので住所から緯度経度を求める
                    this.addressToLatLng().then(lang.hitch(this, function () {
                        sendData.pointLat = this.pointLat;
                        sendData.pointLng = this.pointLng;
                        // 住所が正しく入力されていない場合確認ダイアログを表示する
                        if (sendData.pointLat === '' || sendData.pointLng === '' ||
                            sendData.pointLat === null || sendData.pointLng === null) {
                            console.debug('住所から緯度経度が取得できません');
                            this.chain.confirm('住所から緯度・経度が取得できませんでした。<br>' +
                                '緯度・経度の情報が必要な場合は、キャンセルして地図上で施設の位置をクリックしてください。<br>' +
                                '緯度・経度の情報が不要な場合は、OKボタンを押して登録を完了してください。',
                                function (chain) {
                                    // 追加処理と共にローディング表示
                                    Loader.wait(this.store.add(sendData)).then(function () {
                                        var compMessage = '完了しました。';
                                        // 成功時（POST結果はグリッドが自動的に反映）
                                        chain.info(compMessage, '完了', function () {
                                            // 一覧画面に移動
                                            Router.moveTo('facility');
                                        });
                                    }, function (err) {
                                        // 失敗時
                                        console.error(err);
                                        chain.info('登録に失敗しました。', 'エラー');
                                    });
                                });
                            return;
                        }
                    }));
                }

                this.chain.confirmAdd(function (chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.store.add(sendData)).then(function () {
                        var compMessage = '完了しました。';
                        // 成功時（POST結果はグリッドが自動的に反映）
                        chain.info(compMessage, '完了', function () {
                            // 一覧画面に移動
                            Router.moveTo('facility');
                        });
                    }, function (err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            },

            /**
             * マップを初期化する。
             */
            initMap: function () {
                console.debug('FacilityRegisterPage#initMap()');
                // 市町村を取得
                var self = this;
                var promise = Requester.get('/api/municipalities/' + this._municipalityCd, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(function (data) {
                    // マップの生成
                    var latlng = null;
                    if (data.latitude && data.longitude) {
                        latlng = [data.latitude, data.longitude];
                    } else {
                        latlng = [config.map.latitude, config.map.longitude];
                    }
                    self.map = new IdisMap(self.mapNode, {
                        config: config.map,
                        keyboard: false, // コメント時に+/-が使用できないため
                        touchExtend: false,
                        minZoom: 9,
                        maxZoom: 18,
                        drawControlTooltips: false
                    }
                    ).setView(latlng, 12);
                    // destroy時にmapを破棄するよう設定
                    self.own(self.map);
                    self.own(on(self.map, 'click', lang.hitch(self, function (e) {
                        self.pointLat = e.latlng.lat;
                        self.pointLng = e.latlng.lng;
                        this.addMark(self.pointLat, self.pointLng, self);
                    })));
                    //初期化
                    self.pointLat = '';
                    self.pointLng = '';
                    self.marker = '';
                }, function (error) {
                    self.chain.infoError(error);
                });
                //ローダーの表示
                Loader.wait(promise);
            },

            /**
             * 背景地図ダイアログを表示する。
             */
            showBaseLayerDialog: function () {
                if (!this._baseLayerDialog) {
                    // 初回呼び出し時にインスタンス生成
                    this._baseLayerDialog = new IdisDialog({
                        noUnderlay: true,
                        title: '背景地図',
                        content: new BaseLayerPane({ map: this.map })
                    });
                    // 画面が破棄された際に連れて行く
                    this.own(this._baseLayerDialog);
                }
                this._baseLayerDialog.show();
            },

            /**
             * 地図上でポイントされている位置の住所を設定します。
             */
            mapToAddress: function () {
                if (this.pointLat === '' || this.pointLng === '') {
                    console.debug('not pointed map');
                    this.chain.info('地図が選択されていません。', 'エラー');
                    return;
                }
                console.debug('start reverse geocoding');

                if (_geoService === null) {
                    _geoService = new GeoService({ url: config.geocode && config.geocode.url });
                }
                _geoService.reverseGeocode(leaflet.latLng({
                    lat: this.pointLat,
                    lng: this.pointLng
                })).then(lang.hitch(this, function (res) {
                    this.addMark(this.pointLat, this.pointLng);
                    this.address.set('value', res.address.Address);
                    this._addressLat = this.pointLat;
                    this._addressLng = this.pointLng;
                }), lang.hitch(this, function () {
                    this.chain.info('住所を取得できませんでした。', 'エラー');
                }));

                console.debug('end reverse geocoding (address: ' +
                    this.address.value + ')');
            },

            /**
             * 住所の位置を地図上にポイントします。
             */
            addressToMap: function () {
                if (!this.address.value) {
                    this.chain.info('住所を入力してください。', 'エラー');
                    return;
                }
                console.debug('start geocoding');
                var address = this.address.value;
                // 住所欄に市名が記載されていない場合、追加して検索する。
                if (address.indexOf(config.municInfo.cityMunicName) !== 0) {
                    address = config.municInfo.prefName + config.municInfo.cityMunicName + address;
                }
                // 住所欄に県名が記載されていない場合、追加して検索する。
                if (address.indexOf(config.municInfo.prefName) !== 0) {
                    address = config.municInfo.prefName + address;
                }

                if (_geoService === null) {
                    _geoService = new GeoService({ url: config.geocode && config.geocode.url });
                }
                _geoService.geocode(address).then(lang.hitch(this, function (results) {
                    if (results.length > 0) {
                        var latlng = [results[0].latlng.lat, results[0].latlng.lng];
                        this.pointLat = results[0].latlng.lat;
                        this.pointLng = results[0].latlng.lng;
                        this.addMark(this.pointLat, this.pointLng);
                        this.map.setView(latlng, 14);
                        this._addressLat = results[0].latlng.lat;
                        this._addressLng = results[0].latlng.lng;
                    } else {
                        console.debug('address is not correct');
                        this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                    }
                }));
            },

            /**
             * マーカーを追加する。
             */
            addMark: function (lat, lng) {
                this.removeMark();
                this.marker = leaflet.marker([lat, lng]).addTo(this.map);
            },

            /**
             * マーカーを削除する。
             */
            removeMark: function () {
                if (this.marker) {
                    this.map.removeLayer(this.marker);
                }
            },

            /**
             * 住所の位置から緯度経度を求める。
             */
            addressToLatLng: function () {
                console.debug('start geocoding');
                //住所欄に県名が記載されていない場合、追加して検索する。
                var address = this.address.value;
                if (this.address.value.indexOf(config.municInfo.prefName) !== 0) {
                    address = config.municInfo.prefName + address;
                }
                if (_geoService === null) {
                    _geoService = new GeoService({ url: config.geocode && config.geocode.url });
                }
                return _geoService.geocode(address).then(lang.hitch(this, function (results) {
                    if (results.length > 0) {
                        this.pointLat = results[0].latlng.lat;
                        this._addressLat = results[0].latlng.lat;
                        this.pointLng = results[0].latlng.lng;
                        this._addressLng = results[0].latlng.lng;
                    } else {
                        console.debug('address is not correct');
                        //this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                    }
                }));

            },

            /**
             * 入力値の妥当性をチェックする。
             */
            validateForm: function (sendData) {
                // 入力チェック
                // 施設種別の入力確認
                if (!sendData.facilityTypeId) {
                    console.debug('施設種別が選択されていません');
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '施設種別が選択されていません'
                        });
                        this.infoDialog.show();
                        this.infoDialog = null;
                    }
                    return false;
                }
                // 避難所を選択した上で、避難所区分が一つも選択されていなければエラー
                if (sendData.facilityTypeId === '01' &&
                    sendData.designatedEvacShFlg.length === 0 &&
                    sendData.welfareEvacShFlg.length === 0 &&
                    sendData.temporaryEvacShFlg.length === 0) {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '避難所区分を選択して下さい。'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
                // 施設名称の入力確認
                if (!sendData.facilityName) {
                    console.debug('施設名称が入力されていません');
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '施設名称が入力されていません'
                        });
                        this.infoDialog.show();
                        this.infoDialog = null;
                    }
                    return false;
                }
                // 施設名称（カナ）の入力確認
                if (!sendData.facilityNameKana) {
                    console.debug('フリガナが入力されていません');
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: 'フリガナが入力されていません'
                        });
                        this.infoDialog.show();
                        this.infoDialog = null;
                    }
                    return false;
                }
                // 地区の選択確認
                if (sendData.districtCd === '' || !sendData.districtCd) {
                    console.debug('地区が選択されていません');
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '地区が選択されていません'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
                // 住所の入力確認
                if (!sendData.address) {
                    console.debug('住所が入力されていません');
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '住所が入力されていません'
                        });
                        this.infoDialog.show();
                        this.infoDialog = null;
                    }
                    return false;
                }
                // 臨時避難所を選択の上、災害IDがnullであればエラー
                if (sendData.temporaryEvacShFlg.length !== 0 && sendData.disasterId === null) {
                    console.debug('災害名が正しく選択されていません');
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '災害名が正しく選択されていません'
                        });
                        this.infoDialog.show();
                        this.infoDialog = null;
                    }
                    return false;
                }
                // 避難所区分の組み合わせ確認
                // ｛災害時、臨時｝｛福祉、臨時｝｛災害時、福祉、臨時｝はNG
                if (sendData.temporaryEvacShFlg.length !== 0 &&
                    (sendData.designatedEvacShFlg.length !== 0 ||
                        sendData.welfareEvacShFlg.length !== 0)) {
                    console.debug('臨時避難所は、災害時避難所または福祉避難所と同時に選択できません');
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '臨時避難所は、災害時避難所または福祉避難所と同時に選択できません'
                        });
                        this.infoDialog.show();
                        this.infoDialog = null;
                    }
                    return false;
                }
                return true;
            },

            /**
             * 避難所マスタ一覧リンクをクリックしたとき
             */
            onFacilityLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('facility');
            },

            /**
             * 添付ファイルをアップロードする。
             */
            loadAttachFile: function () {
                // ファイルが空の場合は処理を中断＆不正なファイルの場合、メッセージ表示して処理を中断
                if (this.attachFile._files.length === 0 //||
                    // 札幌市では対象ファイルを制限しない
                    //!FilesUtils.isAttachFile(this.attachFile)
                ) {
                    return;
                }

                console.log('file change');
                var self = this;

                this.attachFile.set('disabled', false);

                // ファイルがonloadされたときにサーバーに一時保存する
                var promise = iframe.post('/api/facility/uploadFile', {
                    form: this.form.id,
                    handleAs: 'json'
                }).then(function (data) {
                    console.log(data);
                    //uploaderをリセット
                    self.attachFile.reset();
                    self.attachFileList.push(data);
                    self.showPreview(data, true);
                }, function (error) {
                    console.log(error);
                    //uploaderをリセット
                    self.attachFile.reset();
                    self.chain.infoError(error);
                });

                //ローダーの表示
                Loader.wait(promise);
            },

            /**
             * 添付ファイルのプレビューを表示する。
             */
            showPreview: function (data, exifFlg) {

                var dataUri = data.attachFilePath.replace('out/', 'data/');
                var fileName = data.attachFileName;
                var fileId = data.facilityAtcFileId;
                var self = this;

                // 画像ファイルの場合
                if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                    fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                    fileName.indexOf('.gif') !== -1) {
                    var image = new Image();

                    //JPEGファイルの場合、EXIFデータの取得を実行する
                    if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                        fileName.indexOf('.JPG') !== -1 || fileName.indexOf('.JPEG') !== -1) {
                        var img = null;
                        this.own(on(image, 'load', lang.hitch(this, function (e) {
                            console.log(e);
                            img = e.target;

                            if (exifFlg) {
                                this.getExifData(img, this);
                            }
                        })));
                    }
                    image.src = dataUri;
                    domClass.add(image, 'is-showPreview');
                    domConstruct.place(image, this.preview);
                    //メニューの作成
                    this.createMenu(image, dataUri, fileName, fileId, self);

                } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                    var excel = new Image();
                    excel.src = 'images/excelicon.png';
                    domClass.add(excel, 'is-showPreview');
                    domConstruct.place(excel, this.preview);
                    //メニューの作成
                    this.createMenu(excel, dataUri, fileName, fileId, self);
                } else if (fileName.indexOf('.pdf') !== -1) {
                    var pdf = new Image();
                    pdf.src = 'images/pdficon.png';
                    domClass.add(pdf, 'is-showPreview');
                    domConstruct.place(pdf, this.preview);
                    //メニューの作成
                    this.createMenu(pdf, dataUri, fileName, fileId, self);
                } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                    var word = new Image();
                    word.src = 'images/wordicon.png';
                    domClass.add(word, 'is-showPreview');
                    domConstruct.place(word, this.preview);
                    //メニューの作成
                    this.createMenu(word, dataUri, fileName, fileId, self);
                } else {
                    var other = new Image();
                    other.src = 'images/othericon.png';
                    domClass.add(other, 'is-showPreview');
                    domConstruct.place(other, this.preview);
                    //メニューの作成
                    this.createMenu(other, dataUri, fileName, fileId, self);
                }
            },

            /**
             * 添付ファイルのサムネイル上にメニューを作る
             */
            createMenu: function (newNode, uri, fileName, id, self) {
                var menu = new Menu({
                    targetNodeId: newNode
                });
                menu.set('style', { 'border': 'none', 'box-shadow': 'none' });

                //ダウンロード操作用
                var download = null;
                var userAgent = window.navigator.userAgent.toLowerCase();
                if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                    var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                    // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                    // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                    download = domConstruct.create('a', { href: '#' });
                    //クリックでファイル取得処理に入る
                    download.onclick = function () {
                        self.downloadFile(url, fileName);
                    };
                } else {
                    // FF, Chromeの場合、download属性でファイルダウンロード
                    download = domConstruct.create('a', {
                        href: uri,
                        download: fileName
                    });
                }

                // ファイル名とメニューとの境界線をセット
                var contentNode = domConstruct.create('div');
                contentNode.innerHTML = fileName;
                domConstruct.place('<hr color=#b0c4de>', contentNode);
                //メニューをセット
                domConstruct.place(menu.domNode, contentNode);
                var tooltip = new TooltipDialog({
                    content: contentNode
                });
                //
                tooltip.containerNode.onmouseleave = function () {
                    popup.close(tooltip);
                };

                // 画像ファイルの場合のみ'開く'をメニューに追加する
                if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                    fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                    fileName.indexOf('.gif') !== -1) {
                    menu.addChild(new MenuItem({
                        label: '開く',
                        iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
                        onClick: function () {
                            console.log('file open');
                            window.open(uri);
                        }
                    }));
                }

                menu.addChild(new MenuItem({
                    label: 'ダウンロード',
                    iconClass: 'dijitIconSave',
                    onClick: function (e) {
                        console.log('file download');
                        console.log(e);
                        //IE対策
                        if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                            download.onclick();
                        } else {
                            download.click();
                        }
                    }
                }));

                menu.addChild(new MenuItem({
                    label: '削除',
                    iconClass: 'dijitIconDelete',
                    onClick: function () {
                        console.log('file delete');

                        // 該当ファイルを削除
                        for (var i = 0; i < self.attachFileList.length; i++) {
                            if (self.attachFileList[i].facilityAtcFileId === id) {
                                self.attachFileList.splice(i, 1); //id:3の要素を削除
                            }
                        }

                        // サムネイルとメニューを削除
                        domConstruct.destroy(newNode);
                        popup.close(tooltip);
                    }
                }));

                menu.startup();
                //メニュー表示処理
                this.own(on(newNode, 'mouseover', lang.hitch(this, function () {
                    popup.open({
                        popup: tooltip,
                        around: newNode,
                        orient: ['below-centered']
                    });
                })));
                //画面破棄時に一緒に破棄する
                this.own(tooltip);
            },

            /**
             * 添付ファイルをダウンロードする。
             */
            downloadFile: function (url, name) {
                // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
                // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
                var xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.responseType = 'arraybuffer';
                xhr.onload = function () {

                    var arrayBuffer = this.response;

                    var blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });

                    // IE10+
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, name);
                    }

                };
                xhr.send();

                return false;

            },

            /**
             * プレビューをクリアする
             **/
            clearPreview: function () {
                array.forEach(this.preview.childNodes, function (childNode) {
                    domConstruct.destroy(childNode);
                });

                if (this._attachFileList) {
                    this._attachFileList.splice(0, this._attachFileList.length);
                }
            },

            /**
             * 利用可否のtips
             */
            setAvailableTips: function () {
                // 共有先
                var message = '利用可否を不可とした場合は、<br>避難所状況画面での新規開設ができなくなります。';
                var tooltip = new TooltipDialog({
                    id: 'availableTips',
                    style: 'width: 300px; height:100px',
                    content: '<p>' + message + '</p>'
                });
                var label = this.availableTip;
                this.own(tooltip);
                on(label, 'mouseover', lang.hitch(function () {
                    popup.open({
                        popup: tooltip,
                        around: label
                    });
                }));
                on(label, 'mouseleave', function () {
                    popup.close(tooltip);
                });
            }
        });
});
