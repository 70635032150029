/**
 * 津波情報画面用モジュール。
 * @module app/view/page/TsunamiPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/control/Router',
    'dojo/text!./templates/TsunamiPage.html',
    'idis/store/IdisRest',
    'idis/view/page/_PageBase',
    './TsunamiWarningDetailContent',
    './TsunamiInfoDetailContent',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'app/common/view/StatementDialog',
    './TsunamiGrid'
], function(module, declare, lang, Router, template, IdisRest, _PageBase, 
    TsunamiWarningDetailContent, TsunamiInfoDetailContent) {
    /**
     * 津波情報画面
     * @class TsunamiPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/view/page/TsunamiPage~TsunamiPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--tsunami',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        TSUNAMI_OBSERVATION: '津波情報',

        TSUNAMI_WARNING: '津波警報・注意報・予報',

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'tsunamiId',
                target: '/api/tsunamis'
            });
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            filter = filter.eq('infoKind', this.infoKind.value);            

            var reportDatetimeFrom = new Date(this.reportDatetimeFrom._getValueAttr()).getTime();
            filter = filter.eq('reportDatetimeFrom', reportDatetimeFrom);

            var reportDatetimeTo = new Date(this.reportDatetimeTo._getValueAttr()).getTime();
            filter = filter.eq('reportDatetimeTo', reportDatetimeTo);

            this.tsunamiGrid.set('collection', this.store.filter(filter));
            // グリッドの詳細ボタンクリック時の動作を設定する
            this.tsunamiGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                    this.showDetailDialog(evt.item);
            }));
        },

        // 検索時刻の初期値をセット
        initTime: function(){
            var endDate = new Date();
            var startDate = new Date();
            startDate.setDate(startDate.getDate()-7);
            this.reportDatetimeTo._setValueAttr(endDate);
            this.reportDatetimeFrom._setValueAttr(startDate);
        },        
        
        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.statementDialog);
            // 検索時刻を初期化する
            this.initTime();  
            // グリッドを初期化する
            this.initGrid();
        },


        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            filter = filter.eq('infoKind', value.infoKind);            

            if (value.reportDatetimeFrom) {
                var reportDatetimeFrom = new Date(value.reportDatetimeFrom).getTime();
                filter = filter.eq('reportDatetimeFrom', reportDatetimeFrom);
            }

            if (value.reportDatetimeTo) {
                var reportDatetimeTo = new Date(value.reportDatetimeTo).getTime();
                filter = filter.eq('reportDatetimeTo', reportDatetimeTo);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.tsunamiGrid.set('collection', collection);
        },


        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },

        showDetailDialog: function(item) {
            // 情報種別によって、表示する詳細ダイアログを変える
            switch (item.infokind) {
                // 津波情報
                case this.TSUNAMI_OBSERVATION:
                    // 情報文ダイアログを表示する
                    this.innerStatementDialog.setMessage(new TsunamiInfoDetailContent({item:item}).getContent());
                    break;

                // 津波警報・注意報
                case this.TSUNAMI_WARNING:
                    // 情報文ダイアログを表示する
                    this.innerStatementDialog.setMessage(new TsunamiWarningDetailContent({item:item}).getContent());
                    break;
            }
            // スクロールをダイアログのトップにする
            this.statementDialog.set('autofocus', false);
            this.statementDialog.show();
        },

        onEvacOrderRegisterButtonClick: function(evt) {
            console.log('[新規登録]ボタンがクリックされました。');
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 避難状況登録登録画面へ遷移
            Router.moveTo('evacorder/register');
        }
    });
});
