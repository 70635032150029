/**
 * 水位共通カラム一覧
 * @module app/river/grid/RiverLevelObservationCommonColumns
 */
define([
    'app/view/grid/CommonColumn',
    'dojo/dom-construct',
    'dojo/dom-style',
    'idis/view/grid/helper'
    // 以下、変数で受けないモジュール
], function (CommonColumn, domConstruct, domStyle, helper) {
    var timeModeLabel = {
        '10': '10分',
        '30': '30分',
        '60': '時間'
    };
    var DIFFERENCE_IMG_PATH = {
        UP: '/images/observation/obs_wlev_up.png',
        DOWN: '/images/observation/obs_wlev_down.png'
    };
    return {
        riverLevel: helper.column('riverLevel', '水位\n[m]', {
            formatter: function (value, item) {
                return CommonColumn.formatObservationData(item, 'riverLevel', 2);
            },
            className: function (item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) {
                    return;
                }
                return CommonColumn.getClassNameExcess(item, 'riverLevel');
            }
        }),
        difference: {
            sortable: false,
            field: 'difference',
            label: '差分\n[cm]',
            renderCell: function (item) {
                var domNode = domConstruct.create('div');
                if (item.difference === null || Number(item.difference) === 0) {
                    domNode.innerHTML = item.difference === null ? '' : item.difference;
                } else {
                    var image = new Image();
                    image.src = item.difference > 0 ? DIFFERENCE_IMG_PATH.UP : DIFFERENCE_IMG_PATH.DOWN;
                    domConstruct.place(image, domNode);
                    var differenceLabelNode = domConstruct.create('span');
                    domStyle.set(differenceLabelNode, 'margin-left', item.difference > 0 ? '1.3em' : '1em');
                    differenceLabelNode.innerHTML = item.difference;
                    domConstruct.place(differenceLabelNode, domNode);
                }
                return domNode;
            }
        },
        changeColumnsLabel: function (mode) {
            this.riverLevel.label = '現況水位\n[m]';
            // this.riverLevel.label = timeModeLabel[mode] + '水位\n[m]';
            this.difference.label = timeModeLabel[mode] + '差分\n[cm]';
        },
        riverLevelIn1hour: {
            field: 'riverLevelIn1hour',
            id: 'riverLevelIn1hour',
            label: '1時間後[m]',
            sortable: false,
            formatter: function (value, item) {
                if (value !== null) {
                    return value + '\n(' + item.forecastTimeIn1hour + ')';
                } else {
                    return '';
                }
            },
            className: function (item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) {
                    return;
                }
                if (
                    item.riverLevelIn1hour >= item.riverLevelDanger &&
                    item.riverLevelDanger !== null &&
                    item.riverLevelDanger !== 0
                ) {
                    return 'is-crisis';
                } else if (
                    item.riverLevelIn1hour >= item.riverLevelEvacuate &&
                    item.riverLevelEvacuate !== null &&
                    item.riverLevelEvacuate !== 0
                ) {
                    return 'is-judgment';
                } else if (
                    item.riverLevelIn1hour >= item.riverLevelCaution &&
                    item.riverLevelCaution !== null &&
                    item.riverLevelCaution !== 0
                ) {
                    return 'is-caution';
                } else if (
                    item.riverLevelIn1hour >= item.riverLevelStandby &&
                    item.riverLevelStandby !== null &&
                    item.riverLevelStandby !== 0
                ) {
                    return 'is-wait';
                } else {
                    return;
                }
            }
        },
        riverLevelIn2hour: {
            field: 'riverLevelIn2hour',
            id: 'riverLevelIn2hour',
            label: '2時間後[m]',
            sortable: false,
            formatter: function (value, item) {
                if (value !== null) {
                    return value + '\n(' + item.forecastTimeIn2hour + ')';
                } else {
                    return '';
                }
            },
            className : function (item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) {
                    return;
                }
                if (
                    item.riverLevelIn2hour >= item.riverLevelDanger &&
                    item.riverLevelDanger !== null &&
                    item.riverLevelDanger !== 0
                ) {
                    return 'is-crisis';
                } else if (
                    item.riverLevelIn2hour >= item.riverLevelEvacuate &&
                    item.riverLevelEvacuate !== null &&
                    item.riverLevelEvacuate !== 0
                ) {
                    return 'is-judgment';
                } else if (
                    item.riverLevelIn2hour >= item.riverLevelCaution &&
                    item.riverLevelCaution !== null &&
                    item.riverLevelCaution !== 0
                ) {
                    return 'is-caution';
                } else if (
                    item.riverLevelIn2hour >= item.riverLevelStandby &&
                    item.riverLevelStandby !== null &&
                    item.riverLevelStandby !== 0
                ) {
                    return 'is-wait';
                } else {
                    return;
                }
            }
        },
        riverLevelIn3hour: {
            field: 'riverLevelIn3hour',
            id: 'riverLevelIn3hour',
            label: '3時間後[m]',
            sortable: false,
            formatter: function (value, item) {
                if (value !== null) {
                    return value + '\n(' + item.forecastTimeIn3hour + ')';
                } else {
                    return '';
                }
            },
            className : function (item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) {
                    return;
                }
                if (
                    item.riverLevelIn3hour >= item.riverLevelDanger &&
                    item.riverLevelDanger !== null &&
                    item.riverLevelDanger !== 0
                ) {
                    return 'is-crisis';
                } else if (
                    item.riverLevelIn3hour >= item.riverLevelEvacuate &&
                    item.riverLevelEvacuate !== null &&
                    item.riverLevelEvacuate !== 0
                ) {
                    return 'is-judgment';
                } else if (
                    item.riverLevelIn3hour >= item.riverLevelCaution &&
                    item.riverLevelCaution !== null &&
                    item.riverLevelCaution !== 0
                ) {
                    return 'is-caution';
                } else if (
                    item.riverLevelIn3hour >= item.riverLevelStandby &&
                    item.riverLevelStandby !== null &&
                    item.riverLevelStandby !== 0
                ) {
                    return 'is-wait';
                } else {
                    return;
                }
            }
        }
    };
});
