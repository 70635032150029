/**
 * チャート描画共通部品処理
 * @module app/view/chart/ChartCommon
 */
define([
], function () {
    return {
        getLabelName: function (data) {
            return data.facilityName + "-" + data.itemName;
        },
        getChartData: function (data) {
            var resultList = [];
            for (var i = 0; i < 31; i++) {
                var d = {};
                var key = "day" + i;
                d['x'] = i;
                d['y'] = data[key];
                d['tooltip'] = data[key] ? data[key] : null;
                resultList.push(d);
            }
            console.log(resultList);
            return resultList;
        },
        getMaxVal: function (data) {

            var resultList = [];
            var prevData = 0;
            for (var i = 0; i < 31; i++) {
                var key = "day" + i;
                var val = data[key] ? data[key] : prevData;
                prevData = data[key] ? data[key] : prevData;
                resultList.push(val);
            }
            return resultList.reduce(function (a, b) { return Math.max(a, b); });
        }

    };
});
