/**
 * レイヤー情報を単に出力する詳細画面パーツ。
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/DamageReportDetail.html',
    'app/model/DisasterInfo',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/view/_IdisWidgetBase',
    'idis/view/dialog/DialogChain',
    'idis/store/IdisRest',
    // 以下、変数として受け取らないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    './DamageReportDetailGrid'
], function(module, array, declare, lang, template, DisasterInfo, Router, UserInfo, _IdisWidgetBase, DialogChain, IdisRest) {
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        constructor: function() {
            this.chain = DialogChain.get(this);
        },

        /**
         * DOMノードを生成する
         */
        buildRendering: function() {
            this.inherited(arguments);
            var layerId = this.item.id;
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'damageReportId',
                target: '/data/layer/data/' + layerId + '/damage4Map.json'
            });
            // グリッドを初期化する
            this.initGrid();
        },

        /**
         * グリッドを初期化する
         */
        initGrid: function() {
            // 全件表示用のcollectionをグリッドにセットする
            // （テンプレートHTML内のdata-dojo-attach-point="grid"でグリッドをthis.gridに紐付け済み）
            var collection = this.store.filter();
            this.grid.set('collection', collection);
            //IE対策
            //報告書リンクを押下した場合
            this.grid.on('reportInfoLinkClick', lang.hitch(this, function(evt) {
                var reportPath = evt.item.reportPath.replace('out', 'data');
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + reportPath;
                this.downloadFile(url, evt.item.reportName);
            }));
            //添付ファイルリンクを押下した場合
            this.grid.on('attachFileLinkClick', lang.hitch(this, function(evt) {
                var attachFilePath = evt.item.attachFilePath.replace('out', 'data');
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + attachFilePath;
                this.downloadFile(url, evt.item.attachFileName);
            }));
            // 被害情報登録ボタンを押下した場合
            this.grid.on('registerButtonClick', lang.hitch(this, function(evt) {
                if (Number(evt.item.disasterId) !== Number(DisasterInfo.getDisasterId())) {
                    this.chain.info('現在選択中の災害に紐づかない情報のため、登録ができません。<br>' +
                        'この情報を登録する場合、災害名を変更してください。', 'エラー');
                    return;
                }
                Router.moveTo('report/register', {
                    damageReportId: evt.item.damageReportId
                });
            }));

            // グリッドの項目表示/非表示
            this.setGridColumn(collection);
        },

        /**
         * グリッドの項目表示/非表示
         */
        setGridColumn: function(collection) {
            collection.fetch().then(lang.hitch(this, function(data){
                var damageTypeFlg = false;
                array.forEach(data, function(d){
                    if (d.damageType !== '13') {
                        damageTypeFlg = true;
                    }
                });
                if (damageTypeFlg) {
                    // 被害報告
                    this.grid.styleColumn('attachFiles', 'display: none;');
                    this.grid.styleColumn('0-2', 'display: table-cell;');
                    this.grid.styleColumn('0-3', 'display: table-cell;');
                    this.grid.styleColumn('0-6', 'display: table-cell;');
                    this.grid.styleColumn('0-7', 'display: table-cell;');
                    this.grid.styleColumn('0-9', 'display: none;');
                } else {
                    // 被害情報投稿
                    this.grid.styleColumn('attachFiles', 'display: table-cell;');
                    this.grid.styleColumn('0-2', 'display: none;');
                    this.grid.styleColumn('0-3', 'display: none;');
                    this.grid.styleColumn('0-6', 'display: none;');
                    this.grid.styleColumn('0-7', 'display: none;');
                    // 被害登録可能なユーザの場合のみ登録ボタン表示
                    if (UserInfo.hasWriteAuthz('F05007')) {
                        this.grid.styleColumn('0-9', 'display: table-cell;');
                    } else {
                        this.grid.styleColumn('0-9', 'display: none;');
                    }
                }
            }));
        },

        // resizeメソッドを定義すると外側のresize時に合わせて呼ばれる
        resize: function(changeSize, resultSize) {
            // リサイズ時は中のBorderContainerに追随させる
            // （ウィジェット自体とBorderContainerで大きさが同一なので、サイズはそのまま渡す）
            this.borderContainer.resize(changeSize, resultSize);
        },

        //IE対策 ダウンロード用ファイルを取得する
        downloadFile: function(url, name) {
            // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
            // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function() {

                var arrayBuffer = this.response;

                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, name);
                }

            };
            xhr.send();

            return false;
        }
    });
});
