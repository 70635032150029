define({
    '01': '住家被害',
    '02': 'ライフライン（電気等）',
    '14': '水道',
    '03': '文教施設（学校等）',
    '04': '病院',
    '05': '庁舎等施設',
    '06': '土砂災害',
    '07': '火災',
    '08': '河川',
    '15': '下水',
    '17': '公園',
    '09': '道路',
    '10': '農業被害',
    '18': '港湾',
    '11': 'その他',
    '19': '状況報告',
    '20': '避難所'
});
