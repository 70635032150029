/**
 * ユーザーに権限のある市町村選択用入力パーツ
 * @module app/sending/MunicipalitySelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/consts/USER_TYPE',
    'idis/model/UserInfo',
    'app/view/form/ManageRegionMunicipalitySelector'
], function(module, declare, USER_TYPE, UserInfo, MRMSelector) {
    /**
     * 市町村選択用入力パーツ
     * @class TestSelector
     * @extends module:idis/view/form/ManageRegionMunicipalitySelector~ManageRegionMunicipalitySelector
     */

    return declare(module.id.replace(/\//g, '.'), MRMSelector,
        /** @lends module:app/sending/MunicipalitySelector~idis/view/form/ManageRegionMunicipalitySelector# */ {
        constructor : function() {
            this.inherited(arguments);
        },

        startup: function() {
            // 所属自治体を初期値とする
            switch (UserInfo.getUserType()) {
                case USER_TYPE.REGION:
                    this.set('value', UserInfo.getMunicipalityCds()[0]);
                    break;
                case USER_TYPE.MUNICIPALITY:
                    this.set('value', UserInfo.getMunicipalityCd());
                    break;
            }
        }
    });
});
