/**
 * 気象情報画面用モジュール。
 * @module app/weatherinfo/view/WeatherInfoTimelinePage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'idis/control/Router',
    'dojo/text!./templates/WeatherInfoTimelinePage.html',
    'idis/service/Requester',
    'idis/view/page/_PageBase',
    'idis/store/IdisRest',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'app/view/form/KisyoAreaSelector',
    './WeatherInfoTimelineGrid'
], function (module, declare, lang, array, Router, template, Requester, _PageBase, IdisRest) {
    /**
     * 気象情報画面
     * @class WeatherInfoPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/weatherinfo/view/WeatherInfoTimelinePage~WeatherInfoTimelinePage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--weatherinfo',

            // ストア
            store: null,

            constructor: function () {
                //this.inherited(arguments);
                // データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'announceTime',
                    target: '/api/WeatherInformations/timeline'
                });
            },

            startup: function () {
                this.inherited(arguments);
                // 7日前の日付を取得する
                var date = new Date();
                date.setDate(date.getDate() - 7);
                this.reportDatetimeFrom.set('value', date);
                // グリッドを初期化する
                this.initGrid(this.form.value);
            },

            /**
            * グリッドを初期化する。
            */
            initGrid: function (value) {
                this.updateGrid(value);
            },

            /**
             * グリッドを指定された検索条件で更新する。
             */
            updateGrid: function (value) {
                // // データ格納オブジェクト
                var filter = new this.store.Filter();

                // 初期化時にset('value')した値をget('value')で取得する。value.reportDatetimeFromから取れないので注意。
                if (this.reportDatetimeFrom.get('value')) {
                    var reportDatetimeFrom = new Date(this.reportDatetimeFrom.get('value')).getTime();
                    filter = filter.eq('reportDatetimeFrom', reportDatetimeFrom);
                }

                if (value.reportDatetimeTo) {
                    var reportDatetimeTo = new Date(value.reportDatetimeTo).getTime();
                    filter = filter.eq('reportDatetimeTo', reportDatetimeTo);
                }

                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);
                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.weatherInfoTimelineGrid.set('collection', collection);
            },

            /**
            //  * 気象情報一覧画面に遷移する
            //  */
            onWeatherInfoButtonClick: function () {
                console.debug('[気象情報一覧表示に切り替え]ボタンがクリックされました。');
                // 避難状況登録登録画面へ遷移
                Router.moveTo('weatherinfo');
            },

            /**
            * グリッドの絞込
            */
            onSubmit: function () {
                console.debug('[検索]ボタンがクリックされました。');
                try {
                    if (this.form.isValid()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGrid(this.form.get('value'));
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            }
        });
});