define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, IdisGrid, helper) {
    /**
     * 情報配信画面の避難所の一覧情報を表示するためのグリッド
     *
     * @class 情報配信ダイアログ 開設避難所情報グリッド
     * @extends module:idis/view/grid/IdisGrid~IdisGrid
     */
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:idis/view/page/ProvideShelterGrid~ProvideShelterGrid# */ {
        columns: [
            helper.column('evacShelterName', '避難所名称', {sortable: false}),
//            helper.column('evacShelterNameKana', '避難所名称かな'),
            helper.column('evacShelterType', '避難所区分', {sortable: false}),
            helper.column('evacShelterSort', '区分', {sortable: false}),
            helper.column('evacShelterDatetime', '開設・閉鎖日時', {
                sortable: false,
                formatter: function(value){
                    var time = '';
                    // {YYYY}/{MM}/{DD}T{HH}:{MM}:{SS}.XXX+09 という形式で来るので、見やすい形にする
                    time += value.substr(0, 10);
                    time += '<br>';
                    time += value.substr(11, 5);
                    return time;
                }
            }),
            helper.column('address', '住所', {sortable: false}),
            helper.column('evacShelterLatitude', '緯度', {sortable: false}),
            helper.column('evacShelterLongitude', '経度', {sortable: false}),
            {label: '避難者情報', field: 'evaquee',
                children: [
                    helper.column('evaqueeNum', '総人数', {sortable: false}),
                    helper.column('evacHouseholdNum', '総世帯数', {sortable: false})
                ],
                sortable: false
            }
            // {label: '避難世帯', field: 'evacHousehold',
            //     children: [
            //         {field: 'evacHouseholdNum', label: '避難世帯総数', sortable: false},
            //         {field: 'evacHouseholdActNum', label: 'うち自主避難世帯数', sortable: false}
            //     ],
            //     sortable: false
            // }
        ]
    });
});
