/**
* 体制概況一覧用モジュール。
* @module app/disasterprevention/view/DisasterPreventionListGrid
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    './DPColumn'
    // 以下、変数として受け取らないモジュール
], function(module, declare, lang, IdisGrid, helper, DPColumn) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
//            DPColumn.regionNameSortable,
            helper.column('municipalityName', '所属'),
            //DPColumn.regionName,
            DPColumn.status,
            helper.column('contact', '連絡先'),
            DPColumn.deploymentMunicipality,
            DPColumn.lastReportTimestamp,
            helper.buttonColumn('detail', '一覧')
        ]
    });
});
