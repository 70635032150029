/**
 * 災害情報詳細画面用モジュール。
 * @module app/view/page/DisasterAdminDetailPage
 */
define([
    'module',
    'dojo/json',
    'dojo/dom-style',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/text!./templates/DisasterAdminDetailPage.html',
    'dojo/date/locale',
    'idis/consts/USER_TYPE',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    './DisasterDialogChain',
    // 以下、変数から参照されないモジュール
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/Textarea',
    'dijit/form/CheckBox',
    'dijit/form/DateTextBox',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup',
    'idis/view/form/DateTimeInput',
    'app/disaster/view/DisasterAdminIntegrate',
    'dijit/Dialog',
    'app/view/form/ParentDisasterSelector',
    'app/waterworks/selectors/IncidentSelector'
], function(module, json, domStyle, declare, lang, array, template, locale,
        USER_TYPE, UserInfo, Requester, InfoDialog, Loader, _PageBase, DisasterDialogChain) {
    /**
     * 災害情報詳細画面。
     * @class DisasterAdminDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/view/page/DisasterAdmintPage~DisasterAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        // 災害ID
        _disasterId: null,

        // 災害名一覧
        _grid: null,

        constructor: function() {
            // ダイアログ連鎖を登録
            this.chain = DisasterDialogChain.get(this);
        },

        // テンプレートHTML内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
        // 更新ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
        onSubmit: function() {
            try {
                var sendData = this.form.get('value');
                sendData.disasterId = this._disasterId;
                delete sendData.parentDisasterSelector;
                var parentDisaster = this.parentDisasterSelector.value;
                if(parentDisaster.extraDisasterId !== 0) {
                    sendData.extraDisasterId = this.parentDisasterSelector.value.extraDisasterId;
                    sendData.extraDisasterName = this.parentDisasterSelector.value.extraDisasterName;
                }

                // 水道局災害名を設定
                delete sendData.incidentSelector;
                var incidentValue = this.incidentSelector.value;
                sendData.incidentId = this.incidentSelector.value.incidentId;
                sendData.relatedFlg = this.incidentSelector.value.relatedFlg;
                
                if (this.form.validate()) {
                    // 水道局災害名を選択していた場合、確認ダイアログを追加表示する
                    if(sendData.relatedFlg === '1' && domStyle.get(this.incidentSelector.selectButton.domNode, 'display')!=='none' ){

                        // ダイアログにメッセージを表示
                        this.chain.confirmIncidentAdd(incidentValue.incidentId, incidentValue.incidentName, 
                                                        locale.format(new Date(incidentValue.updTimestamp)), sendData.disasterName, function() {
                            // 確認ダイアログを閉じる
                            this.chain.hide();
                            // 入力チェックが成功したらsendイベントを発行
                            // 実際の更新処理はサンプル画面側でsendイベントを監視しているメソッドが実施
                            this.emit('send', {
                                // テンプレートHTML内でformに対し
                                // data-dojo-attach-point="form"を指定してthis.formとしてアクセス出来るようにしている
                                value: sendData
                            });
                        });
                    }
                    else{
                        // 入力チェックが成功したらsendイベントを発行
                        // 実際の更新処理はサンプル画面側でsendイベントを監視しているメソッドが実施
                        this.emit('send', {
                            // テンプレートHTML内でformに対し
                            // data-dojo-attach-point="form"を指定してthis.formとしてアクセス出来るようにしている
                            value: sendData
                        });
                    }
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        // テンプレートHTML内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
        // 更新ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
        onDelete: function() {
            var sendData = this.form.get('value');
            sendData.disasterId = this._disasterId;
            this.emit('senddelete', {
                // テンプレートHTML内でformに対し
                // data-dojo-attach-point="form"を指定してthis.formとしてアクセス出来るようにしている
                // value: this.form.get('value')
                value: sendData
                //id: this.form.get('value').disasterId
            });
        },

        /**
         * 住所情報固定ボタンクリック時の動作。
         */
        onFixButtonClick: function() {
            this.chain.confirm([
                'この災害に紐付く住所情報を現在の内容で固定します。',
                'この操作によりシステム上の住所情報変更後も',
                'この災害に紐づく避難情報等を正しく表示することが出来ますが、',
                'この災害に対し新たに避難情報等を登録することができなくなります。',
                '実行してよろしいですか？'
            ].join('<br>'), lang.hitch(this, function() {
                // JSON生成処理のリクエスト
                var api = '/api/evacorders/generate-detail-json/' + this._disasterId;
                Loader.wait(Requester.post(api)).then(lang.hitch(this, function() {
                    this.chain.infoComplete();
                }), lang.hitch(this, function(err) {
                    if(err.response.data && typeof(err.response.data) === 'string'){
                        err.response.data = json.parse(err.response.data);
                    }
                    this.chain.infoError(err);
                }));
            }));
        },

        initDialog: function(item){
            // 市町村ユーザーの場合、県が登録した災害は、更新・削除ボタンを非表示
            var display = UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
                item.municipalityCd !== UserInfo.getMunicipalityCd() ?
                'none' : '';
            domStyle.set(this.buttonArea, 'display', display);
            // formにセット
            this.form.set('value', item);
            this.parentDisasterSelector.getDisasterInfo(item.disasterId);
            if(item.extraDisasterId !== 0) {
                domStyle.set(this.parentDisasterSelector.selectButton.domNode, 'display', 'none');
                domStyle.set(this.parentDisasterSelector.clearButton.domNode, 'display', 'none');
            } else {
                domStyle.set(this.parentDisasterSelector.selectButton.domNode, 'display', '');
                domStyle.set(this.parentDisasterSelector.clearButton.domNode, 'display', '');
            }
            // 水道局災害名が紐付け済みの場合、選択ボタン・解除ボタンを非表示
            this.incidentSelector.getDisasterInfo(item.incidentId, item.relatedFlg);
            if(item.relatedFlg !== '0') {
                domStyle.set(this.incidentSelector.selectButton.domNode, 'display', 'none');
                domStyle.set(this.incidentSelector.clearButton.domNode, 'display', 'none');
            } else {
                domStyle.set(this.incidentSelector.selectButton.domNode, 'display', '');
                domStyle.set(this.incidentSelector.clearButton.domNode, 'display', '');
            }

            // 閲覧モードの場合、登録抑止ボタン・新規登録ボタンを非表示
            if(item.viewMode === '1'){
                domStyle.set(this.submitButton.domNode, 'display', 'none');
                domStyle.set(this.deleteButton.domNode, 'display', 'none');
            }
        }
    });
});
