/**
 * 参集メール一覧グリッド。
 * @module app/convocation/view/mail/ConvoMailListGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'dojo/date/locale',
    'idis/view/form/Button',
    'dojo/on',
    'dojo/dom-construct'
], function(module, declare, lang, IdisGrid, helper, locale, Button, on, domCon) {
	
	    /**
     * 日時を表示形式に変換する
     * 'yyyy-MM-dd HH:mm:dd'を'年月日時分'に変換する
     * FIXME 外部化を検討。helperで対応?
     *
     * @param {string} 'yyyy-MM-dd HH:mm:dd'
     * @return {string} 'yyyy-MM-dd HH:mm'
     */
    var formatDateTime = function(value) {
        var dateLabel = locale.format(new Date(value.replace(/-/g, '/')), {
            selector: 'date',
              datePattern: 'yyyy-MM-dd'
        });
        var timeLabel = locale.format(new Date(value.replace(/-/g, '/')), {
            selector: 'time',
              timePattern: 'HH:mm'
        });
        return dateLabel + ' ' + timeLabel;
    };

    return declare(module.id.replace(/\//g, '.'), IdisGrid,{
        /**
         * 各列の定義
         * @type {Object[]}
         */

        collection: this.store,
        columns: [
            //helper.buttonColumn('detail', '詳細'),
            {field: 'detail', label:'詳細', sortable: false,
            renderCell: function(item){
                var gridNode = this.grid.domNode;
                var button = new Button({
                    label: '詳細',
                    onClick: function() {
                        on.emit(gridNode, 'detailButtonClick', {item: item});
                    }
                });
                var content = null;

                // 参集メールの場合、
                if(item.distSettingId !== null) {
                    content = button.domNode;
                } else {
                    var comment = domCon.create('div');
                    comment.textContent = '-';
                    content = comment;
                }
                return content;
            }},
            //helper.column('distributeTimeStamp', '配信日時'),
            helper.column('distributeTimeStamp', '配信日時', {
                formatter: function(date){
                    return formatDateTime(date);
                }
            }),
            // {field: 'mailtype', label:'メール種別', sortable: false,
            // renderCell: function(item){
            //     var comment = domCon.create('div');
            //     var content = null;

            //     // 参集メールの場合、
            //     if(item.distSettingId !== null) {
            //         comment.textContent = '参集メール';
            //         content = comment;
            //     } else {
            //         comment.textContent = '一般メール';
            //         content = comment;
            //     }
            //     return content;
            // }},
            helper.column('settingName', '配信テンプレート名'),
            helper.column('distributionTitle', '件名'),
            helper.column('distributionBody', '本文'),
            {field: 'groupName', label: '送信先（グループ）', sortable: false,
                formatter: lang.hitch(this, function(item) {
                  return item;
                })
            }
        ]
    });
});
