/**
 * チャートに使うパラメーターを定数として定義
 * @module app/view/ChartConfig
 */
define({
    MARKER_SHAPE: 'm-3,0 c0,-4 6,-4 6,0 m-6,0 c0,4 6,4 6,0',

    MINOR_LINE_COLOR: 'silver',
    MAJOR_LINE_COLOR: 'gray',

    STANDBY_COLOR: '#FF7B00',
    ADVISORY_COLOR: '#CC2800',
    WARNING_COLOR: '#C809FF',
    SPECIAL_WARNING_COLOR: '#6C008C',
    DISASTER_COLOR: '#000',

    LINE_COLOR: '#3F9998'

});
