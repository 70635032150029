/**
 * クロノロジ対応履歴登録ダイアログ
 * @module app/chronology/ChronologyActionRegisterDialog
 */
define([
    'module',
    'dojo/json',
    'dojo/on',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-class', //
    'dojo/dom-construct', //
    'dojo/dom-style',
    'dojo/text!./templates/ChronologyActionRegisterDialog.html',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    'idis/view/Loader', //
    'idis/service/Requester', //
    'idis/util/FilesUtils',
    'exif-js/exif', //
    'idis/service/iframe', //
    './consts/ChronologyType',
    // 以下、変数から参照されないモジュール
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/Textarea',
    'dijit/form/CheckBox',
    'dijit/form/DateTextBox',
    'dijit/form/Select',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup',
    'idis/view/form/DateTimeInput'
], function(module, json, on, declare, lang, array, domClass, domConstruct, domStyle, template,
    Menu, MenuItem, popup, TooltipDialog, InfoDialog, DialogChain, _PageBase, Loader,
    Requester, FilesUtils, exif, iframe, ChronologyType) {
    /**
     * 災害情報登録画面。
     * @class DisasterAdminRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/view/page/DisasterAdmintPage~DisasterAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        _chronologyId: null,

        attachFileList: [],

        _chronologyType: null,

        /**
         * 変数初期化メソッド
         */
        constructor: function() {
            // ダイアログ連鎖を登録
            // 引数に与えたウィジェットのthis.ownを呼び出し、
            // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
            this.chain = DialogChain.get(this);
        },

        initDialog: function(item){
            this._chronologyId = item.chronologyId;
            this.hldStatus.set('value', item.hldStatus);
            var shownPreviews = document.getElementById('preview');
            if(shownPreviews){
                while(shownPreviews.lastChild){
                    shownPreviews.removeChild(shownPreviews.lastChild);
                }
            }
            // 情報区分が大阪市連携の場合、対応履歴の情報種別セレクタを表示
            this._chronologyType = item.chronologyType;
            if(this._chronologyType === ChronologyType.PREFECTURE_SHARE.id){
                domStyle.set(this.chronologyActionTypeArea, 'display', '');
            }else{
                domStyle.set(this.chronologyActionTypeArea, 'display', 'none');
            }
            this.clearPreview();
        },

        // テンプレートHTML内内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
        // 登録ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
        onSubmit: function() {
            try {
                if (this.form.validate()) {
                    // 入力チェックが成功したらsendイベントを発行
                    // 実際の登録処理はサンプル画面側でsendイベントを監視しているメソッドが実施

                    var sendData = {
                        chronologyId : this._chronologyId,
                        hldStatus : this.hldStatus.get('value'),
                        content : this.content.get('value'),
                        attachFileIds: '',
                        chronologyActionType: this.chronologyActionType.get('value')
                    };

                    // 添付ファイルIDをセット
                    if(this.attachFileList.length > 0) {
                        var attachFileIds = [];
                        for(var i=0; i<this.attachFileList.length; i++) {
                            attachFileIds.push(this.attachFileList[i].chronologyAtcFileId);
                        }
                        sendData.attachFileIds = attachFileIds.join(',');
                    } else {
                        sendData.attachFileIds = '';
                    }
                    
                    // 情報区分が大阪市連携でない場合は、情報種別を削除
                    if(this._chronologyType !== ChronologyType.PREFECTURE_SHARE.id){
                        delete sendData.chronologyActionType;
                    }

                    this.emit('send', {
                        value: sendData
                    });
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 添付ファイルをアップロードする。
         */
        loadAttachFile: function() {
            // ファイルが空の場合は処理を中断＆不正なファイルの場合、メッセージ表示して処理を中断
            if (this.attachFile._files.length === 0 ) {
                return;
            }

            if (this.attachFile._files[0].size === 0 ) {
                InfoDialog.show('入力チェック', 'ファイルの中身が空です。');
                this.attachFile.reset();
                return;
            }

            console.log('file change');
            var self = this;

            this.attachFile.set('disabled', false);
            
            // ファイルがonloadされたときにサーバーに一時保存する
            var promise = iframe.post('/api/chronologies/uploadFile', {
                form: this.form.id,
                handleAs: 'json'
            }).then(function(data) {
                //uploaderをリセット
                self.attachFile.reset();
                self.attachFileList.push(data);
                self.showPreview(data, true);

            }, function(error) {
                console.log(error);
                //uploaderをリセット
                self.attachFile.reset();
                self.chain.infoError(error);
            });

            //ローダーの表示
            Loader.wait(promise);

        },

        /**
         * 添付ファイルのプレビューを表示する。
         */
        showPreview: function(data, exifFlg) {
            var dataUri = data.attachFilePath.replace('out/', 'data/');
            var fileName = data.attachFileName;
            var fileId = data.chronologyAtcFileId;
            var self = this;

            // 画像ファイルの場合
            if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
            fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
            fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
            fileName.indexOf('.gif') !== -1 || fileName.indexOf('.bmp') !== -1){
                var image = new Image();

                //JPEGファイルの場合、EXIFデータの取得を実行する
                if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
                fileName.indexOf('.JPG') !== -1 ||fileName.indexOf('.JPEG') !== -1) {
                    var img = null;
                    this.own(on(image, 'load', lang.hitch(this, function(e) {
                        img = e.target;

                        if(exifFlg) {
                            this.getExifData(img, this);
                        }
                    })));
                }
                image.src = dataUri;
                domClass.add(image, 'is-showPreview');
                domConstruct.place(image, this.preview);
                //メニューの作成
                this.createMenu(image, dataUri, fileName, fileId, self, false);

            } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                var excel = new Image();
                excel.src = 'images/excelicon.png';
                domClass.add(excel, 'is-showPreview');
                domConstruct.place(excel, this.preview);
                //メニューの作成
                this.createMenu(excel, dataUri, fileName, fileId, self, false);
            } else if (fileName.indexOf('.pdf') !== -1) {
                var pdf = new Image();
                pdf.src = 'images/pdficon.png';
                domClass.add(pdf, 'is-showPreview');
                domConstruct.place(pdf, this.preview);
                //メニューの作成
                this.createMenu(pdf, dataUri, fileName, fileId, self, false);
            } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                var word = new Image();
                word.src = 'images/wordicon.png';
                domClass.add(word, 'is-showPreview');
                domConstruct.place(word, this.preview);
                //メニューの作成
                this.createMenu(word, dataUri, fileName, fileId, self, false);
            } else if (fileName.indexOf('.ppt') !== -1 || fileName.indexOf('.pptx') !== -1) {
                var ppt = new Image();
                ppt.src = 'images/ppticon.png';
                domClass.add(ppt, 'is-showPreview');
                domConstruct.place(ppt, this.preview);
                //メニューの作成
                this.createMenu(ppt, dataUri, fileName, fileId, self, false);
            } else if (fileName.indexOf('.zip') !== -1) {
                var zip = new Image();
                zip.src = 'images/zipicon.png';
                domClass.add(zip, 'is-showPreview');
                domConstruct.place(zip, this.preview);
                //メニューの作成
                this.createMenu(zip, dataUri, fileName, fileId, self, false);
            } else {
                var other = new Image();
                other.src = 'images/othericon.png';
                domClass.add(other, 'is-showPreview');
                domConstruct.place(other, this.preview);
                //メニューの作成
                this.createMenu(other, dataUri, fileName, fileId, self, false);
            }
        },

        /**
         * JPEGファイルの位置情報を取得する
         */
        getExifData: function(img, self) {
            console.log('getting exif data start');
            exif.getData(img, function(){

                var latitude = exif.getTag(this, 'GPSLatitude');
                var longitude = exif.getTag(this, 'GPSLongitude');

                if(typeof latitude === 'undefined' || typeof longitude === 'undefined'){
                    console.log('GPS data is unavailable.');
                }else{
                    console.log('GPS data is available.');
                    var f = function(number){
                        return number[0].numerator + number[1].numerator /
                        (60 * number[1].denominator) + number[2].numerator / (3600 * number[2].denominator);
                    };
                    self.chain.confirm('この画像の位置情報を使用しますか？', function(chain) {
                        // 位置情報を設定する
                        self.pointLat = f(latitude);
                        self.pointLng = f(longitude);
                        // 地図にマークして中心に移動する
                        self.addMark(self.pointLat, self.pointLng, self);
                        self.map.setView([self.pointLat, self.pointLng], 14);
                        //ダイアログを閉じる
                        chain.hide();
                    });
                }
            });
        },

        /**
         * 添付ファイルのサムネイル上にメニューを作る
         */
        createMenu: function(newNode, uri, fileName, id, self, pic, isAdminPage, isAction) {
            var menu = new Menu({
                targetNodeId: newNode
            });
            menu.set('style', {'border': 'none', 'box-shadow': 'none'});

            //ダウンロード操作用
            var download = null;
            var userAgent = window.navigator.userAgent.toLowerCase();
            if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                download = domConstruct.create('a', {href: '#'});
                //クリックでファイル取得処理に入る
                download.onclick = function() {
                    self.downloadFile(url, fileName);
                };
            }else{
                // FF, Chromeの場合、download属性でファイルダウンロード
                download = domConstruct.create('a', {
                    href: uri,
                    download: fileName
                });
            }

            // ファイル名とメニューとの境界線をセット
            var contentNode = domConstruct.create('div');
            contentNode.innerHTML = fileName;
            domConstruct.place('<hr color=#b0c4de>', contentNode);
            if (pic){
                var previewUri = self.getPreviewUri(uri);
                domConstruct.place('<img src="'+ previewUri + '" width="110">', contentNode);
            }

            //メニューをセット
            domConstruct.place(menu.domNode, contentNode);
            var tooltip = new TooltipDialog({
                content: contentNode
            });
            //
            tooltip.containerNode.onmouseleave = function() {
                popup.close(tooltip);
            };

            // 画像ファイルの場合のみ'開く'をメニューに追加する
            if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
            fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
            fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
            fileName.indexOf('.gif') !== -1 || fileName.indexOf('.bmp') !== -1){
                menu.addChild(new MenuItem({
                    label: '開く',
                    iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
                    onClick: function() {
                        console.log('file open');
                        window.open(uri);
                    }
                }));
            }

            menu.addChild(new MenuItem({
                label: 'ダウンロード',
                iconClass: 'dijitIconSave',
                onClick: function(e) {
                    console.log('file download');
                    console.log(e);
                    //IE対策
                    if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                        download.onclick();
                    } else {
                        download.click();
                    }
                }
            }));

            menu.addChild(new MenuItem({
                label: '削除',
                iconClass: 'dijitIconDelete',
                onClick: function() {
                    if(isAdminPage){
                        if(isAction){
                            // 概況画面から対応履歴の添付を削除
                            for(var i=0; i<self.actionAttachFileList.length; i++) {
                                if(self.actionAttachFileList[i].chronologyAtcFileId === id) {
                                    self.actionAttachFileList.splice(i,1); //id:3の要素を削除
                                }
                            }
                        }else{
                            // 概況画面からクロノロジの添付を削除
                            for(var j=0; j<self.attachFileList.length; j++) {
                                if(self.attachFileList[j].chronologyAtcFileId === id) {
                                    self.attachFileList.splice(j,1); //id:3の要素を削除
                                }
                            }
                        }
                        // サムネイルとメニューを削除
                        domConstruct.destroy(newNode);
                        popup.close(tooltip);
                    }else{
                        self.chain.confirmDel(function(chain) {
                            var promise = Requester.del('/api/chronologies/uploadFile/' + id)
                            .then(function() {
                                chain.infoComplete(function() {
                                    this.chain.hide();
                                    // 該当ファイルを削除
                                    for(var i=0; i<self.attachFileList.length; i++) {
                                        if(self.attachFileList[i].chronologyAtcFileId === id) {
                                            self.attachFileList.splice(i,1); //id:3の要素を削除
                                        }
                                    }
                                    // サムネイルとメニューを削除
                                    domConstruct.destroy(newNode);
                                    popup.close(tooltip);
                                });
                            }, function(error) {
                                console.log(error);
                                chain.infoError(error);
                            });

                            //ローダーの表示
                            Loader.wait(promise);

                        });
                    }
                }
            }));

            menu.startup();
            //メニュー表示処理
            this.own(on(newNode, 'mouseover', lang.hitch(this, function() {
                popup.open({
                    popup: tooltip,
                    around: newNode,
                    orient: ['above-centered']
                });
            })));
            //画面破棄時に一緒に破棄する
            this.own(tooltip);
        },

        /**
         * 添付ファイルをダウンロードする。
         */
        downloadFile: function(url, name) {
            // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
            // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function() {

                var arrayBuffer = this.response;

                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, name);
                }

            };
            xhr.send();

            return false;

        },

        /**
         * プレビューをクリアする
         **/
        clearPreview: function() {

            var length = this.preview.childNodes.length;
            for(var i = 0; i < length; i++) {
                domConstruct.destroy(this.preview.childNodes[0]);
            }

            if(this._attachFileList) {
                this._attachFileList.splice(0, this._attachFileList.length);
            }
        },

        /**
         * プレビュー用の画像ファイルのURIを返す
         **/
        getPreviewUri : function(uri) {
            var previewUri = uri;
            // 画像ファイルの場合
            if(uri.indexOf('.jpg') !== -1 ||uri.indexOf('.jpeg') !== -1 ||
            uri.indexOf('.png') !== -1 || uri.indexOf('.JPG') !== -1 ||
            uri.indexOf('.JPEG') !== -1 || uri.indexOf('.PNG') !== -1 ||
            uri.indexOf('.gif') !== -1 || uri.indexOf('.bmp') !== -1){
                previewUri = uri;
            } else if (uri.indexOf('.xls') !== -1 || uri.indexOf('.xlsx') !== -1) {
                previewUri = 'images/excelicon.png';
            } else if (uri.indexOf('.pdf') !== -1) {
                previewUri = 'images/pdficon.png';
            } else if (uri.indexOf('.doc') !== -1 || uri.indexOf('.docx') !== -1) {
                previewUri = 'images/docicon.png';
            } else if (uri.indexOf('.ppt') !== -1 || uri.indexOf('.pptx') !== -1) {
                previewUri = 'images/ppticon.png';
            } else if (uri.indexOf('.zip') !== -1) {
                previewUri = 'images/zipicon.png';
            } else {
                previewUri = 'images/othericon.png';
            }
            return previewUri;
        }


    });
});
