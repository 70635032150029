/**
 * <避難所情報一覧画面>
 *
 * @module app/shelter/ShelterOpeningGrid.js
 */
define(['module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'idis/util/DateUtils',
    'dojo/date/locale',
    'dojo/dom',
    'dijit/registry',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer'
], function(module, declare, lang, DateUtils, locale, dom, registry, IdisGrid, helper) {
    /**
     * <クラスの説明>
     *
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'),IdisGrid,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */
        {
        columns: [
            helper.buttonColumn('detail', '詳細'),
            helper.column('confirmFlg', '本部確認状況', {
                className: lang.hitch(this, function (item) {
                    // ヘッダーの場合はclassNameを返さない
                    if (!item) {
                        return;
                    }
                    if (item.confirmFlg === '0' && item.activeFlg === '0') {
                        // 本部未確認かつ過去報
                        return 'column-color-gray';
                    }
                    if (item.confirmFlg === '0') {
                        // 本部未確認
                        return 'column-color-red';
                    }
                }),
                formatter: function(value) {
                    if (!value) {
                        return '-';
                    } else if (value === '0') {
                        return '本部<br>未確認';
                    } else {
                        return '完了';
                    }
                }
            }),
            {field: 'districtName', label: '地区', sortable: false},
            {field: 'facilityName', label: '避難所名', sortable: false},
            helper.column('seqNum', '報告連番', {formatter: function(value) { return '第' + value + '報'; }}),
            helper.column('status', 'ステータス', {
                formatter: function(value) {
                    // 1: 開設, 9: 閉鎖
                    if(value === '1'){
                        return '開設済';
                    } else if (value === '9'){
                        return '閉鎖';
                    } else {
                        return '-';
                    }
                }
            }),
            {field: 'shelterStartTime', label: '開設日時', sortable: true,
               	formatter: lang.hitch(this, function(value, item){
               		if (!item) {
               			return '-';
               		}else{
                        return value ? value.replace(/-/g, '/') : '-';
               		}
               	})
            },
            {field: 'shelterEndTime', label: '閉鎖日時', sortable: true,
               	formatter: lang.hitch(this, function(item){
               		if (!item) {
               			return '-';
               		}else{
               			return item.replace(/-/g, '/');
               		}
               	})
            },
            {field: 'evaqueeNum', label: '避難者数', sortable: true,
                formatter: lang.hitch(this,function(item) {
                    if (!item) {
                        return '-';
                    }
                    return item;
                })
            },
            {field: 'capacity', label: '最大収容人数', sortable: false,
                formatter: lang.hitch(this,function(item) {
                    if (item === null || !item) {
                        return '-';
                    }
                    return item;
                })
            },
            helper.column('spaceStatus', '混雑状況', {
                className: lang.hitch(this, function (item) {
                    // ヘッダーの場合はclassNameを返さない
                    if (!item) {
                        return;
                    }
                    if (item.spaceStatus === '1') {
                        // 混雑
                        return 'column-color-yellow';
                    }
                    if (item.spaceStatus === '2') {
                        // 満員
                        return 'column-color-red';
                    }
                }),
                formatter: lang.hitch(this, function(value) {
                    if (!value) {
                        return '-';
                    } else if (value === '0') {
                        return '空き';
                    } else if (value === '1') {
                        return '混雑';
                    } else {
                        return '満員';
                    }
                })
            }),
            {field: '_item', label: '避難所区分', sortable: true,
                formatter:lang.hitch(this,function(item) {
                    var shelterCategory = '';
                    if (item.designatedEvacShFlg === '1'){
                        shelterCategory += '避難所 ';
                    }
                    if (item.welfareEvacShFlg === '1'){
                        shelterCategory += '福祉 ';
                    }
                    if (item.temporaryEvacShFlg === '1'){
                        shelterCategory += '臨時';
                    }
                    if (shelterCategory === '') {
                        shelterCategory = '-';
                    }
                    return shelterCategory;
                })
            },
            {field: 'updTimestamp', label: '最終更新日時', sortable: true,
                formatter: lang.hitch(this, function(item){
                	return item ? item.replace(/-/g, '/') : '-';
                })
            }
        ]
    });
});
