define([
    'module',
    'dojo',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
], function (module, dojo, declare, lang, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--simulation',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.column('scenarioId', 'シナリオID', { sortable: false }),
            helper.column('scenarioName', 'シナリオ名', { sortable: false }),
            helper.column('comment', '備考', { sortable: false }),
            helper.column('crtTimestamp', '作成日時', { sortable: false }),
            helper.column('updTimestamp', '最終更新日時', { sortable: false }),
            helper.buttonColumn('list', '一覧')
        ]
    });
});
