/**
 * <モジュールの説明>
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/Deferred',
    'dojo/json',
    'dojo/text!./templates/EvacOrderFormDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/model/DisasterInfo',
    'idis/model/UserInfo',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'app/config',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'app/view/form/MunicipalitySelector',
    'app/view/form/DistrictSelector',
    'dijit/form/Form'
], function(module, declare, lang, domStyle, Deferred, JSON, template, _PageBase, DisasterInfo,
    UserInfo, InfoDialog, DialogChain, Loader, config) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
        // テンプレート文字列
        templateString: template,
        /**
         * 災害ID
         */
        _disasterId: null,

        constructor: function(){
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
            // 災害IDを取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定させていません。');
            }
        },

        startup:function(){
            this.onSelectFlgChange();
            //大阪市に初期セット
            this.municipalityCd.set('value', config.municInfo.cityMunicCd);
        },

        // 出力帳票の種類を変更したときに「市町村」の入力可否を変更する
        // selectFlg 0:避難状況概況集計, 1:避難状況一覧
        onSelectFlgChange: function() {
            console.debug('市町村の入力可否を変更します。');

            if(this.form.get('value').selectFlg === '1' ){
                this.municipalityCdArea.style.display='none';
                this.districtCdArea.style.display='none';
                this.activeFlgArea.style.display='none';
                domStyle.set(this.dateArea, 'display', '');

            } else if (this.form.get('value').selectFlg === '0'){

                this.municipalityCdArea.style.display='none';
                this.districtCdArea.style.display='none';
                this.activeFlgArea.style.display='none';
                domStyle.set(this.dateArea, 'display', '');

            } else if (this.form.get('value').selectFlg === '2'){
                this.municipalityCdArea.style.display='none';
                this.districtCdArea.style.display='none';
                this.activeFlgArea.style.display='none';
                domStyle.set(this.dateArea, 'display', 'none');
                
            } else if(this.form.get('value').selectFlg === '3'){
                this.municipalityCdArea.style.display='';
                this.districtCdArea.style.display='none';
                this.activeFlgArea.style.display='none';
                domStyle.set(this.dateArea, 'display', 'none');
                
            }

        },

        // 帳票出力を行う前に入力チェックを行う
        onOutputButtonClick : function(fileType) {
            // 選択された帳票種別によって分岐
            var selectFlg = this.form.get('value').selectFlg;
            var repdate = this.repdatetime._date.displayedValue;
            var reptime = this.repdatetime._time.displayedValue;
            var activeFlg = this.form.get('value').activeFlg;
            var municipalityCd = this.form.get('value').municipalityCd;
            if(( selectFlg === '0' ||  selectFlg === '1') &&  (!this.repdatetime.validate() || !repdate || !reptime)) {
                console.debug('日時が選択されていません');
                if (!this.infoDialog) {

                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '日時が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
            } else if (selectFlg !== '0' && selectFlg !== '1' && selectFlg !== '2' && selectFlg !== '3' ){
                console.debug('出力帳票が選択されていません');
                if (!this.infoDialog) {

                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '出力帳票が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
            } else if ((selectFlg === '3') && !municipalityCd){
                console.debug('市/行政区が選択されていません');
                if (!this.infoDialog) {
            
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        // content : '市町村が選択されていません'
                        content : '市/行政区が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
            } else {
                this.downloadExcel(selectFlg, repdate, reptime, activeFlg, municipalityCd, fileType);
            }
        },
        
        // excelを出力
        onOutputExcelButtonClick : function() {
            // 選択された帳票種別によって分岐
            this.onOutputButtonClick('excel');
        },
        
        // pdfを出力
        onOutputPdfButtonClick : function() {
            // 選択された帳票種別によって分岐
            this.onOutputButtonClick('pdf');
        },

        //帳票出力
        downloadExcel : function(selectFlg, repdate, reptime, activeFlg, municipalityCd, fileType) {
            //repdateをYYYYMMDD形式に変換
            repdate = repdate.replace(/\//g,'');
            //reptimeをhhss形式に変換
            reptime = reptime.replace(':', '');

            var reqid = null;
            var fileName = null;
            var paramList = [];
            var extension = {
                'excel' : 'xlsx',
                'pdf' : 'pdf'
            };

            if(selectFlg === '0'){
                reqid = 'EVAC_ORDER_PREF_NO4STYLE_EX';
                fileName ='EvacOrderPref.' + extension[fileType];
                paramList = [
                    // {key: 'municipalityCd', value: municipalityCd},
                    {key: 'disid', value: this._disasterId},
                    {key: 'username', value: UserInfo.getName()},
                    {key: 'repdate', value: repdate},
                    {key: 'reptime', value: reptime}
                ];

            } else if(selectFlg === '1'){
                //概況選択時
                reqid = 'EVAC_ORDER_EX';
                fileName ='EvacOrderOverview.' + extension[fileType];
                paramList = [
                    {key: 'disid', value: this._disasterId},
                    {key: 'repdate', value: repdate},
                    {key: 'reptime', value: reptime}
                ];
            } else if(selectFlg === '2'){
                // 一覧（発令）選択時
                reqid = 'EVAC_ORDER_MUNIC_EX';
                fileName ='EvacOrderMunic.'  + extension[fileType];
                paramList = [
                    {key: 'disid', value: this._disasterId}
                    // {key: 'municipalityCd', value: municipalityCd}
                ];
            } else if(selectFlg === '3'){
                //一覧（地区）選択時
                reqid = 'EVAC_ORDER_DIST_EX';
                fileName ='EvacOrderDist.' + extension[fileType];
                paramList = [
                    {key: 'disid', value: this._disasterId},
                    {key: 'municipalityCd', value: municipalityCd}
                ];
            }

            var data4xoblosDownload = {
                fileName: fileName,
                reqid: reqid,
                paramList: paramList,
                fileType: fileType
            };


            var promise = this.download(data4xoblosDownload, fileName).then(lang.hitch(this, function() {
                this.infoDialog = new InfoDialog({
                    title : 'ダウンロード完了',
                    content : 'ダウンロードが完了しました。'
                });
                this.infoDialog.show();

            }), lang.hitch(this, function(error) {
                console.error(error);
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : 'エラーが発生しました。管理者にお問い合わせください。'
                });
                this.infoDialog.show();
            }));

            Loader.wait(promise);


            // // 避難状況概況帳票出力
            // if(selectFlg === '0'){
            //     reqid = 'EVAC_ORDER_EX';
            //     activeFlg = '';
            //     municipalityCd = '';
            //     districtCd = '';
            //     suffix = '-避難情報概況';
            // // 避難状況一覧帳票出力
            // }else if(selectFlg === '1'){
            //     reqid = 'EVAC_ORDER_MUNIC_EX';
            //     activeFlg = '';
            //     municipalityCd = '&municipalityCd=' + municipalityCd;
            //     districtCd = '';
            //     suffix = '-避難情報一覧(発令)';
            // }else if(selectFlg === '2'){
            //     reqid = 'EVAC_ORDER_DIST_EX';
            //     activeFlg = '';
            //     municipalityCd = '';
            //     districtCd = '&districtCd=' + districtCd;
            //     suffix = '-避難情報一覧(地区)';
            // }
            // var date = this.repdatetime.get('value');
            // fileName =
            //     date.getFullYear() + this._zeroPadding(date.getMonth() + 1) +
            //     this._zeroPadding(date.getDate()) + suffix + '.xlsx';
            // var url = '/bousai/Download/Download?reqid=' + reqid + '&disid=' + this._disasterId +
            // '&repdate=' + repdate + '&reptime=' + reptime + activeFlg + municipalityCd + districtCd;
            // FilesUtils.downloadGet(url,fileName);
            // return false;
        },


        //帳票出力
        download : function(data, baseFileName) {
            var deferred = new Deferred();

            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject();
                    return;
                }

                // バイナリデータを取得
                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }

                deferred.resolve();

            };
            xhr.send(JSON.stringify(data));

            return deferred.promise;
        },

        /**
         * 月や日付を2桁にゼロpaddingする
         */
        _zeroPadding: function(month) {
            return ('00' + month).slice(-2);
        },

        /**
         * キャンセルボタン押下
         */
        onCancel:function(){
            this.getParent().hide();
        }
    });
});
