/**
 * 職員所属グループ一覧グリッド
 * @module app/convocation/view/employee/ConvoEmployeeGrpListGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid'
], function(module, declare, IdisGrid) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        /**
         * 各列の定義
         * @type {Object[]}
         */

        collection: this.store,
        columns: [{field: 'distributionGroupName', label:'所属グループ名'}]
    });
});
