/**
 * ユーザの新規登録・詳細ダイアログ用の基底モジュール。
 * @module app/user/_UserDialogBase
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'idis/model/UserInfo',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button'
], function(module, declare, lang, domStyle, UserInfo, _PageBase, InfoDialog) {
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    {
        /**
         * ユーザID
         */
        _userId: null,

        /**
         * constructor
         */
        constructor: function() {
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * フォームをリセットする。
         */
        reset: function() {
            this.form.reset();
        },

        /**
         * 入力値の妥当性を検証する。
         */
        validate: function() {
            var formData = this.form.get('value');

            // accountType selection
            if (formData.roleCd === '') {
                InfoDialog.show('アカウント種類が選択されていません');
                return false;
            }

            // organization
            if (formData.organization === '') {
                InfoDialog.show('入力チェック', '組織が選択されていません');
                return false;
            }

            // 組織チェック
            switch (formData.roleCd) {
            case 'R01001':	// システム管理
            case 'R01011':  // 県・危機管理防災課・管理
            case 'R01012':	// 県・危機管理防災課・一般
            case 'R01021':	// 県・健康福祉・管理
            case 'R01022':	// 県・健康福祉・一般
            case 'R01031':	// 県・土木・管理
            case 'R01032':	// 県・土木・一般
            case 'R01041':	// 県・道路・管理
            case 'R01042':	// 県・道路・一般
            case 'R01901':	// 県・その他部局・管理
            case 'R01902':	// 県・その他部局・一般
            case 'R01999':	// 県・市町村情報照会用・管理
                // 県ユーザ
                if (formData.organization.slice(0, 1) !== '1') {
                    InfoDialog.show('組織を選択してください');
                    return false;
                }
                break;
            /*
            case 'R02011':	// 振興局・危機管理課・管理
            case 'R02012':	// 振興局・危機管理課・一般
            case 'R02021':	// 振興局・健康福祉・管理
            case 'R02022':	// 振興局・健康福祉・一般
            case 'R02031':	// 振興局・土木・管理
            case 'R02032':	// 振興局・土木・一般
            case 'R02041':	// 振興局・道路・管理
            case 'R02042':	// 振興局・道路・一般
            case 'R02901':	// 振興局・その他部局・管理
            case 'R02902':	// 振興局・その他部局・一般
            case 'R02912':	// 振興局・現地報告・一般
                // 振興局ユーザ処理
                if (formData.organization.slice(0, 1) !== '2') {
                    InfoDialog.show('組織を選択してください');
                    return false;
                }
                break;
            */
            case 'R03011':	// 市・危機管理課・管理
            case 'R03012':	// 市・危機管理課・一般
            case 'R03021':	// 市・健康福祉・管理
            case 'R03022':	// 市・健康福祉・一般
            case 'R03031':	// 市・土木・管理
            case 'R03032':	// 市・土木・一般
            case 'R03041':	// 市・道路・管理
            case 'R03042':	// 市・道路・一般
            case 'R03901':	// 市・その他部局・管理
            case 'R03902':	// 市・その他部局・一般
            case 'R03912':	// 市・現地報告・一般
            case 'R03922':	// 市・避難所運営・一般
                // 市町村ユーザ処理
                // 市町村必須チェック
                if (formData.organization.slice(0, 1) !== '3') {
                    InfoDialog.show('市/行政区組織を選択してください');
                    return false;
                }
                break;
            case 'R04012':	// 特殊関係機関
            case 'R04022':	// 一般関係機関
                // 関係機関ユーザ処理
                // 何もチェックしない
                break;
            default:
                break;
            }

            // account name
            if (formData.userID === '') {
                InfoDialog.show('入力チェック', 'ユーザIDが入力されていません');
                return false;
            }
            if (formData.userName === '') {
                InfoDialog.show('入力チェック', 'ユーザ名が入力されていません');
                return false;
            }
            // password
            if (this.title.innerHTML === 'ユーザ情報新規登録' && formData.password === '') {
                InfoDialog.show('入力チェック', 'パスワードが入力されていません');
                return false;
            }
            if (formData.userPassword !== formData.passwordConfirm) {
                InfoDialog.show('入力チェック', '入力されたパスワードが一致しません');
                return false;
            }
            if (formData.userPassword !== '' && !this.isValidPassword(formData.userPassword)) {
                InfoDialog.show('入力チェック', '入力されたパスワードが規則を満たしません。半角の英数および記号で８文字以上、２０文字以内で入力ください');
                return false;
            }
            return true;
        },

        /**
         * パスワードの妥当性を検証する。
         */
        isValidPassword: function(userPassword) {
            // 長さ
            if (userPassword.length < 8 || userPassword.length > 20) {
                return false;
            }

            // アルファベットが含まれているか
            if (/[^\x21-\x7e]/.test(userPassword)) {
                return false;
            }

            return true;
        }
    });
});
