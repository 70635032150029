/**
* 火山情報一覧画面用モジュール。
* @module app/volcano/view/VolcanoPage
*/

define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/util/DateUtils',
    'dojo/_base/lang',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function(module, declare, IdisGrid, helper, DateUtils) {

    /**
     * 日時を表示形式に変換する
     * 'yyyy-MM-dd HH:mm:dd'を'年月日時分'に変換する
     * FIXME 外部化を検討。helperで対応?
     *
     * @param {string} 'yyyy-MM-dd HH:mm:dd'
     * @return {string} 'yyyy年MM月dd日 HH時mm分'
     */

    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            return item && item.pubStatus === 1 ? 'is-public' : '';
        },

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.buttonColumn('detail', '詳細'
            ),
            helper.column('reportDateTime', '発表日時', {
                formatter: function(reportDateTime) {
                    return DateUtils.format(reportDateTime);
                }
            }),
            helper.column('volcanoName', '火山名'
            ),
            helper.column('title', '情報種別', {
                formatter: function(item) {
                    if (item === '噴火警報・予報') {
                        return item;
                    }
                    else if (item === '降灰予報（詳細）') {
                        return '降灰予報';
                    }
                    else if (item === '降灰予報（速報）') {
                        return '噴火・降灰速報';
                    }
                    return item;
                }
            }),
            helper.column('headline', '見出し文'
            )
        ]
    });
});
