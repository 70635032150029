/**
 * 津波警報詳細情報のメッセージ用コンテンツ
 * @module app/earthquake/tsunami/TsunamiWarningDetailContent
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/text!./templates/TsunamiWarningDetailContent.html',
    'dojox/lang/functional/object',
    'idis/error/InvalidArgumentException',
    'idis/view/_IdisWidgetBase'
], function(module, declare, array, lang, locale, template, object, InvalidArgumentException,
        _IdisWidgetBase) {
    /**
     * 津波警報詳細情報のメッセージ用UIウィジェット
     * @class TsunamiAlertDetailContent
     * @extends module:idis/view/page/_PageBase~_PageBase
     * @param {Object} kwArgs
     * @param {string} [kwArgs.item={Object}] 津波情報
     */
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase,
        /** @lends app/earthquake/tsunami/TsunamiWarningDetailContent~TsunamiWarningDetailContent# */ {
        // テンプレート文字列
        templateString: template,

        constructor: function(options) {
            this.inherited(arguments);
            lang.mixin(this, options);
            // 引数チェック
            if (!this.item) {
                throw new InvalidArgumentException(module.id + '::constructor: Property "item" must be specified.');
            }
        },

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            // コンテンツの中身を生成する
            this.setContent();
        },

        /**
         * コンテンツの中身を生成する
         */
        setContent: function() {
            this.reportDateTime.innerHTML = this.formatDateTime(this.item.reportDatetime);
            this.text.innerHTML =
                (!this.isEmpty(this.item.text)) ? this.item.text.replace(/。/g, '。<br>') : '';

            // テーブル内容を生成
            var html = [];
            array.forEach(this.item.regions, lang.hitch(this, function(item){
                html.push('<tr><td>');
                html.push(item.foAreaName);
                html.push('</td><td>');
                html.push(item.foKindName);
                html.push('</td><td>');
                html.push(item.foMaxHeightTsunamiHeighDescription);
                html.push('</td><td>');
                var arrivalTime =
                    (this.isEmpty(item.foFirstHiehtArrivalTime) ? '' : 
                        this.formatDateTime(item.foFirstHiehtArrivalTime));
                html.push(arrivalTime);
                html.push('</td></tr>');
            }));
            this.bodyNode.innerHTML = html.join('');
        },

        /**
         * 'yyyy-MM-dd HH:mm:ss' を表示形式に変換
         */
        formatDateTime: function(datetime) {
            var reportDateTime = new Date(datetime.replace(/-/g, '/'));
                var dateLabel = locale.format(reportDateTime, {
                    selector: 'date',
                    datePattern: 'MM月dd日'
                });
                var timeLabel = locale.format(reportDateTime, {
                    selector: 'time',
                    timePattern: 'HH時mm分'
                });
            return dateLabel + '&nbsp;' + timeLabel;
        },

        isEmpty: function(str) {
            if (str === void 0) {
                return true;
            }
            if (str === null) {
                return true;
            }
            return false;
        },

        /**
         * コンテンツのHTMLテキストを返す
         */
        getContent: function() {
            // HTMLタグ間に改行コードが入り、セット先のダイアログモジュールで<br>に変換されるため外しておく
            // テキスト内の改行はそのままにしたいため、「HTML終了タグ + 改行コード」にマッチする部分を
            // 「HTML終了タグ」のみにする
            // さらに、最初と最後の改行コードを外す
            return this.contentNode.innerHTML.replace(/>\r?\n/g, '>').replace(/^\r?\n/g, '').replace(/\r?\n$/g, '');
        }

    });
});
