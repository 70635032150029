define({
    '01': '都島区',
    '02': '福島区',
    '03': '此花区',
    '04': '西区',
    '05': '港区',
    '06': '大正区',
    '07': '天王寺',
    '08': '浪速区',
    '09': '西淀川',
    '10': '東淀川',
    '11': '東成区',
    '12': '生野区',
    '13': '旭区',
    '14': '城東区',
    '15': '阿倍野',
    '16': '住吉区',
    '17': '東住吉',
    '18': '西成区',
    '19': '淀川区',
    '20': '鶴見区',
    '21': '住之江',
    '22': '平野区',
    '23': '北区',
    '24': '中央区'
});
