/**
 * 被害情報集計ダイアログ
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/text!./templates/DamageReportNumberDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/model/DisasterInfo',
    'idis/model/UserInfo',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'app/view/form/MunicipalitySelector',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/RadioButton',
    'dijit/form/CheckBox',
    'dijit/form/Select',
    'dijit/form/ValidationTextBox',
    'app/damage/humanDamageReport/HumanDamageReportDialog'
], function (module, declare, lang, template, _PageBase, DisasterInfo, UserInfo, InfoDialog, DialogChain) {
    /**
     * 被害情報集計ダイアログ
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */
        {
            // テンプレート文字列
            templateString: template,
            _humanDamageList: [],
            constructor: function () {
                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);
            },
            /**
             * 画面生成完了
             */
            startup: function () {
                this.inherited(arguments);
                // このウィジェット消滅時にダイアログも削除
                this.own(this.dialog);
                if (UserInfo.getHumanDamageView() === '0') {
                    this.humanDamageListRegisterBtn.set('style', { 'display': 'none' });
                }

                this.innerDialog.on('send', lang.hitch(this, function (evt) {
                    this._humanDamageList = evt.data;

                    this.deadNumI.set('value', this._humanDamageList.filter(function (item) { return item.injuredLevel === '4'; }).length);
                    this.unknownNumI.set('value', this._humanDamageList.filter(function (item) { return item.injuredLevel === '3'; }).length);
                    this.seriousNumI.set('value', this._humanDamageList.filter(function (item) { return item.injuredLevel === '2'; }).length);
                    this.mildNumI.set('value', this._humanDamageList.filter(function (item) { return item.injuredLevel === '1'; }).length);
                }));
            },

            showDetail: function () {
                this.innerDialog.initGrid(this._humanDamageList);
                this.dialog.show();
                this.innerDialog.refresh();
            },

            onSubmit: function () {
                let formData = this.form.get('value');
                formData.humanDamageList = this._humanDamageList;
                console.log(formData);
                this.emit('send', {
                    formData: formData
                });
                this.leave();
            }
        });
});
