/**
 * ユーザーに権限のある県・振興局・市町村選択用入力パーツ
 * 地域名でなく、[振興局]としての表記での選択をする
 * @module app/disasterprevention/view/PrefPromotionBureauListSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/form/TreeSelector',
    './model/PrefPromotionBureau'
], function(module, declare, TreeSelector, PrefPromotionBureau) {
    /**
     * 県/振興局/市町村選択用入力パーツ
     * @class FacilityTypeSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */

    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:idis/view/form/ManageAllRegionMunicipalitySelector~TreeSelector# */ {
        // 選択ダイアログのタイトル
        // title: '県/振興局',
        title: '市/行政区',

        // ツリー・モデル
        model: PrefPromotionBureau
    });
});
