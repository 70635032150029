/* 開発環境用設定ファイル */
define({

    // 配信情報Lアラートカテゴリ
    sendingTypeList: [
      [{value:'11',label:'鉄道'},
       {value:'12',label:'バス'},
       {value:'13',label:'航空'},
       {value:'14',label:'船舶'},
       {value:'15',label:'道路'},
       {value:'16',label:'その他'}],
      [{value:'21',label:'電気'},
       {value:'22',label:'ガス'},
       {value:'23',label:'水道'},
       {value:'24',label:'給水'},
       {value:'25',label:'通信'},
       {value:'26',label:'放送'},
       {value:'27',label:'その他'}],
      [{value:'31',label:'行政手続き'},
       {value:'32',label:'被災者支援'},
       {value:'33',label:'福祉・教育・保険'},
       {value:'34',label:'環境'},
       {value:'35',label:'防犯'},
       {value:'36',label:'保険衛生'},
       {value:'37',label:'医療'},
       {value:'38',label:'その他'}],
       [{value:'41',label:'広報'}],
      [{value:'51',label:'観光・文化'},
       {value:'52',label:'その他'}],
      [{value:'61',label:'その他'}]
    ]
});
