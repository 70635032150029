const { TouchPitchHandler } = require("mapbox-gl");

/**
 * ダム詳細情報のグラフ
 * @module app/shelter/status/chart/Chart
 */
define([
    'module',
    'app/view/chart/ChartConfig',
    'app/view/chart/ChartCommon',
    'app/shelter/status/chart/ChartUtil',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojox/charting/action2d/Tooltip',
    'dojox/charting/action2d/Magnify',
    'dojox/charting/Chart',
    'dojox/charting/plot2d/Lines',
    'dojox/charting/themes/Bahamation',
    'dojox/charting/widget/Legend',
    //
    'dojox/charting/axis2d/Default'
], function (module, ChartConfig, ChartCommon, ChartUtil,
    array, declare, lang,
    Tooltip, Magnify, Chart, Lines, Theme, Legend
) {
    /**
     * ダム詳細情報のグラフ
     * @class Chart
     */
    return declare(module.id.replace(/\//g, '.'), null,
        /** @lends module:app/shelter/status/chart/Chart~Chart# */
        {

            maxYAxis: 10, // Y軸最大値
            minYAxis: 0, // Y軸最小値

            legend: {},
            colorList: [
                '#FF3D00',
                '#FF9100',
                '#FFC400',
                '#FFEA00',
                '#C6FF00',
                '#76FF03',
                '#00E676',
                '#1DE9B6',
                '#00E5FF',
                '#00B0FF',
                '#2979FF',
                '#D500F9',
                '#F50057',
                '#651FFF',
                '#FF1744',
                '#3D5AFE',
            ],
            /**
             * グラフのノードを生成する
             * @param {*} node グラフを入れたいhtml内のdivに対するdojo-data-attach-pointで示したオブジェクト
             * @param {*} LegendNode 凡例を入れたいhtml内のdivに対するdojo-data-attach-pointで示したオブジェクト
             * @param {*} options.mode 検索条件の時間モード
             * @param {*} options.data 詳細GridのCollectionのデータ
             */
            create: function (node, LegendNode, options) {
                if (options) {
                    // 引数を変数にセット
                    lang.mixin(this, options);
                }
                if (!!this.chart) {
                    // 再作成の場合、表を破棄
                    this.chart.destroy();
                }

                this.chart = new Chart(node, {
                    title: this.title,
                    titlePos: 'top',
                    titleGap: 10
                });

                // グラフのデザインテーマ, 他のグラフ群と合わせる
                this.chart.setTheme(Theme);

                // グラフの背景に描画される線の種類を決定。
                ChartCommon.addGridPlot(this.chart);

                if (!!this.data && this.data.length > 0) {
                    this.maxYAxis = this.data.map(function (i) { return ChartUtil.getMaxVal(i); }).reduce(function (a, b) { return Math.max(a, b); });
                }
                // 水位軸
                this.chart.addAxis('y', {
                    min: 0,
                    max: this.maxYAxis + 10,
                    vertical: true,
                    fixLower: 'minor',
                    fixUpper: 'minor'
                });

                // 時間軸
                this.chart.addAxis('x', {
                    labels: this.axisX
                });
                var facilityList = [];
                if (this.data) {
                    array.forEach(this.data, lang.hitch(this, function (item) {
                        // 線グラフとプロットされる点を紐付け
                        this.chart.addSeries(ChartUtil.getLabelName(item),
                            ChartUtil.getChartData(item), {
                            plot: item.facilityId,
                            marker: ChartConfig.MARKER_SHAPE
                        });
                    }));
                    array.forEach(this.data, lang.hitch(this, function (item) {
                        if (facilityList.indexOf(item.facilityId) === -1) {
                            facilityList.push(item.facilityId);
                        }
                    }));
                }
                array.forEach(facilityList, lang.hitch(this, function (id, i) {
                    this.addLinePlot(id, this.colorList[i % this.colorList.length]);
                }));
                if (!this.data) {
                    node.style.visibility = 'hidden';
                } else {
                    node.style.visibility = 'visible';
                }
                //チェックによる非表示、Gridとの対応

                this.chart.render();

                // プロットの凡例を表示
                // 初回起動時のみ生成
                if (!this.legend.id) {
                    this.legend = new Legend({ chart: this.chart, horizontal: true }, LegendNode);
                }
                this.legend.set('chart', this.chart);
                this.legend.refresh();
            },
            addLinePlot: function (itemCode, color) {
                this.chart.addPlot(itemCode, {
                    type: Lines,
                    stroke: { color: color },
                    fill: color,
                    markers: true
                });
            }
        });
});