define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--organization',

        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            return item && item.enableFlg === false ? 'is-public' : '';
        },

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            // ボタン
            helper.buttonColumn('detail', '詳細'),
            helper.column('municipalityName', '市/行政区名'),
            helper.column('regionName', '振興局名'),
            helper.column('deptName', '部名'),
            helper.column('sectName', '課名'),
            helper.column('unitName', '係名')
        ]
    });
});
