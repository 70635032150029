/**
 * 新規メール配信ダイアログ用モジュール。
 * @module app/convocation/view/mail/ConvoSendMailDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/text!./templates/ConvoSendMailDialog.html', // テンプレート文字列
    'dojo/dom-style',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/SimpleTextarea',
    'dijit/form/ValidationTextBox',
    'idis/view/form/Button',
    'app/view/form/ConvoGroupSelector',
    'app/convocation/view/mail/ConvoTempInsertDialog'
], function(module, declare, lang, template, domStyle, IdisRest, Requester, _PageBase,
    InfoDialog, Loader, DialogChain) {
    /**
     * 新規メール配信ダイアログ。
     * @class 新規メール配信ダイアログ
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/ConvoSendMailDialog~ConvoSendMailDialog# */
        {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            // テンプレートコード
            templateCd: '',

            /**
             * 変数の初期化。
             */
            constructor: function() {
                // データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'distributionTmplCd',
                    target: '/api/convoMails/temps'
                });
                this.chain = DialogChain.get(this);
            },

            /**
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function() {
                this.inherited(arguments);
                // 自分が削除された時は関連ダイアログも削除する
                this.own(this.templateInsertDialog);
                this.initTempInsertGrid();
                this.initDetailDialog();
            },

            /**
             * テンプレート選択用グリッドの初期化
             */
            initTempInsertGrid: function() {
                var page = this.templateInsertDialog.getChildren()[0];
                page.tempInsertGrid.set('collection', this.store.filter());
                // グリッドの挿入ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'insert'と'ButtonClick'の結合がボタンクリック時のイベント名
                page.tempInsertGrid.on('insertButtonClick', lang.hitch(this, function(evt) {
                    // grid内の件名と本文をformに設定する
                    this.form.set('value', evt.item);
                    this.templateCd = evt.item.distributionTmplCd;
                    this.templateInsertDialog.hide();
                }));
                // グリッドの修正ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'list'と'ButtonClick'の結合がボタンクリック時のイベント名
                page.tempInsertGrid.on('detailButtonClick', lang.hitch(this, function(evt){
                    // テンプレート詳細ダイアログを表示する
                    this.showConvoMailTempDetailDialog(evt.item);
                }));
            },

            /**
             * ページの初期化
             */
            initPage: function() {
                this.form.reset();
                this.templateCd = '';
            },

            /**
             * 送信ボタンが押下された際の処理。
             */
            onSubmit: function() {
                try {
                    // 入力チェックを通過した場合にのみ処理を実施
                    if (this.validate()) {
                        var val = this.form.get('value');
                        var formData = {
                            'groupId' : val.groupId,
                            'templateCd' : this.templateCd,
                            'title': val.distributionTitle,
                            'body' : val.distributionBody,
                            'status' : val.status,
                            'deliveryTime' : val.deliveryTime,
                            'mailType' : '0'
                        };
                        // 送信イベントを発行する
                        this.emit('send', {value: formData});
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * 入力値の妥当性チェック
             */
            validate: function() {
                var formData = this.form.get('value');
                var now = new Date();
                var aggrTimestampTo = new Date(now.getFullYear(), now.getMonth(), now.getDate(),
                                                        now.getHours(), now.getMinutes());
                // 送信先（グループ）の必須チェック
                if (!formData.groupId || formData.groupId.length === 0) {
                    InfoDialog.show('入力エラー', '送信先（グループ）が選択されていません。');
                    return false;
                }
                if (!formData.status){
                    InfoDialog.show('入力エラー', 'ステータスが選択されていません。');
                    return false;
                }
                if (formData.status === '1' && !formData.deliveryTime){
                    InfoDialog.show('入力エラー', '予約配信時は発信日時を入力して下さい。');
                    return false;
                }
                if (formData.status === '1'){
                    if (formData.deliveryTime < aggrTimestampTo) {
                        InfoDialog.show('入力エラー', '予約配信時刻に、現在時刻より前の日時を設定することはできません。');
                        return false;
                    }
                }
                // 件名のnullチェック
                if (formData.distributionTitle === '') {
                    InfoDialog.show('入力エラー', '件名が入力されていません。');
                    return false;
                }
                // 本文nullチェック
                if (formData.distributionBody === ''){
                    InfoDialog.show('入力エラー', '本文が入力されていません。');
                    return false;
                }

                return true;
            },

            /**
             * テンプレートから挿入ボタンが押下された際の処理。
             */
            onSelectTempButton: function() {
                // テンプレート選択ダイアログの表示
                this.templateInsertDialog.show();
            },

            /**
             * ステータス変更時の挙動
             */
            onChangeStatus: function(){
                var status = this.form.get('value').status;
                if (status === '1') {
                    domStyle.set(this.deliveryTimeArea, 'display', '');
                } else {
                    domStyle.set(this.deliveryTimeArea, 'display', 'none');
                }
            },
            /**
            * テンプレート詳細ダイアログを表示する。
            */
            showConvoMailTempDetailDialog: function(item){
                console.log('[詳細]ボタンがクリックされました');
                var page = this.convoMailTempDetailDialog.getChildren()[0];
                // 画面上のフォームをリセット
                // （ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み）
                page.form.reset();
                // テンプレートコードを保持
                this.distributionTmplCd = item.distributionTmplCd;
                // ダイアログを表示
                this.convoMailTempDetailDialog.show();
                page.form.set('value',item);
            },
            /**
             * 詳細ダイアログのformが投稿された際の挙動を設定する。
             */
            initDetailDialog: function() {
                // 詳細ダイアログの最初の子要素が画面の情報
                var dialog = this.convoMailTempDetailDialog;
                var page = dialog.getChildren()[0];

                // 詳細ダイアログからのupdateイベントを取得
                page.on('update', lang.hitch(this, function(evt) {
                    // ダイアログのform情報に当モジュールで保持しているテンプレートコードを追加
                    evt.value.distributionTmplCd = this.distributionTmplCd;
                    // 更新確認ダイアログの表示
                    this.chain.confirmPut(function(chain) {
                        // 更新処理完了まではローディング画面を表示
                        Loader.wait(this.store.put(evt.value)).then(function() {
                            // 更新処理成功時
                            // 詳細ダイアログを閉じる
                            dialog.hide();
                            // 完了ダイアログの表示
                            chain.infoComplete();
                        }, function(error) {
                            // 更新処理失敗時
                            // エラーダイアログの表示
                            chain.infoError(error);
                        });
                    });
                }));

                // 詳細ダイアログからのdeleteイベントを取得
                page.on('delete', lang.hitch(this, function() {
                    // 削除確認ダイアログの表示
                    this.chain.confirmDel(function(chain) {
                        // 削除処理完了まではローディング画面を表示
                        Loader.wait(this.store.remove(this.distributionTmplCd))
                        .then(function() {
                            // 削除処理成功時
                            // 詳細ダイアログを閉じる
                            dialog.hide();
                            // 完了ダイアログの表示
                            chain.infoComplete();
                        }, function(error) {
                            // 削除処理失敗時
                            // エラーダイアログの表示
                            chain.infoError(error);
                        });
                    });
                }));
            }
        });
});
