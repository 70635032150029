/**
* 部隊活動登録画面用モジュール。
* @module app/rescue/RescueRegisterPage
*/
define([
    'module',
    'app/model/LayerStore',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/data/ItemFileWriteStore',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-geometry',
    'dojo/dom-style',
    'dojo/json',
    'dojo/on',
    'dojo/request/iframe',
    'dojo/text!./templates/RescueRegisterPage.html',
    'dojo/topic',
    'dojo/window',
    'dstore/Rest',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dijit/registry',
    'app/model/DisasterInfo',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/IdisDialog',
    'idis/view/draw/_DrawUtil',
    'idis/view/Loader',
    'leaflet',
    './_RescuePageBase',
    'app/damage/consts/DamageType',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/form/SimpleTextarea',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dojox/form/Uploader',
    'dojox/layout/FloatingPane',
    'idis/view/form/Button',
    'idis/view/form/RadioGroup',
    'idis/view/form/WordCountTextarea',
    '../view/form/LayerDirectorySelector',
    '../view/form/DisasterSelector',
    '../view/form/OrganizationSelector'
], function(module, LayerStore, declare, lang, ItemFileWriteStore, domClass, domConstruct, domGeometry,
     domStyle, json, on, iframe, template, topic, winUtils, Rest, Menu, MenuItem, popup, TooltipDialog,
    registry, DisasterInfo, Locator, Router, IdisMap, UserInfo, Requester, DialogChain, IdisDialog, DrawUtil,
    Loader, leaflet, _RescuePageBase, DamageType) {
        /**
        * 部隊活動新規登録画面。
        * @class RescueRegisterPage
        * @extends module:app/rescue/_RescuePageBase~_RescuePageBase
        */
        return declare(module.id.replace(/\//g, '.'), _RescuePageBase,
        /** @lends module:app/rescue/RescueRegisterPage~RescueRegisterPage# */ {
        // テンプレート文字列
        templateString: template,

        constructor: function() {
            // ベースクラスのコンストラクタを呼ぶ
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            console.debug('start startup');

            this.inherited(arguments);

            // 部隊活動新規登録画面を初期化
            this.initRegisterPage();
        },

        /**
         * 部隊活動新規登録画面を初期化する。
         */
        initRegisterPage: function() {
            // 市町村を取得
            var self = this;
            var promise = Requester.get('/api/rescue', {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(data) {
                // 地図を初期化
                self.initMap(data.latitude, data.longitude);

                //初期化
                self.pointLat = '';
                self.pointLng = '';
                self.marker = '';
                self.attachFileList = [];

                // プレビューを初期化
                self.clearPreview();

                // ログインユーザの組織コードを取得する
                var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
                UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
                UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');

                // 続報番号を初期化
                self._seqNum = 1;

                var selectData = [];

                selectData.push({
                    rescueId  :'',
                    seqNum          :String(self._seqNum)
                });

                var selectContent = {
                    identifier  :'rescueId',
                    label       :'seqNum',
                    items       :selectData
                };

                var rescueItemFileWriteStore = new ItemFileWriteStore({
                        data:  selectContent
                    });
                self.seqNumSelect.set('sortByLabel', false);
                self.seqNumSelect.set('store', rescueItemFileWriteStore);
                self.seqNumSelect.set('value', null);
                self.seqNumSelect.set('disabled', 'disabled');

                // 報告部署にログインユーザの組織情報を設定
                self.organization._setValueAttr(orgCd);

            }, function(error) {
                self.chain.infoError(error);
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 部隊活動を登録する。
         */
        registerRescue: function() {
            var sendData = this.form.get('value');

            // 作図をJSON化する
            topic.publish(this.JSONIZE_RQST);

            if(!sendData.layerName) {
                this.setLayerName(sendData);
            }

            if(sendData.activityArea) {
                sendData.actLatitude = this._rescueAddressPointLat;
                sendData.actLongitude = this._rescueAddressPointLng;
            } else {
                sendData.actLatitude = '';
                sendData.actLongitude = '';
            }

            sendData.disasterId = DisasterInfo.getDisasterId();
            sendData.seqNum = this._seqNum;
            if(this._extAdmNum) {
                sendData.extAdmNum = this._extAdmNum;
            }

            // if(this.fixFlg.value === '0') {
            //     sendData.fixFlg = '1';
            // } else {
            //     sendData.fixFlg = '0';
            // }

            //添付ファイルIDをセット
            if(this.attachFileList.length > 0) {
                var attachFileIds = [];
                for(var i=0; i<this.attachFileList.length; i++) {
                    attachFileIds.push(this.attachFileList[i].rescueAtcFileId);
                }
                sendData.attachFileIds = attachFileIds.join(',');
            } else {
                sendData.attachFileIds = '';
            }

            delete sendData.attachFile;
            delete sendData.organizationName;

            // フォームのバリデーションを行う（共通部品）
            if(!this.form.validate()) {
                return false;
            }
             // フォームのバリデーションを行う（独自チェック）
             if(!this.validateForm(sendData)) {
                 return false;
             }

            var jsonStr = json.stringify(sendData);

            var self = this;
            //登録確認ダイアログを表示
            this.chain.confirmAdd(function(chain) {
                var promise = Requester.post('/api/rescue', {
                    data: jsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(function(data) {
                    console.debug('success register Rescue');
                    // 作図情報を登録
                    // var jsonData = [];
                    // jsonData.push(json.stringify(self.drawJson));
                    //console.log(jsonData);
                    var param = '?rescueId=' + data.rescueId;
                    return Requester.post('/api/rescue/uploadjson' + param, {
                        data: self.drawJson,
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(function() {
                        console.debug('success upload json');
                        chain.infoComplete(function() {
                            //ツリーの更新
                            LayerStore.refreshAll();
                            // 一覧画面に移動
                            Router.moveTo('rescue');
                        });
                    }, function(err) {
                        console.log(err);
                        chain.info('作図ファイルの登録に失敗しました。', 'エラー', function() {
                            // 一覧画面に移動
                            Router.moveTo('rescue');
                        });
                    });
                }, function(error) {
                    console.log('error register rescuerescue');
                    chain.infoError(error);
                });
                //ローダーの表示
                Loader.wait(promise);
            });
        	console.debug('[登録]ボタンがクリックされました。');
        },

        /**
         * 周辺の被害情報を地図に描画する
         */
        setDamageReportMarkers: function(items , selfDamageId) {
            this.layerGroup = new leaflet.featureGroup();
            this.layerGroupForSubMap = new leaflet.featureGroup();

            for(var i=0; i<items.length; i++){
              var item = items[i];
              var lat = item.latitude;
              var lng = item.longitude;

              //緯度経度があるもののみ表示
              if(!lat || !lng){
                continue;
              }
              //自分自身は表示させない
              if(selfDamageId && Number(item.damageReportId) === Number(selfDamageId)){
                  continue;
              }

              //ポップアップ
              var content = '';
              if (item.damageType) {
                  content = '<tr><td style="color:#FF0000"><b>被害位置</b></td></tr>';
                  content += '<tr><td></td></tr>';
                  content += '<tr><td>管理番号:</td><td>' + item.admNum + '</td></tr>';
                  content += '<tr><td>被害種別:</td><td>' + DamageType[item.damageType] + '</td></tr>';
                  if (item.damageAddress01) {
                      content += '<tr><td>住所:</td><td>' + item.damageAddress01 + '</td></tr>';
                  }

                  if (item.hldStatus !== null) {
                      var hldStatusMap = {
                          '0': '未確認',
                          '1': '依頼済',
                          '2': '対応中',
                          '3': '対応済'
                      };
                      content += '<tr><td>対応状況:</td><td>' + hldStatusMap[item.hldStatus] + '</td></tr>';
                  }

                  if (item.urgencyType !== null) {
                      var urgencyTypeMap = {
                          '0': '低',
                          '1': '中',
                          '2': '高'
                      };
                      content += '<tr><td>緊急度:</td><td>' + urgencyTypeMap[item.urgencyType] + '</td></tr>';
                  }

                  if (item.humanDamageFlg) {
                      var humanDamage;
                      if (item.humanDamageFlg === '0') {
                          humanDamage = 'あり';
                      } else {
                          humanDamage = 'なし';
                      }
                      content += '<tr><td>人的被害:</td><td>' + humanDamage + '</td></tr>';
                  }

                  content += '<tr><td></td></tr>';

              } else {
                  if (item.description) {
                      content += '<tr><td>' + item.content + '</td></tr>';
                  }
              }


              var marker = this.makeDamageMarker(item);
              var subMarker = this.makeDamageMarker(item);

              //メイン地図にのみポップアップを設定
              if(content){
                  marker.bindPopup('<table>'+content+'</table>', {maxWidth:1000, closeButton: false});
              }

              this.layerGroup.addLayer(marker);
              this.layerGroupForSubMap.addLayer(subMarker);

            }

        },

        makeDamageMarker: function(item){
            var lat = item.latitude;
            var lng = item.longitude;
            if(!lat || !lng){
              return false;
            }

            //レコードの値から、imgファイル名上の値に変換する
            var urgencyType = '0';
            if(item.urgencyType !== null && item.urgencyType !== ''){
                urgencyType = Number(item.urgencyType) + 1;
            }
            var hldStatus = '01';
            if(item.hldStatus !== null && item.hldStatus !== ''){
                if(item.hldStatus === '1' || item.hldStatus === '2'){
                    hldStatus = '02';
                }else if(item.hldStatus === '3'){
                    hldStatus = '03';
                }
            }
            var damageType = item.damageType;
            var humanDamageFlg = item.humanDamageFlg;

            //メインアイコン
            var mainIcon = '/data/images/draw/icon/080.png';
            if(damageType){
                mainIcon = '/images/damage/type_' + damageType + '_urg_' + urgencyType + '.png';
            }
            var html = '<img src="' + mainIcon + '" height="35px" width="35px" style="position:absolute;">';

            //人的被害アイコン
            var humanDamageIcon = '/images/damage/hmSuff_0.png';
            if (humanDamageFlg !== null) {
                humanDamageIcon = '/images/damage/hmSuff_' + humanDamageFlg + '.png';
            }
            html += '<img src="' + humanDamageIcon + '" height="22px" width="22px"';
            html += ' style="position:absolute;top:15px;left:18px;margin:2px">';

            //対応状況アイコン
            var hldStatusIcon = '/images/damage/status_' + hldStatus + '.png';
            html += '<img src="' + hldStatusIcon + '" height="12px" width="12px"';
            html += ' style="position:absolute;top:-4px;left:20px;">';

            var divIcon = leaflet.divIcon({
              html: html,
              className: 'damage-point',
              iconSize: [0,0],
              iconAnchor: [3,3]
            });


            var marker = leaflet.marker([lat, lng],{
                  icon: divIcon
            });

            return marker;
        }
    });
});
