/**
 * <モジュールの説明>
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/text!./templates/SimulationScenarioRegisterDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/model/DisasterInfo',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/util/FilesUtils',
    'idis/view/dialog/InfoDialog',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'dijit/form/Form'
], function(module, declare, lang, Deferred, JSON, template, _PageBase,
    DisasterInfo, UserInfo, USER_TYPE,FilesUtils, InfoDialog) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
        // テンプレート文字列
        templateString: template,

        buildRendering:function(){
            this.inherited(arguments);
        },
        startup:function(){
            this.inherited(arguments);
        },

        onRegisterButtonClick : function() {
            var scenarioName = this.form.get('value').scenarioName;
            //日時の入力チェック
            if(!scenarioName) {
                console.debug('シナリオ名が入力されていません');
                if(!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title :'エラー',
                        content :'シナリオ名が入力されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }

            var sendData = this.form.get('value');
            this.emit('register', {value: sendData});
        },

        /**
         * キャンセルボタン押下
         */
        onCancel:function(){
            this.getParent().hide();
        }
    });
});
