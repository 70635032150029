/**
 * 避難情報発令判断支援パネル
 * @module app/monitor/EvacRecommendPanel
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-style',
    'dojo/json',
    'dojo/topic',
    'dojo/text!./templates/EvacRecommendPanel.html',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/ConfirmDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/view/page/_PageBase',
    'app/model/DisasterInfo',
    '../config',
    'idis/consts/USER_TYPE',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane'
], function(module, array, declare, lang, locale, domStyle, JSON, topic, template, Router, UserInfo, Requester,
    ConfirmDialog, DialogChain, Loader, _PageBase, DisasterInfo, config, USER_TYPE) {
    /**
     * 避難情報発令判断支援パネル
     * @class EvacRecommendPanel
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/monitor/EvacRecommendPanel~EvacRecommendPanel# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--monitor',

        /**
         * 最大取得件数
         */
        MAX_COUNT: 5,

        /**
         * 市町村コード
         */
        _municipalityCd: null,

        /**
         * 災害ID
         */
        _disasterId: null,

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);
            this._municipalityCd = (UserInfo.getUserType() === USER_TYPE.PREFECTURE) ? config.municInfo.prefCd :
                    ( UserInfo.getUserType() === USER_TYPE.MUNICIPALITY ) ? UserInfo.getMunicipalityCd() :
                    null;
            this._regionCd = ( UserInfo.getUserType() === USER_TYPE.REGION ) ? UserInfo.getRegionCd() : null;
            console.debug('避難情報発令判断支援の市町村コード：' + this._municipalityCd);

            this._disasterId = DisasterInfo.getDisasterId();

        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initTable();

            // 市町村切替時に避難基準超過情報を更新
            this.own(topic.subscribe('idis/view/form/MunicipalitySelectForm::selected',
                lang.hitch(this, function(payload) {
                    this._municipalityCd = payload.municipalityCd;
                    this.initTable();
            })));

            // 災害切替時に避難基準超過情報を更新
            this.own(topic.subscribe('app/view/form/DisasterChanger::updated',
                lang.hitch(this, function() {
                    this._disasterId = DisasterInfo.getDisasterId();
                    this.initTable();
            })));
        },

        /**
         * テーブルを初期化します
         */
        initTable: function() {
            var self = this;

            if(!UserInfo.hasAuthz('F05005')){
                domStyle.set(this.EvacRecommendPanel, 'display', 'none');
                return;
            }

            var userMunics = UserInfo.getMunicipalityCds();

            var param = '';
            if(this._municipalityCd) {
                param += '?municipalityCd=' + this._municipalityCd;
            }
            if(this._regionCd) {
                param += '?regionCd=' + this._regionCd;
            }
            if ( param && this._disasterId){
                param += '&disasterId=' + this._disasterId;
            } else if (this._disasterId){
                param += '?disasterId=' + this._disasterId;
            }

            var promise = Requester.get('/data/evacorder/recommend/' + this._disasterId + '/evacRecommend.json', {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(lang.hitch(this, function(data) {
                console.debug('避難情報発令判断支援一覧（' + this._municipalityCd + '）：' +
                    JSON.stringify(data));
                    var wholeList = data.items;

                    // 管理対象市町村でフィルターをかける
                    var items = [];
                    array.forEach(wholeList, function(recommend){
                        // 管理対象市町村のレコードだった場合 & 有効な情報である場合
                        /*
                        if(userMunics.indexOf(recommend.municipalityCd) !== -1 && recommend.status === '0'){
                            items.push(recommend);
                        }
                        */
                       var shareFlg = false;
                        array.forEach(recommend.municipality,function(municipality){
                            // 管理対象市町村のレコードだった場合 & 全超過している場合
                            if(userMunics.indexOf(municipality.municipalityCd) !== -1 && recommend.status === '1'){
                                shareFlg = true;
                            }
                        });
                        if(shareFlg){
                            items.push(recommend);
                        }
                        
                    });

                var html = [''];
                // 札幌市要件: 札幌市ユーザ以外には表示不要
                if(items.length === 0 || UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                    domStyle.set(this.EvacRecommendPanel, 'display', 'none');
                } else {
                    domStyle.set(this.EvacRecommendPanel, 'display', '');

                    // 各発令理由に関して、最大の避難区分のデータのみ抽出
                    items = this.getMaxEvacOrderType4EachIssueReason(items);

                    array.forEach(items, function(item){
                        var datetimeString = self.formatDateTime(item.updTimestamp);
                        html.push('<tr>');
                        html.push('<td><b>');
                        html.push(datetimeString);
                        html.push('</b></td>');
                        html.push('<td style="width: 20%;"><b>');
                        html.push(this.getIssueReasonType(item));
                        html.push('</b></td>');
                        var evacOrderTypeProperty = this.getEvacOrderType(item);
                        html.push('<td style="text-align: center;' + evacOrderTypeProperty.style + '"><b>');
                        html.push(evacOrderTypeProperty.type);
                        html.push('</b></td>');
                        html.push('</tr>');
                    }, this);

                    if(this.evacRecommendTable) {
                        // 画面遷移してない場合のみ注
                        this.evacRecommendTable.innerHTML = html.join('');
                    }
                }

            }), lang.hitch(this,function(error) {
                console.log(error);
                if (error.response.status === 401) {
                    // 自動ログアウトのメッセージをダイアログで表示
                    new ConfirmDialog({
                        title: '自動ログアウト',
                        content: '一定時間が経過した為、自動的にログアウトされました。<br>ログインしなおしてください。',
                        showCancel: false,
                        onOK: function() {
                            if (document.logout) {
                                // ログアウトフォームをサブミット
                                document.logout.submit();
                            }
                        }
                    }).show();
                // } else {
                    // FIXME: 岡山では発令判断支援がないのでエラーメッセージは表示しない。
                    // self.chain.info('情報の取得に失敗しました。', 'エラー');
                }
            }));
            //ローダーの表示
            Loader.wait(promise);
        },

        getMaxEvacOrderType4EachIssueReason: function(items){
            // サーバサイドから返ってくる時点で、避難区分が重い順に並んでいる。
            // そのため、各発令理由について最初に取り出されたデータが、その発令理由で一番重いデータとなる。
            var sedimentFlg = false;
            var rainfallFlg = false;
            var tsunamiFlg = false;
            var stormSurgeFlg = false;
            var resultList = [];
            array.forEach(items, function(evacRecommend){
                if(evacRecommend.issueReasonType === '01' && !sedimentFlg){
                    resultList.push(evacRecommend);
                    sedimentFlg = true;
                }
                if(evacRecommend.issueReasonType === '02' && !rainfallFlg){
                    resultList.push(evacRecommend);
                    rainfallFlg = true;
                }
                if(evacRecommend.issueReasonType === '04' && !tsunamiFlg){
                    resultList.push(evacRecommend);
                    tsunamiFlg = true;
                }
                if(evacRecommend.issueReasonType === '05' && !stormSurgeFlg){
                    resultList.push(evacRecommend);
                    stormSurgeFlg = true;
                }
            });
            return resultList;
        },

        getIssueReasonType: function(item){
            var type = null;
            switch(item.issueReasonType) {
               case '01':
                   type = '土砂';
                   break;
               case '02':
                   type = '洪水';
                   break;
               case '03':
                   type = '地震';
                   break;
               case '04':
                   type = '津波';
                   break;
               case '05':
                   type = '高潮';
                   break;
               case '06':
                   type = '火災';
                   break;
               case '07':
                   type = '暴風';
                   break;
               //case '08':
               //    type = '火山';
               //    break;
               case '09':
                   type = '国民保護';
                   break;
               case '00':
                   type = 'その他';
                   break;
           }
           return type;
        },

        getEvacOrderType: function(item){
            var evacOrderTypeProperty = {};
            switch(item.evacOrderType) {
               case '11':
                   evacOrderTypeProperty.type = '高齢者等避難';
                   evacOrderTypeProperty.style = 'color:white; background-color:#fc002e';
                   break;
               case '13':
                   evacOrderTypeProperty.type = '避難指示';
                   evacOrderTypeProperty.style = 'color:white; background-color:#aa00aa';
                   break;
               case '14':
                   evacOrderTypeProperty.type = '緊急安全確保';
                   evacOrderTypeProperty.style = 'color:white; background-color:#0c000c';
                   break;
           }
           return evacOrderTypeProperty;
        },

        onEvacRecommendShowClick: function() {
            domStyle.set(this.evacRecommendTableAll, 'display', '');
            domStyle.set(this.evacRecommendLink, 'display', '');
            domStyle.set(this.evacRecommendTableShowMarker, 'display', 'none');
            domStyle.set(this.evacRecommendTableHideMarker, 'display', '');
        },
        onEvacRecommendHideClick: function() {
            domStyle.set(this.evacRecommendTableAll, 'display', 'none');
            domStyle.set(this.evacRecommendLink, 'display', 'none');
            domStyle.set(this.evacRecommendTableShowMarker, 'display', '');
            domStyle.set(this.evacRecommendTableHideMarker, 'display', 'none');
        },

        /**
         * 'yyyy-MM-dd HH:mm' 形式に変換
         */
        formatDateTime: function(val) {
            // var timestamp = new Date(val);
            var timestamp = new Date(val);
            var dateLabel = locale.format(timestamp, {
                selector: 'date',
                datePattern: 'yyyy/MM/dd'
            });
            var timeLabel = locale.format(timestamp, {
                selector: 'time',
                timePattern: 'HH:mm'
            });
            return dateLabel + '&nbsp;' + timeLabel;
        },

        onEvacRecommendLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 時系列情報管理へ遷移
            Router.moveTo('evacrecommend');
        }
    });
});
