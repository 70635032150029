/**
 * <河川水位情報情報詳細画面>
 *
 * @module app/river/RiverLevelObservationDetailPageGrid
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'idis/view/grid/IdisGrid',
    './RiverLevelObservationCommonColumns',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer'
], function (module, declare, IdisGrid, RiverCommonColumns) {
    /**
     * 河川水位情報情報詳細画面用Grid
     *
     * @class RiverLevelObservationDetailPageGrid
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(
        module.id.replace(/\//g, '.'),
        IdisGrid,
        /** @lends module:app/river/RiverLevelObservationHourlyPageGrid~RainfallObservationDetailPageGrid# */
        {
            rowClassName: function (item) {
                return item && item.forecast ? 'is-forecast' : '';
            },

            columns: [
                {
                    field: 'days',
                    label: '月日',
                    sortable: false,
                    formatter: function (value, item) {
                        if (item.forecast) {
                            return '[予測] ' + value;
                        } else {
                            return value;
                        }
                    }
                },
                {
                    field: 'time',
                    label: '時刻',
                    sortable: false
                },
                {
                    field: 'excess',
                    label: '基準値\n超過',
                    className: function (item) {
                        // ヘッダーの場合はclassNameを返さない
                        if (!item) {
                            return;
                        }
                        if (item.riverLevelFlg === '1') {
                            return 'is-missing';
                        } else if (item.riverLevelFlg === '2') {
                            return 'is-notYet';
                        } else if (item.riverLevelFlg === '3') {
                            return 'is-closed';
                        } else if (item.riverLevelFlg === '0') {
                            if (
                                item.riverLevel >= item.riverLevelDanger &&
                                item.riverLevelDanger !== null &&
                                item.riverLevelDanger !== 0
                            ) {
                                return 'is-crisis';
                            } else if (
                                item.riverLevel >= item.riverLevelEvacuate &&
                                item.riverLevelEvacuate !== null &&
                                item.riverLevelEvacuate !== 0
                            ) {
                                return 'is-judgment';
                            } else if (
                                item.riverLevel >= item.riverLevelCaution &&
                                item.riverLevelCaution !== null &&
                                item.riverLevelCaution !== 0
                            ) {
                                return 'is-caution';
                            } else if (
                                item.riverLevel >= item.riverLevelStandby &&
                                item.riverLevelStandby !== null &&
                                item.riverLevelStandby !== 0
                            ) {
                                return 'is-wait';
                            } else {
                                return;
                            }
                        }
                    },
                    formatter: function (v, item) {
                        if (item.riverLevelFlg === '0') {
                            if (
                                item.riverLevel >= item.riverLevelDanger &&
                                item.riverLevelDanger !== null &&
                                item.riverLevelDanger !== 0
                            ) {
                                return '(Lv5)';
                            } else if (
                                item.riverLevel >= item.riverLevelEvacuate &&
                                item.riverLevelEvacuate !== null &&
                                item.riverLevelEvacuate !== 0
                            ) {
                                return '(Lv4)';
                            } else if (
                                item.riverLevel >= item.riverLevelCaution &&
                                item.riverLevelCaution !== null &&
                                item.riverLevelCaution !== 0
                            ) {
                                return '(Lv3)';
                            } else if (
                                item.riverLevel >= item.riverLevelStandby &&
                                item.riverLevelStandby !== null &&
                                item.riverLevelStandby !== 0
                            ) {
                                return '(注)';
                            } else {
                                return '-';
                            }
                        } else {
                            return '-';
                        }
                    },
                    sortable: false
                },
                RiverCommonColumns.riverLevel,
                RiverCommonColumns.difference
                // helper.buttonColumn('correct', '修正')
            ],
            changeMode: function (mode) {
                RiverCommonColumns.changeColumnsLabel(mode);
                this.renderHeader();
            }
        }
    );
});
