/**
 * 職員招集・参集状況管理画面・職員管理タブ用モジュール。
 *
 * @module app/convocation/view/employee/ConvocationEmployeeAdminPage
 */
define(['module',
        'dojo/_base/declare',
        'dojo/_base/lang',
        'dojo/text!./templates/ConvocationEmployeeAdminPage.html',
        'idis/service/Requester',
        'idis/store/IdisRest',
        'idis/view/dialog/DialogChain',
        'idis/view/dialog/InfoDialog',
        'idis/view/page/_PageBase',
        'idis/view/Loader',
        'dojo/Deferred',
        'dojo/json',
        'idis/model/UserInfo',
        'idis/consts/ACL',
        'dojo/dom-style',
        // 以下、変数で受けないモジュール
        'dijit/Dialog',
        'dijit/form/Form',
        'dijit/form/Select',
        'dijit/form/TextBox',
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'idis/view/form/AclButton',
        //'app/view/form/EmployeeDivisionSelector',
        'app/view/form/MunicRegionOrganizationSelector',
        'app/convocation/view/employee/ConvoEmployeeListGrid',
        'app/convocation/view/employee/ConvoEmployeeRegisterDialog',
        'app/convocation/view/employee/ConvoEmployeeDetailDialog',
        'app/convocation/view/employee/ConvoEmployeeFileUploadDialog',
        'app/convocation/view/employee/ConvoEmployeeOutputReportDialog'],
    function(module, declare, lang, template, Requester, IdisRest,
        DialogChain, InfoDialog, _PageBase, Loader, Deferred, JSON, UserInfo, ACL, domStyle) {
        /**
         * 参集状況管理画面
         *
         * @class EvacOrderAdminPage
         * @extends module:idis/view/page/_PageBase~_PageBase
         */
        return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/view/page/EvacOrderAdminPage~EvacOrderAdminPage# */
        {
            // テンプレート文字列
            templateString: template,

            store: null,

            //dpDivisionCdForReport: null,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            // 職員番号
            employeeCd: '',

            // 危機管理対策室ユーザの部コード
            RISK_MANAGE_DEPTCD: 'D13761',

            /**
             * 変数の初期化を行う。
             */
            constructor: function() {
                // 職員管理タブ用データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'employeeCd',
                    target: '/api/convoMails/employees'
                });
                // ダイアログ連鎖を登録
                // 引数に与えたウィジェットのthis.ownを呼び出し、
                // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
                this.chain = DialogChain.get(this);
            },

            /**
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function() {
                this.inherited(arguments);
                this.initConvoEmployeeGrid();
                this.initRegisterDialog();
                this.initDetailDialog();
                this.initFileUploadDialog();
                // 安否の「課選択」ツリーを流用しているため、タイトルを変更する
                //this.dpDivision.set('title', '所属');
                this.organizationSelector.restoreRefresh();
                // 画面を初期化
                this.initPage();

            },

            /**
             * 画面を初期化
             */
            initPage : function() {
                var role = UserInfo.getRoleCd();
	            // システム管理者ユーザ以外の場合
                if (role !== ACL.ADMIN_USER){
                    // 選択ボタンを非表示
                    domStyle.set(this.organizationSelector.selectButton.domNode, 'display', 'none');
                    // 検索ボタンを非活性
                    this.serch.setDisabled(true);
                }
                // ファイル読み込みボタン：システム管理ユーザと 危機管理対策室ユーザ以外は非表示
                //if (role !== ACL.ADMIN_USER && UserInfo.getOrganization().deptCd !== this.RISK_MANAGE_DEPTCD){
	            if (role !== ACL.ADMIN_USER && role !== ACL.CITY_CRI_MANAGE_USER){
	                domStyle.set(this.employeeFileUploadDialog.domNode, 'display', 'none');
                }
            },

            /**
             * 職員管理タブ用グリッドの初期化。
             */
            initConvoEmployeeGrid: function() {
                // 初期表示時の検索条件を設定する
                var filter = new this.store.Filter();
                var role = UserInfo.getRoleCd();
                if (role !== ACL.ADMIN_USER) {
//                    if (UserInfo.getOrganization().sectCd !== null &&
//                          UserInfo.getOrganization().sectCd !== '') {
//                        //this.dpDivision.set('value', UserInfo.getOrganization().sectCd);
//                        //filter = filter.eq('dpDivisionCd', UserInfo.getOrganization().sectCd);
//                        this.organizationSelector.set('value', UserInfo.getOrganization().sectCd);
//                        filter = filter.eq('organization', UserInfo.getOrganization().sectCd);
//                        this.dpDivisionCdForReport = UserInfo.getOrganization().sectCd;
//                    } else if (UserInfo.getOrganization().deptCd !== null &&
//                          UserInfo.getOrganization().deptCd !== '') {
//                        //this.dpDivision.set('value', UserInfo.getOrganization().deptCd);
//                        //filter = filter.eq('dpDivisionCd', UserInfo.getOrganization().deptCd);
//                        this.organizationSelector.set('value', UserInfo.getOrganization().deptCd);
//                        filter = filter.eq('organization', UserInfo.getOrganization().deptCd);
//                        this.dpDivisionCdForReport = UserInfo.getOrganization().deptCd;
//                    }
                    // 画面表示用にユーザ部課係を取得
                    var orgCdSerch = (UserInfo.getOrganization().unitCd ? UserInfo.getOrganization().unitCd :
                                      UserInfo.getOrganization().sectCd ? UserInfo.getOrganization().sectCd :
	                                  UserInfo.getOrganization().deptCd ? UserInfo.getOrganization().deptCd : '');
                    filter = filter.eq('organization', orgCdSerch);
                    //this.dpDivisionCdForReport = orgCdSerch;

                    // 組織名取得
                    var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
                                  UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
                                  UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');
                    this.organizationSelector.set('value', orgCd);

                } else {
                    //this.dpDivision.set('value', '');
                    //filter = filter.eq('dpDivisionCd', '');
                    this.organizationSelector.set('value', '');
                    filter = filter.eq('organization', '');
                    //this.dpDivisionCdForReport = '';
                }

//                // 組織名取得
//                var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
//                             UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
//                             UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');
//                this.organizationSelector.set('value', orgCd);


                //this.dpDivision.set('value', '10010003');
                //filter = filter.eq('dpDivisionCd', '10010003');
                //this.dpDivisionCdForReport = '10010003';

                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);

                // 職員情報をgridにセットする
                this.convoEmployeeGrid.set('collection',collection);
                // グリッドの一覧ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'list'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.convoEmployeeGrid.on('detailButtonClick', lang.hitch(this, function(evt){
                    // 職員情報詳細ダイアログを表示する
                    this.showEmployeeDetailDialog(evt.item);
                }));
            },

            /**
             * 新規登録ダイアログのformが投稿された際の挙動を設定する。
             */
            initRegisterDialog: function() {
                // 新規登録ダイアログの最初の子要素が画面の情報
                var dialog = this.convoEmployeeRegisterDialog;
                var page = dialog.getChildren()[0];
                var self = this;
                // 新規登録ダイアログからのregisterイベントを取得
                page.on('register', lang.hitch(this, function(evt) {
                    // 登録確認ダイアログの表示
                    this.chain.confirmAdd(function(chain) {
                        // 登録処理完了まではローディング画面を表示
                        Loader.wait(this.store.add(evt.value)).then(function() {
                            // 登録処理成功時
                            self.onSubmit();
                            // 新規登録ダイアログを閉じる
                            dialog.hide();
                            // 完了ダイアログの表示
                            chain.infoComplete();
                        }, function(error) {
                            // 登録処理失敗時
                            if (error.response.status === 400) {
                                chain.hide();
                                //InfoDialog.show('登録エラー', '入力された職員番号は既に存在しています');
                                InfoDialog.show('登録エラー', '同じ職員番号の職員情報が既に登録されています');
                            } else {
                                // エラーダイアログの表示
                                chain.infoError(error);
                            }
                        });
                    });
                }));
            },

            /**
             * 詳細ダイアログのformが投稿された際の挙動を設定する。
             */
            initDetailDialog: function() {
                // 詳細ダイアログの最初の子要素が画面の情報
                var dialog = this.convoEmployeeDetailDialog;
                var page = dialog.getChildren()[0];
                var self = this;
                // 詳細ダイアログからのupdateイベントを取得
                page.on('update', lang.hitch(this, function(evt) {
                    // ダイアログのform情報に当モジュールで保持している職員番号を追加
                    evt.value.employeeCd = this.employeeCd;

                    // 更新確認ダイアログの表示
                    this.chain.confirmPut(function(chain) {
                        // 更新処理完了まではローディング画面を表示
                        Loader.wait(this.store.put(evt.value)).then(function() {
                            // 更新処理成功時
                            self.onSubmit();
                            // 詳細ダイアログを閉じる
                            dialog.hide();
                            // 完了ダイアログの表示
                            chain.infoComplete();
                        }, function(error) {
                            // 更新処理失敗時
                            // エラーダイアログの表示
                            chain.infoError(error);
                        });
                    });
                }));

                // 詳細ダイアログからのdeleteイベントを取得
                page.on('delete', lang.hitch(this, function() {
                    // 削除確認ダイアログの表示
                    var messege = '削除します。よろしいですか？<br>' + '職員が0人となったグループ・配信テンプレートは合わせて削除されます。';
                    this.chain.confirm(messege, function(chain) {
                        // 削除処理完了まではローディング画面を表示
                        Loader.wait(this.store.remove(this.employeeCd)).then(function() {
                            // 削除処理成功時
                            self.onSubmit();
                            // 詳細ダイアログを閉じる
                            dialog.hide();
                            // 完了ダイアログの表示
                            chain.infoComplete();
                        }, function(error) {
                            // 削除処理失敗時
                            // エラーダイアログの表示
                            chain.infoError(error);
                        });
                    });
                }));
            },

            /**
             * 職員情報
             * ファイルアップロードダイアログ初期化処理
             */
            initFileUploadDialog: function() {
                // 詳細ダイアログの最初の子要素が詳細画面
                var dialog = this.convoEmployeeFileUploadDialog;
                var page = dialog.getChildren()[0];
                // 更新ボタンが押された際の動作
                page.on('upload', lang.hitch(this, function() {
                    this.onSubmit();
                }));
            },

            /**
             * 職員情報
             * 帳票出力ダイアログ初期化処理
             */
            initOutputReportDialog: function() {
                // 詳細ダイアログの最初の子要素が詳細画面
                var dialog = this.convoEmployeeOutputReportDialog;
                var page = dialog.getChildren()[0];
                // 更新ボタンが押された際の動作
                page.on('upload', lang.hitch(this, function() {
                    this.onSubmit();
                }));
            },

            /**
             * 検索ボタンが押下された際の挙動。
             */
            onSubmit: function() {
                var value = this.form.get('value');
                this.updateGridQuery(value);

                return false;
            },

            /**
             * @param value フォームから取得した情報
             * 職員管理タブ用の検索
             */
            updateGridQuery: function(value) {
                // 入力値を元にグリッド用フィルターを作成
                var empFilter = new this.store.Filter();
                // 職員番号(部分一致)
                if (value.employeeCd) {
                    empFilter = empFilter.eq('employeeCd',value.employeeCd);
                }
                // 氏名(部分一致)
                if (value.name) {
                    empFilter = empFilter.eq('name',value.name);
                }
                // 所属(ツリーによる選択)
//                if (value.dpDivisionCd) {
//                    empFilter = empFilter.eq('dpDivisionCd',value.dpDivisionCd);
                if (value.organization) {
                    empFilter = empFilter.eq('organization',value.organization.slice(1));
                }
                //this.dpDivisionCdForReport = value.dpDivisionCd;
                // メールアドレス(部分一致)
                if (value.emailAddress) {
                    empFilter = empFilter.eq('emailAddress',value.emailAddress);
                }
                // filterに対応するcollectionを取得
                var collection = this.store.filter(empFilter);
                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.convoEmployeeGrid.set('collection', collection);
            },

            /**
             * 職員情報新規登録ダイアログを表示する。
             */
            showEmployeeRegisterDialog: function() {
                var dialog = this.convoEmployeeRegisterDialog;
                var page = dialog.getChildren()[0];
                page.form.reset();
                var role = UserInfo.getRoleCd();
                if (role !== ACL.ADMIN_USER) {
//                    if (UserInfo.getOrganization().sectCd !== null &&
//                          UserInfo.getOrganization().sectCd !== '') {
//                        page.employeeDivisionSelector.set('value', UserInfo.getOrganization().sectCd);
//                    } else if (UserInfo.getOrganization().deptCd !== null &&
//                          UserInfo.getOrganization().deptCd !== '') {
//                        page.employeeDivisionSelector.set('value', UserInfo.getOrganization().deptCd);
//                    }
                    var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
                                 UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
                                 UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');
                    page.organizationSelector.set('value', orgCd);
                }
                this.convoEmployeeRegisterDialog.show();
            },

            /**
             * 職員情報詳細ダイアログを表示する。
             */
            showEmployeeDetailDialog: function(item) {
                var dialog = this.convoEmployeeDetailDialog;
                var page = dialog.getChildren()[0];
                page.form.reset();
                var url = '/api/convoMails/employees/' + item.employeeCd;

                // IDをキーに職員情報を再度サーバから取得し、ダイアログのformに設定
                Requester.get(url).then(function(data) {
                    page.form.set('value', data);

//                    var role = UserInfo.getRoleCd();
//                    if (role !== ACL.ADMIN_USER) {
//                        if (UserInfo.getOrganization().sectCd !== null &&
//                              UserInfo.getOrganization().sectCd !== '' &&
//                              UserInfo.getOrganization().sectCd !== data.sectCd) {
//                            page.deleteButton.setDisabled(true);
//                            page.submitButton.setDisabled(true);
//                        } else if (UserInfo.getOrganization().deptCd !== null &&
//                              UserInfo.getOrganization().deptCd !== '' &&
//                              (UserInfo.getOrganization().deptCd !== data.deptCd &&
//                                  UserInfo.getOrganization().deptCd !== data.sectCd)) {
//                            page.deleteButton.setDisabled(true);
//                            page.submitButton.setDisabled(true);
//                        } else {
//                            page.deleteButton.setDisabled(false);
//                            page.submitButton.setDisabled(false);
//                        }
//                    }
                }, lang.hitch(this, function(error) {
                    // 取得に失敗した場合は、ログ出力とメッセージのダイアログ表示を行う。
                    console.log(error);
                    this.chain.info('職員情報の取得に失敗しました。', 'エラー');
                }));

                // 更新・削除に備え選択された職員番号を保持する
                this.employeeCd = item.employeeCd;

                this.convoEmployeeDetailDialog.show();
            },

            /**
             * 職員一覧出力ボタンが押下された際の挙動。
             */
//            outputListExcel: function() {
//                console.log('[帳票出力]ボタンがクリックされました。');
//                var msg = '職員一覧を出力します。<br>よろしいですか？';
//
//                // 確認ダイアログを表示
//                this.chain.confirm(msg, function(chain) {
//                    // 確認ダイアログでOKを押した場合
//                    chain.hide();
//
//                    var reqid = 'DP_EMPLOYEE_LIST_EX';
//                    var fileName = 'dpEmployeeList.xlsx';
//                    var fileNameJp = '職員一覧.xlsx';
//                    var paramList = [];
//                    paramList.push({key: 'dpDivisionCd', value: this.dpDivisionCdForReport});
//
//                    var data4xoblosDownload = {
//                        fileName: fileName,
//                        reqid: reqid,
//                        paramList: paramList
//                    };
//
//                    var promise = this.download(data4xoblosDownload, fileNameJp).then(lang.hitch(this, function() {
//                            this.infoDialog = new InfoDialog({
//                                title : '出力完了',
//                                content : '職員一覧帳票の出力が完了しました。'
//                            });
//                            this.infoDialog.show();
//
//                        }), lang.hitch(this, function(error) {
//                            console.error(error);
//                            this.infoDialog = new InfoDialog({
//                                title : 'エラー',
//                                content : 'エラーが発生しました。管理者にお問い合わせください。'
//                            });
//                            this.infoDialog.show();
//                    }));
//
//                    Loader.wait(promise);
//                });
//            },

            /**
             * グループ付き職員一覧出力ボタンが押下された際の挙動。
             */
            outputListExcelGroup: function() {
//                console.log('[帳票出力]ボタンがクリックされました。');
//                var msg = 'グループ付き職員一覧を出力します。<br>よろしいですか？';
//
//                // 確認ダイアログを表示
//                this.chain.confirm(msg, function(chain) {
//                    // 確認ダイアログでOKを押した場合
//                    chain.hide();
//
//                    var reqid = 'DP_EMPLOYEE_GROUP_LIST_EX';
//                    var fileName = 'dpEmployeeGroupList.xlsx';
//                    var fileNameJp = 'グループ付き職員一覧.xlsx';
//                    var paramList = [];
//                    //paramList.push({key: 'dpDivisionCd', value: this.dpDivisionCdForReport});
//                    paramList.push({key: 'organization', value: this.dpDivisionCdForReport});
//
//                    var data4xoblosDownload = {
//                        fileName: fileName,
//                        reqid: reqid,
//                        paramList: paramList
//                    };
//
////                    var promise = this.download(data4xoblosDownload, fileNameJp).then(lang.hitch(this, function() {
////                            this.infoDialog = new InfoDialog({
////                                title : '出力完了',
////                                content : 'グループ付き職員一覧帳票の出力が完了しました。'
////                            });
////                            this.infoDialog.show();
////
////                        }), lang.hitch(this, function(error) {
////                            console.error(error);
////                            this.infoDialog = new InfoDialog({
////                                title : 'エラー',
////                                content : 'エラーが発生しました。管理者にお問い合わせください。'
////                            });
////                            this.infoDialog.show();
////                    }));
//
//                    Loader.wait(promise);
//                });
            },

            // 帳票のダウンロード
//            download: function(data, fileNameJp) {
//                var deferred = new Deferred();
//
//                var xhr = new XMLHttpRequest();
//                xhr.open('POST', '/api/xoblos/download', true);
//                xhr.responseType = 'arraybuffer';
//                xhr.setRequestHeader( 'Content-Type', 'application/json' );
//                xhr.onload = function() {
//
//                    // エラー時は処理を止める
//                    if(xhr.status !== 200){
//                        deferred.reject('status error:'+ xhr.status);
//                        return;
//                    }
//
//                    var arrayBuffer = this.response;
//                    var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
//                    // ファイル名を取得
//                    var baseFileName = fileNameJp;
//                    var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');
//
//                    // IE10+
//                    if(window.navigator.msSaveOrOpenBlob){
//                        window.navigator.msSaveOrOpenBlob(blob, fileName);
//                    } else {
//                        // 擬似的にAタグを作成
//                        var link = document.createElement('a');
//                        link.style = 'display: none';
//                        document.body.appendChild(link);
//
//                        // AタグのURLにバイナリデータをセット
//                        var url = window.URL.createObjectURL(blob);
//                        link.href = url;
//
//                        // ファイル名をセット
//                        link.download = fileName;
//
//                        // 擬似的にリンクをクリック
//                        link.click();
//                        // 参照を解放
//                        window.URL.revokeObjectURL(url);
//                        link.remove();
//                    }
//
//                    deferred.resolve();
//
//                };
//                xhr.send(JSON.stringify(data));
//
//                return deferred.promise;
//            },

            /**
             * ファイル読み込みダイアログを表示する。
             */
            showEmployeeFileUploadDialog: function() {
                this.convoEmployeeFileUploadDialog.show();
            },

            /**
             * 帳票出力ダイアログを表示する。
             */
            showEmployeeOutputReportDialog: function() {
                var dialog = this.convoEmployeeOutputReportDialog;
                var page = dialog.getChildren()[0];
                var value = this.form.get('value');
                page.initDialog(value);
                dialog.show();
            },

            /**
             * ウィンドウの幅に合わせてBorderContainerをリサイズし、タブ内のレイアウトを整理する。
             * (startup同様、画面描画時に呼ばれる)
             * @param changeSize
             * @param resultSize
             */
            resize: function(changeSize, resultSize) {
                // 中のContentPaneも追随してリサイズするため、レイアウト崩れを防止できる。
                this.borderContainer.resize(changeSize, resultSize);
            }
        });
    });
