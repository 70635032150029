/**
 * クロノロジ詳細画面用モジュール。
 * @module app/chronology/ChronologyDetailPage
 */
define([
    'module',
    'dojo',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom',
    'dojo/dom-style',
    'dojo/json',
    'dojo/_base/array',
    'dojo/date/locale',
    'dojo/topic',
    'dojo/text!./templates/ChronologyDetailPage.html',
    'dojo/promise/all',
    'dstore/Memory',
    'dstore/Trackable',
    'dojox/lang/functional/array',
    'idis/view/page/_PageBase',
    'idis/map/IdisMap',
    'leaflet',
    '../config',
    'esri-leaflet-geocoder',
    'idis/consts/ACL',
    'idis/model/UserInfo',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/IdisDialog',
    'idis/view/dialog/InfoDialog',
    'app/map/baselayer/BaseLayerPane',
    'dojo/on',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/consts/USER_TYPE',
    'idis/service/Requester',
    'idis/view/Loader',
    './_ChronologyPageBase',
    './ChronologyFormatter',
    './ChronologyLinks',
    './ChronologyAction',
    './ChronologyUtil',
    'idis/util/DateUtils',
    './consts/ChronologyType',
    // 以下、変数で受けないモジュール
    'dijit/layout/TabContainer',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/AclButton',
    'app/view/form/ShareOrganizationMultiSelector',
    'app/view/form/MunicipalitySelector',
    'app/view/form/MunicipalitySelector',
    'app/view/form/ShareOrgsMultiSelector4Chronology',
    'app/view/form/MunicOrganizationSelectorWithSearch',
    './ChronologyActionGrid',
    './ChronologyActionForm',
    './ChronologyAction'
], function(module, dojo, declare, lang, dom, domStyle, json, array, locale, topic, template, all,
    Memory, Trackable, df, _PageBase, IdisMap, leaflet, config, geocoder, ACL,
    UserInfo, DialogChain, IdisDialog, InfoDialog, BaseLayerPane, on, Locator, Router, USER_TYPE,
    Requester, Loader, _ChronologyPageBase, ChronologyFormatter, ChronologyLinks, ChronologyAction,
    ChronologyUtil, DateUtils, ChronologyType) {
    /**
     * ロノロジ詳細画面
     * @class ChronologyDetailPage
     * @extends module:app/chronology/_ChronologyPageBase~_ChronologyPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ChronologyPageBase,
    /** @lends module:app/chronology/ChronologyDetailPage~ChronologyDetailPage# */ {


        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chronology',

        // テンプレート文字列
        templateString: template,

        /**
         * クロノロジID
         * @private
         */
        _chronologyId: null,

        /**
         * クロノロジ種別
         * @private
         */
        _chronologyType: null,

        // 対応情報のストア
        actionStore: null,

        _actualFlg: null, //本登録フラグ

        EXTERNAL_TYPE_LIST: ['01', '02', '03'],

        _organizationCd: null,

        /**
         * constructor
         */
        constructor: function() {
            console.debug('constructor');

            // URLからクロノロジIDを取得
            this._chronologyId = Locator.getQuery().chronologyId;

            // URLから災害シミュレーション用のパラメータを取得
            this._simulateFlg = Locator.getQuery().simulateFlg;
            this._scenarioId = Locator.getQuery().scenarioId;
            this._eventId = Locator.getQuery().eventId;
        },

        buildRendering: function(){
            this.inherited(arguments);
            this.own(this.linkInsertDialog);
            // 対応履歴の新規登録
            this.own(topic.subscribe('app/chronology/ChronologyActionForm::initActionGrid',
                lang.hitch(this, function(payload){
                    var action = this.updateActionGrid(payload);
                    this.actionStore.add(action);
                    //グリッド更新後、位置を最下段にうつす
                    this.grid.scrollTo({ y: this.grid.bodyNode.scrollHeight });
            })));
            // 対応履歴の更新
            this.own(topic.subscribe('app/chronology/ChronologyActionForm::update',
                lang.hitch(this, function(payload){
                    var action = this.updateActionGrid(payload);
                    this.actionStore.put(action);
                    this.actionForm.clearActionForm();
            })));
        },

        updateActionGrid: function(item) {
            var action = new ChronologyAction({
                chronologyActionId: item.chronologyActionId,
                parentChronologyActionId: item.parentChronologyActionId,
                content: item.content,
                hldStatus: item.hldStatus,
                crtUserId: item.crtUserId,
                //報告日時はひとまずレコードの更新日で設定
                crtTimestamp: DateUtils.format(item.crtTimestamp),
                updTimestamp: DateUtils.format(item.updTimestamp),
                organizationName: item.organizationName,
                name: item.name,
                attachFiles: item.attachFiles,
                chronologyActionType: item.chronologyActionType
            });
            return action;
        },

        /**
         * startup
         */
        startup: function() {
            console.debug('startup');
            this.inherited(arguments);

            // 宛先ラベルにhintをつける
            //this.setShareTips();

            // クロノロジ詳細画面を初期化
            this.initDetailPage();

            if(this._simulateFlg === 'true'){
                // 「対応履歴」タブは閲覧不可
                this.tabContainer.removeChild(this.tab2);
            } else {
                this.initChronologyActionGrid();
                //対応履歴フォームのイベントリスナーをセット
                this.initChronologyActionForm();
            }
        },

        setChronologyTypeOptions: function(typeNums) {
            var chronologyTypeList = [
              [{value:'01',label:'防災気象情報'},
               {value:'02',label:'国民保護情報'},
               {value:'03',label:'観測情報'}],
               [{value:'30',label:'要請・措置'},
            //    {value:'31',label:'組織内情報'},
               {value:'34',label:'大阪府連携'}]];
            var list = [];
            array.forEach(typeNums, function(typeNum){
                list = list.concat(chronologyTypeList[typeNum]);
            });
            this.chronologyType.set('options', list);
        },

        /**
         * postCreate
         */
        postCreate: function() {
            console.debug('postCreate');
            this.inherited(arguments);
        },

        /**
         * クロノロジ詳細画面を初期化する
         */
        initDetailPage: function() {
            this.actionStore = Trackable.create(new Memory({
                idProperty: 'chronologyActionId'
            }));
            var promise = null;
            if(this._simulateFlg === 'true'){
                promise = Requester.get('/data/simulation/' + this._eventId + '.json')
                    .then(lang.hitch(this, function(data) {
                        this.initValue(data.chronologyPostForm);
                }), lang.hitch(this, function(error) {
                    console.log(error);
                    this.chain.info('イベント情報の取得に失敗しました。', 'エラー');
                }));
                //ローダーの表示
                Loader.wait(promise);
            } else {
                promise = Requester.get('/api/chronologies/' + this._chronologyId, {
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                }).then(lang.hitch(this, function(data) {
                    if (data === null) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : 'リクエストされた情報は権限がないため閲覧できません',
                            onOK : lang.hitch(this, function() {
                                this.infoDialog.hide();
                                Router.moveTo('chronology');
                            })
                        });
                        this.infoDialog.show();
                        return false;
                    } else {
                        this.initValue(data);
                    }
                }), lang.hitch(this, function(error) {
                    console.log(error);
                    this.chain.info('情報の取得に失敗しました。', 'エラー');
                }));
                //ローダーの表示
                Loader.wait(promise);
            }
        },

        /**
         * クロノロジ詳細画面に値を設置する
         */
         initValue: function(data){
            //初期化
            this.pointLat = '';
            this.pointLng = '';
            this.marker = '';
            this.attachFileList = [];
            var chronologyShares = data.chronologyShares;
            var municipalities = [];
            delete data.chronologyShares; //this.chronologySharesと反応するためここで取り除く

            var sender = array.filter(chronologyShares, function(share){
                return share.senderFlg === '1';
            })[0];
            // municipalities.push(sender.municipalityCd);
            var dests = array.filter(chronologyShares, function(share){
                return share.destinationFlg === '1';
            });
            var chronologyShareOrgList = [];
            var chronologyShareOrgList4Selector = [];
            array.forEach(dests, function(dest){
                municipalities.push(dest.municipalityCd);
                // this.shareMunicipality.set('value', dest.municipalityCd);
                if(dest.organizationCd){
                    chronologyShareOrgList.push(dest.organizationCd);

                    var cd4selector = dest.organizationCd.substr(0,1) + dest.organizationCd;
                    chronologyShareOrgList4Selector.push(cd4selector);
                }

            });
            if(sender === undefined) {
                sender = {unitCd:null,
                sectCd:null,
                deptCd:null,
                municipalityCd:null,
                shareName:'外部システム'};
            }
            // 配信元
            var organizationCd = sender.unitCd ? 'U' + sender.unitCd :
			sender.sectCd ? 'S' + sender.sectCd : sender.deptCd ? 'D' + sender.deptCd : null;
            this.senderOrganization.onMunicipalityChange('271004');
            this.senderOrganization.set('value', organizationCd);

            // 氏名
            this.senderPersonalName.set('value', data.senderPersonalName);

            // 深刻度
            this.urgencyType.set('value', data.urgencyType);

            // 登録ラベルの設定
            if(data.actualFlg && data.actualFlg === '1'){
                this._actualFlg = '1';
                this.registeringStatus.innerHTML = '本登録';
            } else {
                this._actualFlg = '0';
                this.registeringStatus.innerHTML = '本登録待ち';
            }

            if(data.actualFlg && data.actualFlg === '1'){
                domStyle.set(this.registerButton.domNode, 'display', 'none');
            } else {
                domStyle.set(this.registerButton.domNode, 'display', '');
            }

            // 添付ファイルを設定
            if(data.attachFiles) {
                for(var i=0; i<data.attachFiles.length; i++) {
                    this.attachFileList.push(data.attachFiles[i]);
                    this.showPreview(data.attachFiles[i], false);
                }
            }

            this._chronologyType = data.chronologyType;
            // クロノロジ種別ごとの制御
            this.setChronologyTypeOptions([1]);
            this.chronologyType.set('disabled', true);

            // this.chronologyType.set('value', data.chronologyType);
            var category;
            if (data.chronologyType === ChronologyType.DISASTER_HLD.id){
                category = 'DISASTER_HLD';
            } else if (data.chronologyType === ChronologyType.PREFECTURE_SHARE.id){
                category = 'PREFECTURE_SHARE';
            }
            this.chronologyTypeLabel.innerHTML = ChronologyType[category].name;
            domStyle.set(this.chronologyType.domNode, 'display', 'none');

            // 情報区分に合わせて、配信元・宛先・情報種別の表示内容設定
            if (data.chronologyType === ChronologyType.DISASTER_HLD.id){
                // 配信元セット
                domStyle.set(this.senderSelectAreaForRequest, 'display', '');
                domStyle.set(this.senderSelectAreaForPrefectureCooperation, 'display', 'none');
                // 情報区分セット
                domStyle.set(this.chronologyCategoryForRequestArea, 'display', '');
                domStyle.set(this.chronologyCategoryForPrefectureCooperationArea, 'display', 'none');
                this.chronologyCategoryForRequest.set('value', data.chronologyCategory);
                // 宛先セット
                domStyle.set(this.shareSelectAreaForRequest, 'display', '');
                domStyle.set(this.shareSelectAreaForPrefectureCooperation, 'display', 'none');
            } else if (data.chronologyType === ChronologyType.PREFECTURE_SHARE.id){
                // 配信元セット
                domStyle.set(this.senderSelectAreaForRequest, 'display', 'none');
                domStyle.set(this.senderSelectAreaForPrefectureCooperation, 'display', '');
                // 情報区分セット
                domStyle.set(this.chronologyCategoryForRequestArea, 'display', 'none');
                domStyle.set(this.chronologyCategoryForPrefectureCooperationArea, 'display', '');
                this.chronologyCategoryForPrefectureCooperation.set('value', data.chronologyCategory);
                // 宛先セット
                domStyle.set(this.shareSelectAreaForRequest, 'display', 'none');
                domStyle.set(this.shareSelectAreaForPrefectureCooperation, 'display', '');
            }
            // 配信元変更制御
            if (UserInfo.getRoleCd() === 'R01001' || UserInfo.getRoleCd() === 'R01011' || 
                UserInfo.getRoleCd() === 'R01031'){
                this.senderOrganization.set('noAllButton', false);
            } else {
                this.senderOrganization.set('noAllButton', true);
            }

            //  宛先設定
            // 宛先を登録する際ツリーの中身が必要なので、セットしておく
            // 要請時宛先を大阪市の下の組織が選択できるかつ災害対策本部配下のみに設定
            this.shareOrganization.onModelSettingChange('271004', true);
            // 要請時宛先を大阪市の下の組織に設定
            // this.shareOrganization.onMunicipalityChange(UserInfo.getMunicipalityCd());
           
            this.shareOrganization._setValueAttr(chronologyShareOrgList4Selector);

            this._chronologyShares = chronologyShares;
            this._municipalityAuthorizeFlg = data.municipalityAuthorizeFlg;
            this._organizationAuthorizeFlg = data.organizationAuthorizeFlg;

            this._editableShare = (data.chronologyType === ChronologyType.DISASTER_HLD.id) ?
                                    true : false;


            this.chronologyType.set('value', data.chronologyType);

            // 対応状況をcrtTimestampでソートしてからメモリーストアにセットする
            if(data.chronologyActions){
              data.chronologyActions.sort(function(a, b) {
                  if (a.crtTimestamp < b.crtTimestamp) {
                      return -1;
                  }
                  if (a.crtTimestamp > b.crtTimestamp) {
                      return 1;
                  }
                  return 0;
              }).forEach(function(object) {
                  // 更新の場合は削除されているものも送る
                  this.actionStore.add(object);
              }, this);
            }
            // 地図を初期化
            // クロノロジの緯度経度情報 > ユーザの所属市町座標 > 県の座標、の順に評価する
            if(data.sourceLatitude && data.sourceLongitude) {

                this.initMap(data.sourceLatitude, data.sourceLongitude);

                this.pointLat = data.sourceLatitude;
                this.pointLng = data.sourceLongitude;
                this._sourceAddressLat = data.sourceLatitude;
                this._sourceAddressLng = data.sourceLongitude;
                this.addMark(data.sourceLatitude, data.sourceLongitude);
                this.map.setView([data.sourceLatitude, data.sourceLongitude], 14);
            } else {
                if(UserInfo.getMunicipalityCd()) {
                    Requester.get('/api/municipalities/' + UserInfo.getMunicipalityCd(), {
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function(item) {
                        // 地図を初期化
                        this.initMap(item.latitude, item.longitude);

                    }), lang.hitch(this, function(error) {
                        console.log(error);
                        this.initMap(config.map.latitude, config.map.longitude);
                        this.chain.info('情報の取得に失敗しました。', 'エラー');
                    }));
                } else {
                    // 地図を初期化
                    this.initMap(config.map.latitude, config.map.longitude);
                }
            }
            this._disasterId = data.disasterId;
            this._chronologyType = data.chronologyType;
            // hldOrganizationをform.setの対象に含めるとエラーが出るため、このタイミングで取り除く
            delete data.chronologyShares;

            
            this.form.set('value', data);

        },

        /**
         * 対応状況グリッドを初期化する。
         * startup時、続報セレクタ選択時、対応状況の登録更新削除時
         */
        initChronologyActionGrid: function() {
            console.debug('initChronologyActionGrid');
            // 削除フラグがたっているものはグリッドに表示しない
            var filter = new this.actionStore.Filter();
            // var filter = new this.actionStore();
            var delFlgFilter = filter.ne('delFlg', '1');
            this.grid.set('collection', this.actionStore.filter(delFlgFilter));

            this.grid.on('updateActionButtonClick', lang.hitch(this, function(evt) {
                //[編集中]メッセージの付け替え
                if(this.actionForm.selectedAction && this.actionForm.selectedAction.editingMsg){
                    domStyle.set(this.actionForm.selectedAction.editingMsg, 'display', 'none');
                }
                this.actionForm.selectedAction = evt.selectedAction;
                domStyle.set(this.actionForm.selectedAction.editingMsg, 'display', '');
                this.actionForm.showUpdateActionForm(evt.item);
            }));
            
            this.grid.on('addRequestButtonClick', lang.hitch(this, function(evt) {
                //[編集中]メッセージを消す
                if(this.actionForm.selectedAction && this.actionForm.selectedAction.editingMsg){
                    domStyle.set(this.actionForm.selectedAction.editingMsg, 'display', 'none');
                }
                this.actionForm.selectedAction = evt.selectedAction;
                this.actionForm.showAddRequestActionForm(evt.item);
            }));
        },

        /**
         * 対応履歴編集フォームを初期化する
         */
        initChronologyActionForm: function() {
          // 編集フォームのregisterイベントを受け取る
          var self = this;
          this.actionForm.on('register', lang.hitch(this, function(evt) {
              // idが空文字の場合はフォームから削除する
              if (evt.value.id === '') {
                  delete evt.value.id;
              }
              // chronologyActionIdが空文字の場合はフォームから削除する
              if (evt.value.chronologyActionId === '') {
                  delete evt.value.chronologyActionId;
              }
              evt.value.updTimestamp = new Date().getTime();

              // ストアにオブジェクトを追加
              this.actionStore.add(evt.value).then(function() {
                  self.actionForm.clearActionForm();
              });
              this.initChronologyActionGrid();
          }));

          // 編集フォームのupdateイベントを受け取る
          this.actionForm.on('update', lang.hitch(this, function(evt) {
              // chronologyActionIdが空文字の場合はフォームから削除する
              if (evt.value.chronologyActionId === '') {
                  delete evt.value.chronologyActionId;
              }
              // ストアのオブジェクトを更新
              this.actionStore.put(evt.value).then(function() {
                  self.actionForm.clearActionForm();
              });
              evt.value.updTimestamp = new Date().getTime();

              this.initChronologyActionGrid();
          }));

          // 編集フォームのdeleteイベントを受け取る
          this.actionForm.on('delete', lang.hitch(this, function(evt) {
              // chronologyActionIdが空文字ではないとき（サーバーに登録されている対応状況の削除）
              if (evt.value.chronologyActionId !== '') {
                  // 削除フラグをたてる
                  evt.value.delFlg = '1';
                  evt.value.updTimestamp = new Date().getTime();

                  // ストアのオブジェクトを更新
                  this.actionStore.put(evt.value).then(function() {
                      self.actionForm.clearActionForm();
                  });
                  var y1 = this.grid.bodyNode.scrollTop;
                  this.initChronologyActionGrid();
                  this.grid.scrollTo({ y: y1 });
              // chronologyActionIdが空文字なとき（まだサーバーに登録していない対応状況の削除）
              } else {
                  // ストアのオブジェクトを削除
                  this.actionStore.remove(evt.value.id).then(function() {
                      self.actionForm.clearActionForm();
                  });
                  var y2 = this.grid.bodyNode.scrollTop;
                  this.initChronologyActionGrid();
                  this.grid.scrollTo({ y: y2 });
              }
          }));

        },

        showTab1: function(){
            // 地図が見切れてしまうので、リサイズする
            this.tab1.resize();
            setTimeout(lang.hitch(this, function(){this.map.invalidateSize();}), 200);
        },

        registerActualChronology: function(){ // 本登録
            var sendData = this.form.get('value');
            sendData.actualFlg = '1';
            this.update(sendData, true);
        },

        /**
         * クロノロジを更新する。
         */
        updateChronology: function() {
            var sendData = this.form.get('value');
            sendData.actualFlg = this._actualFlg;

            this.update(sendData, false);
        },

        update: function(sendData, isActualRegistering){
            sendData.chronologyType = this._chronologyType;
            // 宛先の入力チェック
            var errMsg = ( sendData.chronologyType === ChronologyType.DISASTER_HLD.id && 
                sendData.shareOrganization.length === 0) ? '宛先が選択されていません' : null;
            if (!this.infoDialog && errMsg !== null) {
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : errMsg
                });

                this.infoDialog.show();
                this.infoDialog=null;
                return false;
            }

            delete sendData.chronologyShares;

            var chronologyCategoryCd = '';
            // 情報種別コードを情報種別に合わせて代入
            if(sendData.chronologyType === ChronologyType.DISASTER_HLD.id){
                chronologyCategoryCd =  String(this.chronologyCategoryForRequest.get('value'));
            } else if(sendData.chronologyType === ChronologyType.PREFECTURE_SHARE.id){
                chronologyCategoryCd =  String(this.chronologyCategoryForPrefectureCooperation.get('value'));
            }
            sendData.chronologyCategory = chronologyCategoryCd;

            // 大阪府連携の時、配信元・宛先は変更不可
            if(this._editableShare){
                var chronologyShareOrgs = [];
                var senderOrgCd = null;
                var shareOrgCds = [];

                shareOrgCds = this.shareOrganization.get('value');
                var checkCompletedMap = this.shareOrganization.tree._checkCompletedMap;
                Object.keys(checkCompletedMap).forEach((key) => {
                    if (key.slice(0,2) === 'DD') {
                        // 親を選択した場合、宛先に親の組織を追加し子の組織を消す
                        var children = this.shareOrganization.model.childrenCache[key];
                        if (children){ 
                            Object.keys(children).forEach(childKey =>
                                shareOrgCds = shareOrgCds.filter((shareOrgCd)=>{
                                    return shareOrgCd !== children[childKey].id;
                                })
                            );
                        }
                        // 宛先ダイアログを開かずに訂正すると初めから親要素のみshareOrgCdsに入るため重複を避ける
                        if (!shareOrgCds.includes(key)){
                            shareOrgCds.push(key);
                        }
                    }
                });
                senderOrgCd = this.senderOrganization.get('value');
                shareOrgCds.forEach(() =>{
                    chronologyShareOrgs.push({id: UserInfo.getMunicipalityCd()});
                });
                // 共有先情報を設定する。
                var chronologyShares = [];

                // 送信元をセットする。
                var senderMunicCd = UserInfo.getMunicipalityCd();
                // 先頭に配信元を追加
                chronologyShareOrgs.unshift({id: senderMunicCd});

                var typeSetting = ChronologyUtil.getSettingFromCode(sendData.chronologyType);
                sendData.municipalityAuthorizeFlg = typeSetting.municipalityAuthorizeFlg;
                sendData.organizationAuthorizeFlg = typeSetting.organizationAuthorizeFlg;

                all(
                    array.map(chronologyShareOrgs, function(org){
                        return this.getMunicipalityInfo(org.id);
                    }, this)
                ).then(lang.hitch(this, function(municInfos){
                    sendData = this.setChronologyShares(
                        sendData, typeSetting, municInfos, senderOrgCd, shareOrgCds, chronologyShares);

                    this.sendRequest(sendData, isActualRegistering);
                }), lang.hitch(this, function(error){
                    console.error(error);
                    this.chain.infoError(error);
                }));

            }else{
                // 共有先を更新不可能な場合
                // FIXME: 各情報との疎通ができたら
                sendData.chronologyShares = this._chronologyShares;
                sendData.municipalityAuthorizeFlg = this._municipalityAuthorizeFlg;
                sendData.organizationAuthorizeFlg = this._organizationAuthorizeFlg;
                this.sendRequest(sendData, isActualRegistering);
            }
        },

        sendRequest: function(sendData, isActualRegistering){
            delete sendData.shareMunicipality;
            sendData.disasterId = this._disasterId;
            sendData.chronologyId = this._chronologyId;
            if (!sendData.chronologyType){
                sendData.chronologyType = this._chronologyType;
            }
            sendData.sourceAddress02 = '';

            if(sendData.sourceAddress01) {
               sendData.sourceLatitude = this._sourceAddressLat;
               sendData.sourceLongitude = this._sourceAddressLng;
            } else {
               sendData.sourceLatitude = '';
               sendData.sourceLongitude = '';
            }

            // 添付ファイルIDをセット
            if(this.attachFileList.length > 0) {
               var attachFileIds = [];
               for(var i=0; i<this.attachFileList.length; i++) {
                   attachFileIds.push(this.attachFileList[i].chronologyAtcFileId);
               }
               sendData.attachFileIds = attachFileIds.join(',');
            } else {
               sendData.attachFileIds = '';
            }

            sendData.allowPublishingFlg = (sendData.allowPublishing[0] === 'on') ? '1' : '0';

            delete sendData.allowPublishing;
            delete sendData.deptCd;
            delete sendData.attachFile;
            delete sendData.senderOrganization;
            delete sendData.organizationName;
            delete sendData.organization;
            delete sendData.shareOrganization;

            // フォームのバリデーションを行う（共通部品）
            if(!this.form.validate()) {
               return false;
            }

            var jsonStr = null;
            var message = '';

            //更新確認ダイアログを表示
            if(this._simulateFlg === 'true'){
                delete sendData.version;
                var subSendData = {};
                subSendData.chronologyTypeName = this.chronologyType.domNode.innerText;
                subSendData.hldStatusName = this.hldStatus.domNode.innerText;
                // subSendData.chronologyCategoryName = this.chronologyCategory.domNode.innerText;
                subSendData.urgencyTypeName = this.urgencyType.domNode.innerText;
                var data = {chronologyPostForm: sendData,
                            chronologyConversionForm: subSendData,
                            scenarioId: this._scenarioId,
                            eventId: this._eventId};
                jsonStr = json.stringify(data);
                message = 'イベントを訂正します。<br>よろしいですか。';
                this.chain.confirm(message, function(chain) {
                   var promise = Requester.put('/api/simulations/event/'+ this._eventId, {
                       data: jsonStr,
                       headers: {'Content-Type': 'application/json; charset=utf-8'},
                       handleAs: 'json',
                       preventCache : true
                   }).then(function() {
                       console.debug('success update Chronology event');
                       chain.infoComplete(function() {
                           Router.moveTo('simulation/event', {scenarioId: this._scenarioId});
                       });
                   }, function(error) {
                       console.log(error);
                       chain.infoError(error);
                   });
                   //ローダーの表示
                   Loader.wait(promise);
                });
            } else {
                jsonStr = json.stringify(sendData);
                if(isActualRegistering){
                    message = '仮登録から、本登録に切り替えます。';
                } else if (this._actualFlg === '0'){
                    message = '仮登録のまま訂正します。本登録は行いません。';
                } else {
                    message = '訂正します。';
                }
                message += 'よろしいですか。';
                this.chain.confirm(message, function(chain) {
                   var promise = Requester.put('/api/chronologies/' + this._chronologyId, {
                       data: jsonStr,
                       headers: {'Content-Type': 'application/json; charset=utf-8'},
                       handleAs: 'json',
                       preventCache : true
                   }).then(function() {
                       console.debug('success update Chronology');
                       chain.infoComplete(function() {
                           // 一覧画面に移動
                           Router.moveTo('chronology');
                       });
                   }, function(error) {
                       console.log(error);
                       chain.infoError(error);
                   });
                   //ローダーの表示
                   Loader.wait(promise);
                });
            }
            console.debug('[更新]ボタンがクリックされました。');
        },

        /**
         * クロノロジを削除する。
         */
        deleteChronology: function() {
            var version = this.form.get('value').version;
            //削除確認ダイアログの表示
            this.chain.confirmDel(function(chain) {
                var promise = Requester.del('/api/chronologies/' + this._chronologyId,{
                    query:{
                        version: version
                    }
                })
                .then(function() {
                    chain.infoComplete(function() {
                        console.debug('success delete Chronology');
                        // 一覧画面に移動
                        Router.moveTo('chronology');
                        // location.reload();
                    });
                }, function(error) {
                    console.log(error);
                    chain.infoError(error);
                });
                //ローダーの表示
                Loader.wait(promise);
            });

            console.debug('[削除]ボタンがクリックされました。');
        },

        setChronologyShares: function(sendData, typeSetting, municInfos, senderOrgCd, shareOrgCds, chronologyShares){
            // 宛先組織の数だけループする。大阪府連携の時はこの関数を呼び出さない。
            if(sendData.chronologyType === ChronologyType.DISASTER_HLD.id){
                // 展開しないと更新時子要素を読み込めないため一度展開して閉じる
                this.senderOrganization.tree.expandAll();
                this.senderOrganization.tree.collapseAll();
                this.shareOrganization.tree.expandAll();
                this.shareOrganization.tree.collapseAll();
                array.forEach(municInfos, function(infos, index){
                    // 仮想ルートと全体を宛先から除く
                    if(infos && infos.id !== '$ROOT$' && infos.id !== '0'){
                        var share = {};
                        var shareOrg = {};
                        // 配信元の場合
                        if(index === 0){
                            share.senderFlg = '1';
                            share.destinationFlg = '0';
                            shareOrg = this.setOrganizationFromTreeItem(
                                this.senderOrganization.tree._itemNodesMap, senderOrgCd);
                        } else {
                            share.senderFlg = '0';
                            share.destinationFlg = '1';
                            var shareOrgCd = shareOrgCds[index-1];
                            // TODO 宛先ダイアログの子要素を開いていなくても、
                            // this.shareOrganization.tree._itemNodesMapにおいて仲間で取得できるようにする必要がある。
                            shareOrg = this.setOrganizationFromTreeItem(
                                this.shareOrganization.tree._itemNodesMap, shareOrgCd);
                        }

                        if(!typeSetting.isMunicipalityUnit && shareOrg !== null){
                            share.deptCd = shareOrg.deptCd;
                            share.sectCd = shareOrg.sectCd;
                            share.unitCd = shareOrg.unitCd;
                            share.organizationCd = shareOrg.unitCd ? shareOrg.unitCd :
                                shareOrg.sectCd ? shareOrg.sectCd : shareOrg.deptCd ? shareOrg.deptCd : null;
                        }
                        share.municipalityCd = infos.id;
                        share.regionCd = infos.regionCd ? infos.regionCd : null;
                        share.shareName = typeSetting.isShareNameOrganization ?
                            infos.name : shareOrg !== null ? shareOrg.name : infos.name;
                        chronologyShares.push(share);
                    }
                }, this);
            }

            //  共有先情報をセットする
            sendData.chronologyShares = chronologyShares;
            return sendData;
        },
        
        /**
         * 外部連携時は情報種別欄を非表示にする。
         */
        hideChronologyCategoryArea: function(data){
            if (data.chronologyType === ChronologyType.WEATHER_WARNING.id ||
                data.chronologyType === ChronologyType.CIVIL_PROTECTION.id ||
                data.chronologyType === ChronologyType.OBSERVATORY.id ){
                domStyle.set(this.chronologyCategoryArea, 'display', 'none');
            }
        },

        /**
         * クロノロジ一覧画面へ遷移する。
         */
        onChronologyLinkClick:function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('chronology');
        },

        /**
         * 対象市/行政区を変更した場合、組織変更セレクタを対象市用に作り直す
         */
        onShareMunicipalityCd: function(evt) {
            this.organizationSelector.onMunicipalityChange(evt.value);
            // this.organizationSelector.restoreRefresh();
        }

    });
});
