define([
    'module',
    'dojo',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/dom-construct',
    'idis/view/grid/IdisGrid',
    './DamageReportAction',
    'dojo/on',
    'idis/util/DateUtils',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog'
], function (module, dojo, declare, lang, array, domClass, domStyle, domConstruct, IdisGrid, DamageReportAction,
    on, DateUtils, Menu, MenuItem, popup, TooltipDialog) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--damage',

        keepScrollPosition: true,

        showHeader: false,

        showFooter: false,
        // DOMノードの準備が完了した際に呼ばれる
        postCreate: function () {
            this.inherited(arguments);
            domStyle.set(this.domNode, 'overflow', 'hidden');
        },
        /**
         * 各列の定義
         * @type {Object[]}
         */

        items: null,

        columns: [
            // ボタン
            {
                field: 'damageReporAction',
                label: '',
                sortable: false,
                className: 'actionGridRow',

                renderCell: function (item) {
                    console.log(item);
                    var filePathList = [];
                    array.forEach(item.attachFiles, function (file) {
                        filePathList.push(file.attachFilePath.replace(/^out/, '/data'));
                    }, filePathList);

                    var hldStatusMap = { '0': '確認中', '1': '対応待ち', '2': '対応中', '3': '対応済' };
                    var action = new DamageReportAction({
                        admNum: item.admNum,
                        seqNum: item.seqNum,
                        isEdit: item.edit,
                        damageReportId: item.damageReportId,
                        damageReportActionId: item.damageReportActionId,
                        content: item.content,
                        hldStatus: hldStatusMap[item.hldStatus],
                        crtUserId: item.crtUserId,
                        crtTimestamp: item.crtTimestamp,
                        updTimestamp: item.updTimestamp,
                        actionCrtDateTime: DateUtils.format(item.crtTimestamp),
                        actionUpdDateTime: DateUtils.format(item.updTimestamp),
                        organizationName: item.organizationName,
                        name: item.name ? '（' + item.name + '）' : '',
                        attachFiles: item.attachFiles

                    });

                    action.updateActionButton.on('click', lang.hitch(this, function () {
                        on.emit(this.grid.domNode, 'updateActionButtonClick', { item: item, selectedAction: action });
                    }));

                    // 画像ファイルを表示
                    if (item.attachFiles && item.attachFiles.length > 0) {
                        array.forEach(item.attachFiles, function (attachFile) {
                            var dataUri = attachFile.attachFilePath.replace('out/', 'data/');
                            var fileName = attachFile.attachFileName;

                            //画像ウィジェットの作成
                            var image = new Image();
                            domClass.add(image, 'is-showPreview');

                            // アイコンを定義
                            if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                                fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                                fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                                fileName.indexOf('.gif') !== -1) {
                                image.src = dataUri;
                            } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                                image.src = 'images/excelicon.png';
                            } else if (fileName.indexOf('.pdf') !== -1) {
                                image.src = 'images/pdficon.png';
                            } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                                image.src = 'images/wordicon.png';
                            } else {
                                image.src = 'images/othericon.png';
                            }

                            //メニューの作成
                            var menu = new Menu({
                                targetNodeId: image
                            });
                            menu.set('style', { 'border': 'none', 'box-shadow': 'none' });

                            //ダウンロード操作用のaタグ作成
                            var download = null;
                            var userAgent = window.navigator.userAgent.toLowerCase();
                            if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + dataUri;
                                // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                                // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                                download = domConstruct.create('a', { href: '#' });
                                //クリックでファイル取得処理に入る
                                download.onclick = function () {
                                    var xhr = new XMLHttpRequest();
                                    xhr.open('GET', url, true);
                                    xhr.responseType = 'arraybuffer';
                                    xhr.onload = function () {
                                        var arrayBuffer = this.response;
                                        var blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
                                        // IE10+
                                        if (window.navigator.msSaveOrOpenBlob) {
                                            window.navigator.msSaveOrOpenBlob(blob, name);
                                        }
                                    };
                                    xhr.send();
                                    return false;
                                };
                            } else {
                                // FF, Chromeの場合、download属性でファイルダウンロード
                                download = domConstruct.create('a', {
                                    href: dataUri,
                                    download: fileName
                                });
                            }

                            // ファイル名・境界線・メニューを順にセット
                            var contentNode = domConstruct.create('div');
                            contentNode.innerHTML = fileName;
                            domConstruct.place('<hr color=#b0c4de>', contentNode);
                            domConstruct.place(menu.domNode, contentNode);
                            //ポップアップ用ウィジェット作成
                            var tooltip = new TooltipDialog({
                                content: contentNode
                            });
                            tooltip.containerNode.onmouseleave = function () {
                                popup.close(tooltip);
                            };

                            // 画像ファイルの場合のみ'開く'をメニューに追加する
                            if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                                fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                                fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                                fileName.indexOf('.gif') !== -1) {
                                menu.addChild(new MenuItem({
                                    label: '開く',
                                    iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
                                    onClick: function () {
                                        window.open(dataUri);
                                    }
                                }));
                            }

                            menu.addChild(new MenuItem({
                                label: 'ダウンロード',
                                iconClass: 'dijitIconSave',
                                onClick: function () {
                                    //IE対策
                                    if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                                        download.onclick();
                                    } else {
                                        download.click();
                                    }
                                }
                            }));

                            menu.startup();
                            //メニュー表示処理
                            action.own(on(image, 'mouseover', lang.hitch(this, function () {
                                popup.open({
                                    popup: tooltip,
                                    around: image,
                                    orient: ['above-centered']
                                });
                            })));
                            //画面破棄時に一緒に破棄する
                            action.own(tooltip);

                            // 添付画像をactionに埋め込む
                            domConstruct.place(image, action.attachFile);
                        }, this);
                    }
                    return action.domNode;
                }
            }
        ]

    });
});
