/**
 * 施設情報マスタ 登録ページ
 * @module app/facilityMaster/FacilityMasterDetailPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/on',
    'dojo/json',
    'dojo/dom-style',
    'dojo/text!./templates/FacilityMasterDetailPage.html',
    'idis/control/Locator',
    'idis/view/page/_PageBase',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/service/Requester',
    'idis/control/Router',
    'idis/map/IdisMap',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/service/GeoService',
    'idis/model/UserInfo',
    'leaflet',
    'app/config',
    // 以下、変数から参照されないモジュール
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'dijit/Tooltip',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/RadioGroup',
    'idis/view/form/DateTimeInput',
    'app/view/form/DistrictSelector',
    'app/view/form/OrganizationLevelSelector',
    'app/facilityMaster/FacilityMasterTypeSelector'
], function (module, declare, lang, on, json, domStyle, template, Locator, _PageBase,
    DialogChain, InfoDialog, Requester, Router, IdisMap, IdisRest, Loader, GeoService, UserInfo, leaflet, config) {
    // GeoServiceを初期化
    var _geoService = null;

    /**
     * 施設情報マスタ 詳細画面
     * @class FacilityMasterDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/FacilityMasterDetailPage~FacilityMasterDetailPage# */ {
            // テンプレート文字列
            templateString: template,

            // ストア
            facilityStore: null,
            /**
            * 施設ID
            */
            _facilityId: null,

            constructor: function () {
                // ダイアログ連鎖を登録
                // 引数に与えたウィジェットのthis.ownを呼び出し、
                // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
                this.chain = DialogChain.get(this);

                // データ格納用オブジェクト
                this.facilityStore = new IdisRest({
                    idProperty: 'facilityId',
                    target: '/api/facilityMaster'
                });
                // 施設IDを取得
                this._facilityId = Locator.getQuery().facilityId;
            },

            /**
             * startup
             */
            startup: function () {
                console.log('startup');
                this.inherited(arguments);

                // 地図を初期化
                this.initMap(config.map.latitude, config.map.longitude);
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
                this.initForm();
            },

            /**
            * フォームを初期化する。
            */
            initForm: function () {
                var page = this.form;
                console.debug('facilityId: ' + this._facilityId);
                try {
                    var promise = Requester.get('/api/facilityMaster/' + this._facilityId)
                        .then(
                            lang.hitch(this,
                                function (data) {
                                    var item = data;
                                    this._facilityAdmId = data.facilityAdmId;
                                    page.set('value', item);
                                    this.districtSelector.set('value', item.districtCd);

                                    this.facilityAdmOrgCd.set('value', item.facilityAdmOrgCd);
                                    this.facilityMasterType.set('value', item.facilityMasterType);

                                    this.facilityAdmFlg.set('checked', item.facilityAdmFlg);
                                    this.publicAvailableFlg.set('checked', item.publicAvailableFlg);

                                    if (!UserInfo.getAdminFlg() && ("D" + UserInfo.getDeptCd()) !== item.facilityAdmOrgCd) {
                                        this.submitButton.set('style', { 'display': 'none' });
                                        this.deleteButton.set('style', { 'display': 'none' });
                                    }

                                    //DBに登録されている緯度・経度を元に、地図・マーカーをセット
                                    //(緯度・経度がnullの場合は、住所からマーカーを追加)
                                    if (item.latitude !== '' && item.longitude !== '') {
                                        this.map.setView([item.latitude, item.longitude], 11);
                                        this.addMark(item.latitude, item.longitude);
                                    }
                                }
                            )
                        );
                    Loader.wait(promise);
                } catch (e) {
                    console.error(e);
                }
            },
            /**
             * 地図を初期化する。
             */
            initMap: function (latitude, longitude) {
                var latlng = null;
                if (latitude && longitude) {
                    latlng = [latitude, longitude];
                } else {
                    latlng = [config.map.latitude, config.map.longitude];
                }

                this.map = new IdisMap(this.mapNode, {
                    config: config.map,
                    keyboard: false, // コメント時に+/-が使用できないため
                    touchExtend: false,
                    minZoom: 9,
                    maxZoom: 18,
                    drawControlTooltips: false
                }
                ).setView(latlng, 12);
                // destroy時にmapを破棄するよう設定
                this.own(this.map);
                this.own(
                    on(this.map, 'click',
                        lang.hitch(this, function (e) {
                            // 作図ダイアログが閉じられているとき住所取得モードとする
                            this.pointLat = e.latlng.lat;
                            this.pointLng = e.latlng.lng;
                            this.addMark(this.pointLat, this.pointLng, this);
                        })
                    ));
                this.borderContainer.resize();
            },
            // テンプレートHTML内内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
            // 登録ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
            onSubmit: function () {
                try {

                    var sendData = this.form.get('value');

                    // フォームのバリデーションを実施
                    if (!this.form.validate()) {
                        return false;
                    }
                    if (this.facilityMasterType.get('value') === '') {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '施設種別を選択してください'
                        });
                        this.infoDialog.show();
                        this.infoDialog = null;
                        return false;
                    }
                    if (this.districtSelector.get('value') === '') {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '地区を選択してください'
                        });
                        this.infoDialog.show();
                        this.infoDialog = null;
                        return false;
                    }
                    if (this.facilityAdmOrgCd.get('value') === '') {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '管理組織を選択してください'
                        });
                        this.infoDialog.show();
                        this.infoDialog = null;
                        return false;
                    }
                    sendData.facilityAdmFlg = this.facilityAdmFlg.get('checked');
                    sendData.publicAvailableFlg = this.publicAvailableFlg.get('checked');
                    sendData.facilityId = Locator.getQuery().facilityId;
                    var jsonStr = json.stringify(sendData);
                    console.log('send data : ' + sendData);
                    this.updateFacility(jsonStr);
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
            * 施設情報を更新する。
            */
            updateFacility: function (sendData) {
                var message = '更新します。よろしいですか？';
                var self = this;
                // this.chain.confirmPut(function(chain) {
                this.chain.confirm(message, function (chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(
                        Requester.put('/api/facilityMaster/' + Locator.getQuery().facilityId,
                            {
                                data: sendData,
                                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                                handleAs: 'json',
                                preventCache: true
                            })).then(function (data) {
                                // 成功時（PUT結果はグリッドが自動的に反映）
                                // chain.infoComplete(function() {
                                chain.info('完了しました。', '完了', function () {
                                    // 施設一覧ページに遷移
                                    Router.moveTo('facilityMaster');
                                });
                            }, function (err) {
                                // 失敗時
                                chain.infoError(err);
                            });
                });
            },

            /**
             * 一覧リンク クリック
             */
            onListLinkClick: function (evt) {

                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('facilityMaster');
            },

            /**削除ボタン */
            deleteButtonClick: function () {
                var value = this.form.get('value');
                console.log('[削除]ボタンがクリックされました。');
                var message = '削除します。よろしいですか？';
                this.chain.confirm(message, function (chain) {
                    // 施設の論理削除 追加処理と共にローディング表示
                    Requester.get('/api/facilityMaster/useFacility?facilityId=' + Locator.getQuery().facilityId)
                        .then(lang.hitch(this, function (isDelete) {
                            console.log(isDelete);
                            if (!isDelete) {
                                this.infoDialog = new InfoDialog({
                                    title: 'エラー',
                                    content: '被害で登録されている施設は削除できません。'
                                });
                                this.infoDialog.show();
                                this.infoDialog = null;
                                this.chain.hide();
                                return;
                            }
                            this.facilityStore.remove(
                                Locator.getQuery().facilityId +
                                '?version=' + value.version)
                                .then(function (data) {
                                    // 成功時（DELETE結果はグリッドが自動的に反映）
                                    // 完了ダイアログを画面に表示する
                                    domStyle.set(chain.dialog.domNode, 'display', '');
                                    chain.info('完了しました。', '完了', function () {
                                        // 施設一覧ページに遷移
                                        Router.moveTo('facilityMaster');
                                    });
                                });
                        }), function (err) {
                            // エラーダイアログを画面に表示する
                            domStyle.set(chain.dialog.domNode, 'display', '');
                            console.log(err);
                            // 失敗時
                            chain.infoError(err);
                        });
                });
            },

            /**
             * 地図上でポイントされている位置の住所を設定します。（発生場所）
             */
            onMapToAddress: function () {
                this.mapToAddress(this.address);
            },

            /**
             * 住所の位置を地図上にポイントします。（発生場所）
             */
            onAddressToMap: function () {
                this.addressToMap(this.address);

            },

            /**
             * 地図上でポイントされている位置の住所を設定します。
             */
            mapToAddress: function (addressWidget) {
                if (this.pointLat === '' || this.pointLng === '') {
                    console.debug('not pointed map');
                    this.chain.info('地図が選択されていません。', 'エラー');
                    return;
                }
                console.debug('start reverse geocoding');
                if (_geoService === null) {
                    _geoService = new GeoService({ url: config.geocode && config.geocode.url });
                }
                _geoService.reverseGeocode(leaflet.latLng({
                    lat: this.pointLat,
                    lng: this.pointLng
                })).then(lang.hitch(this, function (res) {
                    this.addMark(this.pointLat, this.pointLng);
                    this.address.set('value', res.address.Address);
                    this.latitude.set('value', this.pointLat);
                    this.longitude.set('value', this.pointLng);

                }), lang.hitch(this, function () {
                    this.chain.info('住所を取得できませんでした。', 'エラー');
                }));

                console.debug('end reverse geocoding (address: ' +
                    addressWidget.value + ')');
            },

            /**
             * 住所の位置を地図上にポイントします。
             */
            addressToMap: function (addressWidget) {
                console.debug('start geocoding (address: ' +
                    addressWidget.value + ')');

                if (!addressWidget.value) {
                    console.log('not input address');
                    this.chain.info('住所を入力してください。', 'エラー');
                    return;
                }
                var address = addressWidget.value;
                if (_geoService === null) {
                    _geoService = new GeoService({ url: config.geocode && config.geocode.url });
                }
                _geoService.geocode(address).then(lang.hitch(this, function (results) {
                    if (results.length > 0) {
                        var latlng = [results[0].latlng.lat, results[0].latlng.lng];
                        this.pointLat = results[0].latlng.lat;
                        this.pointLng = results[0].latlng.lng;
                        this.addMark(this.pointLat, this.pointLng);
                        this.map.setView(latlng, 11);
                        this.latitude.set('value', this.pointLat);
                        this.longitude.set('value', this.pointLng);
                    } else {
                        console.debug('address is not correct');
                        this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                    }
                }));
            },

            /**
            * DBに登録されている住所から、緯度経度を割り出す。
            * （＋DBに緯度経度情報が入力されている場合に、地図上にマーカーを追加し、表示する）
            */
            addressToMapInit: function (itemlat, itemlng) {
                if (!this.address.value) {
                    this.chain.info('住所を入力してください。', 'エラー');
                    return;
                }
                console.debug('start geocoding');
                var address = this.address.value;
                if (_geoService === null) {
                    _geoService = new GeoService({ url: config.geocode && config.geocode.url });
                }
                _geoService.geocode(address).then(lang.hitch(this, function (results) {
                    if (results.length > 0) {
                        var latlng = [results[0].latlng.lat, results[0].latlng.lng];
                        this._addressLat = results[0].latlng.lat;
                        this._addressLng = results[0].latlng.lng;

                        //DBに緯度経度情報が入力されている場合は、DBの情報を元に地図上にマーカーを追加する。
                        //（DBの住所と緯度経度の情報が異なる場合、マーカーは緯度経度情報を元に追加される）
                        if (itemlat !== null && itemlng !== null) {
                            this.pointLat = itemlat;
                            this.pointLng = itemlng;
                            latlng = [itemlat, itemlng];
                            this.map.setView(latlng, 14);
                            this.addMark(this.pointLat, this.pointLng);
                        }
                    } else {
                        console.debug('address is not correct');
                        this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                    }
                }));
            },

            /**
             * マーカーを追加する。サブ地図がある場合は両方の地図でマーカーを共有する
             */
            addMark: function (lat, lng) {
                this.removeMark();
                this.marker = leaflet.marker([lat, lng]).addTo(this.map);
            },

            /**
             * マーカーを削除する。
             */
            removeMark: function () {
                if (this.marker) {
                    this.map.removeLayer(this.marker);
                }
            },
        });
});

