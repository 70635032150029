/**
 * 本部設置情報の更新種別
 * @module app/disasterprevention/view/model/DistributionType
 * [配信種別] '01':新規, '02':取消, '03':取消
 * [ボタン種別] '04':更新, '05':訂正
 * [取消時の配信種別] '99':取消対象外
 */
define({
    REPORT: '01',
    UPDATE: '02',
    CANCEL: '03',
    UPDATE_BTN: '04',
    CORRECT_BTN: '05',
    UPD_CRT_BTN_CANCEL: '06',
    NO_DISTRIBUTE: '99'
});
