/**
 * 人的被害一覧ダイアログ画面用モジュール。
 * @module app/damage/HumanDamageReportDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/topic',
    'dojo/text!./templates/HumanDamageReportInputDialog.html',
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/dialog/DialogChain',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/Select',
    'dijit/form/ValidationTextBox',
    'dijit/form/CheckBox',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/Button'
], function (module, declare, lang, topic, template,
    DisasterInfo, InfoDialog, WidgetBase, DialogChain) {

    /**
     * 人的被害一覧ダイアログ
     * @class HumanDamageReportDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends app/view/Header~Header# */ {

            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'humandamagereportinput-dialog',

            /**
             * コンストラクタ
             */
            constructor: function () {
                //this.inherited(arguments);
                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);
            },

            /**
             * DOM生成
             */
            buildRendering: function () {
                this.inherited(arguments);

            },

            /**
             * 画面生成完了
             */
            startup: function () {
                this.inherited(arguments);
                this.addBtnGrp.style.display = 'block';
                this.updBtnGrp.style.display = 'none';
            },
            /**
             * フォームデータ初期化
             * @param item 
             */
            initFrom: function (item) {

                this.addBtnGrp.style.display = 'block';
                this.updBtnGrp.style.display = 'none';
                if (item) {
                    this.form.set('value', item);
                    this.empInjured.set('checked', item.empInjured === '1' ? true : false);

                    this.addBtnGrp.style.display = 'none';
                    this.updBtnGrp.style.display = 'block';
                }
            },
            /**
             * 登録ボタン実行
             */
            onSubmit: function () {
                let formData = this.form.get('value');
                formData.empInjured = this.empInjured.get('checked') ? '1' : '0';
                this.emit('update', {
                    data: formData
                });
                this.leave();
            },

            onDelete: function () {
                let formData = this.form.get('value');
                console.log(formData);
                formData.empInjured = this.empInjured.get('checked') ? '1' : '0';
                this.emit('remove', {
                    data: formData
                });
                this.leave();
            }

        });
});
