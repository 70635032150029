/**
 * マニュアルダウンロード画面用モジュール。
 * @module app/download/ManualDownloadPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/ManualDownloadPage.html',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button'
], function(module, declare, template, DialogChain, _PageBase) {
    /**
     * マニュアルダウンロード画面
     * @class ManualDownloadPage
     * @extends module:idis/view/download/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    	/** @lends module:app/download/ManualDownloadPage~ManualDownloadPage# */ {

        // テンプレート文字列
        templateString: template,

        // 基本クラス
        baseClass: 'idis-Page idis-Page--link',

        constructor: function () {
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },
        
        _baseUrl: '',
        /**
         * DOMノードを生成する前の処理。
         */
        buildRendering: function() {
            this.inherited(arguments);
            // this._baseUrl = '/api/static/contents/sample/';

        },

        /**
         * アプリケーションのダウンロード
         */
         downloadApp: function() {
            this.chain.confirm('モバイル通信環境の場合通信量を消費するため、<br />Wifi環境に接続された状態でダウンロードすることをお勧めします。<br />ダウンロードを実行しますか？', 
            function (chain) {
                chain.hide();
                var url = '/api/mobile/download';
                // pdfを別タブで開く
                window.open(url, 'Android');
            });
        },


    });
});
