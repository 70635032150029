/**
 * 地区情報 ファイル読み込みダイアログ用モジュール。
 * @module app/damage/EvacOrderDistrictFileUploadDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/request/iframe',
    'dojo/Deferred',
    'dojo/json',
    'dojo/dom-style',
    'dojo/text!./templates/EvacOrderDistrictFileUploadDialog.html',
    'dstore/RequestMemory',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'app/model/DisasterInfo',
    '../config',
    // 以下、変数で受けないモジュール
    'dojox/form/Uploader',
    'dijit/form/Form',
    'idis/view/form/Button',
    'app/view/page/ErrorMessageDialogForXoblos',
    'app/view/form/CustomizableMunicipalitySelector'
], function(module, declare, lang, array, iframe, Deferred, JSON, domStyle, template, RequestMemory,
        Router, Requester, DialogChain, InfoDialog, _PageBase, Loader, DisasterInfo, config) {
    /**
     * 被害状況ファイルアップロードダイアログ
     * @class DamageReportFileUploadDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
                   /** @lends module:app/view/page/EvacOrderDistrictFileUploadDialog# */ {
        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page--safety-dialog',

        _fileId: null,

        infoDialog : null,

        _disasterId: null,

        DISTRICT_REPORT_REQID: 'DISTRICT_LIST_IN',

        constructor: function() {
            // 入力帳票の各種パスを取得する。
            this.storepath = new RequestMemory({
                idProperty: 'reqid',
                target: '/data/report/xoblos-config.json'
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
            // 災害IDを取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
        },

        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.errorMessageDialogForXoblos);
            this.onInOutChange();
        },

        onInOutChange: function() {
            var type = this.inOutSelect.value;
            if(type === '0'){
                domStyle.set(this.reportInBtn, 'display', '');
                domStyle.set(this.reportOutBtn, 'display', 'none');
                domStyle.set(this.fileUploadForm, 'display', '');
            } else {
                domStyle.set(this.reportInBtn, 'display', 'none');
                domStyle.set(this.reportOutBtn, 'display', '');
                domStyle.set(this.fileUploadForm, 'display', 'none');
            }
        },

        /**
         * ファイル読み込み実行
         */
        onSubmit: function() {
            if(this.inOutSelect.value === '0'){
                if(this.validate(true)){
                    //入力
                    this.inputListExcel();
                }
            } else if(this.validate(false)){
                // 出力
                this.outputListExcel();
            }
        },

        /**
         * ファイル入力実行
         */
        inputListExcel: function() {
            var reqid = this.DISTRICT_REPORT_REQID;
            var reqParams = {};
            this.storepath.fetch().then(lang.hitch(this, function(data) {
                data.some(function(data) {
                    if (reqid === data.reqid) {
                        reqParams = data;
                        return true; //break;
                    }
                });
            })).then(lang.hitch(this, function() {
                // ファイルをサーバーにアップロードするAPIのURL
                var url = '/api/xoblos/uploadReport' +
                    '?reqid=' + reqid +
                    '&disid=' + this._disasterId +
                    '&path=' + reqParams.pathweb;

                iframe.post(url, {
                    form: this.form.id,
                    handleAs: 'json'
                }).then(lang.hitch(this, function(ret) {
                    // アップロードしたファイルパスとエラーメッセージjson、
                    // エラー箇所を強調表示したファイルの出力先を指定してxoBlosをコールする。
                    var promise = Requester.post('/api/xoblos/upload',{
                        data: {
                            reqid: reqid,
                            disid: this._disasterId,
                            filename: ret.fileName
                        }
                    }).then(lang.hitch(this, function(data) {
                        // ResultCode=0以外（xoBlosエラー）の場合にはエラーをスローする。
                        // エラー処理の中で、xoBlosエラーダイアログを表示する。
                        if (data.ResultCode !== 0){
                            var err = new Error('xoblos failed');
                            err.data = data;
                            throw err;
                        } else {
                            var regapi = reqParams.regapi;
                            return  Requester.post(regapi, {
                                data:{
                                    // 返ってきたJSONにmunicipalityCdをセット
                                    municipalityCd: this.municipalityCd.get('value'),
                                    // ResultCodeが0の場合は、帳票に含まれる避難所の一覧がレスポンスのdataに入っている。
                                    items: data.data
                                }
                            });
                        }
                    })).then(lang.hitch(this, function(data) {
                        // 成功した場合は完了ダイアログを表示する。
                        if(data.nonExistentDistrict.length === 0 && data.nonAuthification.length === 0){
                            this.chain.infoComplete(function() {
                                // 確認ダイアログを閉じる
                                this.chain.hide();
                                // 親のダイアログを閉じる。
                                this.getParent().hide();
                                this.emit('upload');
                            });
                        } else {
                            var message = '';
                            message += 'ファイル読み込みを完了しました。<br>';
                            message += 'ただし下記は、管理対象の地区情報ではないため、更新しませんでした。<br><br>';
                            message += '<table style="margin-left: auto;margin-right: auto">';
                            message += '<tr><th style="text-align:center"><b>地区情報</b></th><tr>';
                            array.forEach(data.nonExistentDistrict, function(nonExistentDistrict){
                                message += '<tr><td>' + nonExistentDistrict;
                                message += '</td></tr>';
                            });
                            array.forEach(data.nonAuthification, function(nonAuthification){
                                message += '<tr><td>' + nonAuthification;
                                message += '</td></tr>';
                            });
                            message += '</table>';

                            var title = '完了';
                            // ダイアログにメッセージを表示
                            this.chain.info(message, title, function(){
                                // 確認ダイアログを閉じる
                                this.chain.hide();
                                // 親のダイアログを閉じる。
                                this.getParent().hide();
                                this.emit('upload');
                            });
                        }
                    }), lang.hitch(this, function(error) {
                        //this.uploadError(ret, error, reqParams);
                        // 失敗の場合、引数にデータがあれば、xoBlos用のエラーダイアログを表示する。
                        if (error.data) {
                            // エラーダイアログに結果コードを渡す
                            var errPage = this.errorMessageDialogForXoblos.getChildren()[0];
                            errPage.initErrorMessageDialogForXoblos(
                                error.data.ResultCode,
                                reqParams.errpathweb + this._disasterId + '/' + ret.fileName);
                            this.errorMessageDialogForXoblos.show();
                            this.attachFile.reset();
                        } else {
                            // 引数にエラーがなければ、通常のエラーダイアログを表示する。
                            this.chain.infoError(error, function () {
                                this.chain.hide();
                                // 親のダイアログを閉じる。
                                this.getParent().hide();
                                this.attachFile.reset();
                                Router.moveTo('evacorder');
                            });
                        }
                }));
                    // ローダーの表示
                    Loader.wait(promise);
                }));
            }));
        },

        uploadError: function(ret, error, reqParams) {
            // 失敗の場合、引数にデータがあれば、xoBlos用のエラーダイアログを表示する。
            if(error.response.data) {
                // エラーダイアログに結果コードを渡す
                var errPage = this.errorMessageDialogForXoblos.getChildren()[0];
                errPage.initErrorMessageDialogForXoblos(
                    error.response.data.code,
                    reqParams.errpathweb + this.DISTRICT_REPORT_REQID + '/' +
                        this._disasterId + '/' + ret.fileName,
                    ret.fileName);
                this.errorMessageDialogForXoblos.show();
                this.attachFile.reset();
            } else {
                // 引数にエラーがなければ、通常のエラーダイアログを表示する。
                this.chain.infoError(function() {
                    this.chain.hide();
                    // 親のダイアログを閉じる。
                    this.getParent().hide();
                    Router.moveTo('evacorder');
                });
            }
        },

        /**
         * 入力チェック
         */
        validate: function(inputFlg) {
            //市町村が未選択の場合は処理を中断
            if(this.municipalityCd.status.innerText === '未選択') {
                // InfoDialog.show('入力チェック', '市町村が選択されていません');
                // InfoDialog.show('入力チェック', '市/行政区が選択されていません');
                InfoDialog.show('入力チェック', '区が選択されていません');
                return false;
            }
            if(inputFlg === false && this.municipalityCd.status.innerText === config.municInfo.specialCityName){
                InfoDialog.show('入力チェック', config.municInfo.specialCityName + 'は選択できません');
                return false;
            }
            if(this.attachFile.getFileList().length === 0 && inputFlg === true) {
                InfoDialog.show('入力チェック', 'ファイルが選択されていません');
                return false;
            }
            return true;
        },

        /**
         * 報告書読み込みで呼ばれる
         */
        loadFile: function() {
            //ファイルが空の場合は処理を中断
            if(this.attachFile._files.length === 0) {
                return;
            }
            console.debug('report change');

            // エクセルファイル以外の場合はエラー
            var file = this.attachFile._files[0];
            if(file.name.indexOf('.xls') === -1 && file.name.indexOf('.xlsx') === -1 &&
            file.name.indexOf('.xlsm') === -1) {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : 'エクセルファイルを選択してください。'
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                this.attachFile.reset();
                return;
            }

            var form = new FormData();
            form.append('report', this.attachFile._files[0]);

            // ファイル名をyyyymmddhhssに設定。このファイル名はimport用APIをｊコールするときにも使う。
            var now = new Date();
            this._fileId = now.getFullYear() + this.padZero(now.getMonth() + 1) +
            this.padZero(now.getDate()) + this.padZero(now.getHours()) +
            this.padZero(now.getMinutes()) + this.padZero(now.getSeconds());

            // ファイルがonloadされたときにサーバーに一時保存する
            var promise = iframe.post('/api/upload/uploadFile?fileId=' +
            this._fileId , {
                form: this.form.id,
                handleAs: 'json'
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 一覧情報をエクセル形式で出力します。
         */
        onOutputButtonClick: function() {
            this.formDialog.show();
        },

        //帳票出力
        outputListExcel : function() {

            var reqid = 'DISTRICT_LIST_EX';
            var fileName = 'DistrictList.xlsx';
            var paramList = [{key: 'municipalityCd', value: this.municipalityCd.value}];

            var data4xoblosDownload = {
                fileName: fileName,
                reqid: reqid,
                paramList: paramList
            };

            var promise = this.download(data4xoblosDownload).then(lang.hitch(this, function() {
                this.infoDialog = new InfoDialog({
                    title : 'ダウンロード完了',
                    content : 'ダウンロードが完了しました。'
                });
                this.infoDialog.show();

            }), lang.hitch(this, function(error) {
                console.error(error);
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : 'エラーが発生しました。管理者にお問い合わせください。'
                });
                this.infoDialog.show();
            }));

            Loader.wait(promise);
		},

        // 帳票のダウンロード
        download: function(data) {
            var deferred = new Deferred();
            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject('status error:'+ xhr.status);
                    return;
                }
                // ファイル名をレスポンスヘッダーから取り出す
                var contentDisposition = this.getResponseHeader('content-disposition');
                var inputFileName = contentDisposition.replace(/^.*"(.*)"$/, '$1');

                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                var baseFileName = '';
                switch (inputFileName) {
                    case 'DistrictList.xlsx':
                        baseFileName = '地区一覧.xlsx';
                        break;
                }
                var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }

                deferred.resolve();

            };
            xhr.send(JSON.stringify(data));

            return deferred.promise;
        },

        // 日付をゼロパディング
        padZero : function(num){
            var result;
            if (num < 10) {
                result = '0' + num;
            } else {
                result = '' + num;
            }
            return result;
        }
    });
});
