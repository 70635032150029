/**
 * 再配信確認ダイアログ画面用モジュール。
 * @module app/sending/SendingReConfirmDialog
 */
define([
    'module',
    'dojo',
    'dojo/_base/lang',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/dom-style',
    'dojo/text!./templates/SendingReConfirmDialog.html',
    'app/model/Municipality',
    'app/model/Organization',
    'idis/view/page/_PageBase',
    'app/config',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button'
], function(module, dojo, lang, declare, array, domStyle, template,
        Municipality, Organization, WidgetBase, config) {
    /**
     * 配信確認ダイアログ
     * @class SendingConfirmDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends app/view/Header~Header# */ {

        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sending',

        /**
         * コンストラクタ
         */
        constructor: function() {
            this.inherited(arguments);
        },

        /**
         * DOM生成
         */
        buildRendering: function() {
            this.inherited(arguments);
            // グリッドを初期化
            // this.initGrid();
        },

        /**
         * 画面生成完了
         */
        startup: function() {
            this.inherited(arguments);
            this.container.resize();
        },

        _getCurrentOptionsLabel: function(obj) {
            if (!obj.options || !obj.options.length || !obj.options[0].value ||
                    !obj.options[0].label || !obj.value) {
                return '';
            }
            for (var i = 0; i < obj.options.length; i++) {
                if (obj.options[i].value === obj.value) {
                    return obj.options[i].label;
                }
            }
            return '';
        },

        /*
         * 県のコードを変換する。
         *
         * ManageRegionMunicipalitySelectorは県のコードとしてAreaCode.propertiesの
         * PREF_FULL_CODEの値を使用しているが、配信情報が登録される
         * T_SENDING_HEADERテーブルのMUNICIPALITY_CD列はM_MUNICIPALITYテーブルの
         * MUNICIPALITY_CD列との間に参照整合性制約があるため、県は市町村コードでなければ登録できない。
         */
        _convertPrefectureCd: function(municipalityCd) {
            if (municipalityCd === config.municInfo.prefCd) {
                return config.municInfo.prefMunicCd;
            }
            return municipalityCd;
        },

        /**
         * 画面を再表示
         */
        refresh: function(detailPageObj) {
            var tweet = detailPageObj.twitterOuterFrame;
            var emergencyMail = detailPageObj.emergencyMailOuterFrame;

            //チェックされている場合のみ表示
            // 緊急速報メール
            if (detailPageObj.emergencyMail.checked) {
                domStyle.set(this.dispEmergencyMail, 'display', 'block');
                // 配信先
                var emergencyMailMunicipalityCd =
                        this._convertPrefectureCd(detailPageObj.emergencyMailMunicipalityCd.value);
                Municipality.store.get(emergencyMailMunicipalityCd).then(
                    lang.hitch(this, function(item) {
                        var _emergencyMailMunicipalityName = item ? item.name : '取得失敗';
                        this.emergencyMailMunicipalityName.innerHTML = _emergencyMailMunicipalityName;
                    })
                );
                // 件名
                this.emergencyMailSubject.innerHTML = detailPageObj.emergencyMailSubject.value;
                // 本文
                // this.emergencyMailBody.innerHTML = detailPageObj.emergencyMailBody.value;
                var emergencyElement = this.emergencyMailBody;
                while (emergencyElement.firstChild){
                    emergencyElement.removeChild(emergencyElement.firstChild);
                }
                var emergencyElements = emergencyMail.getElementsByTagName('textarea');
                var emergencyDiv = dojo.create('div');
                array.forEach (emergencyElements, function(d){
                    var oneEmergencyMail = dojo.create('div');
                    oneEmergencyMail.innerHTML = d.value;
//                    oneEmergencyMail.style='padding: 10px; margin-bottom: 10px;' +
                    oneEmergencyMail.style=' border: 1px dotted #333333;';
                    oneEmergencyMail.align='left';
                    emergencyDiv.appendChild(oneEmergencyMail);
                });
                this.emergencyMailBody.appendChild(emergencyDiv);
            }

            // Lアラート
            if (detailPageObj.lalert.checked) {
                domStyle.set(this.dispLalert, 'display', 'block');
                // // 発行元
                // Organization.store.get(detailPageObj.publisher.value).then(
                //     lang.hitch(this, function(item) {
                //         var _publisherName = item ? item.name : '取得失敗';
                //         this.publisher.innerHTML = _publisherName;
                //     })
                // );
                // 緊急度
                this.lalertUrgency.innerHTML = this._getCurrentOptionsLabel(detailPageObj.lalertUrgency);
                // カテゴリ
                this.lalertCategory.innerHTML = this._getCurrentOptionsLabel(detailPageObj.lalertCategory);
                // カテゴリ詳細
                this.sendingDetailType.innerHTML = this._getCurrentOptionsLabel(detailPageObj.sendingDetailType);
                // 件名
                this.lalertSubject.innerHTML = detailPageObj.lalertSubject.value;
                // 本文
                this.lalertBody.innerHTML = detailPageObj.lalertInputBodyText.value;

                if (detailPageObj._sendingItem.lalert.length !== 0) {
                    // 新規配信以外の場合のみ
                    // 変更タイプ
                    domStyle.set(this.dispLalertChangeType, 'display', '');
                    var _lalertChangeTypeIndex = parseInt(detailPageObj.lalertChangeType.value, 10);
                    this.lalertChangeType.innerHTML = detailPageObj.lalertChangeType.options[_lalertChangeTypeIndex];

                    if (detailPageObj.lalertChangeType.value === '0') {
                        // 変更タイプが更新の時
                        domStyle.set(this.dispLalertReason, 'display', 'none');
                    } else {
                        // 変更タイプが更新以外の時
                        // 訂正取消理由
                        domStyle.set(this.dispLalertReason, 'display', '');
                        this.lalertReason.innerHTML = detailPageObj.lalertReason.value;

                    }
                }
            }

            // 県防災メール
            if (detailPageObj.prefectureMail.checked) {
                domStyle.set(this.dispPrefectureMail, 'display', 'block');
                // 配信先
                var prefectureMailMunicipalityCd =
                        this._convertPrefectureCd(detailPageObj.prefectureMailMunicipalitySelector.value);
                Municipality.store.get(prefectureMailMunicipalityCd).then(
                    lang.hitch(this, function(item) {
                        var prefectureMailMunicipalityName = item ? item.name : '取得失敗';
                        this.emergencyMailMunicipalityName.innerHTML = prefectureMailMunicipalityName;
                        this.prefectureMailMunicipalityName.innerHTML = prefectureMailMunicipalityName;
                    })
                );
                // 件名
                this.prefectureMailSubject.innerHTML = detailPageObj.prefectureMailSubject.value;
                // 本文
                this.prefectureMailBody.innerHTML = detailPageObj.prefectureMailBody.value;
            }

            // twitter
            if (detailPageObj.twitter.checked) {
                domStyle.set(this.dispTwitter, 'display', 'block');
                var element = this.twitterPreview;
                while (element.firstChild){
                    element.removeChild(element.firstChild);
                }
                var twitterID = detailPageObj.getTwitterIdStr();
                var elements = tweet.getElementsByTagName('textarea');
                var div = dojo.create('div');
                array.forEach (elements, function(d){
                    var oneTweet = dojo.create('div');
                    oneTweet.innerHTML = twitterID + '<br>' + d.value;
                    oneTweet.style='padding: 10px; margin-bottom: 10px;' +
                    ' border: 1px dotted #333333; width:80%';
                    oneTweet.align='left';
                    div.appendChild(oneTweet);
                });
                this.twitterPreview.appendChild(div);
            }

            // yahoo
            if (detailPageObj.yahoo.checked) {
                domStyle.set(this.dispYahoo, 'display', 'block');
                this.yahooBody.innerHTML = detailPageObj.yahooBody.value;
            }

            // 緊急情報配信（防災アプリ）
            if (detailPageObj.emergencyPush.checked) {
                domStyle.set(this.dispEmergencyPush, 'display', 'block');
                // 件名
                this.emergencyPushSubjectPreview.innerHTML = detailPageObj.emergencyPushSubject.value;
                // 本文
                this.emergencyPushBodyPreview.innerHTML = detailPageObj.emergencyPushBody.value;
            }

            this.container.resize();
        },

        /**
         * 配信
         */
        onSubmit: function() {
            this.emit('update');
        },

        /**
         * キャンセルボタン
         */
        onCancelButton: function() {
            // ダイアログを閉じる
            this.emit('cancel');
            this.leave();
        }
    });
});
