/**
 * 職員招集 自動招集条件新規登録画面用モジュール。
 * @module app/convocation/view/condition/ConvoAutoMailRegisterPage
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/text!./templates/ConvoAutoMailRegisterPage.html', // テンプレート文字列
    'idis/store/IdisRest',
    'dojo/dom-style',
    'idis/control/Locator',
    'idis/control/Router',
    'dojo/_base/lang',
    'idis/service/Requester',
    'idis/view/Loader',
    'app/convocation/view/condition/_ConvoAutoMailPageBase',// 基底クラス
    'idis/view/dialog/DialogChain',
     // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/ValidationTextBox',
    'dijit/form/CheckBox',
    'idis/view/form/AclButton',
    'idis/view/form/WordCountTextarea',
    'app/convocation/view/condition/ConvoAddGroupDialog',
    'app/convocation/view/condition/ConvoAutoMailRegisterGrid',
    'app/view/form/MunicipalityMultiSelector',
    'app/view/form/PrefectureSelector',
    'app/view/form/WeatherWarnSelector',
    'app/view/form/RegionMuni4ObservatorySelector',
    'app/view/form/RainObservationSelector',
    'app/view/form/RiverObservationSelector'
], function(module, declare, template, IdisRest, domStyle, Locator,
        Router, lang, Requester, Loader, _ConvoAutoMailPageBase, DialogChain) {
    /**
     * 職員招集 自動招集条件新規登録画面。
     * @class ConvoAutoMailRegisterPage
     * @extends module:app/convocation/view/condition/_ConvoAutoMailPageBase~_ConvoAutoMailPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ConvoAutoMailPageBase,
        /** @lends module:idis/view/page/ConvoAutoMailRegisterPage~ConvoAutoMailRegisterPage# */
        {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            /**
             * 変数の初期化。
             */
            constructor: function() {
                //this.cndId = Locator.getQuery().id;
                // データ格納用オブジェクト
                this.tempStore = new IdisRest({
                    idProperty: 'distributionTmplCd',
                    target: '/api/convoMails/temps'
                });
                this.chain = DialogChain.get(this);
            },

            /**
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function() {
                this.inherited(arguments);
                this.initTempInsertGrid();
            },

            /**
             * 条件選択ボタンが押下された際に条件選択ダイアログを表示する。
             */
            onConditionButton: function() {
                this.conditionSelectDialog.show();
            },

            /**
             * グループ追加ボタンが押下された際にグループ選択ダイアログを表示する。
             */
            addGroupButtonClick: function() {
                this.selectGroupDialog.show();
            },

            /**
             * 登録ボタンが押された際に条件を登録する。
             */
            onSubmit: function() {

                // formの入力チェック
                if (!this.form.validate()) {
                    return false;
                }

                // 入力項目チェック
                if (!this.validate()) {
                    return false;
                }

                // 登録確認ダイアログの表示
                this.chain.confirmAdd(lang.hitch(this, function(chain) {
                    var value = this.form.get('value');
                    value.groupCd = this.getGroupCdList();
                    this.setCheckBoxValue(value);
                    // リクエストの発行からレスポンス受信後の処理までをpromiseに格納
                    var promise = Requester.post('/api/convoMails/conditions', {
                        data: value
                    }).then(function(){
                        // 処理成功時は完了ダイアログを表示
                        chain.infoComplete(function() {
                            Router.moveTo('convocation/automail');
                        });
                    }, function(err) {
                        // 処理失敗時はエラーログを出力し、エラーダイアログを表示
                        console.error(err);
                        chain.infoError(err);
                  });
                  //ローダーの表示
                  Loader.wait(promise);
                }));
            },
            // パンくずリストのボタンを押下したときの挙動
            onConvoAutoMailAdminPageLinkClick : function(evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('convocation/automail');
            },

            /**
    		 * [地域]を変更し、観測所の更新を行う。
    		 */
    		onRegionChange: function(evt){
    			console.debug('[地域]ボックスが変更されました。');
    			this.updateSelectBox(evt);

    		},

    		/**
    		 * [観測局]を変更し、表の更新を行う。
    		 */
    		onObservationStationChange: function(){
    			console.debug('[観測局]ボックスが変更されました。');
    			if(this.changeFlg !== 0){
    				this.onSubmit();
    			}
    			this.changeFlg++;
    		},


            /**
             * テンプレートから挿入ボタンが押下された際の処理。
             */
            onSelectTempButton: function() {
                // テンプレート選択ダイアログの表示
                this.templateInsertDialog.show();
            },

/**
             * テンプレート選択用グリッドの初期化
             */
            initTempInsertGrid: function() {
                var page = this.templateInsertDialog.getChildren()[0];
                page.tempInsertGrid.set('collection', this.tempStore.filter());
                // グリッドの挿入ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'insert'と'ButtonClick'の結合がボタンクリック時のイベント名
                page.tempInsertGrid.on('insertButtonClick', lang.hitch(this, function(evt) {
                    // grid内の件名と本文をformに設定する
                    this.form.set('value', evt.item);
                    this.templateCd = evt.item.distributionTmplCd;
                    this.templateInsertDialog.hide();
                }));
                // グリッドの修正ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'list'と'ButtonClick'の結合がボタンクリック時のイベント名
                page.tempInsertGrid.on('detailButtonClick', lang.hitch(this, function(evt){
                    // テンプレート詳細ダイアログを表示する
                    this.showConvoMailTempDetailDialog(evt.item);
                }));
            },

            /**
             * 詳細ダイアログのformが投稿された際の挙動を設定する。
             */
            initDetailDialog: function() {
                // 詳細ダイアログの最初の子要素が画面の情報
                var dialog = this.convoMailTempDetailDialog;
                var page = dialog.getChildren()[0];

                // 詳細ダイアログからのupdateイベントを取得
                page.on('update', lang.hitch(this, function(evt) {
                    // ダイアログのform情報に当モジュールで保持しているテンプレートコードを追加
                    evt.value.distributionTmplCd = this.distributionTmplCd;
                    // 更新確認ダイアログの表示
                    this.chain.confirmPut(function(chain) {
                        // 更新処理完了まではローディング画面を表示
                        Loader.wait(this.tempStore.put(evt.value)).then(function() {
                            // 更新処理成功時
                            // 詳細ダイアログを閉じる
                            dialog.hide();
                            // 完了ダイアログの表示
                            chain.infoComplete();
                        }, function(error) {
                            // 更新処理失敗時
                            // エラーダイアログの表示
                            chain.infoError(error);
                        });
                    });
                }));

                // 詳細ダイアログからのdeleteイベントを取得
                page.on('delete', lang.hitch(this, function() {
                    // 削除確認ダイアログの表示
                    this.chain.confirmDel(function(chain) {
                        // 削除処理完了まではローディング画面を表示
                        Loader.wait(this.tempStore.remove(this.distributionTmplCd))
                        .then(function() {
                            // 削除処理成功時
                            // 詳細ダイアログを閉じる
                            dialog.hide();
                            // 完了ダイアログの表示
                            chain.infoComplete();
                        }, function(error) {
                            // 削除処理失敗時
                            // エラーダイアログの表示
                            chain.infoError(error);
                        });
                    });
                }));
            }
        });
});
