/**
 * 河川水位画像情報
 * @module app/river/chart/RiverImageInfo
 */
define({
    /**
     * 各画像を水位と突き合わせるための情報。
     * 統合型防災情報システムの画像に設定された
     * 各水位の表示と画像内位置が正確であることを前提とし、
     * 画像の上端・下端の水位を計算し、グラフのY軸設定に利用する。
     * src: 画像パス
     * width: 画像の幅
     * height: 画像の高さ
     * maxMeter: 最高基準水位
     * minMeter: 最低基準水位
     * maxPixel: 最高基準水位の上からのピクセル数（1始まり）
     * minMeter: 最低基準水位の上からのピクセル数（1始まり）
     */
    RIVER_IMAGE_INFO_MAP: {
        // 上道
        '01020301': {
            src: '/images/observation/riverLevelCrossSection/cross_01020301.png',
            width: 400,
            height: 107,
            maxPixel: 19,
            minPixel: 94,
            maxMeter: 5.70,
            minMeter: 0
        },
        // 干田
        '07020101': {
            src: '/images/observation/riverLevelCrossSection/cross_07020101.png',
            width: 400,
            height: 126,
            maxPixel: 61,
            minPixel: 125,
            maxMeter: 3.50,
            minMeter: 0
        },
        // 千町
        '07020201': {
            src: '/images/observation/riverLevelCrossSection/cross_07020201.png',
            width: 400,
            height: 126,
            maxPixel: 61,
            minPixel: 98,
            maxMeter: 2.00,
            minMeter: 0
        },
        // 菅野
        '01020201': {
            src: '/images/observation/riverLevelCrossSection/cross_01020201.png',
            width: 400,
            height: 110,
            maxPixel: 53,
            minPixel: 90,
            maxMeter: 2.00,
            minMeter: 0
        },
        // 笹ヶ瀬
        '04020401': {
            src: '/images/observation/riverLevelCrossSection/cross_04020401.png',
            width: 534,
            height: 150,
            maxPixel: 83,
            minPixel: 132,
            maxMeter: 3.00,
            minMeter: 0
        },
        // 藤田
        '01020401': {
            src: '/images/observation/riverLevelCrossSection/cross_01020401.png',
            width: 400,
            height: 146,
            maxPixel: 27,
            minPixel: 81,
            maxMeter: 2.50,
            minMeter: 0
        },
        // 甫崎
        '04020201': {
            src: '/images/observation/riverLevelCrossSection/cross_04020201.png',
            width: 400,
            height: 146,
            maxPixel: 58,
            minPixel: 127,
            maxMeter: 3.60,
            minMeter: 0
        },
        // 撫川
        '01020501': {
            src: '/images/observation/riverLevelCrossSection/cross_01020501.png',
            width: 400,
            height: 300,
            maxPixel: 151,
            minPixel: 288,
            maxMeter: 4.00,
            minMeter: 0
        },
        // 中川橋
        '04020302': {
            src: '/images/observation/riverLevelCrossSection/cross_04020302.png',
            width: 400,
            height: 108,
            maxPixel: 52,
            minPixel: 107,
            maxMeter: 3.00,
            minMeter: 0
        },
        // 宮瀬橋
        '04020301': {
            src: '/images/observation/riverLevelCrossSection/cross_04020301.png',
            width: 399,
            height: 109,
            maxPixel: 37,
            minPixel: 108,
            maxMeter: 4.10,
            minMeter: 0
        },
        // 彦崎
        '01020801': {
            src: '/images/observation/riverLevelCrossSection/cross_01020801.png',
            width: 399,
            height: 166,
            maxPixel: 85,
            minPixel: 108,
            maxMeter: 3.00,
            minMeter: 0
        },
        // 秀天
        '01020701': {
            src: '/images/observation/riverLevelCrossSection/cross_01020701.png',
            width: 400,
            height: 116,
            maxPixel: 54,
            minPixel: 93,
            maxMeter: 1.80,
            minMeter: 0
        },
        // 福渡
        '04020601': {
            src: '/images/observation/riverLevelCrossSection/cross_04020601.png',
            width: 399,
            height: 262,
            maxPixel: 82,
            minPixel: 238,
            maxMeter: 6.40,
            minMeter: 0
        },
        // 金川
        '01020901': {
            src: '/images/observation/riverLevelCrossSection/cross_01020901.png',
            width: 400,
            height: 182,
            maxPixel: 113,
            minPixel: 181,
            maxMeter: 5.10,
            minMeter: 0
        },
        // 下神目
        '04010401': {
            src: '/images/observation/riverLevelCrossSection/cross_04010401.png',
            width: 400,
            height: 300,
            maxPixel: 217,
            minPixel: 279,
            maxMeter: 2.20,
            minMeter: 0
        },
        // 九谷新田
        '03210401': {
            src: '/images/observation/riverLevelCrossSection/cross_03210401.png',
            width: 400,
            height: 97,
            maxPixel: 37,
            minPixel: 96,
            maxMeter: 3.00,
            minMeter: 0
        },
        // 下加茂
        '04021001': {
            src: '/images/observation/riverLevelCrossSection/cross_04021001.png',
            width: 400,
            height: 107,
            maxPixel: 64,
            minPixel: 93,
            maxMeter: 1.80,
            minMeter: 0
        },
        // 宇甘
        '04020901': {
            src: '/images/observation/riverLevelCrossSection/cross_04020901.png',
            width: 400,
            height: 133,
            maxPixel: 73,
            minPixel: 106,
            maxMeter: 3.60,
            minMeter: 0
        },
        // 佐伯
        '04010101': {
            src: '/images/observation/riverLevelCrossSection/cross_04010101.png',
            width: 400,
            height: 102,
            maxPixel: 48,
            minPixel: 101,
            maxMeter: 8.80,
            minMeter: 0
        },
        // 鴨越
        '03104701': {
            src: '/images/observation/riverLevelCrossSection/cross_03104701.png',
            width: 400,
            height: 82,
            maxPixel: 43,
            minPixel: 66,
            maxMeter: 3.00,
            minMeter: 0
        },
        // 板屋上
        '03104401': {
            src: '/images/observation/riverLevelCrossSection/cross_03104401.png',
            width: 400,
            height: 110,
            maxPixel: 48,
            minPixel: 109,
            maxMeter: 3.20,
            minMeter: 0
        },
        // 吉永中
        '03104601': {
            src: '/images/observation/riverLevelCrossSection/cross_03104601.png',
            width: 400,
            height: 182,
            maxPixel: 84,
            minPixel: 145,
            maxMeter: 3.30,
            minMeter: 0
        },
        // 山田原
        '05010201': {
            src: '/images/observation/riverLevelCrossSection/cross_05010201.png',
            width: 400,
            height: 91,
            maxPixel: 36,
            minPixel: 90,
            maxMeter: 3.00,
            minMeter: 0
        },
        // 芳井
        '04040301': {
            src: '/images/observation/riverLevelCrossSection/cross_04040301.png',
            width: 400,
            height: 72,
            maxPixel: 39,
            minPixel: 71,
            maxMeter: 3.00,
            minMeter: 0
        },
        // 井原
        '04040201': {
            src: '/images/observation/riverLevelCrossSection/cross_04040201.png',
            width: 400,
            height: 100,
            maxPixel: 61,
            minPixel: 99,
            maxMeter: 2.90,
            minMeter: 0
        },
        // 矢掛
        '04040401': {
            src: '/images/observation/riverLevelCrossSection/cross_04040401.png',
            width: 400,
            height: 138,
            maxPixel: 72,
            minPixel: 122,
            maxMeter: 3.20,
            minMeter: 0
        },
        // 金光
        '04040501': {
            src: '/images/observation/riverLevelCrossSection/cross_04040501.png',
            width: 400,
            height: 145,
            maxPixel: 78,
            minPixel: 144,
            maxMeter: 3.50,
            minMeter: 0
        },
        // 富岡
        '04040101': {
            src: '/images/observation/riverLevelCrossSection/cross_04040101.png',
            width: 400,
            height: 141,
            maxPixel: 69,
            minPixel: 140,
            maxMeter: 4.00,
            minMeter: 0
        },
        // 方谷
        '06040201': {
            src: '/images/observation/riverLevelCrossSection/cross_06040201.png',
            width: 400,
            height: 222,
            maxPixel: 103,
            minPixel: 221,
            maxMeter: 5.10,
            minMeter: 0
        },
        // 高梁
        '01050201': {
            src: '/images/observation/riverLevelCrossSection/cross_01050201.png',
            width: 400,
            height: 202,
            maxPixel: 96,
            minPixel: 183,
            maxMeter: 4.80,
            minMeter: 0
        },
        // 広瀬
        '01040301': {
            src: '/images/observation/riverLevelCrossSection/cross_01040301.png',
            width: 500,
            height: 396,
            maxPixel: 245,
            minPixel: 391,
            maxMeter: 6.90,
            minMeter: 0
        },
        // 成羽
        '01040401': {
            src: '/images/observation/riverLevelCrossSection/cross_01040401.png',
            width: 400,
            height: 241,
            maxPixel: 117,
            minPixel: 240,
            maxMeter: 4.40,
            minMeter: 0
        },
        // 佐与谷
        '91010101': {
            src: '/images/observation/riverLevelCrossSection/cross_91010101.png',
            width: 400,
            height: 146,
            maxPixel: 72,
            minPixel: 128,
            maxMeter: 3.00,
            minMeter: 0
        },
        // 朝間
        '03320601': {
            src: '/images/observation/riverLevelCrossSection/cross_03320601.png',
            width: 400,
            height: 146,
            maxPixel: 63,
            minPixel: 136,
            maxMeter: 4.00,
            minMeter: 0
        },
        // 横見堰
        '03320801': {
            src: '/images/observation/riverLevelCrossSection/cross_03320801.png',
            width: 400,
            height: 266,
            maxPixel: 173,
            minPixel: 265,
            maxMeter: 5.00,
            minMeter: 0
        },
        // 昭和橋
        '01050301': {
            src: '/images/observation/riverLevelCrossSection/cross_01050301.png',
            width: 400,
            height: 175,
            maxPixel: 22,
            minPixel: 145,
            maxMeter: 4.00,
            minMeter: 0
        },
        // 正田
        '03320901': {
            src: '/images/observation/riverLevelCrossSection/cross_03320901.png',
            width: 400,
            height: 90,
            maxPixel: 31,
            minPixel: 89,
            maxMeter: 3.20,
            minMeter: 0
        },
        // 長屋
        '06040101': {
            src: '/images/observation/riverLevelCrossSection/cross_06040101.png',
            width: 400,
            height: 128,
            maxPixel: 29,
            minPixel: 111,
            maxMeter: 5.10,
            minMeter: 0
        },
        // 梅田
        '03320501': {
            src: '/images/observation/riverLevelCrossSection/cross_03320501.png',
            width: 400,
            height: 54,
            maxPixel: 16,
            minPixel: 53,
            maxMeter: 2.00,
            minMeter: 0
        },
        // 足立
        '03320401': {
            src: '/images/observation/riverLevelCrossSection/cross_03320401.png',
            width: 400,
            height: 218,
            maxPixel: 88,
            minPixel: 198,
            maxMeter: 6.00,
            minMeter: 0
        },
        // 大佐
        '06020101': {
            src: '/images/observation/riverLevelCrossSection/cross_06020101.png',
            width: 400,
            height: 165,
            maxPixel: 73,
            minPixel: 147,
            maxMeter: 4.00,
            minMeter: 0
        },
        // 蚊家
        '07040101': {
            src: '/images/observation/riverLevelCrossSection/cross_07040101.png',
            width: 400,
            height: 91,
            maxPixel: 15,
            minPixel: 72,
            maxMeter: 3.00,
            minMeter: 0
        },
        // 上長田
        '03212501': {
            src: '/images/observation/riverLevelCrossSection/cross_03212501.png',
            width: 400,
            height: 167,
            maxPixel: 12,
            minPixel: 146,
            maxMeter: 7.00,
            minMeter: 0
        },
        // 勝山
        '03212601': {
            src: '/images/observation/riverLevelCrossSection/cross_03212601.png',
            width: 400,
            height: 207,
            maxPixel: 136,
            minPixel: 189,
            maxMeter: 2.80,
            minMeter: 0
        },
        // 落合
        '03212701': {
            src: '/images/observation/riverLevelCrossSection/cross_03212701.png',
            width: 400,
            height: 130,
            maxPixel: 63,
            minPixel: 111,
            maxMeter: 4.70,
            minMeter: 0
        },
        // 江川
        '03020301': {
            src: '/images/observation/riverLevelCrossSection/cross_03020301.png',
            width: 400,
            height: 174,
            maxPixel: 86,
            minPixel: 149,
            maxMeter: 2.50,
            minMeter: 0
        },
        // 月田石原
        '03020401': {
            src: '/images/observation/riverLevelCrossSection/cross_03020401.png',
            width: 400,
            height: 96,
            maxPixel: 39,
            minPixel: 81,
            maxMeter: 2.50,
            minMeter: 0
        },
        // 目木
        '03020101': {
            src: '/images/observation/riverLevelCrossSection/cross_03020101.png',
            width: 400,
            height: 119,
            maxPixel: 58,
            minPixel: 102,
            maxMeter: 2.50,
            minMeter: 0
        },
        // 垂水
        '03020201': {
            src: '/images/observation/riverLevelCrossSection/cross_03020201.png',
            width: 400,
            height: 134,
            maxPixel: 94,
            minPixel: 116,
            maxMeter: 1.20,
            minMeter: 0
        },
        // 奥津
        '05010101': {
            src: '/images/observation/riverLevelCrossSection/cross_05010101.png',
            width: 400,
            height: 184,
            maxPixel: 116,
            minPixel: 173,
            maxMeter: 2.50,
            minMeter: 0
        },
        // 吹屋町
        '05010301': {
            src: '/images/observation/riverLevelCrossSection/cross_05010301.png',
            width: 400,
            height: 100,
            maxPixel: 54,
            minPixel: 88,
            maxMeter: 3.20,
            minMeter: 0
        },
        // 小桁
        '01010401': {
            src: '/images/observation/riverLevelCrossSection/cross_01010401.png',
            width: 400,
            height: 120,
            maxPixel: 41,
            minPixel: 100,
            maxMeter: 6.40,
            minMeter: 0
        },
        // 倉見
        '90030101': {
            src: '/images/observation/riverLevelCrossSection/cross_90030101.png',
            width: 400,
            height: 112,
            maxPixel: 36,
            minPixel: 93,
            maxMeter: 3.00,
            minMeter: 0
        },
        // 越畑
        '90010101': {
            src: '/images/observation/riverLevelCrossSection/cross_90010101.png',
            width: 400,
            height: 126,
            maxPixel: 71,
            minPixel: 125,
            maxMeter: 3.00,
            minMeter: 0
        },
        // 寺和田
        '90010201': {
            src: '/images/observation/riverLevelCrossSection/cross_90010201.png',
            width: 400,
            height: 76,
            maxPixel: 17,
            minPixel: 75,
            maxMeter: 3.00,
            minMeter: 0
        },
        // 加茂
        '01010601': {
            src: '/images/observation/riverLevelCrossSection/cross_01010601.png',
            width: 500,
            height: 252,
            maxPixel: 183,
            minPixel: 238,
            maxMeter: 3.10,
            minMeter: 0
        },
        // 合同堰
        '90040101': {
            src: '/images/observation/riverLevelCrossSection/cross_90040101.png',
            width: 400,
            height: 186,
            maxPixel: 71,
            minPixel: 185,
            maxMeter: 6.00,
            minMeter: 0
        },
        // 日上
        '90040201': {
            src: '/images/observation/riverLevelCrossSection/cross_90040201.png',
            width: 400,
            height: 127,
            maxPixel: 43,
            minPixel: 126,
            maxMeter: 4.40,
            minMeter: 0
        },
        // 東一宮
        '01010701': {
            src: '/images/observation/riverLevelCrossSection/cross_01010701.png',
            width: 400,
            height: 148,
            maxPixel: 85,
            minPixel: 147,
            maxMeter: 3.20,
            minMeter: 0
        },
        // 塚角
        '01010801': {
            src: '/images/observation/riverLevelCrossSection/cross_01010801.png',
            width: 400,
            height: 137,
            maxPixel: 81,
            minPixel: 136,
            maxMeter: 5.90,
            minMeter: 0
        },
        // 大原
        '02010101': {
            src: '/images/observation/riverLevelCrossSection/cross_02010101.png',
            width: 400,
            height: 135,
            maxPixel: 68,
            minPixel: 134,
            maxMeter: 2.50,
            minMeter: 0
        },
        // 林野
        '05010601': {
            src: '/images/observation/riverLevelCrossSection/cross_05010601.png',
            width: 400,
            height: 171,
            maxPixel: 82,
            minPixel: 170,
            maxMeter: 3.60,
            minMeter: 0
        },
        // 梶並
        '90020101': {
            src: '/images/observation/riverLevelCrossSection/cross_90020101.png',
            width: 400,
            height: 139,
            maxPixel: 7,
            minPixel: 129,
            maxMeter: 13.00,
            minMeter: 0
        },
        // 火の神
        '90020201': {
            src: '/images/observation/riverLevelCrossSection/cross_90020201.png',
            width: 400,
            height: 112,
            maxPixel: 49,
            minPixel: 111,
            maxMeter: 3.30,
            minMeter: 0
        },
        // 東吉田
        '01010501': {
            src: '/images/observation/riverLevelCrossSection/cross_01010501.png',
            width: 400,
            height: 110,
            maxPixel: 56,
            minPixel: 91,
            maxMeter: 2.00,
            minMeter: 0
        },
        // 正崎
        '04010701': {
            src: '/images/observation/riverLevelCrossSection/cross_04010701.png',
            width: 400,
            height: 133,
            maxPixel: 65,
            minPixel: 132,
            maxMeter: 3.00,
            minMeter: 0
        },
        // 浅越
        '01022301': {
            src: '/images/observation/riverLevelCrossSection/cross_01022301.png',
            width: 400,
            height: 196,
            maxPixel: 30,
            minPixel: 147,
            maxMeter: 5.00,
            minMeter: 0
        },
        // 田土
        '03321101': {
            src: '/images/observation/riverLevelCrossSection/cross_03321101.png',
            width: 400,
            height: 168,
            maxPixel: 111,
            minPixel: 167,
            maxMeter: 3.00,
            minMeter: 0
        },
        // 一の原橋
        '03210701': {
            src: '/images/observation/riverLevelCrossSection/cross_03210701.png',
            width: 399,
            height: 132,
            maxPixel: 78,
            minPixel: 131,
            maxMeter: 2.00,
            minMeter: 0
        },
        // 首部
        '01021201': {
            src: '/images/observation/riverLevelCrossSection/cross_01021201.png',
            width: 400,
            height: 187,
            maxPixel: 85,
            minPixel: 186,
            maxMeter: 5.10,
            minMeter: 0
        },
        // 今保
        '01021101': {
            src: '/images/observation/riverLevelCrossSection/cross_01021101.png',
            width: 400,
            height: 108,
            maxPixel: 53,
            minPixel: 89,
            maxMeter: 2.00,
            minMeter: 0
        },
        // 川合橋
        '03321301': {
            src: '/images/observation/riverLevelCrossSection/cross_03321301.png',
            width: 400,
            height: 122,
            maxPixel: 73,
            minPixel: 121,
            maxMeter: 2.00,
            minMeter: 0
        },
        // 中央橋
        '03040501': {
            src: '/images/observation/riverLevelCrossSection/cross_03040501.png',
            width: 400,
            height: 168,
            maxPixel: 85,
            minPixel: 167,
            maxMeter: 5.00,
            minMeter: 0
        },
        // 川之瀬
        '03321201': {
            src: '/images/observation/riverLevelCrossSection/cross_03321201.png',
            width: 400,
            height: 96,
            maxPixel: 21,
            minPixel: 83,
            maxMeter: 5.00,
            minMeter: 0
        },
        // 溜川
        '01022401': {
            src: '/images/observation/riverLevelCrossSection/cross_01022401.png',
            width: 400,
            height: 261,
            maxPixel: 76,
            minPixel: 214,
            maxMeter: 12.00,
            minMeter: 0
        },
        // 昭和水門
        '01022402': {
            src: '/images/observation/riverLevelCrossSection/cross_01022402.png',
            width: 400,
            height: 110,
            maxPixel: 36,
            minPixel: 68,
            maxMeter: 4.00,
            minMeter: 0
        },
        // 粒江
        '05020401': {
            src: '/images/observation/riverLevelCrossSection/cross_05020401.png',
            width: 400,
            height: 300,
            maxPixel: 112,
            minPixel: 252,
            maxMeter: 5.00,
            minMeter: 0
        },
        // 阿部
        '02040801': {
            src: '/images/observation/riverLevelCrossSection/cross_02040801.png',
            width: 400,
            height: 300,
            maxPixel: 130,
            minPixel: 253,
            maxMeter: 8.00,
            minMeter: 0
        },
        // 川北
        '02010501': {
            src: '/images/observation/riverLevelCrossSection/cross_02010501.png',
            width: 400,
            height: 300,
            maxPixel: 72,
            minPixel: 269,
            maxMeter: 7.00,
            minMeter: 0
        },
        // 本郷
        '04022101': {
            src: '/images/observation/riverLevelCrossSection/cross_04022101.png',
            width: 400,
            height: 300,
            maxPixel: 79,
            minPixel: 277,
            maxMeter: 5.00,
            minMeter: 0
        },
        // 足守
        '05020101': {
            src: '/images/observation/riverLevelCrossSection/cross_05020101.png',
            width: 400,
            height: 300,
            maxPixel: 117,
            minPixel: 251,
            maxMeter: 4.00,
            minMeter: 0
        },
        // 矢神
        '91020101': {
            src: '/images/observation/riverLevelCrossSection/cross_91020101.png',
            width: 400,
            height: 300,
            maxPixel: 36,
            minPixel: 275,
            maxMeter: 4.00,
            minMeter: 0
        },
        // 南方
        '04010801': {
            src: '/images/observation/riverLevelCrossSection/cross_04010801.png',
            width: 400,
            height: 300,
            maxPixel: 119,
            minPixel: 270,
            maxMeter: 5.00,
            minMeter: 0
        },
        // 一方
        '01011201': {
            src: '/images/observation/riverLevelCrossSection/cross_01011201.png',
            width: 400,
            height: 300,
            maxPixel: 120,
            minPixel: 270,
            maxMeter: 5.00,
            minMeter: 0
        },
        // 上水田
        '03020801': {
            src: '/images/observation/riverLevelCrossSection/cross_03020801.png',
            width: 400,
            height: 300,
            maxPixel: 125,
            minPixel: 279,
            maxMeter: 5.00,
            minMeter: 0
        },
        // 茶屋町
        '05020501': {
            src: '/images/observation/riverLevelCrossSection/cross_05020501.png',
            width: 400,
            height: 300,
            maxPixel: 86,
            minPixel: 240,
            maxMeter: 5.00,
            minMeter: 0
        },
        // 落合垂水
        '01011301': {
            src: '/images/observation/riverLevelCrossSection/cross_01011301.png',
            width: 500,
            height: 287,
            maxPixel: 62,
            minPixel: 250,
            maxMeter: 10.00,
            minMeter: 0
        },
        // 奥
        '02010601': {
            src: '/images/observation/riverLevelCrossSection/cross_02010601.png',
            width: 500,
            height: 321,
            maxPixel: 125,
            minPixel: 306,
            maxMeter: 9.00,
            minMeter: 0
        },
        // 竹田
        '01011401': {
            src: '/images/observation/riverLevelCrossSection/cross_01011401.png',
            width: 500,
            height: 210,
            maxPixel: 135,
            minPixel: 154,
            maxMeter: 3.00,
            minMeter: 0
        },
        // 土居
        '01060101': {
            src: '/images/observation/riverLevelCrossSection/cross_01060101.png',
            width: 500,
            height: 300,
            maxPixel: 160,
            minPixel: 224,
            maxMeter: 6.00,
            minMeter: 0
        },
        // 福中
        '01060201': {
            src: '/images/observation/riverLevelCrossSection/cross_01060201.png',
            width: 500,
            height: 196,
            maxPixel: 120,
            minPixel: 178,
            maxMeter: 3.40,
            minMeter: 0
        },
        // 高屋
        '01060301': {
            src: '/images/observation/riverLevelCrossSection/cross_01060301.png',
            width: 500,
            height: 179,
            maxPixel: 125,
            minPixel: 159,
            maxMeter: 5.00,
            minMeter: 0
        },
        // 新庄
        '01060401': {
            src: '/images/observation/riverLevelCrossSection/cross_01060401.png',
            width: 500,
            height: 241,
            maxPixel: 130,
            minPixel: 164,
            maxMeter: 3.00,
            minMeter: 0
        },
        // 久米南
        '01060501': {
            src: '/images/observation/riverLevelCrossSection/cross_01060501.png',
            width: 500,
            height: 208,
            maxPixel: 125,
            minPixel: 194,
            maxMeter: 7.00,
            minMeter: 0
        },
        // 三石
        '05011702': {
            src: '/images/observation/riverLevelCrossSection/cross_05011702.png',
            width: 500,
            height: 311,
            maxPixel: 145,
            minPixel: 240,
            maxMeter: 2.80,
            minMeter: 0
        },
        // 以下、行政サイトにのみ追加の観測局
        // 津瀬
        '03104501': {
            src: '/images/observation/riverLevelCrossSection/cross_03104501.png',
            width: 400,
            height: 324,
            maxPixel: 1,
            minPixel: 262,
            maxMeter: 14.87,
            minMeter: 0
        },
        // 酒津
        '03321001': {
            src: '/images/observation/riverLevelCrossSection/cross_03321001.png',
            width: 400,
            height: 118,
            maxPixel: 35,
            minPixel: 102,
            maxMeter: 12.00,
            minMeter: 0
        },
        // 周匝
        '99000101': {
            src: '/images/observation/riverLevelCrossSection/cross_99000101.png',
            width: 400,
            height: 295,
            maxPixel: 1,
            minPixel: 255,
            maxMeter: 11.8,
            minMeter: 0
        },
        // 下牧
        '99000201': {
            src: '/images/observation/riverLevelCrossSection/cross_99000201.png',
            width: 400,
            height: 317,
            maxPixel: 1,
            minPixel: 218,
            maxMeter: 9.4,
            minMeter: 0
        },
        // 高野
        '99004002': {
            src: '/images/observation/riverLevelCrossSection/cross_99004002.png',
            width: 400,
            height: 325,
            maxPixel: 1,
            minPixel: 243,
            maxMeter: 7.4,
            minMeter: 0
        },
        // 河田原
        '99004004': {
            src: '/images/observation/riverLevelCrossSection/cross_99004004.png',
            width: 400,
            height: 318,
            maxPixel: 1,
            minPixel: 228,
            maxMeter: 12.63,
            minMeter: 0
        },
        // 御休
        '99004005': {
            src: '/images/observation/riverLevelCrossSection/cross_99004005.png',
            width: 400,
            height: 313,
            maxPixel: 1,
            minPixel: 253,
            maxMeter: 10.9,
            minMeter: 0
        },
        // 津山
        '99004006': {
            src: '/images/observation/riverLevelCrossSection/cross_99004006.png',
            width: 400,
            height: 307,
            maxPixel: 1,
            minPixel: 258,
            maxMeter: 7.9,
            minMeter: 0
        },
        // 湯郷
        '99004009': {
            src: '/images/observation/riverLevelCrossSection/cross_99004009.png',
            width: 400,
            height: 313,
            maxPixel: 1,
            minPixel: 266,
            maxMeter: 16.5,
            minMeter: 0
        },
        // 尺所
        '99004010': {
            src: '/images/observation/riverLevelCrossSection/cross_99004010.png',
            width: 400,
            height: 310,
            maxPixel: 1,
            minPixel: 198,
            maxMeter: 6.05,
            minMeter: 0
        },
        // 九蟠
        '99004011': {
            src: '/images/observation/riverLevelCrossSection/cross_99004011.png',
            width: 400,
            height: 309,
            maxPixel: 1,
            minPixel: 195,
            maxMeter: 5.7,
            minMeter: 0
        },
        // 羽出西谷
        '99004012': {
            src: '/images/observation/riverLevelCrossSection/cross_99004012.png',
            width: 400,
            height: 314,
            maxPixel: 1,
            minPixel: 275,
            maxMeter: 251.5,
            minMeter: 0
        },
        // 杉
        '99004014': {
            src: '/images/observation/riverLevelCrossSection/cross_99004014.png',
            width: 400,
            height: 323,
            maxPixel: 1,
            minPixel: 280,
            maxMeter: 243.3,
            minMeter: 0
        },
        // 原
        '99004015': {
            src: '/images/observation/riverLevelCrossSection/cross_99004015.png',
            width: 400,
            height: 316,
            maxPixel: 1,
            minPixel: 275,
            maxMeter: 128.46,
            minMeter: 0
        },
        // 牧山
        '99004022': {
            src: '/images/observation/riverLevelCrossSection/cross_99004022.png',
            width: 400,
            height: 290,
            maxPixel:12,
            minPixel:2246,
            maxMeter: 12,
            minMeter: 0
        },
        // 三野
        '99004026': {
            src: '/images/observation/riverLevelCrossSection/cross_99004026.png',
            width: 400,
            height: 306,
            maxPixel: 1,
            minPixel: 265,
            maxMeter: 10.6,
            minMeter: 0
        },
        // 金川
        '99004028': {
            src: '/images/observation/riverLevelCrossSection/cross_99004028.png',
            width: 400,
            height: 319,
            maxPixel: 1,
            minPixel: 280,
            maxMeter: 8.4,
            minMeter: 0
        },
        // 瀬戸
        '99004029': {
            src: '/images/observation/riverLevelCrossSection/cross_99004029.png',
            width: 400,
            height: 289,
            maxPixel: 1,
            minPixel: 253,
            maxMeter: 6.7,
            minMeter: 0
        },
        // 沖田
        '99004030': {
            src: '/images/observation/riverLevelCrossSection/cross_99004030.png',
            width: 400,
            height: 289,
            maxPixel: 1,
            minPixel: 152,
            maxMeter: 3.7,
            minMeter: 0
        },
        // 沖元
        '99004031': {
            src: '/images/observation/riverLevelCrossSection/cross_99004031.png',
            width: 400,
            height: 303,
            maxPixel: 1,
            minPixel: 171,
            maxMeter: 4.2,
            minMeter: 0
        },
        // 相生橋
        '99004034': {
            src: '/images/observation/riverLevelCrossSection/cross_99004034.png',
            width: 400,
            height: 302,
            maxPixel: 1,
            minPixel: 169,
            maxMeter: 8.3,
            minMeter: 0
        },
        // 日羽
        '99004041': {
            src: '/images/observation/riverLevelCrossSection/cross_99004041.png',
            width: 400,
            height: 292,
            maxPixel: 1,
            minPixel: 250,
            maxMeter: 13.35,
            minMeter: 0
        },
        // 高梁
        '99004042': {
            src: '/images/observation/riverLevelCrossSection/cross_99004042.png',
            width: 400,
            height: 309,
            maxPixel: 1,
            minPixel: 252,
            maxMeter: 12.4,
            minMeter: 0
        },
        // 井原
        '99004045': {
            src: '/images/observation/riverLevelCrossSection/cross_99004045.png',
            width: 400,
            height: 305,
            maxPixel: 1,
            minPixel: 267,
            maxMeter: 8,
            minMeter: 0
        },
        // 矢掛
        '99004046': {
            src: '/images/observation/riverLevelCrossSection/cross_99004046.png',
            width: 400,
            height: 327,
            maxPixel: 1,
            minPixel: 237,
            maxMeter: 6.52,
            minMeter: 0
        },
        // 東三成
        '99004047': {
            src: '/images/observation/riverLevelCrossSection/cross_99004047.png',
            width: 400,
            height: 320,
            maxPixel: 1,
            minPixel: 270,
            maxMeter: 13.3,
            minMeter: 0
        },
        // 船穂
        '99004050': {
            src: '/images/observation/riverLevelCrossSection/cross_99004050.png',
            width: 400,
            height: 287,
            maxPixel: 1,
            minPixel: 177,
            maxMeter: 7.6,
            minMeter: 0
        },
        // 矢形橋
        '99999999': {
            src: '/images/observation/riverLevelCrossSection/cross_99999999.png',
            width: 400,
            height: 316,
            maxPixel: 1,
            minPixel: 250,
            maxMeter: 10.839,
            minMeter: 0
        }
    }
});
