/**
 * <避難所一括操作画面>
 *
 * @module app/shelter/ShelterBulkOperatingPage.js
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/array',
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/text!./templates/ShelterBulkOperatingPage.html', // テンプレート文字列
    'idis/store/IdisRest',
    'idis/service/Requester',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/control/Locator',
    'app/model/DisasterInfo',
    'app/shelter/ShelterUtils',
    './_ShelterPageBase', // 避難所ページ基底クラス
    'app/config',
    'idis/consts/USER_TYPE',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/RadioButton',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'app/provide/ProvideShelterRegisterDialog',
    'app/shelter/DistrictShelterSelector',
    'app/view/form/DistrictSelector',
    './ShelterBulkOperatingGrid',
    './ShelterBulkNumberEditDialog',
    './ShelterBulkTimeEditDialog'
], function(module, array, declare, lang, template, IdisRest, Requester,
    InfoDialog, DialogChain, Loader, Router, UserInfo, Locator,
    DisasterInfo, ShelterUtils, _ShelterPageBase, config, USER_TYPE) {
    /**
     * 避難所一括操作画面
     *
     * @class ShelterOpenigPage
     * @extends module:app/shelter/_ShelterPageBase~_ShelterPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ShelterPageBase,
        /** @lends module:app/shelter/ShelterBulkOperatingPage~ShelterBulkOperatingPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--shelter',

        // Grid用Store
        store: null,

        // お知らせ用Dialog
        infoDialog: null,

        /**
         * クエリ用市町村コード
         */
        _municipalityCd: null,

        /**
         * ユーザー用市町村コード
         */
        _userMunicipalityCds: null,

        /**
         * 自動承認モードフラグ
         * true: ON（自区）
         * false: OFF / ON（他区）
         */
         _autoConfirmFlg: null,

         /**
         * 各開設フラグ
         */
        _floodShFlg: '0',
        _sedimentDisasterShFlg: '0',
        _stormSurgeShFlg: '0',
        _earthquakeShFlg: '0',
        _tsunamiShFlg: '0',
        _fireShFlg: '0',
        _landsideWaterShFlg: '0',
        _volcaneShFlg: '0',

        constructor: function() {
            this.store = new IdisRest({
                idProperty: 'facilityId',
                target: '/api/shelters/operating'
            });
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
            console.debug('現在のログインユーザID：' + UserInfo.getId());
            console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.shelterGrid);
            this.own(this.shelterRegisterDialog);
            this.own(this.timeEditDialog);
            this.own(this.numberEditDialog);
            this.initUserMunicipalityCds();
            this.initDistrictSelector();
            this.initShelterGrid();
        },

        startup: function() {
            this.inherited(arguments);
            // 自動承認モード状況を取得
            ShelterUtils.isAutoConfirmed(this._municipalityCd).then(lang.hitch(this, function(result){
                // 自動承認モードフラグをセット
                this._autoConfirmFlg = result;
            }));
        },

        // 地区の初期値をセット
        initDistrictSelector: function() {
            var userMunicipalityCd = ( UserInfo.getMunicipalityCds()[0] === config.municInfo.prefMunicCd) ?
                                        config.municInfo.defaultMunicCd : UserInfo.getMunicipalityCds()[0] ;

            var districtCd = userMunicipalityCd.substr(2, 3) + '000';

            if(UserInfo.getUserType === USER_TYPE.MUNICIPALITY){
                this.districtCd.set('value', districtCd);
            }
            else {
                // 一覧画面で選択された市町村/地区がある場合
                if(Locator.getQuery().districtCd){
                    this.districtCd.set('value', Locator.getQuery().districtCd);
                }else{
                    // クエリパラメータ自体がない場合は、未選択をセット
                    this.districtCd.set('clear', true);
                }
            }
        },

        initUserMunicipalityCds: function(){
            var userMunicipalityCds = UserInfo.getMunicipalityCds();
            var arr = [];
            userMunicipalityCds.forEach(function(value){
                arr.push(value);
            });
            this._userMunicipalityCds = arr.join(',');
        },

        //Gridに初期表示用のデータをセットする
        initShelterGrid: function() {
            this.updateGridQuery();
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function() {
            var value = this.form.get('value');
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            filter = filter.eq('disasterId', this._disasterId);
            // 未開設(0)or開設中(1)or全て(2)を指定、検索条件に追加
            if (value.isClose) {
                filter = filter.eq('isClose', value.isClose);
            }
            // 検索条件として地区名が指定されていたらそのdistrictCdを検索条件としてセット
            if (value.districtCd) {
                filter = filter.eq('districtCd', value.districtCd);
            }

            //選択した地区に合わせて市町コードを取得。登録した避難所の市町村が一覧画面にセットされるようにする。
            //地区コードから、市町コードの5桁目までを作成
            var newMunicipalityCd = value.districtCd.substr(0, 3);
            //5桁までが一致する管理対象市町コードを取得する
            var userMuniStr = UserInfo.getMunicipalityCds().join(',');
            var municipalityCdsChar = userMuniStr.indexOf(config.municInfo.prePrefCd + newMunicipalityCd);
            this._municipalityCd = userMuniStr.substr(municipalityCdsChar, 6);

            //避難所区分
            var shelterCategory = '';
            if(this.designatedEvacShFlg.checked){
                shelterCategory += '02,';
            }
            if(this.welfareEvacShFlg.checked){
                shelterCategory += '03,';
            }
            if(this.temporaryEvacShFlg.checked){
                shelterCategory += '04,';
            }
            filter = filter.eq('shelterCategory', shelterCategory);

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.shelterGrid.set('collection', collection);
        },

        /**
         * 検索ボタンが押されたときに呼び出される。
         * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
         * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
         */
        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                    // 自動承認モード状況を取得
                    ShelterUtils.isAutoConfirmed(this._municipalityCd).then(lang.hitch(this, function(result){
                        // 自動承認モードフラグをセット
                        this._autoConfirmFlg = result;
                    }));
                }
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },

        // パンくずリストのボタンを押下したときの挙動(概況画面へ遷移)
        onShelterAdminPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('shelter/admin');
        },

        // パンくずリストのボタンを押下したときの挙動(一覧画面へ遷移)
        onShelterListPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('shelter', {
                // 一覧画面で市町村名、市町村コードを使用するためクエリにセット
                municipalityCd : this._municipalityCd
            });
        },

        // 登録ダイアログを表示する
        registerShelterButtonClick: function() {
            console.debug('[登録]ボタンがクリックされました。');
            //開設か閉鎖か、全件取得か。
            var isClose = this.form.get('value').isClose;
            //現在チェックがついている施設IDを取得
            var changedRowList = this.shelterGrid.getSelectedIdList();
            //利用不可の施設は選択リストから除外する
            for(var i = 0; i < changedRowList.length; i++){
                var availableFlg = this.shelterGrid._rowIdToObject['shelterGrid-row-' +
                changedRowList[i]].availableFlg;
                if(availableFlg === '0'){
                    changedRowList.splice(i,1);
                }
            }
            //入力チェック
            if(changedRowList.length === 0 || changedRowList === null){
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : '未入力',
                        content : '避難所を選択して下さい。'
                    });
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
            }

            var startErrList = [];
            var startErrCount = 0;
            var reportErrList = [];
            var reportErrCount = 0;
            var endErrList = [];
            var endErrCount = 0;

            //入力チェック
            array.forEach(changedRowList, function(facilityId) {
                //施設名を取得
                var facilityName = this.shelterGrid._rowIdToObject['shelterGrid-row-' + facilityId].facilityName;
                //開設日時が未記入の場合、その名前をlistに蓄積。
                var shelterStartTime = this.shelterGrid.shelterStartTimeMap[facilityId];
                //調査日時が未記入の場合、その名前をlistに蓄積。
                var reportTime = this.shelterGrid.reportTimeMap[facilityId];
                var shelterEndTime = this.shelterGrid.shelterEndTimeMap[facilityId];
                if (!shelterStartTime) {
                    //入力エラーの施設名をpushし、件数をカウント
                    startErrList.push(facilityName);
                    startErrCount = startErrCount + 1;
                    if(startErrCount > 4){
                        startErrList = ['5件以上の避難所'];
                    }
                }
                if (!reportTime) {
                    //入力エラーの施設名をpushし、件数をカウント
                    reportErrList.push(facilityName);
                    reportErrCount = reportErrCount + 1;
                    if(reportErrCount > 4){
                        reportErrList = ['5件以上の避難所'];
                    }
                }
                if (shelterStartTime && shelterEndTime && shelterStartTime > shelterEndTime) {
                    //入力エラーの施設名をpushし、件数をカウント
                    endErrList.push(facilityName);
                    endErrCount = endErrCount + 1;
                    if(endErrCount > 4){
                        endErrList = '5件以上の避難所';
                    }
                }
            }, this);

            var startTimeErrMsg = startErrList.join(',') + 'の開設日時が設定されていません。' + '\n';
            var reportTimeErrMsg = reportErrList.join(',') + 'の調査日時が設定されていません。' + '\n';
            var endTimeErrMsg = endErrList.join(',') + 'の閉鎖日時が開設日時より前に設定されています。';

            if(startErrCount > 0){
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : startTimeErrMsg
                    });
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
            } else if(reportErrCount > 0){
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : reportTimeErrMsg
                    });
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
            } else if (endErrCount > 0){
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : endTimeErrMsg
                    });
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }
            }

                // 選択された要素の情報を送信用に加工
            var data = {
                items: array.map(changedRowList, function(facilityId) {

	                var facilityAdmId = this.shelterGrid._rowIdToObject['shelterGrid-row-' + facilityId].facilityAdmId;
                    if(facilityAdmId !== null){
	                    facilityAdmId =  ('00000000'+ facilityAdmId).slice(-8);
                    }
                    return {
                        disasterId: this._disasterId,
                        facilityId: facilityId,
                        reportTime: this.shelterGrid.reportTimeMap[facilityId],
                        shelterStartTime: this.shelterGrid.shelterStartTimeMap[facilityId],
                        shelterEndTime: this.shelterGrid.shelterEndTimeMap[facilityId],
                        evacHouseholdNum: this.shelterGrid.evacHouseholdNumMap[facilityId],
                        evaqueeNum: this.shelterGrid.evaqueeNumMap[facilityId],
                        facilityAdmId: facilityAdmId
                    };
                }, this)
            };

            // 配列のまま渡せないため、カンマで文字列を結合する
            var facilityIds = changedRowList.reverse().join(',');
            this._sendData = data;

            // 本部確認 0: 本部未確認, 1: 完了
            if (ShelterUtils.isHeadQuarterUser(UserInfo, this._municipalityCd)) {
                // 全体管理者・市本部ユーザ・区本部ユーザの場合、配信ダイアログを表示する
                this._sendData.headquarterFlg = '1';
            } else if (this._autoConfirmFlg) {
                // 自動承認モードが選択した避難所の所在区でONの場合、本部確認完了で登録する
                this._sendData.headquarterFlg = '1';
            } else {
                // 本部確認「未確認」の場合、配信ダイアログを表示しない
                this._sendData.headquarterFlg = '0';
                return this.sendAndRegister(null);
            }
            this.showShelterDialog(facilityIds, data, isClose);
        },

        sendAndRegister: function(shelterInfo){
	        var message = '';
            // 大阪府連携対象
            if(shelterInfo){
		               message = '登録・更新及び大阪府への配信を行います。よろしいですか？';
            // 大阪府連携非対象
            } else {
                        message = '登録・更新を行います。よろしいですか？';
            }

            this.chain.confirm(message, lang.hitch(this, function(chain) {
                if(shelterInfo){
                    this._sendData.sendPostForm = shelterInfo.lalertForm;
                    this._sendData.twitterFlg = shelterInfo.twitterFlg;
                    this._sendData.tweetList = shelterInfo.tweetList;
                }

                var promise =  Requester.post('/api/shelters/operating', {
                    data: this._sendData
                }).then(lang.hitch(this, function() {
                    var compMessage = shelterInfo && this._simulateFlg !== 'true' ? '登録・配信が完了しました。' : '登録が完了しました。';
                    chain.info(compMessage, '完了', lang.hitch(this, function(){
                        if(shelterInfo){
                            this.shelterRegisterDialog.getChildren()[0].closeDialog();
                        }
                        Router.moveTo('shelter', {
                            municipalityCd: this._municipalityCd
                        });
                    }));
                }), function(error) {
                    // 失敗時
                    console.error(error);
                    chain.info('登録・更新に失敗しました。', 'エラー');
                });
                Loader.wait(promise);
            }));
        },

        //キャンセルボタンを押下したら一覧画面に戻る
        cancelButtonClick: function() {
            console.debug('[キャンセル]ボタンがクリックされました。');
            Router.moveTo('shelter');
        },

        /**
         * 配信情報(開設避難所情報)ダイアログを表示する。
         */
        showShelterDialog: function(facilityIds, obj, isClose) {
            Requester.get('/api/shelters/list/' + this._disasterId + '/' + facilityIds, {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(lang.hitch(this,function(items) {
                var evaqueeData = [];

                var existPubShelter = false;

                array.forEach(obj.items, function(item){
                    var facilityInfo = array.filter(items, function(facility){
                        return facility.facilityId === item.facilityId;
                    })[0];

                    if(facilityInfo.publicFlg && facilityInfo.publicFlg === '1'){
                        existPubShelter = true;
                    }else{
                        // 公開不可の場合はLアラートに載せない
                        return false;
                    }

                    var evaqueeNum = isNaN(item.evaqueeNum) ? 0 : Number(item.evaqueeNum);
                    var evacHouseholdNum = isNaN(item.evacHouseholdNum) ? 0 : Number(item.evacHouseholdNum);

                    var shelterStartOrEndTime = this.formatDateFromObj(item.shelterStartTime);
                    if (item.shelterEndTime) {
                        shelterStartOrEndTime = this.formatDateFromObj(item.shelterEndTime);
                    }
                    // 外部公開情報をセットする（0: 公開, 1: 非公開)
                    // 一括操作の場合、M_FACILITY.PUBLIC_FLGの値に準拠する
                    var publish4Lalert = facilityInfo.publicFlg === '1' ? '0' : '1';
                    // 避難所種別・避難所種別で表現しきれない情報をセットする
                    var evacShelterType = '避難所';
                    var evacShelterTypeDetail = '';
                    if (facilityInfo.welfareEvacShFlg === '1') {
                        // 福祉避難所の場合、避難所種別で表現しきれない情報を「福祉避難所」にする
                        evacShelterTypeDetail = '福祉避難所';
                    }
                    if (facilityInfo.temporaryEvacShFlg === '1') {
                        // 臨時避難所の場合、避難所種別を「臨時避難所」にする
                        // フラグが複数の場合も、臨時避難所が選択されていれば臨時避難所とみなす
                        evacShelterType = '臨時避難所';
                    }
                    // 指定区分をセットする
                    var evacSuitableShelter = '指定なし';
                    if (facilityInfo.designatedEvacShFlg === '1') {
                        // 災害時避難所の場合、指定区分を「指定避難所」にする
                        evacSuitableShelter = '指定避難所';
                    }

                    var evaqueeDataSub = {
                        facilityId: facilityInfo.facilityId,
                        evacShelterName: facilityInfo.facilityName,
                        evacShelterNameKana: facilityInfo.facilityNameKana,
                        evacShelterType: evacShelterType,
                        evacShelterTypeDetail: evacShelterTypeDetail,
                        evacSuitableShelter: evacSuitableShelter,
                        evacShelterSort: item.shelterEndTime ? '閉鎖' : '開設',
                        evacShelterLatitude: facilityInfo.latitude,
                        evacShelterLongitude: facilityInfo.longitude,
                        address: facilityInfo.address,
                        evaqueeNum: evaqueeNum,
                        evacHouseholdNum: evacHouseholdNum,
                        evacShelterDatetime: shelterStartOrEndTime,
                        evacTopical: '',
                        evacShelterStatus: '0', // SV側で「空き」で固定しているため
                        publish: publish4Lalert,
                        capacity: facilityInfo.capacity,
                        contactInfo: facilityInfo.phoneNum,
                        district: facilityInfo.districtDto.districtName
                    };
                    evaqueeData.push(evaqueeDataSub);
                }, this);

                var args = {};

                //公開可能な避難所の操作がない場合、Lアラート連携しない
                if(!existPubShelter){
                    return this.sendAndRegister(null);
                }

                if(isClose === '1'){ //一括閉鎖の場合
                    args.shelterArgs = {
                        sendCategory : '02',
                        subject: ' ',
                        evacTimestamp: new Date(),
                        evaqueeData: evaqueeData,
                        // 更新種別 '01':新規, '02':更新・訂正, '03':取消
                        distributionType: '02',
                        isPrefMailDefault: true
                    };
                } else if(isClose === '0'){ //一括開設の場合
                    args.shelterArgs = {
                        sendCategory : '02',
                        subject: ' ',
                        evacTimestamp: new Date(),
                        evaqueeData: evaqueeData,
                        // 更新種別 '01':新規, '02':更新・訂正, '03':取消
                        // 一旦新規を登録しておくが、あとでinitDialog時に既存のLアラートの報があれば
                        // 更新として02に書き換えられる。
                        distributionType: '01',
                        isPrefMailDefault: true
                    };
                } else if(isClose === '2'){ //全ての場合
                    args.shelterArgs = {
                        sendCategory : '02',
                        subject: ' ',
                        evacTimestamp: new Date(),
                        evaqueeData: evaqueeData,
                        // 更新種別 '01':新規, '02':更新・訂正, '03':取消
                        // 一旦新規を登録しておくが、あとでinitDialog時に既存のLアラートの報があれば
                        // 更新として02に書き換えられる。
                        distributionType: '01',
                        isPrefMailDefault: true
                    };
                }

                args.evacOrderArgs = null;

                args.facilityId = items[0].facilityId;

                // ダイアログの最初の子要素が登録画面
                var dialog = this.shelterRegisterDialog.getChildren()[0];
                // ダイアログの画面を初期化する。
                dialog.initDialog(args, false, false, false);
                // ダイアログのsendイベントを受け取れるようにする
                dialog.on('send', lang.hitch(this, function(evt) {
                    return this.sendAndRegister(evt.value);
                }));
                // ダイアログを破棄できないように設定する。
                this.shelterRegisterDialog.set('closable', false);
                // ダイアログを表示する。
                this.shelterRegisterDialog.show();
            }));
        }
    });
});
