/**
 * <モジュールの説明>
 * @module app/chat/ChatUserFormDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/text!./templates/ChatUserFormDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/model/UserInfo',
    'idis/consts/ACL',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'app/view/form/CustomizableMunicipalitySelector',
    'app/view/form/UserOrganizationSelector',
    'dijit/form/Form'
], function (module, declare, lang, Deferred, JSON, template, _PageBase,
    InfoDialog, DialogChain, Loader, UserInfo, ACL) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
            // テンプレート文字列
            templateString: template,
            _chatGrpId: null,

            constructor: function () {
                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);
            },

            startup: function () {
                this.inherited(arguments);
                this.organizationSelector.restoreRefresh();
                //権限制御 
                this.setMunicipality();
            },

            
            /**
             * ダイアログを初期化する。
             */
            initDialog: function(id) {
                this.form.reset();
                this._chatGrpId = id;
                //権限制御 
                this.setMunicipality();

            },

            //権限制御 
            setMunicipality: function(){
                var role = UserInfo.getRoleCd();
                var munic = UserInfo.getMunicipalityCd();
                var organization = UserInfo.getOrganization();

                // 所属管理者
                if (role === ACL.XXMGR_USER) {
                    this.municipalityCd.set('value', munic);
                    this.municipalityCd.set('disabled', true);
                    this.organizationSelector.setDeptCd(organization.deptCd);
                    this.organizationSelector.set('value', 'D' + organization.deptCd);
                }

            },

            //帳票出力
            onOutputExcelButtonClick: function () {

                var reqid = 'CHAT_USER_LIST_EX';
                var fileName = 'ChatUserList.xlsx';

                var municipalityCd = this.municipalityCd.get('value');
                var organization = this.organizationSelector.get('value');

                var paramList = [{key:'chatGrpId', value: this._chatGrpId}];
                if(municipalityCd !== ''){
                    paramList.push({ key: 'municipalityCd', value: municipalityCd });
                }
                
                if (organization !== '') {
                    switch(organization.substr(0,1) ){
                        case 'D':
                            paramList.push({ key: 'deptCd', value: organization.slice(1) });
                            break;
                        case 'S':
                            paramList.push({ key: 'sectCd', value: organization.slice(1) });
                            break;
                        case 'U':
                            paramList.push({ key: 'unitCd', value: organization.slice(1) });  
                            break;                   
                        default:
                            break;   
                    }
                }

                var data4xoblosDownload = {
                    fileName: fileName,
                    reqid: reqid,
                    paramList: paramList,
                    fileType: 'excel'
                };
                
                var promise = this.download(data4xoblosDownload).then(lang.hitch(this, function () {
                    this.infoDialog = new InfoDialog({
                        title: 'ダウンロード完了',
                        content: 'ダウンロードが完了しました。'
                    });
                    this.infoDialog.show();

                }), lang.hitch(this, function (error) {
                    console.error(error);
                    this.infoDialog = new InfoDialog({
                        title: 'エラー',
                        content: 'エラーが発生しました。管理者にお問い合わせください。'
                    });
                    this.infoDialog.show();
                }));

                Loader.wait(promise);
            },

            // 帳票のダウンロード
            download: function (data) {
                var deferred = new Deferred();
                var xhr = new XMLHttpRequest();
                xhr.open('POST', '/api/xoblos/download', true);
                xhr.responseType = 'arraybuffer';
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onload = function () {

                    // エラー時は処理を止める
                    if (xhr.status !== 200) {
                        deferred.reject('status error:' + xhr.status);
                        return;
                    }
                    // ファイル名をレスポンスヘッダーから取り出す
                    var contentDisposition = this.getResponseHeader('content-disposition');
                    var inputFileName = contentDisposition.replace(/^.*"(.*)"$/, '$1');

                    // aバイナリデータを取得
                    var arrayBuffer = this.response;
                    var blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
                    var baseFileName = '';
                    switch (inputFileName) {
                        case 'ChatUserList.xlsx':
                            baseFileName = '指示共有グループユーザ一覧.xlsx';
                            break;
                    }
                    var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                    // IE10+
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                    } else {
                        // 擬似的にAタグを作成
                        var link = document.createElement('a');
                        link.style = 'display: none';
                        document.body.appendChild(link);

                        // AタグのURLにバイナリデータをセット
                        var url = window.URL.createObjectURL(blob);
                        link.href = url;

                        // ファイル名をセット
                        link.download = fileName;

                        // 擬似的にリンクをクリック
                        link.click();
                        // 参照を解放 (iPadでのエラー回避のためsetTimeoutする)
                        setTimeout(function () {
                            window.URL.revokeObjectURL(url);
                            link.remove();
                        }, 200);
                    }

                    deferred.resolve();

                };
                xhr.send(JSON.stringify(data));

                return deferred.promise;
            },

            /**
             * 月や日付を2桁にゼロpaddingする
             */
            _zeroPadding: function (month) {
                return ('00' + month).slice(-2);
            },

            /**
             * キャンセルボタン押下
             */
            onCancel: function () {
                this.getParent().hide();
            },
            
            // 市町村セレクタの値が変更するときの動作
            onMunicipalityCdChanged: function(municipalityCd) {
                var role = UserInfo.getRoleCd();
                // 所属管理者以外である場合処理を実施
                if (role !== ACL.XXMGR_USER) {
                    this.organizationSelector.restoreRefresh();
                    this.organizationSelector.setMunicipalityCd(municipalityCd.value);
                }
            }
        });
});
