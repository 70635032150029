/**
* 行政区選択用grid
* @module app/view/form/MunicipalityGrid
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dijit/form/RadioButton',
    'dijit/form/CheckBox',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function (module, declare, lang, array, RadioButton, CheckBox,
    IdisGrid, helper) {

    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--damage-list',

        // checkbox制御用
        checkboxes: {},

        // 親子のID
        mainTypeIds: [],

        constructor: function () {
            /**
             * 各列の定義
             * @type {Object[]}
             */
            this.columns = [
                {
                    field: 'main', label: '選択', sortable: false,

                    renderCell: lang.hitch(this, function(item) {
                        this.checkboxes[item.municipalityId] = new CheckBox({
                            onClick: lang.hitch(this, function() {
                                this._onCheckBox(item);
                            })
                        });
                        // HTMLとしてウィジェットに紐付くDOMノードを返す
                        return this.checkboxes[item.municipalityId].domNode;
                    })
                },
                helper.column('municipality', '行政区')
            ];

            this.inherited(arguments);
        },

        reset: function() {
            this.inherited(arguments);
            this.checkboxes = {};
            this.mainTypeIds = [];
        },

        _onCheckBox: function(item) {
            if (this.checkboxes[item.municipalityId].get('checked')) {
                // チェック状態である => 今回追加されたのでmainTypeIdsに入れる
                this.mainTypeIds.push(item.municipalityId);
            } else {
                // チェック状態でない => mainTypeIdsにあったら削除
                var index = array.indexOf(this.mainTypeIds, item.municipalityId);
                if (index !== -1) {
                    this.mainTypeIds.splice(index, 1);
                }
            }

            console.log(this.mainTypeIds);
        }
     });
 });
