define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-attr',
    'dojo/dom-class',
    'dojo/text!./templates/BaseLayerPane.html',
    'dojo/on',
    'idis/consts/QUERY',
    'idis/control/Locator',
    'idis/map/BaseLayerOptions',
    'idis/map/BaseLayerUtils',
    'idis/model/UserInfo',
    'idis/view/_IdisWidgetBase',
    'app/config',
    // 以下、変数で受けないモジュール
    'dojo/query'
], function(module, array, declare, lang, domAttr, domClass, template, on,
    QUERY, Locator, BaseLayerOptions, BaseLayerUtils, UserInfo, _IdisWidgetBase, config) {
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {
        // テンプレート文字列
        templateString: template,

        // ウィジェットのルート要素に付与されるCSSクラス
        baseClass: 'map-BaseLayerPane',

        /**
         * 操作対象のMapオブジェクト
         * @type {Map}
         */
        map: null,

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            // 選択肢一覧の表示を更新
            this.updateOptions();
            this.own(Locator.on('change', lang.hitch(this, this.updateOptions)));
        },

        // DOM構築後に呼ばれる
        postCreate: function() {
            this.inherited(arguments);
            // 背景地図選択肢のクリックを監視
            var selector = '.map-BaseLayerPane-option:click';
            this.own(on(this.layerListContainer, selector, lang.hitch(this, this.onOptionClick)));
        },

        /**
         * 選択肢一覧を再描画する。
         */
        updateOptions: function() {
            // 設置済みの背景地図ID（強調用）
            var selectedId = Locator.getQuery()[QUERY.BASE_LAYER_ID] || BaseLayerOptions[0].id;
            this.layerListContainer.innerHTML = array.map(BaseLayerOptions, function(option) {
                // サーバの起動モードに応じて選択できる背景が変わる
                var baseLayerConfig = config.map.userBaseLayer ? config.map.userBaseLayers[UserInfo.getRunningMode()] : undefined;
                if (!baseLayerConfig || baseLayerConfig.indexOf(option.id) !== -1) {
                    // DOM要素を生成
                    return '<div class="map-BaseLayerPane-option' + (selectedId === option.id ? ' is-selected' : '') +
                        '" data-idis-base-layer-id="' + option.id + '">' +
                        '<img src="images/map/' + (option.image || 'noimage.png') + '" width="75px" height="75px">' +
                        '<caption>' + option.label + '</caption></div>';
                }
            }).join('');
        },

        /**
         * 選択肢がクリックされた場合の動作
         * @param {MouseEvent} evt クリック・イベント
         */
        onOptionClick: function(evt) {
            var node = evt.selectorTarget;
            // 選択済みでない場合は反応
            if (!domClass.contains(node, 'is-selected')) {
                // URLの背景地図IDを更新
                Locator.replaceState(QUERY.BASE_LAYER_ID, domAttr.get(node, 'data-idis-base-layer-id'));
            }
        }
    });
});
