/**
 * 防災情報リンク集画面用のモジュール
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/text!./templates/DpLinkCollectionPage.html',
    'idis/view/page/_PageBase',
    'idis/model/UserInfo'
], function(module, array, declare, template, _PageBase, UserInfo) {

    return declare(module.id.replace(/\//g, '.'), _PageBase, {

        // テンプレート文字列
        templateString: template,

        // 基本クラス
        baseClass: 'idis-Page idis-Page--link',
        /**
         * 表示対象のリンク一覧
         */
        linkList: [{
            name: '防災情報関連',
            children: [
                {name: '内閣府防災情報', href: 'http://www.bousai.go.jp'},
                {name: '総務省消防庁', href: 'http://www.fdma.go.jp'},
                {name: '国土交通省北海道開発局', href: 'http://www.hkd.mlit.go.jp/'},
                {name: 'ＮＨＫ災害情報メニュー', href: 'http://www.nhk.or.jp/saigai/'},
                {name: 'Safety Tips', href: 'http://www.mlit.go.jp/common/001063852.pdf'}
            ]
        }, {
            name: '気象関係',
            children: [
                {name: '気象庁', href: 'http://www.jma.go.jp/jma/'},
                {name: '国土交通省防災情報提供センター', href: 'http://www.mlit.go.jp/saigai/bosaijoho/'},
                {name: '札幌管区気象台', href: 'http://www.jma-net.go.jp/sapporo/'},
                {name: '土砂災害危険度情報', href: 'http://www.njwa.jp/hokkaido-sabou/hazardMap/displayArea.do?area=0'},
                {name: '川の防災情報', href: 'http://www.river.go.jp/portal/#80'},
                {name: '川の水位情報(危機管理型水位計)', href: 'https://k.river.go.jp/?zm=10&clat=43.074906886631524' +
                        '&clon=141.38854980468753&t=0&dobs=1&drvr=1&dtv=1&dtmobs=1&dtmtv=1'}
            ]
        }],

        // DOM要素を構築する
        buildRendering: function() {
            this.inherited(arguments);
            var html = [];
            array.forEach(this.linkList, function(linkGroup) {
                // 各リンクのグループ
                html.push('<section>');
                html.push('<h2>');
                html.push(linkGroup.name);
                html.push('</h2><div>');
                if (linkGroup.children && linkGroup.children.length && linkGroup.children[0].children) {
                    // 更に階層がある場合
                    array.forEach(linkGroup.children, function(thirdGroup) {
                        // 各リンク群
                        html.push('<div><label>');
                        html.push(thirdGroup.name);
                        html.push('</label><ul>');
                        array.forEach(thirdGroup.children, function(item) {
                            // 各リンク要素
                            html.push('<li><a href="');
                            html.push(item.href);
                            html.push('" target="_blank">');
                            html.push(item.name);
                            html.push('</a></li>');
                        });
                    });
                    html.push('</ul></div>');
                } else {
                    // フラットにリンクが並ぶ場合
                    html.push('<ul>');
                    array.forEach(linkGroup.children, function(item) {
                        // 各リンク要素
                        html.push('<li><a href="');
                        html.push(item.href);
                        html.push('" target="_blank">');
                        html.push(item.name);
                        html.push('</a></li>');
                    });
                    html.push('</ul>');
                }
                html.push('</div></section>');
            });
            this.linkContainer.innerHTML = html.join('');
        }
    });
});
