/**
 * <モジュールの説明>
 * @module app/user/UserFormDialog
 */
 define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/dom-style',
    'dojo/text!./templates/UserFormDialog.html', // テンプレート文字列
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/model/DisasterInfo',
    'idis/consts/ACL',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'app/config',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'dijit/form/Form',
    'app/view/form/CustomizableMunicipalitySelector',
    'app/view/form/UserOrganizationSelector'
], function(module, declare, lang, Deferred, JSON, domStyle, template, UserInfo, Requester, _PageBase,
        DisasterInfo, ACL, InfoDialog, DialogChain, Loader, config) {
    /**
     * <クラスの説明>
     * @class FacilityFormDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends app/facility/view/UserFormDialog~UserFormDialog */ {
        // テンプレート文字列
        templateString: template,
        /**
         * 災害ID
         */
         _disasterId: null,

        /**
         * ダウンロードファイル名
         * code
         * 0:利用者一覧
         */
        _fileMappin: [{
                'code' : '0',
                'reqid' : 'USER_LIST_EX',
                'fileName' : 'UserList.xlsx',
                'fileNameJp' : '利用者一覧.xlsx'
            }
        ],

        constructor: function(){
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
            // 災害IDを取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
        },

        setInitValue: function(municipalityCd, organization) {
                this.municipalityCd.set('value',municipalityCd);
                this.organizationSelector.set('value', organization);


            var role = UserInfo.getRoleCd();
            // ログインユーザがシステム管理者・全体管理者・市本部管理者以外は市区の変更不可
            if (!(role === ACL.ADMIN_USER || role === ACL.SYSMGR_USER || role === ACL.SHMGR_USER)) {
			    domStyle.set(this.municipalityCd.selectButton.domNode, 'display', 'none');
                // ログインユーザが所属管理者・所属本部ユーザ（人事室および総務局以外）は自組織のみ閲覧可能（所属の変更不可）
                if (role === ACL.XXMGR_USER || 
                    (role === ACL.XXHONBU_USER && 
                        (UserInfo.getOrganization().deptCd !== config.dept.humanResourceDeptCd ||
                            UserInfo.getOrganization().deptCd !== config.dept.soumuDeptCd ))) {
                    // this.organizationSelector.restoreRefresh();
                    domStyle.set(this.organizationSelector.selectButton.domNode, 'display', 'none');
                }
			}
        },
        // 市町村セレクタの値が変更するときの動作
        onMunicipalityCdChanged4Dialog: function(municipalityCd) {
            if(this._municipalityCd === municipalityCd.value){
                return;
            }else{
                this._municipalityCd = municipalityCd.value;
            }
            this.organizationSelector.restoreRefresh();
            this.organizationSelector.set('value', municipalityCd.value);
            this.organizationSelector.setMunicipalityCd(municipalityCd.value);
        },
        // 所属セレクタの値が変更するときの動作
        onOrganizationChanged4Dialog: function(organization) {
            if(!organization.value){
                this._municipalityCd = null;
                this.organizationSelector.set('value', '');
                return;
            }
            // 所属から市区を逆引き
            var url = organization.value.substr(0,1) === 'S' ? 
            'api/organization/sects/' : organization.value.substr(0,1) === 'D' ? 'api/organization/depts/' : '';
            if (!url){
                return;
            }
            var promise = Requester.get(url + organization.value.substr(1));
            Loader.wait(promise).then(lang.hitch(this, function(org) {
                if (this._municipalityCd === org.municipalityCd){
                    return;
                }
                // 市区を設定
                if(organization.value.substr(1) === org.deptCd || organization.value.substr(1) === org.sectCd){
                    this.municipalityCd.set('value', org.municipalityCd);
                    this._municipalityCd = org.municipalityCd;
                    // 所属を設定
                    this.organizationSelector.set('value', organization.value);
                }
                }), function(error) {
                // 失敗時
                console.error(error);
            });
        },
        // アカウント種別セレクトボックスの初期化を行う
        initSelectBox: function(roleCd) {
            var promise = Requester.get('/api/roles');
            Loader.wait(promise).then(lang.hitch(this, function (data) {
                // セレクトボックスに設定するデータの配列を作成
                var newOptions = [{
                    label: '&nbsp;',
                    value: ''
                }];
                // サーバーレスポンスの各要素をselectへ追加
                data.items.forEach(function (object) {
                    newOptions.push({
                        label: object.roleName,
                        value: object.roleCd
                    });
                });

                // var role = UserInfo.getRoleCd();
                var roleCdOptions = newOptions;
                // if(role!==ACL.ADMIN_USER){
                    // roleCdOptions = newOptions.filter(function(newOptions){
                        // return newOptions.value !== ACL.ADMIN_USER;
                    // });
                // }
                this.roleCdSelect.set('options', roleCdOptions);
                if(roleCd){
                    this.roleCdSelect.set('value', roleCd);
                }else{
                    this.roleCdSelect.set('value', '');
                }
                var role = UserInfo.getRoleCd();
                if (role === ACL.XXHONBU_USER && 
                    (UserInfo.getOrganization().deptCd === config.dept.humanResourceDeptCd ||
                        UserInfo.getOrganization().deptCd === config.dept.soumuDeptCd)){
                    // ログインユーザが人事室および総務局の所属本部ユーザの場合、アカウント種別は応援職員に固定
                    this.roleCdSelect.set('value', ACL.AID_USER);
                    this.roleCdSelect.set('readOnly', true);
                }
            }));
        },

        // 帳票出力を行う前に入力チェックを行う
        onOutputButtonClick : function() {
            // 出力条件
            var municipalityCd = this.form.get('value').municipalityCd;
            var organization = this.form.get('value').organization;
            if(organization){
                if (organization.length === 7){
                    organization = organization.substr(1, 6);
                } else if (organization.length === 10) {
                    organization = organization.substr(1, 9);
                }
            }
            var roleCd = this.form.get('value').roleCd;

            this.getParent().hide();
            // ダウンロード
            this.downloadExcel(municipalityCd,organization,roleCd);
        },
        //帳票出力
        downloadExcel : function(municipalityCd,organization,roleCd) {
            var reqid = null;
            var fileName = null;
            var paramList = [];
            // 連携ファイル名とリクエストIDを取得
            var fileInfs = this._fileMappin.filter(function(){
                { return true; }
            });

            reqid = fileInfs[0].reqid;
            fileName = fileInfs[0].fileName;
            paramList = [
                {key: 'disid', value: this._disasterId}
            ];
            if (municipalityCd) {
                paramList.push({ key: 'municipalityCd', value: municipalityCd });
            }
            if (organization) {
                if (organization.length === 6){
                    paramList.push({ key: 'organizationDept', value: organization });
                } else if (organization.length === 9) {
                    paramList.push({ key: 'organizationSect', value: organization });
                }
            }
            if (roleCd) {
                paramList.push({ key: 'roleCd', value: roleCd });
            }
            var data4xoblosDownload = {
                fileName: fileName,
                reqid: reqid,
                paramList: paramList
            };

            var promise = this.download(data4xoblosDownload).then(lang.hitch(this, function() {
                    this.infoDialog = new InfoDialog({
                        title : 'ダウンロード完了',
                        content : 'ダウンロードが完了しました。'
                    });
                    this.infoDialog.show();

                }), lang.hitch(this, function(error) {
                    console.error(error);
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : 'エラーが発生しました。管理者にお問い合わせください。'
                    });
                    this.infoDialog.show();
            }));

            Loader.wait(promise);
        },

        // 帳票のダウンロード
        download: function(data) {
            var deferred = new Deferred();

            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            var self = this;
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject('status error:'+ xhr.status);
                    return;
                }
                // ファイル名をレスポンスヘッダーから取り出す
                var contentDisposition = this.getResponseHeader('content-disposition');
                var inputFileName = contentDisposition.replace(/^.*"(.*)"$/, '$1');

                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                // ファイル名を取得
                var baseFileName = self._fileMappin.filter(function(item){
                    if(item.fileName===inputFileName) { return true; }
                })[0].fileNameJp;
                var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }

                deferred.resolve();

            };
            xhr.send(JSON.stringify(data));

            return deferred.promise;
        }
    });
});
