/**
 * 部詳細ダイアログ用モジュール。
 * @module app/organization/DeptDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/DeptDetailDialog.html',
    './_DeptDialogBase',
    'idis/view/Loader',
    'idis/service/Requester',
    // 以下、変数で受けないモジュール
    'app/view/form/MunicipalitySelector',
    'app/view/form/RegionSelector',
    'dijit/form/ValidationTextBox'
], function(module, declare, lang, domStyle, template, _DeptDialogBase,
    Loader, Requester) {
    /**
     * 部詳細ダイアログ
     * @class DeptDetailDialog
     * @extends module:app/organization/_DeptDialogBase~_DeptDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), _DeptDialogBase,
    /** @lends module:app/view/page/DeptRegisterDialog~DeptRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,
        // バージョン
        _version: null,

        /**
         * constructor
         */
        constructor: function() {
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * フォームを初期化する。
         */
        initForm: function(object) {
            this._deptCd = object.deptCd;
            var self = this;
            var page = this.form;
            Loader.wait(Requester.get('/api/organization/depts/' + this._deptCd)).then(function (data) {
                page.set('value', data);
                self._version = data.version;
            });
        },

        /**
         * 部を更新する。
         */
        onSubmit: function() {
            try {
                console.debug('部詳細ダイアログの[更新]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                value.deptCd = this._deptCd;
                value.version = this._version;

                this.emit('update', {value: value});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * ダイアログを初期化する。（更新時）
         */
        initDialog: function(object) {
            this.reset();
            this.initForm(object);
        }
    });
});
