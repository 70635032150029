/**
 * <モジュールの説明>
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/text!./templates/SimulationEventRegisterDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/model/DisasterInfo',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/control/Router',
    'idis/util/FilesUtils',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'dijit/form/Form',
    'idis/view/form/WordCountTextarea'
], function(module, declare, lang, Deferred, JSON, template, _PageBase,
    DisasterInfo, UserInfo, USER_TYPE, InfoDialog, DialogChain, Router) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {

        _eventId: null,
        _scenarioId: null,

        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page idis-Page--simulation',

        constructor: function(){
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        startup:function(){
            this.inherited(arguments);
        },

        initPage: function(scenarioId){
            this._scenarioId = scenarioId;
        },

        onEventRegisterButtonClick : function() {
            //大阪市は被害のみなので04を指定
            // this.eventType.set('04');
            // var eventType = this.eventType.get('value');
            var eventType = '04';
            var timing = this.timing.get('value');
            // 入力チェック
            if(!eventType) {
                console.debug('イベント種別が選択されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : 'イベント種別が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return false;
            }
            if(!timing) {
                console.debug('発生タイミングが選択されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '発生タイミングが選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return false;
            }
            if(!this.content.get('value')) {
                console.debug('内容が入力されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '内容が入力されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return false;
            }

            // 該当するイベント種別の登録画面へ遷移する
            var eventUrl = null;
            switch (eventType) {
                case '01':
                    eventUrl = 'chronology/register';
                    break;
                case '02':
                    eventUrl = 'evacorder/register';
                    break;
                case '03':
                    eventUrl = 'shelter/register';
                    break;
                case '04':
                    eventUrl = 'report/register';
                    break;
            }
            // timingをHH:ssで取得
            var formatTiming = timing.toString().split(' ')[4].split(':');
            var timingToSec =
                parseInt(formatTiming[0],10) * 60 * 60 +
                parseInt(formatTiming[1], 10) * 60;

            this.getParent().hide();
            // 各種イベント登録画面へ遷移
            Router.moveTo(eventUrl, {
                simulateFlg: true,
                scenarioId: this._scenarioId,
                eventType: eventType,
                content: this.content.get('value'),
                timing: timingToSec});

        },

        /**
         * キャンセルボタン押下
         */
        onCancel:function(){
            this.getParent().hide();
        }
    });
});
