/**
 * 係新規登録ダイアログ用モジュール。
 * @module app/organization/UnitRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/UnitRegisterDialog.html',
    './_UnitDialogBase',
    // 以下、変数で受けないモジュール
    'app/view/form/MunicipalitySelector',
    'app/view/form/RegionSelector',
    'app/view/form/MunicRegionOrganizationSelector',
    'dijit/form/ValidationTextBox',
    'idis/view/form/WordCountTextarea'
], function(module, declare, lang, domStyle, template, _UnitDialogBase) {
    /**
     * 係新規登録ダイアログ
     * @class UnitRegisterDialog
     * @extends module:app/organization/_UnitDialogBase~_UnitDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), _UnitDialogBase,
    /** @lends module:app/view/page/UnitRegisterDialog~UnitRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 係を新規登録する。
         */
        onSubmit: function() {
            try {
                console.debug('係新規登録ダイアログの[登録]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                // TODO 以下のログ書き出しを消去し、コメントアウトを外す

               this.emit('register', {value: value});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * ダイアログを初期化する。（新規登録時）
         */
        initDialog: function() {
            this.reset();
            this.organizationSelector.restoreRefresh();
        },

        // 市町村セレクタの値が変更するときの動作
        onMunicipalityCdChanged: function(municipalityCd) {
            this.organizationSelector.setMunicipalityCd(municipalityCd.value);
        },

        // 振興局セレクタの値が変更するときの動作
        onRegionCdChanged: function(regionCd) {
            this.organizationSelector.setRegionCd(regionCd.value);
        }
    });
});
