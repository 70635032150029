/**
 * <避難所情報概況画面>
 *
 * @module app/shelter/ShelterOpeningGrid.js
 */
define(['module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom',
    'dijit/registry',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/view/form/Button',
    'app/config',
    'dojo/on',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer'
], function(module, declare, lang, locale, dom, registry, IdisGrid, helper, Button, config, on) {
    /**
     * <クラスの説明>
     *
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'),IdisGrid,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */
        {
            columns: [
            {field: 'municipalityName', label: '区', sortable: false},
            {field: '_item', label: '最終調査日時',
                formatter: lang.hitch(this, function(item){
                    if(item.reportTime) {
                    	return item.reportTime.replace(/-/g, '/');
                    } else {
                        return '-';
                    }
                })
            },
            helper.column('confirmFlg', '本部確認状況', {
                className: lang.hitch(this, function (item) {
                    // ヘッダーの場合はclassNameを返さない
                    if (!item) {
                        return;
                    }
                    if (item.confirmFlg === '0') {
                        // 本部未確認
                        return 'column-color-red';
                    }
                }),
                formatter: function(value) {
                    if (!value) {
                        return '-';
                    } else if (value === '0') {
                        return '本部未確認';
                    } else {
                        return '完了';
                    }
                }
            }),
            {field: 'shelterNum', label: '開設避難所数', sortable: false},
            {field: 'evacHouseholdNum', label: '避難世帯数', sortable: false},
            {field: 'evaqueeNum', label: '避難者数', sortable: false},
            // ボタン
            {
                label: '一覧',
                field: 'list',
                sortable: false,
                renderCell: function(item) {
                    if(item.municipalityCd !== config.municInfo.cityMunicCd){
                        var gridNode = this.grid.domNode;
                        var button = new Button({
                            label: '一覧',
                            onClick: function() {
                                on.emit(gridNode, 'listButtonClick', {item: item});
                            }
                        });
                        return button.domNode;
                    }else{
                        //合計行には詳細ボタンをつけない
                        return null;
                    }

                }
            }
            ]
        }
    );
});
