/**
 * ArcGIS Map Print Service利用時のユーティリティー関数群
 */
define([
    'dojo/_base/Color',
    'leaflet',
    'idis/view/draw/PolylineDecorator',
    '../../config'
    // 以下、変数で受けないモジュール
    ],
    function(Color, leaflet, PolylineDecorator, config){

    /**
     * ArcGISからアイコン画像の取得先URLを取得する
     */
    var _getWebRootUrl = function() {
        return config.print.getImageUrl();
    };
    
    /**
     * レイヤーIDのカウンター
     * @private
     */
    var _layerCounter = 0;

    /**
     * デフォルトカラー（赤）, デフォルト透過度
     * @private
     */
    var _DEFAULT_RGB = [255, 0, 0];
    var _DEFAULT_OPACITY = 255;

    /**
     * フィーチャー又はレイヤーから全体のサイズをピクセルで取得
     *
     * getBounds()を持つフィーチャー又はレイヤーに対応
     *
     * @private
     * @param L.GeoJSONレイヤー
     * @return [x, y]
     */
    var _getIconSize = function(map, layer, zoom){

        var southWest = layer.getBounds().getSouthWest();
        var northEast = layer.getBounds().getNorthEast();

        // ピクセル座標取得
        var southWestPixel = map.project(southWest, zoom);
        var northEastPixel = map.project(northEast, zoom);

        // サイズ
        var x = Math.abs(southWestPixel.x - northEastPixel.x);
        var y = Math.abs(southWestPixel.y - northEastPixel.y);

        return [x, y];

    };

    /**
     * 作図グラフィックピクセルサイズの最適化
     * 1. 新しい幅 (ポイント単位) = (幅 (ピクセル単位) + 0.5)
     * 2. 次に、新しい幅 (ポイント単位) をその値を超えない最も近い整数 (自然数) 値に丸めます。
     * 3. 最終的なライン幅は、(丸めた新しい幅 (ポイント単位) * 72) /ターゲット解像度に設定します。
     * See. http://server.arcgis.com/ja/server/latest/
     * publish-services/linux/line-aliasing-in-map-services.htm
     * @param {Number}
     * @param {Number}
     */
    var _optimizeSize = function(pixel, dpi){
        return Math.floor(pixel + 0.5) * 72 / dpi;
    };

    /**
     * 4326座標を3857座標に変換
     * @param {Array} [lng, lat]
     */
    var _changeCoords = function(coords){
        var geo3857 = leaflet.CRS.EPSG3857.project(new leaflet.LatLng(coords[1], coords[0]));
        return [geo3857.x, geo3857.y];
    };

    /**
     * 通行規制区間のプロパティーにスタイル情報を設定
     *
     * プロパティーにregReason, isRegEnd, dmgReasonのいずれかがある場合は通行規制区間情報とみなす
     * スタイル情報は通行規制区間用のstyle.js参照
     *
     * @param {Obejct} フィーチャーのプロパティー
     */
    var _setTrafficRegProperties = function(properties) {

        if (properties.isRegEnd !== void 0 ||
            properties.dmgReason !== void 0 ||
            properties.regReason !== void 0) {
            // 通行規制データである

            if (properties.isRegEnd) {
                properties._weight = 0;
                properties._opacity = 0;
                properties._fillOpacity = 0;
                return;
            }
            if (properties.dmgReason) {
                properties._weight = 7;
                properties._opacity = 1;
                properties._color = '#800080';
                return;
            }
            if (properties.regReason) {
                properties._weight = 10;
                properties.opacity = 1;
                switch (properties.regReason) {
                case '災害':
                    properties._color = '#FF0000';
                    break;
                case '工事':
                    properties._color = '#FFA500';
                    break;
                case '雪':
                    properties._color = '#6666CC';
                    break;
                case '冬期閉鎖':
                    properties._color = '#ADDFFF';
                    break;
                case '冬期閉鎖（夜間規制）':
                    properties._color = '#000080';
                    break;
                case 'その他':
                    properties._color = '#A52A2A';
                    break;
                default:
                    properties._color = '#0033FF';
                    break;
                }
                return;
            }
        }

    };

    /**
     * GeoJSONオブジェクトを印刷サービス用レイヤーに変換する
     * FeatureCollectionのみ対応
     * @param {Object} map Leaflet.Map
     * @param {Object} geojson
     * @param {Number} dpi
     * @return [Object]
     * 印刷サービス用レイヤーのfeatureCollection.layers[] を返す
     * {"id":"drawLayer",
     *  "opacity":1,
     *  "minScale":0,
     *  "maxScale":0,
     *  "featureCollection": {"layers":[]}}
     */
    var _convGeoJsonToEsriJson = function(map, geojson, dpi){

        var layers = [];

        if(geojson.type !== 'FeatureCollection'){
            return layers;
        }

        var features = geojson.features;

        // 4326を3857座標に変換した座標
        var geo3857;

        // ズームレベル
        var zoom = map.getZoom();

        // FeatureCollection中のfeatureを順に変換し、結果を配列に格納
        for(var i = 0; i < features.length; i++){
            //var layerName = 'drawLayer_' + _layerCounter;
            var geometry = {
                spatialReference: {
                    wkid: 3857
                }
            };
            var coordinates = features[i].geometry.coordinates;
            var geometryType = features[i].geometry.type;
            // FIXME
            // properties.weightが文字列のため、数値への変換が発生している
            // weightは数値にすべき
            var properties = features[i].properties;

            // 道路規制区間のデータのpropertiesにはカラーや幅のスタイル情報が無いため、
            // 以降のプロセスに載せるためにここで設定する
            // FIXME 通行規制用のstyle.jsをここでも利用しているため保守性低い. あるべき姿を要検討.
            _setTrafficRegProperties(properties);

            var symbol = null;

            // colorに関して、rgbaのaまで指定する必要がある。
            // _opacity の指定が0 ~ 1 までの指定であるのに対し、
            // 印刷サービスのカラー指定では、0 ~ 255の値を指定する必要があるため、
            // 255 をopacityで割る.さらに、整数の必要があるため、四捨五入する.
            var fillColor = _DEFAULT_RGB;
            var fillOpacity = _DEFAULT_OPACITY;
            var color = _DEFAULT_RGB;
            var opacity = _DEFAULT_OPACITY;
            if(typeof properties._fillColor !== 'undefined'){
                fillColor = (new Color(properties._fillColor)).toRgb();
            }
            if(typeof properties._fillOpacity !== 'undefined'){
                fillOpacity = Math.round(fillOpacity * properties._fillOpacity);
            }
            fillColor.push(fillOpacity);

            if(typeof properties._color !== 'undefined'){
                color = (new Color(properties._color)).toRgb();
            }
            // 付箋の場合 _colorではなく、_borderColor
            if(typeof properties._borderColor !== 'undefined'){
                color = (new Color(properties._borderColor)).toRgb();
            }
            if(typeof properties._opacity !== 'undefined'){
                opacity = Math.round(opacity * properties._opacity);
            }
            color.push(opacity);

            /**
             * Esri JSONとのマッピング
             */
            switch(geometryType){
                case 'Polygon':
                    geometryType = 'esriGeometryPolygon';

                    // 座標変換しEsri用のgeometryを生成
                    var rings = [];
                    for (var m = 0; m < coordinates[0].length; m++) {
                        var ring = _changeCoords(coordinates[0][m]);
                        rings.push(ring);
                    }

                    // 始点と終点が揃っている必要があるため最後に入れる
                    rings.push(rings[0]);

                    geometry.rings = [rings];

                    symbol = {
                        color: fillColor,
                        outline: {
                            color: color,
                            width: _optimizeSize(Number(properties._weight), dpi),
                            type: 'esriSLS',
                            style: 'esriSLSSolid'
                        },
                        type: 'esriSFS',
                        style: 'esriSFSSolid'
                    };

                    break;
                case 'Point':
                    // 付箋.
                    // Leafletでは付箋はPoint扱い、ArcGISでは付箋（長方形）をポイントで扱うことができない.
                    // 付箋の大きさを緯度経度で求めてポリゴンとして定義し、テキストはポイントとして別レイヤーとして定義する.
                    if(properties._markerType === 'DivIcon'){

                        var width = 200; // デフォルト付箋サイズ
                        var height = 150;
                        if(properties.width !== void 0){
                            // '200px'という形で入っているため、'px'を除去して数値にする.
                            width = parseInt(properties.width, 10);
                        }
                        if(properties.height !== void 0){
                            height = parseInt(properties.height, 10);
                        }
                        var fontSize = 15; // デフォルトフォントサイズ
                        var fontColor = [0, 0, 0]; // デフォルト文字色
                        if(properties._fontColor !== void 0){
                            fontColor = (new Color(properties._fontColor)).toRgb();
                        }
                        fontColor.push(255);        // Opacity

                        // box-sizing: border-box のため、線幅を考慮してwidth, heightになるようにする
                        // polygonのアウトラインは指定領域に1/2プラスされるため、
                        // CSSのように 2 * borderWidth 分ではなく、単にborderWidth分マイナスする
                        var borderWidth = (properties._borderWidth !== void 0) ?
                            _optimizeSize(Number(properties._borderWidth), dpi) : 0;
                        width = width - borderWidth;
                        height = height - borderWidth;

                        // 緯度経度からピクセル座標を求める
                        var pixel = map.project(leaflet.latLng(coordinates[1], coordinates[0]), zoom);
                        // ポリゴン四隅のピクセル座標を求める
                        var xmin = pixel.x;
                        var xmax = pixel.x + width;
                        var ymin = pixel.y;
                        var ymax = pixel.y + height;

                        // ピクセル座標から対角の緯度経度を求める. ピクセル座標Y軸は下方向が正.
                        var northEastPixel = leaflet.point(xmax, ymin);
                        var northEast = map.unproject(northEastPixel, zoom);
                        var southWestPixel = leaflet.point(xmin, ymax);
                        var southWest = map.unproject(southWestPixel, zoom);

                        // 4326を3857座標に変換
                        var northEast3857 = leaflet.CRS.EPSG3857.project(northEast);
                        var southWest3857 = leaflet.CRS.EPSG3857.project(southWest);

                        // ポリゴンレイヤー追加.
                        geometryType = 'esriGeometryPolygon';
                        geometry.rings = [[
                                            [northEast3857.x, northEast3857.y],
                                            [northEast3857.x, southWest3857.y],
                                            [southWest3857.x, southWest3857.y],
                                            [southWest3857.x, northEast3857.y],
                                            [northEast3857.x, northEast3857.y]
                                        ]];

                        symbol = {
                            color: fillColor,
                            outline: {
                                color: color,
                                width: borderWidth,
                                type: 'esriSLS',
                                style: 'esriSLSSolid'
                            },
                            type: 'esriSFS',
                            style: 'esriSFSSolid'
                        };

                        var layerDiv = {
                            layerDefinition: {
                                name: 'drawLayer_' + _layerCounter,
                                geometryType: geometryType
                            },
                            featureSet: {
                                geometryType: geometryType,
                                features: [{
                                    geometry: geometry,
                                    symbol: symbol
                                }]
                            }
                        };

                        layers.push(layerDiv);      // ここで追加
                        _layerCounter++;
                        // geometry初期化
                        geometry = {
                            spatialReference: {
                                wkid: 3857
                            }
                        };

                        // テキストレイヤー.
                        geometryType = 'esriGeometryPoint';

                        // テキスト領域を求める.
                        // 元々のサイズから - 2 * borderWidth.
                        // widthは既に-borderWidthされているため、borderWidth分で調整.
                        // -13 は付箋領域の拡大領域で文字が入らない部分
                        var textWidth = width - borderWidth - 13;
                        xmin = pixel.x - ( - borderWidth );
                        xmax = pixel.x + ( width  + borderWidth );
                        ymin = pixel.y - ( - borderWidth);
                        ymax = pixel.y + ( height + borderWidth);

                        // ピクセル座標から対角の緯度経度を求める. ピクセル座標Y軸は下方向が正.
                        northEastPixel = leaflet.point(xmax, ymin);
                        northEast = map.unproject(northEastPixel, zoom);
                        southWestPixel = leaflet.point(xmin, ymax);
                        southWest = map.unproject(southWestPixel, zoom);

                        // 4326を3857座標に変換
                        northEast3857 = leaflet.CRS.EPSG3857.project(northEast);
                        southWest3857 = leaflet.CRS.EPSG3857.project(southWest);

                        // ポリゴンの左角が始点となる.
                        geometry.x = southWest3857.x;
                        geometry.y = northEast3857.y;

                        // コメントの改行設定
                        // 表示と完全には一致しない
                        // 元々あるセンテンスとしての改行ごとに、テキスト領域に収まらない部分に改行を設定する.
                        // CSSのbreak-all相当を独自に実装
                        var comment = '';

                        fontSize = (properties._fontSize !== void 0) ?
                            _optimizeSize(Number(properties._fontSize), dpi) : _optimizeSize(fontSize, dpi);
                        var numChar = Math.floor(textWidth / fontSize);     // １行あたりの文字数
                        var sentences = properties.comment.split('\n');     // センテンス数

                        for(var k = 0; k < sentences.length; k++){
                            // 1センテンスの文字数
                            var characters = sentences[k];
                            // 必要な改行数
                            var breaks = Math.floor(characters.length / numChar);

                            for(var n = 0; n < breaks + 1; n++){
                                // 付箋の幅に対して文字が入らない場合は改行
                                if (n * numChar + numChar > characters.length) {
                                    comment += characters.substr(n * numChar, numChar);
                                } else {
                                    comment += characters.substr(n * numChar, numChar) + '\n';
                                }
                            }

                            comment += '\n';

                        }

                        symbol = {
                            type: 'esriTS',
                            color: fontColor,
                            verticalAlignment : 'top',
                            horizontalAlignment : 'left',
                            angle: 0,
                            xoffset: 0,
                            yoffset: 0,
                            align: 'start',
                            decoration: 'none',
                            rotated: false,
                            kerning: true,
                            font: {
                                family: 'ＭＳ Ｐゴシック',
                                size: fontSize,
                                style: 'normal',
                                weight: 'normal',
                                decoration: 'none'
                            },
                            text: comment
                        };

                    }

                    if(properties._markerType === 'Icon'){

                        geometryType = 'esriGeometryPoint';
                        geo3857 = leaflet.CRS.EPSG3857.project(
                            new leaflet.LatLng(coordinates[1], coordinates[0]));
                        geometry.x = geo3857.x;
                        geometry.y = geo3857.y;

                        symbol = {
                            type: 'esriPMS',
                            url: properties._iconUrl,
                            width: _optimizeSize(properties._iconSize[0], dpi),
                            height: _optimizeSize(properties._iconSize[1], dpi),
                            xoffset: 0,
                            yoffset: 0
                        };

                    }

                    if (properties._markerType === 'CircleMarker') {

                        geometryType = 'esriGeometryPoint';
                        geo3857 = leaflet.CRS.EPSG3857.project(
                            new leaflet.LatLng(coordinates[1], coordinates[0]));
                        geometry.x = geo3857.x;
                        geometry.y = geo3857.y;

                        // L.Circleフィーチャを生成して、円のサイズを求める
                        var circle = new leaflet.Circle(
                            [features[i].geometry.coordinates[1],features[i].geometry.coordinates[0]],
                            features[i].properties._radius);
                        var size = _getIconSize(map, circle, zoom);

                        symbol = {
                            color: fillColor,
                            outline: {
                                color: color,
                                width: _optimizeSize(Number(properties._weight), dpi),
                                type: 'esriSLS',
                                style: 'esriSLSSolid'
                            },
                            type: 'esriSMS',
                            style: 'esriSMSCircle',
                            size: _optimizeSize(size[0], dpi)
                        };
                    }

                    break;
                case 'LineString':

                    symbol = {
                        color: color,
                        width: _optimizeSize(Number(properties._weight), dpi),
                        type: 'esriSLS',
                        style: 'esriSLSSolid'
                    };

                    // 矢印の場合は、矢印ヘッドを別ポリラインとして先に追加
                    if(properties._markerType === 'arrow'){

                        // ヘッドはcoordinates の N - 1 → N 番目を元に付与される
                        var num = coordinates.length;
                        // N 番目の緯度経度
                        var afterLatLng = leaflet.latLng(coordinates[num - 1][1], coordinates[num - 1][0]);
                        // N - 1 番目の緯度経度
                        var beforeLatLng = leaflet.latLng(coordinates[num - 2][1], coordinates[num - 2][0]);

                        // 矢印の元ライン
                        var line = leaflet.polyline([beforeLatLng, afterLatLng], {});

                        // 矢印ヘッドを生成
                        // 地図に追加して初めて矢印用ポリラインが生成される
                        // 表示上見えないようにするため透過率は0にして追加する
                        var arrowHead = new PolylineDecorator(line).addTo(map);
                        arrowHead.setPatterns([{
                            offset: '100%',
                            repeat: 0,
                            symbol: leaflet.Symbol.arrowHead({
                                pixelSize: 4 * Number(properties._weight),
                                polygon: false,
                                pathOptions: {
                                    opacity: 0
                                }
                            })
                        }]);

                        // 矢印ヘッドの緯度経度を取得する
                        var arrowLayer;
                        for (var key in arrowHead._layers) {
                            if (arrowHead._layers.hasOwnProperty(key)) {
                                arrowLayer = arrowHead._layers[key];
                            }
                        }
                        // 矢印ヘッドの3点のLatLngが得られる
                        var arrowLatLngs = arrowLayer._latlngs;

                        // 矢印ヘッドのgeometryを設定
                        var arrowPaths = [];
                        for (var j = 0; j < arrowLatLngs.length; j++) {
                            // 座標変換
                            var arrowPath = _changeCoords([arrowLatLngs[j].lng, arrowLatLngs[j].lat]);
                            arrowPaths.push(arrowPath);
                        }
                        geometry.paths = [arrowPaths];

                        // 地図から矢印ヘッドを削除
                        map.removeLayer(arrowHead);

                        // ここでヘッド用のレイヤーを追加
                        layers.push({
                            layerDefinition: {
                                name: 'drawLayer_' + _layerCounter,
                                geometryType: 'esriGeometryPolyline'
                            },
                            featureSet: {
                                geometryType: 'esriGeometryPolyline',
                                features: [{
                                    geometry: geometry,
                                    symbol: symbol
                                }]
                            }
                        });

                        // カウンター更新
                        _layerCounter++;
                    }

                    // geometry初期化
                    geometry = {
                        spatialReference: {
                            wkid: 3857
                        }
                    };

                    // 矢印の元ライン
                    geometryType = 'esriGeometryPolyline';
                    var paths = [];
                    for (var l = 0; l < coordinates.length; l++) {
                        var path = _changeCoords(coordinates[l]);
                        paths.push(path);
                    }
                    geometry.paths = [paths];

                    break;
                default:
                    break;
            }

            var layer = {
                layerDefinition: {
                    name: 'drawLayer_' + _layerCounter,
                    geometryType: geometryType
                },
                featureSet: {
                    geometryType: geometryType,
                    features: [{
                        geometry: geometry,
                        symbol: symbol
                    }]
                }
            };

            if(layer.featureSet.features[0].symbol !== null){
                layers.push(layer);
                _layerCounter++;
            }

        }

        return layers;

    };

    /**
     * タイトル用のLayerを返す
     * @param title タイトル
     * @param mapOptions
     * @param startY タイトル領域の始点のY座標
     * @return [背景用Layer, テキスト用Layer]
     */
    var _getTitleLayers = function(title, mapOptions, startY){

        var layers = [];

        // タイトルが詰まって印刷されてしまうため、文字の間に半角スペースを付与
        //var strings = title.split('');
        //title = strings.join(' ');

        // タイトル背景用の白四角ポリゴン グラフィックレイヤー
        var titleBackLayerObj = {
            id: 'titleBgLayer',
            featureCollection: {
                layers: [{
                    layerDefinition: {
                        name: 'back',
                        geometryType: 'esriGeometryPolygon',
                        drawingInfo: {
                            renderer: {
                                type: 'simple',
                                symbol: {
                                    type: 'esriSFS',
                                    style: 'esriSFSSolid',
                                    color: [255, 255, 255, 255]
                                }
                            }
                        }
                    },
                    featureSet: {
                        features: [{
                            geometry: {
                                rings: [
                                        [[mapOptions.extent.xmin, startY],
                                        [mapOptions.extent.xmax, startY],
                                        [mapOptions.extent.xmax, mapOptions.extent.ymax],
                                        [mapOptions.extent.xmin, mapOptions.extent.ymax],
                                        [mapOptions.extent.xmin, startY]]
                                ],
                                spatialReference: {wki: 3857}
                            }
                        }]
                    }
                }]
            }
        };

        // タイトルテキスト用のポイント グラフィックレイヤー
        // タイトル位置は余白の中心座標を設定
        var titleLayerObj = {
            id: 'titleTxLayer',
            featureCollection: {
                layers: [{
                    layerDefinition: {
                        name: 'text',
                        geometryType: 'esriGeometryPoint',
                        drawingInfo: {
                            renderer: {
                                type: 'simple',
                                symbol: {
                                    type: 'esriSMS',
                                    style: 'esriSMSCircle',
                                    color: [0, 0, 0, 255],
                                    size: 5
                                }
                            }
                        }
                    },
                    featureSet: {
                        features: [{
                            geometry: {
                                x: (mapOptions.extent.xmin + mapOptions.extent.xmax)/2,
                                y: (mapOptions.extent.ymax + startY)/2,
                                spatialReference: {wki: 3857}
                            },
                            symbol: {
                                type: 'esriTS',
                                color: [0, 0, 0, 255],
                                verticalAlignment : 'middle',
                                horizontalAlignment : 'center',
                                angle: 0,
                                xoffset: 0,
                                yoffset: 0,
                                align: 'start',
                                decoration: 'none',
                                rotated: false,
                                kerning: true,
                                font: {
                                    family: 'ＭＳ Ｐゴシック',
                                    size: 10,
                                    style: 'normal',
                                    weight: 'normal',
                                    decoration: 'none'
                                },
                                text: title
                            }
                        }]
                    }
                }]
            }
        };

        layers.push(titleBackLayerObj);
        layers.push(titleLayerObj);

        return layers;

    };
    
    /**
     * レイヤー・プロパティにスタイル情報を設定する（Icon用）
     */
    var _setIconProperties = function(layer) {
        if (!!layer.feature && !!layer.feature.properties) {
            layer.feature.properties._markerType = 'Icon';
            layer.feature.properties._iconUrl = 
                _getWebRootUrl() + layer.options.icon.options.iconUrl;
            layer.feature.properties._iconSize = layer.options.icon.options.iconSize;   
        }
    };

    /**
     * レイヤー・プロパティにスタイル情報を設定する（Polygon用）
     */
    var _setPolygonProperties = function(layer) {
        if (!!layer.feature && !!layer.feature.properties) {
            layer.feature.properties._weight = layer.options.weight;
            layer.feature.properties._color = layer.options.color;
            layer.feature.properties._opacity = layer.options.opacity;
            // fillがtrueの場合、fillColorがnullならcolorがセットされる
            layer.feature.properties._fillColor = 
                (layer.options.fill && layer.options.fillColor === null) ? 
                    layer.options.color : layer.options.fillColor;
            // fillがtrueの場合、fillOpacityがnullならopacityがセットされる
            layer.feature.properties._fillOpacity = 
                (layer.options.fill && layer.options.fillOpacity === null) ? 
                    layer.options.opacity : layer.options.fillOpacity;
        }
    };
    
    /**
     * レイヤー・プロパティにスタイル情報を設定する（Polyline用）
     */
    var _setPolylineProperties = function(layer) {
        if (!!layer.feature && !!layer.feature.properties) {
            layer.feature.properties._weight = layer.options.weight;
            layer.feature.properties._color = layer.options.color;
            layer.feature.properties._opacity = layer.options.opacity;
        }
    };
    
    return {

        /**
         * 印刷サービスに渡すJSONデータを作成する
         * この印刷ダイアログが管理する地図、レイヤーコントロールに基づき作成される
         * 座標系は3857のみに対応
         * @see {@link http://resources.arcgis.com/en/help/rest/apiref/exportwebmap_spec.html}
         * @param {String} 印刷タイトル
         * @param {Object} leaflet.Map
         * @oaram {Object} 印刷範囲の緯度経度 leaflet.LatLng
         * @param {Object} 印刷範囲の緯度経度 leaflet.LatLng
         * @param {Number} DPI
         * @param {Object<identifier, layer>} 変換チェックを実施するレイヤー
         * @return {Object} 印刷サービスに送るJSONデータ
         */
        createExportJson: function(title, map, southWest, northEast, dpi, layers){

            // FIXME southWest と northEastだとわかりにくいので、extentでxmax,...を渡すようにする

            // ズームレベル
            var zoom = map.getZoom();

            // 印刷範囲のピクセル座標
            var southWestPixel = map.project(southWest, zoom);
            var northEastPixel = map.project(northEast, zoom);

            // 縦横ピクセル算出
            var pixelX = Math.abs(southWestPixel.x - northEastPixel.x);
            var pixelY = Math.abs(southWestPixel.y - northEastPixel.y);

            // 3857座標
            var southWest3857 = leaflet.CRS.EPSG3857.project(southWest);
            var northEast3857 = leaflet.CRS.EPSG3857.project(northEast);

            // タイトル余白付与前のymax
            // すなわち地図画像が出力される最大緯度 = タイトル余白の開始緯度
            var ymax = northEast3857.y;

            // タイトルがある場合はタイトル余白分のピクセル座標+50を緯度経度に変換
            if (title !== '') {
                pixelY += 50;

                // ピクセル座標Yは下方向が正であるからマイナスする
                var point = leaflet.point(northEastPixel.x, northEastPixel.y - 50);

                // タイトル付与後の新座標
                northEast = map.unproject(point, zoom);
                northEast3857 = leaflet.CRS.EPSG3857.project(northEast);
            }

            // スケールの設定
            var scale = 1000000; // デフォルト100万分の1
            if (zoom >= 15 && zoom < 18) { // 15 ~ 17
                scale = 25000;
            } else if (zoom >= 12 && zoom < 15) { // 12 ~ 14
                scale = 20000;
            } else if (zoom >= 9 && zoom < 12) { // 9 ~ 11
                scale = 1000000;
            } else if (zoom >=5 && zoom < 9) { // 5 ~ 8
                scale = 5000000;
            }

            var mapOptions = {
                extent: {
                    xmin: southWest3857.x,
                    ymin: southWest3857.y,
                    xmax: northEast3857.x,
                    ymax: northEast3857.y,
                    spatialReference: {
                        wkid: 3857
                    },
                    scale: scale
                }
            };

            var exportOptions = {
                dpi: dpi,
                outputSize: [pixelX, pixelY]
            };

            // FIXME 背景地図レイヤーは可変にする
            var baseMap = {
                id: 'WebTiled_8925',
                title: 'WebTiled_8925',
                opacity: 1,
                baseMapLayers: [{
                    id: '3',
                    type: 'WebTiledLayer',
                    urlTemplate: 'https://cyberjapandata.gsi.go.jp/xyz/std/{level}/{col}/{row}.png',
                    credits: '地図情報提供：国土地理院'
                }]
            };

            var operationalLayers = [];
            var operationalLayer = null;

            // レイヤーIDカウンター
            var drawLayerCounter = 0;

            // 印刷対象のレイヤーの出力定義を生成
            // 地図に表示されている順に印刷するため、layerごとにoperationalLayersを生成する
            for (var key in layers) {
                if (layers.hasOwnProperty(key)) {
                    var layer = layers[key];

                    if (layer.options.infoCategoryCd !== void 0) {
                        switch (layer.options.infoCategoryCd) {
                        case 'D004':    // 震源・震度
                        case 'D115':    // 県全体の開設中避難所
                        case 'O001':    // 雨量
                        case 'O002':    // 水位
                        case 'O003':    // 潮位
                        case 'M005':    // 避難場所
                            // 作図レイヤーではないためpropertiesにスタイルの情報を持っていない
                            // レイヤーからgeojson取得前にレイヤーのスタイル情報からpropertiesに必要な情報を予めセットしておく
                            layer.getLayers().forEach(_setIconProperties);
                            break;
                        case 'D006':    // 津波警報・注意報
                            // 作図レイヤーではないためpropertiesにスタイルの情報を持っていない
                            // レイヤーからgeojson取得前にレイヤーのスタイル情報からpropertiesに必要な情報を予めセットしておく
                            layer.getLayers().forEach(_setPolylineProperties);
                            break;
                        case 'D102':    // 気象警報・注意報
                        case 'D105':    // 土砂災害警戒情報
                        case 'D106':    // 竜巻注意報
                        case 'D107':    // 記録的短時間大雨警報
                        case 'D114':    // 県全体の避難情報
                        case 'M006':    // 行政界 FIXME ポリゴンとポリラインどちらもあるもよう
                        case 'O005':    // 防災気象情報 土砂災害危険度情報（地区）
                        case 'O006':    // 防災気象情報 土砂災害危険度情報（5kmメッシュ）
                            // 作図レイヤーではないためpropertiesにスタイルの情報を持っていない
                            // レイヤーからgeojson取得前にレイヤーのスタイル情報からpropertiesに必要な情報を予めセットしておく
                            layer.getLayers().forEach(_setPolygonProperties);
                            break;
                        default:
                            break;
                        }

                        // 広域印刷対象レイヤーに対して実行
                        switch (layer.options.infoCategoryCd) {
                        case 'D004':
                        case 'D006':
                        case 'D102':
                        case 'D105':
                        case 'D106':
                        case 'D107':
                        case 'D108':    // 作図情報
                        case 'D114':
                        case 'D115':
                        case 'O001':
                        case 'O002':
                        case 'O003':
                        case 'O005':
                        case 'O006':
                        case 'M005':
                        case 'M006':
                            // geojsonデータの取得
                            var geojson = layer.toGeoJSON();
                            // 印刷サービス用のJsonデータに変換
                            var drawLayers = _convGeoJsonToEsriJson(map, geojson, dpi);

                            var allDrawLayers = [];
                            for (var i = 0; i < drawLayers.length; i++) {
                                allDrawLayers.push(drawLayers[i]);
                            }

                            operationalLayer = {
                                id: 'drawLayer_' + drawLayerCounter,
                                opacity: 1,
                                minScale: 0,
                                maxScale: 0,
                                featureCollection:{ layers: allDrawLayers }
                            };
                            operationalLayers.push(operationalLayer);

                            drawLayerCounter++;
                            break;
                        default:
                            break;
                        }
                    }

                }
            }

            // タイトル印刷用レイヤーのセット
            if (title !== '') {
                var titleLayers = _getTitleLayers(title, mapOptions, ymax);
                operationalLayers.push(titleLayers[0]);
                operationalLayers.push(titleLayers[1]);
            }

            // 印刷サービスに渡すJSONデータ
            var sendData = {
                baseMap: baseMap,
                mapOptions: mapOptions,
                operationalLayers : operationalLayers,
                exportOptions: exportOptions
            };

            return sendData;

        }

    };

});
