define([
    // ここに書く
    'app/shelter/ShelterAdminPage',
    'app/shelter/ShelterBulkOperatingPage',
    'app/shelter/ShelterDetailPage',
    'app/shelter/ShelterListPage',
    'app/shelter/ShelterRegisterPage',
    'app/shelter/status/ShelterStatusListPage',

    // ここまで
], function () { });