define([
    // ここに書く
    'app/evacorder/EvacOrderAdminPage',
    'app/evacorder/EvacOrderDetailPage',
    'app/evacorder/EvacOrderDistrictPage',
    'app/evacorder/EvacOrderPage',
    'app/evacorder/EvacOrderRegisterFromDetailPage',
    'app/evacorder/EvacOrderRegisterFromRecommendPage',
    'app/evacorder/EvacOrderRegisterPage',
    // ここまで
], function () { });