/**
* 体制状況・報告概況画面用モジュール。
* @module app/employgathering/EmployGatheringStatusListPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/text!./templates/EmployGatheringStatusListPage.html',
    'dstore/Memory',
    'dstore/Trackable',
    'idis/control/Router',
    'idis/store/IdisRest',
    './_EmployGatheringStatusPageBase',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'app/model/DisasterInfo',
    'idis/control/Locator',
    'dojo/dom-style',
    'app/config',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/service/Requester',
    'dojo/_base/array',
    'app/disaster/model/DisasterType',
    'idis/consts/ACL',
    'app/disasterprevention/view/model/HqSetFlg',
    // 以下、変数として受け取らないモジュール
    'dijit/layout/BorderContainer',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'idis/view/form/DateInput',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'app/employgathering/EmployGatheringFormDialog',
    './EmployGatheringStatusListGrid',
    './EmployGatheringStatusDetailDialog'
//    'app/view/form/MunicRegionOrganizationSelector'
//    'app/view/form/EmployOrganizationSelector'
], function(module, declare, lang, json, template, Memory, Trackable,
    Router, IdisRest, _PageBase, DialogChain, InfoDialog, Loader, DisasterInfo, Locator,
    domStyle, config, UserInfo, USER_TYPE, Requester, array, DisasterType, ACL, HqSetFlg) {
    /**
     * 検索条件格納用オブジェクト
     * @type {Object}
     */
    // var filterStore = {};

    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/view/page/DisasterAdmintPage~DisasterAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        // 更新・訂正どちらのボタンからsubmitされたかを判断する
        _updateFlg: null,

        // 大阪市全体の集計フラグ（集計する場合:1）
        _sumFlg: 0,
        
        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--employgathering',

        /**
        * 職員参集管理パラメータ格納用オブジェクト
        * @type {Object}
        */
        DisasterPrevInfItems : null,

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * データ登録用オブジェクト
         * @type {module:dstore/Store}
         */
        registerStore: null,

        /**
         * 防災体制リスト
         */
        _disasterPrevList: null,

        /**
         * 防災体制No
         */
        _seqNum: 0,

        /**
         * 市町村コード(デフォルト：所属市町村)
         */
        _municipalityCd: null,

        /**
         * 災害ID
         */
        _disasterPreventionId: 0,

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.detailDialog);
            this.own(this.confirmDialog);
            // this.own(this.formDialog);
            // this.organizationSelector.setMunicipalityCd(this._municipalityCd);
            // this.organizationSelector.restoreRefresh();
        },

        // DOM要素構築後に呼ばれる
        postCreate: function() {
            // 更新・訂正どちらのボタンからsubmitされたかを判断する
            this.own(this.submitButton.on('click', lang.hitch(this, function() {
                // 更新ボタン(UPDATE_BTN) → insertされる
                // this._updateFlg = 0;

                // 続報登録できるよう改修したが使わないので訂正報のみの扱いとする
                // 訂正ボタン(CORRECT_BTN) → updateされる
                this._updateFlg = 1;
                this.onSubmit();
            })));
            this.own(this.updateButton.on('click', lang.hitch(this, function() {
                // 訂正ボタン(CORRECT_BTN) → updateされる
                this._updateFlg = 1;
                this.onSubmit();
            })));
        },
        constructor: function() {
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
            // 災害IDをDisasterInfoから取得
            this.disasterId = DisasterInfo.getDisasterId();
            if(!this.disasterId) {
                this.disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
            // this._municipalityCd = Locator.getQuery().municipalityCd || UserInfo.getMunicipalityCd();
            if(Locator.getQuery().municipalityCd || Locator.getQuery().dpDeptCd){
                // 一覧画面からの遷移の場合：選択中の組織
                this._municipalityCd = Locator.getQuery().municipalityCd || UserInfo.getMunicipalityCd();
                // ※大阪市の場合、組織カテゴリーコードが未設定となるため、明示的にnullを設定
                this._deptCd = Locator.getQuery().dpDeptCd || null;

            } else {
                // 概況画面からの遷移の場合：ユーザの所属組織
                this._municipalityCd = UserInfo.getMunicipalityCd();
                this._deptCd = UserInfo.getOrganization().deptCd;
            }
            this._disasterPrevList = null;
            var list = [];
            var promise = Requester.get('/api/disasterPrevention/empGathering?disId=' + this.disasterId +
                '&muniCd=' + this._municipalityCd + '&dpDeptCd=' + this._deptCd, {
                // '&muniCd=' + this._municipalityCd + '&deptCd=' + UserInfo.getOrganization().deptCd, {
                preventCache: false
            }).then(lang.hitch(this,function(data) {
                array.forEach(data, function(plan) {
                    var prevList = {value: plan.disasterPrevId,
                    label: '第' + plan.seqNum + '報'};
                    list.push(prevList);
                });
                this._disasterPrevList = list;
                this.setDisasterPrevInf(data[0]);
                this._disasterPreventionId = this.DisasterPrevInfItems.disasterPrevId;
                this._municipalityCd = this.DisasterPrevInfItems.municipalityCd;
                this._deptCd = this.DisasterPrevInfItems.deptCd;
                this.initGrid();
                this.disasterPrevList.set('options', this._disasterPrevList);
                this.disasterPrevList.set('value', this._disasterPreventionId);
            }), lang.hitch(this, function(err){
                console.error(err);
            }));

            Loader.wait(promise);

            // データ格納用オブジェクト
            this.tempStore = new IdisRest({
                idProperty: 'empGatherId',
                target: '/api/employGathering'
            });
            // データ登録用オブジェクト
            this.registerStore = new IdisRest({
                idProperty: 'empGatherId',
                target: '/api/employGathering'
            });

            // 閲覧モードフラグをセット
            this._viewMode = Locator.getQuery().viewMode || '0';
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            // this.initConfirmDialog();
            //this.initDisasterPreventionStatusInf();
            //this.initGrid();
            // this.initDetailDialog();
        },

        /**
         * 体制情報と本部設置情報をセット
         */
        initDisasterPreventionStatusInf: function(){
//            this.reportNum.innerHTML = '第' + this.DisasterPrevInfItems.seqNum + '報';
            this.municipalityName.innerHTML = this.DisasterPrevInfItems.municipalityName;
          //  this.deptName.innerHTML = this.DisasterPrevInfItems.deptName;
            // this.organizationSelector.set('value','D' + this.DisasterPrevInfItems.deptCd);
            // 組織セレクタを非表示
            // this.organizationSelector.set('noAllButton',true);
            this.reportTimestamp.innerHTML = this.DisasterPrevInfItems.reportTimestamp;
            this.disasterType.innerHTML = this.DisasterPrevInfItems.disasterTypeName;
            this.statusName.innerHTML = this.DisasterPrevInfItems.statusName;
            this.comment.innerHTML = this.DisasterPrevInfItems.comment;
            // コメントが空欄の場合スクロールバーを出さないようにする
            if(this.DisasterPrevInfItems.comment === '') {
                domStyle.set(this.commentParent, 'display', 'none');
            }
            if (this.DisasterPrevInfItems.hqName !== null) {
                this.hqName.innerHTML = this.DisasterPrevInfItems.hqName;
            }  else {
                this.hqName.innerHTML = 'なし';
            }
            this.hqSetTimestamp.innerHTML = this.DisasterPrevInfItems.hqSetTimestamp;
            this.hqAbolishedTimestamp.innerHTML = this.DisasterPrevInfItems.hqAbolishedTimestamp;
            this.hqSetPlace.innerHTML = this.DisasterPrevInfItems.hqSetPlace;
            // 閲覧モードの場合、一括更新ボタンを非表示
            if(this._viewMode === '1'){
                domStyle.set(this.submitButton.domNode, 'display', 'none');
                domStyle.set(this.updateButton.domNode, 'display', 'none');
            }
            // 過去報の場合は、権限に関わらず更新ボタンは非表示
            // if(this.DisasterPrevInfItems.activeFlg === 0){
            //     domStyle.set(this.submitButton.domNode, 'display', 'none');
            // }else{
            //     domStyle.set(this.submitButton.domNode, 'display', '');
            // }
            // 大阪市の体制の場合、更新ボタンは非表示（大阪府連携は親画面からに限定）
            // if(this.DisasterPrevInfItems.municipalityCd === config.municInfo.cityMunicCd &&
            //     this.DisasterPrevInfItems.deptCd === null){
            //     domStyle.set(this.submitButton.domNode, 'display', 'none');
            //     // 大阪市の最新の体制の場合、集計ボタンを表示
            //     if(this.DisasterPrevInfItems.activeFlg === 1){                
            //         domStyle.set(this.sumButton.domNode, 'display', '');
            //     } else {
            //         domStyle.set(this.sumButton.domNode, 'display', 'none');
            //     }
            // }else{
            //     // 大阪市の体制でない場合は、集計ボタンを非表示
            //     domStyle.set(this.sumButton.domNode, 'display', 'none');
            // }
            // 大阪市の最新の体制の場合、集計ボタンを表示
            // if(this.DisasterPrevInfItems.municipalityCd === config.municInfo.cityMunicCd &&
            //         this.DisasterPrevInfItems.deptCd === null && this.DisasterPrevInfItems.activeFlg === 1){
            //     domStyle.set(this.sumButton.domNode, 'display', '');
            // }else{
            //     domStyle.set(this.sumButton.domNode, 'display', 'none');
            // }
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // 編集グリッドの初期化
            this.store = Trackable.create(new Memory({
                idProperty: 'empGatherId',
                data: {
                    items: []
                }
            }));
            // 全件表示用のcollectionをグリッドにセットする
            // （テンプレートHTML内のdata-dojo-attach-point="grid"でグリッドをthis.gridに紐付け済み）
            var filter = new this.tempStore.Filter();
            filter = filter.eq('disasterId', this.disasterId);
            filter = filter.eq('disasterPrevId', this._disasterPreventionId);
            filter = filter.eq('municipalityCd', this._municipalityCd);
            // 組織カテゴリコード
            // ※大阪市の場合、組織カテゴリーコードが未設定となるため、明示的にnullを設定
            this._deptCd = this._deptCd === undefined ? null : (this.dpDeptCd === '' ? null : this._deptCd);
            filter = filter.eq('dpDeptCd', this._deptCd);

            // 大阪市全体の集計フラグ
            filter = filter.eq('sumFlg', this._sumFlg);

            // 大阪市全体としての報告日時
            if(this.DisasterPrevInfItems.hqSetTimestamp !== '-'){
                filter = filter.eq('reportTimestamp', new Date(this.DisasterPrevInfItems.reportTimestamp).getTime());
            // }else{
            //     // 報告日時が未設定の場合は、現在（暫定）
            //     filter = filter.eq('reportTimestamp', new Date().getTime());  
            } else {
                // 報告日時が未定かつ集計フラグONの場合は、集計不可
                if (this._sumFlg === 1){
                    var infoDialogMsg = '報告日時が未設定のため、設置済の最新体制の参集人数を集計できません。';
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : infoDialogMsg
                    });
                    this.infoDialog.show();
                    return false;
                }
            }

            this.tempStore.filter(filter)
                // APIで取得したデータをTrackableStoreに代入する
                .forEach(lang.hitch(this, function(item){
                    this.store.put(item);
                // collectionをグリッドにセットする
                })).then(lang.hitch(this, function(){
                    this.grid.set('collection', this.store.track());
                }));

            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.showDetailDialog(evt.item);
            }));

            // 市町村ユーザーの場合(政令指定都市を除く)概況リンクを不可視
            /*if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
                UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
                domStyle.set(this.overviewLabel, 'display', 'none');
            }*/
            // 区本部ユーザ・所属本部ユーザは、自組織に属する情報のみ更新可能
            if((UserInfo.getRoleCd() === ACL.KUHONBU_USER || UserInfo.getRoleCd() === ACL.XXHONBU_USER) &&
                UserInfo.getOrganization().deptCd !== this._deptCd ) {
                this.submitButton.set('disabled', true);
                this.updateButton.set('disabled', true);
                this.sumButton.set('disabled', true);
            }
        },

        /**
         * 詳細ダイアログを表示する。
         * @param {Object} item 参照する行のデータ
         */
        showDetailDialog: function(item) {
            this.innerDetailDialog.initDialog(this, item);
            this.detailDialog.show();
        },

        // /**
        //  * 詳細ダイアログのフォームが投稿された際の動作を設定する。
        //  */
        // initDetailDialog: function() {
        //     // 詳細ダイアログの最初の子要素が詳細画面
        //     var dialog = this.detailDialog;
        //     var page = dialog.getChildren()[0];

        //     var self = this;
        //     // 詳細画面の組織選択のchangeイベントを受け取る
        //     page.EmployGatheringOrganizationSelector.on('change', lang.hitch(this, function(evt) {
        //         this.grid.collection.fetch().forEach(function(item) {
        //             if(item.empCategory===evt.value){
        //                 self.innerDetailDialog.onChangeOrgSelector(item);
        //             }
        //         });
        //     }));

        //     // 詳細画面のsendイベントを受け取る
        //     page.on('send', lang.hitch(this, function(evt) {
        //         this.chain.confirmPut(function(chain) {
        //             var sendData = evt.value;
        //             // 災害IDをセット
        //             sendData.disasterId = this.disasterId;
        //             if(!sendData.empGatherId){
        //                 // 新規登録→IDを削除
        //                 delete sendData.empGatherId;
        //                 // 防災体制IDをセット
        //                 sendData.disasterPrevId = this.DisasterPrevInfItems.disasterPrevId;
        //                 // 市町村コードをセット
        //                 sendData.municipalityCd = this.DisasterPrevInfItems.municipalityCd;
        //             }
        //             Loader.wait(this.store.add(sendData)).then(lang.hitch(this, function() {
        //                 this.initGrid();
        //                 chain.infoComplete(lang.hitch(this, function() {
        //                     // this.distributionType = '02';
        //                     dialog.hide();
        //                     chain.hide();
        //                 }));
        //             }), function(err) {
        //                 // 失敗時
        //                 if(err.response.data && typeof(err.response.data) === 'string'){
        //                     err.response.data = json.parse(err.response.data);
        //                 }
        //                 chain.infoError(err);
        //             });
        //         });
        //     }));
        // },

        /**
         * グリッドから登録
         */
        onSubmit: function() {
            var recItems = [];
            this.grid.collection.forEach(lang.hitch(this, function(item) {
                // 新規登録の場合は、IDをnullとする
                var empGatherId = parseInt(item.empGatherId,10)!==0 ? item.empGatherId : null;
                // IDを持つ、又は入力ありの場合、更新対象
                if(empGatherId!==null && item.gatherDisaster !== null ||
                    (item.empGather!==null || item.empWork !== null || item.empOther !== null )||
                (item.reportTimestampMS!==null || item.comment!==null)){
                        recItems.push({
                            empGatherId: empGatherId,
                            empCategory: item.empCategory,
                            member:item.member,
                            empGather : item.empGather,
                            empWork : item.empWork,
                            empOther : item.empOther,
                            gatherDisaster : item.gatherDisaster,
                            gatherFlood: 0,              //  市町村画面は入力無し
                            reportTimestamp: item.reportTimestampMS,
                            deptCd: item.deptCd,
                            sectCd: item.sectCd,
                            unitCd: item.unitCd,
                            comment: item.comment,
                            version : item.version,
                            orgName : item.orgName
                        });
                }
            }));

            // 固有のバリデーションを実施
            if(!this.validate(recItems)) {
                return false;
            }

            // 以下、バリデーションチェック時、メッセージ表示用要素削除
            recItems.forEach(function(item){
                delete item.orgName;
            });

            // 配置人員一覧画面からは大阪府連携しないよう制御
            // var provideFlg = false;
            // 大阪市 かつ 本部設置かどうか
            var provideFlg = (this._municipalityCd === config.municInfo.cityMunicCd && 
                                this.DisasterPrevInfItems.deptCd === null &&
                                this.DisasterPrevInfItems.hqSetFlg === HqSetFlg.ESTABLISH) ? true : false;

            // 配信対象であれば配信内容確認画面を表示
            if (provideFlg) {
                var sendData = {
                    disasterId : this.disasterId,
                    disasterPrevId : this._disasterPreventionId,
                    municipalityCd : this._municipalityCd,
                    empGatheringList : recItems,
                    updateFlg : this._updateFlg // 訂正ボタンフラグ（0:更新、1:訂正）
                };
                // 登録情報確認ダイアログ
                this.showConfirmDialog(sendData);
                return;
            } else {
                // 登録
                // var message = (this._updateFlg === 0 ? '更新します。' : '訂正します。') + 'よろしいですか？';
                // this.chain.confirm(message, function(chain) {
                this.chain.confirmPut(function(chain) {
                    var sendData = {
                        disasterId : this.disasterId,
                        disasterPrevId : this._disasterPreventionId,
                        municipalityCd : this._municipalityCd,
                        empGatheringList : recItems,
                        updateFlg : this._updateFlg // 訂正ボタンフラグ（0:更新、1:訂正）
                    };

                    Loader.wait(this.registerStore.add(sendData)).then(lang.hitch(this, function() {
                        this.initGrid();
                        chain.infoComplete(lang.hitch(this, function() {
                            // this.distributionType = '02';
                            chain.hide();
                            // 大阪市の参集人数登録の場合
                            // →配置人員一覧画面からは大阪府連携しないよう制御
                            // if(this._municipalityCd === config.municInfo.cityMunicCd && 
                            //     this.DisasterPrevInfItems.deptCd === null){
                            //     var msg = '登録・更新した配置人数を大阪府に連携する場合は、<br>';
                            //         msg += '参集体制管理の一覧画面に戻って詳細画面を表示し、';
                            //         msg += '訂正または更新を実施してください。';
                            //     chain.info(msg);
                            // } else {
                            //     // 画面を更新（大阪市以外の場合）
                            //     location.reload();
                            // }
                            // 画面を更新
                            // location.reload();
                        }));
                        Router.moveTo('employgathering', 
                            {municipalityCd : this._municipalityCd, dpDeptCd: this._deptCd, 
                                viewMode : this._viewMode});
                    }), function(err) {
                        if (err && err.response && err.response.status === 409) {
                            // リスト参集情報が1件でも登録・更新されている場合はエラー（409）
                            var message =  '<br>' +'エラーが発生しました。' + '<br>' +
                                '同一のデータを別の方が更新しています。' +  '<br>' +
                                '「OK」を押して最新の状態をご確認の上、' +  '<br>' +
                                '再度処理をお願いします。' +  '<br>' +
                                '(OKボタンを押すと画面が更新されます)';
                            new InfoDialog({
                                title: 'エラー',
                                content: message,
                                showCancel: false,
                                // 「OK」ボタン押下後はページをリロードする
                                onOK: function() {
                                    chain.hide();
                                    location.reload();//最新報でない場合も最新報でリロードしてしまう
                                }
                            }).show();
                        } else {
                            // 失敗時
                            chain.infoError(err);
                        }
                    });
                });
            }
        },

        //パンくずリストのボタンを押下したときの挙動
        onDisasterPreventionListPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('disasterprevention', {viewMode : this._viewMode});
        },

        //パンくずリストのボタンを押下したときの挙動
        onDisasterPreventionStatusPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('disasterprevention/status', 
            {municipalityCd : this._municipalityCd, dpDeptCd: this._deptCd, viewMode : this._viewMode});
        },

        setDisasterPrevInf: function(data){
            this.DisasterPrevInfItems = {};
            this.DisasterPrevInfItems.disasterId = data.disasterId;
            this.DisasterPrevInfItems.disasterPrevId = data.disasterPrevId;
            this.DisasterPrevInfItems.seqNum = data.seqNum;
            this.DisasterPrevInfItems.municipalityCd = data.municipalityCd;
            this.DisasterPrevInfItems.municipalityName = data.municipalityName;
            this.DisasterPrevInfItems.deptCd = data.deptCd;
            this.DisasterPrevInfItems.deptName = data.deptName;
            this.DisasterPrevInfItems.reportTimestamp = data.reportTimestamp.replace(/-/g, '/');
            this.DisasterPrevInfItems.disasterTypeName = DisasterType.get(data.disasterType).label;
            this.DisasterPrevInfItems.statusName = data.statusName;
            this.DisasterPrevInfItems.comment = data.comment === null ? '' : data.comment;
            this.DisasterPrevInfItems.hqName = data.hqName === null ? '' : data.hqName;
            if(data.hqSetTimestamp !== null){
                this.DisasterPrevInfItems.hqSetTimestamp = data.hqSetTimestamp.replace(/-/g, '/');
            }else{
                this.DisasterPrevInfItems.hqSetTimestamp = '-';
            }
            if(data.hqAbolishedTimestamp !== null){
                this.DisasterPrevInfItems.hqAbolishedTimestamp = data.hqAbolishedTimestamp.replace(/-/g, '/');
            }else{
                this.DisasterPrevInfItems.hqAbolishedTimestamp = '-';
            }
            this.DisasterPrevInfItems.hqSetPlace = data.hqSetPlace === null ? '' : data.hqSetPlace;
            this.DisasterPrevInfItems.hqSetFlg = data.hqSetFlg === null ? '' : data.hqSetFlg;
            this.DisasterPrevInfItems.activeFlg = data.activeFlg;
            this.initDisasterPreventionStatusInf();
        },

        onChangeDisasterPrevList: function(evt){
            // グリッドのロード前に報告番号を切り替えるとグリッドが崩れるため、
            // グリッド右下の「全N件」が取得できない場合（ブランク）は処理中止
            if(this.grid.totalNode && this.grid.totalNode.innerHTML === '&nbsp;'){
                return;
            }
            var list = [];
            var disasterPrevIndex;
            var listCount = 0;
            // 変更前と変更後が同一ではないことを確認
            if (this.DisasterPrevInfItems.disasterPrevId !== evt) {
                var promise = Requester.get('/api/disasterPrevention/empGathering?disId=' + this.disasterId +
                    '&muniCd=' + this._municipalityCd + '&dpDeptCd=' + this._deptCd, {
                    preventCache: false
                }).then(lang.hitch(this,function(data) {
                    array.forEach(data, function(plan) {
                        var prevList = {value: plan.disasterPrevId,
                        label: '第' + plan.seqNum + '報'};
                        list.push(prevList);
                        if(evt === plan.disasterPrevId) {
                            disasterPrevIndex = listCount;
                        }
                    listCount++;
                    });
                    this.setDisasterPrevInf(data[disasterPrevIndex]);
                    this._disasterPrevList = list;
                    this._disasterPreventionId = this.DisasterPrevInfItems.disasterPrevId;
                    this._municipalityCd = this.DisasterPrevInfItems.municipalityCd;
                    this.initGrid();
                    this.disasterPrevList.set('options', this._disasterPrevList);
                    this.disasterPrevList.set('value', this._disasterPreventionId);
                }), lang.hitch(this, function(err){
                    console.error(err);
                }));
                Loader.wait(promise);
            }
        },
        /**
         * 大阪市の参集人員全体を集計する
         */
        onClickSumButton: function(){
            this._sumFlg = 1; // 集計フラグON
            // this._disasterPrevList = null;
            var list = [];
            var promise = Requester.get('/api/disasterPrevention/empGathering?disId=' + this.disasterId +
                '&muniCd=' + this._municipalityCd + '&dpDeptCd=' + this._deptCd, {
                preventCache: false
            }).then(lang.hitch(this,function(data) {
                array.forEach(data, function(plan) {
                    var prevList = {value: plan.disasterPrevId,
                    label: '第' + plan.seqNum + '報'};
                    list.push(prevList);
                });
                this._disasterPrevList = list;
                this.setDisasterPrevInf(data[0]);
                this._disasterPreventionId = this.DisasterPrevInfItems.disasterPrevId;
                this._municipalityCd = this.DisasterPrevInfItems.municipalityCd;
                this._deptCd = this.DisasterPrevInfItems.deptCd;
                this.initGrid();
                this._sumFlg = 0; // 集計フラグ戻し
                this.disasterPrevList.set('options', this._disasterPrevList);
                this.disasterPrevList.set('value', this._disasterPreventionId);
            }), lang.hitch(this, function(err){
                console.error(err);
            }));

            Loader.wait(promise);
        },

        onChangeOrganizationSelector: function(evt){
            var list = [];
            // 変更前と変更後が同一ではないことを確認
            if (('D' + this.DisasterPrevInfItems.deptCd) !== evt.value) {
                var promise = Requester.get('/api/disasterPrevention/empGathering?disId=' + this.disasterId +
                    '&muniCd=' + this._municipalityCd + '&deptCd=' + evt.value.slice(1), {
                    preventCache: false
                }).then(lang.hitch(this,function(data) {
                    array.forEach(data, function(plan) {
                        var prevList = {value: plan.disasterPrevId,
                        label: '第' + plan.seqNum + '報'};
                        list.push(prevList);
                    });
                    this.setDisasterPrevInf(data[0]);
                    this._disasterPrevList = list;
                    this._disasterPreventionId = this.DisasterPrevInfItems.disasterPrevId;
                    this._municipalityCd = this.DisasterPrevInfItems.municipalityCd;
                    this._deptCd = this.DisasterPrevInfItems.deptCd;
                    this.initGrid();
                    this.disasterPrevList.set('options', this._disasterPrevList);
                    this.disasterPrevList.set('value', this._disasterPreventionId);
                }), lang.hitch(this, function(err){
                    console.error(err);
                }));
                Loader.wait(promise);
            }
        }
    });
});
