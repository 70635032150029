/**
 * 利用者登録用ダイアログモジュール。
 * @module app/user/UserRegisterDialog
 */
define([
    'module',
    'leaflet',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/UserRegsiterDialog.html',
    'idis/consts/ACL',
    'idis/view/Loader',
    'idis/service/Requester',
    'app/config',
    './_UserDialogBase',
    './mobilizationType',
    'idis/service/GeoService',
    'idis/model/UserInfo',

    // 以下、変数で受けないモジュール
    'app/view/form/MunicipalitySelector',
    // 'app/view/form/RegionSelector',
    'app/view/form/DeptSelector',
    'app/view/form/UserOrganizationSelector',
    'app/view/form/OfficialPositionSelector',
    'idis/view/form/RadioGroup',
    'idis/view/form/WordCountTextarea'
], function(module, leaflet, declare, lang, domStyle, template, ACL, Loader,
    Requester, config, _UserDialogBase, mobilization, GeoService, UserInfo) {

    return declare(module.id.replace(/\//g, '.'), _UserDialogBase,
    {
        // テンプレート文字列
        templateString: template,

        /**
         * 緯度経度・座標変換用
         * @type {Object}
         */
        _geoService: null,

        constructor: function() {
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 利用者を新規登録する。
         */
        onSubmit: function() {
            try {
                console.debug('[登録]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                delete value.station;

                // 緊急時参集所属
                if(value.roleCd.substr(0,3) === 'R03' && value.roleCd !== ACL.IPPAN_USER){
                    // 非市職員の場合、選択値は無効
                    value.emergencyOrganization ='';
                } else {
                    // 市職員の場合、入力値は無効
                    value.emergencyOrganizationName ='';
                }
                
                value.newUserId = value.userId;
                delete value.userId;
                delete value.passwordConfirm;

                // this.fillAddLatLng().then(function(){
                //     value.latitude = this._lat;
                //     value.longitude = this._lng;
                //     value.address = this._address;
                // });

                value.officialMailAddress = 
                    value.officialMailAddress ?  value.officialMailAddress.trim() : value.officialMailAddress;
                value.personalMailAddress = 
                    value.personalMailAddress ?  value.personalMailAddress.trim() : value.personalMailAddress;

                // メールアドレス確認要否フラグ（要:1, 否:0）
                value.isMailAddChk = (!value.officialMailAddress && !value.personalMailAddress) ? '0' : '1';

                // メールアドレス確認要否によりダイアログに表示する登録後メッセージを編集
                var message = '利用者情報を登録しました。<br>';
                if(value.isMailAddChk === '1'){
                    var oMailAddress = !value.officialMailAddress ? '（未設定）' : value.officialMailAddress;
                    var pMailAddress = !value.personalMailAddress ? '（未設定）' : value.personalMailAddress;
                    message += 'メールアドレスが登録されました。以下の登録アドレスに確認メールを送信しますか？<br>';
                    message += '&nbsp;庁内アドレス：' + oMailAddress + '<br>';
                    message += '&nbsp;個人アドレス：' + pMailAddress + '<br><br>';
                }
                value.message = message;
            
                this.emit('register', {value: value});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * ダイアログを初期化する。（新規登録時）
         */
        initDialog: function() {
            this.reset();

            this.initSelectBox();
            this.setOrganization();
            this.organizationSelector.restoreRefresh();
            this.emergencyOrganization.restoreRefresh();

            // 動員種別にモデルをセット
            this.mobilizationType.set('store', mobilization);
            // 動員区分をクリア
            this.mobilizationSection.set('value', '');
            // 各項目の表示を初期化
            this.supportStationSelector.set('noAllButton', false);
            this.officialPositionSelector.set('noAllButton', false);
            this.officialPositionName.set('disabled', false);
            this.emergencyOrganizationName.style.display = 'none';
            domStyle.set(this.emergencyOrgCdBlock, 'display', '');
            this.emergencyOrganization.set('noAllButton', false);
            this.mobilizationSection.set('disabled', false);
            this.mobilizationType.set('disabled', false);
            this.officialMailAddress.set('disabled', false);
            this.personalMailAddress.set('disabled', false);
        },

        // ログインユーザの組織をセットする
        setOrganization: function () {
			var role = UserInfo.getRoleCd();
            this.stationSelector.set('value', UserInfo.getOrganization().deptCd);
			if (role !== ACL.ADMIN_USER) {
		        var org = UserInfo.getOrganization().unitCd !== null ? 'U' + UserInfo.getOrganization().unitCd :
	                      UserInfo.getOrganization().sectCd !== null ? 'S' + UserInfo.getOrganization().sectCd : 
	                      'D' + UserInfo.getOrganization().deptCd;
		        this.organizationSelector.set('value', org);
				if (UserInfo.getOrganization().unitCd !== null){
					domStyle.set(this.organizationSelector.selectButton.domNode, 'display', 'none');
				}
			}
	    },
			
        // 役割セレクトボックスの初期化を行う
        initSelectBox: function () {
            var promise = Requester.get('/api/roles');
            Loader.wait(promise).then(lang.hitch(this, function (data) {
                // セレクトボックスに設定するデータの配列を作成
                var newOptions = [{
                    label: '--選択してください--',
                    value: ''
                }];
                if (UserInfo.getRoleCd() === ACL.ADMIN_USER){
	                // ログインユーザがシステム管理者の場合、サーバーレスポンスの各要素をselectへ追加
					data.items.forEach(function (object) {
						// if(object.roleCd !== ACL.ADMIN_USER){
							newOptions.push({
								label: object.roleName,
								value: object.roleCd
							});
						// }
					});
				}else if(UserInfo.getRoleCd() === ACL.SYSMGR_USER){
	                // ログインユーザが全体管理者の場合、システム管理以外の要素をselectへ追加
	                data.items.forEach(function (object) {
						if(object.roleCd !== ACL.ADMIN_USER){
		                    newOptions.push({
		                        label: object.roleName,
		                        value: object.roleCd
		                    });
						}
	                });
				}else if(UserInfo.getRoleCd() === ACL.SHMGR_USER){
	                // ログインユーザが市本部管理者の場合、市本部管理者・一般職員の要素をselectへ追加
	                data.items.forEach(function (object) {
						if(object.roleCd === ACL.SHMGR_USER || object.roleCd === ACL.IPPAN_USER){
		                    newOptions.push({
		                        label: object.roleName,
		                        value: object.roleCd
		                    });
						}
	                });
				}else if(UserInfo.getRoleCd() === ACL.XXMGR_USER){
	                // ログインユーザが所属管理者の場合、所属管理者・一般職員・応援職員・自主防災組織・福祉避難所・施設管理事業者の要素をselectへ追加
	                data.items.forEach(function (object) {
						if(object.roleCd === ACL.XXMGR_USER || object.roleCd === ACL.IPPAN_USER || 
                            object.roleCd === ACL.AID_USER || object.roleCd === ACL.VPD_USER || 
                            object.roleCd === ACL.WEL_USER || object.roleCd === ACL.FAC_USER){
		                    newOptions.push({
		                        label: object.roleName,
		                        value: object.roleCd
		                    });
						}
	                });
				}
                this.roleCdSelect.set('options', newOptions);
            }));
        },
	
        /**
         * 住所の位置を地図上にポイントします。
         */
        fillAddLatLng: function() {
            var address = this.address.value;
            var dfd = null;
            // 住所入力がある場合
            if(address) {
                // 緯度経度に入力がない場合
                // 住所からgeocoding
                //var prefIndex = address.indexOf('県');
                //県名がない場合、他県の地名を探し出す可能性があるので県名をつける
                //if(prefIndex === -1){
                //    address = config.municInfo.prefName + address;
                //}
                console.debug('start geocoding');
                
                if (this._geoService === null) {
                    this._geoService = new GeoService();
                }
                dfd = this._geoService.geocode(address).then(lang.hitch(this, function(results) {
                    if(results.length > 0) {
                        this._lat = results[0].latlng.lat;
                        this._lng = results[0].latlng.lng;
                        this._address = address;
                    } else {
                        console.debug('address is not correct');
                        dfd = dfd = this.setDefAddLatLng();
                    }
                })
                );
            } else {
                dfd = this.setDefAddLatLng();
            }
            console.debug('end geocoding (lat:' + this._lat + ', lng:' + this._lng + ')' );
            return dfd;
        },

        setDefAddLatLng: function() {
            return Requester.get('/api/municipalities/' + UserInfo.getSelectedMunicipalityCd(), {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(item) {
                this._lat = item.latitude;
                this._lng = item.longitude;
                this._address = item.address;
            }, function(error) {
                console.log(error);
            });
        }
    });
});
