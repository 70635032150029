/**
* Spectee詳細画面用モジュール。
* @module app/spectee/SpecteeAdminDetailPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/SpecteeAdminDetailPage.html',
    'dojo/topic',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/model/UserInfo',
    'app/model/DisasterInfo',
    'idis/consts/ACL',
    'idis/view/dialog/DialogChain',
    './_SpecteeAdminPageBase',
    '../config',
    // 以下、変数として受け取らないモジュール
    'dijit/form/Form',
    'dijit/form/CheckBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    '../view/form/DisasterSelector',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    './SpecteeAdminDetailGrid',
    './SpecteeMunicAdminDetailGrid'
], function(module, declare, lang, template, topic, _PageBase, Loader, Locator,
    Router, Requester, UserInfo, DisasterInfo, ACL, DialogChain, _SpecteeAdminPageBase, config) {
    /**
    * Spectee情報詳細画面。
    * @class SpecteeAdminDetailPage
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _SpecteeAdminPageBase,
        /** @lends module:app/spectee/SpecteeAdminDetailPage~SpecteeAdminDetailPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--spectee',

        /**
        * データ格納用オブジェクト
        * @type {module:dstore/Store}
        */
        store: null,
        /**
         * 災害ID
         */
        _disasterId: null,
        /**
         * 位置情報
         * @type {Object}
         */
        _latlng: null,
        /**
         * 事案ID
         */
        caseId: null,
        /**
         * page種別(詳細画面)
         * @type {String}
         */
        PAGE_TYPE: 'DETAIL_PAGE',
        /**
         * 使用するgrid名
         * @type {String}
         */
        GRID_NAME: null,
        /**
         * 広島県の緯度経度
         */
        INIT_LATLNG: {lat: config.map.latitude, lng: config.map.longitude},
        /**
         * 位置情報確定レベル(緯度経度・住所なし)
         * @type {String}
         */
        LATLNG_COMFIRM_LEVEL_NONE: '0',
        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);

            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                console.warn('災害IDが設定させていません。');
            }
            console.debug('現在のログインユーザID：' + UserInfo.getId());
            console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());

            // ユーザ情報を取得し、更新権限の有無によりgrid名を決める
            this.GRID_NAME = UserInfo.hasWriteAuthz('F05012') && UserInfo.getOrganization().deptCd === config.dept.crisisManagementDeptCd ? 'grid' : 'municGrid';
            this.caseId = Locator.getQuery().caseId;
        },

        /**
         * buildRendering
         * DOMノードを生成するためのメソッド
         */
        buildRendering: function() {
            this.inherited(arguments);
            // this.own(this.formDialog);
        },

        /**
         * postCreate
         */
        postCreate: function() {
            console.debug('postCreate');
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            // 使用するgridを出し分ける
            this.setGridStyle();
            // グリッドを初期化する
            this.initGrid(this.PAGE_TYPE);
            // 地図を初期化する
            this.initMap(this.INIT_LATLNG.lat, this.INIT_LATLNG.lng);
            this.own(topic.subscribe('idis/view/form/GeocodingForm::geocoded', lang.hitch(this, function(payload) {
                this._latlng = payload.latlng;
                this.map.setView(payload.latlng, 11);
            })));

            this.form.resize();
            // 初期表示用にSpecteeAPIにリクエストする
            var query = this.buildQueryForOneCase();
            this.fetchSpecteeInfo(query);
            // 定期処理を開始する
            this.startTimerForOneCase();
        },

        /**
         * Specteeへの定期取得処理を開始する。
         */
        startTimerForOneCase: function(){
        //関数fetchSpecteeInfo()を60000ミリ秒間隔で呼び出す
            this.specteeTimer =
            setInterval(lang.hitch(this, function() {
                var query = this.buildQueryForOneCase();
                this.fetchSpecteeInfo(query);
            }), 60000);
        },

        /**
         * Specteeより、特定の事案IDの全報情報を取得する。
         */
        fetchSpecteeInfo: function(query){
            Requester.get('/api/spectee/', {
                query: query
            }).then(lang.hitch(this, function(data) {
                console.log('SpecteeよりSNS情報を取得');
                // APIから返却されるレスポンスコードを元に処理する
                if(data.status === '200') {
                    this.lastFetchTime = data.fetchTime;
                    this.updateGrid(data.data);
                }else {
                    this.chain.info('SpecteeよりSNS情報を取得できませんでした。一分後に情報を自動取得します。', 'エラー');
                }
            }), lang.hitch(this, function(error) {
                this.chain.infoError(error);
            }));
        },

        /**
         * 特定事案のSpectee情報取得用のqueryを組み立てる。
         */
        buildQueryForOneCase : function() {
            var query = {
                disasterId : this._disasterId,
                caseId : this.caseId,
                lastFetchTime : this.lastFetchTime
            };
            return query;
        },

        /**
         * お気に入り情報を登録する。
         */
        registerFavorite: function(item, lat, lng, latlngComfirmLevel) {
            // 災害名が選択されていない場合は処理しない
            if(!this._disasterId) {
                this.chain.info('災害名を選択してください', 'エラー');
                return;
            }
            var msg = latlngComfirmLevel !== this.LATLNG_COMFIRM_LEVEL_NONE ? '登録します。よろしいですか？' :
                '登録します。<br>住所から緯度経度を特定できなかったため、<br>地図上に情報が表示されませんがよろしいですか？';
            this.chain.confirm(msg, lang.hitch(this, function(chain) {
                var value = this._toSendValue(item, lat, lng, latlngComfirmLevel);
                Loader.wait(
                    // 取消処理を実行して取消後の返り値を保持する。
                    Requester.post('/api/spectee/favorite/', {
                        data: value
                    })).then(lang.hitch(this, function() {
                        chain.infoComplete();
                        var query = this.buildQueryForOneCase();
                        this.fetchSpecteeInfo(query);
                }), function(err) {
                    // 登録失敗
                    chain.infoError(err);
                });
            }));
        },

        /**
         * お気に入り情報を登録解除する。
         */
        removeFarorite: function(item) {
            // 災害名が選択されていない場合は処理しない
            if(!this._disasterId) {
                this.chain.info('災害名を選択してください', 'エラー');
                return;
            }
            var message = '登録を解除します。<br>' + 'よろしいですか？';
            this.chain.confirm(message, lang.hitch(this, function(chain) {
                Loader.wait(
                    // 取消処理を実行して取消後の返り値を保持する。
                    Requester.del('/api/spectee/favorite/' + item.newsId + '/' + this._disasterId, {
                })).then(lang.hitch(this, function() {
                    chain.infoComplete();
                    var query = this.buildQueryForOneCase();
                    this.fetchSpecteeInfo(query);
                }), function(err) {
                    // 削除失敗
                    chain.infoError(err);
                });
            }));
        },

        /**
         * Spectee管理画面に遷移する。
         */
        onSpecteeAdminPageLinkClick: function(evt) {
            if(this.marker) {
                this.removeMark();
            }
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('spectee');
        }
    });
});
