/**
 * 防災体制・報告の登録ダイアログ用モジュール。
 * @module app/disasterprevention/view/DisasterPreventionRegisterPage
 */
define([
    'module',
    'app/config',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/DisasterPreventionRegisterPage.html',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    './_DisasterPreventionDialogPageBase',
    './model/DisasterPreventStatus',
    './model/HqSetFlg',
    'idis/consts/ACL',
    'app/model/DisasterInfo',
    // 以下、変数から参照されないモジュール
    'dijit/form/ValidationTextBox',
    'dijit/form/NumberTextBox',
    'dijit/form/CheckBox',
    'dijit/form/DateTextBox',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup',
    'app/view/form/DisasterPreventionMunicOrgSelector',
    'dijit/form/Select'
], function(module, config, declare, lang, domStyle, template, UserInfo, USER_TYPE,
    _PageBase, PrevStatus, HqSetFlg, ACL, DisasterInfo) {
    /**
     * 防災体制情報登録画面。
     * @class DisasterPreventionRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/disasterprevention/view/DisasterPreventionRegisterPage~DisasterPreventionRegisterPage */ {
        // テンプレート文字列
        templateString: template,

        // 市町村名
        muniName : null,

        // DOM要素構築後に呼ばれる
        postCreate: function() {
            // 自治体の変更時、体制状況を初期化する
            this.own(this.municipalitySelector.on('change', lang.hitch(this, function() {
                if(this.municipalitySelector.value){
                    this.setStatus();
                }
            })));
            // 災害種別の変更時、体制を初期化する
            //this.own(this.disasterTypeSelect.on('change', lang.hitch(this, function() {
            //    this._disasterType = this.disasterTypeSelect.value;
            //    if(this.municipalitySelector.value){
            //        this.setStatus();
            //    }
            //})));
            // 本部設置状況の変更により、本部設置情報欄の表示有無を切り替える
            this.own(this.hqSetFlg.on('change', lang.hitch(this, function(e){
                //本部名を入力
                this._setDomStyle(e.target.value);
            })));
        },

        onSubmit: function() {
            try {
                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');

                this._setDomStyle(value.hqSetFlg);

                value.attachFileList = this._attachFileList;
                value.disasterId = this._disasterId;
                value.reportUserId = UserInfo.getId();

                // 体制登録対象自治体の区市町村コード
                // ※大阪市の組織の体制は、区市町村コードで区別できないため、
                //   便宜的にidに組織コード、parentIdに区市町村コードを設定している。
                //   idが組織コードの場合は、parentIdの値を区市町村コードに再設定する。
                if(this.municipalitySelector.get('value').substr(0,1) ==='D'){
                    value.municipalityCd = this.getDpInfo(this.municipalitySelector.get('value'),'parentId');
                }

                // 体制登録対象自治体の組織コード
                // municipalitySelectorのtype属性に設定された組織カテゴリコードをセット
                value.dpDeptCd = this.getDpInfo(this.municipalitySelector.get('value'),'type');

                //体制状況から自動で入力する値をセット
                var statusCd = this.status.value;
                // value.statusName = PrevStatus.get(statusCd).label;
                value.statusName = value.statusSelect === '04' ? '4号動員体制' :
                    value.statusSelect === '03' ? '3号動員体制' :
                    value.statusSelect === '02' ? '2号動員体制' :
                    value.statusSelect === '01' ? '1号動員体制' : '';
                delete value.statusSelect;

                if(value.hqSetFlg===HqSetFlg.ESTABLISH){
                    value.hqSetType = PrevStatus.get(statusCd).hqType;
                    // 本部種別名
                    value.hqName = PrevStatus.get(statusCd).label;
                }
                
                // 配信フラグ（大阪市かつ災害対策本部の場合、'1'）
                value.provideFlg = this._provideFlg;

                // 大阪府災害ID
                value.extraDisasterId = DisasterInfo.getExtraDisasterId();

                this.emit('post-send', {value: value});

            } catch (e) {
                console.error(e);
            }
            return false;
        },
        // 対策本部設置状況の値が変更された時の処理
        onChangehqSetFlg: function (evt) {
            // 設置日時の表示を、対策本部設置状況の設置・未設置により表示・非表示を制御
            if(evt.target.value) {
                if(evt.target.value === '0') {
                    this.hqSetTimestamp.reset();
                }
                domStyle.set(this.hqInfNode, 'display', evt.target.value !== '0' ? '' : 'none');
            }
        },
        /**
         * ダイアログを初期化する。（新規登録時）
         */
        initDialog: function(parent) {
            // フォーム初期化
            this.form.reset();
            // 報告日時
            this.reportTimestamp._date.displayedValue = null;
            this.reportTimestamp._time.displayedValue = null;
            // 設置日時
            this.hqSetTimestamp._date.displayedValue = null;
            this.hqSetTimestamp._time.displayedValue = null;
            domStyle.set(this.hqInfNode, 'display', 'none');
            // // 廃止日時
            // this.hqAbolishedTimestamp._date.displayedValue = null;
            // this.hqAbolishedTimestamp._time.displayedValue = null;
            // 災害種別
            this.disasterTypeSelect.set('value', this._disasterType);
            // 報告者所属
            var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
                UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
                UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');
            this.organization.set('value', orgCd);
            // 報告者
            this.reportUserName.innerHTML = UserInfo.getName();
            // 体制登録対象の自治体と組織コード
            if(parent.municipalityCd) {
                // 一覧画面など、呼出し元がある場合
                this.municipalitySelector.set('value', parent.municipalityCd);
            } else {
                // this.municipalitySelector.set('value', UserInfo.getOrganization().deptCd);
            //     // this.municipalitySelector.set('value',
            //     //     UserInfo.getMunicipalityCd() || UserInfo.getMunicipalityCds()[0]);
                //初期値
                if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
            //     // if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY || 
            //     //     UserInfo.getOrganization().deptCd === config.dept.crisisManagementDeptCd || 
            //     //     UserInfo.getRoleCd() === 'R01001'){
            //         // ・区市町村ユーザーの場合、「区市町村コード」を初期値に設定する
            //         // ・ユーザーが危機管理室またはシステム管理者の場合、
            //         // 「大阪市」の体制登録のため「区市町村コード」を設定
                    this.municipalitySelector.set('value', UserInfo.getMunicipalityCd());
                } else {
                    // 上記以外の場合（大阪市の組織）「組織カテゴリコード」を初期値に設定する
                    this.municipalitySelector.set('value', UserInfo.getOrganization().deptCd);
                }
                // this.municipalitySelector.set('value', UserInfo.getOrganization().deptCd);
            }

            // // 自治体
            // if(parent.municipalityCd !== null) {
            //     // 一覧画面からの遷移
            //     this.municipalitySelector.set('value', parent.municipalityCd);
            //     this.municipalitySelector.set('noAllButton', true);
            // } else {
            //     this.municipalitySelector.set('value',
            //         UserInfo.getMunicipalityCd() || UserInfo.getMunicipalityCds()[0]);
            //     if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
            //         // 市町村ユーザーの場合(政令指定都市を除く)編集不可
            //         if(UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
            //             this.municipalitySelector.set('noAllButton', true);
            //         } else {
            //             // 政令指定都市ユーザーの場合市内を選択
            //             this.municipalitySelector.set('prefFlg', '0');
            //         }
            //     }
            // }

            // ログインユーザがシステム管理者・全体管理者・市本部ユーザの場合
            // 代理登録ができるようセレクタを表示（それ以外は非表示）   
            // if(UserInfo.getRoleCd() === ACL.ADMIN_USER) {
            if (!(UserInfo.getRoleCd() === ACL.ADMIN_USER || UserInfo.getRoleCd() === ACL.SYSMGR_USER ||
                UserInfo.getRoleCd() === ACL.SHIHONBU_USER)){
                this.municipalitySelector.set('noAllButton', true);
            }
            this.municipalitySelector.expandAll();

            this._parent = parent;
            this._attachFileList = [];
            this._provideFlg = false;

            // 体制状況にモデルをセット
            this.status.set('store', PrevStatus);
            this.setStatus();
        }
    });
});

