define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'dojo/_base/lang',
    'app/util/DateFormatter',
    'idis/model/UserInfo'
    // 以下、変数として受け取らないモジュール
  ], function(module, declare, IdisGrid, helper, lang, DateFormatter, UserInfo) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
  
      // ルート要素に付与されるCSS
    //   baseClass: 'idis-Page idis-Page--comments',
  
      /**
       * 各列の定義
       * @type {Object[]}
       */
      columns: [
          helper.column('crtUserId', 'ID', {
              formatter: function(value) {
                  if (value === UserInfo.getId()) {
                      this.grid.columns['0-1'].field = 'delete';
                  } else {
                      this.grid.columns['0-1'].field = 'delete-false';
                  }
              }
          }),
          helper.buttonColumn('delete', '削除'),
          helper.column('contents', 'コメント', {sortable: false}),
          helper.column('attachFile', '添付ファイル', {
              formatter: function(value) {
                  if(value && value.length > 0){
                       var html = []; 
                       html.push('<a href="' + value[0].attachFilePath.replace('out/', 'data/') + '"');
                       html.push('download="' + value[0].attachFileName +'">');
                       html.push(value[0].attachFileName +'</a>');
                       return html.join('');
                  } else {
                      return '';
                  }
              },
              sortable: false
          }),
          helper.column('sender', '登録者', {sortable: false}, {
              formatter: function(value) {
                  return value;
              }
          }),
          {field: 'updTimestamp', label: '登録日時',
              formatter: lang.hitch(this, function(item){
                  if(item) {
                    return DateFormatter.jpDateTime(item, true);
                  }
                  return '';
              })
          }

      ]
    });
  });
