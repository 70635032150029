define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, locale, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sending',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.column('areaNameGroup', '発令地区', {
                children: [
                    helper.column('areaName', '地区名', {sortable: false}),
                    helper.column('areaNameKana', 'よみがな', {sortable: false})
                ],
                sortable: false
            }),
            helper.column('evacuateSort', '発令理由', {sortable: false}),
            helper.column('evacuateIssue', '発令種別', {sortable: false}),
            helper.column('typeOfDisaster', '災害種別', {
                sortable: false,
                formatter: function(value){
                    return value.join('<br>');
                }
            }),
            helper.column('target', '対象', {
                children: [
                    helper.column('evacHouseholdNum', '世帯数', {
                        sortable: false,
                        formatter: function(item){
                            if(!item || item === 0){
                                return '-';
                            }
                            return item;
                        }
                    }),
                    helper.column('evaqueeNum', '人数', {
                        sortable: false,
                        formatter: function(item){
                            if(!item || item === 0){
                                return '-';
                            }
                            return item;
                        }
                    })
                ],
                sortable: false
            }),
            helper.column('evacOrderTimestamp', '発令・解除日時', {
                sortable: false,
                formatter: function(item) {
                    if (item) {
                        return locale.format(new Date(item));
                    }
                    return item;
                }
            })
        ]
    });
});
