/**
 * 避難状況詳細画面用モジュール。
 * @module app/view/page/EvacOrderDetailPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-style',
    'dojo/promise/all',
    'dojo/text!./templates/EvacOrderDetailPage.html',
    'dojo/json',
    'idis/view/Loader',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/view/dialog/InfoDialog',
    'idis/model/UserInfo',
    'idis/consts/ACL',
    'idis/consts/USER_TYPE',
    'app/config',
    './_EvacOrderPageBase',
    './EvacOrderDetailStoreModel',
    './Reason',
    './EvacOrderUtil',
    // 'app/evacorder/DistributionType',
    // 以下、変数で受けないモジュール
    'dijit/layout/TabContainer',
    'app/view/form/MunicipalitySelector',
    'app/evacorder/EvacOrderCancelDialog',
    'app/provide/ProvideEvacOrderRegisterDialog',
    './EvacOrderPopulationGrid'
], function(module, declare, lang, array, domStyle, all, template,
    json, Loader, Locator, Router, Requester, IdisRest, InfoDialog,
    UserInfo, ACL, USER_TYPE, config,_EvacOrderPageBase, EvacOrderDetailStoreModel, Reason, EvacOrderUtil) {
    /**
     * 避難状況詳細画面
     * @class EvacOrderDetailPage
     * @extends module:app/evacorder/_EvacOrderPageBase~_EvacOrderPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _EvacOrderPageBase,
    /** @lends module:app/evacorder/EvacOrderDetailPage~EvacOrderDetailPage# */ {

        // テンプレート文字列
        templateString: template,

        // 避難情報ID
        _evacOrderId: null,

        // 初期表示時に選択されていた避難情報発令地区
        _initialDitstirctCds: null,

        // 初期表示時の避難情報発令理由(取消時のLアラート連携用)
        _initialIssueReasonType: null,

        // 初期表示時の発令内容
        _initialEvacOrderType: null,

        _cancelIssueReasonType: null,

        // 最新の避難情報IDのリスト
        _latestEvacOrderIds: [],

        // 災害シミュレーション用
        _simulateFlg: false,
        _eventId: null,
        _scenarioId: null,

        constructor: function() {
            // ベースクラスのコンストラクタを呼ぶ
            this.inherited(arguments);
            // form用のストアを生成する。
            this.formStore = new IdisRest({
                idProperty: 'evacOrderId',
                target: '/api/evacorders'
            });

            // URLから災害シミュレーション用パラメータを取得
            // this._simulateFlg = Locator.getQuery().simulateFlg;
            // this._eventId = Locator.getQuery().eventId;
            // this._scenarioId = Locator.getQuery().scenarioId;
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.cancelDialog);
        },

        // HTML上にウィジェットが設置されてから呼ばれる
        startup: function() {
            this.inherited(arguments);

            // 一覧画面から渡された避難情報IDを取得する。
            this._evacOrderId = Locator.getQuery().evacOrderId;

            // 避難情報詳細ページを初期化する。
            this.initDetailPage();

            // 詳細画面で利用するTipsをセットする
            this.setTipsPopups();

            // 閲覧モードフラグをセット
            this._viewMode = Locator.getQuery().viewMode || '0';

        },

        /**
         * TipsPopupをセットする。
         */
        setTipsPopups: function() {
            // 避難理由
            this.setIssueReasonTypeTips();
            // 発令理由詳細
            this.setIssueReasonTips();
            // 補足情報
            this.setNoteTips();
            // 地区情報
            this.setDistrictTips();
            // 訂正・取り消し理由
            this.setCorrectCancelReasonTips();
        },

        setButtonStatus: function(){
            // 災害シミュレーションの場合、訂正ボタンを表示する
            // if(this._simulateFlg === 'true'){
            //     domStyle.set(this.registerButtonArea, 'display', 'none');
            //     domStyle.set(this.correctButtonArea, 'display', '');
            //     domStyle.set(this.cancelButtonArea, 'display', 'none');
            // } else {
                // Queryから受け取った訂正・取消フラグがtrueであれば、ボタンは表示しない。
                var flg = Locator.getQuery().correctCancelFlg;
                if (flg === this.CORRECT_CANCEL_FLG.TRUE) {
                    // チェックでreturnされなかった場合、訂正取消しフラグを表示する。
                    domStyle.set(this.correctButtonArea, 'display', 'none');
                    domStyle.set(this.cancelButtonArea, 'display', 'none');
                    return;
                } else if ( this._viewMode === '1'){
                // 閲覧モードの場合は、すべてのボタンを表示しない
                    domStyle.set(this.registerButtonArea, 'display', 'none');
                    domStyle.set(this.correctButtonArea, 'display', 'none');
                    domStyle.set(this.cancelButtonArea, 'display', 'none');
                    return;
                }
                // 最新の避難情報IDのリストの中に避難情報IDがなければ、取消しボタンは表示しない。
                var isLatest = false;
                this._latestEvacOrderIds.some(function(latestId) {
                    if (latestId === Number(this._evacOrderId)) {
                        isLatest = true;
                        return true;
                    }
                }, this);
                if (!isLatest) {
                    domStyle.set(this.cancelButtonArea, 'display', 'none');
                }

                // 管理対象市町でなかった場合
                // if(UserInfo.getMunicipalityCds().indexOf(this._municipalityCd) === -1){
                //     domStyle.set(this.registerButtonArea, 'display', 'none');
                //     domStyle.set(this.correctButtonArea, 'display', 'none');
                //     domStyle.set(this.cancelButtonArea, 'display', 'none');
                // }
            // }
        },

        /**
         * 避難情報詳細ページを初期表示する。
         * 処理に依存関係があるものをこの中で呼ぶ。
         */
        initDetailPage: function(){
            // 市町村ユーザーの場合(政令指定都市を除く)概況リンクを不可視
            // if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
            //     UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
            //     domStyle.set(this.overviewLabel, 'display', 'none');
            // }
            // 操作されないようにローディング中にしておく
            Loader.show()
            .then(lang.hitch(this, function(){
                //まずはメッシュ・河川のマスタを取得してくる
                return this.initMeshAndRiverTree();
            }))
            // evacOrderIdは一覧画面からの画面遷移時に渡されてコンストラクタでモジュールにセットされる。
            .then(lang.hitch(this, function(){
                return this.getEvacOrderDetailInfo(this._evacOrderId);
            }))
            // 市町村コードがモジュールにセットされてから処理される。
            .then(lang.hitch(this, function() { 
                // 地図の初期位置を設定する。
                this.initLatlng();

                // 初期表示時の避難情報発令理由を取得する。
                this._initialIssueReasonType = this.issueReasonType.get('value');
                this._initialEvacOrderType = this._evacOrderType;

                this.setEvacOrderTypeSelector(this._initialIssueReasonType, this._evacOrderType);

                // 訂正・取消ボタンの活性/非活性を初期化する。
                this.setButtonStatus();

                // 避難情報ツリーを初期表示した後にツリーのストア情報に基づいて地区レイヤーを表示する。
                this.initTree().then(lang.hitch(this, 'initDistrictLayer'))
                    .then(lang.hitch(this, function(){
                        // メッシュ・河川の値をセットし、地区ツリーのフィルタもつける。ただし地区にチェックはつけない。
                        if(this._issueReasonType === '01'){
                            this._selectMeshes(this._meshCdList);
                            this.onMeshCheckChange(false);
                        }else if(this._issueReasonType === '02'){
                            this._selectRivers(this._riverCdList);
                            this.onRiverCheckChange(false);
                        }
                        //画面パーツの表示制御
//                        this.setDispContentByEvacOrderType(this._issueReasonType);
                    
                    })).then(lang.hitch(this, function() {
                        // 準備が整ったら選択中の各地区をチェック状態にする
                        this._selectDistricts(this._initialDitstirctCds);
                    })).then(lang.hitch(this, function(){
                        //ひととおりの初期値がセットされたのち、メッシュ・河川の変更時トリガーをセット。
                        this.setMeshAndRiverTopicSubscriber();
                    })).then(lang.hitch(this, function(){
                        Loader.hide();
                    }));
            }));
        },

        /**
         * 地図上の地区選択レイヤーを初期化する。
         * @returns {Promise} 完了後に解決するPromise
         */
         // 詳細ページでは現況レイヤーを表示しない。
        initDistrictLayer: function(){
            return all({
                select: this.showSelectLayer()
            });
        },

        /**
         * ツリーを初期化する。
         * TODO ツリー初期化後にexpandAll()メソッドを呼ぶ。
         */
        initTree: function(districtCdList) {
            // ツリーを生成する。
            if (!this.tree) {
                this.tree = new this.treeClass({
                    model: new EvacOrderDetailStoreModel({
                        disasterId: this._disasterId,
                        municipalityCd: this._municipalityCd,
                        filter: function(item){
                            if(districtCdList && districtCdList.length > 0){
                                 // 地区に指定がある場合、指定された地区だけを出力
                                return districtCdList.indexOf(item.districtCd) !== -1; 
                            }
                            // 地区に指定がなければ全量を出力
                            return true;
                        }
                    })
                }, this.treeNode);
                this.tree.startup();
            }
            this.own(this.tree);
            this.tree.onLoadDeferred.then(lang.hitch(this, '_updatePopulationStore'));
            // ツリーの作成が終了したら、地図のfilter用に地区ツリーのキャッシュを取得しておく
            this.tree.onLoadDeferred.then(lang.hitch(this, function() {
                this.tree.model.store._getCache().then(lang.hitch(this, function(items) {
                    this._districtTreeCache = items.data;
                    //地区コードからitemに辿れるようにマップを作っておく。
                    this.tree._itemMap = {};
                    array.forEach(this._districtTreeCache, lang.hitch(this, function(item){
                        this.tree._itemMap[item.id] = item;
                    }));
                }));
            }));
            return this.tree.onLoadDeferred;
        },

        /**
         * レイヤーIDから避難情報を取得する
         */
        getEvacOrderDetailInfo: function(evacOrderId) {
            // 災害シミュレーションからの遷移の場合、避難情報jsonを取得する
            // if(this._simulateFlg === 'true'){
            //     return Requester.get('/data/simulation/' + this._eventId + '.json')
            //     .then(lang.hitch(this, function(item) {
            //         var data = item.evacOrderPostForm;
            //         // 取得した避難情報をformにセットする。
            //         this.form.set('value', data);
            //         // 避難情報で選択されていた地区コードのリストをモジュールで保持する。
            //         var distCdList = [];
            //         data.districtList.forEach(function(item){
            //             distCdList.push(item.districtCd);
            //         });
            //         this._initialDitstirctCds = distCdList;
            //         // 市町村コードを画面に保持しておく。(避難情報登録画面で同様の持ち方をしているので実装を合わせる)
            //         this._municipalityCd = data.municipalityCd;
            //         // 最新の避難情報IDのリストを画面に保持しておく。
            //         this._latestEvacOrderIds = data.latestEvacOrderIds;
            //         this._evacOrderType = data.evacOrderType;
            //     }));
            // } else {
                // キャッシュJSONがあればそちらを参照する
                return Requester.get('/api/evacorders/detail/' + evacOrderId)
                .then(lang.hitch(this, function(data) {
                    this.initEvacOrderDetailInfo(data);
                }));
            // }
        },

        /**
         * レイヤーIDから避難情報を取得する
         */
        initEvacOrderDetailInfo: function(data) {
            // 取得した避難情報をformにセットする。
            this.form.set('value', data);
            // 避難情報で選択されていた地区コードのリストをモジュールで保持する。
            this._initialDitstirctCds = data.evacOrderDistrictCds.split(',');
            // 市町村コードを画面に保持しておく。(避難情報登録画面で同様の持ち方をしているので実装を合わせる)
            this._municipalityCd = data.municipalityCd;
            // 最新の避難情報IDのリストを画面に保持しておく。
            this._latestEvacOrderIds = data.latestEvacOrderIds;
            this._evacOrderType = data.evacOrderType;
            this._issueReasonType = data.issueReasonType;
            
            this._meshCdList = data.meshCdList;
            this._riverCdList = data.riverCdList;
            
            if (data.issueReasonType === "02") {
                this.riverArea.set('style', { display: '' });
            } else {
                this.riverArea.set('style', { display: 'none' });
            }
            console.log(data);
        },

        /**
         * 添付ファイル選択時に呼ばれる。
         */
        loadAttachFile: function() {
            //TODO 避難情報を参考に実装する
            console.debug('EvacOrderRegisterPage#loadAttachFile()');
        },

        /**
         * 訂正ボタンが押下されてFormのサブミットイベントが発生した際に呼ばれる
         */
        onCorrectButtonClick: function() {
            // if(this._simulateFlg === 'true'){
            //     this.validateEvacOrderInfo(false, true);
            // } else {
                this.validateEvacOrderInfo(false, false);
            // }
        },

        validateEvacOrderInfo: function(isIssuing/*, simulateFlg*/){
            // フォームのバリデーションを行う（共通部品）
            if(!this.form.validate()) {
                return false;
            }
            // formデータに災害IDと地区リストをセットする。
            var form = this.form.get('value');

            // 避難情報IDをセットする。
            if(!isIssuing){
                form.evacOrderId = this._evacOrderId;
            }

            // 市町村をセットする。
            form.municipalityCd = this._municipalityCd;

            // 災害IDをセットする。
            form.disasterId = this._disasterId;
            form.evacOrderType = this._evacOrderType;

            // 避難対象人数、避難対象世帯数をセットする。
            form.evaqueeNum = this.evaqueeNumNode.innerHTML;
            form.evacHouseholdNum = this.evacHouseholdNumNode.innerHTML;

            // 発令理由をセットする（disabledにしている項目はform.get('value')で対象外になるため
            form.issueReasonType = this.issueReasonType.get('value');

            // 地区リストをセットする。
            var districtArray = this.tree.getCheckedLeafs();

            // 入力チェック
            if(districtArray.length === 0) {
                console.debug('地区が選択されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '地区が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return false;
            }

            // 地区のチェック状態を確認する。（ブラウザの処理の都合により、中の子地区が全部チェックされない可能性がある）
            var content = '';
            var checkNotCompletedDists = [];
            array.forEach(districtArray, function(district){
                if(!this.tree.isCheckCompleted(district.districtCd)){
                    checkNotCompletedDists.push(district.districtName);
                }
            }, this);
            if( checkNotCompletedDists && checkNotCompletedDists.length > 0){
                // 子地区の処理が終わっていない親地区がある場合、後続処理を実施しないでエラーダイアログを表示する
                content = '以下の地区について、子地区の設定がされていません。<br>' +
                                '「地区」欄から再度チェックし、全ての子地区がチェックされることを<br>' +
                                '確認してから、登録ボタンを押してください。<br><br>';
                content += '地区：' + checkNotCompletedDists.join('、');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : content
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return false;
            }

            // 地区のエラーチェックを行う。
            var nonIssuedDistNameList = this.checkDistricts(form, districtArray, isIssuing, this._initialEvacOrderType);
            if( nonIssuedDistNameList && nonIssuedDistNameList.length){
                // 避難情報が発令されていない/解除済みの地区に対して解除しようとしている場合
                // エラーダイアログを表示
                content = '以下の地区には、' + Reason.get(form.issueReasonType).name +
                    'の避難情報が発令されていないか、発令時刻より前の時間が指定されているため、<br>解除できません。<br><br>';
                content += '地区：' + nonIssuedDistNameList.join('、');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : content
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return;
            }

            // 新規発令の時は、追加で地区のエラーチェックを行う。
            var isEvacOrderTypeChanged = (this._initialEvacOrderType !== form.evacOrderType);
            var districtGap = {
                removedDistCd: [],
                removedDistName: [],
                addedDistCd: [],
                addedDistName: []
            };

            // 発令内容が変わらない場合は、地区の差分を取る必要がある。
            if(!isEvacOrderTypeChanged){
                districtGap = this.calcDistrictGaps(districtArray);
            }


            // 発令内容が変わらず、元々発令されていた地区が欠けていたら、
            // 「訂正」を選ぶ旨をリマインドする
            if(isIssuing && !isEvacOrderTypeChanged && districtGap.removedDistName.length > 0){
                content = '以下の地区が欠けています。<br><br>' +
                    '地区：' + districtGap.removedDistName.join('、') + '<br><br>' +
                    '発令した地区に誤りがあり、一部の地区の発令状況のみ取消したい場合は、<br>' +
                    '正しい地区のみにチェックを入れて、「訂正・取消理由」を入力の上、「訂正」ボタンを押してください。<br><br>' +
                    '一部地区のみ避難情報を解除する場合は、解除対象地区のみを選び、<br>' +
                    '発令内容を「解除」に変更した上で、「発令」ボタンを押してください。';
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : content
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return;
            }


            // 新規発令の時は、発令内容が変わらず、地区に変化がない場合は警告を出す。
            // 時刻を変える場合は「訂正」を選ぶ旨をリマインドする。
            if(isIssuing && !isEvacOrderTypeChanged &&
                    districtGap.removedDistCd.length === 0 && districtGap.addedDistCd.length === 0){
                content = '発令内容・発令地区に変化がないので、続報として登録できません。<br><br>' +
                    '発令日時、発令理由詳細、行動指針、補足情報などを変更する場合は、「訂正」としての連携が必要です。<br>' +
                    '「訂正・取消理由」を入力の上、「訂正」ボタンを押してください。<br><br>';
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : content
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return;
            }

            // 地区を変換する
            if(isIssuing && isEvacOrderTypeChanged){
                // 新規発令の場合は、最新報との「差分」だけが欲しい。
                // まず、発令内容が変わった場合は、チェックされている地区をそのまま連携すればいい。
                // 地区を変換する
                form.districtList = this.convertDistrictArray(districtArray, form.evacOrderType,
                                                                    form.issueReasonType, true);
            } else if (isIssuing && !isEvacOrderTypeChanged) {
                // しかし、新規発令で、かつ発令内容が同じ場合、かつ地区が「増えている」場合、「増えた地区」だけをDB登録したい。
                // なお、地区が「減っている」ケースは、上の処理でエラーダイアログを出しているのでそもそも発生し得ない。
                var wholeDistrictCd = this.convertDistrictArray(districtArray, form.evacOrderType,
                                                                    form.issueReasonType, true);
                form.districtList = [];
                array.forEach(wholeDistrictCd, function(dist){
                    if(districtGap.addedDistCd.indexOf(dist.districtCd) !== -1){
                        form.districtList.push(dist);
                    }
                }, this);

            } else {
                // 訂正の場合
                form.districtList = this.convertDistrictArray(districtArray,
                    form.evacOrderType, form.issueReasonType, false);
            }
            
            //メッシュ・河川のチェック状況。発令・訂正問わず、その時点のチェック状況をすべて送信する。
            form.meshCdList = this._checkedMeshCdList;
            form.riverCdList = this._checkedRiverCdList;

            // Lアラート連携用の地区リストを作成する。
            if(isIssuing){
                var districtArray4Regist = [];
                array.forEach(districtArray, function(dist){
                    if(this.isDistrictCdIncluded(form.districtList, dist)){
                        districtArray4Regist.push(dist);
                    }
                }, this);
                this._districtArray = districtArray4Regist;
                form.evacOrderLalertAreaMap =
                    this.createLalertAreaList(this._districtTreeCache, districtArray4Regist,
                            null, form, false, false, null);
            } else {
                // チェックを外された地区の一覧を作る
                var deselectedList = this.createUnselectedList(districtArray);
                // var evacOrderId = parseInt(this._evacOrderId, 10); //文字列型で入っているので数字に変える
                form.evacOrderLalertAreaMap =
                    this.createLalertAreaList(this._districtTreeCache, districtArray,
                        deselectedList, form, false, true, this._initialEvacOrderType);
            }

            this._evacOrderForm = form;

            if(isIssuing){ // 新規発令の時
                // 緊急速報メールの文面作成対応
                this._municipalityCdFrom = form.municipalityCd;
                delete form.correctCancelReason;
                delete form.evacOrderId; //idを削除することで、最終的にAPIを呼び出すときにPOSTとなる
                this.showEvacOrderDialog4Register(form);
            //} else if(!simulateFlg){ // 訂正の時
            } else{
                // 訂正確認画面ダイアログを表示する。
                var message = '避難情報を訂正します。<br><br>' +
                        '誤って登録した情報を訂正する場合は、<br>OKボタンをクリックして訂正を完了してください。<br><br>' +
                        '<font color="#dc143c">※続報情報を登録する場合には、<br>' +
                        '画面右下の「発令」ボタンから情報を登録してください。</font>';

                this.chain.confirm(message, lang.hitch(this,function(chain) {
                    var correctCancelReason = this.correctCancelReason.get('value');

                    // FIXME 親の確認ダイアログも閉じられるようにする。（訂正も同じ）
                    // 訂正・取消理由が入力されているか確認する。
                    if(!correctCancelReason) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : '訂正・取消理由が入力されていません。'
                        });
                        chain.hide();
                        this.infoDialog.show();
                        this.infoDialog=null;
                        return false;
                    }
                    // ここでこのチェーンは閉じておかないと、後続のダイアログが他のダイアログの下に出てしまう
                    chain.hide();
                    this.showEvacOrderDialog(form, false);
                }));
            // } else {
            //     // 確認ダイアログ内で設定した情報を、避難情報登録フォームに反映させる
            //     this._evacOrderForm.twitterFlg = false;
            //     this._evacOrderForm.prefMailFlg = false;
            //     delete this._evacOrderForm.correctCancelReason;
            //     delete this._evacOrderForm.evacOrderLalertAreaMap;
            //     delete this._evacOrderForm.sendPostForms;
            //     var subSendData = {};
            //     subSendData.municipalityName = config.municInfo.cityMunicName;
            //     subSendData.evacOrderTypeName = this.evacOrderType.domNode.innerText;
            //     subSendData.issueReasonTypeName = this.issueReasonType.domNode.innerText;
            // 
            //     var jsonStr = json.stringify({
            //         evacOrderPostForm: this._evacOrderForm,
            //         evacOrderConversionForm: subSendData,
            //         scenarioId: this._scenarioId,
            //         eventId: this._eventId
            //     });
            // 
            //     var msg = 'イベントを訂正します。<br>よろしいですか。';
            //     this.chain.confirm(msg, function(chain) {
            //         var promise = Requester.put('/api/simulations/event/'+ this._eventId, {
            //             data: jsonStr,
            //             headers: {'Content-Type': 'application/json; charset=utf-8'},
            //             handleAs: 'json',
            //             preventCache : true
            //         }).then(function() {
            //             console.debug('success update Chronology event');
            //             chain.infoComplete(function() {
            //                 Router.moveTo('simulation/event', {scenarioId: this._scenarioId});
            //             });
            //         }, function(error) {
            //             console.log(error);
            //             chain.infoError(error);
            //         });
            //         //ローダーの表示
            //         Loader.wait(promise);
            //     });
            }
        },


        /**
         * 避難情報を発令(訂正や取り消しでない)するときに、Lアラート配信(避難情報)ダイアログを表示する。
         */
        showEvacOrderDialog4Register: function(form) {

            all({
                // 情報配信画面に渡す避難所開設情報を構築する
                shelterArgs: this._getLalertShelterArgs(form.facilityIds),
                // 情報配信画面に渡す避難状況情報を取得する
                evacOrderArgs: this._getLalertEvacOrderWholeCheck(form, false),
                // 緊急メール画面に渡す避難状況情報を取得する
                urgentMailArgs: this._getUrgentMailArgs(this._municipalityCdFrom)
            }).then(lang.hitch(this, function(lalertArgs) {

                // 万が一、クライアントのチェックツリーのエラー/全域判定ロジックの失敗で、
                // 地区情報が返ってこなかった場合は、警告ダイアログを出す。
//                if(!lalertArgs.evacOrderArgs.evaqOrderData ||
//                    lalertArgs.evacOrderArgs.evaqOrderData.length === 0){
//                    console.debug('対象地区がありません');
//                    if (!this.infoDialog) {
//                        this.infoDialog = new InfoDialog({
//                            title : 'エラー',
//                            content : '対象地区がありません。「地区」欄の選択内容をご確認ください。<br>' +
//                                    'チェックした地区が子地区を持つ場合、子地区のチェック状態もお確かめください。'
//                        });
//                    }
//                    this.infoDialog.show();
//                    this.infoDialog=null;
//                    return false;
//                }

                // 避難所引数内の施設IDと避難所引数を外に出す
                if (lalertArgs.shelterArgs) {
                    lalertArgs.facilityId = lalertArgs.shelterArgs.facilityId;
                    lalertArgs.shelterArgs = lalertArgs.shelterArgs.shelterArgs;
                }
                // Lアラート用の情報を付与する
                // lalertArgs.evacOrderArgs.evaqOrderData =
                //     this.addLalertProperties(lalertArgs.evacOrderArgs.evaqOrderData);
                // 緊急速報メールなどの文面用にリストを作成する
                lalertArgs.districtList = form.districtList;

                // ダイアログの最初の子要素が登録画面
                var dialog = this.evacOrderRegisterDialog.getChildren()[0];
                // ダイアログのsendAndRegisterイベントを受け取れるようにする
                dialog.on('sendandregister', lang.hitch(this, function(evt) {
                    // 避難情報・避難所情報の一括登録 ＋ 外部連携配信を一気に行う。
                    return this.sendAndRegister(evt);
                }));
                // ダイアログの画面を初期化する。
                var isIssue =
                    EvacOrderUtil.isReleased(form.evacOrderType) ? false : true;
                dialog.initDialog(lalertArgs, isIssue).then(lang.hitch(this,function(){
                    // ダイアログを破棄できないように設定する。
                    this.evacOrderRegisterDialog.set('closable', false);
                    // ダイアログを表示する。
                    this.evacOrderRegisterDialog.show();
                }));

            }), lang.hitch(this, function(error){
                console.error(error);
                this.chain.infoError(error);
            }));
        },


        isDistrictCdIncluded: function(districtList, dist){
            var isDistIncluded = false;
            array.forEach(districtList, function(checkedDist){
                if(checkedDist.districtCd === dist.districtCd){
                    isDistIncluded = true;
                }
            });
            return isDistIncluded;
        },

        calcDistrictGaps: function(districtArray){
            var districtGap = {
                removedDistCd: [],
                removedDistName: [],
                addedDistCd: [],
                addedDistName: []
            };

            var checkedDistrictCdList = [];
            array.forEach(districtArray, function(checkedDist){
                // 後続処理のため、地区コードのみを取り出して配列化しておく
                checkedDistrictCdList.push(checkedDist.districtCd);

                // 初期表示時の地区配列に、現在選択中の地区が含まれなかったら
                if(this._initialDitstirctCds.indexOf(checkedDist.districtCd) === -1){
                    districtGap.addedDistCd.push(checkedDist.districtCd);
                    districtGap.addedDistName.push(checkedDist.districtName);
                }
            }, this);

            array.forEach(this._initialDitstirctCds, function(distCd){

                // 現在選択中の地区配列に、初期表示時に選択していた地区が含まれなかったら
                if(checkedDistrictCdList.indexOf(distCd) === -1){
                    districtGap.removedDistCd.push(distCd);
                    districtGap.removedDistName.push(this.getDistrictInfo(distCd).districtName);
                }
            }, this);

            return districtGap;
        },

        createUnselectedList: function(selectedDistObjList){
            var list = [];
            array.forEach(this._initialDitstirctCds, function(distCd){
                var isSelected = false;
                array.forEach(selectedDistObjList, function(distObj){
                    if(distObj.districtCd === distCd){
                        isSelected = true;
                    }
                }, this);

                if(!isSelected){
                    list.push(distCd);
                }
            }, this);
            return list;
        },

        sendAndRegister: function(info){


            var message = null;
            var sendPostForms = [];

            // 確認ダイアログ内で設定した情報を、避難情報登録フォームに反映させる
            this._evacOrderForm.note = info.evacOrderInfo.lalertForm.complementaryInfo;
            this._evacOrderForm.twitterFlg = info.evacOrderInfo.twitterFlg;
            this._evacOrderForm.prefMailFlg = info.evacOrderInfo.prefMailFlg;
            this._evacOrderForm.emergencyPushFlg = info.evacOrderInfo.emergencyPushFlg;
            this._evacOrderForm.twitterBodyText = info.evacOrderInfo.twitterMessage; //Twitter用本文
            this._evacOrderForm.prefMailBodyText = info.evacOrderInfo.prefMailBodyText; //県防災情報メール用本文
            this._evacOrderForm.subject = info.evacOrderInfo.prefMailSubject; //県防災情報メール用件名
            this._evacOrderForm.emergencyPushSubject = info.evacOrderInfo.emergencyPushSubject; //緊急情報配信(防災アプリ)用件名
            this._evacOrderForm.emergencyPushBodyText = info.evacOrderInfo.emergencyPushBodyText; //緊急情報配信(防災アプリ)用本文
            if(info.evacOrderInfo.wholeAreaDistrictName){
                // 全域判定結果
                this._evacOrderForm.wholeAreaDistrictName = info.evacOrderInfo.wholeAreaDistrictName;
            }

            delete this._evacOrderForm.evacOrderLalertAreaMap;

            array.forEach(this._evacOrderForm.districtList, function(item){
                //削る項目
                delete item.wardMunicipalityName;
            }, this);

            sendPostForms.push(info.evacOrderInfo.lalertForm);
            if(info.shelterInfo && info.shelterInfo.lalertForm){
                this._evacOrderForm.data.twitterFlg = info.shelterInfo.twitterFlg;
                this._evacOrderForm.data.prefMailFlg = info.shelterInfo.prefMailFlg;
                this._evacOrderForm.data.emergencyPushFlg = info.shelterInfo.emergencyPushFlg;
                this._evacOrderForm.data.sendPostForm = info.shelterInfo.lalertForm;
            }
            if(info.urgentMailInfo){
//                sendPostForms.push(info.urgentMailInfo);
                array.forEach(info.urgentMailInfo, function(urgentMailForm) {
                    sendPostForms.push(urgentMailForm);
                });
            }
            this._evacOrderForm.sendPostForms = sendPostForms;
            message = '避難情報を続報として登録します。<br>' +
                    '最新の世帯数・人口となっているか、また発令日時が正しいか、ご確認ください。<br>' +
                    'よろしければ、OKボタンを押してください。';

            this.chain.confirm(message, lang.hitch(this, function(chain){
                // 最初に、DBに避難情報・避難所情報を登録する。Lアラート・緊急速報メール以外の外部配信もここで行われる。
                var jsonStr = json.stringify(this._evacOrderForm);
                var promise = Requester.post('/api/evacorders', {
                    data: jsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function(data){
                    
                    var message = '登録・配信が完了しました。';
                    if(data.status !== null && data.status !== 200 && data.status !== 201) {
                        if(data.status === 404){
                            message += '<br>大阪府システムとの通信に失敗したため、連携が行われませんでした。';
                        } else if(data.status === 500){
                            // 災害名が存在しない場合
                            if(data.errorCode === '11'){
                                message += '<br>大阪府システムで対応する災害名が存在しないため、連携が行われませんでした。';
                            // 登録不可の災害名の場合
                            } else if(data.errorCode === '12'){
                                message += '<br>大阪府システムでは災害情報が登録不可の災害であるため、連携が行われませんでした。';
                            } else {
                                message += '<br>大阪府システムで情報の連携に失敗しました。';
                            }
                        } else {
                            message += '<br>大阪府システムで情報の連携に失敗しました。';
                        }
                    }

                    chain.info(message, '完了', lang.hitch(this, function(){
                        this.evacOrderRegisterDialog.getChildren()[0].closeDialog();
                        Router.moveTo('evacorder', {
                            municipalityCd: this._municipalityCd
                        });
                    }));
                }), lang.hitch(this, function(error){
                    console.error(error);
                    chain.infoError(error);
                }));
                Loader.wait(promise);
            }));
        },

        /**
         *
         * @param {Object} form
         */
        sendAndRegisterCorrect: function(info){
            var sendPostForms = [];

            // 避難所開設情報が設定されているか確認する。
            // 設定されていない場合はダイアログを警告メッセージに変更する。
            var message = '避難情報を訂正します。<br>よろしいですか。';
            var promise = null;

            // 確認ダイアログ内で設定した情報を、避難情報登録フォームに反映させる
            this._evacOrderForm.note = info.evacOrderInfo.lalertForm.complementaryInfo;
            this._evacOrderForm.correctCancelReason = info.evacOrderInfo.lalertForm.errataDescription;
            this._evacOrderForm.twitterFlg = info.evacOrderInfo.twitterFlg;
            this._evacOrderForm.prefMailFlg = info.evacOrderInfo.prefMailFlg;
            this._evacOrderForm.emergencyPushFlg = info.evacOrderInfo.emergencyPushFlg;
            this._evacOrderForm.bodyText = info.evacOrderInfo.urgentMailMessage;
            delete this._evacOrderForm.evacOrderLalertAreaMap;

            array.forEach(this._evacOrderForm.districtList, function(item){
                //削る項目
                delete item.wardMunicipalityName;
            }, this);

            sendPostForms.push(info.evacOrderInfo.lalertForm);
            this._evacOrderForm.sendPostForms = sendPostForms;
            this.chain.confirm(message, lang.hitch(this, function(chain){
                // 最初に、DBに避難情報・避難所情報を登録する。Lアラート・緊急速報メール以外の外部配信もここで行われる。
                var jsonStr = json.stringify(this._evacOrderForm);
                promise = Requester.put('/api/evacorders/' + this._evacOrderForm.evacOrderId , {
                    data: jsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function(data){
                    
                    var message = '登録・配信が完了しました。';
                    if(data.status !== null && data.status !== 200 && data.status !== 201) {
                        if(data.status === 404){
                            message += '<br>大阪府システムとの通信に失敗したため、連携が行われませんでした。';
                        } else if(data.status === 500){
                            // 災害名が存在しない場合
                            if(data.errorCode === '11'){
                                message += '<br>大阪府システムで対応する災害名が存在しないため、連携が行われませんでした。';
                            // 登録不可の災害名の場合
                            } else if(data.errorCode === '12'){
                                message += '<br>大阪府システムでは災害情報が登録不可の災害であるため、連携が行われませんでした。';
                            // 訂正対象がない
                            } else if(data.errorCode === '14'){
                                message += '<br>大阪府システムでは訂正対象の避難情報が見つからないため、連携が行われませんでした。';
                            } else {
                                message += '<br>大阪府システムで情報の連携に失敗しました。';
                            }
                        } else {
                            message += '<br>大阪府システムで情報の連携に失敗しました。';
                        }
                    }

                    chain.info(message, '完了', lang.hitch(this, function(){
                        this.evacOrderRegisterDialog.getChildren()[0].closeDialog();
                        Router.moveTo('evacorder', {
                            municipalityCd: this._municipalityCd
                        });
                    }));

                }), lang.hitch(this, function(error){
                    console.error(error);
                    chain.infoError(error);
                }));
                Loader.wait(promise);
            }));
        },

        /**
         * Lアラート配信(避難情報)訂正ダイアログを表示する。
         * @param {Object} form
         * @param {boolean} isCancelling false: 訂正, true: 取消
         */
        showEvacOrderDialog: function(form, isCancelling) {

            this._getLalertEvacOrderWholeCheck(form, true).then(lang.hitch(this, function(evacOrderArgs) {

                // 万が一、クライアントのチェックツリーのエラー/全域判定ロジックの失敗で、
                // 地区情報が返ってこなかった場合は、警告ダイアログを出す。
//                if(!evacOrderArgs.evaqOrderData ||
//                    evacOrderArgs.evaqOrderData.length === 0){
//                    console.debug('対象地区がありません');
//                    if (!this.infoDialog) {
//                        this.infoDialog = new InfoDialog({
//                            title : 'エラー',
//                            content : '対象地区がありません。「地区」欄の選択内容をご確認ください。<br>' +
//                                    'チェックした地区が子地区を持つ場合、子地区のチェック状態もお確かめください。'
//                        });
//                    }
//                    this.infoDialog.show();
//                    this.infoDialog=null;
//                    return false;
//                }

                var lalertArgs = {};
                lalertArgs.evacOrderArgs = evacOrderArgs;

                // 訂正・取消理由を渡す
                lalertArgs.evacOrderArgs.errataDescription = this.correctCancelReason.get('value');

                // Lアラート種別を渡す
                lalertArgs.evacOrderArgs.distributionType = '02';

                // ダイアログの最初の子要素が登録画面
                var dialog = this.evacOrderRegisterDialog.getChildren()[0];
                // ダイアログのsendAndRegisterイベントを受け取れるようにする
                dialog.on('sendandregister', lang.hitch(this, function(evt) {
                    // 避難情報・避難所情報の一括登録 ＋ 外部連携配信を一気に行う。
                    if(!isCancelling){
                        return this.sendAndRegisterCorrect(evt);
                    } else {
                        return this.sendAndRegisterCancel(evt);
                    }
                }));
                // ダイアログの画面を初期化する。
                dialog.initDialog(lalertArgs, false).then(lang.hitch(this,function(){
                    // ダイアログを破棄できないように設定する。
                    this.evacOrderRegisterDialog.set('closable', false);
                    // ダイアログを表示する。
                    this.evacOrderRegisterDialog.show();
                }));

            }), lang.hitch(this, function(error){
                console.error(error);
                this.chain.infoError(error);
            }));
        },

        // 避難所情報を初期表示する。
        // initShelterGrid: function() {
        //     this.updateGridQuery();
        // },

        // 避難所グリッドを更新する
        // updateGridQuery: function() {
        //     // 検索条件を格納するGrid用フィルターを作成
        //     var filter = new this.shelterStore.Filter();
        // 
        //     // 災害IDをセットする。
        //     filter = filter.eq('disasterId', this._disasterId);
        // 
        //     // 地区をセットする。避難地区はカンマ区切りに変換してから
        //     var districtArray = this.tree.getCheckedLeafs();
        //     var districtCds = this.parseArray2String(districtArray);
        //     filter = filter.eq('districtCds', districtCds);
        //     // 「発令理由」に一致する「対象災害」を持つ避難所を検索
        //     // 「その他」が選択されている場合は全避難所を表示
        //     // 選択された開設理由からフラグをセット
        //     var shelterType = this.issueReasonType.getValue();
        //     if (shelterType && shelterType !== '00') {
        //         filter = filter.eq('shelterType', shelterType);
        //     }
        //    // filterに対応するcollectionを取得
        //    var collection = this.shelterStore.filter(filter);
        // 
        // 
        //    // collectionをグリッドにセットする（サーバーにリクエストされる）
        //    this.shelterGrid.set('collection', collection);
        // 
        // },

        /**
         * 取消ボタンが押下された際に呼ばれる
         */
        onCancelButtonClick: function() {

            // 訂正確認画面ダイアログを表示する。
            var message = '避難情報を取消します。<br><br>' +
                    Reason.get(this._initialIssueReasonType).name + 'として発令された' +
                    '一連の避難情報をすべて取り消す場合は、<br>OKボタンをクリックして取消しを完了してください。<br><br>' +
                    '<font color="#dc143c">※一部の避難情報を訂正する場合には、<br>' +
                    'キャンセルボタンをクリックして、<br>訂正を行ってください。</font>';

            this.chain.confirm(message, lang.hitch(this,function(chain) {
                this._cancelIssueReasonType = this.issueReasonType.get('value');
                var correctCancelReason = this.correctCancelReason.get('value');

                // FIXME 親の確認ダイアログも閉じられるようにする。（訂正も同じ）
                // 訂正・取消理由が入力されているか確認する。
                if(!correctCancelReason) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '訂正・取消理由が入力されていません。'
                    });
                    chain.hide();
                    this.infoDialog.show();
                    this.infoDialog=null;
                    return false;
                }
                chain.hide();
                var form = this.form.get('value');

                // 取消したあと、同じ市町村内に、別の避難理由で発令中の避難情報が残っているか否かで挙動が変わる。
                var issuesList = this.getIssuesList();
                if(issuesList.length === 1 && issuesList[0] === this._initialIssueReasonType) {

                    // 別の避難理由で発令中の避難情報が残っていない場合、Lアラートには取消報で連携する
                    // formデータに災害IDと地区リストをセットする。
                    // 市町区をセットする。
                    this._evacOrderForm = form;
                    this.showEvacOrderCancelDialog();
                } else {
                    // 別の避難理由で発令中の避難情報が残っていれば、Lアラートには訂正報で連携する
                    form.municipalityCd = this._municipalityCd;

                    // 災害IDをセットする。
                    form.disasterId = this._disasterId;
                    form.evacOrderType = this._evacOrderType;

                    // 避難対象人数、避難対象世帯数をセットする。
                    form.evaqueeNum = this.evaqueeNumNode.innerHTML;
                    form.evacHouseholdNum = this.evacHouseholdNumNode.innerHTML;

                    // 発令理由をセットする（disabledにしている項目はform.get('value')で対象外になるため
                    form.issueReasonType = this.issueReasonType.get('value');

                    form.evacOrderLalertAreaMap =
                        this.createLalertAreaList(this._districtTreeCache, [], null, form, true, false);

                    this._evacOrderForm = form;
                    this.showEvacOrderDialog(form, true);
                }

            }));
        },

        /**
         * Lアラート配信(避難情報)取消ダイアログを表示する。
         * @param {Object} data 取消対象の情報
         * @param {Object} form formからセットする情報
         */
        showEvacOrderCancelDialog: function() {

            // 取消対象のLアラート連携用データを取得しておく。
            return Requester.get('/api/evacorders/lalert/cancel', {
                query: {
                    disasterId: this._disasterId,
                    municipalityCd: this._municipalityCd,
                    issueReasonType: this._initialIssueReasonType
                }
                // 取消処理を実行して取消後の返り値を保持する。
            }).then(lang.hitch(this, function(beforeLalert){
                // 下記の場合にはLアラートに連携しない。
                // ・前報がない場合（自主避難の歴史しか存在しない場合） lalertSendHistIdがint型のため値がない場合は0が返却される
                // ・前報が取消し情報の場合

                // Fix: プロトシステムで削除処理をするための暫定対応のため、コメントアウト
//                 if (beforeLalert.lalertSendHistId === 0 || beforeLalert.distributionType === '03') {
//                     this.chain.hide();
//                     Router.moveTo('evacorder', {
//                         municipalityCd: this._municipalityCd
//                     });
//                     return;
//                 }

                // ダイアログの最初の子要素が登録画面
                var dialog = this.evacOrderRegisterDialog.getChildren()[0];

                // 訂正取消し理由・補足情報は画面の値を使う
                var correctCancelReason = this.correctCancelReason.get('value');
                var note = this.note.get('value');


                // 市町村内の全避難世帯数・避難者数を計算する
                var evacHouseholdNum = 0;
                var evaqueeNum = 0;
                array.forEach(beforeLalert.evacOrderAreaList, function(area) {
                    evacHouseholdNum += area.evacHouseholdNum;
                    evaqueeNum += area.evaqueeNum;
                });

                // ダイアログにセットするパラメータを用意する。
                var evacOrderArgs = {
                    sendCategory : '01', // 避難勧告・指示
                    municipalityCd : this._municipalityCd,
                    evacTimestamp: beforeLalert.sendTimestamp,
                    issueReasonType: beforeLalert.issueReasonType,
                    evacOrderType: this.evacOrderType.get('value'),
                    evacuateReason: beforeLalert.evacuateReason,
                    evacHouseholdNum: evacHouseholdNum,
                    evaqueeNum: evaqueeNum,
                    evacGuidunce: beforeLalert.evacuateGuideline,
                    note: note,
                    evaqOrderData: beforeLalert.evacOrderAreaList,
                    lalertSendHistId: beforeLalert.lalertSendHistId,
                    isFix: true, // 訂正・取消であるかどうか
                    correctCancelReason: correctCancelReason
                };

                // ダイアログのsendAndRegisterイベントを受け取れるようにする
                dialog.on('sendandregister', lang.hitch(this, function(evt) {
                    // 避難情報・避難所情報の一括登録 ＋ 外部連携配信を一気に行う。
                    return this.sendAndRegisterCancel(evt);
                }));

                // ダイアログの画面を初期化する。
                return dialog.initDialog({
                    // 配信種別を指定する
                    evacOrderArgs: lang.mixin(evacOrderArgs, {
                        distributionType: '03' //取消
                    })
                }, false).then(lang.hitch(this, function(){
                    // ダイアログを破棄できないように設定する。
                    this.evacOrderRegisterDialog.set('closable', false);
                    // ダイアログを表示する。
                    this.evacOrderRegisterDialog.show();
                }));
            }));
        },

        sendAndRegisterCancel: function(info){
            // 確認ダイアログ内で設定した情報を、避難情報登録フォームに反映させる
            var correctCancelReason = info.evacOrderInfo.lalertForm.errataDescription;

            // 避難所開設情報が設定されているか確認する。
            // 設定されていない場合はダイアログを警告メッセージに変更する。
            var message = '避難情報を取り消します。<br>よろしいですか。';
            this.chain.confirm(message, lang.hitch(this, function(chain){

                var sendPostForms = [];
                sendPostForms.push(info.evacOrderInfo.lalertForm);

                // 最初に、DBに避難情報・避難所情報を登録する。Lアラート・緊急速報メール以外の外部配信もここで行われる。
                var promise = Requester.put('/api/evacorders/cancel', {
                    data: {
                        evacOrderId: this._evacOrderId,
                        disasterId: this._disasterId,
                        issueReasonType: this._cancelIssueReasonType,
                        municipalityCd: this._municipalityCd,
                        correctCancelReason: correctCancelReason,
                        sendPostForms: sendPostForms
                    }
                }).then(lang.hitch(this, function(data){
                    
                    var message = '取消情報の登録・配信が完了しました。';
                    if(data.status !== null && data.status !== 200 && data.status !== 201) {
                        if(data.status === 404){
                            message += '<br>大阪府システムとの通信に失敗したため、連携が行われませんでした。';
                        } else if(data.status === 500){
                            // 災害名が存在しない場合
                            if(data.errorCode === '11'){
                                message += '<br>大阪府システムで対応する災害名が存在しないため、連携が行われませんでした。';
                            // 登録不可の災害名の場合
                            } else if(data.errorCode === '12'){
                                message += '<br>大阪府システムでは災害情報が登録不可の災害であるため、連携が行われませんでした。';
                            // 訂正対象がない
                            } else if(data.errorCode === '14'){
                                message += '<br>大阪府システムでは取消対象の避難情報が見つからないため、連携が行われませんでした。';
                            } else {
                                message += '<br>大阪府システムで情報の連携に失敗しました。';
                            }
                        } else {
                            message += '<br>大阪府システムで情報の連携に失敗しました。';
                        }
                    }

                    chain.info(message, '完了', lang.hitch(this, function(){
                        this.evacOrderRegisterDialog.getChildren()[0].closeDialog();
                        Router.moveTo('evacorder', {
                            municipalityCd: this._municipalityCd
                        });
                    }));

                }), lang.hitch(this, function(error){
                    console.error(error);
                    chain.infoError(error);
                }));
                Loader.wait(promise);
            }));
        },

        // 発令ボタンを押した時の挙動
        onRegisterButtonClick: function(evt){
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            this.validateEvacOrderInfo(true, false);
        },
        
        // 河川選択の値が変更されたときのイベント
        onChangeRiverArea: function (val) {
            if(val.value.length === 0) {
                return;
            }
            val.value.forEach(lang.hitch(this, function (item) {
                item[0].districtList.split(',').forEach(lang.hitch(this, function(distCd){
                    this.tree.model.store.get(distCd).then(lang.hitch(this, function(item) {
                        if (item) {
                            this.tree.setChecked(item, true);
                        }
                    }));
                }));
            }));

        },
    });
});
