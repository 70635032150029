/**
 * 市町村選択用入力パーツ(複数選択可)
 * @module app/view/form/CustomMunicipalityMultiSelectorNoPref
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/string',
    'dijit/form/TextBox',
    'dojo/dom-style',
    'idis/view/form/TreeChecker',
    'app/model/CustomNoPrefMunicipality'
], function(module, declare, lang, dStr, TextBox, domStyle, TreeChecker, CustomNoPrefMunicipality) {
    /**
     * 地域選択用パーツ。
     * @class MunicipalityMultiSelectorNoPref
     * @extends module:idis/view/form/TreeChecker~TreeChecker
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:idis/view/tree/MunicipalityMultiSelectorNoPref~MunicipalityMultiSelectorNoPrefonSelectorNoPref# */ {
        // 選択ダイアログのタイトル
        title: '対象市/行政区選択',

        // ツリー・モデル
        model: CustomNoPrefMunicipality,

        // ツリー全展開ボタンを非表示に設定
        noExpandAll: true,

        // ツリー全収束ボタンを非表示に設定
        noCollapseAll: true,

        // DOMを構築する
        buildRendering: function () {
            this.inherited(arguments);
            // 検索用入力要素を設置
            this.filterInput = new TextBox({
                placeHolder: this.placeHolder,
                maxLength: 128
            });
            this.filterInput.placeAt(this.treeControlNode);
            this.own(this.filterInput.on('keyup', lang.hitch(this, 'onFilterInputChange')));
        },

        /**
         * 選択した値の設定
         */
        _setValueAttr: function() {
            // 継承した親クラスの処理
            this.inherited(arguments);
            // 以下、当モジュール固有の処理
            // 選択結果が15文字を超えた場合、・・・(三点リーダ)で省略する
            domStyle.set(this.status, {
                'max-width': '15em',
                'white-space': 'nowrap',
                'overflow': 'hidden',
                'text-overflow': 'ellipsis'
            });
        },

        /**
         * 検索用入力要素入力時。
         */
        onFilterInputChange: function () {
            // 入力値を取得
            var value = dStr.trim(this.filterInput.get('value'));
            if (value) {
                // ラベル名が入力値を含むものでフィルターし、ツリーを展開する
                this.model.setFilter(function (item, isLeaf, model) {
                    return isLeaf && (model.getLabel(item) || '').indexOf(value) !== -1;
                }).then(lang.hitch(this, function () {
                    this.tree.expandAll();
                }));
            } else {
                // 入力が空の場合は全要素を表示してツリーを閉じる
                this.model.setFilter().then(lang.hitch(this, function () {
                    this.tree.collapseAll();
                }));
            }
        }
    });
});

