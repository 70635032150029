/**
 * <モジュールの説明>
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/text!./templates/RescueFormDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    // 'idis/model/UserInfo',
    // 'idis/consts/USER_TYPE',
    // '../config',
    // 'idis/util/FilesUtils',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'dijit/form/Form'
], function (module, declare, lang, Deferred, JSON, template,
    _PageBase, DisasterInfo, InfoDialog, DialogChain, Loader) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
            // テンプレート文字列
            templateString: template,
            _disasterId: null,

            constructor: function () {
                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);
                // 災害IDを取得
                this._disasterId = DisasterInfo.getDisasterId();
                if (!this._disasterId) {
                    this._disasterId = 1;
                    console.warn('災害IDが設定されていません。');
                }
            },

            // 岡山では市町村選択なし
            // startup: function () {
            //     this.initDialog();
            // },

            // initDialog: function () {
            //     switch (UserInfo.getUserType()) {
            //         // 市町村・振興局ユーザの場合はデフォルトだけ設定
            //         case USER_TYPE.MUNICIPALITY:
            //             this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
            //             break;
            //         case USER_TYPE.REGION:
            //             this.municipalityCd.set('value', UserInfo.getRegionCd());
            //             break;
            //         default:
            //             break;
            //     }
            // },


            // 帳票出力を行う前に入力チェックを行う
            onOutputButtonClick: function () {
                if (!this._disasterId) {
                    this.chain.info('災害名が正しく選択されていません。', 'エラー');
                    return false;
                }
                //var repdate = this.repdatetime._date.displayedValue;
                //var reptime = this.repdatetime._time.displayedValue;
                var activeFlg = this.form.get('value').activeFlg;

                // 全帳票共通で、日時は必須項目
                // if (!this.repdatetime.validate() || !repdate || !reptime) {
                //     this.chain.info('日時が選択されていません。', 'エラー');
                //     return false;
                // }

                var reqid = 'RESCUE_LIST_EX';
                var fileName = 'RescueList.xlsx';
                var paramList = [];

                paramList = [
                    { key: 'disid', value: this._disasterId },
                    { key: 'activeFlg', value: activeFlg }
                ];

                var data4xoblosDownload = {
                    fileName: fileName,
                    reqid: reqid,
                    paramList: paramList
                };


                var promise = this.download(data4xoblosDownload, fileName).then(lang.hitch(this, function () {
                    this.infoDialog = new InfoDialog({
                        title: 'ダウンロード完了',
                        content: 'ダウンロードが完了しました。'
                    });
                    this.infoDialog.show();

                }), lang.hitch(this, function (error) {
                    console.error(error);
                    this.infoDialog = new InfoDialog({
                        title: 'エラー',
                        content: 'エラーが発生しました。管理者にお問い合わせください。'
                    });
                    this.infoDialog.show();
                }));

                Loader.wait(promise);

            },

            //帳票出力
            download: function (data, baseFileName) {
                var deferred = new Deferred();

                var xhr = new XMLHttpRequest();
                xhr.open('POST', '/api/xoblos/download', true);
                xhr.responseType = 'arraybuffer';
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onload = function () {

                    // エラー時は処理を止める
                    if (xhr.status !== 200) {
                        deferred.reject();
                        return;
                    }

                    // バイナリデータを取得
                    var arrayBuffer = this.response;
                    var blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
                    var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                    // IE10+
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                    } else {
                        // 擬似的にAタグを作成
                        var link = document.createElement('a');
                        link.style = 'display: none';
                        document.body.appendChild(link);

                        // AタグのURLにバイナリデータをセット
                        var url = window.URL.createObjectURL(blob);
                        link.href = url;

                        // ファイル名をセット
                        link.download = fileName;

                        // 擬似的にリンクをクリック
                        link.click();
                        // 参照を解放
                        window.URL.revokeObjectURL(url);
                        link.remove();
                    }

                    deferred.resolve();

                };
                xhr.send(JSON.stringify(data));

                return deferred.promise;
            },

            /**
            * キャンセルボタン押下
             */
            onCancel: function () {
                this.getParent().hide();
            },

            /**
             * 月や日付を2桁にゼロpaddingする
             */
            _zeroPadding: function (month) {
                return ('00' + month).slice(-2);
            }
        });
});
