/**
 * 避難情報のconfigファイル
 * @module app/evacorder/EvacOrderConfig
 */
define({
    ALERT_LEVEL_MAP_STR: {
        '高齢者等避難': {level: 3, label4Lalert: '警戒レベル３'},
        '避難指示': {level: 4, label4Lalert: '警戒レベル４'},
        '緊急安全確保': {level: 5, label4Lalert: '警戒レベル５'},
        '警戒区域': {level: 0, label4Lalert: '-'}
    },

    ALERT_LEVEL_MAP_CD: {
        '11': {level: 3, label4Lalert: '警戒レベル３', evacAction: '高齢者等は避難'},
        '13': {level: 4, label4Lalert: '警戒レベル４', evacAction: '全員避難'},
        '14': {level: 5, label4Lalert: '警戒レベル５', evacAction: '命を守る最善の行動'},
        '19': {level: 0, label4Lalert: '-', evacAction: ''}
    },

    // 発令コードに対する、同じ区分の避難情報への解除コードのマッピング
    RELEASE_MAP: {
        '11': '81',
        '13': '83',
        '14': '84',
        '19': '89'
    },

    // 解除を意味するコード
    RELEASE_CD_LIST: ['80', '81', '83', '84', '89'],

    // 警戒レベル付与対象の発令理由
    ALERT_LEVEL_ISSUE: ['01', '02', '05']
});
