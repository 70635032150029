
define([
	'module',
	'dojo/_base/declare',
	'dojox/lang/functional/array',
	'idis/view/tree/CheckTree'

], function (module, declare, df, CheckTree) {
	return declare(module.id.replace(/\//g, '.'), CheckTree, { /** @lends module:idis/view/tree/CheckTree~CheckTree# */
		/**
		 * チェック状態の葉要素一覧を返す。
		 * @returns {Object[]}
		 */
		getCheckedLeafs: function () {
			// 親IDの一覧
			var parentMap = {};
			df.forIn(this._checkMap, function (item) {
				var parentId = this.model.store.getParentIdentity(item);
				if (parentId === '$ROOT$') {
					parentMap[parentId] = true;
				}
			}, this);
			return df.filter(this._checkMap, function (item) {
				// 親ID一覧に登場しないものだけ残す
				return !parentMap[this.model.store.getIdentity(item)];
			}, this);
		},
	});
});