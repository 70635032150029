/**
 * <モジュールの説明>
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/string',
    'dojo/text!./templates/ChronologyFormDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/util/FilesUtils',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    '../config',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'app/view/form/CustomizableMunicipalitySelector',
    'dijit/form/Form'
], function(module, declare, lang, Deferred, JSON, dStr, template, _PageBase, DisasterInfo, InfoDialog,
		DialogChain, Loader, FilesUtils, UserInfo) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
        // テンプレート文字列
        templateString: template,
        /**
         * 災害ID
         */
        _disasterId: null,

        constructor: function(){
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
            // 災害IDを取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定させていません。');
            }
        },

        // initDialog: function() {
        //     switch (UserInfo.getUserType()) {
                // case USER_TYPE.MUNICIPALITY:
                    // 政令指定都市を除く市町村ユーザの場合は市町村コードを変更できない
                    // if(UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                    //     this.municipalitySelectorNode.set('value', UserInfo.getMunicipalityCd());
                    //     this.municipalitySelectorNode.set('noAllButton', true);
                    // }
                    // 政令指定都市ユーザの場合はデフォルト制御もしない
                    // break;
                // case USER_TYPE.REGION:
                //     // 振興局ユーザの場合、デフォルトだけセットする
                //     // this.municipalitySelectorNode.set('value', UserInfo.getRegionCd());
                //     this.municipalitySelectorNode.set('value', null);
                //     this.municipalitySelectorNode.set('noAllButton', false);
                //     break;
                // case USER_TYPE.PREFECTURE:
                //     // 県ユーザの場合はデフォルトだけ制御(未選択にする)
                //     this.municipalitySelectorNode.set('value', config.municInfo.cityMunicCd);
                //     this.municipalitySelectorNode.set('noAllButton', false);
                //     break;
        //         default:
        //             break;
        //     }
        // },

        /**
         *Excel 帳票を出力する
         */
         outputListExcel: function() {
            if(!this._disasterId){
                this.chain.info('災害名が正しく選択されていません。', 'エラー');
                return false;
            }

            var reqid = 'CHRONOLOGY_LIST_EX';
            var fileName ='ChronologyList.xlsx';
            var fileNameJp = '府連携・市本部内班間連携一覧.xlsx';
            var paramList = [
                {key: 'disid', value: this._disasterId}
            ];
            if(UserInfo.getRoleCd() !== 'R01001'){ //管理ユーザー意外の場合
                paramList.push({key: 'userOrganizationCd', value: UserInfo.getLowestOrganizationCd()});
            }

            var data4xoblosDownload = {
                fileName: fileName,
                reqid: reqid,
                paramList: paramList,
                fileType: 'excel'
            };

            var promise = this.download(data4xoblosDownload, fileNameJp).then(lang.hitch(this, function() {
                this.infoDialog = new InfoDialog({
                    title : 'ダウンロード完了',
                    content : 'ダウンロードが完了しました。'
                });
                this.infoDialog.show();

            }), lang.hitch(this, function(error) {
                console.error(error);
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : 'エラーが発生しました。管理者にお問い合わせください。'
                });
                this.infoDialog.show();
            }));

            Loader.wait(promise);
        },

        /**
         * PDF帳票を出力する
         */
        // outputListPdf: function() {
        //     var municipalityCd = this.form.get('value').municipalityCd;
        //     var regionCd = '';
        //     //var reptimestampFrom = this.form.get('value').reptimestampFrom;
        //     //var reptimestampTo = this.form.get('value').reptimestampTo;
        //     if(!this._disasterId){
        //         this.chain.info('災害名が正しく選択されていません。', 'エラー');
        //         return false;
        //     }
        //     // if(!municipalityCd){
        //     //     this.chain.info('市町村が選択されていません。', 'エラー');
        //     //     return false;
        //     // }
        //     // 振興局を選択していた場合
        //     if(municipalityCd.substring(0,3) === config.municInfo.prefRegCdGudge &&
        //         municipalityCd !== config.municInfo.prefMunicCd){
        //             regionCd = municipalityCd;
        //             municipalityCd = '';
        //     }
        //     //ユーザの情報を詰める
        //     var roleCd = UserInfo.getRoleCd();

        //     var reqid = 'CHRONOLOGY_LIST_EX';
        //     var fileName ='ChronologyList.pdf';
        //     var fileNameJp = '時系列情報一覧.pdf';
        //     var paramList = [
        //         {key: 'disid', value: this._disasterId},
        //         {key: 'roleCd', value: roleCd},
        //         {key: 'userId', value: UserInfo.getId()},
        //         {key: 'userOrganizationCd', value: UserInfo.getLowestOrganizationCd()}
        //     ];
        //     if(municipalityCd){
        //         paramList.push({key: 'municipalityCd', value: municipalityCd});
        //     }
        //     if(regionCd){
        //         paramList.push({key: 'regionCd', value: regionCd});
        //     }

        //     switch (UserInfo.getUserType()) {
        //         case USER_TYPE.MUNICIPALITY:
        //             // 自市町村をセット
        //             paramList.push({key: 'userMunicipalityCd', value: UserInfo.getMunicipalityCd()});
        //             break;
        //         case USER_TYPE.REGION:
        //             // 振興局ユーザの場合は県コードをセット
        //             //paramList.push({key: 'userMunicipalityCd', value: config.municInfo.prefMunicCd});
        //             paramList.push({key: 'userMunicipalityCd', value: UserInfo.getMunicipalityCd()});
        //             break;
        //         case USER_TYPE.PREFECTURE:
        //             // 県ユーザの場合は県コードをセット
        //             // paramList.push({key: 'userMunicipalityCd', value: config.municInfo.prefMunicCd});
        //             paramList.push({key: 'userMunicipalityCd', value: UserInfo.getMunicipalityCd()});
        //             break;
        //         default:
        //             // その他機関の場合はダミーをセットする
        //             paramList.push({key: 'userMunicipalityCd', value: '999999'});
        //             break;
        //     }

        //     var data4xoblosDownload = {
        //         fileName: fileName,
        //         reqid: reqid,
        //         paramList: paramList,
        //         fileType: 'pdf'
        //     };

        //     var promise = this.download(data4xoblosDownload, fileNameJp).then(lang.hitch(this, function() {
        //         this.infoDialog = new InfoDialog({
        //             title : 'ダウンロード完了',
        //             content : 'ダウンロードが完了しました。'
        //         });
        //         this.infoDialog.show();

        //     }), lang.hitch(this, function(error) {
        //         console.error(error);
        //         this.infoDialog = new InfoDialog({
        //             title : 'エラー',
        //             content : 'エラーが発生しました。管理者にお問い合わせください。'
        //         });
        //         this.infoDialog.show();
        //     }));

        //     Loader.wait(promise);
        // },

        /**
         * 月や日付を2桁にゼロpaddingする
         */
        _zeroPadding: function(month) {
            return ('00' + month).slice(-2);
        },

        // 帳票のダウンロード
        download: function(data, fileNameJp) {
            var deferred = new Deferred();

            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject();
                    return;
                }

                // バイナリデータを取得
                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                var baseFileName = fileNameJp;
                var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }

                deferred.resolve();

            };
            xhr.send(JSON.stringify(data));

            return deferred.promise;
        },

        /**
         * Date形式のtimestampをxoBlosインターフェイス仕様のtimestamp文字列に置き換える。
         */
        _getTimestampStr: function(ts){
            if(!ts){
                return '';
            }
            var year = dStr.pad(ts.getFullYear(), 4);
            var month = dStr.pad(ts.getMonth(), 2);
            var date = dStr.pad(ts.getDate(), 2);
            var hour = (ts.getHours()) ? dStr.pad(ts.getHours(), 2) : '00';
            var min = (ts.getMinutes()) ? dStr.pad(ts.getMinutes(), 2) : '00';
            var sec = (ts.getSeconds()) ? dStr.pad(ts.getSeconds(), 2) : '00';

            return year + month + date + hour + min + sec;
        },

        /**
         * キャンセルボタン押下
         */
        onCancel:function(){
            this.getParent().hide();
        }
    });
});
