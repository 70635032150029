/**
* 施設詳細画面用モジュール。
* @module app/facility/FacilityDetailPage
*/
define([
    'module',
    'app/config',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/FacilityDetailPage.html',
    'dojo/on',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-style',
    // 'dojo/request/iframe',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'idis/view/page/_PageBase',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/IdisDialog',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/service/GeoService',
    'idis/service/iframe',
    'idis/service/Requester',
    'idis/util/FilesUtils',
    'idis/view/Loader',
    'app/config',
    'app/model/District',
    'app/model/DisasterInfo',
    'esri-leaflet-geocoder',
    'exif-js/exif',
    'leaflet',
    'dojo/json',
    'app/map/baselayer/BaseLayerPane',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/FilteringSelect',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/NumberTextBox',
    'dijit/form/Textarea',
    'dijit/form/CheckBox',
    'dijit/form/RadioButton',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateInput',
    'idis/view/form/TimeInput',
    'idis/view/form/WordCountTextarea',
    'app/view/form/DistrictSelector',
    'app/view/form/ManageRegionMunicipalitySelector'
], function(module, appconfig, declare, lang, template, on, domClass, domConstruct, domStyle,
   Menu, MenuItem, popup, TooltipDialog, _PageBase,
    Locator, Router, DialogChain, InfoDialog, IdisDialog,
    IdisMap, UserInfo, IdisRest, GeoService, iframe, Requester, FilesUtils,
    Loader, config, District, DisasterInfo, geocoder, exif, leaflet,json, BaseLayerPane) {
        // GeoServiceを初期化
    var _geoService = null;

        /**
        * 施設詳細画面
        * @class FacilityDetailPage
        * @extends module:idis/view/page/_PageBase~_PageBase
        */
        return declare(module.id.replace(/\//g, '.'), _PageBase,
            /** @lends module:app/facility/FacilityDetailPage~FacilityDetailPage# */ {
                // テンプレート文字列
                templateString: template,

                // ストア
                facilityStore: null,

                /**
                * 施設ID
                */
                _facilityId: null,

                /**
                 * 災害ID
                 */
                _disasterId: null,

                /**
                * 住所 緯度
                */
                _addressLat: null,

                /**
                * 住所 経度
                */
                _addressLng: null,

                /**
                * バージョン
                */
                _version: null,

                /**
                 * 添付ファイルリスト
                 */
                attachFileList: [],

                /**
                * 施設ID
                */
                _facilityAdmId: null,

                constructor: function() {
                    // データ格納用オブジェクト
                    this.facilityStore = new IdisRest({
                        idProperty: 'facilityId',
                        target: '/api/facility'
                    });
                    // ダイアログ連鎖を登録
                    // 引数に与えたウィジェットのthis.ownを呼び出し、
                    // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
                    this.chain = DialogChain.get(this);
                    // 市町村コードを取得
                    var municipalityCd = UserInfo.getMunicipalityCds()[0] !== '' ?
                        UserInfo.getMunicipalityCds()[0] : UserInfo.getMunicipalityCd();
                    if(municipalityCd) {
                        this._municipalityCd = municipalityCd;
                    } else {
                        this._municipalityCd = config.municInfo.cityMunicCd;
                    }
                    // 施設IDを取得
                    this._facilityId = Locator.getQuery().facilityId;
                    // 災害IDをDisasterInfoから取得
                    this._disasterId = DisasterInfo.getDisasterId();
                    if(!this._disasterId) {
                        this._disasterId = 1;
                        console.warn('災害IDが設定されていません。');
                    }
                },

                // DOMノードを生成するためのメソッド
                buildRendering: function() {
                    this.inherited(arguments);
                    var promise = this.initTree();
                    Loader.wait(promise).then(lang.hitch(this, function(){
                        this.initForm();
                    }));
                },

                // HTML上にウィジェットが設置されてから呼ばれる
                startup: function() {
                    console.debug('FacilityDetailPage#startup()');
                    this.inherited(arguments);
                    // 画面を初期化
                    this.initPage();
                    // ツールチップをセット
                    this.setAvailableTips();
                },

                /**
                * 施設情報を更新するための準備をする。送るデータの体裁を整える。
                */
                onSubmit: function() {
                    var sendData = this.form.get('value');
                    sendData.version = this._version;
                    // 避難所区分がdisabledの場合、formから取得できないためここでセット
                    if (!sendData.designatedEvacShFlg && !sendData.welfareEvacShFlg) {
                        // 災害時・福祉避難所が空 = 臨時避難所
                        sendData.designatedEvacShFlg = [];
                        sendData.welfareEvacShFlg = [];
                        sendData.temporaryEvacShFlg = ['on'];
                    } else if (!sendData.temporaryEvacShFlg) {
                        // 災害時・福祉・臨時避難所が空 = 災害時または福祉
                        // 災害時・福祉の値はformから取得できるため、臨時避難所のみセットする
                        sendData.temporaryEvacShFlg = [];
                    }
                    // 臨時避難所の場合、災害IDを送る
                    if (sendData.temporaryEvacShFlg.length !== 0) {
                        sendData.disasterId = this._disasterId;
                    }
                    //添付ファイルIDをセット
                    if(this.attachFileList.length > 0) {
                        var attachFileIds = [];
                        for(var i=0; i<this.attachFileList.length; i++) {
                            attachFileIds.push(this.attachFileList[i].facilityAtcFileId);
                        }
                        sendData.attachFileIds = attachFileIds.join(',');
                    } else {
                        sendData.attachFileIds = '';
                    }
                    delete sendData.attachFile;
                    // 地図
                    if(this.pointLat === '' || this.pointLat === null ||
                    this.pointLng === '' || this.pointLng === null){//地図にピンがなかった場合
                        this._addressLat = null;
                        this._addressLng = null;
                        this.addressToLatLng().then(lang.hitch(this, function(){
                            sendData.latitude = this._addressLat === '' ? null : this._addressLat;
                            sendData.longitude = this._addressLng === '' ? null : this._addressLng;
                            this.validation(sendData);
                        }));
                    } else { //地図にピンがあった場合
                        sendData.latitude = this.pointLat === '' ? null : this.pointLat;
                        sendData.longitude = this.pointLng === '' ? null : this.pointLng;
                        this.validation(sendData);
                    }
                },

                /**
                * 入力内容のチェックを行う。
                */
                validation: function(sendData){
                    delete sendData.organizationName;
                    delete sendData.municipalityName;
                    // フォームのバリデーションを行う（共通部品）
                    if(!this.form.validate()) {
                        return false;
                    }
                    // フォームのバリデーションを行う（独自チェック）
                    if(!this.validateForm(sendData)) {
                        return false;
                    }
                    var message = '住所から緯度・経度が取得できませんでした。<br>' +
                            '緯度・経度の情報が必要な場合は、キャンセルして地図上で施設の位置をクリックしてください。<br>' +
                            '緯度・経度の情報が不要な場合は、OKボタンを押して登録を完了してください。';
                    //住所が有効かどうかチェック
                    if(this._addressLat === null || this._addressLng === null){//住所が無効である場合
                            console.debug('address is not correct');
                            this.chain.confirm(message,
                            function(chain) {
                            Loader.wait(this.facilityStore.put(sendData)).then(function() {
                                var compMessage = '完了しました。';
                                // 成功時（PUT結果はグリッドが自動的に反映）
                                chain.info(compMessage, '完了', function() {
                                    // 施設一覧ページに遷移
                                    Router.moveTo('facility');
                                });
                            }, function(err) {
                                // 失敗時
                                console.error(err);
                                chain.info('登録に失敗しました。', 'エラー');
                            });
                        });
                    } else { //住所が有効である場合
                        this.updateFacility(sendData);
                    }
                },

                /**
                * 施設情報を更新する。
                */
                updateFacility: function(sendData){
                    var message = '更新します。よろしいですか？';
                    this.chain.confirm(message, function(chain) {
                        // OKが押された場合
                        // 追加処理と共にローディング表示
                        Loader.wait(this.facilityStore.put(sendData)).then(function() {
                            var compMessage = '完了しました。';
                            // 成功時（PUT結果はグリッドが自動的に反映）
                            chain.info(compMessage, '完了', function() {
                                // 施設一覧ページに遷移
                                Router.moveTo('facility');
                            });
                        }, function(err) {
                            // 失敗時
                            chain.infoError(err);
                        });
                    });
                },

                // チェックボックス項目のフラグに従って画面項目をセットする
                setCheckBoxValues: function(item){
                   if(item.designatedEvacShFlg === '1'){
                        this.designatedEvacShFlg.set('value', 'on');
                        this.temporaryEvacShFlg.set('disabled', 'true');
                    }
                    if(item.welfareEvacShFlg === '1'){
                        this.welfareEvacShFlg.set('value', 'on');
                        this.temporaryEvacShFlg.set('disabled', 'true');
                    }
                    if(item.temporaryEvacShFlg === '1'){
                        this.temporaryEvacShFlg.set('value', 'on');
                        this.designatedEvacShFlg.set('disabled', 'true');
                        this.welfareEvacShFlg.set('disabled', 'true');
                        this.temporaryEvacShFlg.set('disabled', 'true');
                    }
                    this._version = item.version;
                },

                /**
                * 施設情報を削除する。
                */
                deleteFacilityButtonClick: function() {
                    var sendData = this.form.get('value');
                    // 避難所区分がdisabledの場合、formから取得できないためここでセット
                    if (!sendData.designatedEvacShFlg && !sendData.welfareEvacShFlg) {
                        // 災害時・福祉避難所が空 = 臨時避難所
                        sendData.designatedEvacShFlg = [];
                        sendData.welfareEvacShFlg = [];
                        sendData.temporaryEvacShFlg = ['on'];
                    } else if (!sendData.temporaryEvacShFlg) {
                        // 臨時避難所が空 = 災害時または福祉
                        // 災害時・福祉の値はformから取得できるため、臨時避難所のみセットする
                        sendData.temporaryEvacShFlg = [];
                    }
                    var message = '削除します。よろしいですか？';
                    console.debug('施設を削除します。sendData:' + sendData);
                    // 臨時避難所を選択の上、災害IDがnullであればエラー
                    if (sendData.temporaryEvacShFlg.length !== 0 && this._disasterId === null) {
                        console.debug('災害名が正しく選択されていません');
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                title: 'エラー',
                                content: '災害名が正しく選択されていません'
                            });
                            this.infoDialog.show();
                            this.infoDialog = null;
                        }
                        return false;
                    }
                    // 臨時避難所の場合、災害IDをパラメータに含める
                    var param = sendData.temporaryEvacShFlg.length !== 0 ?
                                    '?version=' + this._version + '&disasterId=' + this._disasterId :
                                    '?version=' + this._version;
                    this.chain.confirm(message, function(chain) {
                        chain.hide();
                        // OKが押された場合
                        // 施設IDに紐づく有効な避難所が存在するか確認。
                        var promise = Requester.get('/api/shelters/facility/hasActiveShelter', {
                            query: {
                                facilityId: this._facilityId
                            }
                        }).then(lang.hitch(this, function(result){
                            // Response: 避難所が存在する場合は該当IDリスト、存在しない場合は空リスト
                            if (result.length > 0) {
                                // 見つかった場合は処理中断
                                // エラーダイアログを画面に表示する
                                console.debug('施設に紐づく避難所情報があるため、削除できません。');
                                var errMessage = '施設に紐づく避難所情報があるため、削除できません。' +
                                                '<br>避難所ID:' + result.join(',') + 'を確認して下さい。';
                                chain.info(errMessage, 'エラー');
                            } else {
                                // 見つからなかった場合は削除実行
                                console.debug('施設に紐づく避難所情報はありません');
                                this.executeDeleteFacility(chain, param);
                            }
                        }), function(err){
                            // 失敗時
                            console.error(err);
                            // エラーダイアログを画面に表示する
                            chain.info(err);
                        });
                        Loader.wait(promise);
                    });
                },

                /**
                * 施設情報削除を実行する。
                */
                executeDeleteFacility: function(chain, param) {
                    console.info('in func executeDeleteFacility');
                    // 施設の論理削除 追加処理と共にローディング表示
                    Loader.wait(this.facilityStore.remove(Locator.getQuery().facilityId + param
                        )).then(function() {
                        var compMessage = '完了しました。';
                        // 成功時（DELETE結果はグリッドが自動的に反映）
                        // 完了ダイアログを画面に表示する
                        chain.info(compMessage, '完了', function() {
                            chain.hide();
                            // 施設一覧ページに遷移
                            Router.moveTo('facility');
                        });
                    }, function(err) {
                        // エラーダイアログを画面に表示する
                        console.log(err);
                        // 失敗時
                        chain.infoError(err);
                    });
                },

                /**
                * マップを初期化する。
                */
                initMap: function(lat, lng) {
                    console.debug('FacilityDetailPage#initMap()');
                    // マップの生成
                    var latlng = [lat, lng];
                    this.map = new IdisMap(this.mapNode, {
                        config: config.map,
                        keyboard: false, // コメント時に+/-が使用できないため
                        touchExtend : false,
                        minZoom: 9,
                        maxZoom: 18,
                        drawControlTooltips: false}
                    ).setView(latlng, 12);
                    // destroy時にmapを破棄するよう設定
                    this.own(this.map);
                    this.own(on(this.map, 'click', lang.hitch(this, function(e) {
                        this.pointLat = e.latlng.lat;
                        this.pointLng = e.latlng.lng;
                        this.addMark(this.pointLat, this.pointLng, this);
                    })));
                    //初期化
                    this.pointLat = '';
                    this.pointLng = '';
                    this.marker = '';
                },

                /**
                 * 背景地図ダイアログを表示する。
                 */
                showBaseLayerDialog: function() {
                    if (!this._baseLayerDialog) {
                        // 初回呼び出し時にインスタンス生成
                        this._baseLayerDialog = new IdisDialog({
                            noUnderlay: true,
                            title: '背景地図',
                            content: new BaseLayerPane({map: this.map})
                        });
                        // 画面が破棄された際に連れて行く
                        this.own(this._baseLayerDialog);
                    }
                    this._baseLayerDialog.show();
                },

                /**
                * 地図上でポイントされている位置の住所を設定します。
                */
                mapToAddress: function() {
                    if(this.pointLat === '' || this.pointLng === '') {
                        console.debug('not pointed map');
                        this.chain.info('地図が選択されていません。', 'エラー');
                        return;
                    }
                    console.debug('start reverse geocoding');
                if (_geoService === null) {
                    _geoService = new GeoService({ url: config.geocode && config.geocode.url });
                }
                    _geoService.reverseGeocode(leaflet.latLng({
                        lat: this.pointLat,
                        lng: this.pointLng
                    })).then(lang.hitch(this, function(res) {
                        this.addMark(this.pointLat, this.pointLng);
                        this.address.set('value', res.address.Address);
                        this._addressLat = this.pointLat;
                        this._addressLng = this.pointLng;
                    }), lang.hitch(this, function() {
                        this.chain.info('住所を取得できませんでした。', 'エラー');
                    }));

                    console.debug('end reverse geocoding (address: ' +
                    this.address.value + ')');
                },

                /**
                * 住所の位置を地図上にポイントします。
                */
                addressToMap: function() {
                    if(!this.address.value) {
                        this.chain.info('住所を入力してください。', 'エラー');
                        return;
                    }
                    console.debug('start geocoding');
                    var address = this.address.value;
                    // 区から始まる場合、先頭に「県名・市名」の記載を追加して検索する。
                    if(address.indexOf(appconfig.municInfo.cityMunicName) !== 0){
                        address = appconfig.municInfo.prefName + appconfig.municInfo.cityMunicName + address;
                    }
                    // 市から始まる場合、先頭に「県名」の記載を追加して検索する。
                    if(address.indexOf(appconfig.municInfo.prefName) !== 0){
                        address = appconfig.municInfo.prefName + address;
                } if (_geoService === null) {
                    _geoService = new GeoService({ url: config.geocode && config.geocode.url });
                }
                    _geoService.geocode(address).then(lang.hitch(this, function(results) {
                        if(results.length > 0) {
                            var latlng = [results[0].latlng.lat, results[0].latlng.lng];
                            this.pointLat = results[0].latlng.lat;
                            this.pointLng = results[0].latlng.lng;
                            this._addressLat = results[0].latlng.lat;
                            this._addressLng = results[0].latlng.lng;
                            this.map.setView(latlng, 14);
                            this.addMark(this.pointLat, this.pointLng);
                        } else {
                            console.debug('address is not correct');
                            this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                        }
                    }));
                },

                /**
                * DBに登録されている住所から、緯度経度を割り出す。
                * （＋DBに緯度経度情報が入力されている場合に、地図上にマーカーを追加し、表示する）
                */
                addressToMapInit: function(itemlat, itemlng) {
                    if(!this.address.value) {
                        this.chain.info('住所を入力してください。', 'エラー');
                        return;
                    }
                    console.debug('start geocoding');
                    var address = appconfig.municInfo.cityMunicName + this.address.value;
                if (_geoService === null) {
                    _geoService = new GeoService({ url: config.geocode && config.geocode.url });
                }
                    _geoService.geocode(address).then(lang.hitch(this, function(results) {
                        if(results.length > 0) {
                            var latlng = [results[0].latlng.lat, results[0].latlng.lng];
                            this._addressLat = results[0].latlng.lat;
                            this._addressLng = results[0].latlng.lng;

                            //DBに緯度経度情報が入力されている場合は、DBの情報を元に地図上にマーカーを追加する。
                            //（DBの住所と緯度経度の情報が異なる場合、マーカーは緯度経度情報を元に追加される）
                            if(itemlat !== null && itemlng !== null){
                                this.pointLat = itemlat;
                                this.pointLng = itemlng;
                                latlng = [itemlat, itemlng];
                                this.map.setView(latlng, 14);
                                this.addMark(this.pointLat, this.pointLng);
                            }
                        } else {
                            console.debug('address is not correct');
                            this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                        }
                    }));
                },

                /**
                * フォームに入力されている住所から、緯度・経度を割り出す
                */
                addressToLatLng: function() {
                    console.debug('start geocoding');
                    var address = appconfig.municInfo.prefName + this.address.value;
                if (_geoService === null) {
                    _geoService = new GeoService({ url: config.geocode && config.geocode.url });
                }
                    return _geoService.geocode(address).then(lang.hitch(this, function(results) {
                        if(results.length > 0){ //住所が有効である場合
                            this._addressLat = results[0].latlng.lat;
                            this._addressLng = results[0].latlng.lng;
                        } else { //住所が無効の場合（現実に存在しない住所が入力された場合）
                            this._addressLat = null;
                            this._addressLng = null;
                        }

                    }));
                },

                /**
                * マーカーを追加する。
                */
                addMark: function(lat, lng) {
                    this.removeMark();
                    this.marker = leaflet.marker([lat, lng]).addTo(this.map);
                },

                /**
                * マーカーを削除する。
                */
                removeMark: function() {
                    if(this.marker){
                        this.map.removeLayer(this.marker);
                    }
                },


                /**
                * 入力値の妥当性をチェックする。
                */
                validateForm: function(sendData) {
                    // 入力チェック
                    // 施設種別の入力確認
                    if (!sendData.facilityTypeId) {
                        console.debug('施設種別が選択されていません');
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                titel: 'エラー',
                                content: '施設種別が選択されていません'
                            });
                            this.infoDialog.show();
                            this.infoDialog = null;
                        }
                        return false;
                    }
                    // 避難所を選択した上で、避難所区分が一つも選択されていなければエラー
                    if(sendData.facilityTypeId === '01' &&
                        sendData.designatedEvacShFlg.length === 0 &&
                        sendData.welfareEvacShFlg.length === 0 &&
                        sendData.temporaryEvacShFlg.length === 0) {
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                title : 'エラー',
                                content : '避難所区分を選択して下さい。'
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                        return false;
                    }
                    // 施設名称の入力確認
                    if (!sendData.facilityName) {
                        console.debug('施設名称が入力されていません');
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                titel: 'エラー',
                                content: '施設名称が入力されていません'
                            });
                            this.infoDialog.show();
                            this.infoDialog = null;
                        }
                        return false;
                    }
                    // 施設名称（カナ）の入力確認
                    if (!sendData.facilityNameKana) {
                        console.debug('フリガナが入力されていません');
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                titel: 'エラー',
                                content: 'フリガナが入力されていません'
                            });
                            this.infoDialog.show();
                            this.infoDialog = null;
                        }
                        return false;
                    }
                    // 地区の選択確認
                    if(sendData.districtCd === '' || !sendData.districtCd) {
                        console.debug('地区が選択されていません');
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                title : 'エラー',
                                content : '地区が選択されていません'
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                        return false;
                    }
                    // 住所の入力確認
                    if (!sendData.address) {
                        console.debug('住所が入力されていません');
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                titel: 'エラー',
                                content: '住所が入力されていません'
                            });
                            this.infoDialog.show();
                            this.infoDialog = null;
                        }
                        return false;
                    }
                    // 臨時避難所を選択の上、災害IDがnullであればエラー
                    if (sendData.temporaryEvacShFlg.length !== 0 && sendData.disasterId === null) {
                        console.debug('災害名が正しく選択されていません');
                        if (!this.infoDialog) {
                            this.infoDialog = new InfoDialog({
                                title: 'エラー',
                                content: '災害名が正しく選択されていません'
                            });
                            this.infoDialog.show();
                            this.infoDialog = null;
                        }
                        return false;
                    }
                    return true;
                },

                /**
                * 画面を初期化する。
                */
                initPage: function() {
                    console.debug('FacilityDetailPage#initPage()');
                    //初期化
                    this.pointLat = '';
                    this.pointLng = '';
                    this.marker = '';
                    //初期表示では更新不可メッセージを非表示にする。
                    domStyle.set(this.notEditableMsg, 'display', 'none');
                },

                /**
                 * 避難所マスタ一覧画面リンクをクリックしたとき
                 */
                onFacilityLinkClick:function(evt) {
                    // ブラウザーの遷移処理をキャンセル
                    evt.preventDefault();
                    Router.moveTo('facility');
                },

                /**
                * フォームを初期化する。
                */
                initForm: function() {
                    var page = this.form;
                    console.debug('facilityId: ' + this._facilityId);
                    try{
                        Requester.get('/api/facility/' + this._facilityId + '/' + this._disasterId)
                        .then(lang.hitch(this,function(data) {
                            var item = data;
                            this._facilityAdmId = data.facilityAdmId;
                            page.set('value', item);
                            this.districtSelector.set('value',item.districtDto.districtCd);
                            // チェックボックスのうち、フラグがオンのものはcheckedにして表示する
                            this.setCheckBoxValues(item);
                            //DBに登録されている緯度・経度を元に、地図・マーカーをセット
                            //(緯度・経度がnullの場合は、住所からマーカーを追加)
                            if(item.address !== ''){
                                this.addressToMapInit(item.latitude, item.longitude);
                            }
                            if(item.latitude && item.longitude) {
                                // マップを初期化
                                this.initMap(item.latitude, item.longitude);
                            } else {
                                // 市町村を取得
                                var self = this;
                                var promise = Requester.get('/api/municipalities/' + this._municipalityCd, {
                                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                                    handleAs: 'json',
                                    preventCache : true
                                }).then(function(data) {
                                    self._municipalityName = data.municipalityName;
                                    if(self._municipalityCd !== config.municInfo.prefMunicCd) {
                                        self.address.set('value', data.municipalityName);
                                    }

                                    // マップを初期化
                                    self.initMap(data.latitude, data.longitude);
                                }, function(error) {
                                    self.chain.infoError(error);
                                });
                                //ローダーの表示
                                Loader.wait(promise);
                            }

                            // 添付ファイルを設定
                            this.attachFileList = [];
                            if(item.facilityAtcFiles) {
                                for(var i=0; i<item.facilityAtcFiles.length; i++) {
                                    this.attachFileList.push(item.facilityAtcFiles[i]);
                                    this.showPreview(item.facilityAtcFiles[i], false);
                                }
                            }
                        }));
                    } catch (e) {
                        console.error(e);
                    }
                },

                initTree: function() {
                    return this.districtSelector._initModel();
                },

                /**
                 * 添付ファイルをアップロードする。
                 */
                loadAttachFile: function() {
                    // ファイルが空の場合は処理を中断＆不正なファイルの場合、メッセージ表示して処理を中断
                    if (this.attachFile._files.length === 0  //||
                        // 札幌市では対象ファイルを制限しない
                        //!FilesUtils.isAttachFile(this.attachFile)
                        ) {
                        return;
                    }

                    console.log('file change');
                    var self = this;

                    this.attachFile.set('disabled', false);

                    // ファイルがonloadされたときにサーバーに一時保存する
                    var promise = iframe.post('/api/facility/uploadFile', {
                        form: this.form.id,
                        handleAs: 'json'
                    }).then(function(data) {
                        console.log(data);
                        //uploaderをリセット
                        self.attachFile.reset();
                        self.attachFileList.push(data);
                        self.showPreview(data, true);
                    }, function(error) {
                        console.log(error);
                        //uploaderをリセット
                        self.attachFile.reset();
                        self.chain.infoError(error);
                    });

                    //ローダーの表示
                    Loader.wait(promise);
                },

                /**
                 * 添付ファイルのプレビューを表示する。
                 */
                showPreview: function(data, exifFlg) {

                    var dataUri = data.attachFilePath.replace('out/', 'data/');
                    var fileName = data.attachFileName;
                    var fileId = data.facilityAtcFileId;
                    var self = this;

                    // 画像ファイルの場合
                    if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                    fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                    fileName.indexOf('.gif') !== -1) {
                        var image = new Image();

                        //JPEGファイルの場合、EXIFデータの取得を実行する
                        if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
                        fileName.indexOf('.JPG') !== -1 ||fileName.indexOf('.JPEG') !== -1) {
                            var img = null;
                            this.own(on(image, 'load', lang.hitch(this, function(e) {
                                console.log(e);
                                img = e.target;

                                if(exifFlg) {
                                    this.getExifData(img, this);
                                }
                            })));
                        }
                        image.src = dataUri;
                        domClass.add(image, 'is-showPreview');
                        domConstruct.place(image, this.preview);
                        //メニューの作成
                        this.createMenu(image, dataUri, fileName, fileId, self);

                    } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                        var excel = new Image();
                        excel.src = 'images/excelicon.png';
                        domClass.add(excel, 'is-showPreview');
                        domConstruct.place(excel, this.preview);
                        //メニューの作成
                        this.createMenu(excel, dataUri, fileName, fileId, self);
                    } else if (fileName.indexOf('.pdf') !== -1) {
                        var pdf = new Image();
                        pdf.src = 'images/pdficon.png';
                        domClass.add(pdf, 'is-showPreview');
                        domConstruct.place(pdf, this.preview);
                        //メニューの作成
                        this.createMenu(pdf, dataUri, fileName, fileId, self);
                    } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                        var word = new Image();
                        word.src = 'images/wordicon.png';
                        domClass.add(word, 'is-showPreview');
                        domConstruct.place(word, this.preview);
                        //メニューの作成
                        this.createMenu(word, dataUri, fileName, fileId, self);
                    } else {
                        var other = new Image();
                        other.src = 'images/othericon.png';
                        domClass.add(other, 'is-showPreview');
                        domConstruct.place(other, this.preview);
                        //メニューの作成
                        this.createMenu(other, dataUri, fileName, fileId, self);
                    }
                },

                /**
                 * JPEGファイルの位置情報を取得する
                 */
                getExifData: function(img, self) {
                    console.log('getting exif data start');
                    exif.getData(img, function(){

                        var latitude = exif.getTag(this, 'GPSLatitude');
                        var longitude = exif.getTag(this, 'GPSLongitude');

                        if(typeof latitude === 'undefined' || typeof longitude === 'undefined'){
                            console.log('GPS data is unavailable.');
                        }else{
                            console.log('GPS data is available.');
                            var f = function(number){
                                return number[0].numerator + number[1].numerator /
                                (60 * number[1].denominator) + number[2].numerator / (3600 * number[2].denominator);
                            };
                            self.chain.confirm('この画像の位置情報を使用しますか？', function(chain) {
                                // 位置情報を設定する
                                self.pointLat = f(latitude);
                                self.pointLng = f(longitude);
                                // 地図にマークして中心に移動する
                                self.addMark(self.pointLat, self.pointLng, self);
                                self.map.setView([self.pointLat, self.pointLng], 11);
                                //ダイアログを閉じる
                                chain.hide();
                            });
                        }
                    });
                },

                /**
                 * 添付ファイルのサムネイル上にメニューを作る
                 */
                createMenu: function(newNode, uri, fileName, id, self) {
                    var menu = new Menu({
                        targetNodeId: newNode
                    });
                    menu.set('style', {'border': 'none', 'box-shadow': 'none'});

                    //ダウンロード操作用
                    var download = null;
                    var userAgent = window.navigator.userAgent.toLowerCase();
                    if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                        var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                        // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                        // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                        download = domConstruct.create('a', {href: '#'});
                        //クリックでファイル取得処理に入る
                        download.onclick = function() {
                            self.downloadFile(url, fileName);
                        };
                    }else{
                        // FF, Chromeの場合、download属性でファイルダウンロード
                        download = domConstruct.create('a', {
                            href: uri,
                            download: fileName
                        });
                    }

                    // ファイル名とメニューとの境界線をセット
                    var contentNode = domConstruct.create('div');
                    contentNode.innerHTML = fileName;
                    domConstruct.place('<hr color=#b0c4de>', contentNode);
                    //メニューをセット
                    domConstruct.place(menu.domNode, contentNode);
                    var tooltip = new TooltipDialog({
                        content: contentNode
                    });
                    //
                    tooltip.containerNode.onmouseleave = function() {
                        popup.close(tooltip);
                    };

                    // 画像ファイルの場合のみ'開く'をメニューに追加する
                    if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                    fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                    fileName.indexOf('.gif') !== -1) {
                        menu.addChild(new MenuItem({
                            label: '開く',
                            iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
                            onClick: function() {
                                console.log('file open');
                                window.open(uri);
                            }
                        }));
                    }

                    menu.addChild(new MenuItem({
                        label: 'ダウンロード',
                        iconClass: 'dijitIconSave',
                        onClick: function(e) {
                            console.log('file download');
                            console.log(e);
                            //IE対策
                            if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                                download.onclick();
                            } else {
                                download.click();
                            }
                        }
                    }));

                    menu.addChild(new MenuItem({
                        label: '削除',
                        iconClass: 'dijitIconDelete',
                        onClick: function() {
                            console.log('file delete');

                            // 該当ファイルを削除
                            for(var i=0; i<self.attachFileList.length; i++) {
                                if(self.attachFileList[i].facilityAtcFileId === id) {
                                    self.attachFileList.splice(i,1); //id:3の要素を削除
                                }
                            }

                            // サムネイルとメニューを削除
                            domConstruct.destroy(newNode);
                            popup.close(tooltip);
                        }
                    }));

                    menu.startup();
                    //メニュー表示処理
                    this.own(on(newNode, 'mouseover', lang.hitch(this, function() {
                        popup.open({
                            popup: tooltip,
                            around: newNode,
                            orient: ['below-centered']
                        });
                    })));
                    //画面破棄時に一緒に破棄する
                    this.own(tooltip);
                },

                /**
                 * 添付ファイルをダウンロードする。
                 */
                downloadFile: function(url, name) {
                    // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
                    // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
                    var xhr = new XMLHttpRequest();
                    xhr.open('GET', url, true);
                    xhr.responseType = 'arraybuffer';
                    xhr.onload = function() {

                        var arrayBuffer = this.response;

                        var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});

                        // IE10+
                        if(window.navigator.msSaveOrOpenBlob){
                            window.navigator.msSaveOrOpenBlob(blob, name);
                        }

                    };
                    xhr.send();
                    return false;
                },

                /**
                * 地区が選択されていることを検証する
                */
                checkDistrict :function(item) {
                    var result = false;
                    // item.typeが'MDistrict'の場合はツリーの子要素であるため地区として選択可能にする
                    if(item.type === 'MDistrict') {
                        result = true;
                    } else {
                        result = false;
                    }
                    return result;
                },

                getDistrict: function(districtCd) {
                    return District.store.get(districtCd);
                },

                /**
                 * 利用可否のtips
                 */
                setAvailableTips: function() {
                    // 共有先
                    var message = '利用可否を不可とした場合は、<br>避難所状況画面での新規開設ができなくなります。';
                    var tooltip = new TooltipDialog({
                        id: 'availableTips',
                        style: 'width: 300px; height:100px',
                        content: '<p>' + message + '</p>'
                    });
                    var label = this.availableTip;
                    this.own(tooltip);
                    on(label, 'mouseover', lang.hitch(function() {
                        popup.open({
                            popup: tooltip,
                            around: label
                        });
                    }));
                    on(label, 'mouseleave', function() {
                        popup.close(tooltip);
                    });
                }
            }
        );
    }
);
