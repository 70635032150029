/**
* 体制状況・報告一覧画面用モジュール。
* @module app/disasterprevention/view/DisasterPreventionPrefStatusPage
*/
define([
    'module',
    'app/disaster/model/DisasterType',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/text!./templates/DisasterPreventionPrefStatusPage.html',
    'idis/control/Locator',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/model/UserInfo',
    'idis/control/Router',
    './model/DisasterPreventStatus',
    './model/DistributionType',
    './_DisasterPreventionPageBase',
    '../../config',
    // 以下、変数として受け取らないモジュール
    'app/provide/ProvidePreventionRegisterDialog',
    'app/view/form/OrganizationSelector',
    'app/view/form/UserSelector',
    'dijit/layout/BorderContainer',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'idis/view/form/DateInput',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    './DisasterPreventionPrefStatusGrid',
    './DisasterPreventionRegisterPage',
    './DisasterPreventionDetailPage',
    './DisasterPreventionFormDialog',
    './PrefPromotionBureauListSelector'
], function(module, DisasterType, declare, lang, json, template, Locator, Requester, IdisRest, DialogChain,
                Loader, UserInfo, Router, PrevStatus, DistributionType, _PageBase, config) {

    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/view/page/DisasterAdmintPage~DisasterAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--disasterprevention',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * 自治体コード(デフォルト：所属振興局)
         */
        areaCd: null,

        /**
        * 職員参集管理パラメータ格納用オブジェクト
        * @type {Object}
        */
        DisasterPrevInfItems : null,

        /**
         * 新規登録ボタン表示/非表示切り替えフラグ
         * ボタンを表示する場合はtrue
         */
        _buttonFlg: true,

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.confirmDialog);
        },

        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'disasterPrevId',
                target: '/api/disasterPrevention'
            });
        },

        // DOM要素構築後に呼ばれる
        postCreate: function() {
            // 体制状況にモデルをセット
            this.status.set('store', PrevStatus);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            if(Locator.getQuery().areaCd){
                // 県コードは画面上はPrefectureCdで扱う
                this.areaCd = !Locator.getQuery().areaCd||Locator.getQuery().areaCd===config.municInfo.prefMunicCd?
                    config.municInfo.prefCd : Locator.getQuery().areaCd;
            } else {
                this.areaCd = UserInfo.getRegionCd() || config.municInfo.prefCd;
            }
            this.initForm();
            this.initGrid();
            this.changeButton();
            this.initDetailPage();
        },

        /**
         * 検索フォームを初期化する。
         */
        initForm: function() {
            //初期値
            this.areaListSelector.set('value', this.areaCd);
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                evt.item.areaCd = evt.item.areaCd;
                this.showDetailDialog(evt.item);
            }));
            this.updateGridQuery();
        },

        /**
         * 詳細ダイアログのフォームが投稿された際の動作を設定する。
         */
        initDetailPage: function() {
            // 詳細ダイアログの最初の子要素が詳細画面
            var dialog = this.detailDialog;
            var page = dialog.getChildren()[0];
            // 詳細画面のsendイベントを受け取る
            page.on('put-send', lang.hitch(this, function(evt) {
                var sendData = evt.value;
                var provideFlg = sendData.provideFlg;
                delete sendData.provideFlg;
                // 災害IDをセット
                sendData.disasterId = this._disasterId;

                //添付ファイルIDをセット
                // var attachFileList = sendData.attachFileList;
                // if(attachFileList.length > 0) {
                //     var attachFileIds = [];
                //     for(var i = 0; i < attachFileList.length; i++) {
                //         attachFileIds.push(attachFileList[i].disasterPrevAtcFileId);
                //     }
                //     sendData.attachFileIds = attachFileIds.join(',');
                // } else {
                //     sendData.attachFileIds = '';
                // }

                sendData.attachFileIds = '';
                delete sendData.attachFile;
                delete sendData.attachFileList;

                // 配信種別：更新ボタン（insert）→'04' 訂正ボタン（update）→'05'
                var messg = 'この報告';
                if(sendData.updateFlg===1){
                    this.distributionType = DistributionType.CORRECT_BTN;
                    messg += 'を訂正します。よろしいですか？';
                }else{
                    this.distributionType = DistributionType.UPDATE_BTN;
                    messg += 'を更新します。よろしいですか？';
                }

                // 配信対象であれば配信内容確認画面を表示
                if (provideFlg) {
                        // 登録情報確認ダイアログ
                        this.showConfirmDialog(sendData);
                        return;
                } else {
                    sendData.disPrevDistributionType = DistributionType.NO_DISTRIBUTE;
                    delete sendData.isUnsentLalert;
                }

                // 外部配信対象外の場合、共通の登録確認画面
                this.chain.confirm(messg, function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.registerStore.put(sendData)).then(lang.hitch(this, function() {
                        this.initGrid();
                        chain.infoComplete(lang.hitch(this, function() {
                            dialog.hide();
                            chain.hide();
                        }));
                    }), function(err) {
                        // 失敗時
                        if(err.response.data && typeof(err.response.data) === 'string'){
                            err.response.data = json.parse(err.response.data);
                        }
                        chain.infoError(err);
                    });
                });
            }));

            // 詳細ダイアログの取消処理を受ける
            page.on('put-revert-send', lang.hitch(this, function(evt) {
                var sendData = evt.value;
                // 配信対象であれば配信内容確認画面を表示
                if (sendData.provideFlg) {
                    // 配信種別
                    this.distributionType = DistributionType.CANCEL;
                    // 配信フラグ
                    this._lastDetailItem.provideFlg = sendData.provideFlg;
                    // 登録情報確認ダイアログ
                    this.showConfirmDialog(this._lastDetailItem);
                    return false;
                }

                // 外部配信対象外の場合、共通の確認ダイアログを開く
                this.chain.confirmDel('この報告', lang.hitch(this, function(chain) {
                    // OKなら取消リクエストを実施
                    Loader.wait(this.deleteStore.put(sendData)).then(lang.hitch(this, function() {
                        chain.infoComplete(lang.hitch(this, function() {
                            // 取消成功時
                            this.initGrid();
                            // 各ダイアログを閉じる
                            this.detailDialog.hide();
                            chain.hide();
                        }));
                    }), function(err) {
                        // 失敗時
                        err.response.data = json.parse(err.response.data);
                        chain.infoError(err);
                    });
                }));
            }));
        },

        /**
         * 検索ボタンが押されたときに呼び出される。
         * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
         * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
         */
        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function() {
            var value = this.form.get('value');
            this.areaCd = value.areaCd;
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            // 災害ID
            filter = filter.eq('disasterId', this._disasterId);
           // 市町村コード
            filter = filter.eq('areaCd', this.areaCd);
            //体制状況
            if (value.status) {
                filter = filter.eq('status', value.status);
            }
            // 過去報表示
            filter = filter.eq('pastReportMode', this.pastReport.checked);
            // 報告日時FROM
            if (value.reportDateTimeFrom) {
                var reportDateTimeFrom = new Date(value.reportDateTimeFrom).getTime();
                filter = filter.eq('reportDateTimeFrom', reportDateTimeFrom);
            }
            // 報告日時TO
            if (value.reportDateTimeTo) {
                var reportDateTimeTo = new Date(value.reportDateTimeTo).getTime();
                filter = filter.eq('reportDateTimeTo', reportDateTimeTo);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);

            collection.fetch().then(lang.hitch(this, function(data) {
                if(data.totalLength !== 0){
                    //職員参集管理画面へのパラメータ
                    this.setDisasterPrevInf(data[0]);
                    // データがあれば新規登録ボタンを非表示にする
                    this._noRecordFlg = false;
                }else{
                    // データがあれば新規登録ボタンを表示にする
                    this._noRecordFlg = true;
                }
                this.changeButton();
            }));
        },

        //パンくずリストのボタンを押下したときの挙動
        onDisasterPreventionPrefListPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('disasterprevention/pref');
        },

        /**
         * 職員参集管理画面へ遷移
         */
        moveToEmpGatherStatus: function(){
            Router.moveTo('employgathering/pref', this.DisasterPrevInfItems);
        },

        /**
         * 職員参集管理画面へパラメータセット
         */
        setDisasterPrevInf: function(data){
            this.DisasterPrevInfItems = {};
            this.DisasterPrevInfItems.disasterId = data.disasterId;
            this.DisasterPrevInfItems.disasterPrevId = data.disasterPrevId;
            this.DisasterPrevInfItems.seqNum = data.seqNum;
            this.DisasterPrevInfItems.areaCd = this.areaCd;
            this.DisasterPrevInfItems.areaName = data.areaName;
            this.DisasterPrevInfItems.reportTimestamp = data.reportTimestamp.replace(/-/g, '/');
            this.DisasterPrevInfItems.disasterTypeName = DisasterType.get(data.disasterType).label;
            this.DisasterPrevInfItems.statusName = data.statusName;
            this.DisasterPrevInfItems.comment = data.comment === null ? '' : data.comment;
            this.DisasterPrevInfItems.hqName = data.hqName === null ? '' : data.hqName;
            if(data.hqSetTimestamp !== null){
                this.DisasterPrevInfItems.hqSetTimestamp = data.hqSetTimestamp.replace(/-/g, '/');
            }else{
                this.DisasterPrevInfItems.hqSetTimestamp = '-';
            }
            if(data.hqAbolishedTimestamp !== null){
                this.DisasterPrevInfItems.hqAbolishedTimestamp = data.hqAbolishedTimestamp.replace(/-/g, '/');
            }else{
                this.DisasterPrevInfItems.hqAbolishedTimestamp = '-';
            }
            this.DisasterPrevInfItems.hqSetPlace = data.hqSetPlace === null ? '' : data.hqSetPlace;
        }
    });
});
