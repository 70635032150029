/**
* 被害状況の新規登録・詳細・続報登録画面用の基底モジュール。
* @module app/damage/_DamageReportRegisterPageBase
*/
define([
    'module',
    'app/model/LayerStore',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/date/locale',
    'dojo/dom',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-geometry',
    'dojo/dom-style',
    'dojo/json',
    'dojo/on',
    'dojo/query',
    'dojo/request/iframe',
    'dojo/topic',
    'dojo/Deferred',
    'dstore/Rest',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dijit/registry',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'app/model/DisasterInfo',
    'idis/service/GeoService',
    'idis/service/Requester',
    'idis/util/FilesUtils',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/draw/_DrawUtil',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'leaflet',
    '../config',
    './damageDistMap/DamageDistrictLayerFactory',
    './DistrictCheckTree',
    'idis/view/tree/CheckTree',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'esri-leaflet-geocoder',
    'exif-js/exif',
    '../draw/DrawPanel',
    './_DamageReportPageBase',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dojox/form/Uploader',
    'dojox/layout/FloatingPane',
    'idis/view/form/Button',
    'idis/view/form/RadioGroup',
    '../view/form/LayerDirectorySelector',
    '../view/form/DisasterSelector',
    '../view/form/OrganizationSelector',
    '../view/form/MunicipalitySelector',
    'app/damage/facilitySelector/DamageFacilitySelector',
    'app/view/form/LifelineSelector',
], function (module, LayerStore, declare, lang, array, locale, dom, domClass, domConstruct, domGeometry, domStyle,
    json, on, query, iframe, topic, Deferred, Rest, Menu, MenuItem, popup, TooltipDialog, registry, Locator, Router,
    IdisMap, UserInfo, UserType, DisasterInfo, GeoService, Requester, FilesUtils, DialogChain, InfoDialog, DrawUtil,
    _PageBase, Loader, leaflet, config, DamageDistrictLayerFactory, DistrictCheckTree, CheckTree, CacheStoreModel, CacheTreeRest,
    geocoder, exif, DrawPanel, _DamageReportPageBase) {
    // GeoServiceを初期化
    var _geoService = null;

    /**
     * 被害状況新規登録・詳細・続報登録画面。
     * @class _DamageReportPageBase
     * @extends module:idis/view/page/_DamageReportPageBase~_DamageReportPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _DamageReportPageBase,
        /** @lends module:app/damage/_DamageReportPageBase~_DamageReportPageBase# */ {

            //作図ダイアログ
            drawPanel: null,

            DRAW_INIT_ID: '/app/draw/DrawPanel::SettingDrawEvents',
            JSONIZE_RQST: '/app/draw/DrawPanel::drawnDataJsonizeRequest',
            JSONIZE_DONE: '/app/draw/DrawPanel::drawnDataJsonizeResponse',
            DRAW_BY_JSON: '/app/draw/DrawPanel::drawGeoJSONToLayer',
            REMOVE_ALL: '/app/draw/DrawPanel::removeAllLayers',
            //イベントは破棄しない、全作図モードをOFFにする
            DISABLE_DRAW: '/app/draw/DrawPanel::hideAndDisableDraw',
            //mapに取り付けた全てのイベントを破棄する。
            DRAW_EVT_OFF: '/app/draw/DrawPanel::removeDrawAllEvent',
            // forIE anchorにOnclickでPublishをして、msSaveへ情報を渡す。
            DOWNLOAD_4IE: '/app/damage/MapPage::download4IE',

            DAMAGE_GRID_TYPE_SELECTED: 'app/damage/damagetype/DamageGridTypeSelector::selected',
            DAMAGE_GRID_TYPE_RELEASED: 'app/damage/damagetype/DamageGridTypeSelector::released',
            SELECT_LAYER_NO: 100000,

            /**
             * 被害状況ID
             * @private
             */
            _damageReportId: null,

            /**
             * 続報番号
             * @private
             */
            _seqNum: null,

            /**
             * 外部管理番号
             * @private
             */
            _extAdmNum: null,

            /**
             * 被害種別 リスト
             * @private
             */
            _damageType: null,
            _damageTypeList: null,

            /**
             * 発生場所 緯度
             * @private
             */
            _damageAddressPointLat: null,

            /**
             * 発生場所 経度
             * @private
             */
            _damageAddressPointLng: null,

            /**
             * 大阪市：レイヤーコントロール
             */
            _layerControl: null,

            _damageTypeDispList: [],
            
            _districtTree: null,
            _subDistrict : false,

            /**
             * 地図を初期化する。
             */
            initMap: function (latitude, longitude) {
                var latlng = null;
                if (latitude && longitude) {
                    latlng = [latitude, longitude];
                } else {
                    latlng = [config.map.latitude, config.map.longitude];
                }

                this.map = new IdisMap(this.mapNode, {
                    config: config.map,
                    keyboard: false, // コメント時に+/-が使用できないため
                    touchExtend: false,
                    minZoom: 9,
                    maxZoom: 18,
                    drawControlTooltips: false
                }
                ).setView(latlng, 14);
                // destroy時にmapを破棄するよう設定
                this.own(this.map);
                this.own(on(this.map, 'click', lang.hitch(this, function (e) {
                    // 作図ダイアログが閉じられているとき住所取得モードとする
                    if (this.drawPanel._ActiveMode === null) {
                        this.pointLat = e.latlng.lat;
                        this.pointLng = e.latlng.lng;
                        this.addMark(this.pointLat, this.pointLng, this);
                    }
                })
                ));
                this._layerControl = this.map.layerControl;


                // 作図パネルを生成
                this.createDrawPanel();
                topic.publish(this.DRAW_INIT_ID, this.map);
                topic.subscribe(this.JSONIZE_DONE, lang.hitch(this, function (args) {
                    this.drawJson = args;
                }));


                // IE対応
                // イベントを管理する人は各Mapに必要。
                // TODO pub/subの方がよいか？
                if (DrawUtil._isIE()) { DrawUtil._setPopupEvtForMap(this); }
                this.borderContainer.resize();
            },
            /**
             * すべてのレイヤーを削除
             */
            resetLayer: function () {
                if (!this._layerControl) {
                    return;
                }
                // 地図上のレイヤーを全て削除する。
                var layerControl = this._layerControl;
                Object.keys(layerControl.layers).forEach(lang.hitch(this, function (key) {
                    layerControl.removeLayerById(key);
                }));
            },

            /**
             * 選択された地区一覧を取得
             * @returns 地区一覧
             */
            getLifelineDistList: function () {
                if (this._layerControl && this._layerControl.layers[this.SELECT_LAYER_NO]) {
                    return this._layerControl
                        .layers[this.SELECT_LAYER_NO]
                        .getLayers()
                        .filter(function (item) { return item.options._isSelected; })
                        .map(function (item) {
                            return {
                                districtCd: item.feature.properties.D_CD,
                                districtFreeName: item.feature.properties.D_NAME
                            };
                        });
                } else {
                    return [];
                }
            },

            /**
             * 対応状況のTipsを付与する。
             */
            setHldStatusTips: function () {
                // 避難理由
                var hldStatusTips = new TooltipDialog({
                    id: 'hldStatusTips',
                    style: 'width: 300px;',
                    content: '<p>詳細な対応内容は、画面右上の「対応履歴」タブ内で' +
                        '登録・編集が可能です。</p>'
                });
                this.own(hldStatusTips);
                on(dom.byId('hldStatusLabel'), 'mouseover', function () {
                    popup.open({
                        popup: hldStatusTips,
                        around: dom.byId('hldStatusLabel')
                    });
                });
                on(dom.byId('hldStatusLabel'), 'mouseleave', function () {
                    popup.close(hldStatusTips);
                });
            },


            /**
             * タブを切り替える際に、メイン地図とサブ地図の座標・縮尺を整合させる
             */
            showTab1: function () {
                if (this.map && this.submap) {
                    var latlng = this.submap.getCenter();
                    var zoom = this.submap.getZoom();
                    this.map.setView(latlng, zoom);
                }
            },


            /**
             * 作図ペインを生成する。
             */
            createDrawPanel: function () {
                if (this.drawPanel === null) {
                    this.own(
                        this.drawPanel = new DrawPanel({
                            map: this.map,
                            'class': 'drawPanel-NonModal',
                            dispType: 'damage'
                        }));
                }
            },

            /**
             * 被害種別変更時のイベントを設定
             */
            controlDamageDetailContents: function () {
                if (registry.byId('damageType')) {
                    registry.byId('damageType').on('change', lang.hitch(this, function (value) {
                        this.tabContainer.selectChild(this.tab2);
                        // this.changeDamageDetailContents(value);
                    }));
                }
            },

            setDamageType: function (data, quiet) {
                console.log('set Damage Type');
                this._damageType = data.mainTypeIds[0]; //FIXME: 一旦DBに送るのはメインの被害種別のみ
                this._damageTypeList = data.mainTypeIds.join(',');
                // 状況報告の場合
                if (this._damageType === '19') {
                    this.caseInfo.style.display = 'block';
                    this.damageCaseInfo.style.display = 'none';
                    this.damageAttentionArea.style.display = 'none';
                    this.lifelineType.style.display = 'block';
                } else {
                    this.caseInfo.style.display = 'none';
                    this.damageCaseInfo.style.display = 'block';
                    this.damageAttentionArea.style.display = 'block';
                    this.lifelineKindCd.set('value', '');
                    this.lifelineType.style.display = 'none';
                }
                // 詳細表示の時
                if (this.damageActionInfo && this.roadInfo) {
                    // 道路の場合
                    if (this._damageTypeList.indexOf('09') !== -1) {
                        this.damageActionInfo.style.display = 'none';
                        this.roadInfo.style.display = 'block';
                    } else {
                        this.damageActionInfo.style.display = 'block';
                        this.roadInfo.style.display = 'none';
                    }
                }
                // 水道の場合
                if ((
                    this._damageTypeList.indexOf('14') !== -1 ||
                    this._damageTypeList.indexOf('15') !== -1 ||
                    this._damageTypeList.indexOf('29') !== -1 ||
                    this._damageTypeList.indexOf('28') !== -1 ||
                    this._damageTypeList.indexOf('30') !== -1
                ) && !this._municipalityCd) {
                    InfoDialog.show('入力チェック', '先に「区」を選択してください。');
                    this.innerDamageGridTypeSelector.release();
                    return;
                }
                if (!quiet) {
                    InfoDialog.show('被害種別を選択しました');
                }
                this.showBothArrowDrawButton.style.display = (this._damageType === "09" ? '' : 'none');
                this.showArrowDrawButton.style.display = (this._damageType === "09" ? '' : 'none');
                if (this.treeArea) {
                    this.treeArea.style.display = 'none';
                }
                // 水害のレイヤー設定
                this.resetLifelineDistrictLayer();

            },

            onDistrictChange: function () {
                if (this._initLifelineDistList) {
                    this._layerControl
                        .layers[this.SELECT_LAYER_NO]
                        .getLayers()
                        .filter(lang.hitch(this, function (item) {

                            return this._initLifelineDistList
                                .map(function (item) {
                                    return item.districtCd;
                                })
                                .includes(item.feature.properties.D_CD);
                        }))
                        .forEach(function (item) {
                            item.options._isSelected = true;
                            item.options.fillColor = item.options._selectedColor;
                            item.setStyle(item.options._selectedMouseOnStyle);
                        });

                }
            },

            resetLifelineDistrictLayer: function () {
                if (!this._damageTypeList || !this._municipalityCd) {
                    return;
                }

                // 大阪市：レイヤー初期化
                this.resetLayer();
                if(!!this._districtTree) {
                    this._districtTree.destroyRecursive();
                }

                if (this._damageTypeList.indexOf('14') !== -1 ||
                    this._damageTypeList.indexOf('15') !== -1 ||
                    this._damageTypeList.indexOf('29') !== -1 ||
                    this._damageTypeList.indexOf('28') !== -1 ||
                    this._damageTypeList.indexOf('30') !== -1) {
                    if (this.treeArea) {
                        this.treeArea.style.display = '';
                    }
                    return Requester.get('/data/layer/data/evacorder/area/' + this._municipalityCd + '.geojson')
                        .then(lang.hitch(this, function (selectLayer) {
                            // 地区レイヤーファクトリクラスにツリーの地区情報も渡して、geojsonに登録されている地区をfilterする。
                            var layer = DamageDistrictLayerFactory.getGeoJsonLayers(
                                selectLayer, this._municipalityCd);
                            this._layerControl.addLayer(layer, this.SELECT_LAYER_NO);
                            this._layerControl.toFront(this.SELECT_LAYER_NO);
                            
                            // 地区ツリーの情報を取得する
                            if (this.treeNode) {
                                this._districtTree = new DistrictCheckTree({
                                    model: new CacheStoreModel({
                                        store: new CacheTreeRest({
                                            target: '/api/district/districtTree?municipalityCd=' + this._municipalityCd
                                        })
                                    })
                                }, this.treeNode);
                                this.own(this._districtTree);
                                this._districtTree.placeAt(this.parentTreeNode);
                                this._districtTree.startup();
                                return this._districtTree.onLoadDeferred
                                    .then(lang.hitch(this, function () {
                                        if (this._initLifelineDistList) {
                                            this._selectDistricts(this._initLifelineDistList.map(function (i) { return i.districtCd; }));
                                        }
                                        if (!this._subDistrict) {
                                            // 地図選択＞チェックボックス選択時の処理
                                            this.own(topic.subscribe('app/damage/damageDistMap/DamageDistrictLayerFactory::selectDistrict',
                                                lang.hitch(this, function (payload) {
                                                    console.log('selectDistrict from map');
                                                    console.log(payload);
                                                    this.selectCheckTreeFromDistrictLayer(payload.id);
                                                })
                                            ));

                                            // チェックボックス>地図選択時の処理
                                            this.own(topic.subscribe('app/damage/DistrictCheckTree::districtCheckChenge',
                                                lang.hitch(this, function (payload) {
                                                    console.log('selectDistrict from checkbox');
                                                    console.log(payload);
                                                    this.selectDistrictLayerFromCheckTree(payload);
                                                })
                                            ));

                                            this._subDistrict = true;
                                        }
                                    }));
                                }
                        })).then(lang.hitch(this, function () {
                            // レイヤーが追加されてから、避難区分変更時のメソッドを呼んで避難区分に応じた色を設定する。
                            // 市町村を切り替えた際に、レイヤーを追加した後に色を反映したいため、レイヤー追加後に呼んでおく。
                            this.onDistrictChange(this);
                        }));
                }
            },
            releaseDamageType: function () {
                this._damageType = null;
                this._damageTypeList = null;
                this.showBothArrowDrawButton.style.display = 'none';
                this.showArrowDrawButton.style.display = 'none';
                this.lifelineKindCd.set('value', '');
                this.lifelineType.style.display = 'none';
                // 大阪市：レイヤー初期化
                this.resetLayer();
            },

            /**
             * 指定された地区コード一覧をチェック状態にする。
             * @param {string[]} districtCdList 地区コード一覧
             */
            _selectDistricts: function (districtCdList) {
                array.forEach(districtCdList, function (distCd) {
                    this._districtTree.model.store.get(distCd).then(lang.hitch(this, function(item) {
                         if (item) {
                             this._districtTree.setChecked(item, true);
                         }
                    }));
                }, this);
            },

            /**
             * チェックツリーを選択する。
             * 地図レイヤー選択時に呼ばれる。
             */
            selectCheckTreeFromDistrictLayer: function (id) {
                // 対応するチェックボックスの選択状態を切り替える
                this._districtTree.model.store.get(id).then(lang.hitch(this, function (item) {
                    this._districtTree.setChecked(item, !this._districtTree.isChecked(item));
                }));
            },

            /**
             * 避難地区を選択状態にする。
             * チェックボックス選択時に呼ばれる。
             */
            selectDistrictLayerFromCheckTree: function (id) {
                // 対応する避難地区情報をもつレイヤーを選択する
                var layerList = this._layerControl.layers[this.SELECT_LAYER_NO].getLayers();
                array.some(layerList, function (layer) {
                    // 背景地図レイヤーはfeatureを持っていないため事前に確認する
                    // D_CDは地区レイヤーに設定する地区ID。geojson、レイヤーのプロパティjson、treejsonに設定されている。
                    var layerId = layer.feature && layer.feature.properties.D_CD;
                    if (layerId === id) {
                        var options = layer.options || layer._layers[layer._leaflet_id - 1].options; //jshint ignore:line
                        options.selectDistrictLayer(layer);
                        return true; // break
                    }
                });
            },

            /**
             * 報告状況を初期化する
             */
            initReportStatus: function (reportStatus) {
                if (!reportStatus) {
                    // 報告状況が与えられていない場合、ユーザ種別によってデフォルト値を決定
                    switch (UserInfo.getUserType()) {
                        case UserType.PREFECTURE:
                            reportStatus = '12';
                            break;
                        case UserType.REGION:
                            reportStatus = '12';
                            break;
                        case UserType.MUNICIPALITY:
                            reportStatus = '12';
                            break;
                        default:
                            reportStatus = '12';
                            break;
                    }
                }

                if (reportStatus === 11) {
                    return;
                }

                // 対応状況をセットする
                this.setReportStatus(reportStatus);
            },


            /**
             * 報告状況を更新する
             */
            setReportStatus: function (reportStatus) {
                this._reportStatus = reportStatus;

                this.reportedStatus11.style.display = 'none';
                this.reportedStatus12.style.display = 'none';
                this.reportedStatus31.style.display = 'none';
                this.reportedStatus32.style.display = 'none';
                this.reportedStatus41.style.display = 'none';
                this.publicReport0.style.display = 'none';
                this.publicReport1.style.display = 'none';
                this.publicReport2.style.display = 'none';
                this.publicReport9.style.display = 'none';
                if (reportStatus === '12') {
                    this.reportedStatus12.style.display = '';
                }
                if (reportStatus === '31') {
                    this.reportedStatus31.style.display = '';
                }
                if (reportStatus === '32') {
                    this.reportedStatus32.style.display = '';
                }
                if (reportStatus === '41') {
                    this.reportedStatus41.style.display = '';
                }
            },

            /**
             * 被害詳細関連入力フィールドの表示/非表示を切り替える。
             */
            changeDamageDetailContents: function (value, updList) {
                var typeList = value.split(',');
                var validTypeFlg = false;
                var detailFieldMap = {
                    '01': this.home,
                    '02': this.life,
                    '03': this.educ,
                    '04': this.hosp,
                    '05': this.gbld,
                    '06': this.sedi,
                    '07': this.fire,
                    '08': this.rivr,
                    '09': this.road,
                    '10': this.agri,
                    '11': this.othr,
                    '14': this.wate,
                    '15': this.sewa,
                    '16': this.snow
                };
                Object.keys(detailFieldMap).forEach(function (key) {
                    for (var i = 0; i < typeList.length; i++) {
                        if (key === typeList[i]) {
                            detailFieldMap[key].style.display = '';
                            validTypeFlg = true;
                            break;
                        } else {
                            detailFieldMap[key].style.display = 'none';
                        }
                    }
                }, detailFieldMap);

                // 被害種別を変更する場合は、強調表示を無効化する
                if (updList.length > 0) {
                    array.forEach(updList, function (propName) {
                        if (propName === 'damageName') {
                            query('.damageNameLabel').forEach(function (node) {
                                domClass.remove(node, 'is-changed');
                            });
                        } else {
                            if (propName === 'organization' || propName === 'reportAuthorName') {
                                propName = 'preAuthor';
                            }
                            if (propName === 'reportUpdTimestamp') {
                                propName = 'preReportTimestamp';
                            }
                            if (dom.byId(propName + 'Label')) {
                                domClass.remove(dom.byId(propName + 'Label'), 'is-changed');
                            }
                        }
                    });
                }


            },

            /**
             * 大阪市：被害地図の設定
             */
            initLifelineDistMap: function () {
                this.map.layerControl;
            },

            /**
             * 作図ダイアログを表示する。
             */
            showDrawPanelDialog: function () {
                this.drawPanel.show();
            },

            /**
             * 両方方矢印を描画する
             */
            drawBothArrow: function () {
                this.drawPanel.setTargetFunc('drawPanel-bothRoadClosedButton');
            },

            /**
             * 一方向矢印を描画する
             */
            drawArrow: function () {
                this.drawPanel.setTargetFunc('drawPanel-oneRoadClosedButton');
            },

            /**
             * 入力値の妥当性をチェックする。
             */
            validateForm: function (data) {
                if (!data.municipalityCd || data.municipalityCd === '') {
                    this.chain.info('区を選択してください。', '入力エラー');
                    return false;
                }
                if (!data.damageType || data.damageType === '') {
                    this.chain.info('被害の種類を選択してください。', '入力エラー');
                    return false;
                }
                if (!data.reportAuthorName || data.reportAuthorName === '') {
                    this.chain.info('報告者氏名を入力してください。', '入力エラー');
                    return false;
                }
                if (!data.reportTimestamp || data.reportTimestamp === '') {
                    this.chain.info('報告日時を入力してください。', '入力エラー');
                    return false;
                }
                return true;
            },

            /**
             * 地図上でポイントされている位置の住所を設定します。（発生場所）
             */
            mapToDamageAddress: function () {
                this.mapToAddress(this.damageAddress);
                this._damageAddressPointLat = this.pointLat;
                this._damageAddressPointLng = this.pointLng;
            },

            /**
             * 住所の位置を地図上にポイントします。（発生場所）
             */
            damageAddressToMap: function () {
                var dfd = this.addressToMap(this.damageAddress);
                dfd.then(lang.hitch(this, function () {
                    this._damageAddressPointLat = this.pointLat;
                    this._damageAddressPointLng = this.pointLng;
                }), lang.hitch(this, function () {
                    this.chain.info('住所を取得できませんでした。', 'エラー');
                }));

            },

            /**
             * 地図上でポイントされている位置の住所を設定します。
             */
            mapToAddress: function (addressWidget) {
                if (this.pointLat === '' || this.pointLng === '') {
                    console.debug('not pointed map');
                    this.chain.info('地図が選択されていません。', 'エラー');
                    return;
                }
                console.debug('start reverse geocoding');

                if (_geoService === null) {
                    _geoService = new GeoService({ url: config.geocode && config.geocode.url });
                }
                _geoService.reverseGeocode(leaflet.latLng({
                    lat: this.pointLat,
                    lng: this.pointLng
                })).then(lang.hitch(this, function (res) {
                    this.addMark(this.pointLat, this.pointLng);

                    if (res.address.Address) {
                        addressWidget.set('value', res.address.Address);
                    } else {
                        this.chain.info('住所を取得できませんでした。', 'エラー');
                    }

                }), lang.hitch(this, function () {
                    this.chain.info('住所を取得できませんでした。', 'エラー');
                }));

                console.debug('end reverse geocoding (address: ' +
                    addressWidget.value + ')');
            },

            /**
             * 住所の位置を地図上にポイントします。
             */
            addressToMap: function (addressWidget) {
                console.debug('start geocoding (address: ' +
                    addressWidget.value + ')');

                if (!addressWidget.value) {
                    console.log('not input address');
                    this.chain.info('住所を入力してください。', 'エラー');
                    return;
                }
                var address = addressWidget.value;

                //市名がない場合、他県の地名を探し出す可能性があるので県名をつける
                if (address.indexOf(config.municInfo.cityMunicName) === -1) {
                    address = config.municInfo.cityMunicName + address;
                }
                //県名がない場合、他県の地名を探し出す可能性があるので県名をつける
                if (address.indexOf(config.municInfo.prefName) === -1) {
                    address = config.municInfo.prefName + address;
                }

                if (_geoService === null) {
                    _geoService = new GeoService({ url: config.geocode && config.geocode.url });
                }
                var dfd = _geoService.geocode(address).then(lang.hitch(this, function (results) {
                    if (results.length > 0) {
                        var latlng = [results[0].latlng.lat, results[0].latlng.lng];
                        this.pointLat = results[0].latlng.lat;
                        this.pointLng = results[0].latlng.lng;
                        this.addMark(this.pointLat, this.pointLng);
                        this.map.setView(latlng, 14);
                    } else {
                        console.debug('address is not correct');
                        this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                    }
                })
                );

                console.debug('end reverse geocoding (latitude: ' +
                    this.pointLat + ', longitude: ' + this.pointLng + ')');
                return dfd;
            },

            /**
             * マーカーを追加する。サブ地図がある場合は両方の地図でマーカーを共有する
             */
            addMark: function (lat, lng) {
                this.removeMark();
                this.marker = leaflet.marker([lat, lng]).addTo(this.map);
            },

            /**
             * マーカーを削除する。
             */
            removeMark: function () {
                if (this.marker) {
                    this.map.removeLayer(this.marker);
                }
            },

            /**
             * 周辺の被害情報を地図に描画する
             */
            setDamageReportMarkers: function (items, selfDamageId) {
                this.layerGroup = new leaflet.featureGroup();
                this.layerGroupForSubMap = new leaflet.featureGroup();

                for (var i = 0; i < items.length; i++) {
                    var item = items[i];
                    var lat = item.latitude;
                    var lng = item.longitude;

                    //緯度経度があるもののみ表示
                    if (!lat || !lng) {
                        continue;
                    }
                    //自分自身は表示させない
                    if (selfDamageId && Number(item.damageReportId) === Number(selfDamageId)) {
                        continue;
                    }

                    //ポップアップ
                    var content = '';
                    if (item.damageType) {
                        content = '<tr><td style="color:#FF0000"><b>被害位置</b></td></tr>';
                        content += '<tr><td></td></tr>';
                        content += '<tr><td>管理番号:</td><td>' + item.admNum + '</td></tr>';
                        content += '<tr><td>被害種別:</td><td>' + this._damageTypeDispList[item.damageType] + '</td></tr>';
                        if (item.damageAddress) {
                            content += '<tr><td>住所:</td><td>' + item.damageAddress + '</td></tr>';
                        }

                        if (item.hldStatus !== null) {
                            var hldStatusMap = {
                                '0': '確認中',
                                '1': '対応待ち',
                                '2': '対応中',
                                '3': '対応済'
                            };
                            content += '<tr><td>対応状況:</td><td>' + hldStatusMap[item.hldStatus] + '</td></tr>';
                        }

                        if (item.urgencyType !== null) {
                            var urgencyTypeMap = {
                                '0': '-',
                                '1': '低',
                                '2': '中',
                                '3': '高'
                            };
                            content += '<tr><td>緊急度:</td><td>' + urgencyTypeMap[item.urgencyType] + '</td></tr>';
                        }

                        content += '<tr><td></td></tr>';

                    } else {
                        if (item.description) {
                            content += '<tr><td>' + item.content + '</td></tr>';
                        }
                    }


                    var marker = this.makeDamageMarker(item);
                    var subMarker = this.makeDamageMarker(item);

                    //メイン地図にのみポップアップを設定
                    if (content) {
                        marker.bindPopup('<table>' + content + '</table>', { maxWidth: 1000, closeButton: false });
                    }

                    this.layerGroup.addLayer(marker);
                    this.layerGroupForSubMap.addLayer(subMarker);

                }

            },

            /**
             * 添付ファイルをアップロードする。
             */
            loadAttachFile: function () {
                console.log('file change');
                // ファイルが空の場合は処理を中断＆不正なファイルの場合、メッセージ表示して処理を中断
                if (this.attachFile._files.length === 0  //||
                    // 札幌市では対象ファイルを制限しない
                    //!FilesUtils.isAttachFile(this.attachFile)
                ) {
                    return;
                }

                console.log('file change');
                var self = this;

                this.attachFile.set('disabled', false);

                // ファイルがonloadされたときにサーバーに一時保存する
                var promise = iframe.post('/api/damageReports/uploadFile', {
                    query: {
                        disasterId: this._disasterId
                    },
                    form: this.form.id,
                    handleAs: 'json'
                }).then(function (data) {
                    console.log(data);
                    //uploaderをリセット
                    self.attachFile.reset();
                    self.attachFileList.push(data);
                    self.showPreview(data, true);
                }, function (error) {
                    console.log(error);
                    //uploaderをリセット
                    self.attachFile.reset();
                    self.chain.infoError(error);
                });

                //ローダーの表示
                Loader.wait(promise);
            },

            /**
             * 添付ファイルのプレビューを表示する。
             */
            showPreview: function (data, exifFlg) {
                // #12657追加▼
                // 削除フラグが立っている場合は表示しない　※文字列で受け渡し
                if (data.delFlg === 'true') {
                    return;
                }
                // #12657追加▲
                var dataUri = data.attachFilePath.replace('out/', 'data/');
                var fileName = data.attachFileName;
                // #12657変更▼
                // var fileId = data.chronologyAtcFileId;
                var fileId = data.damageReportAtcFileId;
                // #12657変更▲
                var self = this;
                // #12657追加▼
                // 公開フラグ
                var publicFlg = data.publicFlg;
                // URIからファイルIDを取得
                var fileNameInner = dataUri.split("/").reverse().slice(0, -1)[0];  // ファイルパスのファイル名
                var fileNameNonEx = fileNameInner.split(".").slice(0, -1).join("."); // ファイル名から拡張子を取り除いたもの（チェックボックスのID）
                // 添付ファイルリストに格納
                var attachFileData = [fileId, fileNameInner, publicFlg]; // ファイルIDとファイル名と公開フラグのセット
                self.lstImage.push(attachFileData);
                // #12657追加▲

                // 画像ファイルの場合
                if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                    fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                    fileName.indexOf('.gif') !== -1) {
                    var image = new Image();

                    // #12657追加▼
                    // 画像とチェックボックスのセット用divを作成
                    var inDiv = document.createElement('div');
                    inDiv.setAttribute("class", "in-div");
                    inDiv.setAttribute("id", fileId);   // ファイルIDをdivのIDにする

                    // サムネイルにチェックボックスをつける
                    var ckb = document.createElement('input');
                    ckb.setAttribute("type", "checkbox");
                    ckb.setAttribute("id", fileNameNonEx);
                    ckb.setAttribute("class", "chkImage");
                    ckb.setAttribute("autocomplete", "off");    // 自動補完をオフにする
                    // 公開フラグが立っている場合はチェックを付ける
                    if (publicFlg === "1") {
                        ckb.checked = true;
                    }
                    ckb.style.position = "absolute";    // 位置調整
                    ckb.setAttribute("style", "display: none;");
                    // #12657追加▲

                    //JPEGファイルの場合、EXIFデータの取得を実行する
                    if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                        fileName.indexOf('.JPG') !== -1 || fileName.indexOf('.JPEG') !== -1) {
                        var img = null;
                        this.own(on(image, 'load', lang.hitch(this, function (e) {
                            console.log(e);
                            img = e.target;

                            if (exifFlg) {
                                this.getExifData(img, this);
                            }
                        })));
                    }
                    image.src = dataUri;
                    domClass.add(image, 'is-showPreview');
                    // #12657削除▼
                    // domConstruct.place(image, this.preview);
                    // #12657削除▲
                    //メニューの作成
                    this.createMenu(image, dataUri, fileName, fileId, self);

                    // #12657追加▼
                    // 画像とチェックボックスの紐づけ
                    image.setAttribute("for", fileNameNonEx);
                    inDiv.appendChild(ckb);
                    inDiv.appendChild(image);
                    // 画面へ配置
                    domConstruct.place(inDiv, this.preview);

                    // チェックボックスクリック時の処理
                    ckb.onclick = function () {
                        // 画像リストを検索
                        for (var i = 0; i < self.lstImage.length; i++) {
                            var dat = self.lstImage[i];
                            // チェックボックスのID（拡張子外したファイル名）が同一のデータの公開フラグを切り替える
                            // ※このthisはチェックボックスに対してのthisのため、画面全体で使用しているlstImageを指定する場合別途全体を参照できる変数が必要
                            if (this.id === dat[1].split(".").slice(0, -1).join(".")) {
                                dat[2] = dat[2] === "0" ? "1" : "0";
                            }
                        }
                    };
                    // #12657追加▲
                } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                    var excel = new Image();
                    excel.src = 'images/excelicon.png';
                    domClass.add(excel, 'is-showPreview');
                    // #12657変更▼
                    // domConstruct.place(excel, this.preview);
                    // 画像側に合わせて配置変更
                    var inDiv = document.createElement('div');
                    inDiv.setAttribute("class", "in-div");
                    inDiv.setAttribute("id", fileId);
                    inDiv.appendChild(excel);
                    domConstruct.place(inDiv, this.preview);
                    // #12657変更▲
                    //メニューの作成
                    this.createMenu(excel, dataUri, fileName, fileId, self);
                } else if (fileName.indexOf('.pdf') !== -1) {
                    var pdf = new Image();
                    pdf.src = 'images/pdficon.png';
                    domClass.add(pdf, 'is-showPreview');
                    // #12657変更▼
                    // domConstruct.place(pdf, this.preview);
                    // 画像側に合わせて配置変更
                    var inDiv = document.createElement('div');
                    inDiv.setAttribute("class", "in-div");
                    inDiv.setAttribute("id", fileId);
                    inDiv.appendChild(pdf);
                    domConstruct.place(inDiv, this.preview);
                    // #12657変更▲
                    //メニューの作成
                    this.createMenu(pdf, dataUri, fileName, fileId, self);
                } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                    var word = new Image();
                    word.src = 'images/wordicon.png';
                    domClass.add(word, 'is-showPreview');
                    // #12657変更▼
                    // domConstruct.place(word, this.preview);
                    // 画像側に合わせて配置変更
                    var inDiv = document.createElement('div');
                    inDiv.setAttribute("class", "in-div");
                    inDiv.setAttribute("id", fileId);
                    inDiv.appendChild(word);
                    domConstruct.place(inDiv, this.preview);
                    // #12657変更▲
                    //メニューの作成
                    this.createMenu(word, dataUri, fileName, fileId, self);
                } else {
                    var other = new Image();
                    other.src = 'images/othericon.png';
                    domClass.add(other, 'is-showPreview');
                    // #12657変更▼
                    // domConstruct.place(other, this.preview);
                    // 画像側に合わせて配置変更
                    var inDiv = document.createElement('div');
                    inDiv.setAttribute("class", "in-div");
                    inDiv.setAttribute("id", fileId);
                    inDiv.appendChild(other);
                    domConstruct.place(inDiv, this.preview);
                    // #12657変更▲
                    //メニューの作成
                    this.createMenu(other, dataUri, fileName, fileId, self);
                }
            },

            /**
             * JPEGファイルの位置情報を取得する
             */
            getExifData: function (img, self) {
                console.log('getting exif data start');
                exif.getData(img, function () {

                    var latitude = exif.getTag(this, 'GPSLatitude');
                    var longitude = exif.getTag(this, 'GPSLongitude');

                    if (typeof latitude === 'undefined' || typeof longitude === 'undefined') {
                        console.log('GPS data is unavailable.');
                    } else {
                        console.log('GPS data is available.');
                        var f = function (number) {
                            return number[0].numerator + number[1].numerator /
                                (60 * number[1].denominator) + number[2].numerator / (3600 * number[2].denominator);
                        };
                        self.chain.confirm('この画像の位置情報を使用しますか？', function (chain) {
                            // 位置情報を設定する
                            self.pointLat = f(latitude);
                            self.pointLng = f(longitude);
                            // 地図にマークして中心に移動する
                            self.addMark(self.pointLat, self.pointLng, self);
                            self.map.setView([self.pointLat, self.pointLng], 11);
                            //ダイアログを閉じる
                            chain.hide();
                        });
                    }
                });
            },

            /**
             * 添付ファイルのサムネイル上にメニューを作る
             */
            createMenu: function (newNode, uri, fileName, id, self) {
                var menu = new Menu({
                    targetNodeId: newNode
                });
                menu.set('style', { 'border': 'none', 'box-shadow': 'none' });

                //ダウンロード操作用
                var download = null;
                var userAgent = window.navigator.userAgent.toLowerCase();
                if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                    var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                    // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                    // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                    download = domConstruct.create('a', { href: '#' });
                    //クリックでファイル取得処理に入る
                    download.onclick = function () {
                        self.downloadFile(url, fileName);
                    };
                } else {
                    // FF, Chromeの場合、download属性でファイルダウンロード
                    download = domConstruct.create('a', {
                        href: uri,
                        download: fileName
                    });
                }

                // ファイル名とメニューとの境界線をセット
                var contentNode = domConstruct.create('div');
                contentNode.innerHTML = fileName;
                domConstruct.place('<hr color=#b0c4de>', contentNode);
                //メニューをセット
                domConstruct.place(menu.domNode, contentNode);
                var tooltip = new TooltipDialog({
                    content: contentNode
                });
                //
                tooltip.containerNode.onmouseleave = function () {
                    popup.close(tooltip);
                };

                // 画像ファイルの場合のみ'開く'をメニューに追加する
                if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                    fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                    fileName.indexOf('.gif') !== -1) {
                    menu.addChild(new MenuItem({
                        label: '開く',
                        iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
                        onClick: function () {
                            console.log('file open');
                            window.open(uri);
                        }
                    }));
                }

                menu.addChild(new MenuItem({
                    label: 'ダウンロード',
                    iconClass: 'dijitIconSave',
                    onClick: function (e) {
                        console.log('file download');
                        console.log(e);
                        //IE対策
                        if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                            download.onclick();
                        } else {
                            download.click();
                        }
                    }
                }));

                menu.addChild(new MenuItem({
                    label: '削除',
                    iconClass: 'dijitIconDelete',
                    onClick: function () {
                        console.log('file delete');

                        // 該当ファイルを削除
                        for (var i = 0; i < self.attachFileList.length; i++) {
                            // #12657変更▼
                            // if (self.attachFileList[i].chronologyAtcFileId === id) {
                            //     self.attachFileList.splice(i, 1); //id:3の要素を削除
                            // }
                            if (self.attachFileList[i].damageReportAtcFileId === id) {
                                self.attachFileList.splice(i, 1); //id:3の要素を削除
                            }
                            // #12657変更▲
                        }

                        // #12657追加▼
                        // 連携されているチェックボックスのIDを取得
                        var fileNameInner = uri.split("/").reverse().slice(0, -1)[0];  // uriのファイル名
                        var fileNameNonEx = fileNameInner.split(".").slice(0, -1).join("."); // ファイル名から拡張子を取り除いたもの（チェックボックスのID）
                        // チェックボックスの削除（画像以外（チェックボックスなし）の場合は特に何もしない）
                        domConstruct.destroy(fileNameNonEx);
                        // #12657追加▲
                        // サムネイルとメニューを削除
                        domConstruct.destroy(newNode);
                        popup.close(tooltip);
                        // #12657追加▼
                        // セット枠のdivを削除
                        domConstruct.destroy(String(id));   // idが数値なため文字列に変換しないと見つけてくれない
                        // #12657追加▲
                    }
                }));

                menu.startup();
                //メニュー表示処理
                this.own(on(newNode, 'mouseover', lang.hitch(this, function () {
                    popup.open({
                        popup: tooltip,
                        around: newNode,
                        orient: ['below-centered']
                    });
                })));
                //画面破棄時に一緒に破棄する
                this.own(tooltip);
            },

            /**
             * 添付ファイルをダウンロードする。
             */
            downloadFile: function (url, name) {
                // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
                // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
                var xhr = new XMLHttpRequest();
                xhr.open('GET', url, true);
                xhr.responseType = 'arraybuffer';
                xhr.onload = function () {

                    var arrayBuffer = this.response;

                    var blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });

                    // IE10+
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, name);
                    }

                };
                xhr.send();

                return false;

            },

            /**
             * プレビューをクリアする
             **/
            clearPreview: function () {

                var length = this.preview.childNodes.length;
                for (var i = 0; i < length; i++) {
                    domConstruct.destroy(this.preview.childNodes[0]);
                }

                if (this._attachFileList) {
                    this._attachFileList.splice(0, this._attachFileList.length);
                }
                // #12657追加▼
                // 画像リストの中身もリセット
                if (this.lstImage) {
                    this.lstImage.splice(0, this.lstImage.length);
                }
                // #12657追加▲
            },

            /**
             * 報告名を設定する
             */
            setReportName: function (obj) {
                var currentDate = new Date();
                var prefix = locale.format(currentDate, {
                    selector: 'date',
                    formatLength: 'long',
                    locale: 'zh'
                });

                obj.reportName = prefix + '被害';
            },

            /**
             * 人的被害の入力データを返す。
             */
            humanData: function () {
                return ['deadNum', 'unknownNum', 'seriousNum', 'mildNum', 'unknownSympNum'];
            },

            /**
             * 被害種別ごとの項目名リストを返す。
             */
            //住家被害
            homeData: function () {
                return ['fullDestlHm', 'fullDestlHh', 'fullDestlHr',
                    'halfDestlHm', 'halfDestlHh', 'halfDestlHr',
                    'partDestlHm', 'partDestlHh', 'partDestlHr',
                    'fldAbvHm', 'fldAbvHh', 'fldAbvHr',
                    'fldBlwHm', 'fldBlwHh', 'fldBlwHr',
                    'lhalfDestlHm', 'lhalfDestlHh', 'lhalfDestlHr',
                    'unknownHm', 'unknownHh', 'unknownHr',
                    'pubDestl', 'nhomeOtr', 'unknownNhome'];
            },
            // ライフライン
            lifeData: function () {
                return ['elecMax', 'elecCurr', 'watFailMax',
                    'watRedMax', 'telMax', 'telCurr', 'gusMax', 'gusCurr'];
            },
            //文教施設（学校等）
            educData: function () {
                return ['educDamageName', 'eduDmgType'];
            },
            //病院
            hospData: function () {
                return ['hospDamageName', 'hspDmgType'];
            },
            //庁舎等施設
            gbldData: function () {
                return ['gbldDamageName', 'gvmDmgType'];
            },
            //土砂災害
            sediData: function () {
                return ['sdiDmgType', 'sdiHmDmgFlg'];
            },
            //火災
            fireData: function () {
                return ['totalLossHm', 'totalLossHh', 'totalLossHr',
                    'halfLossHm', 'halfLossHh', 'halfLossHr',
                    'partLossHm', 'partLossHh', 'partLossHr',
                    'nhomeLossHm', 'pubLossHm', 'dangerNum'];
            },
            //河川
            rivrData: function () {
                return ['rivrDamageName', 'rivDmgType', 'rivPlntDmgFlg',
                    'rivEscOrdFlg', 'rivHmDmgFlg'];
            },
            //道路
            roadData: function () {
                return ['roadDamageName', 'rodDmgType', 'rodGroupType', 'rodDmgFlg'];
            },
            //農業被害
            agriData: function () {
                return ['paddylow', 'paddyFlood', 'farmFlow', 'farmFlood'];
            },
            //その他被害
            othrData: function () {
                return ['othrDamageName', 'otrDmgType'];
            },
            // 水道被害
            wateData: function () {
                return ['watFailCurr', 'watRedCurr', 'watMudCurr'];
            },
            // 下水道被害
            sewaData: function () {
                return ['roadFlood', 'lostManholeCvrAir', 'lostManholeCvrOverflow',
                    'manholeOverflow', 'pubInletOverflow', 'drainageOverflow'];
            },
            // 雪害被害
            snowData: function () {
                return ['snowRemoveDistrict1', 'regLengthRoadway1', 'completeRoadway1',
                    'regLengthSidewalk1', 'completeSidewalk1',
                    'snowRemoveDistrict2', 'regLengthRoadway2', 'completeRoadway2',
                    'regLengthSidewalk2', 'completeSidewalk2',
                    'snowRemoveDistrict3', 'regLengthRoadway3', 'completeRoadway3',
                    'regLengthSidewalk3', 'completeSidewalk3',
                    'snowRemoveDistrict4', 'regLengthRoadway4', 'completeRoadway4',
                    'regLengthSidewalk4', 'completeSidewalk4',
                    'snowRemoveDistrict5', 'regLengthRoadway5', 'completeRoadway5',
                    'regLengthSidewalk5', 'completeSidewalk5',
                    'drainPumpStationDrv', 'sluiceOpr', 'roadFloodSnow',
                    'clpsSlopeFace', 'gravelFlow',
                    'roadClose', 'riverOverflow', 'clpsBridge',
                    'fldBlwSnow', 'fldAbvSnow',
                    'fullFallStreetTree', 'halfFallStreetTree', 'fullFallRoadsideTree',
                    'halfFallRoadsideTree', 'otrSnow'];
            },

            /**
             * damageNameから種別毎の被害名に変換
             */
            setFormFromJSON: function (obj) {
                if (obj.damageType === '03') {
                    // 文教施設（学校等）
                    obj.educDamageName = obj.damageName;
                } else if (obj.damageType === '04') {
                    // 病院
                    obj.hospDamageName = obj.damageName;
                } else if (obj.damageType === '05') {
                    // 庁舎等施設
                    obj.gbldDamageName = obj.damageName;
                } else if (obj.damageType === '08') {
                    // 河川
                    obj.rivrDamageName = obj.damageName;
                } else if (obj.damageType === '09') {
                    // 道路
                    obj.roadDamageName = obj.damageName;
                } else if (obj.damageType === '11') {
                    // その他被害
                    obj.othrDamageName = obj.damageName;
                }
            },

            /**
             * 種別毎の被害名からdamageNameに変換
             */
            setJSONFromForm: function (obj) {
                if (obj.damageType === '03') {
                    // 文教施設（学校等）
                    obj.damageName = obj.educDamageName;
                } else if (obj.damageType === '04') {
                    // 病院
                    obj.damageName = obj.hospDamageName;
                } else if (obj.damageType === '05') {
                    // 庁舎等施設
                    obj.damageName = obj.gbldDamageName;
                } else if (obj.damageType === '08') {
                    // 河川
                    obj.damageName = obj.rivrDamageName;
                } else if (obj.damageType === '09') {
                    // 道路
                    obj.damageName = obj.roadDamageName;
                } else if (obj.damageType === '11') {
                    // その他被害
                    obj.damageName = obj.othrDamageName;
                }

                delete obj.educDamageName;
                delete obj.hospDamageName;
                delete obj.gbldDamageName;
                delete obj.rivrDamageName;
                delete obj.roadDamageName;
                delete obj.othrDamageName;
            },

            /**
             * 被害の種類のセレクタ一覧を生成
             */
            getDamageTypeList: function () {
                var deferred = new Deferred();
                if (this._damageTypeDispList.length > 0) {
                    return deferred.resolve(this._damageTypeDispList);
                }
                return Loader.wait(Requester.get('api/code/list?type=DAMAGE_TYPE&display=true')
                    .then(lang.hitch(this, function (data) {
                        data.forEach(lang.hitch(this, function (item) {
                            // 配列のキーをitem.keyにすると、ソート順がkeyIDによってしまうので、ソート順をキーとする
                            this._damageTypeDispList[item.key] = item.value;
                        }));
                        return this._damageTypeDispList;
                    }))
                );
            },

        });
});
