/**
 * 防災ポータル情報配信の新規登録・詳細ダイアログ用の基底モジュール。
 * @module app/prefsitesending/_PrefSiteSendingDialogBase
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/on',
    'dojo/request/iframe',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'exif-js/exif',
    'idis/util/FilesUtils',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/ValidationTextBox',
    'dojox/form/Uploader',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/DateTimeInput',
    'app/view/form/MunicipalitySelector',
    'app/prefsitesending/PrefSiteSendingSelectorDialog',
    'app/view/form/OrganizationSelector'
], function(module, declare, lang, domClass, domConstruct, on, iframe,
        Menu, MenuItem, popup, TooltipDialog, exif, FilesUtils, _PageBase, InfoDialog, Loader) {
    return declare(module.id.replace(/\//g, '.'), _PageBase, {
        /**
         * 添付ファイル一覧格納用オブジェクト
         */
        attachFileList: null,

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 入力値の妥当性を検証する。
         */
        validate: function() {
            var formData = this.form.get('value');

            // 市町村
            if (formData.municipalityCd === '') {
                InfoDialog.show('入力チェック', '市/行政区が選択されていません');
                return false;
            }

            // 所轄組織
            if (formData.sendingSection === '') {
                InfoDialog.show('入力チェック', '所轄組織が選択されていません');
                return false;
            }

            // 掲載日時
            if (formData.pubStartTimestamp === null) {
                InfoDialog.show('入力チェック', '掲載日時が入力されていません');
                return false;
            }

            // 掲載期限
            if (formData.pubEndTimestamp === null) {
                InfoDialog.show('入力チェック', '掲載期限が入力されていません');
                return false;
            }

            // 件名
            if (formData.subject === '') {
                InfoDialog.show('入力チェック', '件名が入力されていません');
                return false;
            }

            // 本文
            if (formData.bodyText === '') {
                InfoDialog.show('入力チェック', '本文が入力されていません');
                return false;
            }

            return true;
        },

        /**
         * 添付ファイルをアップロードする。
         */
        loadAttachFile: function() {
            // ファイルが空の場合は処理を中断＆不正なファイルの場合、メッセージ表示して処理を中断
            if (this.attachFile._files.length === 0) {
                return;
            }
            console.log('file change');
            var self = this;

            this.attachFile.set('disabled', false);

            // ファイルがonloadされたときにサーバーに一時保存する
            var promise = iframe.post('/api/prefsitesending/uploadFile', {
               form: this.form.id,
               handleAs: 'json'
            }).then(function(data) {
                console.log(data);
                //uploaderをリセット
                self.attachFile.reset();
                self.attachFileList.push(data);
                self.showPreview(data, true);
            }, function(error) {
                console.log(error);
                //uploaderをリセット
                self.attachFile.reset();
                self.chain.infoError(error);
            });

            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 添付ファイルのプレビューを表示する。
         */
        showPreview: function(data, exifFlg) {
            var dataUri = data.attachFilePath.replace('out/', 'data/');
            var fileName = data.attachFileName;
            var fileId = data.prefSiteSendingAtcFileId;
            var self = this;

            // 画像ファイルの場合
            if (fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                    fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                    fileName.indexOf('.gif') !== -1) {
                var image = new Image();

                //JPEGファイルの場合、EXIFデータの取得を実行する
                if (fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
                        fileName.indexOf('.JPG') !== -1 ||fileName.indexOf('.JPEG') !== -1) {
                    var img = null;
                    this.own(on(image, 'load', lang.hitch(this, function(e) {
                        console.log(e);
                        img = e.target;

                        if(exifFlg) {
                            this.getExifData(img, this);
                        }
                    })));
                }
                image.src = dataUri;
                domClass.add(image, 'is-showPreview');
                domConstruct.place(image, this.preview);
                //メニューの作成
                this.createMenu(image, dataUri, fileName, fileId, self);
            } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                var excel = new Image();
                excel.src = 'images/excelicon.png';
                domClass.add(excel, 'is-showPreview');
                domConstruct.place(excel, this.preview);
                //メニューの作成
                this.createMenu(excel, dataUri, fileName, fileId, self);
            } else if (fileName.indexOf('.pdf') !== -1) {
                var pdf = new Image();
                pdf.src = 'images/pdficon.png';
                domClass.add(pdf, 'is-showPreview');
                domConstruct.place(pdf, this.preview);
                //メニューの作成
                this.createMenu(pdf, dataUri, fileName, fileId, self);
            } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                var word = new Image();
                word.src = 'images/wordicon.png';
                domClass.add(word, 'is-showPreview');
                domConstruct.place(word, this.preview);
                //メニューの作成
                this.createMenu(word, dataUri, fileName, fileId, self);
            } else {
                var other = new Image();
                other.src = 'images/othericon.png';
                domClass.add(other, 'is-showPreview');
                domConstruct.place(other, this.preview);
                //メニューの作成
                this.createMenu(other, dataUri, fileName, fileId, self);
            }
        },

        /**
         * JPEGファイルの位置情報を取得する
         */
        getExifData: function(img, self) {
            console.log('getting exif data start');
            exif.getData(img, function(){
                var latitude = exif.getTag(this, 'GPSLatitude');
                var longitude = exif.getTag(this, 'GPSLongitude');

                if (typeof latitude === 'undefined' || typeof longitude === 'undefined') {
                    console.log('GPS data is unavailable.');
                } else {
                    console.log('GPS data is available.');
                    var f = function(number) {
                        return number[0].numerator + number[1].numerator /
                        (60 * number[1].denominator) + number[2].numerator / (3600 * number[2].denominator);
                    };
                    self.chain.confirm('この画像の位置情報を使用しますか？', function(chain) {
                        // 位置情報を設定する
                        self.pointLat = f(latitude);
                        self.pointLng = f(longitude);
                        // 地図にマークして中心に移動する
                        self.addMark(self.pointLat, self.pointLng, self);
                        self.map.setView([self.pointLat, self.pointLng], 11);
                        //ダイアログを閉じる
                        chain.hide();
                    });
                }
            });
        },

        /**
         * 添付ファイルのサムネイル上にメニューを作る
         */
        createMenu: function(newNode, uri, fileName, id, self) {
            var menu = new Menu({
                targetNodeId: newNode
            });
            menu.set('style', {'border': 'none', 'box-shadow': 'none'});

            //ダウンロード操作用
            var download = null;
            var userAgent = window.navigator.userAgent.toLowerCase();
            if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                download = domConstruct.create('a', {href: '#'});
                //クリックでファイル取得処理に入る
                download.onclick = function() {
                    self.downloadFile(url, fileName);
                };
            } else {
                // FF, Chromeの場合、download属性でファイルダウンロード
                download = domConstruct.create('a', {
                    href: uri,
                    download: fileName
                });
            }

            // ファイル名とメニューとの境界線をセット
            var contentNode = domConstruct.create('div');
            contentNode.innerHTML = fileName;
            domConstruct.place('<hr color=#b0c4de>', contentNode);
            //メニューをセット
            domConstruct.place(menu.domNode, contentNode);
            var tooltip = new TooltipDialog({
                content: contentNode
            });
            //
            tooltip.containerNode.onmouseleave = function() {
                popup.close(tooltip);
            };

            // 画像ファイルの場合のみ'開く'をメニューに追加する
            if (fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                    fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                    fileName.indexOf('.gif') !== -1) {
                menu.addChild(new MenuItem({
                    label: '開く',
                    iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
                    onClick: function() {
                        console.log('file open');
                        window.open(uri);
                    }
                }));
            }

            menu.addChild(new MenuItem({
                label: 'ダウンロード',
                iconClass: 'dijitIconSave',
                onClick: function(e) {
                    console.log('file download');
                    console.log(e);
                    //IE対策
                    if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                        download.onclick();
                    } else {
                        download.click();
                    }
                }
            }));

            menu.addChild(new MenuItem({
                label: '削除',
                iconClass: 'dijitIconDelete',
                onClick: function() {
                    console.log('file delete');

                    // 該当ファイルを削除
                    for (var i=0; i<self.attachFileList.length; i++) {
                        if (self.attachFileList[i].prefSiteSendingAtcFileId === id) {
                            self.attachFileList.splice(i,1); //id:3の要素を削除
                        }
                    }

                    // サムネイルとメニューを削除
                    domConstruct.destroy(newNode);
                    popup.close(tooltip);
                }
            }));

            menu.startup();
            //メニュー表示処理
            this.own(on(newNode, 'mouseover', lang.hitch(this, function() {
                popup.open({
                    popup: tooltip,
                    around: newNode,
                    orient: ['below-centered']
                });
            })));
            //画面破棄時に一緒に破棄する
            this.own(tooltip);
        },

        /**
         * 添付ファイルをダウンロードする。
         */
        downloadFile: function(url, name) {
            // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
            // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function() {

                var arrayBuffer = this.response;

                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, name);
                }

            };
            xhr.send();

            return false;
        },

        /**
         * プレビューをクリアする
         **/
        clearPreview: function() {
            var length = this.preview.childNodes.length;
            for (var i = 0; i < length; i++) {
                domConstruct.destroy(this.preview.childNodes[0]);
            }

            if(this.attachFileList) {
                this.attachFileList.splice(0, this.attachFileList.length);
            }
        }
    });
});
