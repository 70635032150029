/**
 * 災害名表示、変更ダイアログ用モジュール。
 * @module app/view/form/DisasterChanger
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/topic',
    'dojo/text!./templates/ParentDisasterSelector.html',
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/store/IdisRest',
    'idis/view/_IdisWidgetBase',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    './ParentDisasterSelectorDialog'
], function(module, declare, lang, array, topic, template, DisasterInfo, InfoDialog,
  Locator, Router, IdisRest, WidgetBase) {

    /**
     * 災害名表示、変更ダイアログ表示用ウィジェット
     * @class DisasterChanger
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends app/view/Header~Header# */ {

        // テンプレート文字列
        templateString: template,

        // ルートに付与されるCSS
        baseClass: 'parentDisaster-changer',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        // OKボタンが押された際のイベントが追加済みであるか
        _eventFlg: false,

        /**
         * このプロパティーが定義されているとき、
         * {@link module:dijit/form/Form~Form}のget('value')による取得対象となる。
         * @type {string}
         */
        value: '',

        /**
         * コンストラクタ
         */
        constructor: function() {
          // データ格納用オブジェクト
          this.store = new IdisRest({
              idProperty:'disasterId',
              target: '/api/disasters'
          });
        },

        /**
         * DOM生成
         */
        buildRendering: function() {
          this.inherited(arguments);
        },

        /**
         * 災害情報を取得し、画面へセットする
         */
        getDisasterInfo: function(id) {
          // 災害情報を取得
          this.store.get(id).then(lang.hitch(this, function(item) {
          console.log(item);
            this.change({
              disasterId: item.extraDisasterId,
              name: item.extraDisasterName
            });
          }));
        },

        /**
         * 画面生成完了
         */
        startup: function() {
          this.inherited(arguments);
          // このウィジェット消滅時にダイアログも削除
          this.own(this.dialog);
        },

        /**
         * 災害変更ダイアログを表示する
         */
        showDialog: function() {
          // ダイアログを表示
          this.dialog.show();
          // ダイアログ中のページウィジェットを取得
          var page = this.dialog.getChildren()[0];

          // イベントが追加済みの場合は何もしない
          if (this._eventFlg) {
            return;
          }

          // ダイアログ側でOKボタンが押された際のイベント
          page.on('update', lang.hitch(this, function(evt) {

            // イベント追加済み
            this._eventFlg = true;

            // 災害変更
            this.change(evt.data);

            // ダイアログを非表示にする
            this.dialog.hide();
          }));
          // ダイアログの画面を再表示する
          page.refresh();

        },

        /**
         * 災害を変更する
         */
        change: function(data) {
        console.log(data);
            if(data.disasterId !== 0) {
                // 変更された災害名を画面へセット
                this.extraDisasterNameDisplay.innerHTML = data.disasterId + '_' + data.name;
                // 変更された災害IDをセット
                this.extraDisasterId.set('value', data.disasterId);
                this.extraDisasterName.set('value', data.name);
                this.value = { extraDisasterId: data.disasterId,
                          extraDisasterName: data.name};
            } else {
                this.extraDisasterNameDisplay.innerHTML = this.emptyValueLabel;
                this.extraDisasterId.set('value', 0);
                this.extraDisasterName.set('value', '');
                this.value = { extraDisasterId: 0,
                          extraDisasterName: this.emptyValueLabel};
            }
        },

        /**
         * ダイアログに設定された府災害を初期化する
         */
        clear: function() {
          this.extraDisasterNameDisplay.innerHTML = this.emptyValueLabel;
          this.extraDisasterId.set('value', 0);
          this.extraDisasterName.set('value', '');
          this.value = { extraDisasterId: 0,
                    extraDisasterName: this.emptyValueLabel};
        }
    });

});