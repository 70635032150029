/**
 * 避難状況登録画面用モジュール。
 * @module app/view/page/EvacOrderRegisterPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/promise/all',
    'dojo/topic',
    'dojo/text!./templates/EvacOrderRegisterPage.html',
    'dojo/json',
    'app/evacorder/_EvacOrderRegisterPageBase',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'app/config',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/view/Loader',
    'idis/control/Locator',
    // 以下、変数で受けないモジュール
    'dijit/layout/TabContainer',
    'app/shelter/ShelterOpeningGrid',
    'app/evacorder/EvacOrderMunicipalitySelector',
    'app/provide/ProvideEvacOrderRegisterDialog',
    'app/evacorder/RiverAreaMultiSelector',
    './EvacOrderPopulationGrid'
], function(module, array, declare, lang, domStyle, all, topic, template,
    json, _EvacOrderRegisterPageBase, Router, Requester, IdisRest, config, UserInfo, USER_TYPE, Loader, Locator) {

    /**
     * 避難状況登録画面。
     * @class EvacOrderRegisterPage
     * @extends module:app/evacorder/_EvacOrderPageBase~_EvacOrderPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _EvacOrderRegisterPageBase,
    /** @lends module:app/evacorder/EvacOrderRegisterPage~EvacOrderRegisterPage# */ {
        // テンプレート文字列
        templateString: template,

        // 発信元の市町村コード
        _municipalityCdFrom: null,

        /**
         * 各開設フラグ
         */
        _floodShFlg: '0',
        _sedimentDisasterShFlg: '0',
        _stormSurgeShFlg: '0',
        _earthquakeShFlg: '0',
        _tsunamiShFlg: '0',
        _fireShFlg: '0',
        _landsideWaterShFlg: '0',
        _volcaneShFlg: '0',

        isFixPage: false,

        _districtArray: [],

        // 緊急速報メール配信の判断に用いる。
        // 避難情報が自主避難より格上であり、格上げまたは範囲拡大の場合trueとする。
        _isUrgentMailTarget: false,

        // constructor: function() {
        //     // ベースクラスのコンストラクタを呼ぶ
        //     this.inherited(arguments);
        // 
        //     // form用のストアを生成する。
        //     // this.formStore = new IdisRest({
        //     //     idProperty: 'evacOrderId',
        //     //     target: '/api/evacorders'
        //     // });
        //     // 
        //     // // 避難所一覧用のストアを生成する。
        //     // this.shelterStore = new IdisRest({
        //     //     idProperty: 'facilityId',
        //     //     target: '/api/shelters/opening/districts'
        //     // });
        // },

        // HTML上にウィジェットが設置されてから呼ばれる
        startup: function() {
            this.inherited(arguments);
            
            this.setMeshAndRiverTopicSubscriber();
            
            // 地図の初期位置を設定する。
            this.initLatlng();

            this.setEvacOrderTypeSelector(null);

            // ただの新規発令の場合は、「戻る」ボタンを設けない
            domStyle.set(this.cancelButton.domNode, 'display', 'none');

            // 市町村コードを設定する。
            //this.municipalitySelector.set('value', this._municipalityCd);

            // 避難情報ツリーを追加する。
            // this.initMeshAndRiverTree().then(lang.hitch(this, function() {
            //     this.initTree().then(lang.hitch(this, function() {
            //         // 避難情報選択レイヤーを追加する
            //         this.initDistrictLayer();
            // 
            //         // 避難所一覧グリッドを初期化する。
            //         //this.initShelterGrid();
            // 
            //     })).then(lang.hitch(this, function() {
            //         // 準備が整ったら選択中の各地区をチェック状態にする
            //         this._selectDistricts(Locator.getQuery().districtCds);
            //         // 現況状況レイヤーの初期値をONにする。
            //         this._latestLayerState = this.LATEST_LAYER_STATE.ON;
            //     }));
            // }));
            
            var promise = 
                this.initMeshAndRiverTree()
                .then(lang.hitch(this, this.initTree))
                .then(lang.hitch(this, function() {
                    // 避難情報選択レイヤーを追加する
                    return this.initDistrictLayer();
                    
                    // 避難所一覧グリッドを初期化する。
                    //this.initShelterGrid();

                })).then(lang.hitch(this, function() {
                    // 準備が整ったら選択中の各地区をチェック状態にする
                    this._selectDistricts(Locator.getQuery().districtCds);
                    // 現況状況レイヤーの初期値をONにする。
                    this._latestLayerState = this.LATEST_LAYER_STATE.ON;
                }));
            Loader.wait(promise);
            // }));

            // 市町村ユーザーの場合(政令指定都市を除く)概況リンクを不可視
            // if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
            //     UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
            //     domStyle.set(this.overviewLabel, 'display', 'none');
            // }
        },
        // 河川選択の値が変更されたときのイベント
        onChangeRiverArea: function (val) {            
            all(
                Object.keys(this.tree._checkMap)
                    .map(lang.hitch(this, function (itemId) {
                        return this.tree.model.store.get(itemId);
                    }))
            ).then(lang.hitch(this, function (itemList) {
                itemList.forEach(lang.hitch(this, function(item){
                    this.tree.setChecked(item, false);
                }));
            }));
            if(val.value.length === 0) {
                return;
            }
            val.value.forEach(lang.hitch(this, function (item) {
                item[0].districtList.split(',').forEach(lang.hitch(this, function(distCd){
                    this.tree.model.store.get(distCd).then(lang.hitch(this, function(item) {
                        if (item) {
                            this.tree.setChecked(item, true);
                        }
                    }));
                }));
            }));

        },
        // 発令理由を選択したときのイベント
        toggleOtherReason: function (value) {
            if (value === "02") {
                this.riverArea.set('style', { display: '' });
            } else {
                this.riverArea.set('style', { display: 'none' });
            }
            // 基底クラスのイベントを呼び出す
            this._toggleOtherReason(value);
        }


    });
});
