/**
 * 定数として定義
 */
define({
    // 気象警報モード
    WEATHER_LAYER_ID: [],
    // 土砂モード
    SEDIMENT_LAYER_ID: [],
    // 洪水モード
    FLOOD_LAYER_ID: [],
    // 降水情報モード
    RAIN_LAYER_ID: []
});
