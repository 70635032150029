/**
 * ユーザーに紐付く市町村選択用入力パーツ避難情報画面では変更後にtopicを発行する。
 * @module app/evacorder/EvacOrderMunicipalitySelector
 */
define([
    'module',
    'dojo/topic',
    'dojo/_base/declare',
    'idis/model/UserInfo',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'app/view/form/MunicipalityListSelector',
    'idis/consts/USER_TYPE'
], function(module, topic, declare, UserInfo, CacheStoreModel, CacheTreeRest,
        MunicipalityListSelector, USER_TYPE) {
    /**
     * 市町村選択用パーツ（避難情報）
     * @class FacilityTypeSelector
     * @extends module:idis/view/tree/ShelterFacilitySelector~ShelterFacilitySelector
     */
    return declare(module.id.replace(/\//g, '.'), MunicipalityListSelector,
        /** @lends module:app/evacorder/EvacOrderMunicipalitySelector~EvacOrderMunicipalitySelector*/ {

        // 選択ダイアログのタイトル
        // title: '市町村',
        title: '市/行政区',

        // ツリー・モデル
        model: null,

        constructor : function() {
            var param = '';
            var municipalityCds = null;
            if(UserInfo.getUserType() === USER_TYPE.PREFECTURE){
                municipalityCds = ['431001','431010','431028','431036','431044',
                '431052','432024','432032','432041','432059','432067','432083','432105',
                '432113','432121','432130','432148','432156','432164','433489','433641',
                '433675','433683','433691','434035','434043','434230','434248','434256',
                '434281','434329','434337','434418','434426','434434','434442','434477',
                '434680','434825','434841','435015','435058','435066','435074','435104',
                '435112','435121','435139','435147','435317','430005'];
            }else{
                municipalityCds = [UserInfo.getMunicipalityCd()];
            }
            if(municipalityCds) {
                var firstFlg = true;
                for (var i = 0; i < municipalityCds.length; i++) {
                    if(firstFlg) {
                        param += '?';
                        firstFlg = false;
                    } else {
                        param += '&';
                    }
                    param += 'array[]=' + municipalityCds[i];
                }
            }

            console.debug('param = ' + param);

            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/municipalities/evacMunicipality4Tree' + param
                })
            });
        },

        /**
         * ツリーから市町が選択されてonOKをクリックした場合、変更されて入れば変更後の市町コードを渡すトピックを発行する。
         * 避難情報新規登録画面では、このトピックを受けて画面をリフレッシュする。
         * TreeSelectorの処理をオーバーライド
         */
        applySelectedItem : function() {
            // 要素が選択されていない場合は何もしない
            var item = this.tree.selectedItem;
            if (item) {
                var value = this.tree.model.store.getIdentity(item);
                // 市町が変更されていた場合、トピックを発行する。
                if (this.value !== value) {
                    topic.publish(module.id + '::selectMunicipality', value);
                }
                // 要素が選択されている場合、選択されている要素をウィジェットのvalueとして設定
                this.set('value', value);
            }
        },

        reset: function(){
            this.inherited(arguments);
            topic.publish(module.id + '::selectMunicipality');
        }

    });
});
