/**
 * 被害状況 ファイル読み込みダイアログ用モジュール。
 * @module app/damage/DamageReportFileUploadDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/request/iframe',
    'dojo/text!./templates/DamageReportFileUploadDialog.html',
    'dojox/dtl',
    'dojox/dtl/Context',
    'dstore/RequestMemory',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'app/model/DisasterInfo',
    // 以下、変数で受けないモジュール
    'dojox/form/Uploader',
    'dijit/form/Form',
    'idis/view/form/Button',
    'app/view/page/ErrorMessageDialogForXoblos'
], function(module, declare, lang, array, iframe, template, dtl, Context, RequestMemory,
        Router, Requester, DialogChain, InfoDialog, _PageBase, Loader, DisasterInfo) {
    /**
     * 被害状況ファイルアップロードダイアログ
     * @class DamageReportFileUploadDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
                   /** @lends module:app/view/page/DamageReportPage~DamageReportPage# */ {
        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page--safety-dialog',

        _fileId: null,

        infoDialog : null,

        _disasterId: null,

        _typeMap: {
            '01': 'DAMAGE_HIROSHIMA_OVERVIEW_IN',
            '02': 'DAMAGE_01INFO_IN',
            '03': 'DAMAGE_DMACS_IN',
            '04': 'DAMAGE_LIST_IN'
        },

        _tmpCSVFilePath: null,

        constructor: function() {
            // 入力帳票の各種パスを取得する。
            this.storepath = new RequestMemory({
                idProperty: 'reqid',
                target: '/data/report/xoblos-config.json'
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
            // 災害IDを取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
        },

        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.errorMessageDialogForXoblos);
        },

        /**
         * ファイル読み込み実行
         */
        onSubmit: function() {
            if (!this.validate()) {
                return false;
            }

            var selectFlg = this.form.get('value').selectFlg;
            
            // 被害情報一覧
            if(selectFlg === '0'){
	
	            var reqid = this._typeMap[this.type.get('value')];
                var reqParams = {};

	            this.storepath.fetch().then(lang.hitch(this, function(data) {
                    data.some(function(data) {
                        if (reqid === data.reqid) {
                            reqParams = data;
                            return true; //break;
                        }
                    });
                })).then(lang.hitch(this, function() {
                    // ファイルをサーバーにアップロードするAPIのURL
                    var url = '/api/xoblos/uploadReport' +
                            '?reqid=' + reqid +
                            '&disid=' + this._disasterId +
                            '&path=' + reqParams.pathweb;

                    iframe.post(url, {
                        form: this.form.id,
                        handleAs: 'json'
                    }).then(lang.hitch(this, function(ret) {
                        // アップロードしたファイルパスとエラーメッセージjson、
                        // エラー箇所を強調表示したファイルの出力先を指定してxoBlosをコールする。
                        var promise = Requester.post('/api/xoblos/upload',{
                            data: {
                                reqid: reqid,
                                disid: this._disasterId,
                                filename: ret.fileName
                            }
                        }).then(lang.hitch(this, function(data) {
                            // ResultCode=0以外（xoBlosエラー）の場合にはエラーをスローする。
                            // エラー処理の中で、xoBlosエラーダイアログを表示する。
                            if (data.ResultCode !== 0){
                                var err = new Error('xoblos failed');
                                err.data = data;
                                throw err;
                            } else {
                                var regapi = reqParams.regapi;

                                // ResultCodeが0の場合は、帳票に含まれる避難所の一覧がレスポンスのdataに入っている。
                                var items = data.data;
                                array.forEach(items, function(item){
                                    //削る項目
                                    delete item.secNum;
                                    delete item.orgName;
                                    delete item.hldName;
                                    delete item.disasterName;
                                    delete item.nHomeLossHh;
                                    delete item.nHomeLossHr;
                                    //増やす項目
                                    item.disasterId = this._disasterId;
                                }, this);
                                return  Requester.post(regapi, {
                                    data:{
                                        items: items
                                    }
                                });
                            }
//                     })).then(lang.hitch(this, function() {
//                        // 成功した場合は完了ダイアログを表示する。
//                        this.chain.infoComplete(function() {
//                            // 確認ダイアログを閉じる
//                            this.chain.hide();
//                            // 親のダイアログを閉じる。
//                            this.getParent().hide();
//                            Router.moveTo('report');
//                        });
                        })).then(lang.hitch(this, function(data) {
                            // 成功した場合は完了ダイアログを表示する。
                            if(data.nonExistentDamage.length === 0){
                                this.chain.infoComplete(function() {
                                    // 確認ダイアログを閉じる
                                    this.chain.hide();
                                    // 親のダイアログを閉じる。
                                    this.getParent().hide();
                                    this.emit('upload');
                                });
                            } else {
                                var message = '';
                                message += 'ファイル読み込みを完了しました。<br>';
                                message += 'ただし下記は、管理対象の被害情報ではないため、登録・更新しませんでした。<br><br>';
                                message += '<table style="margin-left: auto;margin-right: auto">';
                                message += '<tr><th style="text-align:center"><b>被害情報</b></th><tr>';
                                array.forEach(data.nonExistentDamage, function(nonExistentDistrict){
                                    message += '<tr><td>' + nonExistentDistrict;
                                    message += '</td></tr>';
                                });
                               message += '</table>';

                               var title = '完了';
                               // ダイアログにメッセージを表示
                               this.chain.info(message, title, function(){
                                   // 確認ダイアログを閉じる
                                   this.chain.hide();
                                   // 親のダイアログを閉じる。
                                   this.getParent().hide();
                                   this.emit('upload');
                               });
                           }
                        }), lang.hitch(this, function(error) {
                            if(error.data) {
                                // 失敗の場合、引数にデータがあれば、xoBlos用のエラーダイアログを表示する。
                                // エラーダイアログに結果コードを渡す
                                var errPage = this.errorMessageDialogForXoblos.getChildren()[0];
                                errPage.initErrorMessageDialogForXoblos(
                                    error.data.ResultCode,
                                    reqParams.errpathweb + this._disasterId + '/' + ret.fileName);
                                this.errorMessageDialogForXoblos.show();
                                this.attachFile.reset();
                            } else {
                                // 引数にエラーがなければ、通常のエラーダイアログを表示する。
                                this.chain.infoError(error, function() {
                                     this.chain.hide();
                                     // 親のダイアログを閉じる。
                                     this.getParent().hide();
                                     this.emit('upload');
                                });
                            }
                        }));
                    // ローダーの表示
                    Loader.wait(promise);
                    }));
                }));
            // 消防情報管理システムcsv
            } else if(selectFlg === '1'){
	          
	            console.debug('csv');
//                var sendData = this.form.get('value');

                //ファイルが空の場合は処理を中断
                if(this.attachFile._files.length === 0) {
                    return;
                }
                
                var url = '/api/damageReports/csvRegister';
                var param = '?csvFilePath=' + this._tmpCSVFilePath + '&disid=' + this._disasterId;
                // ファイルをサーバーにPOSTする
                var promise = Requester.post(url + param , {
                    form: this.formId,
                    handleAs: 'json',
                    // 60分間はタイムアウトしないようにする
                    timeout: 60*60*1000
                }).then(lang.hitch(this, function(data) {
                    // 成功した場合は完了ダイアログを表示する。
                    if(data.nonExistentDamage.length === 0){
                        this.chain.infoComplete(function() {
                            // 確認ダイアログを閉じる
                            this.chain.hide();
                            // 親のダイアログを閉じる。
                            this.getParent().hide();
                            this.emit('upload');
                        });
                     } else {
                         var message = '';
                         message += 'ファイル読み込みを完了しました。<br>';
                         message += 'ただし下記は、管理対象の被害情報ではないため、登録しませんでした。<br><br>';
                         message += '<table style="margin-left: auto;margin-right: auto">';
                         message += '<tr><th style="text-align:center"><b>被害情報</b></th><tr>';
                         array.forEach(data.nonExistentDamage, function(nonExistentDistrict){
                             message += '<tr><td>' + nonExistentDistrict;
                             message += '</td></tr>';
                         });
                         message += '</table>';

                         var title = '完了';
                         // ダイアログにメッセージを表示
                         this.chain.info(message, title, function(){
                             // 確認ダイアログを閉じる
                             this.chain.hide();
                             // 親のダイアログを閉じる。
                             this.getParent().hide();
                             this.emit('upload');
                         });
                     }
                }), lang.hitch(this, function(error) {
                    console.log(error);
                    this.attachFile.reset();
                    this.chain.infoError(error);
                }));
                // ローダーの表示
                Loader.wait(promise);
            }
        },

        /**
         * 入力チェック
         */
        validate: function() {
          //ファイルが空の場合は処理を中断
          if(this.attachFile.getFileList().length === 0) {
              InfoDialog.show('入力チェック', 'ファイルが選択されていません');
              return false;
          }
          return true;
        },

        /**
         * セレクトボックスのオプションをセットする。
         * 画面から呼ばれる
         */
        initOptions: function(options) {
            this.type.set('options', options);
        },

        /**
         *ファイル読み込みで呼ばれる
         **/
        loadFile: function() {
             //ファイルが空の場合は処理を中断
             if(this.attachFile._files.length === 0) {
                 return;
             }

             console.debug('report change');

             
             var selectFlg = this.form.get('value').selectFlg;
             var file = this.attachFile._files[0];

             if(selectFlg === '0'){
                 // エクセルファイル以外の場合はエラー
                 if(file.name.indexOf('.xls') === -1 && file.name.indexOf('.xlsx') === -1 &&
                 file.name.indexOf('.xlsm') === -1) {
                     if (!this.infoDialog) {
                         this.infoDialog = new InfoDialog({
                             title : 'エラー',
                             content : 'エクセルファイルを選択してください。'
                         });
                     }
                     this.infoDialog.show();
                     this.infoDialog=null;
                     this.attachFile.reset();
                     return;
                 }
             }else if(selectFlg === '1'){
	             // ファイルの拡張子が.csv以外の場合は処理を中断する
                 console.debug('file change');
                 if(file.name.indexOf('.csv') === -1) {
	                 if (!this.infoDialog) {
                         this.infoDialog = new InfoDialog({
	                         title : 'エラー',
                             content : '.csvの拡張子のファイルを選択して下さい。'
                         });
                     }
                     this.infoDialog.show();
                     this.infoDialog=null;
                     this.attachFile.reset();
                     return;
                 }
             }

             var form = new FormData();
             form.append('report', this.attachFile._files[0]);

            // ファイル名をyyyymmddhhssに設定。このファイル名はimport用APIをｊコールするときにも使う。
            var now = new Date();
            this._fileId = now.getFullYear() + this.padZero(now.getMonth() + 1) +
                this.padZero(now.getDate()) + this.padZero(now.getHours()) +
                this.padZero(now.getMinutes()) + this.padZero(now.getSeconds());
            var promise = '';
            if(selectFlg === '0'){
                // ファイルがonloadされたときにサーバーに一時保存する
                promise = iframe.post('/api/upload/uploadFile?fileId=' +
                    this._fileId , {
                    form: this.form.id,
                    handleAs: 'json'
                });
             }else if(selectFlg === '1'){
                // CSVファイルがonloadされたときにサーバーに一時保存する
                promise = iframe.post('/api/damageReports/uploadCsvFile?fileId=' +
                    this._fileId , {
                    form: this.form.id,
                    handleAs: 'json'
                }).then(lang.hitch(this, function(data) {
	                this._tmpCSVFilePath = data.tmpCSVFilePath;
                    console.debug('_tmpCSVFilePath:' + this._tmpCSVFilePath);
                }), lang.hitch(this, function(error) {
                   console.debug(error);
	            }));
	         }
            //ローダーの表示
            Loader.wait(promise);
        },

        // 日付をゼロパディング
        padZero : function(num){
            var result;
            if (num < 10) {
                result = '0' + num;
            } else {
                result = '' + num;
            }
            return result;
        }
    });
});
