/**
 * 災害選択用入力パーツ
 * @module app/disaster/view/DisasterAdminIntegrate
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/topic',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/store/IdisRest',
    'idis/view/Loader',
    './DisasterTreeSelector',
    './DisasterDialogChain',
    'app/model/DisasterInfo'
], function(module, declare, lang, topic, CacheStoreModel, CacheTreeRest, IdisRest, Loader,
            DisasterTreeSelector, DisasterDialogChain, DisasterInfo) {
    /**
     * 部局・課選択用パーツ。
     * @class DisasterAdminIntegrate
     * @extends module:app/disaster/view/DisasterTreeSelector~DisasterTreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), DisasterTreeSelector,
        /** @lends module:app/disaster/view/DisasterAdminIntegrate~DisasterAdminIntegrate# */ {
        // 選択ダイアログのタイトル
        title: '災害選択',

        // ツリー・モデル
        model: null,

        // 災害ID
        _disasterId: null,

        // 災害名管理の一覧情報
        _grid: null,

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        constructor: function() {
            // 設置されるたびにモデルを新規生成する
            this._initModel();

            // 連鎖ダイアログを登録
            this.chain = DisasterDialogChain.get(this);
        },

        _initModel: function(){
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/disasters/disasters4Tree'
                })
            });
            return this.model;
        },

        // ダイアログを表示する度にmodelを呼び出すことで施設種別テーブルの変更点をツリーに反映
        showDialog: function() {
            // 災害名一覧を取得
            this._grid = this.detailPage._grid;

            // 災害IDを取得
            this._disasterId = this.detailPage._disasterId;
            this.updateTree();
            return this.inherited(arguments);
        },

        updateTree: function() {
            if(this.tree){
                this.treeParentNode = this.tree.domNode.parentNode; 
                this.tree.destroyRecursive();
                this.treeParentNode.appendChild(this.treeNode);
                delete this.tree;
                delete this.model;
                this.model = this._initModel();
                return this.model;
            }
        },

        // テンプレートHTML内内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
        // 更新ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
        onSubmit: function() {
            try {
                var dialog = this.dialog;
                var item = this.tree.selectedItem;
                var grid = this._grid;

                if (item) {
                    // 災害名が選択されていた場合
                    // 統合する災害名のIDを取得する
                    var mergedDisasterId = this.tree.model.store.getIdentity(item);

                    // データ格納用オブジェクト
                    this.store = new IdisRest({
                        idProperty: 'disasterId',
                        target: '/api/disasters/integrate/'
                    });
                    if (this._disasterId.toString() !== mergedDisasterId &&
                        DisasterInfo.getDisasterId() !== mergedDisasterId) {
                        // 確認ダイアログを表示
                        this.chain.confirmIntegrate(function(chain) {

                            var sendData = dialog.get('value');
                            sendData.disasterId = Number(mergedDisasterId);
                            sendData.targetDisasterId = this._disasterId;
                            sendData.disasterName = 'dummy';
                            sendData.disasterType = 'dummy';
                            sendData.reportFlg = false;
                            sendData.version = this.detailPage.version.displayedValue;

                            var promise = this.store.put(sendData);

                            Loader.wait(promise).then(function() {
                                chain.infoComplete();

                                // 災害名一覧をリロードする
                                grid.refresh();

                                // 登録フォーム・ダイアログを閉じる
                                dialog.hide();
                            }, function(err) {
                                // 失敗時
                                chain.infoError(err);
                            });
                        });
                    } else if (DisasterInfo.getDisasterId() === mergedDisasterId){
                        // 情報ダイアログを表示
                        this.chain.info('現在選択中の災害名が選択されています。他の災害名を選択してください。', function(chain) {
                            // 情報ダイアログでOKを押した場合
                            chain.hide();
                        });
                    } else {
                        // 情報ダイアログを表示
                        this.chain.info('同じ災害名が選択されています。他の災害名を選択してください。', function(chain) {
                            // 情報ダイアログでOKを押した場合
                            chain.hide();
                        });
                    }

                } else {
                    // 災害名が選択されていない場合
                    // 情報ダイアログを表示
                    this.chain.info('災害名を選択してください。', function(chain) {
                        // 情報ダイアログでOKを押した場合
                        chain.hide();
                    });
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            // （ダイアログは初期化時にbody直下へ移動するため、明示的に指定しないと消えずに残ってしまう）
            this.own(this.dialog);
        }

    });
});
