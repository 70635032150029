/**
 * <避難所情報概況画面>
 *
 * @module app/shelter/ShelterOpeningGrid.js
 */
define(['module', // モジュールのパスを返す
    'dojo/_base/array',
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/util/DateUtils',
    'dojo/dom',
    'dojo/dom-class',
    'dijit/registry',
    'dojox/lang/functional/object',
    'idis/view/grid/IdisGrid',
    'dstore/Memory',
    'dijit/form/CheckBox',
    'dgrid/Selection',
    'dgrid/Selector',
    'dgrid/Keyboard',
    'idis/view/grid/helper',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer'
], function(module, array, declare, lang, locale, DateUtils, dom, domClass, registry, df,
     IdisGrid, Memory, CheckBox, Selection, Selector, Keyboard, helper) {
    /**
     * <クラスの説明>
     *
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'),[IdisGrid, Selection, Selector, Keyboard],
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */
        {

        selectionMode: 'multiple',
        allowSelectAll: false,
        allowTextSelection: false,
        allIdList: [],

        columns: [
            // チェックボックス
            {field: 'checkbox', label: '',
            	selector: 'checkbox'
            },
            // {field: 'municipalityName', label: '市町村', sortable: false},
            // {field: 'municipality', label: '市/行政区', sortable: false,
            //     formatter: lang.hitch(this, function(item){
            //         var municipalityName = [];
            //         array.forEach(item,function(municipality) {
            //             municipalityName.push(municipality.municipalityName);
            //         });
            //         return municipalityName.join('<br>');
            //     })
            // },
            {field: 'updTimestamp', label: '日時', sortable: false,
                formatter: lang.hitch(this, function(item){
                    if(item) {
                        return DateUtils.format(item);
                    }
                    return '';
                })
            },
            {field: 'issueReasonType', label: '発令理由', sortable: false,
                formatter: lang.hitch(this, function(item){
                    switch(item){
                        case '01':
                            return '土砂';
                        case '02':
                            return '洪水';
                        case '03':
                            return '地震';
                        case '04':
                            return '津波';
                        case '05':
                            return '高潮';
                        case '06':
                            return '火災';
                        case '07':
                            return '暴風';
                        // case '08':
                        //     return '火山';
                        case '09':
                            return '国民保護';
                        case '00':
                            return 'その他';
                    }
                })
            },
            helper.column('evacOrderType', '発令内容', {
                classMap: {'11':'is-low', '13':'is-middle', '14':'is-high'},
                formatMap: {'11':'高齢者等避難', '13':'避難指示', '14':'緊急安全確保'},
                sortable: false
            }),
            {field: 'recommendReason', label: '基準超過情報', sortable: false,
                formatter: lang.hitch(this, function(item){
                    //var reasonList = item.split(',');
                    var string = '<ul style="padding-left: 20px;text-align: left;">';
                    array.forEach(item, function(singleReason){
                        var msg = singleReason.message;
                        //if(singleReason.recommendFlg === '1'){
                        //    string += '<span style="color:red"><li>' + msg + '</li></span>'; 
                        //} else {
                            string += '<li>' + msg + '</li>';
                        //}
                    });
                    string += '</ul>';
                    return string;
                })
            },
            helper.column('status', '現況',{
                formatMap:{
                    '': '',
                    '0': '一部超過中',
                    '1': '超過中',
                    '2': '撤回済',
                    '3': '発令済',
                    '9': '判定対象外'
                },
                sortable: false
            })
        ],

        renderRow: function(item){
            var div = this.inherited(arguments);

            // 非活性(発令済・撤回・マスタ)のデータは、列を選択不能にし、「選択不能」クラスを付与
            if (item.status === '2' || item.status === '3' ||  item.status === '9') {
                domClass.add(div, 'row-inactive');
                var inputtags = div.getElementsByTagName('input');
                for(var j=0;j<inputtags.length;j++){
                    inputtags[j].disabled = true;
                }
            }
            // アクティブなデータに関しては、避難区分に応じたクラスを付与
            if (item.evacOrderType === '14') {
                domClass.add(div, 'row-active-high');
            } else if (item.evacOrderType === '13'){
                domClass.add(div, 'row-active-middle');
            } else if (item.evacOrderType === '11'){
                domClass.add(div, 'row-active-low');
            }

            // 各列のオブジェクトに、避難種別・発令理由を追加
            div.evacRecommendType = {
                status: item.status,
                evacOrderType: item.evacOrderType,
                issueReasonType: item.issueReasonType,
                municipalityCd: item.municipalityCd,
                districtCdList: item.districtList
            };
            this.allIdList.push(item.evacRecommendId);

            return div;
        },

        // SelectorのallowSelectAllオプションがエラーとなるので、
        // 仮の値としてメモリーストアをおく
        constructor: function() {
            this.collection = new Memory();
        },

        /**
         * 選択した避難レコメンドのID一覧を返す。
         * @returns {string[]} 選択した避難レコメンドのID一覧
         */
        getSelectedIdList: function() {
            // 値がtrueのものに絞り込む（一度選択したものはfalseが入る）
            var selected = df.filterIn(this.selection, function(v) {return v;});
            // 残ったキー一覧を返す
            return df.keys(selected);
        }
    });
});
