/**
 * 地震関連のユーティリティー群。
 * @module app/earthquake/earthquake/EqUtils
 */
define([
    'dojo/_base/lang'
], function(lang) {
    /**
     * 6+といった震度文字列を受け取り、6強といった日本語表記にして返す。
     * @function toJpIntensity
     * @value {string} 震度文字列
     * @returns {string} 日本語震度文字列
     */
    function _toJpIntensity(value) {
        // return lang.isString(value) ? value.replace(/-/, '弱').replace(/\+/, '強') : value;
        // ソート用に震度(+)強は0.9 / (-)弱は0.4の固定値に変換してある
        return lang.isString(value) ? value.replace(/.4/, '弱').replace(/.9/, '強') : value;
    }

    return {
        toJpIntensity: _toJpIntensity
    };
});

