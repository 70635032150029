/**
 * クロノロジ一覧画面用モジュール。
 * @module app/chronology/ChronologyListPage
 */
define([
    'module',
    'dojo',
    'dojo/dom-class',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/_base/array',
    'dojo/date/locale',
    'idis/control/Router',
    'dojo/text!./templates/ChronologyListPage.html',
    'dojo/topic',
    'dojo/dom',
    'dojo/when',
    'dojo/dom-style',
    'dojo/dom-construct',
    'dojo/on',
    'dijit/TooltipDialog',
    'dijit/popup',
    'dijit/registry',
    'dijit/Dialog',
    'dstore/Memory',
    'dstore/Rest',
    'dojox/lang/functional/array',
    'idis/service/Requester',
    'idis/consts/ACL',
    'idis/consts/USER_TYPE',
    'idis/model/UserInfo',
    'app/model/DisasterInfo',
    'idis/store/IdisRest',
    'idis/view/dialog/InfoDialog',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/view/form/AclButton',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/util/FilesUtils',
    'idis/service/iframe',
    'app/model/Municipality',
    './_ChronologyPageBase',
    '../config',
    './consts/ChronologyTypeName',
    './consts/ChronologyType',
    './ChronologyStore',
    'idis/util/storage/SessionStorage',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'idis/view/form/WordCountTextarea',
    'dijit/layout/BorderContainer',
    'dijit/_editor/plugins/FullScreen',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
//    'app/view/form/CustomizableMunicipalitySelector',
//    'app/view/form/ShareOrganizationMultiSelector',
    'app/view/form/MunicOrganizationSelector',
    './ChronologyListGrid',
    './ChronologyFormDialog',
    './ChronologySearchPanel',
    '../common/view/StatementDialog',
    './ChronologyActionRegisterDialog'
], function(module, dojo, domClass, declare, lang, json, array, locale, Router, template, topic,
    dom, when, domStyle, domConstruct, on, TooltipDialog, popup, registry, Dialog, Memory, Rest,
    df, Requester,
    ACL, USER_TYPE, UserInfo, DisasterInfo, IdisRest, InfoDialog, IdisGrid, helper,
    AclButton, DialogChain, Loader, FilesUtils, iframe, Municipality, _ChronologyPageBase, config,
    ChronologyTypeName, ChronologyType, ChronologyStore, SessionStorage) {

    /**
    * 検索条件格納用オブジェクト
    * @type {Object}
    */
    var chronologyFilterStore = {};

    /**
     * クロノロジ一覧画面
     * @class ChronologyListPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ChronologyPageBase,
    /** @lends module:app/chronology/ChronologyListPage~ChronologyListPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chronology',

        /**
        * データ格納用オブジェクト
        * @type {module:dstore/Store}
        */
        store: null,

        itemCartStore : null,

        attachFileList: [],
        actionAttachFileList: [],

        // 表示モード
        resultLimitFlg: 0,

        chronologyId: null,

        chronologyIds: [],

        chronologyIdsUnchecked: [],

        chronologyTypes: [],

        // damageInfoCategory: [],

        reqInfoCategory: [],

        prefectureInfoCategory: [],

        checkItems: [],

        isParentChecked: false,

        isParentUnChecked: false,

        fileApi: '/api/damageReports/uploadFile',

        /**
         * 災害ID
         * @private
         */
        _disasterId: null,

        /**
         * @type {Object}
         */
        timer: null,

        AUTO_SCROLL_UNIT: 10, // 自動スクロールの単位（px）

        AUTO_SCROLL_INTERVAL: 1, // 自動スクロールのインターバル（秒）

        DATE_TIME_IMPUT: 'idis/view/form/DateTimeInput',

        IME_KEY_CODE: 229, // IME確定時のENTERまたはbackspaceを押下した場合のkeyCode
        ENTER_KEY_CODE: 13, // ENTERを押下した場合のkeyCode
        ENTER_CODE: 'Enter', // ENTERを押下した場合のcode値
        BACK_SPACE_KEY: 8, // backspaceを押下した場合のkeyCode
        BACK_SPACE_CODE: 'Backspace', // backspaceを押下した場合のkeyCode


        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'chronologyId',
                target: '/api/chronologies',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);

            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定させていません。');
            }

            console.debug('現在のログインユーザID：' + UserInfo.getId());
            console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());

            this.getSession();
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.formDialog);
            this.own(this.chronologyListGrid);

            // FIXME: 本当は、ツリー部分のフィルターも覚えておいて、それに応じてツリーのチェック状態をセットし直す方が理想。
            this.resetTreeFilter();

            // domを破棄した時に一緒に連れて行く
            this.own(
                topic.subscribe('app/chronology/chronologyListGrid::open', lang.hitch(this, function(payload) {
                    this.onOpenHistoryClick(payload);
                }))
            );
            this.own(topic.subscribe(this.DATE_TIME_IMPUT + '::currentDateTime', lang.hitch(this, function() {
                this.onSubmit();
            })));
            this.own(topic.subscribe(this.DATE_TIME_IMPUT + '::currentDate', lang.hitch(this, function() {
                this.onSubmit();
            })));
            this.own(topic.subscribe(this.DATE_TIME_IMPUT + '::currentTime', lang.hitch(this, function() {
                    this.onSubmit();
            })));
            // 検索条件の更新
            this.own(topic.subscribe('app/chronology/ChronologySearchPanel::update',
                lang.hitch(this, function(payload){
                    this._municipalityCd = payload.municipalityCd;
                    this._organizationCd = payload.organizationCd;
                    this.chronologyTypes = payload.chronologyTypes || this.chronologyTypes;
                    // this.damageInfoCategory = payload.damageInfoCategory || this.damageInfoCategory;
                    this.prefectureInfoCategory = payload.prefectureInfoCategory || this.prefectureInfoCategory;
                    this.reqInfoCategory = payload.reqInfoCategory || this.reqInfoCategory;
                    this.checkItems = payload.checkItems || this.checkItems;
                    this.setFilterStore();
                    this.updateGridQuery();
                   // this.setSummaryNum();
            })));
            // 表示順の更新
            this.own(topic.subscribe('app/chronology/ChronologySearchPanel::displayOrder',
                lang.hitch(this, function(idUpdTimestamp){
                    if(idUpdTimestamp){
                        // 表示順：更新日時
                        this.chronologyListGrid.styleColumn('registerTimestamp', 'display:none;');
                        this.chronologyListGrid.styleColumn('updTimestamp', 'display:table-cell');
                        this.chronologyListGrid.set('sort', 'updTimestamp', false);
                    } else {
                        // 表示順：発信日時
                        this.chronologyListGrid.styleColumn('registerTimestamp', 'display:table-cell');
                        this.chronologyListGrid.styleColumn('updTimestamp', 'display:none;');
                        this.chronologyListGrid.set('sort', 'registerTimestamp', false);
                    }
            })));
            // 検索欄の開閉
            this.own(topic.subscribe('app/chronology/ChronologySearchPanel::openCloseSelectInfo',
                lang.hitch(this, function(isOpen){
                    if(isOpen){
                        domStyle.set(this.infoType.domNode, 'width', '220px');
                        domStyle.set(this.infoType.domNode, 'padding', '8px');
                        this.borderContainer.resize();
                    } else {
                        domStyle.set(this.infoType.domNode, 'width', '');
                        domStyle.set(this.infoType.domNode, 'padding', '0');
                        this.borderContainer.resize();
                    }
            })));
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            // ユーザに応じて市町セレクタを制御
/*            switch (UserInfo.getUserType()) {
                case USER_TYPE.MUNICIPALITY:
                    this.damageMunicipalityCd.set('value', UserInfo.getMunicipalityCd());
                    this.damageMunicipalityCd.set('noAllButton', true);
                    this.registerMunicipalityCd.set('value', UserInfo.getMunicipalityCd());
                    this.registerMunicipalityCd.set('noAllButton', true);
                    break;
                case USER_TYPE.REGION:
                    // 登録のセレクタについてはデフォルトをセットしない(そのため組織セレクタは非表示)
                    domStyle.set(this.registerChronologyShares.domNode, 'display', 'none');
                    domStyle.set(this.registerChronologySharesAlert, 'display', '');
                    break;
                case USER_TYPE.PREFECTURE:
                    //this.registerMunicipalityCd.set('value', UserInfo.getMunicipalityCd());
                    break;
                default:
                    // 関連機関の場合、デフォルトを設定しない。(組織セレクタを非表示)
                    domStyle.set(this.registerChronologyShares.domNode, 'display', 'none');
                    domStyle.set(this.registerChronologySharesAlert, 'display', '');
                    break;
            }*/

            // グリッドを初期化する
            this.initGrid();
            // 画面自動更新用のタイマーを設定(1分毎)
            this.timer = setInterval(lang.hitch(this, function() {
                this.updateGridQuery();
            }), 1 * 60 * 1000);
            //this.controlDestination();
            this.attachFileList = [];
            this.actionAttachFileList = [];

            // チェックボックスを非表示
            this.chronologyListGrid.styleColumn('checkbox', 'display: none;');
            this.fullScreenArea.resize();

            // ファイル選択ボタンを非表示
            var attachButton = document.getElementsByClassName('icon-clip')[1].childNodes;
            // inputエリアを小さくする
            attachButton[0].style.width = '30px';
            // ボタン
            attachButton[1].style.display = 'none';

            //帳票フォームの初期化
            // this.innerFormDialog.initDialog();

            // 初期状態を「更新日時」にする
            //this.searchPanel.updDisplayOrder();

            // 対応内容登録ダイアログ用
            this.initHldContentRegisterPage();

            // domStyle.set(this.fullScreenNavigator.domNode, 'display', 'none');

            this.borderContainer.resize();
            this.full.resize();

            // 画面初期表示時にうまく描画されない問題の対処
            setTimeout(lang.hitch(this, function(){
                this.updateGridQuery();
            }), 800);

           // this.setSummaryNum();
        },

       /* setSummaryNum: function(){
            // 市町村ユーザーの場合：自分の市町村選択時にのみ集計を表示する
            // 振興局、県ユーザーの場合：岡山県の選択時にのみ集計を表示する
            // その他の場合：集計を表示しない
            if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
                this._municipalityCd === UserInfo.getMunicipalityCd()){
                domStyle.set(this.summaryPanel, 'display', '');
                Loader.wait(this.getSummaryNum());
            } else if(UserInfo.getUserType() === USER_TYPE.PREFECTURE ||
                UserInfo.getUserType() === USER_TYPE.REGION &&
                this._municipalityCd === config.municInfo.prefMunicCd){
                domStyle.set(this.summaryPanel, 'display', '');
                Loader.wait(this.getSummaryNum());
            } else {
                domStyle.set(this.summaryPanel, 'display', 'none');
                return;
            }
        },

        getSummaryNum: function(){
            return Requester.get('/api/chronologies/summary?disasterId=' + this._disasterId +
                '&municipalityCd=' + this._municipalityCd , {
                preventCache: false
            }).then(lang.hitch(this,function(obj) {
                this.onDemandNum.innerHTML = obj.onDemandNum;
                this.notYetNum.innerHTML = obj.notYetNum;
                this.onGoingNum.innerHTML = obj.onGoingNum;
                this.completedNum.innerHTML = obj.completedNum;
            }), lang.hitch(this, function(err){
                console.error(err);
                // エラーの場合は非表示
                domStyle.set(this.summaryPanel, 'display', 'none');
            }));
        },*/

        /**
         * 登録フォームの市町村コードを再セットする。
         */
        /*onRegisterMunicipalityChange: function(){
        	console.debug('市町村が変更されました');
        	var registerMunicipalityCd = this.registerMunicipalityCd.get('value');

            // 市町未選択の場合、共有先組織を非表示にして終了
            if(!registerMunicipalityCd){
                this.registerChronologyShares.set('value', []);
                domStyle.set(this.registerChronologySharesAlert, 'display', '');
                domStyle.set(this.registerChronologyShares.domNode, 'display', 'none');
                return false;
            }

            //共有先の入力情報を初期化の上、ツリーを再構築
            this.registerChronologyShares.set('value', []);
            this.registerChronologyShares.onMunicipalityChange(registerMunicipalityCd);
            //共有先セレクタを表示
            domStyle.set(this.registerChronologySharesAlert, 'display', 'none');
            domStyle.set(this.registerChronologyShares.domNode, 'display', '');

        },*/

        showTextPreview: function(data, preview, self, line, isAction) {
            var uri = data.attachFilePath.replace(/^out/, 'data');
            var textModule = domConstruct.create('span');
            var infoIcon = '<img src="/images/draw/icon/050.png" style="width:12px"> ';
            textModule.innerHTML = infoIcon + data.attachFileName + ' ';
            if (line){
                textModule.style = 'display: block;';
            }
            var fileName = data.attachFileName;
            var fileId = data.chronologyAtcFileId;
            domClass.add(textModule, 'is-showPreview');
            domConstruct.place(textModule, preview);
            self.createMenu(textModule, uri, fileName, fileId, self, true, true, isAction);
        },

        /**
         * 添付ファイルをアップロードする。
         */
        loadActionAttachFile: function() {
            //ファイルが空の場合は処理を中断
            if(this.attachFile._files.length === 0) {
                return;
            }
            console.log('file change');
            var self = this;
            this.attachFile.set('disabled', false);
            // ファイルがonloadされたときにサーバーに一時保存する
            var promise = iframe.post('/api/chronologies/uploadFile', {
                form: this.actionForm.id,
                handleAs: 'json'
            }).then(function(data) {
                //uploaderをリセット
                self.attachFile.reset();
                self.actionAttachFileList.push(data);
                self.showTextPreview(data, self.previewText, self, true, true);
                // inputエリアを小さくする
                var attachButton = document.getElementsByName('attachFile')[0];
                attachButton.style.width = '25px';
            }, function(error) {
                console.log(error);
                //uploaderをリセット
                self.attachFile.reset();
                self.chain.infoError(error);
            });
            //ローダーの表示
            Loader.wait(promise);
            // ファイル選択ボタンを非表示
        },

        /**
         * 添付ファイルをアップロードする。
         */
        loadBottomAttachFile: function() {
            //ファイルが空の場合は処理を中断
            if(this.bottomAttachFile._files.length === 0) {
                return;
            }
            console.log('file change');
            var self = this;
            this.bottomAttachFile.set('disabled', false);

            // ファイルがonloadされたときにサーバーに一時保存する
            var promise = iframe.post(this.fileApi, {
                form: this.fileForm.id,
                handleAs: 'json'
            }).then(function(data) {
                //uploaderをリセット
                self.bottomAttachFile.reset();
                self.attachFileList.push(data);
                self.showTextPreview(data, self.preview, self, false, false);
            }, function(error) {
                console.log(error);
                //uploaderをリセット
                self.bottomAttachFile.reset();
                self.chain.infoError(error);
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * 配信カテゴリ詳細の切り替え制御をする。
         */
        controlDestination: function() {
            this.chronologyType.on('change', lang.hitch(this, function(e){
                if(e === ChronologyType.DAMAGE.id) {
                    domStyle.set(this.damageMunicipalityCdFrame,'display','inline-block');
                    domStyle.set(this.registerMunicipalityCdFrame,'display','none');
                    domStyle.set(this.registerChronologySharesFrame,'display','none');
                    this.fileApi = '/api/damageReports/uploadFile';
                }else{
                    domStyle.set(this.damageMunicipalityCdFrame,'display','none');
                    domStyle.set(this.registerMunicipalityCdFrame,'display','inline-block');
                    domStyle.set(this.registerChronologySharesFrame,'display','inline-block');
                    this.fileApi = '/api/chronologies/uploadFile';
                }
            }));
        },

        onCloseSideBoxClick: function(){
            dojo.byId('infoType').style.display = 'none';
            // domStyle.set(this.openSideButton, 'display', 'block');
            this.borderContainer.resize();
        },

        onOpenSideBoxClick: function(){
            dojo.byId('infoType').style.display = 'block';
            // domStyle.set(this.openSideButton, 'display', 'none');
            this.borderContainer.resize();
        },

        onCloseHistoryClick: function(){
            dojo.byId('historyDetail').style.display = 'none';
            dojo.byId('actionTop').style.display = 'none';
            dojo.byId('actionCenter').style.display = 'none';
            dojo.byId('actionBottom').style.display = 'none';
            this.borderContainer.resize();
        },

        // 対応履歴を追加
        onOpenHistoryClick: function(item){
            var html = '';
            this.chronologyId = item.chronologyId;
            array.forEach(item.chronologyActions, function(action){
                if(action.crtTimestamp){
                    html+= '<div>' + locale.format(new Date(action.crtTimestamp)) + '</div>';
                }
                html+= '<div>' + action.content + '（' + action.organizationName + '）</div>';
                array.forEach(action.attachFiles, function(file){
                    var uri = file.attachFilePath.replace(/^out/, '/data');
                    var previewUri = this.getPreviewUri(uri);
                    html+= '<img src="' + previewUri + '" height="50">';
                }, this);
                html+= '<hr style="margin-bottom:10px">';
            }, this);
            this.actionHistory.innerHTML = html;
            dojo.byId('historyDetail').style.display = 'block';
            dojo.byId('actionTop').style.display = 'block';
            dojo.byId('actionCenter').style.display = 'block';
            dojo.byId('actionBottom').style.display = 'block';
            this.actionHeader.innerHTML = '管理番号：' + item.chronologyId + '  ' + ChronologyTypeName[item.chronologyType];
            var attachButton = document.getElementsByClassName('icon-clip')[0].childNodes;
            // inputエリア
            attachButton[0].style.width = '30px';
            // ボタン
            attachButton[1].style.display = 'none';
            this.borderContainer.resize();
        },

        // 全て表示する
        allDisp: function(){
            console.log('全表示するallDisp');
            this.resultLimitFlg = 0;
            domStyle.set(this.displayedPart, 'display', 'none');
            this.borderContainer.resize();
            this.updateGridQuery();
        },

        // 限定する
        restrictDisp: function(){
            // [すべての情報を表示中]からやってきたときだけ、キャンセルボタンをつける
            if(this.resultLimitFlg === 1){
                domStyle.set(this.cancelDispButton.domNode, 'display', 'none');
            }else{
                domStyle.set(this.cancelDispButton.domNode, 'display', '');
            }
            this.resultLimitFlg = 0;
            domStyle.set(this.displayedPart, 'display', 'none');
            domStyle.set(this.selectingDisplay, 'display', 'table-cell');
            this.updateGridQuery();
            // チェックボックス列を表示
            this.chronologyListGrid.styleColumn('checkbox', 'display:table-cell;');
            this.fullScreenArea.resize();
            this.borderContainer.resize();
        },

        // 表示限定モードをキャンセルする
        cancelDisp: function(){
            this.resultLimitFlg = 0;
            domStyle.set(this.displayedPart, 'display', 'none');
            domStyle.set(this.selectingDisplay, 'display', 'none');
            this.updateGridQuery();
            // チェックボックス列を非表示
            this.chronologyListGrid.styleColumn('checkbox', 'display:none;');
            this.fullScreenArea.resize();
            this.borderContainer.resize();
        },

        // 確定する
        decideDisp: function(){
            // チェックボックスを全て取得
            var checkboxParents = document.getElementsByClassName('checkboxParent');
            this.chronologyIds = [];
            this.chronologyIdsUnchecked = [];

            array.forEach(checkboxParents, function(parent){
                var chronologyId = parent.children[0].innerHTML;
                var checked = parent.children[1].innerHTML.split('aria-checked="')[1][0];

                // チェックされているかどうか
                if(checked ==='c' || checked ==='t'){
                    this.chronologyIds.push(Number(chronologyId));
                }else{
                    this.chronologyIdsUnchecked.push(Number(chronologyId));
                }
            },this);

            // 選択されていない場合はエラーダイアログを表示
            if (!this.chronologyIds.length) {
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : '選択されていません',
                    onOK : lang.hitch(this, function() {
                        this.infoDialog.hide();
                    })
                });
                this.infoDialog.show();
                return false;
            }else{
                this.bulkUpdate();
            }
        },

        /**
         * チェックボックス情報操作
         */
        bulkUpdate: function() {
            var sendData = {
                disasterId : this._disasterId,
                chronologyIds : this.chronologyIds,
                chronologyIdsUnchecked : this.chronologyIdsUnchecked
            };
            var jsonStr = json.stringify(sendData);
            var promise = Requester.post('/api/chronologies/bulkUpdate', {
                data: jsonStr,
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(lang.hitch(this, function() {
                console.debug('success register Chronology');
                // 画面を更新
                // location.reload();
                domStyle.set(this.selectingDisplay, 'display', 'none');
                domStyle.set(this.displayedPart, 'display', 'table-cell');
                this.chronologyListGrid.styleColumn('checkbox', 'display:none;');
                this.resultLimitFlg = 1;
                this.updateGridQuery();
                this.fullScreenArea.resize();

            }), lang.hitch(this,function(error) {
                console.log('error register Chronology');
                console.log(error);
                this.chain.infoError(error);
            }));
            //ローダーの表示

            Loader.wait(promise);
            console.debug('[登録]ボタンがクリックされました。');
        },

        /**
         * widgetが居なくなる時に呼ばれる
         * 定期処理を止める
         */
        destroy: function() {
            this.inherited(arguments);
            clearInterval(this.timer);
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.chronologyListGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showDetailPage(evt.item);
            }));

            this.chronologyListGrid.on('showDetailPage', lang.hitch(this, function(evt) {
                this.showDetailPage(evt.item);
            }));


            this.chronologyListGrid.on('showContentDetailPage', lang.hitch(this, function(evt) {
                evt.preventDefault();
                this.showContentDetailPage(evt.item);
            }));

            // 対応内容を追加
            this.chronologyListGrid.on('ChronologyActionsButtonClick', lang.hitch(this, function(evt) {
                this.showHldContentRegisterDialog(evt.item);
            }));

            // 発信日時でソートする
            //this.searchPanel.registerDisplayOrder();

            this.setFilterData();
            // this.updateGridQuery(); // あとでupdateGridQueryするため、今はしない
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            var value = this.form.get('value');

            // 災害ID
            filter = filter.eq('disasterId', this._disasterId);

            // 発信日時FROM
            if (value.registerTimestampFrom) {
                var registerDateTimeFrom = new Date(value.registerTimestampFrom).getTime();
                filter = filter.eq('registerTimestampFrom', registerDateTimeFrom);
            }

            // 発信日時TO
            if (value.registerTimestampTo) {
                var registerDateTimeTo = new Date(value.registerTimestampTo).getTime();
                filter = filter.eq('registerTimestampTo', registerDateTimeTo);
            }

            // 緊急度
            if (value.urgencyType) {
                filter = filter.eq('urgencyType', value.urgencyType);
            }

            // 市町村コード
            if (this._municipalityCd) {
/*                if(this._municipalityCd.substring(0,3) === config.municInfo.prefRegCdGudge &&
                    this._municipalityCd !== config.municInfo.prefMunicCd){
                        // 振興局である場合
                        filter = filter.eq('regionCd', this._municipalityCd);
                    }else{
                        // 県・市町である場合
                        filter = filter.eq('municipalityCd', this._municipalityCd);
                    }*/
                    // 県・市町である場合
                    filter = filter.eq('municipalityCd', this._municipalityCd);
            }

            if(this._organizationCd){
                filter  = filter.eq('organizationCd', this._organizationCd);
            }

            // 対応状況
            var hldStatusList = [];
            if(this.hldStatus0.get('checked')){
                hldStatusList.push('0');
            }
            if(this.hldStatus1.get('checked')){
                hldStatusList.push('1');
            }
            if(this.hldStatus2.get('checked')){
                hldStatusList.push('2');
            }
            if(this.hldStatus3.get('checked')){
                hldStatusList.push('3');
            }
            if(this.hldStatus9.get('checked')){
                hldStatusList.push('9');
            }

            if (value.hldStatus){
                filter = filter.eq('hldStatus', hldStatusList.join(','));
            }

            // キーワード
            if (value.keyword) {
                filter = filter.eq('keyword', value.keyword);
            }

            // 配信元
            // if (value.shareName) {
            //     filter = filter.eq('shareName', value.shareName);
            // }


            // クロノロジ種別系
            var chronologyTypes = [];
            chronologyTypes.push(ChronologyType.DISASTER_HLD.id);
            chronologyTypes.push(ChronologyType.PREFECTURE_SHARE.id);
            filter = filter.eq('chronologyTypes', chronologyTypes.join(','));
            
            // // 被害
            // if (this.chronologyTypes.indexOf(ChronologyType.DAMAGE.id) !== -1) {
            //     chronologyTypes.push(ChronologyType.DAMAGE.id);
            // } else if(this.damageInfoCategory && this.damageInfoCategory.length > 0) {
            //     filter = filter.eq('damageInfoCategory', this.damageInfoCategory.join(','));
            // }

            // // 気象
            // if (this.chronologyTypes.indexOf(ChronologyType.WEATHER_WARNING.id) !== -1) {
            //     chronologyTypes.push(ChronologyType.WEATHER_WARNING.id);
            // }

            // // 観測
            // if (this.chronologyTypes.indexOf(ChronologyType.CIVIL_PROTECTION.id) !== -1) {
            //     chronologyTypes.push(ChronologyType.CIVIL_PROTECTION.id);
            // }

            // // 国民保護
            // if (this.chronologyTypes.indexOf(ChronologyType.OBSERVATORY.id) !== -1) {
            //     chronologyTypes.push(ChronologyType.OBSERVATORY.id);
            // }

            // // 避難情報
            // if (this.chronologyTypes.indexOf(ChronologyType.EVACUATION.id) !== -1) {
            //     chronologyTypes.push(ChronologyType.EVACUATION.id);
            // }

            // // 配備体制
            // if (this.chronologyTypes.indexOf(ChronologyType.PREVENTION.id) !== -1) {
            //     chronologyTypes.push(ChronologyType.PREVENTION.id);
            // }

            // // 避難所情報
            // if (this.chronologyTypes.indexOf(ChronologyType.SHELTER.id) !== -1) {
            //     chronologyTypes.push(ChronologyType.SHELTER.id);
            // }

            // // 部隊活動
            // if (this.chronologyTypes.indexOf(ChronologyType.RESCUE.id) !== -1) {
            //     chronologyTypes.push(ChronologyType.RESCUE.id);
            // }

            // // 道路通行規制
            // if (this.chronologyTypes.indexOf(ChronologyType.TRAFFIC.id) !== -1) {
            //     chronologyTypes.push(ChronologyType.TRAFFIC.id);
            // }


            // // 定時報告は、「定時報告依頼」のコードが代表として入っているので、それがチェックされていたら「定時報告」も追加する。
            // if (this.chronologyTypes.indexOf(ChronologyType.SCHEDULED_REQUEST.id) !== -1) {
            //     chronologyTypes.push(ChronologyType.SCHEDULED_REPORT.id);
            //     chronologyTypes.push(ChronologyType.SCHEDULED_REQUEST.id);
            // }

            // ユーザ情報
            //var userMunicipalityCd = UserInfo.getUserType() === USER_TYPE.REGION ?
            //                            config.municInfo.prefMunicCd : UserInfo.getMunicipalityCd();
            var userMunicipalityCd = UserInfo.getMunicipalityCd();

            // // 組織内情報（ホワイトボード）
            // var whiteBoardIndex = this.chronologyTypes.indexOf(ChronologyType.WHITE_BOARD.id);
            // if (whiteBoardIndex !== -1 &&
            //     (!this._municipalityCd || this._municipalityCd === userMunicipalityCd )) {
            //     // 組織内情報については、チェックボックスが選択されていて、
            //     // かつ自分の市町村がセレクターで選ばれている時のみ、クエリ対象とする
            //     chronologyTypes.push(ChronologyType.WHITE_BOARD.id);
            // }

            // 要請・措置
            if (this.reqInfoCategory.length > 0 ) {
                // 要請・措置については、チェックボックスが選択されていて、
                // かつ自分の市町村がセレクターで選ばれている時のみ、クエリ対象とする
                if(this._municipalityCd === userMunicipalityCd || !this._municipalityCd ){
                    filter = filter.eq('reqInfoCategory', this.reqInfoCategory.join(','));
               // } else if(UserInfo.getUserType() === USER_TYPE.REGION &&
                  //          UserInfo.getRegionCd() === this._municipalityCd ){
                    // 振興局の場合は、市町村コードではなく振興局コードで判定する必要があるので、分けて判定する
                  //  filter = filter.eq('reqInfoCategory', this.reqInfoCategory.join(','));
                } else {
                    // 表示したくないので、ダミーを仕込む。
                    filter = filter.eq('reqInfoCategory', '99999');
                }
            }

            // 大阪府連携
            if (this.prefectureInfoCategory.length > 0 ) {
                // 大阪府連携については、チェックボックスが選択されていて、
                // かつ自分の市町村がセレクターで選ばれている時のみ、クエリ対象とする
                if(this._municipalityCd === userMunicipalityCd || !this._municipalityCd ){
                    filter = filter.eq('prefectureInfoCategory', this.prefectureInfoCategory.join(','));
                } else {
                    // 表示したくないので、ダミーを仕込む。
                    filter = filter.eq('prefectureInfoCategory', '99999');
                }
            }

            // if(chronologyTypes && chronologyTypes.length > 0){
            //     filter = filter.eq('chronologyTypes', chronologyTypes.join(','));
            // } else if (this.searchPanel.isInnerMuniInfoHidden()){
            //     // FIXME: ハードコードを避ける
            //     // 自分の市町村宛の情報ではない情報を検索する場合は、組織内情報や要請を検索結果から外したい
            //     filter = filter.eq('chronologyTypes', '27,01,02,03,24,25,26,32,42,41');
            // }

            // 限定表示
            filter = filter.eq('resultLimitFlg', this.resultLimitFlg);

            filter = filter.eq('userMunicipalityCd', userMunicipalityCd);
            filter = filter.eq('userOrganizationCd', UserInfo.getLowestOrganizationCd());

            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.chronologyListGrid.set('collection', collection);
        },

        resetTreeFilter: function(){
            this.chronologyTypes = [];
            this.reqInfoCategory = [];
            this.prefectureInfoCategory = [];
            // this.damageInfoCategory = [];
            this.checkItems = [];
        },

        /**
         * 検索条件を保管する
         */
        setFilterStore: function() {
            //初期化する
            chronologyFilterStore = {};
            var value = this.form.get('value');

            // 発信日時FROM
            if (value.registerTimestampFrom) {
                chronologyFilterStore.registerTimestampFrom = value.registerTimestampFrom;
            }

            // 発信日時TO
            if (value.registerTimestampTo) {
                chronologyFilterStore.registerTimestampTo = value.registerTimestampTo;
            }

            // 情報種類
            if (value.chronologyType) {
                chronologyFilterStore.chronologyType = value.chronologyType;
            }

            // 緊急度
            if (value.urgencyType) {
                chronologyFilterStore.urgencyType = value.urgencyType;
            }

            // 情報提供者
            // if (value.shareName) {
            //     chronologyFilterStore.shareName = value.shareName;
            // }

            // 市町村コード
            if (this._municipalityCd) {
                chronologyFilterStore.municipalityCd = this._municipalityCd;
            }

            if (this._organizationCd) {
                chronologyFilterStore.organizationCd = this._organizationCd;
            }

            // 市町村コード
            if (value.chronologyShares) {
                chronologyFilterStore.chronologyShares = value.chronologyShares;
            }

            // 表示情報
            chronologyFilterStore.chronologyTypes = this.chronologyTypes;
            // chronologyFilterStore.damageInfoCategory = this.damageInfoCategory;
            chronologyFilterStore.reqInfoCategory = this.reqInfoCategory;
            chronologyFilterStore.prefectureInfoCategory = this.prefectureInfoCategory;
            chronologyFilterStore.checkItems = this.checkItems;

            // 対応状況
            chronologyFilterStore.hldStatus = [];
            if(this.hldStatus0.get('checked')){
                chronologyFilterStore.hldStatus.push('0');
            }
            if(this.hldStatus1.get('checked')){
                chronologyFilterStore.hldStatus.push('1');
            }
            if(this.hldStatus2.get('checked')){
                chronologyFilterStore.hldStatus.push('2');
            }
            if(this.hldStatus3.get('checked')){
                chronologyFilterStore.hldStatus.push('3');
            }
            if(this.hldStatus9.get('checked')){
                chronologyFilterStore.hldStatus.push('9');
            }

            // キーワード
            if (value.keyword) {
                chronologyFilterStore.keyword = value.keyword;
            }

            // 配信元
            // if (value.shareName) {
            //     chronologyFilterStore.shareName = value.shareName;
            // }

            // 表示順
            // chronologyFilterStore.isUpdTimestampSort =
            //    this.chronologyListGrid.get('sort')[0].property==='updTimestamp';

            // チェック
            chronologyFilterStore.resultLimitFlg = this.resultLimitFlg;
        },

        initHldContentRegisterPage: function(){
            // 登録ダイアログの最初の子要素が登録画面
            var dialog = this.hldContentDialog;
            var page = dialog.getChildren()[0];
            // 登録画面のsendイベントを受け取る
            page.on('send', lang.hitch(this, function(evt) {

                //登録確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    var jsonStr = json.stringify(evt.value);
                    var promise = Requester.post('/api/chronologies/action', {
                        data: jsonStr,
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function() {
                        console.debug('success register Chronology');
                        chain.infoComplete(lang.hitch(this, function() {
                            chain.hide();
                            dialog.hide();

                            // 画面を更新
                            this.updateGridQuery();
                            this.previewText.innerHTML = '';
                            this.action.set('value','');
                            this.actionAttachFileList = [];
                        }));
                    }), function(error) {
                        console.log('error register Chronology');
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
            }));
        },

        /**
         * 対応内容登録ダイアログを表示する。
         * テンプレートHTMLでdata-dojo-attach-eventプロパティーを用い、
         * 新規登録ボタンのクリックイベントにこのメソッドを紐付けている。
         */
        showHldContentRegisterDialog: function(item) {
            // ダイアログの最初の子要素が登録画面
            var page = this.hldContentDialog.getChildren()[0];
            // 画面上のフォームをリセット
            // （ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み）
            page.form.reset();

            page.initDialog(item);

            // ダイアログを表示
            this.hldContentDialog.show();
        },

        /**
         * クロノロジ対応履歴を登録する。
         */
        registerChronologyAction: function() {
            var sendData = {
                chronologyId : this.chronologyId,
                content : this.action.value,
                attachFileIds: ''
            };
            // フォームのバリデーションを行う（共通部品）
            if(!this.form.validate()) {
                return false;
            }
            if(!sendData.content) {
                InfoDialog.show('入力チェック', '本文が入力されていません');
                return false;
            }

            // 添付ファイルIDをセット
            if(this.actionAttachFileList.length > 0) {
                var attachFileIds = [];
                for(var i=0; i<this.actionAttachFileList.length; i++) {
                    attachFileIds.push(this.actionAttachFileList[i].chronologyAtcFileId);
                }
                sendData.attachFileIds = attachFileIds.join(',');
            } else {
                sendData.attachFileIds = '';
            }

            var jsonStr = json.stringify(sendData);
            //登録確認ダイアログを表示
            this.chain.confirmAdd(function(chain) {
                var promise = Requester.post('/api/chronologies/action', {
                    data: jsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function() {
                    console.debug('success register Chronology');
                    chain.infoComplete(lang.hitch(this, function() {
                        chain.hide();
                        // 画面を更新
                        this.chronologyListGrid.selectedId = this.evt.item.chronologyId;
                        this.updateGridQuery();
                        this.previewText.innerHTML = '';
                        this.action.set('value','');
                        this.actionAttachFileList = [];
                    }));
                }), function(error) {
                    console.log('error register Chronology');
                    chain.infoError(error);
                });
                //ローダーの表示
                Loader.wait(promise);
            });
            console.debug('[登録]ボタンがクリックされました。');
        },

        // /**
        //  * クロノロジを登録する。
        //  */
        registerChronology: function() {
            var sendData = this.chronologyForm.get('value');
            if(!sendData.content){
                InfoDialog.show('入力チェック', '本文が入力されていません');
                return false;
            }
            if(sendData.chronologyType === ChronologyType.DAMAGE.id){ // 被害情報だった場合、被害情報として登録する。
                this.registerDamageReport(sendData.content);
                return;
            }

            sendData.registerTimestamp = new Date();
            //sendData.senderName = Municipality.childrenCache.$ROOT$[0].name;
            sendData.hldStatus = '0';
            sendData.urgencyType = '0';
            sendData.sourceAddress02 = '';

            if(sendData.sourceAddress01) {
                sendData.sourceLatitude = this.pointLat;
                sendData.sourceLongitude = this.pointLng;
            } else {
                sendData.sourceLatitude = '';
                sendData.sourceLongitude = '';
            }
            if(sendData.registerMunicipalityCd){
                sendData.municipalityCds = [sendData.registerMunicipalityCd];
            }else{
                sendData.municipalityCds = [];
            }
            delete sendData.registerMunicipalityCd;
            delete sendData.damageMunicipalityCd;

            sendData.disasterId = this._disasterId;

            // 共有先組織
            var chronologyShares = [];
            var shareMap = this.registerChronologyShares.tree._checkMap;
            var excludeMap = {};
            var tree = this.registerChronologyShares.tree;
            df.forIn(shareMap, function(item) {
                if(tree.isEveryChildChecked(item)){
                    tree.getItemChildren(item).then(lang.hitch(this, function(items) {
                        if(!items){
                            return false;
                        }
                        array.forEach(items, function(item){
                            excludeMap[tree.model.store.getIdentity(item)] = true;
                        }, this);
                    }));
                }
            }, this);
            df.forIn(shareMap, function(item) {
                var cd = tree.model.store.getIdentity(item);
                if(!excludeMap[cd]){
                    chronologyShares.push(cd);
                }
            }, this);
            sendData.chronologyShares = chronologyShares;
            delete sendData.registerChronologyShares;

            //添付ファイルIDをセット
            if(this.attachFileList.length > 0) {
                var attachFileIds = [];
                for(var i=0; i<this.attachFileList.length; i++) {
                    attachFileIds.push(this.attachFileList[i].chronologyAtcFileId);
                }
                sendData.attachFileIds = attachFileIds.join(',');
            } else {
                sendData.attachFileIds = '';
            }
            this.attachFileList = [];

            delete sendData.attachFile;
            delete sendData.organizationName;

            // フォームのバリデーションを行う（共通部品）
            if(!this.chronologyForm.validate()) {
                return false;
            }

            delete sendData.uploadedfile;
            var jsonStr = json.stringify(sendData);

            //登録確認ダイアログを表示
            this.chain.confirmAdd(function(chain) {
                var promise = Requester.post('/api/chronologies', {
                    data: jsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function() {
                    console.debug('success register Chronology');
                    chain.infoComplete(lang.hitch(this, function() {
                        chain.hide();
                        // 一覧画面に移動
                        this.updateGridQuery();
                        // location.reload();
                        this.preview.innerHTML = '';
                        this.text.set('value','');
                    }));
                }), function(error) {
                    console.log('error register Chronology');
                    chain.infoError(error);
                });
                //ローダーの表示
                Loader.wait(promise);
            });
            console.debug('[登録]ボタンがクリックされました。');
        },

        registerDamageReport: function(content){
            var sendData = {};
            // sendData.description = content;
            sendData.content = content;
            sendData.disasterId = this._disasterId;
            sendData.headquarterCheckFlg = '0'; //FIXME: 直す
            // 市町村
            var damageMunicipalityCd = this.damageMunicipalityCd.get('value');
            if(!damageMunicipalityCd){
                // InfoDialog.show('入力チェック', '市町村が選択されていません。');
                InfoDialog.show('入力チェック', '市/行政区が選択されていません。');
                return;
            }
            sendData.municipalityCd = damageMunicipalityCd;
            sendData.seqNum = 1;
            sendData.fixFlg = '0'; //「未確定」をセット。
            sendData.hldStatus = '0'; //「未対応」をセット。
            sendData.humanDamageFlg = '1'; //「なし」をセット。
            sendData.damageType = '12'; //「簡易報告」をセット。
            sendData.urgencyType = '1'; //「-(通常)」をセット。 //FIXME: 画面から選択できるようにすべき
            // 報告名をセット。
            var currentDate = new Date();
            var prefix = locale.format(currentDate, {
                selector: 'date',
                formatLength: 'long',
                locale: 'zh'
            });
            sendData.reportName = prefix + '被害';
            // 報告状況をユーザごとにセット。
            switch(UserInfo.getUserType()){
                case USER_TYPE.OTHER_ORGAN:
                case USER_TYPE.PREFECTURE:
                    sendData.reportStatus = '31';
                    break;
                case USER_TYPE.REGION:
                    sendData.reportStatus = '31';
                    break;
                default:
                    sendData.reportStatus = '11';
                    break;
            }
            // 報告日時・報告者情報をセット。
            sendData.reportTimestamp = new Date().getTime();
            sendData.reportAuthorName = UserInfo.getName();
            var organ = UserInfo.getOrganization();
            sendData.organization =
                organ.unitCd ? 'U' + organ.unitCd :
                organ.sectCd ? 'S' + organ.sectCd :
                               'D' + organ.deptCd ;
            sendData.urgencyType = '1';
            //添付ファイルIDをセット
            if(this.attachFileList.length > 0) {
                var attachFileIds = [];
                for(var i=0; i<this.attachFileList.length; i++) {
                    attachFileIds.push(this.attachFileList[i].damageReportAtcFileId);
                }
                sendData.attachFileIds = attachFileIds.join(',');
            } else {
                sendData.attachFileIds = '';
            }
            this.attachFileList = [];
            var jsonStr = json.stringify(sendData);

            //登録確認ダイアログを表示
            this.chain.confirmAdd(function(chain) {
                var promise = Requester.post('/api/damageReports', {
                    data: jsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function() {
                    console.debug('success register damageReport');
                    chain.infoComplete(lang.hitch(this, function() {
                        chain.hide();
                        // 一覧画面に移動
                        this.updateGridQuery();
                        // location.reload();
                        this.preview.innerHTML = '';
                        this.text.set('value','');
                    }));
                }), function(error) {
                    console.log('error register damagereport');
                    chain.infoError(error);
                });
                //ローダーの表示
                Loader.wait(promise);
            });
        },


        /**
         * 保管した検索条件をformにセットする
         */
        setFilterData: function() {

            // 発信日時FROM
            if(chronologyFilterStore.registerTimestampFrom) {
                this.registerTimestampFrom.set('value', chronologyFilterStore.registerTimestampFrom);
            }

            // 発信日時TO
            if(chronologyFilterStore.registerTimestampTo) {
                this.registerTimestampTo.set('value', chronologyFilterStore.registerTimestampTo);
            }

            // 情報種類
            if (chronologyFilterStore.chronologyType) {
                this.chronologyType.set('value', chronologyFilterStore.chronologyType);
            }

            // 緊急度
            if (chronologyFilterStore.urgencyType) {
                this.urgencyType.set('value', chronologyFilterStore.urgencyType);
            }

            // 発信元
            // if (chronologyFilterStore.shareName) {
            //     this.shareName.set('value', chronologyFilterStore.shareName);
            // }

            // 市町村コード
            this._municipalityCd = chronologyFilterStore.municipalityCd ||
                                    this.searchPanel.municipalityCd.get('value');

            // 宛先・配信元組織コード
            this._organizationCd = chronologyFilterStore.organizationCd ||
                                    this.searchPanel.organizationCd.get('value');

            // 共有先組織
            if(chronologyFilterStore.chronologyShares) {
                this.chronologyShares.set('value', chronologyFilterStore.chronologyShares);
            }

            // 表示情報
            if (chronologyFilterStore.checkItems && chronologyFilterStore.checkItems.length>0) {
                this.chronologyTypes = chronologyFilterStore.chronologyTypes;
                // this.damageInfoCategory = chronologyFilterStore.damageInfoCategory;
                this.reqInfoCategory = chronologyFilterStore.reqInfoCategory;
                this.prefectureInfoCategory = chronologyFilterStore.prefectureInfoCategory;
                this.checkItems = chronologyFilterStore.checkItems;
            }

            // 対応状況
            if (chronologyFilterStore.hldStatus) {
                var status0 = chronologyFilterStore.hldStatus.indexOf('0') !== -1 ? 'checked' : '';
                var status1 = chronologyFilterStore.hldStatus.indexOf('1') !== -1 ? 'checked' : '';
                var status2 = chronologyFilterStore.hldStatus.indexOf('2') !== -1 ? 'checked' : '';
                var status3 = chronologyFilterStore.hldStatus.indexOf('3') !== -1 ? 'checked' : '';
                var status9 = chronologyFilterStore.hldStatus.indexOf('9') !== -1 ? 'checked' : '';
                this.hldStatus0.set('checked', status0);
                this.hldStatus1.set('checked', status1);
                this.hldStatus2.set('checked', status2);
                this.hldStatus3.set('checked', status3);
                this.hldStatus9.set('checked', status9);
            }

            // キーワード
            if (chronologyFilterStore.keyword) {
                this.keyword.set('value', chronologyFilterStore.keyword);
            }

            // 配信元
            // if (chronologyFilterStore.shareName) {
            //     this.shareName.set('value', chronologyFilterStore.shareName);
            // }

            // クロノロジID
            if (chronologyFilterStore.chronologyId) {
                this.chronologyId.set('value', chronologyFilterStore.chronologyId);
            }

            // 表示順
            /*if(chronologyFilterStore.isUpdTimestampSort){
                this.searchPanel.updDisplayOrder();
            } else {
                this.searchPanel.registerDisplayOrder();
            }*/

            // 検索欄にセット
            this.searchPanel.setFilterData({
                // municipalityCd: this._municipalityCd,
                organizationCd: this._organizationCd,
                checkItems: this.checkItems
            });
        },

        autoScroll: function(){
            domStyle.set(this.autoScrollLabel, 'display', 'none');
            domStyle.set(this.stopAutoScrollLabel, 'display', '');
            var i = 0;
            this.autoScrollTimer = setInterval(lang.hitch(this, function() {
                i++;
                this.chronologyListGrid.scrollTo({ y: (this.AUTO_SCROLL_UNIT * i) });
            }), 1 * this.AUTO_SCROLL_INTERVAL * 1000);
        },

        stopAutoScroll: function(){
            domStyle.set(this.autoScrollLabel, 'display', '');
            domStyle.set(this.stopAutoScrollLabel, 'display', 'none');
            clearInterval(this.autoScrollTimer);
        },

        /**
        * クロノロジを検索する。
        */
        onSubmit: function(data) {
            console.debug('[検索]ボタンがクリックされました。');
            if(data !== undefined) {
                if(data.keyCode !== undefined || data.code !== undefined) {
                    if(!(
                        (data.keyCode === this.ENTER_KEY_CODE || data.code === this.ENTER_CODE) || 
                        (data.keyCode === this.BACK_SPACE_KEY || data.code === this.BACK_SPACE_CODE))) {
                        return;
                    }
                }
            }
            try {
                if (this.form.isValid()) {
                    this.setFilterStore();
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                } else {
                	console.debug('エラーあり');
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        onActionSubmit: function(){
            console.debug('[検索]ボタンがクリックされました。');
            try {
                if (this.form.isValid()) {
                    this.setFilterStore();
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                } else {
                	console.debug('エラーあり');
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
        * 選択したデータのみ表示する。
        */
        filterData: function() {
            console.debug('[限定]ボタンがクリックされました。');
            try {
                if (this.form.isValid()) {
                    this.setFilterStore();
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                } else {
                	console.debug('エラーあり');
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        showEachRegisterPage: function (evt) {
            console.debug('[詳細情報を入力]ボタンがクリックされました。');
            var sendData = this.simpleRegisterForm.get('value');
            evt.preventDefault();
            switch (sendData.simpleRegisterChronologyType) {
                //   case '01':
                // Router.moveTo('convocation/weather/register');
                //       break;
                //   case '02':
                // Router.moveTo('disasterprevention');
                //       break;
                //   case '03':
                // Router.moveTo('chronology/register');
                //       break;
                case '04':
                    Router.moveTo('evacorder/register');
                    break;
                case '05':
                    Router.moveTo('disasterprevention');
                    break;
                case '06':
                    Router.moveTo('shelter/register');
                    break;
                case '07':
                    Router.moveTo('report/register');
                    break;
                case '08':
                    Router.moveTo('materialmanagement/register');
                    break;
                case '09':
                    Router.moveTo('requestmeasure/register');
                    break;
                case '99':
                    Router.moveTo('chronology/register');
                    break;
            }
        },

        /**
         * クロノロジ新規登録画面へ遷移する。
         */
        showRegisterPageConditional: function(evt) {
            console.debug('[新規登録]ボタンがクリックされました。');
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            this.setFilterStore();
            if(this.chronologyType.get('value') === ChronologyType.DAMAGE.id){
                // 被害の新規登録画面へ遷移
                Router.moveTo('report/register');
            } else {
                // 新規登録画面へ遷移
                Router.moveTo('chronology/register');
            }
        },

        /**
         * クロノロジ新規登録画面へ遷移する。
         */
        showRegisterPage: function(evt) {
            console.debug('[新規登録]ボタンがクリックされました。');
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            this.setFilterStore();
            Router.moveTo('chronology/register');
        },

        /**
         * クロノロジ種別に応じた画面へ遷移する。
         */
        showDetailPage: function(object) {
            this.setSession();
            switch (object.chronologyType) {
                case ChronologyType.DAMAGE.id:
                    Router.moveToByUrl(object.sourceUrl);
                    break;
                default:
                    Router.moveTo('chronology/detail',{
                        chronologyId: object.chronologyId
                    });
                    break;
            }
        },

        /**
         * クロノロジ内容詳細画面へ遷移する。
         */
        showContentDetailPage: function(object) {
            this.setSession();
            Router.moveToByUrl(object.sourceUrl);
        },

        getSession: function() {
            var chronologySession = SessionStorage.get('chronologyFilterStore');
            if (!chronologySession) {
                // 初期化
                this.setSession();
                chronologySession = SessionStorage.get('chronologyFilterStore');
            }
            chronologyFilterStore = json.parse(chronologySession);
            SessionStorage.remove('chronologyFilterStore');
        },

        setSession: function() {
            SessionStorage.set('chronologyFilterStore', json.stringify(chronologyFilterStore));
        },

        filterByType: function(value) {
            alert(value);
        },

        /**
         * 帳票出力ダイアログを表示
         */
        showDownLoadDialog: function() {
        	this.formDialog.show();
        },

        outputListExcel: function() {
             var reqid = 'CHRONOLOGY_INFOLIST_EX';
             var disid = DisasterInfo.getDisasterId();

             var suffix = '-新着情報一覧';
             var date = new Date();
             var fileName =
                 date.getFullYear() + this._zeroPadding(date.getMonth() + 1) +
                 this._zeroPadding(date.getDate()) + suffix + '.xlsx';

             var url = '/bousai/Download/Download?reqid=' + reqid + '&disid=' + disid;
             FilesUtils.downloadGet(url, fileName);
             return false;
         },

         /**
          * 月や日付を2桁にゼロpaddingする
          */
         _zeroPadding: function(month) {
             return ('00' + month).slice(-2);
         },

        /**
         * 国民保護情報ダイアログを表示する。
         */
        showCivilProtectionStatementDialog: function(object) {
        	var message = object.content;
            this.innerCivilProtectionStatementDialog.setMessage(message);
            this.civilProtectionStatementDialog.show();
        },

        showFullScreen: function(evt) {
            evt.preventDefault();

            // 非表示
            dom.byId('header').style.display = 'none';
            dom.byId('search').style.display = 'none';
            // dom.byId('bottom-input').style.display = 'none';
            dom.byId('search_splitter').style.display = 'none';
            this.onCloseSideBoxClick();
            // dom.byId('typeFilter').style.display = 'none';
            // 表示
            dom.byId('filterColmuns').style.display = 'inline-block';
            dom.byId('closeFullScreen').style.display = 'inline-block';
            domClass.add(this.chronologyListGrid.domNode, 'big-text');
            domStyle.set(this.fullScreenNavigator.domNode, 'display', '');

            this.borderContainer.resize();
            this.full.resize();

        },

        onClickCloseFullScreen: function() {
            // 表示
            dom.byId('header').style.display = 'block';
            dom.byId('search').style.display = 'block';
            // dom.byId('bottom-input').style.display = 'block';
            // dom.byId('typeFilter').style.display = 'block';
            dom.byId('search_splitter').style.display = 'block';

            domClass.remove(this.chronologyListGrid.domNode, 'big-text');
            // 非表示
            this.onClickAllColmuns();
            dom.byId('filterColmuns').style.display = 'none';
            dom.byId('closeFullScreen').style.display = 'none';
            domStyle.set(this.infoType, 'display', '');
            domStyle.set(this.fullScreenNavigator.domNode, 'display', 'none');

            if(this.autoScrollTimer){ // 自動スクロールをオンにしたまま全画面モードを開放した場合
                this.stopAutoScroll();
            }

            this.onOpenSideBoxClick();
            this.borderContainer.resize();
            this.full.resize();
        },

        onClickFilterColmuns: function() {
            // 列を非表示

            this.chronologyListGrid.styleColumn('updTimestamp', 'display: none;');
            this.chronologyListGrid.styleColumn('registerTimestamp', 'display: none;');
            this.chronologyListGrid.styleColumn('municipalityNames', 'display: none;');
//            this.chronologyListGrid.styleColumn('chronologyShareNames', 'display: none;');
            this.chronologyListGrid.styleColumn('hldStatus', 'display: none;');

            dom.byId('allColmuns').style.display = 'inline-block';
            dom.byId('filterColmuns').style.display = 'none';

            this.fullScreenArea.resize();

        },

        onClickAllColmuns: function() {
            // 列を非表示
            this.chronologyListGrid.styleColumn('updTimestamp', 'display: table-cell;');
            this.chronologyListGrid.styleColumn('registerTimestamp', 'display: table-cell;');
            this.chronologyListGrid.styleColumn('municipalityNames', 'display: table-cell;');
//            this.chronologyListGrid.styleColumn('chronologyShareNames', 'display: table-cell;');
            this.chronologyListGrid.styleColumn('hldStatus', 'display: table-cell;');
            dom.byId('allColmuns').style.display = 'none';
            dom.byId('filterColmuns').style.display = 'inline-block';

            this.fullScreenArea.resize();
        },

        toggleRestrictStrings: function(){
            ChronologyStore.setProp(this.restrictStrings.checked);
            this.updateGridQuery();
        },

        toggleRestrictStringsForFullScr: function(){
            ChronologyStore.setProp(this.restrictStringsForFullScr.checked);
            this.updateGridQuery();
        }
    });
});
