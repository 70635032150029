define([
    'app/damage/damageReportAdmin/DamageReportAdminPage',
    'app/damage/damageReportAdmin/DamageReportAdminViewPage',
    'app/damage/damageReportDetail/DamageReportDetailPage',
    'app/damage/damageReportDetail/DamageReportDetailViewPage',
    'app/damage/DamageReportPage',
    'app/damage/DamageReportViewPage',
    'app/damage/damageReportAggregate/DamageReportAggregatePage',
    'app/damage/damageReportAggregate/DamageReportAggregateViewPage',
    'app/damage/damageReportRegister/DamageReportRegisterPage',
], function () { });