/**
 * 気象情報画面用モジュール。
 * @module app/weatherinfo/view/WeatherInfoPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-construct',
	'dojo/_base/array',
    'idis/control/Router',
    'dojo/text!./templates/WeatherInfoPage.html',
    './_WeatherInfoPageBase',
    'idis/store/IdisRest',
    'app/util/DateFormatter',
    'dojo/when',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    './WeatherInfoGrid'
], function(module, declare, lang, construct, array, Router, template, _PageBase, IdisRest, DateFormatter, when) {
    /**
     * 気象情報画面
     * @class WeatherInfoPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/weatherinfo/view/WeatherInfoPage~WeatherInfoPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--weatherinfo',

        // ストア
        store: null,

        constructor: function() {
            this.inherited(arguments);
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'weatherInfoiId',
                target: '/api/WeatherInformations'
            });
        },

        startup: function() {
            this.inherited(arguments);
            // 更新日時の表示を設定
            var filter = new this.store.Filter();
            when(this.store.filter(filter).fetch()).then(lang.hitch(this, function(data){
                this.updateDateTime.innerHTML = '---';
                if(data[0].reportDateTime) {
                    var date = new Date(data[0].reportDateTime.split('-').join('/'));
                    this.updateDateTime.innerHTML = DateFormatter.jpDateTime(date, true);
                }
            }));
        },

        onWeatherInfoTimeLineButtonClick: function() {
            console.debug('[時系列表示に切り替え]ボタンがクリックされました。');
            // 気象警報・注意報一覧（時系列表示）画面へ遷移
            Router.moveTo('weatherinfo/timeline');
        }
    });
});