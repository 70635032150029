/**
 * 市町内の組織選択用入力パーツ
 * @module idis/view/form/MunicOrganizationSelectorWithSearch
 */
 define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/string',
    'idis/view/form/TreeSelector',
    'idis/view/tree/IdisTree',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'dijit/form/TextBox',
], function(module, declare, lang,  dStr, TreeSelector, IdisTree, CacheStoreModel, CacheTreeRest, TextBox) {
    /**
     * 組織選択用パーツ。
     * @class OrganizationSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:idis/view/tree/OrganizationSelector~OrganizationSelector# */ {
        // 選択ダイアログのタイトル
        title: '組織選択',

        // ツリー・モデル
        model: null,

        placeHolder: '組織名で絞り込み',

        constructor : function() {
            // 組織を0県受け取るようダミーの市町コードを設定
            var municipalityCd = '000000';

            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/organizations/' + municipalityCd
                })
            });

            this.tree = new TreeSelector({
                model : this.model
            }, this.treeNode);
            

        },

        // 市町セレクタが変更された際、市町内の組織を取得し直す
        onMunicipalityChange : function(municipalityCd) {
            if(!municipalityCd){
                return false;
            }

            // 一旦ツリーを削除し、再構築する
            if(this.tree){
                this.tree.destroyRecursive();
            }
            var store = new CacheTreeRest({
                target: '/api/organizations/' + municipalityCd
            });
            // TODO: この部分で[Cannot read property 'setAttribute' of null] というエラーを吐く場合があるが、
            // 画面上の不具合はないため対応を保留中。
            this.model = new CacheStoreModel({
                store: store
            });

            this.tree = new IdisTree({
                model : this.model
            }, this.treeNode);

            // 再配置する
            this.tree.placeAt(this.parentTreeNode);
            this.tree.startup();

            return this.tree.onLoadDeferred;

        },

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            // 検索用入力要素を設置
            this.filterInput = new TextBox({
                placeHolder: this.placeHolder,
                maxLength: 128
            });
            this.filterInput.placeAt(this.treeControlNode);
            this.own(this.filterInput.on('keyup', lang.hitch(this, 'onFilterInputChange')));
        },

        /**
         * 検索用入力要素入力時。
         */
        onFilterInputChange: function() {
            // 入力値を取得
            var value = dStr.trim(this.filterInput.get('value'));
            if (value) {
                // ラベル名が入力値を含むものでフィルターし、ツリーを展開する
                this.model.setFilter(function(item, isLeaf, model) {
                    return isLeaf && (model.getLabel(item) || '').indexOf(value) !== -1;
                }).then(lang.hitch(this, function() {
                    this.tree.expandAll();
                }));
            } else {
                // 入力が空の場合は全要素を表示してツリーを閉じる
                this.model.setFilter().then(lang.hitch(this, function() {
                    this.tree.collapseAll();
                }));
            }
        }

    });
});
