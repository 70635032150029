/**
* 気象情報一覧画面用モジュール。
* @module app/weatherinfo/view/WeatherInfoPage
*/
define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
	'dojo/_base/array',
	'dojox/lang/functional/object',
	'idis/view/grid/IdisGrid',
	'idis/view/grid/helper',
    './WeatherInfoColumn',
	// 以下、変数として受け取らないモジュール
	'dojox/lang/functional/array'
], function(module, declare, lang, array, df, IdisGrid, helper, WeatherInfoColumn) {

    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

	/**
	 * 各列の定義
	 * @type {Object[]}
	 */
	columns: [
        WeatherInfoColumn.cityName,
        WeatherInfoColumn.spWarnHeader,
        WeatherInfoColumn.warnHeader,
        WeatherInfoColumn.advisoryHeader
	]});
});
