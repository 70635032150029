define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom',
    'dojo/text!./templates/AddressPanel.html',
    'idis/consts/QUERY',
    'idis/control/Locator',
    'idis/service/GeoService',
    'idis/view/_IdisWidgetBase',
    'leaflet',
    '../config'
], function (module, declare, lang, dom, template, QUERY, Locator, GeoService,
    _IdisWidgetBase, leaflet, config) {
    // GeoServiceを初期化
    var _geoService = null;

    /* globals LLtoUSNG */
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase, {
        // テンプレート文字列
        templateString: template,

        _timer: null,

        _latlngMessage: null,

        _address: null,

        // 地図が動いてからgeocodingを走らせて住所を取得するまでの時間
        TIMER_FOR_GEOCORDING: 2,

        // ルート要素に付与されるCSSクラス
        baseClass: 'map-AddressPanel',

        // DOMを構築する
        buildRendering: function () {
            this.inherited(arguments);
            this.updateView();
        },

        // DOM構築後に呼ばれる
        postCreate: function () {
            this.inherited(arguments);
            this.own(Locator.on('change', lang.hitch(this, this.updateView)));
        },

        /**
         * 緯度または経度の数値を受け取り、XXX度YY分ZZ.ZZ秒形式の文字列に変換する。
         * @param {number} angle 緯度または経度
         * @returns {string} XXX度YY分ZZ.ZZ秒形式の文字列
         */
        _toJapanese: function (angle) {
            // 度を正数化
            var degree = Math.floor(angle);
            // 元の値から度を引いて60倍する
            var minutesFloat = (angle - degree) * 60;
            // 上述の値を正数化して分とする
            var minutes = Math.floor(minutesFloat);
            // 秒は小数第2桁まで残す
            var seconds = ((minutesFloat - minutes) * 60).toFixed(2);
            // 文字列化して返す
            return [degree, '度', minutes, '分', seconds, '秒'].join('');
        },

        /**
         * 表示状態を更新する。
         */
        updateView: function () {
            // DOMが無ければ何もしない
            if (!this.domNode) {
                return;
            }

            // geocodingされる前に中心点が動いたら、インターバルを消し、二重でgeocodeしないようにする
            if (this._timer) {
                clearInterval(this._timer);
            }

            // URLから緯度経度を取得
            var latlng = Locator.getQuery()[QUERY.LATLNG];
            if (!latlng) {
                // 緯度経度が無ければ何も表示しない
                this.domNode.innerHTML = '';
                return;
            }
            // 緯度経度を緯度と経度に分割
            latlng = latlng.split(',');
            // 緯度
            var lat = parseFloat(latlng[0]);
            // 経度
            var lng = parseFloat(latlng[1]);
            // 経度は-180度から180度までの間に収める
            while (lng > 180) {
                lng -= 360;
            }
            while (lng < -180) {
                lng += 360;
            }
            // UTMを取得（経度は-180 < x < 360の範囲に収めないとエラーになる）
            var utmCode = LLtoUSNG(lat, lng, 3).replace(/\s+/g, '');
            // 小数点以下第6位で丸める
            lat = lat.toFixed(6);
            lng = lng.toFixed(6);

            var message = [
                '緯度: ',
                this._toJapanese(lat),
                ', 経度: ',
                this._toJapanese(Math.abs(lng)),
                ' (',
                lat,
                ',',
                lng,
                '), UTMポイント: ',
                utmCode
            ].join('');

            // DOMの内容を更新する
            this.domNode.innerHTML = message + '<br>住所: ';
            // 緯度経度を示す一文を、あとでgeocodeした住所をinnerHTMLで画面に描画する時のために保管しておく
            this._latlngMessage = message;
            this._timer = setTimeout(lang.hitch(this, 'setAddress'), this.TIMER_FOR_GEOCORDING * 1000);
        },

        /**
         * 住所をgeocode・描画し、表示状態を更新する。
         */
        setAddress: function () {
            this.latLngToAddress().then(lang.hitch(this, function () {
                this.domNode.innerHTML = this._latlngMessage + '<br>住所: ' + this._address;
            }));
        },

        /**
         * 緯度経度情報から住所を取得する。
         */
        latLngToAddress: function () {
            // URLから緯度経度を取得
            var latlng = Locator.getQuery()[QUERY.LATLNG];
            if (!latlng) {
                // 緯度経度が無ければ何も表示しない
                return;
            }
            // 緯度経度を緯度と経度に分割
            latlng = latlng.indexOf(',') > 0 ? latlng.split(',') : latlng.split('%2C');

            // 緯度
            var lat = parseFloat(latlng[0]);
            // 経度
            var lng = parseFloat(latlng[1]);

            console.debug('start reverse geocoding');

            if (_geoService === null) {
                _geoService = new GeoService({ url: config.geocode && config.geocode.url });
            }
            return _geoService.reverseGeocode(leaflet.latLng({
                lat: lat,
                lng: lng
            })).then(lang.hitch(this, function (res) {
                this._address = res.address.Address;
                console.debug('end reverse geocoding (address: ' +
                    this._address + ')');
            }), lang.hitch(this, function () {
                this.chain.info('住所を取得できませんでした。', 'エラー');
                console.debug('error: reverse geocoding (lat: ' +
                    lat + ', lng: ' + lng + ')');
            }));

        }
    });
});
