/**
 * 利用者情報 動員種別
 * @module app/user/mobilizationType
 */
define([
    'module',
    'dojo/store/Memory'
    // 以下、変数で受けないモジュール
], function(module, Memory) {
    // シングルトンを返す
    return new Memory({
        idProperty: 'code',
        data: [
            {'code':'','label':'&nbsp;'},
            {'code':'03', 'label':'緊急本部員（本庁）'},
            {'code':'04', 'label':'緊急本部員（阿倍野）'},
            {'code':'11', 'label':'各区緊急本部員（北区）'},
            {'code':'12', 'label':'各区緊急本部員（都島区）'},
            {'code':'13', 'label':'各区緊急本部員（福島区）'},
            {'code':'14', 'label':'各区緊急本部員（此花区）'},
            {'code':'15', 'label':'各区緊急本部員（中央区）'},
            {'code':'16', 'label':'各区緊急本部員（西区）'},
            {'code':'17', 'label':'各区緊急本部員（港区）'},
            {'code':'18', 'label':'各区緊急本部員（大正区）'},
            {'code':'19', 'label':'各区緊急本部員（天王寺区）'},
            {'code':'20', 'label':'各区緊急本部員（浪速区）'},
            {'code':'21', 'label':'各区緊急本部員（西淀川区）'},
            {'code':'22', 'label':'各区緊急本部員（淀川区）'},
            {'code':'23', 'label':'各区緊急本部員（東淀川区）'},
            {'code':'24', 'label':'各区緊急本部員（東成区）'},
            {'code':'25', 'label':'各区緊急本部員（生野区）'},
            {'code':'26', 'label':'各区緊急本部員（旭区）'},
            {'code':'27', 'label':'各区緊急本部員（城東区）'},
            {'code':'28', 'label':'各区緊急本部員（鶴見区）'},
            {'code':'29', 'label':'各区緊急本部員（阿倍野区）'},
            {'code':'30', 'label':'各区緊急本部員（住之江区）'},
            {'code':'31', 'label':'各区緊急本部員（住吉区）'},
            {'code':'32', 'label':'各区緊急本部員（東住吉区）'},
            {'code':'33', 'label':'各区緊急本部員（平野区）'},
            {'code':'34', 'label':'各区緊急本部員（西成区）'},
            {'code':'51', 'label':'直近参集者（北区）'},
            {'code':'52', 'label':'直近参集者（都島区）'},
            {'code':'53', 'label':'直近参集者（福島区）'},
            {'code':'54', 'label':'直近参集者（此花区）'},
            {'code':'55', 'label':'直近参集者（中央区）'},
            {'code':'56', 'label':'直近参集者（西区）'},
            {'code':'57', 'label':'直近参集者（港区）'},
            {'code':'58', 'label':'直近参集者（大正区）'},
            {'code':'59', 'label':'直近参集者（天王寺区）'},
            {'code':'60', 'label':'直近参集者（浪速区）'},
            {'code':'61', 'label':'直近参集者（西淀川区）'},
            {'code':'62', 'label':'直近参集者（淀川区）'},
            {'code':'63', 'label':'直近参集者（東淀川区）'},
            {'code':'64', 'label':'直近参集者（東成区）'},
            {'code':'65', 'label':'直近参集者（生野区）'},
            {'code':'66', 'label':'直近参集者（旭区）'},
            {'code':'67', 'label':'直近参集者（城東区）'},
            {'code':'68', 'label':'直近参集者（鶴見区）'},
            {'code':'69', 'label':'直近参集者（阿倍野区）'},
            {'code':'70', 'label':'直近参集者（住之江区）'},
            {'code':'71', 'label':'直近参集者（住吉区）'},
            {'code':'72', 'label':'直近参集者（東住吉区）'},
            {'code':'73', 'label':'直近参集者（平野区）'},
            {'code':'74', 'label':'直近参集者（西成区）'},
            {'code':'91', 'label':'防潮扉閉鎖要員（１班（安治川突堤2号上屋））'},
            {'code':'92', 'label':'防潮扉閉鎖要員（２班（東田中水防倉庫））'},
            {'code':'93', 'label':'防潮扉閉鎖要員（３班（東田中水防倉庫））'},
            {'code':'94', 'label':'防潮扉閉鎖要員（４班（水上消防署））'},
            {'code':'95', 'label':'防潮扉閉鎖要員（５班（水上消防署））'},
            {'code':'96', 'label':'防潮扉閉鎖要員（６班（港湾防災センター））'},
            {'code':'97', 'label':'防潮扉閉鎖要員（７班（港湾防災センター））'},
            {'code':'98', 'label':'防潮扉閉鎖要員（８班（福崎水門））'},
            {'code':'99', 'label':'防潮扉閉鎖要員（９班（桜島水防用具庫））'},
            {'code':'A1', 'label':'防潮扉閉鎖要員（10班（此花常吉ポンプ場））'},
            {'code':'A2', 'label':'防潮扉閉鎖要員（11班（大正区役所））'},
            {'code':'A3', 'label':'防潮扉閉鎖要員（12班（大正区役所））'},
            {'code':'A4', 'label':'防潮扉閉鎖要員（13班（港湾局鶴町倉庫））'},
            {'code':'A5', 'label':'防潮扉閉鎖要員（14班（平林4号水門））'},
            {'code':'A6', 'label':'防潮扉閉鎖要員（15班（平林4号水門））'},
            {'code':'A7', 'label':'防潮扉閉鎖要員（簡防１班（港湾防災センター））'},
            {'code':'A8', 'label':'防潮扉閉鎖要員（簡防２班（港湾局鶴町倉庫））'}
        ]
    });
});
