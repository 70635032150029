define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/consts/ACL',
    'idis/model/UserInfo',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function (module, declare, lang, ACL, UserInfo, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--user',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            // ボタン
            helper.buttonColumn('detail', '詳細'),
            { field: 'userId', label: 'ユーザID', sortable: true },
            { field: 'roleName', label: 'アカウント種類', sortable: true },
            //            { field: 'municipalityName', label: '市/行政区', sortable: true },
            //            { field: 'regionName', label: '振興局', sortable: true },
            { field: 'organizationName', label: '所属', sortable: true },
            {
                field: 'officialPosition', label: '役職', sortable: true,
                formatter: lang.hitch(this, function (item) {
                    var type = null;
                    switch (item) {
                        case '0':
                            type = '特別・区長';
                            break;
                        case '1':
                            type = '局長級';
                            break;
                        case '2':
                            type = '部長級';
                            break;
                        case '3':
                            type = '課長級';
                            break;
                        case '4':
                            type = '課長代理級';
                            break;
                        case '5':
                            type = '係長級';
                            break;
                        case null:
                            type = '';
                            break;        
                    }
                    return type;
                })
            },
            { field: 'officialPositionName', label: '役職名', sortable: true },
            { field: 'userName', label: 'ユーザ名', sortable: true },
            //            { field: 'representativeName', label: '代表者氏名', sortable: false },
            { field: 'officialMailAddress', label: '庁内メールアドレス', sortable: true },
            {
                field: 'personalMailAddress', label: '個人用メールアドレス', sortable: true,
                formatter: lang.hitch(this, function (item) {
                    // ログインユーザが全体管理者・一般職員および応援職員・自主防災・福祉避難所・施設管理者・下水処理事業者・
                // 道路管理事業者の場合ユーザ以外の場合、個人用メールアドレスをマスク
                    if (item) {
                        if (!(UserInfo.getRoleCd() === ACL.ADMIN_USER || UserInfo.getRoleCd() === ACL.SYSMGR_USER ||
                            UserInfo.getRoleCd() === ACL.IPPAN_USER || UserInfo.getRoleCd() === ACL.AID_USER || 
                            UserInfo.getRoleCd() === ACL.VPD_USER || UserInfo.getRoleCd() === ACL.WEL_USER || 
                            UserInfo.getRoleCd() === ACL.FAC_USER || UserInfo.getRoleCd() === ACL.SEW_USER || 
                            UserInfo.getRoleCd() === ACL.ROA_USER)) {
                            item = '***';
                        }
                    } else {
                        item = '';
                    }
                    return item;
                })
            },
            { field: 'loginTimestamp', label: 'ログイン日時', sortable: true },
            {
                field: 'account', label: '有効 / 無効', sortable: true,
                formatter: lang.hitch(this, function (item) {
                    var type = null;
                    switch (item) {
                        case '0':
                            type = '有効';
                            break;
                        case '1':
                            type = '無効';
                            break;
                    }
                    return type;
                })
            }
        ]
    });
});
