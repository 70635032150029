/**
 * クロノロジ関連のフォーマッター
 * @module app/chronology/ChronologyFormatter
 */
define([
    'module',
    'idis/util/StringUtils'
], function(module, StringUtils) {
    /**
     * 指定されたURLとタイトルからリンク用HTML文字列を生成して返す。
     * @param {string} url リンクのURL
     * @param {string} title リンクのタイトル
     * @returns {string} リンクを表すHTML文字列
     */
    function _toLinkTag(url, title) {
        return '<a href="' + url + '" target="_blank">' + StringUtils.escape(title) + '</a>';
    }

    /**
     * 指定された文字列内のURLをリンク文字列に変換して返す。
     * 単純なURLのほか、[title](URL)形式のMarkdownも受け付ける。
     * URLとして以下の形式の文字列をサポートする（xは「空白文字」、「"」、「\」を除く文字）。
     * - [scheme]://xxx
     * - ./xxx
     * - ../xxx
     * @param {string} value
     * @returns {string} 受け取った文字列のURL部分をリンクに置き換えた文字列
     */
    function _format(value) {
        var values = [];
        var lastIndex = 0;
        // '[title](URL)' または 'URL' にマッチする正規表現
        var regexp = /\[([^\]]+)\]\(((?:[a-z]+:\/|\.?\.)?\/[^\s"\\)]*)\)|((?:[a-z]+:\/|\.?\.)\/[^\s"\\]*)/g;
        // 受け取った文字列に対し先頭から繰り返しマッチングを行い、
        // マッチした範囲をリンクに、マッチしなかった範囲をエスケープ済み文字列にフォーマットする。
        var match = regexp.exec(value);
        while (match) {
            // 前回と今回の結果の隙間
            values.push(StringUtils.escape(value.slice(lastIndex, match.index)));
            // マッチしたリンクからタグ用文字列を生成
            values.push(match[3] ? _toLinkTag(match[3], match[3]) : _toLinkTag(match[2], match[1]));
            // 今回のマッチ終端位置を記録
            lastIndex = regexp.lastIndex;
            // 前回終了地点を開始地点として再実行
            match = regexp.exec(value);
        }

        if(value) {
            // 最後のマッチ終端位置から文字列終端位置まで
            values.push(value.slice(lastIndex));
        }

        var result = '';
        var temp = values.join('');
        if (temp.indexOf('¥n') !== -1) {
            // 改行コード(¥n)を含む場合は、<br>に変換
            result = temp.replace(/¥n/g, '<br>');
        } else if(temp.indexOf('\\n') !== -1) {
            // 改行コード(¥n)を含む場合は、<br>に変換
            result = temp.replace(/(\\r\\n|\\n)/g, '<br>');
        } else {
            result = temp;
        }

        // 一定文字数を超えたら省略して表示
        var MAX_LENGTH = 250;
        if (result.length >= MAX_LENGTH) {
            return result.substring(0, MAX_LENGTH) + '...';
        }
        return result;
    }

    function _getImgUri(item){
        var imageSrc = '';
        if(item.attachFiles && item.attachFiles.length > 0){

            var dataUri = item.attachFiles[0].attachFilePath.replace('out/', 'data/');
            var fileName = item.attachFiles[0].attachFileName;
            // アイコンを定義
            if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
            fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
            fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
            fileName.indexOf('.gif') !== -1 || fileName.indexOf('.bmp') !== -1){
                imageSrc = dataUri;
            } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                imageSrc = 'images/excelicon.png';
            } else if (fileName.indexOf('.pdf') !== -1) {
                imageSrc = 'images/pdficon.png';
            } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                imageSrc = 'images/wordicon.png';
            } else if (fileName.indexOf('.ppt') !== -1 || fileName.indexOf('.pptx') !== -1) {
                imageSrc = 'images/ppticon.png';
            } else if (fileName.indexOf('.zip') !== -1) {
                imageSrc = 'images/zipicon.png';

            } else {
                imageSrc = 'images/othericon.png';
            }
        }
        return imageSrc;
    }

    return {
        format: _format,
        getImgUri: _getImgUri
    };
});
