/**
 * 体制・報告 詳細/更新画面用モジュール。
 * @module app/disasterprevention/view/DisasterPreventionDetailPage
 */
define([
    'module',
    'app/disaster/model/DisasterType',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/DisasterPreventionDetailPage.html',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/view/dialog/InfoDialog',
    './_DisasterPreventionDialogPageBase',
    './model/DisasterPreventStatus',
    './model/DistributionType',
    './model/HqSetFlg',
    './model/HqType',
    'app/config',
    'idis/consts/ACL',
    'app/model/DisasterInfo',
    // 以下、変数から参照されないモジュール
    'dijit/form/ValidationTextBox',
    'dijit/form/NumberTextBox',
    'dijit/form/CheckBox',
    'dijit/form/DateTextBox',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup',
    './DisasterPreventionDeleteDialog',
    'app/view/form/DisasterPreventionMunicOrgSelector'
], function(module, DisasterType, declare, lang, domStyle, template,
    UserInfo, USER_TYPE, InfoDialog,
    _PageBase, PrevStatus, DistributionType, HqSetFlg, HqType, config, ACL, DisasterInfo) {
    /**
     * 災害情報登録画面。
     * @class DisasterPreventionDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/disasterprevention/view/DisasterPreventionDetailPage~DisasterPreventionDetailPage */ {
        // テンプレート文字列
        templateString: template,

        // 更新・訂正どちらのボタンからsubmitされたかを判断する
        _updateFlg: null,

        // 変更前情報
        _lastDetailItem: null,

        // 取消時、配信対象フラグ
        _revertDistributionType: null,

        // 配信状況
        _isUnsentLalert: null,

        /**
         * 体制の機能コード
         */
        functionCd : 'F05002',

        // 本部設置ができない体制
        _statusCd: null,

        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.deleteDialog);
        },

        // DOM要素構築後に呼ばれる
        postCreate: function() {
            // 災害種別の変更時、体制を初期化する
            //this.own(this.disasterTypeSelect.on('change', lang.hitch(this, function() {
            //    this._disasterType = this.disasterTypeSelect.value;
            //    this.setStatus();
            //    this.status.set('value', this._lastDetailItem.status);
            //})));
            // 本部設置状況の変更により、本部設置情報欄の表示有無を切り替える
            this.own(this.hqSetFlg.on('change', lang.hitch(this, function(e){
                //本部名を入力
                this._setDomStyle(e.target.value);
            })));
            // 更新・訂正どちらのボタンからsubmitされたかを判断する
            this.own(this.submitButton.on('click', lang.hitch(this, function() {
                // 更新ボタン → insertされる
                this._updateFlg = 0;
                this.onSubmit();
            })));
            this.own(this.updateButton.on('click', lang.hitch(this, function() {
                // 訂正ボタン → updateされる
                this._updateFlg = 1;
                this.onSubmit();
            })));
            // 取消ボタン
            this.own(this.revertButton.on('click', lang.hitch(this, function() {
                this.revertReport();
            })));
        },

        /**
         * フォームを初期化する。
         */
        initForm: function(obj) {
            this._disasterPrevId = obj.disasterPrevId;
            this._revertDistributionType = obj.revertDistributionType;
            this._isUnsentLalert = obj.unsentLalert;

            // 更新ボタン・訂正ボタン・取消ボタンの権限を反映
            domStyle.set(this.updateButton.domNode, 'display', 'none');
            domStyle.set(this.submitButton.domNode, 'display', 'none');
            domStyle.set(this.revertButton.domNode, 'display', 'none');
            this.municipalitySelector.set('noAllButton',true);
            // 閲覧モード（viewMode=1）でない場合に以下を実施、閲覧モードの場合はボタンを非表示
            if(UserInfo.hasWriteAuthz(this.functionCd) && obj.viewMode !== '1'){
                switch (UserInfo.getUserType()) {
                    case USER_TYPE.MUNICIPALITY:
                        // 政令指定都市対応をいれる
                        if(UserInfo.getMunicipalityCd()===obj.municipalityCd ||
                           (UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd &&
                              config.municInfo.wardList.indexOf(obj.municipalityCd) !== -1) ){
                            domStyle.set(this.updateButton.domNode, 'display', '');
                            domStyle.set(this.submitButton.domNode, 'display', '');
                            domStyle.set(this.revertButton.domNode, 'display', '');
                        }
                        break;
                    case USER_TYPE.REGION:
                        if(UserInfo.getMunicipalityCds().indexOf(obj.municipalityCd)>-1){
                            domStyle.set(this.updateButton.domNode, 'display', '');
                            domStyle.set(this.submitButton.domNode, 'display', '');
                            domStyle.set(this.revertButton.domNode, 'display', '');
                        }
                        break;
                    case USER_TYPE.PREFECTURE:
                        // ログインユーザがシステム管理者・全体管理者・市本部ユーザの場合
                        // 全組織更新可
                        if (UserInfo.getRoleCd() === ACL.ADMIN_USER || UserInfo.getRoleCd() === ACL.SYSMGR_USER ||
                            UserInfo.getRoleCd() === ACL.SHIHONBU_USER ||
                            // それ以外の組織は選択組織が自組織の場合のみ可
                            obj.dpDeptCd === UserInfo.getOrganization().deptCd){
                            domStyle.set(this.updateButton.domNode, 'display', '');
                            domStyle.set(this.submitButton.domNode, 'display', '');
                            domStyle.set(this.revertButton.domNode, 'display', '');
                        break;
                    }
                }
            }
            // 過去報の場合は、権限に関わらず更新ボタンは不可
            if(obj.activeFlg === 0){
                domStyle.set(this.submitButton.domNode, 'display', 'none');
            }

            this.form.set('value', obj);
            // 災害種別
            this.disasterTypeSelect.set('value', obj.disasterType);
            // 報告日時
            this.reportTimestamp.set('value', obj.reportTimestampMS);
            // 本部設置状況欄
            this._setDomStyle(obj.hqSetFlg);
            if(obj.hqSetFlg==='1'){
                domStyle.set(this.hqAbolishedTimestampArea, 'display', 'none');
            }else if(obj.hqSetFlg==='2'){
                domStyle.set(this.hqAbolishedTimestampArea, 'display', '');
            }
            // 設置日時
            this.hqSetTimestamp.set('value', obj.hqSetTimestampMS);
            // 廃止日時
            this.hqAbolishedTimestamp.set('value', obj.hqAbolishedTimestampMS);
            // 組織
            this.organization.set('value', obj.orgCd);
            // 報告者名
            this.reportUserName.innerHTML = obj.reportUserName;
            // 合計人数
            this.sum.innerHTML = (obj.sum === null) ? '-' : obj.sum;
            // 体制登録対象自治体の区市町村コード
            // 大阪市の組織の場合、組織カテゴリコードを設定値とする
            if(obj.municipalityCd === config.municInfo.cityMunicCd && obj.dpDeptCd){
                this.municipalitySelector.set('value', obj.dpDeptCd);
            } else {
                this.municipalitySelector.set('value', obj.municipalityCd);
            }
            // 体制登録対象自治体の組織コード
            this.dpDeptCd = obj.dpDeptCd;

            // 本部種別にモデルをセット
            this.status.set('store', PrevStatus);
            this.setStatus();
            this.status.set('value', obj.status);
            this._statusCd = obj.status;
            this._seqNum = obj.seqNum;

            // 本部体制
            var statusSelectVal = obj.statusName === '4号動員体制' ? '04' :
                obj.statusName === '3号動員体制' ? '03' :    
                obj.statusName === '2号動員体制' ? '02' :
                obj.statusName === '1号動員体制' ? '01' : '';
            this.statusSelect.set('value', statusSelectVal);

            // 報告番号
            this.seqNum.innerHTML = '第' + this._seqNum + '報';

            // 添付ファイルを設定
            if(obj.attachFiles) {
                for(var i = 0; i < obj.attachFiles.length; i++) {
                    this._attachFileList.push(obj.attachFiles[i]);
                    this.showPreview(obj.attachFiles[i], false);
                }
            }
        },

        // 訂正か取消ボタン押下時によばれる
        onSubmit: function() {
            try {
                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }
                // 登録・更新共通のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }
                // 更新固有のバリデーションを実施
                if(!this._validate()) {
                    return false;
                }

                var value = this.form.get('value');

                this._setDomStyle(value.hqSetFlg);

                // 本部情報入力欄非表示の場合、入力値を削除
                if(domStyle.get(this.hqInfNode, 'display')==='none'){
                    delete value.hqSetTimestamp;
                    delete value.hqAbolishedTimestamp;
                    delete value.hqSetPlace;
                }else if(domStyle.get(this.hqAbolishedTimestampArea, 'display')==='none'){
                    delete value.hqAbolishedTimestamp;
                    delete value.hqSetPlace;
                }
                value.disasterPrevId = this._disasterPrevId;
                value.seqNum = this._seqNum;
                    
                // 体制登録対象自治体の区市町村コード
                // ※大阪市の組織は、区市町村コードで区別できないため、
                //   便宜的にidに組織コード、parentIdに区市町村コードを設定している。
                //   idが組織コードの場合は、parentIdの値を区市町村コードに再設定する。
                if(this.municipalitySelector.get('value').substr(0,1) ==='D'){
                    value.municipalityCd = this.getDpInfo(this.municipalitySelector.get('value'),'parentId');
                }

                // 体制登録対象自治体の組織コード
                // municipalitySelectorのtype属性に設定された組織カテゴリコードをセット
                value.dpDeptCd = this.getDpInfo(this.municipalitySelector.get('value'),'type');

                // 報告者所属組織
                value.organization = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
                    UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
                    UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');

                value.attachFileList = this._attachFileList;

                // 訂正ボタンフラグセット
                value.updateFlg = this._updateFlg;
                // 配信状況セット
                value.isUnsentLalert = this._isUnsentLalert;
                //体制状況から自動で入力する値をセット
                var statusCd = this.status.value;
                // value.statusName = PrevStatus.get(statusCd).label;
                value.statusName = value.statusSelect === '04' ? '4号動員体制' :
                    value.statusSelect === '03' ? '3号動員体制' :    
                    value.statusSelect === '02' ? '2号動員体制' :
                    value.statusSelect === '01' ? '1号動員体制' : '';
                delete value.statusSelect;

                // 本部設置フラグ
                value.hqSetFlg = this.hqSetFlg.get('value');

                // 本部名
                var hqName = PrevStatus.get(statusCd).label;

                // 本部種別
                var hqType = PrevStatus.get(statusCd).hqType;

                // 解除の場合の本部名
                if((statusCd==='009999' || statusCd==='019999') &&
                    value.hqSetFlg!== HqSetFlg.NOT_YET){
                    value.hqName = this._lastDetailItem.hqName;
                } else if(value.hqSetFlg===HqSetFlg.RELEASE || value.hqSetFlg===HqSetFlg.ESTABLISH) {
                    value.hqName = hqName;
                } else {
                    value.hqName = null;
                }

                value.hqSetType = hqType;
                // 災害種別名
                value.disasterTypeName = DisasterType.get(this._disasterType).label;
                // 配信フラグ
                value.provideFlg = this._provideFlg;
                value.activeFlg = this._lastDetailItem !== null ? 
                (this._lastDetailItem.activeFlg === 1 ? true : false) : null;
                value.extraDisasterId = DisasterInfo.getExtraDisasterId();
                value.extraDisasterPrevId = this._lastDetailItem.extraDisasterPrevId;
                this.emit('put-send', {value: value});

            } catch (e) {
                console.error(e);
            }
            return false;
        },
        // 対策本部設置状況の値が変更された時の処理
        onChangehqSetFlg: function (evt) {
            // 設置日時の表示を、対策本部設置状況の設置・未設置により表示・非表示を制御
            if(evt.target.value) {
                // if(evt.target.value === '0') {
                //     this.hqSetTimestamp.reset(); // 登録時の値がセットされるのでリセットしない
                // }
                domStyle.set(this.hqInfNode, 'display', evt.target.value !== '0' ? '' : 'none');
                domStyle.set(this.hqAbolishedTimestampArea, 'display', evt.target.value !== '1' ? '' : 'none');
            }
        },
        /**
         * 取消ボタンがクリックされた際に呼ばれる
         */
        revertReport: function() {
            // 削除対象を選択できるのは、最新報かつ第2報以上のときとする
            if(this._lastDetailItem.activeFlg===1 && this._lastDetailItem.seqNum > 1 &&
                !this._lastDetailItem.onlyFlg){
                this.deleteDialog.show();
            }else{
                this.submitRevertReport(false);
            }
        },
        submitRevertReport: function(allDelete){
            this._provideFlg = false;
            if (allDelete) {
                // 一括取消かつ配信歴がある、かつ現在の体制が配信対象である場合、取消を配信
                // 現在の体制が配信対象ではない場合、直前が配信対象だったとしても、既に取消を配信しているため重複してしまう。
                // →大阪市仕様：本部種別に関わらず、配信する（本部種別が災害対策本部以外も配信対象）
                if (!this._isUnsentLalert){ // && this._lastDetailItem.hqType === HqType.RESPONSE_HQ) {
                    this._provideFlg = true;
                }
            } else {
                // 最新報かつ直前報が配信対象なら配信対象（大阪市の体制情報であることを再チェック）
                if (this._lastDetailItem.activeFlg===1 &&
                        this._revertDistributionType!==DistributionType.NO_DISTRIBUTE &&
                        this.municipalitySelector.get('value') === config.municInfo.cityMunicCd && !this.dpDeptCd) {
                        this._provideFlg = true;
                }
            }
            var value = {
                disasterPrevId: this._disasterPrevId,
                provideFlg: this._provideFlg,
                allDelete: allDelete,
                version: this.version.get('value'),
                extraDisasterId: DisasterInfo.getExtraDisasterId()
            };
            this.emit('put-revert-send', {value: value});
        },

        /**
         * 詳細ダイヤログ固有のヴァリデーションチェック
         * @param {MouseEvent} evt
         */
        _validate: function() {
            var infoDialogMsg = null;

            // 本部解除の場合
            if (this.hqSetFlg.get('value')===HqSetFlg.RELEASE&&
            domStyle.get(this.hqInfNode, 'display')!=='none') {
                // 本部解除時、設置日時入力必須（体制解除は入力不要）
                if(!this.hqSetTimestamp.validate() ||
                    !this.hqSetTimestamp._date.displayedValue ||
                    !this.hqSetTimestamp._time.displayedValue) {
                    infoDialogMsg = '「設置日時」';
                }
                // 廃止日時入力必須
                if(!this.hqAbolishedTimestamp.validate() ||
                    !this.hqAbolishedTimestamp._date.displayedValue ||
                    !this.hqAbolishedTimestamp._time.displayedValue) {
                        infoDialogMsg = infoDialogMsg===null ? '' : infoDialogMsg + '及び';
                        infoDialogMsg = '「廃止日時」';
                }
            }

            // 両日時入力チェックOKの場合、設置日時＜廃止日時チェック
            if (infoDialogMsg) {
                infoDialogMsg += 'が入力されていません。';
            } else if(this.hqAbolishedTimestamp.validate() &&
                this.hqAbolishedTimestamp._date.displayedValue &&
                this.hqAbolishedTimestamp._time.displayedValue &&
                (this.hqSetTimestamp._date.displayedValue>this.hqAbolishedTimestamp._date.displayedValue||
                (this.hqSetTimestamp._date.displayedValue===this.hqAbolishedTimestamp._date.displayedValue&&
                this.hqSetTimestamp._time.displayedValue>=this.hqAbolishedTimestamp._time.displayedValue))) {
                    if(domStyle.get(this.hqAbolishedTimestampArea, 'display')!=='none'){
                        infoDialogMsg = '本部廃止日時は、本部設置日時以降の日時を入力してください。';
                    }
            }

            // エラーメッセージ表示
            if(infoDialogMsg){
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : infoDialogMsg
                });
                this.infoDialog.show();
                return false;
            }
            return true;
        },

        /**
         * ダイアログを初期化する。（更新時）
         */
        initDialog: function(parent, obj) {

            this.form.reset();
            this.reportTimestamp._date.displayedValue = null;
            this.reportTimestamp._time.displayedValue = null;
            this.hqSetTimestamp._date.displayedValue = null;
            this.hqSetTimestamp._time.displayedValue = null;
            this.hqAbolishedTimestamp._date.displayedValue = null;
            this.hqAbolishedTimestamp._time.displayedValue = null;

            this._lastDetailItem = obj;
            this._disasterType = obj.disasterType;
            this._parent = parent;
            this._attachFileList = [];
            this.initDeleteDialog();
            this.initForm(obj);
        },

        /**
         * 削除対象選択ダイアログを初期化する。
         */
        initDeleteDialog: function(){
            // 取消ボタン押下イベントを受け取る
            this.innerDeleteDialog.on('send-delete-target', lang.hitch(this, function(value) {
                this.deleteDialog.hide();
                this.submitRevertReport(value.allDelete);
            }));
        }
    });
});
