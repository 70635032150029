/**
 * 課新規登録ダイアログ用モジュール。
 * @module app/organization/SectRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/SectRegisterDialog.html',
    './_SectDialogBase',
    // 以下、変数で受けないモジュール
    'app/view/form/MunicipalitySelector',
    'app/view/form/RegionSelector',
    'app/view/form/MunicRegionOrganizationSelector',
    'dijit/form/ValidationTextBox'
], function(module, declare, lang, domStyle, template, _SectDialogBase) {
    /**
     * 組織新規登録ダイアログ
     * @class SectRegisterDialog
     * @extends module:app/organization/_SectDialogBase~_SectDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), _SectDialogBase,
    /** @lends module:app/view/page/SectRegisterDialog~SectRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,

        // /**
        //  * constructor
        //  */
        // constructor: function() {
        // },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 課を新規登録する。
         */
        onSubmit: function() {
            try {
                console.debug('課新規登録ダイアログの[登録]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                value.newSectCd = value.sectCd;
                delete value.sectCd;
                this.emit('register', {value: value});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * ダイアログを初期化する。（新規登録時）
         */
        initDialog: function() {
            this.reset();
            this.organizationSelector.restoreRefresh();
        },

        // 市町村セレクタの値が変更するときの動作
        onMunicipalityCdChanged: function(municipalityCd) {
            this.organizationSelector.setMunicipalityCd(municipalityCd.value);
        },

        // 振興局セレクタの値が変更するときの動作
        onRegionCdChanged: function(regionCd) {
            this.organizationSelector.setRegionCd(regionCd.value);
        }
    });
});
