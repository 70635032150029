/**
 * 災害種別
 * @module app/disaster/model/DisasterType
 */
define([
    'module',
    'dojo/store/Memory'
    // 以下、変数で受けないモジュール
], function(module, Memory) {
    // シングルトンを返す
    return new Memory({
        idProperty: 'code',
        data: [
            {code: '01', label: '風水害'},
            {code: '02', label: '地震'},
            {code: '03', label: '津波'},
            {code: '04', label: '国民保護'},
            {code: '99', label: 'その他'}
        ]
    });
});

