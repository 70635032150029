/**
 * 避難情報機能に共通のカラム定義
 * @module app/evacorder/EvacOrderColumn
 */
define([
    'module',
    'dojo/_base/lang',
    'dojo/when',
    'idis/view/grid/helper',
    'idis/util/DateUtils',
    './Reason',
    './EvacOrderType',
    './EvacOrderUtil'
], function(module, lang, when, helper, DateUtils, Reason, EvacOrderType, EvacOrderUtil) {
    return {


        alertLevel: helper.column('alertLevel', '警戒レベル',{
            formatter: lang.hitch(this, function(value, item){
                var levelObj = EvacOrderUtil.getAlertLevel(item.issueReasonType, item.evacOrderType);
                var isAlertLevel = false;
                if(levelObj && levelObj.level !== 0){
                    isAlertLevel = true;
                }
                return isAlertLevel ? ('レベル' + levelObj.level) : '-';
            }),
            sortable: false
        }),

        evacOrderType: helper.column('evacOrderType', '発令内容',{
            formatter: lang.hitch(this, function(item){
                if (!item) {
                    return '-';
                }else{
                    return EvacOrderType.get(item).name;
                }
            })
        }),

        evacOrderTimestamp: helper.column('evacOrderTimestamp', '発令日時',{
            formatter: lang.hitch(this, function(item){
                if (!item) {
                    return '-';
                }else{
                    return DateUtils.format(item);
                }
            })
        }),

        issueReasonType: helper.column('issueReasonType', '発令理由',{
            formatter: lang.hitch(this, function(item){
                if (!item) {
                    return '-';
                }else{
                    return Reason.get(item).name;
                }
            }),
            sortable: false
        }),

        evacOrderTypeFlg: helper.column('evacOrderTypeFlg', '発令種別'),

        // 更新日時
        updTimestamp: helper.column('updTimestamp', '更新日時', {
            formatter: lang.hitch(this, function(item){
                if (!item) {
                    return '-';
                }else{
                    return DateUtils.format(item);
                }
            })
        }),

        /**
         * 基準値列用の列定義オブジェクトを返す。
         * 受け取った値を小数点以下2桁まで表示する。
         * 値が偽値の場合は'-'を表示する（0の場合も'-'を返す点に注意）。
         * @param {string} field フィールド名
         * @param {string} label タイトル行に表示する文字列
         */
        threshold: function(field, label) {
            return helper.column(field, label, {
                formatter: function(value) {
                    return value ? value.toFixed(2) : '-';
                }
            });
        }
    };
});
