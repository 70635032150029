/**
 * 職員選択用入力パーツ
 * @module app/view/form/AddEmployeeSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/string',
    'dijit/form/TextBox',
    'dojo/_base/array',
    'dojo/Deferred',
    'app/view/form/ConvoTreeChecker',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader'
], function(module, declare, lang, dStr, TextBox, array, Deferred, ConvoTreeChecker, CacheStoreModel, CacheTreeRest, Requester,
        DialogChain, Loader) {
    /**
     * 職員選択用パーツ。
     * @class AddEmployeeSelector
     * @extends module:app/view/form/ConvoTreeChecker~ConvoTreeChecker
     */
    return declare(module.id.replace(/\//g, '.'), ConvoTreeChecker,
        /** @lends module:app/view/form/AddEmployeeSelector~AddEmployeeSelector# */ {
        // 選択ダイアログのタイトル
        title: '職員選択',
        // ツリー・モデル
        model: null,

        constructor: function() {
            this.chain = DialogChain.get(this);
            this._initModel();
        },
        // DOMを構築する
        buildRendering: function () {
            this.inherited(arguments);
            // 検索用入力要素を設置
            this.filterInput = new TextBox({
                placeHolder: this.placeHolder,
                maxLength: 128
            });
            this.filterInput.placeAt(this.treeControlNode);
            this.own(this.filterInput.on('keyup', lang.hitch(this, 'onFilterInputChange')));
        },

        _initModel: function() {
            // 設置されるたびにモデルを新規生成する
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/convoMails/groups/employees4Tree'
                })
            });
        },

        /**
         * ツリー選択されている要素をこのウィジェットのvalue値として設定する。
         * 要素が選択されていない場合は何もしない。
         */
        applySelectedItem: function() {
//            var item = this.tree.selectedItem;
//            if (item && item.type === 'MDpEmployee') {
                this.set('value', array.map(this.tree.getCheckedLeafs(), function(item) {
                    if (item.type === 'MDpEmployee') {
                        // 職員の場合、valueにセット
                        return this.tree.model.store.getIdentity(item);
                    } else {
                        // undefined、nullをセットするとエラーとなるので0で回避
                        return 0;
                    }
                }, this));
//            }

            try {
                var self = this;

                // 選択された職員情報の一覧を取得する。
                var promise = Requester.post('/api/convoMails/groups/employee', {
                    data: this.value
                }).then(function(employeeList){
                    // 実際の登録処理はPage側でregisterイベントを監視しているメソッドが実施
                    self.emit('register', {value: employeeList});
                }, function(err) {
                    // 処理失敗時はエラーログを出力し、エラーダイアログを表示
                    console.error(err);
                    self.chain.info('情報の取得に失敗しました。', 'エラー');
                });
                //ローダーの表示
                Loader.wait(promise);
            } catch (e) {
                console.error(e);
            }
        },

        // ダイアログを表示する度にmodelを呼び出すことで施設種別テーブルの変更点をツリーに反映
        showDialog: function() {
            if(this.treeParentNode && this.tree){
                // this.treeNode = this.tree.domNode;
                this.tree.destroyRecursive();
                this.treeParentNode.appendChild(this.treeNode);
                delete this.tree;
                delete this.model;
                this._initModel();
            } else {
                // 2回目の表示以降、treeを削除する際にtreeNodeが削除されてしまうので
                // 初期表示の段階で、ツリー表示時のtreeNode.parentNodeを記憶しておく
                this.treeParentNode = this.treeNode.parentNode;
                // leafだけチェックを外すと、親まで勝手に辿ってくれる。
                array.forEach(this.tree.getCheckedLeafs(), function(leaf){
                    this.tree.setChecked(leaf, false);
                }, this);
            }
            return this.inherited(arguments);
        },

        /**
         * 検索用入力要素入力時。
         */
            onFilterInputChange: function () {
            // 入力値を取得
            var value = dStr.trim(this.filterInput.get('value'));
            if (value) {
                // ラベル名が入力値を含むものでフィルターし、ツリーを展開する
                this.model.setFilter(function (item, isLeaf, model) {
                    return isLeaf && (model.getLabel(item) || '').indexOf(value) !== -1;
                }).then(lang.hitch(this, function () {
                    this.tree.expandAll();
                }));
            } else {
                // 入力が空の場合は全要素を表示してツリーを閉じる
                // this.model.setFilter().then(lang.hitch(this, function () {
                //     this.tree.collapseAll();
                // }));
            }
        }
    });
});

