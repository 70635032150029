/**
 * 配信情報テンプレート一覧画面用モジュール。
 * @module app/sending/SendingTemplatePage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/SendingTemplatePage.html',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    './_SendingPageBase',
    'idis/consts/USER_TYPE',
    'app/model/Region',
    '../config',
    // 以下、変数として受け取らないモジュール
    'dijit/form/ValidationTextBox',
    'idis/view/form/AclButton',
    './SendingTemplateGrid',
    './SendingTemplateRegisterDialog',
    './SendingTemplateDetailDialog',
    'app/view/form/ManageRegionMunicipalitySelector'
], function(module, declare, lang, template, Router, UserInfo, IdisRest, DialogChain,
     _SendingPageBase, USER_TYPE, Region, config) {
        /**
         * 配信情報テンプレート一覧画面用モジュール。
         * @class SendingTemplatePage
         * @extends module:app/sending/_SendingTemplatePageBase~_SendingTemplatePageBase
         */
        return declare(module.id.replace(/\//g, '.'), _SendingPageBase,
        /** @lends module:app/sending/SendingTemplatePage~SendingTemplatePage# */ {
        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page idis-Page--sending',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        _userRoleCd: '',

        _isPrefUser: false,

        _municipalityCd: null,

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'sendingTemplateId',
                target: '/api/sending/template'
            });
            this.chain = DialogChain.get(this);

            // ベースクラスのコンストラクタを呼ぶ
            this.inherited(arguments);
            this._userRoleCd = UserInfo.getRoleCd();
            this._isPrefUser = (UserInfo.getUserType() === USER_TYPE.PREFECTURE) ? true : false;
            this._municipalityCd = this._isPrefUser ? null :
                UserInfo._userInfo.municipalityCd || UserInfo._userInfo.municipalityCds[0];
        },

        /**
         * startup
         */
        startup: function() {
            console.debug('start startup');

            this.inherited(arguments);

            this.initGrid();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // ダイアログを表示
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.sendingTemplateGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showDetailDialog(evt.item);
            }));

            this.sendingTemplateGrid.on('applyButtonClick', lang.hitch(this, function(evt) {
                this.onApplyButtonClick(evt.item);
            }));

            // 保管した検索条件をセットする
            // this.setFilterData();
            if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
                // this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
                this.updateGridQuery();
            } else {
                this.updateGridQuery();
            }
        },

        /**
          * 検索パラメーターの設定
          */
         updateGridQuery: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            var value = this.form.get('value');

            // 市町村
            // var munic = value.municipalityCd;
            var munic = config.municInfo.cityMunicCd;
                        
            this.isRegion(munic).then(lang.hitch(this, function(isRegion){
                if(isRegion){
                    filter = filter.eq('regionCd', munic);
                } else if(munic && munic !== config.municInfo.prefCd){
                    filter = filter.eq('municipalityCd', munic);
                }
            }));

            // キーワード
            if (value.keyword) {
                filter = filter.eq('keyword', value.keyword);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.sendingTemplateGrid.set('collection', collection);
        },

        // 選択したコードがregionCdなのかmunicipalityCdなのか確かめる。
        // regionCdの場合はtrueが帰る
        isRegion: function(cd){
            return Region.store.get(cd).then(lang.hitch(this, function(region){
                return region ? true : false;
			}));
        },

        /**
        * テンプレートを検索する。
        */
        onSubmit: function() {
            console.debug('[検索]ボタンがクリックされました。');
            try {
                if (this.form.isValid()) {
                    this.setFilterStore();
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                } else {
                    console.debug('エラーあり');
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 検索条件を保管する
         */
        setFilterStore: function() {
            //初期化する
            var filterStore = {};
            var value = this.form.get('value');

            // キーワード
            // if (value.municipalityCd) {
            //     filterStore.municipalityCd = value.municipalityCd;
            // }

            // キーワード
            if (value.keyword) {
                filterStore.keyword = value.keyword;
            }
        },

        /**
         * 配信情報一覧画面へ遷移する。
         */
        onSendingPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('sending');
        },

        showRegisterTemplate: function() {
            this.registerDialog.show();
            var page = this.registerDialog.getChildren()[0];
            page.on('update', lang.hitch(this,function() {
                page.leave();
                page.form.reset();
                this.updateGridQuery();
            }));
        },

        /**
         * 詳細ダイアログを表示する。
         * @param {Object} item 参照する行のデータ
         */
        showDetailDialog: function(item) {
            // 最後に開いたデータの項目を保持
            // 配信取消ダイアログでオリジナルの情報を表示するために利用
            this._lastDetailItem = item;
            // this.innerDetailDialog.initDialog(this, item);
            // this.detailDialog.show();
            // ダイアログ中のページウィジェットを取得
            var page = this.detailDialog.getChildren()[0];

            // ダイアログの画面を再表示する
            page.form.reset();
            page.initContent(item);
            this.detailDialog.show();

            page.on('complete', lang.hitch(this,function() {
                page.leave();
                page.form.reset();
                this.updateGridQuery();
            }));
        }
    });
});
