/**
 * お知らせ情報グリッド
 * @module app/convocation/view/group/ConvoGroupRegisterGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/control/Router',
    'idis/consts/ACL',
    'idis/model/UserInfo',
    'idis/view/form/AclButton',
    'idis/view/grid/helper',
    'idis/view/form/Button',
    'dojo/on',
    'dojo/dom-construct',
    './mobilizationType'
], function(module, declare, lang, IdisGrid, Router, ACL, UserInfo, AclButton,
    helper, Button, on, domCon, mobilization) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            return item && !item.pubFlg ? 'is-private' : '';
        },
        /**
         * 各列の定義
         * @type {Object[]}
         */
        collection: this.store,
        columns: [
                // helper.buttonColumn('edit', '編集'),
                {field: 'edit', label:'編集', sortable: false,
                renderCell: function(item){
                    var gridNode = this.grid.domNode;
                    var button = new Button({
                        label: '編集',
                        onClick: function() {
                            on.emit(gridNode, 'editButtonClick', {item: item});
                        }
                    });
                    var content = null;
                    var comment = null;
                    // 訓練モードの場合、編集ボタンを非表示とする
                    if(UserInfo.isTrainingFlg()){
                        comment = domCon.create('div');
                        comment.textContent = '-';
                        content = comment;
                        return content;
                    }
                    if(item.registFlg === '1') {
                        content = button.domNode;
                    } else {
                        comment = domCon.create('div');
                        comment.textContent = '-';
                        content = comment;
                    }
                    return content;
                }},
                {label: '参集対象職員', sortable: false,
                    field: 'employee',
                    children: [
                               helper.column('parentName', '所属', {sortable: false}),
                               helper.column('divisionName', '課', {sortable: false}),
                            //    helper.column('mobilizationCd', '動員区分', {sortable: false}),
                               {field: 'mobilizationCd', label: '動員区分', sortable: false,
                                    formatter: lang.hitch(this, function(item){
                                        if(item === '01') {
                                           return '１号';
                                        } else if (item === '02'){
                                           return '２号';
                                        } else if (item === '03') {
                                           return '３号';
                                        } else if (item === '04') {
                                           return '４号';
                                        } else if (item === '05') {
                                           return '仮４号';
                                        } else {
                                           return '';
                                        }
                                    })
                               },
                                {field: 'mobilizationType', label: '動員種別', sortable: false,
                                    formatter: lang.hitch(this, function(item){
                                        if (item) {
                                            var Type = '';
                                            for (let i = 0; i < mobilization.data.length; i++) {
                                                if (item === mobilization.data[i].code) {
                                                    Type = mobilization.data[i].label;
                                                    break;
                                                } else {
                                                    continue;
                                                }
                                            }
                                            return Type;
                                        } else {
                                            return '';
                                        }
                                    })
                                },
                               //helper.column('employeeCd', '職員番号', {sortable: true}),
                               helper.column('employeeCd', 'ユーザID', {sortable: false}),
                               helper.column('name', '氏名', {sortable: false}),
                            //    {field: 'phoneNum', label: '電話番号',
                            //        formatter: lang.hitch(this, function(item){
                            //            return item;
                            //        })
                            //    },
                               helper.column('emailAddress01', '庁内メールアドレス', {sortable: false})
                            //    helper.column('emailAddress02', 'メールアドレス2', {sortable: false}),
                            //    helper.column('note', '備考', {sortable: false})
                    ]
                },
                {field: 'registFlg', label: '登録種別', sortable: false,
                formatter: lang.hitch(this, function(item){
                    if(item === '1') {
                        return '手動登録';
                    } else {
                        return '自動登録';
                    }
                })
            }
        ]
    });
});
