/**
 * Lアラートお知らせ配信ダイアログ。
 * @module app/provide/ProvideShelterRegisterDialog
 */
define([
    'module',
    'dojo',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-style',
    'dojo/promise/all',
    'dojo/topic',
    'dojo/text!./templates/ProvideShelterRegisterDialog.html',
    'app/provide/_ProvideDialogBase',
    'app/model/DisasterInfo',
    'dstore/Memory',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/model/UserInfo',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/dialog/InfoDialog',
    'app/config',
    'dijit/form/Textarea',
    'idis/view/form/WordCountTextarea',
    'app/provide/ProvideUtil',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'dijit/form/SimpleTextarea',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/DateTimeInput',
    'idis/view/form/Button',
    'app/view/form/MunicipalitySelector',
    'app/view/form/DisasterSelector',
    './form/DistributionTypeInput',
    // './ProvideUrgentMailRegisterDialog',
    './ProvideShelterGrid',
    'idis/view/form/WordCountTextarea'
], function(module, dojo, declare, lang, array, domStyle, all, topic, template, _ProvideDialogBase,
    DisasterInfo, Memory, IdisRest, Loader, UserInfo, Router, Requester, InfoDialog, config,
    Textarea, WordCountTextarea, ProvideUtil) {
    /**
     * Lアラートお知らせ登録ダイアログ画面。
     * @class ProvideShelterDialogRegisterDialog
     * @extends module:app/provide/_ProvideDialogBase~_ProvideDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), _ProvideDialogBase,
        /** @lends module:app/provide/_ProvideDialogBase~_ProvideDialogBase# */ {
        // テンプレート文字列
        templateString: template,

        // ダイアログを開く際に設定する。
        contentType: null,

        // 登録用ストア
        registerStore: null,

        // 災害ID
        _disasterId: null,

        // 施設ID
        _facilityId: null,

        // 引数
        _args: null,

        // 配信日時
        sendTimestamp: null,

        // 配信日時
        _sendTimestamp: null,

        // 避難所グリッド用のストア
        gridStore: null,

        // Lアラート配信時に発信元として設定する市町コード
        _municipalityCd: null,

        // 緊急速報メール利用有無判別フラグ
        _urgentMailFlg: false,

        // 緊急速報メール配信の判断に用いる。
        // 避難情報が自主避難より格上であり、格上げまたは範囲拡大の場合trueとする。
        _isUrgentMailTarget: false,

        // 前回の配信履歴
        _lalertSendHistId: null,

        _isCorrectOrCancel: false,

        _isShelterOnly: false,

        // 緊急速報メールのテンプレート分
        _emergencyBodyTextTemplate: null,

        // ツイッターのテンプレート文
        _twitterBodyTextTemplate: null,

        // 緊急速報メールのテンプレート分
        _emergencyPushBodyTextTemplate: null,

        // 開設避難所
        _openShelters:null,

        // 閉鎖避難所
        _closeShelters:null,

        _twitterFlg: false,

        TWITTER_MAX_LENGTH: 140,

        TWITTER_BODY_ID_LENGTH: 11,

        EMERGENCY_MAIL_MAX_LENGTH: 180,

        EMERGENCY_MAIL_BODY_ID_LENGTH: 17,

        EMERGENCY_PUSH_SUBJECT_MAX_LENGTH: 15,

        EMERGENCY_PUSH_BODY_MAX_LENGTH: 200,

        EMERGENCY_MAIL_LIMIT: 3,

        EMERGENCY_MAIL_SUBJECT: '【大阪市】避難所開設情報',
        EMERGENCY_PUSH_SUBJECT: '【大阪市】避難所開設情報',

        // 緊急速報メール文字数カウント用
        _emergencyMailNum: null,

        // ツイッター文字数カウント用
        _tweetNum: null,

        // 本文設置用災害名
        _disasterName: null,

        // ポータルURL
        PORTAL_URL: 'https://bousai.city.osaka.jp',

        // ベースクラスのコンストラクタでStoreを生成。
        constructor: function() {
            this.shelterStore = new Memory();
            // 登録用ストア
            this.registerStore = new IdisRest({
                target: '/api/lalert/send'
            });
            // 管理対象市町リストの一つ目を設定する
            var municipalityCds = UserInfo.getMunicipalityCds();
            this._municipalityCd = municipalityCds[0];
            this._disasterId =  DisasterInfo.getDisasterId();

            // 災害名を取得する
            Requester.get('/api/disasters/' + this._disasterId, {
            }).then(lang.hitch(this, function(disasterObj) {
                this._disasterName = disasterObj.disasterName;
            }), lang.hitch(this, function() {
                // error
                this._disasterName = '';
            }));
        },

        buildRendering: function() {
            this.inherited(arguments);
        },

        resize: function(changeSize, resultSize) {
            this.borderContainer.resize(changeSize, resultSize);
        },

        // ベースクラスをオーバーライド
        // 画面から渡された値で初期表示設定を行う。
        // isCorrectOrCancelは、避難所の訂正/取消が実施された場合にtrueが連携される
        initDialog: function(args, isCorrect, isCancel, isRemoving){
            this._args = args;
            this._isCorrectOrCancel = (isCorrect || isCancel);
            this._sendTimestamp = new Date();
            var obj = args.shelterArgs;
            // 配信種別をセットする。
            this.sendCategoryNode.innerHTML = this.getCategoryName(obj.sendCategory);
            // 市町村コードをセットする。
            this.municipalityCd._setValueAttr(this._municipalityCd);

            this._facilityId = args.facilityId;

            // 災害名をセットする。
            this.disasterNameNode.set('value',this._disasterId);
            // 補足情報
            this.complementaryInfo._setValueAttr(this._complementaryInfo ? this._complementaryInfo : '');

            if(!args.shelterArgs.isPrefMailDefault){
                this.prefMailFlg.set('checked', false);
            }


            if (args.evacOrderArgs !== null) {
                // 避難所＋避難情報で出す場合
                this._isShelterOnly = false;

                domStyle.set(this.showShelterButton.domNode, 'display', '');
                domStyle.set(this.sendButton.domNode, 'display', '');
                domStyle.set(this.sendShelterOnlyButton.domNode, 'display', 'none');

                domStyle.set(this.errataDescriptionItemNode, 'display', 'none');
                // domStyle.set(this.publishArea, 'display', 'none');//岡山では不可視化するが、後続案件のため残す
                this.borderContainer.resize();

                domStyle.set(this.publishArea, 'display', '');
                // 緊急速報メール対象市町フラグをセットする。
                return this.setLalertContent(obj, true, isCancel, false);
            } else {
                // 避難所だけで出す場合
                this._isShelterOnly = true;

                if(this._isCorrectOrCancel){
                    domStyle.set(this.errataDescriptionItemNode, 'display', '');
                    // domStyle.set(this.publishArea, 'display', 'none');//岡山では不可視化するが、後続案件のため残す
                    this.borderContainer.resize();
                } else {
                    domStyle.set(this.errataDescriptionItemNode, 'display', 'none');
                    // domStyle.set(this.publishArea, 'display', '');//岡山では不可視化するが、後続案件のため残す
                    this.borderContainer.resize();
                }

                // 新規登録の時は緊急情報配信(防災アプリ)を送信選択可能にする
                /* if (args.shelterArgs.distributionType === '01') {
                    domStyle.set(this.emergencyPushArea, 'display', '');
                }
                domStyle.set(this.publishArea, 'display', ''); */

                // domStyle.set(this.cancelButton.domNode, 'display', 'none');
                domStyle.set(this.showShelterButton.domNode, 'display', 'none');
                domStyle.set(this.sendButton.domNode, 'display', 'none');
                domStyle.set(this.sendShelterOnlyButton.domNode, 'display', '');
                this.borderContainer.resize();
                if(isCancel) {
                    // this.emergencyMailFlg.set('disabled', true);
                    this.twitterFlg.set('value', 'on');
                    // this.yahooFlg.set('value', '');
                } else if(isCorrect) {
                    // this.emergencyMailFlg.set('disabled', false);
                    this.twitterFlg.set('value', 'on');
                    // this.yahooFlg.set('value', '');
                } else {
                    // this.emergencyMailFlg.set('disabled', false);
                    this.twitterFlg.set('value', 'on');
                    // this.yahooFlg.set('value', 'on');
                }

                // 配信設定情報を取得する。
                return this.setLalertContent(obj, false, isCancel, isRemoving);
            }
        },

        setLalertContent: function(obj, isFromEvacOrderPage, isCancel, isRemoving){
            // Lアラート用避難所情報を取得
            var promise = Requester.get('/api/shelters/lalert/', {
                query: {
                    disasterId: this._disasterId,
                    facilityId: this._facilityId
                }
            }).then(lang.hitch(this, function(result) {
                // 対象施設の市町村に応じて市町村コードを改めて設定
                this._municipalityCd = result.municipalityCd;
                this.municipalityCd.set('value', this._municipalityCd);
                // 前回の配信履歴がある場合は保持
                this._lalertSendHistId = result.lalertSendHistId;
                // 更新種別の設定
                var distType = obj.distributionType;
                // 更新種別が新規かつ前回の配信履歴があり、その配信履歴が「取消し」でない場合は更新に切り替える
                if (distType === '01' && (this._lalertSendHistId && result.distributionType !== '03' )) {
                    distType = '02';
                }
                // 大阪市対応：更新種別が更新・訂正(=02)かつ前回の配信履歴がない場合は新規(=01)に切り替える
                if (distType === '02' && !this._lalertSendHistId) {
                    distType = '01';
                    this._isCorrectOrCancel = false;
                    // 訂正・取消理由欄を不可視化
                    domStyle.set(this.errataDescriptionItemNode, 'display', 'none');
                    this.borderContainer.resize();
                }

                // 「避難所取消」の場合は、取消した避難所が市のラスト1件であれば、「取消(=03)」をセットする。。
                // それ以外であれば、「更新・訂正(=02)」の訂正。
                if(isCancel && isRemoving && result.shelters.length === 1 &&
                    result.shelters[0].facilityId === obj.evaqueeData[0].facilityId){
                    // isRemoving()かつ、result.sheltersの長さが1であり、
                    // facilityIdが一致していれば、消した避難所がその市町村のラスト1件であると言える。

                    distType = '03';
                    // 「取り消したい最後の情報」がobj.evaqueeData[0]に入っているはずなので、
                    // それをgrid上に表示する
                    result.shelters.push(obj.evaqueeData[0]);
                }


                this.distributionTypeInput.set('value', distType);
                // 空で初期化
                // this.errataDescriptionInput.set('value', '');
                // 操作した避難所名をセット
                this.errataDescriptionInput.set('value', '【' + obj.evaqueeData[0].evacShelterName + '】');

                var shelters = [];
                var facilityIdList = [];
                var totalEvaqueeNum = 0;
                var totalEvaqueeActNum = 0;
                var totalEvacHouseholdNum = 0;
                var totalEvacHouseholdActNum = 0;

                array.forEach(obj.evaqueeData, function(facility){
                    // 緯度経度は、そのままだと桁が大きいので、小数点4桁まで丸める
                    facility.evacShelterLatitude = Math.round(facility.evacShelterLatitude * 10000) / 10000;
                    facility.evacShelterLongitude = Math.round(facility.evacShelterLongitude * 10000) / 10000;

                    // このあとの処理の為、facilityIdをキーにしてオブジェクトを取得できるマップを作っておく。
                    facilityIdList.push(facility.facilityId);
                    if(!isRemoving || distType === '03'){
                        // isRemoving=true(唯一の報の取消である)場合は、Lアラートとして連携しない。
                        // isRemoving=falseの場合のみpushし、Lアラートに送れるようにする。
                        // ただし、isRemoving=trueでも、その市町最後の避難所情報（＝Lアラート取消報：distType=03）
                        // だった場合は、取消内容を表示しなければならないので、グリッドに表示する。

                        shelters.push(facility);

                        // ついでに避難者情報も足し算する
                        if(facility.evacShelterSort === '開設'){
                            totalEvaqueeNum += facility.evaqueeNum;
                            // totalEvaqueeActNum += facility.evaqueeActNum;
                            totalEvacHouseholdNum += facility.evacHouseholdNum;
                            // totalEvacHouseholdActNum += facility.evacHouseholdActNum;
                        }
                    }
                });

                array.forEach(result.shelters, function(shelter){
                    // 緯度経度は、そのままだと桁が大きいので、小数点4桁まで丸める
                    shelter.evacShelterLatitude = Math.round(shelter.evacShelterLatitude * 10000) / 10000;
                    shelter.evacShelterLongitude = Math.round(shelter.evacShelterLongitude * 10000) / 10000;

                    if(facilityIdList.indexOf(shelter.facilityId) === -1){
                        // 避難所種別・避難所種別で表現しきれない情報をセット
                        var evacShelterType = '避難所';
                        var evacShelterTypeDetail = '';
                        if (shelter.welfareEvacShFlg === '1') {
                            // 福祉避難所の場合、避難所種別で表現しきれない情報を「福祉避難所」にする
                            evacShelterTypeDetail = '福祉避難所';
                        }
                        if (shelter.temporaryEvacShFlg === '1') {
                            // 臨時避難所の場合、避難所種別を「臨時避難所」にする
                            // フラグが複数の場合も、臨時避難所が選択されていれば臨時避難所とみなす
                            evacShelterType = '臨時避難所';
                        }
                        shelter.evacShelterType = evacShelterType;
                        shelter.evacShelterTypeDetail = evacShelterTypeDetail;
                        // 指定区分をセットする
                        var evacSuitableShelter = '指定なし';
                        if(shelter.designatedEvacShFlg === '1') {
                            // 災害時避難所の場合、指定区分を「指定避難所」にする
                            evacSuitableShelter = '指定避難所';
                        }
                        shelter.evacSuitableShelter = evacSuitableShelter;

                        // 指定避難所フラグ・福祉避難所フラグ・臨時避難所フラグは削除する
                        delete shelter.designatedEvacShFlg;
                        delete shelter.welfareEvacShFlg;
                        delete shelter.temporaryEvacShFlg;

                        // これから操作する避難所でない場合、そのままリストに追加する。
                        // (これから操作する避難所の場合は、すでにsheltersに追加されているのでスキップする)
                        shelters.push(shelter);

                        // ついでに避難者情報も足し算する
                        if(shelter.evacShelterSort === '開設'){
                            totalEvaqueeNum += shelter.evaqueeNum;
                            // totalEvaqueeActNum += shelter.evaqueeActNum;
                            totalEvacHouseholdNum += shelter.evacHouseholdNum;
                            // totalEvacHouseholdActNum += shelter.evacHouseholdActNum;
                        }
                    }
                });
                var openShelters = [];
                var closeShelters = [];

                if(obj.evaqueeData.length !== 0) {
                    array.forEach(obj.evaqueeData, function(shelter) {
                        if(obj.distributionType !== '01' && shelter.evacShelterSort === '閉鎖') {
                            closeShelters.push(shelter.evacShelterName);
                        } else if(shelter.evacShelterSort === '開設' &&
                            obj.distributionType !== '01' && isRemoving) {
                            closeShelters.push(shelter.evacShelterName);
                        } else if(shelter.evacShelterSort === '開設' &&
                            obj.distributionType !== '01' && !isRemoving &&
                            (this._lalertSendHistId === null ||this._lalertSendHistId === undefined)) {
                            openShelters.push(shelter.evacShelterName);
                        } else if(shelter.evacShelterSort === '開設' &&
                            obj.distributionType === '01') {
                            openShelters.push(shelter.evacShelterName);
                        } else if(obj.distributionType === '01' && shelter.evacShelterSort === '閉鎖') {
                            closeShelters.push(shelter.evacShelterName);
                        }
                    });
                }
                if(openShelters.length !== 0) {
                    this._openShelters = openShelters.join('、 ');
                } else {
                    this._openShelters = null;
                }

                if(closeShelters.length !== 0) {
                    this._closeShelters = closeShelters.join('、 ');
                } else {
                    this._closeShelters = null;
                }

                this.initGrid(shelters, totalEvaqueeNum, totalEvaqueeActNum,
                                    totalEvacHouseholdNum, totalEvacHouseholdActNum);
                return this.getSendingSetting(this._municipalityCd).then(lang.hitch(this, function(){
                    //domStyle.set(this.emergencyMailArea, 'display', (this._urgentMailFlg ? '' : 'none'));
                    domStyle.set(this.snsArea, 'display', (this._twitterFlg ? '' : 'none'));
                    domStyle.set(this.yahooArea, 'display', (this._yahooFlg ? '' : 'none'));
                    this._emergencyBodyTextTemplate = this.createEmergencyBodyTextTemplate();
                    this._twitterBodyTextTemplate = this.createTwitterBodyTextTemplate();
                    this._emergencyPushBodyTextTemplate = this.createEmergencyPushBodyTextTemplate();
                    this.onClickEmergencyMail();
                    this.onClickTwitter();
                }), lang.hitch(this, function(error){
                    console.error(error);
                    this.chain.info('配信設定情報の取得に失敗しました。外部配信を行いません。', 'エラー', function(){
                        this.chain.hide();
                        Router.moveTo('shelter', {
                            municipalityCd: this._municipalityCd
                        });
                    });
                }));

            }), lang.hitch(this, function(error){
                console.error(error);
                this.chain.infoError(error);
                throw new Error('Lアラート情報の取得に失敗しました。');
            }));
            //ローダーの表示
            return Loader.wait(promise);
        },

        onCancelButtonClick: function(){
            // if(!this._isShelterOnly){
                this.emit('cancel');
            // }
            // ダイアログを閉じる。
            this._closeDialog();
        },

        // 避難所グリッドを初期表示する。
        initGrid: function(data, totalEvaqueeNum, totalEvaqueeActNum, totalEvacHouseholdNum, totalEvacHouseholdActNum) {
            // storeに避難所データをセットする。
            this.shelterStore = new Memory({
                data: array.map(data, function(item, i) {
                    return lang.mixin({id:i}, item);
                })
            });

            this.shelterGrid.set('collection', this.shelterStore);

            // 避難者人数
            this.evaqueeNumNode.innerHTML = totalEvaqueeNum ? totalEvaqueeNum + ' 人' : '0 人';

            // 総避難世帯数
            this.evacHouseholdNumNode.innerHTML = totalEvacHouseholdNum ?
                totalEvacHouseholdNum + ' 世帯' : '0 世帯';

            // 自主避難者人数
            this.evaqueeActNumNode.innerHTML = totalEvaqueeActNum ? totalEvaqueeActNum + ' 人' : '0 人';

            // 自主避難世帯数
            this.evacHouseholdActNumNode.innerHTML = totalEvacHouseholdActNum ?
                totalEvacHouseholdActNum + ' 世帯' : '0 世帯';

        },

        setShelterInfo: function(){
            // 避難所情報を格納する配列
            var items = [];
            // 避難所情報をメモリーストアから取得して配列に格納
            this.shelterStore.fetch().forEach(function(object) {
                // 不要なプロパティーを削除
                delete object.id;
                // 配列に格納
                items.push(object);
            });
            // 新規の場合、訂正・取消理由欄は無効
            // formタグを使うとダイアログ上でグリッドのカラム名が表示されないため使用しない
            var distType = this.distributionTypeInput.get('value');
            this._complementaryInfo = this.complementaryInfo.get('value');
            return {
                disasterId        : this._disasterId,
                bodyText          : ' ',
                municipalityCd    : this.municipalityCd.get('value'),
                complementaryInfo : this.complementaryInfo.get('value'),
                sendCategory      : this.SHELTER_CONTENT_TYPE,
//                sendTimestamp     : new Date(),
                sendTimestamp     : this._sendTimestamp,
                distributionType  : distType,
                errataDescription : (this._isCorrectOrCancel && distType !== '01') ?
                    this.errataDescriptionInput.get('value') : null,
                subject           : '開設避難所発信',
                lalertSendHistId  : this._lalertSendHistId,
                tLalertShelterSendHistList : items
            };
        },

        sendAndRegister: function() {
            // 訂正・取消の場合は「訂正取消理由」が必須なので、チェックする。
            var distType = this.distributionTypeInput.get('value');
            // 訂正対象の避難所名
            var evacShelterName = this._args.shelterArgs.evaqueeData[0].evacShelterName;
            if((this._isCorrectOrCancel && distType !== '01') &&
                (this.errataDescriptionInput.get('value') === null ||
                this.errataDescriptionInput.get('value') === '' || 
                // 訂正理由がプリセット内容と同一の場合も必須入力エラーとする
                this.errataDescriptionInput.get('value') === '【'+evacShelterName+'】')){

                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        // content : '訂正・取消理由が入力されていません'
                        content : '訂正理由が入力されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }

            if (this.emergencyMailFlg.checked){
                if (!ProvideUtil.urgentMailValidate(this.emergencyMailSubject.value)) {
                    InfoDialog.show('', '緊急速報メールの件名に電話番号・URL・メールアドレスが含まれています。');
                    return;
                }
                var emergencyMailElement = this.emergencyMailOuterFrame;
                var emergencyMailElements = emergencyMailElement.getElementsByTagName('textarea');
                for(var j=0; j < emergencyMailElements.length; j++) {
                    if (!ProvideUtil.urgentMailValidate(emergencyMailElements[j].value)) {
                        InfoDialog.show('', '緊急速報メールの'+ (j+1) + '番目の本文に電話番号・URL・メールアドレスが含まれています。');
                        return;                
                    }
                    if (emergencyMailElements[j].value === '') {
                        InfoDialog.show('', '緊急速報メールの'+ (j+1) + '番目の本文が入力されていません');
                        return;                
                    }
                    var emergencyMailText = emergencyMailElements[j].value;
                    var emergencyMailWords = this.EMERGENCY_MAIL_MAX_LENGTH - this.getLen(emergencyMailText);
                    if (emergencyMailWords < 0){
                        InfoDialog.show('','緊急速報メールの'+ (j+1) + '番目の本文が文字数制限を超えています。各記入枠の文字数を制限内に抑えてください。');
                        return;
                    }
                }
            }
            if (this.twitterFlg.checked){
                // テキストエリアの残り文字数計算
                var element = this.twitterOuterFrame;
                var elements = element.getElementsByTagName('textarea');

                // 文字数制限を超えている時にエラーメッセージの表示
                for (var i=0; i < elements.length; i++){
                    var text = elements[i].value;
                    var words =  this.TWITTER_MAX_LENGTH - this.getLen(text);
                    // 配信内容が空の時にエラーメッセージの表示
                    if (elements[i].value === ''){
                        InfoDialog.show('',(i+1) + '番目のTwitterの配信内容が空です。配信内容を記載してください。');
                        return;
                    }
                    if (words < 0){
                        InfoDialog.show('',(i+1) + '番目のTwitterの文字数制限を超えています。各記入枠の文字数を制限内に抑えてください。');
                        return;
                    }
                }
            }

            var emergencyPushSubject = '';
            var emergencyPushBodyText = '';
            if(this.emergencyPushFlg.getValue() === 'on'){
                if (!this.validateEmergencyPushSubject()) {
                    return;
                }
                if (!this.validateEmergencyPushBodyText()) {
                    return;
                }
                emergencyPushSubject = this.emergencyPushSubject.get('value');
                emergencyPushBodyText = this.emergencyPushOuterFrame.getElementsByTagName('textarea')[0].value;
            }

            var value = {
                lalertForm: this.setShelterInfo(),
                emergencyMail: this.setEmergencyMail(),
                prefMailFlg: (this.prefMailFlg.getValue() === 'on') ? true : false,
                urgentMailFlg: (this.emergencyMailFlg.getValue() === 'on') ? true : false,
                twitterFlg: (this.twitterFlg.getValue() === 'on') ? true : false,
                tweetList:this.setTweetList(),
                emergencyPushFlg: (this.emergencyPushFlg.getValue() === 'on') ? true : false,
                emergencyPushSubject: emergencyPushSubject,
                emergencyPushBodyText: emergencyPushBodyText,
                yahooFlg: (this.yahooFlg.getValue() === 'on') ? true: false
            };

            this.emit('send', {
                value: value
            });
            return false;
        },

        closeDialog: function(){
            this._closeDialog();
        },

        checkEvacOrder: function(){
            var value = {
                lalertForm: this.setShelterInfo()
            };
            this.emit('hideshelter', {value: value});
            this._closeDialog();
        },


        createEmergencyBodyTextTemplate: function() {
            var bodyText = '避難所開設情報\nこちらは大阪市です。\n';
            bodyText += this._sendTimestamp.toLocaleDateString() + ' ' + this._sendTimestamp.toLocaleTimeString() +
            '時点の避難所情報をお知らせします。\n';
            if(this._openShelters !== null) {
                bodyText += '新たに開設:' + this._openShelters + '\n';
            }
            if(this._closeShelters !== null) {
                bodyText += '新たに閉鎖:' + this._closeShelters + '\n';
            }
            bodyText += '\n' + '現在開設中の避難所情報につきましては、' +
                '大阪市の防災情報サイト（おおさか防災ポータル）をご確認下さい';
            return bodyText;
        },

        createTwitterBodyTextTemplate: function() {
            var bodyText = 'こちらは大阪市です。\n';
            bodyText += this._sendTimestamp.toLocaleDateString() + ' ' + this._sendTimestamp.toLocaleTimeString() +
            '時点の避難所情報をお知らせします。\n';
            if(this._openShelters !== null) {
                bodyText += '新たに開設:' + this._openShelters + '\n';
            }
            if(this._closeShelters !== null) {
                bodyText += '新たに閉鎖:' + this._closeShelters + '\n';
            }
            bodyText += '\n' + '現在開設中の避難所情報につきましては、' +
                '下記ポータルサイトをご確認下さい\n';
            bodyText += '大阪市防災ポータル URL: ' + this.PORTAL_URL;
            bodyText += '\n';
            bodyText += '\#避難所開設 \#' + this._disasterName;

            return bodyText;
        }, 
        createEmergencyPushBodyTextTemplate: function() {
            var bodyText = '避難所開設情報\nこちらは大阪市です。\n';
            bodyText += this._sendTimestamp.toLocaleDateString() + ' ' + this._sendTimestamp.toLocaleTimeString() +
            '時点の避難所情報をお知らせします。\n';
            if(this._openShelters !== null) {
                bodyText += '新たに開設:' + this._openShelters + '\n';
            }
            if(this._closeShelters !== null) {
                bodyText += '新たに閉鎖:' + this._closeShelters + '\n';
            }
            bodyText += '\n' + '現在開設中の避難所情報につきましては、' +
                '大阪市の防災情報サイト（おおさか防災ポータル）をご確認下さい';
            return bodyText;
        },
        setEmergencyMail: function() {
            // 緊急速報メール
            var data;
            if (this.emergencyMailFlg.checked) {
                var emergencyMailData = this.emergencyMailOuterFrame.getElementsByTagName('textarea');
                var emergencyMailList = [];
                array.forEach (emergencyMailData, function(data) {
                    emergencyMailList.push(data.value);
                });
                data = {urgentMailFlg: '1',
                        emergencyMailMunicipalityCd: config.municInfo.cityMunicCd,
                        emergencyMailSubject: this.emergencyMailSubject.get('value'),
                        emergencyMailBodyList: emergencyMailList
                        };
            } else {
                data = {urgentMailFlg: '0'};
            }
            return data;
        }, 
        setTweetList: function() {
            // Twitter
            if (this.twitterFlg.checked) {
                var tweetData = this.twitterOuterFrame.getElementsByTagName('textarea');
                var tweetList = [];
                array.forEach (tweetData, function(data) {
                    tweetList.push(data.value);
                });
                return tweetList;
            } else {
                return null;
            }
        },

        // 本文の文字数に従ってTwitter用の入力項目を作成
        createEmergencyMailElements: function(oneEmergencyText, i) {
            // 枠を追加
            var div = dojo.create('div');
            div.name = 'addedEmergencyMail';

            // テキストボックスを作成
            var addedText = new WordCountTextarea({
                name: 'emergencyMailBody' + i,
                style: 'width: 80%; height:5em;',
                constraints:{max:180},
                value: oneEmergencyText.bodyText
            });
            addedText.startup();
            // 残り文字数を作成
            var rightWords = dojo.create('div');
            var rightWordsStr = oneEmergencyText.bodyText !== undefined ? oneEmergencyText.bodyText : '';
            var rightWordLength = this.EMERGENCY_MAIL_MAX_LENGTH - this.getLen(rightWordsStr);
            rightWords.innerHTML = '残り' + rightWordLength + '文字';
            rightWords.id = 'emergencyMailRight' + i;
            rightWords.name = 'emergencyMailRight';
            rightWords.align = 'left';

            // 新規追加ではなく、既存のレコードであるか判断するための枠を追加
            var idDiv = dojo.create('input');
            idDiv.type = 'hidden';
            idDiv.name = 'emergencyMailId';
            idDiv.id = 'emergencyMailId' + i;
            var lalertSendHistId = (oneEmergencyText.lalertSendHistId !== undefined && 
                oneEmergencyText.lalertSendHistId !== null) ? oneEmergencyText.lalertSendHistId :
                null;
            idDiv.value = lalertSendHistId;

            // 新規作成した要素を親に加える
            this.emergencyMailOuterFrame.appendChild(div);
            div.appendChild(addedText.domNode);
            div.appendChild(rightWords);
            div.appendChild(idDiv);
        },

        /**
         * 緊急速報メールを追加する
         */
        addEmergencyMail: function(oneEmergencyMailText, i) {
            // +ボタンで追加した場合
            if (oneEmergencyMailText.type === 'click') {
                oneEmergencyMailText = '';
                oneEmergencyMailText.bodyText = '';
                this._emergencyMailNum += 1;
                i = this._emergencyMailNum;
            }
            this.createEmergencyMailElements(oneEmergencyMailText, i);

            // 緊急速報メールを分割できるのは3つまでとする
            if(i === this.EMERGENCY_MAIL_LIMIT) {
                domStyle.set(this.addEmergencyMailButton.domNode, 'display', 'none');
            }
        },

        // 緊急速報メール枠を削除
        deleteEmergencyMail: function(){
            if (this._emergencyMailNum > 1) {
                var element = this.emergencyMailOuterFrame;
                element.removeChild(element.lastChild);
                this._emergencyMailNum -= 1;
            }
            // 「+」ボタンが隠れている状態でメール枠を削除した場合、「+」ボタンを再表示する
            if(this._emergencyMailNum < this.EMERGENCY_MAIL_LIMIT &&
                this.addEmergencyMailButton.domNode.style.display === 'none') {
                domStyle.set(this.addEmergencyMailButton.domNode, 'display', '');
            }
        },

        // 緊急速報メール残り文字数を表示
        showEmergencyMailLength: function(evt) {
            // 残り文字数計算
            var text = evt.srcElement.value;
            var words =  this.EMERGENCY_MAIL_MAX_LENGTH - this.getLen(text);
            evt.srcElement.nextElementSibling.innerHTML = '残り' + words+ '文字';
        },

        // 文字を1文字入力するたびに緊急速報メールの残り文字数を更新
        onEmergencyMailBodyTextChange: function(evt){
            this.showEmergencyMailLength(evt);
        },

        onClickEmergencyMail: function(){
            var isChecked = false;

            // 緊急速報メール
            if (this.emergencyMailFlg.checked && domStyle.get(this.emergencyMailArea, 'display') !== 'none') {
                isChecked = true;
                domStyle.set(this.inputEmergencyMail, 'display', 'block');

                // 緊急速報メール削除
                var emergencyElement = this.emergencyMailOuterFrame;
                while (emergencyElement.firstChild) {
                    emergencyElement.removeChild(emergencyElement.firstChild);
                }


                this.emergencyMailSubject.set('value', this.EMERGENCY_MAIL_SUBJECT);
                var emergencyMailBodyText = this._emergencyBodyTextTemplate;
                var emergencyMailCount = this._emergencyMailNum;
                // 緊急速報メール必要枠の計算
                /* this._emergencyMailNum = Math.ceil(this.getLen(emergencyMailBodyText) /
                    this.EMERGENCY_MAIL_MAX_LENGTH);*/
                this._emergencyMailNum = 1;
                if (this._emergencyMailNum > 1) {
                    // 「（続く）」分の文字数を考慮して枠を追加
                    this._emergencyMailNum = Math.ceil((
                        this.getLen(emergencyMailBodyText)) / this.EMERGENCY_MAIL_MAX_LENGTH);
                    if(this._emergencyMailNum > 2) {
                        this._emergencyMailNum = 2;
                    }
//                } else if(this.bodyText.displayedValue.length === 0) {
                } else {
                    this._emergencyMailNum = 1;
                }
                
                var emergencyWords = 0;
                for (var j = 0; j < this._emergencyMailNum; j++) {
                    var oneEmergencyMailContentLength = this.EMERGENCY_MAIL_MAX_LENGTH - 1;
/*                    if (j !== 0) {
                        oneEmergencyMailContentLength -= connectWordLengthFirst;
                    }*/
                    var oneEmergencyMailText = {bodyText:emergencyMailBodyText};
                    if (j !== 0) {
                        oneEmergencyMailText = {bodyText:''};
                    }
//                    var oneEmergencyMailText = {bodyText:''};
                    this.addEmergencyMail(oneEmergencyMailText, j + 1);
                    emergencyWords += oneEmergencyMailContentLength;
                }
                if(emergencyMailCount === this.EMERGENCY_MAIL_LIMIT) {
                    domStyle.set(this.addEmergencyMailButton.domNode, 'display', '');
                }
            } else {
                // 緊急速報メール削除
                var emergencyDisabledElement = this.emergencyMailOuterFrame;
                while (emergencyDisabledElement.firstChild) {
                    emergencyDisabledElement.removeChild(emergencyDisabledElement.firstChild);
                }
                domStyle.set(this.inputEmergencyMail, 'display', 'none');
            }
        },

        /**
         * Twitterの文字数をTwitter基準でカウントする。
         * 日本語は1文字3バイト、英語は1文字1バイトだが
         * バイト数とは関係なく最大文字数は日本語で140字、英語で280文字。
         */
        getLen: function(str){
            var result = 0;
            for(var i=0;i<str.length;i++){
                // 文字コードを取得
                var chr = str.charCodeAt(i);
                // 半角は0.5文字としてカウント
                if((chr >= 0x00 && chr < 0x81) ||
                    (chr === 0xf8f0) ||
                    (chr >= 0xff61 && chr < 0xffa0) ||
                    (chr >= 0xf8f1 && chr < 0xf8f4)){
                    //半角文字の場合は0.5を加算
                    result += 0.5;
                }else{
                    // 全角は1文字としてカウント
                    result += 1;
                }
            }
            //文字数を返す
            return result;
        },

        onClickTwitter: function() {
            var isChecked = false;
            // Twitter
            if (this.twitterFlg.checked && domStyle.get(this.snsArea, 'display') !== 'none') {
                isChecked = true;
                domStyle.set(this.inputTwitter, 'display', 'block');
                var twitterBodyText = this._twitterBodyTextTemplate;

                // tweetを全て削除
                var element = this.twitterOuterFrame;
                while (element.firstChild) {
                    element.removeChild(element.firstChild);
                }
                this._tweetNum = 1;
                this.addTweet(twitterBodyText, this._tweetNum);

                // tweet必要枠の計算
/*                this._tweetNum = Math.ceil(this.getLen(twitterBodyText) / this.TWITTER_MAX_LENGTH);
                var connectWordLengthFirst = 4;
                var connectWordLengthLast = 3;
                var connectAllLength = connectWordLengthFirst + connectWordLengthLast;
                if (this._tweetNum > 1) {
                    // 「（続く）」分の文字数を考慮して枠を追加
                    this._tweetNum = Math.ceil(((this._tweetNum-1) * connectAllLength +
                                                    this.getLen(twitterBodyText)) / this.TWITTER_MAX_LENGTH);
                }
                var words = 0;
                for (var i = 0; i < this._tweetNum; i++) {
                    var oneTweetContentLength = this.TWITTER_MAX_LENGTH - connectWordLengthLast - 1;
                    if (i !== 0) {
                        oneTweetContentLength -= connectWordLengthFirst;
                    }
                    var oneTweetText = twitterBodyText;
                    this.addTweet(oneTweetText, i + 1);
                    words += oneTweetContentLength;
                }*/
            } else {
                // tweetを全て削除
                var disabledElement = this.twitterOuterFrame;
                while (disabledElement.firstChild) {
                    disabledElement.removeChild(disabledElement.firstChild);
                }
                domStyle.set(this.inputTwitter, 'display', 'none');
            }
        },

        addTweet: function(oneTweetText, i) {
            // +ボタンで追加した場合
            if (oneTweetText.type === 'click') {
                oneTweetText = '';
                this._tweetNum += 1;
                i = this._tweetNum;
            } else {
                // 最終ツイート以外に「続く」を入れる
                if (i !== this._tweetNum) {
                    oneTweetText = oneTweetText + '(続く)';
                    this._tweetNum += 1;
                }
            }
            // 最初ツイート以外に「続き」を入れる
            if (i !== 1) {
                oneTweetText = '(続き)' + oneTweetText;
            }
            this.createTweetElements(oneTweetText, i);
        },

        // 本文の文字数に従ってTwitter用の入力項目を作成
        createTweetElements: function(oneTweetText, i) {
            // 枠を追加
            var div = dojo.create('div');
            div.name = 'addedTweet';

            // テキストボックスを作成
            var addedText = new Textarea({
                name: 'twitterBody' + i,
                style: 'height:40px',
                value: oneTweetText
            });

            addedText.startup();

            // 残り文字数を作成
            var leftWords = dojo.create('div');
            var leftWordLength = this.TWITTER_MAX_LENGTH - this.getLen(oneTweetText);
            leftWords.innerHTML = '残り' + leftWordLength + '文字';
            leftWords.id = 'twitterLeft' + i;
            leftWords.name = 'twitterLeft';
            leftWords.align = 'left';

            // 新規作成した要素を親に加える
            this.twitterOuterFrame.appendChild(div);
            div.appendChild(addedText.domNode);
            div.appendChild(leftWords);
        },

        // Tweet枠を削除
        deleteTweet: function(){
            if (this._tweetNum > 1) {
                var element = this.twitterOuterFrame;
                element.removeChild(element.lastChild);
                this._tweetNum -= 1;
            }
        },

        // 文字を1文字入力するたびにTwitterの残り文字数を更新
        onBodyTextChange: function(evt){
            this.showLength(evt);
        },

        // Twitter残り文字数を表示
        showLength: function(evt) {
            // 残り文字数計算
            var text = evt.srcElement.value;
            var words =  this.TWITTER_MAX_LENGTH - this.getLen(text);
            evt.srcElement.nextElementSibling.innerHTML = '残り' + words+ '文字';
        },

        onClickEmergencyPush: function(){
            var isChecked = false;

            // 緊急情報配信(防災アプリ)
            if (this.emergencyPushFlg.checked && domStyle.get(this.emergencyPushArea, 'display') !== 'none') {
                isChecked = true;
                domStyle.set(this.inputEmergencyPush, 'display', 'block');

                // 緊急情報配信削除
                var emergencyElement = this.emergencyPushOuterFrame;
                while (emergencyElement.firstChild) {
                    emergencyElement.removeChild(emergencyElement.firstChild);
                }

                this.emergencyPushSubject.set('value', this.EMERGENCY_PUSH_SUBJECT);
                var emergencyPushBodyText = this._emergencyPushBodyTextTemplate;
                var emergencyPushCount = this._emergencyMailNum;
                this._emergencyMailNum = 1;
                if (this._emergencyMailNum > 1) {
                    // 「（続く）」分の文字数を考慮して枠を追加
                    this._emergencyMailNum = Math.ceil((
                        this.getLen(emergencyPushBodyText)) / this.EMERGENCY_PUSH_BODY_MAX_LENGTH);
                    if(this._emergencyMailNum > 2) {
                        this._emergencyMailNum = 2;
                    }
                } else {
                    this._emergencyMailNum = 1;
                }
                
                var emergencyWords = 0;
                for (var j = 0; j < this._emergencyMailNum; j++) {
                    var oneEmergencyPushContentLength = this.EMERGENCY_PUSH_BODY_MAX_LENGTH - 1;
                    var oneEmergencyPushText = {bodyText:emergencyPushBodyText};
                    if (j !== 0) {
                        oneEmergencyPushText = {bodyText:''};
                    }
                    this.createEmergencyPushElements(oneEmergencyPushText, j + 1);
                    emergencyWords += oneEmergencyPushContentLength;
                }
                if(emergencyPushCount === this.EMERGENCY_MAIL_LIMIT) {
                    domStyle.set(this.addEmergencyMailButton.domNode, 'display', '');
                }
            } else {
                // 緊急情報配信削除
                var emergencyDisabledElement = this.emergencyPushOuterFrame;
                while (emergencyDisabledElement.firstChild) {
                    emergencyDisabledElement.removeChild(emergencyDisabledElement.firstChild);
                }
                domStyle.set(this.inputEmergencyPush, 'display', 'none');
            }
        },

        // 本文の文字数に従って緊急情報配信用の入力項目を作成
        createEmergencyPushElements: function(oneEmergencyText, i) {
            // 枠を追加
            var div = dojo.create('div');
            div.name = 'addedEmergencyPush';

            // テキストボックスを作成
            var addedText = new WordCountTextarea({
                name: 'emergencyPushBody' + i,
                style: 'width: 80%; height:5em;',
                constraints:{max:180},
                value: oneEmergencyText.bodyText
            });
            addedText.startup();
            // 残り文字数を作成
            var rightWords = dojo.create('div');
            var rightWordsStr = oneEmergencyText.bodyText !== undefined ? oneEmergencyText.bodyText : '';
            var rightWordLength = this.EMERGENCY_PUSH_BODY_MAX_LENGTH - this.getLen(rightWordsStr);
            rightWords.innerHTML = '残り' + rightWordLength + '文字';
            rightWords.id = 'emergencyPushRight' + i;
            rightWords.name = 'emergencyPushRight';
            rightWords.align = 'left';

            // 新規追加ではなく、既存のレコードであるか判断するための枠を追加
            var idDiv = dojo.create('input');
            idDiv.type = 'hidden';
            idDiv.name = 'emergencyPushId';
            idDiv.id = 'emergencyPushId' + i;
            var lalertSendHistId = (oneEmergencyText.lalertSendHistId !== undefined && 
                oneEmergencyText.lalertSendHistId !== null) ? oneEmergencyText.lalertSendHistId :
                null;
            idDiv.value = lalertSendHistId;

            // 新規作成した要素を親に加える
            this.emergencyPushOuterFrame.appendChild(div);
            div.appendChild(addedText.domNode);
            div.appendChild(rightWords);
            div.appendChild(idDiv);
        },

        // 緊急情報配信残り文字数を表示
        showEmergencyPushLength: function(evt) {
            // 残り文字数計算
            var text = evt.srcElement.value;
            var words =  this.EMERGENCY_PUSH_BODY_MAX_LENGTH - this.getLen(text);
            evt.srcElement.nextElementSibling.innerHTML = '残り' + words+ '文字';
        },

        // 文字を1文字入力するたびに緊急情報配信の残り文字数を更新
        onEmergencyPushBodyTextChange: function(evt){
            this.showEmergencyPushLength(evt);
        },

        validateEmergencyPushSubject: function(){
            // 文字数制限を超えている時にエラーメッセージの表示
            var text = this.emergencyPushSubject.get('value');
            var words =  this.EMERGENCY_PUSH_SUBJECT_MAX_LENGTH - this.getLen(text);
            // 配信内容が空の時にエラーメッセージの表示
            if (text === ''){
                InfoDialog.show('緊急情報配信(防災アプリ)のタイトルが空です。タイトルを記載してください。');
                return false;
            }
            if (words < 0){
                InfoDialog.show('緊急情報配信(防災アプリ)のタイトルが文字数制限を超えています。タイトルは'+
                    this.EMERGENCY_PUSH_SUBJECT_MAX_LENGTH+'文字以内で入力してください。');
                return false;
            }
            return true;
        },

        validateEmergencyPushBodyText: function(){
            // テキストエリアの残り文字数計算
            var element = this.emergencyPushOuterFrame;
            var elements = element.getElementsByTagName('textarea');

            // 文字数制限を超えている時にエラーメッセージの表示
            for (var i=0; i < elements.length; i++){
                var text = elements[i].value;
                var words =  this.EMERGENCY_PUSH_BODY_MAX_LENGTH - this.getLen(text);
                // 配信内容が空の時にエラーメッセージの表示
                if (elements[i].value === ''){
                    InfoDialog.show('緊急情報配信(防災アプリ)の本文が空です。本文を記載してください。');
                    return false;
                }
                if (words < 0){
                    InfoDialog.show('緊急情報配信(防災アプリ)の本文が文字数制限を超えています。本文は'+
                        this.EMERGENCY_PUSH_BODY_MAX_LENGTH+'文字以内で入力してください。');
                    return false;
                }
            }
            return true;
        }
    });
});
