/**
 * <モジュールの説明>
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/dom-style',
    'dojo/text!./templates/SimulationEventDetailDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/Loader',
    'idis/service/Requester',
    'app/model/DisasterInfo',
    'app/damage/consts/DamageType',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/control/Locator',
    'idis/control/Router',
    'dojo/_base/array',
    'idis/util/FilesUtils',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/TimeInput',
    'dijit/form/RadioButton',
    'dijit/form/Form'
], function(module, declare, lang, Deferred, JSON, domStyle, template, _PageBase, Loader, Requester,
    DisasterInfo, DamageType, UserInfo, USER_TYPE, InfoDialog, DialogChain, Locator, Router, array) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page idis-Page--simulation',

        store: null,

        _eventId: null,
        _eventType: null,
        _timing: null,

        constructor: function() {
            // ベースクラスのコンストラクタを呼ぶ
            this.inherited(arguments);
            this.chain = DialogChain.get(this);
        },

        buildRendering: function() {
            this.inherited(arguments);
        },

        initDetailPage: function(item) {
            this._eventId = item.eventId;
            this._eventType = item.eventType;
            var eventTypeName = '';
            switch (this._eventType) {
                case '01':
                    // eventTypeName = 'クロノロジ';
                    eventTypeName = '時系列情報';
                    break;
                case '02':
                    eventTypeName = '避難情報';
                    break;
                case '03':
                    eventTypeName = '避難所情報';
                    break;
                case '04':
                    eventTypeName = '被害情報';
                    break;
                default:

            }
            this.eventType.innerHTML = eventTypeName;
            var mm,hh;
            if(typeof(item.timing) === 'number'){
                // ss = ('00' + (item.timing % 60)).slice(-2);
                mm = ('00' + parseInt((item.timing / 60), 10) % 60).slice(-2);
                hh = ('00' + parseInt(item.timing / 60 / 60, 10) % 24).slice(-2);
            } else {
                // ss = item.timing.getSeconds();
                mm = item.timing.getMinutes();
                hh = item.timing.getHours();
            }
            var time = new Date('','','',hh,mm);
            time.setHours(hh);
            time.setMinutes(mm);
            // time.setSeconds(ss);
            item.timing = time;
            this.form.set('value', item);

            this.getEventInfo();
        },

        /**
         * イベントごとの詳細情報を取得
         */
        getEventInfo: function() {
            var promise = Requester.get('/data/simulation/' + this._eventId + '.json')
            .then(lang.hitch(this, function(item) {
                var form = null;
                var conversion = null;
                switch (this._eventType) {
                    case '01':
                        form = item.chronologyPostForm;
                        conversion = item.chronologyConversionForm;
                        this.setChroValue(form, conversion);
                        break;
                    case '02':
                        form = item.evacOrderPostForm;
                        conversion = item.evacOrderConversionForm;
                        this.setEvacValue(form, conversion);
                        break;
                    case '03':
                        form = item.shelterPostForm;
                        conversion = item.shelterConversionForm;
                        this.setShelValue(form, conversion);
                        break;
                    case '04':
                        form = item.damageReportPostForm;
                        conversion = item.damageReportConversionForm;
                        this.setDmgValue(form, conversion);
                        break;
                }
            }), lang.hitch(this, function(error) {
                this.chain.infoError(error);
            }));
            // ローダーの表示
            Loader.wait(promise);
        },

        /**
         * クロノロジ情報を詳細ダイアログに設定
         */
        setChroValue: function(form, conversion) {
            domStyle.set(this.chroInfo, 'display', '');
            domStyle.set(this.evacInfo, 'display', 'none');
            domStyle.set(this.shelInfo, 'display', 'none');
            domStyle.set(this.dmgInfo, 'display', 'none');
            this.chronologyType.innerHTML = conversion.chronologyTypeName;
            var str = '';
            array.forEach(form.chronologyShares, function(chronologyShare){
                if(chronologyShare.senderFlg === '0'){
                    str += chronologyShare.shareName + ' ';
                }
            });
            this.urgencyType.innerHTML = conversion.urgencyTypeName;
            this.shareName.innerHTML = str;
            this.chroContent.innerHTML = form.content;
            this.hldStatus.innerHTML = conversion.hldStatusName;
        },

        /**
         * 避難情報を詳細ダイアログに設定
         */
        setEvacValue: function(form, conversion) {
            domStyle.set(this.chroInfo, 'display', 'none');
            domStyle.set(this.evacInfo, 'display', '');
            domStyle.set(this.shelInfo, 'display', 'none');
            domStyle.set(this.dmgInfo, 'display', 'none');
            var date = new Date(form.evacOrderTimestamp);
            this.evacOrderTimestamp.innerHTML =
                date.getFullYear() + '/' +
                date.getMonth() + '/' +
                date.getDate() + ' ' +
                date.getHours() + ':' +
                date.getMinutes();
            var districtNames = '';
            array.forEach(form.districtList, function(item){
                districtNames += item.districtFreeName + ' ';
            });
            if(districtNames.length > 50){
                districtNames = districtNames.substr(0, 50) + '…';
            }
            this.evacOrderDistrictCds.innerHTML = districtNames;
            this.evacMunicipalityName.innerHTML = conversion.municipalityName;
            this.issueReasonType.innerHTML = conversion.issueReasonTypeName;
            this.evacOrderType.innerHTML = conversion.evacOrderTypeName;
        },

        /**
         * 避難所情報を詳細ダイアログに設定
         */
        setShelValue: function(form, conversion) {
            domStyle.set(this.chroInfo, 'display', 'none');
            domStyle.set(this.evacInfo, 'display', 'none');
            domStyle.set(this.shelInfo, 'display', '');
            domStyle.set(this.dmgInfo, 'display', 'none');
            this.facilityName.innerHTML = conversion.facilityName;
            this.status.innerHTML = conversion.statusName;
        },

        /**
         * 被害情報を詳細ダイアログに設定
         */
        setDmgValue: function(form, conversion) {
            domStyle.set(this.chroInfo, 'display', 'none');
            domStyle.set(this.evacInfo, 'display', 'none');
            domStyle.set(this.shelInfo, 'display', 'none');
            domStyle.set(this.dmgInfo, 'display', '');
            this.dmgMunicipalityName.innerHTML = conversion.municipalityName;
            this.damageTypeList.innerHTML = conversion.damageTypeNameList;
            var date = new Date(form.reportTimestamp);
            this.reportTimestamp.innerHTML =
                date.getFullYear() + '/' +
                date.getMonth() + '/' +
                date.getDate() + ' ' +
                date.getHours() + ':' +
                date.getMinutes();
            this.dmgUrgencyType.innerHTML = conversion.urgencyTypeName;
        },

        /**
         * イベント更新ボタン押下
         */
        onUpdButtonClick : function() {
            var timing = this.timing.displayedValue;
            if(!timing) {
                console.debug('発生タイミングが選択されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '発生タイミングが選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }
            if(!this.content.get('value')) {
                console.debug('内容が入力されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '内容が入力されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return false;
            }

            // timingをHH:ss:mmで取得
            var formatTiming = timing.substr(0, 8).split(':');
            var timingToSec =
                parseInt(formatTiming[0],10) * 60 * 60 +
                parseInt(formatTiming[1], 10) * 60;
                // parseInt(formatTiming[2], 10);
            // 登録処理を行うPage.js側にformの情報を渡す
            this.emit('update', {
                eventId: this._eventId,
                timing: timingToSec,
                content: this.content.value});
        },

        onChangeButtonClick: function() {
            // 該当するイベント種別の登録画面へ遷移する
            var eventUrl = null;
            switch (this._eventType) {
                case '01':
                    eventUrl = 'chronology/detail';
                    break;
                case '02':
                    eventUrl = 'evacorder/detail';
                    break;
                case '03':
                    eventUrl = 'shelter/detail';
                    break;
                case '04':
                    eventUrl = 'report/detail';
                    break;
            }
            Router.moveTo(eventUrl, {
                simulateFlg: true,
                scenarioId: Locator.getQuery().scenarioId,
                eventId: this._eventId});
            this.getParent().hide();
        },

        /**
         * キャンセルボタン押下
         */
        onCancel:function(){
            this.getParent().hide();
        }
    });
});
