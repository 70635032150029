/**
 * 被害情報 詳細 閲覧画面用モジュール。
 * @module app/damage/damageReportDetail/DamageReportDetailViewPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/DamageReportDetailViewPage.html',
    'idis/control/Router',
    'app/damage/damageReportDetail/DamageReportDetailPage',
    // 以下、変数として受け取らないモジュール
    'app/damage/damageReportAction/DamageReportActionViewForm',
    'app/damage/damageReportNumber/DamageReportNumberViewDialog',
], function (module, declare, template, Router, DamageReportDetailPage) {

    /**
     * 被害概要 詳細 閲覧ページ
     * @class DamageReportDetailViewPage
     * @extends module:app/damage/damageReportDetail/DamageReportDetailPage#~DamageReportDetailPage
     */
    return declare(module.id.replace(/\//g, '.'), DamageReportDetailPage,
        /** @lends module:app/damage/damageReportDetail/DamageReportDetailViewPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--damage',

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
            },

            // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
            constructor: function () {
            },

            startup: function () {
                this.inherited(arguments);
            },

            /**
             * 被害状況概況一覧画面へ遷移する。
             */
            onDamageReportAdminViewPageLinkClick: function (evt) {
                this._removeEvents();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('report/admin/view');
            },

            /**
             * 被害状況一覧画面へ遷移する。
             */
            onDamageReportViewPageLinkClick: function (evt) {
                this._removeEvents();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('report/view');
            },


        });
});

