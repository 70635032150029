/**
 * 監視画面用モジュール
 * @module app/view/page/MonitoringPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/aspect',
    'dojo/debounce',
    'dojo/date/locale',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-geometry',
    'dojo/dom-style',
    'dojo/promise/all',
    'dojo/text!./templates/MonitoringPage.html',
    'dojo/on',
    'dojo/json',
    'dojo/query',
    'dojo/topic',
    'dojo/when',
    'dojo',
    'leaflet',
    'idis/consts/STORAGE_KEY',
    'idis/consts/QUERY',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/consts/USER_TYPE',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/util/storage/LocalStorage',
    'idis/view/dialog/ConfirmDialog',
    'idis/view/dialog/IdisDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/draw/measure/MeasurePane',
    'idis/view/page/_PageBase',
    'idis/view/draw/_DrawUtil',
    'idis/view/form/MunicipalitySelectForm',
    // 'idis/view/form/LandmarkSelectForm',
    'idis/view/maptoimage/MapToImage',
    'app/util/googleMapUtil',
    '../../config',
    'app/map/AutoUpdatePane',
    'app/map/LayerPane',
    'app/map/detail/DetailMap',
    'app/map/baselayer/BaseLayerPane',
    'app/map/legend/LegendPane',
    'app/map/legend/GridLegendPane',
    'app/map/notice/NoticePanel',
    'app/map/print/PrintDialog',
    'app/model/LayerStore',
    'app/monitor/consts/mapViewSelectCfg',
    'app/draw/DrawPanel',
    'app/damage/integrate/DamageReportIntegrator',
    'app/model/DisasterInfo',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/TabContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/StackContainer',
    'polyline-encoded',
    'app/map/AddressPanel',
    // 'app/monitor/WeatherPanel',
    // 'app/monitor/EvacStdLimitOverPanel',
    'app/monitor/EvacRecommendPanel',
    // 'app/monitor/ObservationPanel',
    'app/monitor/EmergencyPanel'
    // 'app/monitor/EarthquakePanel',
    // 'app/monitor/TsunamiPanel',
    // 'app/monitor/VolcanoPanel',
    // 'app/monitor/EvacorderPanel',
    // 'app/monitor/ShelterPanel',
    // 'app/monitor/DamagePanel',
    // 'app/monitor/TrafficPanel',
    // 'app/monitor/MapViewSelectPanel',
    // 'app/monitor/DangerPanel'
], function (module, array, declare, lang,
    aspect, debounce, locale, domClass, domConstruct, domGeometry, domStyle, all, template, on, json, query, topic,
    when, dojo, L, STORAGE_KEY, QUERY, Locator, Router, USER_TYPE, IdisMap, UserInfo, Requester, LocalStorage,
    ConfirmDialog, IdisDialog, DialogChain, MeasurePane, _PageBase, DrawUtil, MunicipalitySelectForm, MapToImage,
    googleMapService, config, AutoUpdatePane, LayerPane, DetailMap, BaseLayerPane, LegendPane, GridLegendPane,
    NoticePanel, PrintDialog, LayerStore, mapViewSelectCfg, DrawPanel, DamageReportIntegrator, DisasterInfo) {
    /**
     * 左側メニュー内の各画面のキー名とコンストラクター関数の対応
     * @type {Object<string,function>}
     */
    var stackClassMap = {
        layer: LayerPane
    };

    return declare(module.id.replace(/\//g, '.'), _PageBase, {
        // 基本クラス
        baseClass: 'idis-Page idis-Page--map',

        // テンプレート文字列
        templateString: template,

        /**
         * 作図ダイアログに対する参照
         * @type {module:app/draw/DrawPanel~DrawPanel}
         * @private
         */
        _drawPanel: null,

        /**
         * 距離計測ダイアログに対する参照
         * @type {module:idis/view/dialog/IdisDialog~IdisDialog}
         * @private
         */
        _measureDialog: null,

        /**
         * 被害統合機能
         */
        _damageReportIntegrator: null,

        /**
         * 背景地図ダイアログに対する参照
         * @type {module:idis/view/dialog/IdisDialog~IdisDialog}
         * @private
         */
        _baseLayerDialog: null,


        /**
         * 市町村選択ダイアログに対する参照
         * @type {module:idis/view/dialog/IdisDialog~IdisDialog}
         * @private
         */
        _municipalitySelectDialog: null,

        /**
         * popupイベントを保持する
         * @private
         */
        _downloadEvts: [],

        /**
         * 被害統合モードの状態を保持する
         */
        _isIntegrateMode: false,

        /**
         * モニター情報のタブを保持する
         */
        _monitorTab: 'weatherTab',

        // 各種Dialogの表示をコントロールするためのPub/Sub
        // すべての作図モードをOffにして、Dialogを閉じる
        DISABLE_DRAW: '/app/draw/DrawPanel::hideAndDisableDraw',
        DISABLE_MEASURE: '/app/view/page/MapPage::disableMeasure',
        DISABLE_PRINT: '/app/view/page/MapPage::disablePrint',
        DISABLE_DAMAGE_INTEGRATE: '/app/damage/integrate/DamageReportIntegrator::disable',
        // forIE anchorにOnclickでPublishをして、msSaveへ情報を渡す。
        DOWNLOAD_4IE: '/app/view/page/MapPage::download4IE',

        buildRendering: function () {
            this.inherited(arguments);
            this._downloadEvts = [];

            this.municipalitySelectForm = new MunicipalitySelectForm({}, this.municipalitySelectForm);
            this.municipalitySelectForm.startup();
            // // 県ユーザーの場合、市町村セレクタは固定で非表示＆ランドマークセレクタを描画
            // if(UserInfo.getUserType()===USER_TYPE.MUNICIPALITY){
            //     domStyle.set(this.municipalitySelectForm.domNode, 'display', 'none');
            //     this.landmarkSelectForm = new LandmarkSelectForm({}, this.landmarkSelectForm);
            //     this.landmarkSelectForm.startup();
            // }
        },

        /**
         * 左側メニュー内の各画面のキー名とインスタンスの対応
         * @type {Object<string,ContentPane>}
         */
        _stackMap: null,

        /**
         * URL更新前のクエリーの内容
         * @type {Object}
         */
        _lastQuery: null,

        /**
         * 自動更新clearTimeout用のID
         * @type {number}
         */
        _autoUpdateTimeoutId: null,

        /**
         * 位置情報
         * @type {Object}
         */
        _latlng: null,

        /**
         * 市町村ユーザー所属市町村位置情報
         */
        _muniLatlng: null,

        /**
         * 地図のベースになるレイヤー
         * @type {String}
         */
        MUNICIPALITY_LAYER_URL: '/data/master/municipalities.geojson',

        _layerControl: null,

        constructor: function () {
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
            this._stackMap = {};
            this._lastQuery = {};
        },

        startup: function () {
            this.inherited(arguments);

            //トップページ表示時には、中心アイコンをデフォルトで表示する
            LocalStorage.set(STORAGE_KEY.CENTER_MARK, '1');

            // 被害統合モードをオフに設定する
            this._isIntegrateMode = false;
            // 地図と隣り合う領域の境界(splitter）を非表示にしておく
            domStyle.set(this.detailPane._splitterWidget.domNode, 'display', 'none');
            this.wrapper.layout();

            // マップを初期化
            this.initMap();

            // 検索ボタン
            this.own(topic.subscribe('idis/view/form/GeocodingForm::geocoded', lang.hitch(this, function (payload) {
                this._latlng = {
                    lat: payload.latlng.lat,
                    lng: payload.latlng.lng
                };
                this.map.setView(this._latlng, 11);
            })));
            // 市町選択ボタン
            this.own(topic.subscribe('idis/view/form/MunicipalitySelectForm::selected',
                lang.hitch(this, function (payload) {
                    // 自動更新時は反応させない
                    if (payload.latlng) {

                        this._latlng = this._muniLatlng = {
                            lat: payload.latlng.lat,
                            lng: payload.latlng.lng
                        };
                        if (this.map) {
                            this.map.setView(this._latlng, payload.zoom);
                        }
                    }
                    this.updateLayer();
                })));
            // // ランドマークセレクター
            // this.own(topic.subscribe('idis/view/form/LandmarkSelectForm::selected',
            //     lang.hitch(this, function(payload) {
            //         // 自動更新時は反応させない
            //         if (payload.latlng) {
            //             // ランドマーク未選択の場合、市町村の位置をセット
            //             this._latlng = payload.latlng.lat ? payload.latlng : this._muniLatlng;
            //             // URLに反映する。
            //             Locator.pushState({
            //                 ll: this._latlng.lat + ',' + this._latlng.lng
            //             });
            //             // 地図にセット
            //             if (this.map) {
            //                 this.map.setView(this._latlng, payload.zoom);
            //             }
            //         }
            //         this.updateLayer();
            // })));
            // 災害切替
            this.own(topic.subscribe('app/view/form/DisasterChanger::updated',
                lang.hitch(this, function () {
                    this.updateLayer();
                })));
            // 被害統合のonSubmitまたはdeactivateがpubされて、被害統合機能がオフになったときに、ボタンの色を変更する。
            topic.subscribe('app/damage/integrate/DamageReportIntegrator::deactivated',
                lang.hitch(this, function () {
                    this.deactivateIntegrateDamageButton();
                }
                ));
            // テロップ設定の反映
            this.own(topic.subscribe(NoticePanel.TOPIC.CHANGE_SETTING, lang.hitch(this, function (payload) {
                domClass.toggle(this.noticeControlNode, 'is-bottom', payload.region === '1');
            })));
            // 詳細表示領域の状態をURLに従って更新
            this.updateDetailPane();
            // URLの変更を監視
            this.own(Locator.on('change', lang.hitch(this, this.onLocationChanged)));
            // 中心位置表示用ボタンの状態をトグル
            //var centerMarkValue = !!LocalStorage.get(STORAGE_KEY.CENTER_MARK);
            //domClass.toggle(this.centerMarkToggleButton, 'is-checked', centerMarkValue);
            // LocalStorageの変更を監視
            this.own(LocalStorage.watch(STORAGE_KEY.CENTER_MARK, function (value) {
                domClass.toggle(this.centerMarkToggleButton, 'is-checked', !!value);
            }, this));
            // 自動更新設定を反映
            if (LocalStorage.get(STORAGE_KEY.AUTO_UPDATE_INTERVAL)) {
                this.reserveAutoUpdate();
            }
            // Web Storageの内容が変わった場合は反映
            this.own(LocalStorage.watch(STORAGE_KEY.AUTO_UPDATE_INTERVAL, this.reserveAutoUpdate, this));
            // 凡例
            this.own(topic.subscribe('app/monitor/ObservationPanel::showGridLegendLayer',
                lang.hitch(this, function () {
                    this.showGridLegendDialog();
                })));
            // モニター状況のタブ変更
            var self = this;
            this.monitorTab.watch('selectedChildWidget', function (name, oval, nval) {
                self._monitorTab = nval.id;
                self.updateLayer();
            });

            // 表示情報に対応するインスタンスを生成
            var key = 'layer';
            var pane = this._stackMap[key] = new stackClassMap[key]();
            this.stackPane.addChild(pane);

            // IE対応
            // イベントを管理する人は各Mapに必要。
            // TODO pub/subの方がよいか？
            if (DrawUtil._isIE()) { DrawUtil._setPopupEvtForMap(this); }

            this.updateLayer();
        },

        // ウィジェットを破棄する
        destroy: function () {
            // 自動更新を解除
            clearTimeout(this._autoUpdateTimeoutId);

            // 中心点表示をなくす
            LocalStorage.set(STORAGE_KEY.CENTER_MARK, '');
            this.inherited(arguments);
        },

        /**
         * マップ・オブジェクトにサイズ変更を通知する。
         */
        notifyResizeToMap: function () {
            // マップにサイズ変更を通知
            this.map.invalidateSize({ pan: false });
        },

        /**
         * URLが変更された際に反応する。
         */
        onLocationChanged: function () {
            // 現在のクエリー情報を取得
            var query = Locator.getQuery();
            // 詳細ペインの状態が変更された場合
            if (query[QUERY.DETAIL_LAYER_ID] !== this._lastQuery[QUERY.DETAIL_LAYER_ID]) {
                this.updateDetailPane();
            }
            if (!query.l && this._lastQuery.l) {
                topic.publish(module.id + '::resetLayer', this._lastQuery);
            }
            // 次の更新に備えてクエリー状態を保存
            this._lastQuery = query;
        },

        /**
         * URLの状態に従い詳細領域を更新する。
         */
        updateDetailPane: function () {
            // URLから詳細表示用レイヤーIDを取得
            var layerId = Locator.getQuery()[QUERY.DETAIL_LAYER_ID];
            when(layerId && LayerStore.get(layerId), lang.hitch(this, function (item) {
                // 古いウィジェットを破棄
                array.forEach(this.detailContent.getChildren(), function (child) {
                    child.destroyRecursive();
                });
                // 表示対象が指定されているなら設置
                if (item) {
                    // 情報カテゴリー・コードに応じたウィジェットを取得
                    var widgetClass = DetailMap[item.infoCategoryCd];
                    // 詳細表示領域へ設置
                    this.detailTitle.innerHTML = item.name;
                    var widget = new widgetClass({ item: item });
                    this.detailContent.addChild(widget);
                }
                // 詳細表示領域の表示を調整
                this.detailPane.resize({ h: (item ? 250 : 0) });
                domStyle.set(this.detailPane._splitterWidget.domNode, 'display', item ? '' : 'none');
                // 再レイアウト
                this.wrapper.layout();
            }));
        },

        /**
         * 詳細領域を隠す。
         */
        hideDetailPane: function () {
            // URLから詳細レイヤーIDを削除する
            Locator.replaceState(QUERY.DETAIL_LAYER_ID, '');
        },

        /**
         * コントロール領域を隠す。
         */
        hideControlPane: function () {
            // コントロールを非表示
            this.controlPane.set('style', { display: 'none' });
            // 再レイアウト
            this.wrapper.layout();
        },

        /**
         * 指定されたキーワードに対応するペインを左側に表示する。
         * @param {string} key 画面のキー名
         * @param {function} classDef 画面を初期化する場合に使用するクラス
         */
        showControlPane: function (key) {
            // キーに対応するインスタンスを確認
            var pane = this._stackMap[key];
            // 既に存在する場合は選択する
            if (pane) {
                this.stackPane.selectChild(pane);
            } else {
                // キーに対応するクラスを引数無しで生成
                pane = this._stackMap[key] = new stackClassMap[key]();
                // selecChildはaddChildの内部で呼ばれる
                this.stackPane.addChild(pane);
            }
            // コントロールを表示
            this.controlPane.set('style', { display: '' });
            // 再レイアウト
            this.wrapper.layout();
        },

        /**
         * 指定されたキーワードに対応するペインを左側で表示切替する。
         */
        toggleControlPane: function (key) {
            // コントロールを表示
            this.showControlPane(key);
        },

        /**
         * 表示情報ボタンクリック時に呼ばれる。
         * 表示情報レイヤーの表示状態を切り替える。
         */
        toggleLayerPane: function () {
            this.toggleControlPane('layer');
        },

        /**
         * 作図ダイアログを表示する
         */
        showDrawPanelDialog: function () {

            // 「広域印刷」「距離計測」を無効化
            this.switchMainMapDialogs('draw');

            if (!this._drawPanel) {
                this._drawPanel = new DrawPanel({
                    map: this.map,
                    'class': 'drawPanel-NonModal',
                    dispType: 'main'
                });
                // 画面が破棄された際に連れて行く
                this.own(this._drawPanel);
            }
            this._drawPanel.show();
        },

        /**
         * 凡例ダイアログを表示する。
         */
        toggleLegendDialog: function () {
            if (!this._legendDialog) {
                // 初回呼び出し時にインスタンス生成
                this._legendDialog = new IdisDialog({
                    noUnderlay: true,
                    title: '凡例',
                    content: new LegendPane()
                });
                // 画面が破棄された際に連れて行く
                this.own(this._legendDialog);
            }
            if (this._legendDialog.open) {
                this._legendDialog.hide();
            } else {
                this._legendDialog.show();
            }
        },

        showGridLegendDialog: function () {
            if (!this._gridLegendDialog) {
                // 初回呼び出し時にインスタンス生成
                this._gridLegendDialog = new IdisDialog({
                    noUnderlay: true,
                    title: '凡例',
                    content: new GridLegendPane()
                });
                // 画面が破棄された際に連れて行く
                this.own(this._gridLegendDialog);
            }
            if (this._gridLegendDialog.open) {
                this._gridLegendDialog.hide();
            } else {
                this._gridLegendDialog.show();
            }
        },

        /**
         * 距離計測ダイアログを表示する。
         */
        showMeasureDialog: function () {

            // 「作図」「広域印刷」を無効化
            this.switchMainMapDialogs('measure');

            // 初回にウィジェットを生成
            if (!this._measureDialog) {
                this._measureDialog = new IdisDialog({
                    noUnderlay: true,
                    title: '距離計測',
                    content: new MeasurePane({ map: this.map })
                });
            }
            // 画面が破棄された際に連れて行く
            this.own(this._measureDialog);
            this._measureDialog.show();
        },

        /**
         * 印刷ダイアログを表示する
         */
        showPrintDialog: function () {

            // 「作図」「距離計測」を無効化
            this.switchMainMapDialogs('print');

            // 初回にウィジェットを生成
            if (!this._printDialog) {
                this._printDialog = new PrintDialog({
                    noUnderlay: true,
                    map: this.map,
                    layerControl: this.map.layerControl
                });
            }
            // 画面が破棄された際に連れて行く
            this.own(this._printDialog);
            this._printDialog.show();
        },

        /**
         * 背景地図ダイアログを表示する。
         */
        showBaseLayerDialog: function () {
            if (!this._baseLayerDialog) {
                // 初回呼び出し時にインスタンス生成
                this._baseLayerDialog = new IdisDialog({
                    noUnderlay: true,
                    title: '背景地図',
                    content: new BaseLayerPane({ map: this.map })
                });
                // 画面が破棄された際に連れて行く
                this.own(this._baseLayerDialog);
            }
            this._baseLayerDialog.show();
        },

        /**
         * 中心表示の状態を更新する。
         */
        toggleCenterMark: function () {
            // 設定値をトグル
            LocalStorage.set(STORAGE_KEY.CENTER_MARK, LocalStorage.get(STORAGE_KEY.CENTER_MARK) ? '' : '1');
        },

        /**
         * 経緯度グリッドの表示を切り替える。
         */
        toggleLatLngLayer: function () {
            var isActive = this.map.toggleLatLngLayer();
            domClass.toggle(this.latLngGridButton, 'is-checked', isActive);
        },

        /**
         * UTMグリッドの表示を切り替える。
         */
        toggleUtmLayers: function () {
            var isActive = this.map.toggleUtmLayers();
            domClass.toggle(this.utmGridButton, 'is-checked', isActive);
        },

        /**
         * 現在の設定に従って自動更新を予約する。
         */
        reserveAutoUpdate: function () {
            // 既存の更新予約を解除
            if (this._autoUpdateTimeoutId) {
                clearTimeout(this._autoUpdateTimeoutId);
            }
            // Web Storageの値を取得
            var interval = LocalStorage.get(STORAGE_KEY.AUTO_UPDATE_INTERVAL);
            // ボタンのチェック状態を更新
            domClass.toggle(this.autoUpdateButton, 'is-checked', !!interval);
            if (!interval) {
                // 更新間隔が設定されていない場合は終了
                return;
            }
            // 更新間隔が設定されている場合は予約
            console.debug(module.id + '#reserveAutoUpdate: auto update in ' + interval + ' minutes');
            this._autoUpdateTimeoutId = setTimeout(lang.hitch(this, function () {
                // 表示情報ツリーを最新化
                console.debug(module.id + '#reserveAutoUpdate: update start');

                all([
                    // 表示情報ツリーを最新化
                    LayerStore.refreshAll(),
                    // 表示中のレイヤーを最新化
                    this.refreshAllLayers()
                ]).always(lang.hitch(this, function () {
                    // 成否に関わらず次の更新処理を予約
                    console.debug(module.id + '#reserveAutoUpdate: update end');

                    // 行政界を表示
                    this.putFirstLayer();
                    this.reserveAutoUpdate();
                }));
            }), parseFloat(interval) * 60 * 1000);
        },
        /**
         * 現在地図上に表示されている全てのレイヤーを更新する。
         */
        refreshAllLayers: function () {
            // 表示中のレイヤーを表示順の昇順（下にあるものが先）にくるよう並べたリストを取得
            // (表示順を遵守したいので、Locator.getLayerQuery()のキー取得とは別でlayerControlへのアクセスが必要)
            var layerIdList = this.map.layerControl.getLayersByDisplayOrder();
            console.debug(layerIdList);
            this._layerQuery = Locator.getLayerQuery();

            var layerPromiseList = array.map(layerIdList, function (layerId) {
                // レイヤーを一つずつ消去（非同期処理）
                this.map.layerControl.removeLayerById(layerId);
                // jsonファイルからレイヤー情報を新規作成する
                return Requester.get('/data/layer/tree/' + layerId + '.json')
                    .then(lang.hitch(this, function (layerInfo) {
                        var opacity = IdisMap.valueToOpacity(this._layerQuery[layerId]);
                        console.log('★here★');
                        // レイヤーを一つずつ追加（同期処理）
                        return this.map.layerControl.addGeneralLayer(layerInfo, opacity);
                    })).otherwise(function (err) {
                        // 失敗した場合はエラー出力してnullを返す
                        console.error(err);
                        return null;
                    });
            }, this);

            return all(layerPromiseList);
        },

        /**
         * 表示情報の自動更新設定ダイアログを表示する。
         */
        onAutoUpdateButtonClick: function () {
            if (!this._autoUpdateDialog) {
                // 初回呼び出し時にインスタンス生成
                var autoUpdatePane = new AutoUpdatePane();
                var dialog = this._autoUpdateDialog = new ConfirmDialog({
                    title: '自動更新設定',
                    content: autoUpdatePane,
                    onOK: lang.hitch(this, function () {
                        // ダイアログのOKボタンをクリックした際の動作
                        if (autoUpdatePane.form.validate()) {
                            // Web Storageの値を更新
                            var interval = autoUpdatePane.form.get('value').interval;
                            LocalStorage.set(STORAGE_KEY.AUTO_UPDATE_INTERVAL, interval);
                            dialog.hide();
                        }
                    })
                });
                // 自動更新の値がWeb Storageに記録済みの場合はフォームへ反映
                if (LocalStorage.get(STORAGE_KEY.AUTO_UPDATE_INTERVAL)) {
                    autoUpdatePane.form.set('value', { interval: LocalStorage.get(STORAGE_KEY.AUTO_UPDATE_INTERVAL) });
                }
                // 画面が破棄された際に連れて行く
                this.own(this._autoUpdateDialog);
            }
            if (LocalStorage.get(STORAGE_KEY.AUTO_UPDATE_INTERVAL)) {
                // 設定済みの場合は自動更新を解除
                LocalStorage.remove(STORAGE_KEY.AUTO_UPDATE_INTERVAL);
            } else {
                // 自動更新未設定の場合はダイアログを開く
                this._autoUpdateDialog.show();
            }
        },

        /**
         * 「作図」「広域印刷」「距離計測」機能を制御する
         * 各ボタンクリック時に発行し、選択された機能以外はダイアログを閉じ、機能をオフにする
         * @param {String} 'draw'（作図）, 'measure'（距離計測）, 'print'（広域印刷）
         */
        switchMainMapDialogs: function (id) {
            var dialogSet = {
                //key     : [closeTargetDialog, PubUrl]
                'draw': [this._drawPanel, this.DISABLE_DRAW],
                'measure': [this._measureDialog, this.DISABLE_MEASURE],
                'print': [this._printDialog, this.DISABLE_PRINT],
                'damage-integrate': [this._damageReportIntegrator, this.DISABLE_DAMAGE_INTEGRATE]
            };
            // インスタンスがあれば、すべて閉じる。
            Object.keys(dialogSet).forEach(function (key) {
                if (key === id) { return; }
                if (!!dialogSet[key][0]) {
                    topic.publish(dialogSet[key][1]);
                    if (key === 'measure') {
                        dialogSet[key][0].hide();
                    }
                }
            });
        },

        /**
         * 地図を全画面で表示する（災害情報マップ）
         */
        showFullScreanMap: function () {
            Router.moveTo('map');
        },

        /**
         * 地図に表示されている作図データをすべてTanJSONとして保存する。
         */
        downloadAllFeatures: function () {
            var geojson = { 'type': 'FeatureCollection' };
            var features = [];
            this.map.eachLayer(function (featureGroup) {
                if (featureGroup._layers && featureGroup.getLayers().length) {
                    featureGroup.toTanJSON().features.forEach(function (layer) {
                        features.push(layer);
                    });
                }
            });
            geojson.features = features;
            DrawUtil._doDownload(json.stringify(geojson));
        },
        showRouteMenu: function () {
            this.routePanel.style.display = 'block';
        },
        hideRouteMenu: function () {
            this.routePanel.style.display = 'none';
        },
        searchRoute: function (evt) {
            evt.preventDefault();
            var layerGroup = L.layerGroup();
            if (!this.routeFromTxt.get('value')) {
                this.chain.info('出発地が未設定です', 'エラー');
                return; // cancle.
            }
            if (!this.routeToTxt.get('value')) {
                this.chain.info('目的地が未設定です', 'エラー');
                return; //cancel.
            }
            console.log('route');
            if (this._layerControl.layers[10005]) {
                this._layerControl.removeLayerById(10005);
            }
            all([
                // Start Geo Point 
                googleMapService.searchGeocode({ 'address': this.routeFromTxt.get('value') })
                    .then(lang.hitch(this, function (result) {
                        this.routeFromTxt.set('value', result[0].formatted_address);
                        return result[0];
                    })),
                // End Geo Point 
                googleMapService.searchGeocode({ 'address': this.routeToTxt.get('value') })
                    .then(lang.hitch(this, function (result) {
                        this.routeToTxt.set('value', result[0].formatted_address);
                        return result[0];
                    }))
            ]).then(lang.hitch(this, function (data) {
                console.log(data);
                var param = {
                    "origin": data[0].formatted_address,
                    "destination": data[1].formatted_address,
                    "travelMode": "DRIVING",
                    "unitSystem": 0,
                    "waypoints": [],
                    "provideRouteAlternatives": true,
                    "optimizeWaypoints": true,
                    "avoidHighways": false,
                    "avoidTolls": false
                };
                googleMapService.searchRoute(param)
                    .then(lang.hitch(this, function (result) {
                        console.log(result);
                        var routeLine = L.Polyline.fromEncoded(result.routes[0].overview_polyline);

                        // Start Marker 
                        layerGroup.addLayer(
                            L.marker(
                                L.latLng(routeLine._latlngs[0])
                            ).addTo(this.map)
                        );
                        // End Marker 
                        layerGroup.addLayer(
                            L.marker(
                                L.latLng(routeLine._latlngs[routeLine._latlngs.length - 1])
                            ).addTo(this.map)
                        );

                        layerGroup.addLayer(
                            routeLine.addTo(this.map)
                        );
                        this.map.fitBounds(routeLine.getBounds());
                        this._layerControl.addLayer(layerGroup, 10005);
                        this._layerControl.toFront(10005);
                    }));
            }));

        },
        clearRoute: function () {
            this.routeFromTxt.set('value', '');
            this.routeToTxt.set('value', '');
        },
        showMapMenu: function () {
            this.mapPanel.style.display = 'block';
        },
        hideMapMenu: function () {
            this.mapPanel.style.display = 'none';
        },
        initMap: function () {
            var municipalityCd = UserInfo.getSelectedMunicipalityCd();

            if (UserInfo.getLatitude() && UserInfo.getLongitude()) {
                this._latlng = {
                    latitude: UserInfo.getLatitude(),
                    longitude: UserInfo.getLongitude()
                };
            } else if (municipalityCd) {
                Requester.get('/api/municipalities/' + municipalityCd, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(lang.hitch(this, function (item) {
                    this._latlng = {
                        latitude: item.latitude,
                        longitude: item.longitude
                    };
                }, function (error) {
                    console.log(error);
                }));
            } else {
                // デフォルトの位置情報を設定
                this._latlng = config.map;
            }
            // マップの初期化
            this.map = new IdisMap(this.id + '-map', {
                config: this._latlng,
                keyboard: false, // コメント時に+/-が使用できないため
                touchExtend: false, // IE対応
                minZoom: 9,
                maxZoom: 18,
                drawControlTooltips: false
            });

            // mapのdestroyはremoveでDojoと互換なのでownで消せる
            this.own(this.map);

            // 最後のリサイズより10ミリ秒待ってからマップへ通知
            var notifier = debounce(lang.hitch(this, 'notifyResizeToMap'), 10);
            this.own(aspect.after(this.detailPane, 'resize', notifier));

            // 行政界を表示
            this.putFirstLayer();

            this._layerControl = this.map.layerControl;
        },

        /**
         * 地図レイヤーの上に行政界レイヤーを敷く
         * 県の境が見にくいため、自治体ごとのpolygonを置いている.
         * layeridの'999'は仮
         * TODO: 将来的にURLで制御させるか検討要
         */
        putFirstLayer: function () {
            return this.map.layerControl.addGeneralLayer({
                'id': 999,
                'name': '行政界',
                'parentId': 0,
                'disasterId': 21,
                'dispSeqNo': 2,
                'infoCategoryCd': 'D108',
                'pubStatus': '4',
                'deptCd': 'D01003',
                'sectCd': 'S01001',
                'minZoom': 8,
                'maxZoom': 18,
                'tileFlg': false,
                'jsonType': '1',
                'listType': '0',
                'layerUrl': this.MUNICIPALITY_LAYER_URL,
                'styleUrl': 'style.js',
                'hideFlg': false,
                'userId': 'U01001'
            }, 1);
        },

        activateIntegrateDamageButton: function () {
            domClass.add(this.damageIntegrateButton, 'is-Activated');
            this._isIntegrateMode = true;
        },

        deactivateIntegrateDamageButton: function () {
            domClass.remove(this.damageIntegrateButton, 'is-Activated');
            this._isIntegrateMode = false;
        },

        /**
         * 被害統合機能をactiveにする
         */
        activateIntegrateDamage: function () {
            // 他のダイアログを消す
            this.switchMainMapDialogs('damage-integrate');

            if (!this._damageReportIntegrator) {
                this._damageReportIntegrator = new DamageReportIntegrator(this.map);
                this.own(this._damageReportIntegrator);
            }
            this._damageReportIntegrator.activate();

            // ボタンの色を変える
            this.activateIntegrateDamageButton();
        },

        /**
         * 被害統合機能のオン・オフを制御する
         */
        toggleIntegrateDamage: function () {
            if (this._isIntegrateMode) {
                // 被害統合機能をdeactivateする
                topic.publish(this.DISABLE_DAMAGE_INTEGRATE);
                this._isIntegrateMode = false;
            } else {
                this.activateIntegrateDamage();
                this._isIntegrateMode = true;
            }
        },

        /**
         * 避難タブの中身を作る
         */
        initEvacTab: function () {
            //集計結果
            var evacPrepareMuniNum = 0;
            var evacPrepareEvaqueeDistNum = 0;
            var evacPrepareHouseholdDistNum = 0;
            var evacAdvisoryMuniNum = 0;
            var evacAdvisoryEvaqueeDistNum = 0;
            var evacAdvisoryHouseholdDistNum = 0;
            var evacOrderMuniNum = 0;
            var evacOrderEvaqueeDistNum = 0;
            var evacOrderHouseholdDistNum = 0;

            var evacPanelTbody = dojo.byId('evacPanelTbody');
            var self = this;
            Requester.get('/api/evacorders/summary', {
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                handleAs: 'json',
                preventCache: true
            }).then(function (data) {
                array.forEach(data.items, function (item) {
                    var timestamp = new Date(item.reportTimestamp);
                    var minute = timestamp.getMinutes() < 10 ? ('0' + timestamp.getMinutes()) : timestamp.getMinutes();
                    var date = timestamp.getFullYear() + '/' + (timestamp.getMonth() + 1) + '/' + timestamp.getDate();
                    var time = timestamp.getHours() + ':' + minute;
                    var evacOrderType = '';
                    if (item.evacOrderType === '1') {
                        evacOrderType = '避難指示';
                        evacOrderMuniNum += 1;
                        evacOrderEvaqueeDistNum += item.evaqueeDistNum;
                        evacOrderHouseholdDistNum += item.householdDistNum;
                    } else if (item.evacOrderType === '2') {
                        evacOrderType = '避難勧告';
                        evacAdvisoryMuniNum += 1;
                        evacAdvisoryEvaqueeDistNum += item.evaqueeDistNum;
                        evacAdvisoryHouseholdDistNum += item.householdDistNum;
                    } else if (item.evacOrderType === '3') {
                        evacOrderType = '避難準備';
                        evacPrepareMuniNum += 1;
                        evacPrepareEvaqueeDistNum += item.evaqueeDistNum;
                        evacPrepareHouseholdDistNum += item.householdDistNum;
                    }

                    var html = '<tr>';
                    html += '<td style="padding-left:10px; width:30%;">' + date + ' ' + time + '</td>';
                    html += '<td style="width:50%;">' + item.municipalityName + 'が' + item.distNum;
                    html += '地区に' + evacOrderType + '発令</td>';
                    html += '<td style="text-align: right; padding-right:10px; width:20%; color: blue;">';
                    //FIXME: 正しく動作しない。onstart後に加えたdata-dojo-attach-eventはメソッドと紐づかない？
                    html += '<u><a href="?" data-dojo-attach-event="click:onEvacorderLinkClick">詳細&gt;</a></u></td>';
                    html += '</tr>';

                    var dom = domConstruct.toDom(html);
                    domConstruct.place(dom, evacPanelTbody);
                });

                self.evacOrderMuniNum.innerHTML = evacOrderMuniNum;
                self.evacOrderEvaqueeDistNum.innerHTML = evacOrderEvaqueeDistNum;
                self.evacOrderHouseholdDistNum.innerHTML = evacOrderHouseholdDistNum;
                self.evacAdvisoryMuniNum.innerHTML = evacAdvisoryMuniNum;
                self.evacAdvisoryEvaqueeDistNum.innerHTML = evacAdvisoryEvaqueeDistNum;
                self.evacAdvisoryHouseholdDistNum.innerHTML = evacAdvisoryHouseholdDistNum;
                self.evacPrepareMuniNum.innerHTML = evacPrepareMuniNum;
                self.evacPrepareEvaqueeDistNum.innerHTML = evacPrepareEvaqueeDistNum;
                self.evacPrepareHouseholdDistNum.innerHTML = evacPrepareHouseholdDistNum;


            }, function (error) {
                console.log(error);
                //self.chain.info('情報の取得に失敗しました。', 'エラー');
            });
        },


        /**
         * 避難所タブの中身を作る
         */
        initShelterTab: function () {
            var self = this;
            Requester.get('/api/shelters/summary', {
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                handleAs: 'json',
                preventCache: true
            }).then(function (data) {
                self.shelterNum.innerHTML = data.shelterNum;
                self.evaqueeNum.innerHTML = data.evaqueeNum;
                self.evacHouseholdNum.innerHTML = data.evacHouseholdNum;

            }, function (error) {
                console.log(error);
                //self.chain.info('情報の取得に失敗しました。', 'エラー');
            });
        },

        /**
         * 被害情報タブの中身を作る
         */
        initDamageReportTab: function () {

            //集計結果
            var damageHumanNum = 0;
            var damageHouseNum = 0;
            var damageOtherNum = 0;

            var damagePanelTbody = dojo.byId('damagePanelTBody');
            Requester.get('/api/damageReports/summary', {
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                handleAs: 'json',
                preventCache: true
            }).then(function (data) {
                array.forEach(data.items, function (item) {
                    var timestamp = new Date(item.reportTimestamp);
                    var minute = timestamp.getMinutes() < 10 ? ('0' + timestamp.getMinutes()) : timestamp.getMinutes();
                    var date = timestamp.getFullYear() + '/' + (timestamp.getMonth() + 1) + '/' + timestamp.getDate();
                    var time = timestamp.getHours() + ':' + minute;
                    if (item.humanFlg) {
                        damageHumanNum += 1;
                    }
                    if (item.houseFlg) {
                        damageHouseNum += 1;
                    }
                    if (item.otherFlg) {
                        damageOtherNum += 1;
                    }

                    var html = '<tr>';
                    html += '<td style="padding-left:10px; width:30%;">' + date + ' ' + time + '</td>';
                    html += '<td style="width:50%;">' + item.layerName + '</td>';
                    html += '<td style="text-align: right; padding-right:10px; width:20%; color: blue;">';
                    //html += '<a href="?" onClick="onDamageReportLinkClick(';
                    //html += item.municipalityCd +')"><u>詳細&gt;</u></a></td>';
                    //FIXME: 正しく動作しない。onstart後に加えたdata-dojo-attach-eventはメソッドと紐づかない？
                    html += '<u><a href="?" data-dojo-attach-event="click:onDamageReportLinkClick">詳細&gt;</a></u></td>';
                    html += '</tr>';


                    var dom = domConstruct.toDom(html);
                    domConstruct.place(dom, damagePanelTbody);
                });

            }, function (error) {
                console.log(error);
                //self.chain.info('情報の取得に失敗しました。', 'エラー');
            });
        },

        /**
         * 避難情報一覧ページに遷移
         */
        onEvacorderLinkClick: function (evt) {
            console.log(this);
            evt.preventDefault();
            var municipalityCd = null;
            if (!municipalityCd) {
                municipalityCd = config.municInfo.cityMunicCd;
            }
            Router.moveTo('evacorder', {
                municipalityCd: municipalityCd
            });
        },

        /**
         * 避難情報概況ページに遷移
         */
        onEvacorderAdminLinkClick: function (evt) {
            evt.preventDefault();
            Router.moveTo('evacorder/admin');
        },

        /**
         * 避難所情報概況ページに遷移
         */
        onShelterAdminLinkClick: function (evt) {
            evt.preventDefault();
            Router.moveTo('shelterAdmin');
        },

        /**
         * 被害情報一覧ページに遷移
         */
        onDamageReportLinkClick: function (evt) {
            evt.preventDefault();
            var municipalityCd = null;
            if (!municipalityCd) {
                municipalityCd = config.municInfo.cityMunicCd;
            }
            Router.moveTo('report', {
                municipalityCd: municipalityCd
            });
        },

        /**
         * 被害情報概況ページに遷移
         */
        onDamageReportAdminLinkClick: function (evt) {
            evt.preventDefault();
            Router.moveTo('report/admin');
        },

        /**
         * 表示されている地図範囲を画像として保存
         */
        saveMapAsImage: function () {
            console.log('saveMapAsImage');

            // TODO: 表示されている範囲ではなく範囲を指定する場合は、選択されている範囲のlatLngBoundsを渡す（未実装）
            this._mapToImage = new MapToImage(this.map, {
                latLngBounds: null
            });

            // canvas投影が完了したら呼ばれる
            // this.ownは不要
            on(this._mapToImage, 'finish', lang.hitch(this, function (e) {
                console.log('finish');
                // ダウンロードを実行する
                this._mapToImage.download(e.canvas);
            }));

            // 画像生成（canvas投影）開始
            this._mapToImage.start();
        },

        /**
         * レイヤー表示の更新
         */
        updateLayer: function () {
            var payload = {};
            var layerQuery = Locator.getLayerQuery();
            var id = [];
            var disasterId = DisasterInfo.getDisasterId();
            var regionCd = UserInfo.getSelectedRegionCd();
            var municipalityCd = UserInfo.getSelectedMunicipalityCd();

            switch (this._monitorTab) {
                // 気象観測
                case 'weatherTab':
                    id = mapViewSelectCfg.WEATHER_LAYER_ID;
                    payload = { layerQuery: layerQuery, layerId: id };
                    topic.publish(module.id + '::selected', payload);
                    break;
                // 地震・火山
                case 'disasterTab':
                    id = [34, 35, 42, 43];
                    payload = { layerQuery: layerQuery, layerId: id };
                    topic.publish(module.id + '::selected', payload);
                    break;
                // 避難・避難所
                case 'evacShelterTab':
                    // サーバーから避難・避難所情報レイヤーIDを取得する
                    Requester.get('/api/monitor/evacshelter?municipalityCd=' + municipalityCd +
                        '&disasterId=' + disasterId, {
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: true
                    }).then(lang.hitch(this, function (item) {
                        payload = { layerQuery: layerQuery, layerId: item };
                        topic.publish(module.id + '::selected', payload);
                    }), function (error) {
                        console.debug(error);
                    });
                    break;
                // 被害情報
                case 'damageTab':
                    // サーバーから被害情報レイヤーIDを取得する
                    Requester.get('/api/monitor/damage?municipalityCd=' + municipalityCd +
                        '&disasterId=' + disasterId, {
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: true
                    }).then(lang.hitch(this, function (item) {
                        payload = { layerQuery: layerQuery, layerId: item };
                        topic.publish(module.id + '::selected', payload);
                    }), function (error) {
                        console.debug(error);
                    });
                    break;
                // 道路情報
                case 'trafficTab':
                    // サーバーから道路情報レイヤーIDを取得する
                    Requester.get('/api/monitor/traffic?regionCd=' + regionCd + '&disasterId=' + disasterId, {
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: true
                    }).then(lang.hitch(this, function (item) {
                        payload = { layerQuery: layerQuery, layerId: item };
                        topic.publish(module.id + '::selected', payload);
                    }), function (error) {
                        console.debug(error);
                    });
                    break;
            }
        }

    });
});
