define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/util/DateUtils'
], function (module, declare, lang, locale, IdisGrid, helper, DateUtils) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sending',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.column('evacShelterName', '避難所名称', { sortable: false }),
            helper.column('evacShelterType', '避難所区分', { sortable: false }),
            helper.column('evacShelterSort', '区分', { sortable: false }),
            helper.column('evacShelterDatetime', '開設・閉鎖日時', {
                sortable: false,
                formatter: function (value) {
                    if (value) {
                        return DateUtils.format(value);
                    }
                    return '';
                }
            }),
            helper.column('address', '住所', { sortable: false }),
            helper.column('evacShelterLatitude', '緯度', { sortable: false }),
            helper.column('evacShelterLongitude', '経度', { sortable: false }),
            {
                label: '避難者情報', field: 'evaquee',
                children: [
                    helper.column('evaqueeNum', '総人数', { sortable: false }),
                    helper.column('evacHouseholdNum', '総世帯数', { sortable: false })
                ],
                sortable: false
            }
        ]
    });
});
