/**
 * 被害情報 詳細 閲覧画面用モジュール。
 * @module app/damage/damageReportAction/DamageReportActionViewForm
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/DamageReportActionViewForm.html',
    'app/damage/damageReportAction/DamageReportActionForm',
    // 以下、変数として受け取らないモジュール
], function (module, declare, template, DamageReportActionForm) {

    /**
     * 被害概要 詳細 閲覧ページ
     * @class DamageReportActionViewForm
     * @extends module:app/damage/damageReportAction/DamageReportActionForm#~DamageReportActionForm
     */
    return declare(module.id.replace(/\//g, '.'), DamageReportActionForm,
        /** @lends module:app/damage/damageReportAction/DamageReportActionViewForm# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--damage',

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
            },

            // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
            constructor: function () {
            },

            startup: function () {
                this.inherited(arguments);
            },



        });
});

