/**
 * クロノロジの新規登録・詳細・続報登録画面用の基底モジュール。
 * @module app/chronology/_ChronologyPageBase
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/json',
    'dojo/topic',
    'dojo/text!./templates/ChronologyRegisterPage.html',
    'dojo/dom-style',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'idis/consts/STORAGE_KEY',
    'idis/view/page/_PageBase',
    'idis/map/IdisMap',
    'idis/consts/ACL',
    'idis/consts/USER_TYPE',
    'idis/control/Router',
    'idis/model/UserInfo',
    'leaflet',
    '../config',
    'esri-leaflet-geocoder',
    'idis/service/GeoService',
    'idis/service/iframe',
    'idis/service/Requester',
    'idis/util/FilesUtils',
    'idis/util/storage/LocalStorage',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/IdisDialog',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'exif-js/exif',
    'app/map/baselayer/BaseLayerPane',
    'app/model/DisasterInfo',
    'app/model/Municipality',
    'dojo/on',
    '../draw/DrawPanel',
    'dojo/_base/array',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/form/Textarea',
    'dijit/form/CheckBox',
    'dojox/form/Uploader',
    'dijit/form/SimpleTextarea',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'app/view/form/OrganizationSelector'
], function(module, declare, lang, domClass, domConstruct, json, topic, template, domStyle,
    Menu, MenuItem, popup, TooltipDialog, STORAGE_KEY, _PageBase, IdisMap, ACL, USER_TYPE, Router,
	UserInfo, leaflet, config, geocoder, GeoService, iframe, Requester, FilesUtils, LocalStorage,
    DialogChain, IdisDialog, InfoDialog, Loader, exif, BaseLayerPane, DisasterInfo, Municipality,
    on, DrawPanel, array) {
    // GeoServiceを初期化
    var _geoService = null;

    /**
     * クロノロジ新規登録・詳細・続報登録画面画面。
     * @class _ChronologyPageBase
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/chronology/_ChronologyPageBase~_ChronologyPageBase# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * 市町村コード（県）
         */
        MUNICIPALITY_CD_KUMAMOTO_PREF: config.municInfo.prefMunicCd,

        /**
         * 市町村コード（市）
         */
        MUNICIPALITY_CD_KUMAMOTO_CITY: config.municInfo.cityMunicCd,

        //作図ダイアログ
        drawPanel : null,

        DRAW_INIT_ID: '/app/draw/DrawPanel::SettingDrawEvents',
        JSONIZE_RQST: '/app/draw/DrawPanel::drawnDataJsonizeRequest',
        JSONIZE_DONE: '/app/draw/DrawPanel::drawnDataJsonizeResponse',
        DRAW_BY_JSON: '/app/draw/DrawPanel::drawGeoJSONToLayer',
        //イベントは破棄しない、全作図モードをOFFにする
        DISABLE_DRAW: '/app/draw/DrawPanel::hideAndDisableDraw',
        //mapに取り付けた全てのイベントを破棄する。
        DRAW_EVT_OFF: '/app/draw/DrawPanel::removeDrawAllEvent',

        /**
         * 市町村コード
         */
        _municipalityCd: null,

        /**
         * 市町村コードのリスト
         */
        _municipalityCds: null,

        /**
         * 発生元住所 緯度
         */
        _sourceAddressLat: null,


        /**
         * 発生元住所 経度
         */
        _sourceAddressLng: null,

        /**
         * 災害シミュレーションフラグ
         */
         _simulateFlg: false,
         _scenarioId: null,
         _eventType: null,
         _content: null,
         _timing: null,

        /**
         * constructor
         */
        constructor: function() {
            console.debug('constructor');
            this.chain = DialogChain.get(this);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            console.debug('buildRendering');
            this.inherited(arguments);
            // this.own(this.linkInsertDialog);
        },

        /**
         * startup
         */
        startup: function() {
            console.debug('startup');
            this.inherited(arguments);

            // 災害シミュレーションの場合添付ファイルを不可視にする
            if(this._simulateFlg === 'true'){
                domStyle.set(this.attachFileItem, 'display', 'none');
            }
        },

        /**
         * postCreate
         */
        postCreate: function() {
            console.debug('postCreate');
            this.inherited(arguments);
        },

        /**
         * リンク挿入ボタンが押された際に呼ばれる。
         */
        onLinkInsertButtonClick: function() {
            // 内容のカーソル位置を記憶する
            var point = this.content.domNode.selectionStart;
            if (!point && point !== 0) {
                // 取得出来なかった場合は末尾に設定
                point = this.content.get('value').length;
            }
            this._insertPoint = point;
            this.linkInsertForm.reset();
            this.linkInsertDialog.show();
        },

        /**
         * リンク挿入内容確定時に呼ばれる。ベント
         */
        onLinkInsertOK: function() {
            try {
                if (this.linkInsertForm.validate()) {
                    // リンク文字列を生成
                    var value = this.linkInsertForm.get('value');
                    var linkText = '[' + value.title + '](' + value.url + ')';
                    // 内容のカーソル位置か末尾へリンク文字列を挿入
                    var content = this.content.get('value');
                    var newContent = content.slice(0, this._insertPoint) + linkText + content.slice(this._insertPoint);
                    this.content.set('value', newContent);
                    // ダイアログを閉じる
                    this.linkInsertDialog.hide();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

/*        setShareTips: function(){
            // var shareOrganContent = '宛先となる県・市町村を指定してください。<br>' +
            //                         '「岡山県」を指定すると、<br>' +
            //                         '「県、振興局、地域事務所、警察、自衛隊等」<br>' +
            //                         'が宛先となります。<br>' +
            //                         '市町村を指定すると、<br>' +
            //                         '「市町村（危機管理、建設、農林等）と消防本部等」<br>' +
            //                         'が宛先となります。<br>' +
            //                         '登録内容は、登録したーザの所属する県・市町村、<br>' +
            var shareOrganContent = '宛先となる市・行政区を指定してください。<br>' +
                '登録内容は、登録したーザの所属する市・行政区、<br>' +
                '及び宛先の市・行政区のユーザにのみ閲覧可能です。';

            var shareOrganTips = new TooltipDialog({
                id: 'shareTips',
                style: 'width: 375px; height:100px',
                content: '<p>' + shareOrganContent + '</p>'
            });
            var shareOrganLabel = this.shareOrganLabel;
            this.own(shareOrganTips);
            on(shareOrganLabel, 'mouseover', lang.hitch(function() {
                popup.open({
                    popup: shareOrganTips,
                    around: shareOrganLabel
                });
            }));
            on(shareOrganLabel, 'mouseleave', function() {
                popup.close(shareOrganTips);
            });
        },
*/
        /**
         * マップを初期化する。
         */
        initMap: function(latitude, longitude) {

            //中心アイコンを非表示にする
            LocalStorage.set(STORAGE_KEY.CENTER_MARK, '');

            // マップの生成
            var latlng = null;
            if(latitude && longitude) {
                latlng = [latitude, longitude];
            } else {
                latlng = [config.map.latitude, config.map.longitude];
            }
            this.map = new IdisMap(this.mapNode, {
                config: config.map,
                keyboard: false, // コメント時に+/-が使用できないため
                touchExtend : false,
                minZoom: 9,
                maxZoom: 18,
                drawControlTooltips:false}
            ).setView(latlng, 14);
            // destroy時にmapを破棄するよう設定
            this.own(this.map);

            this.own(on(this.map, 'click', lang.hitch(this, function(e) {
                // 作図ダイアログが閉じられているとき住所取得モードとする
                if(this.drawPanel._ActiveMode === null) {
                    this.pointLat = e.latlng.lat;
                    this.pointLng = e.latlng.lng;
                    this.addMark(this.pointLat, this.pointLng, this);
                }
            })));

            // 作図パネルを生成
            this.createDrawPanel();
            topic.publish(this.DRAW_INIT_ID, this.map);
            topic.subscribe(this.JSONIZE_DONE, lang.hitch(this, function(args){
                this.drawJson = args;
            }));
        },

        /**
         * 作図ペインを生成する。
         */
        createDrawPanel: function() {
            if(this.drawPanel === null){
                this.own(
                    this.drawPanel = new DrawPanel({
                        map     : this.map,
                        'class'  : 'drawPanel-NonModal',
                        dispType : 'damage'
                    }));
            }
        },

        /**
         * 作図ダイアログを表示する。
         */
        showDrawPanelDialog: function(){
            this.drawPanel.show();
        },

        /**
         * 背景地図ダイアログを表示する。
         */
        showBaseLayerDialog: function() {
            if (!this._baseLayerDialog) {
                // 初回呼び出し時にインスタンス生成
                this._baseLayerDialog = new IdisDialog({
                    noUnderlay: true,
                    title: '背景地図',
                    content: new BaseLayerPane({map: this.map})
                });
                // 画面が破棄された際に連れて行く
                this.own(this._baseLayerDialog);
            }
            this._baseLayerDialog.show();
        },

        /**
         * 地図上でポイントされている位置の住所を設定します。
         */
        mapToAddress: function() {
            if(this.pointLat === '' || this.pointLng === '') {
                console.debug('not pointed map');
                this.chain.info('地図が選択されていません。', 'エラー');
                return;
            }
            console.debug('start reverse geocoding');

                if (_geoService === null) {
                    _geoService = new GeoService({ url: config.geocode && config.geocode.url });
                }
            _geoService.reverseGeocode(leaflet.latLng({
                lat: this.pointLat,
                lng: this.pointLng
            })).then(lang.hitch(this, function(res) {
                console.log(this);
                console.log(res);
                this.addMark(this.pointLat, this.pointLng);
                this.sourceAddress01.set('value', res.address.Address);
                this._sourceAddressLat = this.pointLat;
                this._sourceAddressLng = this.pointLng;
            }), lang.hitch(this, function() {
                this.chain.info('住所を取得できませんでした。', 'エラー');
            }));

            console.debug('end reverse geocoding (address: ' +
                    this.sourceAddress01.value + ')');
        },

        /**
         * 住所の位置を地図上にポイントします。
         */
        addressToMap: function() {
            if(!this.sourceAddress01.value) {
                console.debug('not input address');
                this.chain.info('住所を入力してください。', 'エラー');
                return;
            }
            console.log('start geocoding');
            var address = this.sourceAddress01.value;

                if (_geoService === null) {
                    _geoService = new GeoService({ url: config.geocode && config.geocode.url });
                }
            _geoService.geocode(address).then(lang.hitch(this, function(results) {
                if(results.length > 0) {
                    var latlng = [results[0].latlng.lat, results[0].latlng.lng];
                    this.pointLat = results[0].latlng.lat;
                    this.pointLng = results[0].latlng.lng;
                    this.addMark(this.pointLat, this.pointLng);
                    this.map.setView(latlng, 14);
                    this._sourceAddressLat = results[0].latlng.lat;
                    this._sourceAddressLng = results[0].latlng.lng;
                } else {
                    console.debug('address is not correct');
                    this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                }
            }));
        },

        /**
         * マーカーを追加する。
         */
                 addMark: function(lat, lng) {
                    this.removeMark();
                    this.marker = leaflet.marker([lat, lng]).addTo(this.map);
                },
        
                /**
                 * マーカーを削除する。
                 */
                removeMark: function() {
                  if(this.marker){
                    this.map.removeLayer(this.marker);
                  }
                },

        /**
         * 添付ファイルをアップロードする。
         */
        loadAttachFile: function() {
            // ファイルが空の場合はメッセージ表示して処理を中断
            if (this.attachFile._files.length === 0 ) {
                return;
            }

            if (this.attachFile._files[0].size === 0 ) {
                InfoDialog.show('入力チェック', 'ファイルの中身が空です。');
                this.attachFile.reset();
                return;
            }

            console.log('file change');
            var self = this;

            this.attachFile.set('disabled', false);

            // ファイルがonloadされたときにサーバーに一時保存する
            var promise = iframe.post('/api/chronologies/uploadFile', {
                form: this.form.id,
                handleAs: 'json'
            }).then(function(data) {
                //uploaderをリセット
                self.attachFile.reset();
                self.attachFileList.push(data);
                self.showPreview(data, true);

            }, function(error) {
                console.log(error);
                //uploaderをリセット
                self.attachFile.reset();
                self.chain.infoError(error);
            });

            //ローダーの表示
            Loader.wait(promise);

        },

        /**
         * 添付ファイルのプレビューを表示する。
         */
        showPreview: function(data, exifFlg) {
            var dataUri = data.attachFilePath.replace('out/', 'data/');
            var fileName = data.attachFileName;
            var fileId = data.chronologyAtcFileId;
            var self = this;

            // 画像ファイルの場合
            if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
            fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
            fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
            fileName.indexOf('.gif') !== -1 || fileName.indexOf('.bmp') !== -1){
                var image = new Image();

                //JPEGファイルの場合、EXIFデータの取得を実行する
                if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
                fileName.indexOf('.JPG') !== -1 ||fileName.indexOf('.JPEG') !== -1) {
                    var img = null;
                    this.own(on(image, 'load', lang.hitch(this, function(e) {
                        img = e.target;

                        if(exifFlg) {
                            this.getExifData(img, this);
                        }
                    })));
                }
                image.src = dataUri;
                domClass.add(image, 'is-showPreview');
                domConstruct.place(image, this.preview);
                //メニューの作成
                this.createMenu(image, dataUri, fileName, fileId, self, false);

            } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                var excel = new Image();
                excel.src = 'images/excelicon.png';
                domClass.add(excel, 'is-showPreview');
                domConstruct.place(excel, this.preview);
                //メニューの作成
                this.createMenu(excel, dataUri, fileName, fileId, self, false);
            } else if (fileName.indexOf('.pdf') !== -1) {
                var pdf = new Image();
                pdf.src = 'images/pdficon.png';
                domClass.add(pdf, 'is-showPreview');
                domConstruct.place(pdf, this.preview);
                //メニューの作成
                this.createMenu(pdf, dataUri, fileName, fileId, self, false);
            } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                var word = new Image();
                word.src = 'images/wordicon.png';
                domClass.add(word, 'is-showPreview');
                domConstruct.place(word, this.preview);
                //メニューの作成
                this.createMenu(word, dataUri, fileName, fileId, self, false);
            } else if (fileName.indexOf('.ppt') !== -1 || fileName.indexOf('.pptx') !== -1) {
                var ppt = new Image();
                ppt.src = 'images/ppticon.png';
                domClass.add(ppt, 'is-showPreview');
                domConstruct.place(ppt, this.preview);
                //メニューの作成
                this.createMenu(ppt, dataUri, fileName, fileId, self, false);
            } else if (fileName.indexOf('.zip') !== -1) {
                var zip = new Image();
                zip.src = 'images/zipicon.png';
                domClass.add(zip, 'is-showPreview');
                domConstruct.place(zip, this.preview);
                //メニューの作成
                this.createMenu(zip, dataUri, fileName, fileId, self, false);
            } else {
                var other = new Image();
                other.src = 'images/othericon.png';
                domClass.add(other, 'is-showPreview');
                domConstruct.place(other, this.preview);
                //メニューの作成
                this.createMenu(other, dataUri, fileName, fileId, self, false);
            }
        },

        /**
         * JPEGファイルの位置情報を取得する
         */
        getExifData: function(img, self) {
            console.log('getting exif data start');
            exif.getData(img, function(){

                var latitude = exif.getTag(this, 'GPSLatitude');
                var longitude = exif.getTag(this, 'GPSLongitude');

                if(typeof latitude === 'undefined' || typeof longitude === 'undefined'){
                    console.log('GPS data is unavailable.');
                }else{
                    console.log('GPS data is available.');
                    var f = function(number){
                        return number[0].numerator + number[1].numerator /
                        (60 * number[1].denominator) + number[2].numerator / (3600 * number[2].denominator);
                    };
                    self.chain.confirm('この画像の位置情報を使用しますか？', function(chain) {
                        // 位置情報を設定する
                        self.pointLat = f(latitude);
                        self.pointLng = f(longitude);
                        // 地図にマークして中心に移動する
                        self.addMark(self.pointLat, self.pointLng, self);
                        self.map.setView([self.pointLat, self.pointLng], 14);
                        //ダイアログを閉じる
                        chain.hide();
                    });
                }
            });
        },

        /**
         * クロノロジ対応履歴を登録する。
         */
        registerChronologyAction: function() {
            // var sendData = this.form.get('value');
            var sendData = {
                chronologyId : this.chronologyId,
                content : this.action.value,
                attachFileIds: ''
            };

            // フォームのバリデーションを行う（共通部品）
            if(!this.form.validate()) {
                return false;
            }

            // 添付ファイルIDをセット
            if(this.attachFileList.length > 0) {
                var attachFileIds = [];
                for(var i=0; i<this.attachFileList.length; i++) {
                    attachFileIds.push(this.attachFileList[i].chronologyAtcFileId);
                }
                sendData.attachFileIds = attachFileIds.join(',');
            } else {
                sendData.attachFileIds = '';
            }

            var jsonStr = json.stringify(sendData);
            //登録確認ダイアログを表示
            this.chain.confirmAdd(function(chain) {
                var promise = Requester.post('/api/chronologies/action', {
                    data: jsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(function() {
                    console.debug('success register Chronology');
                    chain.infoComplete(function() {
                        chain.hide();
                        // 画面を更新
                        // location.reload();
                        // 対応履歴を開きたい
                    });
                }, function(error) {
                    console.log('error register Chronology');
                    chain.infoError(error);
                });
                //ローダーの表示
                Loader.wait(promise);
            });
            console.debug('[登録]ボタンがクリックされました。');
        },

        getMunicipalityInfo: function(cd){
            return Municipality.store.get(cd).then(lang.hitch(this, function(municipality){
                return municipality ? municipality : null;
			}));
        },

        setOrganizationFromTreeObject: function(treeObj){
            var organization = {};
            if(treeObj.id.substr(0,2) === 'UU'){
                organization.unitCd = treeObj.id.substr(1,6);
                organization.sectCd = treeObj.parentId.substr(1,6);
                organization.deptCd = treeObj.grandParentId.substr(1,6);
                // FIXME: deptCdの取得をする
            } else if (treeObj.id.substr(0,2) === 'SS'){
                organization.unitCd = null;
                organization.sectCd = treeObj.id.substr(1,6);
                organization.deptCd = treeObj.parentId.substr(1,6);
            } else if (treeObj.id.substr(0,2) === 'DD'){
                organization.unitCd = null;
                organization.sectCd = null;
                organization.deptCd = treeObj.id.substr(1,6);
            }
            organization.name = treeObj.name;
            return organization;
        },

        setOrganizationFromTreeItem: function(treeObj, value){
            var organization = {};
            // if(value.substr(0,2) === 'UU'){
            // var parentId = treeObj[value][0].item.parentId;
            //     organization.unitCd = treeObj[value][0].item.id.substr(1,6);
            //     organization.sectCd = treeObj[value][0].item.parentId.substr(1,6);
            //     organization.deptCd = treeObj[parentId][0].item.parentId.substr(1,6);
            //     // FIXME: deptCdの取得をする
            // } else 
            if (value.substr(0,2) === 'SS'){
                organization.unitCd = null;
                organization.sectCd = treeObj[value][0].item.id.substr(1,9);
                organization.deptCd = treeObj[value][0].item.parentId.substr(1,6);
            } else if (value.substr(0,2) === 'DD'){
                organization.unitCd = null;
                organization.sectCd = null;
                organization.deptCd = treeObj[value][0].item.id.substr(1,6);
            }
            organization.name = treeObj[value][0].item.name;
            return organization;
        },

        /* 
         * 詳細ページで組織ダイアログを展開しない場合、Unit,Sectが取得できないことがあるため、
         * 個別で取得する
         */
        setOrganizationFromTreeNode: function(treeObj, treeNode, value){
            var organization = {};
            if(value.substr(0,2) === 'UU'){
            var parentId = treeNode.parentId;
                organization.unitCd = treeNode.id.substr(1,6);
                organization.sectCd = treeNode.parentId.substr(1,6);
                array.forEach(treeObj, function(dept) {
                    if(dept.id === parentId) {
                        organization.deptCd = dept.parentId.substr(1,6);
                    }
                });
            } else if (value.substr(0,2) === 'SS'){
                organization.unitCd = null;
                organization.sectCd = treeNode.id.substr(1,6);
                organization.deptCd = treeNode.parentId.substr(1,6);
            } else if (value.substr(0,2) === 'DD'){
                organization.unitCd = null;
                organization.sectCd = null;
                organization.deptCd = treeNode.id.substr(1,6);
            }
            organization.name = treeNode.name;
            return organization;
        },

        /**
         * 添付ファイルのサムネイル上にメニューを作る
         */
        createMenu: function(newNode, uri, fileName, id, self, pic, isAdminPage, isAction) {
            var menu = new Menu({
                targetNodeId: newNode
            });
            menu.set('style', {'border': 'none', 'box-shadow': 'none'});

            //ダウンロード操作用
            var download = null;
            var userAgent = window.navigator.userAgent.toLowerCase();
            if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                download = domConstruct.create('a', {href: '#'});
                //クリックでファイル取得処理に入る
                download.onclick = function() {
                    self.downloadFile(url, fileName);
                };
            }else{
                // FF, Chromeの場合、download属性でファイルダウンロード
                download = domConstruct.create('a', {
                    href: uri,
                    download: fileName
                });
            }

            // ファイル名とメニューとの境界線をセット
            var contentNode = domConstruct.create('div');
            contentNode.innerHTML = fileName;
            domConstruct.place('<hr color=#b0c4de>', contentNode);
            if (pic){
                var previewUri = self.getPreviewUri(uri);
                domConstruct.place('<img src="'+ previewUri + '" width="110">', contentNode);
            }

            //メニューをセット
            domConstruct.place(menu.domNode, contentNode);
            var tooltip = new TooltipDialog({
                content: contentNode
            });
            //
            tooltip.containerNode.onmouseleave = function() {
                popup.close(tooltip);
            };

            // 画像ファイルの場合のみ'開く'をメニューに追加する
            if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
            fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
            fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
            fileName.indexOf('.gif') !== -1 || fileName.indexOf('.bmp') !== -1){
                menu.addChild(new MenuItem({
                    label: '開く',
                    iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
                    onClick: function() {
                        console.log('file open');
                        window.open(uri);
                    }
                }));
            }

            menu.addChild(new MenuItem({
                label: 'ダウンロード',
                iconClass: 'dijitIconSave',
                onClick: function(e) {
                    console.log('file download');
                    console.log(e);
                    //IE対策
                    if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                        download.onclick();
                    } else {
                        download.click();
                    }
                }
            }));

            menu.addChild(new MenuItem({
                label: '削除',
                iconClass: 'dijitIconDelete',
                onClick: function() {
                    if(isAdminPage){
                        if(isAction){
                            // 概況画面から対応履歴の添付を削除
                            for(var i=0; i<self.actionAttachFileList.length; i++) {
                                if(self.actionAttachFileList[i].chronologyAtcFileId === id) {
                                    self.actionAttachFileList.splice(i,1); //id:3の要素を削除
                                }
                            }
                        }else{
                            // 概況画面からクロノロジの添付を削除
                            for(var j=0; j<self.attachFileList.length; j++) {
                                if(self.attachFileList[j].chronologyAtcFileId === id) {
                                    self.attachFileList.splice(j,1); //id:3の要素を削除
                                }
                            }
                        }
                        // サムネイルとメニューを削除
                        domConstruct.destroy(newNode);
                        popup.close(tooltip);
                    }else{
                        self.chain.confirmDel(function(chain) {
                            var promise = Requester.del('/api/chronologies/uploadFile/' + id)
                            .then(function() {
                                chain.infoComplete(function() {
                                    this.chain.hide();
                                    // 該当ファイルを削除
                                    for(var i=0; i<self.attachFileList.length; i++) {
                                        if(self.attachFileList[i].chronologyAtcFileId === id) {
                                            self.attachFileList.splice(i,1); //id:3の要素を削除
                                        }
                                    }
                                    // サムネイルとメニューを削除
                                    domConstruct.destroy(newNode);
                                    popup.close(tooltip);
                                });
                            }, function(error) {
                                console.log(error);
                                chain.infoError(error);
                            });

                            //ローダーの表示
                            Loader.wait(promise);

                        });
                    }
                }
            }));

            menu.startup();
            //メニュー表示処理
            this.own(on(newNode, 'mouseover', lang.hitch(this, function() {
                popup.open({
                    popup: tooltip,
                    around: newNode,
                    orient: ['above-centered']
                });
            })));
            //画面破棄時に一緒に破棄する
            this.own(tooltip);
        },

        /**
         * 添付ファイルをダウンロードする。
         */
        downloadFile: function(url, name) {
            // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
            // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function() {

                var arrayBuffer = this.response;

                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, name);
                }

            };
            xhr.send();

            return false;

        },

        /**
         * プレビューをクリアする
         **/
        clearPreview: function() {

            var length = this.preview.childNodes.length;
            for(var i = 0; i < length; i++) {
                domConstruct.destroy(this.preview.childNodes[0]);
            }

            if(this._attachFileList) {
                this._attachFileList.splice(0, this._attachFileList.length);
            }
        },

        /**
         * プレビュー用の画像ファイルのURIを返す
         **/
        getPreviewUri : function(uri) {
            var previewUri = uri;
            // 画像ファイルの場合
            if(uri.indexOf('.jpg') !== -1 ||uri.indexOf('.jpeg') !== -1 ||
            uri.indexOf('.png') !== -1 || uri.indexOf('.JPG') !== -1 ||
            uri.indexOf('.JPEG') !== -1 || uri.indexOf('.PNG') !== -1 ||
            uri.indexOf('.gif') !== -1 || uri.indexOf('.bmp') !== -1){
                previewUri = uri;
            } else if (uri.indexOf('.xls') !== -1 || uri.indexOf('.xlsx') !== -1) {
                previewUri = 'images/excelicon.png';
            } else if (uri.indexOf('.pdf') !== -1) {
                previewUri = 'images/pdficon.png';
            } else if (uri.indexOf('.doc') !== -1 || uri.indexOf('.docx') !== -1) {
                previewUri = 'images/docicon.png';
            } else if (uri.indexOf('.ppt') !== -1 || uri.indexOf('.pptx') !== -1) {
                previewUri = 'images/ppticon.png';
            } else if (uri.indexOf('.zip') !== -1) {
                previewUri = 'images/zipicon.png';
            } else {
                previewUri = 'images/othericon.png';
            }
            return previewUri;
        }
    });
});
