/**
 * 職員招集 自動招集条件詳細画面用モジュール。
 * @module app/convocation/view/condition/ConvoAutoMailDetailPage
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/text!./templates/ConvoAutoMailDetailPage.html', // テンプレート文字列
    'idis/store/IdisRest',
    'dojo/dom-style',
    'dojo/_base/lang',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/Loader',
    'app/convocation/view/condition/_ConvoAutoMailPageBase',// 基底クラス
    'idis/model/UserInfo',
    'idis/consts/ACL',
    'idis/view/dialog/DialogChain',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/WordCountTextarea',
    'app/convocation/view/condition/ConvoAddGroupDialog',
    'app/convocation/view/condition/ConvoAutoMailRegisterGrid',
    'app/view/form/MunicipalityMultiSelector',
    'app/view/form/PrefectureSelector',
    'app/view/form/WeatherWarnSelector',
    'app/view/form/RainObservationSelector',
    'app/view/form/RiverObservationSelector'
], function(module, declare, template, IdisRest, domStyle, lang,
    Locator, Router, Requester, Loader, _ConvoAutoMailPageBase,
    UserInfo, ACL, DialogChain) {
    /**
     * 職員招集 自動招集条件詳細画面。
     * @class ConvoAutoMailDetailPage
     * @extends module:app/convocation/view/condition/_ConvoAutoMailPageBase~_ConvoAutoMailPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ConvoAutoMailPageBase,
        /** @lends module:idis/view/page/ConvoAutoMailDetailPage~ConvoAutoMailDetailPage# */
        {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            /**
             * 変数の初期化。
             */
            constructor: function() {
                this.cndId = Locator.getQuery().id;
                // データ格納用オブジェクト
                this.tempStore = new IdisRest({
                    idProperty: 'distributionTmplCd',
                    target: '/api/convoMails/temps'
                });
                this.chain = DialogChain.get(this);
            },

            /**
             * DOMノードを生成する関数。
             */
            buildRendering: function() {
                // 継承元のモジュールの処理を実施する
                this.inherited(arguments);
                // 画面項目の初期化
                this.initPage();
                // 画面が破棄された際にダイアログも破棄する
                this.own(this.selectGroupDialog);

                this.initTempInsertGrid();
            },

            startup: function() {
                this.inherited(arguments);
                this.setButtonStatus();
            },

            /**
             * 画面項目の初期化を行う。
             */
            initPage: function() {
                var self = this;
                // 招集条件名と招集パターンの取得
                Requester.get('/api/convoMails/conditions/' + this.cndId, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(function(data) {
                    if (data.autoDistFlg === '0') {
                        domStyle.set(self.deleteButton.domNode, 'display', 'none');
                    }
                	self.form.set('value', data);
                }, function(err) {
                    // 処理失敗時はエラーログを出力し、エラーダイアログを表示
                    console.error(err);
                    self.chain.infoError(err);
                });

//                // 招集事象の取得
//                Requester.get('/api/convoMails/conditions/matters/' + this.cndId, {
//                    headers: {'Content-Type': 'application/json; charset=utf-8'},
//                    handleAs: 'json',
//                    preventCache : true
//                }).then(lang.hitch(this, function(data) {
//                    var value = {
//                        earthquakeIntType : data.earthquakeIntType,
//                        earthquakeOutType : data.earthquakeOutType,
//                        tsunamiIntType : data.tsunamiIntType,
//                        tsunamiOutType : data.tsunamiOutType
//                        // nankaiTroughType : data.nankaiTroughType,
//                        // civilProtectionType: data.civilProtectionType
//                    };
//                    this.parseCheckBoxValue(data, value);
//                    // 招集事象
//                    self.form.set('value', value);
////FIXME
//                    // // 気象警報
//                    // self.weatherCd.set('value', data.weatherCd);
//                    // // 対象市町
//                    // self.municipalityCd.set('value', data.municipalityCd);
//                    // // 対象都道府県（地震）
//                    // self.earthquakePrefectureCd.set('value', data.earthquakePrefectureCd);
//                    // // 対象都道府県（津波）
//                    // self.tsunamiPrefectureCd.set('value', data.tsunamiPrefectureCd);
//                }), function(err) {
//                    // 処理失敗時はエラーログを出力し、エラーダイアログを表示
//                    console.error(err);
//                    self.chain.infoError(err);
//                });
                // 条件対象グループ情報の取得
                Requester.get('/api/convoMails/conditions/groups/' + this.cndId, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                })
                .then(function(data) {
                    // グリッドに一件ずつ追加
                    data.items.forEach(function(item) {
                        self.addDistGroupInfo({
                            id: '' + item.dpDistributionGroupId,
                            name: item.convoGroup,
                            empNum: item.convoStaffNum
                        });

                    });
                }, function(err) {
                    // 処理失敗時はエラーログを出力し、エラーダイアログを表示
                    console.error(err);
                    self.chain.infoError(err);
                });
            },

            /**
             * グループ追加ボタンが押下された際にグループ選択ダイアログを表示する。
             */
            addGroupButtonClick: function() {
                this.selectGroupDialog.show();
            },

            /**
             * 更新ボタンが押下された際の挙動
             */
            onSubmit: function() {
	
	            // formの入力チェック
                if (!this.form.validate()) {
                    return false;
                }
                // 入力値チェック(実際の処理は基底クラス側で定義済み)
                if (!this.validate()) {
                    return false;
                }

                this.chain.confirmPut(function(chain) {
                    var value = this.form.get('value');
                    value.groupCd = this.getGroupCdList();
                    this.setCheckBoxValue(value);
                    value.settingId = this.cndId;
                    var promise = Requester.put('/api/convoMails/conditions', {
                    // リクエストの発行からレスポンス受信後の処理までをpromiseに格納
                    // var promise = Requester.put('/api/convoMails/conditions/' + this.cndId, {
                        data: value
                    }).then(function(){
                        // 処理成功時は完了ダイアログを表示
                        chain.infoComplete(function() {
                            Router.moveTo('convocation/automail');
                        });
                    }, function(err) {
                        // 処理失敗時はエラーログを出力し、エラーダイアログを表示
                        console.error(err);
                        chain.infoError(err);
                    });
                    // ローダの表示
                    Loader.wait(promise);
                });
            },

            /**
             * 削除ボタンが押下された際の処理。
             */
            deleteButtonClick: function() {
                // 削除確認ダイアログの表示
                this.chain.confirmDel(function(chain) {
                    // リクエストの発行からレスポンス受信後の処理までをpromiseに格納
                    var promise = Requester.del('/api/convoMails/conditions/' + this.cndId)
                    .then(function(){
                          // 処理成功時は完了ダイアログを表示
                          chain.infoComplete(function() {
                              Router.moveTo('convocation/automail');
                          });
                    }, function(err) {
                        // 処理失敗時はエラーログを出力し、エラーダイアログを表示
                        console.error(err);
                        chain.infoError(err);
                    });
                    // ローダの表示
                    Loader.wait(promise);
                });
            },

            // パンくずリストのボタンを押下したときの挙動
            onConvoAutoMailAdminPageLinkClick : function(evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('convocation/automail');
            },
            setButtonStatus: function(){
                // 訓練モードの場合、テンプレートから挿入・更新・削除・グループ追加ボタンを非表示とする
                if(UserInfo.isTrainingFlg()){
                    domStyle.set(this.selectTempButton.domNode, 'display', 'none');
                    domStyle.set(this.updateButton.domNode, 'display', 'none');
                    domStyle.set(this.deleteButton.domNode, 'display', 'none');
                    domStyle.set(this.addGroupButton.domNode, 'display', 'none');
                    return;
                }
                var role = UserInfo.getRoleCd();
                // 市本部ユーザ、区本部ユーザ、所属本部ユーザの場合、
                // UserIdに「honbu」を含み -c を含まないユーザだけ更新・削除を可能とする
                if (role === ACL.SHIHONBU_USER || role === ACL.KUHONBU_USER || role === ACL.XXHONBU_USER) {
                    var regexp = /^(?!.*-c).*(?=honbu).*$/;
                    if (!regexp.test(UserInfo.getId())) {
                        domStyle.set(this.cancelButton.domNode, 'display', 'none');
                        domStyle.set(this.updateButton.domNode, 'display', 'none');
                        domStyle.set(this.deleteButton.domNode, 'display', 'none');
                    }
                }
//                this.updateButton.setDisabled(true);
//                this.deleteButton.setDisabled(true);
//                if ( role === ACL.ADMIN_USER) {
//                    this.updateButton.setDisabled(false);
//                }
//                if ( role === ACL.ADMIN_USER) {
//                    this.deleteButton.setDisabled(false);
//                }
            },

            /**
             * テンプレートから挿入ボタンが押下された際の処理。
             */
            onSelectTempButton: function() {
                // テンプレート選択ダイアログの表示
                this.templateInsertDialog.show();
            },

/**
             * テンプレート選択用グリッドの初期化
             */
            initTempInsertGrid: function() {
                var page = this.templateInsertDialog.getChildren()[0];
                page.tempInsertGrid.set('collection', this.tempStore.filter());
                // グリッドの挿入ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'insert'と'ButtonClick'の結合がボタンクリック時のイベント名
                page.tempInsertGrid.on('insertButtonClick', lang.hitch(this, function(evt) {
                    // grid内の件名と本文をformに設定する
                    this.form.set('value', evt.item);
                    this.templateCd = evt.item.distributionTmplCd;
                    this.templateInsertDialog.hide();
                }));
                // グリッドの修正ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'list'と'ButtonClick'の結合がボタンクリック時のイベント名
                page.tempInsertGrid.on('detailButtonClick', lang.hitch(this, function(evt){
                    // テンプレート詳細ダイアログを表示する
                    this.showConvoMailTempDetailDialog(evt.item);
                }));
            },

            /**
             * 詳細ダイアログのformが投稿された際の挙動を設定する。
             */
            initDetailDialog: function() {
                // 詳細ダイアログの最初の子要素が画面の情報
                var dialog = this.convoMailTempDetailDialog;
                var page = dialog.getChildren()[0];

                // 詳細ダイアログからのupdateイベントを取得
                page.on('update', lang.hitch(this, function(evt) {
                    // ダイアログのform情報に当モジュールで保持しているテンプレートコードを追加
                    evt.value.distributionTmplCd = this.distributionTmplCd;
                    // 更新確認ダイアログの表示
                    this.chain.confirmPut(function(chain) {
                        // 更新処理完了まではローディング画面を表示
                        Loader.wait(this.tempStore.put(evt.value)).then(function() {
                            // 更新処理成功時
                            // 詳細ダイアログを閉じる
                            dialog.hide();
                            // 完了ダイアログの表示
                            chain.infoComplete();
                        }, function(error) {
                            // 更新処理失敗時
                            // エラーダイアログの表示
                            chain.infoError(error);
                        });
                    });
                }));

                // 詳細ダイアログからのdeleteイベントを取得
                page.on('delete', lang.hitch(this, function() {
                    // 削除確認ダイアログの表示
                    this.chain.confirmDel(function(chain) {
                        // 削除処理完了まではローディング画面を表示
                        Loader.wait(this.tempStore.remove(this.distributionTmplCd))
                        .then(function() {
                            // 削除処理成功時
                            // 詳細ダイアログを閉じる
                            dialog.hide();
                            // 完了ダイアログの表示
                            chain.infoComplete();
                        }, function(error) {
                            // 削除処理失敗時
                            // エラーダイアログの表示
                            chain.infoError(error);
                        });
                    });
                }));
            }
        });
});
