/**
 * 降灰情報詳細画面用地図レイヤー
 * @module app/volcano/layer/AshBreakingNewsLayer
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/Deferred',
    'dojo/_base/array',
    'dojo/_base/lang',
    'idis/service/Requester',
    'leaflet'
], function(module, declare, Deferred, array, lang,
    Requester, leaflet) {
    return declare(module.id.replace(/\//g, '.'), null, {

        _map: null,
        _layer: null,
        _deferred: null,

        _districtList: [], // 警戒対象自治体リスト
        _polygons: null, // 降灰予報範囲のポリゴン
        _radius: null, // 警戒区域半径

        _popupTitle: '降灰情報',

        // 警報コード毎の色
        _colorsByVwCode: {
            71: 'grey',
            72: 'yellow',
            73: 'red',
            75: 'pink'
        },

        // 警報コード毎のpolygon
        _polygonByVwCode: {
            71: null,
            72: null,
            73: null,
            75: null
        },

        /**
         * コンストラクター
         * @param {*} data
         *              ashBreakingNewsDetailPage.jsからのlayerData
         */
        constructor: function(data) {
            if (data.area && data.area.length > 0) {
                this._districtList = data.area;
            }

            if (data.polygons) {
                this._polygons = data.polygons;
            }

            this._deferred = new Deferred();

            Requester.get('/data/master/municipalities.geojson', {
                preventCache: false

            }).then(lang.hitch(this, function(data) {
                this._layer = leaflet.geoJson(data, {
                    style: lang.hitch(this, this._style),
                    onEachFeature: lang.hitch(this, this._onEachFeature)
                });
                this._deferred.resolve(); // 待ち合わせ解放
            }));
        },

        /**
         * 地図にレイヤーを表示させる
         * @param {leaflet.map}} map
         */
        addTo: function(map) {
            this._map = map;

            // geojson - layer生成を待ち合わせる
            this._deferred.then(lang.hitch(this, function() {
                // 地図にレイヤーを載せる
                this._layer.addTo(this._map);

                // 降灰予報範囲のポリゴンを書く
                if (this._polygons) {
                    this.drawPolygons();
                }
            }));
        },

        /**
         * 降灰予報範囲のpolygonを描く
         */
        drawPolygons: function() {

            // 既存のpolygonがあったら消す
            for (var key in this._polygonByVwCode) {
                if (this._polygonByVwCode.hasOwnProperty(key) && this._polygonByVwCode[key]) {
                    this._map.removeLayer(this._polygonByVwCode[key]);
                    this._polygonByVwCode[key] = null;
                }
            }

            // polygonを作る
            array.forEach(this._polygons, lang.hitch(this, function(item) {
                try {
                    var layer = leaflet.polygon(item.latlngs, {
                        weight: 1,
                        fillColor: this._colorsByVwCode[item.vwCode],
                        fill: true,
                        fillOpacity: 0.7
                    });

                    layer.addTo(this._map);

                    // layer情報を保管
                    this._polygonByVwCode[item.vwCode] = layer;

                } catch (error) {
                    console.error(error);
                    return;
                }
            }));

            this.reorderPolygons();
        },



        reorderPolygons: function() {
            // vwCodeの順に並べる
            var keys = Object.keys(this._polygonByVwCode).sort();

            // 順番にレイヤーを最上位に移動する
            // こうすると, 結果的にvwCodeが大きい順に上から並ぶ
            array.forEach(keys, lang.hitch(this, function(key) {
                if (this._polygonByVwCode[key]) {
                    this._polygonByVwCode[key].bringToFront();
                }
            }));
        },

        /**
         * 警戒対象一覧を更新
         * @param {*} areaList
         *              予報時間選択した際に取得する新たな市区町村リスト
         */
        refreshArea: function(areaList) {
            this._districtList = areaList;
            this._layer.setStyle(lang.hitch(this, this._style));
        },

        /**
         * 降灰予報範囲を更新
         */
        refreshPolygon: function(polygons) {
            this._polygons = polygons;
            this.drawPolygons();
        },

        /**
         * geoJSONのstyle
         * @param {*} feature
         */
        _style: function(feature) {
            // 警戒対象でない時のstyle
            // 外枠だけ
            var defaultStyle = {
                weight: 1,
                fill: false,
                color: '#0D0000',
                lineWidth: 2
            };

            // 警戒対象の自治体一覧に含まれていたらstyleを適用する
            var warnings = array.filter(this._districtList, function(item) {
                return (item.areaICode === feature.properties.N03_007);
            });

            // districtListには同じ自治体は含まれていない前提で, filterされた最初の要素(警報コード)を使う
            if (warnings[0]) {
                return lang.mixin(defaultStyle, {
                    fillColor: this._colorsByVwCode[warnings[0].vwCode],
                    fill: true,
                    fillOpacity: 0.3
                });
            } else {
                return defaultStyle;
            }
        },

        /**
         * 各地物のpopup設定
         * @param {*} feature
         * @param {*} layer
         */
        _onEachFeature: function(feature, layer) {
            var content = '<tr><td colspan=2 style="color:#FF0000"><b>' + this._popupTitle + '</b></td></tr>' +
                // 市町村名
                '<tr><td colspan=2>' +
                (feature.properties.N03_003 ? feature.properties.N03_003 : '') + ' ' +
                (feature.properties.N03_004 ? feature.properties.N03_004 : '') +
                '</td></tr>';

            content += '警戒対象' + '</td></tr>';

            if (content) {
                return layer.bindPopup('<table>' + content + '</table>', { maxWidth: 1000, closeButton: false });
            }
        }
    });
});
