/**
 * 防災体制・報告の新規登録・詳細ダイアログ用の基底モジュール。
 * @module app/disasterprevention/view/_DisasterPreventionPageBase
 */
define([
    'module',
    'app/disaster/model/DisasterType',
    'app/model/DisasterInfo',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-style',
    'dojo/on',
    'dojo/json',
    'dojo/request/iframe',
    'exif-js/exif',
    'idis/consts/USER_TYPE',
    'idis/service/Requester',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    './model/DisasterPreventStatus',
    './model/DistributionType',
    './model/HqSetFlg',
    './model/DisasterType',
    'idis/consts/ACL',
    'app/config',
    './model/HqType',
    //'../../config',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/SimpleTextarea',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/RadioGroup',
    'app/view/form/OrganizationSelector',
    './DisasterPreventionConfirmDialog'
], function(module, DisasterTypeModel, DisasterInfo, declare, lang, domClass,
    domConstruct, domStyle, on, json, iframe, exif, USER_TYPE, Requester, UserInfo, IdisRest, DialogChain,
    _PageBase, InfoDialog, Loader, PrevStatus, DistributionType, HqSetFlg, DisasterType, ACL, config, HqType) {
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    {

        /**
         * データ登録用オブジェクト
         * @type {module:dstore/Store}
         */
        registerStore: null,

        /**
         * データ削除用オブジェクト
         * @type {module:dstore/Store}
         */
        deleteStore: null,

        /**
         * 防災体制ID
         */
        _disasterPreventionId: null,

        /**
         * 災害ID
         */
        _disasterId: null,

        /**
         * 災害種別
         */
        _disasterType: null,

        /**
         * 配信種別 '01':新規, '03':取消, '04':更新, '05':訂正
         */
        distributionType: null,

        /**
         * 体制・職員参集の機能コード
         */
        functionCd : {
            disPrev : 'F05002',
            empGath : 'F05003'
        },

        /**
         * 体制情報未登録フラグ
         * （フラグが立つ場合、新規登録ボタン表示＆職員参集ボタン非表示）
         */
        _noRecordFlg: true,

        /**
         * 詳細ダイアログで最後に開いた要素
         * （取消ダイアログで表示するために利用）
         * @type {Object}
         * @private
         */
        _lastDetailItem: null,

        /**
         * 大阪府災害IDが存在しない場合の確認メッセージ
         */
        _noExtraDisasterIdMessage: '大阪府システムの災害IDが登録されていないため、情報の連携は行われません。 <br>',

        /**
         * 大阪府体制状況IDが存在しない場合の確認メッセージ
         */
        _noExtraDisasterPrevIdMessage: '大阪府システムで管理されていない情報のため、情報の連携は行われません。<br>',
        /**
         * 確認メッセージの続き
         */
        _confirmMessage:'そのまま登録・大阪府への配信を行う場合は、OKボタンを押して登録を完了して下さい。',

        /**
         * 大阪府連携APIが見つからなかった場合のレスポンス
         */
        _noAPIMessage: '<br>' + '大阪府システムとの通信に失敗したため、連携が行われませんでした。',

        /**
         * 大阪府連携APIで登録不可の災害にリクエストした場合のエラーメッセージ
         */
        _noRegistMessage: '<br>' + '大阪府システムでは災害情報が登録不可の災害であるため、情報の連携が行われませんでした。',

        /**
         * 大阪府連携APIで登録不可の災害にリクエストした場合のエラーコード
         */
        _noRegistCode: '12',

        /**
         * 大阪府連携APIで管理IDが異なるリクエストをした場合
         */
        _noManageMessage: '<br>' + '大阪府システムには存在しない情報のため、登録が行われませんでした。',

        /**
         * 大阪府連携APIで登録不可の災害にリクエストした場合のエラーコード
         */
        _noManageCode: '14',

        /**
         * 大阪府連携APIで未分類のエラーが発生した場合
         */
        _errorMessage: '<br>' + '大阪府システムで情報の連携に失敗しました。',

        // 閲覧モードフラグ
        _viewMode: '0',

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.dialog);
            this.own(this.confirmDialog);
            this.own(this.formDialog);
        },

        /**
         * constructor
         */
        constructor: function() {
            // データ登録用オブジェクト
            this.registerStore = new IdisRest({
                idProperty: 'disasterPrevId',
                target: '/api/disasterPrevention'
            });
            // データ登録用オブジェクト
            this.deleteStore = new IdisRest({
                idProperty: 'disasterPrevId',
                target: '/api/disasterPrevention/delete'
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
            //災害情報を取得する
            Requester.get('/api/disasters/' + this._disasterId)
            .then(lang.hitch(this, function(res) {
                this._disasterType = res.disasterType;
                // 一覧画面の検索欄セット
                if(this.status){
                    // 体制セレクタ
                    this.setStatus();
                }
            }));
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initRegisterPage();
            this.initConfirmDialog();
        },

        /**
         * 体制をセット
         */
        setStatus: function(){
            // 自治体コード
            //var areaSelVal = this.municipalitySelector.get('value')!==config.municInfo.prefMunicCd?
            //    this.municipalitySelector.get('value') : config.municInfo.prefCd;

            // 体制登録対象自治体の区市町村コードと組織カテゴリコードをセット
            // ※大阪市の組織は、区市町村コードで区別できないため、
            //   便宜的にidに組織コード、parentIdに区市町村コードを設定している。
            if (!this.municipalitySelector.tree){
                // 概況画面・配置人員一覧画面から遷移した場合（セレクタ未生成）
                // municipalityCdが組織コードの場合は、サーバー側で区市町村コードを逆引きして再設定する
                this.municipalityCd = this.municipalitySelector.get('value');
                // dpDeptCdは設定された値をそのまま使用する
                this.dpDeptCd = (!this.dpDeptCd) ? '' : this.dpDeptCd;
            } else {
                // 組織セレクタを選択した場合
                // municipalityCdが組織カテゴリコードの場合は、parentIdの値を区市町村コードに再設定する。
                this.municipalityCd = this.municipalitySelector.get('value').substr(0,1) ==='D' ?
                    this.getDpInfo(this.municipalitySelector.get('value'),'parentId') :
                    this.municipalitySelector.get('value');
                // municipalityCdが組織カテゴリコード以外の場合は区市町村コードに紐づくtypeの値を組織カテゴリコードに再設定する。
                this.dpDeptCd = this.municipalitySelector.get('value').substr(0,1) ==='D' ?
                    this.municipalitySelector.get('value') :
                    this.getDpInfo(this.municipalitySelector.get('value'),'type');
                    // this.dpDeptCd;
            }
            // 津波は地震と同じ体制
            var disasterType = this._disasterType===DisasterType.TSUNAMI_DISASTER ?
                    DisasterType.EARTHQUAKE_DISASTER : this._disasterType;
            var noItem = true;
            // 大阪市判別フラグ
            var areaFlg = this.municipalitySelector.get('value') === undefined ? 
            UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd :
            this.municipalitySelector.get('value') === config.municInfo.cityMunicCd;

            this.status.set('query', function(item) {
                // 指定の災害種別の体制、解除、ブランクをセット
                // 大阪市の場合は固有の体制コード
                if(areaFlg) {
                    // 指定の災害種別の体制をセット
                    if((item.areaCd !== null && item.areaCd !== undefined) &&
                    item.areaCd === config.municInfo.cityMunicCd && 
                        disasterType!==null && disasterType===item.disasterType){
                        noItem = false;
                        return item;
                    // 詳細ダイアログの場合解除も追加
                    } else if ((item.areaCd !== null && item.areaCd !== undefined) &&
                        item.areaCd === config.municInfo.cityMunicCd && item.label==='解除'){
                        return item;
                    } else if(item.code==='') {
                        return item;
                    }
                 // 大阪市以外の場合
                } else {
                    // 指定の災害種別の体制をセット
                    if((item.areaCd === null || item.areaCd === undefined) && 
                        disasterType!==null && disasterType===item.disasterType){
                        noItem = false;
                        return item;
                    // 詳細ダイアログの場合解除も追加
                    } else if ((item.areaCd === null || item.areaCd === undefined) && item.label==='解除') {
                        return item;
                    } else if(item.code==='') {
                        return item;
                    }
                }
            });
            if(noItem){
                this.status.set('store', PrevStatus);
                this.status.set('query', function(item) {
                    if(item.disasterType===DisasterType.OTHER_DISASTER||item.label==='解除'||item.code===''){
                        return item;
                    }
                });
            }
        },

        /**
         * 新規登録ダイアログを表示する。
         * テンプレートHTMLでdata-dojo-attach-eventプロパティーを用い、
         * 新規登録ボタンのクリックイベントにこのメソッドを紐付けている。
         */
        showRegisterDialog: function() {
            var page = this.dialog.getChildren()[0];
            // 体制登録対象の区市町村コードが未設定の場合（概況画面）：ユーザ情報から設定
            if(!this.municipalityCd){
                if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
            //     // if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY || 
            //     //     UserInfo.getOrganization().deptCd === config.dept.crisisManagementDeptCd || 
            //     //     UserInfo.getRoleCd() === 'R01001'){
            //         // ・区ユーザーの場合、「区市町村コード」を態勢登録対象に設定する
            //         // ・ユーザが危機管理室またはシステム管理者の場合、
            //         //  「大阪市」の体制登録のため「区市町村コード」を設定
                    this.municipalityCd = UserInfo.getMunicipalityCd();
                } else {
            //         // 上記以外の場合（大阪市の組織）「組織カテゴリコード」を体制登録対象に設定する
                    // this.municipalityCd = UserInfo.getOrganization().deptCd;
                    this.municipalityCd = !this.dpDeptCd ? UserInfo.getOrganization().deptCd:this.dpDeptCd;
                }
            } else {
            //     // 選択値がある場合（一覧画面）：選択値から設定
            //     // 以下の場合は、「組織カテゴリコード」を体制登録対象に設定する
            //     // ・区市町村コードが大阪市かつ組織カテゴリコード設定済（大阪市の組織）
            //     // if(this.municipalityCd === config.municInfo.cityMunicCd && this.dpDeptCd ||
            //     // if(this.municipalityCd === config.municInfo.cityMunicCd && this.dpDeptCd){
                if(this.municipalityCd === config.municInfo.cityMunicCd && this.dpDeptCd){
            //         // ・ユーザが危機管理室またはシステム管理者の場合、かつ、
            //         // 組織カテゴリコード設定済、かつ、対象が区市町村でない場合
            //         // （危機管理室またはシステム管理者による「大阪市」の体制登録）
            //         // ((UserInfo.getOrganization().deptCd === config.dept.crisisManagementDeptCd || 
            //         // UserInfo.getRoleCd() === 'R01001') &&
            //         //     this.dpDeptCd && this.municipalityCd.substring(0,2) !== config.municInfo.prePrefCd)){
                            this.municipalityCd = this.dpDeptCd;
                }
            //     // 上記以外の場合、選択値の「区市町村コード」を体制登録対象に設定する
            //     // （区の体制登録、危機管理室またはシステム管理者による「大阪市」の体制登録）
            }
            page.initDialog(this);
            this.dialog.show();
        },

        /**
         * 登録画面のフォームが投稿された際の動作を設定する。
         */
        initRegisterPage: function() {
            // 登録ダイアログの最初の子要素が登録画面
            var page = this.dialog.getChildren()[0];
            page.initDialog(this);
            // 登録画面のsendイベントを受け取る
            page.on('post-send', lang.hitch(this, function(evt) {
                var sendData = lang.mixin({
                    disasterId: this._disasterId
                }, evt.value);
                // 災害種別名
                sendData.disasterTypeName = DisasterTypeModel.get(sendData.disasterType).label;
                //                  //添付ファイルIDをセット
                // var attachFileList = sendData.attachFileList;
                //                  if(attachFileList.length > 0) {
                //                      var attachFileIds = [];
                //                      for(var i = 0; i < attachFileList.length; i++) {
                //                          attachFileIds.push(attachFileList[i].disasterPrevAtcFileId);
                //                      }
                //                        sendData.attachFileIds = attachFileIds.join(',');
                //                  } else {
                //                        sendData.attachFileIds = '';
                //                  }
                sendData.attachFileIds = '';
                delete sendData.attachFile;
                delete sendData.attachFileList;
                sendData.extraDisasterId = DisasterInfo.getExtraDisasterId();

                // 配信対象の場合、配信情報確認画面を表示
                if (sendData.provideFlg) {
                        // 配信種別 新規
                        this.distributionType = DistributionType.REPORT;
                        // 登録情報確認ダイアログ
                        this.showConfirmDialog(sendData);
                        return;
                }

                // 外部配信対象外の場合、共通の登録確認画面
                var self = this;
                var message = this.createConfirmMessage(sendData);
//                this.chain.confirmAdd(function(chain) {
                this.chain.confirm(message, function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    var compMessage;
                    Loader.wait(this.registerStore.add(sendData)).then(function(data) {
                    compMessage = self.createCompMessage(data);
                        // chain.infoComplete(function() {
                        chain.info(compMessage, '完了', function() {
                            self.updateGridQuery();
                            self._buttonFlg = false;
                            self.dialog.hide();
                            chain.hide();
                        });
                    }, function(err) {
                        if (err && err.response && err.response.status === 409) {
                            // すでに体制状況が登録されている場合はエラー（409）
                            new InfoDialog({
                                title: 'エラー',
                                content: '選択された組織では既に体制が登録されています',
                                showCancel: false,
                                onOK: function() {
                                    chain.hide();
                                    location.reload();
                                }
                            }).show();
                        } else {
                            // 失敗時
                            chain.infoError(err);
                        }
                    });
                });

            }));
        },

        /**
         * 詳細ダイアログを表示する。
         * @param {Object} item 参照する行のデータ
         */
        showDetailDialog: function(item) {
            // 最後に開いたデータの項目を保持
            // 配信取消ダイアログでオリジナルの情報を表示するために利用
            this._lastDetailItem = item;
            // 閲覧モードフラグをセット
            item.viewMode = this._viewMode;
            this.innerDetailDialog.initDialog(this, item);
            this.detailDialog.show();
        },

        /**
         * 配信情報(体制情報)ダイアログを表示する。
         * @param {Object} obj 体制情報
         * @param {string} obj.hqSetFlg 本部設置状況
         * @param {string} obj.status 体制状況
         */
        showConfirmDialog: function(obj) {
            var item = obj;
            // 本部設置→本部未設置への更新・訂正の場合、本部設置情報の取消として配信→送信時に更新で配信するよう変更
            // 更新ボタンまたは訂正ボタンの押下
            if((this.distributionType===DistributionType.UPDATE_BTN ||
                this.distributionType===DistributionType.CORRECT_BTN) &&
                // 前報が「設置」かつ「災害対策本部」
                (this._lastDetailItem.hqSetFlg===HqSetFlg.ESTABLISH && 
                    this._lastDetailItem.hqType===HqType.RESPONSE_HQ) &&
                // 最新報が「未設置」
                (item.hqSetFlg===HqSetFlg.NOT_YET)){
                    this.distributionType = DistributionType.UPD_CRT_BTN_CANCEL;
                    item = this._lastDetailItem;
                }
            // 本部解散の場合、廃止日時をセット
            var headquarterDatetime = item.hqSetFlg===HqSetFlg.ESTABLISH ?
                    (item.hqSetTimestampMS || item.hqSetTimestamp) :
                    (item.hqSetTimestampMS || item.hqAbolishedTimestamp);
            // 本部解散の場合、解散する本部名（更新/訂正前本部名）をセット
            var headquarterName = item.hqName;
            if(this._lastDetailItem) {
                headquarterName = item.hqSetFlg===HqSetFlg.ESTABLISH ? item.hqName : this._lastDetailItem.hqName;
            }
            var args = {
                municipalityCd : obj.municipalityCd,
                headquarterName : headquarterName,
                hqSetFlg: obj.hqSetFlg,
                status: obj.status,
                headquarterDatetime: headquarterDatetime,
                distributionType: this.distributionType,
                isUnsentLalert: obj.isUnsentLalert,
                sendData: obj
            };
            // ダイアログの最初の子要素が登録画面
            var dialog = this.confirmDialog.getChildren()[0];
            // ダイアログの画面を初期化する。
            dialog.initDialog(args);
            // ダイアログを表示する。
            this.confirmDialog.show();
        },

        /**
         * 配信情報(体制情報)確認ダイアログを初期化する。
         */
        initConfirmDialog: function(){
            // ダイアログの最初の子要素が登録画面
            var dialog = this.confirmDialog.getChildren()[0];
            // 配信情報確認画面のsendイベントを受け取る
            dialog.on('distribution-send', lang.hitch(this, function(evt) {
                var sendData = evt.value;
                // 配信情報をセット
                sendData.provideFlg = true;
                var confirmMessage = this.createConfirmMessage(sendData);
                // var chain = this.chain;
                if (this.distributionType===DistributionType.REPORT){
                    // 新規登録
                    delete sendData.errataDescription;
                    this.chain.confirm(confirmMessage, function(chain) {
                        Loader.wait(this.registerStore.add(sendData)).then(lang.hitch(this, function(data) {
                        var compMessage = this.createCompMessage(data);
                            // chain.infoComplete(function() {
                            chain.info(compMessage, '完了', function() {
                                this._noRecordFlg = false;
                                this.updateGridQuery();
                                this.dialog.hide();
                                dialog._closeDialog();
                                chain.hide();
                            });
                        }), function(err) {
                            if (err && err.response && err.response.status === 409) {
                                // すでに体制状況が登録されている場合はエラー（409）
                                new InfoDialog({
                                    title: 'エラー',
                                    content: '選択された組織では既に体制が登録されています',
                                    showCancel: false,
                                    onOK: function() {
                                        chain.hide();
                                        location.reload();
                                    }
                                }).show();
                            } else {
                                // 失敗時
                                chain.infoError(err);
                            }
                        });
                    });
                } else if (this.distributionType===DistributionType.CANCEL){

                    this.chain.confirm(confirmMessage, function(chain) {
                    // 取消
                    /*var removePromise = this.deleteStore.put({
                            disasterPrevId: sendData.disasterPrevId,
                            provideFlg: sendData.provideFlg,
                            complementaryInfo: sendData.complementaryInfo,
                            errataDescription: sendData.errataDescription,
                            allDelete: sendData.allDelete,
                            version: sendData.version,
                            extraDisasterId: DisasterInfo.getExtraDisasterId()
                    });*/
                        Loader.wait(this.deleteStore.put({
                            disasterPrevId: sendData.disasterPrevId,
                            provideFlg: sendData.provideFlg,
                            complementaryInfo: sendData.complementaryInfo,
                            errataDescription: sendData.errataDescription,
                            allDelete: sendData.allDelete,
                            version: sendData.version,
                            extraDisasterId: DisasterInfo.getExtraDisasterId()
                        })).then(lang.hitch(this, function(data) {
                            // chain.infoComplete(lang.hitch(this, function(data) {
                            var compMessage = this.createCompMessage(data);
                            chain.info(compMessage, '完了', function() {
                                // 取消成功時
                                this.updateGridQuery();
                                // 各ダイアログを閉じる
                                this.detailDialog.hide();   // 詳細ダイアログ
                                dialog._closeDialog();      // 確認ダイアログ
                                chain.hide();               // 完了ダイアログ
                            // }));
                            });
                        }), function(err) {
                            if (err && err.response && err.response.status === 409) {
                                // リスト参集情報が1件でも登録・更新されている場合はエラー（409）
                                var message =  '<br>' +'エラーが発生しました。' + '<br>' +
                                    '同一のデータを別の方が更新しています。' +  '<br>' +
                                    '「OK」を押して最新の状態をご確認の上、' +  '<br>' +
                                    '再度処理をお願いします。' +  '<br>' +
                                    '(OKボタンを押すと画面が更新されます)';
                                new InfoDialog({
                                    title: 'エラー',
                                    content: message,
                                    showCancel: false,
                                    // 「OK」ボタン押下後はページをリロードする
                                    onOK: function() {
                                        chain.hide();
                                        location.reload();
                                    }
                                }).show();
                            } else if (err.response.data && typeof(err.response.data) === 'string'){
                                    err.response.data = json.parse(err.response.data);
                            } else {
                                // 失敗時
                                chain.infoError(err);
                            }

                        });
                    });
                } else {
                    // 更新・訂正
                    // 配信種別をセット（更新/訂正でなければ、前報取消）
                    sendData.disPrevDistributionType =
                        (this.distributionType===DistributionType.UPDATE_BTN || 
                            this.distributionType===DistributionType.CORRECT_BTN ||
                            // 前報が災害対策本部の設置(UPD_CRT_BTN_CANCEL)の場合でも、前報取消にせずUPDATEにする）
                            this.distributionType===DistributionType.UPD_CRT_BTN_CANCEL) ?
                            DistributionType.UPDATE : DistributionType.CANCEL;
                    this.chain.confirm(confirmMessage, function(chain) {
                        Loader.wait(this.registerStore.put(sendData)).then(lang.hitch(this, function(data) {
                        var compMessage = this.createCompMessage(data);
                            // chain.infoComplete(lang.hitch(this, function() {
                            chain.info(compMessage, '完了', function() {
                                // 取消成功時
                                this.updateGridQuery();
                                // 各ダイアログを閉じる
                                this.detailDialog.hide();   // 詳細ダイアログ
                                dialog._closeDialog();      // 確認ダイアログ
                                chain.hide();               // 完了ダイアログ
                            // }));
                            });
                        }), function(err) {
                            // 失敗時
                            if (err && err.response && err.response.status === 409) {
                                // リスト参集情報が1件でも登録・更新されている場合はエラー（409）
                                var message =  '<br>' +'エラーが発生しました。' + '<br>' +
                                    '同一のデータを別の方が更新しています。' +  '<br>' +
                                    '「OK」を押して最新の状態をご確認の上、' +  '<br>' +
                                    '再度処理をお願いします。' +  '<br>' +
                                    '(OKボタンを押すと画面が更新されます)';
                                new InfoDialog({
                                    title: 'エラー',
                                    content: message,
                                    showCancel: false,
                                    // 「OK」ボタン押下後はページをリロードする
                                    onOK: function() {
                                        chain.hide();
                                        location.reload();//最新報でない場合も最新報でリロードしてしまう
                                    }
                                }).show();
                            } else if (err.response.data && typeof(err.response.data) === 'string'){
                                err.response.data = json.parse(err.response.data);
                            } else {
                                // 失敗時
                                chain.infoError(err);
                            }
                        });
                    });
                }
            }));
        },

        /**
         * 新規登録ボタン/職員参集管理ボタンの表示/非表示を切り替える
         */
        changeButton: function() {
            // デフォルト 不可
            domStyle.set(this.registerButton.domNode, 'display', 'none');
            domStyle.set(this.empGatheringButton.domNode, 'display', 'none');
            // 登録情報がない場合
            if(this._noRecordFlg){
                // 新規登録-> 操作権限のあるユーザー＆所属している市区町村＆閲覧モード（viewMode=1）でないならば登録可能
                if(UserInfo.hasWriteAuthz(this.functionCd.disPrev) && this._viewMode !== '1'){
                    switch (UserInfo.getUserType()) {
                        case USER_TYPE.MUNICIPALITY:
                            if(UserInfo.getMunicipalityCd()===this.municipalityCd ||
                                UserInfo.getMunicipalityCds().indexOf(this.municipalityCd)>-1){
                                    domStyle.set(this.registerButton.domNode, 'display', '');
                            }
                            break;
                        case USER_TYPE.PREFECTURE:
                            // ログインユーザが所属本部ユーザの場合は、自組織のみ可
                            if (UserInfo.getRoleCd() === ACL.XXHONBU_USER && 
                                this.municipalityCd === UserInfo.getOrganization().deptCd){
                                domStyle.set(this.registerButton.domNode, 'display', '');
                            }
                            // ログインユーザがシステム管理者・全体管理者・市本部ユーザの場合
                            // 全組織登録可（代理登録）
                            if (UserInfo.getRoleCd() === ACL.ADMIN_USER || UserInfo.getRoleCd() === ACL.SYSMGR_USER ||
                                    UserInfo.getRoleCd() === ACL.SHIHONBU_USER){
                                domStyle.set(this.registerButton.domNode, 'display', '');
                            }
                            
                            break;
    
                        case USER_TYPE.REGION:
                            if(UserInfo.getMunicipalityCds().indexOf(this.municipalityCd)>-1){
                                domStyle.set(this.registerButton.domNode, 'display', '');
                            }
                            break;
                    }
                }

            // 登録情報がある場合
            } else {
                domStyle.set(this.empGatheringButton.domNode, 'display', 'none');
                // 職員参集画面への遷移 -> 操作権限のあるユーザー＆管内の市町村であれば可
                var municipalityCd = this.municipalitySelector !== undefined ?
                this.municipalitySelector.get('value') : null;
                if (municipalityCd === null) {
                    municipalityCd = UserInfo.getMunicipalityCd();
                }
                // if(UserInfo.hasWriteAuthz(this.functionCd.empGath)){
                    switch (UserInfo.getUserType()) {
                        case USER_TYPE.MUNICIPALITY:
                            if(UserInfo.getMunicipalityCd()===this.municipalityCd){
                                domStyle.set(this.empGatheringButton.domNode, 'display', '');
                            }
                            break;
                        case USER_TYPE.REGION:
                            if(UserInfo.getMunicipalityCds().indexOf(this.municipalityCd)>-1){
                                domStyle.set(this.empGatheringButton.domNode, 'display', '');
                            }
                            break;
                        case USER_TYPE.PREFECTURE:
                            domStyle.set(this.empGatheringButton.domNode, 'display', '');
                            break;
                        case USER_TYPE.OTHER_ORGAN:
                            if(UserInfo.getMunicipalityCd()===this.municipalityCd){
                                domStyle.set(this.empGatheringButton.domNode, 'display', '');
                            }
                            break;
                    }
                // }
                // 大阪市の体制の場合、職員参集画面への遷移を抑止（大阪市はダイアログ上で全体集計と明細入力を行う）
                if(this.municipalityCd === config.municInfo.cityMunicCd && this.dpDeptCd === null){
                    domStyle.set(this.empGatheringButton.domNode, 'display', 'none');
                }
            }
        },
        /**
         * 新規登録ボタンの表示/非表示を切り替える（概況画面用）
         */
        changeRegButton: function() {
            // デフォルト 不可
            domStyle.set(this.registerButton.domNode, 'display', 'none');
            // 登録情報がない場合
            if(this._noRecordFlg){
                // 新規登録-> 操作権限のあるユーザー かつ 閲覧モード（viewMode=1）でないならば可
                if(UserInfo.hasWriteAuthz(this.functionCd.disPrev) && this._viewMode !== '1'){
                    domStyle.set(this.registerButton.domNode, 'display', '');
                }

                // 職員参集画面への遷移 -> 操作権限の有無に関わらず不可（登録情報がないので）
                domStyle.set(this.empGatheringButton.domNode, 'display', 'none');
            }else{
                // 登録情報がある場合、職員参集画面への遷移（参照可）
                domStyle.set(this.empGatheringButton.domNode, 'display', '');
            }
        },
        /**
         * 体制登録対象の区市町村・組織の情報を取得
         */
        getDpInfo: function(dpMunicipalityCd,req){
            if(!this.municipalitySelector.tree){
                return '';
            } else if (!this.municipalitySelector.tree.model.items){
                return '';
            } else {
                var dpItems = this.municipalitySelector.tree.model.items;
                var res = '';
                if(dpItems){
                    dpItems.some(function(dpItem){
                        // 選択された自治体
                        if(dpItem && (dpItem.id === dpMunicipalityCd)){
                            if(req === 'type'){
                                // 選択された自治体のtypeに設定された組織カテゴリコードを取得
                                res = dpItem.type;
                            } else if (req === 'parentId'){
                                // 選択された自治体のparentIdに設定された区市町村コードを取得
                                res = dpItem.parentId;
                            }
                        }
                    }, this);
                }
                return res;
            }
        },
        /**
         * 概況情報をエクセル形式で出力します。
         */
        onOutputButtonClick: function() {
            this.formDialog.show();
        },

        /**
         * 完了メッセージの作成を行う
         */
        createCompMessage: function(data) {
            var compMessage = '完了しました。';
            if(data.statusCd === null) {
                return compMessage;
            }else if(data.statusCd === 500){
                if(data.errCode !== null) {
                    if(data.errCode === this._noRegistCode) {
                        compMessage += this._noRegistMessage;
                    } else if(data.errCode === this._noManageCode) {
                        compMessage += this._noManageMessage;
                    } else {
                        compMessage += this._errorMessage;
                    }
                } else {
                    compMessage += this._errorMessage;
                }
            } else if(data.statusCd < 200 || data.statusCd > 300) {
                compMessage += this._noAPIMessage;
            }
            return compMessage;
        },

        /**
         * 確認メッセージの作成を行う
         */
        createConfirmMessage: function(data) {
            var confirmMessage = '登録及び大阪府への配信を行います。よろしいですか？';
            // 登録か、更新か、訂正か、削除で置換する文字列
            var replaceMessage = this._confirmMessage;
            if(this.distributionType === DistributionType.UPDATE_BTN) {
                replaceMessage = replaceMessage.replace(/登録/g, '更新');
                confirmMessage = confirmMessage.replace(/登録/g, '更新');
            }else if(this.distributionType === DistributionType.CORRECT_BTN){
                replaceMessage = replaceMessage.replace(/登録/g, '訂正');
                confirmMessage = confirmMessage.replace(/登録/g, '訂正');
            }else if(data.allDelete !== undefined){
                replaceMessage = replaceMessage.replace(/登録/g, '削除');
                if(data.allDelete) {
                    confirmMessage = 'すべての報告を' + confirmMessage.replace(/登録/g, '削除');
                } else {
                    confirmMessage = 'この報告を' + confirmMessage.replace(/登録/g, '削除');
                }
            }
            if(!data.provideFlg) {
                replaceMessage = replaceMessage.replace(/・大阪府への配信/, '');
                confirmMessage = confirmMessage.replace(/及び大阪府への配信を行います/, 'します');
            }else{
                if(DisasterInfo.getExtraDisasterId() === '0') {
                    confirmMessage = this._noExtraDisasterIdMessage + replaceMessage;
                // 大阪市では連携先防災体制ID(extraDisasterPrevId)が設定されることはないため判定不要
                // } else if(!data.extraDisasterPrevId && data.extraDisasterPrevId === null &&
                //     data.disasterPrevId !== null && !(data.updateFlg === 0 && 
                //         this.distributionType === DistributionType.UPDATE_BTN)) {
                //         confirmMessage = this._noExtraDisasterPrevIdMessage + replaceMessage;
                }
            }
            return confirmMessage;
        }
    });
});
