/**
 * 利用者情報 動員種別
 * @module app/formDisplay/displayType
 */
define([
    'module',
    'dojo/store/Memory'
    // 以下、変数で受けないモジュール
], function(module, Memory) {
    // シングルトンを返す
    return new Memory({
        idProperty: 'code',
        data: [
            {'code':1, 'label':'地震の概要'},
            {'code':2, 'label':'災害対応状況'},
            {'code':3, 'label':'被害件数'},
            {'code':4, 'label':'市民への情報発信状況'},
            {'code':5, 'label':'緊急通知'},
            {'code':6, 'label':'関係機関情報'},
            {'code':7, 'label':'ライフライン情報'},
            {'code':8, 'label':'被害一覧'},
            {'code':9, 'label':'市有施設被害一覧'},
            {'code':10, 'label':'避難所開設状況（150）'},
            {'code':11, 'label':'避難所開設状況（300）'},
            {'code':12, 'label':'河川水位'},
            {'code':13, 'label':'断水情報'},
            {'code':14, 'label':'応急給水情報'},
            {'code':15, 'label':'消防局災害発生状況'}
        ]
    });
});
