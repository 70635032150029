/**
 * 配信設定一覧グリッド(招集メール配信ダイアログ用)。
 * @module app/convocation/view/mail/ConvoSendAutoMailGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'dojo/_base/lang',
    'idis/view/grid/helper'
], function(module, declare, IdisGrid, lang, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:idis/view/page/ConvoSendAutoMailGrid~ConvoSendAutoMailGrid# */ {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        collection: this.store,
        columns: [
            helper.buttonColumn('detail', '詳細'),
            helper.column('settingName', '配信テンプレート名'),
            {field: 'autoDistFlg', label: '配信種別',
                formatter: lang.hitch(this, function(item){
                    if(item === '1') {
                        return '手動配信';
                    } else {
                        return '自動配信';
                    }
                })
            },
            helper.buttonColumn('distribution', '配信')
        ]
    });
});
