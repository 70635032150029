/**
 * 地図電子ファイル新規登録モジュール
 * @module app/view/page/GeoFileRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/on',
    'dojo/request/iframe',
    'dojo/text!./templates/GeoFileRegisterDialog.html',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'idis/model/UserInfo',
    // 以下、変数として受け取らないモジュール
    'idis/view/form/Button',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/form/Textarea',
    'dojox/form/Uploader',
    'dijit/form/ValidationTextBox',
    'dijit/TitlePane',
    '../view/form/LayerDirectorySelector',
    '../view/form/OrganizationSelector'
], function(module, declare, lang, domStyle, on, iframe, template, InfoDialog, _PageBase, Loader, UserInfo) {
    /**
     * 地図電子ファイル新規登録
     * @class GeoFileRegisterDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/GeoFileRegisterDialog~GeoFileRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,

        startup: function() {
            // 種別選択時の登録画像の拡張子の表示/非表示を制御する
            // 種別選択時の元データの測地系、座標系の拡張子の表示/非表示を制御する
            this.own(on(this.inputDataFormat, 'change', lang.hitch(this, function(evt){
                domStyle.set(this.extensionArea, 'display', evt === '5' ? '' : 'none');
                domStyle.set(this.coordinateArea, 'display', evt === '5' ? 'none' : '');
                domStyle.set(this.geodeticArea, 'display', evt === '5' ? 'none' : '');
            })));

            // 新規フォルダ記入エリアの表示/非表示を制御する
            this.own(on(this.newLayerFlg, 'change', lang.hitch(this, function(evt) {
                if(evt) {
                    this.newLayer.set('style', {display: ''});
                    this.newLayer.set('value', '');
                } else {
                    this.newLayer.set('style', {display: 'none'});
                }
            })));

            // 種別選択時に出力形式の表示/非表示を制御する
            // 種別選択時にstyle.js設定エリアの表示/非表示を制御する
            this.own(on(this.inputDataFormat, 'change', lang.hitch(this, function(evt) {
                if(evt === '3' || evt === '4') {
                    domStyle.set(this.outputDataFormatArea, 'display', '');
                    domStyle.set(this.styleFileArea, 'display', '');
                } else {
                    domStyle.set(this.outputDataFormatArea, 'display', 'none');
                    domStyle.set(this.styleFileArea, 'display', 'none');
                    this.outputDataFormat.set('value', '');
                    this.styleFileName.innerHTML = '';
                    this.tmpStyleFileName.set('value', '');
                    this.tmpStyleFilePath.set('value', '');
                }
            })));

            this.geoFileName.innerHTML = '';
            this.styleFileName.innerHTML = '';
            this.tmpGeoFileName.set('value', '');
            this.tmpGeoFilePath.set('value', '');
            this.tmpStyleFileName.set('value', '');
            this.tmpStyleFilePath.set('value', '');
        },

        /**
         * データ格納領域を初期化し、データを設定する
         */
        setPage: function(){
            // ログインユーザの組織コードを取得する
            var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
            UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
            UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');

            // ログインユーザの組織情報を報告部署に設定する
            this.organization._setValueAttr(orgCd);

            // ファイル情報を初期化する
            this.geoFileName.innerHTML = '';
            this.styleFileName.innerHTML = '';
            this.tmpGeoFileName.set('value', '');
            this.tmpGeoFilePath.set('value', '');
            this.tmpStyleFileName.set('value', '');
            this.tmpStyleFilePath.set('value', '');

            // 出力形式、登録画像の拡張子は非表示とする
            domStyle.set(this.outputDataFormatArea, 'display', 'none');
            domStyle.set(this.extensionArea, 'display', 'none');
            domStyle.set(this.styleFileArea, 'display', 'none');

            // 表示ズームレベルの初期値を設定する
            this.minZoom.set('value', '8');
            this.maxZoom.set('value', '18');
        },

        /**
          * 登録を実行する
          */
        regist: function() {
            // 既にアップロード済のファイルを送信対象から外す為、一時的にdisabledにする
            this.geoFile.set('disabled', true);
            this.styleFile.set('disabled', true);

            // formに定義されている情報を設定する
            var sendData = this.form.get('value');
            sendData.outputDataFormat = this.outputDataFormat.get('value');
            sendData.minZoom = this.minZoom.get('value');
            sendData.maxZoom = this.maxZoom.get('value');
            sendData.pubStatus = '4';
            sendData.pubSectCd = '';

            // EPSGコードを設定する
            var coordinate = this.coordinateR.get('value');
            var geodetic = this.geodeticR.get('value');
            var epsgCd = null;
            if(coordinate !== null && coordinate !== '' && geodetic !== null && geodetic !== ''){
                epsgCd  = this.getEpsgCode(coordinate, geodetic);
            }
            sendData.epsgCd = epsgCd;

            console.debug(sendData);
            try {
                if (this.form.validate() && this.isEpsgValid(coordinate, geodetic) &&
                    this.isGeofileRegisterValid(sendData)) {
                    this.emit('send', {
                        value: sendData
                    });
                }
            } catch (e) {
                console.error(e);
            }

            // 一時的にdisabledにしたアップロード済のファイルを解除する
            this.geoFile.set('disabled', false);
            this.styleFile.set('disabled', false);
            return false;
        },

        /**
        * 選択されたファイルをアップロードする（地図電子ファイル）
        **/
        loadGeoFile: function() {
            // ファイルが空の場合は処理を中断する
            if(this.geoFile._files.length === 0) {
                return;
            }
            // ファイルの拡張子が.zip、.kml、.kmz以外の場合は処理を中断する
            var fileName = this.geoFile._files[0].name;
            console.debug('file change');
            console.debug(fileName);
            if((fileName.indexOf('.zip') === -1) &&
                (fileName.indexOf('.kml') === -1) && (fileName.indexOf('.kmz') === -1)){
                InfoDialog.show('エラー',
                                '選択したファイルが適切ではありません。<br><br>' +
                                'KMLを選択した場合、.kml、.kmzの拡張子のファイルを選択して下さい。<br>' +
                                'それ以外の場合は、.zipの拡張子のファイルを選択して下さい。');
                return;
            }

            var self = this;

            // 地図電子ファイル以外の要素を送信対象からはずす
            this.setForm4UploadFile();
            this.geoFile.set('disabled', false);

            // ファイルがonloadされたときにサーバーに一時保存する
            var promise = iframe.post('/api/geofile/uploadFile', {
                form: this.form.id,
                handleAs: 'json'
            }).then(function(data) {
                console.debug(data);
                // formを戻す
                self.resetForm4UploadFile();
                // 地図電子ファイル情報を設定する
                self.setUploadFileInfo('1', data, fileName);
                //uploaderをリセット
                self.geoFile.reset();
            }, function(error) {
                console.debug(error);
                //formを戻す
                self.resetForm4UploadFile();
                //uploaderをリセット
                self.geoFile.reset();
                self.chain.infoError(error);
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
        * 選択されたファイルをアップロードする（style.js）
        **/
        loadStyleFile: function() {
            // ファイルが空の場合は処理を中断する
            if(this.styleFile._files.length === 0) {
                return;
            }
            // ファイルの拡張子が.js以外の場合は処理を中断する
            var fileName = this.styleFile._files[0].name;
            console.debug('file change');
            console.debug(fileName);
            if(fileName.indexOf('.js') === -1) {
                InfoDialog.show('エラー',
                                '選択したファイルが適切ではありません。<br>' +
                                '.jsの拡張子のファイルを選択して下さい。');
                return;
            }

            var self = this;

            // style.js以外の要素を送信対象からはずす
            this.setForm4UploadFile();
            this.styleFile.set('disabled', false);

            // ファイルがonloadされたときにサーバーに一時保存する
            var promise = iframe.post('/api/geofile/uploadStyleFile', {
                form: this.form.id,
                handleAs: 'json'
            }).then(function(data) {
                console.debug(data);
                // formを戻す
                self.resetForm4UploadFile();
                // 地図電子ファイル情報を設定する
                self.setUploadFileInfo('2', data, fileName);
                //uploaderをリセット
                self.styleFile.reset();
            }, function(error) {
                console.debug(error);
                //formを戻す
                self.resetForm4UploadFile();
                //uploaderをリセット
                self.styleFile.reset();
                self.chain.infoError(error);
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * ファイルアップロードの為、他のフォーム要素を一時的にdisabledにする
         */
        setForm4UploadFile: function() {
            this.newLayer.set('disabled', true);
            this.layerName.set('disabled', true);
            this.inputDataFormat.set('disabled', true);
            this.extension.set('disabled', true);
            this.maxZoom.set('disabled', true);
            this.description.set('disabled', true);
            this.maxNativeZoom.set('disabled', true);
            this.geoFile.set('disabled', true);
            this.tmpGeoFileName.set('disabled', true);
            this.tmpGeoFilePath.set('disabled', true);
            this.tmpStyleFileName.set('disabled', true);
            this.tmpStyleFilePath.set('disabled', true);
        },

        /**
         * ファイルアップロードの為、一時的にdisabledした要素を解除する
         */
        resetForm4UploadFile: function() {
            this.newLayer.set('disabled', false);
            this.layerName.set('disabled', false);
            this.inputDataFormat.set('disabled', false);
            this.geoFile.set('disabled', false);
            this.extension.set('disabled', false);
            this.maxZoom.set('disabled', false);
            this.description.set('disabled', false);
            this.maxNativeZoom.set('disabled', false);
            this.tmpGeoFileName.set('disabled', false);
            this.tmpGeoFilePath.set('disabled', false);
            this.tmpStyleFileName.set('disabled', false);
            this.tmpStyleFilePath.set('disabled', false);
        },

        setUploadFileInfo: function(type, data, fileName){
            if (type === '1') {
                this.geoFileName.innerHTML = fileName;
                this.tmpGeoFileName.set('value', data.tmpGeoFileName);
                this.tmpGeoFilePath.set('value', data.tmpGeoFilePath);
            } else if (type === '2') {
                this.styleFileName.innerHTML = fileName;
                this.tmpStyleFileName.set('value', data.tmpStyleFileName);
                this.tmpStyleFilePath.set('value', data.tmpStyleFilePath);
            }
        },

        /**
         * 入力値をチェックする
         */
        isGeofileRegisterValid: function(data) {
            if (!data.parentLayerId || data.parentLayerId === '') {
                InfoDialog.show('入力エラー', '格納先を選択してください。');
                return false;
            }
            if (!data.inputDataFormat || data.inputDataFormat === '') {
                InfoDialog.show('入力エラー', '種別を選択してください。');
                return false;
            }
            if ((data.inputDataFormat === '3' || data.inputDataFormat === '4' ) &&
                (!data.outputDataFormat || data.outputDataFormat === '')) {
                InfoDialog.show('入力エラー', '出力形式を選択してください。');
                return false;
            }
            if (data.inputDataFormat === '5' && (!data.extension || data.extension === '')) {
                InfoDialog.show('入力エラー', '登録ファイルの拡張子を選択してください。');
                return false;
            }
            /* if (!data.fileName || data.fileName === '') {
                InfoDialog.show('入力エラー', '登録ファイルを選択してください。');
                return false;
            }*/
            if (!data.tmpGeoFileName || data.tmpGeoFileName === '') {
                InfoDialog.show('入力エラー', '登録ファイルを選択してください。');
                return false;
            }
            if (!data.minZoom || data.minZoom === '' || !data.maxZoom || data.maxZoom === '') {
                InfoDialog.show('入力エラー', '表示ズームレベルを選択してください。');
                return false;
            }
            if (Number(data.minZoom) > Number(data.maxZoom)) {
                InfoDialog.show('入力エラー', '表示ズームレベルの範囲が不適切です。');
                return false;
            }
            if ((data.maxNativeZoom || data.maxNativeZoom !== '') &&
                ((Number(data.maxNativeZoom) > Number(data.maxZoom)) ||
                (Number(data.maxNativeZoom) < Number(data.minZoom)))) {
                InfoDialog.show('入力エラー', '最大作成ズームレベルが表示ズームレベルの範囲内にありません。');
                return false;
            }
            if(data.epsgCd === void 0) {
                InfoDialog.show('入力エラー', '元データの測地系、座標系の組み合わせが無効です。<br>適切な組み合わせを選択してください。');
                return false;
            }
            if(data.description.length > 150){
                InfoDialog.show('入力エラー', '情報説明文は150字以下で入力してください。');
                return false;
            }
            return true;
        },

        /**
         * 元データの測地系、座標系の両方が設定されているかをチェックする
         */
        isEpsgValid: function(coordinate, geodetic){
            if((coordinate === '' && geodetic !== '') || (coordinate !== '' && geodetic === '')){
                InfoDialog.show('入力エラー', '元データの測地系、座標系の組み合わせが無効です。<br>適切な組み合わせを選択してください。');
                return false;
            }
            return true;
        },

        /**
         * EPSGコードを取得する
         * @param coordinate 座標系
         * @param geodetic 測地系
         */
        getEpsgCode: function(coordinate, geodetic) {
            var epsgMap=[];
            var epsgMap1=[];
            var epsgMap2=[];
            var epsgMap3=[];
            var epsgMap4=[];

            epsgMap.G0 =epsgMap1;
            epsgMap.G1 =epsgMap2;
            epsgMap.G2 =epsgMap3;
            epsgMap.G3 =epsgMap4;

            epsgMap1.P0 = '104020';
            epsgMap1.P1 = '102610';
            epsgMap1.P2 = '102611';
            epsgMap1.P3 = '102612';
            epsgMap1.P4 = '102613';
            epsgMap1.P5 = '102614';
            epsgMap1.P6 = '102615';
            epsgMap1.P7 = '102616';
            epsgMap1.P8 = '102617';
            epsgMap1.P9 = '102618';
            epsgMap1.P10 = '102619';
            epsgMap1.P11 = '102620';
            epsgMap1.P12 = '102621';
            epsgMap1.P13 = '102622';
            epsgMap1.P14 = '102623';
            epsgMap1.P15 = '102624';
            epsgMap1.P16 = '102625';
            epsgMap1.P17 = '102626';
            epsgMap1.P18 = '102627';
            epsgMap1.P19 = '102628';
            epsgMap1.P20 = '102593';
            epsgMap1.P21 = '102594';
            epsgMap1.P22 = '102595';
            epsgMap1.P23 = '102596';
            epsgMap1.P24 = '102597';
            epsgMap1.P25 = '102598';
            epsgMap2.P0 = '4612';
            epsgMap2.P1 = '2443';
            epsgMap2.P2 = '2444';
            epsgMap2.P3 = '2445';
            epsgMap2.P4 = '2446';
            epsgMap2.P5 = '2447';
            epsgMap2.P6 = '2448';
            epsgMap2.P7 = '2449';
            epsgMap2.P8 = '2450';
            epsgMap2.P9 = '2451';
            epsgMap2.P10 = '2452';
            epsgMap2.P11 = '2453';
            epsgMap2.P12 = '2454';
            epsgMap2.P13 = '2455';
            epsgMap2.P14 = '2456';
            epsgMap2.P15 = '2457';
            epsgMap2.P16 = '2458';
            epsgMap2.P17 = '2459';
            epsgMap2.P18 = '2460';
            epsgMap2.P19 = '2461';
            epsgMap2.P20 = '3097';
            epsgMap2.P21 = '3098';
            epsgMap2.P22 = '3099';
            epsgMap2.P23 = '3100';
            epsgMap2.P24 = '3101';
            epsgMap2.P25 = '102150';
            epsgMap3.P0 = '4301';
            epsgMap3.P1 = '30161';
            epsgMap3.P2 = '30162';
            epsgMap3.P3 = '30163';
            epsgMap3.P4 = '30164';
            epsgMap3.P5 = '30165';
            epsgMap3.P6 = '30166';
            epsgMap3.P7 = '30167';
            epsgMap3.P8 = '30168';
            epsgMap3.P9 = '30169';
            epsgMap3.P10 = '30170';
            epsgMap3.P11 = '30171';
            epsgMap3.P12 = '30172';
            epsgMap3.P13 = '30173';
            epsgMap3.P14 = '30174';
            epsgMap3.P15 = '30175';
            epsgMap3.P16 = '30176';
            epsgMap3.P17 = '30177';
            epsgMap3.P18 = '30178';
            epsgMap3.P19 = '30179';
            epsgMap3.P20 = '3092';
            epsgMap3.P21 = '3093';
            epsgMap3.P22 = '3094';
            epsgMap3.P23 = '3095';
            epsgMap3.P24 = '3096';
            epsgMap3.P25 = '102156';
            epsgMap4.P0 = '4326';
            epsgMap4.P26 = '3857';

            return epsgMap[coordinate][geodetic];
        }

    });
});
