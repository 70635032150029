define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
	'dojo/date/locale',
	'idis/view/grid/IdisGrid',
	'idis/view/grid/helper',
	'dgrid/Selection',
	'dgrid/Selector',
	'dgrid/Keyboard'
], function (module, declare, lang, locale, IdisGrid, helper, Selection, Selector, Keyboard) {

	// var DAMAGE_ATC_FILE_THUMBNAIL_SUFFIX = '_thumbnail';

	return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selection, Selector, Keyboard], {

		// ルート要素に付与されるCSS
		baseClass: 'idis-Page idis-Page--damage',


		/**
		 * 各列の定義
		 * @type {Object[]}
		 */

		columns: [
            helper.column('municipalityName', '区', {sortable:false}),
			helper.column('districtFreeName', '地区', {sortable:false}),
		],
	});
});
