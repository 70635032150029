/**
 * ユーザーに紐付く市町村選択用入力パーツ避難情報画面では変更後にtopicを発行する。
 * @module app/view/form/CustomizableMunicipalitySelector
 */
define([
  'module',
  'dojo/_base/declare',
  'dojo/_base/lang',
  'dojo/_base/array',
  'dojo/topic',
  'dojo/text!./templates/DamageMunicipalityMultiltipleSelect.html',
  'app/model/DisasterInfo',
  'idis/view/dialog/InfoDialog',
  'idis/control/Locator',
  'idis/control/Router',
  'idis/store/IdisRest',
  'idis/view/_IdisWidgetBase',
  'app/view/form/consts/DamageMunicipality',
  // 以下、変数として受け取らないモジュール
  'dijit/Dialog',
  'app/view/form/DamageMunicipalityDialog'
], function (module, declare, lang, array, topic, template, DisasterInfo, InfoDialog,
  Locator, Router, IdisRest, WidgetBase, DamageMunicipality) {

  /**
   * 被害種別選択用モジュール
   * @class CustomizableMunicipalitySelector
   * @extends module:idis/view/page/_PageBase~_PageBase
   */
  return declare(module.id.replace(/\//g, '.'), WidgetBase,
    /** @lends app/view/form/CustomizableMunicipalitySelector~CustomizableMunicipalitySelector# */ {

      // 選択ダイアログのタイトル
      title: '行政区',

      // テンプレート文字列
      templateString: template,

      // ルートに付与されるCSS
      baseClass: 'damagetype-selector',
      /**
       * データ格納用オブジェクト
       * @type {module:dstore/Store}
       */
      store: null,

      /**
       * コンストラクタ
       */
      constructor: function () {
        this.inherited(arguments);
      },

      /**
       * DOM生成
       */
      buildRendering: function () {
        this.inherited(arguments);
        this.municipalityCd.innerText = '未選択';
      },

      /**
       * 画面生成完了
       */
      startup: function () {
        this.inherited(arguments);
        // このウィジェット消滅時にダイアログも削除
        this.own(this.dialog);
      },

      /**
       * 被害種別変更ダイアログを表示する
       */
      showDialog: function () {
        // ダイアログを表示
        this.dialog.show();
        // ダイアログ中のページウィジェットを取得
        var page = this.dialog.getChildren()[0];
        this.changeFlg = false;

        // ダイアログ側でOKボタンが押された際のイベント
        page.on('update', lang.hitch(this, function (evt) {
          // 親被害変更
          this.change(evt.data);
          console.log(evt);

          // ダイアログを非表示にする
          this.dialog.hide();
          //InfoDialog.show('行政区を選択しました');
        }));
        // ダイアログの画面を再表示する
        page.refresh();

      },

      /**
       * 選択されている親災害IDの選択を解除する。
       */
      release: function () {
        this.municipalityCd.innerText = '未選択';
        //        this.subDamageType.innerText = null;
        // 選択されている親災害IDを解除する。
        topic.publish(module.id + '::released', '');
      },

      /**
       * 種別を変更する
       */
      change: function (data) {
        console.log("Change : " + data);
        var mainLabel = [];
        array.forEach(data.mainTypeIds, function (id) {
          mainLabel.push(DamageMunicipality[id]);
        });

        if (mainLabel.length === 0) {
          this.municipalityCd.innerText = '未選択';
        } else {
          this.municipalityCd.innerText = mainLabel.join('、');
        }
        this.changeFlg = true;

        // 選択した種別情報を設定する。
        topic.publish(module.id + '::selected', data);
      },

      /**
       * 被害種別をセットする
       */
      setMunicipalityCd: function (municipalityList) {
        var municipality = {
          '01': '都島区',
          '02': '福島区',
          '03': '此花区',
          '04': '西区',
          '05': '港区',
          '06': '大正区',
          '07': '天王寺',
          '08': '浪速区',
          '09': '西淀川',
          '10': '東淀川',
          '11': '東成区',
          '12': '生野区',
          '13': '旭区',
          '14': '城東区',
          '15': '阿倍野',
          '16': '住吉区',
          '17': '東住吉',
          '18': '西成区',
          '19': '淀川区',
          '20': '鶴見区',
          '21': '住之江',
          '22': '平野区',
          '23': '北区',
          '24': '中央区'
        };
        for (var i = 0; i < municipalityList.length; i++) {
          this.innerDialog.grid.checkboxes[municipalityList[i]].checked = true;
          if (this.innerDialog.grid.mainTypeIds.indexOf(municipalityList[i]) === -1) {
            this.innerDialog.grid.mainTypeIds.push(municipalityList[i]);
          }
          municipalityList[i] = municipality[municipalityList[i]];
        }
        this.municipalityCd.innerText = municipalityList.join('、');
      }

    });

});
