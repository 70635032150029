/**
 * (旧)職員参集詳細グリッド。
 * @module app/convocation/view/mail/ConvoHistoryTotalGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, IdisGrid){ //, helper) {

    return declare(module.id.replace(/\//g, '.'), IdisGrid,{
        /**
         * 各列の定義
         * @type {Object[]}
         */

        collection: this.store,
        columns: [
            { field: 'grandtTotal', label: '-', sortable: false },
            { field: 'mobilizationType', label: '動員区分', sortable: false },
            { field: 'total', label: '合計', sortable: false },
            { field: 'rallyAffiliation', label: '参集職員（所属）', sortable: false },
            { field: 'workAffilication', label: '勤務職員（所属）', sortable: false },
            { field: 'rallyOtherAffiliation', label: '参集職員（他所属）', sortable: false }
        ]
    });
});
