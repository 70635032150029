/**
 * 被害情報 集計 閲覧画面用モジュール。
 * @module app/damage/humanDamageReport/HumanDamageReportInputViewDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/HumanDamageReportInputViewDialog.html',
    'idis/control/Router',
    'app/damage/humanDamageReport/HumanDamageReportInputDialog',
    // 以下、変数として受け取らないモジュール
], function (module, declare, template, Router, HumanDamageReportInputDialog) {

    /**
     * 被害概要 集計 閲覧ページ
     * @class HumanDamageReportInputViewDialog
     * @extends module:app/damage/humanDamageReport/HumanDamageReportInputDialog#~HumanDamageReportInputDialog
     */
    return declare(module.id.replace(/\//g, '.'), HumanDamageReportInputDialog,
        /** @lends module:app/damage/humanDamageReport/HumanDamageReportInputViewDialog# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--damage',

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
            },

            // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
            constructor: function () {
            },

            startup: function () {
                this.inherited(arguments);
            },
        });
});

