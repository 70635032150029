/**
 * クロノロジ検索欄パネル
 * @module app/chronology/ChronologySearchPanel
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/text!./templates/ChronologySearchPanel.html',
    'dojo/topic',
    'dojo/on',
    'dijit/TooltipDialog',
    'dijit/popup',
    'idis/store/CacheTreeRest',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/store/CacheStoreModel',
    'idis/view/tree/CheckTree',
    'idis/view/page/_PageBase',
    'app/config',
    './consts/ChronologyType',
    // 以下、変数で受けないモジュール
    'app/view/form/CustomizableMunicipalitySelector',
    'app/view/form/MunicipalitySelector',
    './ChronologyOrganizationSelector',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane'
], function(module, array, declare, lang, Deferred, domClass, domStyle, template, topic, on,
    TooltipDialog, popup,
    CacheTreeRest, UserInfo, USER_TYPE, CacheStoreModel, CheckTree, _PageBase,
    config, ChronologyType) {
    /**
     * クロノロジ検索欄パネル
     * @class ChronologySearchPanel
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/chronology/ChronologySearchPanel~ChronologySearchPanel# */ {
        // テンプレート文字列
        templateString: template,

        // チェック値を保持するリスト
        chronologyTypes: [],
        // damageInfoCategory: [],
        reqInfoCategory: [],
        prefectureInfoCategory: [],
        checkItems: [],

        // 宛先・配信元
        selectedOrganizationCd: null,
        isOrganizationChange:false,

        // 保持した値をセットする場合は、グリッド更新はしない
        stopUpdate: true,
        _hideInnerMuniInfo: false,

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            this.initOrganizationForm();
            // this.initMunicForm();
            this.initTree();
            // 表示順にhintをつける
            //this.setOrderDisplayTips();
            // 共有先の検索条件にhintをつける
            this.setShareTips();
        },

        // 自治体ではなく、大阪市以下の組織の制御を選択できるようにする
        initOrganizationForm: function(){
            this.organizationCd.onModelSettingChange('271004', false);
        },

        initMunicForm: function(){
            // ユーザに応じて市町セレクタを制御
            switch (UserInfo.getUserType()) {
                case USER_TYPE.MUNICIPALITY:
                    this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
                    this.municipalityCd.reset();
                    this.municipalityCd.set('clear', true);
                    break;
                case USER_TYPE.REGION:
                    // 振興局ユーザの場合、検索セレクタのデフォルトをセットし、未選択にはできないようにする
                    //this.municipalityCd.set('value', UserInfo.getRegionCd());
                    //domStyle.set(this.municipalityCd.clearButton.domNode, 'display', 'none');
                    this.municipalityCd.reset();
                    this.municipalityCd.set('clear', true);
                    break;
                case USER_TYPE.PREFECTURE:
                    // 県ユーザの場合、デフォルトだけ制御 (被害登録は未選択)
                    // this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
                    this.municipalityCd.reset();
                    this.municipalityCd.set('clear', true);
                    break;
                case USER_TYPE.OTHER_ORGAN:
                    //this.municipalityCd.set('value', config.municInfo.prefMunicCd);
                    this.municipalityCd.reset();
                    this.municipalityCd.set('clear', true);
                    break;
            }
        },

        /**
         * 検索欄の開閉
         */
        onOpenSelectInfoClick: function() {
            domStyle.set(this.selectInfo, 'display', '');
            domStyle.set(this.openSelectInfo, 'display', 'none');
            topic.publish(module.id + '::openCloseSelectInfo', true);
        },
        onCloseSelectInfoClick: function() {
            domStyle.set(this.selectInfo, 'display', 'none');
            domStyle.set(this.openSelectInfo, 'display', '');
            topic.publish(module.id + '::openCloseSelectInfo', false);
        },

        /**
         * 検索フォームの市町村コードを再セットする。
         */
        onOrganizationChange: function(){
            if(this.stopUpdate){
                return;
            }
            console.debug('組織が変更されました');
            this.isOrganizationChange = true;
            this.selectedOrganizationCd = this.organizationCd.get('value');
            const prefixCds = /^[A-Z]/;
            if (prefixCds.test(this.selectedOrganizationCd)){
                this.selectedOrganizationCd = this.selectedOrganizationCd.slice(1);
            }
            // 空文字の時はnullにする
            if(this.selectedOrganizationCd === ''){
                this.selectedOrganizationCd = null;
            }
            // グリッド更新
            this.updateGridQuery({organizationCd: this.selectedOrganizationCd,
                reqInfoCategory: this.reqInfoCategory, prefectureInfoCategory: 
                    this.prefectureInfoCategory, checkItems: this.checkItems});
         },
        onMunicipalityChange: function(){
            console.debug('市町村が変更されました');
            var municipalityCd = this.municipalityCd.get('value');

            // 県または市町村が選ばれていた場合のみ、共有先組織を選択可能
            var isMunic = false;
            if(municipalityCd &&
                (municipalityCd === config.municInfo.prefMunicCd ||
                municipalityCd.substring(0,3) !== config.municInfo.prefRegCdGudge)){
                isMunic = true;
            }
            //共有先の入力情報を初期化の上、ツリーを再構築
            if(this.chronologyShares){
                //値が入っていたら初期化する
                if(this.chronologyShares.get('value')){
                    this.chronologyShares.set('value', '');
                }
                // 共有先組織選択可否の制御
                if(!isMunic){
                    this.chronologyShares.set('disabled', 'true');
                }else{
                    this.chronologyShares.set('disabled', '');
                    this.chronologyShares.onMunicipalityChange(municipalityCd);
                }
            }
            //共有先セレクタを表示
            //domStyle.set(this.chronologyShares.domNode, 'display', '');

            // 初期表示時は処理しない
            if(this.itemTree) {
                // 所属市町村以外の市町村の場合、組織内情報と要請・措置を選べないようにする。
                var hideInnerMuniInfo = false;
                if (municipalityCd &&
                    (UserInfo.getMunicipalityCd()&&UserInfo.getMunicipalityCd()!==municipalityCd)) {
//                    || UserInfo.getRegionCd()&&UserInfo.getRegionCd()!==municipalityCd)) {
                        hideInnerMuniInfo = true;
                }
                // ツリーにフィルターセット＆ツリーに表示されている要素のみチェック項目に含める
                this.setFilterTreeModel(hideInnerMuniInfo);
                this._hideInnerMuniInfo = hideInnerMuniInfo;
            }

            // グリッド更新
            // this.setFilterData({municipalityCd: municipalityCd});
            this.updateGridQuery({municipalityCd: municipalityCd,
                reqInfoCategory: this.reqInfoCategory, checkItems: this.checkItems});
        },

        /**
         * 表示順：更新日時
         */
       /* updDisplayOrder: function(){
            domClass.remove(this.updDisplayOrderBtn, 'unSelectedOrderDisplay');
            domClass.remove(this.registerDisplayOrderBtn, 'selectedOrderDisplay');
            domClass.add(this.updDisplayOrderBtn, 'selectedOrderDisplay');
            domClass.add(this.registerDisplayOrderBtn, 'unSelectedOrderDisplay');
            topic.publish(module.id + '::displayOrder', true);
        },*/

        /**
         * 表示順：発信日時
         */
        /*registerDisplayOrder: function(){
            domClass.remove(this.updDisplayOrderBtn, 'selectedOrderDisplay');
            domClass.remove(this.registerDisplayOrderBtn, 'unSelectedOrderDisplay');
            domClass.add(this.updDisplayOrderBtn, 'unSelectedOrderDisplay');
            domClass.add(this.registerDisplayOrderBtn, 'selectedOrderDisplay');
            topic.publish(module.id + '::displayOrder', false);
        },*/

        /**
         * 保管した検索条件をformにセットする
         */
        setFilterData: function(store){
            // // 市町村コード
            // if(store.municipalityCd) {
            //     this.stopUpdate = true;
            //     this._municipalityCd = store.municipalityCd;
            //     this.municipalityCd.set('value', this._municipalityCd);
            // }

            // 宛先・配信元
            if(store.organizationCd){
                this.stopUpdate = true;
                this.selectedOrganizationCd = store.organizationCd;
                var setOrganizationCd = store.organizationCd;
                var organizationCdFirstChar = store.organizationCd.slice(0,1);
                if (organizationCdFirstChar.match(/[A-Z]/)){
                    setOrganizationCd = organizationCdFirstChar + store.organizationCd;
                }
                this.organizationCd.set('value', setOrganizationCd);
            }

            // 表示情報
            if (store.checkItems.length>0) {
                this.chronologyTypes = [];
                // this.damageInfoCategory = [];
                this.reqInfoCategory = [];
                this.prefectureInfoCategory = [];
                array.forEach(store.checkItems, function(item){
                    this.stopUpdate = true;
                    if(item.isParent){
                        this.itemTree.setChecked(item, true, 'PARNET');
                    }else{
                        this.itemTree.setChecked(item, true, 'CHILD');
                    }
                }, this);
            } else {
                // 初期表示は全選択
                this.setAllItemChecked(true);
            }
        },

        /**
         * ツリーを初期化する。
         */
        initTree: function() {
            this.reqInfoCategory = [];
            this.prefectureInfoCategory = [];
            this.checkItems = [];
            this.itemTree = new CheckTree({
                model: new CacheStoreModel({
                    store: new CacheTreeRest({
                        // クエリにセットされているmunicipalityCdを取得し、その値に一致する施設をツリーで返す
                        target: 'dojo/app/chronology/ChronologyType.json'
                    })
                }),
                onCheckChange: lang.hitch(this, function(item, checked) {
                    var items = this.itemTree.model.childrenCache;
                    var querySkipping = false;

                    // 親要素がチェックがされた場合
                    if (item.isParent){
                        this.selectedParentId = item.id;

                        // 子要素を保存する
                        this.children = items[item.id];
                        this.isParentChecked = checked;

                        // 全チェック状態から、子要素の一つが外された時も、「親要素のチェックが外された」と判定されてしまう。
                        // このようなケースでは、this.isParentUnCheckedをtrueにしたくないので、単なるチェック状態とは別に、追加の判定が必要。
                        // 「親要素が選択解除された」、かつ「選択中の子要素の数が、子要素の総数よりは１少ない状態」がこれに相当する。
                        // FIXME: もっといい判定条件がある気がする。
                        this.isParentUnChecked = !checked &&
                            (this.countChildrenSelected(this.children) !== this.children.length - 1 );
                    } else if(item.parentId) {
                        // 子要素がチェックされた場合、「全チェック→１要素をのぞいて半チェック」となった場合は少し特殊。
                        // 「子要素が外れた時（先発）」「親要素が半チェック＝外れた時（後発）」で合計二回クエリが飛んでしまう。
                        // これを避けるため、先発の場合はクエリをスキップしたい。
                        var brothers = items[item.parentId];

                        // 条件を満たすのは、
                        querySkipping =
                                // 自分はチェックが外されたときに
                                !checked &&
                                // 親もチェック状態で（子供のチェックが外された時、親はまだチェック状態）
                                this.isSelected(item.parentId) &&
                                //自分以外の兄弟要素は全てチェック中の時
                                (this.countChildrenSelected(brothers) === brothers.length - 1 );
                    }

                    var id = item.id;
                    // チェックされたとき
                    if (checked) {

                        if(id.length === 2){
                            // idが２桁なら、クロノロジ種別。
                            this.chronologyTypes.push(id);
                        } else if (id.length === 4 && (id.substring(0,2) === ChronologyType.DISASTER_HLD.id)){
                            // idが４桁なら、クロノロジ種別の、さらに下位分類の区分。上２桁で種別を判断。
                            this.reqInfoCategory.push(id.substring(2, 4));
                        } else if (id.length === 4 && (id.substring(0,2) === ChronologyType.PREFECTURE_SHARE.id)){
                            // idが４桁なら、クロノロジ種別の、さらに下位分類の区分。上２桁で種別を判断。
                            this.prefectureInfoCategory.push(id.substring(2, 4));
                        }
                        // チェック項目を保持
                        this.checkItems.push(item);

                    } else {
                        var subId = id.substring(2, 4);
                        if(id.length === 2){
                            // idが２桁なら、クロノロジ種別。
                            this.removeFromFilter(item.type, 'ChronologyType', id, this.chronologyTypes);
                        } else if (id.length === 4 && (id.substring(0,2) === ChronologyType.DISASTER_HLD.id)){
                            // idが４桁なら、クロノロジ種別の、さらに下位分類の区分。上２桁で種別を判断。
                            this.removeFromFilter(item.type, 'ChronologyCategory', subId, this.reqInfoCategory);
                        } else if (id.length === 4 && (id.substring(0,2) === ChronologyType.PREFECTURE_SHARE.id)){
                            // idが４桁なら、クロノロジ種別の、さらに下位分類の区分。上２桁で種別を判断。
                            this.removeFromFilter(item.type, 'ChronologyCategory', subId, this.prefectureInfoCategory);
                        }
                        // チェック項目保持リストから削除
                        var tempList = [];
                        array.forEach(this.checkItems, function(item){
                            if(item.id !== id){
                                tempList.push(item);
                            }
                        });
                        this.checkItems = tempList;
                    }

                    var filterItem = {reqInfoCategory: this.reqInfoCategory,
                                    prefectureInfoCategory: this.prefectureInfoCategory,
                                    // damageInfoCategory: this.damageInfoCategory,
                                    chronologyTypes: this.chronologyTypes,
                                    checkItems: this.checkItems,
                                    municipalityCd:this.municipalityCd.value,
                                    organizationCd: this.selectedOrganizationCd
                                };

                    // まとめてチェックされた場合は、全ての子要素が配列に追加されてからまとめて更新
                    if (this.isParentChecked && this.isAllChildrenChecked(this.children)) {
                        this.isParentChecked = false;
                        this.updateGridQuery(filterItem);
                    } else if (this.isParentUnChecked && !this.isAnyChildrenChecked(this.children)){
                        // まとめてチェックを外された場合は、全ての子要素が配列から排除されてからまとめて更新
                        this.isParentUnChecked = false;
                        this.updateGridQuery(filterItem);
                    } else if(!this.isParentUnChecked && !this.isParentChecked && !querySkipping && id!=='$ROOT$') {
                        this.updateGridQuery(filterItem);
                    }

                }),
                autoExpand:true
            }, this.itemTreeNode);

        },

        /**
         * ツリーの全ての要素のチェックを一括セットする。
         * @param {boolean}} checked チェックをつけるか
         */
        setAllItemChecked: function(checked) {
            var deferred = new Deferred();
            this.itemTree.model._getCache().then(lang.hitch(this, function(){
                array.forEach(this.itemTree.model.childrenCache[this.itemTree.model.store.rootId], function(item){
                    this.stopUpdate = true;
                    this.itemTree.setChecked(item, checked, 'PARNET');
                }, this);
                deferred.resolve();
            }));
            return deferred.promise;
        },

        /**
         * 所属市町村以外の市町村の場合、組織内情報と要請・措置を選べないようにする。
         * @param {boolean}} hideInnerMuniInfo 組織内情報と要請・措置を選べないようにするかどうか
         */
        setFilterTreeModel: function(hideInnerMuniInfo) {
            var deferred = new Deferred();
            if (!hideInnerMuniInfo) {
                // 所属市町村の場合、全項目表示
                this.itemTree.model.setFilter().then(lang.hitch(this, function(){
                    // チェックした項目を非表示にした場合、this.checkItemsから除外したので戻す
                    this.checkItems = [];
                    array.forEach(Object.values(this.itemTree._checkMap), function(item){
                        // チェック項目を取得
                        this.checkItems.push(item);
                        // 要請項目を取得
                        if(item.id.slice(0,2)===ChronologyType.DISASTER_HLD.id){
                            this.reqInfoCategory.push(item.id.substring(2, 4));
                        } else if(item.id.slice(0,2)===ChronologyType.PREFECTURE_SHARE.id){
                            this.prefectureInfoCategory.push(item.id.substring(2, 4));
                        }
                    }, this);
                    deferred.resolve();
                }));
            } else {
                // 所属市町村でない場合、組織内情報と要請・措置の項目を要素から外す 大阪府連携も
                this.itemTree.model.setFilter(function(item, isLeaf, model) {
                    return [ChronologyType.DISASTER_HLD.id, ChronologyType.WHITE_BOARD.id]
                                .indexOf(model.getIdentity(item).slice(0,2))<0 && isLeaf;
                }).then(lang.hitch(this, function() {
                    // 要請項目を初期化
                    this.reqInfoCategory = [];
                    this.prefectureInfoCategory = [];
                    // this.checkItemsからも除外
                    this.checkItems = array.filter(this.checkItems, function(item){
                        return [ChronologyType.DISASTER_HLD.id, ChronologyType.PREFECTURE_SHARE.id]
                            .indexOf(item.id.slice(0,2))<0;
                    });
                    deferred.resolve();
                }));
            }
            return deferred.promise;
        },

        updateGridQuery: function(items){

            // 保持していた値をセットした場合は、処理しない
            if(this.stopUpdate){
                this.stopUpdate = false;
                return false;
            }
            // 全選択の場合、チェック要素は指定しない
            // 宛先配信元検索ダイアログに変更があった場合
            if (this.isOrganizationChange){
                this.isOrganizationChange = false;
            } else {
                // グリッドの変更があった場合
                if(items.checkItems && items.checkItems.length >= this.itemTree.model.items.length-1){
                    items.chronologyTypes = [];
                    // items.damageInfoCategory = [];
                    items.reqInfoCategory = [];
                    items.prefectureInfoCategory = [];
                    items.checkItems = [];
                    items.municipalityCd = null;
                    // items.organizationCd = null;
                }
            }
            topic.publish(module.id + '::update', items);
        },

        isSelected: function(id){
            var checkedItems = this.itemTree._checkMap; // 選択された要素（オブジェクト）をまとめたオブジェクト。
            return checkedItems[id] ? true : false;
        },

        // クロノロジTypeフィルターでチェックを外された時の処理
        removeFromFilter: function(itemType, searchType, id, list){
            if (itemType === searchType) {
                for(var i=0; i<list.length; i++){
                    if(list[i] === id){
                        list.splice(i, 1);
                        return list;
                    }
                }
            }
        },

        // 子要素の中に、どれか一つでもチェックされているものがあればtrueを返す
        isAnyChildrenChecked: function(children){
            var checkedItems = this.itemTree._checkMap; // 選択された要素（オブジェクト）をまとめたオブジェクト。
            var isAnyChildrenChecked = false;
            array.forEach(children, function(child){
                if(checkedItems[child.id]){
                    // 一つでもチェックされているものがあればtrueを返す
                    isAnyChildrenChecked = true;
                }
            }  , this);
            return isAnyChildrenChecked;
        },

        // 子要素の全てがチェックされていればtrueを返す。
        isAllChildrenChecked: function(children){
            var checkedItems = this.itemTree._checkMap; // 選択された要素（オブジェクト）をまとめたオブジェクト。
            var isAllChildrenChecked = true;
            array.forEach(children, function(child){
                if(!checkedItems[child.id]){
                    // 一つでもチェックされているものがなければ
                    isAllChildrenChecked = false;
                }
            }  , this);
            return isAllChildrenChecked;
        },

        // 子要素のうち、チェックされている数を返す。
        countChildrenSelected: function(children){
            var checkedItems = this.itemTree._checkMap; // 選択された要素（オブジェクト）をまとめたオブジェクト。
            var selectedChildrenNum = 0;
            array.forEach(children, function(child){
                if(checkedItems[child.id]){
                    // 子要素がチェックされていたらカウントアップ。
                    selectedChildrenNum++;
                }
            }  , this);

            return selectedChildrenNum;
        },

        isInnerMuniInfoHidden: function(){
            return this._hideInnerMuniInfo;
        },


        /**
         * 表示順のtips
         */
/*        setOrderDisplayTips: function() {
            var orderDisplayContent = '「更新日時」を選ぶと、最後に更新された日時が表示されます。<br>' +
                                      '「発信日時」を選ぶと、避難所の開設日時や被害の発生日時など、' +
                                      '各情報に紐づいた日時が表示されます。<br>' +
                                      'モードに応じて、一覧は表示された日時の昇順で初期表示されます。';

            var orderDisplayTips = new TooltipDialog({
                id: 'orderDisplayTips',
                style: 'width: 300px; height:50px',
                content: '<p>' + orderDisplayContent + '</p>'
            });
            var orderDisplayLabel = this.orderDisplayLabel;
            this.own(orderDisplayTips);
            on(orderDisplayLabel, 'mouseover', lang.hitch(function() {
                popup.open({
                    popup: orderDisplayTips,
                    around: orderDisplayLabel
                });
            }));
            on(orderDisplayLabel, 'mouseleave', function() {
                popup.close(orderDisplayTips);
            });
        },*/

        /**
         * 共有先のtips
         */
        setShareTips: function() {
            // 共有先
            var shareMunicContent = '';
            //var shareOrganContent = '';
            switch (UserInfo.getUserType()) {
                case USER_TYPE.MUNICIPALITY:
                    // shareOrganContent += '共有先組織を選択すると、その組織を共有先に指定したクロノロジのみが表示されます。<br>';
                    // shareOrganContent += '（共有先組織が「全体」であるクロノロジは表示されません。）';
                 //   shareOrganContent += '共有先組織を選択すると、その組織を共有先に指定した時系列情報のみが表示されます。<br>';
                  //  shareOrganContent += '（共有先組織が「全体」である時系列情報は表示されません。）';
                    shareMunicContent += '<strong>宛先・配信元</strong>を指定した場合、';
                    shareMunicContent += 'その自治体に関連する時系列情報を表示します。<br>';
                    break;
                case USER_TYPE.REGION:
                    // shareMunicContent += '<strong>県</strong>または<strong>市町村</strong>を指定した場合、';
                    // shareMunicContent += 'その自治体に関連するクロノロジを表示します。<br>';
                    // shareMunicContent += '<strong>地域</strong>を指定した場合、地域内の市町村に関連するクロノロジを表示します。<br>';
                    // shareOrganContent += '共有先自治体に県または市町村を指定した場合、共有先組織の指定が可能です。<br>';
                    // shareOrganContent += '共有先組織を指定した場合、共有先組織が「全体」であるクロノロジは表示されません。';
                    shareMunicContent += '<strong>宛先・配信元</strong>を指定した場合、';
                    shareMunicContent += 'その自治体に関連する時系列情報を表示します。<br>';
                  //  shareMunicContent += '<strong>地域</strong>を指定した場合、地域内の宛先・配信元に関連する時系列情報を表示します。<br>';
                  //  shareOrganContent += '共有先自治体に宛先・配信元を指定した場合、共有先組織の指定が可能です。<br>';
                  //  shareOrganContent += '共有先組織を指定した場合、共有先組織が「全体」である時系列情報は表示されません。';
                    break;
                case USER_TYPE.PREFECTURE:
                case USER_TYPE.OTHER_ORGAN:
                    // shareMunicContent += '<strong>県</strong>または<strong>市町村</strong>を指定した場合、';
                    // shareMunicContent += 'その自治体に関連するクロノロジを表示します。<br>';
                    // shareMunicContent += '<strong>地域</strong>を指定した場合、地域内の市町村に関連するクロノロジを表示します。<br>';
                 // shareMunicContent += '<strong>全選択</strong>を指定した場合、閲覧権限のある全クロノロジを表示します（ダイアログの「クリア」を押すことで全選択が可能です）。';
                    // shareOrganContent += '宛先自治体に県または市町村を指定した場合、宛先組織の指定が可能です。<br>';
                    // shareOrganContent += '宛先組織を指定した場合、宛先組織が「全体」であるクロノロジは表示されません。';
                    shareMunicContent += '<strong>宛先・配信元</strong>を指定した場合、';
                    shareMunicContent += 'その自治体に関連する時系列情報を表示します。<br>';
                //    shareMunicContent += '<strong>地域</strong>を指定した場合、地域内の宛先・配信元に関連する時系列情報を表示します。<br>';
                // shareMunicContent += '<strong>全選択</strong>を指定した場合、閲覧権限のある全時系列情報を表示します（ダイアログの「クリア」を押すことで全選択が可能です）。';
                //    shareOrganContent += '宛先自治体に宛先・配信元を指定した場合、宛先組織の指定が可能です。<br>';
                 //   shareOrganContent += '宛先組織を指定した場合、宛先組織が「全体」である時系列情報は表示されません。';
                    break;
                default:
                    break;
                }

         /*   var shareOrganTips = new TooltipDialog({
                id: 'shareTips',
                style: 'width: 300px; height:100px',
                content: '<p>' + shareOrganContent + '</p>'
            });
            var shareOrganLabel = this.shareOrganLabel;
            this.own(shareOrganTips);
            on(shareOrganLabel, 'mouseover', lang.hitch(function() {
                popup.open({
                    popup: shareOrganTips,
                    around: shareOrganLabel
                });
            }));
            on(shareOrganLabel, 'mouseleave', function() {
                popup.close(shareOrganTips);
            });*/

            // 自治体のhintが不要な場合、
            if(!shareMunicContent){
                // domStyle.set(this.shareMunicHint, 'display', 'none');
                return false;
            }

            var shareMunicTips = new TooltipDialog({
                id: 'shareMunicTips',
                style: 'width: 300px; height:100px',
                content: '<p>' + shareMunicContent + '</p>'
            });
            var shareMunicLabel = this.shareMunicLabel;
            this.own(shareMunicTips);
            on(shareMunicLabel, 'mouseover', lang.hitch(function() {
                popup.open({
                    popup: shareMunicTips,
                    around: shareMunicLabel
                });
            }));
            on(shareMunicLabel, 'mouseleave', function() {
                popup.close(shareMunicTips);
            });
        }
    });
});
