/**
 * 避難状況登録・詳細画面ベースモジュール。
 * @module app/view/page/_EvacOrderPageBase
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/data/ItemFileWriteStore',
    'dojo/date/locale',
    'dojo/dom',
    'dojo/dom-style',
    'dojo/json',
    'dojo/on',
    'dojo/topic',
    'dojo/promise/all',
    'dijit/TooltipDialog',
    'dijit/popup',
    'dstore/Memory',
    'dstore/Trackable',
    'idis/view/page/_PageBase',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'app/evacorder/DistrictLayerFactory',
    'app/model/DisasterInfo',
    './EvacOrderCheckTree',
    './MeshCheckTree',
    './RiverCheckTree',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    '../config',
    './DistributionType',
    './EvacOrderType',
    './EvacOrderTypeModel',
    './EvacOrderConfig',
    './Reason',
    'app/model/Municipality',
    './EvacOrderUtil',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/ValidationTextBox',
    'idis/view/form/WordCountTextarea',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    // 'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'app/view/form/OrganizationSelector',
    'app/shelter/ShelterOpeningGrid'
    // 'app/provide/view/ProvideRegisterCityDialog',
], function(module, declare, lang, array, ItemFileWriteStore, locale, dom, domStyle, json, on,
    topic, all, TooltipDialog, popup, Memory, Trackable, _PageBase, Router, Requester,
    DialogChain, InfoDialog, IdisMap, UserInfo, DistrictLayerFactory, DisasterInfo, EvacOrderCheckTree,
    MeshCheckTree, RiverCheckTree, CacheStoreModel, CacheTreeRest,
    config, DistributionType, EvacOrderType, EvacOrderTypeModel, EvacOrderConfig, Reason,
    Municipality, EvacOrderUtil) {
    /**
     * 地区レイヤー選択時の避難区分別選択色
     * @type {Object}
     * @private
     */
    var _orderTypeSelectColorMap = {
        '11': '#FF2800',
        '13': '#AA00AA',
        '14': '#0C000C',
        '19': '#FFFF00',
        '80': '#808080'
    };

    /**
     * 避難状況登録画面。
     * @class EvacOrderRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/evacorder/EvacOrderRegisterPage~EvacOrderRegisterPage# */ {
        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--evacorder',

        // お知らせダイアログ
        infoDialog: null,

        // formの内容をpostするためのストア
        formStore: null,

        // ツリー
        tree: null,

        // 地域選択ツリー用のストア
        treeStore: null,

        // 地域選択ツリーのクラス
        treeClass: EvacOrderCheckTree,

        // ページで保持する災害ID
        _disasterId: null,

        // ページで保持するログインユーザの組織情報
        _orgCd: null,

        // ページで保持する市町村コード
        _municipalityCd: null,

        // ユーザーの管理対象市町コード
        _userMunicipalityCds: [],

        // 避難状況ページのレイヤーコントロール
        _layerControl: null,

        // 地図情報filter用の地区ツリーのキャッシュデータ
        _districtTreeCache: null,

        // 選択された避難区分
        _evacOrderType: null,

        _issueReasonLists: [],

        _autoNumSet: {},
        
        _preventUpdPopulationStoreFlg: false,

        // 県の市町区コード
        PREF_MUNICIPALITY_CODE: config.municInfo.prefMunicCd,

        // 県名
        PREF_NAME: config.municInfo.prefName,

        // 市の市町村コード
        KUMAMOTOCITY_MUNICIPALITY_CODE: config.municInfo.cityMunicCd,

        // 県ユーザでログインした場合のデフォルトコード
        DEFAULT_MUNICIPALITY_CODE: config.municInfo.defaultMunicCd,

        // 県の緯度経度
        INIT_LATLNG: {lat: config.map.latitude , lng: config.map.longitude },

        CORRECT_CANCEL_FLG: {
            TRUE: '1',
            FALSE: '0'
        },
        LATEST_LAYER_STATE: {
            ON: '1',
            OFF: '0'
        },

        // 避難情報の現況(LATEST_STATE)と発令情報を選択（SELECT）するレイヤーに設定するidを指定する。
        // このidをみて、それぞれのレイヤーを判定する。
        // FIXME 暫定的に1000を設定。適切なidを設定するようにする。
        SELECT_LAYER_NO : 100000,
        LATEST_STATE_LAYER_NO : null,

        /**
         * 最後に選択した避難理由のコード
         * @type {string}
         * @private
         */
        _lastReason: null,

        // 閲覧モードフラグ
        _viewMode: '0',

        constructor: function() {
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
            // 避難世帯数・避難者数編集グリッドの初期化
            this.populationStore = Trackable.create(new Memory({
                idProperty: 'districtCd',
                data: {
                    items: []
                }
            }));
            this.populationCollection = this.populationStore.sort('order');
        },

        // DOMノード構築後に呼ばれる
        postCreate: function() {
            this.inherited(arguments);
            // グリッド上は地区コード順に並ぶようにする
            this.populationGrid.set('collection', this.populationCollection);
            // 理由変更時に人数を更新
            this.own(this.issueReasonType.on('change', lang.hitch(this, '_updatePopulationStore')));
            // 合計数の表示を反映
            this.own(this.populationCollection.on('add, delete, update', lang.hitch(this, '_updateEvacSummary')));
            this._updateEvacSummary();
        },

        // HTML上にウィジェットが設置されてから呼ばれる
        startup: function() {
            this.inherited(arguments);

            // Cookieから取得した災害IDを取得する。
            this._disasterId = DisasterInfo.getDisasterId();

            // 一覧画面から渡された市町村名を取得する。
            this._userMunicipalityCds = UserInfo.getMunicipalityCds();

            // 地図をレイヤーに追加する。
            this.initMap();

            // ログインユーザの組織コードを取得する。
            this.getOrganizationCd();

            // チェックボックス>地図選択時の処理
            this.own(topic.subscribe('app/evacorder/EvacOrderCheckTree::selectDistrict',
                lang.hitch(this, function(payload) {
                    this.selectDistrictLayerFromCheckTree(payload);
                })
            ));

            // 地図選択＞チェックボックス選択時の処理
            this.own(topic.subscribe('app/evacorder/DistrictLayerFactory::selectDistrict',
                lang.hitch(this, function(payload) {
                    this.selectCheckTreeFromDistrictLayer(payload.id);
                })
            ));

            // 避難情報ツリーを選択した時に呼ばれる
            this.own(topic.subscribe('app/evacorder/EvacOrderCheckTree::calcEvacOrderTarget',
                lang.hitch(this, function() {
                    // 避難世帯数・避難者数の情報を更新する（グリッドと入力欄に反映される）
                    this._updatePopulationStore();
                })
            ));

            // 続報セレクタに変更イベントを設定
            // this.evacOrderType.on('change', lang.hitch(this, function(evt) {
            //     this.onEvacOrderTypeChanged(evt);
            // }));
        },
        
        /**
         * メッシュツリー、河川ツリーを初期化する。
         * TODO ツリー初期化後にexpandAll()メソッドを呼ぶ。
         */
        initMeshAndRiverTree: function() {
            // ツリーを生成する。
            if (!this.meshTree) {
                this.meshTree = new MeshCheckTree({
                    model: new CacheStoreModel({
                        store : new CacheTreeRest({
                            target: '/api/district/mesh'
                        })
                    })
                }, this.meshTreeNode);
                this.meshTree.startup();
            }
            // this.meshTree.onLoadDeferred.then(lang.hitch(this, function() {
            //     this.meshTree.model.store._getCache().then(lang.hitch(this, function(items) {
            //         this._meshTreeCache = items.data;
            //     }));
            // }));
            
            // ツリーを生成する。
            if (!this.riverTree) {
                this.riverTree = new RiverCheckTree({
                    model: new CacheStoreModel({
                        store : new CacheTreeRest({
                            target: '/api/district/river'
                        })
                    })
                }, this.riverTreeNode);
                this.riverTree.startup();
            }
            // this.riverTree.onLoadDeferred.then(lang.hitch(this, function() {
            //     this.riverTree.model.store._getCache().then(lang.hitch(this, function(items) {
            //         this._riverTreeCache = items.data;
            //     }));
            // }));
            //return this.meshTree.onLoadDeferred;
            return all({
                mesh: this.meshTree.onLoadDeferred,
                river: this.riverTree.onLoadDeferred
            });
        },
        
        // 発令内容による画面項目の表示制御
        setDispContentByEvacOrderType: function(evacOrderType){
            if(evacOrderType === '01'){
                //土砂が選ばれた場合
                domStyle.set(this.meshTreeArea, 'display', '');
                domStyle.set(this.riverTreeArea, 'display', 'none');
                domStyle.set(this.dispTreeNodeArea, 'display', '');
                domStyle.set(this.undispTreeNodeArea, 'display', 'none');
                domStyle.set(this.treeArea, 'display', 'none');
            }else if(evacOrderType === '02'){
                //洪水が選ばれた場合
                domStyle.set(this.meshTreeArea, 'display', 'none');
                domStyle.set(this.riverTreeArea, 'display', '');
                domStyle.set(this.dispTreeNodeArea, 'display', '');
                domStyle.set(this.undispTreeNodeArea, 'display', 'none');
                domStyle.set(this.treeArea, 'display', 'none');
            }else{
                //その他が選ばれた場合
                domStyle.set(this.meshTreeArea, 'display', 'none');
                domStyle.set(this.riverTreeArea, 'display', 'none');
                domStyle.set(this.dispTreeNodeArea, 'display', 'none');
                domStyle.set(this.undispTreeNodeArea, 'display', 'none');
                domStyle.set(this.treeArea, 'display', '');
            }
        },
        
        /**
         * 地区ツリーを再作成する。
         * 市町変更時にツリーを破棄して再作成する。
         */
        /* reCreateTree: function(districtCdList) {
            // ツリーをdata-dojo-attach-pointで紐付けるノードを保持する。
            this._treeNode = this.treeNode;

            //  ツリーを一度破棄する
            this.tree.destroyRecursive();
            delete this.tree;

            // ツリーの親ノードの子供として、保持しておいたノードを追加する。
            this.treeParentNode.appendChild(this._treeNode);

            // 避難情報ツリーを追加する。
            return this.initTree(districtCdList);

        }, */
        
        /**
         * 地区ツリーを再構築する
         * districtCdList: 地区コードリスト(葉地区のみ)。要素を持つ場合、その要素のみでツリーを構築する。
         *                 要素を持たない場合、全ての地区でツリーを構築する。
         * defaultAllCheckFlg: trueの場合、ツリーをデフォルトで全チェックする。そうでない場合、チェック状態は引き継ぐ。
         */
        updateTreeFilter: function(districtCdList, defaultAllCheckFlg) {
            //地区ツリーのフィルタを更新
            this.tree.model.setFilter(function(item) {
                if(districtCdList && districtCdList.length > 0){
                    // 地区に指定がある場合、指定された地区だけを出力
                    return districtCdList.indexOf(item.districtCd) !== -1;
                }
                return true; 
            });
            
            //defaultAllCheckFlgが立っている場合、フィルタ後のツリーは全チェックする
            if(districtCdList && defaultAllCheckFlg){
                // (1)フィルタに引っかからなかったものは、その親も含めて先にチェックを外す。
                var hiddenLeafCdList = []; //checkされていたがフィルタに引っかからなかったleaf
                array.forEach(this.tree.getCheckedLeafs(), function(leaf){
                    if(districtCdList.indexOf(leaf.id) === -1){
                        // filterに弾かれたleaf
                        hiddenLeafCdList.push(leaf.id);
                    }
                }, this);
                //leafの親を取ってくる(ルートまで辿る)。
                var hiddenParentCdList =  this.tree.getParentsRecursive(hiddenLeafCdList);
                
                // cd → itemに変換
                var itemList = [];
                array.forEach(hiddenLeafCdList.concat(hiddenParentCdList), function(cd){
                    var item = this.tree._itemMap[cd];
                    if(item && itemList.indexOf(item) === -1){
                        itemList.push(item);
                    }
                }, this);
                // 何も考えずに親子ともどもチェックを外す
                this.tree.setAllChecked(itemList, false);
                
                // (2) ツリーの表示されている部分を全チェックする。
                // districtCdListは葉地区だけなので、親を取ってくる(ルートまで辿る)。
                var parentCdList = this.tree.getParentsRecursive(districtCdList);
                // cd → itemに変換
                itemList = [];
                array.forEach(districtCdList.concat(parentCdList), function(cd){
                    var item = this.tree._itemMap[cd];
                    if(item && itemList.indexOf(item) === -1){
                        itemList.push(item);
                    }  
                }, this); 
                // 何も考えずに親子ともどもチェックをつける(ツリー全体をチェックする)
                this.tree.setAllChecked(itemList, true);
            
            //defaultAllCheckFlgが立っていない場合、フィルタで弾かれた地区のチェックを外すだけに留める。
            // (ただしdistrictCdListが空であればフィルタで弾かれることがないので何も処理しない)
            //このとき親も再帰的にチェック状況を判定する必要がある。
        }else if(districtCdList && districtCdList.length > 0){
                // leafだけチェックを外すと、親まで勝手に辿ってくれる。
                array.forEach(this.tree.getCheckedLeafs(), function(leaf){
                    if(districtCdList.indexOf(leaf.id) === -1){
                        this.tree.setChecked(leaf, false);
                    }
                }, this);
            }
        },
        
        onMeshCheckChange: function(distCheckFlg) {
            //地区を大量に選ぶので、地区選択時の避難所store更新イベントを抑止。
            this._preventUpdPopulationStoreFlg = true;
            var checkedMeshArray =  this.meshTree.getCheckedLeafs();
            // チェック中のメッシュ情報を残しておく。
            var checkedMeshCdList= [];
            // チェック中のメッシュ群に紐づく地区のリストを取得する
            var showDistrictLeafs= [];
            array.forEach(checkedMeshArray, function(mesh){
                checkedMeshCdList.push(mesh.id);
                array.forEach(mesh.districtCdList, function(districtCd){
                    if(showDistrictLeafs.indexOf(districtCd) === -1){
                        showDistrictLeafs.push(districtCd);
                    }        
                }, this);
            }, this);
            this.updateTreeFilter(showDistrictLeafs, distCheckFlg);
            this._checkedMeshCdList = checkedMeshCdList;
            // 抑止していたイベントをまとめて処理。
            this.updatePopulationStoreAtOnce();
        },
        
        onRiverCheckChange: function(distCheckFlg) {
            var evacOrderType = this.evacOrderType.get('value');
            //地区を大量に選ぶので、地区選択時の避難所store更新イベントを抑止。
            this._preventUpdPopulationStoreFlg = true;
            var checkedRiverArray =  this.riverTree.getCheckedLeafs();
            // チェック中の河川情報を残しておく。
            var checkedRiverCdList= [];
            // チェック中の河川群に紐づく地区のリストを取得する
            var showDistrictLeafs= [];
            array.forEach(checkedRiverArray, function(river){
                checkedRiverCdList.push(river.id);
                array.forEach(river.districtCdList, function(districtCd){
                    if(showDistrictLeafs.indexOf(districtCd) === -1){
                        var item = this.tree._itemMap[districtCd];
                        // チェック中の河川において、当該地区の対象避難者数が「0人でない」ものがあれば発令対象。
                        // null人の場合は、M_DISTRICTで定義されている人数を対象避難者数として発令することに注意。
                        var isEvacTarget = array.some(item.districtDetailList, function(detail){
                            if(detail.issueReasonType === '02' && checkedRiverCdList &&
                                checkedRiverCdList.indexOf(detail.riverCd) !== -1){
                                var population = 
                                    this.calcEvacNumForEvacOrderType(detail,evacOrderType,true).population;
                                if(population !== 0){
                                    return true;
                                }
                            }
                        }, this);
                        if(isEvacTarget){
                            showDistrictLeafs.push(districtCd);
                        }
                    }        
                }, this);
            }, this);
            this.updateTreeFilter(showDistrictLeafs, distCheckFlg);
            this._checkedRiverCdList = checkedRiverCdList;
            
            // 抑止していたイベントをまとめて処理。
            this.updatePopulationStoreAtOnce();
            
        },
        
        clearMeshCheck: function(){
            if(!this.meshTree){
                return;
            }
            var checkedMeshArray =  this.meshTree.getCheckedLeafs();
            array.forEach(checkedMeshArray, function(mesh){
                this.meshTree.setChecked(mesh, false);
            }, this);
        },
        
        clearRiverCheck: function(){
            if(!this.riverTree){
                return;
            }
            var checkedRiverArray =  this.riverTree.getCheckedLeafs();
            array.forEach(checkedRiverArray, function(river){
                this.riverTree.setChecked(river, false);
            }, this);
        },
        
        //メッシュ・河川をチェックしたときにイベントが発動するようにする。
        // 詳細画面では、初期値設定時にイベントが発動しないよう、順番に気をつける。
        setMeshAndRiverTopicSubscriber: function(){
            // メッシュツリーを選択した時に呼ばれる
            this.own(topic.subscribe('app/evacorder/MeshCheckTree::meshCheckChenge',
                lang.hitch(this, function() {
                    this.onMeshCheckChange(true);
                })
            ));
            
            // 河川ツリーを選択した時に呼ばれる
            this.own(topic.subscribe('app/evacorder/RiverCheckTree::riverCheckChenge',
                lang.hitch(this, function() {
                    this.onRiverCheckChange(true);
                })
            ));
        },
        
        // 地区ツリーを表示
        dispTreeNode: function(evt){
            evt.preventDefault();
            domStyle.set(this.dispTreeNodeArea, 'display', 'none');
            domStyle.set(this.undispTreeNodeArea, 'display', '');
            domStyle.set(this.treeArea, 'display', '');
        },
        undispTreeNode: function(evt){
            evt.preventDefault();
            domStyle.set(this.dispTreeNodeArea, 'display', '');
            domStyle.set(this.undispTreeNodeArea, 'display', 'none');
            domStyle.set(this.treeArea, 'display', 'none');
        },
        
        // 2つの配列が同じかどうかを確認する。
        comPareTwoArrays: function(arrayA, arrayB) {
            //nullや空で一致の場合もtrueで返す
            if((!arrayA || !arrayA.length) && (!arrayB || !arrayB.length)){
                return true;
            }
            //片方だけがnullの場合にNPEが出ないようにfalseを返す
            if((!arrayA || !arrayA.length) || (!arrayB || !arrayB.length)){
                return false;
            }
            
            return (arrayA.length === arrayB.length) &&
                array.every(arrayA,function(item){
                    return arrayB.indexOf(item) !== -1;
                });
        },
        
        // 2つの配列の共通部分を返す
        getIntersectOfTwoArrays: function(arrayA, arrayB) {
            //配列が空なら空を返す
            if(!arrayA || !arrayA.length || !arrayB){
                return [];
            }
            return array.filter(arrayA, function(item) {
                return arrayB.indexOf(item) !== -1;
            });
        },
        
        // 2つの発令内容が同じグループに属するか確認する。
        comPareTwoEvacOrderTypes: function(typeA, typeB) {
            //準備・勧告同士ならOK
            if((typeA === '01' || typeA === '02') && (typeB === '01' || typeB === '02')){
                return true;
            }
            //指示・発生同士ならOK
            if((typeA === '03' || typeA === '04') && (typeB === '03' || typeB === '04')){
                return true;
            }
            //その他はNG
            return false;
        },
        
        // 発令種別ごとの避難者数・避難世帯数を返す
        // doNot0Replace: trueであれば、null値はnullのまま(0に変換せずに)返す
        calcEvacNumForEvacOrderType: function(districtDetail, evacOrderType, doNot0Replace) {
            var do0Replace = !doNot0Replace;
            var population;
            var householdsNum;
            
            if(do0Replace){
                population = 0;
                householdsNum = 0;
            }
            if(evacOrderType === '01'){
                population = districtDetail.preparePopulation;
                householdsNum = districtDetail.prepareHouseholdsNum;
            }else if(evacOrderType === '02'){
                population = districtDetail.advisoryPopulation;
                householdsNum = districtDetail.advisoryHouseholdsNum;
            }else if(evacOrderType === '03'){
                population = districtDetail.orderPopulation;
                householdsNum = districtDetail.orderHouseholdsNum;
            }else if(evacOrderType === '04'){
                population = districtDetail.occurPopulation;
                householdsNum = districtDetail.occurHouseholdsNum;
            }
            
            if(do0Replace){
                population = population ? population : 0;
                householdsNum = householdsNum ? householdsNum : 0;
            }
            return {population: population, householdsNum: householdsNum};
        },

        /**
         * 指定された要素を指定された理由を考慮した初期入力値と共にstoreへ登録する。
         * @param {string} reason 避難理由コード
         * @param {Object} item 地区要素
         */
        _putWithEvacNumForReason: function(reasonType, checkAllRecordFlg, item) {
            // このストアが更新される(putのみ、削除は扱わない)のは、以下の場合。
            // (1)地区のチェックが増えた場合 (2)メッシュ・河川の選択が増減した場合 (3)発令理由が変わった場合 (4)発令内容が変わった場合
            // フリーフォーム(地区名/人数世帯数)に入れる内容の出元の候補は以下。
            // (A)本グリッドに値が既に入っており(自動または手動書き換え)、その値を更新せずに引き継ぐ。
            // (B)指定された発令理由で同地区に発令/解除済みであり、その最新の値を引き継ぐ。 (地区&理由に対して一意)
            // (C)M_DISTRICTの値 (地区に対して一意)
            // (D)M_DISTRICT_DETAILの値 (地区&理由&[メッシュ/河川]&発令内容に対して一意。地区名は発令内容に依存しない)
            
            //(1)で土砂・洪水以外のとき、同じ理由で発令/解除中であれば、(B)。そうでなければ、(C)。
            //(1)で土砂のとき、同じ理由で発令/解除中であり、最新の発令と現在のメッシュ選択状況が同じとき(B)。
            //(1)で洪水のとき、同じ理由で発令/解除中であり、最新の発令と現在の河川選択状況が同じ、かつ[準備or勧告]同士または[指示or発生]同士であるとき(B)。
            //(1)で土砂・洪水のとき、同じ理由で発令/解除中であり、発令内容として解除を選択していれば(B)。
            //   -土砂・洪水について、上述のルールを満たさない場合は(D)。
            //   -(D)のとき、メッシュ・河川を複数(最大2つ)選択中であれば、人数は、選択しているメッシュ/河川の総和とする。また地区名は(C)から取る。
            //(2)で土砂・洪水以外のとき(起こり得ないが)、(A)。
            //(2)について、メッシュ・河川の追加選択によって新規に増える地区は、(1)で対応している。(2)での処理としては既存レコードに対しての制御だけ考えれば良い。
            //(2)で土砂・洪水のとき、自分の地区に影響するメッシュ・河川のチェックが増減した場合のみ(1)のルートに入る。そうでない場合(A)。
            //(3)については、常に(1)と同じ動きをする(登録画面からのみの導線。地区をいじったあとに理由を変えることは基本的に想定しない)
            //(4)で土砂・洪水以外のとき、(A)。
            //(4)で土砂・洪水のとき、解除を選択していれば(1)のルートに入る。
            //(4)で洪水のとき、[準備or勧告]と[指示or発生]のグループ間移動がされていれば(1)のルートに入る。
            //   -土砂・洪水について、上述のルールを満たさない場合は(A)。

            
            // 選択した避難理由に対し、手入力がされたものがあれば変更せず（規定値との差異で判定する）、
            // 前回の入力値があればその値、無ければ地区の総数を初期入力値として設定
            var targetReason = reasonType && array.filter(item.evacOrderDistrictReasonList, function(distReason) {
                return distReason.issueReasonType === reasonType;
            });
            // 判定のためのインプット
            var latest = (targetReason && targetReason.length) ? targetReason[0] : null;            
            var evacOrderType = this.evacOrderType.get('value');
            var checkedMeshCdList =  this._checkedMeshCdList;
            var checkedRiverCdList =  this._checkedRiverCdList;
            
            //フォーム部が更新対象であるかを判定していく。
            //storeに記載のない新規地区であれば更新対象。
            var updateFlg = !item.evaqueeNum;
            //発令理由が変わっていれば更新対象。
            if(!updateFlg && reasonType !== item.evacReasonType){
                updateFlg = true;
            }
            //土砂であって、地区に関連するメッシュのチェック状況が増減していれば更新対象。
            var intersect;
            if(!updateFlg && reasonType === '01'){
                //現在のチェック状況のうち、当該地区に関連するもの
                intersect = this.getIntersectOfTwoArrays(checkedMeshCdList, item.meshCdList);
                if(!this.comPareTwoArrays(item.checkedMeshCdList, intersect)){
                    updateFlg = true;
                }
            }
            //洪水であって、地区に関連する河川のチェック状況が増減していれば更新対象。
            if(!updateFlg && reasonType === '02'){
                //現在のチェック状況のうち、当該地区に関連するもの
                intersect = this.getIntersectOfTwoArrays(checkedRiverCdList, item.riverCdList);
                if(!this.comPareTwoArrays(item.checkedRiverCdList, intersect)){
                    updateFlg = true;
                }
            }
            //土砂・洪水であって、発令内容が解除に変更されていれば更新対象。
            if(!updateFlg && (reasonType === '01' || reasonType === '02')){
                if(item.executingEvacOrderType !== evacOrderType && evacOrderType === '80'){
                    updateFlg = true;
                }
            }
            //洪水であって、[準備or勧告]内の変更、[指示or発生]内の変更でなければ更新対象。
            if(!updateFlg && reasonType === '02'){
                if(!this.comPareTwoEvacOrderTypes(item.executingEvacOrderType, evacOrderType)){
                    updateFlg = true;
                }
            }
            
            //更新対象のものについて処理。
            if(updateFlg){
                // store未登録の場合(itemのevaqueeNum有無で判定)
                var canGetFromLatest = false;
                //種別によらず、解除を選択していれば最新報から取る。
                if(latest && evacOrderType === '80'){
                    canGetFromLatest = true;
                }
                //土砂でも洪水でもなく、発令済
                if(latest && reasonType !== '01' && reasonType !== '02'){
                    canGetFromLatest = true;
                }
                //土砂ならば、選択メッシュの整合もチェック。
                if(latest && reasonType === '01' && 
                    this.comPareTwoArrays(checkedMeshCdList, latest.evacMeshCdList)){
                    canGetFromLatest = true;
                }
                //洪水ならば、選択河川の整合と発令内容の整合もチェック。
                if(latest && reasonType === '02' && 
                    this.comPareTwoArrays(checkedRiverCdList, latest.evacRiverCdList) &&
                    this.comPareTwoEvacOrderTypes(evacOrderType, latest.evacOrderType)){
                    canGetFromLatest = true;
                }
                
                if(canGetFromLatest){
                    //最新から素直に詰めていく。
                    item.evacHouseholdNum = latest.evacHouseholdDistNum;
                    item.evaqueeNum = latest.evaqueeDistNum;
                    item.districtFreeName = latest.districtFreeName;
                    item.districtFreeNameKana = latest.districtFreeNameKana;
                }else if(reasonType !== '01' && reasonType !== '02'){
                    //最新がなくても、土砂・洪水でなければ地区マスタを詰める。
                    item.evacHouseholdNum = item.householdsNum;
                    item.evaqueeNum = item.population;
                    item.districtFreeName = item.districtName;
                    item.districtFreeNameKana = item.districtNameKana;
                }else{
                    //上記ではみ出た場合
                    var detailCount = 0;
                    var population = 0;
                    var householdsNum = 0;
                    var districtName;
                    var districtNameKana;
                    if(reasonType === '01'){
                        //土砂を選択時
                        array.forEach(item.districtDetailList, function(detail){
                            if(detail.issueReasonType === '01' && checkedMeshCdList &&
                                checkedMeshCdList.indexOf(detail.meshCd) !== -1){
                                detailCount++;
                                population += this.calcEvacNumForEvacOrderType(detail,evacOrderType).population;
                                householdsNum += this.calcEvacNumForEvacOrderType(detail,evacOrderType).householdsNum;
                                districtName = detail.districtName;
                                districtNameKana = detail.districtNameKana;
                            }
                        }, this);
                    }else if(reasonType === '02'){
                        //洪水を選択時
                        array.forEach(item.districtDetailList, function(detail){
                            if(detail.issueReasonType === '02' && checkedRiverCdList &&
                                checkedRiverCdList.indexOf(detail.riverCd) !== -1){
                                detailCount++;
                                population += this.calcEvacNumForEvacOrderType(detail,evacOrderType).population;
                                householdsNum += this.calcEvacNumForEvacOrderType(detail,evacOrderType).householdsNum;
                                districtName = detail.districtName;
                                districtNameKana = detail.districtNameKana;
                            }
                        }, this);
                    }
                    //2つ以上のメッシュ・地区を選んだ場合、または上で名前を取れなかった場合は、M_DISTRICTから名称を取る。
                    if(detailCount > 1 || !districtName){
                        districtName = item.districtName;
                        districtNameKana = item.districtNameKana;
                    }
                    // 和が地区全体の人数を超えた場合は、M_DISTRICTの値で設定し直す。
                    // 人数・世帯数の集計値が空または0の場合も、M_DISTRICTの全体の値として設定し直す。
                    // 後者は暫定対応。マスタを完璧に整備すればこの処理は不要
                    if(!population || population > item.population){
                        population = item.population;
                    }
                    if(!householdsNum || householdsNum > item.householdsNum){
                        householdsNum = item.householdsNum;
                    }
                    //値を詰める。            
                    item.evacHouseholdNum = householdsNum;
                    item.evaqueeNum = population;
                    item.districtFreeName = districtName;
                    item.districtFreeNameKana = districtNameKana;
                
                }
                
                // 「現況」欄は一律ルールで詰める
                if(latest){
                    var currentStatus = [];
                    currentStatus.push(EvacOrderType.get(latest.evacOrderType).simpleLabel);
                    currentStatus.push(locale.format(new Date(latest.evacOrderTimestamp)));
                    item.currentStatus = currentStatus.join('');
                }else{
                    item.currentStatus = '-';
                }
                
            }
            
            //「発令」欄は一律で詰める
            item.executingEvacOrderType = this.evacOrderType.get('value');
            //発令理由を記憶しておく
            item.evacReasonType = this.issueReasonType.get('value');
            //土砂・洪水であれば、どのメッシュ・河川がチェックされているのかを保持しておく。
            if(reasonType === '01'){
                item.checkedMeshCdList = this.getIntersectOfTwoArrays(checkedMeshCdList, item.meshCdList);
            }else if(reasonType === '02'){
                item.checkedRiverCdList = this.getIntersectOfTwoArrays(checkedRiverCdList, item.riverCdList);
            }

            // // 各欄が、ユーザにより手入力による変更がなされているかどうかをチェックする。
            // // 前回、プログラムが自動でセットした値と、現在各欄に入っている値を比較し、
            // // 不一致だったら手入力あり、一致していたら手入力なしと認定。
            // var isEvacHoldInput = !item.evacHouseholdNum ||
            //     (this._autoNumSet[item.districtCd] &&
            //     this._autoNumSet[item.districtCd].evacHouseholdNum === item.evacHouseholdNum);
            // var isEvaqueeNumInput = !item.evaqueeNum ||
            //     (this._autoNumSet[item.districtCd] &&
            //     this._autoNumSet[item.districtCd].evaqueeNum === item.evaqueeNum);
            // var isDistrictFreeNameInput = !item.districtFreeName ||
            //     (this._autoNumSet[item.districtCd] &&
            //     this._autoNumSet[item.districtCd].districtFreeName === item.districtFreeName);
            // var isDistrictFreeNameKanaInput = !item.districtFreeNameKana ||
            //     (this._autoNumSet[item.districtCd] &&
            //     this._autoNumSet[item.districtCd].districtFreeNameKana === item.districtFreeNameKana);
            // 
            // if (targetReason && targetReason.length) {
            //     // 各カラムに関して、手入力されたものがあるかどうかを判定。手入力されていたら、手入力されている方を優先する。
            //     // 手入力されているものがなければ、
            //     item.evacHouseholdNum =
            //             isEvacHoldInput ?
            //             targetReason[0].evacHouseholdDistNum : item.evacHouseholdNum;
            //     item.evaqueeNum =
            //             isEvaqueeNumInput ?
            //             targetReason[0].evaqueeDistNum : item.evaqueeNum;
            //     item.districtFreeName =
            //             isDistrictFreeNameInput ?
            //             targetReason[0].districtFreeName : item.districtFreeName;
            //     item.districtFreeNameKana =
            //             isDistrictFreeNameKanaInput ?
            //             targetReason[0].districtFreeNameKana : item.districtFreeNameKana;
            // 
            //     // 「現況」欄用の整形
            //     var currentStatus = [];
            //     currentStatus.push(EvacOrderType.get(targetReason[0].evacOrderType).simpleLabel);
            //     currentStatus.push(locale.format(new Date(targetReason[0].evacOrderTimestamp)));
            // 
            //     item.currentStatus = currentStatus.join('');
            // } else {
            //     this._latestTargetReason = null;
            //     item.evacHouseholdNum =
            //             isEvacHoldInput ? item.householdsNum : item.evacHouseholdNum;
            //     item.evaqueeNum =
            //             isEvaqueeNumInput ? item.population : item.evaqueeNum ;
            //     item.districtFreeName =
            //             isDistrictFreeNameInput ? item.districtName : item.districtFreeName;
            //     item.districtFreeNameKana =
            //             isDistrictFreeNameKanaInput ? item.districtNameKana : item.districtFreeNameKana;
            // 
            //     item.currentStatus = '-';
            // }
            // this._autoNumSet[item.districtCd] = {};
            // this._autoNumSet[item.districtCd].evacHouseholdNum = item.evacHouseholdNum;
            // this._autoNumSet[item.districtCd].evaqueeNum = item.evaqueeNum;
            // this._autoNumSet[item.districtCd].districtFreeName = item.districtFreeName;
            // this._autoNumSet[item.districtCd].districtFreeNameKana = item.districtFreeNameKana;
            // 
            // item.executingEvacOrderType = this.evacOrderType.get('value');

            if(checkAllRecordFlg){
                //全件更新する場合、この時点では配列に詰めておくだけ。
                this.tmpPopulationStoreArray.push(item);
            }else{
                //全件更新しない場合は、この時点でstore, gridに反映させる。
                this.populationStore.putSync(item);
            }
            
        },

        /**
         * 発令内容が変わった際に呼ばれる
         */
        // onEvacOrderTypeChanged: function(evt){
        //     this._updateReason(evt);
        // },

        /**
         * 避難世帯数・避難者数グリッドの内容を地区選択状態によって更新する。
         * checkAllRecordFlg : trueのとき、新規追加レコード以外も全レコード更新チェックを行う。
         */
        _updatePopulationStore: function(checkAllRecordFlg) {
            // ツリーが初期化前の場合は何もしない
            if (!this.tree) {
                return;
            }
            //イベントが抑止されていたら何もしない。
            if(this._preventUpdPopulationStoreFlg){
                return;
            }

            var reason = this.issueReasonType.get('value');
            // 発令理由が選ばれていない場合はstoreを空にして終了
            if(!reason){
                this.populationStore.fetchSync().forEach(function(item) {
                    var itemId = this.populationStore.getIdentity(item);
                    this.populationStore.removeSync(itemId);
                }, this);
                return;
            }
            var evacOrderType = this.evacOrderType.get('value');
            
            // 引数のフラグで指定された場合(メッシュ・河川のチェック増減時を想定)は全レコード更新
            // 避難理由または発令内容が変わっている場合も、storeの既存レコードについても中身の更新対象
            checkAllRecordFlg = checkAllRecordFlg || 
                this._lastReason !== reason || this._lastEvacOrderType !== evacOrderType;
            
            //変更があった場合には、避難理由・種別を更新しておく。
            if(checkAllRecordFlg){
                this._lastReason = reason;
                this._lastEvacOrderType = evacOrderType;
            }
            
            // storeのレコードを、地区ツリーのチェック済レコードに揃えていく。
            var checkedList =  this.tree.getCheckedLeafs();
            // チェック済み要素をキーとする辞書（削除対象確認に利用）
            var checkedMap = {};
            // チェック済み要素一覧のうち、store未登録の要素を記録
            var newItemList = [];
            array.forEach(checkedList, function(item) {
                var itemId = this.populationStore.getIdentity(item);
                checkedMap[itemId] = true;
                if (!this.populationStore.getSync(itemId)) {
                    newItemList.push(item);
                }
            }, this);
            // store登録済み要素のうち、チェックの外れた要素を記録
            var oldItemIdList = [];
            this.populationStore.fetchSync().forEach(function(item) {
                var itemId = this.populationStore.getIdentity(item);
                if (!checkedMap[itemId]) {
                    oldItemIdList.push(itemId);
                }
            }, this);
            // チェック解除済み要素をstoreから除去 (全件更新しない場合のみ)
            if(!checkAllRecordFlg){
                array.forEach(oldItemIdList, function(id) {
                    this.populationStore.removeSync(id);
                }, this);
            }
            // 全件更新のときは、追加・更新・削除対象を都度都度storeに反映させず、配列に持たせておく
            if(checkAllRecordFlg){
                this.tmpPopulationStoreArray = [];
                // store登録済み要素のうち、チェックが外れていないもの
                this.populationStore.fetchSync()
                    //.forEach(lang.hitch(this, '_putWithEvacNumForReason', reason, checkAllRecordFlg));
                    .forEach(lang.hitch(this, function(item){
                        if(oldItemIdList.indexOf(item.id) === -1){
                            this._putWithEvacNumForReason(reason, true, item);
                        }
                    }));
            }
            // 新規チェック要素をstoreへ登録
            array.forEach(newItemList, function(item) {
                // ツリーに影響を与えないため、コピーを登録
                // 避難理由に合わせて避難世帯数・避難者数の初期値を設定
                this._putWithEvacNumForReason(reason, checkAllRecordFlg, lang.mixin(null, item));
            }, this);
            
            if(checkAllRecordFlg){
                // 最後にまとめて全件更新してstore/gridに反映
                // 追加・更新されるitemは最新化されて配列に格納されている。削除対象は配列に入っていない。
                // よって、配列をそのままstoreのdataとして与え直せばよい。
                this.populationStore.setData(this.tmpPopulationStoreArray);
                this.populationCollection = this.populationStore.sort('order');
                this.populationGrid.set('collection', this.populationCollection);
            }
            
        },
        
        /**
         * 避難世帯数・避難者数グリッドの内容を地区選択状態によって更新する。(一括操作)
         */
        updatePopulationStoreAtOnce: function() {
            // 処理前に、抑止を終了しておく。
            this._preventUpdPopulationStoreFlg = false;
            
            //避難者storeを全レコード更新チェックする。
            this._updatePopulationStore(true);
            
            //対象人数・世帯数を更新
            this._updateEvacSummary();
        },

        /**
         * 避難世帯数グリッドの発令カラムを更新する。
         */
        // _updateReason: function(evt){
        //     console.log(evt);
        //     this.populationStore.fetchSync().forEach(function(item) {
        //         item.executingEvacOrderType = this.evacOrderType.get('value');
        //         this.populationStore.put(item);
        //     }, this);
        // },

        /**
         * 避難世帯数・避難者数の合計表示を更新する。
         */
        _updateEvacSummary: function() {
            var evaqueeNum = 0;
            var evacHouseholdNum = 0;
            this.populationCollection.fetchSync().forEach(function(item) {
                evaqueeNum += (item.evaqueeNum) ? parseInt(item.evaqueeNum, 10) : 0;
                evacHouseholdNum += (item.evacHouseholdNum) ? parseInt(item.evacHouseholdNum, 10) : 0;
            });
            this.evaqueeNumNode.innerHTML = evaqueeNum;
            this.evacHouseholdNumNode.innerHTML = evacHouseholdNum;
        },

        /**
         * チェックツリーを選択する。
         * 地図レイヤー選択時に呼ばれる。
         */
        selectCheckTreeFromDistrictLayer: function(id) {
            // 対応するチェックボックスの選択状態を切り替える
            this.tree.model.store.get(id).then(lang.hitch(this, function(item) {
                this.tree.setChecked(item,!this.tree.isChecked(item));
            }));
        },

        /**
         * 避難地区を選択状態にする。
         * チェックボックス選択時に呼ばれる。
         */
        selectDistrictLayerFromCheckTree: function(id) {
            // 対応する避難地区情報をもつレイヤーを選択する
            var layerList = this._layerControl.layers[this.SELECT_LAYER_NO].getLayers();
            array.some(layerList, function(layer) {
                // 背景地図レイヤーはfeatureを持っていないため事前に確認する
                // D_CDは地区レイヤーに設定する地区ID。geojson、レイヤーのプロパティjson、treejsonに設定されている。
                var layerId = layer.feature && layer.feature.properties.D_CD;
                if (layerId === id) {
                    var options = layer.options || layer._layers[layer._leaflet_id - 1].options; //jshint ignore:line
                    options.selectDistrictLayer(layer);
                    return true; // break
                }
            });
        },

        /**
         * ログインユーザの組織コードをセッションから取得する
         */
        getOrganizationCd: function() {
            this._orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
            UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
            UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');

        },

        // 現在その市町村において登録されている（取り消しされていない）避難情報の発令理由の一覧を取得・保存する
        getIssuesList: function(){
            var issueReasonLists = [];
            this._districtTreeCache.forEach(lang.hitch(this, function(dist){
                array.forEach(dist.evacOrderDistrictReasonList, function(reason){
                    if(reason.issueReasonType &&
                        issueReasonLists.indexOf(reason.issueReasonType) === -1){
                        issueReasonLists.push(reason.issueReasonType);
                    }
                }, this);
            }));
            return issueReasonLists;
        },

        getDistrictInfo: function(districtCd){
            var districtInfo;
            array.forEach(this._districtTreeCache, function(dist){
                if(dist.districtCd === districtCd){
                    districtInfo = dist;
                }
            });
            return districtInfo;
        },

        /**
         * 地図を初期生成する。
         */
        initMap: function() {
            // 地図を生成する。
            this.map = new IdisMap(this.mapNode, {
                config: config.map,
                keyboard: false, // コメント時に+/-が使用できないため
                touchExtend: false,
                minZoom: 9,
                maxZoom: 18,
                drawControlTooltips: false
            // 初期値は県を設定する。
            }).setView(this.INIT_LATLNG, 11);

            // destroy時にmapを破棄するよう設定
            this.own(this.map);

            // 生成したmapのlayerControlを画面にセットする。
            this._layerControl = this.map.layerControl;

        },

        /**
         * 市町村コードから、対象の市町の緯度経度を取得して、地図を移動させる。
         */
        initLatlng: function() {
            // 「県」以外の場合は地図を追加した後で対象の市町の緯度経度を取得して、
            // 地図の中心位置に移動させる。
            // 「県」の場合は移動しない。
            if(this._municipalityCd === this.PREF_MUNICIPALITY_CODE){
                return;
            }
            // 市町村の緯度経度情報の一覧を取得する。
            Requester.get('/data/municipality/municipalityList.json', {preventCache: false})
            .then(lang.hitch(this,function(obj) {

                var municipalities = obj.municipalities;
                var latlng = this.INIT_LATLNG;

                // 市町村の緯度経度情報の一覧と画面で指定されている市町村を比較して当該市町村の緯度経度を取得する。
                municipalities.some(lang.hitch(this,function(item) {
                    if (item.municipalityCd===this._municipalityCd) {
                        latlng = item.latlng;
                        return true; //break
                    }
                }));
                this.map.setView(latlng, 12);
            }));
        },

        /**
         * 避難情報発令地区「選択」レイヤーを地図上に表示する。
         * 選択レイヤーの初期値は、詳細情報のstyle.urlから取得する。
         *「選択」レイヤーはgeojsonからDistrictSelectLayerFactoryによって生成する。
         * @returns {Promise} 追加完了後に解決するPromise
         */
        showSelectLayer: function() {
            return Requester.get('/data/layer/data/evacorder/area/' + this._municipalityCd + '.geojson')
            .then(lang.hitch(this, function(selectLayer) {
                // 地区レイヤーファクトリクラスにツリーの地区情報も渡して、geojsonに登録されている地区をfilterする。
                var layer = DistrictLayerFactory.getGeoJsonLayers(
                    selectLayer, this._districtTreeCache, this._municipalityCd);
                this._layerControl.addLayer(layer, this.SELECT_LAYER_NO);
                this._layerControl.toFront(this.SELECT_LAYER_NO);
            })).then(lang.hitch(this, function(){
                // レイヤーが追加されてから、避難区分変更時のメソッドを呼んで避難区分に応じた色を設定する。
                // 市町村を切り替えた際に、レイヤーを追加した後に色を反映したいため、レイヤー追加後に呼んでおく。
                this.onEvacTypeChange(this.evacOrderType.value);
            }));
        },

        /**
         * 現況レイヤーを取得して地図上に表示する。
         * @returns {Promise} 完了後に解決するPromise
         */
        showLatestStateLayer: function() {
            // 市町村の避難情報「現況」レイヤーのidを取得する。
            return Requester.get('/api/evacorders/layerid', {
                query: {
                    disasterId: this._disasterId,
                    municipalityCd: this._municipalityCd
                }
            }).then(lang.hitch(this, function(id) {
                // 市町村の「現況」レイヤーを取得する。
                this.LATEST_STATE_LAYER_NO = id;
                return Requester.get('/data/layer/tree/' + id + '.json');
            })).then(lang.hitch(this, function(layerInfo) {
                // 現在の避難情報発令状況を地図上に表示する。
                var opacity = 1;
                return this._layerControl.addGeneralLayer(layerInfo, opacity);
            })).then(lang.hitch(this, function() {
                // 「現況」レイヤーは背面に移動する。
                this._layerControl.layers[this.LATEST_STATE_LAYER_NO].bringToBack();
            }));
        },

        /**
         * 指定された地区コード一覧をチェック状態にする。
         * @param {string[]} districtCdList 地区コード一覧
         */
        _selectDistricts: function(districtCdList) {
            array.forEach(districtCdList, function(distCd) {
                // this.tree.model.store.get(distCd).then(lang.hitch(this, function(item) {
                //     if (item) {
                //         this.tree.setChecked(item, true);
                //     }
                // }));
                var item = this.tree._itemMap[distCd];
                if (item) {
                    this.tree.setChecked(item, true);
                }
            }, this);
        },
        
        /**
         * 指定されたメッシュコード一覧をチェック状態にする。
         * @param {string[]} meshCdList メッシュコード一覧
         */
        _selectMeshes: function(meshCdList) {
            if(!meshCdList || !meshCdList.length){
                return;
            }
            array.forEach(meshCdList, function(meshCd) {
                this.meshTree.model.store.get(meshCd).then(lang.hitch(this, function(item) {
                    if (item) {
                        this.meshTree.setChecked(item, true);
                    }
                }));
            }, this);
        },
        
        /**
         * 指定された河川コード一覧をチェック状態にする。
         * @param {string[]} riverCdList 河川コード一覧
         */
        _selectRivers: function(riverCdList) {
            if(!riverCdList || !riverCdList.length){
                return;
            }
            array.forEach(riverCdList, function(riverCd) {
                this.riverTree.model.store.get(riverCd).then(lang.hitch(this, function(item) {
                    if (item) {
                        this.riverTree.setChecked(item, true);
                    }
                }));
            }, this);
        },

        /**
         * 避難情報種別が変更された際に呼ばれる。
         * onChange時に呼ばれるようにhtmlにattachされている。
         * @param {string} value 発令理由コード
         */
        onEvacTypeChange: function(value) {
            this._evacOrderType = value;
            // 初期化時に避難区分がフォームにセットされた段階で当該メソッドが呼ばれてしまう。
            // 地図生成時にモジュールにセットするlayerControlが未生成の可能性があるため
            // 事前にlayerControlの有無をチェックする。
            if(!this._layerControl || Object.keys(this._layerControl.layers).length===0){
                return;
            }
            var layerGroup = this._layerControl.layers[this.SELECT_LAYER_NO];
            var color = _orderTypeSelectColorMap[value];
            // レイヤーのfeatureをそれぞれ確認して、選択されているものは色を変更する。
            layerGroup.eachLayer(function(layer) {
                // Polygonはlayer.options、MultiPoligonはlayer._layer[id].optionsとしてoptionsを保持している。
                var options = layer.options || layer._layers[layer._leaflet_id - 1].options; //jshint ignore:line
                options._selectedColor = color;
                if(options._isSelected){
                    layer.setStyle({fillColor: color});
                }
            });
            
            // 札幌市の場合、洪水が選択されている場合に限り、河川ツリーをリフレッシュしていた
            // 大阪市では河川ツリーは使わないのでリフレッシュしない
            // [準備,勧告], [指示,発生], [解除]をまたいだ発令の場合、地区ツリーから再構築する必要がある。
            /* if(this.issueReasonType.get('value') === '02'){
                //01-02同士, 03-04同士の場合を弾く
                if(!this.comPareTwoEvacOrderTypes(preEvacType, value)){
                    // 解除に切り替えられた場合、デフォルトチェックはしない。
                    // 未発令のものも勝手にチェックされると解除できなくなるため。
                    var distCheckFlg = (value !== '80') ;
                    this.onRiverCheckChange(distCheckFlg);
                }
            } */
            this._updatePopulationStore();
        },

        /**
         * 地区リストの配列をカンマ区切りの文字列に変換する。
         */
        parseArray2String: function(array) {
            var str = '';
            if(array.length!==0){
                array.forEach(function(obj){
                    str += obj.districtCd + ',';
                });
                str = str.slice(0,-1);
            }
            // 最後の文字列を削除する。
            return str;
        },

        /**
         * 地区ツリーの配列から、緊急速報メールに必要な地区情報だけを抜き出して、地区配列として返す
         */
        convertDistrictList: function(districtList) {
            return array.map(districtList, function(item) {
                var gridItem = this.populationStore.getSync(this.populationStore.getIdentity(item));
                return {
                    districtCd : item.districtCd,
                    issueReasonType: null,
                    evacOrderType: null,
                    districtName: gridItem.districtName,
                    districtFreeName: gridItem.districtFreeName,
                    districtFreeNameKana: gridItem.districtFreeNameKana,
                    evacuee: gridItem.evaqueeNum ? parseInt(gridItem.evaqueeNum, 10) : 0,
                    household: gridItem.evacHouseholdNum ? parseInt(gridItem.evacHouseholdNum, 10) : 0,
                    timestamp: null,
                    wardMunicipalityName: gridItem.municipalityName
                };
            }, this);
        },

        /**
         * 地区ツリーの配列から、DB登録に必要な地区情報だけを抜き出して、地区配列として返す。
         */
        convertDistrictArray: function(districtList, evacOrderType, issueReasonType, isIssuing) {
            return array.map(districtList, function(item) {
                var gridItem = this.populationStore.getSync(this.populationStore.getIdentity(item));
                // 解除の場合は、地区別に異なる発令内容のセットが必要
                var individualEvacOrderType = null;
                if(evacOrderType === '80'){
                    // 選択した発令理由に対し、最新の情報を取得する。
                    var latestStatus = array.filter(item.evacOrderDistrictReasonList, function(distReason) {
                        return distReason.issueReasonType === issueReasonType;
                    });

                    // 訂正報で、元々現況が解除報だった場合は、現在の発令区分をセットする。
                    if(!isIssuing && EvacOrderUtil.isReleased(latestStatus[0].evacOrderType)){
                        individualEvacOrderType = latestStatus[0].evacOrderType;
                    } else if (!isIssuing && !EvacOrderUtil.isReleased(latestStatus[0].evacOrderType)){
                       // 訂正報で、元々現況が発令だったのを解除に変えた場合は、
                       // 被訂正報の「前報」が解除対象なので、被訂正報の「前報」の発令区分をセットする。
                        individualEvacOrderType = EvacOrderConfig.RELEASE_MAP[latestStatus[0].lastEvacOrderType];
                    } else {
                       // それ以外の場合（新規発令の場合＆訂正報でも、現況が解除報でない場合）
                       // c.f. 「訂正報でも、現況が解除報でない場合」は、例えば解除しそびれた地区を追加で解除する場合など
                       // この処理にたどり着く時点で、evacOrderDistrictReasonListが空であることはないので、判定処理が不要。
                        individualEvacOrderType = EvacOrderConfig.RELEASE_MAP[latestStatus[0].evacOrderType];
                    }
                }
                return {
                    districtCd : item.districtCd,
                    // 解除の場合は、地区別に異なる発令内容をセットするが、そうでなければ一律で良い
                    evacOrderType: (evacOrderType === '80') ? individualEvacOrderType : evacOrderType,
                    districtFreeName : gridItem.districtFreeName,
                    districtFreeNameKana : gridItem.districtFreeNameKana,
                    evaqueeDistNum : gridItem.evaqueeNum ? parseInt(gridItem.evaqueeNum, 10) : 0 ,
                    evacHouseholdDistNum : gridItem.evacHouseholdNum ? parseInt(gridItem.evacHouseholdNum, 10) : 0,
                    wardMunicipalityName: gridItem.municipalityName
                };
            }, this);
        },

        // 指定された地区（item）の最新状況が、指定の発令理由で「発令済み」かどうかをチェックする
        isAlerted: function(form, item, issueReasonType, evacOrderTimestamp, isIssuing, initialEvacOrderType){
            // 選択した発令理由に対し、最新の情報を取得する。
            var latestStatus = array.filter(item.evacOrderDistrictReasonList, function(distReason) {
                return distReason.issueReasonType === issueReasonType;
            }); // filter関数の返り値の都合でリスト型が入るが、長さは常に0か1

            // 指定された発令理由に合致する現況情報がなければ、falseを返す
            if (!latestStatus || !latestStatus.length) {
                return false;
            }

            // 訂正でない場合、最新状況が「解除」でもfalseを返す
            if(isIssuing && EvacOrderUtil.isReleased(latestStatus[0].evacOrderType)){
                return false;
            }

            // 発令時刻より前の時間で「解除」しようとしていても、falseを返す
            if(evacOrderTimestamp < latestStatus[0].evacOrderTimestamp){
                return false;
            }

            // 例外ケース： 発令（第一報）→ 解除 に「訂正」した場合、
            if(!isIssuing &&  //「発令」ではない
                !EvacOrderUtil.isReleased(initialEvacOrderType) && // 訂正前は解除報ではない
                EvacOrderUtil.isReleased(form.evacOrderType) && // 解除報にしようとしている
                (!latestStatus[0].lastEvacOrderType || // 最新報に「前報」がない＝第一報
                    EvacOrderUtil.isReleased(latestStatus[0].lastEvacOrderType) )){ // 最新報の前報が解除報
                return false;
            }

            // それ以外の場合は、発令中と判断する
            return true;
        },

        /**
         * 地区ツリーの配列から、全域判定用に必要な地区情報だけを抜き出して、地区配列として返す。
         * 訂正の場合は、訂正対象の避難情報の発令時間がevacOrderTimestampで連携される。新規発令の場合はnull。
         */
        createLalertAreaList: function(districtTree, districtArray,
                deselectedList, form, isCancelling, isCorrecting, initialEvacOrderType){
            var evacOrderLalertAreaMap = {};
            var evacOrderTimestamp = form.evacOrderTimestamp.getTime();

            array.forEach(districtTree, function(item){

                // まず、今発令しようとしている発令理由以外にについて整理する。
                array.forEach(item.evacOrderDistrictReasonList, function(reason){
                    // evacOrderId === 0だと、発令されていない情報
                    if(reason.evacOrderId !== 0 && reason.issueReasonType !== form.issueReasonType){
                        if(!reason.issueReasonType){
                            return;
                        }
                        var list = evacOrderLalertAreaMap[reason.issueReasonType];
                        if(!list){
                            list = [];
                        }
                        list.push(this.createLalertArea(item.districtCd, reason, null));
                        evacOrderLalertAreaMap[reason.issueReasonType] = list;
                    }
                }, this);

                // 避難情報取り消し時は、現在選択中の発令理由以外はLアラートに連携しないので、ここでreturnする
                if(isCancelling){
                    return;
                }

                var list4currentIssue = evacOrderLalertAreaMap[form.issueReasonType];
                if(!list4currentIssue){
                    list4currentIssue = [];
                }

                // 次に、今発令しようとしている発令理由について整理する。
                // 地区が選択中かどうかを判定
                var isSelected = false;
                array.forEach(districtArray, function(dist){
                    if(dist.districtCd === item.districtCd){
                        isSelected = true;
                    }
                });

                var latestStatus = this.getLatestStatus(item, form.issueReasonType);

                if( (isSelected && !form.evacOrderId) ||
                    (isSelected && form.evacOrderId && latestStatus &&
                        (form.evacOrderId === String(latestStatus.evacOrderId) ||
                        evacOrderTimestamp >= latestStatus.evacOrderTimestamp) ) ||
                    (isSelected && form.evacOrderId && !latestStatus) ){
                    // 新規発令かつ地区が選択中の場合
                    // 訂正でもその地区に対する最新報を訂正・上書きする場合
                    //   -> (evacOrderIdまたはevacOrderTimestampで判断)
                    // 訂正でも地区の追加（未発令地区への避難情報付与）である場合
                    // 画面入力内容がLアラートへの連携内容になる
                    list4currentIssue.push(
                        this.convertDistrictForIssue(item, form, isCorrecting, initialEvacOrderType));
                } else {

                    // 選択されていない地区である or 訂正するのが、その地区に対する最新報でない場合、
                    // その地区についてのlatestStatusをLアラート連携対象とする。
                    // latestStatusがなければ、そもそもLアラート内容に含めない。
                    if(!latestStatus) {
                        //その地区に、発令予定の発令理由での過去の発令情報がなければ、そもそもリストには含めない。
                        return;
                    }

                    // latestStatusの内容をLアラートに連携するのであれば、Lアラート用に情報を修正する
                    var district = this.convertDistrictFromLatestSituation(
                                    item.districtCd, latestStatus, deselectedList, evacOrderTimestamp);
                    if(district){
                        list4currentIssue.push(district);
                    }
                }
                evacOrderLalertAreaMap[form.issueReasonType] = list4currentIssue;
            }, this);

            return evacOrderLalertAreaMap;
        },

        getLatestStatus: function(item, issueReasonType){
            // 選択した発令理由に対し、最新の情報を取得する。
            var latestStatus = array.filter(item.evacOrderDistrictReasonList, function(distReason) {
                return distReason.issueReasonType === issueReasonType;
            });

            // 指定された発令理由に合致する過去報がなければ、nullをreturnする
            if (!latestStatus || !latestStatus.length) {
                return null;
            }

            // array.filterの戻り値は配列である 最新報があるならlengthは必ず1であるため、0番目の要素を返す
            return latestStatus[0];
        },


        /**
         * 今から避難情報を登録/訂正する訳ではないが、現状で避難情報の登録がある地区について、
         * 最新状況（latestStatus）からLアラート連携に必要な情報だけを抜き出し・変換して、地区情報として返す。
         */
        convertDistrictFromLatestSituation: function(districtCd, latestStatus, deselectedList, evacOrderTimestamp){
            // セットする避難情報地区の発令内容には、基本はlatestStatusのevacOrderTypeをセットするが、
            // 訂正対象が最新報（または最新報の更新）で、かつ訂正によりチェックを外された地区だったら、地区のパージを意味する。
            // その場合は、最新報の「前報」の発令内容、つまり「latestStatusのlastEvacOrderType」をセット表示するべき
            var evacOrderType = latestStatus.evacOrderType;
            if(evacOrderTimestamp >= latestStatus.evacOrderTimestamp &&
                deselectedList && deselectedList.indexOf(districtCd) !== -1){
                if(!latestStatus.lastEvacOrderType){
                    // 前報がなければ、その地区への発令そのものを取り消しているということなので、Lアラートに書かない
                    return null;
                }
                // 前報があれば、前報の発令内容をセットする。
                evacOrderType = latestStatus.lastEvacOrderType;
            }

            // 過去報があれば、情報をセットして返す。
            return this.createLalertArea(districtCd, latestStatus, evacOrderType);
        },

        createLalertArea: function(districtCd, latestStatus, evacOrderType){
            return {
                districtCd : districtCd,
                areaName : latestStatus.districtFreeName,
                areaNameKana : latestStatus.districtFreeNameKana,
                evacuateSort: evacOrderType ? evacOrderType : latestStatus.evacOrderType,
                evacuateIssue: evacOrderType ? evacOrderType : latestStatus.evacOrderType,
                reason : latestStatus.issueReason,
                evaqueeNum : parseInt(latestStatus.evaqueeDistNum, 10),
                evacHouseholdNum : parseInt(latestStatus.evacHouseholdDistNum, 10),
                note : latestStatus.note,
                evacGuidance : latestStatus.evacGuidance,
                evacOrderTimestamp: latestStatus.evacOrderTimestamp
            };
        },

        /**
         * 今から発令予定の地区について、
         * 地区ツリーの各地区要素から、Lアラート連携に必要な情報だけを抜き出し・変換して、地区情報として返す。
         */
        convertDistrictForIssue: function(item, form, isCorrecting, initialEvacOrderType){
            var gridItem = this.populationStore.getSync(this.populationStore.getIdentity(item));

            // 選択した発令理由に対し、最新の情報を取得する。
            var latestStatus = array.filter(item.evacOrderDistrictReasonList, function(distReason) {
                return distReason.issueReasonType === form.issueReasonType;
            });

            var evacuateSort = '';
            if(isCorrecting && form.evacOrderType === '80' && !EvacOrderUtil.isReleased(initialEvacOrderType)) {
                // まず例外ケースの処理。
                // 発令→解除に訂正する場合、解除される情報はあくまで被訂正報の訂正前の発令情報ではなく、
                // 被訂正報の【前報】の発令報である。
                // なお、被訂正報の【前報】がなかったり、前報が解除報であるケースはない（checkDistrictsで弾いているため）
                evacuateSort = EvacOrderConfig.RELEASE_MAP[latestStatus[0].lastEvacOrderType];
            } else if(form.evacOrderType === '80'){
                // 上記以外の解除報を連携する場合、新規登録なら現況情報をストレートに解除報用コードに変換すればいい。
                // ただ、訂正であった場合、元から解除の情報をもう一度解除として訂正するケースがあり、その場合は変換不要。
                evacuateSort = (isCorrecting && EvacOrderUtil.isReleased(latestStatus[0].evacOrderType)) ?
                    latestStatus[0].evacOrderType : EvacOrderConfig.RELEASE_MAP[latestStatus[0].evacOrderType];
            } else {
                evacuateSort =  form.evacOrderType;
            }

            return {
                districtCd : item.districtCd,
                areaName : gridItem.districtFreeName,
                areaNameKana : gridItem.districtFreeNameKana,
                // 画面で「解除」が選ばれていたら、最新状況で発令されているコードに対応する解除コードをセットする。
                // 画面で「発令」が選ばれていたら、一括で、フォームで選ばれている発令コードをセットする
                evacuateSort: evacuateSort ,
                evacuateIssue: evacuateSort ,
                reason: form.issueReason,
                evaqueeNum : parseInt(gridItem.evaqueeNum, 10),
                evacHouseholdNum : parseInt(gridItem.evacHouseholdNum, 10),
                note: form.note,
                evacGuidance: form.evacGuidance,
                evacOrderTimestamp: form.evacOrderTimestamp
            };
        },

        /**
         * 受け取った配列の各要素に、Lアラート用の要素（警戒レベル・文章形式の発令理由）を追加して返却する
         */
        addLalertProperties: function(districtList) {
            return array.map(districtList, function(item) {
                item.alertLevel = this.getAlertLevel(item);
                // 文章形式の発令理由は、発令理由「その他」だった場合はissueReasonの内容をセットする
                item.reason = (item.issueReasonType !== '00') ?
                    Reason.get(item.issueReasonType).lalertReason :
                    item.reason;
                return item;
            }, this);
        },


        sendLalert: function(form){
            if(!form){
                return;
            }

            var jsonStr = json.stringify(form);
            return Requester.post('/api/lalert/send', {
                data: jsonStr,
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(lang.hitch(this, function(result){
                console.info('大阪府連携に成功しました：');
                console.info(result);
            }), lang.hitch(this, function(error){
                this.chain.infoError(error);
                console.error(error);
                throw new Error('大阪府連携失敗  data:' + form);
            }));
        },

        checkDistricts: function(form, districtArray, isIssuing, initialEvacOrderType){
            // 種別が解除でなければチェックしない
            if(!EvacOrderUtil.isReleased(form.evacOrderType)){
                return;
            }

            var nonIssuedDistNameList = [];
            array.forEach(districtArray, function(checkedDist){
                //各地区に対して、現在画面で選んでいる発令内容で避難情報が発令中かどうかをチェック
                var item = this.populationStore.getSync(checkedDist.districtCd);
                // 「現況が解除」の時にさらに解除報を連携するのは、新規発令の時NG、訂正報なら許容（isAlertメソッド内で制御）
                // 発令→解除への訂正は、被訂正報が第一報であればNG、それ以外なら許容（isAlertメソッド内で制御）
                if(!this.isAlerted(form, item, form.issueReasonType,
                        form.evacOrderTimestamp,  isIssuing, initialEvacOrderType)){
                    nonIssuedDistNameList.push(item.districtName);
                }
            }, this);

            return nonIssuedDistNameList;
        },

        // これから避難情報を発令予定の地区について、すでに発令済みの情報と合わせて全域判定を行う。
        _getLalertEvacOrderWholeCheck: function(form, isCorrectCancel){

            // 新規登録時
            var wholeAreaCheckForm = {
                municipalityCd: form.municipalityCd,
                // issueReasonType: form.issueReasonType,
                // issueReasonTypeList: issueReasonLists,
                disasterId: form.disasterId,
                evacOrderLalertAreaMap: form.evacOrderLalertAreaMap
            };
            var jsonStr = json.stringify(wholeAreaCheckForm);
            return Requester.post('/api/evacorders/lalert/wholeAreaCheck', {
                data: jsonStr,
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(lang.hitch(this, function(data) {
                console.debug('取得した避難情報 : ' + data);

                // 市町村内の全避難世帯数・避難者数を計算する。
                // 同じ地区が、相異なる発令理由・発令内容で発令対象となっていた場合、
                // 地区同士を比較して、より新しく発令した方を集計用の人数・世帯数として集計する。
                // 全域判定が挟まると重複してしまうが、その重複ぶんは無視をする。
                var evacHouseholdNum = 0;
                var evaqueeNum = 0;
                var isIssued = false; // 発令中の避難情報があるかどうか
                var distEvaqNumMap = {}; // 地区コードと地区情報オブジェクトのマップ
                array.forEach(data.evacOrderAreaList, function(area) {
                    if (area.evacuateIssue !== '解除') {
                        isIssued = true;
                        //地区がマップ内にあるかどうか判定する
                        if(Object.keys(distEvaqNumMap).indexOf(area.districtCd) !== -1 &&
                            distEvaqNumMap[area.districtCd].evacOrderTimestamp < area.evacOrderTimestamp){
                            // 地区がマップ内に登録済みで、かつ登録済みの地区情報よりも現在の地区の方が新しい場合
                            distEvaqNumMap[area.districtCd] = area;
                        } else if(Object.keys(distEvaqNumMap).indexOf(area.districtCd) === -1) {
                            // 含まれていなかった場合は、今のareaをそのまま登録。
                            distEvaqNumMap[area.districtCd] = area;
                        }
                    }
                });

                // マップ内には、各地区の最新情報１件のみが入っている。
                array.forEach(Object.keys(distEvaqNumMap), function(distCd){
                    var dist = distEvaqNumMap[distCd];
                    evacHouseholdNum += (dist.evacHouseholdNum) ? dist.evacHouseholdNum : 0;
                    evaqueeNum += (dist.evaqueeNum) ? dist.evaqueeNum : 0;
                }, this);

                if(isIssued){
                    // もし一つでも「発令」があって、かつ対象世帯数・人数がこの時点で0だということは、
                    // 対象世帯数・人数が不明だということなので、nullを仕込む。（Lアラート連携時には空欄になる）
                    evacHouseholdNum = (evacHouseholdNum === 0) ? null : evacHouseholdNum;
                    evaqueeNum = (evaqueeNum === 0) ? null : evaqueeNum;
                }

                return {
                    sendCategory : '01', // 避難勧告・指示
                    municipalityCd : form.municipalityCd,
                    subject: form.evacOrderName,
                    evacTimestamp: form.evacOrderTimestamp,
                    issueReasonType: form.issueReasonType,
                    evacuateReason: form.issueReason,
                    evacOrderType: form.evacOrderType, // 緊急メール配信用
                    evacHouseholdNum: evacHouseholdNum,
                    evaqueeNum: evaqueeNum,
                    evacGuidunce: form.evacGuidance,
                    lastDistributionType: data.distributionType,
                    note: form.note,
                    evaqOrderData: data.evacOrderAreaList,
                    lalertSendHistId: data.lalertSendHistId,
                    isFix: isCorrectCancel, // 訂正・取消であるかどうか
                    correctCancelReason: form.correctCancelReason
                };
            }), function(error){
                console.error(error);
                throw new Error('全域判定APIの呼び出しに失敗。', error);
            });
        },


        /**
         * 指定された施設IDを元にLアラート・ダイアログ用の避難所情報を生成して返す。
         * @param {string} facilityIds 避難所のID一覧
         * @returns {Promise<Object>} Lアラート・ダイアログに出す避難所情報
         */
        _getLalertShelterArgs: function(facilityIds) {
            if (!facilityIds){
                return null;
            }
            facilityIds = facilityIds || '[]';
            return Requester.get('/api/facility/list/' + facilityIds).then(lang.hitch(this, function(facilityItems) {
                if(!facilityItems) {
                    return null;
                }
                var sendFacilityItems = array.filter(facilityItems, function(item){
                    return (item.publicFlg && item.publicFlg === '1');
                });
                if(!sendFacilityItems || sendFacilityItems.length === 0){
                    return null;
                }
                var evacShelterDateTime = this.formatDateFromObj(new Date());
                return {
                    facilityId: facilityItems[0].facilityId,
                    shelterArgs: {
                        sendCategory: '02',
                        subject: ' ',
                        evacTimestamp: new Date(),
                        // 更新種別 '01':新規, '02':更新・訂正, '03':取消
                        distributionType: '01',
                        evaqueeData: array.map(sendFacilityItems, function(item) {
                            return {
                                facilityId: item.facilityId,
                                address: item.address01 + item.address02,
                                evacShelterName: item.facilityName,
                                evacShelterNameKana: item.facilityNameKana,
                                evacShelterType: item.facilityTypeName,
                                evacShelterSort: '開設',
                                evacShelterLatitude: item.latitude,
                                evacShelterLongitude: item.longitude,
                                evaqueeNum: 0,
                                evaqueeActNum: 0,
                                evacHouseholdNum: 0,
                                evacHouseholdActNum: 0,
                                evacShelterDatetime: evacShelterDateTime,
                                evacTopical:' ',
                                evacShelterTypeOfDisaster:' ',
                                designatedEvacShFlg: item.designatedEvacShFlg,
                                edesignatedEvacShFlg: item.edesignatedEvacShFlg,
                                welfareEvacShFlg: item.welfareEvacShFlg
                            };
                        })
                    }
                };
            }), function(error){
                console.error(error);
                throw new Error('避難所用大阪府連携情報取得に失敗');
            });
        },

        _getUrgentMailArgs: function(municipalityCd) {
            var municipalityName = null;

            if (municipalityCd === this.PREF_MUNICIPALITY_CODE) {
                municipalityName = this.PREF_NAME;
            } else {
                municipalityName = Municipality.store.get(municipalityCd)
                .then(function(item) {
                    return item.name;
                });
            }
            var orgItem = this.organization.model.store.get(this._orgCd);
            /* var checkedRiverArray =  this.riverTree.getCheckedLeafs();
            var checkedRiverNameList = [];
            array.forEach(checkedRiverArray, function(river){
                checkedRiverNameList.push(river.name);
            }); */
            return all({
                municipalityName: municipalityName,
                organizationName: orgItem.then(function(item){ return item.name; }),
                // riverName: checkedRiverNameList.join('、'),
                // Lアラート配信ダイアログを表示した際に取得した地区一覧をテンプレートに利用する。
                districtList: Requester.post('/api/evacorders/district/hierarchy', {
                    data: {
                        municipalityCd: this._municipalityCd,
                        districtList: this.convertDistrictList(this._districtArray)
                    }
                })
            });
        },

        // これから発令する避難情報が「格上げまたは範囲拡大」であるかどうかの判定を行う。
        _checkUpgradeStatus: function(form){
            // 新規登録時
            var jsonStr = json.stringify(form);
            return Requester.post('/api/evacorders/checkUpgradeStatus', {
                data: jsonStr,
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(lang.hitch(this, function(data) {
                console.debug('緊急速報メール配信対象フラグ : ' + data.isUrgentMailTarget);

                return data.isUrgentMailTarget;
            }));
        },

        /**
         * 指定されたフォーム情報を元にLアラート・ダイアログ用の避難状況情報を生成して返す。
         * @param {Object} form フォーム情報
         * @param {String} distributionType 更新種別
         * @returns {Promise<Object>} Lアラート・ダイアログに出す避難状況情報
         */
        _getLalertEvacOrderArgs: function(form, distributionType, isCorrectCancel) {
            // 新規登録時
            var uri = '/api/evacorders/lalert';
            var query = {
                disasterId: form.disasterId,
                municipalityCd: form.municipalityCd,
                issueReasonType: form.issueReasonType
            };

            // 訂正時
            if (distributionType === DistributionType.CORRECT) {
                uri = '/api/evacorders/lalertCorrection';
                query.correctedId = form.evacOrderId;
                query.evacOrderType	= form.evacOrderType;
            }

            return Requester.get(uri, {
                query: query
            }).then(lang.hitch(this, function(data) {
                console.debug('取得した避難情報 : ' + data);

                // 市町村内の全避難世帯数・避難者数を計算する
                var evacHouseholdNum = 0;
                var evaqueeNum = 0;
                array.forEach(data.evacOrderAreaList, function(area) {
                    if (area.evacuateIssue !== '解除') {
                        evacHouseholdNum += area.evacHouseholdNum;
                        evaqueeNum += area.evaqueeNum;
                    }
                });
                return {
                    sendCategory : '01', // 避難勧告・指示
                    municipalityCd : form.municipalityCd,
                    subject: form.evacOrderName,
                    evacTimestamp: form.evacOrderTimestamp,
                    issueReasonType: form.issueReasonType,
                    issueReason: form.issueReason,
                    evacOrderType: form.evacOrderType, // 緊急メール配信用
                    evacHouseholdNum: evacHouseholdNum,
                    evaqueeNum: evaqueeNum,
                    evacGuidunce: form.evacGuidance,
                    note: form.note,
                    evaqOrderData: data.evacOrderAreaList,
                    lalertSendHistId: data.lalertSendHistId,
                    isFix: isCorrectCancel, // 詳細ページからの場合はerrata欄を表示
                    correctCancelReason: form.correctCancelReason
                };
            }));
        },

        setEvacOrderTypeSelector: function(issueReasonType, evacOrderType){
            // 動的な避難区分セレクターを作る
            var selectData = [];

            array.forEach(EvacOrderTypeModel, function(item){
                if (item.available === true || item.available[0] === true) {
                    selectData.push(item);
                }
            });

            var selectContent = null;
            if(issueReasonType &&
                (issueReasonType === '01' || issueReasonType === '02' || issueReasonType === '05')){
                selectContent = {
                    identifier  :'evacOrderTypeCd',
                    label       :'labelWithLevel',
                    items       :selectData
                };
            } else {
                selectContent = {
                    identifier  :'evacOrderTypeCd',
                    label       :'labelWithoutLevel',
                    items       :selectData
                };
            }


            var evacOrderItemFileWriteStore = new ItemFileWriteStore({
                    data:  selectContent
                });
            this.evacOrderType.set('sortByLabel', false);
            this.evacOrderType.set('store', evacOrderItemFileWriteStore);
            if(evacOrderType){
                // 解除系は81~89のいずれかが連携されるが、クライアント側では80として扱っているので変換の必要がある
                var evacValue = (evacOrderType.substring(0,1) === '8') ? '80' : evacOrderType;
                this.evacOrderType.set('value', evacValue);
            } else {
                this.evacOrderType.set('value', '11');
            }
            this.evacOrderType.on('change', lang.hitch(this,function(evt) {
                this.onEvacTypeChange(evt);
            }));
        },

        getAlertLevel: function(item){
            if(EvacOrderConfig.ALERT_LEVEL_ISSUE.indexOf(item.issueReasonType) === -1){
                // 警戒レベル付与対象の種別一覧に【含まれない】場合は、警戒レベルを付与しない
                return null;
            }
            if(item.evacuateIssue === '解除'){
                // 解除対象の地区には、警戒レベルを付与しない
                return null;
            }
            return EvacOrderConfig.ALERT_LEVEL_MAP_STR[item.evacuateSort].label4Lalert;
        },

        /**
         * 添付ファイル選択時に呼ばれる。
         */
        loadAttachFile: function() {
            //TODO 避難情報を参考に実装する
            console.debug('EvacOrderRegisterPage#loadAttachFile()');
        },

        // TODO 監視ページと同じように情報表示ボタンを設置する？要検討
        toggleLayer: function() {
            var targetLayer = this._layerControl.layers[this.LATEST_STATE_LAYER_NO];
            if(this._latestLayerState === this.LATEST_LAYER_STATE.ON) {
                this._latestLayerState = this.LATEST_LAYER_STATE.OFF;
                domStyle.set(this.mapCntlBtn, 'backgroundColor', '');
                // document.getElementById('currentStatus').style.backgroundColor = '#F90';
                this._layerControl.removeLayer(targetLayer);
            } else {
                this._latestLayerState = this.LATEST_LAYER_STATE.ON;
                domStyle.set(this.mapCntlBtn, 'backgroundColor', '#F90');
                this._layerControl.addLayer(targetLayer, this.LATEST_STATE_LAYER_NO);
                this._layerControl.layers[this.LATEST_STATE_LAYER_NO].bringToBack();
            }
        },

        /**
         * パンくずリストの避難情報概況ページリンクをクリックした時に呼ばれる
         * @param {Event} evt クリックイベント
         */
        onAdminLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('evacorder/admin', {viewMode : this._viewMode});
        },

        /**
         * パンくずリストの避難情報一覧ページリンクをクリックした時に呼ばれる
         * @param {Event} evt クリックイベント
         */
        onListLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('evacorder', {viewMode : this._viewMode});
        },

        /**
         * 避難理由のtips
         */
        setIssueReasonTypeTips: function() {
            // 避難理由
            var issueReasonTypeTips = new TooltipDialog({
                id: 'issueReasonTypeTips',
                style: 'width: 300px;',
                content: '<p>避難理由は訂正することができません。<br><br>' +
                    '避難理由を訂正する場合には、一度避難情報を取消してから、' +
                    '再度、避難情報を登録してください。</p>'
            });
            this.own(issueReasonTypeTips);
            on(dom.byId('issueReasonTypeLabel'), 'mouseover', function(){
                popup.open({
                    popup: issueReasonTypeTips,
                    around: dom.byId('issueReasonTypeLabel')
                });
            });
            on(dom.byId('issueReasonTypeLabel'), 'mouseleave', function(){
                popup.close(issueReasonTypeTips);
            });
        },

        /**
         * 発令理由詳細のtips
         */
        setIssueReasonTips: function() {
            // 避難理由
            var issueReasonTips = new TooltipDialog({
                id: 'issueReasonTips',
                style: 'width: 300px;',
                content: '<p>発令理由詳細の内容は、大阪府に連携され、NHK、Yahoo等から発信される情報として活用されることがあります。</p>'
            });
            this.own(issueReasonTips);
            on(dom.byId('issueReasonLabel'), 'mouseover', function(){
                popup.open({
                    popup: issueReasonTips,
                    around: dom.byId('issueReasonLabel')
                });
            });
            on(dom.byId('issueReasonLabel'), 'mouseleave', function(){
                popup.close(issueReasonTips);
            });
        },

        /**
         * 地区のtips
         */
        setDistrictTips: function() {
            // 避難理由
            var districtTips = new TooltipDialog({
                id: 'districtTips',
                style: 'width: 300px;',
                content: '<p>所属する子地区がすべて選択されると親の地区名で各メディアに連携されます。<br><br>' +
                    '地区情報に補足がある場合には、補足情報に入力してください。</p>'
            });
            this.own(districtTips);
            on(dom.byId('districtLabel'), 'mouseover', function(){
                popup.open({
                    popup: districtTips,
                    around: dom.byId('districtLabel')
                });
            });
            on(dom.byId('districtLabel'), 'mouseleave', function(){
                popup.close(districtTips);
            });
        },

        /**
         * 補足情報のtips
         */
        setNoteTips: function() {
            // 避難理由
            var noteTips = new TooltipDialog({
                id: 'noteTips',
                style: 'width: 300px; height:100px',
                content: '<p>補足情報は、大阪府に連携され、NHK、Yahoo等から発信される情報として活用されることがあります。</p>'
            });
            this.own(noteTips);
            on(dom.byId('noteLabel'), 'mouseover', function() {
                popup.open({
                    popup: noteTips,
                    around: dom.byId('noteLabel')
                });
            });
            on(dom.byId('noteLabel'), 'mouseleave', function() {
                popup.close(noteTips);
            });
        },

        /**
         * 訂正・取消理由のtips
         */
        setCorrectCancelReasonTips: function() {
            // 避難理由
            var correctCancelReasonTips = new TooltipDialog({
                id: 'correctCancelReasonTips',
                style: 'width: 300px;',
                content: '<p>訂正・取消の理由を書いてください。 入力内容は、NHK、Yahoo等から発信される情報として活用されます。<br><br>' +
                    '訂正・取消を実行する場合は必須入力です。</p>'
            });
            this.own(correctCancelReasonTips);
            on(dom.byId('correctCancelReasonLabel'), 'mouseover', function(){
                popup.open({
                    popup: correctCancelReasonTips,
                    around: dom.byId('correctCancelReasonLabel')
                });
            });
            on(dom.byId('correctCancelReasonLabel'), 'mouseleave', function(){
                popup.close(correctCancelReasonTips);
            });
        },

        formatDateFromObj: function(date){
            return date.getFullYear() + '-' + this._zeroPadding(date.getMonth() + 1) + '-' +
                        this._zeroPadding(date.getDate()) + 'T' + this._zeroPadding(date.getHours()) + ':' +
                        this._zeroPadding(date.getMinutes()) + ':' + '00.000+09';
        },

        /**
         * 月や日付を2桁にゼロpaddingする
         */
        _zeroPadding: function(month) {
            return ('00' + month).slice(-2);
        }

    });
});
