/**
 * 施設情報マスタ 登録ページ
 * @module app/facilityMaster/FacilityMasterRegisterPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/on',
    'dojo/json',
    'dojo/text!./templates/FacilityMasterRegisterPage.html',
    'idis/view/page/_PageBase',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/service/Requester',
    'idis/control/Router',
    'idis/map/IdisMap',
    'idis/view/Loader',
    'idis/service/GeoService',
    'leaflet',
    'app/config',
    // 以下、変数から参照されないモジュール
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'dijit/Tooltip',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/RadioGroup',
    'idis/view/form/DateTimeInput',
    'app/view/form/DistrictSelector',
    'app/view/form/OrganizationLevelSelector',
    'app/facilityMaster/FacilityMasterTypeSelector'
], function (module, declare, lang, on, json, template, _PageBase,
    DialogChain, InfoDialog, Requester, Router, IdisMap, Loader, GeoService, leaflet, config) {
    // GeoServiceを初期化
    var _geoService = null;

    /**
     * 施設情報マスタ 登録画面
     * @class FacilityMasterRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/FacilityMasterRegisterPage~FacilityMasterRegisterPage# */ {
            // テンプレート文字列
            templateString: template,
            constructor: function () {
                // ダイアログ連鎖を登録
                // 引数に与えたウィジェットのthis.ownを呼び出し、
                // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
                this.chain = DialogChain.get(this);

            },

            /**
             * startup
             */
            startup: function () {
                console.log('startup');
                this.inherited(arguments);

                // 地図を初期化
                this.initMap(config.map.latitude, config.map.longitude);
            },

            /**
             * 地図を初期化する。
             */
            initMap: function (latitude, longitude) {
                var latlng = null;
                if (latitude && longitude) {
                    latlng = [latitude, longitude];
                } else {
                    latlng = [config.map.latitude, config.map.longitude];
                }

                this.map = new IdisMap(this.mapNode, {
                    config: config.map,
                    keyboard: false, // コメント時に+/-が使用できないため
                    touchExtend: false,
                    minZoom: 9,
                    maxZoom: 18,
                    drawControlTooltips: false
                }
                ).setView(latlng, 12);
                // destroy時にmapを破棄するよう設定
                this.own(this.map);
                this.own(
                    on(this.map, 'click',
                        lang.hitch(this, function (e) {
                            // 作図ダイアログが閉じられているとき住所取得モードとする
                            this.pointLat = e.latlng.lat;
                            this.pointLng = e.latlng.lng;
                            this.addMark(this.pointLat, this.pointLng, this);
                        })
                    ));
                this.borderContainer.resize();
            },
            // テンプレートHTML内内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
            // 登録ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
            onSubmit: function () {
                try {

                    var sendData = this.form.get('value');

                    // フォームのバリデーションを実施
                    if (!this.form.validate()) {
                        return false;
                    }
                    if (this.facilityMasterType.get('value') === '') {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '施設種別を選択してください'
                        });
                        this.infoDialog.show();
                        this.infoDialog = null;
                        return false;
                    }
                    if (this.districtSelector.get('value') === '') {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '地区を選択してください'
                        });
                        this.infoDialog.show();
                        this.infoDialog = null;
                        return false;
                    }

                    if (this.facilityAdmOrgCd.get('value') === '') {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '管理組織を選択してください'
                        });
                        this.infoDialog.show();
                        this.infoDialog = null;
                        return false;
                    }

                    sendData.facilityAdmFlg = this.facilityAdmFlg.get('checked');
                    sendData.publicAvailableFlg = this.publicAvailableFlg.get('checked');

                    var jsonStr = json.stringify(sendData);
                    console.log('send data : ' + sendData);
                    this.chain.confirm("登録します。よろしいですか。", function (chain) {
                        var promise = Requester.post('/api/facilityMaster', {
                            data: jsonStr,
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(function (data) {
                            console.debug('success register DamageReport');
                            //chain.infoComplete(function() {
                            chain.info('完了しました。', '完了', function () {
                                // 一覧画面に移動
                                Router.moveTo('facilityMaster', { municipalityCd: this._municipalityCd });
                            });

                        }, function (error) {
                            console.log('error register damagereport');
                            chain.infoError(error);
                        });
                        //ローダーの表示
                        Loader.wait(promise);
                    });
                } catch (e) {
                    console.error(e);
                }
                return false;
            },
            /**
             * 一覧リンク クリック
             */
            onListLinkClick: function (evt) {

                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('facilityMaster');
            },


            /**
             * 地図上でポイントされている位置の住所を設定します。（発生場所）
             */
            onMapToAddress: function () {
                this.mapToAddress(this.address);
            },

            /**
             * 住所の位置を地図上にポイントします。（発生場所）
             */
            onAddressToMap: function () {
                this.addressToMap(this.address);

            },

            /**
             * 地図上でポイントされている位置の住所を設定します。
             */
            mapToAddress: function (addressWidget) {
                if (this.pointLat === '' || this.pointLng === '') {
                    console.debug('not pointed map');
                    this.chain.info('地図が選択されていません。', 'エラー');
                    return;
                }
                console.debug('start reverse geocoding');

                if (_geoService === null) {
                    _geoService = new GeoService({ url: config.geocode && config.geocode.url });
                }
                _geoService.reverseGeocode(leaflet.latLng({
                    lat: this.pointLat,
                    lng: this.pointLng
                })).then(lang.hitch(this, function (res) {
                    this.addMark(this.pointLat, this.pointLng);
                    this.address.set('value', res.address.Address);
                    this.latitude.set('value', this.pointLat);
                    this.longitude.set('value', this.pointLng);

                }), lang.hitch(this, function () {
                    this.chain.info('住所を取得できませんでした。', 'エラー');
                }));

                console.debug('end reverse geocoding (address: ' +
                    addressWidget.value + ')');
            },

            /**
             * 住所の位置を地図上にポイントします。
             */
            addressToMap: function (addressWidget) {
                console.debug('start geocoding (address: ' +
                    addressWidget.value + ')');

                if (!addressWidget.value) {
                    console.log('not input address');
                    this.chain.info('住所を入力してください。', 'エラー');
                    return;
                }
                var address = addressWidget.value;
                if (_geoService === null) {
                    _geoService = new GeoService({ url: config.geocode && config.geocode.url });
                }
                _geoService.geocode(address).then(lang.hitch(this, function (results) {
                    if (results.length > 0) {
                        var latlng = [results[0].latlng.lat, results[0].latlng.lng];
                        this.pointLat = results[0].latlng.lat;
                        this.pointLng = results[0].latlng.lng;
                        this.addMark(this.pointLat, this.pointLng);
                        this.map.setView(latlng, 11);
                        this.latitude.set('value', this.pointLat);
                        this.longitude.set('value', this.pointLng);
                    } else {
                        console.debug('address is not correct');
                        this.chain.info('住所から位置情報を取得できませんでした。', 'エラー');
                    }
                }));
            },

            /**
             * マーカーを追加する。サブ地図がある場合は両方の地図でマーカーを共有する
             */
            addMark: function (lat, lng) {
                this.removeMark();
                this.marker = leaflet.marker([lat, lng]).addTo(this.map);
            },

            /**
             * マーカーを削除する。
             */
            removeMark: function () {
                if (this.marker) {
                    this.map.removeLayer(this.marker);
                }
            },
        });
});

