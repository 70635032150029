/**
 * 被害情報や事前情報等の表示情報を扱う。
 * @module app/model/Layer
 */
define([
    'idis/model/UserInfo',
    'idis/store/JsonLayerModel',
    './LayerStore'
], function(UserInfo, JsonLayerModel, LayerStore) {
    /**
     * シングルトンを返す。
     */
    return new JsonLayerModel({
        rootId: 0,
        store: LayerStore,
        filter: function(item) {
            if (item.hideFlg) {
                // 非表示フラグ指定時は一律非表示
                return false;
            }
            if (item.infoCategoryCd.indexOf('T') === 0) {
                // ディレクトリー（情報種別コードが'T'で始まる）は子要素がある場合だけ表示
                return item.children && item.children.length && UserInfo.hasAccess(item);
            } else {
                // 現在のユーザにアクセス権がある場合は表示
                return UserInfo.hasAccess(item);
            }
        }
    });
});

