/**
 * 水道局情報閲覧詳細（浄水場被害情報）画面用モジュール。
 * @module app/waterworks/JosuihigaiDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/JosuihigaiDetailDialog.html',
    './_WaterworksBase',
    // 以下、変数から参照されないモジュール
], function(module, declare, lang, domStyle, template, _WaterworksBase) {
    /**
     * 水道局受信情報管理詳細(浄水場被害情報）画面
     * @class JosuihigaiDetailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _WaterworksBase,
        /** @lends module:app/waterworks/JosuihigaiDetailDialog~JosuihigaiDetailDialog# */ {


        // テンプレート文字列
        templateString: template,

        // 初期処理
        initProcess:function(key, item){
            // ダイアログの初期化
            this.initDialog(key, item);
        },

        afterProcess: function(){   
            // scroll Bar top
            this.josuihigaiDiv.scrollTop = 0;
        }

    });

});
