/**
 * 雨量共通カラム一覧
 * @module app/rain/grid/RainfallObservationCommonColum
 */
define([
    'app/view/grid/CommonColumn',
    'idis/view/grid/helper'
], function(CommonColumn, helper) {
    /**
     * 基準値超過フラグを返す
     * @param {*} value     観測値
     * @param {*} type      観測種別
     */
    var getExcess = function(value, type){
        switch (type) {
            // case '10min':
            //     if(value>=30){ return 3;}
            //     if(value>=20){ return 2;}
            //     if(value>=10){ return 1;}
            // break;
            case '60min':
                if(value>=80){ return 3;}
                if(value>=50){ return 2;}
                if(value>=30){ return 1;}
            break;
            case 'cumulative':
                if(value>=250){ return 6;}
                if(value>=200){ return 5;}
                if(value>=150){ return 4;}
            break;
        }
    };
    return {
        rainfall10Min: helper.column('rainfall10Min', '10分雨量\n[mm]', {
            formatter: function(value, item) {
                return CommonColumn.formatObservationData(item, 'rainfall10Min', 1);
            },
            className : function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) { return; }
                return CommonColumn.getClassNameExcess(item, 'rainfall10Min',
                    getExcess(item.rainfall10Min, '10min'));
            },
            sortable: false }),
        rainfall30Min: helper.column('rainfall30Min', '30分雨量\n[mm]', {
            formatter: function(value, item) {
                return CommonColumn.formatObservationData(item, 'rainfall30Min', 1);
            },
            className : function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) { return; }
                return CommonColumn.getClassNameExcess(item, 'rainfall30Min');
            },
            sortable: false }),
        rainfall60Min: helper.column('rainfall60Min', '60分雨量\n[mm]', {
            formatter: function(value, item) {
                return CommonColumn.formatObservationData(item, 'rainfall60Min', 1);
            },
            className : function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) { return; }
                return CommonColumn.getClassNameExcess(item, 'rainfall60Min',
                    getExcess(item.rainfall60Min, '60min'));
            },
            sortable: false }),
        // rainfallHourly: helper.column('rainfallHourly', '時間雨量\n[mm/1時間]', {
        //     formatter: function(value, item) {
        //         return CommonColumn.formatObservationData(item, 'rainfallHourly', 1);
        //     },
        //     className : function(item) {
        //         // ヘッダーの場合はclassNameを返さない
        //         if (!item) { return; }
        //         return CommonColumn.getClassNameExcess(item, 'rainfallHourly',
        //             getExcess(item.rainfallHourly, '60min'));
        //     },
        //     sortable: false }),
        rainfall6Hour: helper.column('rainfall6Hour', '6時間雨量\n[mm]', {
            formatter: function(value, item) {
                return CommonColumn.formatObservationData(item, 'rainfall6Hour', 1);
            },
            className : function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) { return; }
                return CommonColumn.getClassNameExcess(item, 'rainfall6Hour');
            },
            sortable: false }),
        rainfall24Hour: helper.column('rainfall24Hour', '24時間雨量\n[mm]', {
            formatter: function(value, item) {
                return CommonColumn.formatObservationData(item, 'rainfall24Hour', 1);
            },
            className : function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) { return; }
                return CommonColumn.getClassNameExcess(item, 'rainfall24Hour');
            },
            sortable: false }),
        rainfallCumulative: helper.column('rainfallCumulative', '累計雨量\n[mm]', {
            formatter: function(value, item) {
                return CommonColumn.formatObservationData(item, 'rainfallCumulative', 1);
            },
            className : function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) { return; }
                return CommonColumn.getClassNameExcess(item, 'rainfallCumulative',
                    getExcess(item.rainfallCumulative, 'cumulative'));
            },
            sortable: false })
    };
});

