/**
 * <雨量ランキング>
 * @module app/view/page/RainfallObservationRankingPage
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/text!./templates/RainfallObservationRankingPage.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/control/Router',
    'idis/store/IdisRest',
    'idis/view/dialog/InfoDialog',
    // 以下、変数で受けないモジュール,
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/DateTimeInput',
    'dijit/form/Form',
    'dijit/form/Select',
    './grid/RainfallObservationRankingPageGrid'
], function(module, declare, lang, template, _PageBase, Router, IdisRest, InfoDialog) {
    /**
     * 雨量ランキング
     * @class RainfallObservationRankingPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/RainfallObservationRankingPage~RainfallObservationRankingPage# */ {
        // テンプレート文字列
        templateString: template,
        store: null,
        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--rainfallobs',

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            this.initTime();
            this.initGrid();
        },

        constructor: function() {
            this.store = new IdisRest({
                target: '/api/rain/rank'
             });
        },

        initTime: function(){
        	var endDate = new Date();
        	var startDate = new Date();
        	startDate.setDate(startDate.getDate()-1);
        	this.rankingEndTime._setValueAttr(endDate);
        	this.rankingStartTime._setValueAttr(startDate);
        },

        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.onDetailButtonClick(evt.item);
            }));
            this.updateGridQuery();
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function() {
            var value = this.form.get('value');
            // 入力チェック
            if(!value.rankingStartTime || !value.rankingEndTime) {
                console.debug('対象期間が選択されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '対象期間が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return false;
            }

            // 対象期間が一週間以上であれば検索できなくする
            if(value.rankingEndTime.getTime() - 7 * 24 * 60 * 60 * 1000 > value.rankingStartTime.getTime()) {
                console.debug('対象期間を一週間以内で指定してください');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '対象期間は一週間以内で指定してください'
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return false;
            }

            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            // 対象項目
            if (value.rankingMode) {
                filter = filter.eq('rankingMode', value.rankingMode);
            }
            // 対象期間(From)
            if (value.rankingStartTime) {
                var rankingStartTime = new Date(value.rankingStartTime).getTime();
                filter = filter.eq('rankingStartTime', rankingStartTime);
            }
            // 対象期間(To)
            if (value.rankingEndTime) {
                var rankingEndTime = new Date(value.rankingEndTime).getTime();
                filter = filter.eq('rankingEndTime', rankingEndTime);
            }
            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
            this.setGridColumn();
        },

        /**
         * 検索ボタンが押されたときに呼び出される。
         * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
         * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
         */
        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },

        /**
         * [対象項目]によってグリッドのカラム表示・非表示項目を切り替える
         */
        setGridColumn: function() {
        	if (this.rankingModeSelect.value === '01') {
        		// 時間雨量の場合、
        		// 「時間雨量」「観測日時」カラムを表示、「24時間雨量」「累加雨量」を非表示
        		this.grid.styleColumn('0-3', 'display: table-cell;');
        		this.grid.styleColumn('0-4', 'display: none;');
        		this.grid.styleColumn('0-5', 'display: none;');
        		this.grid.styleColumn('0-6', 'display: table-cell;');
        	} else if (this.rankingModeSelect.value === '02') {
        		// 24時間雨量の場合、
        		// 「24時間雨量」「観測日時」カラムを表示、「時間雨量」「累加雨量」を非表示
        		this.grid.styleColumn('0-3', 'display: none;');
        		this.grid.styleColumn('0-4', 'display: table-cell;');
        		this.grid.styleColumn('0-5', 'display: none;');
        		this.grid.styleColumn('0-6', 'display: table-cell;');
        	}else{
           		// 累加雨量の場合、
        		// 「累加雨量」カラムを表示、「時間雨量」「24時間雨量」「観測日時」を非表示
        		this.grid.styleColumn('0-3', 'display: none;');
        		this.grid.styleColumn('0-4', 'display: none;');
        		this.grid.styleColumn('0-5', 'display: table-cell;');
        		this.grid.styleColumn('0-6', 'display: none;');

        	}
        },

        /**
         * [詳細]ボタンが押下されたときに呼び出される
         */
        onDetailButtonClick: function(object) {
            var date = new Date(object.dataTimestamp).getTime();
            var timeMode ='10';
            // 雨量情報詳細画面へ遷移 (別Windowで開く)
            var query = '/?p=observation/rainfall/detail' +
                    '&observatoryId=' + object.observatoryId +
                    '&regionCd=' + object.regionCd +
                    '&date=' + date +
                    '&timeMode=' + timeMode;
            window.open(query);
        },

        /**
         * [雨量情報一覧]が押下されたときに呼び出される
         */
        onRainfallObservationLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('observation/rainfall');
        }
    });
});
