/**
 * 組織選択用入力パーツ
 * @module app/view/form/SectSelector
 */
define([
    'module',
    'dojo/_base/lang',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/dom-style',
    'dojo/promise/all',
    'dojo/when',
    'dojox/lang/functional/array',
    'idis/error/InvalidArgumentException',
    'idis/view/form/TreeChecker',
    'app/model/ChronologyShareOrgs'
], function(module, lang, declare, array, domStyle, all, when, df, InvalidArgumentException,
    TreeChecker, ChronologyShareOrgs) {
    /**
     * 組織選択用パーツ。
     * @class SectSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:app/view/form/SectSelector~SectSelector# */ {
        // 選択ダイアログのタイトル
        title: '県/市町村選択',

        // ツリー・モデル
        model: ChronologyShareOrgs,

        /**
         * 選択した値の設定
         */
        _setValueAttr: function(value) {
            // 継承した親クラスの処理
            //this.inherited(arguments);
            if (!lang.isArray(value)) {
                var message = '#_setValueAttr: 配列を指定してください: value=' + value;
                throw new InvalidArgumentException(module.id + message);
            }
            // value値を設定
            this._set('value', value);
            // 偽値は数値の0のみ有効とする
                if (value || value === 0) {
                    this._set('value', value);
                    this.emit('change', {value: value});
                    this._initTree();
                } else {
                    this._set('value', '');
                    this.emit('change', {value: ''});
                    this.status.innerHTML = this.emptyValueLabel;
                }
                // 要素の選択状態をリセットする
                this._initTree().then(lang.hitch(this, function() {
                    this.tree.set('selectedItem', null);
                }));
                this.dialog.hide();
            // 選択状態を更新
            this._initTree().then(lang.hitch(this, function() {
                // 指定されなかったvalue値に対応する要素のチェックを解除
                return all(df.map(this.tree._checkMap, function(item, id) {
                    if (array.indexOf(value, id) === -1) {
                        return this.tree.setChecked(item, false);
                    }
                }, this));
            })).then(lang.hitch(this, function() {
                return all(array.map(value, function(v) {
                    // 各値を識別子とする要素を取得
                    return when(this.tree.model.store.get(v), lang.hitch(this, function(item) {
                        // チェックされたものをツリーに反映
                        return this.tree.setChecked(item, true);
                    }));
                }, this));
            })).then(lang.hitch(this, function() {
                // チェックされたものを選択表示へ反映
                // 親がチェックされている場合、子は表示しない
                var excludeMap = {};
                df.forIn(this.tree._checkMap, function(item) {
                    if(this.tree.isEveryChildChecked(item)){
                        this.tree.getItemChildren(item).then(lang.hitch(this, function(items) {
                            if(!items){
                                return false;
                            }
                            array.forEach(items, function(item){
                                excludeMap[this.tree.model.store.getIdentity(item)] = true;
                            }, this);
                        }));
                    }
                }, this);
                var ret = df.filter(this.tree._checkMap, function(item) {
                    return !excludeMap[this.tree.model.store.getIdentity(item)];
                }, this);
                if(ret.length > 0) {
                    this.status.innerHTML = array.map(ret, function(item) {
                        return this.model.getLabel(item);
                    }, this).join(', ') ||  '全体';
                } else {
                    this.status.innerHTML = '未選択';
                }

            }));
            // 以下、当モジュール固有の処理
            // 選択結果が20文字を超えた場合、・・・(三点リーダ)で省略する
            domStyle.set(this.status, {
                'max-width': '20em',
                'white-space': 'nowrap',
                'overflow': 'hidden',
                'text-overflow': 'ellipsis'
            });
        }
    });
});
