/**
 * テンプレート新規登録ダイアログ用。
 * @module app/view/ConvoMailTempRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ConvoMailTempRegisterDialog.html',
    'idis/view/page/_PageBase',
    'idis/view/dialog/DialogChain',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/SimpleTextarea',
    'dijit/form/ValidationTextBox',
    'idis/view/form/DateTimeInput',
    'idis/view/form/Button',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup'

], function(module, declare, lang, template, _PageBase, DialogChain) {
    /**
     * テンプレート新規登録ダイアログ内の画面。
     * @class ProvideTempRegisterDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), [_PageBase],
        /** @lends module:idis/view/page/ConvoMailTempRegisterDialog~ConvoMailTempRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,

        constructor: function() {
            // ダイアログの連鎖関係を取得
            this.chain = DialogChain.get(this);
        },

        /**
         * 登録ボタンが押された際の処理。
         */
        onSubmit: function() {
            try {
                var value = this.form.get('value');
                if (this.form.validate() && this.validateForm(value)) {
                    // 実際の登録処理はPage側でregisterイベントを監視しているメソッドが実施
                    this.emit('register', {value: value});
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },
        
        /**
         * @param value formの情報
         * formの値の妥当性を判定する。
         */
        validateForm: function(value) {
            
            var msg = [];
            
            if (!value.distributionBody) {
                this.chain.info('本文を入力してください。', '入力エラー');
                return false;
            }

            if (msg.length>0) {
                this.chain.info(msg.join('<br>'), '入力チェックエラー');
                return false;
            }
            return true;
        }
    });
});

