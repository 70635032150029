/**
 * 地震情報画面用モジュール。
 * @module app/earthquake/view/EarthquakePage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/control/Router',
    'dojo/text!./templates/EarthquakePage.html',
    'idis/store/IdisRest',
    'idis/view/page/_PageBase',
    './EarthquakeDetailContent',
    './LongPeriodGroundMotionDetailContent',
    '../../config',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'app/common/view/StatementDialog',
    './EarthquakeGrid',
    './LongPeriodGroundMotionGrid',
], function(module, declare, lang, Router, template, IdisRest, _PageBase, EarthquakeDetailContent, LongPeriodGroundMotionDetailContent, config) {
    /**
     * 地震情報画面
     * @class EarthquakePage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/earthquake/view/EarthquakePage~EarthquakePage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClassEarthquake: 'idis-Page idis-Page--earthquake',
        baseClassLongEarthquake: 'idis-Page idis-Page--longperiodgroundmotion',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        earthquakeStore: null,
        longEarthquakeStore: null,

        tabName: null,
        
        constructor: function() {
            // データ格納用オブジェクト
            this.earthquakeStore = new IdisRest({
                idProperty: 'earthquakeId',
                target: '/api/earthquakes',
            });
            this.longEarthquakeStore = new IdisRest({
                idProperty: 'longperiodgroundmotionId',
                target: '/api/earthquake/longperiodgroundmotion'
            });
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.statementDialog);
            this.own(this.statementDialogLg);
            // 検索時刻を初期化する
            this.initForm();
            // グリッドを初期化する
            this.initGrid();                       
        },

        startup: function() {
            this.inherited(arguments);

            this.tabName = 'earthquake';

            this.initGrid();

            this.initForm();
        },

        initForm: function(){
            // 県名表示
            this.prefName.innerHTML = config.municInfo.prefName;
            this.prefNameLg.innerHTML = config.municInfo.prefName;
            // 検索時刻を初期化する
            this.initTime();
        },
        
        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.earthquakeStore.Filter();

            var reportDatetimeFrom = new Date(this.reportDatetimeFrom._getValueAttr()).getTime();
            filter = filter.eq('reportDatetimeFrom', reportDatetimeFrom);

            var reportDatetimeTo = new Date(this.reportDatetimeTo._getValueAttr()).getTime();
            filter = filter.eq('reportDatetimeTo', reportDatetimeTo);

            filter = filter.eq('prefFlg', this.prefFlg.checked);

            this.earthquakeGrid.set('collection', this.earthquakeStore.filter(filter));
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.earthquakeGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.showDetailDialog(evt.item);
            }));

            var filterLg = new this.longEarthquakeStore.Filter();

            var reportDatetimeFromLg = new Date(this.reportDatetimeFromLg._getValueAttr()).getTime();
            filterLg = filterLg.eq('reportDatetimeFrom', reportDatetimeFromLg);

            var reportDatetimeToLg = new Date(this.reportDatetimeToLg._getValueAttr()).getTime();
            filterLg = filterLg.eq('reportDatetimeTo', reportDatetimeToLg);

            filter = filterLg.eq('prefFlg', this.prefFlgLg.checked);

            this.LongPeriodGroundMotionGrid.set('collection', this.longEarthquakeStore.filter(filter));
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.LongPeriodGroundMotionGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.showLongEarthquakeDetailDialog(evt.item);
            }));
        },

        changEarthquake: function(){
            this.tabName = 'earthquake';
        },
        changeLongEarthquake: function(){
            this.tabName = 'longEarthquake';
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.earthquakeStore.Filter();
            if (value.reportDatetimeFrom) {
                var reportDatetimeFrom = new Date(value.reportDatetimeFrom).getTime();
                filter = filter.eq('reportDatetimeFrom', reportDatetimeFrom);
            }

            if (value.reportDatetimeTo) {
                var reportDatetimeTo = new Date(value.reportDatetimeTo).getTime();
                filter = filter.eq('reportDatetimeTo', reportDatetimeTo);
            }

            filter = filter.eq('prefFlg', this.prefFlg.checked);
            // filterに対応するcollectionを取得
            var collection = this.earthquakeStore.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.earthquakeGrid.set('collection', collection);
        },

        updateLongEarthquakeGridQuery: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.longEarthquakeStore.Filter();

            if (value.reportDatetimeFromLg) {
                var reportDatetimeFromLg = new Date(value.reportDatetimeFromLg).getTime();
                filter = filter.eq('reportDatetimeFrom', reportDatetimeFromLg);
            }

            if (value.reportDatetimeToLg) {
                var reportDatetimeToLg = new Date(value.reportDatetimeToLg).getTime();
                filter = filter.eq('reportDatetimeTo', reportDatetimeToLg);
            }

            filter = filter.eq('prefFlg', this.prefFlgLg.checked);

            // filterに対応するcollectionを取得
            var collection = this.longEarthquakeStore.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.LongPeriodGroundMotionGrid.set('collection', collection);
        },

        onSubmit: function() {
            try {
                if (this.tabName === 'earthquake'){
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery(this.form.get('value'));
                }
            }
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },

        onSubmitLg: function() {
            try {
                if (this.tabName === 'longEarthquake'){
                if (this.formLg.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateLongEarthquakeGridQuery(this.formLg.get('value'));
                }
            }
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },

        // 検索時刻の初期値をセット
        initTime: function(){
            var endDate = new Date();
            var startDate = new Date();
            startDate.setDate(startDate.getDate()-7);
            this.reportDatetimeTo._setValueAttr(endDate);
            this.reportDatetimeFrom._setValueAttr(startDate);
            this.reportDatetimeToLg._setValueAttr(endDate);
            this.reportDatetimeFromLg._setValueAttr(startDate);
        },

        showDetailDialog: function(item) {
            // 情報文ダイアログを表示する
            this.innerStatementDialog.setMessage(new EarthquakeDetailContent({item:item}).getContent());
            // スクロールをダイアログのトップにする
            this.statementDialog.set('autofocus', false);
            this.statementDialog.show();
        },

        showLongEarthquakeDetailDialog: function(item) {
            // 情報文ダイアログを表示する
            this.innerStatementDialogLg.setMessage(new LongPeriodGroundMotionDetailContent({item:item}).getContent());
            // スクロールをダイアログのトップにする
            this.statementDialogLg.set('autofocus', false);
            this.statementDialogLg.show();
        },

        onEvacOrderRegisterButtonClick: function(evt) {
            console.log('[新規登録]ボタンがクリックされました。');
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 避難状況登録登録画面へ遷移
            Router.moveTo('evacorder/register');
        },


    });
});
