/**
 * <モジュールの説明>
 * @module app/view/page/<ウィジェット名>
 */
define(['module',
	'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ConvoEmployeeOutputReportDialog.html',
    'idis/consts/ACL',
    'idis/model/UserInfo',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'dojo/Deferred',
    'dojo/json',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'dijit/form/RadioButton',
    'dijit/form/Form'],
    function(module, declare, lang, template, ACL, UserInfo,
        DialogChain, InfoDialog, _PageBase, Loader, Deferred, JSON) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
        // テンプレート文字列
        templateString: template,

        constructor: function(){
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
            // 災害IDを取得
            //this._disasterId = DisasterInfo.getDisasterId();
        },

            /**
             * ダイアログの初期化
             */
         initDialog: function(val) {
             var role = UserInfo.getRoleCd();
             if (role !== ACL.ADMIN_USER) {
                 this.organization = val.organization.slice(1);
             } else {
	             this.organization = null;
             }
         },

         /*
                       * ファイル読み込み実行
         */
        onSubmit: function() {
            if (!this.validate()) {
                return false;
            }
            this.onOutputExcelButtonClick();
        },

        //帳票出力
        onOutputExcelButtonClick : function() {
                //console.log('[帳票出力]ボタンがクリックされました。');
                //var msg = '職員一覧を出力します。<br>よろしいですか？';

                this.chain.confirm('職員一覧を出力します。<br>よろしいですか？' , function(chain) {
                // 確認ダイアログを表示
                    // 確認ダイアログでOKを押した場合
                    chain.hide();

                    var reqid = 'DP_EMPLOYEE_LIST_EX';
                    var fileName = 'dpEmployeeList.xlsx';
                    var fileNameJp = '職員一覧.xlsx';
                    var paramList = [];
                    paramList.push({key: 'organization', value: this.organization});

                    var data4xoblosDownload = {
                        fileName: fileName,
                        reqid: reqid,
                        paramList: paramList,
                        fileType: 'excel'
                    };

                    var promise = this.download(data4xoblosDownload, fileNameJp).then(lang.hitch(this, function() {
                            this.infoDialog = new InfoDialog({
                                title : '出力完了',
                                content : '職員一覧帳票の出力が完了しました。'
                            });
                            this.infoDialog.show();

                        }), lang.hitch(this, function(error) {
                            console.error(error);
                            this.infoDialog = new InfoDialog({
                                title : 'エラー',
                                content : 'エラーが発生しました。管理者にお問い合わせください。'
                            });
                            this.infoDialog.show();
                    }));

                    Loader.wait(promise);
                 });
        },

        onOutputPdfButtonClick : function() {
                //console.log('[帳票出力]ボタンがクリックされました。');
                //var msg = '職員一覧を出力します。<br>よろしいですか？';

                this.chain.confirm('職員一覧を出力します。<br>よろしいですか？' , function(chain) {
                // 確認ダイアログを表示
                    // 確認ダイアログでOKを押した場合
                    chain.hide();

                    var reqid = 'DP_EMPLOYEE_LIST_EX';
                    var fileName = 'dpEmployeeList.pdf';
                    var fileNameJp = '職員一覧.pdf';
                    var paramList = [];
                    paramList.push({key: 'organization', value: this.organization});

                    var data4xoblosDownload = {
                        fileName: fileName,
                        reqid: reqid,
                        paramList: paramList,
                        fileType: 'pdf'
                    };

                    var promise = this.download(data4xoblosDownload, fileNameJp).then(lang.hitch(this, function() {
                            this.infoDialog = new InfoDialog({
                                title : '出力完了',
                                content : '職員一覧帳票の出力が完了しました。'
                            });
                            this.infoDialog.show();

                        }), lang.hitch(this, function(error) {
                            console.error(error);
                            this.infoDialog = new InfoDialog({
                                title : 'エラー',
                                content : 'エラーが発生しました。管理者にお問い合わせください。'
                            });
                            this.infoDialog.show();
                    }));

                    Loader.wait(promise);
                 });
	    },


        //帳票出力
        download : function(data, baseFileName) {
            var deferred = new Deferred();

            var xhr = new XMLHttpRequest();
            xhr.open('POST', '/api/xoblos/download', true);
            xhr.responseType = 'arraybuffer';
            xhr.setRequestHeader( 'Content-Type', 'application/json' );
            xhr.onload = function() {

                // エラー時は処理を止める
                if(xhr.status !== 200){
                    deferred.reject();
                    return;
                }

                // バイナリデータを取得
                var arrayBuffer = this.response;
                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, fileName);
                } else {
                    // 擬似的にAタグを作成
                    var link = document.createElement('a');
                    link.style = 'display: none';
                    document.body.appendChild(link);

                    // AタグのURLにバイナリデータをセット
                    var url = window.URL.createObjectURL(blob);
                    link.href = url;

                    // ファイル名をセット
                    link.download = fileName;

                    // 擬似的にリンクをクリック
                    link.click();
                    // 参照を解放
                    window.URL.revokeObjectURL(url);
                    link.remove();
                }

                deferred.resolve();

            };
            xhr.send(JSON.stringify(data));

            return deferred.promise;
        },

        /**
         * キャンセルボタン押下
         */
        onCancelButtonClick:function(){
            this.getParent().hide();
        },

        /**
         * 入力チェック
         */
        validate: function() {
            //ファイルが空の場合は処理を中断
//            if(this.attachFile.getFileList().length === 0) {
//                InfoDialog.show('入力チェックエラー', 'ファイルが選択されていません');
//                return false;
//            }
            return true;
        }
    });
});
