/**
* 火山情報詳細画面用モジュール。
* @module app/volcano/VolcanoDetailPage
*/
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function(module, declare, IdisGrid, helper) {

    /**
     * 日時を表示形式に変換する
     * 'yyyy-MM-dd HH:mm:dd'を'年月日時分'に変換する
     * FIXME 外部化を検討。helperで対応?
     *
     * @param {string} 'yyyy-MM-dd HH:mm:dd'
     * @return {string} 'yyyy年MM月dd日 HH時mm分'
     */



    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            return item && item.pubStatus === 1 ? 'is-public' : '';
        },

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.column('areaIName', '対象市/行政区'),
            helper.column('vwName', '警報・予報種別'),
            helper.column('condition', '状況')
        ]
    });
});
