/**
 * 被害状況集計画面用モジュール。
 * @module app/damege/damageReportAdmin/DamageReportAdminPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/text!./templates/DamageReportAggregatePage.html',
    'dojo',
    'dojo/dom-style',
    'dojo/topic',
    'dojo/Deferred',
    'dstore/Memory',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'idis/view/Loader',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'app/model/DisasterInfo',
    'idis/view/dialog/DialogChain',
    'idis/map/IdisMap',
    'app/config',
    '../_DamageReportPageBase',
    '../integrate/IntegrationDialog.js',
    'leaflet',
    'leaflet.markercluster',
    // 以下、変数として受け取らないモジュール
    'dijit/form/Form',
    'dijit/form/CheckBox',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'app/view/form/DisasterSelector',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'app/view/form/CustomMunicipalityMultiSelectorNoPref',
    'app/view/form/DamageHldOrgMultiSelector',
    'app/damage/damageForm/DamageReportFormDialog',
    './DamageReportAggregateGrid',
    'app/view/form/RegionSelector'
], function (
    module,
    declare,
    lang,
    array,
    template,
    dojo,
    domStyle,
    topic,
    Deferred,
    Memory,
    Locator,
    Router,
    IdisRest,
    Requester,
    Loader,
    UserInfo,
    UserType,
    DisasterInfo,
    DialogChain,
    IdisMap,
    config,
    _DamageReportPageBase,
    IntegrationDialog,
    leaflet
) {
    var damageFilterStore = {};

    /**
     * 被害報画面。
     * @class DamageReportAggregatePage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(
        module.id.replace(/\//g, '.'),
        _DamageReportPageBase,
        /** @lends module:app/damege/DamageReportAggregatePage~DamageReportAggregatePage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--damage',

            /**
             * データ格納用オブジェクト
             * @type {module:dstore/Store}
             */
            store: null,

            KUMAMOTO_PREF_LATLNG: { LAT: 34.661909, LNG: 133.934671 },
            MAP_ZOOM: 11,

            /**
             * 地図を動的に描画・操作するために発行するトピック
             */
            DRAW_CIRCLE: 'app.damage.DamageReportAggregatePage' + '::drawCircle',

            /**
             * 地図上に描画された市町
             */
            drawnDamageIds: [],
            markerGroupList: [],

            _integrationDialog: null,

            _damageTypeDispList: [],

            _layerControl: null,
            _mapFeatureList: null,

            constructor: function () {
                //this.inherited(arguments);
                // データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'damageReportId',
                    target: '/api/damageReports/aggregate',
                    rangeStartParam: 'offset',
                    rangeCountParam: 'count'
                });
                this.chain = DialogChain.get(this);
                //地図アイコンに関する情報を初期化
                this.drawnDamageIds = [];
                this.markerGroupList = [];

                console.debug('現在のログインユーザID：' + UserInfo.getId());
                console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());
                this.getDamageTypeList();
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
            },

            startup: function () {
                this.inherited(arguments);

                this.initButtonStatus();

                // 地図を初期化する
                this.initMap(this.INIT_LATLNG.lat, this.INIT_LATLNG.lng);

                // グリッドの概況を地図上に図示するためのトピック
                var drawCircleEvent = topic.subscribe(
                    this.DRAW_CIRCLE,
                    lang.hitch(this, function (arg) {
                        this.addCircle(arg);
                    })
                );
                this._events.push(drawCircleEvent);
                // 被害の種類一覧設定
                this.initSetDamageTypeList();

                // 初期選択する市町村情報をセット
                this.initMunicipalitySelector();

                if (UserInfo.getDamageViewType() === '4') {
                    this.reportStatus.set('value', '41');
                    this.reportStatus.set('disabled', true);
                }

                this.onMunicipalityChange();
                this.own(
                    topic.subscribe(
                        this.MUNICIPALITY_SELECTED,
                        lang.hitch(this, function (data) {
                            this.setMunicipalityCd(data);
                        })
                    )
                );

                this.own(
                    topic.subscribe(
                        this.MUNICIPALITY_RELEASED,
                        lang.hitch(this, function (data) {
                            this.releaseMunicipalityCd(data);
                        })
                    )
                );

                this.own(this.municipalityCd);

                // グリッドを初期化する
                this.initGrid();

                // 大阪市：画面自動更新用のタイマーを設定(5分毎)
                this.timer = setInterval(
                    lang.hitch(this, function () {
                        this.updateGridQuery(this.form.get('value'));
                    }),
                    5 * 60 * 1000
                );
            },
            // 被害の種類のセレクタ一覧を生成
            initSetDamageTypeList: function () {
                var optionList = [
                    {
                        label: '&nbsp;',
                        value: ''
                    }
                ];
                this.getDamageTypeList().then(
                    lang.hitch(this, function (itemList) {
                        Object.keys(itemList).forEach(function (id) {
                            optionList.push({
                                value: itemList[id].key,
                                label: itemList[id].val
                            });
                        });
                        this.damageType.set('options', optionList);
                    })
                );
            },

            /**
             * widgetが居なくなる時に呼ばれる
             * this._eventsを削除する。
             * 定期処理を止める
             */
            destroy: function () {
                this._removeEvents();
                this.inherited(arguments);
                clearInterval(this.timer);
            },

            // TODO:要確認 新規登録ボタンについては、FUNC_CDとは別に「関係機関は表示しない」という暫定措置をとる
            initButtonStatus: function () {
                if (UserInfo.getUserType() === UserType.OTHER_ORGAN) {
                    domStyle.set(this.registerButton, 'display', 'none');
                }
            },

            /**
             * 一覧画面に初期表示する市町村のコードを取得する。
             *
             */
            initMunicipalitySelector: function () {
                if (Locator.getQuery().municipalityCd) {
                    // 概況画面から遷移した場合は、選択されていた市町村を設定する。
                    this._municipalityCd = Locator.getQuery().municipalityCd;
                } else if (UserInfo.getUserType() === UserType.MUNICIPALITY) {
                    // 市町ユーザの場合、自分の市町を設定
                    this._municipalityCd = UserInfo.getMunicipalityCd();
                } else if (UserInfo.getUserType() === UserType.OTHER_ORGAN) {
                    // 市町ユーザの場合、自分の市町を設定
                    this._municipalityCd = UserInfo.getMunicipalityCd();
                }

                if (this._municipalityCd === config.municInfo.cityMunicCd) {
                    this._municipalityCd = '';
                }
                console.log('Init Munic : ' + this._municipalityCd);
                //市町セレクタがある場合、初期表示を設定する。
                if (this.municipalityCd && this._municipalityCd) {
                    if(Array.isArray(Locator.getQuery().municipalityCd)){
                        this.municipalityCd.set('value', this._municipalityCd);
                    }else{
                        this.municipalityCd.value[0] = this._municipalityCd;
                    }
                }

                // 大阪市：区役所ユーザは区の変更が不可
                if (UserInfo.getApprovalType() !== "1") {
                    this.overviewLabel.style.display = 'none';
                }
            },

            /**
             * 一覧画面に初期表示する所属のコードを取得する。
             *
             */
            initOrganizationSelector: function () {
                if (Locator.getQuery().deptCd) {
                    // 選択されていた所属コードを設定する。
                    this._deptCd = Locator.getQuery().deptCd;
                }
                if (UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd) {
                    // 市町ユーザの場合、自分の市町を設定
                    this._deptCd = UserInfo.getDeptCd();
                }
                console.log('Init deptCd : ' + this._deptCd);
                //市町セレクタがある場合、初期表示を設定する。
                if (this._deptCd) {
                    this.reportOrganization.set('value', ['D' + this._deptCd]);
                }
            },

            /**
             * グリッドを初期化する。
             */
            initGrid: function () {
                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'list'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.damageReportAggregateGrid.on(
                    'listButtonClick',
                    lang.hitch(this, function (evt) {
                        this.onListButtonClick(evt);
                    })
                );

                this.damageReportAggregateGrid.on(
                    'dgrid-select',
                    lang.hitch(this, function (evt) {
                        this.onSelectRecord(evt.rows[0].data);
                    })
                );

                // 保管した検索条件をセットする
                this.setFilterData();

                this.updateGridQuery(this.form.get('value'));
            },

            /**
             * マップを初期化する。
             */
            initMap: function (latitude, longitude) {
                var latlng = [latitude, longitude];

                this.map = new IdisMap(this.mapNode, {
                    config: config.map,
                    keyboard: false, // コメント時に+/-が使用できないため
                    touchExtend: false,
                    minZoom: 9,
                    maxZoom: 18,
                    drawControlTooltips: false
                }).setView(latlng, 14);
                // destroy時にmapを破棄するよう設定
                this.own(this.map);

                // 生成したmapのlayerControlを画面にセットする。
                this._layerControl = this.map.layerControl;
            },

            /**
             * グリッドのレコードをクリックした時の処理。
             */
            onSelectRecord: function (arg) {
                // 選択した市町村に地図位置を合わせる
                var lat = arg.latitude;
                var lng = arg.longitude;

                if (lat && lng) {
                    this.map.setView([lat, lng], this.MAP_ZOOM);
                } else {
                    this.map.setView([this.INIT_LATLNG.lat, this.INIT_LATLNG.lng], this.MAP_ZOOM);
                }

                //this.selectedMuniName.innerHTML = arg.municipalityName || '(全道)';
                //this.damageNum.innerHTML = arg.damageNum;

                //Pane中身(被害種別ごとの集計)の初期化
                // for (var i = 1; i <= 13; i++) {
                //     var type = ('00' + i).slice(-2);
                //     dojo.byId('damageType' + type + 'Aggr').style.display = 'none';
                // }

                // var damageTypeAggr = {};
                // var damageWithLatLngNum = 0;
                // array.forEach(arg.damageReportList, function (damage) {
                //     // hldStatusはCHAR(2)で送られてきてしまうため、加工する
                //     var hldStatus = damage.hldStatus.slice(0, 1);
                //     if (!damageTypeAggr[damage.damageType]) {
                //         damageTypeAggr[damage.damageType] = {};
                //     }
                //     if (!damageTypeAggr[damage.damageType][hldStatus]) {
                //         damageTypeAggr[damage.damageType][hldStatus] = 0;
                //     }
                //     damageTypeAggr[damage.damageType][hldStatus] += 1;
                //     if (damage.latitude && damage.longitude) {
                //         damageWithLatLngNum += 1;
                //     }
                // });
                // //緯度経度の情報があり、地図上に表示される件数
                // this.damageWithLatLngNum.innerHTML = damageWithLatLngNum;

                // Object.keys(damageTypeAggr).forEach(function (type) {
                //     dojo.byId('damageType' + type + 'Aggr').style.display = '';
                //     var html = '';
                //     //var startFlg = false;
                //     if (damageTypeAggr[type]['0']) {
                //         html += '確認中 ' + damageTypeAggr[type]['0'] + '件 ';
                //     }
                //     if (damageTypeAggr[type]['1']) {
                //         html += '対応待ち ' + damageTypeAggr[type]['1'] + '件 ';
                //     }
                //     if (damageTypeAggr[type]['2']) {
                //         html += '対応中 ' + damageTypeAggr[type]['2'] + '件 ';
                //     }
                //     if (damageTypeAggr[type]['3']) {
                //         html += '対応済 ' + damageTypeAggr[type]['3'] + '件 ';
                //     }
                //     dojo.byId('damageType' + type + 'Content').innerHTML = html || '&nbsp';

                // }, damageTypeAggr);
            },

            /**
             * 地図上に概況を図示する
             */
            addCircle: function (arg) {
                //市町名がない場合は合計行なので、地図上の図示は行わない
                if (!arg.municipalityName) {
                    return;
                }

                //マーカーグループの作成
                this.markerGroup = new leaflet.markerClusterGroup({
                    iconCreateFunction: function (cluster) {
                        var markers = cluster.getAllChildMarkers();
                        var humanDamageFlg = false;
                        dojo.some(markers, function (marker) {
                            if (marker.humanDamageFlg === '0') {
                                humanDamageFlg = true;
                                return false;
                            }
                        });
                        var color = 'rgba( 85, 176, 250, 0.7 )';
                        if (humanDamageFlg) {
                            color = 'rgba( 240, 105, 60, 0.7 )';
                        }
                        var radius = Math.min(cluster.getChildCount() * 10, 4 * 10);
                        var html = '';
                        html += '<div class="leaflet-marker-icon marker-cluster ';
                        html += 'marker-cluster-small leaflet-zoom-animated leaflet-clickable"';
                        html += 'style="margin-left: ' + (50 - radius) + 'px; margin-top: ' + (50 - radius) + 'px;';
                        html += 'font-size: ' + radius + 'px;';
                        html += 'line-height: ' + radius * 2 + 'px;';
                        html += 'background-color: ' + color + ';';
                        html += 'width: ' + radius * 2 + 'px; height: ' + radius * 2 + 'px;';
                        html += 'border-radius:' + radius + 'px;';
                        html += '"><span>' + cluster.getChildCount() + '</span></div>';
                        // html += '<div style="width:100px; height:100px;border-radius:50px;"><span>';
                        // html += cluster.getChildCount() + '</span></div></div>';

                        return leaflet.divIcon({
                            html: html,
                            className: 'marker-cluster',
                            iconSize: new leaflet.Point(100, 100)
                        });
                    }
                });

                var damageReportList = arg.damageReportList;
                //被害単位のマーカー作成
                array.forEach(
                    damageReportList,
                    function (damageReport) {
                        var lat = damageReport.latitude;
                        var lng = damageReport.longitude;
                        var marker = [];
                        // すでに描画されていた場合は戻る。
                        if (this.drawnDamageIds.indexOf(damageReport.damageReportId) !== -1) {
                            return false;
                        }
                        if (lat && lng) {
                            // 概況画面では対応状況が2桁で取得されてしまうので、切り出し
                            damageReport.hldStatus = damageReport.hldStatus.slice(0, 1);
                            marker = this.makeDamageMarker(damageReport);
                            marker.humanDamageFlg = damageReport.humanDamageFlg;
                            this.markerGroup.addLayer(marker);
                        }
                    },
                    this
                );

                this.map.addLayer(this.markerGroup);
                //追加したマーカーを管理
                this.markerGroupList.push(this.markerGroup);
                this.drawnDamageIds.push(arg.damageReportId);
            },

            /**
             * 地図上のアイコンを削除する
             */
            removeCircles: function () {
                var map = this.map;
                array.forEach(this.markerGroupList, function (group) {
                    map.removeLayer(group);
                });
                this.markerGroupList.length = 0;
                this.drawnDamageIds.length = 0;
            },

            /**
             * 市町村コードを再セットする。
             */
            onMunicipalityChange: function () {
                console.debug('行政区が変更されました');
                this._municipalityCd = this.municipalityCd.get('value');
            },

            /**
             * 被害情報管理：集計表示に遷移する
             */
            onDamageReportPageBtnClick: function (evt) {
                //検索条件の保管
                this.setFilterStore();

                this._removeEvents();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('report');
            },

            /**
             * 検索
             */
            onSubmit: function () {
                try {
                    if (this.form.validate()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery(this.form.get('value'));
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             *検索パラメーターの設定
             */
            updateGridQuery: function (value) {
                //
                this.removeCircles();
                this.damageReportAggregateGrid.mainTypeIds = [];
                // 既存の被害レイヤーをすべて消す
                Object.keys(this._layerControl.layers).forEach(
                    lang.hitch(this, function (key) {
                        this._layerControl.removeLayerById(key);
                    })
                );
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();
                var value = this.form.get('value');

                // 災害ID
                filter = filter.eq('disasterId', this._disasterId);

                // 市町村コード
                // if (this.municipalityCd.value) {
                if (this._municipalityCd) {
                    filter = filter.eq('municipalityCd', this.municipalityCd.value.join(','));
                }

                // 報告者組織
                if (this.reportOrganization.value) {
                    filter = filter.eq('reportOrganization', this.reportOrganization.value.join(','));
                }

                // キーワード
                if (value.keyword) {
                    filter = filter.eq('keyword', value.keyword);
                }

                // 検索対象市町村に対して権限があるかどうか
                // var hasAuthForMunic = false;
                // if (UserInfo.getUserType() === UserType.MUNICIPALITY) {
                //     if (!this.municipalityCd.value) {
                //         // 市町村ユーザで、市町村が「全選択」であれば、問答無用で「権限なし」
                //         // FIXME: 本当は、自分の市町村の情報だけは全情報出さなければいけない
                //         hasAuthForMunic = false;
                //     } else if (UserInfo.getMunicipalityCd() === this.municipalityCd.value) {
                //         // 自分の市町村であれば、権限あり
                //         hasAuthForMunic = true;
                //     } else if (
                //         UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd &&
                //         this.municipalityCd.value.substr(0, 4) === config.municInfo.cityMunicCd.substr(0, 4)
                //     ) {
                //         // 政令指定都市ユーザの場合、市町村コードの最初４桁があっていれば、市内の区であるので、権限ありとする
                //         hasAuthForMunic = true;
                //     }
                // }
                if (value.reportStatus) {
                    filter = filter.eq('reportStatus', value.reportStatus);
                }

                if (UserInfo.getDamageViewType() === '4') {
                    filter = filter.eq('reportStatus', '41');
                }

                // 被害種別
                if (value.damageType) {
                    filter = filter.eq('damageType', value.damageType);
                }

                // 緊急度
                if (value.urgencyType) {
                    filter = filter.eq('urgencyType', value.urgencyType);
                }

                // 管理番号
                if (value.admNum) {
                    filter = filter.eq('admNum', value.admNum);
                }

                // 子被害表示
                if (value.childReport.length === 0) {
                    filter = filter.eq('hasNoParentFlg', '1');
                } else {
                    filter = filter.eq('hasNoParentFlg', '0');
                }

                // 報告日時FROM
                if (value.reportCrtDateTimeFrom) {
                    var reportCrtDateTimeFrom = new Date(value.reportCrtDateTimeFrom).getTime();
                    filter = filter.eq('reportCrtDateTimeFrom', reportCrtDateTimeFrom);
                }

                // 報告日時TO
                if (value.reportCrtDateTimeTo) {
                    var reportCrtDateTimeTo = new Date(value.reportCrtDateTimeTo).getTime();
                    filter = filter.eq('reportCrtDateTimeTo', reportCrtDateTimeTo);
                }

                // 更新日時FROM
                if (value.reportUpdDateTimeFrom) {
                    var reportUpdDateTimeFrom = new Date(value.reportUpdDateTimeFrom).getTime();
                    filter = filter.eq('reportUpdDateTimeFrom', reportUpdDateTimeFrom);
                }

                // 更新日時TO
                if (value.reportUpdDateTimeTo) {
                    var reportUpdDateTimeTo = new Date(value.reportUpdDateTimeTo).getTime();
                    filter = filter.eq('reportUpdDateTimeTo', reportUpdDateTimeTo);
                }

                // 過去報表示
                if (value.pastReport.length === 0) {
                    filter = filter.eq('activeFlg', '1');
                } else {
                    filter = filter.eq('activeFlg', '0');
                }

                // 子被害表示
                if (value.deletedReport.length !== 0) {
                    filter = filter.eq('deletedReportFlg', '1');
                }

                // 人的被害あり
                if (value.humanDamageFlg.length !== 0) {
                    filter = filter.eq('humanDamageFlg', '0');
                }

                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);
                this.gridList = new Memory({
                    idProperty: 'damageReportId'
                });

                collection.fetch().then(
                    lang.hitch(this, function (data) {
                        this.showDamageLayer(data.featureCollection);
                        console.log(data);

                        if (data.grid) {
                            for (let i = 0; i < data.grid.items.length; i++) {
                                let item = data.grid.items[i];

                                this.gridList.addSync(item);
                            }
                        }
                        // collectionをグリッドにセットする（サーバーにリクエストされる）
                        this.damageReportAggregateGrid.set('collection', this.gridList);
                    })
                );
            },

            onOpenFilterClick: function () {
                this.openingDetailFilter = true;
                domStyle.set(this.openFilterLink, 'display', 'none');
                domStyle.set(this.closeFilterLink, 'display', '');
                domStyle.set(this.reportTimeForm, 'display', '');
                domStyle.set(this.updTimeForm, 'display', '');
                domStyle.set(this.aboveSearchButtonDiv, 'display', 'none');
                this.borderContainer.resize();
            },

            onCloseFilterClick: function () {
                this.openingDetailFilter = false;
                domStyle.set(this.openFilterLink, 'display', '');
                domStyle.set(this.closeFilterLink, 'display', 'none');
                domStyle.set(this.reportTimeForm, 'display', 'none');
                domStyle.set(this.updTimeForm, 'display', 'none');
                domStyle.set(this.aboveSearchButtonDiv, 'display', '');
                this.borderContainer.resize();
            },

            /**
             * 検索条件を保管する
             */
            setFilterStore: function () {
                //初期化する
                damageFilterStore = {};
                var value = this.form.get('value');

                // 報告日時FROM
                if (value.reportCrtDateTimeFrom) {
                    damageFilterStore.reportCrtDateTimeFrom = value.reportCrtDateTimeFrom;
                }

                // 報告日時TO
                if (value.reportCrtDateTimeTo) {
                    damageFilterStore.reportCrtDateTimeTo = value.reportCrtDateTimeTo;
                }

                // 更新日時FROM
                if (value.reportUpdDateTimeFrom) {
                    damageFilterStore.reportUpdDateTimeFrom = value.reportUpdDateTimeFrom;
                }

                // 更新日時TO
                if (value.reportUpdDateTimeTo) {
                    damageFilterStore.reportUpdDateTimeTo = value.reportUpdDateTimeTo;
                }

                // 過去報表示
                if (value.pastReport.length > 0) {
                    damageFilterStore.pastReport = 'checked';
                }

                // 被害種別
                if (value.damageType) {
                    damageFilterStore.damageType = value.damageType;
                }

                // 対応状況
                if (value.hldStatus) {
                    damageFilterStore.hldStatus = value.hldStatus;
                }

                // 緊急度
                if (value.urgencyType) {
                    damageFilterStore.urgencyType = value.urgencyType;
                }

                // 市町村コード
                // if (value.municipalityCd) {
                //     damageFilterStore.municipalityCd = value.municipalityCd;
                // }

                // 対応課
                if (value.reportOrganization) {
                    damageFilterStore.reportOrganization = value.reportOrganization;
                }

                // キーワード
                if (value.keyword) {
                    damageFilterStore.keyword = value.keyword;
                }

                // 報告状況
                if (value.reportStatus) {
                    damageFilterStore.reportStatus = value.reportStatus;
                }

                // 管理番号
                if (value.admNum) {
                    damageFilterStore.admNum = value.admNum;
                }

                // 子被害表示
                if (value.childReport.length > 0) {
                    damageFilterStore.childReport = 'checked';
                }

                // 削除済み被害表示
                if (value.deletedReport.length > 0) {
                    damageFilterStore.deletedReport = 'checked';
                }

                // 人的被害あり
                if (value.humanDamageFlg.length > 0) {
                    damageFilterStore.humanDamageFlg = 'checked';
                }

                // 詳細検索項目を開いているかを保存する
                damageFilterStore.openingDetailFilter = this.openingDetailFilter;
            },

            /**
             * 保管した検索条件をフォームにセットする
             */
            setFilterData: function () {
                //保管されていれば値をセット

                // 報告日時FROM
                console.log(damageFilterStore);
                if (damageFilterStore.reportCrtDateTimeFrom) {
                    this.reportCrtDateTimeFrom.set('value', damageFilterStore.reportCrtDateTimeFrom);
                }

                // 報告日時TO
                if (damageFilterStore.reportCrtDateTimeTo) {
                    this.reportCrtDateTimeTo.set('value', damageFilterStore.reportCrtDateTimeTo);
                }

                // 更新日時FROM
                if (damageFilterStore.reportUpdDateTimeFrom) {
                    this.reportUpdDateTimeFrom.set('value', damageFilterStore.reportUpdDateTimeFrom);
                }

                // 更新日時TO
                if (damageFilterStore.reportUpdDateTimeTo) {
                    this.reportUpdDateTimeTo.set('value', damageFilterStore.reportUpdDateTimeTo);
                }

                // 過去報表示
                if (damageFilterStore.pastReport) {
                    this.pastReport.set('checked', 'checked');
                }

                // 被害種別
                if (damageFilterStore.damageType) {
                    this.damageType.set('value', damageFilterStore.damageType);
                }

                // 対応状況
                if (damageFilterStore.hldStatus) {
                    this.hldStatus.set('value', damageFilterStore.hldStatus);
                }

                // 緊急度
                if (damageFilterStore.urgencyType) {
                    this.urgencyType.set('value', damageFilterStore.urgencyType);
                }

                // 市町村コード
                if (damageFilterStore.municipalityCd) {
                    this.municipalityCd.set('value', damageFilterStore.municipalityCd);
                }

                // 対応課
                if (damageFilterStore.reportOrganization) {
                    this.reportOrganization.set('value', damageFilterStore.reportOrganization);
                }

                // 報告状況
                if (damageFilterStore.reportStatus) {
                    this.reportStatus.set('value', damageFilterStore.reportStatus);
                }

                // キーワード
                if (damageFilterStore.keyword) {
                    this.keyword.set('value', damageFilterStore.keyword);
                }

                // 管理番号
                if (damageFilterStore.admNum) {
                    this.admNum.set('value', damageFilterStore.admNum);
                }

                // 子被害表示
                if (damageFilterStore.childReport) {
                    this.childReport.set('checked', 'checked');
                }

                // 削除済み被害表示
                if (damageFilterStore.deletedReport) {
                    this.deletedReport.set('checked', 'checked');
                }

                // 人的被害あり
                if (damageFilterStore.humanDamageFlg) {
                    this.humanDamageFlg.set('checked', 'checked');
                }

                //詳細検索フォームを開いているかどうか
                if (damageFilterStore.openingDetailFilter) {
                    this.onOpenFilterClick();
                }
            },

            /**
             * 新規登録画面を表示
             */
            showRegisterPage: function () {
                //検索条件の保管
                this.setFilterStore();
                this._removeEvents();

                //新規画面に移動
                var municipalityCd = this.municipalityCd.value;

                if(municipalityCd.length === 1){
                    municipalityCd = municipalityCd.toString();
                    if (municipalityCd && 
                        municipalityCd.substring(0, 3) !== config.municInfo.prefRegCdGudge &&
                        municipalityCd !== config.municInfo.cityMunicCd) {
                        Router.moveTo('report/register', { municipalityCd: municipalityCd });
                    } else {
                        Router.moveTo('report/register');
                    }
                }else{
                    Router.moveTo('report/register');
                }
            },

            /**
             * 詳細画面を表示
             */
            onDetailButtonClick: function (object) {
                //検索条件の保管
                this.setFilterStore();

                this._removeEvents();
                // 被害状況・報告詳細画面へ遷移
                Router.moveTo('report/detail', {
                    damageReportId: object.damageReportId
                });
            },

            /**
             * 被害状況概況一覧画面へ遷移する。（フィルタの保存が必要なので独自に定義）
             */
            onDamageReportAdminPageLinkClick: function (evt) {
                //検索条件の保管
                this.setFilterStore();

                this._removeEvents();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('report/admin');
            },
            /**
             *一覧ボタン押下時
             */
            onListButtonClick: function (evt) {
                // 被害状況画面へ遷移
                this._removeEvents();
                this.setFilterStore();
                console.log(evt);
                // 被害状況・報告詳細画面へ遷移
                Router.moveTo('report/detail', {
                    damageReportId: evt.item.damageReportId
                });
            },

            /**
             * 被害統合ボタン押下時
             */
            onIntegrationClick: function () {
                if (this._integrationDialog === null) {
                    this._integrationDialog = new IntegrationDialog();
                }
                this._integrationDialog.setData(this.damageReportAggregateGrid.mainTypeIds);
                this._integrationDialog.show();
            },

            /**
             * 被害の種類のセレクタ一覧を生成
             */
            getDamageTypeList: function () {
                var deferred = new Deferred();
                if (this._damageTypeDispList.length > 0) {
                    return deferred.resolve(this._damageTypeDispList);
                }
                return Loader.wait(
                    Requester.get('api/code/list?type=DAMAGE_TYPE&display=true').then(
                        lang.hitch(this, function (data) {
                            data.forEach(
                                lang.hitch(this, function (item) {
                                    this._damageTypeDispList[item.sort] = { key: item.key, val: item.value };
                                })
                            );
                            return this._damageTypeDispList;
                        })
                    )
                );
            },
            /**
             * 帳票出力
             */
            showDamageForm: function () {
                this.damageFormDialog.show();
            },
            /**
             *  取得した被害情報から地図上に被害を表示する
             */
            showDamageLayer: function (itemList) {
                if (this._layerControl.layers[10010]) {
                    this._layerControl.removeLayerById(10010);
                }
                this._mapFeatureList = itemList;
                console.log(this._mapFeatureList);
                Requester.get('/data/master/style/D100_style.js', {
                    handleAs: 'text'
                }).then(
                    lang.hitch(this, function (data) {
                        var markers = leaflet.markerClusterGroup();
                        var style = eval('(' + data + ')');
                        console.log(style);
                        var layerGroup = leaflet.layerGroup();
                        markers.addLayer(leaflet.geoJson(this._mapFeatureList, style.geojsonOptions));
                        markers.addTo(this.map);
                        layerGroup.addLayer(markers);
                        this.map.fitBounds(markers.getBounds());
                        this._layerControl.addLayer(layerGroup, 10010);
                        this._layerControl.toFront(10010);
                    }),
                    function (e) {
                        console.log(e);
                    }
                );
            }
        }
    );
});
