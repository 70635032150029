/**
 * 長周期地震動に関する観測情報のメッセージ用コンテンツ
 * @module app/earthquake/view/LongPeriodGroundMotionDetailContent
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/text!./templates/LongPeriodGroundMotionDetailContent.html',
    'dojox/lang/functional/object',
    'idis/error/InvalidArgumentException',
    'idis/view/_IdisWidgetBase'
], function(module, declare, array, lang, locale, template,
    object, InvalidArgumentException, _IdisWidgetBase) {
    /**
     * 長周期地震動詳細情報のメッセージ用UIウィジェット
     * @class LongPeriodGroundMotionDetailContent
     * @extends module:idis/view/page/_PageBase~_PageBase
     * @param {Object} kwArgs
     * @param {string} [kwArgs.item={Object}] 長周期地震動情報
     */
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase,
        /** @lends module:app/earthquake/view/LongPeriodGroundMotionDetailContent~LongPeriodGroundMotionDetailContent# */ {
        // テンプレート文字列
        templateString: template,

        constructor: function(options) {
        	this.inherited(arguments);
        	lang.mixin(this, options);
        	// 引数チェック
            if (!this.item) {
                throw new InvalidArgumentException(module.id + '::constructor: Property "item" must be specified.');
            }
        },

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            // コンテンツの中身を生成する
            this.setContent();
        },

        /**
         * コンテンツの中身を生成する
         */
        setContent: function() {
        	this.reportDateTime.innerHTML = this.formatDateTime(this.item.reportDatetime);
        	this.text.innerHTML =
        		(!this.isEmpty(this.item.text)) ? this.item.text :  '';
        	this.forecastCommentText.innerHTML =
        		(!this.isEmpty(this.item.forecastCommentText)) ? this.item.forecastCommentText : '';

        	this.originTime.innerHTML = this.item.originTime ? this.formatDateTime(this.item.originTime) : '-';
        	this.depth.innerHTML = this.item.depth;
        	this.hypocenyterName.innerHTML = this.item.hypocenyterName;
        	this.magnitude.innerHTML = this.item.magnitude;
        	this.latitude.innerHTML = this.item.latitude;
        	this.longitude.innerHTML = this.item.longitude;

        	// 県市町と全国都道府県データを分割する
        	// 長周期地震動階級ごとに保持する。<長周期地震動階級 | [{Object}]>
        	var hItemMap = {};
        	var pItemMap = {};
        	array.forEach(this.item.regions, function(item){
        		// 大阪府の市町村コード'27'から始まり、且つ
                // placeCodeのサイズが2より大きい(=大阪府自身でない)場合に市町村として判定
        		if (/^52/.test(item.placeCode) && item.placeCode.length > 2) {
        			if (!hItemMap.hasOwnProperty(item.lgIntensity)) {
        				hItemMap[item.lgIntensity] = [];
        			}
        			hItemMap[item.lgIntensity].push(item);
				} else {
					if (!pItemMap.hasOwnProperty(item.lgIntensity)) {
        				pItemMap[item.lgIntensity] = [];
        			}
        			pItemMap[item.lgIntensity].push(item);
				}
        	});

        	// 県市町のテーブル内容を生成
            var hHtml = [];
            var hItemKeys = object.keys(hItemMap);
            if (hItemKeys.length === 0) {
                hHtml.push('<tr>');
                hHtml.push('<td colspan="6" class="earthquake-LongPeriodGroundMotionDetailContent--placeName">情報がありません。</td>');
                hHtml.push('</tr>');
            } else {
                hItemKeys.sort();
                hItemKeys.reverse();
                array.forEach(hItemKeys, function(key) {
                    // 各階級に対しテーブル一行が対応
                    hHtml.push('<tr><td colspan="2">');
                    hHtml.push(key);
                    hHtml.push('</td><td colspan="4" class="earthquake-LongPeriodGroundMotionDetailContent--placeName">');
                    var cities = [];
                    // 当該階級の大阪府内各自治体名を追加
                    array.forEach(hItemMap[key], function(value){
                        cities.push(value.placeName);
                    });
                    hHtml.push(cities.join('&nbsp;'));
                    hHtml.push('</td></tr>');
                });
            }
            this.lgIntensityNode1.innerHTML = hHtml.join('');

            // 都道府県のテーブル内容を生成
            var pHtml = [];
            var pItemKeys = object.keys(pItemMap);
            if (pItemKeys.length === 0) {
                pHtml.push('<tr>');
                pHtml.push('<td colspan="6" class="earthquake-LongPeriodGroundMotionDetailContent--placeName">情報がありません。</td>');
                pHtml.push('</tr>');
            } else {
                pItemKeys.sort();
                pItemKeys.reverse();
                array.forEach(pItemKeys, function(key) {
                    // 各階級に対しテーブル一行が対応
                    pHtml.push('<tr><td colspan="2">');
                    pHtml.push(key);
                    pHtml.push('</td><td colspan="4" class="earthquake-LongPeriodGroundMotionDetailContent--placeName">');
                    var cities = [];
                    // 当該階級の各都道府県を追加
                    array.forEach(pItemMap[key], function(value){
                        cities.push(value.placeName);
                    });
                    pHtml.push(cities.join('&nbsp;'));
                    pHtml.push('</td></tr>');
                });
            }
            this.lgIntensityNode2.innerHTML = pHtml.join('');
        },

        /**
         * 'yyyy-MM-dd HH:mm:ss' を表示形式に変換
         */
        formatDateTime: function(datetime) {
        	var reportDateTime = new Date(datetime.replace(/-/g, '/'));
  		  	var dateLabel = locale.format(reportDateTime, {
  		  		selector: 'date',
  		  		datePattern: 'yyyy年MM月dd日'
  		  	});
  		  	var timeLabel = locale.format(reportDateTime, {
  		  		selector: 'time',
  		  		timePattern: 'HH時mm分'
  		  	});
        	return dateLabel + '&nbsp;' + timeLabel;
        },

        isEmpty: function(str) {
        	if (str === void 0) {
        		return true;
        	}
        	if (str === null) {
        		return true;
        	}
        	return false;
        },

        /**
         * コンテンツのHTMLテキストを返す
         */
        getContent: function() {
        	// HTMLタグ間に改行コードが入り、セット先のダイアログモジュールで<br>に変換されるため外しておく
        	// テキスト内の改行はそのままにしたいため、「HTML終了タグ + 改行コード」にマッチする部分を
        	// 「HTML終了タグ」のみにする
        	// さらに、最初と最後の改行コードを外す
        	return this.contentNode.innerHTML.replace(/>\r?\n/g, '>').replace(/^\r?\n/g, '').replace(/\r?\n$/g, '');
        }

    });
});
