/**
 * （旧）職員招集・メール配信一覧画面用モジュール。
 *
 * @module app/convocation/view/history/ConvocationMailHistoryPage
 */
define(['module',
        'dojo/_base/declare',
        'dojo/_base/lang',
        'dojo/json',
        'dojo/Deferred',
        'dojo/dom-style',
        'dojo/text!./templates/ConvocationMailHistoryPage.html',
        'idis/store/IdisRest',
        'idis/view/Loader',
        'idis/control/Router',
        'idis/view/dialog/DialogChain',
        'idis/view/dialog/InfoDialog',
        'idis/view/page/_PageBase',
        // 以下、変数で受けないモジュール
        'dijit/Dialog',
        'dijit/form/CheckBox',
        'dijit/form/Form',
        'dijit/form/Select',
        'dijit/form/TextBox',
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'idis/view/form/AclButton',
        'idis/view/form/Button',
        'idis/view/form/DateTimeInput',
        'app/view/form/MunicRegionOrganizationSelector',
        'app/convocation/view/history/ConvoHistoryGrid',
        'app/convocation/view/history/ConvoHistoryTotalGrid',
        './ConvocationMailHistoryDetailDialog'],
    function(module, declare, lang, JSON, Deferred, domStyle, template, IdisRest, Loader, Router,
        DialogChain, InfoDialog, _PageBase) {
        /**
         * （旧）職員参集一覧画面
         *
         * @class ConvocationMailHistoryPage
         * @extends module:idis/view/page/_PageBase~_PageBase
         */
        return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/convocation/view/mail/ConvocationMailHistoryPage~ConvocationMailHistoryPage# */
        {
            // テンプレート文字列
            templateString: template,

            store: null,
            totalStore: null,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            /**
             * 変数の初期化。
             */
            constructor: function() {
                //一覧画面用データ格納オブジェクト
                this.store = new IdisRest({
                    idProperty: 'distributionHistOldId',
                    target: '/api/convoMails/history',
                    sortParam: 'sort',
                    rangeStartParam: 'offset',
                    rangeCountParam: 'count'
                });
                this.totalStore = new IdisRest({
                    idProperty: 'id',
                    target: '/api/convoMails/history/total'
                });
                // ダイアログの連鎖関係を取得
                this.chain = DialogChain.get(this);
            },

            /**
             * DOMノードを生成するためのメソッド
             */
            buildRendering: function() {
                this.inherited(arguments);
                this.initConvoMailHistGrid();
                this.initConvoMailTotalHistGrid();
                this.organizationSelector.restoreRefresh();
                this.own(this.detailDialog);
            },

            /**
             * 履歴一覧用グリッドの初期化。
             */
            initConvoMailHistGrid: function() {
                // 初期表示時の検索条件を設定する
                var filter = new this.store.Filter();

                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);

                // 履歴一覧をgridにセット
                this.ConvoHistoryGrid.set('collection', collection);
                 //グリッドの詳細ボタンクリック時の動作を設定する
                 //helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.ConvoHistoryGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                    // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                    this.showDetailDialog(evt.item);
                }));
            },

            /**
             * 合計一覧用グリッドの初期化。
             */
             initConvoMailTotalHistGrid: function() {
                // 初期表示時の検索条件を設定する
                var filter = new this.totalStore.Filter();

                // filterに対応するcollectionを取得
                var collection = this.totalStore.filter(filter);

                // 一覧をgridにセット
                this.ConvoHistoryTotalGrid.set('collection', collection);
            },

            /**
             * 検索ボタンが押下された際の挙動。
             */
            onSubmit: function() {
                this.updateGridQuery();
                this.updateTotalGridQuery();
                return false;
            },

            /**
             * 画面から入力された検索条件に合致する履歴一覧を表示する。
             */
            updateGridQuery: function() {
                var value = this.form.value;
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();
                // 拠点
                if (value.organizationSelector) {
                    var organization = value.organizationSelector.slice(1);
                    filter = filter.eq('organization', organization);
                }
                // 動員区分
                if (value.issueReasonType) {
                    filter = filter.eq('issueReasonType', value.issueReasonType);
                }
                // 集計日時
                if (value.totallingDateTime) {
                    var totallingDateTime = new Date(value.totallingDateTime).getTime();
                    filter = filter.eq('mailHistTime', totallingDateTime);
                }
                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);
                // 取得したcollectionをgridに設定
                this.ConvoHistoryGrid.set('collection', collection);
            },

            /**
             * 画面から入力された検索条件に合致する履歴（合計）を表示する。
             */
            updateTotalGridQuery: function() {
                var value = this.form.value;
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.totalStore.Filter();
                // 拠点
                if (value.organizationSelector) {
                    var organization = value.organizationSelector.slice(1);
                    filter = filter.eq('organization', organization);
                }
                // 動員区分
                if (value.issueReasonType) {
                    filter = filter.eq('issueReasonType', value.issueReasonType);
                }
                // 集計日時
                if (value.totallingDateTime) {
                    var totallingDateTime = new Date(value.totallingDateTime).getTime();
                    filter = filter.eq('mailHistTime', totallingDateTime);
                }
                // filterに対応するcollectionを取得
                var collection = this.totalStore.filter(filter);
                // 取得したcollectionをgridに設定
                this.ConvoHistoryTotalGrid.set('collection', collection);
            },

            /**
             * @param item 招集状況一覧グリッドの行データ
             * 招集状況管理の詳細ボタンが押下された際の挙動。
             */
             showDetailDialog: function(item) {
                console.log('[詳細]ボタンがクリックされました。');
                // ダイアログの最初の子要素が詳細画面
                var page = this.detailDialog.getChildren()[0];
                // 画面上のフォームをリセット
                // ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み
                page.form.reset();
                page.initDialog(item);
                this.detailDialog.show();
            },

            /**
             * ウィンドウの幅に合わせてBorderContainerをリサイズし、タブ内のレイアウトを整理する。
             * (startup同様、画面描画時に呼ばれる)
             * @param changeSize
             * @param resultSize
             */
            resize: function(changeSize, resultSize) {
                // 中のContentPaneも追随してリサイズするため、レイアウト崩れを防止できる。
                this.borderContainer.resize(changeSize, resultSize);
            },
            /**
             * ウィジェットの値をリセットする。
             */
            reset: function() {
                this.groupId.set('value', []);
            }
        });
    });
