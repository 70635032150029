/**
 * 水道局災害名表示、選択ダイアログ用モジュール。
 * @module app/waterworks/selectors/IncidentSelector
 */
define([
  'module',
  'dojo/_base/declare',
  'dojo/_base/lang',
  'dojo/text!./templates/IncidentSelector.html',
  'idis/store/IdisRest',
  'idis/view/_IdisWidgetBase',
  // 以下、変数として受け取らないモジュール
  'dijit/Dialog',
  'app/waterworks/selectors/IncidentSelectorDialog'
], function(module, declare, lang, template, IdisRest, WidgetBase) {

  /**
   * 水道局災害名表示、選択ダイアログ表示用ウィジェット
   * @class IncidentSelector
   * @extends module:idis/view/page/_PageBase~_PageBase
   */
  return declare(module.id.replace(/\//g, '.'), WidgetBase,
      /** @lends app/waterworks/selectors/IncidentSelector~IncidentSelector# */ {

      // テンプレート文字列
      templateString: template,

      // ルートに付与されるCSS
      baseClass: 'incident-selector',

      // OKボタンが押された際のイベントが追加済みであるか
      _eventFlg: false,

      /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
      store: null,

      /**
       * このプロパティーが定義されているとき、
       * {@link module:dijit/form/Form~Form}のget('value')による取得対象となる。
       * @type {string}
       */
      value: '',

      /**
       * コンストラクタ
       */
      constructor: function() {
        // データ格納用オブジェクト
        this.store = new IdisRest({
            idProperty:'incidentId',
            target: '/api/waterworks/incident'
          });
      },

      /**
       * DOM生成
       */
      buildRendering: function() {
        this.inherited(arguments);
      },

      /**
       * 画面生成完了
       */
      startup: function() {
        this.inherited(arguments);
        // このウィジェット消滅時にダイアログも削除
        this.own(this.dialog);
      },

      /**
       * 水道局災害名情報を取得し、画面へセットする
       */
      getDisasterInfo: function(id, relatedFlg) {
        
        // 紐付け状態を確認
        if(relatedFlg === '0'){
          this.clear();
          return;
        }

        // 水道局災害名情報を取得
        this.store.get(id).then(lang.hitch(this, function(item) {
          console.log(item);
          this.select({
            incidentId: item.incidentId,
            relatedFlg: '1',
            incidentName: item.incidentName,
            updTimestamp: item.updTimestamp
          });
        }));
      },

      /**
       * 水道局災害名選択ダイアログを表示する
       */
      showDialog: function() {
        // ダイアログを表示
        this.dialog.show();
        // ダイアログ中のページウィジェットを取得
        var page = this.dialog.getChildren()[0];

        // イベントが追加済みの場合は何もしない
        if (this._eventFlg) {
          return;
        }

        // ダイアログ側でOKボタンが押された際のイベント
        page.on('update', lang.hitch(this, function(evt) {

          // イベント追加済み
          this._eventFlg = true;

          // 水道局災害名選択
          this.select(evt.data);

          // ダイアログを非表示にする
          this.dialog.hide();
        }));
        // ダイアログの画面を再表示する
        page.refresh();

      },

      /**
       * 水道局災害名を選択する
       */
      select: function(data) {
        console.log(data);
          if(data.incidentId !== 0) {
              // 選択された水道局災害名を画面へセット
              this.incidentNameDisplay.innerHTML = data.incidentId + '_' + data.incidentName;
              // 選択された水道局災害IDをセット
              this.incidentId.set('value', data.incidentId);
              this.incidentName.set('value', data.incidentName);
              this.value = { incidentId: data.incidentId,
                incidentName: data.incidentName,
                relatedFlg: '1',
                updTimestamp: data.updTimestamp};
          } else {
              this.incidentNameDisplay.innerHTML = this.emptyValueLabel;
              this.incidentId.set('value', 0);
              this.incidentName.set('value', '');
              this.value = { incidentId: 0,
                incidentName: this.emptyValueLabel,
                relatedFlg: '0',
                updTimestamp: 0};
          }
      },

      /**
       * ダイアログに設定された水道局災害名を初期化する
       */
      clear: function() {
        this.incidentNameDisplay.innerHTML = this.emptyValueLabel;
        this.incidentId.set('value', 0);
        this.incidentName.set('value', '');
        this.value = { incidentId: 0,
          incidentName: this.emptyValueLabel,
          relatedFlg: '0',
          updTimestamp: 0,};
      }
  });

});
