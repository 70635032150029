/**
 * 防災体制・報告の新規登録・詳細ダイアログ用の基底モジュール。
 * @module app/disasterprevention/view/_DisasterPreventionDialogPageBase
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-style',
    'dojo/on',
    'dojo/request/iframe',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'exif-js/exif',
    'idis/service/Requester',
    'idis/model/UserInfo',
    'app/model/DisasterInfo',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    './model/DisasterPreventStatus',
    './model/DisasterType',
    './model/HqSetFlg',
    'app/config',
    './model/HqType',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/SimpleTextarea',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/RadioGroup',
    'app/view/form/OrganizationSelector',
    'app/view/form/CustomizableMunicipalitySelector',
    'app/view/form/MunicipalitySelector',
    'app/disasterprevention/view/DisasterPreventionConfirmDialog'
], function(module, declare, lang, domClass, domConstruct, domStyle, on, iframe,
    Menu, MenuItem, popup, TooltipDialog, exif, Requester, UserInfo, DisasterInfo,
    _PageBase, InfoDialog, Loader, PrevStatus, DisasterType, HqSetFlg, config) {
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    {
        /**
         * 防災体制ID
         */
        _disasterPreventionId: null,

        /**
         * 災害ID
         */
        _disasterId: null,

        /**
         * 災害種別
         */
        _disasterType: null,

        /**
         * 添付ファイル一覧格納用オブジェクト
         */
        _attachFileList: null,

        /**
         * 呼び出し元
         */
        _parent: null,

        /**
         * 配信対象フラグ
         */
        _provideFlg: false,

        /**
         * 大阪市解除体制コード
         */
        _osakaRelease:'009999',

        /**
         * 大阪市以外解除体制コード
         */
        _release:'019999',

        /**
         * constructor
         */
        constructor: function() {
            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }

            //災害情報を取得する
            Requester.get('/api/disasters/' + this._disasterId)
            .then(lang.hitch(this, function(res) {
                this._disasterType = res.disasterType;
            }));
        },

        /**
         * フォームをリセットする。
         */
        reset: function() {
            this.form.reset();
        },

        /**
         * 入力値の妥当性を検証する。
         */
        validate: function() {
            var infoDialogMsg = null;
            if(!this.reportTimestamp.validate() || !this.reportTimestamp._date.displayedValue ||
                    !this.reportTimestamp._time.displayedValue) {
                infoDialogMsg = '「報告日時」';
                console.debug('報告日時が入力されていません');
            }
            if(this.municipalitySelector.get('value') === '' ||
                this.municipalitySelector.get('value') === config.municInfo.prefMunicCd) {
                infoDialogMsg = '「組織」';
                console.debug('組織が選択されていません');
            }
            // 本部設置の場合
            if(domStyle.get(this.hqInfNode, 'display')!=='none' &&
                    (!this.hqSetTimestamp.validate() ||
                    !this.hqSetTimestamp._date.displayedValue ||
                    !this.hqSetTimestamp._time.displayedValue)) {
                infoDialogMsg = infoDialogMsg===null ? '' : infoDialogMsg + '及び';
                infoDialogMsg += '「設置日時」';
                console.debug('設置日時が入力されていません');
            }
            if (this.status.get('value') === '') {
                infoDialogMsg = '「体制状況」';
                console.debug('体制状況が選択されていません');
            }
            // 体制が解除の場合は廃止日時が必要
            if((this.status.get('value') === this._osakaRelease || 
                this.status.get('value') === this._release) && 
                (!this.hqAbolishedTimestamp.validate() ||
                !this.hqAbolishedTimestamp._date.displayedValue ||
                !this.hqAbolishedTimestamp._time.displayedValue) &&
                (this._lastDetailItem.hqSetFlg !== '0')) {
                infoDialogMsg = infoDialogMsg===null ? '' : infoDialogMsg + '及び';
                infoDialogMsg += '「廃止日時」';
            }
            if(infoDialogMsg!==null){
                infoDialogMsg += 'が入力されていません';
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : infoDialogMsg
                });
                this.infoDialog.show();
                return false;
            } else if(infoDialogMsg===null && (this.status.get('value') === this._osakaRelease || 
                this.status.get('value') === this._release) &&
                this.hqSetFlg.get('value') !== HqSetFlg.RELEASE && 
                this._lastDetailItem.hqSetFlg !== '0') {
                infoDialogMsg = '体制状況が「解除」の場合は、対策本部設置状況は「廃止」を選択してください。';
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : infoDialogMsg
                });
                this.infoDialog.show();
                return false;
            } else if(!this.form.isValid()) {
                console.debug('無効な値が入力されています');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '無効な値が入力されています'
                    });
                }
                this.infoDialog.show();
                return false;
            }

            return true;
        },

        /**
         * 添付ファイルをアップロードする。
         */
        loadAttachFile: function() {
            //ファイルが空の場合は処理を中断
            if(this.attachFile._files.length === 0) {
                return;
            }

            var self = this;

            console.log('file change');

            var url = '/api/disasterPrevention/attachments';
            // ファイルをサーバーにPOSTする
            var promise = iframe.post(url, {
                form: this.form.id,
                handleAs: 'json'
            });
            // ローダーの表示
            Loader.wait(promise).then(lang.hitch(this, function(data) {
                console.log(data);
                // 添付ファイルの配列に追加
                self._attachFileList.push(data);
                // プレビューを表示する
                self.showPreview(data);
                // ファイルアップローダー部品をリセットする
                self.attachFile.reset();
            }), function(error) {
                console.log(error);
                self.attachFile.reset();
                self._parent.chain.infoError(error);
            });
        },

        /**
         * 添付ファイルのプレビューを表示する。
         */
        showPreview: function(data, exifFlg) {

            var dataUri = data.attachFilePath.replace('out/', 'data/');
            var fileName = data.attachFileName;
            var fileId = data.disasterPrevAtcFileId;
            var self = this;

            // 画像ファイルの場合
            if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
            fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
            fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
            fileName.indexOf('.gif') !== -1) {
                var image = new Image();

                //JPEGファイルの場合、EXIFデータの取得を実行する
                if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
                fileName.indexOf('.JPG') !== -1 ||fileName.indexOf('.JPEG') !== -1) {
                    var img = null;
                    this.own(on(image, 'load', lang.hitch(this, function(e) {
                        console.log(e);
                        img = e.target;

                        if(exifFlg) {
                            this.getExifData(img, this);
                        }
                    })));
                }
                image.src = dataUri;
                domClass.add(image, 'is-showPreview');
                domConstruct.place(image, this.preview);
                //メニューの作成
                this.createMenu(image, dataUri, fileName, fileId, self);

            } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                var excel = new Image();
                excel.src = 'images/excelicon.png';
                domClass.add(excel, 'is-showPreview');
                domConstruct.place(excel, this.preview);
                //メニューの作成
                this.createMenu(excel, dataUri, fileName, fileId, self);
            } else if (fileName.indexOf('.pdf') !== -1) {
                var pdf = new Image();
                pdf.src = 'images/pdficon.png';
                domClass.add(pdf, 'is-showPreview');
                domConstruct.place(pdf, this.preview);
                //メニューの作成
                this.createMenu(pdf, dataUri, fileName, fileId, self);
            } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                var word = new Image();
                word.src = 'images/wordicon.png';
                domClass.add(word, 'is-showPreview');
                domConstruct.place(word, this.preview);
                //メニューの作成
                this.createMenu(word, dataUri, fileName, fileId, self);
            } else {
                var other = new Image();
                other.src = 'images/othericon.png';
                domClass.add(other, 'is-showPreview');
                domConstruct.place(other, this.preview);
                //メニューの作成
                this.createMenu(other, dataUri, fileName, fileId, self);
            }
        },

        /**
         * JPEGファイルの位置情報を取得する
         */
        getExifData: function(img, self) {
            console.log('getting exif data start');
            exif.getData(img, function(){

                var latitude = exif.getTag(this, 'GPSLatitude');
                var longitude = exif.getTag(this, 'GPSLongitude');

                if(typeof latitude === 'undefined' || typeof longitude === 'undefined'){
                    console.log('GPS data is unavailable.');
                }else{
                    console.log('GPS data is available.');
                    var f = function(number){
                        return number[0].numerator + number[1].numerator /
                        (60 * number[1].denominator) + number[2].numerator / (3600 * number[2].denominator);
                    };
                    self._parent.chain.confirm('この画像の位置情報を使用しますか？', function(chain) {
                        // 位置情報を設定する
                        self.pointLat = f(latitude);
                        self.pointLng = f(longitude);
                        // 地図にマークして中心に移動する
                        self.addMark(self.pointLat, self.pointLng, self);
                        self.map.setView([self.pointLat, self.pointLng], 11);
                        //ダイアログを閉じる
                        chain.hide();
                    });
                }
            });
        },

        /**
         * 添付ファイルのサムネイル上にメニューを作る
         */
        createMenu: function(newNode, uri, fileName, id, self) {
            var menu = new Menu({
                targetNodeId: newNode
            });
            menu.set('style', {'border': 'none', 'box-shadow': 'none'});

            //ダウンロード操作用
            var download = null;
            var userAgent = window.navigator.userAgent.toLowerCase();
            if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                download = domConstruct.create('a', {href: '#'});
                //クリックでファイル取得処理に入る
                download.onclick = function() {
                    self.downloadFile(url, fileName);
                };
            }else{
                // FF, Chromeの場合、download属性でファイルダウンロード
                download = domConstruct.create('a', {
                    href: uri,
                    download: fileName
                });
            }

            // ファイル名とメニューとの境界線をセット
            var contentNode = domConstruct.create('div');
            contentNode.innerHTML = fileName;
            domConstruct.place('<hr color=#b0c4de>', contentNode);
            //メニューをセット
            domConstruct.place(menu.domNode, contentNode);
            var tooltip = new TooltipDialog({
                content: contentNode
            });
            //
            tooltip.containerNode.onmouseleave = function() {
                popup.close(tooltip);
            };

            // 画像ファイルの場合のみ'開く'をメニューに追加する
            if(fileName.indexOf('.jpg') !== -1 ||fileName.indexOf('.jpeg') !== -1 ||
            fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
            fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
            fileName.indexOf('.gif') !== -1) {
                menu.addChild(new MenuItem({
                    label: '開く',
                    iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
                    onClick: function() {
                        console.log('file open');
                        window.open(uri);
                    }
                }));
            }

            menu.addChild(new MenuItem({
                label: 'ダウンロード',
                iconClass: 'dijitIconSave',
                onClick: function(e) {
                    console.log('file download');
                    console.log(e);
                    //IE対策
                    if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                        download.onclick();
                    } else {
                        download.click();
                    }
                }
            }));

            menu.addChild(new MenuItem({
                label: '削除',
                iconClass: 'dijitIconDelete',
                onClick: function() {
                    console.log('file delete');

                    // 該当ファイルを削除
                    for(var i=0; i<self._attachFileList.length; i++) {
                        if(self._attachFileList[i].disasterPrevAtcFileId === id) {
                            self._attachFileList.splice(i,1); //id:3の要素を削除
                        }
                    }

                    // サムネイルとメニューを削除
                    domConstruct.destroy(newNode);
                    popup.close(tooltip);
                }
            }));

            menu.startup();
            //メニュー表示処理
            this.own(on(newNode, 'mouseover', lang.hitch(this, function() {
                popup.open({
                    popup: tooltip,
                    around: newNode,
                    orient: ['below-centered']
                });
            })));
            //画面破棄時に一緒に破棄する
            this.own(tooltip);
        },

        /**
         * 添付ファイルをダウンロードする。
         */
        downloadFile: function(url, name) {
            // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
            // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function() {

                var arrayBuffer = this.response;

                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});

                // IE10+
                if(window.navigator.msSaveOrOpenBlob){
                    window.navigator.msSaveOrOpenBlob(blob, name);
                }

            };
            xhr.send();

            return false;

        },

        /**
         * 本部設置情報＆配信情報の設定
         * @param {*} hqSetFlg 本部設置状況 0:未設置 1:設置 2:解除
         */
        _setDomStyle: function(hqSetFlg){
            switch (hqSetFlg) {
                case HqSetFlg.ESTABLISH:
                    // 本部設置の場合
                    domStyle.set(this.hqInfNode, 'display', '');                    // 本部設置情報
                    domClass.add(this.hqSetTimestampArea, 'is-required');           // 本部設置日時
                    // 新規登録ダイアログは廃止日時なし
                    if(this.hqAbolishedTimestampArea){
                        // domClass.remove(this.hqAbolishedTimestampArea, 'is-required');  // 本部廃止日時
                        domClass.add(this.hqAbolishedTimestampArea, 'is-required'); 
                    }
                    var statusCd = this.status.get('value');
                    // 設置する本部名をセット
                    // this.hqName.value = PrevStatus.get(statusCd).hqName;
                    this.hqName.value = PrevStatus.get(statusCd).label;
                    this.hqType.value = PrevStatus.get(statusCd).hqType;
                    this._provideFlg = PrevStatus.get(statusCd).provideFlg==='1' ? true : false;
                    // 詳細画面
                    if (this._lastDetailItem) {
                        // // 更新前後の本部種別が一致するなら、配信しない
                        // var hqType = PrevStatus.get(statusCd).hqType;
                        // if(this._lastDetailItem.hqType===hqType){
                        //     this._provideFlg = false;
                        // }
                        // 大阪市仕様：本部種別に関わらず、配信する
                        if(this._lastDetailItem.hqSetFlg===HqSetFlg.ESTABLISH ){// &&
                            // this._lastDetailItem.hqType===HqType.RESPONSE_HQ) {
                                this._provideFlg = true;
                        }
                    }
                    break;
                case HqSetFlg.RELEASE:
                    // 本部解除の場合
                    statusCd = this.status.get('value');
                    domStyle.set(this.hqInfNode, 'display', '');                    // 本部設置情報
                    domClass.add(this.hqSetTimestampArea, 'is-required');           // 本部設置日時
                    domClass.add(this.hqAbolishedTimestampArea, 'is-required');  // 本部廃止日時
                    this._provideFlg = PrevStatus.get(statusCd).provideFlg==='1' ? true : false;
                    // 更新前が本部設置なら、配信する
                    if (this._lastDetailItem) {
                        // 大阪市仕様：本部種別に関わらず、配信する
                        if(this._lastDetailItem.hqSetFlg===HqSetFlg.ESTABLISH ){// &&
                            // this._lastDetailItem.hqType===HqType.RESPONSE_HQ) {
                            this._provideFlg = true;
                        }
                    }
                    // 更新前が本部解除なら、配信しない
                    if(this._lastDetailItem.hqSetFlg===HqSetFlg.RELEASE){
                        this._provideFlg = false;
                    }
                    break;
                case HqSetFlg.NOT_YET:
                    // 本部未設置の場合
                    domStyle.set(this.hqInfNode, 'display', 'none');                    // 本部設置情報
                    // 配信しない
                    this._provideFlg = false;
                    // 更新前が本部設置なら、配信する
                    if (this._lastDetailItem) {
                        if(this._lastDetailItem.hqSetFlg !== null) {
                            if(this._lastDetailItem.hqSetFlg===HqSetFlg.ESTABLISH){
                                this._provideFlg = 
                                    PrevStatus.get(this._lastDetailItem.status).provideFlg==='1' ? true : false;
                            }
                        }
                    }
                    this.hqSetTimestamp.set('value', null);
                    break;
            }
            
            // 大阪市の体制でない場合、配信しない（大阪市のみ配信）
            // if(this.municipalitySelector.get('value') !== config.municInfo.cityMunicCd) {
            if(!(this.municipalitySelector.get('value') === config.municInfo.cityMunicCd && !this.dpDeptCd)){
                this._provideFlg = false;
            }
            // 過去報の場合、配信しない
            if (this._lastDetailItem && this._lastDetailItem.activeFlg!==1) {
                this._provideFlg = false;
            }

        },
        /**
         * 体制登録対象の区市町村・組織の情報を取得
         */
         getDpInfo: function(dpMunicipalityCd,req){
            if(!this.municipalitySelector.tree){
                return '';
            } else if (!this.municipalitySelector.tree.model.items){
                return '';
            } else {
                var dpItems = this.municipalitySelector.tree.model.items;
                var res = '';
                if(dpItems){
                    dpItems.some(function(dpItem){
                        // 選択された自治体
                        if(dpItem && (dpItem.id === dpMunicipalityCd)){
                            if(req === 'type'){
                                // 選択された自治体のtypeに設定された組織カテゴリコードを取得
                                res = dpItem.type;
                            } else if (req === 'parentId'){
                                // 選択された自治体のparentIdに設定された区市町村コードを取得
                                res = dpItem.parentId;
                            }
                        }
                    }, this);
                }
                return res;
            }
        },
        /**
         * 体制セット
         */
        setStatus: function(){
            // 新規登録時は解除を表示しない
            var postFlg = this._lastDetailItem ? false:true;
            // 自治体コード
            //var areaSelVal = this.municipalitySelector.get('value')!==config.municInfo.prefMunicCd?
            //    this.municipalitySelector.get('value') : config.municInfo.prefCd;

            // 組織カテゴリコード
            this.dpDeptCd = this.getDpInfo(this.municipalitySelector.get('value'),'type');
            // 津波は地震と同じ体制
            var disasterType = this._disasterType===DisasterType.TSUNAMI_DISASTER ?
                    DisasterType.EARTHQUAKE_DISASTER : this._disasterType;
            // var noItem = true;
            // 大阪市判別フラグ
            var areaFlg = this.municipalitySelector.get('value') === undefined ? 
            UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd :
            this.municipalitySelector.get('value') === config.municInfo.cityMunicCd;

            this.status.set('query', function(item) {
                // 大阪市の場合は固有の体制コード
                if(areaFlg) {
                    // 指定の災害種別の体制をセット
                    if((item.areaCd !== null && item.areaCd !== undefined) &&
                        item.areaCd === config.municInfo.cityMunicCd && 
                        disasterType!==null && disasterType===item.disasterType){
                        // noItem = false;
                        return item;
                    // 詳細ダイアログの場合解除も追加
                    } else if ((item.areaCd !== null && item.areaCd !== undefined) &&
                        item.areaCd === config.municInfo.cityMunicCd && item.label==='解除'&&!postFlg) {
                        return item;
                    }
                 // 大阪市以外の場合
                 } else {
                    // 指定の災害種別の体制をセット
                    if((item.areaCd === null || item.areaCd === undefined) && 
                        disasterType!==null && disasterType===item.disasterType){
                        // noItem = false;
                        return item;
                    // 詳細ダイアログの場合解除も追加
                    } else if ((item.areaCd === null || item.areaCd === undefined) && item.label==='解除'&&!postFlg) {
                        return item;
                    }
                 }
            });
        },

        onChangeStatus: function (evt) {
            var disabledFlg = false;
            if(PrevStatus.get(evt).disabledFlg === '1') {
                disabledFlg = true;
            }
            this.hqSetFlg.set('disabled', disabledFlg);
            if(disabledFlg) {
                this.hqSetFlg.set('value', 0);
                this.hqSetTimestamp.set('value', null);
                domStyle.set(this.hqInfNode, 'display', 'none');
            }
            this._setDomStyle(this.hqSetFlg.get('value'));
        },
        /**
         * 所属の値が変更された時の処理
         */
        onChangeMunicipality: function () {
            // 大阪市の体制の場合
            if(this.municipalitySelector.get('value') === config.municInfo.cityMunicCd){
                // 組織カテゴリコードが未設定となるため、明示的にnullを設定
                this.dpDeptCd = null;
                // 参集人数を活性化
                this.empGather.set('readOnly', false);  // 参集職員（所属）
                this.empWork.set('readOnly', false);  // 勤務職員（所属）
                this.empOther.set('readOnly', false);  // 参集職員（他所属）
                // 背景色クリア
                domStyle.set(this.empGather.domNode, 'background-color', '');
                domStyle.set(this.empWork.domNode, 'background-color', '');
                domStyle.set(this.empOther.domNode, 'background-color', '');
                // イベント有効化
                domStyle.set(this.empGather.domNode, 'pointer-events', '');
                domStyle.set(this.empWork.domNode, 'pointer-events', '');
                domStyle.set(this.empOther.domNode, 'pointer-events', '');
                // 集計ボタンを表示
                domStyle.set(this.sumButton.domNode, 'display', '');
                
                // 過去報の場合は集計ボタンを非表示
                if(this._lastDetailItem && this._lastDetailItem.activeFlg!==1){
                    domStyle.set(this.sumButton.domNode, 'display', 'none');
                }

            } else {
                // 非活性化
                this.empGather.set('readOnly', true);  // 参集職員（所属）
                this.empWork.set('readOnly', true);  // 勤務職員（所属）
                this.empOther.set('readOnly', true);  // 参集職員（他所属）

                // グレーアウト
                domStyle.set(this.empGather.domNode, 'background-color', '#e9e9e9');
                domStyle.set(this.empWork.domNode, 'background-color', '#e9e9e9');
                domStyle.set(this.empOther.domNode, 'background-color', '#e9e9e9');
                // イベント無効化
                domStyle.set(this.empGather.domNode, 'pointer-events', 'none');
                domStyle.set(this.empWork.domNode, 'pointer-events', 'none');
                domStyle.set(this.empOther.domNode, 'pointer-events', 'none');

                // 集計ボタンを非表示
                domStyle.set(this.sumButton.domNode, 'display', 'none');

                // 新規登録画面（登録なしフラグがtrue）の場合、参集人数をクリア
                if(this._parent && this._parent._noRecordFlg){
                    this.empGather.set('value', null);
                    this.empWork.set('value', null);
                    this.empOther.set('value', null);
                }
            }
        },
        /**
         * 大阪市の参集人員全体を集計する
         */
        onClickSumButton: function(){
            // var infoDialogMsg = null;
            var value = this.form.get('value');
            if (!value.reportTimestamp){
                var infoDialogMsg = '「報告日時」が入力されていません。<br>設置済の最新体制の参集人数を集計できません。';
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : infoDialogMsg
                });
                this.infoDialog.show();
                return false;
            }
            // 報告日時が未設定の場合は、現在を設定
            // if(!value.reportTimestamp){
            //     value.reportTimestamp = new Date();
            // }
            var reportTimestamp = new Date(value.reportTimestamp).getTime();
            var promise = Requester.get('/api/disasterPrevention/sum?disasterId=' + 
                this._disasterId + '&reportTimestamp=' + reportTimestamp, {
                preventCache: false
            }).then(lang.hitch(this,function(data) {
                // 参集職員（所属）
                this.empGather.set('value', data.empGather); 
                // 勤務職員（所属）
                this.empWork.set('value', data.empWork); 
                // 参集職員（他所属）
                this.empOther.set('value', data.empOther); 
                // 合計人数
                this.sum.innerHTML = (data.sum === null) ? '-' : data.sum;
            }), lang.hitch(this, function(err){
                console.error(err);
            }));
            Loader.wait(promise);
        }
//        /**
//         * プレビューをクリアする
//         **/
//        clearPreview: function() {
//
//            var length = this.preview.childNodes.length;
//            for(var i = 0; i < length; i++) {
//                domConstruct.destroy(this.preview.childNodes[0]);
//            }
//
//            if(this._attachFileList) {
//                this._attachFileList.splice(0, this._attachFileList.length);
//            }
//        }
    });
});
