/**
 * 被害自動公開設定ダイアログ用モジュール。
 * @module app/damage/damageAutoPubSetting/DamageAutoPubSettingDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'idis/control/Router',
    'dojo/text!./templates/DamageAutoPubSettingDialog.html', // テンプレート文字列
    'idis/store/IdisRest',
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/Loader',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'app/model/Damage',
    // 以下、変数で受けないモジュール
    'app/damage/DamageReportPage',
    'dijit/Dialog',
    'idis/view/form/Button',
], function(module, declare, lang, Router, template, IdisRest, _PageBase,
    Loader, Requester, DialogChain, Damage) {
    /**
    * 被害自動公開設定ダイアログ
    * @class 被害自動公開設定ダイアログ
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
      /** @lends module:app/damage/damageAutoPubSetting/damageAutoPubSettingDialog~damageAutoPubSettingDialog# */
    {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--damage',

        /**
           * 変数の初期化。
           */
        costructor: function() {
            this.chain = DialogChain.get(this);
        },

        /**
         * ページの初期化
         */
        initDialog: function(disasterId) {
            this.form.reset();
            this.templateCd = '';

            //ダイアログ内ラジオボタンに被害自動公開設定を反映
            //被害自動公開設定を取得する
            Damage.getAutoPubInfo(disasterId).then(lang.hitch(this, function(isAutoPub) {
                this.isAutoPub = isAutoPub;

                var autoPubFlg0 = this.autoPubFlg0;
                var autoPubFlg1 = this.autoPubFlg1;

                if (isAutoPub) { 
                    //被害自動公開設定がtrueの場合、「自動で公開する」を初期表示とする
                    autoPubFlg1.set('checked', true);
                } else {
                    //被害自動公開設定がfalseの場合、「自動で公開しない」を初期表示とする
                    autoPubFlg0.set('checked', true);
                }
            }));
        },

        onSubmit: function(){
            //被害自動公開設定を更新する
            try{
                var sendData = this.form.get('value');
                this.emit('send', {sendData: sendData});
            } catch (e) {
                console.error(e);
            }
        },
    });
});