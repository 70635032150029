/**
 * 水道局災害名選択ダイアログ用グリッド
 * @module app/waterworks/selectors/IncidentSelectorGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/date/locale',
    'dgrid/Selector',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, locale, Selector, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selector],
        /** @lends module:idis/view/form/IncidentSelectorGrid~IncidentSelectorGrid# */ {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        collection: this.store,
        columns: [
            helper.column('incidentId','水道局災害ID'),
            helper.column('incidentName','水道局災害名', {sortable: false}),
            helper.column('updTimestamp', '更新日時', {
                formatter: function(item) {
                    if(item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                }
            })
        ]
    });
});