/**
* 体制状況一覧用モジュール。
* @module app/disasterprevention/view/DisasterPreventionPrefStatusGrid
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    './DPColumn'
    // 以下、変数として受け取らないモジュール
], function(module, declare, lang, locale, IdisGrid, helper, DPColumn) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.buttonColumn('detail', '詳細'),
            {field: 'seqNum', label: '続報番号'},
            // DPColumn.areaName('県・振興局'),
            DPColumn.status,
            DPColumn.Headquarters,
            DPColumn.deploymentPrefecture,
            {field: 'reportUserName', label: '報告者', sortable: false},
            DPColumn.reportTimestamp
        ]
    });
});
