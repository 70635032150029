/**
 * チャットグループの新規登録・詳細ダイアログ用の基底モジュール。
 * @module app/chat/_ChatGroupDialogBase
 */
define([
    'module',
    'leaflet',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button'
], function(module, leaflet, declare, lang, domStyle, InfoDialog, _PageBase) {

    return declare(module.id.replace(/\//g, '.'), _PageBase,
    {
        /**
         * グループID
         */
        _chatGroupId: null,

        /**
         * グループ名
         */
        _chatGroupName: null,

        /**
         * 目的
         */
        _description: null,

        /**
         * constructor
         */
        constructor: function() {
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * フォームをリセットする。
         */
        reset: function() {
            this.form.reset();
        },

        /**
         * 入力値の妥当性を検証する。
         */
        validate: function() {

            // 使用区分が災害時の時
            if (this.form.value.chatGroupUseType === '1') {
                // 所属が必須
                if (this.form.value.municipalityCd === '') {
                    InfoDialog.show('入力チェック', '参集体制所属が選択されていません');
                    return false;
                }
                // 本部設置のいずれかが入力されている
                var responseHqFlg = (this.responseHqFlg.getValue() === 'on');
                var alertHqFlg = (this.alertHqFlg.getValue() === 'on');
                var otherHqFlg = (this.otherHqFlg.getValue() === 'on');
                if (!responseHqFlg && !alertHqFlg && !otherHqFlg) {
                    InfoDialog.show('入力チェック', '本部設置が選択されていません');
                    return false;
                }
            }

            return true;
        },

        /**
         * 使用区分の変更
         */
        onChangeChatGroupUseType: function(evt) {
            this.setDisasterPrevention(evt.target.value);
        },

        /**
         * 参集体制に関する設定項目の表示非表示
         */
        setDisasterPrevention: function(value) {
            // 使用区分が災害時の場合、所属と本部設置を入力可能とする
            if (value === '1') {
                // 災害時
                domStyle.set(this.organizationArea, 'display', '');
                domStyle.set(this.hqFlgArea, 'display', '');
            } else if (value === '2') {
                // 常時
                domStyle.set(this.organizationArea, 'display', 'none');
                domStyle.set(this.hqFlgArea, 'display', 'none');
            }
        },

        /**
         * 体制登録対象の区市町村・組織の情報を取得
         */
        getDpInfo: function(dpMunicipalityCd, req){
            if (!this.municipalitySelector.tree) {
                return '';
            } else if (!this.municipalitySelector.tree.model.items) {
                return '';
            } else {
                var dpItems = this.municipalitySelector.tree.model.items;
                var res = '';
                if (dpItems) {
                    dpItems.some(function(dpItem){
                        // 選択された自治体
                        if (dpItem && (dpItem.id === dpMunicipalityCd)) {
                            if (req === 'type') {
                                // 選択された自治体のtypeに設定された組織カテゴリコードを取得
                                res = dpItem.type;
                            } else if (req === 'parentId') {
                                // 選択された自治体のparentIdに設定された区市町村コードを取得
                                res = dpItem.parentId;
                            }
                        }
                    }, this);
                }
                return res;
            }
        }

    });
});
