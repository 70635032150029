/**
 * 地区選択用レイヤー
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/topic',
    'leaflet',
    'idis/view/draw/_OptionConverterMixin'
], function (module, declare, lang, topic, leaflet, _OptionConverterMixin) {
    // 地図レイヤーのスタイルを設定する。
    return {
        // 避難情報カラー
        _colors: {
            // 高齢者等避難: orange
            _prepareColor: '#FC002E',
            // 避難指示: red
            _advisoryColor: '#AA00AA',
            // 緊急安全確保: 'purple'
            _orderColor: '#0C000C',
            // 解除: 'black'
            _cancelColor: '#808080'
        },

        // 非選択状態
        _defaultStyle: {
            weight: 1,
            opacity: 0.9,
            color: '#808080',
            // fillColor: '#808080',
            dashArray: '',
            fillOpacity: 0.1
        },
        // 非選択状態且つマウスオーバー
        _mouseOnStyle: {
            weight: 2,
            opacity: 0.8,
            color: '#808080',
            // fillColor: '#ff9500',
            dashArray: '',
            fillOpacity: 0.6
        },
        // 選択状態
        _selectedStyle: {
            weight: 1,
            opacity: 1,
            color: '#808080',
            // fillColor: '#ff9500',
            dashArray: '',
            fillOpacity: 0.8
        },
        // 選択状態且つマウスオーバー
        _selectedMouseOnStyle: {
            weight: 2,
            opacity: 1,
            color: '#808080',
            // fillColor: '#ff9500',
            dashArray: '',
            fillOpacity: 0.9
        },

        _filter: function (feature) {
            var result = false;
            // 最新の地区マスタ
            var districtTreeCache = this._districtTreeCache;
            districtTreeCache.some(lang.hitch(this, function (item) {
                // リーフ要素でない場合はスキップ
                if (item.evacOrderDistFlg === '0') {
                    return;
                }
                // マスタに存在する有効地区情報であれば対象にする。
                if (feature.properties.D_CD === item.districtCd) {
                    result = true;
                    return true; //break
                }
            }));
            return result;
        },

        // onEachFeatureを設定する。
        _onEachFeature: function (feature, layer) {
            // 地区名をポップアップに設定する(D_NAME: 地区名)
            if (feature.properties.D_NAME) {
                layer.bindPopup(
                    // D_NAME:地区名
                    feature.properties.D_NAME,
                    // ポップアップのプロパティ
                    {
                        maxWidth: 1000,
                        closeButton: false,
                        // ポップアップがフィーチャーと重なり選択しづらいので、
                        // 離れた場所にポップアップを表示する。
                        offset: leaflet.point(0, -25)
                    }
                );
            }
            // Polygonはlayer.options、MultiPoligonはlayer._layer[id].optionsとしてoptionsを保持している。
            var options = layer.options || layer._layers[layer._leaflet_id - 1].options; //jshint ignore:line
            // レイヤーにイベントを付与する
            layer.on({
                mouseover: options._mouseOverFeature,
                mouseout: options._mouseOutFeature,
                click: options._selectFeature
            });
        },

        // マウスオーバーをした際のアクションを追加する。
        _mouseOverFeature: function (e) {
            var layer = e.target;
            // Polygonはlayer.options、MultiPoligonはlayer._layer[id].optionsとしてoptionsを保持している。
            var options = layer.options || layer._layers[layer._leaflet_id - 1].options; //jshint ignore:line

            // 画面上で選択されている避難区分の色を設定する。
            // MultiPolygonの場合、複数の子レイヤーに色を設定する必要があるため、配列で保持している全てのレイヤーに色を指定する。
            options.fillColor = options._selectedColor;
            if (layer._layers) {
                Object.keys(layer._layers).forEach(function (key) {
                    layer._layers[key].options.fillColor = options._selectedColor;
                });
            }

            // 選択の有無に応じて設定するスタイルを変更する。
            // if(options._isSelected){
            //     layer.setStyle(options._selectedMouseOnStyle);
            // }else{
            //     layer.setStyle(options._mouseOnStyle);
            // }

            if (!leaflet.Browser.ie && !leaflet.Browser.opera && !leaflet.Browser.edge) {
                layer.bringToFront();
            }

            // ポップアップを開く。
            layer.openPopup();

        },

        // マウスアウトした際のアクションを追加する。
        _mouseOutFeature: function (e) {
            var layer = e.target;
            // Polygonはlayer.options、MultiPoligonはlayer._layer[id].optionsとしてoptionsを保持している。
            var options = layer.options || layer._layers[layer._leaflet_id - 1].options; //jshint ignore:line

            if (options._isSelected) {
                // 画面上で選択されている避難区分の色を設定する。
                // MultiPolygonの場合、複数の子レイヤーに色を設定する必要があるため、配列で保持している全てのレイヤーに色を指定する。
                options.fillColor = options._selectedColor;
                if (layer._layers) {
                    Object.keys(layer._layers).forEach(function (key) {
                        layer._layers[key].options.fillColor = options._selectedColor;
                    });
                }

                // スタイルを選択する。
                // layer.setStyle(options._selectedStyle);

            } else {
                // 画面上で選択されている避難区分の色を設定する。
                // MultiPolygonの場合、複数の子レイヤーに色を設定する必要があるため、配列で保持している全てのレイヤーに色を指定する。
                options.fillColor = options._cancelColor;
                if (layer._layers) {
                    Object.keys(layer._layers).forEach(function (key) {
                        layer._layers[key].options.fillColor = options._cancelColor;
                    });
                }

                // スタイルを選択する。
                layer.setStyle(options._defaultStyle);
            }

            // ポップアップを閉じる。
            if (layer.closePopup) {
                layer.closePopup();

            } else {
                // MultiPolygonの場合closePopの対象となるのはイベントのターゲットとなるレイヤー
                // MultiPolygonのlayerはclosePopupのfuntionを保持していないため、子レイヤーのfunctionを利用する。
                layer._layers[layer._leaflet_id - 1].closePopup();;//jshint ignore:line
            }

        },

        // クリックされた時の処理を追加する。
        // 市町が選択された場合、市町ID及び親ID（地域ID）情報をレイヤーから取得する。
        // 該当する項目のチェックボックスを指定する処理を発行する。
        // _selectFeature : function(e) {
        //     // D_CD: 地区コード、M_CD: 市町村コード
        //     var properties = e.target.feature.properties;
        //     var item = {id: properties.D_CD, parentId: properties.M_CD};
        //     topic.publish('app/evacorder/DistrictLayerFactory' + '::selectDistrict', item);
        // },

        // レイヤーが選択された時の処理
        // 自分のレイヤーが呼ばれた時にはtopicでチェックボックスが選択されて、
        // それに伴ってこのメソッドが呼ばれることでスタイルが変更される。
        _selectDistrictLayer: function (layer, color) {
            if (!this._isSelected) {
                this._isSelected = true;
                this.fillColor = this._colors[color];
                var layerStyle = this._selectedStyle;
                layerStyle['fillColor'] = this._colors[color];
                // MultiPolygon対応
                if (layer._layers) {
                    Object.keys(layer._layers).forEach(function (key) {
                        layer._layers[key].options.fillColor = this._colors[color];
                    }, this);
                }
                layer.setStyle(layerStyle);
            }
        },

        // レイヤーが選択解除された時の処理
        _deselectDistrictLayer: function (layer) {
            if (this._isSelected) {
                this._isSelected = false;
                this.fillColor = this._cancelColor;
                // MultiPolygon対応
                if (layer._layers) {
                    Object.keys(layer._layers).forEach(function (key) {
                        layer._layers[key].options.fillColor = this._cancelColor;
                    }, this);
                }

                layer.setStyle(this._defaultStyle);
            }
        },

        _toTanJSON: function () {
            var tanJsons = this.toGeoJSON();
            tanJsons.features = [];
            this.eachLayer(function (layer) {
                lang.hitch(this, function () {
                    var options = layer.options || layer._layers[layer._leaflet_id - 1].options; //jshint ignore:line
                    options = this.optionConverter(options, 'export');
                });
                tanJsons.features.push(layer);
            });
            return {
                type: tanJsons.type,
                features: tanJsons.features
            };
        },

        // GeoJsonレイヤーのFeature Groupを返却する
        getGeoJsonLayers: function (geojson) {
            leaflet.GeoJSON.include(_OptionConverterMixin);
            return leaflet.geoJson(geojson, {
                style: this._defaultStyle,
                // filter: this._filter,
                // _districtTreeCache: districtTreeCache,
                // onEachFeatureとonEachFeatureでアタッチするイベント
                onEachFeature: this._onEachFeature,
                _mouseOverFeature: this._mouseOverFeature,
                _mouseOutFeature: this._mouseOutFeature,
                _selectFeature: this._selectFeature,
                // color
                _colors: this._colors,
                _selectedColor: this._colors._advisoryColor,
                _defaultStyle: this._defaultStyle,
                _mouseOnStyle: this._mouseOnStyle,
                _selectedStyle: this._selectedStyle,
                _selectedMouseOnStyle: this._selectedMouseOnStyle,
                // 外部から利用する機能
                selectDistrictLayer: this._selectDistrictLayer,
                deselectDistrictLayer: this._deselectDistrictLayer,
                toTanJSON: this._toTanJSON,
                // 選択されいてるfeatureかどうかの判定用フラグ
                _isSelected: false
            });
        }
    };
});
