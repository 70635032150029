/**
 * 配信情報登録画面用モジュール。
 * @module app/sending/SendingRegisterPage
 */
define([
    'module',
    'app/model/DisasterInfo',
    'dojo',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-style',
    'dojo/json',
    'dojo/text!./templates/SendingRegisterPage.html',
    'dojo/topic',
    'dojo/when',
    'dojo/Deferred',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    './_SendingPageBase',
    'app/model/Organization',
    'app/provide/ProvideUtil',
    'idis/consts/USER_TYPE',
    'app/config',
    // 以下、変数として受け取らないモジュール
    'dijit/form/CheckBox',
    'dijit/form/Select',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/DateTimeInput',
    'app/view/form/OrganizationSelector',
    'app/sending/SendingConfirmDialog',
    'app/sending/SendingTestConfirmDialog',
    'app/sending/SendingSelectorDialog',
    'app/sending/MunicipalitySelector'
    ], function(module, DisasterInfo, dojo, declare, lang, array, domStyle, json, template,
        topic, when, Deferred, Locator, Router, UserInfo, Requester, InfoDialog, Loader,
        _SendingPageBase, Organization, ProvideUtil, USER_TYPE, config) {
    /**
     * 配信情報新規登録画面。
     * @class SendingRegisterPage
     * @extends module:app/sending/_SendingPageBase~_SendingPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _SendingPageBase,
            /** @lends module:app/sending/SendingRegisterPage~SendingRegisterPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sending',

        _isEmergency: null, // true or false
        _deptCd: null,
        _sectCd: null,
        _unitCd: null,
        // ツイッター文字数カウント用
        _tweetNum: null,

        // 緊急速報メール文字数カウント用
        _emergencyMailNum: null,

        TWITTER_MAX_LENGTH: 140,

        EMERGENCY_MAIL_MAX_LENGTH: 180,

        EMERGENCY_MAIL_LIMIT: 3,

        // 配信先市町村の設定情報
        _sendingSetting: null,

        _municipalityCd: null,

        constructor: function() {
            this.inherited(arguments);
        },

        startup: function() {
            this.inherited(arguments);

            this._isEmergency = Locator.getQuery().emergency === '0' ? false : true;
            this._deptCd = UserInfo.getOrganization().deptCd;
            this._sectCd = UserInfo.getOrganization().sectCd;
            this._unitCd = UserInfo.getOrganization().unitCd;

            this.lalert.set('checked', true);
            if (this._isEmergency) {
                this.emergencyMail.set('checked', true);
                domStyle.set(this.dispEmergencyMailCheckBox, 'display', 'inline');
            }else{
                this.emergencyMail.set('checked', false);
                domStyle.set(this.dispEmergencyMailCheckBox, 'display', 'none');
            }

            // 配信課
            // 報告部署にログインユーザの組織情報を設定
            var orgCd = (this._unitCd ? 'U' + this._unitCd :
                             this._sectCd ? 'S' + this._sectCd :
                             this._deptCd ? 'D' + this._deptCd : '');
            Organization.store.get(orgCd).then(
                lang.hitch(this, function(item){
                var _sendOrganizationName = item ? item.name : '取得失敗';

                this.sendOrganizationName.set('value',_sendOrganizationName);
            }));

            this._municipalityCd = (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY) ?
                UserInfo.getMunicipalityCd() || UserInfo.getMunicipalityCds()[0] : null;

            this._changeCheckBoxesVisibleByAuth();
            this._changeCheckBoxesVisible(config.municInfo.cityMunicCd);
            this._setMunicipalityChangedEvent();
        },

        /**
         * サーバから配信先市町村の設定を取得する
         * コールバック関数の引数には成功時は設定の値、失敗時はnullが入る
         * メンバ変数_sendingSettingに設定を代入する
         *
         * 呼ぶときは when(this._setSendingSetting(municipalityCd), function())
         */
        _setSendingSetting: function(municipalityCd) {
            var deferred = new Deferred();
            this._sendingSetting = null;
            if (!municipalityCd) {
                return deferred.promise;
            }
            municipalityCd = this._convertPrefectureCd(municipalityCd);
            var promise = Requester.get('/api/municipalities/sendingsetting/', {
                query: {
                    municipalityCds: municipalityCd
                }
            });
            Loader.wait(promise).then(lang.hitch(this, function(sendingSettings) {
                if (sendingSettings.length === 0) {
                    deferred.resolve(null);
                }
                this._sendingSetting = sendingSettings[0];
                deferred.resolve(this._sendingSetting);
            }), function() {
                // error
                deferred.resolve(null);
            });
            return deferred.promise;
        },

        /**
         * 市町村に応じてチャネルのチェックボックスの表示・非表示を変更する。
         */
        _changeCheckBoxesVisible: function(municipalityCd) {
            if (!municipalityCd) {
                return;
            }
            when(this._setSendingSetting(municipalityCd),
                lang.hitch(this, function(sendingSetting) {
                    if (!sendingSetting) {
                        // 設定の取得に失敗した場合は非表示
                        this.twitter.checked = false;
                        this.yahoo.checked = false;
                        domStyle.set(this.dispTwitterCheckBox, 'display', 'none');
                        domStyle.set(this.dispYahooCheckBox, 'display', 'none');
                        return;
                    }

                    // twitter
                    if (sendingSetting.twitterFlg && UserInfo.hasAuthz('F06003')) {
                        this.twitter.set('checked', true);
                        domStyle.set(this.dispTwitterCheckBox, 'display', 'inline');
                    } else {
                        this.twitter.set('checked', false);
                        domStyle.set(this.dispTwitterCheckBox, 'display', 'none');
                    }

                    // yahoo
                    if (sendingSetting.yahooFlg && UserInfo.hasAuthz('F06003')) {
                        this.yahoo.set('checked', true);
                        domStyle.set(this.dispYahooCheckBox, 'display', 'inline');
                    } else {
                        this.yahoo.set('checked', false);
                        domStyle.set(this.dispYahooCheckBox, 'display', 'none');
                    }
                })
            );
        },

        _changeCheckBoxesVisibleByAuth: function(){
            // 県防災情報メール、Lアラートは、市町村の別によらず、単純に権限を持っているかどうかで決める
            if(UserInfo.hasAuthz('F06005')){
                domStyle.set(this.dispLalertCheckBox, 'display', '');
                // domStyle.set(this.dispMailCheckBox, 'display', '');
            } else {
                domStyle.set(this.dispLalertCheckBox, 'display', 'none');
                // domStyle.set(this.dispMailCheckBox, 'display', 'none');
            }

            // Yahoo, twitterは、市町村別設定によっては、あとで出てくるが、まずは権限で弾く
            if(UserInfo.hasAuthz('F06003')){
                domStyle.set(this.dispTwitterCheckBox, 'display', '');
                domStyle.set(this.dispYahooCheckBox, 'display', '');
            } else {
                domStyle.set(this.dispTwitterCheckBox, 'display', 'none');
                domStyle.set(this.dispYahooCheckBox, 'display', 'none');
            }
        },

        /*
         * 配信先市町村が変更されたときのイベントを登録
         */
        _setMunicipalityChangedEvent: function() {
            this.municipalityCd.on('change', lang.hitch(this, function(evt) {
                this._changeCheckBoxesVisible(evt.value);
            }));
        },

        /*
         * チャネル別詳細設定を表示
         */
        showChannelDetails: function() {
            domStyle.set(this.inputGuide, 'display', 'none');
            var isChecked = false;
            // 緊急速報メール
            if (this.emergencyMail.checked) {
                isChecked = true;
                domStyle.set(this.inputEmergencyMail, 'display', 'block');

                // 緊急速報メール削除
                var emergencyElement = dojo.byId('emergencyMailOuterFrame');
                while (emergencyElement.firstChild) {
                    emergencyElement.removeChild(emergencyElement.firstChild);
                }


                // this.emergencyMailMunicipalityCd.set('value', this.municipalityCd.value);
                // this.emergencyMailSubject.set('value', this.subject.displayedValue);
                this.emergencyMailSubjectPreview.innerHTML = this.subject.displayedValue;
                // this.emergencyMailBody.set('value', this.bodyText.displayedValue);
                var emergencyMailBodyText = this.bodyText.displayedValue;
                var emergencyMailCount = this._emergencyMailNum;
                // 緊急速報メール必要枠の計算
                this._emergencyMailNum = Math.ceil(this.getLen(emergencyMailBodyText) /
                    this.EMERGENCY_MAIL_MAX_LENGTH);
                if (this._emergencyMailNum > 1) {
                    // 「（続く）」分の文字数を考慮して枠を追加
                    this._emergencyMailNum = Math.ceil((
                        this.getLen(emergencyMailBodyText)) / this.EMERGENCY_MAIL_MAX_LENGTH);
                } else if(this.bodyText.displayedValue.length === 0) {
                    this._emergencyMailNum = 1;
                }
                
                var emergencyWords = 0;
                for (var j = 0; j < this._emergencyMailNum; j++) {
                    var oneEmergencyMailContentLength = this.EMERGENCY_MAIL_MAX_LENGTH - 1;
/*                    if (j !== 0) {
                        oneEmergencyMailContentLength -= connectWordLengthFirst;
                    }*/
                    var oneEmergencyMailText = {bodyText:emergencyMailBodyText.substr(
                        emergencyWords, oneEmergencyMailContentLength)};
                    this.addEmergencyMail(oneEmergencyMailText, j + 1);
                    emergencyWords += oneEmergencyMailContentLength;
                }
                if(emergencyMailCount === this.EMERGENCY_MAIL_LIMIT) {
                    domStyle.set(this.addEmergencyMailButton.domNode, 'display', '');
                }
            } else {
                domStyle.set(this.inputEmergencyMail, 'display', 'none');
            }

            // Lアラート
            if (this.lalert.checked) {
                isChecked = true;
                domStyle.set(this.inputLalert, 'display', 'block');
                this.lalertInputBody.set('value', this.bodyText.displayedValue);
                this.onChangeLalertCategorySelect();
            } else {
                domStyle.set(this.inputLalert, 'display', 'none');
            }

            // // 県防災情報メール
            // if (this.prefectureMail.checked) {
            //     isChecked = true;
            //     domStyle.set(this.inputPrefectureMail, 'display', 'block');
            //     this.preMailMunicipalityCd.set('value', this.municipalityCd.value);
            //     this.prefectureMailSubject.set('value', this.subject.displayedValue);
            //     this.prefectureMailBody.set('value', this.bodyText.displayedValue);
            // } else {
            //     domStyle.set(this.inputPrefectureMail, 'display', 'none');
            // }

            // Twitter
            if (this.twitter.checked) {
                isChecked = true;
                domStyle.set(this.inputTwitter, 'display', 'block');
                var twitterBodyText = '【' + this.subject.displayedValue + '】'+ this.bodyText.displayedValue;

                // tweetを全て削除
                var element = dojo.byId('twitterOuterFrame');
                while (element.firstChild) {
                    element.removeChild(element.firstChild);
                }

                // tweet必要枠の計算
                this._tweetNum = Math.ceil(this.getLen(twitterBodyText) / this.TWITTER_MAX_LENGTH);
                var connectWordLengthFirst = 4;
                var connectWordLengthLast = 3;
                var connectAllLength = connectWordLengthFirst + connectWordLengthLast;
                if (this._tweetNum > 1) {
                    // 「（続く）」分の文字数を考慮して枠を追加
                    this._tweetNum = Math.ceil(((this._tweetNum-1) * connectAllLength +
                                                    this.getLen(twitterBodyText)) / this.TWITTER_MAX_LENGTH);
                }
                var words = 0;
                for (var i = 0; i < this._tweetNum; i++) {
                    var oneTweetContentLength = this.TWITTER_MAX_LENGTH - connectWordLengthLast - 1;
                    if (i !== 0) {
                        oneTweetContentLength -= connectWordLengthFirst;
                    }
                    var oneTweetText = twitterBodyText.substr(words, oneTweetContentLength);
                    this.addTweet(oneTweetText, i + 1);
                    words += oneTweetContentLength;
                }
            } else {
                domStyle.set(this.inputTwitter, 'display', 'none');
            }

             // Yahoo
            if (this.yahoo.checked) {
                isChecked = true;
                domStyle.set(this.inputYahoo, 'display', 'block');
                var yahooBodyText = '【' + this.subject.displayedValue + '】'+ this.bodyText.displayedValue;
                this.yahooBody.set('value', yahooBodyText);
            } else {
                domStyle.set(this.inputYahoo, 'display', 'none');
            }

            // 緊急情報配信（防災アプリ）
            if (this.emergencyPush.checked) {
                isChecked = true;
                domStyle.set(this.inputEmergencyPush, 'display', 'block');
                this.emergencyPushSubject.set('value', this.subject.displayedValue);
                this.emergencyPushBody.set('value', this.bodyText.displayedValue);
            } else {
                domStyle.set(this.inputEmergencyPush, 'display', 'none');
            }

            // チェックが付いていなければガイドを表示
            if (!isChecked){
                domStyle.set(this.channelSelectWarning, 'display', 'block');
            }else{
                domStyle.set(this.channelSelectWarning, 'display', 'none');
            }
        },

        addTweet: function(oneTweetText, i) {
            // +ボタンで追加した場合
            if (oneTweetText.type === 'click') {
                oneTweetText = '';
                this._tweetNum += 1;
                i = this._tweetNum;
            } else {
                // 最終ツイート以外に「続く」を入れる
                if (i !== this._tweetNum) {
                    oneTweetText = oneTweetText + '(続く)';
                }
            }
            // 最初ツイート以外に「続き」を入れる
            if (i !== 1) {
              oneTweetText = '(続き)' + oneTweetText;
            }
            this.createTweetElements(oneTweetText, i);
        },

        /**
         * 配信情報一覧画面へ遷移する。
         */
        onSendingPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('sending');
        },

        /**
         * テンプレートダイアログを表示する
         */
        showTemplateDialog: function() {

            // ダイアログ中のページウィジェットを取得
            var page = this.dialog.getChildren()[0];
            page.initContent(this._isEmergency, this._municipalityCd);

            // ダイアログを表示
            this.dialog.show();

            // 画面初期表示時にうまく描画されない問題の対処
            setTimeout(lang.hitch(this, function(){
                this.dialog.show();
            }), 40);

            // ダイアログ側でOKボタンが押された際のイベント
            page.on('update', lang.hitch(this, function(evt) {
                // テンプレート変更
                this._changeTemplate(evt.data);
                // ダイアログを非表示にする
                this.dialog.hide();
            }));

            // ダイアログの画面を再表示する
            page.refresh();
        },

        /**
         * 未定義か地域コードの場合はメッセージを表示してtrueを返す
         */
        _invalidMunicipalityCd: function(municipalityCd, targetName) {
            if(!municipalityCd){
                if (targetName) {
                    InfoDialog.show('', targetName + 'の市/行政区を選択してください。');
                } else {
                    InfoDialog.show('', '市/行政区を選択してください。');
                }
                return true;
            }
            if (municipalityCd !== config.municInfo.prefCd &&
                    municipalityCd.slice(0, 4) === config.municInfo.prePrefCd + '00') {
                if (targetName) {
                    InfoDialog.show('', targetName + 'では地域は選択できません。');
                } else {
                    InfoDialog.show('', '地域は選択できません。');
                }
                return true;
            }
            return false;
        },

        /**
         * 県のコードを変換する。
         *
         * ManageRegionMunicipalitySelectorは県のコードとしてAreaCode.propertiesの
         * PREF_FULL_CODEの値を使用しているが、配信情報が登録される
         * T_SENDING_HEADERテーブルのMUNICIPALITY_CD列はM_MUNICIPALITYテーブルの
         * MUNICIPALITY_CD列との間に参照整合性制約があるため、県は市町村コードでなければ登録できない。
         */
        _convertPrefectureCd: function(municipalityCd) {
            if (municipalityCd === config.municInfo.prefCd) {
                 return config.municInfo.prefMunicCd;
            }
            return municipalityCd;
        },

        /**
         * 配信確認ダイアログを表示する
         */
        showConfirmDialog: function() {
            // // 市町村
            // if (this._invalidMunicipalityCd(this.municipalityCd.value, null)) {
            //     return;
            // }

            // 配信先を選択しているかチェック
            if (!(this.emergencyMail.checked ||
                  this.lalert.checked ||
                  this.prefectureMail.checked ||
                  this.twitter.checked ||
                  this.yahoo.checked ||
                  this.emergencyPush.checked)) {
                InfoDialog.show('', '配信先チャンネルを選択してください。');
                return;
            }

            // チェックボックスとチャネル詳細が合致しているかチェック
            if (this._invalidChannelDetailDisplay()) {
                InfoDialog.show('', '「チャンネル別詳細設定ボタン」を<br>押して詳細を確認してください。');
                return;
            }

            // Lアラートの内容チェック
            if (this.lalert.checked) {
                // Lアラートの本文チェック
                if (this.lalertInputBody.value === '') {
                    InfoDialog.show('入力チェック', 'Lアラートの本文が入力されていません');
                    return;
                }
            }
            // 緊急速報メールの内容チェック
            if (this._isEmergency && this.emergencyMail.checked) {
                // if (this._invalidMunicipalityCd(this.emergencyMailMunicipalityCd.value, '緊急速報メール')) {
                //     return;
                // }
                if (!ProvideUtil.urgentMailValidate(this.emergencyMailSubject.value)) {
                    InfoDialog.show('', '緊急速報メールの件名に電話番号・URL・メールアドレスが含まれています。');
                    return;
                }
/*                if (!ProvideUtil.urgentMailValidate(this.emergencyMailBody.value)) {
                    InfoDialog.show('', '緊急速報メールの本文に電話番号・URL・メールアドレスが含まれています。');
                    return;
                }
                if (this.emergencyMailBody.value === '') {
                    InfoDialog.show('入力チェック', '緊急速報メールの本文が入力されていません');
                    return;
                }
*/
                var emergencyMailElement = dojo.byId('emergencyMailOuterFrame');
                var emergencyMailElements = emergencyMailElement.getElementsByTagName('textarea');
                for(var j=0; j < emergencyMailElements.length; j++) {
                    if (!ProvideUtil.urgentMailValidate(emergencyMailElements[j].value)) {
                        InfoDialog.show('', '緊急速報メールの'+ (j+1) + '番目の本文に電話番号・URL・メールアドレスが含まれています。');
                        return;                
                    }
                    if (emergencyMailElements[j].value === '') {
                        InfoDialog.show('', '緊急速報メールの'+ (j+1) + '番目の本文が入力されていません');
                        return;                
                    }
                }
                // if (this.emergencyMailSubject.value === '') {
                //     InfoDialog.show('入力チェック', '緊急速報メールの件名が入力されていません');
                //     return;
                // }
            }

            // // Lアラート
            // if (this.lalert.checked && !this.publisher.value) {
            //     InfoDialog.show('', 'Lアラートの発行元を選択してください。');
            //     return;
            // }

            // // 県防災メール
            // if (this.prefectureMail.checked) {
            //     if (this._invalidMunicipalityCd(this.preMailMunicipalityCd.value, '県防災情報メール')) {
            //         return;
            //     }
            //     if (this.prefectureMailSubject.value === '') {
            //         InfoDialog.show('入力チェック', '県防災情報メールの本文が入力されていません');
            //         return;
            //     }
            //     if (this.prefectureMailBody.value === '') {
            //         InfoDialog.show('入力チェック', '県防災情報メールの件名が入力されていません');
            //         return;
            //     }
            // }

            //twitter
            if (this.twitter.checked){
                // テキストエリアの残り文字数計算
                var element = dojo.byId('twitterOuterFrame');
                var elements = element.getElementsByTagName('textarea');
                // 配信内容が空の時にエラーメッセージの表示
                if (this.getLen(elements[0].value) === 0){
                    InfoDialog.show('','Twitterの配信内容が空です。配信内容を記載してください。');
                    return;
                }
                // 文字数制限を超えている時にエラーメッセージの表示
                for (var i=0; i < elements.length; i++){
                    var text = elements[i].value;
                    var words =  this.TWITTER_MAX_LENGTH - this.getLen(text);
                    if (words < 0){
                        InfoDialog.show('','Twitterの文字数制限を超えています。各記入枠の文字数を制限内に抑えてください。');
                        return;
                    }
                }
            }

            // yahoo
            if (this.yahoo.checked) {
                if (this.yahooBody.value === '') {
                    InfoDialog.show('入力チェック', 'Yahoo!防災速報の配信内容が入力されていません');
                    return;
                }
            }

            // 緊急情報配信（防災アプリ）
            if (this.emergencyPush.checked) {
                if (this.emergencyPushSubject.value === '') {
                    InfoDialog.show('入力チェック', '緊急情報配信（防災アプリ）の件名が入力されていません');
                    return;
                }
                if (this.emergencyPushBody.value === '') {
                    InfoDialog.show('入力チェック', '緊急情報配信（防災アプリ）の本文が入力されていません');
                    return;
                }
            }

            // フォームの入力チェック
            if (!this.form.validate()) {
                return;
            }

            // ダイアログを表示
            this.confirmDialog.show();
            // ダイアログ中のページウィジェットを取得
            var page = this.confirmDialog.getChildren()[0];
            // ダイアログ側で「一括配信」ボタンが押された際のイベント
            page.on('register', lang.hitch(this, function(data) {
                // 登録
                return this.register(data.sendTimestamp);
            }));
            page.refresh(this);
        },

        /**
         * テスト送信ダイアログを表示する
         */
        showTestConfirmDialog: function() {

            // 配信先を選択しているかチェック
            if (!this.emergencyPush.checked) {
                InfoDialog.show('', '配信先チャンネルを選択してください。');
                return;
            }

            // チェックボックスとチャネル詳細が合致しているかチェック
            if (this._invalidChannelDetailDisplay()) {
                InfoDialog.show('', '「チャンネル別詳細設定ボタン」を<br>押して詳細を確認してください。');
                return;
            }

            // 緊急情報配信（防災アプリ）
            if (this.emergencyPush.checked) {
                if (this.emergencyPushSubject.value === '') {
                    InfoDialog.show('入力チェック', '緊急情報配信（防災アプリ）の件名が入力されていません');
                    return;
                }
                if (this.emergencyPushBody.value === '') {
                    InfoDialog.show('入力チェック', '緊急情報配信（防災アプリ）の本文が入力されていません');
                    return;
                }
            }

            // フォームの入力チェック
            if (!this.form.validate()) {
                return;
            }

            // ダイアログを表示
            this.confirmTestDialog.show();
            // ダイアログ中のページウィジェットを取得
            var page = this.confirmTestDialog.getChildren()[0];
            // ダイアログ側で「テスト配信」ボタンが押された際のイベント
            page.on('register', lang.hitch(this, function(data) {
                // テスト送信
                return this.testSend(data.sendTimestamp);
            }));
            page.refresh(this);
        },

        /**
         * チェックボックスとチャネル詳細が合致していないことをチェックする
         * 合致していない場合trueを返す
         */
        _invalidChannelDetailDisplay: function() {
            var objs = [
                {display: this.inputEmergencyMail.style.display,
                 checked: this.emergencyMail.checked},
                {display: this.inputLalert.style.display,
                 checked: this.lalert.checked},
                {display: this.inputPrefectureMail.style.display,
                 checked: this.prefectureMail.checked},
                {display: this.inputTwitter.style.display,
                 checked: this.twitter.checked},
                {display: this.inputYahoo.style.display,
                 checked: this.yahoo.checked}
            ];
            for (var i = 0; i < objs.length; i++) {
                var dispState = objs[i].display !== 'block' ? false : true;
                if (dispState !== objs[i].checked) {
                    return true;
                }
            }
            return false;
        },

        /**
         * 配信情報を登録する。
         */
        register: function(sendTimestamp) {
            console.debug('[登録]ボタンがクリックされました。');
            //var sendData = this.form.get('value');
            var sendData = {};

            /* 共通部分 */
            sendData.disasterId = DisasterInfo.getDisasterId();
            sendData.infoType = '10'; // '10'「お知らせ」決め打ち
            sendData.deptCd = this._deptCd;
            sendData.sectCd = this._sectCd;
            sendData.unitCd = this._unitCd;
            sendData.sendOrganizationName = this.sendOrganizationName.get('value');
            sendData.sendTimestamp = sendTimestamp;
            // var municipalityCd = this.municipalityCd.get('value');
            // sendData.municipalityCd = this._convertPrefectureCd(municipalityCd);
            sendData.municipalityCd = config.municInfo.cityMunicCd;
            sendData.subject = this.subject.get('value');
            sendData.bodyText = this.bodyText.get('value');
            sendData.senderName = this.senderName.get('value');

            /* チャネル別詳細設定 */
            // 緊急速報メール
            if (this.emergencyMail.checked) {
                sendData.urgentMailFlg = '1';
                // var emergencyMailMunicipalityCd = this.emergencyMailMunicipalityCd.get('value');
                // sendData.emergencyMailMunicipalityCd = this._convertPrefectureCd(emergencyMailMunicipalityCd);
                sendData.emergencyMailMunicipalityCd = config.municInfo.cityMunicCd;
                sendData.emergencyMailSubject = this.subject.get('value');
//                sendData.emergencyMailBody = this.emergencyMailBody.get('value');
                var emergencyMailData = this.emergencyMailOuterFrame.getElementsByTagName('textarea');
                var emergencyMailList = [];
                 array.forEach (emergencyMailData, function(data) {
                     emergencyMailList.push(data.value);
                 });
                 sendData.emergencyMailBodyList = emergencyMailList;
            } else {
                sendData.urgentMailFlg = '0';
            }

            // Lアラート
            if (this.lalert.checked) {
                sendData.lalertFlg = '1';
                sendData.publisher = this.publisher.get('value');
                sendData.sendCategory ='04'; // '04'「お知らせ」決め打ち
                sendData.lalertUrgency = this.lalertUrgency.get('value');
                sendData.lalertCategory = this.lalertCategory.get('value');
                sendData.sendingDetailType = this.sendingDetailType.get('value');
                sendData.lalertChangeType = null;
                sendData.lalertReason = null;
                sendData.lalertSendHistId = null;
                sendData.lalertSubject = null;
                sendData.lalertBodyText = this.lalertInputBody.get('value');
            } else {
                sendData.lalertFlg = '0';
            }

            // 県防災情報メール
            if (this.prefectureMail.checked) {
                sendData.prefectureMailFlg = '1';
                var prefectureMailMunicipalityCd = this.preMailMunicipalityCd.get('value');
                sendData.preMailMunicipalityCd = this._convertPrefectureCd(prefectureMailMunicipalityCd);
                sendData.prefectureMailSubject = this.prefectureMailSubject.get('value');
                sendData.prefectureMailBody = this.prefectureMailBody.get('value');
            } else {
                sendData.prefectureMailFlg = '0';
            }

            // Twitter
            if (this.twitter.checked) {
                sendData.twitterFlg = '1';
                var tweetData = this.twitterOuterFrame.getElementsByTagName('textarea');
                var tweetList = [];
                 array.forEach (tweetData, function(data) {
                     tweetList.push(data.value);
                 });
                sendData.tweetList = tweetList;
            } else {
                sendData.twitterFlg = '0';
            }

            // Yahoo
            if (this.yahoo.checked) {
                sendData.yahooFlg = '1';
                sendData.yahooBody = this.yahooBody.get('value');
            } else {
                sendData.yahooFlg = '0';
            }

            //  緊急情報配信（防災アプリ）
            if (this.emergencyPush.checked) {
                sendData.emergencyPushFlg = '1';
                sendData.emergencyPushBody = this.emergencyPushBody.get('value');
                sendData.emergencyPushSubject = this.emergencyPushSubject.get('value');
            } else {
                sendData.emergencyPushFlg = '0';
            }

            console.dir(sendData);

            // 最終確認を行う際のメッセージ文字列
            var lastConfirmMsg = '';
            lastConfirmMsg += this.lalert.checked ? '・Lアラート' : '';
            lastConfirmMsg += this.emergencyMail.checked ? '・緊急速報メール' : '';
            // lastConfirmMsg += this.prefectureMail.checked ? '・県防災情報メール' : '';
            lastConfirmMsg += this.twitter.checked ? '・Twitter' : '';
            lastConfirmMsg += this.yahoo.checked ? '・Yahoo!防災速報' : '';
            lastConfirmMsg += this.emergencyPush.checked ? '・緊急情報配信（防災アプリ）' : '';
            lastConfirmMsg += 'に連携します。<br>よろしいですか？';
            lastConfirmMsg = lastConfirmMsg.slice(1);
            lastConfirmMsg = '<div style="text-align:center;">' + lastConfirmMsg + '</div>';

            // 登録確認ダイアログを表示
            return this.chain.confirm(lastConfirmMsg, function(chain) {
                var promise = Requester.post('/api/sending', {
                    data: json.stringify(sendData),
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function(data) {
                    console.debug('success register Sending');
                    console.debug(data);
                    chain.infoComplete(function() {
                        this.confirmDialog.hide();
                        // 一覧画面に移動
                        Router.moveTo('sending');
                    });
                }), lang.hitch(this, function(error) {
                    console.log('error register Sending');
                    chain.infoError(error);
                    this.confirmDialog.hide();
                }));
                // ローダーの表示
                Loader.wait(promise);
            });
        },

        /**
         * テスト配信情報を登録する。
         */
        testSend: function(sendTimestamp) {
            console.debug('[テスト配信]ボタンがクリックされました。');
            var sendData = {};

            /* 共通部分 */
            sendData.disasterId = DisasterInfo.getDisasterId();
            sendData.infoType = '10'; // '10'「お知らせ」決め打ち
            sendData.deptCd = this._deptCd;
            sendData.sectCd = this._sectCd;
            sendData.unitCd = this._unitCd;
            sendData.sendOrganizationName = this.sendOrganizationName.get('value');
            sendData.sendTimestamp = sendTimestamp;
            sendData.municipalityCd = config.municInfo.cityMunicCd;
            sendData.subject = this.subject.get('value');
            sendData.bodyText = this.bodyText.get('value');
            sendData.senderName = this.senderName.get('value');

            /* チャネル別詳細設定 */
            //  緊急情報配信（防災アプリ）
            if (this.emergencyPush.checked) {
                sendData.emergencyPushFlg = '1';
                sendData.emergencyPushBody = this.emergencyPushBody.get('value');
                sendData.emergencyPushSubject = this.emergencyPushSubject.get('value');
            } else {
                sendData.emergencyPushFlg = '0';
            }

            console.dir(sendData);

            // 最終確認を行う際のメッセージ文字列
            var lastConfirmMsg = '';
            lastConfirmMsg += '緊急情報配信（防災アプリ）へのテスト配信を行います。';
            lastConfirmMsg += '<br>よろしいですか？';
            lastConfirmMsg = '<div style="text-align:center;">' + lastConfirmMsg + '</div>';

            // 登録確認ダイアログを表示
            return this.chain.confirm(lastConfirmMsg, function(chain) {
                var promise = Requester.post('/api/sending/test', {
                    data: json.stringify(sendData),
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function(data) {
                    console.debug('success test Sending');
                    console.debug(data);
                    chain.infoComplete(function() {
                        this.confirmTestDialog.hide();
                        this.chain.hide();
                        this.getParent().hide();
                    });
                }), lang.hitch(this, function(error) {
                    console.log('error test Sending');
                    chain.infoError(error);
                    this.confirmTestDialog.hide();
                }));
                // ローダーの表示
                Loader.wait(promise);
            });
        },

        /**
         * テンプレートを変更する
         */
        _changeTemplate: function(data) {
            // 選択されたテンプレートを画面へセット
            this.subject.set('value', data.subject);
            this.bodyText.set('value', data.bodyText);

            // 選択しているテンプレートIDを設定する。
            topic.publish(module.id + '::selected', data.admNum);
        },

        /**
         * メンバ変数_sendingSettingからtwitterIdとして表示する文字列を返す
         */
        getTwitterIdStr: function() {
            // return this._sendingSetting.municipalityName + ' @' + this._sendingSetting.twitterId;
            return config.municInfo.cityMunicName + ' @' + this._sendingSetting.twitterId;
        },

        /**
         * 選択可能な全てのチャネルを選択する
         */
        checkAllChannels: function() {
            if (this._isEmergency) {
                this.emergencyMail.set('checked', true);
            }
            if(UserInfo.hasAuthz('F06005')){
                this.lalert.set('checked', true);
                // this.prefectureMail.set('checked', true);
            }

            if (this._sendingSetting) {
                if (this._sendingSetting.twitterFlg && UserInfo.hasAuthz('F06003')) {
                    this.twitter.set('checked', true);
                }
                if (this._sendingSetting.yahooFlg && UserInfo.hasAuthz('F06003')) {
                    this.yahoo.set('checked', true);
                }
            }
            this.emergencyPush.set('checked', true);
        },

        /**
         * 全てのチャネルを未選択にする
         */
        uncheckAllChannels: function() {
            this.emergencyMail.set('checked',false);
            this.lalert.set('checked',false);
            this.prefectureMail.set('checked',false);
            this.twitter.set('checked',false);
            this.yahoo.set('checked',false);
            this.emergencyPush.set('checked', false);
        },

        /**
         * 緊急速報メールを追加する
         */
        addEmergencyMail: function(oneEmergencyMailText, i) {
            // +ボタンで追加した場合
            if (oneEmergencyMailText.type === 'click') {
                oneEmergencyMailText = '';
                oneEmergencyMailText.bodyText = '';
                this._emergencyMailNum += 1;
                i = this._emergencyMailNum;
            }
            this.createEmergencyMailElements(oneEmergencyMailText, i);

            // 緊急速報メールを分割できるのは3つまでとする
            if(i === this.EMERGENCY_MAIL_LIMIT) {
                domStyle.set(this.addEmergencyMailButton.domNode, 'display', 'none');
            }
        }
    });
});
