/**
 * 災害対応共有サイト組織選択用入力パーツ
 * @module app/view/form/DisInfoShareDestinationSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/form/TreeSelector',
    'app/model/DisInfoShareDestination'
], function(module, declare, TreeSelector, DisInfoShareDestination) {
    /**
     * 組織選択用パーツ。
     * @class DisInfoShareDestinationSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:app/view/form/DisInfoShareDestinationSelector~DisInfoShareDestinationSelector# */ {
        // 選択ダイアログのタイトル
        title: '本部選択',

        // ツリー・モデル
        model: DisInfoShareDestination,

        // ツリー全展開ボタンを非表示に設定
        noExpandAll: true,

        // ツリー全収束ボタンを非表示に設定
        noCollapseAll: true
    });
});

