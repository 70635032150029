/**
 * 組織を扱うモデル。
 * @module app/model/Item
 */
define([
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function(CacheStoreModel, CacheTreeRest) {
    /**
     * シングルトンを返す。
     */
    console.log('Sect.js');

    return new CacheStoreModel({
        store: new CacheTreeRest({
            target: '/api/organization/sects/sect4Tree'
        })
    });
});
