/**
 * 人的被害一覧ダイアログ用グリッド
 * @module app/damage/humanDamageReport/HumanDamageReportGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/date/locale',
    'dgrid/Selector',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function (module, declare, locale, Selector, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selector],
        /** @lends module:app/damage/humanDamageReportGrid~ProvideTemplatePage# */ {

            /**
             * 各列の定義
             * @type {Object[]}
             */
            baseClass: 'humanDamageReportGrid',
            noDataMessage: '',
            minRowsPerPage: Infinity,
            maxRowsPerPage: Infinity,

            collection: this.store,
            columns: [
                // ボタン
                helper.buttonColumn('update', '修正'),

                // 人的被害詳細ID - 番号
                helper.column('damageHumanId', '番号'),

                // 負傷程度
                helper.column('injuredLevel', '負傷程度', {
                    formatMap: {
                        '1': '軽傷',
                        '2': '重症',
                        '3': '行方不明',
                        '4': '死亡'
                    },
                    sortable: false
                }),

                // 年齢
                helper.column('age', '年齢'),

                // 性別
                helper.column('sex', '性別', {
                    formatMap: {
                        '0': '不明',
                        '1': '男',
                        '2': '女'
                    },
                    sortable: false
                }),

                // 職員負傷
                helper.column('empInjured', '職員負傷', {
                    formatMap: {
                        '0': 'なし',
                        '1': 'あり'
                    },
                    sortable: false
                }),

                // 消防・救急出場
                helper.column('rescueDsp', '消防・救急出場', {
                    formatMap: {
                        '0': '不明',
                        '1': 'あり',
                        '2': 'なし'
                    },
                    sortable: false
                }),

                // 警察出場
                helper.column('policeDsp', '警察出場', {
                    formatMap: {
                        '0': '不明',
                        '1': 'あり',
                        '2': 'なし'
                    },
                    sortable: false
                }),

                // 備考
                helper.column('comment', '備考'),
            ]
        });
});
