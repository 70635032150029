/**
* 被害状況 概況 閲覧画面用モジュール。
* @module app/damege/DamageReportAdminPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/DamageReportAdminViewPage.html',
    'idis/control/Router',
    'app/damage/damageReportAdmin/DamageReportAdminPage',
    // 以下、変数として受け取らないモジュール
], function (module, declare, template, Router, DamageReportAdminPage) {

    /**
     * 被害概要 閲覧ページ
     * @class DamageReportAdminViewPage
     * @extends module:app/damage/damageReportAdmin/DamageReportAdminPage~DamageReportAdminPage
     */
    return declare(module.id.replace(/\//g, '.'), DamageReportAdminPage,
        /** @lends module:app/damage/damageReportAdmin/DamageReportAdminViewPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--damage',

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
            },

            // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
            constructor: function () {
            },

            startup: function () {
                this.inherited(arguments);
            },

            /**
            *一覧ボタン押下時
            */
            onListButtonClick: function (evt) {
                // 被害状況画面へ遷移
                this._removeEvents();
                this.setFilterStore();
                Router.moveTo('report/view', { municipalityCd: evt.item.municipalityCd });
            },

            /**
             * 定時報告画面を表示
             */
            showScheduledReportPage: function () {
                this._removeEvents();
            },
            
            /**
            *一覧ボタン押下時
            */
            showReportListButtonClick: function (evt) {
                // 被害状況画面へ遷移
                this._removeEvents();
                this.setFilterStore();
                Router.moveTo('report/view');
            },

        });
});

