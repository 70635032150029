/**
 * 予測水位入力画面用モジュール。
 * @module app/river/FutureRiverLevelDetailDialog
 */
define([
    'module',
    'dojo/json',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom',
    'dojo/dom-style',
    'dojo/dom-class',
    'dojo/_base/array',
    'dojo/text!./templates/FutureRiverLevelDetailDialog.html',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    'idis/service/Requester',
    //'app/disaster/model/DisasterType',
    // 以下、変数から参照されないモジュール
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/Textarea',
    'dijit/form/CheckBox',
    'dijit/form/DateTextBox',
    'dijit/form/Select',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup',
    'idis/view/form/DateTimeInput',
    'dijit/Dialog'
    //'app/view/form/ParentDisasterSelector'
], function (module, json, declare, lang, dom, domStyle, domClass, array, template, InfoDialog, _PageBase, Loader, Router, UserInfo, IdisRest, DialogChain, Requester) {
    /**
     * 予測水位登録画面。
     * @class FutureRiverLevelDetailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/river/FutureRiverLevelDetailDialog~FutureRiverLevelDetailDialog# */ {
        // テンプレート文字列
        templateString: template,

        // タイムラインに設定する
        _floodActionPlanList: null,

        // タイムラインに設定する
        _earthActionPlanList: null,

        // タイムラインに設定する
        _peopleActionPlanList: null,

        // タイムラインに設定する
        _otherActionPlanList: null,

        // タイムラインに設定する
        _actionPlanList: null,

        // 観測所名
        _obsName: null,

        // 観測日
        _dateTimestamp: null,

        // テンプレートHTML内内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
        // 登録ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
        onSubmit: function() {
            try {
                var sendValue = this.form.get('value');
                // if (sendValue.riverLevelIn1hour === '' && sendValue.riverLevelIn2hour === '' && 
                //     sendValue.riverLevelIn3hour === ''){
                //     this.infoDialog = new InfoDialog({
                //         title : 'エラー',
                //         content : '予測水位が入力されていません'
                //     });
                //     this.infoDialog.show();
                //     this.infoDialog=null;
                // }
                // data-dojo-propsでの入力チェックと、画面独自の入力チェック
                if (this.form.validate() && this._validateCheck()) {
                    // 入力チェックが成功したらsendイベントを発行
                    // 実際の登録処理はサンプル画面側でsendイベントを監視しているメソッドが実施
                    var formData = {
                        'value' : sendValue,
                        'dateTimestamp' : this._dateTimestamp,
                        'obsName': this._obsName,
                        'userId' : UserInfo.getId()
                    };
                    //this.emit('send', {
                    //    // テンプレートHTML内でformに対し
                    //    // data-dojo-attach-point="form"を指定してthis.formとしてアクセス出来るようにしている
                    //    value: sendValue
                    //});
                    this.emit('send', {
                        // テンプレートHTML内でformに対し
                        // data-dojo-attach-point="form"を指定してthis.formとしてアクセス出来るようにしている
                        value: formData
                    });
                }else if(!this.form.isValid()) {
                    console.debug('無効な値が入力されています');
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : '無効な値が入力されています'
                       });
                    }
                    this.infoDialog.show();
                    this.infoDialog=null;
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                target: '/api/river/detail/bulk/'
            });

            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },
        startup: function () {

            this.inherited(arguments);
            //this.own(this.numberEditDialog);
        },

        _validateCheck: function() {
            var infoDialogMsg = null;

            // エラーメッセージ表示
            if(infoDialogMsg){
                infoDialogMsg += 'が入力されていません。';
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : infoDialogMsg
                });
                this.infoDialog.show();
                return false;
            }
            return true;
        }, 
        
        initDialog: function(value) {
            if (!!value) {
                this.obsInfo.innerHTML = value.obsName + '観測局' + '&nbsp;' + String(value.dateTimestamp);
                this._dateTimestamp = value.dateTimestamp;
                this._obsName = value.obsName;
                // 観測日時に対応した予測水位を取得する。
                Requester.get('/api/river/riverforecastLevel?observatoryId=' + value.obsId +
                '&dateTimestamp=' + this._dateTimestamp.replace('/','-')).then(
                    lang.hitch(this, function (data) {
                        this.riverLevelIn1hour.set('value', data.riverLevelIn1hour);
                        this.riverLevelIn2hour.set('value', data.riverLevelIn2hour);
                        this.riverLevelIn3hour.set('value', data.riverLevelIn3hour);
                    })
                );
            }
        }
    });
});
