/**
 * 水道局災害名選択ダイアログ画面用モジュール。
 * @module app/waterworks/selectors/IncidentSelectorDialog
 */
define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
    'dojo/topic',
    'dojo/text!./templates/IncidentSelectorDialog.html',
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog',
	'idis/store/IdisRest',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
	'app/waterworks/selectors/IncidentSelectorGrid'
], function(module, declare, lang, topic, template, DisasterInfo, InfoDialog, IdisRest, WidgetBase) {

    /**
     * 水道局災害名選択ダイアログ
     * @class IncidentSelectorDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends app/waterworks/selectors/IncidentSelectorDialog~IncidentSelectorDialog# */ {

        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'incident-selector',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        refreshed: 'app/disaster/view/DisasterAdminPage::refreshed',

        updated: 'app/disaster/view/DisasterAdminPage::updated',

        /**
         * コンストラクタ
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'incidentId',
                target: '/api/waterworks/incidentdialog'
            });

            // 災害名登録がPubされた際にグリッドを最新の情報に更新する
            topic.subscribe(this.refreshed, lang.hitch(this, function(disasterId) {
                console.log('Pub' + disasterId);
                this.refresh();
            }));

            // 災害名更新がPubされた際にグリッドを最新の情報に更新する
            topic.subscribe(this.updated, lang.hitch(this, function(disasterId) {
                console.log('Pub' + disasterId);
                this.refresh();
            }));
        },

        /**
         * DOM生成
         */
        buildRendering: function() {
            this.inherited(arguments);
            // グリッドを初期化
            this.initGrid();
        },

        /**
         * 画面生成完了
         */
        startup: function() {
            this.inherited(arguments);
        },

        /**
         * 画面を再表示
         */
        refresh: function() {
            this.container.resize();
            this.item = null;
            // 最新の災害情報を取得
            DisasterInfo.load().then(lang.hitch(this, function() {
                // グリッドを再表示
                this.grid.refresh();
            }));
        },

        /**
         * グリッド初期化
         */
        initGrid: function() {        
            this.grid.set('collection', this.store);

            // グリッドの行選択イベント
            this.grid.on('dgrid-select', lang.hitch(this, function(evt) {
                // 選択された行データを退避
                this.item = evt.rows[0].data;
            }));
        },

        /**
         * 水道局災害名選択実行
         */
        onSubmit: function() {
            if (!this.item) {
                InfoDialog.show('入力チェック', '水道局災害名が選択されていません');
                return false;
            }
            this.emit('update', {
                data: this.item
            });
        }
    });

});
