define([
    'module',
    'dojo/_base/declare',
    'dgrid/Selector',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
    // 'idis/view/grid/helper'
], function(module, declare,  Selector, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selector], {

        /**
         * セレクションモード
         */
        selectionMode: 'single',
        
        collection: this.store,

        rowClassName: function(item) {
            return item && item.newFlg ? 'is-new' : '';
        },

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            {field: 'updDate', label: '情報更新日時', 
            // sortable: false,
                formatter: function(value){
                    if(value) {
                        if(value.length > 17) {
                            value = value.substr(0, 16);
                        }
                        return value;
                    } else {
                        return '-';
                    }
                }
            },
            {field: 'locationInfo', label: '場所', 
                sortable: false,
                formatter: function(value){
                    var locationData = null;
                    if(value && value[0]) {
                        // Specteeから情報を取得する場合
                        locationData = value[0];
                    }else if (value && !value[0]) {
                        // お気に入り情報を取得する場合
                        locationData = value;
                    }
                    if(locationData) {
                        var address = '';
                        address =  locationData.prefecture ? address + locationData.prefecture : address;
                        address =  locationData.city ? address + locationData.city : address;
                        address =  locationData.town ? address + locationData.town : address;
                        return address;
                    } else {
                        return '-';
                    }
                }
            },
            helper.column('title', '内容', {
                sortable: false,
                formatter: function(value, item) {
                    var reportNo = '';
                    if(item.reportNo) {
                        reportNo = '[第' + item.reportNo + '報] ';
                    }
                        if(reportNo + value) {
                            return reportNo + value;
                        } else {
                            return '-';
                    }
                }
            })
        ]
    });
});
