const { set } = require("dojo/dom-attr");

/**
 * 被害種別選択用モジュール
 * @module app/formDisplay/damagetype/DamageGridTypeSelector
 */
define([
  'module',
  'dojo/_base/declare',
  'dojo/_base/lang',
  'dojo/_base/array',
  'dojo/topic',
  'dojo/text!./templates/DamageGridTypeSelector.html',
  'idis/service/Requester',
  'idis/view/Loader',
  'idis/view/_IdisWidgetBase',
  // 以下、変数として受け取らないモジュール
  'dijit/Dialog',
  './DamageGridTypeDialog'
], function (module, declare, lang, array, topic, template, Requester, Loader, WidgetBase) {

  /**
   * 被害種別選択用モジュール
   * @class DamageGridTypeSelector
   * @extends module:idis/view/page/_PageBase~_PageBase
   */
  return declare(module.id.replace(/\//g, '.'), WidgetBase,
    /** @lends app/formDisplay/damagetype/DamageGridTypeSelector~DamageGridTypeSelector# */ {

      // テンプレート文字列
      templateString: template,

      // ルートに付与されるCSS
      baseClass: 'damagetype-selector',

      _damageTypeList: [],
      /**
       * コンストラクタ
       */
      constructor: function () {
        this.inherited(arguments);
        this.getDamageTypeList();
      },

      /**
       * DOM生成
       */
      buildRendering: function () {
        this.inherited(arguments);
        this.damageType.innerText = '未選択';
      },

      /**
       * 画面生成完了
       */
      startup: function () {
        this.inherited(arguments);
        // このウィジェット消滅時にダイアログも削除
        this.own(this.dialog);

        // ダイアログ中のページウィジェットを取得
        var page = this.dialog.getChildren()[0];
        this.changeFlg = false;

        // ダイアログ側でOKボタンが押された際のイベント
        page.on('update', lang.hitch(this, function (evt) {
          // 親被害変更
          this.change(evt.data);
          console.log(evt);

          // ダイアログを非表示にする
          this.dialog.hide();
          // ダイアログの画面を再表示する
          page.refresh();
        }));
      },

      /**
       * 災害変更ダイアログを表示する
       */
      showDialog: function () {
        // ダイアログを表示
        this.dialog.show();

        // ダイアログ中のページウィジェットを取得
        var page = this.dialog.getChildren()[0];
        // ダイアログの画面を再表示する
        page.refresh();
      },

      /**
       * 選択されている親災害IDの選択を解除する。
       */
      release: function () {
        this.damageType.innerText = '未選択';
        //        this.subDamageType.innerText = null;
        // 選択されている親災害IDを解除する。
        topic.publish(module.id + '::released', '');
      },

      showClearBtn: function (visible) {
        this.clearButton.set('style', { 'display': visible ? '' : 'none' });
      },

      showSelectBtn: function (visible) {
        this.selectButton.set('style', { 'display': visible ? '' : 'none' });
      },
      /**
       * 種別を変更する
       */
      change: function (data) {

        var mainLabel = [];
        array.forEach(data.mainTypeIds, lang.hitch(this, function (id) {
          mainLabel.push(this._damageTypeList[id]);
        }));

        this.damageType.innerText = mainLabel.join('、');
        this.changeFlg = true;

        // 選択した種別情報を設定する。
        topic.publish(module.id + '::selected', data);
      },

      /**
       * 被害種別をセットする
       */
      setDamageType: function (damageTypeList) {
        var damageTypeNameList = [];
        for (var i = 0; i < damageTypeList.length; i++) {
          var key = damageTypeList[i];
          if (!this.innerDialog.grid.checkboxes[key]) {
            this.innerDialog.grid.defaultKeyList.push(key);
          } else {
            this.innerDialog.grid.checkboxes[key].set('checked', true);
          }
          if (this.innerDialog.grid.mainTypeIds.indexOf(key) === -1) {
            this.innerDialog.grid.mainTypeIds.push(key);
          }
          damageTypeNameList[i] = this._damageTypeList[key];
        }
        this.damageType.innerText = damageTypeNameList.join('、');
      },

      resetDamageType: function () {
        Object.keys(this.innerDialog.grid.checkboxes)
          .forEach(lang.hitch(this, function (key) {
            var chkbox = this.innerDialog.grid.checkboxes[key];
            chkbox.set('checked', false);
          }));
      },

      /**
       * 被害の種類のセレクタ一覧を生成
       */
      getDamageTypeList: function () {
        Loader.wait(Requester.get('api/code/list?type=DAMAGE_TYPE')
          .then(lang.hitch(this, function (data) {
            console.log(data);
            data.forEach(lang.hitch(this, function (item) {
              this._damageTypeList[item.key] = item.value;
            }));
          }))
        );
      }
    });

});
