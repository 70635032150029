/**
 * 配信ポータル画面用モジュール。
 * @module app/sending/SendingHistoryPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/SendingHistoryPage.html',
    'idis/control/Router',
    'idis/store/IdisRest',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'app/model/DisasterInfo',
    // 以下、変数として受け取らないモジュール
    'dijit/form/CheckBox',
    'idis/view/form/DateTimeInput',
    'app/sendingHistory/SendingHistoryGrid',
    'app/view/form/ManageRegionMunicipalitySelector'
], function (module, declare, lang, template, Router, IdisRest, DialogChain,InfoDialog, _PageBase, DisasterInfo) {
    /**
     * 検索条件格納用オブジェクト
     * @type {Object}
     */
    var SendingFilterStore = null;

    /**
     * 配信情報画面。
     * @class SendingPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/sending/SendingHistoryPage~SendingHistoryPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--sending',

            /**
             * データ格納用オブジェクト
             * @type {module:dstore/Store}
             */
            store: null,

            // [template内のcheckBox, サーバで受ける検索条件の変数名, サーバでのtrue時の値, サーバでのfalse時の値]
            _searchCriteriaCheckBox: [],

            constructor: function () {
                // データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'gridId',
                    target: '/api/sendingHisotry',
                    sortParam: 'sort',
                    rangeStartParam: 'offset',
                    rangeCountParam: 'count'
                });
                this.chain = DialogChain.get(this);

                // 災害IDを取得する。
                this._disasterId = DisasterInfo.getDisasterId();
            },

            buildRendering: function () {
                this.inherited(arguments);

                // [template内のcheckBox, サーバで受ける検索条件の変数名, サーバでのtrue時の値, サーバでのfalse時の値]
                this._searchCriteriaCheckBox = [
                    { obj: this.disasterPrevention, param: 'disasterPrevention', t: '2', f: null },    // 本部設置状況
                    { obj: this.shelter, param: 'shelter', t: '3', f: null },                          // 避難所情報
                    { obj: this.evac, param: 'evac', t: '4', f: null },                                // 避難情報
                    { obj: this.damage, param: 'damage', t: '5', f: null },                            // 被害情報
                    { obj: this.damageDetail, param: 'damageDetail', t: '6', f: null },                // 被害詳細情報
                    { obj: this.chronology, param: 'chronology', t: '7', f: null },                    // クロノロジー情報
                    { obj: this.information, param: 'information', t: '9', f: null },                  // お知らせ情報

                    { obj: this.success, param: 'success', t: 'true', f: 'false' }, // 配信完了
                    { obj: this.wait, param: 'pending', t: 'true', f: 'false' }, // 配信待ち
                    { obj: this.fail, param: 'fail', t: 'true', f: 'false' }  // 失敗・一部失敗
                ];
            },

            startup: function () {
                this.inherited(arguments);
                this._loadFilterStore();
                this._initGrid();

            },

            /**
             * グリッドを初期化する。
             */
            _initGrid: function () {
                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.sendingGrid.on('detailButtonClick', lang.hitch(this, function (evt) {
                    this.onDetailButtonClick(evt.item);
                }));

                this._updateGridQuery();
            },

            /**
             * 検索
             */
            onSubmit: function () {
                try {
                    if (this.form.validate()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this._updateGridQuery();
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * 検索パラメーターの設定
             */
            _updateGridQuery: function () {
                var filter = new this.store.Filter();

                // 災害IDはコンストラクタで存在チェックをしているため常にfilterにセット
                filter = filter.eq('disasterId', this._disasterId);

                // 検索条件をセット
                if (this._isSearch()) {
                    for (var i = 0; i < this._searchCriteriaCheckBox.length; i++) {
                        if (this._searchCriteriaCheckBox[i].obj.checked) {
                            filter = filter.eq(this._searchCriteriaCheckBox[i].param,
                                this._searchCriteriaCheckBox[i].t);
                        } else {
                            if (this._searchCriteriaCheckBox[i].f !== null) {
                                filter = filter.eq(this._searchCriteriaCheckBox[i].param,
                                    this._searchCriteriaCheckBox[i].f);
                            }
                        }
                    }

                    var value = this.form.get('value');
                    var searchCriteriaTimestamp = [['sendingTimestampFrom', value.sendTimestampFrom],
                    ['sendingTimestampTo', value.sendTimestampTo]];
                    for (i = 0; i < searchCriteriaTimestamp.length; i++) {
                        if (searchCriteriaTimestamp[i][1]) {
                            var timestamp = new Date(searchCriteriaTimestamp[i][1]).getTime();
                            filter = filter.eq(searchCriteriaTimestamp[i][0], timestamp);
                        } else {
                            filter = filter.eq(searchCriteriaTimestamp[i][0], 0);
                        }
                    }

                    var collection = this.store.filter(filter);
                    this.sendingGrid.set('collection', collection);
                } else {
                    InfoDialog.show('入力エラー', '検索条件を設定してください。');
                    return false;
                }
            },

            /**
             * 検索条件が一つでも設定されていればtrue
             */
            _isSearch: function () {
                for (var i = 0; i < this._searchCriteriaCheckBox.length; i++) {
                    if (this._searchCriteriaCheckBox[i].obj.checked) {
                        return true;
                    }
                }

                var value = this.form.get('value');
                var timestamp = [value.sendTimestampFrom,
                value.sendTimestampTo];
                for (i = 0; i < timestamp.length; i++) {
                    if (timestamp[i]) {
                        return true;
                    }
                }
                return false;
            },

            /**
             * 検索条件を保管する
             */
            saveFilterStore: function () {
                var value = this.form.get('value');
                SendingFilterStore = {
                    disasterPrevention: value.disasterPrevention,
                    shelter: value.shelter,
                    evac: value.evac,
                    damage: value.damage,
                    damageDetail: value.damageDetail,
                    chronology: value.chronology,
                    information: value.information,
                    success: value.success,
                    wait: value.wait,
                    fail: value.fail,
                    sendTimestampFrom: value.sendTimestampFrom,
                    sendTimestampTo: value.sendTimestampTo,
                };
            },

            /**
             * 保管した検索条件をフォームにセットする
             */
            _loadFilterStore: function () {
                //保管されていれば値をセット
                if (SendingFilterStore) {
                    this.disasterPrevention.set('value', SendingFilterStore.disasterPrevention);
                    this.shelter.set('value', SendingFilterStore.shelter);
                    this.evac.set('value', SendingFilterStore.evac);
                    this.damage.set('value', SendingFilterStore.damage);
                    this.damageDetail.set('value', SendingFilterStore.damageDetail);
                    this.chronology.set('value', SendingFilterStore.chronology);
                    this.information.set('value', SendingFilterStore.information);

                    this.success.set('value', SendingFilterStore.success);
                    this.wait.set('value', SendingFilterStore.wait);
                    this.fail.set('value', SendingFilterStore.fail);
                    this.sendTimestampFrom.set('value', SendingFilterStore.sendTimestampFrom);
                    this.sendTimestampTo.set('value', SendingFilterStore.sendTimestampTo);

                    this.form.set('value', SendingFilterStore);
                    SendingFilterStore = null;
                }
            },

            /**
             * 新規登録画面を表示
             */
            showRegisterPage: function () {
                //検索条件の保管
                this.saveFilterStore();
                //新規画面に移動
                Router.moveTo('sendingHistory/register', { emergency: '0' });
            },

            /**
             * 詳細画面を表示
             */
            onDetailButtonClick: function (object) {
                //検索条件の保管
                this.saveFilterStore();
                // 配信情報詳細画面へ遷移
                Router.moveTo('sendingHistory/detail', { sendingId: object.sendingId, twitterId: object.sendingTwitterId });
            }

        });
});
