/**
* 体制概況一覧用モジュール。
* @module app/disasterprevention/view/DisasterPreventionPrefListGrid
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    './DPColumn'
    // 以下、変数として受け取らないモジュール
], function(module, declare, lang, IdisGrid, helper, DPColumn) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
//            DPColumn.areaName('道・振興局'),
            DPColumn.status,
            DPColumn.deploymentPrefecture,
            DPColumn.lastReportTimestamp,
            helper.buttonColumn('detail', '一覧')
        ]
     });
 });
