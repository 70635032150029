/**
* 体制状況・報告概況画面用モジュール。
* @module app/disasterprevention/view/DisasterPreventionListPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/text!./templates/DisasterPreventionListPage.html',
    'idis/model/UserInfo',
    'idis/control/Router',
    'idis/control/Locator',
    'idis/store/IdisRest',
    'idis/service/Requester',
    './_DisasterPreventionPageBase',
    // 以下、変数として受け取らないモジュール
    'dijit/layout/BorderContainer',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'idis/view/form/DateInput',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'app/view/form/DisasterSelector',
    'app/view/form/LayerDirectorySelector',
    'app/view/form/OrganizationSelector',
    'app/provide/ProvidePreventionRegisterDialog',
    './model/DisasterPreventStatus',
    './DisasterPreventionStatusGrid',
    './DisasterPreventionRegisterPage',
    './DisasterPreventionListGrid',
    './DisasterPreventionFormDialog'
], function(module, declare, lang, json, template, UserInfo, Router, Locator, IdisRest, Requester, _PageBase) {

    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/view/page/DisasterAdmintPage~DisasterAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--disasterprevention',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        empStore: null,

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // グリッドを初期化する
            this.initGrid();
        },


        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                target: '/api/disasterPrevention/list'
            });
            // 閲覧モードフラグをセット
            this._viewMode = Locator.getQuery().viewMode || '0';
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.grid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.moveDetailPage(evt.item);
            }));
            
            this.updateGridQuery();

            // 新規登録ボタンの表示制御
            this.checkRegButtonShow();
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function() {
            var value = this.form.get('value');
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            filter = filter.eq('disasterId', this._disasterId);
            // 報告時点
            if (value.reportDateTime) {
                filter = filter.eq('reportDateTime', value.reportDateTime.getTime());
            }
            // すべての市町村を表示が選択されていない場合、体制をとっている市町村のみを検索
            if (value.allCityFlg.length){
                filter = filter.eq('statusFlg', 0);
            }else{
                filter = filter.eq('statusFlg', 1);
            }
            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);

            // 新規登録ボタンの表示制御
            this.checkRegButtonShow();

            // collection.fetch().then(lang.hitch(this, function(data) {
            // var noRecordFlg = true;
            //     if(data.totalLength !== 0){
            //         array.forEach(data, function(row) {
            //             if(row.municipalityCd === UserInfo.getMunicipalityCd() &&
            //             row.statusName !== null) {
            //                 // データがあれば新規登録ボタンを非表示にする
            //                 noRecordFlg = false;
            //             }
            //         });
            //     }
            //     this._noRecordFlg = noRecordFlg;
            //     this.changeButton();
            // }));
        },

        /**
         * 検索ボタンが押されたときに呼び出される。
         * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
         * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
         */
        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },

        /**
         * 一覧画面を表示する。
         * @param {Object} item 参照する行のデータ
         */
        moveDetailPage: function(item) {
            // ダイアログの最初の子要素が詳細画面
            // ※大阪市の組織の体制は、区市町村コードで区別できないため、
            //   便宜的にmunicipalityCdに組織コードを設定している。
            //   municipalityCdが組織コードの場合は、サーバー側で区市町村コードを逆引きして再設定する。
            Router.moveTo('disasterprevention/status', 
                {municipalityCd: item.municipalityCd, dpDeptCd: item.dpDeptCd, viewMode : this._viewMode});
        },

        /**
         * 職員参集管理画面へ遷移
         */
        moveToEmpGatherStatus: function(){
            Router.moveTo('employgathering', {viewMode : this._viewMode});
        },

        /**
        * 県と市町村の概況一覧を切り替える。
        */
        moveToPrefListPage: function(evt) {
            evt.preventDefault();
            Router.moveTo('disasterprevention/pref');
        },
        /**
        * 新規登録ボタンの表示制御
        */
        checkRegButtonShow: function() {
            // 自組織のデータがあれば（体制設定済の場合）新規登録ボタンを非表示にする
            var municipalityCd = UserInfo.getMunicipalityCd();
            var dpDeptCd = UserInfo.getOrganization().deptCd;

            Requester.get('/api/disasterPrevention?disasterId=' + this._disasterId + 
                '&municipalityCd=' + municipalityCd + '&dpDeptCd=' + dpDeptCd + '&pastReportMode=false')
            .then(lang.hitch(this, function(data) {
                // データがあれば新規登録ボタンを非表示にする
                if(data && data.items.length !== 0){
                    // this._noRecordFlg = flg;
                    this._noRecordFlg = false;
                } else {
                    // データがなければ新規登録ボタンを表示する
                    this._noRecordFlg = true;
                }
                this.changeRegButton();
            }));
        }
    });
});
