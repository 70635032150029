/**
 * 施設管理 ファイル読み込みダイアログ用モジュール。
 * @module app/chat/ChatUserFileUploadDialog
 */
define([
    'module',
    'app/model/DisasterInfo',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/ChatUserFileUploadDialog.html',
    'dojo/request/iframe',
    'dstore/RequestMemory',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    // 以下、変数で受けないモジュール
    'dojox/form/Uploader',
    'dijit/form/Form',
    'idis/view/form/Button',
    'app/view/page/ErrorMessageDialogForXoblos'
], function(module, DisasterInfo, array, declare, lang, template, iframe, RequestMemory, Router, Requester,
  DialogChain, InfoDialog, _PageBase, Loader) {
    /**
     * 施設管理ファイルアップロードダイアログ
     * @class ChatUserFileUploadDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,{
        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page--safety-dialog',

        _fileId: null,

        infoDialog : null,

        _disasterId: null,

        _chatGroupId: null,

        CHAT_USER_REPORT_REQID: 'CHAT_USER_LIST_IN',

        constructor: function() {
          // 入力帳票の各種パスを取得する。
          this.storepath = new RequestMemory({
              idProperty: 'reqid',
              target: '/data/report/xoblos-config.json'
          });
          // 連鎖ダイアログを登録
          this.chain = DialogChain.get(this);
          // 災害IDを取得
          this._disasterId = DisasterInfo.getDisasterId();
          if(!this._disasterId) {
              this._disasterId = 1;
              console.warn('災害IDが設定されていません。');
          }
        },

        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.errorMessageDialogForXoblos);
        },

        /**
         * ダイアログを初期化する。
         */
        initDialog: function(id) {
            this.form.reset();
            this._chatGroupId = id;

        },

        /**
         * ファイル読み込み実行
         */
        onSubmit: function() {
          if (!this.validate()) {
              return false;
          }
          
          var reqid = this.CHAT_USER_REPORT_REQID;
          var reqParams = {};
          this.storepath.fetch().then(lang.hitch(this, function(data) {
              data.some(function(data) {
                  if (reqid === data.reqid) {
                      reqParams = data;
                      return true; //break;
                  }
              });
          })).then(lang.hitch(this, function() {
              // ファイルをサーバーにアップロードするAPIのURL
              var url = '/api/xoblos/uploadReport' +
                      '?reqid=' + reqid +
                      '&disid=' + this._disasterId +
                      '&path=' + reqParams.pathweb;

              iframe.post(url, {
                  form: this.form.id,
                  handleAs: 'json'
              }).then(lang.hitch(this, function(ret) {
                  // アップロードしたファイルパスとエラーメッセージjson、
                  // エラー箇所を強調表示したファイルの出力先を指定してxoBlosをコールする。
                  var promise = Requester.post('/api/xoblos/upload',{
                      data: {
                          reqid: reqid,
                          disid: this._disasterId,
                          filename: ret.fileName
                      }
                  }).then(lang.hitch(this, function(data) {
                      // ResultCode=0以外（xoBlosエラー）の場合にはエラーダイアログを表示する。
                      if (data.ResultCode !== 0){
                          var err = new Error('xoblos failed');
                          err.data = data;
                          throw err;
                      } else {
                          // xoblosエラーが出なかった場合は、返ってきたJSONデータをJavaに転送し、登録処理を行う
                          var regapi = reqParams.regapi;
                          return  Requester.post(regapi, {
                              data:{
                                  // 返ってきたJSONにchatGroupIdをセット
                                  chatGroupId: this._chatGroupId,
                                  // ResultCodeが0の場合は、帳票に含まれる施設情報の一覧がレスポンスのdataに入っている。
                                  items: data.data
                              }
                          });
                      }
                  })).then(lang.hitch(this, function(data) {
                        // 成功した場合、完了ダイアログを表示する。
                        if (data.length === 0 ) {
                            this.chain.infoComplete(function() {
                                // 確認ダイアログを閉じる
                                this.chain.hide();
                                // 親のダイアログを閉じる。
                                this.getParent().hide();
                                this.attachFile.reset();
                                this.emit('upload');
                            });
                        } else {
                            var message = '';
                            message += '下記の利用者は、ログイン中のユーザと異なる所属の利用者であるため、登録・更新できませんでした。<br><br>';
                            message += '<div style="overflow-y: scroll; width: 600px; max-height:100px; "><table style="width:100%; margin-left: auto;margin-right: auto; border-collapse: collapse; ">';
                            message += '<tr><th style="text-align:center"></th><tr>';
                            message += '<tr><td>利用者ID：' ;
                            var dataIndex = 0;
                            array.forEach(data, function(data){
                                if(dataIndex === 0) {
                                    message += data;
                                } else {
                                    message += ',  ' + data;
                                }
                                dataIndex++;
                            });
                            message += '</td></tr>';
                            message += '</table></div>';
                            var title = '完了';
                            // ダイアログにメッセージを表示
                            this.chain.info(message, title, function(){
                                // 確認ダイアログを閉じる
                                this.chain.hide();
                                // 親のダイアログを閉じる。
                                this.getParent().hide();
                                this.emit('upload');
                            });
                        }
                  }), lang.hitch(this, function(error) {

                        // 失敗の場合、引数にデータがあれば、xoBlos用のエラーダイアログを表示する。
                        if(error.data) {
                            // エラーダイアログに結果コードを渡す
                            var errPage = this.errorMessageDialogForXoblos.getChildren()[0];
                            errPage.initErrorMessageDialogForXoblos(
                                error.data.ResultCode,
                                reqParams.errpathweb + this._disasterId + '/' + ret.fileName);
                                this.errorMessageDialogForXoblos.show();
                                this.attachFile.reset();
                        } else {
                            // 引数にエラーがなければ、通常のエラーダイアログを表示する。
                            this.chain.infoError(error, function() {
                                this.chain.hide();
                                // 親のダイアログを閉じる。
                                this.getParent().hide();
                                this.attachFile.reset();
                                Router.moveTo('chatUser');
                            });
                        }
                  }));
                  // ローダーの表示
                  Loader.wait(promise);
              }));
          }));
        },        

        /**
         * 入力チェック
         */
        validate: function() {
          //ファイルが空の場合は処理を中断
          if(this.attachFile.getFileList().length === 0) {
            InfoDialog.show('入力チェック', 'ファイルが選択されていません');
            return false;
          }
          return true;
        },

        /**
         *ファイル読み込みで呼ばれる
         **/
        loadFile: function() {
             //ファイルが空の場合は処理を中断
             if(this.attachFile._files.length === 0) {
                 return;
             }

             // エクセルファイル以外の場合はエラー
             var file = this.attachFile._files[0];
             if(file.name.indexOf('.xls') === -1 && file.name.indexOf('.xlsx') === -1 &&
             file.name.indexOf('.xlsm') === -1) {
                 if (!this.infoDialog) {
                     this.infoDialog = new InfoDialog({
                         title : 'エラー',
                         content : 'エクセルファイルを選択してください。'
                     });
                 }
                 this.infoDialog.show();
                 this.infoDialog=null;
                 this.attachFile.reset();
                 return;
             }

             var form = new FormData();
             form.append('report', this.attachFile._files[0]);

            // ファイル名をyyyymmddhhssに設定。このファイル名はimport用APIをｊコールするときにも使う。
            var now = new Date();
            this._fileId = now.getFullYear() + this.padZero(now.getMonth() + 1) +
        		this.padZero(now.getDate()) + this.padZero(now.getHours()) +
        		this.padZero(now.getMinutes()) + this.padZero(now.getSeconds());

             // ファイルがonloadされたときにサーバーに一時保存する
             var promise = iframe.post('/api/upload/uploadFile?fileId=' +
            		 this._fileId , {
                 form: this.form.id,
                 handleAs: 'json'
             });
             //ローダーの表示
             Loader.wait(promise);
         },

        // 日付をゼロパディング
        padZero : function(num){
        	var result;
        	if (num < 10) {
        		result = '0' + num;
        	} else {
        		result = '' + num;
        	}
        	return result;
        }
    });
});
