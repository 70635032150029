/**
 * 参集メール一覧グリッド。
 * @module app/convocation/view/mail/ConvoHistoryGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/util/DateUtils',
    'idis/view/grid/helper'
], function(module, declare, lang, IdisGrid, DateUtils, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid,{
        /**
         * 各列の定義
         * @type {Object[]}
         */

        collection: this.store,
        columns: [
            helper.buttonColumn('detail', '詳細'),
            { field: 'position', label: '拠点', sortable: false },
            { field: 'mobilizationType', label: '動員区分', sortable: false,
                formatter: lang.hitch(this, function(item){
                    if(item === '1') {
                        return '１号';
                    } else if (item === '2'){
                        return '２号';
                    } else if (item === '3') {
                        return '３号';
                    } else if (item === '4') {
                        return '４号';
                    } else if (item === '5') {
                        return '５号';
                    } else if (item === '0') {
                        return '情報連絡体制';
                    }
                })
            },
            { field: 'total', label: '合計', sortable: false },
            { field: 'rallyAffiliation', label: '参集職員（所属）', sortable: false },
            { field: 'workAffilication', label: '勤務職員（所属）', sortable: false },
            { field: 'rallyOtherAffiliation', label: '参集職員（他所属）', sortable: false },
            { field: 'historyContent', label: '備考', sortable: false },
            { field: 'reportTimeStamp',label: '報告日時', sortable: false,
            formatter: function(updTimestamp){
                return updTimestamp ? DateUtils.format(updTimestamp) : '-';
            }}
        ]
    });
});
