/**
 * 水道局情報閲覧 モジュール
 * @module app/waterworks/WaterworksPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/WaterworksPage.html',
    'idis/control/Router',
    'idis/store/IdisRest',
    'app/waterworks/_WaterworksBase',
    'idis/model/UserInfo',
    'app/config',
    'idis/consts/USER_TYPE',
    'dojo/dom-style',
    'dojo/json',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'dijit/form/ValidationTextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'app/view/form/CustomMunicipalityMultiSelectorNoPref',
    './DansuiDetailDialog',
    './JosuihigaiDetailDialog',    
    './SohaisuihigaiDetailDialog',    
    './ChoshaDetailDialog', 
    './ShiminDetailDialog',
    './OkyuDetailDialog',
    'app/waterworks/grid/DansuiGrid',    
    'app/waterworks/grid/JosuihigaiGrid',
    'app/waterworks/grid/SohaisuihigaiGrid',
    'app/waterworks/grid/ChoshaGrid',
    'app/waterworks/grid/ShiminGrid',
    'app/waterworks/grid/OkyuGrid',
], function(module,  declare, lang, template, Router, IdisRest, _WaterworksBase, 
    UserInfo, config, UserType, domStyle, JSON) {

    /**
    * 検索条件格納用オブジェクト
    * @type {Object}
    */
    var waterworksFilterStore = {
        dansui: {},
        josuihigai: {},
        sohaisuihigai: {},
        chosha: {},
        shimin: {},
        okyu: {}
    };

    /**
     * 水道局受信情報管理画面。
     * @class waterworksPage
    * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _WaterworksBase,
    /** @lends module:app/waterworks/_WaterworksBase~_WaterworksBase# */ {
        // テンプレート文字列
        templateString: template,
        
        /**
        * データ格納用オブジェクト
        * @type {module:dstore/Store}
        */
        dansuiStore: null,
        josuihigaiStore: null,
        sohaisuihigaiStore: null,
        choshaStore: null,
        shiminStore: null,
        okyuStore: null,
        
        /** ユーザの持つ区市町村コード */
        _municipalityCd: null,

        /** form用 */
        targetForm: null, 

        /** 水道局災害選択肢用 */
        targetIncidentSelector: null,

        /** 大阪市災害選択肢用 */
        targetdisasterSelector: null,

        /** グリッド用 */
        targetGridQuery: null,

        /** 検索エリア開閉用 */
        targetBorderContainer: null,

        /** フィルター項目の初期化 */
        initFilterItem: {
            /** 減断水情報フィルター項目 */
            dansui: {
                incidentNm: null,
                disasterNm: null,
                gysNm: null,
                hasseinichijiFrom: null,
                hasseinichijiTo: null,
                fukkyunichijiFrom: null,
                fukkyunichijiTo: null,
                openingDetailFilter: null
            },
    
            /** 浄水被害情報用フィルター項目 */
            josuihigai: {
                incidentNm: null,
                disasterNm: null,
                damageFlg: null,
                entryDatetimeFrom: null,
                entryDatetimeTo: null,
                updateDatetimeFrom: null,
                updateDatetimeTo: null,
                openingDetailFilter: null
            },
    
            /** 送・配水場被害情報用フィルター項目 */
            sohaisuihigai: {
                incidentNm: null,
                disasterNm: null,
                damageFlg: null,
                entryDatetimeFrom: null,
                entryDatetimeTo: null,
                updateDatetimeFrom: null,
                updateDatetimeTo: null,
                openingDetailFilter: null
            },
    
            /** 庁舎・事業所被害情報用フィルター項目 */
            chosha: {
                incidentNm: null,
                disasterNm: null,
                higaikibo: null,
                shinkokudo: null,
                hasseinichijiFrom: null,
                hasseinichijiTo: null,
                entryDatetimeFrom: null,
                entryDatetimeTo: null,
                updateDatetimeFrom: null,
                updateDatetimeTo: null,
                openingDetailFilter: null
            },
    
            /** 市民断水情報用フィルター項目 */
            shimin: {
                incidentNm: null,
                disasterNm: null,
                gysNm: null,
                gendansuistatusshimin: null,
                entryDatetimeFrom: null,
                entryDatetimeTo: null,
                updateDatetimeFrom: null,
                updateDatetimeTo: null,
                openingDetailFilter: null
            },
    
            /** 応急給水情報用フィルター項目 */
            okyu: {
                incidentNm: null,
                disasterNm: null,
                gysNm: null,
                facilityKind: null,
                okyuhitsuyo: null,
                okyutaio: null,
                entryDatetimeFrom: null,
                entryDatetimeTo: null,
                updateDatetimeFrom: null,
                updateDatetimeTo: null,
                openingDetailFilter: null
            }

        },

        /**詳細検索エリアOpen.Close用 */
        openingDetailFilter: {
            dansui: false,
            josuihigai: false,
            sohaisuihigai: false,
            chosha: false,
            shimin: false,
            okyu: false            
        },

        /** 現在選択されているタブ情報 */
        tabName: null,

        /**  */
        changeFlag:{
            incident: false,
            disaster: false,
        },

        constructor: function() {

            // form用のストアを生成する。
            this.dansuiStore = new IdisRest({
                idProperty: 'bsDansuiId',
                target: '/api/waterworks/dansui',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            this.josuihigaiStore = new IdisRest({
                idProperty: 'bsJosuihigaiId',
                target: '/api/waterworks/josuihigai',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            this.sohaisuihigaiStore = new IdisRest({
                idProperty: 'bsSohaisuihigaiId',
                target: '/api/waterworks/sohaisuihigai',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            this.choshaStore = new IdisRest({
                idProperty: 'bsChosyaId',
                target: '/api/waterworks/chosha',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            this.shiminStore = new IdisRest({
                idProperty: 'bsShiminId',
                target: '/api/waterworks/shimin',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            this.okyuStore = new IdisRest({
                idProperty: 'bsOkyuId',
                target: '/api/waterworks/okyu',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });

        },

        /**
         * 画面のDOM要素を組み立てる際に呼ばれる
         */
        buildRendering: function(){
            this.inherited(arguments);
            
            // updateGridQuery連想配列
            this.targetGridQuery = {
                dansui: "dansuiUpdateGridQuery",
                josuihigai: "josuihigaiUpdateGridQuery",
                sohaisuihigai: "sohaisuihigaiUpdateGridQuery",
                chosha: "choshaUpdateGridQuery",
                shimin: "shiminUpdateGridQuery",
                okyu: "okyuUpdateGridQuery"
            };

            // 水道局災害名選択の連想配列
            this.targetIncidentSelector = {
                dansui: this.dansuiIncidentList,
                josuihigai: this.josuihigaiIncidentList,
                sohaisuihigai: this.sohaisuihigaiIncidentList,
                chosha: this.choshaIncidentList,
                shimin: this.shiminIncidentList,
                okyu: this.okyuIncidentList
            };

            // 大阪市災害名選択の連想配列
            this.targetdisasterSelector = {
                dansui: this.dansuiDisastertList,
                josuihigai: this.josuihigaiDisastertList,
                sohaisuihigai: this.sohaisuihigaiDisastertList,
                chosha: this.choshaDisastertList,
                shimin: this.shiminDisastertList,
                okyu: this.okyuDisastertList
            };

            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.dansuiDetailDialog);
            this.own(this.josuihigaiDetailDialog);
            this.own(this.sohaisuihigaiDetailDialog);
            this.own(this.choshaDetailDialog);
            this.own(this.shiminDetailDialog);
            this.own(this.okyuDetailDialog);
            
            this.own(this.dansuiGysCd);
            this.own(this.shiminGysCd);
            this.own(this.okyuGysCd);
        },

        // HTML上にウィジェットが設置されてから呼ばれる
        startup: function() {
            this.inherited(arguments);
            
            // form連想配列
            this.targetForm = {
                dansui: this.formDansui,
                josuihigai: this.formJosuihigai,
                sohaisuihigai: this.formSohaisuihigai,
                chosha: this.formChosha,
                shimin: this.formShimin,
                okyu: this.formOkyu
            };

            // 初期選択する市町村情報を取得
            this.initMunicipalityCd();

            // 画面保持情報の設定
            for (var key in this.targetGridQuery) {
                if(!this.targetGridQuery.hasOwnProperty(key)){
                    continue;
                }
                // 初期選択する市町村情報を設定（初回時のみ）
                if(!Object.keys(waterworksFilterStore[key]).length) {
                    this.initMunicipalitySelector(key);
                }
                this.setFilterData(key);
            }

            // 初期タブ名設定
            this.tabName = 'dansui';

            // グリッドを初期化する
            this.initGrid();
            
            // フォームを初期化する
            this.initForm();
            
        },


        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.dansuiGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showDetailPage(evt.item, 'dansui');
            }));
            this.josuihigaiGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showDetailPage(evt.item, 'josuihigai');
            }));
            this.sohaisuihigaiGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showDetailPage(evt.item, 'sohaisuihigai');
            }));
            this.choshaGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showDetailPage(evt.item, 'chosha');
            }));
            this.shiminGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showDetailPage(evt.item, 'shimin');
            }));
            this.okyuGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showDetailPage(evt.item, 'okyu');
            }));

        },

        /**
         * 一覧画面に初期表示する市町村のコードを取得する。
         *
         */
        initMunicipalityCd: function () {

            if (UserInfo.getUserType() === UserType.MUNICIPALITY) {
                // 市町ユーザの場合、自分の市町を設定
                this._municipalityCd = UserInfo.getMunicipalityCd();
            } else if (UserInfo.getUserType() === UserType.OTHER_ORGAN) {
                // 市町ユーザの場合、自分の市町を設定
                this._municipalityCd = UserInfo.getMunicipalityCd();
            }
            if (this._municipalityCd === config.municInfo.cityMunicCd) {
                this._municipalityCd = '';
            }

        },

        /**
         * 一覧画面に初期表示する市町村のコードを設定する。
         *
         */
        initMunicipalitySelector: function (key) {
            if (!this._municipalityCd) {
                return;
            }
            //市町セレクタがある場合、初期表示を設定する。
            if (key === 'dansui') {
                this.dansuiGysCd.value[0] = this._municipalityCd;
            } else if (key === 'shimin') {
                this.shiminGysCd.value[0] = this._municipalityCd;
            } else if (key === 'okyu') {
                this.okyuGysCd.value[0] = this._municipalityCd;
            }
        },


        /**
         * 検索ボタンが押下された際の挙動。
         */
        onSubmit: function(obj){
            var key = obj.currentTarget.value;            
            this[this.targetGridQuery[key]]();
            this.setFilterStore(key);

            // 他タブに水道局災害、大阪市災害を再設定し、再検索
            this.resetAndSearchAgain(key);

            return false;
        },


        /**
         * 減断水情報一覧データの取得
         */
        dansuiUpdateGridQuery:function(){
            var store = this.dansuiStore;
            var form =  this.formDansui;

            // 入力値を元にグリッド用フィルターを作成
            var filter = new store.Filter();
            var value = form.get('value');
            
            // 水道局災害名
            filter = filter.eq('incidentId', value.incidentNm);

            // 大阪市災害名
            filter = filter.eq('disasterId', value.disasterNm);
            
            // 行政区コード
            if (this.dansuiGysCd !== null && this.dansuiGysCd.value.length > 0) {
                filter = filter.eq('gysCd', this.dansuiGysCd.value.join(','));
            }

            // 発生日時FROM
            if (value.hasseinichijiFrom !== null) {
                var hasseinichijiFrom = new Date(value.hasseinichijiFrom).getTime();
                filter = filter.eq('hasseinichijiFrom', hasseinichijiFrom);
            }
            
            // 発生日時To
            if (value.hasseinichijiTo !== null) {
                var hasseinichijiTo = new Date(value.hasseinichijiTo).getTime();
                filter = filter.eq('hasseinichijiTo', hasseinichijiTo);
            }

            // 復旧日時FROM
            if (value.fukkyunichijiFrom !== null) {
                var fukkyunichijiFrom = new Date(value.fukkyunichijiFrom).getTime();
                filter = filter.eq('fukkyunichijiFrom', fukkyunichijiFrom);
            }
            
            // 復旧日時To
            if (value.fukkyunichijiTo !== null) {
                var fukkyunichijiTo = new Date(value.fukkyunichijiTo).getTime();
                filter = filter.eq('fukkyunichijiTo', fukkyunichijiTo);
            }

            var collection = store.filter(filter);
            this.dansuiGrid.set('collection', collection);
        },
        
        /**
         * 浄水場被害状況一覧データの取得
         */
        josuihigaiUpdateGridQuery:function(){
            var store = this.josuihigaiStore;
            var form = this.formJosuihigai;

            // 入力値を元にグリッド用フィルターを作成
            var filter = new store.Filter();
            var value = form.get('value');
            
            // 水道局災害名
            filter = filter.eq('incidentId', value.incidentNm);

            // 大阪市災害名
            filter = filter.eq('disasterId', value.disasterNm);
            
            // 被害ありフラグ(被害あり='1')
            if (value.damageFlg.length !== 0) {
                filter = filter.eq('damageFlg', '1');
            }

            // 登録日時FROM
            if (value.entryDatetimeFrom !== null) {
                var entryDatetimeFrom = new Date(value.entryDatetimeFrom).getTime();
                filter = filter.eq('entryDatetimeFrom', entryDatetimeFrom);
            }
            
            // 登録日時To
            if (value.entryDatetimeTo !== null) {
                var entryDatetimeTo = new Date(value.entryDatetimeTo).getTime();
                filter = filter.eq('entryDatetimeTo', entryDatetimeTo);
            }            

            // 更新日時FROM
            if (value.updateDatetimeFrom !== null) {
                var updateDatetimeFrom = new Date(value.updateDatetimeFrom).getTime();
                filter = filter.eq('updateDatetimeFrom', updateDatetimeFrom);
            }
            
            // 更新日時To
            if (value.updateDatetimeTo !== null) {
                var updateDatetimeTo = new Date(value.updateDatetimeTo).getTime();
                filter = filter.eq('updateDatetimeTo', updateDatetimeTo);
            }

            var collection = store.filter(filter);
            this.josuihigaiGrid.set('collection', collection);
        },
        
        /**
         * 送・配水場被害情報一覧データの取得
         */
        sohaisuihigaiUpdateGridQuery:function(){
            var store = this.sohaisuihigaiStore;
            var form = this.formSohaisuihigai;

            // 入力値を元にグリッド用フィルターを作成
            var filter = new store.Filter();
            var value = form.get('value');
            
            
            // 水道局災害名
            filter = filter.eq('incidentId', value.incidentNm);

            // 大阪市災害名
            filter = filter.eq('disasterId', value.disasterNm);
            
            // 被害ありフラグ(被害あり='1')
            if (value.damageFlg.length !== 0) {
                filter = filter.eq('damageFlg', '1');
            }

            // 登録日時FROM
            if (value.entryDatetimeFrom) {
                var entryDatetimeFrom = new Date(value.entryDatetimeFrom).getTime();
                filter = filter.eq('entryDatetimeFrom', entryDatetimeFrom);
            }
            
            // 登録日時To
            if (value.entryDatetimeTo) {
                var entryDatetimeTo = new Date(value.entryDatetimeTo).getTime();
                filter = filter.eq('entryDatetimeTo', entryDatetimeTo);
            }            

            // 更新日時FROM
            if (value.updateDatetimeFrom) {
                var updateDatetimeFrom = new Date(value.updateDatetimeFrom).getTime();
                filter = filter.eq('updateDatetimeFrom', updateDatetimeFrom);
            }
            
            // 更新日時To
            if (value.updateDatetimeTo) {
                var updateDatetimeTo = new Date(value.updateDatetimeTo).getTime();
                filter = filter.eq('updateDatetimeTo', updateDatetimeTo);
            }


            var collection = store.filter(filter);
            this.sohaisuihigaiGrid.set('collection', collection);
        },
        
        /**
         * 庁舎・事業所被害情報一覧データの取得
         */
        choshaUpdateGridQuery:function(){
            var store = this.choshaStore;
            var form = this.formChosha;

            // 入力値を元にグリッド用フィルターを作成
            var filter = new store.Filter();
            var value = form.get('value');
            
            // 水道局災害名
            filter = filter.eq('incidentId', value.incidentNm);

            // 大阪市災害名
            filter = filter.eq('disasterId', value.disasterNm);

            // 被害規模
            filter = filter.eq('higaikibo', value.higaikibo);

            // 深刻度
            filter = filter.eq('shinkokudo', value.shinkokudo);

            // 発生日時FROM
            if (value.hasseinichijiFrom) {
                var hasseinichijiFrom = new Date(value.hasseinichijiFrom).getTime();
                filter = filter.eq('hasseinichijiFrom', hasseinichijiFrom);
            }
            
            // 発生日時To
            if (value.hasseinichijiTo !== null) {
                var hasseinichijiTo = new Date(value.hasseinichijiTo).getTime();
                filter = filter.eq('hasseinichijiTo', hasseinichijiTo);
            }

            // 登録日時FROM
            if (value.entryDatetimeFrom !== null) {
                var entryDatetimeFrom = new Date(value.entryDatetimeFrom).getTime();
                filter = filter.eq('entryDatetimeFrom', entryDatetimeFrom);
            }
            
            // 登録日時To
            if (value.entryDatetimeTo !== null) {
                var entryDatetimeTo = new Date(value.entryDatetimeTo).getTime();
                filter = filter.eq('entryDatetimeTo', entryDatetimeTo);
            }        

            // 更新日時FROM
            if (value.updateDatetimeFrom !== null) {
                var updateDatetimeFrom = new Date(value.updateDatetimeFrom).getTime();
                filter = filter.eq('updateDatetimeFrom', updateDatetimeFrom);
            }
            
            // 更新日時To
            if (value.updateDatetimeTo !== null) {
                var updateDatetimeTo = new Date(value.updateDatetimeTo).getTime();
                filter = filter.eq('updateDatetimeTo', updateDatetimeTo);
            }

            var collection = store.filter(filter);
            this.choshaGrid.set('collection', collection);
        },
        
        
        /**
         * 市民断水情報一覧データの取得
         */
        shiminUpdateGridQuery:function(){
            var store =  this.shiminStore;
            var form = this.formShimin;

            // 入力値を元にグリッド用フィルターを作成
            var filter = new store.Filter();
            var value = form.get('value');
            
            // 水道局災害名
            filter = filter.eq('incidentId', value.incidentNm);

            // 大阪市災害名
            filter = filter.eq('disasterId', value.disasterNm);
            
            // 行政区コード
            if (this.shiminGysCd !== null && this.shiminGysCd.value.length > 0) {
                filter = filter.eq('gysCd', this.shiminGysCd.value.join(','));
            }

            // 断水状況
            filter = filter.eq('gendansuistatusshimin', value.gendansuistatusshimin);

            // 登録日時FROM
            if (value.entryDatetimeFrom !== null) {
                var entryDatetimeFrom = new Date(value.entryDatetimeFrom).getTime();
                filter = filter.eq('entryDatetimeFrom', entryDatetimeFrom);
            }
            
            // 登録日時To
            if (value.entryDatetimeTo !== null) {
                var entryDatetimeTo = new Date(value.entryDatetimeTo).getTime();
                filter = filter.eq('entryDatetimeTo', entryDatetimeTo);
            }        

            // 更新日時FROM
            if (value.updateDatetimeFrom !== null) {
                var updateDatetimeFrom = new Date(value.updateDatetimeFrom).getTime();
                filter = filter.eq('updateDatetimeFrom', updateDatetimeFrom);
            }
            
            // 更新日時To
            if (value.updateDatetimeTo !== null) {
                var updateDatetimeTo = new Date(value.updateDatetimeTo).getTime();
                filter = filter.eq('updateDatetimeTo', updateDatetimeTo);
            }

            var collection = store.filter(filter);
            this.shiminGrid.set('collection', collection);
        },
        
        
        /**
         * 応急給水情報一覧データの取得
         */
        okyuUpdateGridQuery:function(){
            var store = this.okyuStore;
            var form = this.formOkyu;

            // 入力値を元にグリッド用フィルターを作成
            var filter = new store.Filter();
            var value = form.get('value');
            
            // 水道局災害名
            filter = filter.eq('incidentId', value.incidentNm);

            // 大阪市災害名
            filter = filter.eq('disasterId', value.disasterNm);
            
            // 行政区コード
            if (this.okyuGysCd !== null && this.okyuGysCd.value.length > 0) {
                filter = filter.eq('gysCd', this.okyuGysCd.value.join(','));
            }

            // 給水拠点種別
            filter = filter.eq('facilityKind', value.facilityKind);

            // 必要状況
            filter = filter.eq('okyuhitsuyo', value.okyuhitsuyo);

            // 対応状況
            filter = filter.eq('okyutaio', value.okyutaio);

            // 登録日時FROM
            if (value.entryDatetimeFrom !== null) {
                var entryDatetimeFrom = new Date(value.entryDatetimeFrom).getTime();
                filter = filter.eq('entryDatetimeFrom', entryDatetimeFrom);
            }
            
            // 登録日時To
            if (value.entryDatetimeTo !== null) {
                var entryDatetimeTo = new Date(value.entryDatetimeTo).getTime();
                filter = filter.eq('entryDatetimeTo', entryDatetimeTo);
            }       

            // 更新日時FROM
            if (value.updateDatetimeFrom !== null) {
                var updateDatetimeFrom = new Date(value.updateDatetimeFrom).getTime();
                filter = filter.eq('updateDatetimeFrom', updateDatetimeFrom);
            }
            
            // 更新日時To
            if (value.updateDatetimeTo !== null) {
                var updateDatetimeTo = new Date(value.updateDatetimeTo).getTime();
                filter = filter.eq('updateDatetimeTo', updateDatetimeTo);
            }

            var collection = store.filter(filter);
            this.okyuGrid.set('collection', collection);
        },
        

        /**
         * 水道局災害名管理画面へ遷移
         */
        showIncidentPage:function(){
            Router.moveTo('waterworks/incident');
        },
        
        /**
         * 詳細画面へ遷移する。
         */
        showDetailPage: function(item, key) {
            // 詳細ダイアログを表示
            switch(key){
            case 'dansui':
                // 水道局受信情報管理詳細（減断水情報）画面へ遷移
                this.innerDansuiDetailDialog.initProcess(key, item);
                this.dansuiDetailDialog.show();
                this.innerDansuiDetailDialog.afterProcess();
                break;
            case 'josuihigai':
                // 水道局受信情報管理詳細（浄水場被害情報）画面へ遷移
                this.innerJosuihigaiDetailDialog.initProcess(key, item);
                this.josuihigaiDetailDialog.show();
                this.innerJosuihigaiDetailDialog.afterProcess();
                break;
            case 'sohaisuihigai':
                // 水道局受信情報管理詳細（送・配水場被害情報）画面へ遷移
                this.innerSohaisuihigaiDetailDialog.initProcess(key, item);
                this.sohaisuihigaiDetailDialog.show();
                this.innerSohaisuihigaiDetailDialog.afterProcess();
                break;
            case 'chosha':
                // 水道局受信情報管理詳細（庁舎・事業所被害情報）画面へ遷移
                this.innerChoshaDetailDialog.initProcess(key, item);
                this.choshaDetailDialog.show();
                this.innerChoshaDetailDialog.afterProcess();
                break;
            case 'shimin':
                // 水道局受信情報管理詳細（市民断水情報）画面へ遷移
                this.innerShiminDetailDialog.initProcess(key, item);
                this.shiminDetailDialog.show();
                this.innerShiminDetailDialog.afterProcess();
                break;
            case 'okyu':
                // 水道局受信情報管理詳細（応急給水）画面へ遷移
                this.innerOkyuDetailDialog.initProcess(key, item);
                this.okyuDetailDialog.show();
                this.innerOkyuDetailDialog.afterProcess();
                break;
            default:
                console.log('詳細ボタンクリックイベントエラー');
                break; 
            }
        },
        
        /**
         * ▼詳細検索linkをClick
         */
        onOpenFilterClick: function (obj){
            var key = obj.currentTarget.name;  
            this.openFiletr(key);
            
        },

        /**
         * ▲詳細検索を終えるlinkをClick
         */
        onCloseFilterClick: function (obj){
            // charset（使われないと仮定して）にキーワードを設定
            var key = obj.currentTarget.name;
            this.closeFilter(key);
        },

        /**
         * 詳細検索エリアを開く
         * @param {*} key 
         */
        openFiletr: function(key){
            switch(key){
                case 'dansui':
                    domStyle.set(this.dansuiFilterArea, 'display', '');
                    domStyle.set(this.dansuiOpenFilterLink, 'display', 'none');
                    domStyle.set(this.dansuiCloseFilterLink, 'display', '');
                    this.dansuiBorderContainer.resize();
                    break;
                case 'josuihigai':
                    domStyle.set(this.josuihigaiFilterArea, 'display', '');
                    domStyle.set(this.josuihigaiOpenFilterLink, 'display', 'none');
                    domStyle.set(this.josuihigaiCloseFilterLink, 'display', '');
                    this.josuihigaiBorderContainer.resize();
                    break;
                case 'sohaisuihigai':
                    domStyle.set(this.sohaisuihigaiFilterArea, 'display', '');
                    domStyle.set(this.sohaisuihigaiOpenFilterLink, 'display', 'none');
                    domStyle.set(this.sohaisuihigaiCloseFilterLink, 'display', '');
                    this.sohaisuihigaiBorderContainer.resize();
                    break;
                case 'chosha':
                    domStyle.set(this.choshaFilterArea, 'display', '');
                    domStyle.set(this.choshaOpenFilterLink, 'display', 'none');
                    domStyle.set(this.choshaCloseFilterLink, 'display', '');
                    this.choshaBorderContainer.resize();
                    break;
                case 'shimin':
                    domStyle.set(this.shiminFilterArea, 'display', '');
                    domStyle.set(this.shiminOpenFilterLink, 'display', 'none');
                    domStyle.set(this.shiminCloseFilterLink, 'display', '');
                    this.shiminBorderContainer.resize();
                    break;
                case 'okyu':
                    domStyle.set(this.okyuFilterArea, 'display', '');
                    domStyle.set(this.okyuOpenFilterLink, 'display', 'none');
                    domStyle.set(this.okyuCloseFilterLink, 'display', '');
                    this.okyuBorderContainer.resize();
                    break;
                default:
                    return;

            }
            this.openingDetailFilter[key] = true;
        },

        /**
         * 詳細検索エリアを閉じる
         * @param {*} key 
         */
        closeFilter: function(key){
            switch(key){
                case 'dansui':
                    domStyle.set(this.dansuiFilterArea, 'display', 'none');
                    domStyle.set(this.dansuiOpenFilterLink, 'display', '');
                    domStyle.set(this.dansuiCloseFilterLink, 'display', 'none');
                    this.dansuiBorderContainer.resize();
                case 'josuihigai':
                    domStyle.set(this.josuihigaiFilterArea, 'display', 'none');
                    domStyle.set(this.josuihigaiOpenFilterLink, 'display', '');
                    domStyle.set(this.josuihigaiCloseFilterLink, 'display', 'none');
                    this.josuihigaiBorderContainer.resize();
                    break;
                case 'sohaisuihigai':
                    domStyle.set(this.sohaisuihigaiFilterArea, 'display', 'none');
                    domStyle.set(this.sohaisuihigaiOpenFilterLink, 'display', '');
                    domStyle.set(this.sohaisuihigaiCloseFilterLink, 'display', 'none');
                    this.sohaisuihigaiBorderContainer.resize();
                    break;
                case 'chosha':
                    domStyle.set(this.choshaFilterArea, 'display', 'none');
                    domStyle.set(this.choshaOpenFilterLink, 'display', '');
                    domStyle.set(this.choshaCloseFilterLink, 'display', 'none');
                    this.choshaBorderContainer.resize();
                    break;
                case 'shimin':
                    domStyle.set(this.shiminFilterArea, 'display', 'none');
                    domStyle.set(this.shiminOpenFilterLink, 'display', '');
                    domStyle.set(this.shiminCloseFilterLink, 'display', 'none');
                    this.shiminBorderContainer.resize();
                    break;
                case 'okyu':
                    domStyle.set(this.okyuFilterArea, 'display', 'none');
                    domStyle.set(this.okyuOpenFilterLink, 'display', '');
                    domStyle.set(this.okyuCloseFilterLink, 'display', 'none');
                    this.okyuBorderContainer.resize();
                    break;
                default:
                    return;
            }
            this.openingDetailFilter[key] = false;
        },

        /**
         * 検索条件を保管する
         */
        setFilterStore: function (key) {
            //初期化する
            waterworksFilterStore[key] = this.initFilterItem[key];
            var filterStore = waterworksFilterStore[key];
            var value = this.targetForm[key].get('value');
            
            // フィルターの設定
            for (var filterKey in this.initFilterItem[key]) {
                if(!this.initFilterItem[key].hasOwnProperty(filterKey)){
                    continue;
                }
                // フィルター項目がチェックボックスであるか確認
                if(filterKey === 'damageFlg'){
                    if(value.damageFlg.length > 0) {
                        filterStore.damageFlg = 'checked';
                    } else {
                        filterStore.damageFlg = '';
                    }
                } else if(filterKey === 'openingDetailFilter') {
                    // 詳細検索項目を開いているかを保存する
                    filterStore.openingDetailFilter = this.openingDetailFilter[key];
                
                } else {
                    // 検索項目を保存
                    filterStore[filterKey] = value[filterKey];
                }    
            }
        },

        /**
         * 保管した検索条件をフォームにセットする
         */
        setFilterData: function (key) {
            //保管されていれば値をセット
            var filterStore = waterworksFilterStore[key];
            var form = this.targetForm[key];

            if(!filterStore){ return;}
            
            var copyFilterStore = JSON.parse(JSON.stringify(filterStore));
            var existsKey = "gysNm";
            if(existsKey in copyFilterStore){
                delete copyFilterStore.gysNm;
            }       

            // 検索項目を設定
            form.set('value', copyFilterStore);
            
            // フィルターの設定
            for (var filterKey in this.initFilterItem[key]) {
                if(!this.initFilterItem[key].hasOwnProperty(filterKey)){
                    continue;
                }
                // フィルター項目がチェックボックスであるか確認
                if(filterKey === 'damageFlg' && filterStore.damageFlg){
                    if(key === 'sohaisuihigai') {
                        this.sohaisuihigaiDamageFlg.set('checked', filterStore.damageFlg);
                    } else{
                        this.josuihigaiDamageFlg.set('checked', filterStore.damageFlg);    
                    }
                } else if(filterKey === 'openingDetailFilter') {
                    //詳細検索フォームを開いているか
                    if(filterStore.openingDetailFilter){
                        this.openFiletr(key);
                    }
                } else if(filterKey === 'gysNm' && filterStore.gysNm){
                    for(var index=0; index < filterStore.gysNm.length; index++){
                        if(key === 'dansui') {
                            this.dansuiGysCd.value[index] = filterStore.gysNm[index];
                        } else if(key === 'shimin'){
                            this.shiminGysCd.value[index] = filterStore.gysNm[index];
                        } else{
                            this.okyuGysCd.value[index] = filterStore.gysNm[index];
                        }
                    }
                }
            } 
        },

        /**
         * フォームを生成し値を設定する
         */
        initForm: function () {
            var incidentList = [
                {
                    label: '&nbsp;',
                    value: ''
                }
            ];
            var disasterList = [
                {
                    label: '&nbsp;',
                    value: ''
                }
            ];

            this._defaultSelectItem = null;
            
            // 水道局災害名セレクタ情報を取得
            this.getIncidentList().then(
                lang.hitch(this, function (itemList) {
                    Object.keys(itemList).forEach(function (id) {
                        incidentList.push({
                            value: itemList[id].key,
                            label: itemList[id].val
                        });
                    });

                    // 災害名セレクタ情報を取得
                    this.getDisasterList().then(
                        lang.hitch(this, function (itemList) {
                            Object.keys(itemList).forEach(function (id) {
                                disasterList.push({
                                    value: itemList[id].key,
                                    label: itemList[id].val
                                });
                            });

                            // 水道局災害名のセレクタ一覧を生成
                            for (var key in this.targetIncidentSelector) {                
                                if (!this.targetIncidentSelector.hasOwnProperty(key)) {    
                                    continue;
                                }
                                var incidentSelector = this.targetIncidentSelector[key];
                                incidentSelector.set('options',  incidentList.map( list => ({'value':list.value, 'label':list.label})));

                                if(this._defaultSelectItem !== null){
                                    incidentSelector.set('value', this._defaultSelectItem.incidentId);
                                }
                            }

                            // 災害名のセレクタ一覧を生成
                            for (var key in this.targetdisasterSelector) {
                                if (!this.targetdisasterSelector.hasOwnProperty(key)) {    
                                    continue;
                                }

                                var disasterSelector =this.targetdisasterSelector[key];
                                disasterSelector.set('options',  disasterList.map( list => ({'value':list.value, 'label':list.label})));

                                if(this._defaultSelectItem !== null){
                                    disasterSelector.set('value', this._defaultSelectItem.disasterId);
                                }
                            }

                            // フォームの初期値設定とグリッドデータ取得
                            for (var key in this.targetGridQuery) {
                            if(!this.targetGridQuery.hasOwnProperty(key)){
                                continue;
                            }
                            this.setFilterData(key);
                            this[this.targetGridQuery[key]]();
                            this.setFilterStore(key);
                            }
                        })
                    );
                })
            );
        },


        /**
         * 他タブへの再設定と再検索
         */
        resetAndSearchAgain:function(key){
            // 検索対象となったタブのフィルター情報を取得する
            waterworksFilterStore[key] = this.initFilterItem[key];
            var filterStore = waterworksFilterStore[key];
            var value = this.targetForm[key].get('value');
            
            // フィルターの設定
            for (var keyItem in this.initFilterItem) {
                if(keyItem === key){
                    continue;
                }
                if(!this.initFilterItem.hasOwnProperty(keyItem)){
                    continue;
                }

                var otherFilterStore = waterworksFilterStore[keyItem];
                // 完全に条件が一致していた場合をチェック
                if( (otherFilterStore.incidentNm === value.incidentNm) &&
                    (otherFilterStore.disasterNm === value.disasterNm) ){
                        // １件でも一致していた場合は以降も一致していると判断し処理を終了
                        break;
                    }
                // 検索項目を保存
                otherFilterStore.incidentNm = value.incidentNm;
                otherFilterStore.disasterNm = value.disasterNm;
                this.setFilterData(keyItem);
                this[this.targetGridQuery[keyItem]]();
            }
        } ,

        /**
         * 現在表示しているタブ名を設定
         */
        changeDansui: function(){
            this.tabName = 'dansui';
        },
        changeJosuihigai: function(){
            this.tabName = 'josuihigai';
        },
        changeSohaisuihigai: function(){
            this.tabName = 'sohaisuihigai';
        },
        changeChosha: function(){
            this.tabName = 'chosha';
        },
        changeShimin: function(){
            this.tabName = 'shimin';
        },
        changeOkyu: function(){
            this.tabName = 'okyu';
        },

        /**
         * 水道局災害名：変更イベント
         * 災害名と同期させる
         * @returns 
         */
        changeIncidentSelect: function () {

            var form = this.targetForm[this.tabName];
            var value = form.get('value');

            var disasterSelector =this.targetdisasterSelector[this.tabName];

            if(value.incidentNm === ''){
                disasterSelector.set('value', '');
                return;
            }

            for(var index = 0; index < this._disasterDispList.length; index++){
                if(value.incidentNm === this._disasterDispList[index].incident){
                    disasterSelector.set('value', this._disasterDispList[index].key);
                    break;
                }
                else {                    
                    this.changeFlag.incident = true;
                    disasterSelector.set('value', '');
                }
            }
        },

        /**
         * 大阪市災害名：変更イベント
         * 水道局災害名と同期させる
         * @returns 
         */
        changeDisasterSelect: function() {
            var form = this.targetForm[this.tabName];
            var value = form.get('value');
            var incidentSelector = this.targetIncidentSelector[this.tabName];
            if(this.changeFlag.incident && value.disasterNm === ''){
                this.changeFlag.incident = false;
                return;
            }
            if(value.disasterNm === ''){
                incidentSelector.set('value', '');
                return;
            }
            for(var index = 0; index < this._disasterDispList.length; index++){
                if(value.disasterNm === this._disasterDispList[index].key){
                    incidentSelector.set('value', this._disasterDispList[index].incident);
                    break;
                }
            }
            if (this.changeFlag.incident ) {
                this.changeFlag.incident = false;
                return;
            }
        },

    });
});
