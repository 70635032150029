/**
 * 招集状況詳細画面用モジュール。
 *
 * @module app/convocation/view/mail/ConvoStatusListPage
 */
define(['module',
        'dojo/_base/declare',
        'dojo/_base/lang',
        'dojo/date/locale',
        'dojo/dom-style',
        'dojo/text!./templates/ConvoStatusListPage.html',
        'idis/view/dialog/DialogChain',
        'idis/view/page/_PageBase',
        'idis/view/dialog/InfoDialog',
        'idis/control/Router',
        'idis/store/IdisRest',
        'idis/service/Requester',
        'idis/control/Locator',
        'idis/view/Loader',
        'dojo/Deferred',
        'dojo/json',
        'idis/model/UserInfo',
        'idis/consts/ACL',
        // 以下、変数で受けないモジュール
        'dijit/Dialog',
        'dijit/layout/BorderContainer',
        'dijit/layout/ContentPane',
        'dijit/form/Form',
        'dijit/form/Select',
        'dijit/form/TextBox',
        'idis/view/form/AclButton',
        'idis/view/form/Button',
        'idis/view/form/DateTimeInput',
        'app/view/form/EmployeeDivisionSelector',
        'app/convocation/view/mail/ConvoStatusListGrid',
        'app/convocation/view/mail/ConvoDeputyAnswerDialog',
        'app/convocation/view/mail/ConvoStatusListOutputReportDialog'],
        function(module, declare, lang, locale, domStyle, template, DialogChain, _PageBase, InfoDialog,
                 Router, IdisRest, Requester, Locator, Loader, Deferred, JSON, UserInfo, ACL) {
    /**
     * 招集状況詳細画面。
     *
     * @class ConvoStatusListPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/convocation/view/mail/ConvoStatusListPage# */
    {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--convocation',

        /**
         * グローバル変数の初期化を行うメソッド。
         */
        constructor: function() {
            this.distributionId = Locator.getQuery().mid;
            this.groupCd = Locator.getQuery().gcd;
            this.distSettingId = Locator.getQuery().sid;
            this.divisionCd = Locator.getQuery().dcd;
            this.organizationCd = Locator.getQuery().ocd;
            var url = null;
            if (this.groupCd) {
                url = '/api/convoMails/mails/list/' + this.distributionId + '/' + this.groupCd + '/' + this.organizationCd;
            } else {
                url = '/api/convoMails/mails/list/' + this.distributionId + '/division/' + this.divisionCd + '/' + this.organizationCd;
            }
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'distributionHistDetailId',
                target: url
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
        },

        /**
         * DOMノードを生成するためのメソッド
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 件名、本文、配信時刻を設定する
            this.initForm();
            this.initConvoStatusListGrid();
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.deputyAnswerDialog);
            this.initOutputReportDialog();
        },

        /**
         * 一覧画面上部のコンテンツを生成するためのメソッド
         */
        initForm: function(){
            var url = null;
            if (this.groupCd) {
                url = '/api/convoMails/mails/' + this.distributionId + '/' + this.groupCd;
            } else {
                url = '/api/convoMails/mails/' + this.distributionId + '/division/' + this.divisionCd;
            }
            Requester.get(url)
                .then(lang.hitch(this,function(data) {
//                if (data.autoFlg){
//                    this.sender.innerHTML = '自動送信';
//                } else {
//                    this.sender.innerHTML = '手動送信';
//                }
                this.sender.innerHTML = data.sender;
                if (typeof data.distributeTimeStamp !== 'undefined' && data.distributeTimeStamp !== null) {
                    // distributeTimeStampがnullかundefinedではない場合フォーマットを変える
                    var date = locale.parse(data.distributeTimeStamp, {
                        datePattern: 'yyyy-MM-dd',
                        timePattern: 'HH:mm:ss'
                    });
                    this.distributeTimeStamp.innerText = locale.format(date, {
                        datePattern: 'yyyy/MM/dd',
                        timePattern: 'HH:mm'
                    });
                } else {
                    this.distributeTimeStamp.innerText = '-';
                }
                this.title.innerHTML = data.distributionTitle;
                this.body.innerHTML = data.distributionBody;
                this.organization.innerHTML = data.groupName;

                this.ConvoStatusListGrid.renderHeader();
            }));

            var role = UserInfo.getRoleCd();
            this.msg.hidden = true;
            if (role !== ACL.ADMIN_USER && role !== ACL.SYSMGR_USER) {
                this.msg.hidden = false;
            }
            // 市本部ユーザ、区本部ユーザ、所属本部ユーザの場合、
            // UserIdに「honbu」を含み -c を含まないユーザだけ再送を可能とする
            if (role === ACL.SHIHONBU_USER || role === ACL.KUHONBU_USER || role === ACL.XXHONBU_USER) {
                var regexp = /^(?!.*-c).*(?=honbu).*$/;
                if (!regexp.test(UserInfo.getId())) {
                    domStyle.set(this.selectButton.domNode, 'display', 'none');
                    domStyle.set(this.submitButton.domNode, 'display', 'none');
                }
            }
        },

        /**
         * Gridの初期化を行うメソッド
         */
        initConvoStatusListGrid: function() {
            this.ConvoStatusListGrid.set('collection', this.store);
            // グリッドの代理回答ボタンクリック時の動作を設定する
            this.ConvoStatusListGrid.on('deputyAnswerButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                // 代理回答ダイアログを表示する
                this.showDeputyAnswerDialog(evt.item);
            }));
        },

        /**
         * 職員参集状況一覧
         * 帳票出力ダイアログ初期化処理
         */
        initOutputReportDialog: function() {
            // 詳細ダイアログの最初の子要素が詳細画面
            var dialog = this.convoStatusListOutputReportDialog;
            var page = dialog.getChildren()[0];
            // 更新ボタンが押された際の動作
            page.on('upload', lang.hitch(this, function() {
                this.onSubmit();
            }));
        },

        /**
         * 代理回答ダイアログを表示する。
         */
        showDeputyAnswerDialog: function(item) {
            var dialog = this.deputyAnswerDialog;
            var page = dialog.getChildren()[0];
            page.initDialog(item);
            dialog.show();
        },

        /**
         * 未回答者を選択ボタンが押された際に呼ばれるメソッド
         */
        selectButtonClick: function() {
            var self = this;
            this.ConvoStatusListGrid._rows.forEach(function(row) {
                if (row.getElementsByClassName('distA01')[0].innerText ==='応答なし') {
                    self.ConvoStatusListGrid._select(row, null, true);
                }
            });
            this.ConvoStatusListGrid._fireSelectionEvents();
        },

        /**
         * 再送ボタンが押された際に呼ばれるメソッド
         */
        onResend: function() {
            // 再送対象を取得
            var selection = this.ConvoStatusListGrid.selection;
            if (Object.keys(selection).length === 0) {
                this.chain.info('再送対象が選択されていません。', 'エラー');
                return;
            }

            var formData = {
                'mailType' : '1',
                'histId' : this.distributionId,
                'histDetailId' : Object.keys(selection),
                'distSettingId' : this.distSettingId
            };

            // 再送確認ダイアログの表示
            this.chain.confirm('参集メールの再送を行います。<br>' + 'よろしいですか？', function(chain) {
                // リクエストの発行からレスポンス受信後の処理までをpromiseに格納
                var promise = Requester.post('/api/convoMails/mails', {
                    data : formData
                }).then(function(){
                    // 完了ダイアログを表示
                    chain.infoComplete(function() {
                        location.reload();
                    });
                }, function(err) {
                    // 処理失敗時はエラーログを出力し、エラーダイアログを表示
                    console.error(err);
                    chain.infoError(err);
                });
                //ローダーの表示
                Loader.wait(promise);
            });
        },

        /**
         * @param evt ボタンクリックイベント
         * パンくずリスト（概況画面）が押された際に呼ばれるメソッド
         */
        onConvoStatusDetailLinkClick : function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('convocation/statusDetail', {
                id: this.distributionId,
                settingid: this.distSettingId
            });
        },

        /**
         * パンくずリストのボタンを押下したときの挙動
         */
        onConvocationMailAdminPageLinkClick : function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('convocation/mail');
        },

        /**
         * 帳票出力ボタンが押された際に呼ばれるメソッド
         */
//        outputListExcel: function() {
//            console.log('[帳票出力]ボタンがクリックされました。');
//
//            // 確認ダイアログを表示
//            this.chain.confirm('一覧に表示されているデータを出力します。<br>' +
//                'よろしいですか？', function(chain) {
//                // 確認ダイアログでOKを押した場合
//                chain.hide();
//
//                var reqid = 'EMP_DISTRIBUTION_LIST_EX';
//                var fileName = 'EmpDistributionList.xlsx';
//                var fileNameJp = '参集状況一覧.xlsx';
//                var deptCd = '';
//                var sectCd = '';
//                var role = UserInfo.getRoleCd();
//                if (role !== ACL.ADMIN_USER) {
//                    deptCd = UserInfo.getOrganization().deptCd;
//                    sectCd = UserInfo.getOrganization().sectCd;
//                }
//                var paramList = [
//                    {key: 'distId', value: this.distributionId},
//                    {key: 'grpId', value: this.groupCd},
//                    {key: 'deptCd', value: deptCd},
//                    {key: 'sectCd', value: sectCd}
//                ];
//
//                var data4xoblosDownload = {
//                    fileName: fileName,
//                    reqid: reqid,
//                    paramList: paramList
//                };
//
//                var promise = this.download(data4xoblosDownload, fileNameJp).then(lang.hitch(this, function() {
//                        this.infoDialog = new InfoDialog({
//                            title : '出力完了',
//                            content : '一覧帳票の出力が完了しました。'
//                        });
//                        this.infoDialog.show();
//
//                    }), lang.hitch(this, function(error) {
//                        console.error(error);
//                        this.infoDialog = new InfoDialog({
//                            title : 'エラー',
//                            content : 'エラーが発生しました。管理者にお問い合わせください。'
//                        });
//                        this.infoDialog.show();
//                }));
//
//                Loader.wait(promise);
//            });
//        },

        // 帳票のダウンロード
//        download: function(data, fileNameJp) {
//            var deferred = new Deferred();
//
//            var xhr = new XMLHttpRequest();
//            xhr.open('POST', '/api/xoblos/download', true);
//            xhr.responseType = 'arraybuffer';
//            xhr.setRequestHeader( 'Content-Type', 'application/json' );
//            xhr.onload = function() {
//
//                // エラー時は処理を止める
//                if(xhr.status !== 200){
//                    deferred.reject('status error:'+ xhr.status);
//                    return;
//                }
//
//                var arrayBuffer = this.response;
//                var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
//                // ファイル名を取得
//                var baseFileName = fileNameJp;
//                var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');
//
//                // IE10+
//                if(window.navigator.msSaveOrOpenBlob){
//                    window.navigator.msSaveOrOpenBlob(blob, fileName);
//                } else {
//                    // 擬似的にAタグを作成
//                    var link = document.createElement('a');
//                    link.style = 'display: none';
//                    document.body.appendChild(link);
//
//                    // AタグのURLにバイナリデータをセット
//                    var url = window.URL.createObjectURL(blob);
//                    link.href = url;
//
//                    // ファイル名をセット
//                    link.download = fileName;
//
//                    // 擬似的にリンクをクリック
//                    link.click();
//                    // 参照を解放
//                    window.URL.revokeObjectURL(url);
//                    link.remove();
//                }
//
//                deferred.resolve();
//
//            };
//            xhr.send(JSON.stringify(data));
//
//            return deferred.promise;
//        },

        /**
         * 帳票出力ダイアログを表示する。
         */
        showStatusListOutputReportDialog: function() {
            //this.convoStatusListOutputReportDialog.show();
            var dialog = this.convoStatusListOutputReportDialog;
            var page = dialog.getChildren()[0];
            page.initDialog(this.distributionId, this.groupCd, this.divisionCd, this.organizationCd);
            dialog.show();
        }
    });
});
