/**
 * 被害種別 複数選択用パーツ。
 * @module app/damage/damagetype/DamageTypeMultiSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/form/TreeChecker'
], function (module, declare, domStyle, CacheStoreModel, CacheTreeRest, TreeChecker) {
    /**
     * 被害種別グループ用パーツ。
     * @class UsersSelector
     * @extends module:idis/view/form/TreeChecker~TreeChecker
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:idis/view/tree/DamageTypeMultiSelector~DamageTypeMultiSelector# */ {
            // 選択ダイアログのタイトル
            title: '被害種別選択',
            // ツリー・モデル
            model: null,
            // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
            constructor: function () {
                this._initModel();
            },


            _initModel: function () {
                this.model = new CacheStoreModel({
                    store: new CacheTreeRest({
                        target: 'api/damageReports/damageTypeTree'
                    })
                });
            },

            /**
             * 選択した値の設定
             */
            _setValueAttr: function () {
                // TreeChecker本来の処理
                this.inherited(arguments);
                // 文字列省略をオーバーライド
                domStyle.set(this.status, {
                    'max-width': '20em',
                    'white-space': 'nowrap',
                    'overflow': 'hidden',
                    'text-overflow': 'ellipsis'
                });
            }
        });
});

