/**
 * 基底モジュール。
 * @module app/fdmaDisasterSituation/_FdmaDisasterSituationBase
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'idis/service/Requester',
    'dojo/_base/lang',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button'
], function(module, declare, _PageBase, Loader, Requester, lang) {

    return declare(module.id.replace(/\//g, '.'), _PageBase,
    {

        baseClass: 'idis-Page idis-Page--fdmaDisasterSituation',

        _FdmaDisasterGroupList: [],

        /**
         * constructor
         */
        constructor: function() {
        },

        /**
         * フォームをリセットする。
         */
        reset: function() {
            this.form.reset();
        },

        // 消防局災害グループ名のセレクタ一覧を生成
        initSetFdmaDisasterGroupList: function (selector) {
            var optionList = [
                {
                    label: '&nbsp;',
                    value: ''
                }
            ];
            this.getFdmaDisasterGroup().then(
                lang.hitch(this, function (itemList) {
                    Object.keys(itemList).forEach(function (id) {
                        optionList.push({
                            value: itemList[id].key,
                            label: itemList[id].val
                        });
                    });
                    selector.set('options', optionList);
                })
            );
        },
        /**
         * 消防局災害グループ名マスタ情報取得
         */
        getFdmaDisasterGroup: function () {
            return Loader.wait(
                Requester.get('api/fdmaDisaster/group').then(
                    lang.hitch(this, function (data) {
                        if(data !== null && data !== undefined){
                            for(var index = 0; index < data.items.length; index++){
                                var item = data.items[index];
                                var nameCount = item.disasterGroupName.length;
                                var disasterName = "";
                                if (nameCount > 50){
                                    disasterName = item.disasterGroupName.substr(0, 50) + "...";
                                }else{
                                    disasterName = item.disasterGroupName;
                                }
                                var id = item.entryDatetime + "_" + item.disasterGroupNo;
                                var name = item.entryDatetime + "_" + disasterName;
                                this._FdmaDisasterGroupList[index] = { key: id, val: name };
                            }                            
                        }
                        return this._FdmaDisasterGroupList;
                    })
                )
            );
        },
    });

});
