/**
 * 体制用区市町村・組織選択用セレクター
 * @module app/view/form/DisasterPreventionMunicOrgSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/string',
    'dijit/form/TextBox',
    'dojo/when',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'app/view/form/MunicipalityListSelector',
    'idis/view/dialog/InfoDialog',
    'app/config'
], function (module, declare, lang, dStr, TextBox, when, CacheStoreModel, CacheTreeRest, MunicipalityListSelector,
    InfoDialog, config) {
    /**
     * 市町村選択用パーツ（避難情報）
     * @class DisasterPreventionMunicOrgSelector
     * @extends app/view/form/MunicipalityListSelector~MunicipalityListSelector
     */
    return declare(module.id.replace(/\//g, '.'), MunicipalityListSelector,
        /** @lends module:app/evacorder/CustomizableMunicipalitySelector~CustomizableMunicipalitySelector*/ {

        // 選択ダイアログのタイトル
        title: '区市町村・組織選択',

        // ツリー・モデル
        model: null,
        emptyValueLabel: '全選択',
        
        startup: function () {
            this.inherited(arguments);
            this.dialog.hideOnOK = false;
        },

        // DOMを構築する
        buildRendering: function () {
            this.inherited(arguments);
            // 検索用入力要素を設置
            this.filterInput = new TextBox({
                placeHolder: this.placeHolder,
                maxLength: 128
            });
            this.filterInput.placeAt(this.treeControlNode);
            this.own(this.filterInput.on('keyup', lang.hitch(this, 'onFilterInputChange')));
        },

        postMixInProperties: function () {
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/municipalities/disasterPreventionOrgs4Tree'
                })
            });
        },
        _setValueAttr: function (value) {
            // 偽値は数値の0のみ有効とする
            if (value || value === 0) {
                if (this.prefUnselectable) {
                    //都道府県選択不可
                    if (value === config.municInfo.prefMunicCd) {
                        InfoDialog.show('エラー', '府は選択することができません。');
                        return false;
                    }
                }
                this._set('value', value);
                this.emit('change', { value: value });
                this._initTree().then(lang.hitch(this, function () {
                    var model = this.tree.model;
                    var label;
                    if (this.fullName) {
                        label = model.getFullName(value);
                    } else {
                        label = when(model.store.get(value), function (item) {
                            return model.getLabel(item);
                        });
                    }
                    when(label, lang.hitch(this, function (name) {
                        // 親の情報は不要なので省略する。
                        var lastIndex = name.lastIndexOf('/');
                        if (lastIndex !== -1) {
                            var excludeParentName = name.substring(lastIndex + 1);
                            name = excludeParentName;
                        }
                        this.status.innerHTML = name;
                    }));
                }));
            } else {
                this._set('value', '');
                this.emit('change', { value: '' });
                this.status.innerHTML = this.emptyValueLabel;
            }
            // 要素の選択状態をリセットする
            this._initTree().then(lang.hitch(this, function () {
                this.tree.set('selectedItem', null);
            }));
            this.dialog.hide();
        },

        /**
         * 検索用入力要素入力時。
         */
         onFilterInputChange: function () {
            // 入力値を取得
            var value = dStr.trim(this.filterInput.get('value'));
            if (value) {
                // ラベル名が入力値を含むものでフィルターし、ツリーを展開する
                this.model.setFilter(function (item, isLeaf, model) {
                    return isLeaf && (model.getLabel(item) || '').indexOf(value) !== -1;
                }).then(lang.hitch(this, function () {
                    this.tree.expandAll();
                }));
            } else {
                // 入力が空の場合は全要素を表示してツリーを閉じる
                this.model.setFilter().then(lang.hitch(this, function () {
                    this.tree.collapseAll();
                }));
            }
        }
    });
});
