/**
* Spectee管理画面用モジュール。
* @module app/spectee/SpecteeAdminPage
*/
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-class',
    'dojo/text!./templates/SpecteeAdminPage.html',
    'dojo/topic',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/model/UserInfo',
    'app/model/DisasterInfo',
    'idis/consts/ACL',
    'idis/view/dialog/DialogChain',
    './_SpecteeAdminPageBase',
    '../config',
    // 以下、変数として受け取らないモジュール
    'dijit/form/Form',
    'dijit/form/CheckBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    '../view/form/DisasterSelector',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    './SpecteeAdminGrid',
    './SpecteeMunicAdminGrid'
], function(module, array, declare, lang, locale, domClass, template, topic, _PageBase,
    Loader, Router, Requester, UserInfo,  DisasterInfo, ACL, DialogChain, _SpecteeAdminPageBase, config) {

    /**
    * 検索条件格納用オブジェクト
    * @type {Object}
    */
    var specteeyFilterStore = {};
    /**
    * Spectee情報画面。
    * @class SpecteeAdminPage
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _SpecteeAdminPageBase,
    /** @lends module:app/spectee/SpecteeAdminPage~SpecteeAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--spectee',

        /**
        * データ格納用オブジェクト
        * @type {module:dstore/Store}
        */
        store: null,
        /**
         * 災害ID
         */
        _disasterId: null,
        /**
         * 位置情報
         * @type {Object}
         */
        _latlng: null,
        /**
         * page種別
         * @type {String}
         */
        pageType: null,
        /**
         * page種別(管理画面)
         * @type {String}
         */
        PAGE_TYPE: 'ADMIN_PAGE',
        /**
         * 使用するgrid名
         * @type {String}
         */
        GRID_NAME: null,
        /**
         * 広島県の緯度経度
         */
        INIT_LATLNG: {lat: config.map.latitude, lng: config.map.longitude},
        /**
         * お気に入り情報
         * @type {String}
         */
        IS_FAVORITE: '1',
        /**
         * 処理開始フラグ
         * @type {String}
         */
        FETCH_SPECTEE_INFO_STARTED: false,
        /**
         * 位置情報確定レベル(緯度経度・住所なし)
         * @type {String}
         */
        LATLNG_COMFIRM_LEVEL_NONE: '0',

        /**
         * constructor
         */
        constructor: function() {
            this.chain = DialogChain.get(this);

            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                console.warn('災害IDが設定させていません。');
            }
            console.debug('現在のログインユーザID：' + UserInfo.getId());
            console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());

            // ユーザ情報を取得し、更新権限の有無によりgrid名を決める
            this.GRID_NAME = UserInfo.hasWriteAuthz('F05012') && UserInfo.getOrganization().deptCd === config.dept.crisisManagementDeptCd ? 'grid' : 'municGrid';
        },

        /**
         * buildRendering
         * DOMノードを生成するためのメソッド
         */
        buildRendering: function() {
            this.inherited(arguments);
            // this.own(this.formDialog);
        },

        /**
         * postCreate
         * DOM構築後に呼ばれる
         */
        postCreate: function() {
            console.debug('postCreate');
            this.inherited(arguments);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            // 使用するgridを出し分ける
            this.setGridStyle();
            // グリッドを初期化する
            this.initGrid(this.PAGE_TYPE);
            // 検索詳細条件入力エリアを非表示
            this.toggleOptionFilter();
            // 地図を初期化する
            this.initMap(this.INIT_LATLNG.lat, this.INIT_LATLNG.lng);
            this.own(topic.subscribe('idis/view/form/GeocodingForm::geocoded', lang.hitch(this, function(payload) {
                this._latlng = payload.latlng;
                this.map.setView(payload.latlng, 11);
            })));

            // フォームに初期値をセットする
            this.initFormValue();
            // 検索対象期間、DateTimeInputのイベントを定義する
            this.setDateTimeInputEvent();
            // 初期表示用にSpecteeAPIにリクエストする
            this.searchSpecteeInfo();
            // 定期処理を開始する
            this.startTimer();
            // 検索条件としてすべての区を選択
            this.allWardOn();
        },

        /**
         * 日時入力フォームのイベントを定義する。
         */
        setDateTimeInputEvent: function() {
            // 検索対象期間の4つの入力フォーム(日付、日時は別物)のイベントを定義する
            var formArray =[
                ['postDateTimeFrom','_date'],
                ['postDateTimeFrom','_time'],
                ['postDateTimeTo','_date'],
                ['postDateTimeTo','_time']
            ];
            array.forEach(formArray, lang.hitch(this, function(form) {
                // 検索対象期間のフォームイベントを定義する
                this.own(this[form[0]][form[1]].on('change', lang.hitch(this, function() {
                    // イベント定義時に自動的にSpecteeへのリクエスト処理が走るため
                    // ページ初期表示時のリクエスト処理以降の処理であるかを判定する
                    if(this.FETCH_SPECTEE_INFO_STARTED) {
                        this.searchSpecteeInfo();
                    }
                })));
                this[form[0]][form[1]].startup();
            }));
        },

        /**
         * フォームに初期値をセットする。
         */
        initFormValue: function() {
            // ページ遷移する際に検索条件を保管していたかを判定する
            if(Object.keys(specteeyFilterStore).length) {
                // ページ遷移前に保管した検索条件がある場合

                // 保管した検索条件をArrayに変換する
                var queryArray =
                    Object.keys(specteeyFilterStore).map(function (key) {return specteeyFilterStore[key];});

                // 保管した検索条件をフォームにセットする
                Object.keys(specteeyFilterStore).forEach(lang.hitch(this, function (value,idx) {
                    if(value) {
                        var form = {};
                        form[value] = queryArray[idx];
                        this.form.set('value', form);
                    }
                }));
            }else {
                // ページ遷移前に保管した検索条件がない場合

                var prefFormObj = {};
                // form名、valueを組み立てる(form名とvalueは同値)
                var prefFormValue = 'pref' + config.municInfo.prePrefCd;
                // 都道府県form用オブジェクトに追加する
                prefFormObj[prefFormValue] = prefFormValue;
                // 都道府県formのセット処理
                this.form.set('value', prefFormObj);

                // 現在より1時間前の時刻を取得し、検索対象期間(開始)にセットする
                var time = new Date();
                time.setDate(time.getDate() - 1);
                this.postDateTimeFrom.set('value', time);
            }
        },

        /**
         * Specteeへの定期取得処理を開始する。
         */
        startTimer: function(){
            //関数fetchSpecteeInfo()を60000ミリ秒間隔で呼び出す
            this.specteeTimer =
            setInterval(lang.hitch(this, function() {
                if(this.form.get('value').favorite[0] !== '1') {
                    // お気に入り情報に絞っている場合は処理しない
                    var query = this.buildSpecteeQuery();
                    this.fetchSpecteeInfo(query);
                }
            }), 60000);
        },

        /**
         * Spectee/お気に入り情報の検索を開始する。
         */
        searchSpecteeInfo: function() {
            // 検索条件保管用storeを初期化する
            specteeyFilterStore = {};
            var formValue = this.form.get('value');
            // 検索条件を保管する
            specteeyFilterStore = lang.mixin(null, formValue);

            // 情報取得を開始する
            if(this.form.get('value').favorite[0] === '1') {
                // お気に入り情報に絞っている場合
                // フォームを非表示にする
                this.toggleOptionFilterButton.style.display = 'none';
                this.optionFilterArea.style.display = 'none';
                this.hideOptionFilterArea = false;
                this.toggleOptionFilter();
                this.latest.domNode.style.display = 'none';
                this.latestLabel.style.display = 'none';
                this.searchPeriod.style.display = 'none';
                this.form.resize();
                this.fetchFavoriteInfo();
            }else {
                // お気に入り情報に絞っていない場合は、フォームの値をもとにSpectee APIより最新情報を取得する
                // フォームを表示する
                this.toggleOptionFilterButton.style.display = '';
                this.latest.domNode.style.display = '';
                this.latestLabel.style.display = '';
                this.searchPeriod.style.display = '';
                this.form.resize();

                var query = this.buildSpecteeQuery();
                this.fetchSpecteeInfo(query);
            }
        },

        allWardOn: function (){
            this.city27102.set('checked', true);
            this.city27103.set('checked', true);
            this.city27104.set('checked', true);
            this.city27106.set('checked', true);
            this.city27107.set('checked', true);
            this.city27108.set('checked', true);
            this.city27109.set('checked', true);
            this.city27111.set('checked', true);
            this.city27113.set('checked', true);
            this.city27114.set('checked', true);
            this.city27115.set('checked', true);
            this.city27116.set('checked', true);
            this.city27117.set('checked', true);
            this.city27118.set('checked', true);
            this.city27119.set('checked', true);
            this.city27120.set('checked', true);
            this.city27121.set('checked', true);
            this.city27122.set('checked', true);
            this.city27123.set('checked', true);
            this.city27124.set('checked', true);
            this.city27125.set('checked', true);
            this.city27126.set('checked', true);
            this.city27127.set('checked', true);
            this.city27128.set('checked', true);

            this.searchSpecteeInfo();
        },
        allWardOff: function (){
            this.city27102.set('checked', false);
            this.city27103.set('checked', false);
            this.city27104.set('checked', false);
            this.city27106.set('checked', false);
            this.city27107.set('checked', false);
            this.city27108.set('checked', false);
            this.city27109.set('checked', false);
            this.city27111.set('checked', false);
            this.city27113.set('checked', false);
            this.city27114.set('checked', false);
            this.city27115.set('checked', false);
            this.city27116.set('checked', false);
            this.city27117.set('checked', false);
            this.city27118.set('checked', false);
            this.city27119.set('checked', false);
            this.city27120.set('checked', false);
            this.city27121.set('checked', false);
            this.city27122.set('checked', false);
            this.city27123.set('checked', false);
            this.city27124.set('checked', false);
            this.city27125.set('checked', false);
            this.city27126.set('checked', false);
            this.city27127.set('checked', false);
            this.city27128.set('checked', false);

            this.searchSpecteeInfo();
        },
            
        /**
         * 検索条件に合致するSpectee情報を取得する。
         */
        fetchSpecteeInfo: function(query){
            Requester.get('/api/spectee/', {
                query: query
            }).then(lang.hitch(this, function(data) {
                console.log('SpecteeよりSNS情報を取得');
                // APIから返却されるレスポンスコードを元に処理する
                if(data.status === '200') {
                    this.lastFetchTime = data.fetchTime;
                    this.updateGrid(data.data);
                }else {
                    this.chain.info('SpecteeよりSNS情報を取得できませんでした。一分後に情報を自動取得します。', 'エラー');
                }

                // 画面初期表示時の処理が完了した段階でSpectee処理の開始フラグを有効にする
                // この処理により、画面初期表示時に日時フォームのchangeイベントにより複数回リクエストが飛ぶことを防いでいる
                if(!this.FETCH_SPECTEE_INFO_STARTED) {
                    this.FETCH_SPECTEE_INFO_STARTED = true;
                }
            }), lang.hitch(this, function(error) {
                this.chain.infoError(error);
            }));
        },

        /**
         * Spectee情報取得用のqueryを組み立てる。
         */
        buildSpecteeQuery: function (){
            var formValue = this.form.get('value');

            // フォームの入力値をArrayに変換する
            var formValueArray = Object.keys(formValue).map(function (key) {return formValue[key];});
            var query = {};
            query.prefIds = null;
            query.cityIds = null;
            query.eventIds = null;
            // 都道府県ID、市町村ID、対象・事象IDをカンマ区切りの文字列にする
            array.forEach(formValueArray ,function(value){
                if(/^(pref)/.test(value)) {
                    var prefValStr = value[0].replace(/pref/ , '' ) ;
                    query.prefIds = query.prefIds === null ? prefValStr : query.prefIds + ',' + prefValStr;
                } else if (/^(city)/.test(value)) {
                    var cityValStr = value[0].replace(/city/ , '' ) ;
                    query.cityIds = query.cityIds === null ? cityValStr : query.cityIds + ',' + cityValStr;
                } else if (/^(event)/.test(value)) {
                    var eveValStr = value[0].replace(/event/ , '' ) ;
                    query.eventIds = query.eventIds === null ? eveValStr : query.eventIds + ',' + eveValStr;
                }
            });

            // 期間検索のqueryを追加する
            var afterDate =
                this.form.get('value').postDateTimeFrom ? this.form.get('value').postDateTimeFrom.getTime() : 0;
            var beforeDate =
                this.form.get('value').postDateTimeTo ? this.form.get('value').postDateTimeTo.getTime() : 0;

            // SpecteeAPIでは検索対象期間(開始)に現在時刻以降を指定できないため
            // 現在時刻を超えた場合はアラートを表示し、強制的に現在時刻に変更する
            var now = new Date();
            if(this.form.get('value').postDateTimeFrom > now) {
                afterDate = now.getTime();
            }
            if(afterDate) {
                query.afterDate = locale.format(new Date(afterDate), {
                    selector: 'date',
                    datePattern: 'yyyy-MM-dd HH:mm:00'
                });
            }

            // Spectee APIの仕様上2018年1月27日午前0時0分以前は指定できないため、
            // フォームの値がそれ以前の日時の場合はqueryに追加しない
            var limitDateTime = new Date(2018, 1, 27, 0, 0, 0);
            if(this.form.get('value').postDateTimeTo <= limitDateTime) {
                beforeDate = now.getTime();
            }
            if(beforeDate) {
                query.beforeDate = locale.format(new Date(beforeDate), {
                    selector: 'date',
                    datePattern: 'yyyy-MM-dd HH:mm:00'
                });
            }

            // 事案中の最新報のみに絞っているかを判定する
            query.latest = this.form.get('value').latest[0] === '1' ? '1' : '0';
            // 最終情報取得日時をセットする
            // この日時と次回情報取得日時までの間に取得された情報が新着情報となる
            query.lastFetchTime = this.lastFetchTime;
            // 災害IDをセットする お気に入り情報に登録済みであるかを判定する際に使用する
            query.disasterId = this._disasterId;
            return query;
        },

        /**
         * お気に入り情報を取得する。
         */
        fetchFavoriteInfo: function() {
            // 災害名が選択されていない場合は処理しない
            if(!this._disasterId) {
                this.chain.info('災害名を選択してください', 'エラー');
                return;
            }
            Requester.get('/api/spectee/favorite/', {
                query: {disasterId : this._disasterId}
            }).then(lang.hitch(this, function(data) {
                console.log('登録情報を取得');
                this.updateGrid(data.items);
                // イベント定義時に自動的にSpecteeへのリクエスト処理が走るため
                // ページ初期表示時のリクエスト処理以降の処理であるかを判定する
                if(!this.FETCH_SPECTEE_INFO_STARTED) {
                    this.FETCH_SPECTEE_INFO_STARTED = true;
                }
            }), lang.hitch(this, function(error) {
                this.chain.infoError(error);
            }));
        },

        /**
         * お気に入り情報を登録する。
         */
        registerFavorite: function(item, lat, lng, latlngComfirmLevel) {
            // 災害名が選択されていない場合は処理しない
            if(!this._disasterId) {
                this.chain.info('災害名を選択してください', 'エラー');
                return;
            }
            var msg = latlngComfirmLevel !== this.LATLNG_COMFIRM_LEVEL_NONE ? '登録します。よろしいですか？' :
                '登録します。<br>住所から緯度経度を特定できなかったため、<br>地図上に情報が表示されませんがよろしいですか？';
            this.chain.confirm(msg, lang.hitch(this, function(chain) {
                var value = this._toSendValue(item, lat, lng, latlngComfirmLevel);
                Loader.wait(
                    // 取消処理を実行して取消後の返り値を保持する。
                    Requester.post('/api/spectee/favorite/', {
                        data: value
                    })).then(lang.hitch(this, function() {
                        chain.infoComplete();
                        this.searchSpecteeInfo();
                }), function(err) {
                    // 登録失敗
                    chain.infoError(err);
                });
            }));
        },

        /**
         * お気に入り情報を登録解除する。
         */
        removeFarorite: function(item) {
            // 災害名が選択されていない場合は処理しない
            if(!this._disasterId) {
                this.chain.info('災害名を選択してください', 'エラー');
                return;
            }
            var message = '登録を解除します。<br>' + 'よろしいですか？';
            this.chain.confirm(message, lang.hitch(this, function(chain) {
                Loader.wait(
                    // 取消処理を実行して取消後の返り値を保持する。
                    Requester.del('/api/spectee/favorite/' + item.newsId + '/' + this._disasterId, {
                    })).then(lang.hitch(this, function() {
                    chain.infoComplete();
                    this.searchSpecteeInfo();
                }), function(err) {
                    // 削除失敗
                    chain.infoError(err);
                });
            }));
        },

        /**
         * 検索条件エリアの表示・非表示を切り替える。
         */
        toggleFilterPane: function() {
            if (this.togglePane(this.container, this.filterPane)) {
                this.toggleFilterPaneButton.set('label', '表示');
            } else {
                this.toggleFilterPaneButton.set('label', '非表示');
            }
            domClass.toggle(this.toggleFilterPaneButton.domNode, 'on');
        },

        hideOptionFilterArea: false,
        /**
         * 詳細検索条件入力エリアの表示・非表示を切り替える。
         */
        toggleOptionFilter: function() {
            if (this.hideOptionFilterArea) {
                this.optionFilterArea.style.display = 'block';
                domClass.remove(this.toggleOptionFilterButton, 'icon-triangle-down');
                domClass.add(this.toggleOptionFilterButton, 'icon-triangle-up');
            } else {
                this.optionFilterArea.style.display = 'none';
                domClass.remove(this.toggleOptionFilterButton, 'icon-triangle-up');
                domClass.add(this.toggleOptionFilterButton, 'icon-triangle-down');
            }
            this.hideOptionFilterArea = !this.hideOptionFilterArea;
            // フォームの高さ固定を一旦解除
            this.form.set('style', {
                height: null
            });
            // 検索条件ペインの高さ固定を一旦解除
            this.filterPane.set('style', {
                height: null
            });
            this.mapPane.set('style', {
                height: null
            });
            domClass.toggle(this.toggleOptionFilterButton, 'on');
            // コンテナをリサイズ
            this.container.resize();
            this.mapPane.resize();
        },

        /**
         * 詳細画面に遷移する。
         */
        moveToDetailPage: function(evt) {
            if(this.marker) {
                this.removeMark();
            }
            // 事案IDを渡す
            if(evt && evt.item && evt.item.caseId) {
                var caseId = evt.item.caseId;
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('spectee/detail', {caseId: caseId});
            }
        }
    });
});
