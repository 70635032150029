/**
 * 招集グループ詳細画面用モジュール。
 * @module app/convocation/view/group/ConvoGroupDetailPage
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/json',
    'dojo/text!./templates/ConvoGroupDetailPage.html', // テンプレート文字列
    'dojo/query',
    'dojo/dom-style',
    'dojo/promise/all',
    'dojo/Deferred',
    'app/convocation/view/group/_ConvoGroupPageBase', // 共通基底クラス
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'idis/control/Router',
    'idis/control/Locator',
    'idis/service/Requester',
    'dstore/Memory',
    'idis/model/UserInfo',
    'idis/consts/ACL',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'app/view/form/MunicRegionOrganizationSelector',
    'app/convocation/view/group/ConvoGrpEmpFileUploadDialog'
], function(module, declare, lang, JSON, template, query, domStyle, all, Deferred, _PageBase,
    DialogChain, InfoDialog, Loader, Router, Locator, Requester, Memory, UserInfo, ACL) {
    /**
     * 招集グループ詳細画面。
     * @class ConvoGroupDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/ConvoGroupDetailPage~ConvoGroupDetailPage# */
        {
            // テンプレート文字列
            templateString: template,

            store: null,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            /**
             * 変数の初期化。
             */
            constructor: function() {
                this.chain = DialogChain.get(this);
                // URLからグループコードを取得
                this.groupCd = Locator.getQuery().id;
                this.store = new Memory({
                    'idProperty': 'dpEmployeeCd'
                });
            },

            /**
             * DOMノードを生成するためのメソッド。
             */
            buildRendering: function() {
                this.inherited(arguments);
                this.initDetailPage();
                this.initFileUploadDialog();
                this.organizationSelector.restoreRefresh();
            },

            startup: function() {
                this.inherited(arguments);
                this.setButtonStatus();
            },

            /**
             * 更新ボタンが押下された際の挙動。
             */
            onSubmit:function(){
                var formData = this.form.get('value');
                // formの入力チェック
                if (!this.form.validate()) {
                    return false;
                }

	            // 課コードの入力チェック
//                if (!this.validateNotForm()){
//                   return false;
//                }

	            // // 課コードの重複チェック
                if (!this.checkDuplicate()){
                   return false;
                }

                // 招集対象職員の職員番号の配列
                var employees = [];
                // gridの行数分処理する
                this.store.fetch().forEach(function(item) {
                    // 職員番号のみサーバー側に渡す
                    employees.push(item.employeeCd);
                });
                // requestに追加
                formData.employeeCds = employees;
                var role = UserInfo.getRoleCd();
                if (role !== ACL.ADMIN_USER) {
                    if (UserInfo.getOrganization().unitCd !== '') {
                        formData.divisionCd = UserInfo.getOrganization().unitCd;
                    } else if (UserInfo.getOrganization().sectCd !== '' && UserInfo.getOrganization().unitCd === '') {
	                    formData.divisionCd = UserInfo.getOrganization().sectCd;
                    } else {
                        formData.divisionCd = UserInfo.getOrganization().deptCd;
                    }
                }
                this.updateConvoGroup(formData);
                return false;
            },

            /**
             * 検索ボタンが押下された際の挙動。
             */
             updateGrid:function(){
                var value = this.formSearch.get('value');
                this.updateGridQuery(value);

                return false;
            },

            /**
             * @param value フォームから取得した情報
             * 職員管理タブ用の検索
             */
            updateGridQuery: function(value) {
                var requestParam = '';
                // ユーザID
                if (value.userId) {
                    requestParam += 'dpEmployeeCd=' + value.userId;
                }
                // 氏名(部分一致)
                if (value.name) {
                    if (requestParam !== '') {
                        requestParam += '&';
                    }
                    requestParam += 'name=' + value.name;
                }
                // 所属
                if (value.organizationSelector) {
                    if (requestParam !== '') {
                        requestParam += '&';
                    }
                    requestParam += 'organizationSelector=' + value.organizationSelector.slice(1);
                }
                // 動員区分
                if (value.mobilizationSection && value.mobilizationSection !== '0') {
                    if (requestParam !== '') {
                        requestParam += '&';
                    }
                    requestParam += 'mobilizationSection=' + value.mobilizationSection;
                }
                // メールアドレス
                if (value.mailAddress) {
                    if (requestParam !== '') {
                        requestParam += '&';
                    }
                    requestParam += 'mailAddress=' + value.mailAddress;
                }
                var promise = Requester.get('/api/convoMails/groups/' + this.groupCd + '/search?' + requestParam).then(
                        lang.hitch(this, function(data) {
                    // ストアクリア
                    this.storeClear();
                    var self = this;
                    // 職員をグリッドに追加する
                    data.employeeList.forEach(lang.hitch(this, function(employee) {
                        self.store.addSync({
                            parentName      : employee.parentName,
                            divisionName    : employee.divisionName,
                            employeeCd      : employee.employeeCd,
                            name            : employee.name,
                            mobilizationCd  : employee.mobilizationCd,
                            mobilizationType: employee.mobilizationType,
                            phoneNum        : employee.phoneNum,
                            phoneNum02      : employee.phoneNum02,
                            emailAddress01  : employee.emailAddress01,
                            emailAddress02  : employee.emailAddress02,
                            note            : employee.note,
                            registFlg       : employee.registFlg
                        });
                    }));
                    // グリッドを更新する
                    this.convoGroupRegisterGrid.refresh();
                }), lang.hitch(this, function(error) {
                    console.log(error);
                    this.chain.info('情報の取得に失敗しました。', 'エラー');
                }));
                // ローダの表示
                Loader.wait(promise);
            },

            /**
             * ストアクリア
             */
             storeClear:function(){
                var self = this;
                self.store.setData([]);
            },

            /**
             * @param formData form情報
             * グループ情報の更新処理。
             */
            updateConvoGroup: function(formData) {
                //更新確認ダイアログを表示
                this.chain.confirmPut(function(chain) {
                    var promise = Requester.put('/api/convoMails/groups/' + this.groupCd, {
                        data: formData
                    }).then(function() {
                        chain.infoComplete(function() {
                            // 一覧画面に移動
                            Router.moveTo('convocation/group');
                        });
                    }, function(error) {
                        console.log(error);
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
            },

            /**
             * 削除ボタンが押下された際の挙動。
             */
            deleteButtonClick: function() {
                //削除確認ダイアログの表示
                var messege = '削除します。よろしいですか？<br>' + '対象グループが0件となった配信テンプレートは合わせて削除されます。';
                this.chain.confirm(messege, function(chain) {
                    // DELETEメソッドを発行
                    var promise = Requester.del('/api/convoMails/groups/' + this.groupCd)
                    .then(function() {
                        chain.infoComplete(function() {
                            // 一覧画面に移動
                            Router.moveTo('convocation/group');
                        });
                    }, function(error) {
                        console.log(error);
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
            },

            /**
             * 詳細画面の初期化。
             */
            initDetailPage: function() {
                // 市本部ユーザ、区本部ユーザ、所属本部ユーザの場合、
                // UserIdに「honbu」を含み -c を含まないユーザだけ更新・削除を可能とする
                var role = UserInfo.getRoleCd();
                if (role === ACL.SHIHONBU_USER || role === ACL.KUHONBU_USER || role === ACL.XXHONBU_USER) {
                    var regexp = /^(?!.*-c).*(?=honbu).*$/;
                    if (!regexp.test(UserInfo.getId())) {
                        domStyle.set(this.employeeFileUploadDialog.domNode, 'display', 'none');
                        domStyle.set(this.employeeFileOutPutDialog.domNode, 'display', 'none');
                        domStyle.set(this.submitButton.domNode, 'display', 'none');
                        domStyle.set(this.deleteButton.domNode, 'display', 'none');
                    }
                }
                var promise = Requester.get('/api/convoMails/groups/' + this.groupCd).then(lang.hitch(this,
                        function(data) {
                    this.form.set('value', data);
                    var self = this;
                    // 職員をグリッドに追加する
                    data.employeeList.forEach(lang.hitch(this, function(employee) {
                        self.store.addSync({
                            parentName      : employee.parentName,
                            divisionName    : employee.divisionName,
                            employeeCd      : employee.employeeCd,
                            employeeNo      : employee.employeeNo,
                            mobilizationCd  : employee.mobilizationCd,
                            mobilizationType: employee.mobilizationType,
                            name            : employee.name,
                            phoneNum        : employee.phoneNum,
                            emailAddress01  : employee.emailAddress01,
                            emailAddress02  : employee.emailAddress02,
                            note            : employee.note,
                            registFlg       : employee.registFlg
                        });
                    }));
                    // グリッドを更新する
                    this.convoGroupRegisterGrid.refresh();
                }), lang.hitch(this, function(error) {
                    console.log(error);
                    this.chain.info('情報の取得に失敗しました。', 'エラー');
                }));
                // ローダの表示
                Loader.wait(promise);
            },

            /**
             * 職員情報
             * ファイルアプロードダイアログ初期化処理
             */
             initFileUploadDialog: function() {
                // 詳細ダイアログの最初の子要素が詳細画面
                var dialog = this.convoEmployeeFileUploadDialog;
                var page = dialog.getChildren()[0];
                // 更新ボタンが押された際の動作
                page.on('upload', lang.hitch(this, function() {
                    location.reload();
                }));
            },

            /**
             * ファイル読み込みダイアログを表示する。
             */
             showEmployeeFileUploadDialog: function() {
                this.convoEmployeeFileUploadDialog.show();
            },

            /**
             * 職員一覧出力ボタンが押下された際の挙動。
             */
             outputListExcel: function() {
                console.log('[帳票出力]ボタンがクリックされました。');
                var msg = 'グループ配下の職員一覧を出力します。<br>よろしいですか？';

                // 確認ダイアログを表示
                this.chain.confirm(msg, function(chain) {
                    // 確認ダイアログでOKを押した場合
                    chain.hide();

                    var reqid = 'DP_EMPLOYEE_LIST_EX';
                    var fileName = 'dpEmployeeList.xlsx';
                    var fileNameJp = '参集対象職員一覧.xlsx';
                    var paramList = [];
                    paramList.push({key: 'groupCd', value: this.groupCd});

                    var data4xoblosDownload = {
                        fileName: fileName,
                        reqid: reqid,
                        paramList: paramList
                    };

                    var promise = this.download(data4xoblosDownload, fileNameJp).then(lang.hitch(this, function() {
                            this.infoDialog = new InfoDialog({
                                title : '出力完了',
                                content : '職員一覧帳票の出力が完了しました。'
                            });
                            this.infoDialog.show();

                        }), lang.hitch(this, function(error) {
                            console.error(error);
                            this.infoDialog = new InfoDialog({
                                title : 'エラー',
                                content : 'エラーが発生しました。管理者にお問い合わせください。'
                            });
                            this.infoDialog.show();
                    }));

                    Loader.wait(promise);
                });
            },

            // 帳票のダウンロード
            download: function(data, fileNameJp) {
                var deferred = new Deferred();

                var xhr = new XMLHttpRequest();
                xhr.open('POST', '/api/xoblos/download', true);
                xhr.responseType = 'arraybuffer';
                xhr.setRequestHeader( 'Content-Type', 'application/json' );
                xhr.onload = function() {

                    // エラー時は処理を止める
                    if(xhr.status !== 200){
                        deferred.reject('status error:'+ xhr.status);
                        return;
                    }

                    var arrayBuffer = this.response;
                    var blob = new Blob([arrayBuffer], {type: 'application/octet-stream'});
                    // ファイル名を取得
                    var baseFileName = fileNameJp;
                    var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                    // IE10+
                    if(window.navigator.msSaveOrOpenBlob){
                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                    } else {
                        // 擬似的にAタグを作成
                        var link = document.createElement('a');
                        link.style = 'display: none';
                        document.body.appendChild(link);

                        // AタグのURLにバイナリデータをセット
                        var url = window.URL.createObjectURL(blob);
                        link.href = url;

                        // ファイル名をセット
                        link.download = fileName;

                        // 擬似的にリンクをクリック
                        link.click();
                        // 参照を解放 (iPadでのエラー回避のためsetTimeoutする)
                        setTimeout(function() {
                            window.URL.revokeObjectURL(url);
                            link.remove();
                        }, 200);
                    }

                    deferred.resolve();

                };
                xhr.send(JSON.stringify(data));

                return deferred.promise;
            },

            setButtonStatus: function(){
                this.submitButton.setDisabled(true);
                this.deleteButton.setDisabled(true);
                if (UserInfo.hasAuthz('F08001')) {
                    this.submitButton.setDisabled(false);
                }
                if (UserInfo.hasAuthz('F08001')) {
                    this.deleteButton.setDisabled(false);
                }
                // 訓練モードの場合、ファイル読み込み・職員追加・削除・更新ボタンを非表示とする
                if(UserInfo.isTrainingFlg()){
                    domStyle.set(this.employeeFileUploadDialog.domNode, 'display', 'none');
                    domStyle.set(this.addEmployeeSelector.domNode, 'display', 'none');
                    domStyle.set(this.deleteButton.domNode, 'display', 'none');
                    domStyle.set(this.submitButton.domNode, 'display', 'none');
                }
            }
        });
});
