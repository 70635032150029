/**
 * 災害情報詳細/更新画面用モジュール。
 * @module app/view/page/DisasterAdminRegisterPage
 */
define([
    'module',
    'dojo/json',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom',
    'dojo/dom-style',
    'dojo/dom-class',
    'dojo/_base/array',
    'dojo/text!./templates/DisasterAdminRegisterPage.html',
    'dojo/date/locale',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/service/Requester',
    'idis/view/Loader',
    'idis/model/UserInfo',
    'app/disaster/model/DisasterType',
    './DisasterDialogChain',
    // 以下、変数から参照されないモジュール
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/Textarea',
    'dijit/form/CheckBox',
    'dijit/form/DateTextBox',
    'dijit/form/Select',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/CheckGroup',
    'idis/view/form/RadioGroup',
    'idis/view/form/DateTimeInput',
    'dijit/Dialog',
    'app/view/form/ParentDisasterSelector',
    'app/waterworks/selectors/IncidentSelector',
], function (module, json, declare, lang, dom, domStyle, domClass, array, template, locale, InfoDialog, _PageBase,
    Requester, Loader, UserInfo, DisasterType, DisasterDialogChain) {
    /**
     * 災害情報登録画面。
     * @class DisasterAdminRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/view/page/DisasterAdmintPage~DisasterAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        constructor: function() {
            this.inherited(arguments);
            // 連鎖ダイアログを登録
            this.chain = DisasterDialogChain.get(this);
        },

        // テンプレートHTML内内でformに対し、data-dojo-attach-event="onSubmit:onSubmit"でこのメソッドを紐付け
        // 登録ボタンをtype="submit"にしているので、ボタン押下時にフォームがsubmitされて呼ばれる
        onSubmit: function() {
            try {
                // data-dojo-propsでの入力チェックと、画面独自の入力チェック
                if (this.form.validate() && this._validateCheck()) {
                    // 入力チェックが成功したらsendイベントを発行
                    var sendValue = this.form.get('value');
                    delete sendValue.parentDisasterSelector;
                    var parentDisaster = this.parentDisasterSelector.value;
                    if(parentDisaster.extraDisasterId !== 0) {
                        sendValue.extraDisasterId = this.parentDisasterSelector.value.extraDisasterId;
                        sendValue.extraDisasterName = this.parentDisasterSelector.value.extraDisasterName;
                    }

                    // 水道局災害名を設定
                    delete sendValue.incidentSelector;
                    var incidentValue = this.incidentSelector.value;
                    sendValue.incidentId = this.incidentSelector.value.incidentId;
                    sendValue.relatedFlg = this.incidentSelector.value.relatedFlg;

                    // 水道局災害名を選択していた場合、確認ダイアログを追加表示する
                    if(sendValue.relatedFlg === '1'){
                    
                        // ダイアログにメッセージを表示
                        this.chain.confirmIncidentAdd(incidentValue.incidentId, incidentValue.incidentName, 
                                                        locale.format(new Date(incidentValue.updTimestamp)), sendValue.disasterName, function(){
                            // 確認ダイアログを閉じる
                            this.chain.hide();
                            // 実際の登録処理はサンプル画面側でsendイベントを監視しているメソッドが実施
                            this.emit('send', {
                            // テンプレートHTML内でformに対し
                            // data-dojo-attach-point="form"を指定してthis.formとしてアクセス出来るようにしている
                            // value: this.form.get('value')
                            value: sendValue
                            });
                        });
                    }
                    else{
                        this.emit('send', {
                            // テンプレートHTML内でformに対し
                            // data-dojo-attach-point="form"を指定してthis.formとしてアクセス出来るようにしている
                            // value: this.form.get('value')
                            value: sendValue
                        });
                    }
                    
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        _validateCheck: function() {
            var infoDialogMsg = null;

            // エラーメッセージ表示
            if(infoDialogMsg){
                infoDialogMsg += 'が入力されていません。';
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : infoDialogMsg
                });
                this.infoDialog.show();
                return false;
            }
            return true;
        }, 

        initDialog: function() {
            this.form.reset();
            // 府災害名を初期化
            this.parentDisasterSelector.clear();
            // 水道局災害名を初期化
            this.incidentSelector.clear();
            // 災害種別を初期化
            this.initDisasterType();
        }, 

        /**
         * 災害種別を選択した後にダイアログを閉じて再表示すると、
         * 選択した値が初期値として残り、changeイベントまで
         */
        initDisasterType: function() {
            var disasterTypeList = [];
            array.forEach(DisasterType.data, function(disasterType) {
                disasterTypeList.push({value:disasterType.code, 
                    label:disasterType.label});
            });
            disasterTypeList.unshift({value: null,label: '&nbsp;'});
            this.disasterType.set('options', disasterTypeList);
            this.disasterType.textDirNode.innerText = '';
            this.disasterType._lastValueReported = null;
        }
    });
});