/**
 * 被害自動公開設定取得用モデル
 * @module app/model/Damage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/store/Memory',
    'idis/service/Requester'
], function(module, declare, lang, Deferred, Memory, Requester) {

    // 招集メール自動配信抑止・抑止解除APIのエンドポイント
    var API_ENDPOINT_AUTOPUB = '/api/disasters/autoPubFlg/';

    // モジュール定義
    var Damage = declare(module.id.replace(/\//g, '.'), null, {

        /**
         * 被害自動公開設定を取得する
         */
        getAutoPubInfo: function(id) {
            var deferred = new Deferred();
            // APIコール
            Requester.get(API_ENDPOINT_AUTOPUB + id).then(lang.hitch(this, function(data) {

                deferred.resolve(data);

            }), function(error) {
                deferred.reject('被害自動公開設定の取得に失敗しました。', error);
            });
            // プロミスを返却
            return deferred.promise;
        },

    });

    // シングルトン
    return new Damage();

});