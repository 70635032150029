/**
 * 配信情報テンプレート詳細ダイアログ
 * @module app/sending/SendingTemplateDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/SendingTemplateDetailDialog.html',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'idis/view/form/Button',
    'dijit/form/Select',
    'app/view/form/MunicipalitySelector',
    'dijit/form/ValidationTextBox',
    'idis/view/form/WordCountTextarea'
], function(module, declare, lang, template, Requester, DialogChain, InfoDialog, _PageBase, Loader) {
    /**
     * 配信情報テンプレート詳細ダイアログ
     * @class SendingTemplateDetailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
                   /** @lends module:app/view/page/SendingTemplateDetailDialog~SendingTemplateDetailDialog# */ {
        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page idis-Page--sending',

        _fileId: null,

        infoDialog : null,

        templateId: null,

        constructor: function() {
            // ベースクラスのコンストラクタを呼ぶ
            this.inherited(arguments);
            this.chain = DialogChain.get(this);
        },

        buildRendering: function() {
            this.inherited(arguments);
        },

        resize: function(changeSize, resultSize) {
            // ウィジェット内最上位要素のresizeを呼ぶことで、ダイアログ内のサイズ調整を行う。
            // このウィジェットはダイアログ内最上位のウィジェットであるため、
            // ダイアログを開く際にresizeメソッドが無いとサイズ調整が行われない
            this.form.resize(changeSize, resultSize);
        },

        /**
         * ダイアログ内容を初期化する。
         */
        initContent: function(item) {
            this.form.reset();
            this.form.set('value', item);
            this.templateId = item.sendingTemplateId;
        },

        onUpdate: function(){
            var data = this.form.get('value');
            if (!data.name) {
                InfoDialog.show('入力エラー', 'テンプレート名を入力してください。');
                return false;
            }
            if (!data.subject) {
                InfoDialog.show('入力エラー', '件名を入力してください。');
                return false;
            }
            if (!data.bodyText) {
                InfoDialog.show('入力エラー', '本文を入力してください。');
                return false;
            }
            this.chain.confirm('更新します。よろしいですか？', lang.hitch(this, function(chain) {
                var jsonStr = data;
                var promise = Requester.put('/api/sending/template/' + this.templateId, {
                    data: jsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function() {
                    console.debug('success register Sending');
                    chain.infoComplete(lang.hitch(this, function() {
                        this.chain.hide();
                        this.getParent().hide();
                        this.emit('complete', {
                            value: this.form.get('value')
                        });
                  }));
                }), lang.hitch(this, function(error) {
                    console.log('error register damagereport');
                    chain.infoError(error);
                    console.log(error);
                }));
                //ローダーの表示
                Loader.wait(promise);
            }));
        },

        onDelete: function(){
            this.chain.confirmDel(lang.hitch(this, function(chain) {
                var promise = Requester.del('/api/sending/template/' + this.templateId, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function() {
                    console.debug('success register Sending');
                    chain.infoComplete(lang.hitch(this, function() {
                        this.chain.hide();
                        this.getParent().hide();
                        this.emit('complete', {
                            value: this.form.get('value')
                        });
                    }));
                }), lang.hitch(this, function(error) {
                    console.log('error register damagereport');
                    chain.infoError(error);
                    console.log(error);
                }));
                //ローダーの表示
                Loader.wait(promise);
            }));
        }
    });
});
