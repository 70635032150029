/**
 * 河川区域 複数選択用パーツ。
 * @module app/evacorder/RiverAreaMultiSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/form/TreeChecker'
], function (module, declare, lang, domStyle, CacheStoreModel, CacheTreeRest, TreeChecker) {
    /**
     * 河川区域グループ用パーツ。
     * @class UsersSelector
     * @extends module:idis/view/form/TreeChecker~TreeChecker
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:idis/view/tree/RiverAreaMultiSelector~RiverAreaMultiSelector# */ {
            // 選択ダイアログのタイトル
            title: '河川区域選択',
            // ツリー・モデル
            model: null,
            selectBtnLbl: '河川選択',
            // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
            constructor: function () {
                this._initModel();
            },


            _initModel: function () {
                this.model = new CacheStoreModel({
                    store: new CacheTreeRest({
                        target: 'api/river/riverAreaTree'
                    })
                });
            },

            // DOMを構築する
            buildRendering: function () {
                this.inherited(arguments);
                // 選択後の値ラベルは非表示
                this.status.style.display = 'none';
                // 選択ボタンのラベルを強制的に変更
                this.selectButton.domNode.querySelector('.dijitButtonText').innerHTML = '河川選択';
            },
            /**
             * 選択した値の設定
             */
            _setValueAttr: function (value) {
                // TreeChecker本来の処理
                this.inherited(arguments);

                var obj = value.map(lang.hitch(this, function (v) {
                    return this.model.items.filter(function (i) {
                        return i.id === v;
                    });
                }));
                // OnChangeイベントが使えるようにする
                this.emit('change', { value: obj });
            }
        });
});

