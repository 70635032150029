/**
* 被害状況登録画面用モジュール。
* @module app/damage/damageReportRegister/DamageReportRegisterPage
*/
define([
    'module',
    'app/model/LayerStore',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-style',
    'dojo/json',
    'dojo/text!./templates/DamageReportRegisterPage.html',
    'dojo/topic',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/IdisDialog',
    'idis/view/Loader',
    'app/config',
    '../_DamageReportRegisterPageBase',
    'dojox/lang/functional/array',
    'idis/view/dialog/InfoDialog',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/form/SimpleTextarea',
    'dijit/form/ValidationTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'dojox/form/Uploader',
    'dojox/layout/FloatingPane',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/RadioGroup',
    'idis/view/form/WordCountTextarea',
    'app/view/form/OrganizationSelector',
    'app/view/form/HldOrganizationMultiSelector',
    'app/view/form/DamageMunicipalitySelector',
    'app/damage/damagetype/DamageGridTypeSelector',
    '../damageReportNumber/DamageReportNumberDialog',
    '../humanDamageReport/HumanDamageReportDialog'
], function (module, LayerStore, declare, lang, array,
    domStyle, json, template, topic,
    Locator, Router, UserInfo, UserType, Requester, DialogChain, IdisDialog,
    Loader, config, _DamageReportRegisterPageBase, df, InfoDialog) {

    /**
    * 被被害状況新規登録画面。
    * @class DamageReportRegisterPage
    * @extends module:app/damage/_DamageReportRegisterPageBase~_DamageReportRegisterPageBase
    */
    return declare(module.id.replace(/\//g, '.'), _DamageReportRegisterPageBase,
        /** @lends module:app/damage/damageReportRegister/DamageReportRegisterPage~DamageReportRegisterPage# */ {
            // テンプレート文字列
            templateString: template,

            // 道路交通規制情報取込フラグ
            trafficImportFlg: 0,

            // 被害情報投稿取込フラグ
            spotImageImportFlg: 0,

            _municCdForSpecialCity: null,

            _confirmContext: null,

            _oldMunicipalityCd: null,

            // （特別市ユーザー）市町村セレクターと発生場所の市町村が同じかどうか
            _diffMunicFlg: false,

            // 災害シミュレーション用
            _scenarioId: null,
            _simulateFlg: false,
            _eventType: null,
            _content: null,
            _timing: null,

            _numberData: null,

            _recentAttendeesFlg: false,
            // #12657追加▼
            /**
             * 画像リスト
             */
            lstImage: [],
            // #12657追加▲

            /**
             * constructor
             */
            constructor: function () {
                this._scenarioId = Locator.getQuery().scenarioId;
                this._simulateFlg = Locator.getQuery().simulateFlg;
                this._eventType = Locator.getQuery().eventType;
                this._content = Locator.getQuery().content;
                this._timing = Locator.getQuery().timing;
            },

            /**
             * startup
             */
            startup: function () {
                console.log('startup');
                this.inherited(arguments);

                this.initMunicipalitySelector();

                this.own(topic.subscribe(this.DAMAGE_GRID_TYPE_SELECTED, lang.hitch(this, function (data) {
                    this.setDamageType(data);
                })));

                this.own(topic.subscribe(this.DAMAGE_GRID_TYPE_RELEASED, lang.hitch(this, function (data) {
                    this.releaseDamageType(data);
                })));

                this.own(this.innerDamageGridTypeSelector);

                this.damageReportNumberDialog.on('send', lang.hitch(this, function (evt) {
                    this._numberData = evt.formData;
                    //数値情報の全ての項目が0の場合、未入力ラベルを表示する
                    if(this.checkNumberData(this._numberData)){
                        this.numberEntered.style.display = 'none';
                        this.numberNotEntered.style.display = '';
                    //1項目でも1以上の数値の場合、数値入力がある項目のラベル編集処理を呼び出す
                    }else{
                        this.numberEntered.style.display = '';
                        this.numberNotEntered.style.display = 'none';
                        this.setNumberDataLabel(this._numberData);
                    }
                }));
                this.own(this.innerDamageReportNumber);

                // 被害状況新規登録画面を初期化
                this.initRegisterPage();

            },

            /**
             * 市町セレクタを設定する。
             */
            initMunicipalitySelector: function () {

                if (Locator.getQuery().municipalityCd) {
                    // 概況画面から遷移した場合は、選択されていた市町村を設定する。
                    this._municipalityCd = Locator.getQuery().municipalityCd;
                } else if (UserInfo.getUserType() === UserType.MUNICIPALITY) {
                    // 市町ユーザの場合、自分の市町を設定
                    this._municipalityCd = UserInfo.getMunicipalityCd();
                } else if (UserInfo.getUserType() === UserType.OTHER_ORGAN) {
                    // 市町ユーザの場合、自分の市町を設定
                    this._municipalityCd = UserInfo.getMunicipalityCd();
                }

                // 大阪市：区役所ユーザは区の変更が不可
                if (UserInfo.getApprovalType() !== "1") {
                    this.overviewLabel.style.display = 'none';
                }

                if (UserInfo.getMobilizationType() > 50 && UserInfo.getMobilizationType() < 75){
                    Requester.get('/api/recentattendees/mobilization/' + UserInfo.getMobilizationType())
                    .then(lang.hitch(this, function (ramu) {
                        if (ramu.municipalityCd.toString() !== UserInfo.getMunicipalityCd()){
                            this._recentAttendeesFlg = true;
                        }

                        if (this._recentAttendeesFlg === true){
                            this.municipalitySelectForm.style.display = 'none';
                            this.raMunicipalityCd.set('options', [
                                {
                                    value: UserInfo.getMunicipalityCd(), label: UserInfo.getMunicipalityName()
                                },
                                {
                                    value: ramu.municipalityCd, label: ramu.municipalityName
                                }]);
                    
                            //市町セレクタがある場合、初期表示を設定する。
                            if (this.municipalityCd && this._municipalityCd) {
                                this.raMunicipalityCd.set('value', this._municipalityCd);
                            }

                        }else{
                            this.raMunicipalitySelectForm.style.display = 'none';

                            //市町セレクタがある場合、初期表示を設定する。
                            if (this.municipalityCd && this._municipalityCd) {
                                this.municipalityCd.set('value', this._municipalityCd);
                            }
                            // 大阪市：区役所ユーザは区の変更が不可
                            if (UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                                this.municipalityCd.set('noAllButton', true);
                                this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
                            }       
                        }
                        
                    }), function (error) {
                        self.chain.infoError(error);
                    });
                }else{
                    this.raMunicipalitySelectForm.style.display = 'none';

                    //市町セレクタがある場合、初期表示を設定する。
                    if (this.municipalityCd && this._municipalityCd) {
                        this.municipalityCd.set('value', this._municipalityCd);
                    }
                    // 大阪市：区役所ユーザは区の変更が不可
                    if (UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd) {
                        this.municipalityCd.set('noAllButton', true);
                        this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
                    }
    

                }

            },

            /**
             * 市町村コードを再セットする。
             */
            onMunicipalityChange: function () {
                console.debug('市町村が変更されました');
                if (this._recentAttendeesFlg === true){
                    this._municipalityCd = this.raMunicipalityCd.value;
                }else{
                    this._municipalityCd = this.municipalityCd.get('value');
                }

                var self = this;

                //地図の緯度経度と住所情報を変更
                // 道路交通規制情報取込の場合は、変更しない
                // 被害情報投稿からの取込の場合は、被害情報投稿の位置情報を設定するため変更しない
                if (this.map && (this.trafficImportFlg === 0 && this.spotImageImportFlg === 0)) {
                    // 特別市ユーザーかつ市町村セレクターと発生場所の市町村が異なる場合は、変更しない
                    if (UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd &&
                        this._diffMunicFlg !== true) {
                        Requester.get('/api/municipalities/' + self._municipalityCd, {
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(function (municipality) {
                            // マップを初期化
                            if (municipality.latitude && municipality.longitude) {
                                self.map.setView([municipality.latitude, municipality.longitude]);
                            }
                        }, function (error) {
                            console.log(error);
                            self.chain.info('情報の取得に失敗しました。', 'エラー');
                        });
                    }
                }
                // 大阪市：水害のレイヤー設定
                this.resetLifelineDistrictLayer();

                this._oldMunicipalityCd = this._municipalityCd;

                // 発生場所を設定（市町村まで）
                // 被害情報投稿からの取込の場合は、被害情報投稿の位置情報から設定するためここでは設定しない
                if (!this.damageAddress.value && this.spotImageImportFlg === 0) {
                    this.setDamageAddressFromMunicCd(this._municipalityCd);
                }
            },

            /**
             * 被害状況新規登録画面を初期化する。
             */
            initRegisterPage: function () {

                console.log('initRegisterPage');
                //周辺被害を非表示として初期化
                this.showMarkersFlg = false;

                //諸変数の初期化
                this.pointLat = '';
                this.pointLng = '';
                this.marker = '';
                this.attachFileList = [];

                if (Locator.getQuery().damageReportId) {
                    // 監視ページ詳細の被害情報投稿からの取込の場合、位置情報、項目をセット
                    this.setSpotImage(Locator.getQuery());
                } else if (this._municipalityCd) {
                    // 市町が与えられている場合、市町の位置情報を受け取る
                    this.setDamageAddressFromMunicCd(this._municipalityCd);
                } else {
                    // 地図が与えられていない場合は県の代表点で初期化
                    this.initMap(config.map.latitude, config.map.longitude);
                }

                // 所管にログインユーザの組織情報を設定 複数選択できるツリーなので、子組織を持つときは子もすべてチェックされた状態にしなければいけない
                var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
                    UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
                        UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');
                // 報告日時欄に、現在時刻を自動セットする
                this.reportCrtTimestamp.set('value', new Date().getTime());

                //数値情報の入力状況を設定（初期値）
                this.numberEntered.style.display = 'none';
                this.numberNotEntered.style.display = '';

                // プレビューを初期化
                this.clearPreview();

                // 報告部署にログインユーザの組織情報を設定
                this.reportOrganization.set('value', orgCd);
                this.reportAuthorName.innerHTML = UserInfo.getName();

                // 被害種別・人的被害有無の変更時イベントを設定
                this.controlDamageDetailContents();


                // 交通規制情報の取込の場合、規制情報をセット
                this.setTrafficRegulations(Locator.getQuery());

                // 災害シミュレーションの場合添付ファイル、作図を不可視にする
                if (this._simulateFlg === 'true') {
                    domStyle.set(this.attachFileItem, 'display', 'none');
                }

            },

            setDamageAddressFromMunicCd: function (municCd) {
                self = this;
                Requester.get('/api/municipalities/' + municCd, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(function (data) {
                    var latitude = config.map.latitude;
                    var longitude = config.map.longitude;
                    if (data && data.latitude && data.longitude) {
                        latitude = data.latitude;
                        longitude = data.longitude;
                    }
                    // 地図を初期化
                    if (!self.map) {
                        self.initMap(latitude, longitude);
                    }
                    // 発生場所に市町村までデフォルト入力
                    self.damageAddress.set('value', data.municipalityName);
                    self.damageAddressToMap();
                }, function (error) {
                    self.chain.infoError(error);
                });
            },

            /**
             * 対応状況のStyleをセットする
             */
            setHldStatusStyle: function (hldStatusDomNode, hldStatus) {
                if (hldStatus === '0') {
                    domStyle.set(hldStatusDomNode.children[0], 'color', 'black');
                    domStyle.set(hldStatusDomNode.children[0], 'font-weight', 'bold');
                    domStyle.set(hldStatusDomNode.children[1], 'color', 'gray');
                    domStyle.set(hldStatusDomNode.children[2], 'color', 'gray');
                    domStyle.set(hldStatusDomNode.children[3], 'color', 'gray');
                } else if (hldStatus === '1') {
                    domStyle.set(hldStatusDomNode.children[0], 'color', 'blue');
                    domStyle.set(hldStatusDomNode.children[1], 'color', 'black');
                    domStyle.set(hldStatusDomNode.children[1], 'font-weight', 'bold');
                    domStyle.set(hldStatusDomNode.children[2], 'color', 'gray');
                    domStyle.set(hldStatusDomNode.children[3], 'color', 'gray');
                } else if (hldStatus === '2') {
                    domStyle.set(hldStatusDomNode.children[0], 'color', 'blue');
                    domStyle.set(hldStatusDomNode.children[1], 'color', 'blue');
                    domStyle.set(hldStatusDomNode.children[2], 'color', 'black');
                    domStyle.set(hldStatusDomNode.children[2], 'font-weight', 'bold');
                    domStyle.set(hldStatusDomNode.children[3], 'color', 'gray');
                } else if (hldStatus === '3') {
                    domStyle.set(hldStatusDomNode.children[0], 'color', 'blue');
                    domStyle.set(hldStatusDomNode.children[1], 'color', 'blue');
                    domStyle.set(hldStatusDomNode.children[2], 'color', 'blue');
                    domStyle.set(hldStatusDomNode.children[3], 'color', 'black');
                    domStyle.set(hldStatusDomNode.children[3], 'font-weight', 'bold');
                }
            },

            /**
             * 被害状況を登録する。
             */
            registerDamageReport: function () {
                var sendData = this.form.get('value');

                // 被害種別
                if (!this._damageType) {
                    console.debug('被害の種類が選択されていません');
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title: 'エラー',
                            content: '被害の種類が選択されていません'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
                    return false;
                }

                if(this._recentAttendeesFlg === true){
                    // 入力チェック
                    // 行政区のチェック
                    if (this.raMunicipalityCd.value === '') {
                        //行政区が未選択の場合は処理を中断
                        InfoDialog.show('入力チェック', '区が選択されていません');
                        return false;
                    }
                }else{
                    // 入力チェック
                    // 行政区のチェック
                    if (this.municipalityCd.status.innerText === '未選択') {
                        //行政区が未選択の場合は処理を中断
                        InfoDialog.show('入力チェック', '区が選択されていません');
                        return false;
                    } else if (this.municipalityCd.status.innerText === '大阪市') {
                        //行政区が大阪市の場合は処理を中断
                        InfoDialog.show('入力チェック', '区が選択されていません');
                        return false;
                    }
                }

                //状況報告（damageType = 19）以外の場合の入力チェック
                if(this._damageType !== '19') {
                    // 被害規模
                    if(sendData.damageScale === ""){
                        console.debug('被害規模が選択されていません');
                        if(!this.infoDialog){
                            this.infoDialog = new InfoDialog({
                                title: 'エラー',
                                content: '被害規模が選択されていません'
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                        return false;
                    }

                    // 深刻度
                    if(sendData.urgencyType === ""){
                        console.debug('深刻度が選択されていません');
                        if(!this.infoDialog){
                            this.infoDialog = new InfoDialog({
                                title: 'エラー',
                                content: '深刻度が選択されていません'
                            });
                        }
                        this.infoDialog.show();
                        this.infoDialog = null;
                        return false;
                    }
                }   

                // 大阪市 報告者氏名
                sendData.reportAuthorName = this.reportAuthorName.innerText;

                // 大阪市 電気
                sendData.elecDmgFlg = parseInt(this.elecDmgFlg.value);
                // 大阪市 水道
                sendData.gasDmgFlg = parseInt(this.gasDmgFlg.value);
                // 大阪市 ガス
                sendData.waterDmgFlg = parseInt(this.waterDmgFlg.value);
                // 大阪市 電話
                sendData.phoneDmgFlg = parseInt(this.phoneDmgFlg.value);

                // 大阪市 市民利用施設
                sendData.publicFacility = this.publicFacility.get('checked');

                sendData.damageType = this._damageType;
                sendData.damageTypeList = this._damageTypeList;

                sendData.multiWardDamageFlg = this.multiWardDamageFlg.checked;
                if(sendData.multiWardDamageFlg){
                    if(!sendData.multiMunicipalityCd1 && !sendData.multiMunicipalityCd2 && !sendData.multiMunicipalityCd3) {
                        //未選択の場合エラー
                        InfoDialog.show('入力チェック', '複数区にまたがる被害の登録ですが、区2・区3・区4が未選択です');
                        return false;
                    }
                    if(sendData.multiMunicipalityCd1) { // 区2が選択されている場合
                        if(sendData.multiMunicipalityCd1 === sendData.municipalityCd ||
                            sendData.multiMunicipalityCd1 === sendData.multiMunicipalityCd2 ||
                            sendData.multiMunicipalityCd1 === sendData.multiMunicipalityCd3) { // 区2がほかの区と同値の場合
                        //同値の場合エラー
                        InfoDialog.show('入力チェック', '区2が他の区と重複しています。');
                        return false;
                        }
                    }
                    if(sendData.multiMunicipalityCd2) { // 区3が選択されている場合
                        if(sendData.multiMunicipalityCd2 === sendData.municipalityCd ||
                            sendData.multiMunicipalityCd2 === sendData.multiMunicipalityCd1 ||
                            sendData.multiMunicipalityCd2 === sendData.multiMunicipalityCd3) { // 区3がほかの区と同値の場合
                        //同値の場合エラー
                        InfoDialog.show('入力チェック', '区3が他の区と重複しています。');
                        return false;
                        }
                    }
                    if(sendData.multiMunicipalityCd3) { // 区4が選択されている場合
                        if(sendData.multiMunicipalityCd3 === sendData.municipalityCd ||
                            sendData.multiMunicipalityCd3 === sendData.multiMunicipalityCd1 ||
                            sendData.multiMunicipalityCd3 === sendData.multiMunicipalityCd2) { // 区4がほかの区と同値の場合
                        //同値の場合エラー
                        InfoDialog.show('入力チェック', '区4が他の区と重複しています。');
                        return false;
                        }
                    }

                }

                // 作図をJSON化する
                topic.publish(this.JSONIZE_RQST);

                if (sendData.damageAddress) {
                    sendData.damageLatitude = this._damageAddressPointLat;
                    sendData.damageLongitude = this._damageAddressPointLng;
                } else {
                    sendData.damageLatitude = '';
                    sendData.damageLongitude = '';
                }

                if (!sendData.damageLatitude || !sendData.damageLongitude) {
                    InfoDialog.show('入力チェック', '地図の位置情報がありません。<br />住所または、地図から位置情報を設定してください。');
                    return false;
                }

                //フォーム上で入力させない項目を格納
                if (!sendData.reportName) {
                    this.setReportName(sendData);
                }
                sendData.disasterId = this._disasterId;

                if(this._recentAttendeesFlg === true){
                    sendData.municipalityCd = this.raMunicipalityCd.value;
                }else{
                    sendData.municipalityCd = this._municipalityCd;
                }
                
                delete sendData.raMunicipalityCd;

                sendData.seqNum = 1;
                sendData.parentAdmNum = null;
                sendData.extAdmNum = null;
                sendData.reportStatus = this._reportStatus || 12;
                // 「確定フラグ」「本部確認状況フラグ」は現段階では使わないので固定値をセット
                sendData.fixFlg = '1';
                sendData.headquarterCheckFlg = '0';
                sendData.reportOrganization = this.reportOrganization._getValueAttr();

                // 大阪市：数値登録のダイアログ入力結果
                sendData.numberData = this._numberData;
                if (this._numberData && Array.isArray(sendData.numberData.humanDamageList)) {
                    // 送信データに不要なgridRowIdの項目を削除する
                    sendData.numberData.humanDamageList =
                        sendData.numberData.humanDamageList.map(function (item) {
                            delete item.gridRowId;
                            return item;
                        });
                }

                // 大阪市 断水地区一覧
                sendData.lifelineDistList = this.getLifelineDistList();

                // #12657変更▼
                // //添付ファイルIDをセット
                // if (this.attachFileList.length > 0) {
                //     var attachFileIds = [];
                //     for (var i = 0; i < this.attachFileList.length; i++) {
                //         attachFileIds.push(this.attachFileList[i].damageReportAtcFileId);
                //     }
                //     sendData.attachFileIds = attachFileIds.join(',');
                // } else {
                //     sendData.attachFileIds = '';
                // }

                // 添付ファイル関連の設定
                if (this.attachFileList.length > 0) {
                    var attachFileIds = []; // ファイルID
                    var publicFlgs = [];    // 公開フラグ
                    for (var i = 0; i < this.attachFileList.length; i++) {
                        var attachFile = this.attachFileList[i];
                        attachFileIds.push(attachFile.damageReportAtcFileId);
                        var fileData = this.lstImage[i];
                        publicFlgs.push(fileData[2]);
                    }
                    sendData.attachFileIds = attachFileIds.join(',');
                    sendData.publicFlgs = publicFlgs.join(',');
                } else {
                    sendData.attachFileIds = '';
                    sendData.publicFlgs = '';
                }
                // #12657変更▲

                delete sendData.attachFile;

                // フォームのバリデーションを行う（共通部品）
                if (!this.form.validate()) {
                    return false;
                }
                // フォームのバリデーションを行う（独自チェック）
                if (!this.validateForm(sendData)) {
                    return false;
                }

                var jsonStr = null;
                // 災害シミュレーションのイベントとして登録する場合
                if (this._simulateFlg === 'true') {
                    var subData = {};
                    subData.municipalityName = this.municipalityCd.domNode.innerText.replace('選択', '');
                    subData.damageTypeNameList = this.DamageGridTypeSelector.innerText.replace('選択', '').replace('解除', '');
                    subData.urgencyTypeName = this.urgencyType.domNode.innerText;
                    switch (this._reportStatus) {
                        case '11':
                            subData.reportStatusName = this.reportedStatus11.innerHTML;
                            break;
                        case '12':
                            subData.reportStatusName = this.reportedStatus12.innerHTML;
                            break;
                        case '31':
                            subData.reportStatusName = this.reportedStatus31.innerHTML;
                            break;

                        case '32':
                            subData.reportStatusName = this.reportedStatus32.innerHTML;
                            break;
                        case '41':
                            subData.reportStatusName = this.reportedStatus41.innerHTML;
                            break;
                        default:
                    }
                    switch (this.form.value.hldStatus) {
                        case '0':
                            subData.hldStatusName = '確認中';
                            break;
                        case '0':
                            subData.hldStatusName = '対応待ち';
                            break;
                        case '0':
                            subData.hldStatusName = '対応中';
                            break;
                        case '0':
                            subData.hldStatusName = '対応済み';
                            break;
                        default:

                    }
                    jsonStr = json.stringify({
                        damageReportPostForm: sendData,
                        damageReportConversionForm: subData,
                        scenarioId: this._scenarioId,
                        eventType: this._eventType,
                        content: this._content,
                        timing: this._timing
                    });
                    var message = 'イベント登録します。';
                    this.chain.confirm(message, function (chain) {
                        var promise = Requester.post('/api/simulations/event/', {
                            data: jsonStr,
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(lang.hitch(this, function () {
                            console.debug('success register Chronology event');
                            chain.infoComplete(function () {
                                Router.moveTo('simulation/event', { scenarioId: this._scenarioId });
                            });
                        }, function (error) {
                            console.log('error register Chronology event');
                            chain.infoError(error);
                        }));
                        //ローダーの表示
                        Loader.wait(promise);
                    });
                } else {

                    jsonStr = json.stringify(sendData);
                    var self = this;
                    //登録確認ダイアログを表示
                    console.log('here');
                    var msg;
                    if( this._damageType !== '19' && (sendData.damageScale !== '0' || sendData.urgencyType !== '0') && (sendData.numberData === null || this.checkNumberData(sendData.numberData))){
                        msg = '数値情報が未登録です。<br>このまま被害情報を登録しますか？';
                    }else{
                        msg = '登録します。よろしいですか？';
                    }

                    // 区本部ユーザで、単数区の被害(被害種別 !== 状況報告(19))の場合
                    //if (UserInfo.getRoleCd() === "R02022" && this.multiWardDamageFlg.checked === false && this._damageType !== '19') {
                    if (UserInfo.getRoleCd() === "R02022" &&
                        this.multiWardDamageFlg.checked === false &&
                        this._damageType !== '19') {
                        msg = '被害情報を登録し、大阪府システムに送信します。よろしいですか？';
                    }

                    this.chain.confirm(msg, function (chain) {
                        var promise = Requester.post('/api/damageReports', {
                            data: jsonStr,
                            headers: { 'Content-Type': 'application/json; charset=utf-8' },
                            handleAs: 'json',
                            preventCache: true
                        }).then(function (data) {
                            console.debug('success register DamageReport');
                            message = '完了しました。';
                            // 作図情報を登録
                            var param = '?damageReportId=' + data.damageReportId;
                            return Requester.post('/api/damageReports/uploadjson' + param, {
                                data: self.drawJson,
                                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                                handleAs: 'json',
                                preventCache: true
                            }).then(function () {
                                console.debug('success upload json');
                                //chain.infoComplete(function() {
                                chain.info(message, '完了', function () {
                                    //ツリーの更新
                                    LayerStore.refreshAll();
                                    // 一覧画面に移動
                                    // #12657変更（添付ファイル登録時完了ダイアログで複数回OKボタンを押さないと戻れない問題対応）▼
                                    // this.leave();
                                    Router.moveTo('report', {municipalityCd: this._municipalityCd});
                                    // #12657変更▲
                                });
                            }, function (err) {
                                console.log(err);
                                chain.info('作図ファイルの登録に失敗しました。', 'エラー', function () {
                                    // 一覧画面に移動
                                    // #12657変更（添付ファイル登録時完了ダイアログで複数回OKボタンを押さないと戻れない問題対応）▼
                                    // this.leave();
                                    Router.moveTo('report', {municipalityCd: this._municipalityCd});
                                    // #12657変更▲
                                });
                            });
                        }, function (error) {
                            console.log('error register damagereport');
                            chain.infoError(error);

                        });
                        //ローダーの表示
                        Loader.wait(promise);
                    });
                    console.debug('[登録]ボタンがクリックされました。');
                }
            },

            setTrafficRegulations: function (data) {
                if (!data.id) {
                    return;
                }
                this.trafficImportFlg = 1;
                // 被害種別に道路(09)をセット＆変更不可
                this.innerDamageGridTypeSelector.change({ mainTypeIds: ['09'] });
                domStyle.set(this.innerDamageGridTypeSelector.clearButton.domNode, 'display', 'none');
                // 道路名＝路線名
                this.roadDamageName.set('value', data.roadName);
                // 道路種別＝路線名の頭で判断
                var rodGroupType = {
                    '市道': 1,
                    '道道': 2,
                    '国道': 3,
                    '農道': 4
                }[data.roadName.slice(0, 2)] || 5;
                if (data.roadName.slice(0, 4) === '一般道道') {
                    this.rodGroupType.set('value', 2);
                } else {
                    this.rodGroupType.set('value', rodGroupType);
                }
                // 道路被害種別＝規制種別
                var rodDmgType = {
                    1: 2, //全面通行禁止
                    2: 6, //大型車通行止め
                    3: 3, //片側通行禁止
                    4: 7, //車線・路肩規制
                    5: 8  //歩道規制
                }[data.kind] || 9;
                this.rodDmgType.set('value', rodDmgType);
                // 備考
                if (data.note) {
                    this.comment.set('value', data.note);
                    this.commonTitlePane.set('open', true);
                }
                // 項目のない連携情報は原因／内容に記載
                var content = [];
                content.push('[規制区間]' + data.address);
                content.push('[原因]' + data.reason);
                content.push('[規制期間]' + data.period);
                content.push('[管理者等]' + data.management);
                this.content.set('value', content.join('\n'));
                // 緯度経度をセット
                this.pointLat = data.lat;
                this.pointLng = data.lng;
                this.addMark(this.pointLat, this.pointLng);
                this.map.setView([this.pointLat, this.pointLng]);
                this.mapToDamageAddress();
            },

            showDamageReportNumberDialog: function () {
                this.damageReportNumberDialog.show();
            },

            /**
             * 被害情報投稿から取り込む値をセットする
             */
            setSpotImage: function (data) {
                if (!data.damageReportId) {
                    return;
                }
                this.spotImageImportFlg = 1;

                // 被害情報を取得（添付ファイルを新規登録用にコピーする）
                var self = this;
                Requester.post('/api/damageReports/copyAtcFile/' + data.damageReportId, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(function (data) {
                    self._municipalityCd = data.municipalityCd;
                    self.municipalityCd.set('value', data.municipalityCd);
                    self.damageAddress.set('value', data.damageAddress);
                    self.damageScale.set('value', data.damageScale);
                    self.urgencyType.set('value', data.urgencyType);
                    self.comment.set('value', data.comment);
                    // 地図を設定
                    var lat = data.damageLatitude;
                    var lng = data.damageLongitude;
                    self.pointLat = lat;
                    self.pointLng = lng;
                    self._damageAddressPointLat = lat;
                    self._damageAddressPointLng = lng;
                    if (self.map) {
                        self.addMark(lat, lng);
                        self.map.setView([lat, lng]);
                    } else {
                        self.initMap(lat, lng);
                    }
                    // 緯度経度から発生場所を設定
                    self.mapToDamageAddress();
                    // 添付ファイルを設定
                    if (data.attachFiles) {
                        for (var i = 0; i < data.attachFiles.length; i++) {
                            self.attachFileList.push(data.attachFiles[i]);
                            self.showPreview(data.attachFiles[i], false);
                        }
                    }
                    // 被害の数値データ反映
                    self.innerDamageReportNumber.form.set('value', data.numberData);
                    if (data.numberData && data.numberData.humanDamageList) {
                        self.innerDamageReportNumber._humanDamageList = data.numberData.humanDamageList;
                    } else {
                        self.innerDamageReportNumber._humanDamageList = [];
                    }

                }, function (error) {
                    console.log(error);
                    self.chain.info('情報の取得に失敗しました。', 'エラー');
                });

            },
            onChangeFacility: function () {
                var _facilityId = this.facilityId.get('value');
                Loader.wait(
                    Requester.get('/api/facilityMaster/' + _facilityId)
                        .then(lang.hitch(this, function (data) {
                            console.log(data);
                            this.damageAddress.set('value', data.address);
                            this.publicFacility.set('checked', data.publicAvailableFlg);
                            this.damageAddressToMap();
                        })));
            },
            /**
             * 複数区の被害がチェックされたとき
             */
            changeMultiWard: function () {
                this.multiMunicipalitySection.style.display = this.multiWardDamageFlg.checked ? '' : 'none';
            }
        });
});
