/**
 * 市町村・災害種別別配備体制及び設置本部カテゴリー
 * @module app/disasterprevention/view/model/DisasterPreventStatus
 * areaCd: 県/振興局/市町村
 * disasterType:災害種別
 *  01:風水害
 *  02:地震・津波(災害種別CDは03)
 *  04:国民保護
 *  99:その他
 */
define([
    'module',
    'dojo/store/Memory'
    // 以下、変数で受けないモジュール
], function(module, Memory) {
    // シングルトンを返す
    return new Memory({
        idProperty: 'code',
        data: [
            {
                'code':'',
                'areaCd':'',
                'disasterType':'',
                'label':'&nbsp;'
            },
            {
                'code':'000030',
                'areaCd':'271004',
                'disasterType':'01',
                'label':'情報連絡体制',
                'hqType':'03',
                'provideFlg':'1',
                'disabledFlg':'0'
            },
            {
                'code':'000020',
                'areaCd':'271004',
                'disasterType':'01',
                'label':'災害警戒本部',
                'hqType':'02',
                'provideFlg':'1',
                'disabledFlg':'0'
            },
            {
                'code':'000010',
                'areaCd':'271004',
                'disasterType':'01',
                'label':'災害対策本部',
                'hqType':'01',
                'provideFlg':'1',
                'disabledFlg':'0'
            },
            {
                'code':'000060',
                'areaCd':'271004',
                'disasterType':'02',
                'label':'情報連絡体制',
                'hqType':'03',
                'provideFlg':'1',
                'disabledFlg':'0'
            },
            {
                'code':'000050',
                'areaCd':'271004',
                'disasterType':'02',
                'label':'災害警戒本部',
                'hqType':'02',
                'provideFlg':'1',
                'disabledFlg':'0'
            },
            {
                'code':'000040',
                'areaCd':'271004',
                'disasterType':'02',
                'label':'災害対策本部',
                'hqType':'01',
                'provideFlg':'1',
                'disabledFlg':'0'
            },
            {
                'code':'000090',
                'areaCd':'271004',
                'disasterType':'04',
                'label':'情報連絡体制',
                'hqType':'03',
                'provideFlg':'1',
                'disabledFlg':'0'
            },
            {
                'code':'000080',
                'areaCd':'271004',
                'disasterType':'04',
                'label':'災害警戒本部',
                'hqType':'02',
                'provideFlg':'1',
                'disabledFlg':'0'
            },
            {
                'code':'000070',
                'areaCd':'271004',
                'disasterType':'04',
                'label':'災害対策本部',
                'hqType':'01',
                'provideFlg':'1',
                'disabledFlg':'0'
            },
            {
                'code':'000120',
                'areaCd':'271004',
                'disasterType':'99',
                'label':'情報連絡体制',
                'hqType':'03',
                'provideFlg':'1',
                'disabledFlg':'0'
            },
            {
                'code':'000110',
                'areaCd':'271004',
                'disasterType':'99',
                'label':'災害警戒本部',
                'hqType':'02',
                'provideFlg':'1',
                'disabledFlg':'0'
            },
            {
                'code':'000100',
                'areaCd':'271004',
                'disasterType':'99',
                'label':'災害対策本部',
                'hqType':'01',
                'provideFlg':'1',
                'disabledFlg':'0'
            },
            {
                'code':'009999',
                'areaCd':'271004',
                'label': '解除',
                'hqType':'99',
                'provideFlg':'1',
                'disabledFlg':'0'
            },
            {
                'code':'010030',
                'disasterType':'01',
                'label':'情報連絡体制',
                'hqType':'03',
                'provideFlg':'0',
                'disabledFlg':'0'
            },
            {
                'code':'010020',
                'disasterType':'01',
                'label':'災害警戒本部',
                'hqType':'02',
                'provideFlg':'0',
                'disabledFlg':'0'
            },
            {
                'code':'010010',
                'disasterType':'01',
                'label':'災害対策本部',
                'hqType':'01',
                'provideFlg':'0',
                'disabledFlg':'0'
            },
            {
                'code':'010060',
                'disasterType':'02',
                'label':'情報連絡体制',
                'hqType':'03',
                'provideFlg':'0',
                'disabledFlg':'0'
            },
            {
                'code':'010050',
                'disasterType':'02',
                'label':'災害警戒本部',
                'hqType':'02',
                'provideFlg':'0',
                'disabledFlg':'0'
            },
            {
                'code':'010040',
                'disasterType':'02',
                'label':'災害対策本部',
                'hqType':'01',
                'provideFlg':'0',
                'disabledFlg':'0'
            },
            {
                'code':'010090',
                'disasterType':'04',
                'label':'情報連絡体制',
                'hqType':'03',
                'provideFlg':'0',
                'disabledFlg':'0'
            },
            {
                'code':'010080',
                'disasterType':'04',
                'label':'災害警戒本部',
                'hqType':'02',
                'provideFlg':'0',
                'disabledFlg':'0'
            },
            {
                'code':'010700',
                'disasterType':'04',
                'label':'災害対策本部',
                'hqType':'01',
                'provideFlg':'0',
                'disabledFlg':'0'
            },
            {
                'code':'010120',
                'disasterType':'99',
                'label':'情報連絡体制',
                'hqType':'03',
                'provideFlg':'0',
                'disabledFlg':'0'
            },
            {
                'code':'010110',
                'disasterType':'99',
                'label':'災害警戒本部',
                'hqType':'02',
                'provideFlg':'0',
                'disabledFlg':'0'
            },
            {
                'code':'010100',
                'disasterType':'99',
                'label':'災害対策本部',
                'hqType':'01',
                'provideFlg':'0',
                'disabledFlg':'0'
            },
            {
                'code':'019999',
                'label': '解除',
                'hqType':'99',
                'provideFlg':'0',
                'disabledFlg':'0'
            }
        ]
    });
});
