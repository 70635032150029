/**
 * 地域選択用入力パーツ
 * @module app/shelter/DistrictShelterSelector
 */
define([
    'app/config',
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/when',
    'idis/view/form/TreeSelector',
    'app/model/District',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/dialog/InfoDialog'
], function(config, module, declare, lang, when, TreeSelector, District,
    CacheStoreModel, CacheTreeRest,InfoDialog) {
    /**
     * 地域選択用パーツ。
     * @class DistrictSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:idis/view/tree/DistrictSelector~DistrictSelector# */ {
        // 選択ダイアログのタイトル
        title: '地区選択',

        // ツリー・モデル
        model: null,

        // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
        constructor: function() {
            this._initModel();
        },

        /**
         * 選択した値の設定
         */
         // 継承元との差分は以下。
         // cityUnselectableが指定された場合、政令指定都市(の地区コード)は選択不可。
        _setValueAttr: function(value) {
            // 偽値は数値の0のみ有効とする
            if (value || value === 0) {
                if(this.cityUnselectable){
                    //政令指定都市の地区は選択不可
                    if(value === config.municInfo.cityMunicCd){
                        InfoDialog.show('エラー', '政令指定都市は選択することができません。');
                        this.reset();
                        return false;
                    }
                }
                    this._set('value', value);
                    this.emit('change', {value: value});
                    this._initTree().then(lang.hitch(this, function() {
                        var model = this.tree.model;
                        var label;
                        if (this.fullName) {
                            label = model.getFullName(value);
                        } else {
                            label = when(model.store.get(value), function(item) {
                                return model.getLabel(item);
                            });
                        }
                        when(label, lang.hitch(this, function(name) {
                            this.status.innerHTML = name;
                        }));
                    }));
                } else {
                    this._set('value', '');
                    this.emit('change', {value: ''});
                    this.status.innerHTML = this.emptyValueLabel;
                }
                // 要素の選択状態をリセットする
                this._initTree().then(lang.hitch(this, function() {
                    this.tree.set('selectedItem', null);
                }));
        },

        _initModel: function(){
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                //ユーザの管理対象の地区一覧を取得する。
                // target: '/api/district/managingDistrict4Tree'
                //ユーザの管理対象にかかわらず全地区一覧を取得する。
                target: '/api/district/district4Tree'
                })
            });
        }
    });
});
