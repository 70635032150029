/**
 * <モジュールの説明>
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/text!./templates/RiverLevelInputDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/dialog/ConfirmDialog',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/control/Router',
    'idis/store/IdisRest',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
	'dijit/form/NumberTextBox',
    'dijit/form/RadioButton',
    'app/view/form/MunicipalitySelector',
    'dijit/form/Form',
    './RiverNumberEditDialog'
], function(module, declare, template, _PageBase, ConfirmDialog, InfoDialog, DialogChain, Loader, Router, IdisRest) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
        // テンプレート文字列
        templateString: template,
        
        //時間モード
        _timeMode: null,

        // 観測日時
        _timetime: null,

        // ユーザID
        _userId: null,

        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                target: '/api/river/detail/bulk/'
            });

            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },
        initDialog: function(date, date2) {
        	this._timetime = date;
            this._userId = date2;
        },
        startup: function () {

            this.inherited(arguments);
            this.own(this.numberEditDialog);
        },
        // 更新ボタンを押下したときの挙動
        updateButtonClick: function(evt) {
        	console.debug(this.gridInput);
            console.debug(this.gridInput.selection);
            var keyIds = Object.keys(this.gridInput.selection);
            console.debug(keyIds);

            var updates = [];
            for(var val of keyIds){
                if(
                    this.gridInput.riverLevelIn1hourMap[val] !== undefined || 
                    this.gridInput.riverLevelIn2hourMap[val] !== undefined || 
                    this.gridInput.riverLevelIn3hourMap[val] !== undefined 
                    ){
                        console.log('valid:'+val);
                        var update = new Object();
                        update.riverLevelId = val;
                        update.riverLevelIn1hour =  this.gridInput.riverLevelIn1hourMap[val];
                        update.riverLevelIn2hour =  this.gridInput.riverLevelIn2hourMap[val];
                        update.riverLevelIn3hour =  this.gridInput.riverLevelIn3hourMap[val];
                        update.forecastTimeIn1hour = this.gridInput.forecastTimeIn1hourMap[val];
                        update.forecastTimeIn2hour = this.gridInput.forecastTimeIn2hourMap[val];
                        update.forecastTimeIn3hour = this.gridInput.forecastTimeIn3hourMap[val];
                        update.observatoryName = this.gridInput.observatoryNameMap[val];
                        update.dateTimeSelectNode = this._timetime;
                        update.userId = this._userId;
                        updates.push(update);
                    }
            }
            console.log(updates);
            if(updates.length > 0){
                this.chain.confirmPut(function(chain) {
                    var promise = this.store.put(updates);
                    Loader.wait(promise).then(function() {
                        chain.infoComplete(function() {
                            console.debug('観測情報の更新に成功しました。');
                            var date = new Date(this._date).getTime();
                            //チャート・グラフを再生成するため、ページをリフレッシュ
                            Router.moveTo('observation/river', {
                                date : date,
                                timeMode : this._timeMode
                            });
                            location.reload();
                        });
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }
        }
    });
});