/**
 * テスト配信確認ダイアログ画面用モジュール。
 * @module app/sending/SendingTestConfirmDialog
 */
define([
    'module',
    'dojo',
    'dojo/_base/lang',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/dom-style',
    'dojo/date/locale',
    'dojo/text!./templates/SendingTestConfirmDialog.html',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button'
], function(module, dojo, lang, declare, array, domStyle,
        locale, template, WidgetBase) {
    /**
     * テスト配信確認ダイアログ
     * @class SendingTestConfirmDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends app/view/Header~Header# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sending',

        sendTimestamp: null,

        constructor: function() {
            this.inherited(arguments);
        },

        buildRendering: function() {
            this.inherited(arguments);
        },

        startup: function() {
            this.inherited(arguments);
            this.container.resize();
        },

        /**
         * 画面に内容をセット
         */
        refresh: function(registerPageObj) {
            var value = registerPageObj.form.value;

            this.sendTimestamp = value.sendTimestamp;

            // 共通部分
            this.sendOrganizationNamePreview.innerHTML = value.sendOrganizationName;
            this.sendTimestampPreview.innerHTML = locale.format(this.sendTimestamp);
            this.senderNamePreview.innerHTML = value.senderName;
            this.subjectPreview.innerHTML = value.subject;
            this.bodyTextPreview.innerHTML = value.bodyText;

            // チャンネル別
            // 緊急情報配信（防災アプリ）
            if (registerPageObj.emergencyPush.checked) {
                domStyle.set(this.dispEmergencyPush, 'display', 'block');
                // 件名
                this.emergencyPushSubjectPreview.innerHTML = registerPageObj.emergencyPushSubject.value;
                // 本文
                this.emergencyPushBodyPreview.innerHTML = registerPageObj.emergencyPushBody.value;
            }

            this.container.resize();
        },

        /**
         * テスト配信
         */
        onSubmit: function() {
            this.emit('register', {sendTimestamp:this.sendTimestamp});
        },

        /**
         * キャンセルボタン
         */
        onCancelButton: function() {
            this.emit('cancel');
            this.leave();
        }
    });
});
