/**
 * 参集管理の新規登録・詳細ダイアログ用の基底モジュール。
 * @module app/employgathering/_EmployGatheringStatusPageBase
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/dom-style',
    'dojo/on',
    'dojo/request/iframe',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'exif-js/exif',
    'idis/model/UserInfo',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/SimpleTextarea',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/RadioGroup',
    'app/view/form/OrganizationSelector'
], function(module, declare, lang, array, domClass, domConstruct, domStyle, on, iframe,
    Menu, MenuItem, popup, TooltipDialog, exif, UserInfo, _PageBase, InfoDialog) {
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    {
        /**
         * 防災体制ID
         */
        _disasterPreventionId: null,

        /**
         * 防災体制ID
         */
        _municipalityCd: null,

        /**
         * 呼び出し元
         */
        _parent: null,

        /**
         * フォームをリセットする。
         */
        reset: function() {
            this.form.reset();
        },

        /**
         * 入力値の妥当性を検証する。
         */
        // validate: function() {
        //     var formData = this.form.get('value');

        //     // 所轄組織
        //     if (formData.organization === '') {
        //         InfoDialog.show('入力チェック', '所轄組織が選択されていません');
        //         return false;
        //     }

        //     // 報告日時
        //     if (formData.pubStartTimestamp === '') {
        //         InfoDialog.show('入力チェック', '報告日時が入力されていません');
        //         return false;
        //     }

        //     return true;
        // },

        /**
         * グリッド編集値ヴァリデーションチェック
         */
        validate: function(items) {
            var messages = [];
            // 入力無し
            if(items.length===0){
                InfoDialog.show('入力チェック', '更新対象の配置人数、報告日時を入力してください。');
                return false;
            }

            array.forEach(items, function(item) {
                // 報告日時
                if(!item.reportTimestamp) {
                    messages.push(['［組織名］', item.orgName, '： 報告日時を正しく入力してください(YYYY/MM/DD HH:mm)'].join(''));
                } else {
                    item.reportTimestamp = new Date(item.reportTimestamp);
                }
                // 参集人数
                if (
                    // 全ての入力域がブランクの場合（数字以外の値、整数以外の値、負の値は画面上でエラーとしている）
                    ((!parseInt(item.empGather,10) && parseInt(item.empGather,10)!==0) || item.empGather<0) &&
                    ((!parseInt(item.empWork,10) && parseInt(item.empWork,10)!==0) || item.empWork<0) &&
                    ((!parseInt(item.empOther,10) && parseInt(item.empOther,10)!==0) || item.empOther<0)){
                    messages.push([item.orgName, '：参集人数には0以上の数値を入力してください'].join(''));
                }
            });
            if (messages.length>0) {
                InfoDialog.show('入力チェック', messages.join('<br>'));
                return false;
            }

            return true;
        },

        /**
         * 職員参集一覧情報をエクセル形式で出力します。
         */
        onOutputButtonClick: function() {
            this.innerFormDialog.initDialog({
                disasterPrevId : this._disasterPreventionId,
                municipalityCd : this._municipalityCd
            });
            this.formDialog.show();
        }
    });
});
