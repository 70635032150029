define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/view/dialog/InfoDialog',
    'dojo/topic',
    'dojo/on',
    'dgrid/Selection',
    'dgrid/Selector',
    'dgrid/Keyboard',
    'dijit/form/CheckBox',
    'idis/view/form/Button',
    'app/config'
], function (module, declare, lang, array, locale, IdisGrid, helper,
    InfoDialog, topic, on, Selection,
    Selector, Keyboard, CheckBox, Button, config) {

    //県の市町コード
    var PREF_MUNICIPALITY_CODE = config.municInfo.prefMunicCd;

    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selection, Selector, Keyboard], {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--damage',


        DRAW_CIRCLE: 'app.damage.DamageReportAdminPage' + '::drawCircle',

        checkboxes: {},
        mainTypeIds: [],
        municipalityCd: null,

        /**
         * 条件に一致するデータが存在しない場合に表示されるメッセージ
         * @type {string}
         */
        noDataMessage: '条件に一致するデータが存在しません',

        constructor: function () {
            /**
             * 各列の定義
             * @type {Object[]}
             */
            this.columns = [

                // ボタン
                {
                    field: 'chkbox', label: '', sortable: false,

                    renderCell: lang.hitch(this, function (item) {
                        if (!item.crtUserId) {
                            return '';
                        }
                        this.checkboxes[item.damageReportId] = new CheckBox({
                            onClick: lang.hitch(this, function () {
                                this._onCheckBox(item);
                            })
                        });
                        // HTMLとしてウィジェットに紐付くDOMノードを返す
                        return this.checkboxes[item.damageReportId].domNode;
                    })
                },
                {
                    field: 'list', label: '詳細', sortable: false,
                    renderCell: function (item) {
                        console.log('here');
                        if (!item.crtUserId) {
                            return document.createTextNode('合計');
                        }
                        if (item.municipalityCd !== PREF_MUNICIPALITY_CODE) {
                            var gridNode = this.grid.domNode;
                            var button = new Button({
                                label: '詳細',
                                onClick: function () {
                                    on.emit(gridNode, 'listButtonClick', { item: item });
                                }
                            });
                            return button.domNode;
                        } else {
                            //合計行には詳細ボタンをつけない
                            return null;
                        }

                    }
                },
                helper.column('damageTypeListName', '被害の種類'),

                helper.column('reportCrtTimestamp', '発生日時', {
                    formatter: function (val) {
                        if (val) {
                            var date = locale.parse(val, {
                                datePattern: 'yyyy-MM-dd',
                                timePattern: 'HH:mm:ss'
                            });
                            return locale.format(date, {
                                datePattern: 'yyyy-MM-dd',
                                timePattern: 'HH:mm'
                            });
                        } else {
                            return '';
                        }
                    }
                }),
                helper.column('damageAddress', '発生場所'),

                {
                    label: '人的被害', field: 'human', sortable: false,
                    children: [
                        { field: 'deadNumI', label: '死者', sortable: false },
                        { field: 'unknownNumI', label: '行方不明者', sortable: false },
                        { field: 'seriousNumI', label: '重傷者', sortable: false },
                        { field: 'mildNumI', label: '軽傷者', sortable: false }
                    ]
                },
                {
                    label: '住家被害', field: 'property', sortable: false,
                    children: [
                        { field: 'fullDestlHmI', label: '全壊', sortable: false },
                        { field: 'halfDestlHmI', label: '半壊', sortable: false },
                        { field: 'partDestlHmI', label: '一部損壊', sortable: false },
                        { field: 'fldAbvHmI', label: '床上浸水', sortable: false },
                        { field: 'fldBlwHmI', label: '床下浸水', sortable: false },
                    ]
                },
                {
                    label: '水道', field: 'water', sortable: false,
                    children: [
                        { field: 'watFailCurrI', label: '箇所', sortable: false }
                    ]
                },
                {
                    label: '道路', field: 'rod', sortable: false,
                    children: [
                        { field: 'rodDamageI', label: '箇所', sortable: false }
                    ]
                },
                {
                    label: '橋梁', field: 'brg', sortable: false,
                    children: [
                        { field: 'brgDamageI', label: '箇所', sortable: false }
                    ]
                },
                {
                    label: '河川', field: 'rivr', sortable: false,
                    children: [
                        { field: 'rivrDamageI', label: '箇所', sortable: false }
                    ]
                },
                {
                    label: '港湾', field: 'hbr', sortable: false,
                    children: [
                        { field: 'hbrDamageI', label: '箇所', sortable: false }
                    ]
                },


            ];
        },

        renderRow: function (item) {
            var div = this.inherited(arguments);
            topic.publish(this.DRAW_CIRCLE, item);
            return div;
        },

        _onCheckBox: function (item) {
            if (!this.municipalityCd) {
                this.municipalityCd = item.municipalityCd;
            }
            if (this.municipalityCd !== item.municipalityCd) {
                InfoDialog.show('入力エラー', '異なる区は選択できません。');
                this.checkboxes[item.damageReportId].set('checked', false);
                return;
            }
            if (item.reportStatus === '41') {
                InfoDialog.show('入力エラー', '確定報は選択できません。');
                this.checkboxes[item.damageReportId].set('checked', false);
                return;
            }
            if (item.damageType === '19') {
                InfoDialog.show('入力エラー', '状況報告の被害は選択できません。');
                this.checkboxes[item.damageReportId].set('checked', false);
                return;
            }

            if (this.mainTypeIds.map(
                function (store) {
                    return store.damageReportId;
                }
            ).indexOf(item.damageReportId) !== -1) {
                return;
            }

            if (this.checkboxes[item.damageReportId].get('checked')) {
                // チェック状態である => 今回追加されたのでmainTypeIdsに入れる
                this.mainTypeIds.push(item);
            } else {
                // チェック状態でない => mainTypeIdsにあったら削除
                this.mainTypeIds =
                    this.mainTypeIds.filter(function (data) {
                        return data.damageReportId !== item.damageReportId;
                    });
            }

            console.log(this.mainTypeIds);
        }
    });
});
