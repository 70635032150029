/**
 * 火山詳細画面
 * @module app/volcano/VolcanoDetailPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/VolcanoDetailPage.html',
    'dojo/dom',
    'dojo/dom-style',
    'idis/view/page/_PageBase',
    'idis/map/IdisMap',
    'app/config',
    'idis/control/Router',
    'idis/control/Locator',
    'idis/service/Requester',
    'dojo/_base/array',
    'idis/util/DateUtils',
    'dstore/Memory',
    'app/volcano/layer/VolcanoDetailLayer',
    //
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    './VolcanoDetailGrid'
], function(module, declare, lang, template,
    dom, domStyle, _PageBase, IdisMap,
    config, Router, Locator, Requester, array, DateUtils, Memory, VolcanoDetailLayer) {
    /**
     * 避難所詳細画面
     * @class VolcanoDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/volcano/VolcanoDetailPage~VolcanoDetailPage# */
        {
            // テンプレート文字列
            templateString: template,

            constructor: function() {
                this.inherited(arguments);
                this.volcanoEventId = Locator.getQuery().volcanoEventId;
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function() {
                this.inherited(arguments);
                this.initGrid();
            },
            startup: function() {
                this.inherited(arguments);
                this.initMap();
                this.setContent();
            },


            /**
            * グリッドを初期化する。
            */
            initGrid: function() {
                Requester.get('/api/volcanos/detail?volcanoEventId=' + this.volcanoEventId)
                    .then(lang.hitch(this, function(response) {

                        //IDを振る
                        array.forEach(response, lang.hitch(this, function(row, idx) {
                            row.id = idx;
                        }));

                        //gridに入れる内容を熊本県に絞る(岡山県では使用しないのでそのまま)
                        var responseUsedInGrid = response.items.filter(function(item) {
                            return (/^43/.test(item.areaICode) && String(item.areaICode).length > 2);
                        });

                        //Grid用のstoreを作りつつ、gridにset
                        this.volGrid.set('collection', new Memory({
                            data: responseUsedInGrid,
                            idProperty: 'id'
                        }));

                        // 地図に色をつける一覧を作る
                        var AreaICodeForMap = array.map(response.items, function(item) {
                            return {
                                areaICode: item.areaICode.toString(10),
                                vwCode: item.vwCode.toString(10),
                                vwName: item.vwName
                            };
                        });
                        this.initLayer(AreaICodeForMap);
                    }));
            },

            initLayer: function(AreaICodeForMap) {
                var layerData = {
                    area: AreaICodeForMap
                };
                this.layer = new VolcanoDetailLayer(layerData);
                this.layer.addTo(this.map);
            },

            /*
             * 一覧ボタンが押されたときに呼び出される。
             * 一覧画面に遷移する。
            */
            onVolcanoPageLinkClick: function(evt) {
                evt.preventDefault();
                Router.moveTo('volcano');
            },

            /**
             * マップを初期化する。
             */
            initMap: function() {
                // mapの生成
                var latlng = [config.map.latitude, config.map.longitude];
                this.map = new IdisMap(this.mapNode, {
                    config: config.map,
                    keyboard: false, //コメント時に+/-が使用できないため
                    touchExtend: false,
                    drawControlTooltips: false
                }).setView(latlng, 9);
                // destroy時にmapを破棄するよう設定
                this.own(this.map);
            },

            /**
            * コンテンツの中身を表示する
            */
            setContent: function() {
                Requester.get('/api/volcanos/detail/dtlContent?volcanoEventId=' + this.volcanoEventId)
                    .then(lang.hitch(this, function(response) {
                        // volcano
                        var rowsHeaderTopBriefInfo = document.getElementById('rowsHeaderTopBriefInfo');
                        rowsHeaderTopBriefInfo.style.backgroundColor = '#999999';
                        rowsHeaderTopBriefInfo.innerHTML = '<b>&lt;噴火警報・予報&gt;</b>';
                        var rowsReportDateTime = document.getElementById('rowsReportDateTime');
                        rowsReportDateTime.style.backgroundColor = 'lightgrey';
                        rowsReportDateTime.innerHTML = '&lt;発表時刻&gt;';
                        var reportDateTime = document.getElementById('reportDateTime');
                        reportDateTime.innerHTML = DateUtils.format(response.reportDatetime);

                        var rowVolName = document.getElementById('rowVolName');
                        rowVolName.style.backgroundColor = 'lightgrey';
                        rowVolName.innerHTML = '&lt;火山名&gt;';
                        var volName = document.getElementById('volName');
                        volName.innerHTML = response.volcanoName;

                        var rowEdOffice = document.getElementById('rowEdOffice');
                        rowEdOffice.style.backgroundColor = 'lightgrey';
                        rowEdOffice.innerHTML = '&lt;編集官署&gt;';
                        var edOffice = document.getElementById('edOffice');
                        edOffice.innerHTML = response.volEdOffice;

                        var overview = document.getElementById('overview');
                        overview.style.backgroundColor = '#AAAAAA';
                        overview.innerHTML = '&lt;発表概要&gt;';

                        var rowHeadLine = document.getElementById('rowHeadLine');
                        rowHeadLine.style.backgroundColor = 'lightgrey';
                        rowHeadLine.innerHTML = '&lt;見出し&gt;';
                        var headLine = document.getElementById('headLine');
                        headLine.innerHTML = response.volHeadline;

                        var rowVolActivity = document.getElementById('rowVolActivity');
                        rowVolActivity.style.backgroundColor = 'lightgrey';
                        rowVolActivity.innerHTML = '&lt;詳細&gt;';
                        var volActivity = document.getElementById('volActivity');
                        volActivity.innerHTML = response.volActivity;

                        var rowVolPrevention = document.getElementById('rowVolPrevention');
                        rowVolPrevention.style.backgroundColor = 'lightgrey';
                        rowVolPrevention.innerHTML = '&lt;防災上の注意&gt;';
                        var volPrevention = document.getElementById('volPrevention');
                        volPrevention.innerHTML = response.volPrevention;

                        this.layout();
                    })
                    );
            },

            /**
             * 左Paneのレイアウトを調整する
             *
             * 左上のテーブルの内容は最初は空のため, heightが0になる
             * この状態で下のgridが描画されるのでコンテンツが重なってしまう
             * テーブル内容が 'setContent' で作られた後にテーブルの高さをContentPaneにセットして
             * 再配置するのがこのfunctionの目的
             */
            layout: function() {
                // tableの高さを測る
                var tableHeight = domStyle.get(dom.byId('HeaderTable'), 'height');

                // 高さをcontentpaneに伝える
                domStyle.set(this.headerTablePane, 'height', tableHeight);

                // 左側のBorderContainerを再配置
                this.leftPane.layout();
            }
        }
    );
});
