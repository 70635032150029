/**
* 避難所状況数位画面用モジュール。
* @module app/auth/AuthAdminPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dgrid/ColumnSet',
    'dojox/lang/functional/object',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/IdisSelector',
    'idis/view/grid/helper',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function (module, declare, lang, ColumnSet, df, IdisGrid, IdisSelector, helper) {

    return declare(module.id.replace(/\//g, '.'), [IdisGrid, IdisSelector, ColumnSet,], {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--Shulter-Status',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columnSets: [
            [[
                {
                    field: 'checkbox',
                    label: '選択',
                    selector: 'checkbox'
                },
                helper.column('municipalityName', '行政区'),
                helper.column('facilityName', '施設名'),
                helper.column('itemName', '項目'),
            ]
            ], [
                [
                    helper.column('day0', '1日'),
                    helper.column('day1', '2日'),
                    helper.column('day2', '3日'),
                    helper.column('day3', '4日'),
                    helper.column('day4', '5日'),
                    helper.column('day5', '6日'),
                    helper.column('day6', '7日'),
                    helper.column('day7', '8日'),
                    helper.column('day8', '9日'),
                    helper.column('day9', '10日'),
                    helper.column('day10', '11日'),
                    helper.column('day11', '12日'),
                    helper.column('day12', '13日'),
                    helper.column('day13', '14日'),
                    helper.column('day14', '15日'),
                    helper.column('day15', '16日'),
                    helper.column('day16', '17日'),
                    helper.column('day17', '18日'),
                    helper.column('day18', '19日'),
                    helper.column('day19', '20日'),
                    helper.column('day20', '21日'),
                    helper.column('day21', '22日'),
                    helper.column('day22', '23日'),
                    helper.column('day23', '24日'),
                    helper.column('day24', '25日'),
                    helper.column('day25', '26日'),
                    helper.column('day26', '27日'),
                    helper.column('day27', '28日'),
                    helper.column('day28', '29日'),
                    helper.column('day29', '30日'),
                    helper.column('day30', '31日'),
                ]
            ]
        ]

    });
});
