/**
 * 職員情報詳細ダイアログ用モジュール。
 * @module app/convocation/view/employee/ConvoEmployeeRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/ConvoEmployeeDetailDialog.html',
    'app/convocation/view/employee/_ConvoEmployeePageBase',
    'idis/view/dialog/DialogChain',
    'dojo/dom-style',
    'idis/model/UserInfo',
    'idis/consts/ACL',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/ValidationTextBox',
    'idis/view/form/AclButton',
    //'app/view/form/EmployeeDivisionSelector',
    'app/view/form/MunicRegionOrganizationSelector',
    'app/convocation/view/employee/ConvoEmployeeGrpListGrid'
], function(module, declare, template, _PageBase, DialogChain, domStyle, UserInfo, ACL) {
    /**
     * 職員情報詳細ダイアログ。
     * @class ConvoEmployeeRegisterDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/convocation/view/employee/ConvoEmployeeRegisterDialog~ConvoEmployeeRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--convocation',

        /**
         * 変数の初期化。
         */
        constructor: function() {
            // ダイアログの連鎖関係を取得
            this.chain = DialogChain.get(this);
        },

        buildRendering: function() {
            this.inherited(arguments);
            // 画面を初期化
            this.initPage();
            this.organizationSelector.restoreRefresh();
        },

        // startup: function() {
        //     this.inherited(arguments);
        // },

        /**
         * 画面を初期化
        */            
        initPage : function() {
            var role = UserInfo.getRoleCd();
            // システム管理者ユーザ以外
            if (role !== ACL.ADMIN_USER){
                // 選択ボタンを非表示
                domStyle.set(this.organizationSelector.selectButton.domNode, 'display', 'none');
            }

//            var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
//                         UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
//                         UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');
//            this.organizationSelector.set('value', orgCd);
        },

        /**
         * 更新ボタンが押下された際の挙動。
         */
        onSubmit: function() {
            try {
                // data-dojo-propsを利用した必須チェック
                if (!this.form.validate()) {
                    return false;
                }
                // モジュール固有の入力チェック
                if (!this.validate()) {
                    return false;
                }
//                if (!this.validateNotForm()){
//                    return false;
//                }
                var formData = this.form.get('value');
                delete formData.passwordConfrm;

                // 組織コードの先頭1桁は不要なので削っておく
                if(formData.organization !== ''){
                    formData.organization = formData.organization.slice(1);
                }

                // 登録処理を行うPage.js側にformの情報を渡す
                this.emit('update', {value: formData});
            } catch(error) {
                // 処理失敗時はエラーログを出力
                console.error(error);
            }
            return false;
        },

        /**
         * 削除ボタンが押下された際の挙動。
         */
        onDelete: function() {
            try {
                this.emit('delete');
            } catch(error) {
                // 処理失敗時はエラーログを出力
                console.error(error);
            }
            return false;
        }
    });
});
