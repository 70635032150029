define([
    'module',
    'dojo/when',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'idis/util/DateUtils',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/view/LazyNode',
    'app/view/grid/CommonColumn',
    './EvacOrderColumn',
    'app/model/Municipality'
], function(module, when, declare, lang, domClass, DateUtils, IdisGrid, helper, LazyNode, 
    CommonColumn, EvacOrderColumn, Municipality) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            // ボタン
            helper.buttonColumn('detail', '詳細'),
            {field: 'municipalityCd', 
            label: '区', sortable: false, 
            renderCell: function(item) {
                var itemPromise = Municipality.store.get(item.municipalityCd);
                var lazyNode = new LazyNode({
                    promise: when(itemPromise).then(function(item) {
                        if (item) {
                            return Municipality.getLabel(item);
                        } else {
                            return '大阪市外';
                        }
                    })
                });
                return lazyNode.domNode;
            }},
            helper.column('districtFreeName', '地区', {sortable: false}),
            EvacOrderColumn.alertLevel,
            EvacOrderColumn.evacOrderType,
            EvacOrderColumn.evacOrderTimestamp,
            EvacOrderColumn.issueReasonType,
            helper.column('_item', '対象', {
                children: [
                    helper.column('evacHouseholdDistNum', '世帯数', {sortable: true}),
                    helper.column('evaqueeDistNum', '人数', {sortable: true})
                ],
                sortable: false
            }),
            // 訂正・取消はそれぞれのフラグからサーバー側で判断して返しているため、
            // dstoreのGridでは単純にリクエストできないためソート対象から外す。
            helper.column('correctCancelStatus', '訂正・取消', {sortable: false}),
            // 更新日時
            EvacOrderColumn.updTimestamp
        ],

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            domClass.add(this.domNode, 'evacorder-EvacOrderGrid');
        }
    });
});
