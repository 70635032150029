/**
 * ニュース詳細情報表示ダイアログ
 * 「緊急ニュース」「お知らせ」共通で利用する
 * @module app/bbs/CommentDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/CommentDetailDialog.html',
    'idis/view/page/_PageBase',
    'app/util/DateFormatter',
    'idis/store/IdisRest',
    'idis/service/iframe',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/model/UserInfo',
    'dojo/on',
    'dojo/dom-class',
    'dojo/dom-construct',
    'exif-js/exif',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dojo/date/locale',
    'idis/util/FilesUtils',
    'idis/view/dialog/InfoDialog',
    'app/model/DisasterInfo',
    'idis/service/Requester',
    // 以下、変数で受けないモジュール
    'dojox/form/Uploader',
    'dijit/form/Select',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/WordCountTextarea',
    './BbsDetailDialog',
    './CommentsGrid'
], function(module, declare, lang, template, _PageBase, DateFormatter, IdisRest, iframe, DialogChain,
    Loader, UserInfo, on, domClass, domConstruct, exif, Menu, MenuItem, 
    popup, TooltipDialog, locale , FilesUtils, InfoDialog, DisasterInfo, Requester) {
    /**
     * ニュース詳細情報表示ダイアログ
     *
     * @class CommentDetailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/bbs/CommentDetailDialog~CommentDetailDialog# */ {
        // テンプレート文字列
        templateString: template,

        attachFileList: [],

        // 未登録コメント添付ファイル
        tempCommentAttachFile: [],

        /**
         * コメント登録用オブジェクト
         * @type {module:dstore/Store}
         */
        commentStore: null,

        /**
         * コメント取得用オブジェクト
         * @type {module:dstore/Store}
         */
        getCommentStore: null,

        // 基本クラス
        baseClass: 'news-DetailDailog',

        /**
        * データ格納用オブジェクト
        * @type {module:dstore/Store}
        */
        store: null,
        
        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'messageId',
                target: '/api/bbses',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            // コメント登録用オブジェクト
            this.commentStore = new IdisRest({
                target: '/api/bbses/register'
            });

            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.detailPageDialog);
        },

        startup: function() {
            this.inherited(arguments);
            this.initDetailPage();
        },

        /**
         * 詳細ダイアログを初期化する。
         */
        initDetailPage: function() {
            var dialog = this.detailPageDialog;
            var page = dialog.getChildren()[0];

            // 詳細ダイアログのupdateSendイベントを受け取る
            page.on('update', lang.hitch(this, function(evt) {
                this.chain.confirmPut(function(chain) {
                    // データ更新用オブジェクト
                    this.updateStore = new IdisRest({
                        idProperty: 'messageId',
                        target: '/api/bbses/update'
                    });
                    // 更新処理と共にローディング表示
                    Loader.wait(this.updateStore.put(evt.value)).then(lang.hitch(this, function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            var fil = this.storeMessage = new IdisRest({
                                target: '/api/bbses/message/' + evt.value.messageId
                            });
                            fil.fetch().then(lang.hitch(this, function(formItem){
                                this.setContent(formItem);
                                this.updateDialog(formItem);
                            }));
                        }));
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));

            // 詳細ダイアログのremoveイベントを受け取る
            page.on('remove', lang.hitch(this, function(evt) {
                this.chain.confirmDel(function(chain) {
                    // OKが押された場合

                    // データ削除用オブジェクト
                    this.deleteStore = new IdisRest({
                        target: '/api/bbses/delete'
                    });
                    // 削除処理と共にローディング表示
                    Loader.wait(this.deleteStore.remove(evt.value.messageId)).then(lang.hitch(this, function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            var fil = this.storeMessage = new IdisRest({
                                target: '/api/bbses/message/' + evt.value.messageId
                            });
                            fil.fetch().then(lang.hitch(this, function(formItem){
                                this.setContent(formItem);
                                this.updateDialog(formItem);
                            }));
                        }));
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    }));
                });
            }));
        },



        /**
         * ダイアログの初期表示またはコメント登録後に呼ばれダイアログを更新する
         * @param 
         */
        updateDialog: function(item) {
            // フォームリセット
            // 未登録コメント添付ファイル情報の初期化
            this.tempCommentAttachFile.length = 0;
            // テキストエリアの初期化
            this.commentForm.reset();
            this.searchForm.reset();
            this.selectComment.reset();
            this.searchText.reset();
            this.addCommentTextArea.hidden = true;
            if(item.crtUserId === UserInfo._userInfo.userId || UserInfo._userInfo.roleCd === 'R01001') {
                this.showDetailPageLink.hidden = false;
            } else {
                this.showDetailPageLink.hidden = true;
            }
            if (item.contents){
                this._detailPage = item;
            }
            // グリッド更新
            if (item.messageId){
                this.updateGrid(item.messageId);
            } else {
                this.updateGrid(item);
            } 
        },
        
        /**
         * グリッド表示
         * @param 
         */
        updateGrid: function(messageId) {
            this.getCommentStore = new IdisRest({
                target: '/api/bbses/comment/' + messageId,
                idProperty: 'messageId'
            });
            var filter = new this.getCommentStore.Filter();
            filter = filter.eq('searchText', this.searchText.value.trim());

            var collection = this.getCommentStore.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.commentsgrid.set('collection', collection);

            this.commentsgrid.on('deleteButtonClick', lang.hitch(this, function(evt) {
                this.chain.confirmDel(lang.hitch(this, function(chain) {
                    // OKが押された場合
                    var deleteStore =  new IdisRest({
                        target: '/api/bbses/delete'
                    });
                    // 削除処理と共にローディング表示
                    Loader.wait(deleteStore.remove(evt.item.messageId)).then(lang.hitch(this, function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            chain.hide();
                            this.updateDialog(messageId);
                        }));
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    }));
                }));
            
            }));
        },

        /**
         * ダイアログのテーブルにデータをセット
         */
        setContent: function(item) {
            this._messageId = item.messageId;
            this.title.innerHTML = item.title;
            this.time.innerHTML = DateFormatter.jpDateTime(
                new Date(item.pubStartTimestamp), true);

            if(item.contents !== null){
                this.infoDetail.innerHTML = item.contents.replace(/\n/g, '<br>');
            }

            if (!!item.refUrl && item.refUrl !== '') {
                var htmlLink = [];
                var hasScheme = item.refUrl.match(/(http|https):\/\/.+/);
                if (hasScheme) {
                    // URLスキームがあるとき
                    htmlLink.push('<a target="_blank" class="u-external" href="');
                } else {
                    // URLスキームがないとき
                    htmlLink.push('<a target="_blank" class="u-external" href="//');
                }
                htmlLink.push(item.refUrl);
                htmlLink.push('" tabindex="-1">');
                htmlLink.push(item.refUrl);
                htmlLink.push('</a>');
                this.url.innerHTML = htmlLink.join('');
            } else {
                this.url.innerHTML = '&nbsp;';
            }

            if(item.sender !== null){
                this.organization.innerHTML = item.sender.replace(/\n/g, '<br>');
            }

            var attachFile = item.attachFile;
            var attachFileNameList = [];
            var hasAttachFile = false;
            // 添付ファイルを設定
            for (var i=0; i< attachFile.length; i++) {
                if(attachFile[i].delFlg  === 'false'){
                    // 送付ファイルとして表示
                    attachFileNameList.push('<a href="' + attachFile[i].attachFilePath.replace('out/', 'data/') + '"');
                    attachFileNameList.push('download="' + attachFile[i].attachFileName +'" tabindex="-1">');
                    attachFileNameList.push(attachFile[i].attachFileName +'</a><br>');
                    // attachFileListに登録
                    this.attachFileList.push(attachFile[i]);
                    hasAttachFile = true;
                }
            }
            if (hasAttachFile) {
                this.files.innerHTML = attachFileNameList.join('');
            } else {
                this.files.innerHTML = '&nbsp;';
            }

        },

        /**
         * コメント追加ボタンクリックイベント
         */
        addComment: function() {
            if (!this.selectComment.value ||
                this.selectComment.value === '手動入力' && this.comment.value.trim() === '') {
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : 'コメントが入力されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return;
            }
            if(encodeURIComponent(this.comment.value).replace(/%../g,'x').length > 3000){
                console.debug('無効な値が入力されています');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '無効な値が入力されています'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return;
            }
            // 登録の確認処理
            this.chain.confirmAdd(lang.hitch(function(chain) {
                // OKが押された場合
                var commnet = '';
                if(this.selectComment.value === '手動入力') {
                    commnet = this.comment.value;
                } else {
                    commnet = this.selectComment.value;
                }
                var attachFileIds = [];
                if (this.tempCommentAttachFile.length > 0) {
                    attachFileIds.push(this.tempCommentAttachFile[0].messageAtcFileId);
                }
                console.log(Requester);
                
                // var sender = '';
                // Requester.get('/api/users/org/').then(
                //     lang.hitch(this, function(data) {
                //     console.log(data);
                //     sender = data.organizationName;
                // }));

                console.log(this);
                console.log(UserInfo);
                
                

                var sendData = {
                    replyMessageId: this._messageId,
                    disasterId: DisasterInfo.getDisasterId(),
                    // senderId: UserInfo.getGovOrgCd(),
                    // sender: UserInfo.getSelectedMunicipalityCd(),
                    // sender: (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
                    // UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
                    // UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : ''),
                    sender: UserInfo._userInfo.userId,
                    contents: commnet.trim(),
                    attachFileIds: attachFileIds.join(',')
                };
                // コメント登録処理と共にローディング表示
                Loader.wait(this.commentStore.add(sendData)).then(lang.hitch(this, function() {
                    chain.infoComplete(lang.hitch(this, function() {
                        // 再表示
                        chain.hide();
                        // コメント欄更新
                        this.initDialog();
                        this.tempCommentAttachFile.length = 0;
                        this.attachFile.reset();
                        this.updateDialog(this._messageId);
                    }));
                }, function(err) {
                    // 失敗時
                    chain.infoError(err);
                }));
            }));
        },

        /**
         * 添付ファイルをアップロードする。
         */
        loadAttachFile: function() {
            // ファイルが空の場合は処理を中断＆不正なファイルの場合、メッセージ表示して処理を中断
            if (this.attachFile._files.length === 0 ||
                !FilesUtils.isAttachFile(this.attachFile)) {
                return;
            }
            // すでにtempCommentAttachFileにある未登録ファイルを削除
            this.deleteTempCommentAttachFile();

            // TODO: self削除
            var self = this;
            self.attachFile.set('disabled', false);

            var promise = iframe.post('/api/bbses/uploadFile/message/', {
                form: 'commentForm',
                handleAs: 'json'
            }).then(function(data) {
                // tempCommentAttachFileは一件のみ保持するため
                // pushする前にリセットする
                self.tempCommentAttachFile.length = 0;
                self.tempCommentAttachFile.push(data);
            }, function(error) {
                //uploaderをリセット
                self.attachFile.reset();
                self.chain.infoError(error);
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
         * コメントを検索する
         */
        onSubmit: function() {
            console.debug('[検索]ボタンがクリックされました。');
            try {
                this.updateGrid(this._messageId);
            } catch (e) {
                console.error(e);
            }
            return;
        },

        /**
         * セレクターのvalueによって、テキストエリアを表示する
         */
        onChange: function(value) {
            if (value === '手動入力') {
                this.addCommentTextArea.hidden = false;
            } else {
                this.addCommentTextArea.hidden = true;
            }
        },

        /**
         * ダイアログを初期化する。
         */
        initDialog: function() {
            //this.form.reset();
            this.selectComment.setValue = null;
        },

        /**
         * アップロードファイルを削除(未登録時）
         */
        deleteTempCommentAttachFile: function() {
            if(this.tempCommentAttachFile.length > 0) {
                var param = '';
                var firstFlg = true;
                for(var i = 0; i < this.tempCommentAttachFile.length; i++) {
                    if(firstFlg) {
                        param += '?';
                        firstFlg = false;
                    } else {
                        param += '&';
                    }
                    param += 'array[]=' + this.tempCommentAttachFile[i].messageAtcFileId;
                }
                console.log('param' + param);

                // データ削除用オブジェクト
                this.delStore = new IdisRest({
                    target: '/api/bbses/deleteAtcFile'
                });
                // 削除処理
                Loader.wait(this.delStore.remove(param).then(function() {},
                    function(err) {
                        console.log(err);
                }));
                // テンポラリファイルリストをリセット
                this.tempCommentAttachFile.length = 0;
            }
        },

        /**
         * キャンセルボタン押下時処理
         * アップロードファイルを削除(未登録時）
         */
        cancel: function() {
            this.deleteTempCommentAttachFile();
            // ダイアログを閉じる
            this.leave();
            this.emit('cancel');
        },

        /**
         * お知らせ編集ダイアログへ遷移
         */
        showDetailPageDialog: function(){
            var item = this._detailPage;
            var page = this.detailPageDialog.getChildren()[0];

            // 画面上のフォームをリセット
            page.form.reset();
            page.clearPreview();

            // 添付ファイルをプレビューに反映
            this.innerDetailPageDialog.displayAttachFile(item.attachFile);

            // 内容をフォームに反映
            var value = lang.mixin(null, item);
            delete value.attachFile;
            page.form.set('value', value);

            // 詳細ダイアログを表示
            this.innerDetailPageDialog.initDialog(item);
            this.detailPageDialog.show();
        }
    });
});
