/**
* 部隊活動画面用モジュール。
* @module app/rescue/RescuePage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/RescuePage.html',
    'dojo/_base/array',
    'dojo/json',
    'dojo/topic',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dstore/Memory',
    'dstore/Rest',
    'dijit/registry',
    'idis/view/grid/IdisGrid',
    'idis/view/page/_PageBase',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/model/UserInfo',
    'app/config',
    'app/model/DisasterInfo',
    './AreaRescueUtil',
    'leaflet',
    'idis/consts/ACL',
    'idis/map/IdisMap',
    'idis/view/Loader',
    'idis/view/form/AclButton',
    'idis/view/dialog/DialogChain',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/form/NumberTextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/RadioButton',
    'dojox/form/Uploader',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/DateTimeInput',
    'idis/view/form/RadioGroup',
    'app/rescue/RescueGrid',
    'app/view/page/MapPage',
    'app/view/form/MunicipalitySelector',
    './RescueFormDialog'
], function (module, declare, lang, domStyle, template, array, json, topic, domClass,
    domConstruct, Memory, Rest, registry, IdisGrid, _PageBase,
    Locator, Router, Requester, IdisRest, UserInfo, config, DisasterInfo, Util,
    leaflet, ACL, IdisMap, AclButton, Loader, DialogChain) {

    /**
    * 検索条件格納用オブジェクト
    * @type {Object}
    */
    var RescueFilterStore = {};

    /**
    * 部隊活動情報画面。
    * @class RescuePage
    * @extends module:idis/view/page/_PageBase~_PageBase
    */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/rescue/RescuePage~RescuePage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--rescue',

            /**
             * データ格納用オブジェクト
             * @type {module:dstore/Store}
             */
            store: null,

            /**
             * 部隊活動ID
             */
            _rescueId: null,

            /**
             * 部隊活動ID
             */
            _RescueId: null,

            /**
             * 災害ID
             */
            _disasterId: null,

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
                this.own(this.formDialog);
            },

            constructor: function () {
                // データ格納用オブジェクト
                this.store = new IdisRest({
                    idProperty: 'RescueId',
                    target: '/api/rescue'
                });
                this.chain = DialogChain.get(this);

                //災害IDをセット(ここで正しい？)
                this._disasterId = DisasterInfo.getDisasterId();
            },

            startup: function () {
                this.inherited(arguments);
                // グリッドを初期化する
                this.initGrid();
                // 新規登録ボタンの活性/非活性を初期化する
                this.setButtonStatus();

                this.initMap();

                this.addActivityArea();
            },


            /**
             * マップを初期化する。
             */
            initMap: function () {
                var latlng = [config.map.latitude, config.map.longitude];

                this.map = new IdisMap(this.mapNode, {
                    config: config.map,
                    keyboard: false, // コメント時に+/-が使用できないため
                    touchExtend: false,
                    minZoom: 9,
                    maxZoom: 18,
                    drawControlTooltips: false
                }
                ).setView(latlng, 9);
                // destroy時にmapを破棄するよう設定
                this.own(this.map);

                // 生成したmapのlayerControlを画面にセットする。
                this._layerControl = this.map.layerControl;
            },

            /**
             * マップをリフレッシュ
             */
            refreshMap: function (data) {
                // mapに計画情報を反映
                topic.publish(
                    'app/rescue/RescuePage::selectedFromGrid',
                    Util.historyListToHash(data));
            },

            /**
             * 計画を地図に表示
             * @param {*} payload
             */
            _setPlan: function (payload) {
                var matchedLayer;
                this.featureLayer.eachLayer(function (layer) {
                    if (payload.researchAreaCd === layer.feature.properties.DISTRICT_CD) {
                        matchedLayer = layer;
                        lang.mixin(layer.feature.properties, payload);
                    }
                });
                this.featureLayer.resetStyle(matchedLayer);
                this.map.panInsideBounds(matchedLayer.getBounds());
            },

            setButtonStatus: function () {
                var role = UserInfo.getRoleCd();
                if (role === ACL.VIEW_USER) {
                    this.RegisterButton.set('disabled', true);
                    this.FileUploadButton.set('disabled', true);
                }
            },

            /**
             * ファイルアプロードダイアログ初期化処理
             */
            initFileUploadDialog: function () {
                // 詳細ダイアログの最初の子要素が詳細画面
                var dialog = this.fileUploadDialog;
                var page = dialog.getChildren()[0];
                // 登録ボタンが押された際の動作
                page.on('upload', lang.hitch(this, function () {
                    dialog.hide();
                    this.updateGridQuery(this.form.get('value'));
                }));
            },

            showFileUploadDialog: function () {
                this.fileUploadDialog.show();
            },

            /**
             * グリッドを初期化する。
             */
            initGrid: function () {
                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.rescueGrid.on('detailButtonClick', lang.hitch(this, function (evt) {
                    this.onDetailButtonClick(evt.item);
                }));

                // 保管した検索条件をセットする
                this.setFilterData();
                this.updateGridQuery(this.form.get('value'));
            },

            /**
             * 活動エリアを表示する
             */
            addActivityArea: function () {
                // rescueId = 1のデータを取得
                var rescueId = 1;
                // var rescueIds = [1];
                // // 本当は検索したデータすべてを表示
                // array.forEach(rescueIds, function(id){
                //
                // });
                Requester.get('/api/rescue/' + rescueId, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' },
                    handleAs: 'json',
                    preventCache: true
                }).then(lang.hitch(this, function (item) {
                    // 地図情報の整形
                    //item.rescueGeoInfo = item.rescueGeoInfo.replace(/'/g,'"');
                    //this.geojson = json.parse(item.rescueGeoInfo);

                    var url = '/data/kumamoto.geojson';
                    Requester.get(url, {
                        preventCache: false
                    }).then(lang.hitch(this, function () {
                        var featureLayer = leaflet.geoJson(this.geojson);
                        featureLayer.addTo(this.map);
                        //地図がクリックされたらポップアップを表示
                        this.showPopup(featureLayer, item);
                    }));
                }));

            },

            showPopup: function (featureLayer, item) {
                //TODO:URL直す
                var url = 'http://localhost:3000/?rescueId=' + item.rescueId + '&p=rescue%2Fdetail';

                // クリックイベント
                featureLayer.on('click', lang.hitch(this, function (evt) {
                    // ファイルを表示するHTMLを作成
                    this.displayedFiles = '';
                    array.forEach(item.attachFiles, function (file) {
                        this.displayedFiles += '<img src="' + file.attachFilePath +
                            '" class="is-showPreview" style="width:10em">';
                    }, this);

                    // ダイアログのHTMLを作成
                    var detail = '<div>種別：' + item.rescueType +
                        '</div><div>活動内容：' + item.activityContent + '</div>' +
                        this.displayedFiles + '<div align="right" data-dojo-attach-event="click:onDetailButtonClic"' +
                        'data-dojo-attach-point="detailButtonClickOnPopup"><a href="' +
                        url + '">詳細></a></div>';

                    // 部隊活動情報をポップアップで表示
                    var popup = leaflet.popup();
                    popup.setLatLng(evt.latlng);
                    popup.setContent(detail);
                    popup.openOn(this.map);
                }));
            },

            /**
             * 計画を地図に表示
             * @param {*} payload
             */
            _setSituations: function (payload) {
                array.forEach(this.geojson.features, function (feature) {
                    if (payload[feature.properties.rescueGeoInfo]) {
                        lang.mixin(feature.properties, payload[feature.properties.rescueGeoInfo]);
                    }
                });
                this.showLayer();
            },

            /**
             * グリッドで選択された計画を地図でハイライト
             * @param {*} payload
             */
            _selectedFromGrid: function (payload) {
                this.featureLayer.eachLayer(lang.hitch(this, function (layer) {
                    var areaCode = layer.feature.properties.DISTRICT_CD;
                    if (areaCode === payload[0].researchAreaCode) {
                        // 地図移動
                        this.map.panInsideBounds(layer.getBounds());
                        this.map.closePopup();
                    }
                }));
            },

            _toggleLayer: function (evt) {
                return evt;
            },

            /**
             * 検索
             */
            onSubmit: function () {
                try {
                    if (this.form.validate()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery(this.form.get('value'));
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
             * 検索パラメーターの設定
             */
            updateGridQuery: function (value) {
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();
                // 災害IDをセット
                filter = filter.eq('disasterId', this._disasterId);

                // 部隊種別
                var rescueType = '';
                // チェックされていればカンマ区切りで値を挿入
                // 自衛隊
                if (this.rescueTypeSdf.checked) {
                    rescueType += this.rescueTypeSdf.value;
                    rescueType += ',';
                }
                // 警察
                if (this.rescueTypePolice.checked) {
                    rescueType += this.rescueTypePolice.value;
                    rescueType += ',';
                }
                // 消防
                if (this.rescueTypeFire.checked) {
                    rescueType += this.rescueTypeFire.value;
                    rescueType += ',';
                }
                // その他
                if (this.rescueTypeOther.checked) {
                    rescueType += this.rescueTypeOther.value;
                }
                // 部隊種別をセット
                if (rescueType) {
                    filter = filter.eq('rescueType', rescueType);
                }

                // 過去報表示
                if (this.activeFlg.checked) {
                    filter = filter.eq('activeFlg', this.activeFlg.value);
                }

                // 報告日時FROM
                if (value.reportDateTimeFrom) {
                    var reportDateTimeFrom = new Date(value.reportDateTimeFrom).getTime();
                    filter = filter.eq('reportDateTimeFrom', reportDateTimeFrom);
                }

                // 報告日時TO
                if (value.reportDateTimeTo) {
                    var reportDateTimeTo = new Date(value.reportDateTimeTo).getTime();
                    filter = filter.eq('reportDateTimeTo', reportDateTimeTo);
                }

                // 活動状況
                var activityStatus = '';
                // チェックされていればカンマ区切りで値を挿入
                // 活動予定
                if (this.activityStatusScheduled.checked) {
                    activityStatus += this.activityStatusScheduled.value;
                }
                // 活動中
                if (this.activityStatusActive.checked) {
                    activityStatus += this.activityStatusActive.value;
                }
                // 活動終了
                if (this.activityStatusEnd.checked) {
                    activityStatus += this.activityStatusEnd.value;
                }
                activityStatus = activityStatus.replace(/(.)(?=.)/g, '$1,');
                // 活動状況をセット
                if (activityStatus) {
                    filter = filter.eq('activityStatus', activityStatus);
                }

                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);

                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.rescueGrid.set('collection', collection);
            },

            /**
             * 検索条件を保管する
             */
            setFilterStore: function () {
                //初期化する
                RescueFilterStore = {};
                var value = this.form.get('value');

                // 報告日時FROM
                if (value.reportDateTimeFrom) {
                    RescueFilterStore.reportDateTimeFrom = value.reportDateTimeFrom;
                }

                // 報告日時TO
                if (value.reportDateTimeTo) {
                    RescueFilterStore.reportDateTimeTo = value.reportDateTimeTo;
                }

            },

            /**
             * 保管した検索条件をフォームにセットする
             */
            setFilterData: function () {
                //保管されていれば値をセット

                // 報告日時FROM
                if (RescueFilterStore.reportDateTimeFrom) {
                    this.reportDateTimeFrom.set('value', RescueFilterStore.reportDateTimeFrom);
                }

                // 報告日時TO
                if (RescueFilterStore.reportDateTimeTo) {
                    this.reportDateTimeTo.set('value', RescueFilterStore.reportDateTimeTo);
                }
            },

            outputListExcel: function () {
                this.rescueFormDialog.show();
            },

            //パンくずリストのボタンを押下したときの挙動
            onRescueAdminPageLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('rescue/admin');
            },


            /**
             * 新規登録画面を表示
             */
            showRegisterPage: function () {
                //検索条件の保管
                this.setFilterStore();

                //新規画面に移動
                Router.moveTo('rescue/register');
            },

            /**
             * 詳細画面を表示
             */
            onDetailButtonClick: function (object) {
                //検索条件の保管
                this.setFilterStore();

                // 部隊活動情報詳細画面へ遷移
                Router.moveTo('rescue/detail', {
                    rescueId: object.rescueId
                });
            },

            // 帳票ダイアログを表示する
            onOutputButtonClick: function () {
                //出力する項目
                this.formDialog.show();
            }

            //帳票出力
            // downloadExcel: function () {
            //     var fileName = '部隊活動情報.csv';
            //     // TODO:出力できるようにする
            //     var url = '/bousai/Download/Download?rescueid=' + this._rescueId;
            //     FilesUtils.downloadGet(url, fileName);
            //     return false;
            // }
        });
});
