/**
 * ダム詳細情報のグラフ
 * @module app/dam/chart/DamHistoryChart
 */
define([
    'module',
    'app/view/chart/ChartConfig',
    'app/view/chart/ChartCommon',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojox/charting/action2d/Tooltip',
    'dojox/charting/action2d/Magnify',
    'dojox/charting/Chart',
    'dojox/charting/plot2d/ClusteredColumns',
    'dojox/charting/plot2d/Lines',
    'dojox/charting/themes/Bahamation',
    'dojox/charting/widget/SelectableLegend',
    //
    'dojox/charting/axis2d/Default'
], function(module, ChartConfig, ChartCommon,
    array, declare, lang,
    Tooltip, Magnify, Chart, ClusteredColumns, Lines, Theme, SelectableLegend
) {
    /**
     * ダム詳細情報のグラフ
     * @class DamHistoryChart
     */
    return declare(module.id.replace(/\//g, '.'), null,
        /** @lends module:app/dam/chart/DamHistoryChart~DamHistoryChart# */
    {
        title: '雨量',

        maxYAxis: 0,        // Y軸最大値
        minYAxis: 0,        // Y軸最小値
        maxRightAxis: 0,    // 右軸最大値


        legend: {},

        // 基準値
        excessLevelData: {
            'warning60min'	       : 50,
            'alert60min'	       : 20,
			'caution60min'	       : 10,
            'warningCumulative'	   : 150,
            'alertCumulative'	   : 80,
			'cautionCumulative'	   : 50
        },

        rainfallKeyName : 'rainfall10Min',


        /**
         * グラフのノードを生成する
         * @param {*} node グラフを入れたいhtml内のdivに対するdojo-data-attach-pointで示したオブジェクト
         * @param {*} LegendNode 凡例を入れたいhtml内のdivに対するdojo-data-attach-pointで示したオブジェクト
         * @param {*} options.mode 検索条件の時間モード
         * @param {*} options.data 詳細GridのCollectionのデータ
         */
        create: function(node, LegendNode, options) {
            if(options){
                // 引数を変数にセット
                lang.mixin(this, options);
                // Gridとは逆順に並べる必要がある
                this.data.reverse();

                // 再作成の場合、表を破棄
                ChartCommon.destroyChart(this.chart);

                // タイトルをセット
                this.title = {
                    '10' : '10分',
                    '30' : '30分',
                    '60' : '60分'
                }[this.mode] + '雨量';

                this.rainfallKeyName = 'rainfall' + this.mode + 'Min';

                // 左右の縦軸の最大値をセット
                this.setMaxAxis();
            }

            this.chart = new Chart(node, {
                title: this.title,
                titlePos: 'top',
                titleGap: 10
            });

            // グラフのデザインテーマ, 他のグラフ群と合わせる
            this.chart.setTheme(Theme);

            // グラフの背景に描画される線の種類を決定。
            ChartCommon.addGridPlot(this.chart);

            // 雨量軸
            this.chart.addAxis('y', {
                min: 0.0,
                max: this.maxYAxis+0.5,
                vertical: true,
                fixLower: 'minor',
                fixUpper: 'minor'
            });

            // 予測雨量分の時間を追加&、チャートデータを取得する
            var futureChartData = this.addFutureRainfallData();

            // 時間軸
            ChartCommon.addDataTimestampXAxis(this.chart, this.data);

            // 累計雨量軸
            this.chart.addAxis('right',{
                min: 0.0,
                max: this.maxRightAxis,
                vertical: true,
                leftBottom: false,
                fixLower: 'major',
                fixUpper: 'major'
            });
	        // 累計雨量の線グラフを追加
			this.chart.addPlot('rainfallCumulative', {
				type: Lines,
				markers: true,
				vAxis: 'right'
			});
            // 線グラフとプロットされる点を紐付け
			this.chart.addSeries('累計雨量[mm]',
                ChartCommon.getChartData(this.data, 'rainfallCumulative'), {
                plot: 'rainfallCumulative',
                marker: ChartConfig.MARKER_SHAPE,
                stroke: {color: '#00ffff'},
                fill: '#00ffff'
            });
            //チェックによる非表示、Gridとの対応
            this.rainfallCumulativeTooltip = new Tooltip(this.chart, 'rainfallCumulative');
            this.rainfallCumulativeMagnify = new Magnify(this.chart, 'rainfallCumulative');

			// 累加雨量最大値が基準値以上場合、累計雨量警戒値表示
			if(this.maxRightAxis>=this.excessLevelData.warningCumulative){
				this.chart.addPlot('cumulativeExcessLevel', {
					type: Lines,
					vAxis: 'right'
				});
				// 基準値データ取得＆横軸分要素配列作成
				var warningCumulative = [];
				var cautionCumulative = [];
				var alertCumulative = [];
                array.forEach(this.data, function(){
                    cautionCumulative.push(this.excessLevelData.cautionCumulative);
					alertCumulative.push(this.excessLevelData.alertCumulative);
					warningCumulative.push(this.excessLevelData.warningCumulative);
                }, this);
                this.chart.addSeries(
                    '累計雨量警戒値('+this.excessLevelData.warningCumulative+'mm)',
                    warningCumulative, {plot: 'cumulativeExcessLevel',
                    stroke: {color: '#cf19fc'}});
                this.chart.addSeries(
                    '累計雨量警戒値('+this.excessLevelData.alertCumulative+'mm)',
                    alertCumulative, {plot: 'cumulativeExcessLevel',
                    stroke: {color: '#fcc20f'}});
                this.chart.addSeries(
                    '累計雨量警戒値('+this.excessLevelData.cautionCumulative+'mm)',
                    cautionCumulative, {plot: 'cumulativeExcessLevel',
                    stroke: {color: '#f4fd10'}});
			}


			// 60分雨量最大値が60分雨量警戒値以上の場合、基準値表示
			if(!this.data||this.mode==='60'&&this.maxYAxis>=this.excessLevelData.warning60min){
				this.chart.addPlot('60minExcessLevel', {
					type: Lines
				});
				// 基準値データ取得＆横軸分要素配列作成
				var warning60min = [];
				var caution60min = [];
				var alert60min = [];
                array.forEach(this.data, function(){
                    caution60min.push(this.excessLevelData.caution60min);
					alert60min.push(this.excessLevelData.alert60min);
					warning60min.push(this.excessLevelData.warning60min);
                }, this);
                this.chart.addSeries(
                    '60分雨量警戒値('+this.excessLevelData.warning60min+'mm)',
                    warning60min, {plot: '60minExcessLevel',
                    stroke: {color: '#fc0502'}});
                this.chart.addSeries(
                    '60分雨量警戒値('+this.excessLevelData.alert60min+'mm)',
                    alert60min, {plot: '60minExcessLevel',
                    stroke: {color: '#fcc20f'}});
                this.chart.addSeries(
                    '60分雨量警戒値('+this.excessLevelData.caution60min+'mm)',
                    caution60min, {plot: '60minExcessLevel',
                    stroke: {color: '#f4fd10'}});
            }

            // 雨量の線グラフを追加
            this.chart.addPlot(this.rainfallKeyName, {
                type: ClusteredColumns,
				gap: 5
            });
            // 線グラフとプロットされる点を紐付け
			this.chart.addSeries(this.title + '[mm]',
                ChartCommon.getChartData(this.data, this.rainfallKeyName), {
                plot: this.rainfallKeyName
            });
            //チェックによる非表示、Gridとの対応
            this.rainfallTooltip = new Tooltip(this.chart, this.rainfallKeyName);

            // 予想雨量データがあれば追加
            if (!this.data || futureChartData) {
                this.chart.addPlot('future', {
                    type: ClusteredColumns,
                    gap: 5,
                    stroke: {color: 'lightblue'},
                    fill: 'lightblue'
                });
                this.futureTooltip = new Tooltip(this.chart, 'future');
                // 線グラフとプロットされる点を紐付け
                this.chart.addSeries('予想雨量[mm]',
                    futureChartData || [], {
                    plot: 'future',
                    marker: ChartConfig.MARKER_SHAPE
                });
            }

            this.chart.render();

            // プロットの凡例を表示
            // 初回起動時のみ生成
            if (!this.legend.id) {
                this.legend = new SelectableLegend({ chart: this.chart, horizontal: true }, LegendNode);
            }
            this.legend.set('chart', this.chart);
            this.legend.refresh();
	        // 縦軸のタイトルを表示
            ChartCommon.setAxisTitle(this.chart, 'y', '雨量[mm]');
            ChartCommon.setAxisTitle(this.chart, 'right', '累計雨量[mm]');
        },

        /**
         * 予想雨量分の時間を横軸に追加するためにthis.dataに予測時間を追加、
         * また、予想雨量のチャートデータを返す。
         * 予想雨量が全てnullの場合は、nullを返して、表示しない。
         */
        addFutureRainfallData: function(){
            // 予測雨量ないならnullを返す
            if (!this.futureData){
                return null;
            }
            var chatData = [];
            var isNullCount = 0;
            var counter = this.data.length + 1;
            // 検索観測日時
            var targetDataTimestamp = this.data.slice(-1)[0].dataTimestamp;
            for (var i = 1; i <= 3; i++) {
                var futureLevel = this.futureData['futureRainfall' + i + 'hour'];
                if (futureLevel!==null) {
                    // 雨量情報の右側に追加
                    chatData.push({ x: counter, y: futureLevel });
                    // 検索観測日時 ＋ i時間 をデータに追加する
                    var futureTimestamp = new Date(targetDataTimestamp)
                                .setHours(new Date(targetDataTimestamp).getHours() + i);
                    this.data.push({dataTimestamp: futureTimestamp});
                    counter++;
                } else {
                    isNullCount++;
                }
            }
            return isNullCount < 3 ? chatData : null;
        },

        setMaxAxis: function() {
            // Y軸の最大値をセット
            this.maxYAxis = ChartCommon.getMaxData(this.data, this.rainfallKeyName, 0);
            if(this.mode==='60'){
                // 60分雨量の基準値以上の場合、最大基準値を最大値とする
                this.maxYAxis = this.maxYAxis > this.excessLevelData.caution60min ?
                                this.maxYAxis : this.excessLevelData.warning60min;
            }
            if (this.futureData) {
                // 予測雨量とも比較
                for (var i = 1; i <= 3; i++) {
                    var futureLevel = this.futureData['futureRainfall' + i + 'hour'];
                    if (futureLevel) {
                        this.maxYAxis = this.maxYAxis > futureLevel ?
                                        this.maxYAxis : futureLevel;
                    }
                }
            }
            // 右軸の最大値をセット
            this.maxRightAxis = ChartCommon.getMaxData(this.data, 'rainfallCumulative', 0);
            // 基準値以上の場合、最大基準値を最大値とする
            this.maxRightAxis = this.maxRightAxis > this.excessLevelData.cautionCumulative ?
                                this.maxRightAxis : this.excessLevelData.warningCumulative;
        }
    });
});