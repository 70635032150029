/**
* 水道局災害名一覧画面用グリッド。
* @module app/waterworks/grid/IncidentGrid
*/
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'dojo/dom-construct',
    'idis/util/DateUtils',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function(module, declare, IdisGrid, helper, domCon, DateUtils) {

    var formatDateTime = function(value) {
        var dateOption = {
            date:'yyyy-MM-dd',
            time:'HH:mm:ss'
        };
        return DateUtils.format(value, dateOption);
    };

    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            helper.column('incidentId', '水道局災害ID'),
            helper.column('incidentName','水道局災害名', {sortable: false}),
            {field: 'disasterId', label:'災害ID（防災情報システム）',
                renderCell: function(item){
                    var content = null;
                    var comment = null;
                    if(item.relatedFlg === null || item.relatedFlg === '0'){
                        comment = domCon.create('div');
                        comment.textContent = '-';
                        content = comment;
                    }else{
                        comment = domCon.create('div');
                        comment.textContent = item.disasterId;
                        content = comment;
                    }
                    return content;
            }},
            {field: 'disasterName', label:'災害名（防災情報システム）', sortable: false, 
                renderCell: function(item){
                    var content = null;
                    var comment = null;
                    if(item.relatedFlg === null || item.relatedFlg === '0'){
                        comment = domCon.create('div');
                        comment.textContent = '-';
                        content = comment;
                    }else{
                        comment = domCon.create('div');
                        comment.textContent = item.disasterName;
                        content = comment;
                    }
                    return content;
            }},
            {field: 'updTimestamp', label: '更新日時',
                formatter: function(updTimestamp){
                    return updTimestamp ? formatDateTime(updTimestamp) : '-';
                }
            }
        ]
    });
});