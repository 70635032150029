/**
* 地震情報一覧画面用モジュール。
* @module app/earthquake/view/EarthquakePage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojox/lang/functional/object',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    '../../config',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function(module, declare, array, lang, locale, df, IdisGrid, helper, config) {

    /**
     * 日時を表示形式に変換する
     * 'yyyy-MM-dd HH:mm:dd'を'年月日時分'に変換する
     * FIXME 外部化を検討。helperで対応?
     *
     * @param {string} 'yyyy-MM-dd HH:mm:dd'
     * @return {string} 'yyyy年MM月dd日 HH時mm分'
     */
    var formatDateTime = function(value) {
        var dateLabel = locale.format(new Date(value.replace(/-/g, '/')), {
            selector: 'date',
              datePattern: 'MM月dd日'
        });
        var timeLabel = locale.format(new Date(value.replace(/-/g, '/')), {
            selector: 'time',
              timePattern: 'HH時mm分'
        });
        return dateLabel + ' ' + timeLabel;
    };

    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            return item && item.pubStatus === 1 ? 'is-public' : '';
        },

        /**
         * 各列の定義
         * @type {Object[]}
         */
         columns: [
             helper.buttonColumn('detail', '詳細'),
             helper.column('reportDatetime', '発表日時', {
                formatter: function(reportDatetime){
                    return formatDateTime(reportDatetime);
                }
             }),
             helper.column('regions', config.municInfo.prefName, {
                formatter: function(regions) {
                    var include = array.some(regions, function(region){
                        var prefCode = config.municInfo.prePrefCd;
                        if (region.placeCode.slice(0,2)===prefCode) {
                            return true;
                        }
                    });
                    return (include) ? '有り' : '無し';
                },
                sortable: false
             }),
             helper.column('title', '発表の種類'),
             helper.column('originTime', '発現日時', {
                formatter: function(originTime){
                    return originTime ? formatDateTime(originTime) : '-';
                },
                sortable: false
             }),
             helper.column('hypocenyterName', '震央地名', {sortable: false}),
             helper.column('magnitude', 'マグニチュード', {sortable: false}),
             helper.column('regions', '最大震度', {
                formatter: function(regions) {
                    var max = 0;
                    array.forEach(regions, function(region){
                        var sindo4calc;
                        if (region.intensity === '5-') {
                            sindo4calc = '5';
                        } else if (region.intensity === '5+') {
                            sindo4calc = '6';
                        } else if (region.intensity === '6-') {
                            sindo4calc = '7';
                        } else if (region.intensity === '6+') {
                            sindo4calc = '8';
                        } else if (region.intensity === '7') {
                            sindo4calc = '9';
                        } else {
                            sindo4calc = region.intensity;
                        }
                        max = ((max > Number(sindo4calc)) ? max : Number(sindo4calc));
                    });
                    if (max === 5) {
                        max = '5弱';
                    } else if (max === 6) {
                        max = '5強';
                    } else if (max === 7) {
                        max = '6弱';
                    } else if (max === 8) {
                        max = '6強';
                    } else if (max === 9) {
                        max = '7';
                    }
                    return max;
                },
                sortable: false
             })
         ]
     });
 });
