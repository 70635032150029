define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--user',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            // ボタン
            helper.buttonColumn('detail', '詳細'),
            { field: 'userId', label: 'ユーザID', sortable: true },
            { field: 'roleName', label: 'アカウント種類', sortable: true },
            { field: 'organizationName', label: '組織', sortable: true },
            { field: 'userName', label: 'ユーザ名', sortable: true },
            { field: 'representativeName', label: '代表者氏名', sortable: false },
            { field: 'representativePhoneNumber', label: '電話番号', sortable: false },
            { field: 'representativeMailAddress', label: 'メールアドレス', sortable: false }
        ]
    });
});
