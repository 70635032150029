/**
 * テンプレート一覧グリッド(送信ダイアログ用)。
 * @module app/convocation/view/mail/ConvoTempInsertGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/on',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/view/form/Button',
//    'idis/model/UserInfo',
//    'dojo/dom-construct',
    './ConvoTempInsertDialog'
], function(module, declare, on, IdisGrid, helper, Button) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid,
        /** @lends module:idis/view/page/ConvoTempInsertGrid~ConvoTempInsertGrid# */ {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        collection: this.store,
        columns: [
            helper.buttonColumn('insert', '適用'),
            helper.column('distributionTitle', '件名'),
            helper.column('distributionBody', '本文'),
            // helper.buttonColumn('detail', '修正')
            // 修正ダイアログボタン
            {field: 'detail', label:'詳細', sortable: false,
            renderCell: function(item){
                var gridNode = this.grid.domNode;
                var button = new Button({
                    label: '詳細',
                    onClick: function() {
                        on.emit(gridNode, 'detailButtonClick', {item: item});
                    }
                });
                var content = null;

                //console.log(item);

                // 作成者、または、管理者
//                if(item.crtUserId === UserInfo._userInfo.userId || UserInfo._userInfo.roleCd === 'R01001') {
                    content = button.domNode;
//                } else {
//                    var comment = domCon.create('div');
//                    comment.textContent = '-';
//                    content = comment;
//                }
                return content;
            }}
        ]
    });
});
