/**
 * チェック可能なツリー用モジュール。
 * @module app/evacorder/EvacOrderCheckTree
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/topic',
    'dojo/has',
    'idis/view/tree/CheckTree'
], function(module, declare, lang, array, topic, has, CheckTree) {
    /**
     * 各要素をチェック可能なツリー
     * @class EvacOrderCheckTree
     * @extends module:idis/view/tree/CheckTree~CheckTree
     * @param {Object} kwArgs
     * @param {module:dijit/tree/model} kwArgs.model ツリー・モデル
     */
    return declare(module.id.replace(/\//g, '.'), CheckTree,
    /** @lends module:idis/view/tree/CheckTree~EvacOrderCheckTree# */ {
    
    /**
     * 識別子と要素の対応
     * @type {Object<string,Object>}
     */
    _itemMap: {},

        /**
         * ツリー要素がチェックされたときに呼ばれる。
         * CheckTreeクラスをのonCheckChangeをオーバーライドしている。
         * チェック時にレイヤーの選択状態・非選択状態を設定する。
         *
         * @param {Object} item ツリー要素
         * @param {boolean} checked チェック状態
         * @returns {None|Promise} 非同期処理を実行する場合はPromiseを返す。
         * override
         */
        onCheckChange: function(item, checked) {
            var id = this.model.getIdentity(item);
            console.debug(module.id + '#onCheckChange: id=' + id + ', checked=' + checked);
            topic.publish(module.id + '::selectDistrict', id);
            topic.publish(module.id + '::calcEvacOrderTarget');
        },
        
        /**
         * 指定された要素のチェック状態をまとめて更新する。
         * とにかく高速化するために不要な処理を省いている。
         * 親子のチェック、半チェック判定などはせずに強制的にチェック状態を更新する。
         * @param {object} item チェック状態更新対象の要素
         * @param {boolean} checked 新しいチェック状態
         */
        setAllChecked: function(items, checked) {
            array.forEach(items, function(item){
                // 要素自身の状態を更新
                var id = this.model.getIdentity(item);
                var oldChecked = !!this._checkMap[id];
                // チェック中要素の対応付けを更新
                if (checked) {
                    this._checkMap[id] = item;
                } else {
                    delete this._checkMap[id];
                }
                // 半チェック要素から外す
                delete this._halfCheckMap[id];
                // 表示情報が変化した場合は表示を更新
                // （非チェック・半チェック間の変化を含む）
                this._onItemChange(item);
                // チェック状態が変化した場合はカスタム処理を実行
                // （非チェック・半チェック間の変化は含まない）
                if (checked !== oldChecked) {
                    this.onCheckChange(item, !!this._checkMap[id]);
                }
                
                // チェック処理が終わった要素について、「処理完了マップ」に追加する。
                if (checked && id !== '$ROOT$') {
                    this._checkCompletedMap[id] = item;
                } else if(id !== '$ROOT$') {
                    delete this._checkCompletedMap[id];
                }
            }, this);
        },
        
        /**
         * itemsの親要素にあたる地区コードを再起的に(ルートまで)取得する。
         * @param {object} items 地区コードのリスト
         */
        getParentsRecursive: function(districtCds) {
            var result = [];
            var children = districtCds;
            
            while (true) {
                if(children === null || children.length === 0){
                    break;
                }
                var parents = [];
                // 
                /* jshint ignore:start */
                array.forEach(children, lang.hitch(this, function(child){
                    var parent = this._itemMap[child] && this._itemMap[child].parentId;
                    if(parent && result.indexOf(parent) === -1 && parent !== '$ROOT$'){
                        parents.push(parent);
                        result.push(parent);
                    }
                }), this);
                /* jshint ignore:end */
                children = parents;
            }
            return result;
        }
    });
});
