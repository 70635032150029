/**
 * よくある質問集画面用のモジュール
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/text!./templates/QuestionsPage.html',
    'idis/view/page/_PageBase'
], function (module, array, declare, template, _PageBase) {

    return declare(module.id.replace(/\//g, '.'), _PageBase, {

        // 基本クラス
        baseClass: 'idis-Page idis-Page--link',

        // テンプレート文字列
        templateString: template,

        /**
         * 質問、回答の一覧
         */
        questionList: [
            {
                category: 'アカウント',
                question: [{
                        name: '問1：庁内メールアドレスが未登録の人がいます。登録したいが、どのようにすれば良いでしょうか。',
                        children: [{
                            name: '所属管理者（防災担当者）を通じて危機管理室までお問合せください。'}]
                    },{
                        name: '問2：パスワードをリセットする方法について知りたいです。',
                        children: [{
                            name: 'パスワードをリセットする方法は以下3種類あります。\n' +
                            '1.Webシステムログイン画面のリンクからリセットを実施する。\n' +
                            '2.<a href="?p=user" class="toUserPage"'+
                            '" target="_blank" rel="noopener noreferrer">'+
                            '利用者管理画面</a>'+
                            'でご自身の詳細を表示し、アカウント情報出力（パスワードリセット）を実施する。\n' +
                            '3.<a href="?p=user" class="toUserPage"'+
                            '" target="_blank" rel="noopener noreferrer">'+
                            '利用者管理画面</a>でご自身の詳細を表示し、パスワード欄、パスワード（確認）欄に任意のパスワードを入力し、更新する。\n' +
                            '「パスワード再発行のお知らせ」メールが複数件届いた場合、最新のメールのパスワードでログインをお試しください。\n' +
                            'お試しいただいてもログインできない場合、ヘルプデスクへお問い合わせください。',
                        }]
                    },{
                        name: '問3：利用者管理画面で、自分以外のユーザの情報を検索・閲覧できません。',
                        children: [{
                            name: '一般職員ユーザの場合は、セキュリティ面やプライバシーを考慮し、自分の情報のみを閲覧・編集できるよう制御しています。'}]
                    },{
                        name: '問4：再任用の職員で、以前のアカウント情報でログインができません。どのようにすれば良いでしょうか。',
                        children: [{
                            name: '退職に伴い、以前のアカウントは削除されています。\n'+
                            '再任用後に利用が必要な方は新規発行しますので、各所属の防災担当者までお問い合わせください。'}]
                    }
                ]
            },{
                category: 'アプリ',
                question: [{
                        name: '問1：アプリ更新通知から更新する方法がわかりません。',
                        children: [{
                            name: 'マニュアル「アプリ導入・更新・削除ガイド_Android版」もしくは「アプリ導入・更新・削除ガイド_iOS（iPhone）版」を参照ください。'}]
                    },{
                        name: '問2：iOSでのアプリ引き換えコードを取得したいです。',
                        children: [{
                            name: '利用者管理からご自身の詳細ダイアログを表示し、iOSアプリ引き換えコードの「再発行ボタン」を押すと、登録されているメールアドレスに送付されます。\n'+
                            'マニュアル「アプリ導入・更新・削除ガイド_iOS（iPhone）版」を参照ください。'}]
                    },{
                        name: '問3：スマートフォンアプリで登録した避難所情報・被害情報がwebで確認できません。',
                        children: [{
                            name: '参照している災害名が異なっている可能性があります。スマートフォンアプリで選択した災害名と同じ災害名を選び、各機能から確認を行ってください。'}]
                    },{
                        name: '問4：避難所状況で登録ボタンが表示されません。',
                        children: [{
                            name: 'ユーザーの権限によって登録の可否が設定されています。'}]
                    },{
                        name: '問5：個人所有のスマートフォンを利用しても良いでしょうか。',
                        children: [{
                        name: '個人所有のスマートフォンを利用しても問題ありません。但し、通信に係る費用等はすべて自己負担となる旨をご理解の上ご利用ください。'}]
                    },{
                        name: '問6：モバイルアプリの「被害情報投稿」から被害情報を登録しましたが、Web上で確認する方法を教えてほしい。',
                        children: [{
                        name: '「情報共有」＞「地図」画面を開きます。「表示情報」のパネルを開き表示情報ツリーを表示し、アプリで選択した災害名と同じ災害名を選択します。\n'+
                        '災害名＞「大阪市」＞被害情報投稿時に設定した区＞「被害情報投稿」＞被害情報投稿時の日付からご確認できます。\n'+
                        '詳細はマニュアル「大阪市防災情報システムスマートフォンアプリ運用及び操作説明書」をご参照ください。'}]
                    }
                ]
            },{
                category: '災害',
                question: [{
                        name: '問1：訓練用に新しく災害を作りたいです。',
                        children: [{
                            name: '所属管理者（防災担当者）を通じて危機管理室までお問合せください。'}]
                    },{
                        name: '問2：災害名の変更ができません。変更ボタンがありませんが、どうしたら良いでしょうか。',
                        children: [{
                            name: '災害名の変更はトップページでのみ可能です。\n'+
                            '画面左上の「大阪市防災情報システム」のリンクをクリックしトップページに遷移すると、災害名の右に変更ボタンが表示されます。'}]
                    },{
                        name: '問3：過去の災害情報を確認することはできますか。',
                        children: [{
                            name: 'トップページ上部で過去の災害名を選択することで確認することができます。'}]
                    },{
                        name: '問4：別の災害について確認・入力を行いたいです。',
                        children: [{
                            name: 'トップページ上部で災害名を選択することで確認・入力することができます。'}]
                    },{
                        name: '問5：入力したと報告のあった災害情報がシステム上に表示されません。',
                        children: [{
                        name: '入力時の災害名と閲覧している災害名が同じかどうかを確認してください。災害名は画面上部に表示されています。'}]
                    },{
                        name: '問6：災害モードが「宣言」で赤くなる時はどんな時でしょうか。',
                        children: [{
                        name: '災害モード宣言が入力されている場合、赤く表示されます。'}]
                    }
                ]
            },{
                category: 'システム全般',
                question: [{
                        name: '問1：操作中にエラーが発生したのですが、どうすれば良いでしょうか。',
                        children: [{
                            name: 'ヘルプデスクにお問合せください。'}]
                    }
                ]
            },{
                category: '地図',
                question: [{
                        name: '問1：地図情報を表示しようとしましたが、最新の情報が表示されません。',
                        children: [{
                            name: 'PC をお使いの場合は、「Shift」キー、「F5」キーを同時に押して、キャッシュをクリアしてください。\n'+
                            'モバイル端末をお使いの場合は、ブラウザから閲覧履歴の削除をしてください。\n'+
                            '解消しない場合には、ヘルプデスクまでお問合せください。'}]
                    },{
                        name: '問2：背景地図を変更することはできますか。',
                        children: [{
                            name: '背景地図ボタンクリックにより以下の地図から選択が可能です。\n'+
                            '道路地図、標準地図、淡色地図、写真地図、衛星画像地図、OpenStreetMap'}]
                    }
                ]
            },{
                category: '帳票',
                question: [{
                        name: '問1：帳票入力で読み込む帳票ファイルの項目列を削除したり、追加しても良いでしょうか。',
                        children: [{
                            name: '帳票ファイルの項目列の削除や追加はせず、帳票出力したテンプレート形式のまま使用してください。\n'+
                            '帳票読み込みは以下の機能にあります。\n'+
                            '・被害情報\n'+
                            '・避難所情報\n'+
                            '・避難所情報マスタ管理'}]
                    },{
                        name: '問2：帳票入力で読み込む帳票ファイルの入力内容を知りたいです。',
                        children: [{
                            name: '帳票出力したエクセルファイルの2シート目の「入力方法」シートを参照ください。'}]
                    },{
                        name: '問3：帳票入力時に「入力エラー」という画面が出て、エクセルファイルがダウンロードされました。このファイルはどういった物でしょうか。',
                        children: [{
                            name: 'そのファイルには、帳票入力時にエラーの原因となった対象データが茶色く塗りつぶされて表示されます。\n'+
                            '帳票の「入力方法」シートを参考に、エラーの原因となった対象データを修正してください。'}]
                    }
                ]
            },{
                category: '被害情報・避難所情報',
                question: [{
                        name: '問1：「自動承認モード」と画面に表示されているが、通常時と何が違うのでしょうか。どうやって解除するのでしょうか。',
                        children: [{
                            name: '自動承認モード適用中に操作された避難所開設情報は、すべて本部確認完了として登録・更新されます。\n'+
                            '自動承認モードの開始および解除操作は、各区本部のユーザのみ操作可能です。'}]
                    },{
                        name: '問2：被害の種類で、対象の被害が選択できません。',
                        children: [{
                            name: '利用者の所属により、登録できる被害が設定されています。利用者の所属が間違っている場合は、ヘルプデスクにお問合せください。'}]
                    },{
                        name: '問3：被害情報の確定報を誤って登録してしまいました。削除はできますか。',
                        children: [{
                            name: '確定報は定時報告や大阪府連携の集計対象となるため、ユーザ側の操作による削除ができません。\n'+
                            '危機管理室へお問い合わせいただき、削除したい旨と削除したい確定報の以下の情報をお伝えください。\n'+
                            '1. 管理番号\n'+
                            '2. 被害の種類\n'+
                            '3. 区\n'}]
                    }
                ]
            },{
                category: 'マニュアル',
                question: [{
                        name: '問1：マニュアルの場所を知りたいです。',
                        children: [{
                            name: '大阪市庁内ポータルの以下に掲載されております。\n'+
                            '危機管理室の所属サイト＞防災システム等の運用＞01_防災システムについて＞防災情報システム再構築関係資料＞00_新システムマニュアル等'}]
                    }
                ]
            },{
                category: 'メール',
                question: [{
                        name: '問1：気象情報のメールが配信されません。',
                        children: [{
                            name: 'メンテナンス＞利用者管理からご自身の詳細を開き、メール配信条件をご確認ください。'}]
                    },{
                        name: '問2：個人用アドレスを設定したが、メールが配信されません。',
                        children: [{
                            name: '\"@dcloud.z-hosts.com\" と \"@osaka-bousai.z-hosts.com\" のドメインからのメール受信を許可してください。\n'+
                            'また、個人用アドレスに間違いがないかご確認ください。\n'+
                            'キャリアメールの受信の設定は、以下URLを参照ください。（各キャリアのサイトに遷移します。）\n'+
                            '・docomo\n'+
                            '<a href="' +
                            'https://www.docomo.ne.jp/info/spam_mail/spmode/domain/' +
                            '" target="_blank" rel="noopener noreferrer">'+
                            'https://www.docomo.ne.jp/info/spam_mail/spmode/domain/'+
                            '</a>\n'+

                            '・au\n'+
                            '<a href="' +
                            'https://www.au.com/support/service/mobile/trouble/mail/email/filter/detail/domain/' +
                            '" target="_blank" rel="noopener noreferrer">'+
                            'https://www.au.com/support/service/mobile/trouble/mail/email/filter/detail/domain/'+
                            '</a>\n'+

                            '・softbank\n' +
                            '<a href="' +
                            'https://www.softbank.jp/mobile/support/mail/antispam/email-i/white-only/' +
                            '" target="_blank" rel="noopener noreferrer">'+
                            'https://www.softbank.jp/mobile/support/mail/antispam/email-i/white-only/'+
                            '</a>'}]
                    },{
                        name: '問3：動員指示が配信されません。',
                        children: [{
                            name: 'メンテナンス＞利用者管理からご自身の詳細を開き、動員区分を確認いただき、ご自身が動員対象であるかをご確認ください。\n'+
                            '動員区分が間違っている場合は、所属管理者（防災担当者）にご確認ください。'}]
                    },{
                        name: '問4：個人用アドレスを設定しドメインの許可設定をしているが、メールが配信されません。',
                        children: [{
                        name: '個人用アドレスに間違いがないかご確認ください。\n'+
                        'また、システムから配信されたメールが受信拒否されると、その宛先メールアドレスは配信停止対象に設定され、以後メールが配信されません。\n'+
                        '停止設定を解除するにはヘルプデスクにお問合せください。'}]
                    }
                ]
            },{
                category: '本部体制・動員体制',
                question: [{
                        name: '問1：配備体制で、体制状況が赤くなるのはどんな時ですか。',
                        children: [{
                            name: '本部種別が「災害対策本部」で、対策本部設置状況が「設置」の場合に赤く表示されます。\n'+
                            '一覧画面の詳細ボタンの押下により、設定内容を確認できます。'}]
                    }
                ]
            }
        ],

        // DOMノードを構築する
        buildRendering: function () {
            this.inherited(arguments);
            var html = [];

            /**
             * 説明資料ファイルPATH
             */
            var FAIL_PATH = '/data/questions/';

            array.forEach(this.questionList, function (questionList) {
                html.push('<details><summary><font size = "3">');
                html.push(questionList.category);
                html.push('</font></summary>');
                array.forEach(questionList.question, function (questionName) {
                    html.push('<section><h2>');
                    html.push(questionName.name);
                    html.push('</h2>');
                    array.forEach(questionName.children, function (value) {
                        html.push('<div><ul><li>');
                        var item = value.name;
                        while (item.includes('\n')) {
                            html.push(item.match(/^.+\n/));
                            html.push('<br>');
                            var lineNum = item.search(/\n.*/);
                            item = item.substr(lineNum + 1);
                        }
                        html.push(item);

                        if (item.includes('自分のユーザで何ができるのかを知りたい。')) {
                            var authzFilePath = FAIL_PATH + 'userAuthList.xlsx';
                            html.push('<a href="');
                            html.push(authzFilePath);
                            html.push('" download="ユーザ種別・権限一覧.xlsx">ユーザ種別・権限一覧.xlsx</a>');
                            console.log(authzFilePath);
                        }

                        html.push('</li></ul></div></section>');
                    });
                });
                html.push('</details>');
            }, this);
            this.linkContainer.innerHTML = html.join('');
        }
    });
});
