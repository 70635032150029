/**
 * 避難状況登録画面用モジュール。
 * @module app/view/page/EvacOrderRegisterFromRecommendPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/promise/all',
    'dojo/topic',
    'dojo/text!./templates/EvacOrderRegisterPage.html',
    'dojo/json',
    'app/evacorder/_EvacOrderRegisterPageBase',
    'app/model/DisasterInfo',
    'idis/view/Loader',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'app/config',
    'idis/consts/USER_TYPE',
    'idis/service/Requester',
    // 以下、変数で受けないモジュール
    'dijit/layout/TabContainer',
    'app/shelter/ShelterOpeningGrid',
    'app/evacorder/EvacOrderMunicipalitySelector',
    'app/provide/ProvideEvacOrderRegisterDialog',
    './EvacOrderPopulationGrid'
], function(module, array, declare, lang, domStyle, all, topic, template,
        json, _EvacOrderRegisterPageBase, DisasterInfo, Loader, Locator, Router,
        UserInfo, config, USER_TYPE, Requester) {

    /**
     * 避難状況登録画面。
     * @class EvacOrderRegisterPage
     * @extends module:app/evacorder/_EvacOrderRegisterPageBase~_EvacOrderRegisterPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _EvacOrderRegisterPageBase,
    /** @lends module:app/evacorder/EvacOrderRegisterFromRecommendPage~EvacOrderRegisterFromRecommendPage# */ {
        // テンプレート文字列
        templateString: template,

        _evacRecommendIds: null,

        _districtCdList: [],

        constructor: function(){
            console.debug('constructor');

            // URLから避難情報詳細IDを取得
            this._evacOrderId = Locator.getQuery().evacOrderId;
        },

        buildRendering: function() {
            console.debug('buildRendering');
            this.inherited(arguments);
        },

        startup: function(){
            this.inherited(arguments);

            // キャンセルボタンを表示
            domStyle.set(this.cancelButtonArea, 'disable', '');

            // 市町村ユーザーの場合(政令指定都市を除く)概況リンクを不可視
            // if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
            //     UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
            //     domStyle.set(this.overviewLabel, 'display', 'none');
            // }

            var promise;

            this.initRegisterPage().then(lang.hitch(this, function() {
                // 地図の緯度経度を表示
                // 広島県の場合は移動しない。
                if(this._municipalityCd === this.PREF_MUNICIPALITY_CODE){
                    promise = this.initTreeAndLayers();
                }

                this.initLatlng4Page().then(lang.hitch(this, function(){
                    promise = this.initTreeAndLayers();
                    // 発令内容セレクターを変更。
                    this.setEvacOrderTypeSelector(this._issueReasonType, this._evacOrderType);
                }));

            }));
            Loader.wait(promise);
        },

        initTreeAndLayers: function(){
            // 避難情報ツリーを初期表示した後にツリーのストア情報に基づいて地区レイヤーを表示する。
            return this.initTree().then(lang.hitch(this, 'initDistrictLayer'))
            .then(lang.hitch(this, function() {
                // 準備が整ったら選択中の各地区をチェック状態にする
                this._selectDistricts(this._districtCdList);
                // 現況状況レイヤーの初期値をONにする。
                this._latestLayerState = this.LATEST_LAYER_STATE.ON;
                // 避難所一覧グリッドを初期化する。
                //this.initShelterGrid();
            }));
        },

        /**
         * 市町村コードから、対象の市町の緯度経度を取得して、地図を移動させる。
         */
        initLatlng4Page: function() {
            // 広島「県」以外の場合は地図を追加した後で対象の市町の緯度経度を取得して、
            // 地図の中心位置に移動させる。
            // 市町村の緯度経度情報の一覧を取得する。
            return Requester.get('/data/municipality/municipalityList.json', {preventCache: false})
            .then(lang.hitch(this,function(obj) {

                var municipalities = obj.municipalities;
                var latlng = this.INIT_LATLNG;

                // 市町村の緯度経度情報の一覧と画面で指定されている市町村を比較して当該市町村の緯度経度を取得する。
                municipalities.some(lang.hitch(this,function(item) {
                    if (item.municipalityCd===this._municipalityCd) {
                        latlng = item.latlng;
                        return true; //break
                    }
                }));
                this.map.setView(latlng, 12);
            }));
        },

        initRegisterPage: function(){

            return Requester.get('/api/evacorders/detail/' + this._evacOrderId, {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(lang.hitch(this, function(data) {
                //画面に設置
                console.log(data);

                this._municipalityCd = data.municipalityCd;

                // 市町村
                this.municipalitySelector.set('value', data.municipalityCd);
                this.municipalitySelector.set('noAllButton', true);
                this._municipalityCd = data.municipalityCd;

                // 避難区分
                this.evacOrderType.set('value', data.evacOrderType);
                this._evacOrderType = data.evacOrderType;

                // 発令理由
                this.issueReasonType.set('value', data.issueReasonType);
                this.issueReasonType.setDisabled(true);
                this._issueReasonType = data.issueReasonType;

                // 補足情報
                this.note.set('value', data.note);

                // 避難行動指針
                this.evacGuidance.set('value', data.evacGuidance);

                // 報告者
                this.evacOrderAuthorName.set('value', data.evacOrderAuthorName);

                // this._districtCdList = data.districtList;

            }), lang.hitch(this, function(error) {
                this.chain.infoError(error);
            }));

        },

        onCancelButtonClick: function(){
            Router.moveTo('evacorder/detail', {
                evacOrderId : this._evacOrderId
            });
        }


    });
});
