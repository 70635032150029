/**
 * <避難所情報登録画面>
 *
 * @module app/shelter/ShelterRegisterPage.js
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/text!./templates/ShelterRegisterPage.html', // テンプレート文字列
    'dojo/json',
    'dojo/dom-style',
    'idis/view/dialog/InfoDialog',
    'dojo/_base/lang',
    'dojo/promise/all',
    'idis/control/Router',
    'idis/consts/STORAGE_KEY',
    'idis/store/IdisRest',
    'idis/control/Locator',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'app/model/DisasterInfo',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/map/IdisMap',
    'dojo/on',
    'app/config',
    'leaflet',
    'idis/view/dialog/IdisDialog',
    'idis/util/storage/LocalStorage',
    'app/map/baselayer/BaseLayerPane',
    'app/shelter/ShelterUtils',
    './_ShelterPageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/TabContainer',
    'dijit/layout/BorderContainer',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Form',
    'dijit/form/Textarea',
    'dijit/form/NumberTextBox',
    'dijit/form/ValidationTextBox',
    'dijit/form/CheckBox',
    'dijit/layout/AccordionContainer',
    'dojox/form/Uploader',
    'app/view/form/OrganizationSelector',
    'dijit/TitlePane',
    'idis/view/form/TimeInput',
    'idis/view/form/DateTimeInput',
    'idis/consts/ACL',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/WordCountTextarea',
    'app/sample/view/SampleGrid',
    'app/view/form/ShelterSelector',
    'app/shelter/ShelterTypeMultiSelector',
    'app/provide/ProvideShelterRegisterDialog'
], function(module, declare, template, json, domStyle, InfoDialog, lang, all, Router, STORAGE_KEY,
        IdisRest, Locator, Loader, DialogChain, DisasterInfo, UserInfo, Requester, IdisMap, on,
        config, leaflet, IdisDialog, LocalStorage, BaseLayerPane, ShelterUtils, _ShelterPageBase) {
    /**
     * 避難所新規登録画面
     *
     * @class ShelterRegisterPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ShelterPageBase,
    /** @lends module:app/shelter/ShelterRegisterPage~ShelterRegisterPage# */ {

        // テンプレート文字列
        templateString : template,

        // ルート要素に付与されるCSS
        baseClass : 'idis-Page idis-Page--shelter',

        /**
         * データ格納用オブジェクト
         *
         * @type {module:dstore/Store}
         */
        store : null,

        /**
         * 災害ID
         */
        _disasterId: null,

        /**
         * 市町村コード
         *   - 初期化時：ユーザの所属市町村コード
         *   - 避難所選択後：避難所の所在市町村コード
         */
        _municipalityCd: null,

        /**
         * formデータ
         */
        _sendData: null,

        /**
         * 背景地図ダイアログに対する参照
         * @type {module:idis/view/dialog/IdisDialog~IdisDialog}
         * @private
         */
        _baseLayerDialog: null,

        /**
         * 施設ID
         */
        _facilityId : null,

        /**
         * 前報保持状況
         * TODO: 不要であれば消す
         */
        _latestStatus: null,

        /**
         * 混雑状況
         */
        _spaceStatus: null,

        /**
         * 緯度経度
         */
         _latitude: null,
         _longitude: null,

         /**
         * メッセージ表示用ダイアログ
         */
        infoDialog : null,

        /**
         * 自動承認モードフラグ
         * true: ON（自区）
         * false: OFF / ON（他区）
         */
        _autoConfirmFlg: null,

        constructor : function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty : 'shelterId',
                target : '/api/shelters'
            });
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
            this._municipalityCd = UserInfo.getMunicipalityCd();

            //トップページから遷移したときの選択施設情報を取得
            this._facilityId = Locator.getQuery().facilityId;

            console.debug('現在のログインユーザID：' + UserInfo.getId());
            console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());

            this._scenarioId = Locator.getQuery().scenarioId;
            this._simulateFlg = Locator.getQuery().simulateFlg;
            this._eventType = Locator.getQuery().eventType;
            this._content = Locator.getQuery().content;
            this._timing = Locator.getQuery().timing;
        },


        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
        },

        // HTML上にウィジェットが設置されてから呼ばれる
        startup: function() {
            console.debug('ShelterRegisterPage#startup()');
            this.inherited(arguments);
            //地図を初期化
            this.initMap();
            // 画面を初期化
            this.initPage();
            //トップページ地図からの遷移の場合,遷移元の選択施設情報をセット
            if (this._facilityId !== null && this._facilityId !== undefined &&
                this._facilityId.length === 8){
                this.facilityId.set('value',this._facilityId);
                this.setPageContent(this._facilityId);
            }
            // タブ切替時、マップが正常表示されないため監視して再描画する
            var self = this;
            this.own(this.tabContainer.watch('selectedChildWidget', lang.hitch(this, function(name, oval, nval){
                if (nval.id === 'mapContainerShelterRegister') {
                    self.map.remove();
                    self.initMap(this._latitude, this._longitude);
                    self.mapContainer.resize();
                    // latlngがnullの場合、エラーが出るためチェックしてaddMarkする
                    if (this._latitude && this._longitude){
                        self.addMark(this._latitude, this._longitude);
                    }
                }
            })));
        },

        /**
         * 画面を初期化する。
         */
        initPage: function() {
            console.debug('ShelterRegisterPage#initPage()');
            //初期化
            this.pointLat = '';
            this.pointLng = '';
            this.marker = '';
            this.attachFileList = [];
            // プレビューを初期化
            this.clearPreview();
        },

        // 避難所を選択したときに避難所情報をセット
        onChangeShelter: function(evt){
            this._facilityId = evt.value;
            this.setPageContent();
            if (Locator.getQuery().facilityId){
                Locator.pushState('facilityId', this._facilityId);
            }
        },

        getLatestStatus: function(){
            // TODO: 変数latestStatusを必要とする箇所が無いため、不要であれば関数自体消す
            // 指定した災害・施設の最新避難所状況を取得する。未登録の場合は404。
            return Requester.get('/api/shelters/last-status', {
                query: {
                    disasterId: this._disasterId,
                    facilityId: this._facilityId
                }
            }).otherwise(function(err) {
                if (err && err.response && err.response.status === 404) {
                    // 見つからなかった場合は避難所名だけ取得
                    return null;
                } else {
                    // それ以外のエラーは最後にまとめる
                    console.error(err);
                    throw null;
                }
            });
        },

        setPageContent: function(){
            // 選択した避難所の情報を画面項目にセットする
            // 避難所の施設ID(=ツリーの市町コード以外)を選択している時に処理を行う。
            if (config.municInfo.wardList.indexOf(this._facilityId) === -1  && this._facilityId !== ''){
                all({
                    facilityInfo: Requester.get('/api/facility/' + this._facilityId + '/' + this._disasterId)
                    // TODO: 使用箇所がないためコメントアウト。不要と判明次第消す。
                    // latestStatus: this.getLatestStatus()
                }).then(
                    lang.hitch(this, function(result){
                        var data = result.facilityInfo;
                        // この画面で前報がある＝一回開設したあとに閉鎖した避難所
                        // TODO: 使用箇所がないためコメントアウト。不要と判明次第消す。
                        // this._latestStatus = result.latestStatus;
                        // クリアが選択された場合はundefinedとなるためチェックを実施
                        // 非公開フラグ 0:非公開, 1:公開
                        if (data.publicFlg === '0') { this.privateFlg.set('checked', true); }
                        // 避難所コード
                        this.shelterCd.innerHTML = data.facilityId ? data.facilityId : '-';
                        // 所在地
                        this.address.innerHTML = data.address ? data.address : '-';
                        // 最大収容人数
                        this.capacity.innerHTML = data.capacity ? data.capacity : '-';
                        this._municipalityCd = data.districtDto.municipalityCd;
                        // 避難所区分
                        var _shelterType = '';
                        if (data.designatedEvacShFlg === '1'){
                            _shelterType += '災害時避難所  ';
                        }
                        if (data.welfareEvacShFlg === '1'){
                            _shelterType += '福祉避難所  ';
                        }
                        if (data.temporaryEvacShFlg === '1'){
                            _shelterType += '臨時避難所  ';
                        }
                        if (_shelterType !==''){
                            this.shelterType.innerHTML = _shelterType || '&nbsp;';
                        } else {
                            this.shelterType.innerHTML = '未設定';
                        }
                        // 緯度経度
                        this._latitude = data.latitude;
                        this._longitude = data.longitude;
                        var latlng = [data.latitude, data.longitude];
                        if(data.latitude && data.longitude) {
                            // マップを初期化
                            this.map.setView(latlng, 14);
                            this.addMark(latlng[0], latlng[1]);
                        }
                        // 自動承認モード状況を取得
                        // 選択した避難所の市区コードで自動承認モードが設定されている場合、自動承認対象となる
                        ShelterUtils.isAutoConfirmed(this._municipalityCd).then(lang.hitch(this, function(result){
                            // 自動承認モードフラグをセット
                            this._autoConfirmFlg = result;
                        }));
                        // シュミレーション機能の場合の対応
                        if(this._simulateFlg === 'true'){
                            this._facilityName = data.facilityName;
                        }
                    }));
            } else {
                // クリアの場合、画面項目を初期化する
                // 避難所コード
                this.shelterCd.innerHTML =  '';
                // 所在地
                this.address.innerHTML = '';
                // 最大収容人数
                this.capacity.innerHTML = '';
                // 避難所区分
                this.shelterType.innerHTML = '';
                // 保持項目の初期化
                this._municipalityCd = UserInfo.getMunicipalityCd();
                this._latitude = config.map.latitude;
                this._longitude = config.map.longitude;
                this._autoConfirmFlg = null;
                // マップ初期化
                this.removeMark();
                var latlng = [this._latitude, this._longitude];
                this.map.setView(latlng, 14);
            }
        },

        /**
         * マップを初期化する。
         */
        initMap: function(lat, lng) {
            console.debug('ShelterRegisterPage#initMap()');
            //中心アイコンを非表示にする
            LocalStorage.set(STORAGE_KEY.CENTER_MARK, '');
            // マップの生成
            var latlng = null;
            if(lat && lng) {
                latlng = [lat, lng];
            } else {
                latlng = [config.map.latitude, config.map.longitude];
            }
            var self = this;
            self.map = new IdisMap(self.mapNode, {
                config: config.map,
                keyboard: false, // コメント時に+/-が使用できないため
                touchExtend : false,
                minZoom: 9,
                maxZoom: 18,
                drawControlTooltips:false}
            ).setView(latlng, 14);
            // destroy時にmapを破棄するよう設定
            self.own(self.map);
            self.own(on(self.map, 'click', lang.hitch(self, function(e) {
                self.pointLat = e.latlng.lat;
                self.pointLng = e.latlng.lng;
            })));
            //初期化
            self.pointLat = '';
            self.pointLng = '';
            self.marker = '';
        },

        /**
         * マーカーを追加する。
         */
        addMark: function(lat, lng) {
            this.removeMark();
            this.marker = leaflet.marker([lat, lng]).addTo(this.map);
        },

        /**
         * マーカーを削除する。
         */
        removeMark: function() {
            if(this.marker){
                this.map.removeLayer(this.marker);
            }
        },

        // 登録項目の値をセットする
        setRegisterValues: function() {
            // 本部確認状況
            if (ShelterUtils.isHeadQuarterUser(UserInfo, this._municipalityCd)) {
                // 0: 本部未確認, 1: 完了
                // 全体管理者・市本部ユーザ・区本部ユーザの場合、本部確認完了で登録する
                this._sendData.confirmFlg = '1';
            } else if (this._autoConfirmFlg) {
                // 自動承認モードが選択した避難所の所在区でONの場合、本部確認完了で登録する
                this._sendData.confirmFlg = '1';
            } else {
                this._sendData.confirmFlg = '0';
            }
            // 混雑状況
            this._sendData.spaceStatus = this._spaceStatus;
            // チェックボックスの値をセット チェック有: ['on'], チェックなし: []
            // 非公開フラグ 0:非公開, 1:公開
            this._sendData.privateFlg.length > 0 ? this._sendData.privateFlg = '0' : this._sendData.privateFlg = '1';
            // ライフライン（基本）
            this._sendData.elecDmgFlg.length > 0 ? this._sendData.elecDmgFlg = '1' : this._sendData.elecDmgFlg = '0';
            this._sendData.gasDmgFlg.length > 0 ? this._sendData.gasDmgFlg = '1' : this._sendData.gasDmgFlg = '0';
            this._sendData.waterDmgFlg.length > 0 ? this._sendData.waterDmgFlg = '1' : this._sendData.waterDmgFlg = '0';
            this._sendData.sewageDmgFlg.length > 0 ? this._sendData.sewageDmgFlg = '1' : this._sendData.sewageDmgFlg = '0';
            this._sendData.phoneDmgFlg.length > 0 ? this._sendData.phoneDmgFlg = '1' : this._sendData.phoneDmgFlg = '0';
            this._sendData.docomoDmgFlg.length > 0 ? this._sendData.docomoDmgFlg = '1' : this._sendData.docomoDmgFlg = '0';
            this._sendData.softbankDmgFlg.length > 0 ? this._sendData.softbankDmgFlg = '1' : this._sendData.softbankDmgFlg = '0';
            this._sendData.auDmgFlg.length > 0 ? this._sendData.auDmgFlg = '1' : this._sendData.auDmgFlg = '0';
            this._sendData.rakutenDmgFlg.length > 0 ? this._sendData.rakutenDmgFlg = '1' : this._sendData.rakutenDmgFlg = '0';
            // 詳細情報タブ
            this._sendData.noShoesShFlg.length > 0 ? this._sendData.noShoesShFlg = '1' : this._sendData.noShoesShFlg = '0';
            this._sendData.noSmokingShFlg.length > 0 ? this._sendData.noSmokingShFlg = '1' : this._sendData.noSmokingShFlg = '0';
            // 閉鎖以外の場合、閉鎖時間を削除する
            // 一度閉鎖を選んで開設状況を変えると画面上で閉鎖時刻が見えなくなるため対策
            if(this._sendData.status !== '9'){
                delete this._sendData.shelterEndTime;
            }
        },

        // 新規登録ダイアログを表示する
        onSubmit : function() {
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
            this._sendData = this.form.get('value');
            this.setRegisterValues();
            // 混雑状況の入力確認
            if (!this._spaceStatus) {
                console.debug('混雑状況が選択されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        titel: 'エラー',
                        content: '混雑状況が選択されていません'
                    });
                    this.infoDialog.show();
                    this.infoDialog = null;
                }
            }// 避難所が未選択または、代わりに市町コードを選択した場合はエラー
            else if(!this.facilityId.value || config.municInfo.wardList.indexOf(this.facilityId.value) !== -1) {
                console.debug('避難所が選択されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '避難所が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
            }//必須入力項目の「調査日」の確認
            else if(!this.reportTime.validate() || !this.reportTime._date.displayedValue ||
                !this.reportTime._time.displayedValue) {
                console.debug('調査日が選択されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '調査日が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
            }//必須入力項目の「開設日時」の確認 （開設準備中でない場合のみ）
            else if( this._sendData.status !== '0' &&
                ( !this.shelterStartTime.validate() || !this.shelterStartTime._date.displayedValue ||
                !this.shelterStartTime._time.displayedValue)) {
                console.debug('開設日時が選択されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '開設日時が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
            //閉鎖ステータスで閉鎖日時が入力されていない場合エラー
            } else if (this._sendData.status === '9' && (
                !this.shelterEndTime.validate() || !this.shelterEndTime._date.displayedValue ||
                !this.shelterEndTime._time.displayedValue)){
                console.debug('閉鎖日時が入力されていません');
                if (!this.infoDialog) {

                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : '閉鎖日時が入力されていません'
                });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            //閉鎖日時が入力されている場合、その入力値が不正ならエラー
            } else if((this.shelterEndTime._date.displayedValue || this.shelterEndTime._time.displayedValue) &&
            !this.shelterEndTime.validate()) {
                console.debug('閉鎖日時の入力値が不正です');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '閉鎖日時の入力値が不正です'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
            }
            //閉鎖時刻が開設日時より手前に設定されている場合、エラー
            else if(this.shelterEndTime._date.displayedValue && this.shelterEndTime._time.displayedValue &&
                //閉鎖日が開設日より前の場合
                (this.shelterEndTime._date.displayedValue < this.shelterStartTime._date.displayedValue ||
                //または閉鎖日と開設日が同日かつ、時間が前の場合
                (this.shelterEndTime._date.displayedValue === this.shelterStartTime._date.displayedValue &&
                this.shelterEndTime._time.displayedValue < this.shelterStartTime._time.displayedValue))){
                    console.debug('閉鎖日時が開設日時の前に設定されています');
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : '閉鎖日時が開設日時の前に設定されています'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog = null;
            }
            else if(!this.form.isValid()) {
                console.debug('無効な値が入力されています');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '無効な値が入力されています'
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
            }else{
                //添付ファイルIDをセット
                if(this.attachFileList.length > 0) {
                    var attachFileIds = [];
                    for(var i=0; i<this.attachFileList.length; i++) {
                        attachFileIds.push(this.attachFileList[i].shelterAtcFileId);
                    }
                    this._sendData.attachFileIds = attachFileIds.join(',');
                } else {
                    this._sendData.attachFileIds = '';
                }
                delete this._sendData.shelterAtcFile;

                this._sendData.disasterId = this._disasterId;
                if(this._simulateFlg === 'true'){
                    // シミュレーション機能の場合、外部連携はしない。
                    this.registerShelter();
                } else if(this._sendData.confirmFlg !== '1' || this._sendData.privateFlg === '0') {
                    // 本部確認状況が未確認または、非公開フラグが有効な場合、配信ダイアログ表示はしない
                    this.sendAndRegister(null);
                } else {
                    this.showShelterDialog(this._sendData);
                }
            }
        },

        sendAndRegister: function(shelterInfo){
            var message = '';
            var message = shelterInfo ? '避難所の登録及び大阪府への配信を行います。よろしいですか？'
                                      : '避難所の登録を行います。よろしいですか？';
            this.chain.confirm(message, lang.hitch(this, function(chain) {
                if(shelterInfo){
                    this._sendData.sendPostForm = shelterInfo.lalertForm;
                }
                var jsonStr = json.stringify(this._sendData);
                var promise =  Requester.post('/api/shelters', {
                    data: jsonStr
                }).then(lang.hitch(this, function(result) {
                    console.debug(result);
                    var compMessage = shelterInfo ? '登録・配信が完了しました。' : '登録が完了しました。';
                    chain.info(compMessage, '完了', lang.hitch(this, function(){
                        this.shelterRegisterDialog.getChildren()[0].closeDialog();
                        Router.moveTo('shelter', {
                            municipalityCd: this._municipalityCd
                        });
                    }));
                }), function(error) {
                    // 失敗時
                    console.error(error);
                    chain.info('登録に失敗しました。', 'エラー');
                });
                Loader.wait(promise);
            }), lang.hitch(this, function(chain){ // キャンセル押下時
                chain.hide();
                if(shelterInfo){
                    this.shelterRegisterDialog.getChildren()[0].closeDialog();
                }
            }));
        },


        /**
         * 配信情報ダイアログを表示する。
         */
        showShelterDialog: function(obj) {
            Requester.get('/api/facility/' + obj.facilityId + '/' + this._disasterId
            ).then(lang.hitch(this,function(item) {
                // Lアラート項目を整形
                console.log(obj.shelterStartTime);
                var sort = '開設';
                var shelterStartOrEndTime = obj.shelterStartTime;
                if (obj.shelterEndTime  && obj.shelterEndTime !== null) {
                    sort = '閉鎖';
                    shelterStartOrEndTime = obj.shelterEndTime;
                }
                // 日付の形式を、Lアラート用にフォーマットする
                shelterStartOrEndTime = this.formatDateFromObj(shelterStartOrEndTime);
                // string型の数値情報を数字に変える
                var evaqueeNum4Lalert = isNaN(obj.evaqueeNum) ? 0 : Number(obj.evaqueeNum);
                var evacHouseholdNum4Lalert = isNaN(obj.evacHouseholdNum) ? 0 : Number(obj.evacHouseholdNum);
                // 外部公開情報をセットする（0: 公開, 1: 非公開)
                var publish4Lalert = obj.privateFlg === '1' ? '0' : '1';
                // 避難所種別・避難所種別で表現しきれない情報をセットする
                var evacShelterType = '避難所';
                var evacShelterTypeDetail = '';
                if (item.welfareEvacShFlg === '1') {
                    // 福祉避難所の場合、避難所種別で表現しきれない情報を「福祉避難所」にする
                    evacShelterTypeDetail = '福祉避難所';
                }
                if (item.temporaryEvacShFlg === '1') {
                    // 臨時避難所の場合、避難所種別を「臨時避難所」にする
                    // フラグが複数の場合も、臨時避難所が選択されていれば臨時避難所とみなす
                    evacShelterType = '臨時避難所';
                }
                // 指定区分をセットする
                var evacSuitableShelter = '指定なし';
                if(item.designatedEvacShFlg === '1') {
                    // 災害時避難所の場合、指定区分を「指定避難所」にする
                    evacSuitableShelter = '指定避難所';
                }

                var evaqueeData = [{
                    facilityId: obj.facilityId,
                    evacShelterName: item.facilityName,
                    evacShelterNameKana: item.facilityNameKana,
                    evacShelterType: evacShelterType,
                    evacShelterTypeDetail: evacShelterTypeDetail,
                    evacSuitableShelter: evacSuitableShelter,
                    evacShelterSort: sort,
                    evacShelterLatitude: item.latitude,
                    evacShelterLongitude: item.longitude,
                    address: item.address,
                    evaqueeNum: evaqueeNum4Lalert,
                    evacHouseholdNum: evacHouseholdNum4Lalert,
                    evacShelterDatetime:shelterStartOrEndTime,
                    evacTopical:obj.comment,
                    evacShelterStatus: obj.spaceStatus,
                    publish: publish4Lalert,
                    capacity: item.capacity,
                    contactInfo: item.phoneNum,
                    district: item.districtDto.districtName
                }];
                var args = {};
                args.shelterArgs = {
                    sendCategory : '02',
                    subject: ' ',
                    evacTimestamp: new Date(),
                    evaqueeData: evaqueeData,
                    // 更新種別 '01':新規, '02':更新・訂正, '03':取消
                    distributionType: '01'
                };
                args.evacOrderArgs = null;
                args.facilityId = obj.facilityId;

                // ダイアログの最初の子要素が登録画面
                var dialog = this.shelterRegisterDialog.getChildren()[0];
                // ダイアログの画面を初期化する。
                dialog.initDialog(args, false, false, false);
                // ダイアログのsendイベントを受け取れるようにする
                dialog.on('send', lang.hitch(this, function(evt) {
                    return this.sendAndRegister(evt.value);
                }));
                // ダイアログを破棄できないように設定する。
                this.shelterRegisterDialog.set('closable', false);
                // ダイアログを表示する。
                this.shelterRegisterDialog.show();
            }));
        },

        /**
         * 配信情報ダイアログの表示を挟まずに登録する。
         */
        registerShelter: function(){
            // 当関数を使用するのはシミュレーション機能の場合のみ
            // 配信を行わない他の登録処理では、sendAndRegister()を使用する
            var message = '登録します。よろしいですか？';
            var jsonStr = null;
            // シュミレーション用の項目整形
            switch (this.form.value.status) {
                case '1':
                    this._statusName = '開設';
                    break;
                case '9':
                    this._statusName = '閉鎖';
                    break;
                default:
            }
            var subSendData = {};
            subSendData.statusName = this._statusName;
            subSendData.facilityName = this._facilityName;
            // シュミレーション機能の場合
            if(this._simulateFlg === 'true'){
                jsonStr = json.stringify({
                    shelterPostForm: this._sendData,
                    shelterConversionForm: subSendData,
                    scenarioId: this._scenarioId,
                    eventType: this._eventType,
                    content: this._content,
                    timing: this._timing});
                message = 'イベント登録します。';
                this.chain.confirm(message, function(chain) {
                    var promise = Requester.post('/api/simulations/event/', {
                        data: jsonStr,
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(function() {
                        console.debug('success register shelter event');
                        chain.infoComplete(function() {
                            chain.hide();
                            Router.moveTo('simulation/event', {scenarioId: this._scenarioId});
                        });
                    }, function(error) {
                        console.log('error register shelter event');
                        chain.infoError(error);
                    });
                    //ローダーの表示
                    Loader.wait(promise);
                });
            } else {
                this.chain.confirm(message, lang.hitch(this, function(chain)  {
                    delete this._sendData.version;
                    delete this._sendData.layerName;
                    delete this._sendData.layerUrl;
                    this._sendData.sendPostForm = null;
                    // 登録
                    jsonStr = json.stringify(this._sendData);
                    var promise = Requester.post('/api/shelters',{
                        data: jsonStr,
                        headers: {'Content-Type': 'application/json; charset=utf-8'},
                        handleAs: 'json',
                        preventCache : true
                    }).then(lang.hitch(this, function(data){
                        console.debug('避難所の登録に成功しました。data:' + data);
                        this._shelterId = data.shelterId;
                        chain.info('登録が完了しました。', '完了', lang.hitch(this, function(){
                            chain.hide();
                            Router.moveTo('shelter', {
                                municipalityCd: this._municipalityCd
                            });
                        }));
                    }), function(error){
                        // 失敗時
                        console.error(error);
                        chain.infoError(error);
                    });
                    Loader.wait(promise);
                }));
            }
        },

        // パンくずリストのボタンを押下したときの挙動
        onShelterAdminPageLinkClick : function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('shelter/admin');
        },

        onShelterListPageLinkClick : function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('shelter', {
                municipalityCd : this._municipalityCd
            });
        },

        // キャンセルボタンを押下したら前の画面に戻る
        cancelButtonClick : function() {
            console.debug('[キャンセル]ボタンがクリックされました。');
            this.leave();
        },

        // 混雑状況をクリックした時の挙動
        onStatusButtonClick: function(evt) {
            var target = evt.target;
            // 比較のため、前の選択値を保持
            var lastStatus = this._spaceStatus;
            // 押下したボタンに応じて、値を保持し色を変える
            this._spaceStatus = target.value;
            this.setSpaceStatusButtonColor(this._spaceStatus);
            // 登録確認ダイアログを表示する（登録権限のあるユーザのみ）
            if (UserInfo.hasWriteAuthz('F05006')) {
                var message = '';
                if (lastStatus !== this._spaceStatus) {
                    message = '混雑状況が変更されました。このまま登録を行いますか？';
                } else {
                    message = 'このまま更新を行いますか？';
                }
                this.chain.confirm(message, lang.hitch(this, function() {
                    this.chain.hide();
                    this.onSubmit();
                }));
            }
        },

        // 混雑状況のボタン色をセットする
        setSpaceStatusButtonColor: function(value) {
            // '0': 空き, '1': 混雑, '2': 満員
            if (value === '0') {
                this.spaceStatusQuiet.set('class', 'wide-button accent');
                this.spaceStatusBusy.set('class', 'wide-button');
                this.spaceStatusFull.set('class', 'wide-button');
            } else if (value === '1') {
                this.spaceStatusQuiet.set('class', 'wide-button');
                this.spaceStatusBusy.set('class', 'wide-button accent');
                this.spaceStatusFull.set('class', 'wide-button');
            } else {
                this.spaceStatusQuiet.set('class', 'wide-button');
                this.spaceStatusBusy.set('class', 'wide-button');
                this.spaceStatusFull.set('class', 'wide-button accent');
            }
        },

        // 地図タブをクリックした時の挙動
        onMapTabClick: function() {
            console.log('clicked on MapTab');
            // タブ切替時、マップが正常表示されないためリサイズする
            this.map.remove();
            this.initMap();
            this.container.resize();
        },

        /**
         * 背景地図ダイアログを表示する。
         */
         showBaseLayerDialog: function() {
            if (!this._baseLayerDialog) {
                // 初回呼び出し時にインスタンス生成
                this._baseLayerDialog = new IdisDialog({
                    noUnderlay: true,
                    title: '背景地図',
                    content: new BaseLayerPane({map: this.map})
                });
                // 画面が破棄された際に連れて行く
                this.own(this._baseLayerDialog);
            }
            this._baseLayerDialog.show();
        }
    });
});
