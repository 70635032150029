/**
 * 消防庁被害情報 ファイル読み込み 人的被害ダイアログ用モジュール。
 * @module app/damage/damageFileUpload/DamageReportHumanDialog
 */
define([
    'module',
    'app/model/DisasterInfo',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/text!./templates/DamageReportHumanDialog.html',
    'dojo/request/iframe',
    'dojo/promise/all',
    'dstore/Memory',
    'idis/service/GeoService',
    'idis/service/Requester',
    'idis/model/UserInfo',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'app/config',
    // 以下、変数で受けないモジュール
    'dojox/form/Uploader',
    'dijit/form/Form',
    'idis/view/form/Button',
    'app/damage/damageFireFileUpload/DamageReportHumanGrid',
], function (module, DisasterInfo, array, declare, lang, Deferred, template, iframe, all, Memory, GeoService, Requester,
    UserInfo, DialogChain, InfoDialog, _PageBase, Loader, config) {
    // GeoServiceを初期化
    var _geoService = null;
    /**
     * 人的被害ダイアログ
     * @class DamageReportHumanDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
                   /** @lends module:app/view/page/DamageReportHumanDialog~DamageReportHumanDialog# */ {
            // テンプレート文字列
            templateString: template,

            baseClass: 'idis-Page--damage-dialog',

            _fileId: null,

            infoDialog: null,

            _disasterId: null,
            // データ格納用オブジェクト
            store: null,

            constructor: function () {
                // 連鎖ダイアログを登録
                this.chain = DialogChain.get(this);
                // 災害IDを取得
                this._disasterId = DisasterInfo.getDisasterId();
                if (!this._disasterId) {
                    this._disasterId = 1;
                    console.warn('災害IDが設定されていません。');
                }
            },

            buildRendering: function () {
                this.inherited(arguments);
            },

            /**
             * グリッド初期化
             * showDialogの前に、呼び出しもとでinitGridを呼び出す
             * @param dataList Gridに表示するデータ一覧
             */
            initGrid: function (dataList) {
                // データ格納用オブジェクト
                this.store = new Memory({
                    idProperty: 'gridRowId',
                });
                if (Array.isArray(dataList)) {
                    for (let i = 0; i < dataList.length; i++) {
                        let item = dataList[i];
                        item.gridRowId = i;
                        this.store.addSync(item);
                    }
                }
                var collection = this.store;
                this._dataList = dataList;

                this.grid.set('collection', collection);
                this.grid.contentNode.parentNode.style.marginTop = '43px';
                this.grid.contentNode.parentNode.style.marginBottom = '25px';

            },

            /**
             * 登録実行
             */
            onSubmit: function () {
                console.log(this.store.data);
                var itemList = this.store.data;
                var urgencyTypeList = array.map(array.filter(this.grid.urgencyTypeSelect, function (item) {
                    return !!item; // not undifined
                }), function (item) {
                    return { no: item.name.replace('urgencyType_', ''), val: item.value };
                });
                console.log(urgencyTypeList);

                array.map(itemList, function (item) {
                    console.log(item);
                    if (!item.damageAddress) {
                        item.damageAddress = "大阪府大阪市" + item.municipalityName;
                    }
                    item['damageLatitude'] = -1;
                    item['damageLongitude'] = -1;
                    item['urgencyType'] = 0;
                    return item;
                });
                for (var i = 0; i < urgencyTypeList.length; i++) {
                    for (var j = 0; j < itemList.length; j++) {
                        if (itemList[j].no !== urgencyTypeList[i].no) {
                            continue;
                        }
                        // Gridと深刻度の設定結果をマッピングする
                        itemList[j].urgencyType = urgencyTypeList[i].val;
                    }
                }
                // 住所から緯度経度を取得
                var dfd = new Deferred();

                Loader.wait(this.resolveDamageAddress(dfd, itemList, 0).then(lang.hitch(this, function (itemList) {
                    console.log(itemList);
                    var value = this.form.get('value');
                    value.items = itemList;

                    var sendItem = this.prepareBundlePostData(value);

                    return Requester.post('/api/damageReports/report/registerHumanDamage', {
                        data: sendItem
                    }).then(lang.hitch(this, function (data) {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 完了ダイアログを表示
                        if (data.length === 0) {
                            this.chain.infoComplete(function () {
                                // 登録ダイアログを閉じる
                                this.chain.hide();
                                // 画面を更新
                                location.reload(false);
                                this.emit('upload');
                            });
                        } else {
                            var message = '';
                            message += 'ファイル読み込みを完了しました。<br>';
                            message += 'ただし下記は、更新しませんでした。<br><br>';
                            message += '<table style="margin-left: auto;margin-right: auto">';
                            message += '<tr><th style="text-align:center"><b>被害</b></th><tr>';
                            array.forEach(data, function (data) {
                                message += '<tr><td>' + data;
                                message += '</td></tr>';
                            });
                            message += '</table>';

                            var title = '完了';
                            // ダイアログにメッセージを表示
                            this.chain.info(message, title, function () {
                                // 登録ダイアログを閉じる
                                this.chain.hide();

                                // 画面を更新
                                location.reload(false);
                                this.emit('upload');
                            });
                        }
                    }), function (err) {
                        // 失敗時
                        this.chain.infoError(err);
                    });
                })));
            },

            resolveDamageAddress(dfd, itemlist, idx) {
                console.log(idx + "/" + itemlist.length);
                if (idx === itemlist.length) {
                    return dfd.resolve(itemlist);
                }
                var item = itemlist[idx];
                var idx = idx + 1;
                if (item.damageAddress.indexOf('大阪市') === -1) {
                    item.damageAddress = '大阪市' + item.damageAddress;
                }
                if (item.damageAddress.indexOf('大阪府') === -1) {
                    item.damageAddress = '大阪府' + item.damageAddress;
                }
                console.log(item.damageAddress);
                if (_geoService === null) {
                    _geoService = new GeoService({ url: config.geocode && config.geocode.url });
                }
                _geoService.geocode(item.damageAddress)
                    .then(lang.hitch(this, function (results) {
                        if (results.length > 0) {
                            console.log(results[0]);
                            item.damageLatitude = results[0].latlng.lat;
                            item.damageLongitude = results[0].latlng.lng;
                            this.resolveDamageAddress(dfd, itemlist, idx);
                        } else { // 住所が存在しない場合
                            item.damageAddress = "大阪府大阪市" + item.municipalityName;
                            _geoService.geocode(item.damageAddress)
                                .then(lang.hitch(this, function (results) {
                                    if (results.length > 0) {
                                        console.log(results[0]);
                                        item.damageLatitude = results[0].latlng.lat;
                                        item.damageLongitude = results[0].latlng.lng;
                                        this.resolveDamageAddress(dfd, itemlist, idx);
                                    } else {
                                        // 住所が存在しない場合 

                                        this.resolveDamageAddress(itemlist, idx);
                                    }
                                }), function (err) {
                                    // 失敗時
                                    this.chain.infoError(err);
                                });
                        }
                    }));
                return dfd.promise;
            },

            prepareBundlePostData: function (sendItem) {
                sendItem.disid = this._disasterId;
                sendItem.items = this._dataList;
                return sendItem;
            }
        });
});
