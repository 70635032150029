/**
 * クロノロジ関連のUtility
 * @module app/chronology/ChronologyUtil
 */
define([
    'module'
], function(module) {

    var CHRONOLOGY_SETTING_MAP = {
        demand:  {code: '30',
                    municipalityAuthorizeFlg: '1',
                    organizationAuthorizeFlg: '1',
                    isShareNameOrganization: false,
                    isMunicipalityUnit: false},
        internal: {code: '31',
                    municipalityAuthorizeFlg: '1',
                    organizationAuthorizeFlg: '1',
                    isShareNameOrganization: false,
                    isMunicipalityUnit: false},
        prefecture: {code: '34',
                    municipalityAuthorizeFlg: '1',
                    organizationAuthorizeFlg: '0',
                    isShareNameOrganization: true,
                    isMunicipalityUnit: false}
    };

    function _getSettingFromCode(chronologyType){
        console.log(module);
        var setting = null;
        Object.keys(CHRONOLOGY_SETTING_MAP).forEach(function(key){
            if(CHRONOLOGY_SETTING_MAP[key].code === chronologyType){
                setting = CHRONOLOGY_SETTING_MAP[key];
            }
        });

        return setting;
    }

    return {
        getSettingFromCode: _getSettingFromCode
    };
});
