/**
 * <避難所状況一覧画面>
 *
 * @module app/shelter/ShelterListPage.js
 */
define(['module', // モジュールのパスを返す
    'dojo',
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/text!./templates/ShelterListPage.html', // テンプレート文字列
    'dojo/json',
    'dojo/Deferred',
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/store/IdisRest',
    'idis/service/Requester',
    'dojo/dom',
    'dojo/dom-style',
    'dojo/request/iframe',
    'dijit/registry',
    'idis/view/grid/IdisGrid',
    'dstore/Memory',
    'idis/consts/ACL',
    'idis/view/form/AclButton',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/control/Locator',
    'idis/view/Loader',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'app/config',
    'app/model/DisasterInfo',
    'app/shelter/ShelterUtils',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Form',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'app/shelter/ShelterFormDialog',
    'app/shelter/DistrictShelterSelector',
//    '../view/form/DistrictSelector',
    '../shelter/ShelterListGrid',
    '../shelter/ShelterFileUploadDialog',
    'app/provide/ProvideShelterRegisterDialog'
], function(module, dojo, declare, lang, array, template, json, Deferred, _PageBase, IdisRest,
        Requester, dom, domStyle,
        iframe, registry, IdisGrid, Memory, ACL, AclButton, Router, UserInfo,
        Locator, Loader, InfoDialog, DialogChain, config, DisasterInfo, ShelterUtils) {
    /**
     * 検索条件格納用オブジェクト
     * @type {Object}
     */
      var filterStore = {};
    /**
     * 避難所状況一覧画面
     *
     * @class ShelterListPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/shelter/ShelterListPage~ShelterListPage# */
    {
        // テンプレート文字列
        templateString: template,
        shelterGrid: null,
        store: null,

        /**
         * 災害ID
         */
        _disasterId: null,

        /**
         * 画面に表示されている最新の市区コード
         * - 概況画面から遷移した場合、初期は選択した市区コード
         * - 一覧画面が初期画面の場合、初期はユーザの市区コード
         */
        _municipalityCd: null,

        /**
         * 自動承認モードフラグ
         * true: ON（自区）
         * false: OFF / ON（他区）
         */
        _autoConfirmFlg: null,

        /**
         * 閲覧モードフラグ
         * '0' (通常モード): false, '1' (閲覧モード): true
         */
        _viewMode: null,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--shelter',

        // イベント格納
        _events: [],

         constructor: function() {
            this.store = new IdisRest({
                target: '/api/shelters'
             });
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'shelterId',
                target: '/api/shelters',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            // 検索条件ストアを初期化
            filterStore = {};
            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
            this.chain = DialogChain.get(this);
            // 閲覧モード状況を取得
            this._viewMode = Locator.getQuery().viewMode === '1' ? true : false;
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            if(Locator.getQuery().municipalityCd){
                // 概況画面で選択された市町村がある場合
                this._municipalityCd = Locator.getQuery().municipalityCd;
            }else{
                // クエリパラメータ自体がない場合は、管理対象市町をセット
                this._municipalityCd = UserInfo.getMunicipalityCd();
            }
            this.initDistrictSelector();
            this.own(this.formDialog);
            this.own(this.fileUploadDialog);
            this.initFileUploadDialog();
        },

        startup: function() {
            this.inherited(arguments);
            this.initShelterGrid();
            // 自動承認モード状況を取得
            ShelterUtils.isAutoConfirmed().then(lang.hitch(this, function(result){
                // 自動承認モードフラグをセット
                this._autoConfirmFlg = result;
                // 照会結果を取得してからボタンをセット
                this.setButtonStatus();
            }));
            this.borderContainer.resize();
        },

        /**
         * 検索条件を保管する
         */
        setFilterStore: function() {
          //初期化する
          filterStore = {};
          var value = this.form.get('value');
          // 市町・地区
          if (value.districtCd) {
              filterStore.districtCd = value.districtCd;
          }
          // 開設日時From
          if (value.shelterStartTimeFrom) {
              filterStore.shelterStartTimeFrom = value.shelterStartTimeFrom;
          }
          // 開設日時To
          if (value.shelterStartTimeTo) {
              filterStore.shelterStartTimeTo = value.shelterStartTimeTo;
          }
          // 閉鎖日時From
          if (value.shelterEndTimeFrom) {
              filterStore.shelterEndTimeFrom = value.shelterEndTimeFrom;
          }
          // 閉鎖日時To
          if (value.shelterEndTimeTo) {
              filterStore.shelterEndTimeTo = value.shelterEndTimeTo;
          }
          // 過去報表示
          if (value.activeFlg) {
              filterStore.activeFlg = value.activeFlg;
          }
          // 避難所区分
          if (value.shelterCategory) {
              filterStore.shelterCategory = value.shelterCategory;
          }
        },

        /**
         * 保管した検索条件をフォームにセットする
         */
        setFilterData: function() {
          //保管されていれば値をセット
            // 市・区
            if(filterStore.districtCd) {
              this.districtCd.set('value', filterStore.districtCd);
            }
            // 開設日時From
            if(filterStore.shelterStartTimeFrom) {
              this.shelterStartTimeFrom.set('value', filterStore.shelterStartTimeFrom);
            }
            // 開設日時To
            if(filterStore.shelterStartTimeTo) {
              this.shelterStartTimeTo.set('value', filterStore.shelterStartTimeTo);
            }
            // 閉鎖日時From
            if(filterStore.shelterEndTimeFrom) {
              this.shelterEndTimeFrom.set('value', filterStore.shelterEndTimeFrom);
            }
            // 閉鎖日時To
            if(filterStore.shelterEndTimeTo) {
              this.shelterEndTimeTo.set('value', filterStore.shelterEndTimeTo);
            }
            // 過去報表示
            if(filterStore.activeFlg) {
              this.activeFlg.set('value', filterStore.activeFlg);
            }
            // 避難所区分
            if(filterStore.shelterCategory) {
              this.shelterCategory.set('value', filterStore.shelterCategory);
            }
        },

        /**
         * ファイルアプロードダイアログ初期化処理
         */
        initFileUploadDialog: function() {
            // 操作権限がない（参照権限しかない）場合にはダイアログを初期化しない
            // var options = [];
            // 避難所状況一覧は全ユーザに追加する。
            // options.push({
            //     value: '04',
            //     label: '避難所状況一覧'
            // });

            // 詳細ダイアログの最初の子要素が詳細画面
            var dialog = this.fileUploadDialog;
            var page = dialog.getChildren()[0];
            // page.initOptions(options);
            // 登録ボタンが押された際の動作
            page.on('upload', lang.hitch(this, function() {
                dialog.hide();
                this.updateGridQuery(this.form.get('value'));
            }));
        },

        //ユーザー権限に応じてボタンの表示を制御
        setButtonStatus: function(){
            // 自動承認モードの設定権限がない場合、「自動承認モード」ボタンを非表示にする。
            if (!ShelterUtils.hasAutoConfirmAuthz(UserInfo)) {
                domStyle.set(this.AutoConfirmButton.domNode, 'display', 'none');
            }
            // 避難所管理の登録権限を持たない場合、「新規開設」「ファイル読み込み」「一括操作」ボタンを非表示にする。
            if (!UserInfo.hasWriteAuthz('F05006') || ShelterUtils.isCityNormalUser(UserInfo)){
                domStyle.set(this.RegisterButton.domNode, 'display', 'none');
                domStyle.set(this.BulkOperatingButton.domNode, 'display', 'none');
                domStyle.set(this.FileUploadButton.domNode, 'display', 'none');
            }
            // 施設管理の登録権限を持たない場合、施設追加ボタンを非表示にする。
            if (!UserInfo.hasWriteAuthz('F10001')){
                domStyle.set(this.RegisterFacilityButton.domNode, 'display', 'none');
            }
            // 自動承認モードがON（自区）の場合、ラベルをセットする。
            if (this._autoConfirmFlg) {
                domStyle.set(this.confirmLabel, 'display', '');
                // 自動承認モードの設定権限があるユーザのみ解除ボタンを表示
                if (ShelterUtils.hasAutoConfirmAuthz(UserInfo)) {
                    domStyle.set(this.AutoConfirmButton.domNode, 'display', 'none');
                    domStyle.set(this.AutoConfirmCancelButton.domNode, 'display', '');
                }
            }
            // 閲覧モードの場合、登録系ボタンを非表示にする
            if (this._viewMode) {
                // 非表示対象：「自動承認モード」「新規開設」「ファイル読み込み」「一括操作」「施設追加」
                domStyle.set(this.AutoConfirmButton.domNode, 'display', 'none');
                domStyle.set(this.AutoConfirmCancelButton.domNode, 'display', 'none');
                domStyle.set(this.RegisterButton.domNode, 'display', 'none');
                domStyle.set(this.BulkOperatingButton.domNode, 'display', 'none');
                domStyle.set(this.FileUploadButton.domNode, 'display', 'none');
                domStyle.set(this.RegisterFacilityButton.domNode, 'display', 'none');
            }
        },

        /**
         * 地区選択セレクターを初期化する。
         */
        initDistrictSelector: function() {
            var municipalityCd = this._municipalityCd;
            if (!municipalityCd) {
                return;
            }
            // 市町の地区コードは市町コードの市町部分(3~5桁目)+000
            if (this._municipalityCd !== config.municInfo.cityMunicCd) {
                var districtCd = municipalityCd.substr(2, 3) + '000';
                if (districtCd !== '000000') {
                    this.districtCd.set('value', districtCd);
                }
            }

        },

        initShelterGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.shelterGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.onDetailButtonClick(evt.item);
            }));

            // 概況画面から選択された情報を元に初期表示情報を設定する
            // グリッド用フィルターを作成
            var filter = new this.store.Filter();
            // 災害IDをセット
            filter = filter.eq('disasterId', this._disasterId);
            //市町コードをセット
            if (this._municipalityCd && this._municipalityCd !== config.municInfo.cityMunicCd) {
                filter = filter.eq('municipalityCd', this._municipalityCd);
            }
            // 過去報を非表示
            filter = filter.eq('activeFlg', '1');

            // 保管した検索条件がある場合上書きでセットする
            this.setFilterData();

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.shelterGrid.set('collection', collection);
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function() {
        	var value = this.form.get('value');
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            // 災害ID
            filter = filter.eq('disasterId', this._disasterId);

            if (value.districtCd) {
                filter = filter.eq('districtCd', value.districtCd);
            }
            if (value.facilityName) {
                filter = filter.eq('mfacility.facilityName', value.facilityName);
            }
            if (value.shelterStartTimeFrom) {
                var shelterStartTimeFrom = new Date(value.shelterStartTimeFrom).getTime();
                filter = filter.eq('shelterStartTimeFrom', shelterStartTimeFrom);
            }
            if (value.shelterStartTimeTo) {
                var shelterStartTimeTo = new Date(value.shelterStartTimeTo).getTime();
                filter = filter.eq('shelterStartTimeTo', shelterStartTimeTo);
            }
            if (value.shelterEndTimeFrom) {
                var shelterEndTimeFrom = new Date(value.shelterEndTimeFrom).getTime();
                filter = filter.eq('shelterEndTimeFrom', shelterEndTimeFrom);
            }
            if (value.shelterEndTimeTo) {
                var shelterEndTimeTo = new Date(value.shelterEndTimeTo).getTime();
                filter = filter.eq('shelterEndTimeTo', shelterEndTimeTo);
            }
            // 過去報表示が選択されていない場合、アクティブラグが立っているものだけを検索
            if (!this.activeFlg.get('checked')) {
                filter = filter.eq('activeFlg', '1');
            }

            // 避難所区分
            var shelterCategory = '';
            if(this.designatedEvacShFlg.checked){
                shelterCategory += '02,';
            }
            if(this.welfareEvacShFlg.checked){
                shelterCategory += '03,';
            }
            if(this.temporaryEvacShFlg.checked){
                shelterCategory += '04,';
            }
            filter = filter.eq('shelterCategory', shelterCategory);

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.shelterGrid.set('collection', collection);

            // 検索条件の保管
            this.setFilterStore();
        },

        /**
         * 検索ボタンが押されたときに呼び出される。
         * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
         * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
         */
        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
                this._events.push(this.endTimeEvent);
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },

        /**
         * ページ遷移時に格納していたイベントを削除する。
         */
        _removeEvents: function(){
            this._events.forEach(function(event){
                event.remove();
            });
        },

        // 自動承認モードボタンを押下したときの挙動
        onAutoConfirmButtonClick: function() {
            // 確認ダイアログを表示
            var message = '自動承認モードがONの場合は、避難所状況が更新された場合に'
                        + '自動的に外部に公開されます。'
                        + '<br>自動承認モードに切り替えますか？';
            this.chain.confirm(message, lang.hitch(this, function(chain) {
                // ダイアログを閉じる
                chain.hide();
                // TODO: 登録処理～自動承認登録～ボタン制御までのプロミス連鎖化
                this.showShelterDialog();
                // 自動承認モードの処理
                ShelterUtils.startAutoConfirm(chain);
                // ラベル・ボタンの表示・非表示化
                domStyle.set(this.confirmLabel, 'display', '');
                domStyle.set(this.AutoConfirmButton.domNode, 'display', 'none');
                domStyle.set(this.AutoConfirmCancelButton.domNode, 'display', '');

                // this.showShelterDialog().then(lang.hitch(this, function(){
                //     // 自動承認モードの処理
                //     ShelterUtils.startAutoConfirm(chain).then(lang.hitch(this, function(){
                //         // ラベル・ボタンの表示・非表示化
                //         domStyle.set(this.confirmLabel, 'display', '');
                //         domStyle.set(this.AutoConfirmButton.domNode, 'display', 'none');
                //         domStyle.set(this.AutoConfirmCancelButton.domNode, 'display', '');
                //     }));
                // }));
            }));
        },

        // 自動承認モード解除ボタンを押下したときの挙動
        onAutoConfirmCancelButtonClick: function() {
            // 確認ダイアログを表示
            var message = '自動承認モードを解除しますか？';
            this.chain.confirm(message, lang.hitch(this, function(chain) {
                // ラベル・ボタンの表示・非表示化
                domStyle.set(this.confirmLabel, 'display', 'none');
                domStyle.set(this.AutoConfirmButton.domNode, 'display', '');
                domStyle.set(this.AutoConfirmCancelButton.domNode, 'display', 'none');
                // 自動承認モード解除の処理
                ShelterUtils.endAutoConfirm(chain);
                // ダイアログを閉じる
                chain.hide();
            }));
        },

        /**
         * 配信情報(開設避難所情報)ダイアログを表示する。
         */
         showShelterDialog: function() {
            // var deferred = new Deferred();
            // 該当災害・区内の未承認報を一覧取得する
            var promise = Requester.get('/api/shelters/autoConfirm/start/', {
                query : {
                    disasterId : this._disasterId,
                    municipalityCd : UserInfo.getMunicipalityCd()
                }
            }).then(lang.hitch(this, function(item) {
                var isOnlyPrivate = true;
                var privateShelters = [];
                var publicShelters = [];
                var shelterIds = [];
                if (!item.shelters || item.shelters.length === 0) {
                    // 該当災害・区内に未承認報がない場合は先に進まない
                    return;
                    // return deferred.resolve();
                }
                array.forEach(item.shelters, function(data){
                    // 本部確認用に避難所IDをリスト化して保持する
                    shelterIds.push(data.shelterId);
                    if (data.privateFlg === '1') {
                        // Lアラート配信対象（公開報）
                        isOnlyPrivate = false;
                        publicShelters.push(data);
                    } else {
                        // Lアラート配信対象外（非公開報）
                        privateShelters.push(data);
                    }
                });
               // 非公開指定の報のみの場合、配信ダイアログを挟まずに対象避難所を本部確認する
               if (isOnlyPrivate) {
                   return this.sendAndConfirm(shelterIds, null);
               }
               // Lアラート項目の整形
               var evaqueeDatas = [];
               array.forEach(publicShelters, function(obj){
                    // 日付の形式を、Lアラート用にフォーマットする
                    var shelterStartTime = ShelterUtils.formatDate(obj.shelterStartTime);
                    var shelterEndTime = obj.shelterEndTime ? ShelterUtils.formatDate(obj.shelterEndTime) : null;
                    var shelterStartOrEndTime = shelterStartTime;
                    // Lアラート 開設状況
                    var sort = '開設';
                    if (obj.shelterEndTime && obj.shelterEndTime !== null) {
                        sort = '閉鎖';
                        shelterStartOrEndTime = shelterEndTime;
                    }
                    // Lアラート 避難者数・避難世帯数
                    var evaqueeNum4Lalert = isNaN(obj.evaqueeNum) ? 0 : Number(obj.evaqueeNum);
                    var evacHouseholdNum4Lalert = isNaN(obj.evacHouseholdNum) ? 0 : Number(obj.evacHouseholdNum);
                    // 外部公開情報をセットする（0: 公開, 1: 非公開)
                    var publish4Lalert = obj.privateFlg === '1' ? '0' : '1';
                    // 避難所種別・避難所種別で表現しきれない情報をセットする
                    var evacShelterType = '避難所';
                    var evacShelterTypeDetail = '';
                    if (obj.welfareEvacShFlg === '1') {
                        // 福祉避難所の場合、避難所種別で表現しきれない情報を「福祉避難所」にする
                        evacShelterTypeDetail = '福祉避難所';
                    }
                    if (obj.temporaryEvacShFlg === '1') {
                        // 臨時避難所の場合、避難所種別を「臨時避難所」にする
                        // フラグが複数の場合も、臨時避難所が選択されていれば臨時避難所とみなす
                        evacShelterType = '臨時避難所';
                    }
                    // 指定区分をセットする
                    var evacSuitableShelter = '指定なし';
                    if(item.designatedEvacShFlg === '1') {
                        // 災害時避難所の場合、指定区分を「指定避難所」にする
                        evacSuitableShelter = '指定避難所';
                    }

                    var evaqueeData = {
                        facilityId: obj.facilityId,
                        evacShelterName: obj.facilityName,
                        evacShelterNameKana: obj.facilityNameKana,
                        evacShelterType: evacShelterType,
                        evacShelterTypeDetail: evacShelterTypeDetail,
                        evacSuitableShelter: evacSuitableShelter,
                        evacShelterSort: sort,
                        evacShelterLatitude: obj.latitude,
                        evacShelterLongitude: obj.longitude,
                        address: obj.address,
                        evaqueeNum: evaqueeNum4Lalert ? evaqueeNum4Lalert : 0,
                        evacHouseholdNum: evacHouseholdNum4Lalert ? evacHouseholdNum4Lalert : 0,
                        evacShelterDatetime: shelterStartOrEndTime,
                        evacTopical: obj.comment ? obj.comment : '',
                        evacShelterStatus: obj.spaceStatus,
                        publish: publish4Lalert,
                        capacity: obj.capacity,
                        contactInfo: obj.phoneNum,
                        district: obj.districtName
                    };
                    evaqueeDatas.push(evaqueeData);
               });

               var args = {};
               //  Lアラート更新種別をセット。
               // 該当災害・区内で既にLアラート配信履歴があり、かつ取消(=03)以外の場合は、「更新・訂正(=02)」
               // 配信履歴がない、または最後の配信種別が取消(=03)の場合は「新規(=01)」
               var distributionType = '02';
               if (!item.lalertSendHistId || item.distributionType === '03') {
                    distributionType = '01';
               }

               args.shelterArgs = {
                   sendCategory : '02',
                   evacTimestamp: new Date(),
                   evaqueeData: evaqueeDatas,
                   // 更新種別 '01':新規, '02':更新・訂正, '03':取消
                   distributionType: distributionType
                //    seqNum: this._seqNum // TODO: seqNumが必要か要検討
               };
               args.facilityId = publicShelters[0].facilityId;
               args.evacOrderArgs = null;

               // ダイアログの最初の子要素が登録画面
               var dialog = this.shelterRegisterDialog.getChildren()[0];
               // ダイアログの画面を初期化する。
               dialog.initDialog(args, false, false, false);
               // ダイアログのsendイベントを受け取れるようにする
               dialog.on('send', lang.hitch(this, function(evt) {
                    return this.sendAndConfirm(shelterIds, evt.value);
               }));
               // ダイアログを破棄できないように設定する。
               this.shelterRegisterDialog.set('closable', false);
               // ダイアログを表示する。
               this.shelterRegisterDialog.show();
            }));
            // ローダーの表示
            Loader.wait(promise);
            // // プロミスを返却
            // return deferred.promise;
        },

        sendAndConfirm: function(shelterIds, shelterInfo){
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
            // var deferred = new Deferred();
            var sendData = {
                shelterIds : shelterIds,
                disasterId : this._disasterId
                // FIXME: versionを含める場合は追加
            };
            if(shelterInfo){
                // TODO: 一括配信時のtwitter連携方針の検討
                //    this._sendData.twitterFlg = shelterInfo.twitterFlg;
                //    this._sendData.tweetList = shelterInfo.tweetList;
                sendData.sendPostForm = shelterInfo.lalertForm;
            }
            var jsonStr = json.stringify(sendData);
            var message = '';
            // 大阪府連携対象
            if(shelterInfo){
               message = '本部確認及び大阪府への配信を行います。よろしいですか？';
            // 大阪府連携非対象
            } else {
               message = '本部確認します。よろしいですか？';
            }
            this.chain.confirm(message, lang.hitch(this, function(chain){
                var promise = Requester.put('/api/shelters/autoConfirm/start', {
                    data: jsonStr
                }).then(lang.hitch(this, function(data){
                    console.debug('未承認報の一括承認に成功しました。data:' + data);
                    var compMessage = shelterInfo ?
                                    '本部確認・配信が完了しました。' : '本部確認が完了しました。';
                    chain.info(compMessage, '完了', lang.hitch(this, function(){
                        chain.hide();
                        if(shelterInfo){
                            this.shelterRegisterDialog.getChildren()[0].closeDialog();
                        }
                        this.updateGridQuery();
                        // deferred.resolve();
                    }));
                }), function(error){
                    // 失敗時
                    console.error(error);
                    chain.info('本部確認に失敗しました。', 'エラー');
                    // deferred.rejected();
                });
                Loader.wait(promise);
            }), lang.hitch(this, function(chain){ // キャンセル押下時
                chain.hide();
                if(shelterInfo){
                    this.shelterRegisterDialog.getChildren()[0].closeDialog();
                }
            }));
            // return deferred.promise;
        },

        onStatusButtonClick: function() {
            Router.moveTo('shelter/status',{
                municipalityCd : this._municipalityCd,
            });
        },

        // 新規登録ボタンを押下したときの挙動
        onRegisterButtonClick: function() {
            // 避難所情報新規登録画面へ遷移
            Router.moveTo('shelter/register');
        },

        // 施設追加ボタンを押下したときの挙動
        onRegisterFacilityButtonClick: function() {
            // 施設新規登録画面へ遷移
            Router.moveTo('facility/register', {
                type : 'shelter'
            });
        },

        // 一括操作ボタンを押下したときの挙動
        onOpeningButtonClick: function() {
            // 避難所情報一括操作画面へ遷移
            Router.moveTo('shelter/operating', {
                municipalityCd : this._municipalityCd,
                districtCd : this.districtCd.get('value')
            });
        },

        // 詳細ボタンを押下したときの挙動
        onDetailButtonClick: function(object) {
            // 避難所情報詳細画面へ遷移
            Router.moveTo('shelter/detail', {
                municipalityCd : this._municipalityCd,
                shelterId : object.shelterId,
                viewMode: Locator.getQuery().viewMode
            });
        },

        // 帳票出力ボタンを押した時の挙動
        showFormDialog: function() {
            this.formDialog.show();
        },

        // ファイル読み込みボタンを押した時の挙動
        showFileUploadDialog: function() {
            this.fileUploadDialog.show();
        },

        // パンくずリストのボタンを押下したときの挙動
        onShelterAdminPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('shelter/admin', {
                viewMode: Locator.getQuery().viewMode
            });
        },

        // 詳細検索を表示ボタンをクリックした時の挙動
        onOpenFilterClick: function() {
            domStyle.set(this.shelterEndTime, 'display', '');
            domStyle.set(this.searchActiveFlg, 'display', '');
            domStyle.set(this.shelterCategory, 'display', '');
            domStyle.set(this.detailSearchOpen, 'display', 'none');
            domStyle.set(this.detailSearchClose, 'display', '');
            this.borderContainer.resize();
        },

        // 詳細検索を閉じるボタンをクリックした時の挙動
        onCloseFilterClick: function() {
            domStyle.set(this.shelterEndTime, 'display', 'none');
            domStyle.set(this.searchActiveFlg, 'display', 'none');
            domStyle.set(this.shelterCategory, 'display', 'none');
            domStyle.set(this.detailSearchOpen, 'display', '');
            domStyle.set(this.detailSearchClose, 'display', 'none');
            this.borderContainer.resize();
        }
    }
    );
});
