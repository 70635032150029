/**
 * 招集メール配信ダイアログ用モジュール。
 * @module app/user/UserProcessSituationDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'idis/control/Router',
    'dojo/text!./templates/UserProcessSituationDialog.html', // テンプレート文字列
    'idis/store/IdisRest',
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/Loader',
    'idis/service/Requester',
    'idis/util/FilesUtils',
    'idis/view/dialog/DialogChain',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'idis/view/form/Button'
], function(module, declare, lang, Router, template, IdisRest, _PageBase,
    Loader, Requester, FilesUtils, DialogChain) {
    /**
     * 招集メール配信ダイアログ。
     * @class 招集メール配信ダイアログ
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/user/UserProcessSituationDialog~UserProcessSituationDialog# */
        {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            /**
             * 変数の初期化。
             */
            constructor: function() {
                this.chain = DialogChain.get(this);
            },

            /**
             * CSVファイルをダウンロードする
             */
            onSubmit: function() {

                var value = this.form.get('value');
                FilesUtils.downloadGet(value.csvFilePath, value.csvFileName);
	        }
     });
});
