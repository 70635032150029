/**
 * ユーザ管理 ファイル読み込みダイアログ用モジュール。
 * @module app/user/UserFileUploadDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/UserFileUploadDialog.html',
    'dojo/request/iframe',
    'dojo/dom-style',
    'idis/service/Requester',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    // 以下、変数で受けないモジュール
    'dojox/form/Uploader',
    'dijit/form/Form',
    'idis/view/form/Button',
    'app/view/page/ErrorMessageDialogForXoblos'
], function(module, declare, lang, template, iframe, domStyle, Requester, InfoDialog, _PageBase, Loader) {
    /**
     * ユーザ管理ファイルアップロードダイアログ
     * @class UserFileUploadDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
                   /** @lends module:app/view/page/DamageReportPage~DamageReportPage# */ {
        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Page--safety-dialog',

        _fileId: null,

        infoDialog : null,

        file: [],

        EMPLOYEE_REPORT_REQID: 'DP_EMPLOYEE_LIST_IN',

        /**
         * constructor
         */
        constructor: function() {
        },

        /**
         * ファイル読み込み実行
         */
        onSubmit: function() {
			this.loadAttachFile();
        },

        /**
         *ファイル選択時に呼ばれる
         **/
        loadFile: function() {
             //ファイルが空の場合は処理を中断
             if(this.attachFile._files.length === 0) {
                 return;
             }

             console.debug('report change');

             // エクセルファイル以外の場合はエラー
             var file = this.attachFile._files[0];
             if(file.name.indexOf('.xml') === -1) {
                 if (!this.infoDialog) {
                     this.infoDialog = new InfoDialog({
                         title : 'エラー',
                         content : 'xmlファイルを選択してください。'
                     });
                 }
                 this.infoDialog.show();
                 this.infoDialog=null;
                 this.attachFile.reset();
                 return;
             }

             var form = new FormData();
             form.append('report', this.attachFile._files[0]);

            // ファイル名をyyyymmddhhssに設定。このファイル名はimport用APIをコールするときにも使う。
            var now = new Date();
            this._fileId = now.getFullYear() + this.padZero(now.getMonth() + 1) +
                this.padZero(now.getDate()) + this.padZero(now.getHours()) +
                this.padZero(now.getMinutes()) + this.padZero(now.getSeconds());
         },

        /**
         *実行ボタン押下時に呼ばれる
         **/
        loadAttachFile: function() {
            // ファイルが空の場合は処理を中断
            var reqid = this.EMPLOYEE_REPORT_REQID;
//            var reqParams = {};
            var form = new FormData();

            form.append('report', this.attachFile._files[0]);

            // ファイル名をyyyymmddhhssに設定。このファイル名はimport用APIをコールするときにも使う。
            var now = new Date();
            this._fileId = now.getFullYear() + this.padZero(now.getMonth() + 1) +
                this.padZero(now.getDate()) + this.padZero(now.getHours()) +
                this.padZero(now.getMinutes()) + this.padZero(now.getSeconds());
            // ファイルがonloadされたときにサーバーに一時保存する
            var self = this;
            Loader.wait(iframe.post('/api/users/uploadFile', {
                form: this.form.id,
                handleAs: 'json'
            })).then(lang.hitch(this, function(ret) {
                // アップロードしたファイルパスとエラーメッセージjson、
                InfoDialog.show('取り込み開始', 'ファイル読み込みを開始しました。<br>処理結果は処理結果確認ボタンから確認してください。' );
                self.submitButton.set('disabled', true);
                var promise = Requester.post('/api/users/Import',{
                    data: {
                        reqid: reqid,
                        disid: null,
                        filename: ret.fileName
                    }
                }).then(lang.hitch(this, function(){
                   // InfoDialog.show('取り込み終了', 'ファイル読み込みを終了しました。' );
                   InfoDialog.show('取り込み開始', 'ファイル読み込みを開始しました。<br>処理結果は処理結果確認ボタンから確認してください。' );
                   Loader.wait(promise);
                }), function(error) {
                    // ファイル読み込み失敗
                    if (error.response.status === 400) {
                        // ファイル読み込みダイアログを閉じる
                        InfoDialog.show('取り込みエラー', '他のユーザが実行中の為取り込みできません。' );
                    } else if (error.response.status === 404) {
                        // ファイル読み込みダイアログを閉じる
                        InfoDialog.show('取り込みエラー', 'XMLファイル読み取り時にエラーが発生しました。' );
                    } else {
//	                    // エラー内容をダイアログに表示する
                        InfoDialog.show('取り込みエラー', 'エラーが発生しました。管理者にお問い合わせください。' );
                        self.submitButton.set('disabled', false);
//		                // uploaderをリセット
		                this.attachFile.reset();
		                this.chain.infoError(error);
                    }
                });
            }), function(error) {
                // エラー内容をダイアログに表示する
                InfoDialog.show('取り込みエラー', 'ファイル取込時にエラーが発生しました。管理者にお問い合わせください。'  );
                this.submitButton.set('disabled', false);
                this.chain.infoError(error);
                // uploaderをリセット
                this.attachFile.reset();
                this.chain.infoError(error);
		    });
        },

        /**
         * 入力チェック
         */
        validate: function() {
            //ファイルが空の場合は処理を中断
            if(this.attachFile.getFileList().length === 0) {
                this.infoDialog = new InfoDialog({
                title : 'エラー',
                content : 'xmlファイルを選択してください。'
             	});
                this.infoDialog.show();
                return false;
            }
            return true;
        },

        // 日付をゼロパディング
        padZero : function(num){
            var result;
            if (num < 10) {
                result = '0' + num;
            } else {
                result = '' + num;
            }
            return result;
        }, 

        // ダイアログが表示される際に呼ばれる
        initDialog: function() {
            var promise = Requester.get('/api/users/processSituation',{
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(lang.hitch(this, function(data){
                if(data.processSituation === '処理中') {
                    this.submitButton.set('disabled', true);
                } else {
                    this.submitButton.set('disabled', false);
                }
            }), function(error) {
                // 処理状況テーブル取得失敗
                console.error(error);
                this.submitButton.set('disabled', true);
            });
            Loader.wait(promise);
        }
    });
});
