/**
 * 避難所状況表示項目 複数選択用パーツ。
 * 
 * @module app/shelter/status/selector/ShelterStatusTypeSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'dojo/_base/lang',
    'dojo/string',
    'dijit/form/TextBox',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/form/TreeChecker'
], function (module, declare, domStyle, lang, dStr, TextBox, CacheStoreModel, CacheTreeRest, TreeChecker) {
    /**
     * 表示項目選択パーツ。
     * @class UsersSelector
     * @extends module:idis/view/form/TreeChecker~TreeChecker
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:idis/view/tree/ShelterStatusTypeSelector~ShelterStatusTypeSelector# */ {
            // 選択ダイアログのタイトル
            title: '表示項目選択',
            // ツリー・モデル
            model: null,
            // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
            constructor: function () {
                this._initModel();
            },
            // DOMを構築する
            buildRendering: function () {
                this.inherited(arguments);
                // 検索用入力要素を設置
                this.filterInput = new TextBox({
                    placeHolder: this.placeHolder,
                    maxLength: 128
                });
                this.filterInput.placeAt(this.treeControlNode);
                this.own(this.filterInput.on('keyup', lang.hitch(this, 'onFilterInputChange')));
            },


            _initModel: function () {
                this.model = new CacheStoreModel({
                    store: new CacheTreeRest({
                        target: 'api/code/tree?type=SHLT_DP_TYPE'
                    })
                });
            },

            /**
             * 選択した値の設定
             */
            _setValueAttr: function () {
                // TreeChecker本来の処理
                this.inherited(arguments);
                // 文字列省略をオーバーライド
                domStyle.set(this.status, {
                    'max-width': '20em',
                    'white-space': 'nowrap',
                    'overflow': 'hidden',
                    'text-overflow': 'ellipsis'
                });
            },
            /**
             * 検索用入力要素入力時。
             */
            onFilterInputChange: function () {
                // 入力値を取得
                var value = dStr.trim(this.filterInput.get('value'));
                if (value) {
                    // ラベル名が入力値を含むものでフィルターし、ツリーを展開する
                    this.model.setFilter(function (item, isLeaf, model) {
                        return isLeaf && (model.getLabel(item) || '').indexOf(value) !== -1;
                    }).then(lang.hitch(this, function () {
                        this.tree.expandAll();
                    }));
                } else {
                    // 入力が空の場合は全要素を表示してツリーを閉じる
                    this.model.setFilter().then(lang.hitch(this, function () {
                        this.tree.collapseAll();
                    }));
                }
            }
        });
});

