/**
 * <避難所情報概況画面>
 *
 * @module app/river/grid/RiverLeveInputDialogGrid.js
 */
 define([
    'app/config',
    'module', // モジュールのパスを返す
    'dojo/_base/array',
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/query',
    'dijit/form/Button',
    'dojo/topic',
    'dojox/lang/functional/array',
    'idis/util/DateUtils',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/IdisSelector',
    'idis/view/grid/helper',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'idis/consts/ACL',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'dijit/layout/BorderContainer'
], function(config, module, array, declare, lang, domClass, query, Button,
    topic, df, DateUtils, IdisGrid, IdisSelector, helper) {

    // 日時編集イベント
    var _TIME_EDIT_EVENT = module.id + '::' + 'TimeEdit';
    // 数値編集イベント
    var _NUMBER_EDIT_EVENT = module.id + '::' + 'NumberEdit';

    /**
     * 指定された'yyyy-MM-dd HH:mm:ss'形式の日付文字列をDateインスタンス化して返す。
     * @function _parseDateStr
     * @param {string} dateStr 日付文字列
     * @returns {Date} 日付文字列に対応するDateインスタンス
     * @private
     */
    function _parseDateStr(dateStr) {
        var matched = dateStr.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/);
        var year = parseInt(matched[1], 10);
        // 数値上の月は0始まり
        var month = parseInt(matched[2], 10) - 1;
        var day = parseInt(matched[3], 10);
        var hour = parseInt(matched[4], 10);
        var minute = parseInt(matched[5], 10);
        var second = parseInt(matched[6], 10);
        return new Date(year, month, day, hour, minute, second);
    }

    /**
     * 編集可能な日時グリッド列定義を生成して返す。
     * @function _editableTsColumn
     * @param {string} field フィールド名
     * @param {string} label 列名
     * @private
     */
    function _editableNumColumn(field, label) {
        return {
            field: field,
            label: label,
            sortable: false,
            // イベント種別判別用の独自プロパティー
            _editEvent: _NUMBER_EDIT_EVENT,
            renderCell: function(item) {
                var node = document.createElement('div');
                node.innerHTML = [
                    '<span class="river-RiverLeveInputDialogGrid-Label">',
                    this.grid.formatField(item, field),
                    '</span><div class="river-RiverLeveInputDialogGrid-Edit"></div>'
                ].join('');
                return node;
            }
        };
    }
    /**
     * 編集可能な日時グリッド列定義を生成して返す。
     * @function _editableTsColumn
     * @param {string} field フィールド名
     * @param {string} label 列名
     * @private
     */
    function _editableNumColumn2(field, label) {
        return {
            field: field,
            label: label,
            sortable: false,
            // イベント種別判別用の独自プロパティー
            _editEvent: _NUMBER_EDIT_EVENT,
            renderCell: function(item) {
                var node = document.createElement('div');
                node.innerHTML = [
                    '<span class="river-RiverLeveInputDialogGrid-Label">',
                    this.grid.formatField(item, field),
                    '</span></div>'
                ].join('');
                return node;
            }
        };
    }
    /**
     * <クラスの説明>
     *
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    var RiverLeveInputDialogGrid = declare(module.id.replace(/\//g, '.'), [IdisGrid, IdisSelector],
    /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */
    {
        allowTextSelection: false,

        /**
         * グリッド行IDと報告日時の対応
         * @type {Object}
         */
        reportTimeMap: null,

        /**
         * グリッド行IDと開設日時の対応
         * @type {Object}
         */
        shelterStartTimeMap: null,

        /**
         * グリッド行IDと閉鎖日時の対応
         * @type {Object}
         */
        shelterEndTimeMap: null,

        evacHouseholdNumMap: null,

        evaqueeNumMap: null,

        /**
         * 日付列判別用。
         * 列名をキー、日付列かどうかを値として格納する。
         * @type {Object}
         */
        editTypeMap: null,

        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            return item && (item.availableFlg === '0') ? 'gray-row' : '';
        },

        renderRow: function(item) {
            var div = this.inherited(arguments);
            // rowClassNameが指定されている場合
            if (this.rowClassName) {
                var className = this.rowClassName;
                // 関数の場合は行データを与えて実行する
                if (lang.isFunction(className)) {
                    className = className(item);
                }
                // 偽値でなければCSSクラスとして追加
                if (className) {
                    domClass.add(div, className);
                }
            }
            return div;
        },

        columns: [
            // チェックボックス
            {
              field: 'checkbox',
              label: 'checkbox',
              selector: 'checkbox'
            },
            // helper.column('riverLevelId', 'ID', {sortable: false }),
            helper.column('observatoryName', '観測所名', {sortable: false}),
            _editableNumColumn('riverLevelIn1hour', '予測水位1時間後[m]'),
            _editableNumColumn('riverLevelIn2hour', '予測水位2時間後[m]'),
            _editableNumColumn('riverLevelIn3hour', '予測水位3時間後[m]'),
            //_editableNumColumn2('observatoryName', '観測所名'),
            //_editableNumColumn2('forecastTimeIn1hour', '1時間後'),
            //_editableNumColumn2('forecastTimeIn2hour', '2時間後'),
            //_editableNumColumn2('forecastTimeIn3hour', '3時間後')
        ],
        constructor: function() {
            // 変数を初期化
            // this.reportTimeMap = {};
            // this.shelterStartTimeMap = {};
            // this.shelterEndTimeMap = {};
            // this.evacHouseholdNumMap = {};
            // this.evaqueeNumMap = {};
            this.observatoryNameMap = {};
            this.editTypeMap = {};
            this.riverLevelIn1hourMap = {};
            this.riverLevelIn2hourMap = {};
            this.riverLevelIn3hourMap = {};
            this.forecastTimeIn1hourMap = {};
            this.forecastTimeIn2hourMap = {};
            this.forecastTimeIn3hourMap = {};
            this.riverLevelIdMap = {};
        },

        // プロパティー設定後に呼ばれる
        postMixInProperties: function() {
            this.inherited(arguments);
            // 日付列を記録
            df.forEach(this.columns, function(column) {
                this.editTypeMap[column.field] = column._editEvent === _TIME_EDIT_EVENT;
            }, this);
        },

        // DOM構築後に呼ばれる
        postCreate: function() {
            this.inherited(arguments);
            console.log(this);
            // 選択行の編集ボタンに対するクリックを監視
            this.on('.dgrid-selected .river-RiverLeveInputDialogGrid-Edit:click', lang.hitch(this, function(evt) {
                var cell = this.cell(evt);
                var row = cell.row;
                var column = cell.column;
                // 編集ダイアログを開くためのイベントを発行
                topic.publish(cell.column._editEvent, {
                    // 対象グリッド
                    grid: this,
                    // 列定義
                    column: column,
                    // 行はIDで指定（一括操作と揃えるため配列化）
                    idList: [row.id],
                    // 初期値として既存設定値を渡す
                    value: this.fetchItemValue(row.data, column.field)
                });
            }));
            // 1件以上選択されている場合のみ一括入力ボタンをクリック可能にする
            // this.on('dgrid-refresh-complete, dgrid-error, dgrid-select, 
            //     dgrid-deselect', lang.hitch(this, function() {
            //     var disabled = !this.getSelectedIdList().length;
            //     this.reportTimeBulkEditButton.set('disabled', disabled);
            //     this.shelterStartTimeBulkEditButton.set('disabled', disabled);
            //     this.shelterEndTimeBulkEditButton.set('disabled', disabled);
            // }));
            // 行選択時はサーバー側の値を既存値として設定する
            this.on('dgrid-select', lang.hitch(this, function(evt) {
                array.map(evt.rows, function(row) {
                    df.forEach(this.columns, function(column) {
                        // 編集対象列以外は何もしない
                        if (!column._editEvent) {
                            return;
                        }
                        // 以下、編集対象列の場合
                        var isDate = this.isDateField(column.field);
                        // サーバー側の設定値が存在するなら初期値として設定
                        //var value = row.data && row.data[column.field];
                        //if (value) {
                        this.setEditValue(row, column.field, isDate ? _parseDateStr(row.data[column.field]): row.data[column.field]);
                        //}
                        // DOMを更新
                        this.updateLabel(row, column.field);
                    }, this);
                }, this);
            }));
            // 行選択解除時は入力値をクリアする
            this.on('dgrid-deselect', lang.hitch(this, function(evt) {
                array.map(evt.rows, function(row) {
                    df.forEach(this.columns, function(column) {
                        // 編集対象列以外は何もしない
                        if (!column._editEvent) {
                            return;
                        }
                        // 以下、編集対象列の場合
                        // 入力値をクリア
                        this.deleteEditValue(row.id, column.field);
                        // DOMを更新
                        this.updateLabel(row, column.field);
                    }, this);
                }, this);
            }));
        },

        /**
         * 指定された列が日付列かどうかを返す。
         * @returns {boolean} 指定された列が日付列かどうか
         */
        isDateField: function(field) {
            return this.editTypeMap[field];
        },

        /**
         * グリッド要素またはそのIDを受け取り、グリッド要素のIDを返す。
         * @param {Object|string} itemOrId グリッド要素またはそのID
         * @returns {string} グリッド要素のID
         * @private
         */
        _toItemId: function(itemOrId) {
            if (lang.isObject(itemOrId)) {
                return this.get('collection').getIdentity(itemOrId);
            } else {
                return itemOrId;
            }
        },

        /**
         * 指定された要素の指定されたフィールドの編集値を設定する。
         * @param {Object|string} itemOrId グリッド要素またはそのID
         * @param {string} field 列名
         * @param {Date|string} value フィールドの設定値
         */
        setEditValue: function(row, field, value) {
            var itemId = this._toItemId(row.id);
            var time;
            if (itemId) {
                this[field + 'Map'][itemId] = value;
                this['observatoryNameMap'][itemId] = row.data.observatoryName;
                this['forecastTimeIn1hourMap'][itemId] = row.data.forecastTimeIn1hour;
                this['forecastTimeIn2hourMap'][itemId] = row.data.forecastTimeIn2hour;
                this['forecastTimeIn3hourMap'][itemId] = row.data.forecastTimeIn3hour;
            } else {
                console.error('不明な設定先要素');
            }
        },

        /**
         * 指定された要素の指定されたフィールドの編集値を削除する。
         * @param {Object|string} itemOrId グリッド要素またはそのID
         * @param {string} field 列名
         */
        deleteEditValue: function(itemOrId, field) {
            if (itemOrId) {
                delete this[field + 'Map'][this._toItemId(itemOrId)];
            }
        },

        /**
         * 指定した要素の指定したフィールド値を取得する。
         * グリッドの編集値が存在する場合はそれを優先し、無ければ元の設定値を取得する。
         * @param {Object} item グリッド要素
         * @param {string} field フィールド名
         * @returns {Date|string} 日付フィールドなら日付、それ以外は文字列形式で値を返す
         */
        fetchItemValue: function(item, field) {
            if (!item) {
                return null;
            }
            var value = item[field];
            if(item && this[field + 'Map'][this._toItemId(item)] !== undefined){
                value = this[field + 'Map'][this._toItemId(item)];
            }
            return value;
        },

        /**
         * 指定した要素の指定したフィールドの表示値を返す。
         * グリッドの編集値が存在する場合はそれを優先し、無ければ元の設定値を取得する。
         * @param {Object} item グリッド要素
         * @param {string} field フィールド名
         * @returns {string} フィールドの表示文字列
         */
        formatField: function(item, field) {
            var value = this.fetchItemValue(item, field);
            if (!value && !Number.isFinite(value)) {
                return '未設定';
            } else if (this.isDateField(field)) {
                return DateUtils.format(value).slice(0, -3);
            } else if (field === 'riverLevelIn1hour') {
                if(item.forecastTimeIn1hour === null){
                    return value + '\n(' + item.newForecastTimeIn1hour + ')';
                } else {
                    return value + '\n(' + item.forecastTimeIn1hour + ')';
                }
            } else if (field === 'riverLevelIn2hour') {
                if(item.forecastTimeIn2hour === null){
                    return value + '\n(' + item.newForecastTimeIn2hour + ')';
                } else {
                    return value + '\n(' + item.forecastTimeIn2hour + ')';
                }
            } else if (field === 'riverLevelIn3hour') {
                if(item.forecastTimeIn3hour === null){
                    return value + '\n(' + item.newForecastTimeIn3hour + ')';
                } else {
                    return value + '\n(' + item.forecastTimeIn3hour + ')';
                }
            } else {
                return value;
            }
        },

        /**
        * 指定された行・列の現在の設定値をDOMに反映する。
        * 本来はputメソッドで元データを更新し、HTMLは列定義を元に自動更新されるようにすべきであるが、
        * グリッドのstoreがRESTベースでありputはサーバーへのPUTとして解釈されてしまうため、
        * putメソッドは使わずにこのメソッド内で直接HTMLを書き換える。
        * @param {Object} row dgrid行情報
        * @param {string} row.id 行ID
        * @param {string} row.data 行データ
        * @param {string} row.element 行のDOM要素
        * @param {string} field 列名
         */
        updateLabel: function(row, field) {
            var labelNode = query('.field-' + field + ' .river-RiverLeveInputDialogGrid-Label', row.element)[0];
            if (labelNode) {
                labelNode.innerHTML = this.formatField(row.data, field);
            }
        },

        /**
         * グリッドの編集結果を更新する。
         * @param {Object} kwArgs キーワード引数
         * @param {string} kwArgs.field フィールド名
         * @param {Date|string} kwArgs.value 編集結果の値
         * @param {string[]} kwArgs.idList 行ID一覧
         */
        updateEditValues: function(kwArgs) {
            array.forEach(kwArgs.idList, function(id) {
                // POST用データを保持
                this.setEditValue(this.row(id), kwArgs.field, kwArgs.value);
                // DOMを更新
                this.updateLabel(this.row(id), kwArgs.field);
            }, this);
        }
    });

    // イベント名を外から参照出来るようにする
    RiverLeveInputDialogGrid.TIME_EDIT_EVENT = _TIME_EDIT_EVENT;
    RiverLeveInputDialogGrid.NUMBER_EDIT_EVENT = _NUMBER_EDIT_EVENT;

    return RiverLeveInputDialogGrid;
});