/**
 * 行政区選択ダイアログ画面用モジュール。
 * @module app/view/form/DamageMunicipalityDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/_base/lang',
    'dojo/topic',
    'dojo/text!./templates/DialogContent.html',
    'dstore/Memory',
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog',
    'idis/store/IdisRest',
    'idis/view/page/_PageBase',
    'idis/view/dialog/DialogChain',
    'app/view/form/consts/DamageMunicipality',

    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    './MunicipalityGrid'
], function (module, declare, array, lang, topic, template, Memory, DisasterInfo, InfoDialog,
    IdisRest, WidgetBase, DialogChain, DamageMunicipality) {

    /**
     * 被害種別選択ダイアログ
     * @class DamageGridTypeDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends app/view/form/DamageMunicipalityDialog~DamageMunicipalityDialog# */ {

            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'damagetype-selector',

            SELECTION_RELEASED: 'app/view/form/CustomizableMunicipalitySelector::released',

            /**
             * データ格納用オブジェクト
             * @type {module:dstore/Store}
             */
            store: null,

            noUnderlay: true,
            title: '行政区選択ダイアログ',
            hasData: false,

            /**
             * コンストラクタ
             */
            constructor: function () {
                this.inherited(arguments);

                this.store = new Memory({
                    'idProperty': 'municipalityId',
                    'data': []
                });
            },

            /**
             * DOM生成
             */
            buildRendering: function () {
                this.inherited(arguments);
            },

            /**
             * 画面生成完了
             */
            startup: function () {
                this.inherited(arguments);
                this.initContent();

                this.own(topic.subscribe(this.SELECTION_RELEASED, lang.hitch(this, function () {
                    this.initContent();
                })));
            },

            /**
             * 画面を再表示
             */
            refresh: function () {
                this.container.resize();
                this.item = null;
            },

            /**
            * widget生成後, 画面表示前
            */
            postCreate: function () {
                this.inherited(arguments);
            },

            initContent: function () {

                var data = [];
                if (!this.hasData) {
                    Object.keys(DamageMunicipality).forEach(function (id) {
                        var obj = {};
                        var key = ('00' + String(id)).slice(-2); // iをゼロパディング
                        obj.municipalityId = key;
                        obj.municipality = DamageMunicipality[key];
                        // 新規登録時には現地画像を表示しない
                        if (id !== '13' || location.href.indexOf('detail') > -1) {
                            data.push(obj);
                        }
                    }, DamageMunicipality);
                }

                // 順番を修正する
                data.sort(function (a, b) {
                    if (a.municipalityId > b.municipalityId) {
                        return 1;
                    } else {
                        return -1;
                    }
                });


                this.grid.reset();

                array.forEach(data, lang.hitch(this, function (item) {
                    this.store.addSync(item);
                }));

                this.grid.set('collection', this.store);
                this.hasData = true;
            },

            onConfirm: function () {
                this.inherited(arguments);

                this.emit('update', {
                    data: {
                        mainTypeIds: this.grid.mainTypeIds
                    }
                });

            },

            onCancel: function () {
                this.inherited(arguments);
                this.close();
            },

            show: function () {
                this.inherited(arguments);

                this.grid.resize();
                this.container.resize();
            },

            onHide: function () {
                this.inherited(arguments);
                this._newStore();
                this.grid.set('collection', this.store);
                this.hasData = false;
            },

            close: function () {
                this.inherited(arguments);
                this.hide();
            },

            _newStore: function () {
                if (this.store) {
                    delete this.store;
                }

                this.store = new Memory({
                    'idProperty': 'municipalityId',
                    'data': []
                });
            }
        });

});
