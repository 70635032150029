/**
 * 部新規登録ダイアログ用モジュール。
 * @module app/organization/DeptRegisterDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/DeptRegisterDialog.html',
    './_DeptDialogBase',
    // 以下、変数で受けないモジュール
    'app/view/form/MunicipalitySelector',
    'app/view/form/RegionSelector',
    'dijit/form/ValidationTextBox'
], function(module, declare, lang, domStyle, template, _DeptDialogBase) {
    /**
     * 部新規登録ダイアログ
     * @class DeptRegisterDialog
     * @extends module:app/organization/_DeptDialogBase~_DeptDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), _DeptDialogBase,
    /** @lends module:app/view/page/DeptRegisterDialog~DeptRegisterDialog# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * constructor
         */
        constructor: function() {
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        /**
         * 部を新規登録する。
         */
        onSubmit: function() {
            try {
                console.debug('部新規登録ダイアログの[登録]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if(!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                value.newDeptCd = value.deptCd;
                delete value.deptCd;
                // TODO 以下のログ書き出しを消去し、コメントアウトを外す

                this.emit('register', {value: value});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * ダイアログを初期化する。（新規登録時）
         */
        initDialog: function() {
            this.reset();
        }
    });
});
