/**
 * ユーザーに紐付く市町村選択用入力パーツ避難情報画面では変更後にtopicを発行する。
 * @module app/evacorder/EvacOrderMunicipalitySelector
 */
define([
    'module',
    'dojo/topic',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/string',
    'dijit/form/TextBox',
    'dojo/when',
    'idis/model/UserInfo',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'app/view/form/MunicipalityListSelector',
    'idis/view/dialog/InfoDialog',
    'app/config'
], function (module, topic, declare, lang, dStr, TextBox, when, UserInfo, CacheStoreModel, CacheTreeRest, MunicipalityListSelector,
    InfoDialog, config) {
    /**
     * 行政区選択用パーツ（避難情報）
     * @class FacilityTypeSelector
     * @extends module:idis/view/tree/ShelterFacilitySelector~ShelterFacilitySelector
     */
    return declare(module.id.replace(/\//g, '.'), MunicipalityListSelector,
        /** @lends module:app/evacorder/EvacOrderMunicipalitySelector~EvacOrderMunicipalitySelector*/ {

            // 選択ダイアログのタイトル
            title: '区',

            // ツリー・モデル
            model: null,

            startup: function () {
                this.inherited(arguments);
                this.dialog.hideOnOK = false;
            },
            // DOMを構築する
            buildRendering: function () {
                this.inherited(arguments);
                // 検索用入力要素を設置
                this.filterInput = new TextBox({
                    placeHolder: this.placeHolder,
                    maxLength: 128
                });
                this.filterInput.placeAt(this.treeControlNode);
                this.own(this.filterInput.on('keyup', lang.hitch(this, 'onFilterInputChange')));
            },

            postMixInProperties: function () {
                // デフォルトは市町村のうち、管理対象のものを表示
                var prefFlg = this.prefFlg || '1';
                var regionFlg = this.regionFlg || '1';
                var municFlg = this.municFlg || '1';
                var manageFlg = this.manageFlg || '1';
                var cityFlg = this.cityFlg || '1';
                // manageFlgが立っている場合、管理対象の上位組織を表示するためのフラグ(manageFlgが0ならば無効)
                var manageParentFlg = this.manageParentFlg || '0';
                var option = 'prefFlg=' + prefFlg + '&regionFlg=' + regionFlg;
                option += '&municFlg=' + municFlg + '&manageFlg=' + manageFlg;
                option += '&manageParentFlg=' + manageParentFlg;
                option += '&cityFlg=' + cityFlg;
                this.model = new CacheStoreModel({
                    store: new CacheTreeRest({
                        target: '/api/municipalities/customizableMunicipality4Tree?' + option
                    })
                });
            },

            /**
             * 選択した値の設定
             */
            // 継承元との差分は以下。
            // (1) prefUnselectable/regionUnselectableが指定された場合、県/振興局は選択不可。
            // (2) 親の名前を表示させない。ex. [岡山県/岡山市/岡山市中区] → [岡山市中区]
            _setValueAttr: function (value) {
                // 偽値は数値の0のみ有効とする
                if (value || value === 0) {
                    if (this.prefUnselectable) {
                        //県選択不可
                        if (value === config.municInfo.prefMunicCd) {
                            InfoDialog.show('エラー', '市は選択することができません。');
                            return false;
                        }
                    }
                    if (this.regionUnselectable) {
                        //振興局選択不可
                        if (value !== config.municInfo.prefMunicCd &&
                            value.substring(0, 3) === config.municInfo.prefRegCdGudge) {
                            InfoDialog.show('エラー', '地域は選択することができません。');
                            return false;
                        }
                    }
                    if (this.cityUnselectable) {
                        //政令指定都市選択不可
                        if (value === config.municInfo.cityMunicCd) {
                            // InfoDialog.show('エラー', '政令指定都市全体を指定することはできません。<br>区を選択してください。');
                            // 大阪市の場合
                            InfoDialog.show('エラー', '大阪市全体を指定することはできません。<br>区を選択してください。');
                            return false;
                        }
                    }
                    this._set('value', value);
                    this.emit('change', { value: value });
                    this._initTree().then(lang.hitch(this, function () {
                        var model = this.tree.model;
                        var label;
                        if (this.fullName) {
                            label = model.getFullName(value);
                        } else {
                            label = when(model.store.get(value), function (item) {
                                return model.getLabel(item);
                            });
                        }
                        when(label, lang.hitch(this, function (name) {
                            // 親の情報は不要なので省略する。
                            var lastIndex = name.lastIndexOf('/');
                            if (lastIndex !== -1) {
                                var excludeParentName = name.substring(lastIndex + 1);
                                name = excludeParentName;
                            }
                            this.status.innerHTML = name;
                        }));
                    }));
                } else {
                    this._set('value', '');
                    this.emit('change', { value: '' });
                    this.status.innerHTML = this.emptyValueLabel;
                }
                // 要素の選択状態をリセットする
                this._initTree().then(lang.hitch(this, function () {
                    this.tree.set('selectedItem', null);
                }));
                this.dialog.hide();
            },

            /**
             * 検索用入力要素入力時。
             */
            onFilterInputChange: function () {
                // 入力値を取得
                var value = dStr.trim(this.filterInput.get('value'));
                if (value) {
                    // ラベル名が入力値を含むものでフィルターし、ツリーを展開する
                    this.model.setFilter(function (item, isLeaf, model) {
                        return isLeaf && (model.getLabel(item) || '').indexOf(value) !== -1;
                    }).then(lang.hitch(this, function () {
                        this.tree.expandAll();
                    }));
                } else {
                    // 入力が空の場合は全要素を表示してツリーを閉じる
                    this.model.setFilter().then(lang.hitch(this, function () {
                        this.tree.collapseAll();
                    }));
                }
            }

        });
});
