/**
 * 利用者更新用ダイアログモジュール。
 * @module app/formDisplay/formDisplayDialog
 */
define([
    'module',
    'leaflet',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date',
    'dojo/date/locale',
    'dojo/dom-style',
    'dojo/text!./templates/formDisplayDialog.html',
    'dojo/topic',
    'idis/consts/ACL',
    'idis/model/UserInfo',
    'idis/view/Loader',
    'idis/view/dialog/InfoDialog',
    'idis/service/Requester',
    'app/config',
    'app/formDisplay/_formDisplayDialogBase',
    'app/formDisplay/displayType',
    'idis/util/DateUtils',
    'app/util/DateFormatter',
    'app/model/DisasterInfo',
    // 以下、変数で受けないモジュール
    'idis/view/form/RadioGroup',
    'idis/view/form/WordCountTextarea',
    'app/view/form/MunicipalityMultiSelectorNoPref',
    'app/view/form/OrganizationMultiSelector',
    'app/view/form/DisasterSelector',
    'app/view/form/EarthQuakeSelector',
    'app/formDisplay/damagetype/DamageGridTypeSelector'
], function(module, leaflet, declare, lang, date, locale, domStyle, template, topic, ACL,
    UserInfo, Loader, InfoDialog, Requester, config, _formDisplayDialogBase, displayType,
    DateUtils, DateFormatter, DisasterInfo) {

    return declare(module.id.replace(/\//g, '.'), _formDisplayDialogBase,
    {
        // テンプレート文字列
        templateString: template,

        /**
         * 最新日時
         * デフォルトは現在日時の直近10分刻み時刻
         * @type {Date}
         */
        latestDateTime: null,

        /**
         * 災害発生日時
         */
         _disasterStartTimestamp: null,
         _disasterEndTimestamp: null,

        /**
        * 水道局災害名リスト
        */
        _IncidentDisasterList: [],

        constructor: function() {
            this.latestDateTime = this.roundTime(new Date());

            // 最過去日時を設定
            var dateStr = locale.format(date.add(this.latestDateTime, 'day', -365), {
            	selector: 'date',
            	datePattern: 'yyyy/MM/dd'
            });
            this.oldestDateTime = new Date(dateStr + ' 00:00');
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            
            // 消防局災害グループ取得
            this.initSetFdmaDisasterGroupList(this.disasterGroupList);

            // 災害選択用データ取得
            this.getIncidentDisasterList();
        },
    
        /**
         * フォームを初期化する。
         */
        initForm: function (object) {
            this._screenId = object.screenId;
            // ディスプレイ種別にモデルをセット
            this.displayType.set('store', displayType);
            // セレクトボックスのスタイル設定
            this.dateSelect.set('style', {width: '10em'});
            this.timeSelect.set('style', {width: '8em'});
            // 日付のセレクトボックスを生成する
            this.setDateSelect();
            // 時間のセレクトボックスを生成する
            this.setTimeSelect(this.latestDateTime);
            // 水道局災害セレクトボックスを生成する
            this.initSetIncidentSelect(this.incidentNameSelect);
            // 大阪市災害セレクトボックスを生成し、スタイルを設定
            this.initSetIncidentDisasterSelect(this.incidentDisasterNameSelect);
            if (this.incidentDisasterNameSelect.options.length === 1){
                this.incidentDisasterStatus.set('disabled', 'true');
                this.incidentDisasterNameSelect.set('disabled', 'true');
            }
            else {
                var chkDisasterId = parseInt(DisasterInfo.getDisasterId());
                for (var incident in this._IncidentDisasterList) {
                    if (this._IncidentDisasterList[incident].disasterId === chkDisasterId) {
                        this.incidentDisasterStatus.set('value', '1');
                        break;
                    }
                }
                this.onChangeSelect();
            }
            
            // 登録日時、更新日時に初期値として現在日時を設定
            var date = new Date();
            this.incidentTimestamp.set('value', date);
            this.updateDatetime.set('value', date);
            
            Requester.get('/api/formDisplay/detail/' + this._screenId)
            .then(lang.hitch(this,function(data) {
                var item = data;
                this.displayType.set('value', item.displayType);
                this.remarks.set('value', item.remarks);
                if (item.disasterId !== null) {
                    this.disasterId.set('value', item.disasterId);
                }
                if (item.municipalityCd !== null) {
                    this.municipalityCd.set('value', item.municipalityCd);
                }
                if (item.organizationCd !== null) {
                    this.organizationCd.set('value', item.organizationCd);
                }
                if (item.notifyDist !== null) {
                    this.notifyDist.set('value', item.notifyDist);
                }
                if (item.hldStatus !== null) {
                    this.hldStatus.set('value', item.hldStatus);
                }
                if (item.lifelineKindCd !== null) {
                    this.lifelineKindCd.set('value', item.lifelineKindCd);
                }
                if (item.notifyType !== null) {
                    this.notifyType.set('value', item.notifyType);
                }
                if (item.reportedStatus !== null) {
                    this.reportedStatus.set('value', item.reportedStatus);
                }
                if (item.shelterStatus !== null) {
                    this.shelterStatus.set('value', item.shelterStatus);
                }
                if (item.urgencyType !== null) {
                    this.urgencyType.set('value', item.urgencyType);
                }
                if (item.earthQuake !== null) {
                    this.earthQuake.set('value', item.earthQuake);
                }
                if (item.riverLevel !== null) {
                    this.riverLevel.set('value', item.riverLevel);
                }
                if (item.startTimestamp !== null) {
                    this.startTimestamp.set('value', item.startTimestamp);
                }
                if (item.endTimestamp !== null) {
                    this.endTimestamp.set('value', item.endTimestamp);
                }
                if (item.damageTypeList !== null) {
                    this.innerDamageGridTypeSelector.resetDamageType();
                    var damageTypeArr = item.damageTypeList.split(',');
                    this.innerDamageGridTypeSelector.setDamageType(damageTypeArr);
                }
                if (item.dateSelect !== null) {
                    this.dateSelect.set('value', item.dateSelect);
                }
                if (item.timeSelect !== null) {
                    this.timeSelect.set('value', item.timeSelect);
                }
                if (item.entryDatetime !== null && item.disasterGroupNo !== null) {
                    this.disasterGroupList.set('value', item.entryDatetime + '_' + item.disasterGroupNo);
                    this.updateDatetime.set('value', item.updateDatetime);
                }
                if (item.incidentId !== null) {
                    for (var incident in this._IncidentDisasterList) {
                        if (this._IncidentDisasterList[incident].incidentId === item.incidentId) {
                            this.incidentNameSelect.set('value', item.incidentId);
                            this.incidentTimestamp.set('value', item.incidentTimestamp);
                            // 大阪市災害名が紐づいていた場合、設定する
                            if (item.incidentDisasterId !== null) {
                                this.incidentDisasterNameSelect.set('value', item.incidentId);
                            }
                            // ラジオボタンの選択状態を設定する
                            if (item.incidentStatus === '1') {
                                this.incidentDisasterStatus.set('value', '1');
                            }
                            else if (item.incidentStatus === '2') {
                                this.incidentSelectStatus.set('value', '2');
                            }
                            this.onChangeSelect();
                            break;
                        }
                    }
                }
            }));
        },

        /**
         * 更新する。
         */
        onSubmit: function() {
            try {
                console.debug('[更新]ボタンがクリックされました。');

                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                // 固有のバリデーションを実施
                if (!this.validate()) {
                    return false;
                }

                var value = this.form.get('value');

                value.screenId = this._screenId;
                value.damageTypeList = this._damageTypeList;

                // 作成年月日
                value.entryDatetime =  value.disasterGroupNo.substr(0, 8);

                // 災害グループID
                value.disasterGroupNo = value.disasterGroupNo.substr(9);

                // フォームの不要な項目を削除
                if (value.incidentStatus === '1' ){
                    delete value.incidentDisasterNameSelect;
                } else if (value.incidentStatus === '2') {
                    delete value.incidentNameSelect;
                }
                
                // 水道局災害を選択した場合、フォームに項目を追加する
                if (this.displayType.value === 13 || this.displayType.value === 14) {
                    var incidentStatusValue = this.form.get('value').incidentStatus;
                    var incidentValue;

                    if (incidentStatusValue === '1') {
                        if(!this.incidentDisasterNameSelect.value){
                            InfoDialog.show('入力チェック', '大阪市災害名が選択されていません');
                            return false;
                        }
                        incidentValue = this.incidentDisasterNameSelect.value;
                    }
                    else if (incidentStatusValue === '2') {
                        if(!this.incidentNameSelect.value){
                            InfoDialog.show('入力チェック', '水道局災害名が選択されていません');
                            return false;
                        }
                        incidentValue = this.incidentNameSelect.value;
                    }
                    for (var incident in this._IncidentDisasterList) {
                        if (incidentValue === this._IncidentDisasterList[incident].incidentId) {
                            value.incidentId = this._IncidentDisasterList[incident].incidentId;

                            if (this._IncidentDisasterList[incident].disasterId !== 0) {
                                value.incidentDisasterId = this._IncidentDisasterList[incident].disasterId;
                            }
                            break;
                        }
                    }
                    
                }
                this.emit('update', { value: value });
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * ダイアログを初期化する。（更新時）
         */
        initDialog: function(object) {
            this.reset();
            this.initForm(object);
            this.screenId.innerHTML = this._screenId;
            this.initSearchSelector(object.displayType);
            this.own(topic.subscribe(this.DAMAGE_GRID_TYPE_SELECTED, lang.hitch(this, function (data) {
                this.setDamageType(data);
            })));

            this.own(topic.subscribe(this.DAMAGE_GRID_TYPE_RELEASED, lang.hitch(this, function (data) {
                this.releaseDamageType(data);
            })));

            //大阪市災害名セレクタに変更イベントを設定
            this.incidentDisasterNameSelect.on('change', lang.hitch(this, function (evt) {
                this.onSeqIncidentDisasterChange(evt);
            }));

            //水道局災害名セレクタに変更イベントを設定
            this.incidentNameSelect.on('change', lang.hitch(this, function (evt) {
                this.onSeqIncidentDisasterChange(evt);
            }));
        },

        /**
         * 災害選択が変更された場合の挙動
         */
        onDisasterChanged: function(disasterId) {
            if (disasterId.value) {
                domStyle.set(this.disasterTimeStampArea, 'display', '');
                var promise = Requester.get('/api/disasters/' + disasterId.value);
                Loader.wait(promise).then(lang.hitch(this, function (data) {
                    if (data.startTimestamp !== null && data.endTimestamp !== null) {
                        this.disasterTimeStamplabel.innerText = 
                        DateUtils.format(data.startTimestamp) + ' ～ ' + DateUtils.format(data.endTimestamp);
                    }else if (data.startTimestamp !== null && data.endTimestamp === null) {
                        this.disasterTimeStamplabel.innerText = 
                        DateUtils.format(data.startTimestamp) + ' ～ 現在発生中';
                    }
                }));
            } else {
                domStyle.set(this.disasterTimeStampArea, 'display', 'none');
            }
        },

        /** 初期表示：検索条件の表示・非表示を管理する*/
        initSearchSelector: function(displayType){
            this.resetDisplaySearch();
            if (displayType === 1){
                domStyle.set(this.earthQuakeArea, 'display', '');
            } else if (displayType === 2) {
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
            } else if (displayType === 3) {
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
            } else if (displayType === 4) {
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
            } else if (displayType === 5) {
                document.getElementById('organLabel').innerText = '発信所属：';
                domStyle.set(this.organizationArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.notifyTypeArea, 'display', '');
                domStyle.set(this.notifyDistArea, 'display', '');
            } else if (displayType === 6) {
                domStyle.set(this.organizationArea, 'display', '');
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
            } else if (displayType === 7) {
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.lifelineArea, 'display', '');
            } else if (displayType === 8) {
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.hldStatusArea, 'display', '');
                domStyle.set(this.severityArea, 'display', '');
                domStyle.set(this.reportedStatusArea, 'display', '');
                domStyle.set(this.damageTypeArea, 'display', '');
            } else if (displayType === 9) {
                domStyle.set(this.organizationArea, 'display', '');
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.hldStatusArea, 'display', '');
                domStyle.set(this.severityArea, 'display', '');
                domStyle.set(this.reportedStatusArea, 'display', '');
            } else if (displayType === 10 || displayType === 11) {
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.congestionArea, 'display', '');
            } else if (displayType === 12) {
                // domStyle.set(this.disasterArea, 'display', '');
                // domStyle.set(this.startTimestampArea, 'display', '');
                // domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.riverLevelArea, 'display', '');
                domStyle.set(this.riverTimestampArea, 'display', '');
            } else if (displayType === 13 || displayType === 14) {
                domStyle.set(this.incidentSelectArea,  'display', '');
            } else if (displayType === 15) {
                domStyle.set(this.disasterGroupListArea, 'display', '');
                domStyle.set(this.updateDatetimeArea, 'display', '');
            }
        },

        resetDisplaySearch: function(){
            document.getElementById('organLabel').innerText = '組織選択：';
            domStyle.set(this.organizationArea, 'display', 'none');
            domStyle.set(this.municipalityArea, 'display', 'none');
            domStyle.set(this.disasterArea, 'display', 'none');
            domStyle.set(this.startTimestampArea, 'display', 'none');
            domStyle.set(this.endTimestampArea, 'display', 'none');
            domStyle.set(this.congestionArea, 'display', 'none');
            domStyle.set(this.hldStatusArea, 'display', 'none');
            domStyle.set(this.severityArea, 'display', 'none');
            domStyle.set(this.lifelineArea, 'display', 'none');
            domStyle.set(this.reportedStatusArea, 'display', 'none');
            domStyle.set(this.notifyTypeArea, 'display', 'none');
            domStyle.set(this.damageTypeArea, 'display', 'none');
            domStyle.set(this.notifyDistArea, 'display', 'none');
            domStyle.set(this.riverLevelArea, 'display', 'none');
            domStyle.set(this.earthQuakeArea, 'display', 'none');
            domStyle.set(this.riverTimestampArea, 'display', 'none');
            domStyle.set(this.disasterGroupListArea, 'display', 'none');
            domStyle.set(this.updateDatetimeArea, 'display', 'none');
            domStyle.set(this.incidentSelectArea,  'display', 'none');
        },

        // ディスプレイ種別が変更になる場合に呼ぶ
        onDisplayTypeChanged: function(){
            this.resetDisplaySearch();
            console.log(this.displayType.value);
            if (this.displayType.value === 1){
                domStyle.set(this.earthQuakeArea, 'display', '');
            } else if (this.displayType.value === 2) {
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
            } else if (this.displayType.value === 3) {
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
            } else if (this.displayType.value === 4) {
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
            } else if (this.displayType.value === 5) {
                document.getElementById('organLabel').innerText = '発信所属：';
                domStyle.set(this.organizationArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.notifyTypeArea, 'display', '');
                domStyle.set(this.notifyDistArea, 'display', '');
            } else if (this.displayType.value === 6) {
                domStyle.set(this.organizationArea, 'display', '');
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
            } else if (this.displayType.value === 7) {
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.lifelineArea, 'display', '');
            } else if (this.displayType.value === 8) {
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.hldStatusArea, 'display', '');
                domStyle.set(this.severityArea, 'display', '');
                domStyle.set(this.reportedStatusArea, 'display', '');
                domStyle.set(this.damageTypeArea, 'display', '');
            } else if (this.displayType.value === 9) {
                domStyle.set(this.organizationArea, 'display', '');
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.hldStatusArea, 'display', '');
                domStyle.set(this.severityArea, 'display', '');
                domStyle.set(this.reportedStatusArea, 'display', '');
            } else if (this.displayType.value === 10 || this.displayType.value === 11) {
                domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.municipalityArea, 'display', '');
                domStyle.set(this.startTimestampArea, 'display', '');
                domStyle.set(this.endTimestampArea, 'display', '');
                domStyle.set(this.congestionArea, 'display', '');
            } else if (this.displayType.value === 12) {
                // domStyle.set(this.disasterArea, 'display', '');
                domStyle.set(this.riverLevelArea, 'display', '');
                domStyle.set(this.riverTimestampArea, 'display', '');
                // domStyle.set(this.startTimestampArea, 'display', '');
                // domStyle.set(this.endTimestampArea, 'display', '');
            } else if (this.displayType.value === 13 || this.displayType.value === 14) {
                domStyle.set(this.incidentSelectArea,  'display', '');
            } else if (this.displayType.value === 15) {
                domStyle.set(this.disasterGroupListArea, 'display', '');
                domStyle.set(this.updateDatetimeArea, 'display', '');
            }
        },

        /**
         * 直近の10分刻み時刻に丸める
         * 例えば、10時03分の場合は10時00分、10時13分の場合は10時10分に丸められる
         * @param {Date}
         * @returns {Date} 丸めた日時
         */         
        roundTime: function(dt) {
            // 分を取得し、10で割り切れなければ、そのあまりでminuteをマイナスする
            var minutes = dt.getMinutes();
            var mod = minutes % 10;
            if (mod !== 0) {
                return date.add(dt, 'minute', -mod);
            }
            return dt;
        },

        /**
         * 日付のセレクトボックスを生成する
         */
         setDateSelect: function() {
        	// 最新日時から10日前までの日付を算出し、セレクトボックスを生成
            var currentDate = this.latestDateTime;
            var counter = 0;
            this._dateOptions = [];
			this._dateOptions.push({ value: '', label: '　' });
            // 選択値。最後に追加されたアクティブなオプション時刻がデフォルト選択値。
            var defaultDate = null;

            // 1年前まで繰り返す
            while (counter < 366) {
            	// セレクトボックスの値
                var dateValue = locale.format(currentDate, {
                	selector: 'date',
                	datePattern: 'yyyy/MM/dd'
                });
                // セレクトボックスのラベル 「yyyy年MM月dd日」
                var dateLabel = DateFormatter.jpDate(currentDate, true);
				this._dateOptions.push({ value: dateValue, label: dateLabel });
                defaultDate = dateValue;

                // 1日前に更新
                currentDate = date.add(currentDate, 'day', -1);
                // カウンターを増やす
            	counter++;
            }
            // セレクトボックスを設定
            this.dateSelect.set('options', this._dateOptions);
            this.dateSelect.startup();

            // デフォルト値の設定
        	this.timeSelect.set('value', defaultDate);
        },

        /**
         * 時間のセレクトボックスを生成する
         * @param {Date} 最新時刻。指定されていればその時間以降の選択をdisabledにする。
         */
        setTimeSelect: function() {

        	// ループの開始時刻（使いたいのは時刻なので日付は何でも良い。0時0分）
        	var currentDate = new Date(1970, 1, 1, 0, 0);
        	var counter = 0;
        	this._timeOptions = [];
			this._timeOptions.push({ value: '', label: '　', disabled: false });
        	// 選択値。最後に追加されたアクティブなオプション時刻がデフォルト選択値。
        	// var defaultTime = null;
        	// 00時00分から23時50分まで生成するので、ループは144回
        	while (counter < 144) {
        		// セレクトボックスの値
        		var timeValue = locale.format(currentDate, {
        			selector: 'time',
        			timePattern: 'HH:mm'
        		});
        		// セレクトボックスのラベル
        		var timeLabel = locale.format(currentDate, {
        			selector: 'time',
        			// 24時間指定
        			timePattern: 'HH時mm分'
        		});
				this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: false });
				// defaultTime = timeValue;
        		/*if (!datetime) {
            		// 10分モードの場合
            		this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: false });
            		defaultTime = timeValue;
            		// }
        		} else {
        			// 最新観測日の場合は、時刻を越えている場合は選択不可
        			if (date.compare(datetime, currentDate, 'time') !== -1) {
        				this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: false });
        				defaultTime = timeValue;
        			} else {
        				this._timeOptions.push({ value: timeValue, label: timeLabel, disabled: true });
        			}
        		}*/

        		// 10分後に更新
        		currentDate = date.add(currentDate, 'minute', 10);
                // カウンターを増やす
            	counter++;
        	}

        	// セレクトボックスを設定
            this.timeSelect.set('options', this._timeOptions);
            this.timeSelect.startup();

            // デフォルト値の設定
        	// this.timeSelect.set('value', defaultTime);
        },

        /**
         * 災害選択ラジオボタンが変更された場合に呼ばれる
         */
        onChangeSelect: function() {
            var status = this.form.get('value').incidentStatus;
            // ラジオボタンが選択されていないセレクトボックスを無効化する
            if (status === '1') {
                this.incidentDisasterNameSelect.set('disabled', '');
                this.incidentNameSelect.set('disabled', 'true');
            } else if (status === '2') {
                this.incidentDisasterNameSelect.set('disabled', 'true');
                this.incidentNameSelect.set('disabled', '');
            }
        },

        /**
         * 災害選択セレクトボックスのデータを取得する
         */
        getIncidentDisasterList: function() {
            return Loader.wait(
                Requester.get('api/waterworks/incidentlist').then(
                    lang.hitch(this, function (data) {
                        if (data !== null && data !== undefined) {
                            for (var index = 0; index < data.items.length; index++) {
                                var item = data.items[index];
                                this._IncidentDisasterList[index] = { incidentId: item.incidentId, incidentName: item.incidentName,
                                                                        disasterId: item.disasterId, disasterName: item.disasterName};
                            }                            
                        }
                        return this._IncidentDisasterList;
                    })
                )
            );
        },

        /**
         * 水道局災害名セレクトボックスを生成する
         */
        initSetIncidentSelect: function(selector) {
            // セレクトボックスの先頭に空白を入れる
            var optionList = [
                {
                    label: '&nbsp;',
                    value: ''
                }
            ];
            for (var incident in this._IncidentDisasterList) {
                if (this._IncidentDisasterList.hasOwnProperty(incident)) {
                    var incidentName = this._IncidentDisasterList[incident].incidentName;
                    if (incidentName.length > 50) {
                        incidentName = incidentName.substr(0,50) + '...';
                    }
                    optionList.push({
                        value: this._IncidentDisasterList[incident].incidentId,
                        label: incidentName
                    });
                }
            }
            selector.set('options', optionList);
            // 初期選択を設定する
            if (optionList.length !== 0) {
                selector.set('value', optionList[0].value);
            }
        },

        /**
         * 大阪市災害名セレクトボックスを生成する
         */
        initSetIncidentDisasterSelect: function(selector) {
            // 災害ID順にソートする
            var disasterSortList = Array.from(this._IncidentDisasterList);
            // セレクトボックスの先頭に空白を入れる
            var optionList = [
                {
                    label: '&nbsp;',
                    value: ''
                }
            ];
            disasterSortList.sort((a,b) => b.disasterId-a.disasterId);
            for (var id in disasterSortList) {
                if (disasterSortList[id].disasterId !== 0) {
                    optionList.push({
                        value: disasterSortList[id].incidentId,
                        label: disasterSortList[id].disasterName
                    });
                }
            }
            selector.set('options', optionList);
            // 初期選択を設定する
            if (optionList.length !== 0) {
                selector.set('value', optionList[0].value);
            }
        },

        /**
         * 応急給水情報ディスプレイで災害選択プルダウンの選択を連動させる処理
         */
        onSeqIncidentDisasterChange: function (evt) {
            var status = this.form.get('value').incidentStatus;
            var pairFlg = false;
            // ラジオボタンが選択されていないセレクトボックスの値を自動で設定する。
            if (status === '1') {
                for (var incident in this._IncidentDisasterList) {
                    if (this._IncidentDisasterList[incident].incidentId === evt) {
                        this.incidentNameSelect.set('value', evt);
                        pairFlg = true;
                        break;
                    }
                }
                if(!pairFlg){
                    this.incidentNameSelect.set('value', '');
                }
            } else if (status === '2') {
                for (var incident in this._IncidentDisasterList) {
                    if (this._IncidentDisasterList[incident].incidentId === evt) {
                        this.incidentDisasterNameSelect.set('value', evt);
                        pairFlg = true;
                        break;
                    }
                }
                if(!pairFlg){
                    this.incidentDisasterNameSelect.set('value', '');
                }
            }
        }
    });
});
