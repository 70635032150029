/**
 * 招集グループ一覧グリッド
 * @module app/convocation/view/group/ConvoGroupListGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'dojo/_base/lang',
    'idis/control/Router',
    'idis/consts/ACL', 
    'idis/view/form/AclButton',
    'idis/view/grid/helper'
], function(module, declare, IdisGrid, lang, Router, ACL, AclButton,helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */

        collection: this.store,
        columns: [
                helper.buttonColumn('detail', '詳細'),
                helper.column('groupName', 'グループ名'),
                helper.column('count', '登録人数'),
                {field: 'autoDistFlg', label: '登録種別', sortable: false,
                formatter: lang.hitch(this, function(item){
                    if(item === '0') {
                        return '手動登録';
                    } else {
                        return '自動登録';
                    }
                })
            }
        ]
    });
});