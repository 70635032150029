/**
 * 大阪市管理施設選択用入力パーツ
 * @module idis/view/form/ShelterFacilitySelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/string',
    'dojo/when',
    'dijit/form/TextBox',
    'idis/view/dialog/InfoDialog',
    'idis/view/form/TreeSelector',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest'
], function (module, declare, lang, dStr, when, TextBox, InfoDialog, TreeSelector,
    CacheStoreModel, CacheTreeRest) {
    /**
     * 大阪市管理施設選択用入力パーツ
     * @class FacilityTypeSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:idis/view/tree/ShelterFacilitySelector~ShelterFacilitySelector# */ {
            // 選択ダイアログのタイトル
            title: '施設（大阪市管理施設）',

            // ツリー・モデル
            model: null,

            constructor: function () {
                this.model = new CacheStoreModel({
                    store: new CacheTreeRest({
                        target: '/api/damageReports/damageFacilities4Tree'
                    })
                });
            },

            startup: function () {
                this.inherited(arguments);
                this.dialog.hideOnOK = false;
            },

            // DOMを構築する
            buildRendering: function () {
                this.inherited(arguments);
                // 検索用入力要素を設置
                this.filterInput = new TextBox({
                    placeHolder: this.placeHolder,
                    maxLength: 128
                });
                this.filterInput.placeAt(this.treeControlNode);
                this.own(this.filterInput.on('keyup', lang.hitch(this, 'onFilterInputChange')));
            },

            _setValueAttr: function (value) {
                // 偽値は数値の0のみ有効とする
                if (value || value === 0) {
                    // 組織や区役所が選択されている場合は処理をSkip
                    if (value.substring(0,1) === 'D') {
                        // 組織や区役所が選択されている場合は処理をSkip
                        InfoDialog.show('エラー', '市の組織や区役所は選択できません。施設を選択してください。');
                        return false;
                    } 
                    this._set('value', value);
                    this.emit('change', { value: value });
                    this._initTree().then(lang.hitch(this, function () {
                        var model = this.tree.model;
                        var label;
                        if (this.fullName) {
                            label = model.getFullName(value);
                        } else {
                            label = when(model.store.get(value), function (item) {
                                return model.getLabel(item);
                            });
                        }
                        when(label, lang.hitch(this, function (name) {
                            this.status.innerHTML = name;
                        }));
                    }));    
                } else {
                    this._set('value', '');
                    this.emit('change', { value: '' });
                    this.status.innerHTML = this.emptyValueLabel;
                }
                // 要素の選択状態をリセットする
                this._initTree().then(lang.hitch(this, function () {
                    this.tree.set('selectedItem', null);
                }));
                this.dialog.hide();
            },

            /**
             * 検索用入力要素入力時。
             */
            onFilterInputChange: function () {
                // 入力値を取得
                var value = dStr.trim(this.filterInput.get('value'));
                if (value) {
                    // ラベル名が入力値を含むものでフィルターし、ツリーを展開する
                    this.model.setFilter(function (item, isLeaf, model) {
                        return isLeaf && (model.getLabel(item) || '').indexOf(value) !== -1;
                    }).then(lang.hitch(this, function () {
                        this.tree.expandAll();
                    }));
                } else {
                    // 入力が空の場合は全要素を表示してツリーを閉じる
                    this.model.setFilter().then(lang.hitch(this, function () {
                        this.tree.collapseAll();
                    }));
                }
            }
        });
});
