define([
    //以下レイヤーは設計書に基づいたレイヤーID
    {id: 40, name: '10分雨量', anti:[41,42,43], category: '観測情報'},
    {id: 41, name: '30分雨量', anti:[40,42,43], category: '観測情報'},
    {id: 42, name: '60分雨量', anti:[40,41,43], category: '観測情報'},
    {id: 43, name: '累加雨量', anti:[40,41,42], category: '観測情報'},
    {id: 44, name: '河川水位情報', category: '観測情報'},
    {id: 44, name: '危機管理型水位計情報', category: '観測情報'},
    {id: 47, name: '河川カメラ情報', category: '観測情報'},
    {id: 50, name: '気象警報・注意報', anti: [105], category: '防災気象情報'},
    {id: 51, name: '高解像度降水ナウキャスト', category: '防災気象情報'},
    {id: 52, name: '降水短時間予報', category: '防災気象情報'},
    {id: 53, name: '大雨警報（浸水害）危険度分布', category: '防災気象情報'},
    {id: 54, name: '洪水警報危険度分布', category: '防災気象情報'},
    {id: 55, name: '地震情報', category: '防災気象情報'},
    {id: 91, name: '長周期地震動に関する観測情報', com:[92], category: '防災気象情報'},
    {id: 56, name: '津波情報', category: '防災気象情報'},
    {id: 57, name: '台風解析・予報情報', category: '防災気象情報'},
    {id: 58, name: '解析積雪深・解析降雪量', category: '防災気象情報'},
    {id: 60, name: '避難所', category: '施設情報'},
    // {id: 30, name: '潮位情報', category: 'その他の観測情報'},
    // {id: 104, name: '想定最大規模', category: '洪水想定区域'},
    // {id: 163, name: 'ため池情報', category: '洪水想定区域'},
    // {id: 102, name: '津波浸水想定区域', category: '洪水想定区域'},
    // {id: 101, name: '高潮浸水想定区域', category: '洪水想定区域'},
    // {id: 150, name: '土石流', category: '土砂災害警戒区域'},
    // {id: 156, name: '地すべり', category: '土砂災害警戒区域'},
    // {id: 153, name: '急傾斜地', category: '土砂災害警戒区域'},
    // {id: 159, name: '山腹崩壊危険地区', category: '土砂災害警戒危険箇所'},
    // {id: 160, name: '崩壊土砂流出危険地区', category: '土砂災害警戒危険箇所'},
    // {id: 161, name: '地すべり危険地区', category: '土砂災害警戒危険箇所'},
    // {id: 30, name: '市内気象情報', category: 'その他の観測情報'},
    // {id: 44, name: '土砂災害危険度メッシュ', category: '気象情報'},
    // {id: 34, name: '噴火警報・注意報', category: '地震・津波・火山の情報'},
    // {id: 52, name: '土砂災害警戒情報', category: '気象情報'},
    // {id: 105, name: '避難指示等発令情報', anti: [31], category: '避難情報'},
    // {id: 50, name: 'ダム諸量情報', category: 'ダム情報'},
    // {id: 99, name: '現在の道路通行規制情報', category: '通行規制情報'},
    // {id: 98, name: '今後の通行規制情報', category: '通行規制情報'},
    // {id: 97, name: '道路カメラ情報', category: '道路カメラ'},
    // {id: 190, name: '避難所', category: '避難所'},
    // {id: 191, name: '宿泊施設', category: '宿泊施設'}
]);
