/**
 * <河川水位情報情報一覧画面>
 *
 * @module app/river/grid/RiverLevelObservationPageGrid
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'app/view/grid/CommonColumn',
    './RiverLevelObservationCommonColumns'
    // 以下、変数で受けないモジュール
], function (module, declare, lang, IdisGrid, helper, CommonColumn, RiverCommonColumns) {
    /**
     * 河川水位情報情報一覧画面用Grid
     *
     * @class RiverLevelObservationPageGrid
     * @extends module:idis/view/grid/IdisGrid~IdisGrid
     */
    return declare(
        module.id.replace(/\//g, '.'),
        IdisGrid,
        /** @lends module:app/river/grid/RiverLevelObservationPageGrid~RiverLevelObservationPageGrid# */ {
            columns: [
                // helper.column('regionName', '地域'),
                CommonColumn.optionColumn('riverName', '河川名'),
                // 観測局
                CommonColumn.observatoryName,
                // 水系
                CommonColumn.optionColumn('riverSysName', '水系'),
                // 管理区分
                CommonColumn.managerCd,

                helper.buttonColumn('detail', '詳細'),
                helper.column('excess', '基準値\n超過', {
                    className: function (item) {
                        // ヘッダーの場合はclassNameを返さない
                        if (!item) {
                            return;
                        }
                        if (item.riverLevelFlg === '0') {
                            return {
                                1: 'is-wait',
                                2: 'is-caution',
                                3: 'is-judgment',
                                4: 'is-crisis'
                            }[item.excess];
                        } else {
                            return {
                                1: 'is-missing',
                                2: 'is-notYet',
                                3: 'is-closed'
                            }[item.riverLevelFlg];
                        }
                    },
                    formatter: function (value) {
                        return (
                            {
                                0: '-', // 非超過
                                1: '(注)',
                                2: '(Lv3)',
                                3: '(Lv4)',
                                4: '(Lv5)'
                            }[value] || '-'
                        ); // 非有効値
                    }
                }),
                RiverCommonColumns.riverLevel,
                RiverCommonColumns.difference,
                helper.column('riverLeve', '予測水位', {
                    sortable: false,
                    children: [
                        RiverCommonColumns.riverLevelIn1hour,
                        RiverCommonColumns.riverLevelIn2hour,
                        RiverCommonColumns.riverLevelIn3hour
                    ]
                }),
                helper.column('limitLevel', '基準値', {
                    sortable: false,
                    children: [
                        CommonColumn.threshold('riverLevelStandby', '氾濫注意\n[m]', 2),
                        CommonColumn.threshold('riverLevelCaution', '警戒レベル3発令準備水位\n[m]', 2),
                        CommonColumn.threshold('riverLevelEvacuate', '警戒レベル4発令水位\n[m]', 2),
                        CommonColumn.threshold('riverLevelDanger', '警戒レベル5発令水位\n[m]', 2)
                    ]
                })
            ],
            changeMode: function (mode) {
                RiverCommonColumns.changeColumnsLabel(mode);
                this.renderHeader();
            }
        }
    );
});
