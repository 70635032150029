/**
 * 避難状況・報告概況画面用モジュール。
 * @module app/view/page/EvacOrderAdminPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-style',
    'idis/control/Router',
    'idis/control/Locator',
    'dojo/text!./templates/EvacOrderAdminPage.html',
    'idis/view/page/_PageBase',
    'idis/store/IdisRest',
    'idis/consts/ACL',
    'idis/view/form/AclButton',
    'idis/view/grid/IdisGrid',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    '../config',
    'app/model/DisasterInfo',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'app/evacorder/EvacOrderFormDialog',
    'app/evacorder/EvacOrderAdminGrid'
], function(module, array, declare, lang, domClass, domStyle, Router, Locator, template, _PageBase,
	IdisRest, ACL, AclButton, IdisGrid, UserInfo, USER_TYPE, config, DisasterInfo) {
    /**
     * 避難状況・報告概況画面
     * @class EvacOrderAdminPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/view/page/EvacOrderAdminPage~EvacOrderAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--evacorder',

        _disasterId:null,

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.formDialog);
        },

        constructor: function() {
            this.store = new IdisRest({
                idProperty: 'evacOrderId',
                target: '/api/evacorders/overview',
                sortParam: 'sort',
                rangeStartParam: 'offset',
                rangeCountParam: 'limit'
            });
        },

        /**
         * グリッドを初期化する。
         * @function module:app/view/page/EvacOrderAdminPage~EvacOrderAdminPage#~startup
         */
        startup: function() {
            this.inherited(arguments);

            // 災害IDを取得する。
            this._disasterId = DisasterInfo.getDisasterId();

            // グリッドを初期表示
            this.initGrid();

            // 画面自動更新用のタイマーを設定
            this.onChangeAutoUpdateGrid();

            // 登録ボタンの活性/非活性を初期化
            this.setButtonStatus();

            // 閲覧モードフラグをセット
            this._viewMode = Locator.getQuery().viewMode || '0';

            // 閲覧モードの場合、避難情報発令ボタンを非表示
            if(this._viewMode === '1'){
                domStyle.set(this.registerButtonArea, 'display', 'none');
            }
        },

        /**
         * widgetが居なくなる時に呼ばれる
         * 定期処理を止める
         */
        destroy: function () {
            this.inherited(arguments);
            clearInterval(this.timer);
        },

        /**
         * 画面自動更新のON/OFF制御
         */
        onChangeAutoUpdateGrid: function () {
            // いったん自動更新OFFにリセット
            clearInterval(this.timer);
    
            if(this.isAutoUpdate.checked){
                // 自動更新ONの場合、画面自動更新用のタイマーを設定(5分毎)
                this.timer = setInterval(lang.hitch(this, function () {
                    this.updateGridQuery(this.form.get('value'));
                }), 5 * 60 * 1000);
            }
        },

        // ユーザー権限が「部局ユーザ」「その他ユーザ」の場合は新規登録ボタンを非活性に
        setButtonStatus: function(){
            var role = UserInfo.getRoleCd();
            if (role === ACL.DEPT_USER || role === ACL.OTHER_USER) {
                domStyle.set(this.correctButtonArea, 'display', 'none');
            }
        },

        /**
         * グリッドの初期化処理。
         * @function module:app/view/page/EvacOrderAdminPage~EvacOrderAdminPage#~initGrid
         */
        initGrid: function() {
            this.grid.on('listButtonClick', lang.hitch(this, function(evt) {
                this.onListButtonClick(evt.item);
            }));
            var filter = new this.store.Filter();
            filter = filter.eq('disasterId', this._disasterId);

            // if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY &&
            //       UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd){
            //     // 政令指定都市ユーザだった場合、デフォルト表示は市内各区とする
            //     filter = filter.eq('municipalityCd', config.municInfo.cityMunicCd);
            // } else if (UserInfo.getUserType() === USER_TYPE.REGION){
            //     // 振興局ユーザだった場合、デフォルト表示は市内各区とする
            //     filter = filter.eq('regionCd', UserInfo.getRegionCd());
            // }

            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);

            // this.setGridColumn(collection);

        },

        /**
         * 新規登録ボタンを押下したときの挙動
         */
        onRegisterButtonClick: function() {
            // 避難情報新規登録画面へ遷移
            Router.moveTo('evacorder/register');
        },

        /**追記
         * 避難情報概況を取得する。
         * @function module:app/view/page/EvacOrderAdminPage~EvacOrderAdminPage#~startup
         */
        onSubmit: function() {
            try {
                if (this.form.validate()) {
                // 入力値が正常ならグリッドの検索条件を更新
                this.updateGridQuery(this.form.get('value'));
                console.debug(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**追記
        * グリッドの検索条件を指定された値で更新する。
        * @param {Object} value name属性と値のマッピング
        */
        updateGridQuery: function(value){
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();

            // 災害IDはコンストラクタで存在チェックをしているため常にfilterにセット
            filter = filter.eq('disasterId', this._disasterId);

            if (value.evacOrderTimestamp) {
                filter = filter.eq('evacOrderTimestamp', value.evacOrderTimestamp.getTime());
            }

            if (value.displayAllFlg && value.displayAllFlg.length !== 0) {
                filter = filter.eq('displayAllFlg', true);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);

            // this.setGridColumn(collection);

        },

        /* setGridColumn: function(collection) {
            collection.fetch().then(lang.hitch(this, function(data){
                // 高齢者等避難情報
                var evacPrepareDistNum= 0;
                var evacPrepareEvaqueeDistNum= 0;
                var evacPrepareHouseholdDistNum = 0;
                // 避難指示
                var evacOrderDistNum= 0;
                var evacOrderEvaqueeDistNum= 0;
                var evacOrderHouseholdDistNum = 0;
                // 緊急安全確保
                var disasterOccurDistNum= 0;
                var disasterOccurEvaqueeDistNum= 0;
                var disasterOccurHouseholdDistNum = 0;
                array.forEach(data, function(d){
                    evacPrepareDistNum += d.evacPrepareDistNum;
                    evacPrepareEvaqueeDistNum += d.evacPrepareEvaqueeDistNum;
                    evacPrepareHouseholdDistNum += d.evacPrepareHouseholdDistNum;
                    evacOrderDistNum += d.evacOrderDistNum;
                    evacOrderEvaqueeDistNum += d.evacOrderEvaqueeDistNum;
                    evacOrderHouseholdDistNum += d.evacOrderHouseholdDistNum;
                    disasterOccurDistNum += d.disasterOccurDistNum;
                    disasterOccurEvaqueeDistNum += d.disasterOccurEvaqueeDistNum;
                    disasterOccurHouseholdDistNum += d.disasterOccurHouseholdDistNum;
                });
            }));
        }, */

        /**
         * 概況情報をエクセル形式で出力します。
         */
        onOutputButtonClick: function() {
            console.log('xoBlosサービスをコールします。');
            this.formDialog.show();
        },

        /**
         * 一覧ボタンクリック時の処理。
         * @function module:app/view/page/EvacOrderAdminPage~EvacOrderAdminPage#~onListButtonClick
         */
        onListButtonClick: function(item) {
            // 避難状況・報告管理画面へ遷移
            Router.moveTo('evacorder', {
                municipalityCd: item.municipalityCd,
                viewMode : this._viewMode
            });
        }
    });
});
