/**
 * お知らせ一覧画面用モジュール。
 * @module app/bbs/BbsAdminPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/control/Router',
    'dojo/text!./templates/BbsAdminPage.html',
    'idis/view/page/_PageBase',
    'dojo/dom',
    'dojo/dom-style',
    'dijit/registry',
    'dstore/Memory',
    'dstore/Rest',
    'idis/consts/ACL',
    'idis/model/UserInfo',
    'app/model/DisasterInfo',
    'idis/store/IdisRest',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/view/form/AclButton',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/util/FilesUtils',
    'dojo/dom-construct',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'app/view/form/MunicipalitySelector',
    './BbsAdminGrid',
    './BbsRegisterDialog',
    './BbsDetailDialog',
    './CommentDetailDialog',
    '../common/view/StatementDialog',
    '../view/form/OrganizationSelector'
], function(module, declare, lang, Router, template, _PageBase, dom, domStyle, registry,
    Memory, Rest, ACL, UserInfo, DisasterInfo, IdisRest, IdisGrid, helper,
    AclButton, DialogChain, Loader, FilesUtils, domConstruct) {

    /**
    * 検索条件格納用オブジェクト
    * @type {Object}
    */
    var bbsFilterStore = {};

    /**
     * お知らせ情報管理画面
     *  BbsAdminPage
     *  module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/bbs/BbsAdminPage~BbsAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--bbs',

        /**
        * データ格納用オブジェクト
        * @type {module:dstore/Store}
        */
        store: null,

        /**
         * データ登録用オブジェクト
         * @type {module:dstore/Store}
         */
        registerStore: null,

        /**
         * データ更新用オブジェクト
         * @type {module:dstore/Store}
         */
        updateStore: null,

        /**
         * データ削除用オブジェクト
         * @type {module:dstore/Store}
         */
        deleteStore: null,

        /**
         * 災害ID
         * @private
         */
        _disasterId: null,

        /**
         * @type {Object}
         */
        timer: null,

        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'messageId',
                target: '/api/bbses',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            // データ登録用オブジェクト
            this.registerStore = new IdisRest({
                idProperty: 'messageId',
                target: '/api/bbses/register'
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);

            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定させていません。');
            }

            console.debug('現在のログインユーザID：' + UserInfo.getId());
            console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.registerDialog);
            this.own(this.detailDialog);
            this.own(this.commentDetailDialog);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);
            // グリッドを初期化する
            this.initGrid();
            this.initRegisterPage();
            this.initDetailPage();
            this.initCommentDetailPage();
            this.setButtonStatus();
            //お知らせ確認画面の右上の閉じるボタンを非表示にする
            domStyle.set(this.commentDetailDialog.closeButtonNode, 'display', 'none');
        },

        /**
         * widgetが居なくなる時に呼ばれる
         * 定期処理を止める
         */
        destroy: function() {
            this.inherited(arguments);
            clearInterval(this.timer);
        },

        // ユーザー権限が「閲覧ユーザ」の場合は新規登録ボタンを非活性に
        setButtonStatus: function(){
            var role = UserInfo.getRoleCd();
            if (role === ACL.VIEW_USER) {
                this.registerButton.setDisabled(true);
            }
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.bbsAdminGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showDetailPage(evt.item);
            }));

            // 公開範囲の更新
            // this.grid.on('changeStatusSubmit', lang.hitch(this, function(e) {
            //     var pubStatus = registry.byNode(query('.dgirdUpdPubStatus')[0].children[0]).get('value');
            //     this.updPubStatus(e.item, pubStatus);
            // }));


            this.bbsAdminGrid.on('commentButtonClick', lang.hitch(this, function(evt) {
                this.showCommentDetailPage(evt.item);
            }));

            this.bbsAdminGrid.on('fileDownloadButtonClick', lang.hitch(this, function(evt) {
                this.onFileDownload(evt.item);
            }));

            this.setFilterData();
            this.updateGridQuery();
        },

        /**
         * グリットを更新する。
         */
        updateGridQuery: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            var value = this.form.get('value');

            // 災害ID
            filter = filter.eq('disasterId', this._disasterId);

            // 発信日時FROM
            if (value.registerTimestampFrom) {
                var registerDateTimeFrom = new Date(value.registerTimestampFrom).getTime();
                filter = filter.eq('registerTimestampFrom', registerDateTimeFrom);
            }

            // 発信日時TO
            // if (value.registerTimestampTo) {
            //     var registerDateTimeTo = new Date(value.registerTimestampTo).getTime();
            //     filter = filter.eq('registerTimestampTo', registerDateTimeTo);
            // }

            // ログインユーザの組織コードを取得する
            var orgCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
            UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
            UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');


            filter = filter.eq('destinationId', orgCd);

            var collection = this.store.filter(filter);

            this.bbsAdminGrid.set('collection', collection);

            this.bbsAdminGrid.on('attachFileLinkClick', lang.hitch(this, function(evt) {
                var attachFilePath = evt.item.attachFilePath.replace('out', 'data');
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + attachFilePath;
                this.downloadFile(url, evt.item.attachFileName);
            }));

            //定期更新処理
            this.timer = setTimeout(lang.hitch(this, function() {
                this.updateGridQuery();
            }),600*1000);
        },

        /**
         * 検索条件を保管する
         */
        setFilterStore: function() {
            //初期化する
            bbsFilterStore = {};
            var value = this.form.get('value');

            // 発信日時FROM
            if (value.registerTimestampFrom) {
                bbsFilterStore.registerTimestampFrom = value.registerTimestampFrom;
            }

            // 発信日時TO
            // if (value.registerTimestampTo) {
            //     bbsFilterStore.registerTimestampTo = value.registerTimestampTo;
            // }
        },

        /**
         * 保管した検索条件をformにセットする
         */
        setFilterData: function() {

            // 発信日時FROM
            if(bbsFilterStore.registerTimestampFrom) {
                this.registerTimestampFrom.set('value', bbsFilterStore.registerTimestampFrom);
            }

            // 発信日時TO
            // if(bbsFilterStore.registerTimestampTo) {
            //     this.registerTimestampTo.set('value', bbsFilterStore.registerTimestampTo);
            // }
        },

        /**
        * お知らせ情報を検索する。
        */
        onSubmit: function() {
            console.debug('[検索]ボタンがクリックされました。');
            try {
                if (this.form.isValid()) {
                    this.setFilterStore();
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                } else {
                    console.debug('エラーあり');
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 新規登録ダイアログを表示する。
         * テンプレートHTMLでdata-dojo-attach-eventプロパティーを用い、
         * 新規登録ボタンのクリックイベントにこのメソッドを紐付けている。
         */
        showRegisterDialog: function() {
            // 検索条件を保管する。
            // this.saveFilterData();
            this.innerDialog.clearPreview();
            this.innerDialog.initDialog();
            // this.innerDialog.initForum();
            this.registerDialog.show();
        },

        /**
         * 検索条件を保管する。
         */
        saveFilterData: function() {
            bbsFilterStore = this.form.get('value') || {};
        },

        /**
         * 新規登録ダイアログを初期化する。
         */
        initRegisterPage: function() {
            // 登録ダイアログの最初の子要素が登録画面
            var dialog = this.registerDialog;
            var page = dialog.getChildren()[0];

            // 登録ダイアログのsendイベントを受け取る
            page.on('send', lang.hitch(this, function(evt) {
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    var sendData = evt.value;

                    // 登録処理と共にローディング表示
                    Loader.wait(this.registerStore.add(sendData)).then(lang.hitch(this, function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            this.updateGridQuery(this.form.get('value'));
                        }));
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 詳細ダイアログを初期化する。
         */
        initDetailPage: function() {
            var dialog = this.detailDialog;
            var page = dialog.getChildren()[0];

            // 詳細ダイアログのupdateSendイベントを受け取る
            page.on('update', lang.hitch(this, function(evt) {
                this.chain.confirmPut(function(chain) {
                    // OKが押された場合

                    // データ更新用オブジェクト
                    this.updateStore = new IdisRest({
                        idProperty: 'messageId',
                        target: '/api/bbses/update'
                    });
                    // 更新処理と共にローディング表示
                    Loader.wait(this.updateStore.put(evt.value)).then(lang.hitch(this, function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            this.updateGridQuery(this.form.get('value'));
                        }));
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));

            // 詳細ダイアログのremoveイベントを受け取る
            page.on('remove', lang.hitch(this, function(evt) {
                this.chain.confirmDel(function(chain) {
                    // OKが押された場合

                    // データ削除用オブジェクト
                    this.deleteStore = new IdisRest({
                        target: '/api/bbses/delete'
                    });
                    // 削除処理と共にローディング表示
                    Loader.wait(this.deleteStore.remove(evt.value.messageId)).then(lang.hitch(this, function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            this.updateGridQuery(this.form.get('value'));
                        }));
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    }));
                });
            }));
        },

        /**
         * 掲示板ダイアログを初期化する。
         */
        initCommentDetailPage: function() {
            var dialog = this.commentDetailDialog;
            var page = dialog.getChildren()[0];

            // 登録ダイアログのsendイベントを受け取る
            page.on('send', lang.hitch(this, function(evt) {
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    var sendData = evt.value;

                    // 登録処理と共にローディング表示
                    Loader.wait(this.registerStore.add(sendData)).then(lang.hitch(this, function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            this.updateGridQuery(this.form.get('value'));
                        }));
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    }));
                });
            }));

            // 詳細ダイアログのupdateSendイベントを受け取る
            page.on('update', lang.hitch(this, function(evt) {
                this.chain.confirmPut(lang.hitch(this, function(chain) {
                    // OKが押された場合

                    // データ更新用オブジェクト
                    this.updateStore = new IdisRest({
                        idProperty: 'messageId',
                        target: '/api/bbses/update'
                    });
                    // 更新処理と共にローディング表示
                    Loader.wait(this.updateStore.put(evt.value)).then(function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            this.updateGridQuery(this.form.get('value'));
                        }));
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                }));
            }));

            // 詳細ダイアログのremoveイベントを受け取る
            page.on('remove', lang.hitch(this, function(evt) {
                this.chain.confirmDel(lang.hitch(this, function(chain) {
                    // OKが押された場合

                    // データ削除用オブジェクト
                    this.deleteStore = new IdisRest({
                        target: '/api/bbses/delete'
                    });
                    // 削除処理と共にローディング表示
                    Loader.wait(this.deleteStore.remove(evt.value.messageId)).then(function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            this.updateGridQuery(this.form.get('value'));
                        }));
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                }));
            }));

            page.on('cancel', lang.hitch(this, function() {
                this.updateGridQuery(this.form.get('value'));
            }));
        },

        /**
         * 詳細画面へ遷移する。
         */
        showDetailPage: function(item) {
            // 検索条件を保管する。
            // this.saveFilterData();
            // ダイアログの最初の子要素が詳細画面
            var page = this.detailDialog.getChildren()[0];

            // 画面上のフォームをリセット
            page.form.reset();
            page.clearPreview();

            // 添付ファイルをプレビューに反映
            this.innerDetailDialog.displayAttachFile(item.attachFile);

            // 内容をフォームに反映
            var value = lang.mixin(null, item);
            delete value.attachFile;
            page.form.set('value', value);

            // 詳細ダイアログを表示
            this.innerDetailDialog.initDialog(item);
            this.detailDialog.show();
        },

        /**
         * 掲示板画面へ遷移する。
         */
        showCommentDetailPage: function(item) {
            // ストアからデータを取得し、詳細ダイアログを表示する
            var fil = this.storeMessage = new IdisRest({
                target: '/api/bbses/message/' + item.messageId
            });

            fil.fetch().then(lang.hitch(this, function(formItem){
                this.innerCommentDetailDialog.setContent(formItem);
            }));

            this.innerCommentDetailDialog.updateDialog(item);
            this.commentDetailDialog.show();
        },

        /**
         * 一覧画面から添付ファイルのダウンロードを行う。
         */
        onFileDownload: function(item) {

            item.attachFile.forEach(function(element){
                var uri = element.attachFilePath.replace('out/', 'data/');
                var fileName = element.attachFileName;
                var download = null;
                var userAgent = window.navigator.userAgent.toLowerCase();
                if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                    var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                    // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                    // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                    download = domConstruct.create('a', {
                        href: '#'
                    });
                    // クリックでファイル取得処理に入る
                    download.onclick = function() {
                        this.downloadFile(url, fileName);
                    };
                    download.onclick();
                } else {
                    // FF, Chromeの場合、download属性でファイルダウンロード
                    download = domConstruct.create('a', {
                        href: uri,
                        download: fileName
                    });
                    download.click();
                }
            });
        },

        /**
         * 添付ファイルをダウンロードする。
         */
        downloadFile: function(url, name) {
            // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
            // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function() {

                var arrayBuffer = this.response;

                var blob = new Blob([arrayBuffer], {
                    type: 'application/octet-stream'
                });

                // IE10+
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, name);
                }
            };
            xhr.send();
            return false;
        }

    });
});
