/**
 * 招集状況詳細グリッド
 * @module app/convocation/view/mail/ConvoStatusDetailGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'dgrid/Selector'
], function(module, declare, lang, IdisGrid, helper, Selector) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selector], {

        /**
         * 各列の定義
         * @type {Object[]}
         */

        collection: this.store,

        columns: [
            helper.buttonColumn('detail', '詳細'),
            {field: 'id', label: 'ID',
                formatter: lang.hitch(this, function(item){
                    return item;
                })
            },
            {field: 'groupName', label: '所属',
                formatter: lang.hitch(function(item){
                    if (!item) {
                        return '-';
                    }
                    return item;
                })
            },
            {field: 'count', label: '対象人数',
                formatter: lang.hitch(function(item){
                    if (!item) {
                        return '0';
                    }
                    return item;
                })
            },
            {field: 'answer', label: '回答数',
                formatter: lang.hitch(function(item){
                    if (!item) {
                        return '0';
                    }
                    return item;
                })
            }
        ]
    });
});
