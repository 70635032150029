/**
 * お知らせ一覧画面用モジュール
 * 
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'dojo/dom-construct',
    'dojo/on',
    'app/util/DateFormatter',
    'dojo/dom-construct',
    'idis/view/form/Button',
    'app/view/form/PubStatusSelector',
    'idis/model/UserInfo',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function(module, declare, lang, locale, IdisGrid, helper, 
    domConstruct, on, DateFormatter, domCon, Button , PubStatusSelector, UserInfo) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--bbs',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            // 掲示板ダイアログボタン
            helper.buttonColumn('comment', '掲示板画面を開く', '掲示板'),
            // 編集ダイアログボタン
            // helper.buttonColumn('detail', 'お知らせを編集する', '編集'),
            {field: 'detail', label:'お知らせを編集する', sortable: false,
            renderCell: function(item){
                var gridNode = this.grid.domNode;
                var button = new Button({
                    label: '編集',
                    onClick: function() {
                        on.emit(gridNode, 'detailButtonClick', {item: item});
                    }
                });
                var content = null;
                
                console.log(item);
                
                // 作成者、または、管理者
                if(item.crtUserId === UserInfo._userInfo.userId || UserInfo._userInfo.roleCd === 'R01001') {
                    content = button.domNode;
                } else {
                    var comment = domCon.create('div');
                    comment.textContent = '-';
                    content = comment;
                }
                return content;
            }},

            // レベル
            helper.column('messageType', 'レベル', {
                formatMap: {
                    '001': '一般（お知らせ）',
                    '002': '通知（お知らせ）',
                    '003': '注意（お知らせ）',
                    '004': '重要（お知らせ）',
                    '005': '緊急'
                },
                sortable: false
            }),
            // タイトル
            {field: 'title', label: 'タイトル', sortable: false},
            // 内容（本文）
            {field: 'contents', label: '内容', sortable: false,
                formatter: lang.hitch(this, function(item) {
                    // return item;
                    if (item !== null && item.length >= 30) {
                        return item.substr(0,30) + '...';
                    } else {
                        return item;
                    }
                })

            },
            // 発表日時
            {field: 'pubStartTimestamp', label: '発表日時',
                formatter: lang.hitch(this, function(item){
                    var res = DateFormatter.jpDateTime(new Date(item), true);
                    var now = DateFormatter.jpDateTime(new Date(), true);

                    if (res > now) {
                        // return DateFormatter.jpDateTime(new Date(item.replace(/-/g, '/')), true);
                        return res + ' (発表前)';
                    } else {
                        return res;
                    }
                })
            },
            // 添付ファイル
            {field: 'attachFiles', label: '添付ファイルダウンロード', sortable: false,
            renderCell: function(items) {
                var attachNode = domConstruct.create('span');
                var gridNode = this.grid.domNode;
                if(items.attachFile && items.attachFile.length !== 0) {
                    items.attachFile.forEach(function(item) {
                        var path = item.attachFilePath.replace('out', 'data');
                        var name = item.attachFileName;
                        var node = null;
                        var userAgent = window.navigator.userAgent.toLowerCase();
                        if(userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)){
                            // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                            // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                            node = domConstruct.create('a', {
                                innerHTML: name,
                                href: '#'
                            });
                            //クリックでファイル取得処理に入る
                            node.onclick = function() {
                                on.emit(gridNode, 'attachFileLinkClick', {item: item});
                            };
                        } else {
                            // 報告書リンクを作成
                            node = domConstruct.create('a', {
                                innerHTML: name,
                                href: path,
                                download: name
                            });
                        }
                        domConstruct.place(node, attachNode);
                        domConstruct.place('<br>', attachNode);
                    });
                    return attachNode;
                }
            }}
        ]
    });
});
