/**
 * 招集グループ一覧グリッド
 * @module app/convocation/view/employee/ConvoEmployeeListGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'dojo/_base/lang',
    'idis/control/Router',
    'idis/consts/ACL',
    'idis/view/form/AclButton',
    'idis/view/grid/helper'
], function(module, declare, IdisGrid, lang, Router, ACL, AclButton, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        /**
         * 各列の定義
         * @type {Object[]}
         */

        collection: this.store,
        columns: [
                  helper.buttonColumn('detail', '詳細'),
                  {label: '組織',
                   field: 'divisionCd',
                   children: [
                              helper.column('parentName', '組織カテゴリ', {sortable: false}),
                              helper.column('divisionName', '課', {sortable: false})
                   ],
                   sortable: false
                  },
                  helper.column('name', '氏名', {sortable: false}),
                  {field: 'phoneNum', label: '電話番号',
                      formatter: lang.hitch(this, function(item){
                          return item;
                      })
                  },
                  helper.column('emailAddress01', 'メールアドレス1', {sortable: false}),
                  helper.column('emailAddress02', 'メールアドレス2', {sortable: false}),
                  helper.column('note', '備考', {sortable: false})
              ]
    });
});
