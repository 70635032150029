/**
 * Leaflet.PolylineDecoratorの一部functionをoverrideするもの
 */
define([
    'leaflet'
], function(leaflet) {
    return leaflet.PolylineDecorator.extend({
        initialize: function initialize(paths, options) {
            leaflet.PolylineDecorator.prototype.initialize.call(this, paths, options);
        },

        /**
         * overrideするもの. Leaflet.PolylineDecoratorはここで
         * "地図の表示範囲に存在するもの"にfilterしているが, それをすると作図した矢印が
         * 地図の表示範囲から離れるとただの直線になってしまうので保存時に困る時がある.
         * overrideして"全ての場合"を戻すように変更している
         */
        _getPatternLayers: function _getPatternLayers(pattern) {
            var _this4 = this;
            return this._paths.map(function(path) {
                var directionPoints = _this4._getDirectionPoints(path, pattern);
                return leaflet.featureGroup(_this4._buildSymbols(path, pattern.symbolFactory, directionPoints));
            });
        }
    });
});
