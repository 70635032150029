/**
 * 旧）職員招集・メール配信詳細ダイアログ用モジュール。
 * @module app/convocation/view/history/ConvocationMailHistoryDetailDialog
 */
define(['module',
 'dojo/_base/declare',
 'dojo/_base/lang',
 'dojo/json',
 'dojo/Deferred',
 'dojo/dom-style',
 'dojo/text!./templates/ConvocationMailHistoryDetailDialog.html',
 'idis/store/IdisRest',
 'idis/view/Loader',
 'idis/control/Router',
 'idis/view/dialog/DialogChain',
 'idis/view/dialog/InfoDialog',
 'idis/view/page/_PageBase',
 'idis/util/DateUtils',
 // 以下、変数で受けないモジュール
 'dijit/Dialog',
 'dijit/form/CheckBox',
 'dijit/form/Form',
 'dijit/form/Select',
 'dijit/form/TextBox',
 'dijit/layout/BorderContainer',
 'dijit/layout/ContentPane',
 'idis/view/form/AclButton',
 'idis/view/form/Button'],
function(module, declare, lang, JSON, Deferred, domStyle, template, IdisRest, Loader, Router,
        DialogChain, InfoDialog, _PageBase, DateUtils) {
    /**
     * （旧）職員参集一覧画面
     *
     * @class ConvocationMailHistoryPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/convocation/view/mail/ConvocationMailHistoryDetailDialog~ConvocationMailHistoryDetailDialog# */
    {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--convocation',

        constructor: function() {
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        /**
         * DOMノードを生成するためのメソッド
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        initDialog: function(item) {
            // formにセット
            this.municipalityName.innerHTML = item.municipalityName;
            this.reportTimeStamp.innerHTML = item.reportTimeStamp ? DateUtils.format(item.reportTimeStamp) : '-';
            this.reporterName.innerHTML = item.reporterName;
            var mobilizationType = null;
            if(item.mobilizationType === '1') {
                mobilizationType = '１号';
            } else if(item.mobilizationType === '2') {
               mobilizationType = '２号';
            } else if(item.mobilizationType === '3') {
                mobilizationType = '３号';
            } else if(item.mobilizationType === '4') {
                mobilizationType = '４号';
            } else if(item.mobilizationType === '5') {
                mobilizationType = '５号';
            } else if(item.mobilizationType === '0') {
                mobilizationType = '情報連絡体制';
            }
            this.mobilizationType.innerHTML = mobilizationType;
            this.historyContent.innerHTML = item.historyContent;
            this.rallyAffiliation.innerHTML = item.rallyAffiliation;
            this.workAffilication.innerHTML = item.workAffilication;
            this.rallyOtherAffiliation.innerHTML = item.rallyOtherAffiliation;
            this.total.innerHTML = item.total;
        }
    });
});