/**
* 水道局災害名一覧用モジュール。
* @module app/waterworks/IncidentPage
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/IncidentPage.html',
    'idis/store/IdisRest',
    'idis/control/Router',
    'dojo/_base/lang',
    'app/waterworks/_WaterworksBase',
    // 以下、変数として受け取らないモジュール
    'dijit/layout/BorderContainer',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'idis/view/form/DateInput',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'app/waterworks/grid/IncidentGrid',
], function(module, declare, template, IdisRest, Router, lang, _WaterworksBase) {
    return declare(module.id.replace(/\//g, '.'), _WaterworksBase,
    /** @lends module:app/waterworks/_WaterworksBase~_WaterworksBase# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--incident',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * 変数の初期化。
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'incidentId',
                target: '/api/waterworks/incidentlist',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
        },

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // グリッドを初期化する
            this.initGrid();            

            // select要素の初期化
            this.initSetIncidentList(this.incidentName);
        
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            var filter = new this.store.Filter();
            // 全件表示用のcollectionをグリッドにセットする
            this.incidentGrid.set('collection', this.store.filter(filter));
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateGridQuery: function(value) {
           // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            var value = this.form.get('value');

            // 紐付けフラグ(紐付け済み='1')
            if (value.relatedFlg.length !== 0) {
                filter = filter.eq('relatedFlg', '1');
            }

            // 水道局災害名
            if (value.incidentName) {
                filter = filter.eq('incidentName', value.incidentName);
            }

            // 更新日時FROM
            if (value.updateDateTimeFrom) {
                var updateDateTimeFrom = new Date(value.updateDateTimeFrom).getTime();
                filter = filter.eq('updateDateTimeFrom', updateDateTimeFrom);
            }
            
            // 更新日時To
            if (value.updateDateTimeTo) {
                var updateDateTimeTo = new Date(value.updateDateTimeTo).getTime();
                filter = filter.eq('updateDateTimeTo', updateDateTimeTo);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.incidentGrid.set('collection', collection);
        },

        /**
         * 検索ボタンが押されたときに呼び出される。
         * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
         * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
         */
        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            } finally {
                return false;
            }
        },
        
        // パンくずリストのボタンを押下したときの挙動(水道局情報閲覧画面へ遷移)
        onWaterworksPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('waterworks');
        },

        // 水道局災害名のセレクタ一覧を生成
        initSetIncidentList: function (selector) {
            var optionList = [
                {
                    label: '&nbsp;',
                    value: ''
                }
            ];
            this.getIncidentList().then(
                lang.hitch(this, function (itemList) {
                    Object.keys(itemList).forEach(function (id) {
                        optionList.push({
                            value: itemList[id].key,
                            label: itemList[id].val
                        });
                    });
                    selector.set('options', optionList);
                })
            );
        },
    });
});
