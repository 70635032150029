/**
 * 施設情報グリッド
 * @module app/facility/FacilityGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--facility',


        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
                  helper.buttonColumn('detail', '詳細'),
                  helper.column('facilityId', '施設ID'),
                  helper.column('_item', '避難所区分', {
                    formatter: lang.hitch(this, function(item) {
                        var shelterCategory = '';
                        if (item.designatedEvacShFlg === '1'){
                            shelterCategory += '避難所 ';
                        }
                        if (item.welfareEvacShFlg === '1'){
                            shelterCategory += '福祉 ';
                        }
                        if (item.temporaryEvacShFlg === '1'){
                            shelterCategory += '臨時';
                        }
                        if (shelterCategory === '') {
                            shelterCategory = '-';
                        }
                        return shelterCategory;
                        })
                  }),
                  helper.column('facilityName', '施設名称'),
                  helper.column('address', '住所'),
                  helper.column('phoneNum', '電話番号'),
                  {field: 'capacity', label: '収容可能人数', sortable: true,
                      formatter: function(value, item) {//避難所・一時滞在施設以外の時は「-」
                          if (item.facilityTypeId !== '01' && item.facilityTypeId !== '04') {
                              return '-';
                          }
                          return value;
                      }
                  }
                  ]
    });
});
