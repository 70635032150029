define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/promise/all',
    'dojo/text!./templates/LegendPane.html',
    'dojo/when',
    'dojox/lang/functional/object',
    'idis/control/Locator',
    './_LegendPaneBase',
    'app/model/Layer',
    'app/model/LayerStore',
    './LEGEND_LIST'
], function(module, array, declare, lang, all, template, when, df,
    Locator, _LegendPaneBase, Layer, LayerStore, LEGEND_LIST) {
    return declare(module.id.replace(/\//g, '.'), _LegendPaneBase, {
        // テンプレート文字列
        templateString: template,

        // ウィジェットのルート要素に付与されるCSSクラス
        baseClass: 'map-LegendPane',

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            // 凡例一覧の表示を更新
            this.updateView();
            this.own(Locator.on('change', lang.hitch(this, this.updateView)));
        },

        /**
         * 凡例一覧を再描画する。
         */
        showFixedList: function() {
            // 全凡例中、実際に使われているものに絞り込む
            var legendListToShow = [];

            array.forEach(LEGEND_LIST, function(legend) {
                if(legend.code){
                    if(this.GRID_LEGEND_LIST.indexOf(legend.code) !== -1){
                        legendListToShow.push(legend);
                    }
                } else if(legend.path){
                    // パス指定時は1つでも一致するものがあれば表示
                    // pathが文字列でnameを省略した場合、pathをnameとして扱う
                    // pathが正規表現でnameを省略した場合、最初にマッチした結果をnameとして用いる
                    for (var i = 0; i < this.GRID_LEGEND_PATH_LIST.length; i++) {
                        if (lang.isString(legend.path)) {
                            // 凡例が文字列の場合は完全一致で判定
                            if (legend.path === this.GRID_LEGEND_PATH_LIST[i]) {
                                // name省略時はpathをnameとして扱う
                                legendListToShow.push(lang.mixin({name: legend.path}, legend));
                                break;
                            }
                        } else {
                            // 凡例が正規表現指定の場合
                            var result = legend.path.exec(this.GRID_LEGEND_PATH_LIST[i]);
                            if (result) {
                                // name省略時はpathの先頭グループ or 全体をnameとして扱う
                                legendListToShow.push(lang.mixin({
                                    name: result[1] || result[0]
                                }, legend));
                                break;
                            }
                        }
                    }
                }

            }, this);
            // 結果を配置
            this._placeLegendList(legendListToShow);
        },

        /**
         * 凡例一覧を再描画する。
         */
        updateView: function() {
            // 表示中のレイヤーID一覧
            var layerIdList = df.keys(Locator.getLayerQuery());
            // レイヤーID一覧からレイヤー一覧を取得する
            var layerPromiseList = array.map(layerIdList, function(layerId) {
                return when(LayerStore.get(layerId)).then(function(layer) {
                    return when(Layer.getFullName(layerId, '/')).then(function(path) {
                        // レイヤー情報にパス情報を追加する
                        return lang.mixin({path: path}, layer);
                    });
                }).otherwise(function(err) {
                    // 失敗した場合はエラー出力してnullを返す
                    console.error(err);
                    return null;
                });
            });
            all(layerPromiseList).then(lang.hitch(this, function(layerList) {
                var codeMap = {}, pathList = [];
                array.forEach(layerList, function(layerData) {
                    // 表示中レイヤーの情報カテゴリー・コード一覧を取得
                    var code = layerData && layerData.infoCategoryCd;
                    if (code) {
                        if (!codeMap[code]) {
                            codeMap[code] = [];
                        }
                        codeMap[code].push(layerData);
                    }
                    // 表示中レイヤーのパス一覧を取得
                    var path = layerData && layerData.path;
                    if (path) {
                        pathList.push(path);
                    }
                });
                // 全凡例中、実際に使われているものに絞り込む
                var activeLegendList = [];
                array.forEach(LEGEND_LIST, function(legend) {
                    if (legend.code) {
                        // 情報種別コード指定時は一致した場合
                        if (codeMap[legend.code]) {
                            activeLegendList.push(legend);
                        }
                    } else if (legend.path) {
                        // パス指定時は1つでも一致するものがあれば表示
                        // pathが文字列でnameを省略した場合、pathをnameとして扱う
                        // pathが正規表現でnameを省略した場合、最初にマッチした結果をnameとして用いる
                        for (var i = 0; i < pathList.length; i++) {
                            if (lang.isString(legend.path)) {
                                // 凡例が文字列の場合は完全一致で判定
                                if (legend.path === pathList[i]) {
                                    // name省略時はpathをnameとして扱う
                                    activeLegendList.push(lang.mixin({name: legend.path}, legend));
                                    break;
                                }
                            } else {
                                // 凡例が正規表現指定の場合
                                var result = legend.path.exec(pathList[i]);
                                if (result) {
                                    // name省略時はpathの先頭グループ or 全体をnameとして扱う
                                    activeLegendList.push(lang.mixin({
                                        name: result[1] || result[0]
                                    }, legend));
                                    break;
                                }
                            }
                        }
                    } else {
                        console.warn(module.id + '#updateView: 凡例定義はcodeかpathを指定する必要があります', legend);
                    }
                });
                // 結果を配置
                this._placeLegendList(activeLegendList);
            }));
        }
    });
});
