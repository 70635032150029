/**
 * 避難状況 ファイル読み込み確認ダイアログ用モジュール。
 * @module app/evacorder/EvacOrderFileUploadConfirmDialog
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/date/locale',
    'dojo/text!./templates/EvacOrderFileUploadConfirmDialog.html',
    'dojo/request/iframe',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'dgrid/Grid',
    // 以下、変数で受けないモジュール
    'dojox/form/Uploader',
    'dijit/form/Form',
    'idis/view/form/Button'
], function(module, array, declare, lang, domClass, locale, template, iframe,
    InfoDialog, _PageBase, Loader, Grid) {
    /**
     * 避難状況ファイルアップロード確認ダイアログ
     * @class EvacOrderFileUploadConfirmDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
                   /** @lends module:app/view/page/EvacOrderPage~EvacOrderPage# */ {
        // テンプレート文字列
        templateString: template,

        baseClass: 'idis-Dialog idis-ConfirmDialog--evacorder',

        _fileId: null,

        infoDialog: null,

        _grid: null,


        buildRendering: function(){
            this.inherited(arguments);
            this._grid = new Grid({
                columns: [
                    // { label: '市町村', field: 'municipalityName'},
                    { label: '市/行政区', field: 'municipalityName'},
                    { label: '地区', field: 'districtName'},
                    {
                        label: '避難理由',
                        field: 'evacOrderReason',
                        formatter: function(value) {
                            var ret;
                            switch(value) {
                                case '01':
                                    ret = '土砂';
                                    break;
                                case '02':
                                    ret = '洪水';
                                    break;
                                case '03':
                                    ret = '地震';
                                    break;
                                // case '04':
                                //     ret = '津波';
                                //     break;
                                // case '05':
                                //     ret = '高潮';
                                //     break;
                                case '06':
                                    ret = 'その他';
                                    break;
                            }
                            return ret;
                        }
                    },
                    {
                        label: '避難区分',
                        field: 'evacOrderType',
                        renderCell: function(item, value, node) {
                            var str;
                            switch(value) {
                                case '01':
                                    str = '高齢者等避難';
                                    domClass.add(node, 'is-warning');
                                    break;
                                case '02':
                                    str = '避難指示';
                                    domClass.add(node, 'is-high');
                                    break;
                                case '03':
                                    str = '避難指示（緊急）';
                                    domClass.add(node, 'is-specialhigh');
                                    break;
                                case '04':
                                    str = '自主避難';
                                    domClass.add(node, 'is-row');
                                    break;
                                case '05':
                                    str = '解除(高齢者等避難)';
                                    break;
                                case '06':
                                    str = '解除(避難指示)';
                                    break;
                                case '07':
                                    str = '解除(避難指示（緊急）)';
                                    break;
                                case '08':
                                    str = '解除(自主避難)';
                                    break;
                            }
                            node.innerText = str;
                        }
                    },
                    { label: '対象世帯数', field: 'houseNum'},
                    { label: '対象人数', field: 'population'},
                    {
                        label: '発令日時',
                        field: 'evacOrderTimestamp',
                        formatter: function(value) {
                            var dt = new Date(value);
                            var dateLabel = locale.format(dt, {
                                selector: 'date',
                                datePattern: 'yyyy/MM/dd'
                            });
                            var timeLabel = locale.format(dt, {
                                selector: 'time',
                                timePattern: 'HH:mm:ss'
                            });
                            return dateLabel + '&nbsp;' + timeLabel;
                        }
                    }
                ]
            }, this.gridNode);

        },

        /**
         * 登録実行
         */
        onSubmit: function() {

            if (this.validate()) {
                var value = this.form.get('value');
                value.disasterId = this._disasterId;
                this.emit('send', {
                    value: value
                });
            }
        },

        /**
         * 入力チェック
         */
        validate: function() {
            return true;
        },

        /**
         * ファイルアプロード確認ダイアログ初期化処理
         */
        initFileUploadConfirmDialog: function(data) {
            // 前回グリッドに描画したデータを消去する
            if(this._lastRowList){
                array.forEach(this._lastRowList, function(rowNode){
                    this._grid.removeRow(rowNode , false);
                }, this);
            }

            // グリッドにデータを描画し、そのデータを保管しておく
            this._lastRowList = this._grid.renderArray(data);

            console.debug(data);
        }
    });
});
