/**
 * 避難所 状況推移画面モジュール。
 * @module app/shelter/status/ShelterStatusListPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/date/locale',
    'dojo/text!./templates/ShelterStatusListPage.html',
    'app/shelter/status/chart/Chart',
    'app/model/DisasterInfo',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    // 以下、変数として受け取らないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'idis/view/form/CheckGroup',
    'idis/view/form/DateInput',
    'idis/view/form/DateTimeInput',
    'idis/view/form/TimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/RadioGroup',
    'app/view/form/ShelterSelector',
    'app/view/form/CustomizableMunicipalitySelector',
    'app/shelter/status/selector/ShelterStatusTypeSelector',
    'app/shelter/status/ShelterStatusGrid',
], function (module, declare, lang, Deferred, locale, template, Chart, DisasterInfo, Locator, Router, UserInfo, IdisRest, Loader, DialogChain, _PageBase) {
    /**
     * 避難所 状況推移画面実装。
     * @class ShelterStatusListPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/shelter/status/ShelterStatusListPage~ShelterStatusListPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--Shulter-Status',
            store: null,
            chart: null,

            targetGrid: null, //呼び出し元記憶用
            timeoutId: 0, //タイムアウトの破棄用
            itemList: [],
            chartItemList: [],

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);

                // 開始日：当日から30日前を計算
                var startDate = new Date();
                startDate.setDate(startDate.getDate() - 30);
                this.statusDateTimeFrom.set('value', locale.format(startDate, { selector: "date", datePattern: "yyyy/MM/dd" }));
                // 終了日:当日
                this.statusDateTimeTo.set('value', locale.format(new Date(), { selector: "date", datePattern: "yyyy/MM/dd" }));

            },

            constructor: function () {
                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);

                this.store = new IdisRest({
                    idProperty: 'rowId',
                    target: '/api/shelters/status'
                });
            },
            startup: function () {
                this.inherited(arguments);
                if (Locator.getQuery().municipalityCd) {
                    // 概況画面から遷移した場合は、選択されていた市町村を設定する。
                    this._municipalityCd = Locator.getQuery().municipalityCd;
                    //市町セレクタがある場合、初期表示を設定する。
                    if (this.municipalityCd) {
                        this.municipalityCd.set('value', this._municipalityCd);
                    }
                }
                // グリッドを初期化する
                this.initGrid();
            },
            postCreate: function () {
                this.inherited(arguments);
                this.chart = new Chart();
                this.container.on('show', lang.hitch(this, function () {
                    this.chart.create(this.chartNode, this.legendNode);
                }));
            },

            /**
             * 検索ボタンが押されたときに呼び出される。
             * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
             * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
             */
            onSubmit: function () {
                try {
                    if (this.form.validate()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery(this.form.get('value'));
                    }
                } catch (e) {
                    console.error(e);
                }
                return false;
            },

            /**
            * グリッドを初期化する。
            */
            initGrid: function () {
                // グリッドの選択時の動作を設定する
                this.grid.on('dgrid-select', lang.hitch(this, function (evt) {
                    console.log(evt);
                    this.idList = this.grid.getSelectedIdList();
                    this.chartItemList = [];
                    this.itemList.forEach(lang.hitch(this, function (item) {
                        if (this.idList.length === this.chartItemList.length) {
                            return;
                        }
                        if (this.idList.indexOf(item.rowId) !== -1) {
                            this.chartItemList.push(item);
                        }
                    }));
                    this.initChart(this.chartItemList);
                }));
                // グリッドの選択時の動作を設定する
                this.grid.on('dgrid-deselect', lang.hitch(this, function (evt) {
                    console.log(evt);
                    this.idList = this.grid.getSelectedIdList();
                    this.chartItemList = [];

                    this.itemList.forEach(lang.hitch(this, function (item) {
                        if (this.idList.length === this.chartItemList.length) {
                            return;
                        }
                        if (this.idList.indexOf(item.rowId) !== -1) {
                            this.chartItemList.push(item);
                        }
                    }));

                    this.initChart(this.chartItemList);
                }));

                this.chart.create(this.chartNode, this.legendNode);

                this.updateGridQuery(this.form.get('value'));

            },

            changeDispType: function (e) {
                console.log(e);
                this.dispType.set('value', e.target.value);

                this.updateGridQuery(this.form.get('value'));
            },

            /**
            *検索パラメーターの設定
            */
            updateGridQuery: function (form) {
                var tomorrow = new Date();

                tomorrow.setDate(tomorrow.getDate() + 1);
                if (!form.municipalityCd) {
                    this.chain.info('区が選択されていません。', 'エラー');
                    return false;
                }
                if (!form.statusDateTimeFrom) {
                    this.chain.info('調査開始日が選択されていません。', 'エラー');
                    return false;
                }

                if (!form.statusDateTimeTo) {
                    this.chain.info('調査終了日が選択されていません。', 'エラー');
                    return false;
                }
                if ((form.statusDateTimeTo - form.statusDateTimeFrom) < 0) {
                    this.chain.info('調査日が不正です', 'エラー');
                    return false;
                }

                if (((form.statusDateTimeTo - form.statusDateTimeFrom) / 86400000) > 31) {
                    this.chain.info('調査日は31日以内で選択してください。', 'エラー');
                    return false;
                }
                if (form.statusDateTimeTo.getTime() > tomorrow) {
                    this.chain.info('調査日は本日より過去日で選択してください。', 'エラー');
                    return false;
                }

                this.itemList = [];
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();
                if (!!this.dispTypeList.get('value').join(',')) {
                    filter = filter.eq('dispTypeList', this.dispTypeList.get('value').join(','));
                }
                filter = filter.eq('disasterId', DisasterInfo.getDisasterId());
                filter = filter.eq('municipalityCd', form.municipalityCd);
                filter = filter.eq('startDateTime', form.statusDateTimeFrom.getTime());
                filter = filter.eq('endDateTime', form.statusDateTimeTo.getTime());
                // filterに対応するcollectionを取得
                //var collection = this.store.filter(filter);

                this.store.filter(filter).fetch().forEach(lang.hitch(this, function (item) {
                    this.itemList.push(item);
                }));
                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.grid.set('collection', this.store.filter(filter));

                // fetch中にGridを書くと正常に表示されない場合があるので、遅延実行させる。
                setTimeout(lang.hitch(this, function () {
                    this.grid.set('columnSets', this.createGridColumn());
                }), 500);

            },
            /**
             * グラフを作成する
             * @param data 検索結果の観測データ
             */
            initChart: function (data) {
                // ダムグラフ
                this.chart.create(this.chartNode, this.legendNode,
                    { mode: this.mode, data: data, axisX: this.createChartAxisX() });
                this.borderContainer.resize();
            },

            /**
             * Grid列を検索条件に合わせて動的に作る
             * @returns 
             */
            createGridColumn() {

                var columnSets = [
                    [[
                        {
                            field: 'checkbox',
                            label: '選択',
                            selector: 'checkbox'
                        },
                        {
                            field: 'municipalityName',
                            label: '行政区'
                        },
                        {
                            field: 'facilityName',
                            label: '施設名'
                        },
                        {
                            field: 'itemName',
                            label: '項目'
                        }
                    ]], [[
                        {
                            field: 'day0',
                            label: '1日'
                        }
                    ]]];
                var baseDate = this.form.get('value').statusDateTimeFrom;
                var endDate = this.form.get('value').statusDateTimeTo;
                var dateColumn = [];
                for (var i = 0; i < 31; i++) {
                    var targetDate = baseDate;
                    // console.log(locale.format(targetDate, { selector: "date", datePattern: "MM/dd" }));
                    dateColumn.push({
                        field: 'day' + i,
                        label: locale.format(targetDate, { selector: "date", datePattern: "MM/dd" })
                    });
                    targetDate.setDate(targetDate.getDate() + 1);

                    if (targetDate.getTime() > endDate.getTime()) {
                        break;
                    }
                }
                columnSets[1][0] = dateColumn;
                return columnSets;
            },
            createChartAxisX: function () {
                var baseDate = this.form.get('value').statusDateTimeFrom;
                var endDate = this.form.get('value').statusDateTimeTo;
                var legendList = [];
                for (var i = 0; i < 31; i++) {
                    var targetDate = baseDate;
                    legendList.push({
                        value: i,
                        text: locale.format(targetDate, { selector: "date", datePattern: "MM/dd" })
                    });

                    targetDate.setDate(targetDate.getDate() + 1);

                    if (targetDate.getTime() > endDate.getTime()) {
                        break;
                    }
                }
                return legendList;
            },

            // パンくずリストのボタンを押下したときの挙動
            onShelterAdminPageLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('shelter/admin');
            },

            onShelterListPageLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('shelter', {
                    municipalityCd: this._municipalityCd
                });
            },

            onOutputButtonClick: function () {
                if (this.chartItemList.length === 0) {
                    this.chain.info('出力する行が選択されていません。', 'エラー');
                    return false;
                }
                if (this.chartItemList.length > 10) {
                    this.chain.info('出力する行は10個までにしてください', 'エラー');
                    return false;
                }

                //repdateをYYYYMMDD形式に変換
                var dtFrom = this.statusDateTimeFrom.displayedValue.replace(/\//g, '');
                var dtTo = this.statusDateTimeTo.displayedValue.replace(/\//g, '');
                var paramList = [
                    { key: 'disid', value: DisasterInfo.getDisasterId() },
                    { key: 'reportdatefrom', value: dtFrom },
                    { key: 'reportdateto', value: dtTo }
                ];
                for (var i = 0; i < 10; i++) {
                    var idx = i + 1;
                    if (this.chartItemList[i]) {
                        paramList.push(
                            { key: 'f' + idx, value: this.chartItemList[i].facilityId }
                        );
                        paramList.push(
                            { key: 'p' + idx, value: this.chartItemList[i].itemCode }
                        );
                    }
                }
                var data4xoblosDownload = {
                    fileName: 'ShelterGraphList.xlsx',
                    reqid: 'SHELTER_GRAPH_LIST_EX',
                    paramList: paramList,
                    fileType: 'excel'
                };
                var promise = this.download(data4xoblosDownload).then(lang.hitch(this, function () {
                    this.chain.info('ダウンロードが完了しました。', 'ダウンロード完了');

                }), lang.hitch(this, function (error) {
                    console.error(error);
                    this.chain.info('エラーが発生しました。管理者にお問い合わせください。', 'エラー');
                }));

                Loader.wait(promise);
            },
            download: function (data) {
                var deferred = new Deferred();

                var xhr = new XMLHttpRequest();
                xhr.open('POST', '/api/xoblos/download', true);
                xhr.responseType = 'arraybuffer';
                xhr.setRequestHeader('Content-Type', 'application/json');
                var self = this;
                xhr.onload = function () {

                    // エラー時は処理を止める
                    if (xhr.status !== 200) {
                        deferred.reject('status error:' + xhr.status);
                        return;
                    }
                    // ファイル名をレスポンスヘッダーから取り出す
                    var contentDisposition = this.getResponseHeader('content-disposition');
                    var inputFileName = contentDisposition.replace(/^.*"(.*)"$/, '$1');

                    var arrayBuffer = this.response;
                    var blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
                    // ファイル名を取得
                    var fileName = inputFileName;

                    // IE10+
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                    } else {
                        // 擬似的にAタグを作成
                        var link = document.createElement('a');
                        link.style = 'display: none';
                        document.body.appendChild(link);

                        // AタグのURLにバイナリデータをセット
                        var url = window.URL.createObjectURL(blob);
                        link.href = url;

                        // ファイル名をセット
                        link.download = fileName;

                        // 擬似的にリンクをクリック
                        link.click();
                        // 参照を解放
                        window.URL.revokeObjectURL(url);
                        link.remove();
                    }

                    deferred.resolve();

                };
                xhr.send(JSON.stringify(data));

                return deferred.promise;
            }
        });
});

