/**
 * お知らせ情報グリッド
 * @module app/convocation/view/condition/ConvoAutoMaiRegisterGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'idis/view/grid/IdisGrid',
    'idis/control/Router',
    'idis/consts/ACL', 
    'idis/model/UserInfo',
    'idis/view/form/AclButton',
    'idis/view/grid/helper',
    'idis/view/form/Button',
    'dojo/on',
    'dojo/dom-construct'
], function(module, declare, lang, IdisGrid, Router, ACL, UserInfo, AclButton, 
    helper, Button, on, domCon) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        // IdisGrid拡張：行自体にCSSクラスを追加する場合に指定する
        rowClassName: function(item) {
            return item && !item.pubFlg ? 'is-private' : '';
        },
        /**
         * 各列の定義
         * @type {Object[]}
         */
        collection: this.store,
        columns: [
            //helper.buttonColumn('detail', '詳細'),
            helper.column('convoGroup', 'グループ名'),
            //helper.column('convoStaffNum', '対象職員数'),
            //helper.buttonColumn('delete', '削除')
            {field: 'delete', label:'削除', sortable: false,
            renderCell: function(item){
                var gridNode = this.grid.domNode;
                var button = new Button({
                    label: '削除',
                    onClick: function() {
                        on.emit(gridNode, 'deleteButtonClick', {item: item});
                    }
                });
                var content = null;
                var comment = null;
                // 訓練モードの場合、編集ボタンを非表示とする
                if(UserInfo.isTrainingFlg()){
                    comment = domCon.create('div');
                    comment.textContent = '-';
                    content = comment;
                }else{
                    content = button.domNode;
                }
                return content;
            }}
        ]
    });
});
