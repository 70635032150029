/**
 * 市町村選択用入力パーツ(複数選択可)
 * @module app/view/form/MunicipalityMultiSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'idis/view/form/TreeChecker',
    'app/model/Municipality'
], function(module, declare, domStyle, TreeChecker, Municipality) {
    /**
     * 地域選択用パーツ。
     * @class MunicipalityMultiSelector
     * @extends module:idis/view/form/TreeChecker~TreeChecker
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:idis/view/tree/MunicipalityMultiSelector~MunicipalityMultiSelectoronSelector# */ {
        // 選択ダイアログのタイトル
        title: '対象市/行政区選択',

        // ツリー・モデル
        model: Municipality,

        /**
         * 選択した値の設定
         */
        _setValueAttr: function() {
            // 継承した親クラスの処理
            this.inherited(arguments);
            // 以下、当モジュール固有の処理
            // 選択結果が20文字を超えた場合、・・・(三点リーダ)で省略する
            domStyle.set(this.status, {
                'max-width': '20em',
                'white-space': 'nowrap',
                'overflow': 'hidden',
                'text-overflow': 'ellipsis'
            });
        }
    });
});

