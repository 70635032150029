/**
 * 災害変更ダイアログ画面用モジュール。
 * @module app/view/form/DisasterChangerDialog
 */
define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
    'dojo/topic',
    'dojo/text!./templates/ParentDisasterSelectorDialog.html',
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog',
	'idis/store/IdisRest',
    'idis/view/page/_PageBase',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
	'app/view/form/ParentDisasterSelectorGrid'
], function(module, declare, lang, topic, template, DisasterInfo, InfoDialog, IdisRest, WidgetBase) {

    /**
     * 災害変更ダイアログ
     * @class DisasterChangerDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends app/view/Header~Header# */ {

        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'parentDisaster-changer',

        /**
         * データ格納用オブジェクト
         * @type {module:dstore/Store}
         */
        store: null,

        /**
         * コンストラクタ
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'disasterId',
                target: '/api/disasters/prefDisaster'
            });
        },

        /**
         * DOM生成
         */
        buildRendering: function() {
            this.inherited(arguments);
            // グリッドを初期化
            this.initGrid();
        },

        /**
         * 画面生成完了
         */
        startup: function() {
            this.inherited(arguments);
        },

        /**
         * 画面を再表示
         */
        refresh: function() {
            this.container.resize();
            this.item = null;
            // 最新の災害情報を取得
            DisasterInfo.load().then(lang.hitch(this, function() {
                // 最新の災害IDを退避
                // this.current = DisasterInfo.getExtraDisasterId();
                // グリッドを再表示
                this.grid.refresh();
            }));
        },

        /**
         * グリッド初期化
         */
        initGrid: function() {        
            this.grid.set('collection', this.store);

            // グリッドの行選択イベント
            this.grid.on('dgrid-select', lang.hitch(this, function(evt) {
                // 選択された行データを退避
                this.item = evt.rows[0].data;
            }));
        },

        /**
         * 災害変更実行
         */
        onSubmit: function() {
            if (!this.item) {
                InfoDialog.show('入力チェック', '災害が選択されていません');
                return false;
            }
            this.emit('update', {
                data: this.item
            });
        }
    });

});
