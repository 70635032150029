/**
 * 指定河川洪水情報のメッセージ用コンテンツ
 * @module app/floodforecast/view/FloodForecastDetailContent
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-style',
    'dojo/text!./templates/FloodForecastDetailContent.html',
    'dojox/lang/functional/object',
    'idis/error/InvalidArgumentException',
    'idis/view/_IdisWidgetBase'
], function(module, declare, array, lang, locale, domStyle, template, object, InvalidArgumentException,
        _IdisWidgetBase) {
    /**
     * 指定河川洪水情報のメッセージ用UIウィジェット
     * @class FloodForecastDetailContent
     * @extends module:idis/view/page/_PageBase~_PageBase
     * @param {Object} kwArgs
     * @param {string} [kwArgs.item={Object}] 津波情報
     */
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase,
        /** @lends app/floodforecast/view/FloodForecastDetailContent~FloodForecastDetailContent# */ {
        // テンプレート文字列
        templateString: template,

        constructor: function(options) {
            this.inherited(arguments);
            lang.mixin(this, options);
            // 引数チェック
            if (!this.item) {
                throw new InvalidArgumentException(module.id + '::constructor: Property "item" must be specified.');
            }
        },

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            // コンテンツの中身を生成する
            this.setContent();
        },

        /**
         * コンテンツの中身を生成する
         */
        setContent: function() {
            this.riverName.innerHTML = '河川：' + this.getRiverName(this.item);
            this.text.innerHTML = (!this.isEmpty(this.item.text)) ? this.item.text : '';

            // テーブルを一旦非表示化
            domStyle.set(this.tableNode, 'display', 'none');
            // 市ごとのデータが無い場合はそのまま終了
            var region = this.item.regions && this.item.regions[0];
            if (!region) {
                this.bodyNode.innerHTML = '';
                return;
            }
            // テーブル内容を生成
            var html = [];

            // 想定地区一覧
            array.forEach(this.item.regions, function(region) {
                if (region.kindPropertyType === '浸水想定地区') {
                    html.push('<tr><td>');
                    html.push(region.areaPrefecture);
                    html.push(region.areaCity);
                    html.push('</td><td>');
                    html.push(region.areaSubcitylist);
                    html.push('</td></tr>');
                }
            });
            if(html.length !== 0) {
                this.bodyNode.innerHTML = html.join('');
                // テーブルを再表示
                domStyle.set(this.tableNode, 'display', '');
            }

            // 主文内容を生成
            var divHtml = [];
            // 想定地区一覧
            array.forEach(this.item.regions, function(region) {
                if (!region.kindPropertyText || region.kindPropertyType !== '主文') {
                    return;
                }
                divHtml.push('<div>');
                divHtml.push(region.kindPropertyText);
                divHtml.push('</div>');
            });
            this.kindPropertyTextNode.innerHTML = divHtml.join('');
        },

        /**
         * 'yyyy-MM-dd HH:mm:ss' を表示形式に変換
         */
        formatDateTime: function(datetime) {
            var reportDateTime = new Date(datetime.replace(/-/g, '/'));
                var dateLabel = locale.format(reportDateTime, {
                    selector: 'date',
                    datePattern: 'MM月dd日'
                });
                var timeLabel = locale.format(reportDateTime, {
                    selector: 'time',
                    timePattern: 'HH時mm分'
                });
            return dateLabel + '&nbsp;' + timeLabel;
        },

        isEmpty: function(str) {
            if (str === void 0) {
                return true;
            }
            if (str === null) {
                return true;
            }
            return false;
        },

        /**
         * コンテンツのHTMLテキストを返す
         */
        getContent: function() {
            // HTMLタグ間に改行コードが入り、セット先のダイアログモジュールで<br>に変換されるため外しておく
            // テキスト内の改行はそのままにしたいため、「HTML終了タグ + 改行コード」にマッチする部分を
            // 「HTML終了タグ」のみにする
            // さらに、最初と最後の改行コードを外す
            return this.contentNode.innerHTML.replace(/>\r?\n/g, '>').replace(/^\r?\n/g, '').replace(/\r?\n$/g, '');
        },

        getRiverName: function(item) {
            var riverName = '';
            for(var i=0; i<item.regions.length; i++) {
                var region = item.regions[i];
                if (region.kindPropertyType === '主文') {
                    if(region.tsAreaName !== null || region.tsAreaName !== '') {
                        riverName = region.tsAreaName;
                        break;
                    }
                }
            }
            return riverName;
        }

    });
});
