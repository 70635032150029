/**
 * 利用者情報 市町村選択
 * @module app/user/municipalityCdList
 */
define([
    'module',
    'dojo/store/Memory'
    // 以下、変数で受けないモジュール
], function(module, Memory) {
    // シングルトンを返す
    return new Memory({
        idProperty: 'code',
        data: [
            {'code':'271276' ,'label':'北区'},
            {'code':'271021' ,'label':'都島区'},
            {'code':'271039' ,'label':'福島区'},
            {'code':'271047' ,'label':'此花区'},
            {'code':'271284' ,'label':'中央区'},
            {'code':'271063' ,'label':'西区'},
            {'code':'271071' ,'label':'港区'},
            {'code':'271080' ,'label':'大正区'},
            {'code':'271098' ,'label':'天王寺区'},
            {'code':'271110' ,'label':'浪速区'},
            {'code':'271136' ,'label':'西淀川区'},
            {'code':'271233' ,'label':'淀川区'},
            {'code':'271144' ,'label':'東淀川区'},
            {'code':'271152' ,'label':'東成区'},
            {'code':'271161' ,'label':'生野区'},
            {'code':'271179' ,'label':'旭区'},
            {'code':'271187' ,'label':'城東区'},
            {'code':'271241' ,'label':'鶴見区'},
            {'code':'271195' ,'label':'阿倍野区'},
            {'code':'271250' ,'label':'住之江区'},
            {'code':'271209' ,'label':'住吉区'},
            {'code':'271217' ,'label':'東住吉区'},
            {'code':'271268' ,'label':'平野区'},
            {'code':'271225' ,'label':'西成区'}
        ]
    });
});
