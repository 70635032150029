/**
* 消防局災害状況一覧画面用モジュール。
* @module app/fdmaDisasterSituation/FdmaDisasterSituationGrid
*/
define([
    'module',
    'dojo/_base/lang',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/util/DateUtils',
    'dojo/date/locale',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function(module,lang, declare, IdisGrid, helper, DateUtils, locale) {

    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

		// ルート要素に付与されるCSS
		baseClass: 'idis-Page idis-Page--fdmaDisasterSituation',

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            { field: 'entryDatetime', label: '作成年月日', sortable: true,
                formatter: lang.hitch(this, function(item){
                    if (item === null || !item) {
                        return '-';
                    }
                    return item;
                })
            },
            { field: 'disasterGroupName', label: '災害グループ名', sortable: false,
                formatter: lang.hitch(this, function(item){
                    if (item === null || !item) {
                        return '-';
                    }
                    return item;
                })
            },
            { field: 'activeFlg', label: '最新報', sortable: true,
                formatter: lang.hitch(this, function(item){
                    if (item === '0') {
                        return '';
                    }else if(item === '1'){
                        return '〇';
                    }else{
                        return '-';
                    }
                })
            },
            {
                label: '火災',
                field: 'fire',
                children: [
                    { field: 'fireAware', label: '覚知', sortable: true,
                        formatter: lang.hitch(this, function(item){
                            if (item === null) {
                                return '-';
                            }
                            return item;
                        })
                    },
                    { field: 'fireComplete', label: '完了', sortable: true,
                        formatter: lang.hitch(this, function(item){
                            if (item === null) {
                                return '-';
                            }
                            return item;
                        })
                    },
                ],
                sortable: false
            },
            {
                label: '救助',
                field: 'rescue',
                children: [
                    { field: 'rescueAware', label: '覚知', sortable: true,
                        formatter: lang.hitch(this, function(item){
                            if (item === null) {
                                return '-';
                            }
                            return item;
                        })
                    },
                    { field: 'rescueComplete', label: '完了', sortable: true,
                        formatter: lang.hitch(this, function(item){
                            if (item === null) {
                                return '-';
                            }
                            return item;
                        })
                    },
                ],
                sortable: false
            },
            {
                label: '救急',
                field: 'firstAid',
                children: [
                    { field: 'firstAidAware', label: '覚知', sortable: true,
                        formatter: lang.hitch(this, function(item){
                            if (item === null) {
                                return '-';
                            }
                            return item;
                        })
                    },
                    { field: 'firstAidComplete', label: '完了', sortable: true,
                        formatter: lang.hitch(this, function(item){
                            if (item === null) {
                                return '-';
                            }
                            return item;
                        })
                    },
                ],
                sortable: false
            },
            {
                label: '救護',
                field: 'nursing',
                children: [
                    { field: 'nursingAware', label: '覚知', sortable: true,
                        formatter: lang.hitch(this, function(item){
                            if (item === null) {
                                return '-';
                            }
                            return item;
                        })
                    },
                    { field: 'nursingComplete', label: '完了', sortable: true,
                        formatter: lang.hitch(this, function(item){
                            if (item === null) {
                                return '-';
                            }
                            return item;
                        })
                    },
                ],
                sortable: false
            },
            helper.column('status', '状況',  {
                formatter: lang.hitch(this, function(item){
                    if (item === '0') {
                        return '発生中';
                    }else if(item === '1'){
                        return '完了';
                    }else{
                        return '-';
                    }
                })
            }),
            {field: 'updateDatetime', label: '更新日時', sortable: true,
                formatter: function(updateDatetime){
                    return updateDatetime ? DateUtils.format(updateDatetime) : '-';
                }
            }
        ]
    });
});