/**
 * 防災情報リンク集画面用のモジュール
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/promise/all',
    'dojo/dom-style',
    'dojo/text!./templates/EvacRecommendPage.html',
    'dojo/topic',
    'dstore/Memory',
    'dstore/Trackable',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/view/dialog/DialogChain',
    'idis/view/page/_PageBase',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/store/JsonFileMemory',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'app/evacrecommend/DistrictLayerFactory4EvacRecommend',
    'app/model/DisasterInfo',
    '../config',
    'idis/consts/USER_TYPE',
    // 以下、変数で受けないモジュール
    'dijit/form/CheckBox',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/ValidationTextBox',
    'dijit/layout/TabContainer',
    'idis/view/form/WordCountTextarea',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    'idis/view/form/DateTimeInput',
    'app/view/form/ManageRegionMunicipalitySelector',
    'app/evacorder/EvacOrderMunicipalitySelector',
    'app/view/form/RegionSelector',
    './EvacRecommendGrid'
], function (module, array, declare, lang, domClass, all, domStyle, template, topic, Memory, Trackable, Locator,
    Router, DialogChain, _PageBase, Requester, IdisRest, JsonFileMemory, IdisMap, UserInfo, DistrictLayerFactory,
    DisasterInfo, config) {

    /**
    * 検索条件格納用オブジェクト
    * @type {Object}
    */
    var evacRecommendFilterStore = {};

    return declare(module.id.replace(/\//g, '.'), _PageBase, {

        // テンプレート文字列
        templateString: template,

        // 基本クラス
        baseClass: 'idis-Page idis-Page--evacrecommend',

        INIT_LATLNG: { lat: config.map.latitude, lng: config.map.longitude },

        _layerControl: null,

        _layerControl4adminMap: null,

        _municipalityCd: null,

        _isEvacOrderPresent: false,

        _selectedMuniCdList: [],

        _selectedMuniNameList: [],

        _selectedMunicipalityMap: {},

        _selectedDistCdList: [],

        _selectedDistNameList: [],

        _selectedDataMap: {},

        _districtTreeCache: null,

        _currentEvacOrderType: null,

        _currentIssueReasonType: null,

        _selectedMunicipalityCd: null,

        _latestLayerState: null,

        _latestAllDistrictLayerState: null,

        _isShowingAllDist: false,

        // デフォルトのズームサイズ
        _zoomSize: 10,

        LATEST_STATE_LAYER_NO: null,

        LATEST_LAYER_STATE: {
            ON: '1',
            OFF: '0'
        },

        ALLDISTRICT_LAYER_STATE: {
            ON: '1',
            OFF: '0'
        },

        // 閲覧モードフラグ
        _viewMode: '0',

        // DOM要素を構築する
        buildRendering: function () {
            this.inherited(arguments);
        },

        constructor: function () {
            this.chain = DialogChain.get(this);
            // データ格納用オブジェクト
            this._disasterId = DisasterInfo.getDisasterId();
            this.store = Trackable.create(new Memory({
                idProperty: 'evacRecommendId',
                target: '/data/evacorder/recommend/' + this._disasterId + '/evacRecommend.json'
            }));
        },

        startup: function () {
            this.inherited(arguments);

            // Cookieから取得した災害IDを取得する。
            this._disasterId = DisasterInfo.getDisasterId();

            this._municipalityCd = config.municInfo.cityMunicCd;
            this.municipalitySelector.set('value', this._municipalityCd);

            // if( UserInfo.getUserType() === USER_TYPE.PREFECTURE || UserInfo.getUserType() === USER_TYPE.REGION ){
            //     domStyle.set(this.mapCntlBtn, 'display', 'none');
            //     domStyle.set(this.river, 'display', 'none');
            //     domStyle.set(this.mesh, 'display', 'none');
            //     this._isShowingAllDist = true;
            //     //県全体を表示する場合は、ズームレベルを下げる。
            //     this._zoomSize = 9;
            // }

            // if( UserInfo.getUserType() === USER_TYPE.MUNICIPALITY ) {
            //     this.municipalitySelector.set('noAllButton', true);
            // }

            // if(this._municipalityCd){
            //     this.municipalitySelector.set('value', this._municipalityCd);
            // } else if(this._regionCd) {
            //     this.municipalitySelector.set('value', this._regionCd);
            // }


            var childMunicList = null;
            // 県だったら、クエリにはnullを仕込む
            // var childMunicList = (this._municipalityCd === config.municInfo.prefCd) ?
            //                                 null : UserInfo.getMunicipalityCds();

            Requester.get('/data/evacorder/recommend/' + this._disasterId + '/evacRecommend.json', {
                headers: { 'Content-Type': 'application/json; charset=utf-8' },
                handleAs: 'json',
                preventCache: true
            }).then(lang.hitch(this, function (data) {
                // JSONの中身で画面・グリッドを生成する
                this.initPage(childMunicList, data.items);
            }), lang.hitch(this, function (error) {
                console.log(error);
                if (error.response.status === 404) { //まだevacRecommend.jsonが未生成だった場合
                    // グリッドは空で画面を生成する
                    this.initPage(childMunicList, []);
                }
            }));

            // 閲覧モードフラグをセット
            this._viewMode = Locator.getQuery().viewMode || '0';

        },

        initPage: function (childMunicList, gridData) {
            this.initGrid(childMunicList, gridData);
            this.initMap();
            this.initLatlng();
            this.hideQuery();
            this.setStatusColumn();
            this.initDistrictLayer().then(lang.hitch(this, function () {
                this._latestLayerState = this.LATEST_LAYER_STATE.OFF;
            }));

            // 閲覧モードの場合、発令ボタンを非表示にする
            if (this._viewMode === '1') {
                domStyle.set(this.submitButton.domNode, 'display', 'none');
            }

            // 初期状態では選択されているレコードがないので、発令ボタンを押せないようにする
            this.submitButton.set('disabled', true);

            this.borderContainer.resize();
        },

        onSubmit: function () {
            try {
                console.log('onSubmit');

                domStyle.set(this.river, 'display', 'none');
                domStyle.set(this.mesh, 'display', 'none');

                var selectedCd = this.municipalitySelector.get('value');

                if (!selectedCd || selectedCd === config.municInfo.cityMunicCd) {
                    // 「未選択」あるいは「市」が選ばれた場合
                    // domStyle.set(this.mapCntlBtn, 'display', 'none');

                    // this._municipalityCd = config.municInfo.prefCd;
                    // this._zoomSize = 9;

                    // 地図の初期位置を設定する。
                    //this.initLatlng();
                    // this.reCreateLayers();

                    //this.setStatusColumn();
                    this.updateGridQuery(null);

                } else {
                    // this.municipalitySelector.set('value', selectedCd);

                    // var childMunicList = this.getChildMunicCd(selectedCd);
                    // if(childMunicList.length === 0){
                    // 一つの市町が選ばれた場合
                    // 現況情報を表示できるようにする
                    // domStyle.set(this.mapCntlBtn, 'display', '');
                    // this._regionCd = null;
                    // this._municipalityCd = selectedCd;
                    // this._zoomSize = 10;
                    // childMunicList.push(selectedCd);
                    // } else {
                    //     // 振興局が選ばれた場合
                    //     // 現況情報を表示できないようにする
                    //     domStyle.set(this.mapCntlBtn, 'display', 'none');
                    //     this._regionCd = selectedCd;
                    // 
                    //     this._municipalityCd = null;
                    //     this._zoomSize = 9;
                    // }

                    // 地図の初期位置を設定する。
                    // this.initLatlng();
                    // this.reCreateLayers();

                    //this.setStatusColumn();
                    this.updateGridQuery(selectedCd);
                }
            } catch (e) {
                console.error(e);
            } finally {
                // onSubmitのデフォルトのイベントをキャンセルする。
                return false;
            }
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function (municLists, items) {
            var self = this;

            // 読み取ったJSONの中身をソートする
            items.sort(function (a, b) {
                // 1_発令が必要なデータを優先
                var abool = self.checkNecessary(a);
                var bbool = self.checkNecessary(b);
                if (abool !== bbool) {
                    if (abool) {
                        return -1;
                    } else if (bbool) {
                        return 1;
                    }
                }
                // 2_日時の降順
                if (a.updTimestamp !== b.updTimestamp) {
                    // ミリ秒単位を除外
                    var atimestamp = a.updTimestamp - a.updTimestamp % 1000;
                    var btimestamp = b.updTimestamp - b.updTimestamp % 1000;
                    if (atimestamp > btimestamp) {
                        return -1;
                    } else {
                        return 1;
                    }
                }
                // 3_発令内容の昇順
                if (a.evacOrderType !== b.evacOrderType) {
                    if (a.evacOrderType < b.evacOrderType) {
                        return -1;
                    } else {
                        return 1;
                    }
                }
                // 4_発令理由の昇順
                if (a.issueReasonType !== b.issueReasonType) {
                    if (a.issueReasonType < b.issueReasonType) {
                        return -1;
                    } else {
                        return 1;
                    }
                }
                return 0;
            });

            // 読み取ったJSONの中身をストアに保存する
            array.forEach(items, function (item) {
                this.store.add(item);
            }, this);

            // グリッドの選択時の動作を設定する
            this.grid.on('dgrid-select', lang.hitch(this, function (evt) {

                // 現在選択中のレコードと、新しく選択したレコードが一致するか判定する
                var isValidSelection = this.isValidSelection(evt.rows[0].data);

                if (isValidSelection) {
                    // 現在選択中のレコードと、新しく選択したレコードが一致する場合＋初回選択時
                    // 選択中のレコードと、条件(避難区分・発令理由)の異なるレコードを、選択不能にする
                    this.makeRecordsUnselectable();
                    // 選択されたレコメンドのデータを保管する
                    this.addEvacRecommendData(evt);
                    // レコメンド対象地域のデータを保管し、文字列表示ペイン・地図に反映
                    this.updateEvacArea(evt.rows[0].data.evacOrderType);
                    // 発令ボタンをクリック可能にする
                    this.submitButton.set('disabled', false);
                } else {
                    // 現在選択中のレコードと、新しく選択したレコードが一致しない場合
                    // 内部的な選択処理をリバースする
                    this.reverseInvalidSelection(evt.rows[0]);
                }
                console.log('selectedEvacRecommendIds:' + this.grid.getSelectedIdList());
            }));

            // グリッドの選択解除時の動作を設定する
            this.grid.on('dgrid-deselect', lang.hitch(this, function (evt) {
                // 選択中のレコメンドのデータを更新する
                this.removeEvacRecommendData(evt);
                // レコメンド対象地域のデータを更新し、文字列表示ペイン・地図に反映
                this.updateEvacArea(evt.rows[0].data.evacOrderType);
                if (Object.keys(this.grid.selection).length === 0) {
                    // 選択中のレコードがなくなった場合、グリッドの全てのレコードの状態を元に戻す
                    this._currentEvacOrderType = null;
                    this._currentIssueReasonType = null;
                    this._selectedMunicipalityCd = null;
                    this.makeAllRecordsSelectable();
                    // 発令ボタンを無効化する
                    this.submitButton.set('disabled', true);
                }
                console.log('selectedEvacRecommendIds:' + this.grid.getSelectedIdList());
            }));

            this.updateGridQuery(municLists);
        },

        /**
         * 発令が必要なデータであるかどうかを判定する。
         * 赤字の基準超過情報が存在する場合はtrueを返す。
         */
        checkNecessary: function (data) {
            for (var i = 0; i < data.recommendReason.length; i++) {
                var item = data.recommendReason[i];
                if (item.recommendFlg === '1') {
                    return true;
                }
            }
        },

        // regionCdであれば、子供の地区コードリストを返す。municipalityCdであれば市町村コードを返す。
        getChildMunicCd: function (cd) {
            var childList = this.municipalitySelector.model.childrenCache[cd];
            if (!childList) {
                return cd;
            }
            var childCdList = [];
            array.forEach(childList, function (municObj) {
                childCdList.push(municObj.id);
            });
            return childCdList;
        },

        /**
         * クリックされたレコードが選択可能かどうか判定する
         * 発令済レコード・撤回レコード・マスタレコードはfalse
         * 現在選択中のレコードと条件が一致する場合と、現在選択中のレコードがない場合に、trueを返す
         */
        isValidSelection: function (item) {

            if (item.status === '9' || item.status === '3' || item.status === '2') {
                return false;
            }
            if (!this._currentEvacOrderType && !this._currentIssueReasonType && !this._selectedMunicipalityCd) {
                // 現在選択中のレコードがなければ、クリックされたレコードの条件を保存
                this._currentEvacOrderType = item.evacOrderType;
                this._currentIssueReasonType = item.issueReasonType;
                //this._selectedMunicipalityCd = item.municipalityCd;
                return true;
            } else if (item.evacOrderType === this._currentEvacOrderType &&
                item.issueReasonType === this._currentIssueReasonType) {
                //                item.issueReasonType === this._currentIssueReasonType &&
                //                item.municipalityCd === this._selectedMunicipalityCd){
                return true;
            }
            return false;
        },

        /**
         * 無効なレコードが選択された場合に、選択処理を取り消す
         */
        reverseInvalidSelection: function (row) {
            console.log('invalid selection');
            var evacRecommendId = row.data.evacRecommendId;
            // 選択解除する
            delete this.grid.selection[evacRecommendId];
            // 選択中クラスが付与されてしまうので、強制的にリムーブする
            domClass.remove(row.element, 'dgrid-selected');

            var message = '';
            if (row.data.status === '9') {
                message += '自動判定対象外の情報は、発令することができません';
            } else if (row.data.status === '3') {
                message += '発令済の情報は、発令することができません';
            } else if (row.data.status === '2') {
                message += '撤回した情報は、発令することができません';
            } else {
                // message += '同時に発令できるのは、<br>市町村・発令理由・避難区分が同一の情報のみです';
                message += '同時に発令できるのは、<br>発令理由・避難区分が同一の情報のみです';
            }

            // ユーザに選択できない旨を表示
            this.chain.info(message, '', function () {
                this.chain.hide();
            });
        },

        /**
         * グリッドで選択された避難レコメンドを元に、避難情報を発令する
         */
        createEvacOrder: function () {
            var evacRecommendIds = this.grid.getSelectedIdList().join(',');
            Router.moveTo('evacorder/registerFromRecommend', {
                evacRecommendIds: evacRecommendIds
            });
        },

        /**
         * 避難レコメンドの条件が一致しないレコードを、選択不能にする
         */
        makeRecordsUnselectable: function () {
            array.forEach(this.grid._rows, function (row) {
                if (!this.isValidSelection(row.evacRecommendType)) {
                    domClass.add(row, 'row-invalid');
                    var inputtags = row.getElementsByTagName('input');
                    for (var j = 0; j < inputtags.length; j++) {
                        inputtags[j].disabled = true;
                    }
                }
                return row;
            }, this);
        },

        /**
         * グリッド上の全てのレコードを選択可能の状態にする
         */
        makeAllRecordsSelectable: function () {
            array.forEach(this.grid._rows, function (row) {

                domClass.remove(row, 'row-invalid');
                var inputtags = row.getElementsByTagName('input');
                for (var j = 0; j < inputtags.length; j++) {
                    inputtags[j].disabled = false;
                }
                return row;
            }, this);
        },

        /**
         * 選択されたレコードを、選択中レコードマップに保存する
         */
        addEvacRecommendData: function (evt) {
            var item = evt.rows[0].data;
            // グリッドで選択されたデータを退避・保管
            this._selectedDataMap[item.evacRecommendId] = item;
            console.log(this._selectedDataMap);
        },

        /**
         * 選択解除されたレコードを、選択中レコードマップから削除する
         */
        removeEvacRecommendData: function (evt) {
            var item = evt.rows[0].data;
            // グリッドで選択解除されたデータを、保管先から削除
            delete this._selectedDataMap[item.evacRecommendId];
            console.log(this._selectedDataMap);
        },

        /**
         * 現在選択中のレコードの発令対象地区を、漏れ・被りがないようマージし、地区一覧表示ペインに表示＋地図上に反映
         */
        updateEvacArea: function (evacOrderType) {

            var lastDistCdList = this._selectedDistCdList;
            console.log('lastDistCdList: ' + lastDistCdList);

            this._selectedDistCdList = [];
            this._selectedDistNameList = [];
            this._selectedMuniCdList = [];
            this._selectedMuniNameList = [];
            this._selectedMunicipalityMap = {};

            // 発令対象河川名、発令対象メッシュコードを非表示にする
            domStyle.set(this.river, 'display', 'none');
            domStyle.set(this.mesh, 'display', 'none');
            var selectedRiverNameList = [];
            var selectedMeshCdList = [];

            // グリッド上で現在選択中のID(evacRecommendId)のリストを取得
            var selectedIdList = this.grid.getSelectedIdList();
            console.log(selectedIdList);

            // グリッドで現在選択中の全データの、対象地区コード一覧と対象地区名一覧をリストにマージする
            for (var i = 0; i < selectedIdList.length; i++) {
                var item = this._selectedDataMap[selectedIdList[i]];

                array.forEach(item.municipality, function (muni) {
                    /**
                    // 地区コード
                    array.forEach(item.districtList, function(district){
                        if(this._selectedDistCdList.indexOf(district) === -1){
                            this._selectedDistCdList.push(district);
                        }
                    }, this);
    
                    // 地区名
                    array.forEach(item.districtNameList, function(districtName){
                        if(this._selectedDistNameList.indexOf(districtName) === -1){
                            this._selectedDistNameList.push(districtName);
                        }
                    }, this);
                     */
                    this.setDistList(muni);

                }, this);

                // 洪水の場合、発令対象河川名を表示する
                if (item.issueReasonType === '02') {
                    // domStyle.set(this.river, 'display', '');
                    selectedRiverNameList = selectedRiverNameList.concat(item.riverNameList);
                    selectedRiverNameList = selectedRiverNameList.filter(function (x, y, self) {
                        return self.indexOf(x) === y;
                    });
                    selectedRiverNameList.sort();
                    var riverName = selectedRiverNameList.join('、');
                    this.riverName.innerHTML = riverName;
                }
                // 土砂の場合、発令対象メッシュコードを表示する
                if (item.issueReasonType === '01') {
                    domStyle.set(this.mesh, 'display', '');
                    selectedMeshCdList = selectedMeshCdList.concat(item.meshCdList);
                    selectedMeshCdList = selectedMeshCdList.filter(function (x, y, self) {
                        return self.indexOf(x) === y;
                    });
                    selectedMeshCdList.sort();
                    var meshCd = selectedMeshCdList.join('、');
                    this.meshCd.innerHTML = meshCd;
                }

            }

            // 地区名を画面右側の対象地区一覧表示ペインに表示する
            this._selectedDistNameList.sort();
            //var distNamesStr = this._selectedDistNameList.join('、');
            var selectedDistStrList = [];
            for (var n = 0; n < this._selectedMuniNameList.length; n++) {
                var municipalityName = this._selectedMuniNameList[n];
                var distNameList = this._selectedMunicipalityMap[municipalityName];
                distNameList.sort();
                var distNamesStr = '<b>' + municipalityName + ':</b>';
                distNamesStr += distNameList.join('、');
                selectedDistStrList.push(distNamesStr);
            }
            this.district.innerHTML = selectedDistStrList.join('<br>');

            // 選択解除により、対象から外れた地区のリストを作成
            var deselectedDistList = [];
            array.forEach(lastDistCdList, function (distCd) {
                if (this._selectedDistCdList.indexOf(distCd) === -1) {
                    deselectedDistList.push(distCd);
                }
            }, this);

            // 避難区分に応じて、対象地区の色を決定
            var color = this.getColor4EvacOrderType(evacOrderType);
            // 対象地区を地図上に色付けて表示
            array.forEach(this._selectedDistCdList, function (districtCd) {
                this.selectDistrictLayerFromGridCheck(districtCd, true, color, this._layerControl);
            }, this);

            // 対象外になった地図の色を戻す
            array.forEach(deselectedDistList, function (districtCd) {
                this.selectDistrictLayerFromGridCheck(districtCd, false, null, this._layerControl);
            }, this);
        },

        setDistList: function (item) {
            var distNameList = [];
            if (this._selectedMunicipalityMap[item.municipalityName]) {
                distNameList = this._selectedMunicipalityMap[item.municipalityName];
            }

            // 行政区コード
            if (this._selectedMuniCdList.indexOf(item.municipalityCd) === -1) {
                this._selectedMuniCdList.push(item.municipalityCd);
            }

            // 行政区名
            if (this._selectedMuniNameList.indexOf(item.municipalityName) === -1) {
                this._selectedMuniNameList.push(item.municipalityName);
            }

            // 地区コード
            array.forEach(item.districtCdList, function (district) {
                if (this._selectedDistCdList.indexOf(district) === -1) {
                    this._selectedDistCdList.push(district);
                }
            }, this);

            // 地区名
            array.forEach(item.districtNameList, function (districtName) {
                if (this._selectedDistNameList.indexOf(districtName) === -1) {
                    this._selectedDistNameList.push(districtName);
                    distNameList.push(districtName);
                }
            }, this);

            this._selectedMunicipalityMap[item.municipalityName] = distNameList;
        },

        getColor4EvacOrderType: function (evacOrderType) {
            var color = '';
            switch (evacOrderType) {
                case '11':
                    color = '_prepareColor';
                    break;
                case '13':
                    color = '_advisoryColor';
                    break;
                case '14':
                    color = '_orderColor';
                    break;
            }
            return color;
        },

        /**
         * グリットを更新する。
         */
        updateGridQuery: function (municipalityCds) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            var value = this.form.get('value');

            // 市町村
            if (municipalityCds) {
                //filter = filter['in']('municipalityCd', municipalityCds);
                filter = filter.and(function (object) {
                    if (!object.municipality) {
                        return false;
                    }
                    for (var i = 0; i < object.municipality.length; i++) {
                        var item = object.municipality[i];
                        if (municipalityCds.indexOf(item.municipalityCd) !== -1) {
                            return object;
                        }
                    }
                });
            }

            // 発令理由
            if (value.issueReasonType) {
                filter = filter.eq('issueReasonType', value.issueReasonType);
            }

            // 避難区分
            if (value.evacOrderType) {
                filter = filter.eq('evacOrderType', value.evacOrderType);
            }

            // 対象日時FROM
            if (value.evacRecommendTimestampFrom) {
                var evacRecommendTimestampFrom = new Date(value.evacRecommendTimestampFrom).getTime();
                filter = filter.gt('updTimestamp', evacRecommendTimestampFrom);
            }

            // 対象日時TO
            if (value.evacRecommendTimestampTo) {
                var evacRecommendTimestampTo = new Date(value.evacRecommendTimestampTo).getTime();
                filter = filter.lt('updTimestamp', evacRecommendTimestampTo);
            }

            // 超過状況
            var statusList = [];
            if (value.status === '0') {
                //「1つ以上で超過中」なので、一部超過中と全超過中を表示
                statusList.push('0');
                statusList.push('1');
            } else if (value.status) {
                // 「全超過中」「撤回済」はそのまま対応するステータスを出す
                statusList.push(value.status);
            } else {
                // 超過状況が指定されていない → 一部超過/全超過/撤回済みを表示
                statusList.push('0');
                statusList.push('1');
                statusList.push('2');
            }
            // 発令済みの表示要否
            if (this.dealtFlg.get('checked')) {
                statusList.push('3');
            }
            filter = filter['in']('status', statusList);
            /**
            if (this.dealtFlg.get('checked') && this.settledFlg.get('checked')) {
                filter = filter['in']('status', ['2', '1', '0']);
            } else if (this.dealtFlg.get('checked')) {
                filter = filter['in']('status', ['1', '0']);
            } else if (this.settledFlg.get('checked')) {
                filter = filter['in']('status', ['2', '0']);
            } else {
                filter = filter.eq('status', '0');
            }
             */

            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.grid.set('collection', collection);
        },

        /**
         * 保管した検索条件をformにセットする
         */
        setFilterData: function () {

            // 発信日時FROM
            if (evacRecommendFilterStore.registerTimestampFrom) {
                this.registerTimestampFrom.set('value', evacRecommendFilterStore.registerTimestampFrom);
            }

            // 発信日時TO
            if (evacRecommendFilterStore.registerTimestampTo) {
                this.registerTimestampTo.set('value', evacRecommendFilterStore.registerTimestampTo);
            }

            // 市町村コード
            if (evacRecommendFilterStore.municipalityCd) {
                this.municipalityCd.set('value', evacRecommendFilterStore.municipalityCd);
            }
        },

        setStatusColumn: function () {
            // ステータスの種類が多いので、分かりやすさのために一旦必ず出しておくことにする
            return true;
            // if(!this.dealtFlg.get('checked') && !this.settledFlg.get('checked')){
            //     this.grid.styleColumn('0-6', 'display: none;');
            // } else {
            //     this.grid.styleColumn('0-6', 'display: table-cell;');
            // }
        },

        /**
         * 地図を初期生成する。
         */
        initMap: function () {
            // 地図を生成する。
            this.map = new IdisMap(this.mapNode, {
                config: config.map,
                keyboard: false, // コメント時に+/-が使用できないため
                touchExtend: false,
                minZoom: 8,
                maxZoom: 18,
                drawControlTooltips: false
            }).setView(this.INIT_LATLNG, this._zoomSize);

            // 描画する際に、マップのリサイズを行う
            this.map.invalidateSize();

            // destroy時にmapを破棄するよう設定
            this.own(this.map);

            // 生成したmapのlayerControlを画面にセットする。
            this._layerControl = this.map.layerControl;

        },

        /**
         * 市町村コードから、対象の市町の緯度経度を取得して、地図を移動させる。
         */
        initLatlng: function () {
            // 市町村の緯度経度情報の一覧を取得する。
            Requester.get('/data/municipality/municipalityList.json', { preventCache: false })
                .then(lang.hitch(this, function (obj) {

                    var municipalities = obj.municipalities;
                    var latlng = this.INIT_LATLNG;

                    // 市町村の緯度経度情報の一覧と画面で指定されている市町村を比較して当該市町村の緯度経度を取得する。
                    municipalities.some(lang.hitch(this, function (item) {
                        if (item.municipalityCd === this._municipalityCd) {
                            latlng = item.latlng;
                            return true; //break
                        }
                    }));
                    this.map.setView(latlng, this._zoomSize);
                }));
        },

        onShowQueryButtonClick: function () {
            this.showQuery();
        },

        onHideQueryButtonClick: function () {
            this.hideQuery();
        },

        onDealtFlgButtonClick: function () {
            this.setStatusColumn();
        },

        onSettledFlgButtonClick: function () {
            this.setStatusColumn();
        },

        hideQuery: function () {
            domStyle.set(this.hideQueryLabel, 'display', 'none');
            domStyle.set(this.showQueryLabel, 'display', '');
            domStyle.set(this.queryPane.domNode, 'display', 'none');
            this.borderContainer.resize();
            this.subBorderContainer.resize();

            // borderContainerのリサイズが終わってから、mapNodeのリサイズを行う
            setTimeout(lang.hitch(this, function () {
                this.map.invalidateSize();
            }), 400);
        },

        showQuery: function () {
            domStyle.set(this.hideQueryLabel, 'display', '');
            domStyle.set(this.showQueryLabel, 'display', 'none');
            domStyle.set(this.queryPane.domNode, 'display', '');
            this.borderContainer.resize();
            this.subBorderContainer.resize();

            // borderContainerのリサイズが終わってから、mapNodeのリサイズを行う
            setTimeout(lang.hitch(this, function () {
                this.map.invalidateSize();
            }), 400);
        },

        /**
         * 地図レイヤーを再作成する。
         * 市町変更時にレイヤーを削除して再作成する。
         */
        reCreateLayers: function () {
            // 地図上のレイヤーを全て削除する。
            var layerControl = this._layerControl;
            Object.keys(layerControl.layers).forEach(lang.hitch(this, function (key) {
                layerControl.removeLayerById(key);

            }));
            // 地区レイヤーを追加する。
            this.initDistrictLayer();
        },

        /**
         * 地図上の地区選択レイヤーを初期化する。
         * @returns {Promise} 完了後に解決するPromise
         */
        // 登録画面では現況レイヤーを表示する。
        initDistrictLayer: function () {
            return all({
                // 選択レイヤーを取得して地図上に表示する。
                select: this.showSelectLayer(),
                // 現況レイヤーを取得して地図上に表示する。
                state: this.showLatestStateLayer().otherwise(function () {
                    // 未登録時は404になるため、失敗も成功扱いにする
                    this._isEvacOrderPresent = false;
                })

            });
        },

        /**
         * 現況レイヤーを取得して地図上に表示する。
         * @returns {Promise} 完了後に解決するPromise
         */
        showLatestStateLayer: function () {
            // 市町村の避難情報「現況」レイヤーのidを取得する。
            return Requester.get('/api/evacorders/layerid', {
                query: {
                    disasterId: this._disasterId,
                    //municipalityCd: this._municipalityCd
                    municipalityCd: config.municInfo.cityMunicCd
                }
            }).then(lang.hitch(this, function (id) {
                // 市町村の「現況」レイヤーを取得する。
                this.LATEST_STATE_LAYER_NO = id;
                this._isEvacOrderPresent = true;
                return Requester.get('/data/layer/tree/' + id + '.json');
            })).then(lang.hitch(this, function (layerInfo) {
                // 現在の避難情報発令状況を地図上に表示する。
                var opacity = 0.7;
                return this._layerControl.addGeneralLayer(layerInfo, opacity);
            })).then(lang.hitch(this, function () {
                // 「現況」レイヤーは全面に移動する。
                this._layerControl.toFront(this.LATEST_STATE_LAYER_NO);
                // 初期状態は非表示とする
                var targetLayer = this._layerControl.layers[this.LATEST_STATE_LAYER_NO];
                this._layerControl.removeLayer(targetLayer);
            }));
        },

        /**
         * 避難情報発令地区「選択」レイヤーを地図上に表示する。
         * 選択レイヤーの初期値は、詳細情報のstyle.urlから取得する。
         *「選択」レイヤーはgeojsonからDistrictSelectLayerFactoryによって生成する。
         * @returns {Promise} 追加完了後に解決するPromise
         */
        showSelectLayer: function () {
            var municipalityCd = this._municipalityCd;
            // if(this._regionCd || this._municipalityCd === config.map.prefCd){
            //     municipalityCd = config.municInfo.prefCd;
            // } else if (this._municipalityCd) {
            //     municipalityCd = this._municipalityCd;
            // } else {
            //     municipalityCd = config.municInfo.prefCd;
            // }

            return Requester.get('/data/layer/data/evacorder/area/' + municipalityCd + '.geojson')
                .then(lang.hitch(this, function (selectLayer) {
                    // 地区レイヤーファクトリクラスにツリーの地区情報も渡して、geojsonに登録されている地区をfilterする。
                    var layer = DistrictLayerFactory.getGeoJsonLayers(selectLayer);
                    this._layerControl.addLayer(layer, this.SELECT_LAYER_NO);
                    // this._layerControl4adminMap.addLayer(layer, this.SELECT_LAYER_NO);
                    // 現況レイヤーのポップアップを表示させるため、選択レイヤーは背面に持っていく
                    this._layerControl.layers[this.SELECT_LAYER_NO].bringToBack();
                }));
        },

        /**
         *  現況レイヤーの表示をトグルする
         */
        toggleLayer: function () {
            console.log('toggleLayer');
            if (!this._isEvacOrderPresent) {
                this.chain.info('現在発令されている避難情報はありません', '', function () {
                    this.chain.hide();
                });
                return;
            }

            var targetLayer = this._layerControl.layers[this.LATEST_STATE_LAYER_NO];
            if (this._latestLayerState === this.LATEST_LAYER_STATE.ON) {
                this._latestLayerState = this.LATEST_LAYER_STATE.OFF;
                this.mapCntlBtn.innerText = '発令済避難情報表示';
                this._layerControl.removeLayer(targetLayer);
            } else {
                this._latestLayerState = this.LATEST_LAYER_STATE.ON;
                this.mapCntlBtn.innerText = '発令済避難情報非表示';
                this._layerControl.addLayer(targetLayer, this.LATEST_STATE_LAYER_NO);
                // this._layerControl.layers[this.LATEST_STATE_LAYER_NO].bringToBack();
                this._layerControl.toFront(this.LATEST_STATE_LAYER_NO);
            }
        },

        toggleAllDistricts: function () {
            if (this._latestAllDistrictLayerState === this.ALLDISTRICT_LAYER_STATE.ON) {
                console.log('hide');
                this.hideAllDistrict();
            } else {
                console.log('show');
                this.showAllDistrict();
            }
        },

        showAllDistrict: function () {
            this._latestAllDistrictLayerState = this.ALLDISTRICT_LAYER_STATE.ON;
            array.forEach(this.grid._rows, function (row) {
                var data = row.evacRecommendType;
                if (data.status === '' || data.status === '0') {
                    array.forEach(data.districtCdList, function (districtCd) {
                        this.selectDistrictLayerFromGridCheck(districtCd, true,
                            this.getColor4EvacOrderType(data.evacOrderType), this._layerControl4adminMap);
                    }, this);
                }
            }, this);
        },

        hideAllDistrict: function () {
            this._latestAllDistrictLayerState = this.ALLDISTRICT_LAYER_STATE.OFF;
            // this.showAllDistrictsBtn.innerText = '全対象地区を表示';
            array.forEach(this.grid._rows, function (row) {
                var data = row.evacRecommendType;
                if (data.status === '' || data.status === '0') {
                    array.forEach(data.districtCdList, function (districtCd) {
                        this.selectDistrictLayerFromGridCheck(
                            districtCd, false, null, this._layerControl4adminMap);
                    }, this);
                }
            }, this);
        },

        /**
         * 地区コードを受け取り、地図上での地区の選択状態をトグルする
         */
        selectDistrictLayerFromGridCheck: function (districdCd, isSelected, color, layerControl) {
            // 対応する避難地区情報をもつレイヤーを選択する
            var layerList = layerControl.layers[this.SELECT_LAYER_NO].getLayers();
            array.some(layerList, function (layer) {
                // 背景地図レイヤーはfeatureを持っていないため事前に確認する
                // D_CDは地区レイヤーに設定する地区ID。geojson、レイヤーのプロパティjson、treejsonに設定されている。
                var layerId = layer.feature && layer.feature.properties.D_CD;
                if (layerId === districdCd) {
                    var options = layer.options || layer._layers[layer._leaflet_id - 1].options; //jshint ignore:line
                    if (isSelected) {
                        options.selectDistrictLayer(layer, color);
                    } else {
                        options.deselectDistrictLayer(layer);
                    }
                    return true; // break
                }
            });
        }

    });
});
