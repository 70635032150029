/**
 * 基底モジュール。
 * @module app/waterworks/_WaterworksBase
 */
define([
    'module',
    'dojo/_base/declare',
    'idis/view/page/_PageBase',
    'idis/util/DateUtils',
    'dojo/_base/lang',
    'idis/service/Requester',
    'idis/view/Loader',
    'app/model/DisasterInfo',
    // 以下、変数で受けないモジュール
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button'
], function(module, declare, _PageBase, DateUtils, lang, Requester, Loader, DisasterInfo) {

    return declare(module.id.replace(/\//g, '.'), _PageBase,
    {

        baseClass: 'idis-Page idis-Page--waterworks',        
        
        timestampItems : null,

        _IncidentDispList: [],

        _disasterDispList: [],

        _defaultSelectItem: null,
        
        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);           
            
            // Timestamp型の項目名を配列に設定
            this.timestampItems = 
                [
                    'entryDatetime',
                    'updateDatetime',
                    'hasseinichiji',
                    'fukkyuumikomi',
                    'fukkyunichiji',
                    'torikomijikoku',
                    'fukkyumikomi00',
                    'fukkyumikomi01',
                    'fukkyumikomi02',
                    'fukkyumikomi03',
                    'fukkyumikomi04'
                ];
        },    

        /**
         * ダイアログを初期化する。
         */
        initDialog: function(key, items) {
            // clear
            document.querySelectorAll('.tdval').forEach(el => el.innerHTML = '');

            // value setting
            var keys = Object.keys(items);
            var self = this;
            keys.forEach((wd) => {
                // 要素と一致する箇所に値をセット
                if(self[wd] !== null && self[wd] !== undefined) { 
                    if(this.timestampItems.indexOf(wd) !== -1) {
                        // 日時の項目である場合は日時フォーマットに変換
                        self[wd].innerHTML = items[wd] ? DateUtils.format(items[wd]) : '-';
                    } else if(wd === 'facilityKind'){
                        self[wd].innerHTML = items[wd] ?
                        items[wd] === '1' ? '1：運搬給水':
                        items[wd] === '2' ? '2：拠点給水':
                        '-':'-';
                    } else if( wd === 'higainoshurui'){                    
                        self[wd].innerHTML = items[wd] ?
                        items[wd] === '1' ? '1:庁舎・事業所等施設':
                        items[wd] === '2' ? '2:ブロック塀（庁舎・事業所等施設）':
                        items[wd] === '3' ? '3:水道施設（上水）':
                        items[wd] === '5' ? '5:その他':
                        items[wd] === '6' ? '6:状況報告':
                        '-':'-';
                    } else if(wd === 'higaikibo') {
                        self[wd].innerHTML = items[wd] ?
                        items[wd] === '1' ? '1:軽微被害なし':
                        items[wd] === '2' ? '2:小':
                        items[wd] === '3' ? '3:中':
                        items[wd] === '4' ? '4:大':
                        '-':'-';
                    } else if(wd === 'shinkokudo') {
                        self[wd].innerHTML = items[wd] ?
                        items[wd] === '1' ? '1:軽微被害なし':
                        items[wd] === '2' ? '2:小':
                        items[wd] === '3' ? '3:中':
                        items[wd] === '4' ? '4:高':
                        '-':'-';
                    } else if(wd === 'shiminriyoshisetsu') {
                        self[wd].innerHTML = items[wd] ?
                        items[wd] === '0' ? '0：市民利用施設以外':
                        items[wd] === '1' ? '1：市民利用施設':
                        '-':'-';
                    } else if(wd === 'invalidFlg'){
                        self[wd].innerHTML = items[wd] ?
                        items[wd] === '0' ? '0：有効':
                        items[wd] === '1' ? '1：無効':
                        '-':'-';
                    } else {
                        self[wd].innerHTML  = items[wd];
                    }
                }
            }); 
        },

        /**
         * 水道局災害名のセレクタ一覧を生成
         */
        getIncidentList: function () {
            return Loader.wait(
                Requester.get('api/waterworks/incident').then(
                    lang.hitch(this, function (data) {
                        if(data !== null && data !== undefined){
                            for(var index = 0; index < data.items.length; index++){
                                var item = data.items[index];
                                var incidentName = item.incidentName;
                                if(incidentName.length > 32){
                                    incidentName = incidentName.substr(0,32) + '...';
                                }
                                this._IncidentDispList[index] = { key: item.incidentId, val: incidentName };
                            }                            
                        }
                        return this._IncidentDispList;
                    })
                )
            );
        },
        
        /**
         * 災害名のセレクタ一覧を生成
         */
        getDisasterList: function () {
            return Loader.wait(
                Requester.get('api/waterworks/incidentlist').then(
                    lang.hitch(this, function (data) {
                        if(data !== null && data !== undefined){
                            // 災害IDの降順
                            var disasterList = data.items;
                            disasterList.sort((a,b) => b.disasterId-a.disasterId);
                            var listIndex = 0;
                            for(var index = 0; index < disasterList.length; index++){
                                var item = disasterList[index];
                                // （水道局）災害選択 初期値の取得
                                if(item.relatedFlg === '1'){
                                    if(this._defaultSelectItem === null &&  this._defaultSelectItem !== undefined && !item.delFlg){
                                        if(item.disasterId === parseInt(DisasterInfo.getDisasterId())) {
                                            this._defaultSelectItem = {
                                                incidentId: item.incidentId,
                                                disasterId: item.disasterId
                                            };
                                        }
                                    }
                                    var disasterName = item.disasterName;
                                    if(disasterName.length > 32){
                                        disasterName = disasterName.substr(0,32) + '...';
                                    }
                                    this._disasterDispList[listIndex] = { key: item.disasterId, val: disasterName, incident: item.incidentId };
                                    listIndex++;
                                }
                            }                            
                        }
                        return this._disasterDispList;
                    })
                )
            );
        },
    });
});
