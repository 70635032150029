/**
 * 共有先選択用入力パーツ(複数選択可)
 * @module app/view/form/HldOrganizationMultiSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/string',
    'dijit/form/TextBox',
    'dojo/dom-style',
    'idis/view/form/TreeChecker',
    'idis/view/tree/CheckTree',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    './HldOrganizationMultiSelector'
], function(module, declare, lang, dStr, TextBox,
     domStyle, TreeChecker, CheckTree, CacheStoreModel, CacheTreeRest, HldOrganizationMultiSelector) {
    /**
     * 対応課選択用パーツ。
     * @class HldOrganizationMultiSelector
     * @extends module:idis/view/form/TreeChecker~TreeChecker
     */
    return declare(module.id.replace(/\//g, '.'), HldOrganizationMultiSelector,
        /** @lends module:idis/view/tree/HldOrganizationMultiSelector~HldOrganizationMultiSelector# */ {
        // 選択ダイアログのタイトル
        title: '共有先選択',

        // ツリー・モデル
        model: null,

        emptyValueLabel: '未選択',

        constructor : function() {
            this.inherited(arguments);

            // 宛先ですべての組織を選択できるようにするためには、iheritedしているmodelを利用する。つまり、以下modelとonModelSettingChangeを消す。
            // 組織を0県受け取るようダミーの市町コードを設定
            var municipalityCd = '000000';
            var isPreventionOrg = false;
            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/chronologies//selector/'+ municipalityCd + '/' + isPreventionOrg
                })
            });
        },

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
        },

        
        // 市町セレクタ、災害対策本部のみ表示かどうかが変更された際、市町内の組織を取得し直す
        onModelSettingChange : function(municipalityCd, isPreventionOrg) {
            if(!municipalityCd){
                return false;
            }

            // 一旦ツリーを削除し、再構築する
            if(this.tree){
                this.tree.destroyRecursive();
            }
            var store = new CacheTreeRest({
                target: '/api/chronologies/selector/' + municipalityCd + '/' + isPreventionOrg
            });
            // 
            // var store = new CacheTreeRest({
            //     target: '/api/organization/selector/' + municipalityCd 
            // });
            // TODO: この部分で[Cannot read property 'setAttribute' of null] というエラーを吐く場合があるが、
            // 画面上の不具合はないため対応を保留中。
            this.model = new CacheStoreModel({
                store: store
            });

            this.tree = new CheckTree({
                model : this.model
            }, this.treeNode);

            // 再配置する
            this.tree.placeAt(this.parentTreeNode);
            this.tree.startup();

            return this.tree.onLoadDeferred;
        }
    });
});
