define([
], function() {
    return {
        optionConverter: function(options, convertType){
            //leaflet側で定義しているリスト
            var importReplaceList = [
                'drawType',
                'color',
                'fillColor',
                'opacity',
                'fillOpacity',
                'weight',
                'radius',
                'iconUrl',
                'iconSize',
                'iconAnchor',
                'borderColor',
                'borderWidth',
                'borderOpacity',
                'fileList',
                'height',
                'width',
                'comment',
                'fontColor',
                'fontSize',
                'title',
                'description',
                'bothArrow'
            ];
            //TanJSONで定義する必要があるリスト
            var exportReplaceList = [
                '_markerType',
                '_color',
                '_fillColor',
                '_opacity',
                '_fillOpacity',
                '_weight',
                '_radius',
                '_iconUrl',
                '_iconSize',
                '_iconAnchor',
                '_borderColor',
                '_borderWidth',
                '_borderOpacity',
                'fileList',
                'height',
                'width',
                'comment',
                '_fontColor',
                '_fontSize',
                'name',
                'description',
                'bothArrow'
            ];
            var defaultValue = {
                color      : '#3388ff',
                fillColor  : '#3388ff',
                opacity    : 1,
                fillOpacity: 0.2,
                weight     : 4
            };
            if(convertType === 'import'){
                return this.replacePropName(options, exportReplaceList, importReplaceList, defaultValue);
            }else if(convertType === 'export'){
                return this.replacePropName(options, importReplaceList, exportReplaceList, defaultValue);
            }
        },

        replacePropName: function(options, usedTo, willbe, defValue){
            if(!!options){
                var replacedOptions = {};
                for(var propName in options){
                    //おまじない的な
                    if(!options.hasOwnProperty(propName)){continue;}
                    if(usedTo.indexOf(propName)===-1){
                        continue;
                    }
                    if(options[propName]==='' || options[propName]===undefined){
                        options[propName] = defValue[propName];
                    }
                    replacedOptions[willbe[usedTo.indexOf(propName)]] = options[propName];
                }
                return replacedOptions;
            }
        }
    };
});
