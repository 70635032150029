/**
 * <モジュールの説明>
 * @module app/view/page/<ウィジェット名>
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/text!./templates/DamageReportFormDialog.html', // テンプレート文字列
    'idis/view/page/_PageBase', // 共通基底クラス
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'dojo/dom-style',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',
    'idis/view/form/CheckGroup',
    'dijit/form/RadioButton',
    'app/view/form/DamageMunicipalitySelector',
    'dijit/form/Form',
    // 'app/view/form/CustomizableMunicipalitySelector',
    'app/view/form/OrganizationSelector',
    'app/view/form/DisasterSelector',
    'app/damage/damagetype/DamageTypeMultiSelector',
], function (module, declare, lang, Deferred, JSON, template,
    _PageBase, DisasterInfo, InfoDialog, DialogChain, Loader, UserInfo, Requester, domStyle) {
    /**
     * <クラスの説明>
     * @class <ウィジェット名>
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/<ウィジェット名>~<ウィジェット名># */ {
            // テンプレート文字列
            templateString: template,
            _disasterId: null,
            isResizeDialog: false,

            constructor: function () {
                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);
                // 災害IDを取得
                this._disasterId = DisasterInfo.getDisasterId();
                if (!this._disasterId) {
                    this._disasterId = 1;
                    console.warn('災害IDが設定されていません。');
                }
            },

            startup: function () {
                var municipalityCd = this._municipalityCd;
                var deptCd = this._deptCd;
                var disasterId = this._disasterId;
                this.setInitValue(municipalityCd,deptCd,disasterId);
                // this.own(topic.subscribe(this.DAMAGE_GRID_TYPE_SELECTED, lang.hitch(this, function (data) {
                //     this.setDamageType(data);
                //     })))
            },

            /**
             * 災害ID、区、所属をデフォルトで設定
             */

             setInitValue: function (municipalityCd,deptCd,disasterId) {
                 if (municipalityCd){
                    this.municipalityCd.set('value',municipalityCd.shift());
                 }
                 if (deptCd){
                    this.hldOrganization.set('value','D' + deptCd);
                 }
                 if(disasterId){
                    this.disasterId.set('value',disasterId);
                 }
            },
            /**
             * 報告種別が選択された際の挙動
             */
            onReportTypeChange: function () {

                var reportType = this.form.get('value').reportType;
                var top = this.domNode.parentNode.parentNode.style.top.replace('px', '');
                var left = this.domNode.parentNode.parentNode.style.left.replace('px', '');
                if (reportType === '1') {
                    domStyle.set(this.disasterIdBlock, 'display', '');
                    domStyle.set(this.municipalityCdBlock, 'display', '');
                    domStyle.set(this.hldOrganizationBlock, 'display', '');
                    domStyle.set(this.reportStatusBlock, 'display', '');
                    domStyle.set(this.urgencyTypeBlock, 'display', '');
                    domStyle.set(this.DamageGridTypeSelectorBlock, 'display', '');
                    if (!this.isResizeDialog) {
                        this.domNode.parentNode.parentNode.style.top = (parseInt(top) - 136) + 'px';
                        this.domNode.parentNode.parentNode.style.left = (parseInt(left) - 106) + 'px';
                        this.isResizeDialog = true;
                    }
                } else if (reportType === '2') {
                    domStyle.set(this.disasterIdBlock, 'display', '');
                    domStyle.set(this.municipalityCdBlock, 'display', '');
                    domStyle.set(this.hldOrganizationBlock, 'display', '');
                    domStyle.set(this.reportStatusBlock, 'display', 'none');
                    domStyle.set(this.urgencyTypeBlock, 'display', '');
                    domStyle.set(this.DamageGridTypeSelectorBlock, 'display', '');
                    if (!this.isResizeDialog) {
                        this.domNode.parentNode.parentNode.style.top = (parseInt(top) - 136) + 'px';
                        this.domNode.parentNode.parentNode.style.left = (parseInt(left) - 106) + 'px';
                        this.isResizeDialog = true;
                    }
                } else {
                    domStyle.set(this.disasterIdBlock, 'display', 'none');
                    domStyle.set(this.municipalityCdBlock, 'display', 'none');
                    domStyle.set(this.hldOrganizationBlock, 'display', 'none');
                    domStyle.set(this.reportStatusBlock, 'display', 'none');
                    domStyle.set(this.urgencyTypeBlock, 'display', 'none');
                    domStyle.set(this.DamageGridTypeSelectorBlock, 'display', 'none');

                    if (this.isResizeDialog) {
                        this.domNode.parentNode.parentNode.style.top = (parseInt(top) + 136) + 'px';
                        this.domNode.parentNode.parentNode.style.left = (parseInt(left) + 106) + 'px';
                        this.isResizeDialog = false;
                    }
                }
            },

            // 帳票出力を行う前に入力チェックを行う
            onOutputExcelButtonClick: function () {

                if (!this._disasterId) {
                    this.chain.info('災害名が正しく選択されていません。', 'エラー');
                    return false;
                }
                var aggrDatetime = this.form.get('value').aggrDatetime;
                var reportType = this.form.get('value').reportType;
                // 全帳票共通で、日時は必須項目
                if (!this.aggrDatetime.validate() || !aggrDatetime) {
                    this.chain.info('日時が選択されていません。', 'エラー');
                    return false;
                }
                if (!this.DamageTypeMultiSelector.value) {
                    this.chain.info('被害の種類が選択されていません。', 'エラー');
                    return false;
                }

                //repdateをYYYYMMDD形式に変換
                var repdate = this.aggrDatetime._date.displayedValue.replace(/\//g, '');
                //reptimeをhhss形式に変換
                var reptime = this.aggrDatetime._time.displayedValue.replace(':', '');

                var disid = this._disasterId;

                var postData = this.form.get('value');
                if (postData.urgencyType.length === 0) {
                    delete postData.urgencyType;
                }
                if (postData.reportStatus.length === 0) {
                    delete postData.reportStatus;
                }
                // if(postData.damageType.length===0){
                //     delete postData.damageType;
                // }
                if (reportType === '2') {
                    postData.reportStatus = '41';
                }
                if (this.DamageTypeMultiSelector.value.length > 0) {
                    postData.damageType = this.DamageTypeMultiSelector.value;
                }
                postData.aggrDatetime = postData.aggrDatetime.getTime();
                var param = Object.keys(postData).map(function (key) {
                    return key + "=" + postData[key]; //postDataで渡ってきた文字列をkeyとvalueの形で繋ぐ
                }).join("&");

                Loader.wait(Requester.get('/api/damageReports/getDamageReportId?' + param, {
                    headers: { 'Content-Type': 'application/json; charset=utf-8' }
                })).then(lang.hitch(function (data) {
                    console.log(data);

                    var reqid = 'DAMAGE_OVERVIEW_EX';
                    var fileName = 'DamageOverview.xlsx';
                    var fileNameJp = '被害情報概況.xlsx';
                    var paramList = [
                        { key: 'disid', value: disid },
                        { key: 'repdate', value: repdate },
                        { key: 'reptime', value: reptime }

                    ];
                    if (reportType === '0') {
                        //一覧選択時

                        reqid = 'DAMAGE_LIST_EX';
                        fileName = 'DamageList.xlsx';
                        fileNameJp = '被害情報一覧.xlsx';
                        paramList = [
                            { key: 'disid', value: disid },
                            { key: 'repdate', value: repdate },
                            { key: 'reptime', value: reptime },
                            { key: 'damageViewType', value: UserInfo.getDamageViewType() },
                            { key: 'humanDamageFlg', value: UserInfo.getHumanDamageView() },
                            { key: 'municipalityCd', value: UserInfo.getMunicipalityCd() }
                        ];

                    } else if (reportType === '1') {
                        //概況選択時
                        reqid = 'HEADQUARTERS_MEETING_FILE_EX';
                        fileName = 'HeadquartersMeetingFile.xlsx';
                        fileNameJp = '本部報告資料.xlsx';
                        paramList = [
                            { key: 'repdate', value: repdate },
                            { key: 'reptime', value: reptime },
                            { key: 'disid', value: disid }
                        ];
                        if (data && data.length !== 0) {
                            paramList.push({ key: 'damageReportId', value: data.join(',') });
                        }
                    } else if (reportType === '2') {
                        // 様式2選択時
                        reqid = 'PRESS_RELEASE_FILE_EX';
                        fileName = 'PressReleaseFile.xlsx';
                        fileNameJp = '報道発表資料.xlsx';
                        paramList = [
                            { key: 'repdate', value: repdate },
                            { key: 'reptime', value: reptime },
                        ];
                        if (data && data.length !== 0) {
                            paramList.push({ key: 'damageReportId', value: data.join(',') });
                        }
                    }


                    var data4xoblosDownload = {
                        fileName: fileName,
                        fileNameJp: fileNameJp,
                        reqid: reqid,
                        paramList: paramList,
                        fileType: 'excel'
                    };
                    return data4xoblosDownload;
                })).then(lang.hitch(this, function (data4xoblosDownload) {

                    Loader.wait(
                        this.download(data4xoblosDownload, data4xoblosDownload.fileNameJp)
                            .then(lang.hitch(this, function () {
                                this.infoDialog = new InfoDialog({
                                    title: 'ダウンロード完了',
                                    content: 'ダウンロードが完了しました。'
                                });
                                this.infoDialog.show();

                            }), lang.hitch(this, function (error) {
                                console.error(error);
                                this.infoDialog = new InfoDialog({
                                    title: 'エラー',
                                    content: 'エラーが発生しました。管理者にお問い合わせください。'
                                });
                                this.infoDialog.show();
                            })
                            ));
                }))/* ) */;
            },



            //帳票出力
            download: function (data, baseFileName) {
                var deferred = new Deferred();

                var xhr = new XMLHttpRequest();
                xhr.open('POST', '/api/xoblos/download', true);
                xhr.responseType = 'arraybuffer';
                xhr.setRequestHeader('Content-Type', 'application/json');
                xhr.onload = function () {

                    // エラー時は処理を止める
                    if (xhr.status !== 200) {
                        deferred.reject();
                        return;
                    }

                    // バイナリデータを取得
                    var arrayBuffer = this.response;
                    var blob = new Blob([arrayBuffer], { type: 'application/octet-stream' });
                    var fileName = baseFileName.replace(/^.*"(.*)"$/, '$1');

                    // IE10+
                    if (window.navigator.msSaveOrOpenBlob) {
                        window.navigator.msSaveOrOpenBlob(blob, fileName);
                    } else {
                        // 擬似的にAタグを作成
                        var link = document.createElement('a');
                        link.style = 'display: none';
                        document.body.appendChild(link);

                        // AタグのURLにバイナリデータをセット
                        var url = window.URL.createObjectURL(blob);
                        link.href = url;

                        // ファイル名をセット
                        link.download = fileName;

                        // 擬似的にリンクをクリック
                        link.click();
                        // 参照を解放
                        window.URL.revokeObjectURL(url);
                        link.remove();
                    }

                    deferred.resolve();

                };
                delete data.fileNameJp;
                xhr.send(JSON.stringify(data));

                return deferred.promise;
            },
            /**
             * 月や日付を2桁にゼロpaddingする
             */
            _zeroPadding: function (month) {
                return ('00' + month).slice(-2);
            },

            /**
             * キャンセルボタン押下
             */
            onCancel: function () {
                this.reportType.set('value', 0);
                this.isResizeDialog = false;
                this.getParent().hide();
            }
        });
});
