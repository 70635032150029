/**
 * 各エリアコード
 * @module app/model/KisyoAreaCode
 */
define([
    'dstore/Memory'
], function(Memory) {
    /**
     * シングルトンを返す。
     */
    var area = new Memory({
        data: [
            {
                code:'0110000',
                name:'札幌市',
                parentCode:'010031'
            },
            {
                code:'0110100',
                name:'中央区',
                parentCode:'010031'
            },
            {
                code:'0110200',
                name:'北区',
                parentCode:'010031'
            },
            {
                code:'0110300',
                name:'東区',
                parentCode:'010031'
            },
            {
                code:'0110400',
                name:'白石区',
                parentCode:'010031'
            },
            {
                code:'0110500',
                name:'豊平区',
                parentCode:'010031'
            },
            {
                code:'0110600',
                name:'南区',
                parentCode:'010031'
            },
            {
                code:'0110700',
                name:'西区',
                parentCode:'010031'
            },
            {
                code:'0110800',
                name:'厚別区',
                parentCode:'010031'
            },
            {
                code:'0110900',
                name:'手稲区',
                parentCode:'010031'
            },
            {
                code:'0111000',
                name:'清田区',
                parentCode:'010031'
            },
            {
                code:'010010',
                name:'宗谷北部',
                parentCode:''
            },
            {
                code:'010011',
                name:'宗谷南部',
                parentCode:''
            },
            {
                code:'010012',
                name:'利尻・礼文',
                parentCode:''
            },
            {
                code:'010020',
                name:'上川北部',
                parentCode:''
            },
            {
                code:'010021',
                name:'上川中部',
                parentCode:''
            },
            {
                code:'010022',
                name:'上川南部',
                parentCode:''
            },
            {
                code:'010023',
                name:'留萌北部',
                parentCode:''
            },
            {
                code:'010024',
                name:'留萌中部',
                parentCode:''
            },
            {
                code:'010025',
                name:'留萌南部',
                parentCode:''
            },
            {
                code:'010030',
                name:'石狩北部',
                parentCode:''
            },
            {
                code:'010031',
                name:'石狩中部',
                parentCode:''
            },
            {
                code:'010032',
                name:'石狩南部',
                parentCode:''
            },
            {
                code:'010033',
                name:'北空知',
                parentCode:''
            },
            {
                code:'010034',
                name:'中空知',
                parentCode:''
            },
            {
                code:'010035',
                name:'南空知',
                parentCode:''
            },
            {
                code:'010036',
                name:'後志北部',
                parentCode:''
            },
            {
                code:'010037',
                name:'羊蹄山麓',
                parentCode:''
            },
            {
                code:'010038',
                name:'後志西部',
                parentCode:''
            },
            {
                code:'010040',
                name:'網走西部',
                parentCode:''
            },
            {
                code:'010041',
                name:'網走東部',
                parentCode:''
            },
            {
                code:'010042',
                name:'網走南部',
                parentCode:''
            },
            {
                code:'010043',
                name:'北見地方',
                parentCode:''
            },
            {
                code:'010044',
                name:'紋別北部',
                parentCode:''
            },
            {
                code:'010045',
                name:'紋別南部',
                parentCode:''
            },
            {
                code:'010050',
                name:'釧路北部',
                parentCode:''
            },
            {
                code:'010051',
                name:'釧路中部',
                parentCode:''
            },
            {
                code:'010052',
                name:'釧路南東部',
                parentCode:''
            },
            {
                code:'010053',
                name:'釧路南西部',
                parentCode:''
            },
            {
                code:'010054',
                name:'根室北部',
                parentCode:''
            },
            {
                code:'010055',
                name:'根室中部',
                parentCode:''
            },
            {
                code:'010056',
                name:'根室南部',
                parentCode:''
            },
            {
                code:'010057',
                name:'十勝北部',
                parentCode:''
            },
            {
                code:'010058',
                name:'十勝中部',
                parentCode:''
            },
            {
                code:'010059',
                name:'十勝南部',
                parentCode:''
            },
            {
                code:'010060',
                name:'胆振西部',
                parentCode:''
            },
            {
                code:'010061',
                name:'胆振中部',
                parentCode:''
            },
            {
                code:'010062',
                name:'胆振東部',
                parentCode:''
            },
            {
                code:'010063',
                name:'日高西部',
                parentCode:''
            },
            {
                code:'010064',
                name:'日高中部',
                parentCode:''
            },
            {
                code:'010065',
                name:'日高東部',
                parentCode:''
            },
            {
                code:'010070',
                name:'渡島北部',
                parentCode:''
            },
            {
                code:'010071',
                name:'渡島東部',
                parentCode:''
            },
            {
                code:'010072',
                name:'渡島西部',
                parentCode:''
            },
            {
                code:'010073',
                name:'檜山北部',
                parentCode:''
            },
            {
                code:'010074',
                name:'檜山南部',
                parentCode:''
            },
            {
                code:'010075',
                name:'檜山奥尻島',
                parentCode:''
            }
        ]
    });

    area.fetchSync().forEach(function(item) {
        area[item.code] = item.name;
    });

    return area;
});
