/**
 * 管理対象市町選択用入力パーツ(複数選択可)
 * @module app/view/form/MunicipalityListMultiSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/dom-style',
    'idis/store/CacheStoreModel',
    'idis/store/CacheTreeRest',
    'idis/view/form/TreeChecker',
    'idis/model/UserInfo'
], function(module, declare, domStyle, CacheStoreModel, CacheTreeRest, TreeChecker,
    UserInfo) {
    /**
     * 管理対象市町選択用パーツ
     * @class MunicipalityListMultiSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeChecker,
        /** @lends module:app/view/tree/MunicipalityListMultiSelector~MunicipalityListMultiSelector# */ {
        // 選択ダイアログのタイトル
        title: '対象市/行政区選択',

        // ツリー・モデル
        model: null,

        constructor : function() {
            var param = '';
            var municipalityCds = UserInfo.getMunicipalityCds();
            if(municipalityCds) {
                var firstFlg = true;
                for (var i = 0; i < municipalityCds.length; i++) {
                    if(firstFlg) {
                        param += '?';
                        firstFlg = false;
                    } else {
                        param += '&';
                    }
                    param += 'array[]=' + municipalityCds[i];
                }
            }

            console.debug('param = ' + param);

            this.model = new CacheStoreModel({
                store: new CacheTreeRest({
                    target: '/api/municipalities/municipality4Tree' + param
                })
            });
        },
        
        /**
         * 選択した値の設定
         */
        _setValueAttr: function() {
            // 継承した親クラスの処理
            this.inherited(arguments);
            // 以下、当モジュール固有の処理
            // 選択結果が20文字を超えた場合、・・・(三点リーダ)で省略する
//            domStyle.set(this.status, {
//                'max-width': '20em',
//                'white-space': 'nowrap',
//                'overflow': 'hidden',
//                'text-overflow': 'ellipsis'
//            });
        }
    });
});
