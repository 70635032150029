define([
    'module',
    'dojo/_base/declare',
    'dojo/Deferred',
], function (module, declare, Deferred) {

    return new declare(null, {
        // ウィジェットのルート要素に付与されるCSSクラス
        baseClass: '',
        /* globals google */
        /* jshint nonew:false */
        directionsService: null,
        googleGeoService: null,

        searchGeocode: function (options) {
            var deferred = new Deferred();
            if (this.googleGeoService === null) {
                this.googleGeoService = new google.maps.Geocoder();
            }
            this.googleGeoService.geocode(options,
                function (result, status) {
                    console.log(result);
                    deferred.progress(result, status);

                    if (status === google.maps.DirectionsStatus.OK) {
                        deferred.resolve(result);
                    } else {
                        deferred.reject(result);
                    }
                });
            return deferred.promise;
        },
        searchRoute: function (options) {
            var deferred = new Deferred();
            if (this.directionsService === null) {
                this.directionsService = new google.maps.DirectionsService();
            }
            this.directionsService.route(options,
                function (result, status) {
                    console.log(result);
                    deferred.progress(result, status);

                    if (status === google.maps.DirectionsStatus.OK) {
                        deferred.resolve(result);
                    } else {
                        deferred.reject(result);
                    }
                });
            return deferred.promise;
        }
    })();
});