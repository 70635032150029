/**
 * 災害体制の列定義一覧。
 * @module app/weatherinfo/view/WeatherInfoColumn
 */
define([
    'idis/view/grid/helper',
    'dojo',
    'dojo/on',
    '../model/kindCode'
], function(helper, dojo, on, kindCode) {
    var statusFormater = {
		'発表' : '◎',
		'継続' : '〇',
		'特別警報から警報' : '◎',
		'特別警報から注意報' : '◎',
		'警報から注意報' : '◎',
		'解除' : '×',
		'発表警報・注意報はなし' : ''
	};

	var getClassName = function(item, warnKey){
		if(!item){
			return kindCode.getClass(warnKey) + '_HEADER';
		}
		if(item.warningMap!==null&&item.warningMap[kindCode.getCode(warnKey)]){
			return kindCode.getClass(warnKey);
		}
	};

	var formatWeather = function(item, warnKey){
		if(!item){
			return;
        }
		if(item.warningMap!==null){
			var status = item.warningMap[kindCode.getCode(warnKey)];
			if(status){
				return statusFormater[status];
			}
		}
		return '';
    };
    
    return {
		// cityName: {field: 'cityName', label: '市町村.', sortable: false,
		cityName: {field: 'cityName', label: '', sortable: false,
            renderCell: function(item){
                var actions = dojo.create('a', {href: '#'});
                actions.innerHTML = item.cityName;
                var gridNode = this.grid.domNode;
                actions.onclick = function() {
                    on.emit(gridNode, 'moveToDetail', {item: item.areaCode});
                };
                // HTMLとしてウィジェットに紐付くDOMノードを返す
                return actions;
            }
        },
		areaName: {field: 'areaName', label: '地域', sortable: false,
            renderCell: function(item){
                var actions = dojo.create('a', {href: '#'});
                actions.innerHTML = item.areaName;
                var gridNode = this.grid.domNode;
                actions.onclick = function() {
                    on.emit(gridNode, 'moveToDetail', {item: item.areaCode});
                };
                // HTMLとしてウィジェットに紐付くDOMノードを返す
                return actions;
            }
        },
        spWarnHeader: {
            field: 'spWarnHeader', id: 'spWarnHeader',
            label: '特別警報',
            sortable: false,
            children: [{
                    field: 'spRain', id: 'spRain',
                    label: '大雨',
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'RAIN_SPECIAL_WARNING');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'RAIN_SPECIAL_WARNING');
                }},
                {
                    field: 'spWind', id: 'spWind',
                    label: '暴風',
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'STORM_SPECIAL_WARNING');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'STORM_SPECIAL_WARNING');
                }},
                {
                    field: 'spWindSnow', id: 'spWindSnow',
                    label: '暴風雪',
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'STORM_SNOW_SPECIAL_WARNING');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'STORM_SNOW_SPECIAL_WARNING');
                }},
                {
                    field: 'spHeavySnow', id: 'spHeavySnow',
                    label: '大雪',
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'SNOW_SPECIAL_WARNING');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'SNOW_SPECIAL_WARNING');
                }},
                {
                    field: 'spWaves', id: 'spWaves',
                    label: '波浪',
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'WAVE_SPECIAL_WARNING');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'WAVE_SPECIAL_WARNING');
                }},
                {
                    field: 'spTide',
                    id: 'spTide',
                    label: '高潮',
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'STORM_SURGE_SPECIAL_WARNING');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'STORM_SURGE_SPECIAL_WARNING');
                }}
            ]
        },
        warnHeader: helper.column('warnHeader', '警報', {
            sortable: false,
            children: [
                helper.column('wnRain', '大雨', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'HEAVY_RAIN_WARNING');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'HEAVY_RAIN_WARNING');
                }}),
                helper.column('wnFlood', '洪水', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'FLOOD_WARNING');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'FLOOD_WARNING');
                }}),
                helper.column('wnWind', '暴風', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'STORM_WARNING');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'STORM_WARNING');
                }}),
                helper.column('wnWindSnow', '暴風雪', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'STORM_SNOW_WARNING');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'STORM_SNOW_WARNING');
                }}),
                helper.column('wnHeavySnow', '大雪', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'HEAVY_SNOW_WARNING');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'HEAVY_SNOW_WARNING');
                }}),
                helper.column('wnWaves', '波浪', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'WAVE_WARNING');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'WAVE_WARNING');
                }}),
                helper.column('wnTide', '高潮', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'STORM_SURGE_WARNING');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'STORM_SURGE_WARNING');
                }})
            ]
        }),
        advisoryHeader: helper.column('advisoryHeader', '注意報', {
            sortable: false,
            children: [
                helper.column('advRain', '大雨', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'HEAVY_RAIN_ADVISORY');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'HEAVY_RAIN_ADVISORY');
                }}),
                helper.column('advFlood', '洪水', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'FLOOD_ADVISORY');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'FLOOD_ADVISORY');
                }}),
                helper.column('advWind', '強風', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'STRONG_WIND_ADVISORY');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'STRONG_WIND_ADVISORY');
                }}),
                helper.column('advWindSnow', '風雪', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'WIND_AND_SNOW_ADVISORY');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'WIND_AND_SNOW_ADVISORY');
                }}),
                helper.column('advHeavySnow', '大雪', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'HEAVY_SNOW_ADVISORY');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'HEAVY_SNOW_ADVISORY');
                }}),
                helper.column('advWaves', '波浪', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'WAVE_ADVISORY');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'WAVE_ADVISORY');
                }}),
                helper.column('advTide', '高潮', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'STORM_SURGE_ADVISORY');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'STORM_SURGE_ADVISORY');
                }}),
                helper.column('advThunder', '雷', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'LIGHTNING_ADVISORY');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'LIGHTNING_ADVISORY');
                }}),
                helper.column('advMelt', '融雪', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'SNOW_MELTING_ADVISORY');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'SNOW_MELTING_ADVISORY');
                }}),
                helper.column('advFog', '濃霧', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'HEAVY_FOG_ADVISORY');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'HEAVY_FOG_ADVISORY');
                }}),
                helper.column('advDry', '乾燥', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'DRYING_ADVISORY');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'DRYING_ADVISORY');
                }}),
                helper.column('advAvalanche', 'なだれ', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'AVALANCHE_ADVISORY');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'AVALANCHE_ADVISORY');
                }}),
                helper.column('advLowtemp', '低温', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'LOW_TEMPERATURE_ADVISORY');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'LOW_TEMPERATURE_ADVISORY');
                }}),
                helper.column('advFrost', '霜', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'FROST_ADVISORY');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'FROST_ADVISORY');
                }}),
                helper.column('advIce', '着氷', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'ICING_ADVISORY');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'ICING_ADVISORY');
                }}),
                helper.column('advSnowStuck', '着雪', {
                    sortable: false,
                    className: function(item){
                        return getClassName(item, 'SNOW_ARRIVAL_ADVISORY');
                    },
                    formatter: function(v,item){
                        return formatWeather(item, 'SNOW_ARRIVAL_ADVISORY');
                }}) 
            ]
        })
    };
});























































































































































































































































































































































































































