/**
 * 消防局情報閲覧 モジュール
 * @module app/fdmaDisasterSituation/FdmaDisasterSituationPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/FdmaDisasterSituationPage.html',
    'idis/control/Router',
    'idis/store/IdisRest',
    'idis/view/dialog/InfoDialog',
    'app/fdmaDisasterSituation/_FdmaDisasterSituationBase',
    'app/fdmaDisasterSituation/FdmaDisasterSituationGrid',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/DateTimeInput',

], function(module,  declare, lang, template, Router, IdisRest, InfoDialog, _FdmaDisasterSituationBase) {
    /**
    * 検索条件格納用オブジェクト
    * @type {Object}
    */
    var store = {};
    /**
     * 消防局情報閲覧画面
     * @class fdmaDisasterSituationPage
    * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _FdmaDisasterSituationBase,
    /** @lends module:app/fdmaDisasterSituation/_FdmaDisasterSituationBase~_FdmaDisasterSituationBase# */ {
        // テンプレート文字列
        templateString: template,

        /**
        * データ格納用オブジェクト
        * @type {module:dstore/Store}
        */
        Store: null,

        constructor: function() {

            // form用のストアを生成する。
            this.store = new IdisRest({
                idProperty: 'fdmaDisasterSituationId',
                target: '/api/fdmaDisaster',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
        },

        /**
         * 画面のDOM要素を組み立てる際に呼ばれる
         */
        buildRendering: function() {

            this.inherited(arguments);

            // select要素の初期化
            this.initSetFdmaDisasterGroupList(this.disasterGroupList);
        },

        // HTML上にウィジェットが設置されてから呼ばれる
        startup: function() {
            this.inherited(arguments);

            // 時間を初期化する
            this.initTime();

            // グリッドを初期化する
            this.initGrid();
        },

        /**
         * 検索ボタンが押下された際の挙動。
         */
        onSubmit: function () {
            var startDate = new Date();
            var endDate = new Date();
            var value = this.form.get('value');

            // 時間未入力
            if (!value.updateTimeFrom && !value.updateTimeTo) {
                this.initTime();
            }

            // 登録日時From未入力
            if (!value.updateTimeFrom) {
                startDate.setTime(value.updateTimeTo.getTime() - (14 * 24 * 60 * 60 * 1000));
                this.updateTimeFrom._setValueAttr(startDate);
            }

            // 登録日時To未入力
            if (!value.updateTimeTo) {
                endDate.setTime(value.updateTimeFrom.getTime() + (14 * 24 * 60 * 60 * 1000));
                this.updateTimeTo._setValueAttr(endDate);
            }

            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGrid();
                } else {
                    console.debug('エラーあり');
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        // 検索時刻の初期値をセット
        initTime: function(){
            var startDate = new Date();
            var endDate = new Date();
            startDate.setDate(startDate.getDate()-14);
            this.updateTimeFrom._setValueAttr(startDate);
            this.updateTimeTo._setValueAttr(endDate);
        },

        /**
         * Gridを初期化する。
         */
        initGrid: function() {
            this.updateGrid();
        },

        updateGrid: function() {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.store.Filter();
            var value = this.form.get('value');

            if (value.updateTimeFrom) {
                var updateTimeFrom = new Date(value.updateTimeFrom).getTime();
                filter = filter.eq('updateTimeFrom', updateTimeFrom);
            }

            if (value.updateTimeTo) {
                var updateTimeTo = new Date(value.updateTimeTo).getTime();
                filter = filter.eq('updateTimeTo', updateTimeTo);
            }
        
            if(this.updateTimeFrom || this.updateTimeTo){
                var durationFrom = this.updateTimeFrom.get('value');
                var durationTo = this.updateTimeTo.get('value');

                // 入力チェック 2週間以上の期間の場合、検索処理を中断する
                if(durationTo.getTime() - durationFrom.getTime()  > 14 * 24 * 60 * 60 * 1000 + 60000) {
                    if (!this.infoDialog) {
                        this.infoDialog = new InfoDialog({
                            title : 'エラー',
                            content : '2週間を超えた期間を指定した検索はできません。2週間以内の期間を検索してください。'
                        });
                    }
                    this.infoDialog.show();
                    this.infoDialog=null;
                    return false;
                }
            }

            // 作成年月日
            var entryDatetime = value.disasterGroup.substr(0, 8);
            filter = filter.eq('entryDatetime', entryDatetime);

            // 災害グループID
            var disasterGroupNo = value.disasterGroup.substr(9);
            filter = filter.eq('disasterGroupNo', disasterGroupNo);

            // 過去報表示
            var pastReport = this.form.get('value').pastReport;
            filter = filter.eq('activeFlg', pastReport);

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.fdmaDisasterSituationGrid.set('collection', collection);
        }
    });
});