/**
 * 水位状況情報詳細画面用モジュール。
 * @module app/view/page/RiverLevelObservationDetailPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/dom-construct',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/topic',
    'dstore/Memory',
    'dojo/text!./templates/RiverLevelObservationDetailPage.html',
    'idis/view/page/_PageBase',
    'idis/store/IdisRest',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/model/UserInfo',
    'idis/control/Locator',
    'dijit/registry',
    'idis/view/Loader',
    './RiverDialogChain',
    'app/observation/view/form/DateTimeSelect',
    'app/observationstation/model/DataKind',
    // 以下、変数で受けないモジュール
    'app/view/form/ObservationRegionSelector',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/form/TextBox',
    'idis/view/form/DateTimeInput',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    // './RiverLevelCorrectDialog',
    './chart/RiverLevelCrossSectionChart',
    './RiverLegend',
    './grid/RiverLevelObservationDetailPageGrid',
    './FutureRiverLevelDetailDialog'
], function (
    module,
    array,
    declare,
    lang,
    JSON,
    domConstruct,
    domClass,
    domStyle,
    topic,
    Memory,
    template,
    _PageBase,
    IdisRest,
    Router,
    Requester,
    UserInfo,
    Locator,
    registry,
    Loader,
    RiverDialogChain,
    DateTimeSelect,
    DataKind
) {
    /**
     * 水位状況情報詳細画面
     * @class RiverLevelObservationDetailPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(
        module.id.replace(/\//g, '.'),
        _PageBase,
        /** @lends module:./RiverLevelObservationDetailPage~RiverLevelObservationDetailPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--riverlevelobs',

            // ストア
            store: null,

            // 時間モード
            mode: 10,

            lastRequestDateTime: '',

            // 変更フラグ
            changeFlg: 0,

            // 編集フラグ
            // correctFlg: false,

            // 初回制御用フラグ
            firstFlg: true,

            // 閲覧モードフラグ
            _viewMode: '0',

            constructor: function () {
                this.store = new IdisRest({
                    target: '/api/river/detailWithForecast'
                });
                // 閲覧モードフラグをセット
                // this._viewMode = Locator.getQuery().viewMode || '0';
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
                // 初期表示は、時間モード10分で最新の時間データ
                // 最新の観測情報を取得する
                this.own(this.dialog);
                this.initRegisterPage();
                Requester.get('/api/river/latestDataTime?timeMode=10').then(
                    lang.hitch(this, function (data) {
                        // 雨量データ格納用オブジェクト
                        this.store = new IdisRest({
                            idProperty: 'riverLevelId',
                            target: '/api/river/detailWithForecast'
                        });
                        // 連鎖ダイアログを登録
                        this.chain = RiverDialogChain.get(this);
                        // 日付セレクトボックスを生成・設置する
                        // Queryに日付がある場合はそれをセットする
                        var dateQuery = Number(Locator.getQuery().date);
                        if (dateQuery) {
                            data = dateQuery;
                        }
                        this.dateTimeSelect = new DateTimeSelect({
                            to: data
                        });

                        this.dateTimeSelect.placeAt(this.dateTimeSelectNode, 'only');
                        this.borderContainer.resize();
                    })
                );
                // this.own(this.correctDialog);
            },

            startup: function () {
                this.inherited(arguments);
                // this.setCorrectMode();
                // var roleCd = UserInfo.getRoleCd();
                // if(roleCd === ACL.ADMIN_USER) {
                // 	this.correctDialogArea.style.display='';
                // } else {
                // 	this.correctDialogArea.style.display='none';
                // }
                this.initForm();
            },

            initForm: function () {
                // 一覧からの遷移時、地域セレクタに選択した地域をセットする
                if (Locator.getQuery().regionCd) {
                    this.region.set('value', Locator.getQuery().regionCd);
                }
                // 閲覧モードの場合、予測水位入力ボタンを非表示
                // if (this._viewMode === '1') {
                //     domStyle.set(this.registerButton.domNode, 'display', 'none');
                // }
            },

            /**
             * DOM構築後に呼ばれる
             */

            postCreate: function () {
                this.inherited(arguments);
                // 日時の変更を監視
                this.own(
                    topic.subscribe(
                        DateTimeSelect.TOPIC.CHANGE_DATE_TIME,
                        lang.hitch(this, function (datetime) {
                            // 1回の操作で日付と時間が同時に変更される場合があり、このとき同じ日時で2回続けて変更イベントが発行される
                            // 同じ日時で連続でデータ取得のリクエストが飛ぶことを避けるため、前回の日時を保持し、異なる場合のみデータを取得するようにする
                            if (this.lastRequestDateTime !== datetime.date + ' ' + datetime.time) {
                                // データを取得する
                                this.store = new IdisRest({
                                    idProperty: 'riverLevelId',
                                    target: '/api/river/detailWithForecast'
                                });
                                var filter = new this.store.Filter();
                                // 観測日時をフィルターにセット
                                var date = new Date(datetime.date + ' ' + datetime.time).getTime();
                                // 詳細ボタンから飛んだ時に、500エラーの回避
                                if (isNaN(date)) {
                                    filter = filter.eq('date', Locator.getQuery().date);
                                } else {
                                    filter = filter.eq('date', date);
                                }
                                // [観測局]のセット
                                var value = this.form.get('value');
                                if (value.observatoryId) {
                                    filter = filter.eq('observatoryId', value.observatoryId);
                                } else {
                                    // 初期化時に非同期処理によりobservatoryIdが取得できていない場合、Locatorからセット
                                    filter = filter.eq('observatoryId', Locator.getQuery().observatoryId);
                                }
                                filter = filter.eq('timeMode', value.timeMode);
                                var collection = this.store.filter(filter);
                                this.grid.set('collection', collection);

                                collection.fetch().then(
                                    lang.hitch(this, function (data) {
                                        if (data.totalLength !== 0) {
                                            this.riverLevelId = data[0].riverLevelId;
                                            this.initText(data);
                                            this.initChart(data);
                                        }
                                    })
                                );
                            }
                            this.lastRequestDateTime = datetime.date + ' ' + datetime.time;
                        })
                    )
                );
                // 最新ボタンクリックを監視
                this.own(
                    topic.subscribe(
                        DateTimeSelect.TOPIC.CLICK_LATEST,
                        lang.hitch(this, function () {
                            // 最新の観測情報を取得
                            Requester.get('/api/river/latestDataTime?timeMode=' + this.timeMode).then(
                                lang.hitch(this, function (data) {
                                    // 観測日時セレクトボックスを再構築する
                                    // セレクトボックスの値が変更されれば、変更トピックが発火されて上記の「日時の変更を監視」でキャッチされ、対応するデータが取得される
                                    this.dateTimeSelect.rebuild(data);
                                })
                            );
                        })
                    )
                );
                this.initSelectBox();
                this.grid.changeMode(this.mode);
                // // グラフエリアを作る
                // this.chart = new RiverLevelCrossSectionChart();
                // this.controlContent.on('show', lang.hitch(this, function() {
                //     this.chart.create(this.chartNode, this.legend);
                // }));
            },

            /**
             * 時間モードの初期化を行う
             */
            setTimeModeSelect: function () {
                var obsInfos = this.observationStationsSelectBox.options;
                var observatoryId = this.observationStationsSelectBox.get('value');
                array.forEach(
                    obsInfos,
                    function (item) {
                        if (item.value === observatoryId) {
                            var timeMode;
                            // 時間観測局の場合、正時のみ
                            if (item.isHourObs) {
                                this.obsName = item.label;
                                domStyle.set(this.timeModeSelect.domNode, 'display', 'none');
                                domStyle.set(this.hourMode, 'display', '');
                                timeMode = 'hourly';
                            } else {
                                this.obsName = item.label;
                                domStyle.set(this.timeModeSelect.domNode, 'display', '');
                                domStyle.set(this.hourMode, 'display', 'none');
                                timeMode = this.timeModeSelect.get('value');
                            }
                            // 時間モードによって、グリッド更新
                            this.onChangeModeSelect(timeMode);
                        }
                    },
                    this
                );
            },

            // セレクトボックスの初期化を行う
            initSelectBox: function () {
                // 観測局セレクトボックスの初期化
                // デフォルトでは県全域の観測局を表示
                var promise = Requester.get(
                    '/api/observationStation/selectBox/?dataKind=' + DataKind.RIVER_LEVEL + '&regionCd='
                );
                Loader.wait(promise).then(
                    lang.hitch(this, function (data) {
                        this.observationStationsSelectBox.options = data;
                        var observatoryId = Locator.getQuery().observatoryId;
                        this.observationStationsSelectBox.set('value', observatoryId);
                        // 時間モードの初期化
                        var timeModeQuery = Locator.getQuery().timeMode;
                        if (timeModeQuery !== '10') {
                            this.timeModeSelect.set('value', timeModeQuery);
                        }
                        // 観測日時の初期化
                        // this.initGrid();
                        // 時間モードをセット
                        this.setTimeModeSelect();
                    })
                );
            },

            // 観測局セレクトボックスの更新を行う
            updateSelectBox: function (value) {
                // var query = null;
                // if (value.length > 0) {
                // 	if (value.length === 6 || value.length === 0) {
                // 		// 地域選択の場合
                // 		query = 'regionCd=' + value;
                // 	} else {
                // 		// 流域選択の場合
                // 		query = 'riverBasinCd=' + value;
                // 	}
                // }
                // 選択された地域内の観測局一覧を取得する
                var promise = Requester.get(
                    '/api/observationStation/selectBox/?dataKind=' + DataKind.RIVER_LEVEL + '&regionCd=' + value
                );
                Loader.wait(promise).then(
                    lang.hitch(this, function (data) {
                        // セット中の観測局IDを退避
                        var observatoryId =
                            this.observationStationsSelectBox.get('value') || Locator.getQuery().observatoryId;
                        // セレクトボックスにオプションを設定する
                        this.observationStationsSelectBox.set('options', data).reset();
                        // もともとセットされていた観測局が選択肢にあるならセット
                        array.forEach(
                            data,
                            function (item) {
                                if (item.value === observatoryId) {
                                    this.observationStationsSelectBox.set('value', observatoryId);
                                }
                            },
                            this
                        );
                        // 時間モードをセット
                        this.setTimeModeSelect();
                    })
                );
            },

            /**
             * 観測日時を10分モードの最新時刻で初期化する。
             */
            initDateTime: function () {
                var date = this.date;
                Requester.get('/api/river/latestDataTime?timeMode=10').then(function (data) {
                    date._setValueAttr(data);
                });
            },

            // 一つ前の観測局を取得する
            onPreviousButtonClick: function () {
                console.debug('[<]ボタンがクリックされました。');

                var options = this.observationStationsSelectBox.options;
                var value = this.observationStationsSelectBox.value;
                for (var i = 1; i < options.length; i++) {
                    if (options[i].value === value) {
                        this.observationStationsSelectBox.set('value', options[i - 1].value);
                        console.debug(options[i - 1]);
                        break;
                    }
                }
            },
            // 一つ後の観測局を取得する
            onNextButtonClick: function () {
                console.debug('[>]ボタンがクリックされました。');

                var options = this.observationStationsSelectBox.options;
                var value = this.observationStationsSelectBox.value;
                for (var i = 0; i < options.length - 1; i++) {
                    if (options[i].value === value) {
                        this.observationStationsSelectBox.set('value', options[i + 1].value);
                        console.debug(options[i - 1]);
                        break;
                    }
                }
            },

            // setCorrectMode: function(){
            // 	this.grid.styleColumn('0-4', 'display: none;');
            // },

            // onCorrectButtonClick: function(){
            // 	console.debug('[情報修正]ボタンがクリックされました');
            // 	if (!this._correctFlg){
            // 		this.grid.styleColumn('0-4', 'display: table-cell;');
            // 		this._correctFlg = true;
            // 	}else{
            // 		this.grid.styleColumn('0-4', 'display: none;');
            // 		this._correctFlg = false;
            // 	}

            // },

            /**
             * 観測所の情報を初期化する。
             */
            initText: function (data) {
                if (data.totalLength !== 0) {
                    // 観測所の基準値情報の初期化
                    if (data[0].riverLevelDanger) {
                        this.riverLevelDanger.innerHTML = data[0].riverLevelDanger + '&nbsp;m';
                    } else {
                        this.riverLevelDanger.innerHTML = '-';
                    }
                    if (data[0].riverLevelEvacuate) {
                        this.riverLevelEvacuate.innerHTML = data[0].riverLevelEvacuate + '&nbsp;m';
                    } else {
                        this.riverLevelEvacuate.innerHTML = '-';
                    }
                    if (data[0].riverLevelCaution) {
                        this.riverLevelCaution.innerHTML = data[0].riverLevelCaution + '&nbsp;m';
                    } else {
                        this.riverLevelCaution.innerHTML = '-';
                    }
                    if (data[0].riverLevelStandby) {
                        this.riverLevelStandby.innerHTML = data[0].riverLevelStandby + '&nbsp;m';
                    } else {
                        this.riverLevelStandby.innerHTML = '-';
                    }
                    // 観測所の住所情報の初期化
                    this.regionName.innerHTML = data[0].regionName;
                    this.observatoryName.innerHTML = data[0].observatoryName;
                    this.riverSysName.innerHTML = data[0].riverSysName;
                    this.riverName.innerHTML = data[0].riverName;
                    if (data[0].observatoryId === '0691300400999'){
                        this.riverName.innerHTML = '旧淀川河川流域等（大川・堂島川・安治川、土佐堀川、木津川、尻無川）';
                    } else {
                        this.riverName.innerHTML = data[0].riverName;
                    }
                    this.observatoryAddress.innerHTML = data[0].observatoryAddress;
                    // 観測日時の設定
                    // 観測日時に対応した予測水位を取得する。
                    var value = this.form.get('value');
                    Requester.get('/api/river/riverforecastLevelCheck?observatoryId=' + value.observatoryId +
                    '&dateTimestamp=' + this.lastRequestDateTime.replace('/','-')).then(
                        lang.hitch(this, function (data) {
                            if (data.riverLevelForecastLevelCheck) {
                                this.forecastTimeTop.innerHTML = '※';
                                this.forecastTime.innerHTML = this.lastRequestDateTime.substr(11,2) + ':' + this.lastRequestDateTime.substr(14,2);
                                this.forecastTimeEnd.innerHTML = '以降は予測水位';
                            } else {
                                this.forecastTimeTop.innerHTML = '';
                                this.forecastTime.innerHTML = '';
                                this.forecastTimeEnd.innerHTML = '';            
                            }
                        })
                    );
                }
            },

            // initGrid: function(){
            //     // グリッドの詳細ボタンクリック時の動作を設定する
            //     // helper.buttonColumnでフィールド名に指定した'correct'と'ButtonClick'の結合がボタンクリック時のイベント名
            //     this.grid.on('correctButtonClick', lang.hitch(this, function(evt) {
            //     	this.innerDialog.initDialog(evt.item, this.form.get('value').observatoryId,
            //     			this.lastRequestDateTime, this.mode);
            //         this.correctDialog.show();
            //     }));
            // },
            /**
             * 登録画面のフォームが投稿された際の動作を設定する。
             */
            initRegisterPage: function () {
                // 登録ダイアログの最初の子要素が登録画面
                var dialog = this.dialog;
                var page = dialog.getChildren()[0];
                // 登録画面のsendイベントを受け取る
                page.on(
                    'send',
                    lang.hitch(this, function (evt) {
                        this.store = new IdisRest({
                            target: '/api/river/detail/bulk/detail/'
                        });
                        var sendValue = evt.value;
                        sendValue.riverLevelId = this.riverLevelId;
                        //
                        var updates = [];
                        var update = new Object();
                        var value = this.form.get('value');
                        update.observatoryId = value.observatoryId;
                        update.riverLevelIn1hour =  sendValue.value.riverLevelIn1hour;
                        update.riverLevelIn2hour =  sendValue.value.riverLevelIn2hour;
                        update.riverLevelIn3hour =  sendValue.value.riverLevelIn3hour;
                        update.forecastTimeIn1hour = Locator.getQuery().forecastTimeIn1hour;
                        update.forecastTimeIn2hour = Locator.getQuery().forecastTimeIn2hour;
                        update.forecastTimeIn3hour = Locator.getQuery().forecastTimeIn3hour;
                        update.observatoryName = Locator.getQuery().observatoryName;
                        update.dateTimeSelectNode = sendValue.dateTimestamp;
                        update.userId = Locator.getQuery().userId;
                        updates.push(update);
                        //
                        var grid = this.grid;
                        this.chain.confirmPut(function (chain) {
                            //var promise = this.store.add(sendValue);
                            var promise = this.store.add(updates);
                            Loader.wait(promise).then(
                                function () {
                                    // // 災害情報登録可の時は体制状況登録画面への遷移ダイアログ表示
                                    // if (item.reportFlg === false) {
                                    //     chain.completeAndMove();
                                    // }else{
                                    //     chain.hide();
                                    // }
                                    // 登録した災害を、ヘッダに設定
                                    //クッキーから災害名、避難所キーボックスを削除
                                    // cookie('DISASTER_ID', '');
                                    // cookie('SHELTER_KEYBOX_FLG', '');
                                    // cookie('EXTRA_DISASTER_ID', '');
                                    // 災害名登録時に付番された災害IDをDisasterSelectorにpubする
                                    // topic.publish(module.id + '::changed', item.disasterId);
                                    // 登録対象の災害IDをDisasterChangerDialogにpubする
                                    // topic.publish(module.id + '::refreshed', item.disasterId);
                                    // 登録ダイアログを初期化
                                    chain.infoComplete(function() {
                                    chain.hide();
                                    page.initDialog();
                                    dialog.hide();
                                    grid.refresh();
                                    this.updateGridQuery();
                                    });
                                },
                                function (err) {
                                    chain.infoError(err);
                                }
                            );
                        });
                    })
                );
            },

            /**
             * Formの値をチェックし、表の更新を行う。
             */
            onSubmit: function () {
                try {
                    if (this.form.isValid()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery();
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    return false;
                }
            },

            /**
             * 表の更新を行う。
             */
            updateGridQuery: function () {
                // データを取得する
                this.store = new IdisRest({
                    idProperty: 'riverLevelId',
                    target: '/api/river/detailWithForecast'
                });
                var filter = new this.store.Filter();
                // 観測日時をフィルターにセット
                var date = new Date(this.lastRequestDateTime).getTime();
                filter = filter.eq('date', date);
                // [観測局]のセット
                var value = this.form.get('value');
                if (value.observatoryId) {
                    filter = filter.eq('observatoryId', value.observatoryId);
                }
                // [時間モード]のセット
                filter = filter.eq('timeMode', value.timeMode);
                var collection = this.store.filter(filter);
                this.grid.set('collection', collection);
                collection.fetch().then(
                    lang.hitch(this, function (data) {
                        if (data.totalLength !== 0) {
                            this.riverLevelId = data[0].riverLevelId;
                            this.initText(data);
                            this.initChart(data);
                        }
                    })
                );
            },

            initChart: function (data) {
                // RiverLevelCrossSectionChartの初期化
                // 水位情報：複製して逆順にする
                var reversed = array
                    .filter(data, function () {
                        return true;
                    })
                    .reverse();
                this.riverLevelChart.set('dataAll', reversed);
                // 河川水位グラフ（検索条件の観測日時のデータのみ渡す）
                var chatData = [];

                array.forEach(data, function () {
                    chatData.push(data[0]);
                });
                this.riverLevelChart.set('data', chatData);
                // モード
                this.riverLevelChart.set('mode', this.mode);

                // // 初回のみサイズ変更
                // if (this.firstFlg) {
                //     var drawWidth = this.riverLevelChart.chart.drawWidth;
                //     var drawHeight = this.riverLevelChart.chart.drawHeight;
                //     var width = drawWidth;
                //     var height = drawHeight;
                //     if ( drawWidth < 550 ) {
                //         width = 1280 - drawWidth - 10;
                //         height += 54;
                //     } else {
                //         var y = 450 / drawWidth;
                //         width = 1220 - (drawWidth * y) - 10;
                //         height = drawHeight * y + 125;
                //     }
                //     // this.chartPaneR.resize({h: this.chartPaneR.h, w: width});
                //     this.chartPaneT.resize({h: height, w: this.chartPaneT.w});
                //     this.firstFlg = false;
                // }

                // // RiverHistoryChartの初期化
                // this.chart.create(this.chartNode, this.legend,
                //     {mode:this.mode, data:data});
                // this.chartBorderContainer.resize();
            },

            /**
             * 時間モードが変更された際に呼ばれる
             */
            onChangeModeSelect: function (value) {
                // 正時の場合、1時間モードとする
                this.mode = value === 'hourly' ? '60' : value;
                // 観測時間選択の表示を切り替える
                this.dateTimeSelect.changeMode(this.mode);
                this.grid.changeMode(this.mode);
                console.debug('[時間モード]を' + value + 'に変更します。');
                this.store.target = '/api/river/detailWithForecast';

                var filter = this.store.Filter();

                var observatoryId = this.form.get('value').observatoryId;
                filter = filter.eq('observatoryId', observatoryId);

                // 時間モードをフィルターにセット
                filter = filter.eq('timeMode', this.mode);

                // 観測日時をフィルターにセット
                var date = new Date(this.lastRequestDateTime).getTime();
                filter = filter.eq('date', date);

                var collection = this.store.filter(filter);
                this.grid.set('collection', collection);

                collection.fetch().then(
                    lang.hitch(this, function (data) {
                        if (data.totalLength !== 0) {
                            this.riverLevelId = data[0].riverLevelId;
                            this.initText(data);
                            this.initChart(data);
                        }
                    })
                );
            },

            onRegionChange: function (evt) {
                console.debug('[地域]ボックスが変更されました。');
                this.updateSelectBox(evt);
            },

            /**
             * [観測局]を変更し、表の更新を行う。
             */
            onObservationStationChange: function () {
                console.debug('[観測局]ボックスが変更されました。');
                if (this.changeFlg !== 0) {
                    // 時間モードをセット
                    this.setTimeModeSelect();
                }
                this.changeFlg++;
            },

            onRiverLevelObservationLinkClick: function (evt) {
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                console.debug('onRiverLevelObservationLinkClick');
                Router.moveTo('observation/river', { viewMode: this._viewMode });
            },
            /**
             * 新規登録ダイアログを表示する。
             * テンプレートHTMLでdata-dojo-attach-eventプロパティーを用い、
             * 新規登録ボタンのクリックイベントにこのメソッドを紐付けている。
             */
            showRegisterDialog: function () {
                // ダイアログの最初の子要素が登録画面
                var page = this.dialog.getChildren()[0];
                // 画面上のフォームをリセット
                var value = this.form.get('value');
                page.initDialog({
                    dateTimestamp: this.lastRequestDateTime,
                    obsName: this.obsName,
                    obsId: value.observatoryId,
                    riverLevelId: Locator.getQuery().riverLevelId,
                    rLI1: Locator.getQuery().riverLevelIn1hour,
                    rLI2: Locator.getQuery().riverLevelIn2hour,
                    rLI3: Locator.getQuery().riverLevelIn3hour
                });
                // ダイアログを表示
                this.dialog.show();
            }
        }
    );
});