/**
 * 観測地域モデル
 * @module app/model/ObservationRegion
 */
define([
    'dojo/store/Memory'
    // 以下、変数で受けないモジュール
], function(Memory) {
    // シングルトンを返す
    return new Memory({
        idProperty: 'value',
        data: [
            {
                'value': '',
                'label': '北海道全域',
                'selected': true,
                'regionFlg': ''
            },
            {
                'value': '',
                'label': '------地域選択------',
                'disabled': 'true',
                'regionFlg': '1'
            },
            {
                'value': '010010',
                'label': '空知地域',
                'regionFlg': '1'
            },
            {
                'value': '010011',
                'label': '石狩地域',
                'regionFlg': '1'
            },
            {
                'value': '010012',
                'label': '後志地域',
                'regionFlg': '1'
            },
            {
                'value': '010013',
                'label': '胆振地域',
                'regionFlg': '1'
            },
            {
                'value': '010014',
                'label': '日高地域',
                'regionFlg': '1'
            },
            {
                'value': '010015',
                'label': '渡島地域',
                'regionFlg': '1'
            },
            {
                'value':'010016',
                'label':'檜山地域',
                'regionFlg': '1'
            },
            {
                'value':'010017',
                'label':'上川地域',
                'regionFlg': '1'
            },
            {
                'value':'010018',
                'label':'留萌地域',
                'regionFlg': '1'
            },
            {
                'value':'010019',
                'label':'宗谷地域',
                'regionFlg': '1'
            },
            {
                'value':'010020',
                'label':'オホーツク地域',
                'regionFlg': '1'
            },
            {
                'value':'010021',
                'label':'十勝地域',
                'regionFlg': '1'
            },
            {
                'value':'010022',
                'label':'釧路地域',
                'regionFlg': '1'
            },
            {
                'value':'010023',
                'label':'根室地域',
                'regionFlg': '1'
            }
        ]
    });
});
