/**
 * Lアラート避難情報配信ダイアログ。
 * @module app/provide/ProvideEvacOrderRegisterDialog
 */
define([
    'module',
    'dojo',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom-style',
    'dojo/text!./templates/ProvideEvacOrderRegisterDialog.html',
    'dojo/topic',
    './_ProvideDialogBase',
    'app/config',
    'app/model/DisasterInfo',
    'dstore/Memory',
    'idis/util/DateUtils',
    'idis/control/Router',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/InfoDialog',
    'app/model/Municipality',
    'app/evacorder/Reason',
    'app/evacorder/EvacOrderType',
    'app/evacorder/EvacOrderUtil',
    './ProvideUtil',
    'dijit/form/Textarea',
    // 以下、変数から参照されないモジュール
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/ValidationTextBox',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/DateTimeInput',
    'idis/view/form/Button',
    'app/view/form/MunicipalitySelector',
    'app/view/form/DisasterSelector',
    './form/DistributionTypeInput',
    './ProvideShelterRegisterDialog',
    './ProvideUrgentMailRegisterDialog',
    './ProvideEvacOrderGrid'
], function(module, dojo, array, declare, lang, locale, domStyle, template, topic, _ProvideDialogBase, config,
    DisasterInfo, Memory, DateUtils, Router, IdisRest, Loader, UserInfo, Requester, InfoDialog,
    Municipality, Reason, EvacOrderType, EvacOrderUtil, ProvideUtil, Textarea) {
    /**
     * Lアラート避難情報登録ダイアログ画面。
     * @class ProvideEvacOrderRegisterDialog
     * @extends module:app/provide/_ProvideDialogBase~_ProvideDialogBase
     */
    return declare(module.id.replace(/\//g, '.'), _ProvideDialogBase,
        /** @lends module:app/provide/_ProvideDialogBase~_ProvideDialogBase# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--provide',

        // ダイアログを開く際に設定する。
        contentType: null,

        // 登録用ストア
        registerStore: null,

        // 配信日時
        sendTimestamp: null,

        // ダイアログで保持する市町村コード
        _municipalityCd: null,

        // 災害ID
        _disasterId: null,
        // 災害名
        _disasterName: null,

        // 引数
        _args: null,

        // 訂正・取消理由が必須かどうか
        _errata: false,

        _shelterInfo: {},

        _evacOrderInfo: {},

        // 避難理由の文字列表現
        _evacuateReason: null,

        // 緊急速報メール利用有無判別フラグ
        _urgentMailFlg: false,

        // 緊急速報メール配信の判断に用いる。
        _isIssue: false,

        // twitter配信の判断に用いる
        _isTwitter: false,
        // ツイッター文字数カウント用
        _tweetNum: null,

        TWITTER_MAX_LENGTH: 140,

        // 緊急メール速報の本文リスト
        _bodyTextList: [],

        // 札幌防災ポータルURL
        // SAPPORO_PORTAL_URL: config.portalInfo.url,

        // 発令理由
        SEDIMENT: '01', //土砂
        FLOOD: '02',    //洪水

        // ベースクラスのコンストラクタでStoreを生成。
        constructor: function() {
            // 登録用ストア
            this.registerStore = new IdisRest({
                target: '/api/lalert/send'
            });
            // 管理対象市町リストの一つ目を設定する
            var municipalityCds = UserInfo.getMunicipalityCds();
            this._municipalityCd = municipalityCds[0];
            this._disasterId =  DisasterInfo.getDisasterId();
            // 災害名を取得する
            Requester.get('/api/disasters/' + this._disasterId, {
            }).then(lang.hitch(this, function(disasterObj) {
                this._disasterName = disasterObj.disasterName;
            }), lang.hitch(this, function() {
                // error
                this._disasterName = '';
            }));
        },

        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.shelterRegisterDialog);
            // this.own(this.urgentMailRegisterDialog);
        },

        // ベースクラスをオーバーライド
        // 画面から渡された値で初期表示設定を行う。
        initDialog: function(args, isIssue) {
            // 次のダイアログ(緊急速報メール等)に渡す値を保持する
            this._args = args;
            var evacOrderArgs = args.evacOrderArgs;

            // 初期表示状態のボタン表示状態を変更
            if(!args.shelterArgs){
                // 避難所情報がない場合は、ただの配信ボタンのみを表示
                domStyle.set(this.evacShelRegisterButton.domNode, 'display', 'none');
                domStyle.set(this.showShelterButton.domNode, 'display', 'none');
                domStyle.set(this.evacRegisterButton.domNode, 'display', '');
            } else {
                // 避難所情報がない場合は、避難所を確認するボタンのみを表示
                domStyle.set(this.evacShelRegisterButton.domNode, 'display', 'none');
                domStyle.set(this.showShelterButton.domNode, 'display', '');
                domStyle.set(this.evacRegisterButton.domNode, 'display', 'none');
            }
            domStyle.set(this.mailAndSnsLabel, 'display', 'none');

            // 市町村の、各配信先への配信必要有無をチェック・セットする。
            return this.getSendingSetting(evacOrderArgs.municipalityCd).then(lang.hitch(this,function() {

                // 緊急速報メールを配信するのは、以下2条件の全てを満たすときのみ。
                // １、緊急速報メールの利用市町であること（this._urgentMailFlg）
                // ２、発令画面から、解除ではない情報を発令するとき
                this._isIssue = isIssue;

                // if(!this._isIssue){
                //     domStyle.set(this.prefMailArea, 'display', 'none');
                //     this.prefMailFlg.set('checked', false);
                //     this.borderContainer.resize();
                // }
                //大阪市ではメールは送らない。
                this.prefMailFlg.set('checked', false);
                domStyle.set(this.prefMailArea, 'display', 'none');
                this.borderContainer.resize();

                // 更新種別 '01':新規, '02':更新・訂正, '03':取消
                // 明確に指定されている場合はその値、無ければ前回の履歴で判定
                // 「前回配信履歴がある＆前回のdistributionTypeが取消(03)以外」＝更新(02)、それ以外＝新規(01)
                var distType = evacOrderArgs.distributionType ||
                    ( (evacOrderArgs.lalertSendHistId && evacOrderArgs.lastDistributionType !== '03' ) ? '02' : '01');
                this.distributionTypeInput.set('value', distType);

                if (this._urgentMailFlg && !this._isIssue){
                    // 配信条件を満たさない場合は、非表示にする
                    domStyle.set(this.urgentMailFlgArea, 'display', 'none');
                    domStyle.set(this.urgentMailArea.domNode, 'display', 'none');
                    this.urgentMailFlg.set('checked', false);
//                    // 配信条件を満たさない場合は、緊急速報メールフラグにチェック無し
//                    domStyle.set(this.urgentMailFlgArea, 'display', '');
//                    this.urgentMailFlg.set('checked', false);
//                    // 取消の場合、緊急速報メールのチェック非活性
//                    if(distType==='03'){
//                        this.urgentMailFlg.set('disabled', true);
//                    } else {
//                        this.urgentMailFlg.set('disabled', false);
//                    }
                    this.borderContainer.resize();
                } else if(!this._urgentMailFlg){
                    // 配信条件を満たさない場合は、緊急速報メールフラグは非表示。
                    domStyle.set(this.urgentMailFlgArea, 'display', 'none');
                    // 緊急速報メールのチェックを外す
                    this.urgentMailFlg.set('checked', false);
                    this.borderContainer.resize();
                } else {
                    domStyle.set(this.urgentMailFlgArea, 'display', '');
                    // 緊急速報メールは、デフォルトでチェックをつける
                    this.urgentMailFlg.set('checked', true);
                    this.borderContainer.resize();
                }

                // 市町村別の配信設定に応じて、画面内容を制御する。
                if(this._twitterFlg && !this._isIssue){
                    // 配信条件を満たさない場合は、非表示
                    domStyle.set(this.snsArea, 'display', 'none');
                    domStyle.set(this.twitterArea.domNode, 'display', 'none');
                    this.twitterFlg.set('checked', false);
                    // domStyle.set(this.publishArea, 'display', 'none'); //岡山では不可視化するが、後続案件のため残す

                    // 配信条件を満たさない場合は、Twitterフラグにチェック無し
//                    domStyle.set(this.snsArea, 'display', '');
//                    this.twitterFlg.set('checked', false);
//                    // 取消の場合、Twitterのチェック非活性
//                    if(distType==='03'){
//                      this.twitterFlg.set('disabled', true);
//                    } else {
//                      this.twitterFlg.set('disabled', false);
//                      // Twitter配信対象
//                        this._isTwitter = true;
//                    }
                    this.borderContainer.resize();
                } else if(!this._twitterFlg ){
                    // twitter(解除時も連携)を利用しておらず、解除をする場合は、公開先エリアそのものを不可視化
                    domStyle.set(this.snsArea, 'display', 'none');
                    domStyle.set(this.twitterArea.domNode, 'display', 'none');
                    // Twitterのチェックを外す
                    this.twitterFlg.set('checked', false);
                    this.borderContainer.resize();
                } else {
                    domStyle.set(this.snsArea, 'display', '');
                    this.twitterFlg.set('checked', true);
                    // Twitter配信対象
                    this._isTwitter = true;
                    this.borderContainer.resize();
                }

                // 緊急情報配信(防災アプリ)
                // if(!this._isIssue){
                    // 配信条件を満たさない場合は、非表示
                    // 大阪市では表示しないので全てnoneに設定
                    domStyle.set(this.emergencyPushFlgArea, 'display', 'none');
                    domStyle.set(this.emergencyPushArea.domNode, 'display', 'none');
                    this.emergencyPushFlg.set('checked', false);
                    this.borderContainer.resize();
                /* } else {
                    domStyle.set(this.emergencyPushFlgArea, 'display', 'none');
                    this.borderContainer.resize();
                } */

                // 各公開先がすべて非表示であれば全体を非表示
                if (domStyle.get(this.prefMailArea, 'display') === 'none' &&
                    domStyle.get(this.urgentMailFlgArea, 'display') === 'none' &&
                    domStyle.get(this.snsArea, 'display') === 'none' &&
                    domStyle.get(this.emergencyPushFlgArea, 'display') === 'none') {

                    domStyle.set(this.publishArea, 'display', 'none');
                    this.borderContainer.resize();
                }

                // 配信種別をセットする。
                this.sendCategoryNode.innerHTML = this.getCategoryName(evacOrderArgs.sendCategory);
                // 市町村コードをセットする。
                this.municipalityCd._setValueAttr(evacOrderArgs.municipalityCd);

                // 補足情報
                this.complementaryInfo.set('value', evacOrderArgs.note);

                // 訂正・取消理由欄は修正時のみ表示 初期状態ではnoneが設定されている。
                if (evacOrderArgs.isFix) {
                    this._errata = true;
                    domStyle.set(this.errataDescriptionItemNode, 'display', '');
                    this.errataDescription.set('value', evacOrderArgs.correctCancelReason);

                    // 訂正・取消時はLアラート以外を配信しないので、Lアラート関連以外のノードを全て非表示にする
                    // domStyle.set(this.publishArea, 'display', 'none');//岡山では不可視化するが、後続案件のため残す
                    this.borderContainer.resize();
                } else {
                    this._errata = false;
                    domStyle.set(this.errataDescriptionItemNode, 'display', 'none');
                    this.errataDescription.set('value', null);

                    // 訂正・取消時はLアラート以外を配信しないので、Lアラート関連以外のノードを全て非表示にする
                    // domStyle.set(this.publishArea, 'display', '');//岡山では不可視化するが、後続案件のため残す
                    this.borderContainer.resize();
                }

                // 災害名をセットする。
                this.disasterNameNode.set('value',this._disasterId);
                // 避難情報発令日時をセットする。
                this.sendTimestamp = new Date(evacOrderArgs.evacTimestamp);
                this.evacTimestampNode.innerHTML = DateUtils.format(this.sendTimestamp);
                // 発令理由をセットする。
                this._evacuateReason = evacOrderArgs.evacuateReason;
                this.evacuateReasonNode.innerHTML = this._evacuateReason;
                // 対象世帯数をセットする。
                this.evacHouseholdNum.set('value', evacOrderArgs.evacHouseholdNum);
                // 対象人数をセットする。
                this.evaqueeNum.set('value', evacOrderArgs.evaqueeNum);
                // 更新種別が '03':取消の場合 には避難人数、世帯数を変更させない。
                if (distType === '03') {
                    this.evacHouseholdNum.set('disabled', 'disabled');
                    this.evaqueeNum.set('disabled', 'disabled');
                }
                // 避難行動指針
                if(evacOrderArgs.evacGuidunce !== '') {
                    this.evacuateGuidelineNode.innerHTML = evacOrderArgs.evacGuidunce;
                    domStyle.set(this.evacuateGuidelineArea, 'display', '');
                }
                // 対象地区に関する情報はグリッドで表示する。
                this.initGrid(evacOrderArgs);

                // 緊急速報メールetcの文面を生成・セットする
                // 緊急情報配信(防災アプリ)も同一メッセージをここでセット
                if(args.urgentMailArgs){
                    this._urgentMailArgs = this.createUrgentMailInfo(args);
                    this.subjectInput.set('value', this.createUrgentMailSubject(args));
                    this.urgentMailBodyText._setValueAttr(this.createEvacOrderBodyText(this._urgentMailArgs));
                    this.urgentMailBodyText2.set('value', '');
                    this.urgentMailBodyText3.set('value', '');
                    this.emergencyPushSubjectInput.set('value', this.createUrgentMailSubject(args));
                    this.emergencyPushBodyText.set('value', this.createEvacOrderBodyText(this._urgentMailArgs));
                } else {
                    this.subjectInput.set('value', '');
                    this.urgentMailBodyText.set('value', '');
                    this.urgentMailBodyText2.set('value', '');
                    this.urgentMailBodyText3.set('value', '');
                    this.emergencyPushSubjectInput.set('value', '');
                    this.emergencyPushBodyText.set('value', '');
                }

                // Twitter配信対象の場合、文面を生成・セットする
                if(this._isTwitter){
                    var twitterSubject = this.createUrgentMailSubject(args);
                    var twitterBody = this.createEvacOrderBodyText(this._urgentMailArgs, true);

                    // Twitter初期設定
                    this.initTwitter(twitterSubject, twitterBody);
                }

            }), lang.hitch(this, function(error){
                console.error(error);
                this.chain.infoError(error);
            }));
        },

        // Twitterの初期表示設定
        initTwitter: function(subject, bodyText){
            domStyle.set(this.inputTwitter, 'display', 'block');
            var twitterBodyText = '【' + subject + '】'+ '\n' + bodyText;

            // tweetを全て削除
            var element = this.twitterOuterFrame;
            while (element.firstChild) {
                element.removeChild(element.firstChild);
            }

            var tweetList = this.splitTweetText(twitterBodyText);
            this._tweetNum = tweetList.length;
            for (var i = 0; i < this._tweetNum; i++) {
                this.addTweet(tweetList[i], i + 1);
            }
        },

        togglePublishFlg: function(){
            // var destination = '';
            // if(this.prefMailFlg.getValue() === 'on' && this.twitterFlg.getValue() === 'on'){
            //     destination += '県防災情報メール・Twitter';
            // } else if (this.prefMailFlg.getValue() === 'on') {
            //    destination += '県防災情報メール';
            // } else if (this.twitterFlg.getValue() === 'on') {
            //     destination += 'Twitter';
            // }
            // var appendixInfo = '（' + destination +  'にも、同じ文面が' +
            //                         '「おかやま防災ポータル」へのリンクと共に連携されます）';

            if(this.urgentMailFlg.getValue() === 'on' && this._urgentMailFlg){
                // 緊急速報メールを送る場合は、緊急速報メール用のペインを表示
                domStyle.set(this.urgentMailArea.domNode, 'display', '');
                // domStyle.set(this.hideBodyTextAreaLabel4Lalert, 'display', '');
                domStyle.set(this.showBodyTextAreaLabel, 'display', 'none');
                domStyle.set(this.mailBodyTextArea, 'display', '');
                domStyle.set(this.showTwitterAreaLabel, 'display', 'none');

                // 緊急速報メールラベル
                domStyle.set(this.urgentMailLabel, 'display', '');
                // タイトル欄
                domStyle.set(this.urgentMailSubjectArea, 'display', '');
                // 外部連携本文ラベル
                domStyle.set(this.mailAndSnsLabel, 'display', 'none');

                // twitter用のペインの制御
                if(this.twitterFlg.getValue() === 'on'){
                    domStyle.set(this.twitterArea.domNode, 'display', '');
                    domStyle.set(this.twitterTextArea, 'display', '');
                } else {
                    domStyle.set(this.twitterArea.domNode, 'display', 'none');
                    domStyle.set(this.twitterTextArea, 'display', 'none');
                }

                // 緊急情報配信(防災アプリ)用のペインの制御
                // 大阪市では表示しないので全てnoneに設定
                if (this.emergencyPushFlg.getValue() === 'on') {
                    domStyle.set(this.emergencyPushArea.domNode, 'display', 'none');
                    domStyle.set(this.emergencyPushTextArea, 'display', 'none');
                } else {
                    domStyle.set(this.emergencyPushArea.domNode, 'display', 'none');
                    domStyle.set(this.emergencyPushTextArea, 'display', 'none');
                }

                // if(this.prefMailFlg.getValue() === 'on' || this.twitterFlg.getValue() === 'on'){
                //     this.urgentMailAppendixInfo.innerHTML = appendixInfo;
                // } else {
                //     this.urgentMailAppendixInfo.innerHTML = '';
                // }

                this.borderContainer.resize();
            //} else if (this.prefMailFlg.getValue() === 'on' || this.twitterFlg.getValue() === 'on') {
            // 県防災情報メール
            } else if (this.prefMailFlg.getValue() === 'on') {    
                // 緊急速報メールを送るが、県防災情報メール・SNSのいずれかを場合は、ラベルを変えて本文のみを表示
                domStyle.set(this.urgentMailArea.domNode, 'display', '');
                domStyle.set(this.urgentMailLabel, 'display', 'none');
                domStyle.set(this.mailAndSnsLabel, 'display', '');
                if(this.prefMailFlg.getValue() === 'on'){
                    // 県防災情報メールは件名が必要
                    domStyle.set(this.urgentMailSubjectArea, 'display', '');
                } else {
                    // SNSは件名不要
                    domStyle.set(this.urgentMailSubjectArea, 'display', 'none');
                }
                this.borderContainer.resize();
            } else if (this.twitterFlg.getValue() === 'on') {    
                // 緊急速報メールを送るが、県防災情報メール・SNSのいずれかを場合は、ラベルを変えて本文のみを表示
                domStyle.set(this.twitterArea.domNode, 'display', '');
                domStyle.set(this.twitterTextArea, 'display', '');
                domStyle.set(this.showTwitterAreaLabel, 'display', 'none');
//                domStyle.set(this.urgentMailArea.domNode, 'display', 'none');

                // 緊急速報メール用のペインの制御
                if(this.urgentMailFlg.getValue() === 'on' && this._urgentMailFlg){
                    domStyle.set(this.urgentMailArea.domNode, 'display', '');
                    domStyle.set(this.mailBodyTextArea, 'display', '');
                } else {
                    domStyle.set(this.urgentMailArea.domNode, 'display', 'none');
                    domStyle.set(this.mailBodyTextArea, 'display', 'none');
                }

                // 緊急情報配信(防災アプリ)用のペインの制御
                // 大阪市では表示しないので全てnoneに設定
                if (this.emergencyPushFlg.getValue() === 'on') {
                    domStyle.set(this.emergencyPushArea.domNode, 'display', 'none');
                    domStyle.set(this.emergencyPushTextArea, 'display', 'none');
                } else {
                    domStyle.set(this.emergencyPushArea.domNode, 'display', 'none');
                    domStyle.set(this.emergencyPushTextArea, 'display', 'none');
                }

                this.borderContainer.resize();
            } else if (this.emergencyPushFlg.getValue() === 'on') {    
                domStyle.set(this.emergencyPushArea.domNode, 'display', '');
                domStyle.set(this.emergencyPushTextArea, 'display', '');

                // 緊急速報メール用のペインの制御
                if (this.urgentMailFlg.getValue() === 'on' && this._urgentMailFlg) {
                    domStyle.set(this.urgentMailArea.domNode, 'display', '');
                    domStyle.set(this.mailBodyTextArea, 'display', '');
                } else {
                    domStyle.set(this.urgentMailArea.domNode, 'display', 'none');
                    domStyle.set(this.mailBodyTextArea, 'display', 'none');
                }

                // twitter用のペインの制御
                if (this.twitterFlg.getValue() === 'on') {
                    domStyle.set(this.twitterArea.domNode, 'display', '');
                    domStyle.set(this.twitterTextArea, 'display', '');
                } else {
                    domStyle.set(this.twitterArea.domNode, 'display', 'none');
                    domStyle.set(this.twitterTextArea, 'display', 'none');
                }
                this.borderContainer.resize();
            } else {
                // 緊急速報メール・県防災情報メール・SNSのいずれも送らない場合は、ペイン自体を非表示
                domStyle.set(this.urgentMailArea.domNode, 'display', 'none');
                domStyle.set(this.twitterArea.domNode, 'display', 'none');
                // domStyle.set(this.hideBodyTextAreaLabel4Lalert, 'display', '');
                domStyle.set(this.emergencyPushArea.domNode, 'display', 'none');
                this.borderContainer.resize();
            }
        },

        hideBodyTextArea: function(){
            // domStyle.set(this.hideBodyTextAreaLabel, 'display', 'none');
            // domStyle.set(this.hideBodyTextAreaLabel4Lalert, 'display', 'none');
            domStyle.set(this.showBodyTextAreaLabel, 'display', '');
            domStyle.set(this.showTwitterAreaLabel, 'display', '');
            domStyle.set(this.showEmergencyPushAreaLabel, 'display', '');
            // domStyle.set(this.showBodyTextAreaLabel4Lalert, 'display', '');
            domStyle.set(this.mailBodyTextArea, 'display', 'none');
            domStyle.set(this.twitterTextArea, 'display', 'none');
            domStyle.set(this.emergencyPushTextArea, 'display', 'none');
            this.borderContainer.resize();
        },

        showBodyTextArea: function(){
            // domStyle.set(this.hideBodyTextAreaLabel, 'display', '');
            // domStyle.set(this.hideBodyTextAreaLabel4Lalert, 'display', '');
            domStyle.set(this.showBodyTextAreaLabel, 'display', 'none');
            // domStyle.set(this.showBodyTextAreaLabel4Lalert, 'display', 'none');
            domStyle.set(this.mailBodyTextArea, 'display', '');
            this.borderContainer.resize();
        },

        showTwitterArea: function(){
            // domStyle.set(this.hideBodyTextAreaLabel, 'display', '');
            // domStyle.set(this.hideBodyTextAreaLabel4Lalert, 'display', '');
            domStyle.set(this.showTwitterAreaLabel, 'display', 'none');
            // domStyle.set(this.showBodyTextAreaLabel4Lalert, 'display', 'none');
            domStyle.set(this.twitterTextArea, 'display', '');
            this.borderContainer.resize();
        },

        showEmergencyPushArea: function(){
            // domStyle.set(this.hideBodyTextAreaLabel, 'display', '');
            // domStyle.set(this.hideBodyTextAreaLabel4Lalert, 'display', '');
            domStyle.set(this.showEmergencyPushAreaLabel, 'display', 'none');
            // domStyle.set(this.showBodyTextAreaLabel4Lalert, 'display', 'none');
            domStyle.set(this.emergencyPushTextArea, 'display', '');
            this.borderContainer.resize();
        },

        // 避難対象地区グリッドを初期表示する。
        initGrid: function(obj) {
            this.evacOrderGrid.set('collection', new Memory({
                // グリッド格納時にIDが付与されてしまうので、元データを変更させないようコピーを格納する
                data: array.map(obj.evaqOrderData, function(item, i) {
                    return lang.mixin({id: i}, item);
                })
            }));
        },

        resize: function(changeSize, resultSize) {
            this.borderContainer.resize(changeSize, resultSize);
        },

        // 画面の「登録」ボタンを受け取る
        sendAndRegister: function() {

            // エラーチェック
            if(!this.validateForm()){
                return false;
            }

            var subject = '避難情報 発信'; // 初期値
            if(EvacOrderUtil.isReleased(this._args.evacOrderArgs.evacOrderType)){
                subject = '避難情報 解除';
            } else {
                subject = EvacOrderType.get(this._args.evacOrderArgs.evacOrderType).simpleLabel + ' 発令';
            }

            var isIssueIncluded = false;
            array.forEach(this._args.evacOrderArgs.evaqOrderData, function(area){
                if(area.evacuateIssue === '発令'){
                    isIssueIncluded = true;
                }
            });

            // formタグを使うとダイアログ上でグリッドのカラム名が表示されないため使用しない
            var lalertForm = {
                disasterId           : this._disasterId,
                bodyText             : ' ',
                municipalityCd       : this.municipalityCd.get('value'),
                complementaryInfo    : this.complementaryInfo.value,
                distributionType     : this.distributionTypeInput.get('value'),
                errataDescription    : this._args.evacOrderArgs.isFix ? this.errataDescription.get('value') : null,
                sendCategory         : this.EVAC_ORDER_CONTENT_TYPE,
                issueReasonType      : this._args.evacOrderArgs.issueReasonType,
                sendTimestamp        : this.sendTimestamp,
                subject              : subject,
                evacuateReason       : this._evacuateReason,
                evacuateGuideline    : this.evacuateGuidelineNode.innerHTML,
                abstEvacHouseholdNum : isIssueIncluded ? this.evacHouseholdNum.get('value') : null,
                abstEvaqueeNum       : isIssueIncluded ? this.evaqueeNum.get('value') : null,
                evacOrderAreaList    : this._args.evacOrderArgs.evaqOrderData,
                lalertSendHistId     : this._args.evacOrderArgs.lalertSendHistId
            };
            this._evacOrderInfo.lalertForm = lalertForm;

            var urgentMailForm;
            var urgentMailFormList = [];

            // 岡山では緊急速報メールを不可視化するので、入力チェックをなくす。
            if((this.prefMailFlg.getValue() === 'on') ||
                (this.twitterFlg.getValue() === 'on') ||
                (this.urgentMailFlg.getValue() === 'on' && this._urgentMailFlg)){
                // 本文がある場合は、文面をチェックする
                if(!this.validateUrgentMailBodyText()){
                    return false;
                }
            }

            if(this.twitterFlg.getValue() === 'on'){
                if(!this.validateTwitter()){
                    return false;
                }
                this._evacOrderInfo.twitterFlg = true;
                var tweetData = this.twitterOuterFrame.getElementsByTagName('textarea');
                var tweetList = [];
                array.forEach (tweetData, function(data) {
                    tweetList.push(data.value);
                });
                this._evacOrderInfo.twitterMessage = tweetList;                
                //this._evacOrderInfo.twitterMessage = this.urgentMailBodyText.get('value');
            } else {
                this._evacOrderInfo.twitterFlg = false;
            }

            if(this.prefMailFlg.getValue() === 'on'){
                this._evacOrderInfo.prefMailFlg = true;
                this._evacOrderInfo.prefMailBodyText = this.urgentMailBodyText.get('value');
                if(!this.validateUrgentMailSubject()){
                    return false;
                }
                this._evacOrderInfo.prefMailSubject = this.subjectInput.get('value');
            } else {
                this._evacOrderInfo.prefMailFlg = false;
            }

            if(this.emergencyPushFlg.getValue() === 'on'){
                if (!this.validateEmergencyPushSubject()) {
                    return false;
                }
                if (!this.validateEmergencyPushBodyText()) {
                    return false;
                }
                this._evacOrderInfo.emergencyPushFlg = true;
                this._evacOrderInfo.emergencyPushSubject = this.emergencyPushSubjectInput.get('value');
                this._evacOrderInfo.emergencyPushBodyText = this.emergencyPushBodyText.get('value');
            } else {
                this._evacOrderInfo.emergencyPushFlg = false;
            }

            // 全域判定結果をセットする。
            if(this._urgentMailArgs){
                var wholeAreaName = '';
                array.forEach(this._urgentMailArgs.districtListWholeArea, function(area) {
                    if(wholeAreaName){
                        wholeAreaName += '、';
                    }
                    wholeAreaName += area.districtFreeName;
                });
                this._evacOrderInfo.wholeAreaDistrictName = wholeAreaName;
            }

            // 緊急速報メールを送る場合、緊急速報メールのフォームを作成する
            if(this._isIssue && this.urgentMailFlg.getValue() === 'on'){
                if(!this.validateUrgentMailSubject()){
                    return false;
                }

                // メール本文をリセット
                this._bodyTextList = [];
                // 本文１をセット
                this._bodyTextList.push(this.urgentMailBodyText.get('value'));
                // 本文２に入力がある場合、セット
                if(this.urgentMailBodyText2.get('value') !== ''){
                    this._bodyTextList.push(this.urgentMailBodyText2.get('value'));
                }
                // 本文３に入力がある場合、セット
                if(this.urgentMailBodyText3.get('value') !== ''){
                    this._bodyTextList.push(this.urgentMailBodyText3.get('value'));
                }

                // 対象市町を取得する
                var target = {'targetMunicipalityCd' : this.municipalityCd.get('value')};
                var tLalertSendTargetList = [target];

                var municipalityCd = this.municipalityCd.get('value');
                subject = this.subjectInput.get('value');

                var sendCategory = this.URGENT_MAIL_CONTENT_TYPE;

                this._bodyTextList.forEach(function(data){
                    urgentMailForm = {};
                    // 災害IDをformにセットする。
                    urgentMailForm.disasterId = DisasterInfo.getDisasterId();
                    // 対象市町をformにセットする。
                    urgentMailForm.tLalertSendTargetList = tLalertSendTargetList;
                    // 配信情報種別をformにセットする。
                    urgentMailForm.sendCategory = sendCategory;
                    // 緊急速報メールは、distributionTypeが常に01。
                    urgentMailForm.distributionType = '01';
                    urgentMailForm.municipalityCd = municipalityCd;
                    urgentMailForm.subject = subject;
                    // 現在の時刻を配信日時としてformにセットする。
                    urgentMailForm.sendTimestamp = new Date();
                    // メール本文をformにセットする。
                    urgentMailForm.bodyText = data;
                    // メールをセット
                    urgentMailFormList.push(urgentMailForm);
                });
            }

            this.emit('sendandregister', {
                evacOrderInfo: this._evacOrderInfo,
                shelterInfo: this._shelterInfo,
                urgentMailInfo: urgentMailFormList
            });
            return false;
        },

        validateForm: function(){

            if(!this.evaqueeNum.validate() || !this.evacHouseholdNum.validate()){
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '対象世帯数・対象人数は半角数字で入力してください。'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }

            if(this._errata && (this.errataDescription.get('value') === '')){
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '訂正・取り消し理由が入力されていません。'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }

            return true;
        },

        /**
         * 配信情報(開設避難所情報)ダイアログを表示する。
         */
        showShelterDialog: function() {
            var args = this._args;

            // ダイアログの最初の子要素が登録画面
            var dialog = this.shelterRegisterDialog.getChildren()[0];

            // 登録画面のsendイベントを受け取る
            dialog.on('send', lang.hitch(this, function(evt) {
                // 避難所ダイアログで整理した情報を保管しておく
                this._shelterInfo.lalertForm = evt.value.lalertForm;
                this.sendAndRegister();
            }));

            // 登録画面のcheckEvacOrderイベントを受け取る
            dialog.on('hideshelter', lang.hitch(this, function(evt) {
                // 避難所ダイアログで整理した情報を保管しておく
                this._shelterInfo = evt.value;
                // 一度避難所ダイアログを確認したので、、このまま配信できる状態にする
                domStyle.set(this.evacShelRegisterButton.domNode, 'display', '');
            }));

            // 登録画面のcancelイベントを受け取る
            dialog.on('cancel', lang.hitch(this, function() {
                this._closeDialog();
            }));

            // ダイアログの画面を初期化する。
            dialog.initDialog(args, false, false, false).then(lang.hitch(this, function(){

                // ダイアログを破棄できないように設定する。
                this.shelterRegisterDialog.set('closable', false);
                // ダイアログを表示する。
                this.shelterRegisterDialog.show();
            }));

        },

        closeDialog: function(){
            this._closeDialog();
            this.shelterRegisterDialog.getChildren()[0].closeDialog();
        },

        /////////////以下はLアラート以外の配信先用のロジック////////////////

        createUrgentMailInfo: function(obj) {
            var evacOrderData = {
                municipalityName: obj.urgentMailArgs.municipalityName,
                organizationName: obj.urgentMailArgs.organizationName,
                // riverName: obj.urgentMailArgs.riverName,
                municipalityCd : obj.evacOrderArgs.municipalityCd,
                evacTimestamp: obj.evacOrderArgs.evacTimestamp,
                evacOrderType: obj.evacOrderArgs.evacOrderType,
                issueReasonType: obj.evacOrderArgs.issueReasonType,
                issueReason: obj.evacOrderArgs.evacuateReason,
                evacGuidunce: obj.evacOrderArgs.evacGuidunce
            };
            // shelterListは、開設された避難所名と避難所区分をプロパティに持つオブジェクトのリスト
            var shelterList = [];
            if(obj.shelterArgs) {
                obj.shelterArgs.evaqueeData.forEach(function(evaqueeData){
                    var shelter = {
                        shelterName: evaqueeData.evacShelterName,
                        designatedEvacShFlg: evaqueeData.designatedEvacShFlg,
                        edesignatedEvacShFlg: evaqueeData.edesignatedEvacShFlg,
                        welfareEvacShFlg: evaqueeData.welfareEvacShFlg
                    };
                    shelterList.push(shelter);
                });
            }

            var args = {
                municipalityCd : obj.evacOrderArgs.municipalityCd,
                sendCategory   : this.URGENT_MAIL_CONTENT_TYPE,
                evacOrderData  : evacOrderData,
                shelterList: shelterList,
                districtListWholeArea   : obj.urgentMailArgs.districtList.districtList,
                districtList : obj.districtList

            };

            return args;
        },

        createUrgentMailSubject: function(obj){
            return EvacOrderType.get(obj.evacOrderArgs.evacOrderType).name;
        },

        // 配信内容にURL、電話番号、メールアドレスが含まれていないかをチェック
        // bodyTextValidate: function(text) {
        //     //URL正規表現パターン
        //     var chkURL = /(https?|ftp)(:\/\/[-_.!~*\'()a-zA-Z0-9;\/?:\@&=+\$,%#]+)/;
        //     // メールアドレス正規表現パターン({1}@{2} のような形式)
        //     var chkMail = /([a-zA-Z0-9])+([a-zA-Z0-9\._-])*@([a-zA-Z0-9_-])+([a-zA-Z0-9\._-]+)+/;
        //     // 電話番号正規表現パターン1(最初１−５桁)
        //     var chkTel1 = /\d{1,5}-\d{1,4}-\d{4}/;
        //     // 電話番号正規表現パターン2(最初２桁)
        //     var chkTel2 = /0\d-\d{4}-\d{4}/;
        //     // 電話番号正規表現パターン3(携帯電話)
        //     var chkTel3 = /(050|070|080|090)-\d{4}-\d{4}/;
        //     // 電話番号正規表現パターン4(フリーダイヤル)
        //     var chkTel4 = /0120-\d{3}-\d{3}/;
        //
        //     // URLが含まれているかチェック
        //     if (text.match(chkURL)) {
        //         console.debug('配信内容にURLが含まれています');
        //         return false;
        //     }
        //     // メールアドレスが含まれているかチェック
        //     else if (text.match(chkMail)) {
        //         console.debug('配信内容にメールアドレスが含まれています');
        //         return false;
        //     }
        //     // 電話番号が含まれているかチェック(4種類)
        //     else if (text.match(chkTel1) ||
        //         text.match(chkTel2) ||
        //         text.match(chkTel3) ||
        //         text.match(chkTel4)) {
        //         console.debug('配信内容に電話番号が含まれています');
        //         return false;
        //     } else {
        //         return true;
        //     }
        // },

        // 文面が空白のみで作られているかどうかチェックする。
        isTextFilled: function(text) {
            if (!text)  {
                return false;
            }

            text = text.replace(/\s/g, '');
            if (text.length > 0) {
                return true;
            } else {
                return false;
            }
        },
        // 文字数をカウントする。ただし改行は二文字とカウントする。
        countText: function(text) {
            text = text.replace(/\n/g, '\n\n');
            return text.length;
        },

        createEvacOrderTwitterBody: function(data){
            console.log(data);
        },

        createEvacOrderBodyText: function(data, isForTwitter) {
            var str = '';
            var evacOrder = data.evacOrderData;
            // var shelterList = data.shelterList;
            var districtList = data.districtList; //全域判定のない、生の地区一覧
            var districtListWholeArea = data.districtListWholeArea; //全域判定された地区名


            var sort = '発令';
            var evacOrderType = EvacOrderType.get(evacOrder.evacOrderType).name;
            if(EvacOrderUtil.isReleased(evacOrder.evacOrderType)){
                sort = '解除';
                var typeList = [];
                array.forEach(districtList, function(dist){
                    if(typeList.indexOf(EvacOrderType.get(dist.evacOrderType).issue) === -1) {
                        typeList.push(EvacOrderType.get(dist.evacOrderType).issue);
                    }
                }, this);
                evacOrderType = typeList.join('、');
            }

            if(evacOrder.evacOrderType !== null) {
                // 避難情報発令日時
                var evacTimestamp = locale.format(evacOrder.evacTimestamp, {
                    selector: 'date',
                    datePattern: 'yyyy/MM/dd HH:mm'
                });
                // 本文を生成する。
                var alertLevel = EvacOrderUtil.getAlertLevel(evacOrder.issueReasonType , evacOrder.evacOrderType);
                var alertLevelStr =
                    (alertLevel && alertLevel.level !== 0) ?
                    '【警戒レベル' + alertLevel.level + '】' + alertLevel.evacAction + '\n' : '';
                var municStr = '';
                Municipality.store.get(data.municipalityCd).then(
                    lang.hitch(this, function(item){
                    municStr = 'こちらは' + item.name + 'です。\n';
                }));
                str = (isForTwitter) ? (municStr + alertLevelStr) : (alertLevelStr + municStr);

                if(sort === '発令'){
                    var message = '';
                    if(evacOrder.issueReasonType === this.SEDIMENT){
                        message = EvacOrderType.get(evacOrder.evacOrderType).sedimentMessage;
                        if(message !== ''){
                            str += message + '、';
                        }
                    } else if(evacOrder.issueReasonType === this.FLOOD){
                        message = EvacOrderType.get(evacOrder.evacOrderType).floodMessage;
                        if(message !== ''){
                            str += evacOrder.riverName + message + '、';
                        }
                    }
                }

                str += evacTimestamp + 'に' +
                            evacOrderType + 'を' + sort + 'しました。\n\n';
            }

            // リストが0件でない場合は本文を生成する
            if (districtListWholeArea.length) {
                str += sort + '地区:';
                var areaName = '';
                array.forEach(districtListWholeArea, function(area) {
                    
                    if(areaName === area.wardMunicipalityName){
                            str += '、' + area.districtFreeName;
                    } else {
                        // 親地区の場合は全域に置き換える
                        if(area.districtFreeName === area.wardMunicipalityName){
                            str += '\n' + area.wardMunicipalityName + ':' + '全域';
                        // 大阪市の場合は行政区表示なし
                        } else if(area.districtName === config.municInfo.cityMunicName){
                            str += '\n' + area.districtFreeName;
                        } else {
                            str += '\n' + area.wardMunicipalityName + ':' + area.districtFreeName;
                        }
                        areaName = area.wardMunicipalityName;
                    }
                    //str += areaName + ':' + area.districtFreeName + '\n';
                });
                str +='\n\n';
            }

            if(isForTwitter){
                // str += '現在発令中の避難情報につきましては、下記ポータルサイトよりご確認下さい。' + '\n';
                // str += '大阪市防災ポータル URL: ' + this.SAPPORO_PORTAL_URL + '\n';
                str += '#避難情報' + ' ' + '#' + this._disasterName;
                // twitterの場合は短く切る。
                return str;
            }

            if(sort !== '解除' && evacOrder.issueReason){
                str += '理由：' + evacOrder.issueReason + '\n\n';
            }

            if(evacOrder.evacGuidunce){
                str += '避難行動指針：' + evacOrder.evacGuidunce + '\n\n';
            }

            // 避難所情報
            str += '避難所：';
            if(evacOrder.issueReasonType === this.SEDIMENT){
                str += 'ハザードマップ、テレビ、ラジオ、HP';
            } else if(evacOrder.issueReasonType === this.FLOOD){
                str += '洪水ハザードマップ、テレビ、ラジオ、HP';
            } else {
                str += 'テレビ、ラジオ、HP';
            }
            str += '等を参照\n\n';

            /**
            // 避難所名のリストが0件でない場合は本文を生成する。
            if(shelterList.length !==0) {
                // 避難所区分ごとに避難所名を格納するためのリスト。
                var edesignatedEvacShList = [];
                var designatedEvacShList = [];
                var welfareEvacShList = [];
                var edesignatedDesignatedEvacShList = [];
                var edesignatedWelfareEvacShList = [];
                var designatedWelfareEvacShList = [];
                var edesignatedDesignatedWelfareEvacShList = [];
                var otherEvacShList = [];

                // 避難所区分のない避難所だけかどうか
                var isOtherShTypeOnly = true;

                shelterList.forEach(function(shelter) {
                    if (shelter.edesignatedEvacShFlg === '1' && shelter.designatedEvacShFlg === '0' &&
                            shelter.welfareEvacShFlg === '0') {
                        edesignatedEvacShList.push(shelter.shelterName);
                    } else if (shelter.edesignatedEvacShFlg === '0' && shelter.designatedEvacShFlg === '1' &&
                                shelter.welfareEvacShFlg === '0') {
                        designatedEvacShList.push(shelter.shelterName);
                    } else if (shelter.edesignatedEvacShFlg === '0' && shelter.designatedEvacShFlg === '0' &&
                                shelter.welfareEvacShFlg === '1') {
                        welfareEvacShList.push(shelter.shelterName);
                    } else if (shelter.edesignatedEvacShFlg === '1' && shelter.designatedEvacShFlg === '1' &&
                                shelter.welfareEvacShFlg === '0') {
                        edesignatedDesignatedEvacShList.push(shelter.shelterName);
                    } else if (shelter.edesignatedEvacShFlg === '1' && shelter.designatedEvacShFlg === '0' &&
                                shelter.welfareEvacShFlg === '1') {
                        edesignatedWelfareEvacShList.push(shelter.shelterName);
                    } else if (shelter.edesignatedEvacShFlg === '0' && shelter.designatedEvacShFlg === '1' &&
                                shelter.welfareEvacShFlg === '1') {
                        designatedWelfareEvacShList.push(shelter.shelterName);
                    } else if (shelter.edesignatedEvacShFlg === '1' && shelter.designatedEvacShFlg === '1' &&
                                shelter.welfareEvacShFlg === '1') {
                        edesignatedDesignatedWelfareEvacShList.push(shelter.shelterName);
                    } else {
                        otherEvacShList.push(shelter.shelterName);
                    }
                });

                str += '開設された避難所は以下の通りです。\n';

                // それそれの避難所区分に避難所名があれば出力
                if (edesignatedEvacShList.length !== 0) {
                    str += '\n' + '[指定緊急避難場所]' + '\n';
                    edesignatedEvacShList.forEach(function(name) {
                        str += name + '\n';
                        isOtherShTypeOnly = false;
                    });
                }
                if (designatedEvacShList.length !== 0) {
                    str += '\n' + '[指定避難所]' + '\n';
                    designatedEvacShList.forEach(function(name) {
                        str += name + '\n';
                        isOtherShTypeOnly = false;
                    });
                }
                if (welfareEvacShList.length !== 0) {
                    str += '\n' + '[福祉避難所]' + '\n';
                    welfareEvacShList.forEach(function(name) {
                        str += name + '\n';
                        isOtherShTypeOnly = false;
                    });
                }
                if (edesignatedDesignatedEvacShList.length !== 0) {
                    str += '\n' + '[指定緊急避難場所・緊急避難所]' + '\n';
                    edesignatedDesignatedEvacShList.forEach(function(name) {
                        str += name + '\n';
                        isOtherShTypeOnly = false;
                    });
                }
                if (edesignatedWelfareEvacShList.length !== 0) {
                    str += '\n' + '[指定緊急避難場所・福祉避難所]' + '\n';
                    edesignatedWelfareEvacShList.forEach(function(name) {
                        str += name + '\n';
                        isOtherShTypeOnly = false;
                    });
                }
                if (designatedWelfareEvacShList.length !== 0) {
                    str += '\n' + '[指定避難所・福祉避難所]' + '\n';
                    designatedWelfareEvacShList.forEach(function(name) {
                        str += name + '\n';
                        isOtherShTypeOnly = false;
                    });
                }
                if (edesignatedDesignatedWelfareEvacShList.length !== 0) {
                    str += '\n' + '[指定緊急避難場所・指定避難所・福祉避難所]' + '\n';
                    edesignatedDesignatedWelfareEvacShList.forEach(function(name) {
                        str += name + '\n';
                        isOtherShTypeOnly = false;
                    });
                }
                if (otherEvacShList.length !== 0) {
                    // 避難所区分がnullのもだけであれば、避難所区分は表示しない
                    if (isOtherShTypeOnly) {
                        otherEvacShList.forEach(function(name) {
                            str += name + '\n';
                        });
                    } else {
                        str += '\n' + '[その他の避難所]' + '\n';
                        otherEvacShList.forEach(function(name) {
                        str += name + '\n';
                        });
                    }
                }

                // 最後のスペースは削除
                str = str.slice(0,-1);
                str += '\n\n';
            }
             */

            // 発令者を本文に追加する。(熊本・岡山では廃止)
            // if(evacOrder.municipalityName !== null && evacOrder.organizationName !== null) {
            //     str += '(' + evacOrder.municipalityName +' '+ evacOrder.organizationName + ')';
            // }

            return str;
        },

        onBodyTextChange: function(){
            setTimeout(lang.hitch(this, this.countBodyText), 10);
        },

        countBodyText: function(){
            var bodyText = this.urgentMailBodyText.get('value');
            var textLength = this.countText(bodyText);
            this.textLength.innerHTML = textLength;
        },

        onBodyTextChange2: function(){
            setTimeout(lang.hitch(this, this.countBodyText2), 10);
        },

        countBodyText2: function(){
            var bodyText = this.urgentMailBodyText2.get('value');
            var textLength = this.countText(bodyText);
            this.textLength2.innerHTML = textLength;
        },

        onBodyTextChange3: function(){
            setTimeout(lang.hitch(this, this.countBodyText3), 10);
        },

        countBodyText3: function(){
            var bodyText = this.urgentMailBodyText3.get('value');
            var textLength = this.countText(bodyText);
            this.textLength3.innerHTML = textLength;
        },

        onEmergencyBodyTextChange: function(){
            setTimeout(lang.hitch(this, this.countEmergencyBodyText), 10);
        },

        countEmergencyBodyText: function(){
            var bodyText = this.emergencyPushBodyText.get('value');
            var textLength = this.countText(bodyText);
            this.emergencyTextLength.innerHTML = textLength;
        },

        validateUrgentMailSubject: function(){
            if (!this.isTextFilled(this.subjectInput.get('value'))) {
                console.debug('タイトルが入力されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : 'タイトルが入力されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }
            if (!ProvideUtil.urgentMailValidate(this.subjectInput.get('value'))) {
                console.debug('タイトルに電話番号・URL・メールアドレスを含むことはできません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : 'タイトルに電話番号・URL・メールアドレスを含むことはできません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }
            if (this.countText(this.subjectInput.get('value')) > 15) {
                var message4subject = 'タイトルは15文字以内で入力してください。(現在' +
                this.countText(this.subjectInput.get('value')) + '文字)';
                console.debug(message4subject);
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : message4subject
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }
            return true;
        },

        validateUrgentMailBodyText: function(){
            if (!this.isTextFilled(this.urgentMailBodyText.get('value'))) {
                console.debug('本文が入力されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '本文が入力されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }
            if (!ProvideUtil.urgentMailValidate(this.urgentMailBodyText.get('value'))) {
                console.debug('配信内容に電話番号・URL・メールアドレスを含むことはできません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '配信内容に電話番号・URL・メールアドレスを含むことはできません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }
            if (this.countText(this.urgentMailBodyText.get('value')) > 200) {
                var message4body = '本文は200文字以内で入力してください。(現在' +
                this.countText(this.urgentMailBodyText.get('value')) + '文字)';
                console.debug(message4body);
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : message4body
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }
            return true;
        },

        // 文字を1文字入力するたびにTwitterの残り文字数を更新
        onBodyTextChangeTwitter: function(evt){
            setTimeout(lang.hitch(this, this.showLength(evt)), 10);
        },

        // Twitter残り文字数を表示
        showLength: function(evt) {
            // 残り文字数計算
            var text = evt.srcElement.value;
//            var num = evt.srcElement.name.substr(11);
            var words =  this.TWITTER_MAX_LENGTH - this.getLen(text);
//            dojo.byId('twitterLeft'+ num).innerHTML = '残り' + words+ '文字';
            evt.srcElement.nextElementSibling.innerHTML = '残り' + words+ '文字';
        },

        addTweet: function(oneTweetText, i) {
            // +ボタンで追加した場合
            if (oneTweetText.type === 'click') {
                oneTweetText = '';
                this._tweetNum += 1;
                i = this._tweetNum;
            } else {
                // 最終ツイート以外に「続く」を入れる
                if (i !== this._tweetNum) {
                    oneTweetText = oneTweetText + '(続く)';
                }
            }
            // 最初ツイート以外に「続き」を入れる
            if (i !== 1) {
                oneTweetText = '(続き)' + oneTweetText;
            }
            this.createTweetElements(oneTweetText, i);
        },

        /**
         * Twitterの文字数をTwitter基準でカウントする。
         * 日本語は1文字3バイト、英語は1文字1バイトだが
         * バイト数とは関係なく最大文字数は日本語で140字、英語で280文字。
         */
        getLen: function(str){
            var result = 0;
            for(var i=0;i<str.length;i++){
                result += this.getCharSize(str.substr(i, 1));
            }
            //文字数を返す
            return result;
        },

        /**
         * 引数の1文字のサイズを返却する
         */
        getCharSize: function(str) {
            // 文字コードを取得
            var chr = str.charCodeAt(0);
            // 半角は0.5文字としてカウント
            if((chr >= 0x00 && chr < 0x81) ||
                (chr === 0xf8f0) ||
                (chr >= 0xff61 && chr < 0xffa0) ||
                (chr >= 0xf8f1 && chr < 0xf8f4)){
                //半角文字の場合は0.5を加算
                return 0.5;
            }else{
                // 全角は1文字としてカウント
                return 1;
            }
        },

        /**
         * 地域名・ハッシュタグ・リンク等を破壊しないようにツイート文字列を分割、配列で返却する
         */
        splitTweetText: function(str) {
            var result = [];
            // 文字数が140文字以内の場合（1枠に収まる場合）
            if (this.getLen(str) <= this.TWITTER_MAX_LENGTH) {
                // そのまま配列に追加して返却
                result.push(str);
                return result;
            }
            var tweetFrameCount = 2;
            var connectWordLengthFirst = 3;
            var connectWordLengthLast = 3;
            var currentTextLen = 0;
            for (var i = 0; i < tweetFrameCount; i++) {
                var oneTweetContentLength = this.TWITTER_MAX_LENGTH;
                var oneTweetText = '';
                if (i === 0) {
                    // 最初のツイート枠の場合
                    // 「(続く)」分の文字数を減らす
                    oneTweetContentLength -= connectWordLengthLast;
                } else {
                    // 「(続き)」分の文字数を減らす
                    oneTweetContentLength -= connectWordLengthFirst;
                }
                // 現在のツイート枠用に文字列を取得する
                oneTweetText = this.substrForTwitter(str, currentTextLen, oneTweetContentLength);
                // 現在のツイート枠が最後、かつ文字列が用意したツイート枠に収まりきっていない場合
                if (i + 1 === tweetFrameCount && currentTextLen + oneTweetText.length < str.length) {
                    // 現在のツイート枠から「(続く)」分の文字数を減らして文字列を再取得する
                    oneTweetContentLength -= connectWordLengthLast;
                    oneTweetText = this.substrForTwitter(str, currentTextLen, oneTweetContentLength);
                    // ツイート枠を追加
                    tweetFrameCount++;
                }
                result.push(oneTweetText);
                // 次の文字列抽出開始位置を保存
                currentTextLen += oneTweetText.length;
            }
            return result;
        },

        /**
         * Twitter用substr
         */
        substrForTwitter: function(str, start, length) {
            var result = '';
            var currentTextLen = start;
            var currentTweetTextLen = 0;
            // 区切り文字として扱う文字
            var separatorList = ['、', '。', '\n'];
            // 現在のTwitter文字数＋次に結合する文字数が1枠の文字数上限以下であれば、文字列を結合する
            while (currentTweetTextLen + this.getCharSize(str.substr(currentTextLen, 1)) <= length) {
                var currentStr = str.substr(currentTextLen, 1);
                result += currentStr;
                currentTweetTextLen += this.getCharSize(currentStr);
                currentTextLen++;
                // 現在の文字が区切り文字の場合、次の区切り文字出現位置をチェックして文字列を抽出する
                if (separatorList.indexOf(currentStr) !== -1) {
                    var separatorIndexList = [];
                    for (var i = 0; i < separatorList.length; i++) {
                        // 未発見は無視
                        if (str.indexOf(separatorList[i], currentTextLen) !== -1) {
                            separatorIndexList.push(str.indexOf(separatorList[i], currentTextLen));
                        }
                    }
                    var nextIndex = str.length;
                    if (separatorIndexList.length > 0) {
                        // 最初に出現するもの（＝最小値）を採用
                        nextIndex = this.getMinValueFromArray(separatorIndexList) + 1;
                    }
                    var extractedStr = str.substr(currentTextLen, nextIndex - currentTextLen);
                    if (currentTweetTextLen + this.getLen(extractedStr) > length) {
                        // 現在の文字数＋抽出した文字数が1枠の上限を超える場合、ループを終了して次のツイート枠で処理する
                        break;
                    }
                }
            }
            // 文字列を返す
            return result;
        },

        /**
         * 引数の配列から最小値を取得する
         */
        getMinValueFromArray: function(ary) {
            var minValue = ary.reduce(function(a, b){
                return Math.min(a, b);
            });
            return minValue;
        },

        // Tweet枠を削除
        deleteTweet: function(){
            if (this._tweetNum > 1) {
//                var element = dojo.byId('twitterOuterFrame');
                var element = this.twitterOuterFrame;
                element.removeChild(element.lastChild);
                this._tweetNum -= 1;
            }
        },

        // 本文の文字数に従ってTwitter用の入力項目を作成
        createTweetElements: function(oneTweetText, i) {
            // 枠を追加
            var div = dojo.create('div');
            div.name = 'addedTweet';

            // テキストボックスを作成
            var addedText = new Textarea({
                name: 'twitterBody' + i,
                style: 'height:40px',
                value: oneTweetText
            });

            addedText.startup();

            // 残り文字数を作成
            var leftWords = dojo.create('div');
            var leftWordLength = this.TWITTER_MAX_LENGTH - this.getLen(oneTweetText);
            leftWords.innerHTML = '残り' + leftWordLength + '文字';
            leftWords.id = 'twitterLeft' + i;
            leftWords.name = 'twitterLeft';
            leftWords.align = 'right';

            // 新規作成した要素を親に加える
            this.twitterOuterFrame.appendChild(div);
            div.appendChild(addedText.domNode);
            div.appendChild(leftWords);
        },

        /**
         * メンバ変数_sendingSettingからtwitterIdとして表示する文字列を返す
         */
        getTwitterIdStr: function() {
            return this._sendingSetting.municipalityName + ' @' + this._sendingSetting.twitterId;
        },

        /**
         * Twitter入力チェック
         */
        validateTwitter: function(){
            // テキストエリアの残り文字数計算
//            var element = dojo.byId('twitterOuterFrame');
            var element = this.twitterOuterFrame;
            var elements = element.getElementsByTagName('textarea');
            // 文字数制限を超えている時にエラーメッセージの表示
            for (var i=0; i < elements.length; i++){
                var text = elements[i].value;
                var words =  this.TWITTER_MAX_LENGTH - this.getLen(text);
                // 配信内容が空の時にエラーメッセージの表示
                if (elements[i].value === ''){
                    InfoDialog.show('',(i+1) + '番目のTwitterの配信内容が空です。配信内容を記載してください。');
                    return;
                }
                if (words < 0){
                    InfoDialog.show('',(i+1) + '番目のTwitterの文字数制限を超えています。各記入枠の文字数を制限内に抑えてください。');
                    return;
                }
            }
            return true;
        },

        validateEmergencyPushSubject: function(){
            if (!this.isTextFilled(this.emergencyPushSubjectInput.get('value'))) {
                console.debug('タイトルが入力されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : 'タイトルが入力されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }
            if (this.countText(this.emergencyPushSubjectInput.get('value')) > 15) {
                var message4subject = 'タイトルは15文字以内で入力してください。(現在' +
                this.countText(this.emergencyPushSubjectInput.get('value')) + '文字)';
                console.debug(message4subject);
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : message4subject
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }
            return true;
        },

        validateEmergencyPushBodyText: function(){
            if (!this.isTextFilled(this.emergencyPushBodyText.get('value'))) {
                console.debug('本文が入力されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '本文が入力されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }
            if (this.countText(this.emergencyPushBodyText.get('value')) > 200) {
                var message4body = '本文は200文字以内で入力してください。(現在' +
                this.countText(this.emergencyPushBodyText.get('value')) + '文字)';
                console.debug(message4body);
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : message4body
                    });
                }
                this.infoDialog.show();
                this.infoDialog = null;
                return false;
            }
            return true;
        }

    });
});