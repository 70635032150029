define([
    // ここに書く
    'app/convocation/view/ConvocationTopPage',
    'app/convocation/view/condition/ConvoAutoMailDetailPage',
    'app/convocation/view/condition/ConvoAutoMailRegisterPage',
    'app/convocation/view/condition/ConvocationAutoCondAdminPage',
    'app/convocation/view/employee/ConvocationEmployeeAdminPage',
    'app/convocation/view/group/ConvoGroupDetailPage',
    'app/convocation/view/group/ConvoGroupRegisterPage',
    'app/convocation/view/group/ConvocationGroupAdminPage',
    'app/convocation/view/mail/ConvoMailTempPage',
    'app/convocation/view/mail/ConvoStatusDetailPage',
    'app/convocation/view/mail/ConvoStatusListPage',
    'app/convocation/view/mail/ConvocationMailAdminPage',
    'app/convocation/view/user/UserAdminPage',
    'app/convocation/view/history/ConvocationMailHistoryPage',
    // ここまで
], function () { });