/**
 * 被害情報 集計 閲覧画面用モジュール。
 * @module app/damage/humanDamageReport/HumanDamageReportViewDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/HumanDamageReportViewDialog.html',
    'idis/control/Router',
    'app/damage/humanDamageReport/HumanDamageReportDialog',
    // 以下、変数として受け取らないモジュール
    'app/damage/humanDamageReport/HumanDamageReportInputViewDialog',
], function (module, declare, template, Router, HumanDamageReportDialog) {

    /**
     * 被害概要 集計 閲覧ページ
     * @class HumanDamageReportViewDialog
     * @extends module:app/damage/humanDamageReport/HumanDamageReportDialog#~HumanDamageReportDialog
     */
    return declare(module.id.replace(/\//g, '.'), HumanDamageReportDialog,
        /** @lends module:app/damage/humanDamageReport/HumanDamageReportViewDialog# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--damage',

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
            },

            // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
            constructor: function () {
            },

            startup: function () {
                this.inherited(arguments);
            },
        });
});

