/**
 * クロノロジ新規登録画面用モジュール。
 * @module app/chronology/ChronologyRegisterPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/json',
    'dojo/dom-class',
    'dojo/dom-style',
    'dojo/text!./templates/ChronologyRegisterPage.html',
    'dojo/promise/all',
    'idis/view/page/_PageBase',
    'idis/map/IdisMap',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    'leaflet',
    '../config',
    'esri-leaflet-geocoder',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/IdisDialog',
    'app/map/baselayer/BaseLayerPane',
    'dojo/on',
    'dijit/TooltipDialog',
    'dijit/popup',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/view/Loader',
    'idis/view/dialog/InfoDialog',
    './_ChronologyPageBase',
    './ChronologyUtil',
    'app/model/DisasterInfo',
    'dojox/lang/functional/array',
    'app/model/Municipality',
    './consts/ChronologyType',
    // 以下、変数で受けないモジュール
    'idis/view/form/AclButton',
    'dijit/form/ValidationTextBox',
    'dijit/form/CheckBox',
    'dijit/form/RadioButton',
    'app/view/form/ShareOrgsMultiSelector4Chronology',
    'app/view/form/ManageRegionMunicipalitySelector',
    'app/view/form/MunicipalitySelector',
    'app/view/form/ShareOrganizationMultiSelector',
    'app/view/form/MunicOrganizationSelectorWithSearch'
], function(module, declare, lang, array, json, domClass, domStyle, template, all, _PageBase,
	IdisMap, UserInfo, USER_TYPE, leaflet, config, geocoder, DialogChain, IdisDialog,
	BaseLayerPane, on, TooltipDialog, popup, Locator, Router, Requester, Loader, InfoDialog,
    _ChronologyPageBase, ChronologyUtil, DisasterInfo, df, Municipality, ChronologyType) {
    /**
     * クロノロジ新規登録画面
     * @class ChronologyRegisterPage
     * @extends module:app/chronology/_ChronologyPageBase~_ChronologyPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _ChronologyPageBase,
    /** @lends module:app/chronology/ChronologyRegisterPage~ChronologyRegisterPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--chronology',

        // 危機管理対策室コード
        _sapporoMuniCd: '011002',

        // 危機管理対策室コード
        _sapporoDeptCd: 'D13761',

        // システム管理ユーザ
        _roleSystemAdmin: 'R01001',

        // 市・危機管理・管理ユーザ
        _roleAdmin: 'R01011',

        // 市・危機管理・職員ユーザ
        _roleStaff: 'R01012',

        /**
         * constructor
         */
        constructor: function() {
            console.debug('constructor');
            this._scenarioId = Locator.getQuery().scenarioId;
            this._simulateFlg = Locator.getQuery().simulateFlg;
            this._eventType = Locator.getQuery().eventType;
            this._content = Locator.getQuery().content;
            this._timing = Locator.getQuery().timing;
        },

        buildRendering: function(){
            this.inherited(arguments);
            this.own(this.linkInsertDialog);
            // this.organizationSelector.restoreRefresh();
        },

        /**
         * startup
         */
        startup: function() {
            console.debug('startup');
            this.inherited(arguments);
            this._senderMunicCd = (UserInfo.getUserType() === USER_TYPE.MUNICIPALITY) ?
                    UserInfo.getMunicipalityCds()[0] : config.municInfo.prefMunicCd;
            // 宛先ラベルにhintをつける
            //this.setShareTips();

            // クロノロジ新規登録画面を初期化
            this.initRegisterPage();
        },

        /**
         * postCreate
         */
        postCreate: function() {
            console.debug('postCreate');
            this.inherited(arguments);
        },

        /**
         * クロノロジ新規登録画面を初期化する
         */
        initRegisterPage: function() {
            // プレビューを初期化
            this.clearPreview();
            // 諸変数の初期化
            this.attachFileList = [];
            this.pointLat = '';
            this.pointLng = '';
            this.marker = '';

            var userMunicCd = UserInfo.getMunicipalityCd();
            // var userRegionCd = UserInfo.getRegionCd();
            var organizationCd = (UserInfo.getOrganization().unitCd ? 'U' + UserInfo.getOrganization().unitCd :
			UserInfo.getOrganization().sectCd ? 'S' + UserInfo.getOrganization().sectCd :
            UserInfo.getOrganization().deptCd ? 'D' + UserInfo.getOrganization().deptCd : '');

            // 要請時の配信元の設定
            this.senderOrganization.onMunicipalityChange('271004');   //要請時配信元を大阪市の下の組織が選択できるようにした
            this.senderOrganization.set('value', organizationCd);
            if (UserInfo.getRoleCd() === 'R01001' || UserInfo.getRoleCd() === 'R01011' || 
                UserInfo.getRoleCd() === 'R01031'){
                this.senderOrganization.set('noAllButton', false);
            } else {
                this.senderOrganization.set('noAllButton', true);
            }
            // 情報区分
            this.chronologyType.set('value', '30');
            this.onChronologyTypeChange();
            // 宛先
            // 要請時宛先を大阪市の下の組織が選択できるかつ災害対策本部配下のみに設定
            this.shareOrganization.onModelSettingChange('271004', true);
            // 要請時宛先を大阪市の下の組織に設定
            // this.shareOrganization.onMunicipalityChange(UserInfo.getMunicipalityCd());


            // ユーザ種別に応じて、地図のセット・配信元の確定
            var promise;
            if(userMunicCd){
                // 市町村・県・関係機関ユーザの場合
                // 市町村の位置に地図をセット＋市町名を取得
                promise = Requester.get('/api/municipalities/' + userMunicCd, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function(data) {
                    // 地図を初期化
                    this.initMap(data.latitude, data.longitude);
                    this._senderName = organizationCd;
                }), lang.hitch(this, function(error) {
                    this.chain.infoError(error);
                }));
                //ローダーの表示
                Loader.wait(promise);
            }
        },



        /**
         * 市町村コードを再セットする。
         */
        //  onMunicipalityChange: function(){
        // 	console.debug('市町村が変更されました');
        //     var selectedMunicCd = this.shareMunicipalityCd.get('value')[0];

        //     // 市町未選択の場合、共有先組織を非表示にして終了
        //     if(!selectedMunicCd){
        //         this.chronologyShares.set('value', []);
        //         domStyle.set(this.chronologySharesAlert, 'display', '');
        //         domStyle.set(this.chronologyShares.domNode, 'display', 'none');
        //         return false;
        //     }

        //     //地図の位置を変更
        //     if(this.map){
        //         Requester.get('/api/municipalities/' + selectedMunicCd, {
        //             headers: {'Content-Type': 'application/json; charset=utf-8'},
        //             handleAs: 'json',
        //             preventCache : true
        //         }).then(lang.hitch(this, function(municipality) {
        //             // マップを初期化
        //             if(municipality.latitude && municipality.longitude){
        //                 this.map.setView([municipality.latitude,municipality.longitude]);
        //             }
        //         }), lang.hitch(this, function(error) {
        //             console.log(error);
        //             this.chain.info('情報の取得に失敗しました。', 'エラー');
        //         }));
        //     }

        //     //共有先の入力情報を初期化の上、ツリーを再構築
        //     this.chronologyShares.set('value', []);
        //     this.chronologyShares.onMunicipalityChange(selectedMunicCd);
        //     //共有先セレクタを表示
        //     domStyle.set(this.chronologySharesAlert, 'display', 'none');
        //     domStyle.set(this.chronologyShares.domNode, 'display', '');

        // },

        // 仮登録する
        registerChronologyTemp: function(){
            this.registerChronology(false);
        },

        // 本登録する
        registerChronologyActual: function(){
            this.registerChronology(true);
        },

        /**
         * クロノロジを登録する。
         */
        registerChronology: function(isActual) {
            var sendData = this.form.get('value');

            // 入力チェック
            var errMsg = ( sendData.chronologyType === ChronologyType.DISASTER_HLD.id && 
                sendData.shareOrganization.length === 0) ? '宛先が選択されていません' : null;
            if (!this.infoDialog && errMsg !== null) {
                this.infoDialog = new InfoDialog({
                    title : 'エラー',
                    content : errMsg
                });

                this.infoDialog.show();
                this.infoDialog=null;
                return false;
            }


            // フォームのバリデーションを行う（共通部品）
            if(!this.form.validate()) {
                return false;
            }

            sendData.actualFlg = isActual ? '1' : '0';

            var chronologyShareOrgs = [];
            var senderOrgCd = null;
            var shareOrgCds = [];
            var chronologyCategoryCd = '';

            // 配信元・宛先、情報種別コードを情報種別に合わせて代入
            if(sendData.chronologyType === ChronologyType.DISASTER_HLD.id){
                // 展開しないと更新時子要素を読み込めないため一度展開して閉じる
                this.senderOrganization.tree.expandAll();
                this.senderOrganization.tree.collapseAll();
                shareOrgCds = this.shareOrganization.get('value');
                var checkCompletedMap = this.shareOrganization.tree._checkCompletedMap;
                Object.keys(checkCompletedMap).forEach((key) => {
                    if (key.slice(0,2) === 'DD') {
                        // 親を選択した場合、かつ子要素を持っている組織の場合、宛先に親の組織を追加し子の組織を消す
                        var children = this.shareOrganization.model.childrenCache[key];
                        if (children){
                            Object.keys(children).forEach(childKey =>
                                shareOrgCds = shareOrgCds.filter((shareOrgCd)=>{
                                    return shareOrgCd !== children[childKey].id;
                                })
                            );
                        }
                        shareOrgCds.push(key);
                    }
                });
                senderOrgCd = this.senderOrganization.get('value');
                shareOrgCds.forEach(() =>{
                    chronologyShareOrgs.push({id: UserInfo.getMunicipalityCd()});
                });
                chronologyCategoryCd =  String(this.chronologyCategoryForRequest.get('value'));
            } else if(sendData.chronologyType === ChronologyType.PREFECTURE_SHARE.id){
                senderOrgCd = '271004';
                shareOrgCds = ['270008'];
                chronologyShareOrgs.push({id: '270008'});
                chronologyCategoryCd =  String(this.chronologyCategoryForPrefectureCooperation.get('value'));
            }

            // 共有先情報を設定する。
            var chronologyShares = [];

            // 送信元をセットする。
            var senderMunicCd = UserInfo.getMunicipalityCd();
            // 先頭に配信元を追加
            chronologyShareOrgs.unshift({id: senderMunicCd});
            // 各種設定のフラグを取得する
            var typeSetting = ChronologyUtil.getSettingFromCode(sendData.chronologyType);
            sendData.municipalityAuthorizeFlg = typeSetting.municipalityAuthorizeFlg;
            sendData.organizationAuthorizeFlg = typeSetting.organizationAuthorizeFlg;
            // 市町村データを取得。
            all(
                array.map(chronologyShareOrgs, function(org){
                    return this.getMunicipalityInfo(org.id);
                }, this)
            ).then(lang.hitch(this, function(municInfos){
                // 宛先組織の数だけループする
                if(sendData.chronologyType === ChronologyType.DISASTER_HLD.id){
                    array.forEach(municInfos, function(infos, index){
                    // 仮想ルートと全体を宛先から除く
                        if(infos && infos.id !== '$ROOT$' && infos.id !== '0'){
                            var share = {};
                            var shareOrg = {};
                            // 配信元の場合
                            if(index === 0){
                                share.senderFlg = '1';
                                share.destinationFlg = '0';
                                shareOrg = this.setOrganizationFromTreeItem(
                                    this.senderOrganization.tree._itemNodesMap, senderOrgCd);
                            } else {
                                share.senderFlg = '0';
                                share.destinationFlg = '1';
                                var shareOrgCd = shareOrgCds[index-1];
                                shareOrg = this.setOrganizationFromTreeItem(
                                    this.shareOrganization.tree._itemNodesMap, shareOrgCd);
                            }

                            if(!typeSetting.isMunicipalityUnit && shareOrg !== null){
                                share.deptCd = shareOrg.deptCd;
                                share.sectCd = shareOrg.sectCd;
                                share.unitCd = shareOrg.unitCd;
                                share.organizationCd = shareOrg.unitCd ? shareOrg.unitCd :
                                    shareOrg.sectCd ? shareOrg.sectCd : shareOrg.deptCd ? shareOrg.deptCd : null;
                            }
                            share.municipalityCd = infos.id;
                            share.regionCd = infos.regionCd ? infos.regionCd : null;
                            share.shareName = typeSetting.isShareNameOrganization ?
                                infos.name : shareOrg !== null ? shareOrg.name : infos.name;
                            chronologyShares.push(share);
                        }
                    }, this);
                } else if (sendData.chronologyType === ChronologyType.PREFECTURE_SHARE.id){
                    // 大阪府連携の時の配信元・宛先を設定する
                    var sender = {};
                    // 配信元大阪市
                    sender.senderFlg = '1';
                    sender.destinationFlg = '0';
                    sender.municipalityCd= '271004';
                    sender.organizationCd = null;
                    sender.regionCd = null;
                    sender.sectCd = null;
                    sender.senderFlg = '1';
                    sender.shareName = '大阪市';
                    sender.unitCd = null;
                    chronologyShares.push(sender);

                    var share = {};
                    // 宛先大阪府
                    share.senderFlg = '0';
                    share.destinationFlg = '1';
                    share.municipalityCd = '270008';
                    share.organizationCd = null;
                    share.regionCd = null;
                    share.sectCd = null;
                    share.senderFlg = '0';
                    share.shareName = '大阪府';
                    chronologyShares.push(share);
                }

                //  共有先情報をセットする
                sendData.chronologyShares = chronologyShares;

                // 送信情報の補完
                sendData.sourceAddress02 = '';

                if(sendData.sourceAddress01) {
                    sendData.sourceLatitude = this._sourceAddressLat;
                    sendData.sourceLongitude = this._sourceAddressLng;
                } else {
                    sendData.sourceLatitude = '';
                    sendData.sourceLongitude = '';
                }
                sendData.chronologyType = String(this.chronologyType.value);
                sendData.chronologyCategory = chronologyCategoryCd;
                sendData.disasterId = DisasterInfo.getDisasterId();

                //添付ファイルIDをセット
                if(this.attachFileList) {
                    var attachFileIds = [];
                    for(var i=0; i<this.attachFileList.length; i++) {
                        attachFileIds.push(this.attachFileList[i].chronologyAtcFileId);
                    }
                    sendData.attachFileIds = attachFileIds.join(',');
                } else {
                    sendData.attachFileIds = '';
                }

                sendData.allowPublishingFlg = (sendData.allowPublishing[0] === 'on') ? '1' : '0';

                delete sendData.allowPublishing;
                delete sendData.attachFile;
                delete sendData.organizationName;
                delete sendData.shareMunicipalityCd;
                delete sendData.senderOrganization;
                // delete sendData.senderNameMunic; // todo 情報種別による切り替え修正
                delete sendData.shareOrganization;

                var jsonStr = null;

                //登録確認ダイアログを表示
                var message = null;
                // 災害シミュレーションのイベントとして登録する場合
                if(this._simulateFlg === 'true'){
                    delete sendData.chronologyPostForm;
                    var subSendData = {};
                    subSendData.chronologyTypeName = this.chronologyType.domNode.innerText;
                    subSendData.hldStatusName = this.hldStatus.domNode.innerText;
                    subSendData.chronologyCategoryName = this.chronologyCategory.domNode.innerText;
                    subSendData.urgencyTypeName = this.urgencyType.domNode.innerText;
                    jsonStr = json.stringify({
                        chronologyPostForm: sendData,
                        chronologyConversionForm: subSendData,
                        scenarioId: this._scenarioId,
                        eventType: this._eventType,
                        content: this._content,
                        timing: this._timing});
                    message = isActual ? '【本登録】としてイベント登録します。' :
                                        '【仮登録】としてイベント登録します。';
                    this.chain.confirm(message, function(chain) {
                        var promise = Requester.post('/api/simulations/event/', {
                            data: jsonStr,
                            headers: {'Content-Type': 'application/json; charset=utf-8'},
                            handleAs: 'json',
                            preventCache : true
                        }).then(function() {
                            console.debug('success register Chronology event');
                            chain.infoComplete(function() {
                                chain.hide();
                                Router.moveTo('simulation/event', {scenarioId: this._scenarioId});
                            });
                        }, function(error) {
                            console.log('error register Chronology event');
                            chain.infoError(error);
                        });
                        //ローダーの表示
                        Loader.wait(promise);
                    });
                } else {
                    jsonStr = json.stringify(sendData);
                    if (sendData.actualFlg === '0'){
                        message = '【仮登録】として登録します。<br>他のユーザには見えない状態で保存されます。<br>登録ユーザは、再編集及び本登録することが可能です。';
                    } else {
                        message = '【本登録】として登録します。<br>登録後、他のユーザも登録情報が閲覧可能となります。';
                    }
                    this.chain.confirm(message, function(chain) {
                        var promise = Requester.post('/api/chronologies', {
                            data: jsonStr,
                            headers: {'Content-Type': 'application/json; charset=utf-8'},
                            handleAs: 'json',
                            preventCache : true
                        }).then(function() {
                            console.debug('success register Chronology');
                            chain.infoComplete(function() {
                                chain.hide();
                                // 一覧画面に移動
                                Router.moveTo('chronology');
                            });
                        }, function(error) {
                            console.log('error register Chronology');
                            chain.infoError(error);
                        });
                        //ローダーの表示
                        Loader.wait(promise);
                    });
                    console.debug('[登録]ボタンがクリックされました。');
                }
            }), lang.hitch(this, function(error){
                console.error(error);
                this.chain.infoError(error);
            }));
        },

        onChronologyTypeChange: function(){
            var type = this.chronologyType.get('value');
            if(type === ChronologyType.DISASTER_HLD.id){
                // 配信元セット
                domStyle.set(this.senderSelectAreaForRequest, 'display', '');
                domStyle.set(this.senderSelectAreaForPrefectureCooperation, 'display', 'none');
                // 情報区分セット
                domStyle.set(this.chronologyCategoryForRequestArea, 'display', '');
                domStyle.set(this.chronologyCategoryForPrefectureCooperationArea, 'display', 'none');
                // 宛先セット
                domStyle.set(this.shareSelectAreaForRequest, 'display', '');
                domStyle.set(this.shareSelectAreaForPrefectureCooperation, 'display', 'none');
            } else if(type === ChronologyType.PREFECTURE_SHARE.id) {
                // 配信元セット
                domStyle.set(this.senderSelectAreaForRequest, 'display', 'none');
                domStyle.set(this.senderSelectAreaForPrefectureCooperation, 'display', '');
                // 情報区分セット
                domStyle.set(this.chronologyCategoryForRequestArea, 'display', 'none');
                domStyle.set(this.chronologyCategoryForPrefectureCooperationArea, 'display', '');
                // 宛先セット
                domStyle.set(this.shareSelectAreaForRequest, 'display', 'none');
                domStyle.set(this.shareSelectAreaForPrefectureCooperation, 'display', '');
            }

        },
        /**
         * クロノロジ一覧画面へ遷移する。
         */
        onChronologyLinkClick:function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('chronology');
        }
    });
});
