/**
 * 職員招集管理者管理画面用モジュール。
 * @module app/convocation/view/user/UserAdminPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/text!./templates/UserAdminPage.html',
    'idis/view/page/_PageBase',
    'dojo/dom',
    'dijit/registry',
    'dijit/Dialog',
    'dstore/Memory',
    'dstore/Rest',
    'idis/consts/ACL',
    'idis/view/grid/IdisGrid',
    'idis/view/form/AclButton',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/service/Requester',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'app/view/form/EmployeeDivisionSelector',
    './UserAdminGrid',
    './UserRegisterDialog',
    './UserDetailDialog'
], function(module, declare, lang, json, template, _PageBase, dom, registry,
    Dialog, Memory, Rest, ACL, IdisGrid, AclButton, IdisRest, Loader, DialogChain, Requester) {
    /**
     * 職員招集管理者管理画面
     * @class UserAdminPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/convocation/view/user/UserAdminPage~UserAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--user',

        /**
         * ストア
         */
        store: null,

        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'userId',
                target: '/api/convoMails/users',
                sortParam: 'sort',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });
            // ダイアログ連鎖を登録
            this.chain = DialogChain.get(this);
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.innerUserRegisterDialog);
            this.own(this.innerUserDetailDialog);
        },

        /**
         * startup
         */
        startup: function() {
            this.inherited(arguments);

            this.initGrid();
            this.initPage();
        },

        /**
         * グリッドを初期化する。
         */
        initGrid: function() {
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.userAdminGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                this.showUpdateDialog(evt.item);
            }));

            this.updateGridQuery();
        },

        /**
         * 職員招集管理者管理画面を初期化する。
         */
        initPage: function() {
            // 役割セレクトボックスの初期化
            this.initSelectBox();

            // 新規登録ダイアログを初期化
            this.initRegisterDialog();

            // 詳細ダイアログを初期化
            this.initDetailDialog();
        },

        /**
         * 新規登録ダイアログを初期化する。
         */
        initRegisterDialog: function() {
            var dialog = this.userRegisterDialog;
            var page = dialog.getChildren()[0];

            // 登録画面のregisterイベントを受け取る
            page.on('register', lang.hitch(this, function(evt) {
                console.debug('registerイベント');
                // 追加確認ダイアログを表示
                this.chain.confirmAdd(function(chain) {
                    // OKが押された場合
                    // 追加処理と共にローディング表示
                    Loader.wait(this.store.add(evt.value)).then(lang.hitch(this, function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                        // 一覧を取得しなおす
                        this.updateGridQuery();
                    }), function(err) {
                        if(err.response.data) {
                            var jsonData = json.parse(err.response.data);
                            // ユーザIDが重複していた場合はDBエラー（E2001）
                            if(jsonData.code && jsonData.code === 'E2001') {
                                chain.info('ユーザーIDが重複しています。', 'エラー');
                                return;
                            }
                        }

                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        /**
         * 詳細ダイアログを初期化する。
         */
        initDetailDialog: function() {
            var dialog = this.userDetailDialog;
            var page = dialog.getChildren()[0];

            // 詳細画面のremoveイベントを受け取る
            page.on('remove', lang.hitch(this, function(evt) {
                console.debug('removeイベント');
                this.chain.confirmDel(function(chain) {
                    var promise = this.store.remove(evt.value.userId+'?version='+evt.value.version);
                    Loader.wait(promise).then(lang.hitch(this, function() {
                        // 登録フォーム・ダイアログを閉じる
                        dialog.hide();
                        chain.infoComplete();
                        // 一覧を取得しなおす
                        this.updateGridQuery();
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));

            // 詳細画面のupdateイベントを受け取る
            page.on('update', lang.hitch(this, function(evt) {
                console.debug('updateイベント');
                // 追加確認ダイアログを表示
                this.chain.confirmPut(function(chain) {
                    var form = evt.value;
                    // 追加処理と共にローディング表示
                    Loader.wait(this.store.put(form)).then(lang.hitch(this, function() {
                        // 成功時（POST結果はグリッドが自動的に反映）
                        // 登録ダイアログを閉じる
                        dialog.hide();
                        // 完了ダイアログを表示
                        chain.infoComplete();
                        // 一覧を取得しなおす
                        this.updateGridQuery();
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));
        },

        // 役割セレクトボックスの初期化を行う
        initSelectBox: function () {
            var promise = Requester.get('/api/roles');
            Loader.wait(promise).then(lang.hitch(this, function (data) {
                // セレクトボックスに設定するデータの配列を作成
                var newOptions = [{
                    label: '&nbsp;',
                    value: ''
                }];
                // サーバーレスポンスの各要素をselectへ追加
                data.items.forEach(function (object) {
                    newOptions.push({
                        label: object.roleName,
                        value: object.roleCd
                    });
                });
                this.roleCdSelect.set('options', newOptions);
            }));
        },

        /**
         * 職員招集管理者を検索する。
         */
        onSubmit: function() {
            console.debug('[検索]ボタンがクリックされました。');
            try {
                if (this.form.isValid()) {
                    // 入力値が正常ならグリッドの検索条件を更新
                    this.updateGridQuery();
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 職員招集管理者新規登録ダイアロを表示する。
         */
        showRegisterDialog: function() {
            console.debug('[新規登録]ボタンがクリックされました。');
            this.innerUserRegisterDialog.initDialog();
            this.userRegisterDialog.show();
        },

        /**
         * 職員招集管理者詳細ダイアログを表示する。
         */
        showUpdateDialog: function(object) {
            console.debug('[詳細]ボタンがクリックされました。');
            this.innerUserDetailDialog.initDialog(object);
            this.userDetailDialog.show();
        },

        filterByType: function(value) {
            alert(value);
        },

        /**
         * グリットを更新する。
         */
        updateGridQuery: function() {
            var filter = new this.store.Filter();
            var value = this.form.get('value');
            console.log(value);

            // ユーザ種別による検索
            if (value.roleCd && value.roleCd !== '') {
                filter = filter.eq('roleCd', value.roleCd);
            }

            // 組織による検索
            if (value.organization && value.organization !== '') {
                filter = filter.eq('organization', value.organization);
            }

            // filterに対応するcollectionを取得
            var collection = this.store.filter(filter);

            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.userAdminGrid.set('collection', collection);
        }
    });
});
