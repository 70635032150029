define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/map/IdisMap',
    'idis/view/grid/helper',
    'dojo/topic',
    'dojo/on',
    'dgrid/Selection',
    'dgrid/Selector',
    'dgrid/Keyboard',
    'idis/view/form/Button',
    'app/config'
], function (module, declare, lang, locale, IdisGrid, IdisMap, helper, topic, on, Selection,
    Selector, Keyboard, Button, config) {

    //県の市町コード
    var PREF_MUNICIPALITY_CODE = config.municInfo.prefMunicCd;

    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selection, Selector, Keyboard], {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--damage',


        DRAW_CIRCLE: 'app.damage.DamageReportAdminPage' + '::drawCircle',


        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            { field: 'regionName', label: '地域', sortable: false },
            {
                field: 'municipalityName', label: '区', sortable: false
            },
            {
                field: '_item', label: '更新日時', className: 'updTimestamp',
                formatter: lang.hitch(this, function (item) {
                    if (item.lastUpdTimestamp) {
                        var date = locale.parse(item.lastUpdTimestamp, {
                            datePattern: 'yyyy-MM-dd',
                            timePattern: 'HH:mm:ss'
                        });
                        return locale.format(date, {
                            datePattern: 'yyyy-MM-dd',
                            timePattern: 'HH:mm'
                        });
                        //return item.lastUpdTimestamp;
                    } else {
                        return '-';
                    }
                }),
                sortable: false
            },
            {
                label: '人的被害', field: 'human', sortable: false,
                children: [
                    { field: 'deadNumI', label: '死者', sortable: false },
                    { field: 'unknownNumI', label: '行方不明者', sortable: false },
                    { field: 'seriousNumI', label: '重傷者', sortable: false },
                    { field: 'mildNumI', label: '軽傷者', sortable: false }
                ]
            },
            {
                label: '住家被害', field: 'property', sortable: false,
                children: [
                    { field: 'fullDestlHmI', label: '全壊', sortable: false },
                    { field: 'halfDestlHmI', label: '半壊', sortable: false },
                    { field: 'partDestlHmI', label: '一部損壊', sortable: false },
                    { field: 'fldAbvHmI', label: '床上浸水', sortable: false },
                    { field: 'fldBlwHmI', label: '床下浸水', sortable: false },
                ]
            },
            {
                label: '水道', field: 'water', sortable: false,
                children: [
                    { field: 'watFailCurrI', label: '箇所', sortable: false }
                ]
            },
            {
                label: '道路', field: 'rod', sortable: false,
                children: [
                    { field: 'rodDamageI', label: '箇所', sortable: false }
                ]
            },
            {
                label: '橋梁', field: 'brg', sortable: false,
                children: [
                    { field: 'brgDamageI', label: '箇所', sortable: false }
                ]
            },
            {
                label: '河川', field: 'rivr', sortable: false,
                children: [
                    { field: 'rivrDamageI', label: '箇所', sortable: false }
                ]
            },
            {
                label: '港湾', field: 'hbr', sortable: false,
                children: [
                    { field: 'hbrDamageI', label: '箇所', sortable: false }
                ]
            },
            // ボタン
            {
                field: 'list', label: '一覧', sortable: false,
                renderCell: function (item) {
                    if (item.municipalityCd !== PREF_MUNICIPALITY_CODE) {
                        var gridNode = this.grid.domNode;
                        var button = new Button({
                            label: '一覧',
                            onClick: function () {
                                on.emit(gridNode, 'listButtonClick', { item: item });
                            }
                        });
                        return button.domNode;
                    } else {
                        //合計行には詳細ボタンをつけない
                        return null;
                    }

                }
            }


        ],

        renderRow: function (item) {
            var div = this.inherited(arguments);
            topic.publish(this.DRAW_CIRCLE, item);
            return div;
        }
    });
});
