/**
 * 組織選択用入力パーツ
 * @module app/view/form/EarthQuakeSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/string',
    'idis/view/form/TreeSelector',
    'app/model/EarthQuake',
    'dijit/form/TextBox'
], function(module, declare, lang, dStr, TreeSelector, EarthQuake, TextBox) {
    /**
     * 組織選択用パーツ。
     * @class EarthQuakeSelector
     * @extends module:idis/view/form/TreeSelector~TreeSelector
     */
    return declare(module.id.replace(/\//g, '.'), TreeSelector,
        /** @lends module:app/view/form/EarthQuakeSelector~EarthQuakeSelector# */ {
        // 選択ダイアログのタイトル
        title: '地震情報',

        // ツリー・モデル
        model: EarthQuake,

        // ツリー全展開ボタンを非表示に設定
        noExpandAll: true,

        // ツリー全収束ボタンを非表示に設定
        noCollapseAll: true,

        placeHolder: '絞り込み',

        // DOMを構築する
        buildRendering: function () {
            this.inherited(arguments);
            // 検索用入力要素を設置
            this.filterInput = new TextBox({
                placeHolder: this.placeHolder,
                maxLength: 128
            });
            this.filterInput.placeAt(this.treeControlNode);
            this.own(this.filterInput.on('keyup', lang.hitch(this, 'onFilterInputChange')));
        },

        /**
         * 検索用入力要素入力時。
         */
        onFilterInputChange: function () {
            // 入力値を取得
            var value = dStr.trim(this.filterInput.get('value'));
            if (value) {
                // ラベル名が入力値を含むものでフィルターし、ツリーを展開する
                this.model.setFilter(function (item, isLeaf, model) {
                    return isLeaf && (model.getLabel(item) || '').indexOf(value) !== -1;
                }).then(lang.hitch(this, function () {
                    this.tree.expandAll();
                }));
            } else {
                // 入力が空の場合は全要素を表示してツリーを閉じる
                this.model.setFilter().then(lang.hitch(this, function () {
                    this.tree.collapseAll();
                }));
            }
        }
    });
});
