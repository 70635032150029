/**
 * レイヤー選択用モジュール。
 * @module idis/map/LayerSelector
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-attr',
    'dojo/on',
    'dijit/_WidgetBase',
    'dojox/lang/functional/object',
    'idis/consts/QUERY',
    'idis/control/Locator',
    // 以下、変数で受けないモジュール
    'dojo/query'
], function(module, array, declare, lang, domAttr, on, _WidgetBase, df, QUERY, Locator) {

    /**
     * レイヤー識別用に用いるHTML属性名
     * @type {string}
     * @private
     */
    var _LAYER_ID_ATTR = 'data-idis-layer-id';

    /**
     * レイヤー選択用ウィジェット
     * @class LayerSelector
     */
    return declare(module.id.replace(/\//g, '.'), _WidgetBase, /** @lends idis/map/LayerSelector~LayerSelector# */ {
        // ルート要素のCSSクラス
        baseClass: 'map-LayerSelector',

        /**
         * 初期レイヤー一覧
         * @param {number[]}
         */
        initialLayers: null,

        /**
         * 表示を許可されたレイヤー一覧
         * @param {number[]}
         */
        allowedLayers: null,

        /**
         * レイヤー定義一覧
         * @param {Object[]}
         */
        layers: null,

        /**
         * 識別子とレイヤー情報の対応付け
         * @type {Object<number, Object>}
         */
        _layerMap: null,

        _lastLayerParam: null,

        constructor: function() {
            this._layerMap = {};
            // レイヤー情報の対応付けを保持
            array.forEach(this.layers, function(layer) {
                this._layerMap[layer.id] = layer;
            }, this);
        },

        // DOM要素を構築する
        buildRendering: function() {
            // ルート要素はULタグにする
            this.domNode = this.srcNodeRef || this.ownerDocument.createElement('ul');
            this.inherited(arguments);
        },

        // DOM要素構築後に呼ばれる
        startup: function() {
            this.inherited(arguments);
            // 現在のレイヤー一覧を取得
            this._lastLayerParam = Locator.getQuery()[QUERY.LAYER_LIST];
            // 初期レイヤー一覧をURLに反映
            // postCreate時点だと親画面設置前のため
            // URL書き換えに対しRouterが誤動作するので、このタイミングで実施する。
            if (this._lastLayerParam) {
                // 許可されないレイヤーが出ていた場合は隠す
                df.forIn(Locator.getLayerQuery(), function(transparency, layerId) {
                    if (array.indexOf(this.allowedLayers, layerId) === -1) {
                        this.hideLayer(layerId);
                    }
                }, this);
            }
            if (!this._lastLayerParam) {
                // レイヤーが何も出ていない場合は初期用を表示
                array.forEach(this.initialLayers, this.showLayer, this);
                this._lastLayerParam = Locator.getQuery()[QUERY.LAYER_LIST];
            }
            // 項目一覧を更新する
            this.updateView();
            // URLの変更を監視
            this.own(Locator.on('change', lang.hitch(this, this.onLocationChanged)));
            // ウィジェット内の要素がクリックされたらレイヤーの表示を切り替える
            this.on('li:click', lang.hitch(this, function(evt) {
                var layerId = domAttr.get(evt.target, _LAYER_ID_ATTR) ||
                                domAttr.get(evt.target.parentNode, _LAYER_ID_ATTR);
                this.toggleLayer(layerId);
            }));
        },

        /**
         * ロケーションが変化した場合に呼ばれる。
         */
        onLocationChanged: function() {
            var layerParam = Locator.getQuery()[QUERY.LAYER_LIST];
            // レイヤー一覧が変わった場合に限りDOM要素を更新する
            if (layerParam !== this._lastLayerParam) {
                this._lastLayerParam = layerParam;
                this.updateView();
            }
        },

        /**
         * 指定されたレイヤーを表示する。
         * @param {number} id レイヤーID
         */
        showLayer: function(id) {
            var layerData = this._layerMap[id];
            // 背反なレイヤーが指定されている場合は非表示化する
            if (layerData.anti) {
                array.forEach(layerData.anti, this.hideLayer, this);
            }
            // 現状の選択レイヤー一覧を取得
            var layerQuery = Locator.getLayerQuery();
            // レイヤーIDをクエリーに追加
            layerQuery[id] = layerQuery[id] || '0';
            // URLに反映
            Locator.replaceState(QUERY.LAYER_LIST, Locator.toLayerQuery(layerQuery));
        },

        /**
         * 指定されたレイヤーを非表示化する。
         * @param {number} id レイヤーID
         */
        hideLayer: function(id) {
            // 現状の選択レイヤー一覧を取得
            var layerQuery = Locator.getLayerQuery();
            // レイヤーIDをクエリーから削除
            if (layerQuery[id]) {
                delete layerQuery[id];
            }
            // URLに反映
            Locator.replaceState(QUERY.LAYER_LIST, Locator.toLayerQuery(layerQuery));
        },

        /**
         * 指定されたレイヤー表示を切り替える。
         * @param {number} id レイヤーID
         */
        toggleLayer: function(id) {
            var layerData = this._layerMap[id];
            // 背反なレイヤーが指定されている場合は非表示化する
            if (layerData.anti) {
                array.forEach(layerData.anti, this.hideLayer, this);
            }
            // 現状の選択レイヤー一覧を取得
            var layerQuery = Locator.getLayerQuery();
            // クエリーにIDがあったら消す、なかったら追加する
            if (layerQuery[id]) {
                delete layerQuery[id];
            } else {
                layerQuery[id] = '0';
            }
            // URLに反映
            Locator.replaceState(QUERY.LAYER_LIST, Locator.toLayerQuery(layerQuery));
        },

        /**
         * 項目一覧を更新する。
         */
        updateView: function() {
            var layerQuery = Locator.getLayerQuery();
            var html = [];
            array.forEach(this.layers, function(layer) {
                // 許可されたレイヤーだけ候補に掲載
                if (array.indexOf(this.allowedLayers, layer.id) === -1) {
                    return;
                }
                html.push('<li ');
                if (layer.id in layerQuery) {
                    html.push('class="is-shown" ');
                }
                html.push(_LAYER_ID_ATTR);
                html.push('="');
                html.push(layer.id);
                html.push('"><div>');
                html.push(layer.name);
                html.push('</div></li>');
            }, this);
            this.domNode.innerHTML = html.join('');
        }
    });
});

