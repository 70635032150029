/**
 * 被害種別選択ダイアログ画面用モジュール。
 * @module app/damage/damagetype/DamageGridTypeDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/_base/lang',
    'dojo/topic',
    'dojo/text!./templates/DialogContent.html',
    'dstore/Memory',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/Loader',
    'idis/view/dialog/InfoDialog',
    'idis/store/IdisRest',
    'idis/view/page/_PageBase',

    // 以下、変数で受けないモジュール
    'idis/view/form/Button',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    './DamageTypeGrid'
], function (module, declare, array, lang, topic, template, Memory, UserInfo, Requester, Loader, InfoDialog,
    IdisRest, WidgetBase) {

    /**
     * 被害種別選択ダイアログ
     * @class DamageGridTypeDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends pp/damage/damagetype/DamageGridTypeDialog~DamageGridTypeDialog# */ {

            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'damagetype-selector',

            SELECTION_RELEASED: 'app/damage/damagetype/DamageGridTypeSelector::released',

            /**
             * データ格納用オブジェクト
             * @type {module:dstore/Store}
             */
            store: null,


            noUnderlay: true,
            title: '被害の種類選択',
            hasData: false,

            /**
             * コンストラクタ
             */
            constructor: function () {
                this.inherited(arguments);

                // this.store = new Memory({
                //     'idProperty': 'damageTypeId',
                //     'data': []
                // });
                this.store = new IdisRest({
                    idProperty: 'Id',
                    target: '/api/code/grid'
                });
            },

            /**
             * DOM生成
             */
            buildRendering: function () {
                this.inherited(arguments);
            },

            /**
             * 画面生成完了
             */
            startup: function () {
                this.inherited(arguments);
                this.initContent();

                this.own(topic.subscribe(this.SELECTION_RELEASED, lang.hitch(this, function () {
                    this.initContent();
                })));
            },

            /**
             * 画面を再表示
             */
            refresh: function () {
                this.container.resize();
                this.item = null;
            },

            /**
            * widget生成後, 画面表示前
            */
            postCreate: function () {
                this.inherited(arguments);
            },

            initContent: function () {

                this.grid.reset();
                Loader.wait(Requester.get('api/damageReports/damageTypeAuth?deptCd=' + UserInfo.getDeptCd())
                    .then(lang.hitch(this, function (authList) {
                        this.grid.authList = authList;

                        var filter = new this.store.Filter();
                        filter = filter.eq('type', 'DAMAGE_TYPE');
                        filter = filter.eq('display', 'true');


                        this.grid.set('collection', this.store.filter(filter));
                        this.hasData = true;
                    }))
                );
            },

            onConfirm: function () {
                this.inherited(arguments);
                //            if (!this.grid.mainTypeId) {
                //                InfoDialog.show('入力チェック', '主となる被害種別を指定してください');
                //                return;
                //            }

                if (this.grid.mainTypeIds.length === 0) {
                    InfoDialog.show('入力チェック', '被害の種類を選択してください');
                    return;
                }
                else if (this.grid.mainTypeIds.length > 3) {
                    InfoDialog.show('入力チェック', '3つ以下の被害の種類を選択してください');
                    return;
                }
                else if (this.grid.mainTypeIds.indexOf('14') >= 0 && this.grid.mainTypeIds.length > 1) {
                    InfoDialog.show('入力チェック', '水道と他の被害は同時に選択できません');
                    return;
                }
                else if (this.grid.mainTypeIds.indexOf('15') >= 0 && this.grid.mainTypeIds.length > 1) {
                    InfoDialog.show('入力チェック', '水道と他の被害は同時に選択できません');
                    return;
                }
                else if (this.grid.mainTypeIds.indexOf('28') >= 0 && this.grid.mainTypeIds.length > 1) {
                    InfoDialog.show('入力チェック', '電話と他の被害は同時に選択できません');
                    return;
                }
                else if (this.grid.mainTypeIds.indexOf('29') >= 0 && this.grid.mainTypeIds.length > 1) {
                    InfoDialog.show('入力チェック', '電気と他の被害は同時に選択できません');
                    return;
                }
                else if (this.grid.mainTypeIds.indexOf('30') >= 0 && this.grid.mainTypeIds.length > 1) {
                    InfoDialog.show('入力チェック', 'ガスと他の被害は同時に選択できません');
                    return;
                }
                else if (this.grid.mainTypeIds.indexOf('19') >= 0 && this.grid.mainTypeIds.length > 1) {
                    InfoDialog.show('入力チェック', '状況報告と他の被害は同時に選択できません');
                    return;
                }
                else if (this.grid.mainTypeIds.indexOf('09') !== -1 && this.grid.mainTypeIds.length > 1) {
                    InfoDialog.show('入力チェック', '道路と被害は同時に選択できません');
                    return;
                }

                this.emit('update', {
                    data: {
                        mainTypeIds: this.grid.mainTypeIds
                        //                    subTypeIds: this.grid.subTypeIds
                    }
                });

            },

            onCancel: function () {
                this.inherited(arguments);
                this.close();
            },

            show: function () {
                this.inherited(arguments);
                console.log('show');

                this.grid.resize();
                this.container.resize();
            },

            onHide: function () {
                this.inherited(arguments);
                this._newStore();
                this.grid.set('collection', this.store);
                this.hasData = false;
            },

            close: function () {
                this.inherited(arguments);
                this.hide();
            },

            _newStore: function () {
                if (this.store) {
                    delete this.store;
                }

                this.store = new Memory({
                    'idProperty': 'damageTypeId',
                    'data': []
                });
            }
        });

});
