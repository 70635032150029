/**
 * 津波情報詳細情報のメッセージ用コンテンツ
 * @module app/earthquake/tsunami/TsunamiInfoDetailContent
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/array',
    'dojo/_base/lang',
    'dojo/text!./templates/TsunamiInfoDetailContent.html',
    'idis/error/InvalidArgumentException',
    'idis/view/_IdisWidgetBase',
    'app/util/DateFormatter'
], function(module, declare, array, lang, template, InvalidArgumentException, _IdisWidgetBase, DateFormatter) {
    /**
     * 津波情報詳細情報のメッセージ用UIウィジェット
     * @class TsunamiInfoDetailContent
     * @extends module:idis/view/page/_PageBase~_PageBase
     * @param {Object} kwArgs
     * @param {string} [kwArgs.item={Object}] 津波情報
     */
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase,
        /** @lends app/earthquake/tsunami/TsunamiInfoDetailContent~TsunamiInfoDetailContent# */ {
        // テンプレート文字列
        templateString: template,

        constructor: function(options) {
            this.inherited(arguments);
            lang.mixin(this, options);
            // 引数チェック
            if (!this.item) {
                throw new InvalidArgumentException(module.id + '::constructor: Property "item" must be specified.');
            }
        },

        // DOMを構築する
        buildRendering: function() {
            this.inherited(arguments);
            // コンテンツの中身を生成する
            this.setContent();
        },

        /**
         * コンテンツの中身を生成する
         */
        setContent: function() {
            this.reportDateTime.innerHTML = DateFormatter.jpDateTime(this.item.reportDatetime);
            this.text.innerHTML =
                (!this.isEmpty(this.item.text)) ? this.item.text.replace(/。/g, '。<br>') : '';

            // テーブル内容を生成
            var html = [];
            array.forEach(this.item.regions, lang.hitch(this, function(item){
                if (!item.foTideStationName){ return; }
                html.push('<tr><td>');
                html.push(item.foTideStationName);
                html.push('</td><td>');
                if (this.isEmpty(item.foTideStationHighTideDateTime)) {
                    html.push('');
                } else {
                    html.push(DateFormatter.dateTime(item.foTideStationHighTideDateTime));
                }
                html.push('</td><td>');
                if (this.isEmpty(item.foFirstHiehtArrivalTime)) {
                    html.push(item.foFirstHiehtCondition);
                } else {
                    html.push(DateFormatter.dateTime(item.foFirstHiehtArrivalTime));
                }
                html.push('</td></tr>');
            }));
            this.bodyNode.innerHTML = html.join('');
        },

        /**
         * FIXME 外部化
         */
        isEmpty: function(str) {
            if (str === void 0) {
                return true;
            }
            if (str === null) {
                return true;
            }
            return false;
        },

        /**
         * コンテンツのHTMLテキストを返す
         * FIXME 外部化
         */
        getContent: function() {
            // HTMLタグ間に改行コードが入り、セット先のダイアログモジュールで<br>に変換されるため外しておく
            // テキスト内の改行はそのままにしたいため、「HTML終了タグ + 改行コード」にマッチする部分を
            // 「HTML終了タグ」のみにする
            // さらに、最初と最後の改行コードを外す
            return this.contentNode.innerHTML.replace(/>\r?\n/g, '>').replace(/^\r?\n/g, '').replace(/\r?\n$/g, '');
        }

    });
});
