/**
 * 職員招集メール一覧グリッド
 * @module app/safety/mail/SafetyMailListGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dojo/dom',
    'dijit/registry',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'dgrid/Selector',
    'dijit/form/CheckBox'
], function(module, declare, lang, locale, dom, registry, IdisGrid, helper, Selector) {
	return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selector], {

        /**
         * 各列の定義
         * @type {Object[]}
         */

        collection: this.store,

        columns: [
             {
                 field: 'checkbox',
                 label: '再送',
                 selector: 'checkbox'
             },
            helper.column('groupName', 'グループ'),
//            helper.column('distributionHistDetailId', '防災配信履歴詳細ID'),
            helper.column('employeeNo', '職員番号'),
            helper.column('organizationName', '所属'),
            helper.column('name', '氏名'),
            {field: 'sendStatus', label: '配信状況', sortable: true,
                formatter: lang.hitch(this, function(item){
                    if(item === '02') {
                        return '未確認';
                    } else if (item === '03'){
                        return '送信済み';
                    } else if (item === '01') {
                        return 'HARDERROR';
                    } else if (item === '99') {
                        return 'DROP';
                    } else if (item === '00') {
                        return 'アドレス未登録のため、未送信';
                    } else if (item === '98') {
                        return '不正なアドレスです';
                    }
                })
            },
            //helper.column('distEmailAddress', 'メールアドレス'),
            //helper.column('emailAddress02', 'メールアドレス2'),
//            helper.column('confirmStatusTimestamp', '受信確認'),
            {field: 'replyTimestamp',label: '回答日時',className: 'replyTimestamp'},
            // {field: 'confirmStatusTimestamp', label: '参集状況', className: 'confirmStatusTimestamp', sortable: false,
            // 	formatter: lang.hitch(this, function(item) {
            // 		return item;
            // 	})
            // },
            {field: 'distA01', label: '参集状況', className: 'distA01', sortable: false,
                formatter: lang.hitch(this, function(item) {
                    return item;
                })
            },
            // helper.column('distA01', '参集状況'),
            helper.column('distA02', '本人安否'),
            helper.column('distA03', '家族安否'),
            helper.column('distA04', '住宅'),
            // helper.column('distA05', '回答5'),
            helper.column('note', '備考'),
            helper.buttonColumn('deputyAnswer', '代理回答')
        ]
    });
});
