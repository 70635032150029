/**
 * 災害変更ダイアログ用グリッド
 * @module app/view/form/ParentDisasterSelectorGrid
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'dgrid/Selector',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, locale, Selector, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), [IdisGrid, Selector],
        /** @lends module:idis/view/page/ProvideTemplatePage~ProvideTemplatePage# */ {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        collection: this.store,
        columns: [
            helper.column('disasterId','災害番号'),
            helper.column('name','災害名称'),
            helper.column('type', '災害種別', {
                sortable: false,
                formatMap: {
                    '01': '風水害',
                    '02': '地震',
                    '03': '津波',
                    '04': '国民保護',
                    '99': 'その他'
                }
            }),
            helper.column('trainingFlg', '災害モード', {
                sortable: false,
                formatter: function(value, item) {
                    switch (item.trainingFlg) {
                        case false: return '本番';
                        case true: return '訓練(練習)';
                        default: return '';
                    }
                }
            }),
            helper.column('startTimestamp', '発生期間 \n（開始）', {
                formatter: function(item) {
                    if(item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                }
            }),
            helper.column('endTimestamp', '発生期間\n（終了）', {
                formatter: function(item) {
                    if(item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                }
            })
        ]
    });
});
