define([
    'leaflet',
    'idis/view/draw/PolylineDecorator'
    // 以下、変数で受けないモジュール
], function(leaflet, PolylineDecorator) {
    // 矢印
    var Arrow = leaflet.Draw.Arrow = leaflet.Draw.Polyline.extend({

        statics: {
            TYPE: 'arrow'
        },

        initialize: function (map, options) {
            leaflet.Draw.Polyline.prototype.initialize.call(this, map, options);
            this.type = leaflet.Draw.Arrow.TYPE;
        },

        arrowOptions: {
            offset: 100 + '%',
            repeat: 0,
            symbol: {
                pixelSize: 16,
                polygon: false,
                pathOptions: {
                    opacity: 1,
                    weight: 4
                }
            }
        },

        // Override
        _finishShape: function () {
            var latlngs = this._poly._defaultShape ? this._poly._defaultShape() : this._poly.getLatLngs();
            var intersects = this._poly.newLatLngIntersects(latlngs[latlngs.length - 1]);

            if ((!this.options.allowIntersection && intersects) || !this._shapeIsValid()) {
                this._showErrorTooltip();
                return;
            }

            // 最後の矢印ヘッドを削除
            this._clearHead();

            this._fireCreatedEvent();
            this.disable();
            if (this.options.repeatMode) {
                this.enable();
            }
        },

        // Override
        _onMouseMove: function (e) {
            var newPos = this._map.mouseEventToLayerPoint(e.originalEvent);
            var latlng = this._map.layerPointToLatLng(newPos);

            // Save latlng
            // should this be moved to _updateGuide() ?
            this._currentLatLng = latlng;

            this._updateTooltip(latlng);

            // Update the guide line
            this._updateGuide(newPos);

            // Update the mouse marker position
            this._mouseMarker.setLatLng(latlng);

            // 矢印ヘッド位置を更新
            this._updateHead(latlng);

            leaflet.DomEvent.preventDefault(e.originalEvent);
        },

        // 矢印ヘッド位置を更新
        _updateHead: function(newPos){

            // 前回のヘッドを削除
            this._clearHead();

            var markerCount = this._markers ? this._markers.length : 0;

            if (markerCount > 0) {

                var beforePos = this._markers[markerCount - 1].getLatLng();
                var dummyLine = leaflet.polyline([beforePos, newPos], {});
                var arrowHead = new PolylineDecorator(dummyLine).addTo(this._map);
                arrowHead.setPatterns([{
                    offset: '100%',
                    repeat: 0,
                    symbol: leaflet.Symbol.arrowHead({
                        pixelSize: 16,
                        polygon: false,
                        pathOptions: {
                            opacity: 1,
                            weight: 4
                        }
                    })
                }]);

                // 矢印ヘッドを保持
                this._beforeHead = arrowHead;
            }

        },

        // 前回のヘッドを削除
        _clearHead: function(){

            if (this._beforeHead) {
                this._map.removeLayer(this._beforeHead);
            }

        }

    });

    // 矢印用のプロパティをセット
    leaflet.Polyline.include({

        // レイヤーoptionsに矢印をセットする
        // 線幅に応じて矢印の大きさを変更する
        // @param weight 線幅
        setArrowHead: function(pathOptions){

            if (this.options.arrowHead) {
                // 既存の矢印を削除
                if(this.options.bothArrow === true) {
                    for(var i = 0; i <= this.options.arrowHead.length -1; i++) {
                        this._map.removeLayer(this.options.arrowHead[i]);
                    }
                } else {
                    this._map.removeLayer(this.options.arrowHead);
                }
            }
            
            // 矢印を生成
            // 矢印サイズは幅4pxに対して16pxが基本.
            var oneWayArrowHead = new PolylineDecorator(leaflet.polyline(this._latlngs, {})).addTo(this._map);
            oneWayArrowHead.setPatterns([{
                offset: '100%',
                repeat: 0,
                symbol: leaflet.Symbol.arrowHead({
                    pixelSize: 4 * Number(pathOptions.weight),
                    polygon: false,
                    pathOptions: {
                        color: pathOptions.color,
                        opacity: pathOptions.opacity,
                        weight: pathOptions.weight
                    }
                })
            }]);

            // 両方向に矢印がある場合
            if(this.options.bothArrow === true) {
                var latlng = [this._latlngs[1], this._latlngs[0]];
                var bothArrowHead = new PolylineDecorator(leaflet.polyline(latlng, {})).addTo(this._map);
                bothArrowHead.setPatterns([{
                    offset: '100%',
                    repeat: 0,
                    symbol: leaflet.Symbol.arrowHead({
                        pixelSize: 4 * Number(pathOptions.weight),
                        polygon: false,
                        pathOptions: {
                            color: pathOptions.color,
                            opacity: pathOptions.opacity,
                            weight: pathOptions.weight
                        }
                    })
                }]);

                this.options.arrowHead = [oneWayArrowHead, bothArrowHead];
            } else {
                this.options.arrowHead = oneWayArrowHead;
            }
        }

    });

    // 矢印の編集対応
    // 元々のイベントハンドラーを上書きし、矢印に対応
    leaflet.Edit.PolyVerticesEdit.addInitHook(function(){

        this._onMarkerClick = function (e) {
            var poly = this._poly;

            var minPoints = leaflet.Polygon && (this._poly instanceof leaflet.Polygon) ? 4 : 3,
                marker = e.target;

                if (this._defaultShape().length < minPoints) {
                    return;
                }

                this._removeMarker(marker);

                this._updatePrevNext(marker._prev, marker._next);

                if (marker._middleLeft) {
                    this._markerGroup.removeLayer(marker._middleLeft);
                }
                if (marker._middleRight) {
                    this._markerGroup.removeLayer(marker._middleRight);
                }

                if (marker._prev && marker._next) {
                    this._createMiddleMarker(marker._prev, marker._next);

                } else if (!marker._prev) {
                    marker._next._middleLeft = null;

                } else if (!marker._next) {
                    marker._prev._middleRight = null;
                }

                // 矢印の場合
                if (poly.options.drawType === 'arrow') {

                    // 既存の矢印を削除
                    this._map.removeLayer(poly.options.arrowHead);

                    // マーカーの頂点の一番最後（すなわち、矢印ヘッドが位置する）とその1つ前の頂点位置から、
                    // 矢印ヘッドを再生成
                    var length = this._markers.length;
                    var endPoint = this._markers[length - 1]._latlng;
                    var startPoint = this._markers[length - 2]._latlng;
                    var arrowHead = new PolylineDecorator(leaflet.polyline([startPoint, endPoint], {}));
                    arrowHead.addTo(this._map);
                    arrowHead.setPatterns([{
                        offset: '100%',
                        repeat: 0,
                        symbol: leaflet.Symbol.arrowHead({
                            pixelSize: 4 * Number(poly.options.weight),
                            polygon: false,
                            pathOptions: {
                                color: poly.options.color,
                                opacity: poly.options.opacity,
                                weight: poly.options.weight
                            }
                        })
                    }]);
                    poly.options.arrowHead = arrowHead;

                }

                this._fireEdit();
        };

        this._onMarkerDrag = function(e){
            var marker = e.target;
            var poly = this._poly;

            leaflet.extend(marker._origLatLng, marker._latlng);

            if (marker._middleLeft) {
                marker._middleLeft.setLatLng(this._getMiddleLatLng(marker._prev, marker));
            }
            if (marker._middleRight) {
                marker._middleRight.setLatLng(this._getMiddleLatLng(marker, marker._next));
            }

            if (poly.options.poly) {
                var tooltip = poly._map._editTooltip;

                if (!poly.options.poly.allowIntersection && poly.intersects()) {

                    var originalColor = poly.options.color;
                    poly.setStyle({ color: this.options.drawError.color });

                    if (leaflet.version.indexOf('0.7') !== 0) {
                        marker.dragging._draggable._onUp(e);
                    }
                    this._onMarkerClick(e); // Remove violating marker
                    // FIXME: Reset the marker to it's original position (instead of remove)

                    if (tooltip) {
                        tooltip.updateContent({
                            text: leaflet.drawLocal.draw.handlers.polyline.error
                        });
                    }

                    setTimeout(function () {
                        poly.setStyle({ color: originalColor });
                        if (tooltip) {
                            tooltip.updateContent({
                                text: leaflet.drawLocal.edit.handlers.edit.tooltip.text,
                                subtext: leaflet.drawLocal.edit.handlers.edit.tooltip.subtext
                            });
                        }
                    }, 1000);
                }
            }

            // 矢印の場合
            if (poly.options.drawType === 'arrow') {

                // 既存の矢印を削除
                this._map.removeLayer(poly.options.arrowHead);

                // マーカーの頂点の一番最後（すなわち、矢印ヘッドが位置する）とその1つ前の頂点位置から、
                // 矢印ヘッドを再生成
                var length = this._markers.length;
                var endPoint = this._markers[length - 1]._latlng;
                var startPoint = this._markers[length - 2]._latlng;
                var arrowHead = new PolylineDecorator(leaflet.polyline([startPoint, endPoint], {}));
                arrowHead.addTo(this._map);
                arrowHead.setPatterns([{
                    offset: '100%',
                    repeat: 0,
                    symbol: leaflet.Symbol.arrowHead({
                        pixelSize: 4 * Number(poly.options.weight),
                        polygon: false,
                        pathOptions: {
                            color: poly.options.color,
                            opacity: poly.options.opacity,
                            weight: poly.options.weight
                        }
                    })
                }]);
                poly.options.arrowHead = arrowHead;

            }

            this._poly.redraw();
            this._poly.fire('editdrag');
        };

    });

    return Arrow;
});
