/**
* 被害統合選択用grid
* @module app/damage/integrate/DamageListGrid
*/
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dijit/form/RadioButton',
    'dijit/form/CheckBox',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function (module, declare, lang, array, RadioButton, CheckBox,
    IdisGrid, helper) {

    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--damage-list',

        // radiobutton/checkbox制御用
        radioButtons: {},
        checkboxes: {},

        // 親子のID
        parentId: null,
        children: [],

        constructor: function () {
            /**
             * 各列の定義
             * @type {Object[]}
             */
            this.columns = [
                {
                    field: 'parent', label: '親', sortable: false,

                    renderCell: lang.hitch(this, function (item) {
                        this.radioButtons[item.damageReportId] = new RadioButton({
                            onClick: lang.hitch(this, function () {
                                this._onRadioButton(item);
                            })
                        });
                        // HTMLとしてウィジェットに紐付くDOMノードを返す
                        return this.radioButtons[item.damageReportId].domNode;
                    })
                },
                {
                    field: 'child', label: '子', sortable: false,

                    renderCell: lang.hitch(this, function (item) {
                        this.checkboxes[item.damageReportId] = new CheckBox({
                            onClick: lang.hitch(this, function () {
                                this._onCheckBox(item);
                            })
                        });
                        // HTMLとしてウィジェットに紐付くDOMノードを返す
                        return this.checkboxes[item.damageReportId].domNode;
                    })
                },
                helper.column('admNum', '管理番号'),
                helper.column('damageTypeListName', '被害種別'),
                helper.column('damageAddress', '発生場所')
            ];

            this.inherited(arguments);
        },

        reset: function () {
            this.inherited(arguments);

            this.radioButtons = {};
            this.checkboxes = {};
            this.parentId = null;
            this.children = [];
        },

        /**
         *  ラジオボタンを押した時
         *  押されたものが親, それ以外を全て子供にチェックを入れるようにしている
         * @param {*} item 押された行の内容
         */
        _onRadioButton: function (item) {
            this.parentId = item.damageReportId;
            console.log('parent: ' + this.parentId);

            // 全部のcheckboxをenableにしてチェック状態にする
            this.children = [];
            for (var key in this.checkboxes) {
                if (this.checkboxes.hasOwnProperty(key)) {
                    this.checkboxes[key].set('disabled', false);
                    this.checkboxes[key].set('checked', true);
                    this._onCheckBox({ damageReportId: key });
                }
            }

            // 今回親にした行のチェックボックスをdisableに
            this.checkboxes[item.damageReportId].set('disabled', 'disabled');
            this.checkboxes[item.damageReportId].set('checked', false);
            this._onCheckBox(item);

        },

        _onCheckBox: function (item) {
            if (this.checkboxes[item.damageReportId].get('checked')) {
                // チェック状態である => 今回追加されたのでchildrenに入れる
                this.children.push(item.damageReportId);
            } else {
                // チェック状態でない => childrenにあったら削除
                var index = array.indexOf(this.children, item.damageReportId);
                if (index !== -1) {
                    this.children.splice(index, 1);
                }
            }

            console.log(this.children);
        }
    });
});
