define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/date/locale',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper'
], function(module, declare, lang, locale, IdisGrid, helper) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sending',

        /**
         * 各列の定義
         * @type {Object[]}
         */

        columns: [
            // ボタン
            helper.buttonColumn('detail', '詳細'),
            helper.column('infoType', '情報種別'),
            helper.column('subject', '件名'),
            helper.column('bodyText', '本文'),
            {
              label: '配信状況',field: 'sendingStatus', sortable: false,
              children: [
                helper.column('lalertStatus', 'Lアラート', {
                    formatter: function(value) {return value ? value : '-';}, sortable: false}),
                helper.column('emergencyMailStatus', '緊急速報メール', {
                  formatter: function(value) {return value ? value : '-';}, sortable: false}),
            //   helper.column('prefectureMailStatus', '県防災情報メール', {
            //       formatter: function(value) {return value ? value : '-';}, sortable: false})
        //      ]
        //   },
        //   {
        //     label: '配信状況(県)',field: 'sendingStatusPref', sortable: false,
        //     children: [
                    helper.column('twitterStatus', 'Twitter', {
                        formatter: function(value) {
                            // TODO: なぜか(renderCellでも)リンクにすると見えなくなるので、要調査
                            // if(value==='完了'){
                            //     var url = 'https://twitter.com/home?lang=ja';
                            //     return '<a href=' + url + ' target="_blank">' + value + '</a>';
                            // }else if (value==='失敗') {
                            //     return value;
                            // }else{
                                return value ? value : '-';
                            // }
                        },
                        sortable: false}),
                //   helper.column('yahooStatus', '防災アプリ(Yahoo)', {
                    helper.column('yahooStatus',  'Yahoo!防災速報', {
                        formatter: function(value) {return value ? value : '-';}, sortable: false}),
                    helper.column('emergencyInfoStatus',  '緊急情報配信(防災アプリ)', {
                    formatter: function(value) {return value ? value : '-';}, sortable: false})
                ]
            },
            helper.column('sendOrganizationName', '配信課'),
            {field: 'sendTimestamp', label: '配信日時', sortable: true,
                formatter: lang.hitch(this, function(item){
                    if(item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                })
            },
            helper.column('senderName', '登録者'),
            {field: 'crtTimestamp', label: '登録日時', sortable: true,
                formatter: lang.hitch(this, function(item){
                    if(item) {
                        return locale.format(new Date(item));
                    }
                    return '';
                })
            }
        ]
    }
    );
});
