/**
 * 人的被害一覧ダイアログ画面用モジュール。
 * @module app/damage/HumanDamageReportDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/topic',
    'dojo/text!./templates/HumanDamageReportDialog.html',
    'dstore/Memory',
    'app/model/DisasterInfo',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/dialog/DialogChain',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'idis/view/form/Button',
    'app/damage/humanDamageReport/HumanDamageReportGrid',
    'app/damage/humanDamageReport/HumanDamageReportInputDialog'
], function (module, declare, lang, topic, template, Memory,
    DisasterInfo, InfoDialog, WidgetBase, DialogChain) {

    /**
     * 人的被害一覧ダイアログ
     * @class HumanDamageReportDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), WidgetBase,
        /** @lends app/view/Header~Header# */ {

            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'humandamagereport-dialog',

            /**
             * データ格納用オブジェクト
             * @type {module:dstore/Store}
             */
            store: null,

            /**
             * コンストラクタ
             */
            constructor: function () {
                //this.inherited(arguments);
                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);
            },

            /**
             * DOM生成
             */
            buildRendering: function () {
                this.inherited(arguments);

            },

            /**
             * 画面生成完了
             */
            startup: function () {
                this.inherited(arguments);
                // このウィジェット消滅時にダイアログも削除
                this.own(this.humanDamageReportInputDialog);

                this.innerInputDialog.on('update', lang.hitch(this, function (evt) {
                    if (!evt.data.gridRowId) {
                        evt.data.gridRowId = this.store.data.reduce(
                            function (a, b) {
                                return Math.max(a.gridRowId || 0, b.gridRowId);
                            }, -1) + 1;
                        this.store.addSync(evt.data);
                    } else {
                        this.store.put(evt.data);
                    }
                    this.grid.set('collection', this.store);
                    this.refresh();
                }));
                this.innerInputDialog.on('remove', lang.hitch(this, function (evt) {
                    this.store.removeSync(evt.data.gridRowId);
                    this.grid.set('collection', this.store);
                    this.refresh();
                }));
            },

            /**
             * 画面を再表示
             */
            refresh: function () {
                this.container.resize();
                this.grid.refresh();
            },

            /**
             * グリッド初期化
             */
            initGrid: function (dataList) {
                // データ格納用オブジェク
                this.store = new Memory({
                    idProperty: 'gridRowId',
                });
                if (Array.isArray(dataList)) {
                    for (let i = 0; i < dataList.length; i++) {
                        let item = dataList[i];
                        item.gridRowId = i;
                        this.store.addSync(item);
                    }
                }
                var collection = this.store;

                this.grid.set('collection', collection);

                this.grid.on('updateButtonClick', lang.hitch(this, function (evt) {
                    this.innerInputDialog.initFrom(evt.item);
                    this.humanDamageReportInputDialog.show();
                }));
            },

            onAdd: function () {
                this.innerInputDialog.form.reset();
                this.innerInputDialog.initFrom(null);
                this.humanDamageReportInputDialog.show();
            },

            /**
             * 親被害変更実行
             */
            onSubmit: function () {
                console.log(this.store.data);
                this.emit('send', {
                    data: this.store.data
                });
                this.leave();
            }
        });
});
