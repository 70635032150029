/**
 * 人的被害一覧ダイアログ画面用モジュール。
 * @module app/damage/HumanDamageReportDialog
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/json',
    'dojo/text!./templates/DamagePublicStatusDialog.html',
    'idis/view/page/_PageBase',
    'idis/view/dialog/InfoDialog',
    'idis/service/Requester',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/model/UserInfo',
    // #12657追加▼
    'dojo/dom-class',   // 画像プレビュー用
    'dojo/dom-construct',   // 画像プレビュー用
    // #12657追加▲
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'dijit/form/TextBox',
    'dijit/form/Select',
    'dijit/form/ValidationTextBox',
    'dijit/form/CheckBox',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/RadioGroup',
    'idis/view/form/Button'
], function (module, array, declare, lang, json, template,
    _PageBase, InfoDialog, Requester, Loader, DialogChain, UserInfo,
    domClass, domConstruct) {

    /**
     * 人的被害一覧ダイアログ
     * @class HumanDamageReportDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends app/view/Header~Header# */ {

            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'damageReportPublic-dialog',

            // #12657追加▼
            /**
             * 画像リスト
             */
            lstImage: [],
            // #12657追加▲

            /**
             * コンストラクタ
             */
            constructor: function () {
                //this.inherited(arguments);
                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);
            },

            /**
             * DOM生成
             */
            buildRendering: function () {
                this.inherited(arguments);

            },

            /**
             * 画面生成完了
             */
            startup: function () {
                this.inherited(arguments);
            },
            /**
             * フォームデータ初期化
             * @param item 
             */
            initFrom: function (item) {
                
                // システム管理者(R01001 / R01011)と広報担当は登録ボタンを表示、それ以外は非表示                    
                if (UserInfo.getRoleCd() === 'R03202' || UserInfo.getRoleCd() === 'R01001' || UserInfo.getRoleCd() === 'R01011') {                    
                    this.registerPublicStatusButton.set('style', { display: '' });
                }

                this.form.reset();
                this.admNum.set('value', item.admNum);
                this.version.set('value', item.version);
                this.seqNum.set('value', item.seqNum);
                
                // #12657変更▼
                // if(item.publicFlg === '1' ){
                //     this.damagePublicRdo.set('checked', 'checked');
                //     this.damageNonPublicRdo.set('checked', '');
                // } else {
                //     this.damagePublicRdo.set('checked', '');
                //     this.damageNonPublicRdo.set('checked', 'checked');
                // }
                this.damageReportId.set('value', item.damageReportId);
                var setPublicFlg = (item.publicFlg === '2' ||  item.publicFlg === '0') ? '1' : item.publicFlg;
                this.form.set('value', {publicFlg:setPublicFlg});
                // #12657変更▲
            },

            initContent:function (curItem, preItem) {
                // 被害による通行状況（確定報・公開報）
                // 被害種別を判定(道路)
                if( curItem.damageType === '09') {
                    this.fPublicTrafficStatus.hidden = false; 
                    // 公開報を含む被害報告ありフラグをチェック
                    if (preItem.containingPublicReportFlg === '1' ){
                        this.fTrafficStatus.hidden = false;
                        this.trafficStatus.set('value', curItem.trafficStatus);
                        this.publicTrafficStatus.set('value', preItem.publicTrafficStatus);
                        if(curItem.publicUpdFlg && curItem.publicUpdFlg === '1'){
                            // 更新フラグが立っていた場合は最新報の情報を設定
                            this.publicTrafficStatus.set('value', curItem.publicTrafficStatus);
                        }
                      
                    } else {               
                        // 公開設定が未確認かつ被害概要（公開用）の登録・編集が1度もない場合
                        // 確定報の通行状況を設定 
                        this.publicTrafficStatus.set('value', curItem.trafficStatus); 
                        
                        // 公開設定の更新フラグをチェック
                        if(curItem.publicUpdFlg && curItem.publicUpdFlg === '1'){
                            // 更新フラグが立っていた場合は最新報の情報を設定
                            this.publicTrafficStatus.set('value', curItem.publicTrafficStatus);
                        }

                    }
                } 
                // 被害概要（確定報・公開報）と 内部向け備考
                // 公開報を含む被害報告ありフラグをチェック
                if ( preItem.containingPublicReportFlg === '1' ){
                    this.fDamageContent.hidden = false;
                    this.damageContent.set('value', curItem.damageContent);
                    this.publicDamageContent.set('value', preItem.publicDamageContent);
                    this.internalComment.set('value', preItem.internalComment);
                    // 公開設定の更新フラグをチェック
                    if(curItem.publicUpdFlg && curItem.publicUpdFlg === '1'){
                        // 更新フラグが立っていた場合は最新報の情報を設定
                        this.publicDamageContent.set('value', curItem.publicDamageContent);
                        // 内部向け備考
                        this.internalComment.set('value', curItem.internalComment);
                    }
                    
                } else {
                    // 公開設定が未確認かつ被害概要（公開用）の登録・編集が1度もない場合
                    // 確定報の被害概要を設定  
                    this.publicDamageContent.set('value', curItem.damageContent);
                    // 内部向け備考
                    this.internalComment.set('value', preItem.internalComment);

                    // 公開設定の更新フラグをチェック
                    if(curItem.publicUpdFlg && curItem.publicUpdFlg === '1'){
                        // 更新フラグが立っていた場合は最新報の情報を設定
                        this.publicDamageContent.set('value', curItem.publicDamageContent);
                        this.internalComment.set('value', curItem.internalComment);
                    }
                }

                // #12657追加▼
                // プレビュー欄の初期化
                this.clearPreview();
                // 添付ファイルが存在する場合
                if (curItem.attachFiles.length > 0) {
                    // 画像のみ取り出す
                    var imgCnt = 0; // 配置した画像の数
                    for (var i = 0; i < curItem.attachFiles.length; i++) {
                        var fileData = curItem.attachFiles[i];              // ファイル情報
                        var fileNameInner = fileData.attachFilePath.split("/").reverse().slice(0, -1)[0];  // DB登録されているファイルパスのファイル名
                        var fileNameNonEx = fileNameInner.split(".").slice(0, -1).join("."); // ファイル名から拡張子を取り除いたもの（チェックボックスのID）
                        var dataUri = fileData.attachFilePath.replace('out/', 'data/');   // ファイルパス

                        // ファイル名より画像か判断
                        if (fileNameInner.indexOf('.jpg') !== -1 || fileNameInner.indexOf('.JPG') !== -1 ||
                            fileNameInner.indexOf('.jpeg') !== -1 || fileNameInner.indexOf('.JPEG') !== -1 ||
                            fileNameInner.indexOf('.png') !== -1 || fileNameInner.indexOf('.PNG') !== -1 ||
                            fileNameInner.indexOf('.gif') !== -1 || fileNameInner.indexOf('.GIF') !== -1) {
                            // イメージ設定
                            var image = new Image();
                            image.id = fileNameInner;
                            image.src = dataUri;

                            // 画像とチェックボックスのセット用div作成
                            var inDiv = document.createElement('div');
                            inDiv.setAttribute("class", "in-div");
                            inDiv.setAttribute("id", fileData.damageReportAtcFileId);   // ファイルIDをdivのIDにする

                            // 画像リストへ追加
                            var imgData = [fileData.damageReportAtcFileId, fileNameInner, fileData.publicFlg]; // ファイルIDとファイル名と公開フラグのセット
                            this.lstImage.push(imgData);

                            // サムネイルにチェックボックスをつける
                            var ckb = document.createElement('input');
                            ckb.setAttribute("type", "checkbox");
                            ckb.setAttribute("id", fileNameNonEx);
                            ckb.setAttribute("class", "chkImage");
                            ckb.setAttribute("autocomplete", "off");    // 自動補完をオフにする
                            image.setAttribute("for", fileNameNonEx);

                            // 公開フラグが立っている場合チェックを付けた状態にする
                            if (fileData.publicFlg === "1") {
                                ckb.checked = true;
                            }
                            ckb.setAttribute("style", "display: none;");

                            // プレビュー欄への設定
                            domClass.add(image, 'is-showPreview');
                            inDiv.appendChild(ckb);
                            inDiv.appendChild(image);
                            domConstruct.place(inDiv, this.preview);

                            imgCnt++;   // 配置画像数カウント
                            var self = this;    // チェックボックスチェック時の処理用

                            // チェックボックスクリック時の処理
                            ckb.onclick = function() {
                                // 画像リストを検索
                                for (var i = 0; i < self.lstImage.length; i++) {
                                    var dat = self.lstImage[i];
                                    // チェックボックスのID（拡張子外したファイル名）が同一のデータの公開フラグを切り替える
                                    if (this.id === dat[1].split(".").slice(0, -1).join(".")) {
                                        dat[2] = dat[2] === "0" ? "1" : "0";
                                    }
                                }
                            };

                            // // プレビュー画像クリック時に対応するチェックボックスも変更されるようにする
                            // image.onclick = function() {
                            //     // imgにはファイル名そのままでIDを設定しているので拡張子を除外すればチェックボックスのIDを検索できる
                            //     var ckbId = this.id.split(".").slice(0, -1).join(".");
                            //     var ckbTarget = document.getElementById(ckbId);
                            //     if (ckbTarget.checked == true)
                            //         ckbTarget.checked = false;
                            //     else
                            //         ckbTarget.checked = true;

                            //     // 公開フラグの切り替え
                            //     for (var i = 0; i < self.lstImage.length; i++) {
                            //         var dat = self.lstImage[i];
                            //         // 画像のID（ファイル名）が同一のデータの公開フラグを切り替える
                            //         if (this.id == dat[1]) {
                            //             if (dat[2] == "0")
                            //                 dat[2] = "1";
                            //             else
                            //                 dat[2] = "0";
                            //         }
                            //     }
                            // };
                        }
                    }
                }
                // #12657追加▲
            },

            onChangePublicFlg: function () {
                // #12657削除▼
                // // 添付画像の公開設定：非活性、活性への切り替え
                // this.damageNonPublicRdo.set('disabled', this.form.get('value').publicFlg !== '1');
                // this.damagePublicRdo.set('disabled',this.form.get('value').publicFlg !== '1');
                // if(this.form.get('value').publicFlg !== '1'){
                //     // 公開設定：非表示時、添付画像の公開設定：全非公開設定
                //     this.damageNonPublicRdo.set('value','1');
                // }
                // #12657削除▲
            },

            /**
             * 登録ボタン実行
             */
            onSubmit: function () {
                var publicFlg = this.form.get('value').publicFlg;
                // #12657変更▼
                // if (publicFlg === '1' && (this.damageNonPublicRdo.get('checked') === true || 
                //                             this.damageNonPublicRdo.get('checked') === 'checked')) {
                //     publicFlg = 2;
                // }

                // 公開設定が「公開」で、添付画像の公開設定の画像がない、またはチェックが一つもついていない場合は公開フラグを「公開(添付なし)」にする
                if (publicFlg === '1') {
                    var imagePublicFlg = 0;
                    for (var i = 0; i < this.lstImage.length; i++) {
                        var pubFlg = this.lstImage[i][2];
                        // チェック確認（チェックありがあった時点で抜ける）
                        if (pubFlg === "1") {
                            imagePublicFlg++;
                            break;
                        }
                    }
                    // チェックボックスの選択がなかった場合
                    if (imagePublicFlg === 0) {
                        publicFlg = 2;
                    }
                }
                // #12657変更▲
                // フォームのバリデーションを行う（共通部品）
                if (!this.form.validate()) {
                    return false;
                }
                
                this.chain.confirm('全ての被害報の公開設定変更しますか？', lang.hitch(this, function (chain) {
                    chain.hide();

                    // #12657変更▼
                    // Loader.wait(Requester.post('/api/damageReports/updatePublicStatus?admNum=' + this.admNum.get('value') + 
                    //     '&seqNum='+ this.seqNum.get('value'),
                    Loader.wait(Requester.post('/api/damageReports/updatePublicStatus?damageReportId=' + this.damageReportId.get('value') + 
                        '&admNum=' + this.admNum.get('value') + '&seqNum='+ this.seqNum.get('value'),
                    // #12657変更▲
                    {
                        data: json.stringify({
                             publicFlg: publicFlg, 
                             version: this.version.get('value') ,
                             publicDamageContent: this.publicDamageContent.get('value'),
                             publicTrafficStatus: this.publicTrafficStatus.get('value'),
                             internalComment: this.internalComment.get('value'),
                             publicUpdFlg: '1',
                             attachFiles: this.lstImage
                            }),
                        headers: { 'Content-Type': 'application/json; charset=utf-8' },
                        handleAs: 'json',
                        preventCache: true
                    }).then(function () {
                        console.debug('success register public damageReport');
                        chain.infoComplete(lang.hitch(this, function () {
                            chain.hide();
                            location.reload(false);
                        }));
                    }), function (err) {
                        // いずれかに失敗時
                        chain.infoError(err);
                    });
                }));
            },

            // #12657追加▼
            /**
             * プレビュー欄のクリア
             */
            clearPreview: function() {
                var length = this.preview.childNodes.length;    // プレビュー欄のオブジェクト件数
                // プレビュー画像とチェックボックスの削除
                for (var i = 0; i < length; i++) {
                    domConstruct.destroy(this.preview.childNodes[0]);
                }
                // 画像リストの中身もリセット
                if (this.lstImage) {
                    this.lstImage.splice(0, this.lstImage.length);
                }
            }
            // #12657追加▲
        });
});
