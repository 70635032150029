/**
 * <避難所情報概況画面>
 *
 * @module app/shelter/ShelterAdminPage.js
 */
define(['module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/text!./templates/ShelterAdminPage.html', // テンプレート文字列
    'dojo/json',
    'dojo/Deferred',
    'idis/store/IdisRest',
    'idis/service/Requester',
    'idis/view/page/_PageBase', // 共通基底クラス
    'dojo/dom',
    'dojo/dom-style',
    'dijit/registry',
    'idis/view/grid/IdisGrid',
    'dstore/Memory',
    'idis/consts/ACL',
    'idis/view/Loader',
    'idis/view/form/AclButton',
    'idis/view/grid/helper',
    'idis/view/dialog/DialogChain',
    'idis/model/UserInfo',
    'idis/control/Locator',
    'app/model/DisasterInfo',
    'idis/control/Router',
    'app/config',
    'idis/consts/USER_TYPE',
    'app/shelter/ShelterUtils',
    // 以下、変数で受けないモジュール
    'dijit/layout/BorderContainer',
    'dijit/form/RadioButton',
    'dijit/form/Select',
    'dijit/form/Form',
    'dijit/form/CheckBox',
    'dijit/layout/ContentPane',
    'app/shelter/ShelterFormDialog',
    'app/shelter/ShelterAdminGrid'
], function (module, declare, lang, array, template, json, Deferred, IdisRest, Requester,
    _PageBase, dom, domStyle,
    registry, IdisGrid, Memory, ACL, Loader, AclButton, helper,
    DialogChain, UserInfo, Locator, DisasterInfo, Router, config, USER_TYPE, ShelterUtils) {
    /**
     * 避難所概況画面
     * @class ShelterAdminPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/shelter/ShelterAdminPage~ShelterAdminPage# */
        {
            // テンプレート文字列
            templateString: template,
            store: null,
            shelterGrid: null,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--shelter',

            /**
             * 自動承認モードフラグ
             * true: ON（自区）
             * false: OFF / ON（他区）
             */
            _autoConfirmFlg: null,

            /**
             * 閲覧モードフラグ
             * '0' (通常モード): false, '1' (閲覧モード): true
             */
            _viewMode: null,

            constructor: function () {
                this.store = new IdisRest({
                    target: '/api/shelters/admin'
                });
                // 災害IDをDisasterInfoから取得
                this._disasterId = DisasterInfo.getDisasterId();
                if (!this._disasterId) {
                    this._disasterId = 1;
                    console.warn('災害IDが設定されていません。');
                }
                this.chain = DialogChain.get(this);
                // 閲覧モード状況を取得
                this._viewMode = Locator.getQuery().viewMode === '1' ? true : false;

                console.debug('現在のログインユーザID：' + UserInfo.getId());
                console.debug('現在の災害ID：' + DisasterInfo.getDisasterId());
            },

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
                this.initShelterGrid();
                this.own(this.formDialog);
            },

            startup: function () {
                this.inherited(arguments);
                // 自動承認モード状況を取得
                ShelterUtils.isAutoConfirmed().then(lang.hitch(this, function (result) {
                    // 自動承認モードフラグをセット
                    this._autoConfirmFlg = result;
                    // 照会結果を取得してからボタンをセット
                    this.setButtonStatus();
                }));
            },


            setButtonStatus: function () {
                // 自動承認モードがON（自区）の場合、ラベルをセットする。
                if (this._autoConfirmFlg) {
                    domStyle.set(this.confirmLabel, 'display', '');
                    // 自動承認モードの設定権限があるユーザのみ解除ボタンを表示
                    if (ShelterUtils.hasAutoConfirmAuthz(UserInfo)) {
                        domStyle.set(this.AutoConfirmButton.domNode, 'display', 'none');
                        domStyle.set(this.AutoConfirmCancelButton.domNode, 'display', '');
                    }
                }
                // 自動承認モードの設定権限がない場合、または閲覧モードの場合、
                // 「自動承認モード」ボタンを非表示にする。
                if (!ShelterUtils.hasAutoConfirmAuthz(UserInfo) || this._viewMode) {
                    domStyle.set(this.AutoConfirmButton.domNode, 'display', 'none');
                    domStyle.set(this.AutoConfirmCancelButton.domNode, 'display', 'none');
                }
            },

            initShelterGrid: function () {
                // グリッドの詳細ボタンクリック時の動作を設定する
                // helper.buttonColumnでフィールド名に指定した'list'と'ButtonClick'の結合がボタンクリック時のイベント名
                this.shelterGrid.on('listButtonClick', lang.hitch(this, function (evt) {
                    this.onListButtonClick(evt.item);
                }));
                //表を初期条件で表示
                this.updateGridQuery();
            },

            //一覧ボタンを押下したときの挙動
            onListButtonClick: function (object) {
                console.debug('[一覧]ボタンがクリックされました。');
                console.debug(object);
                // 避難所情報一覧画面へ遷移
                Router.moveTo('shelter', {
                    municipalityCd: object.municipalityCd,
                    viewMode: Locator.getQuery().viewMode
                });
            },
            //新規登録ボタンを押下したときの挙動
            onRegisterButtonClick: function () {
                console.debug('[新規登録]ボタンがクリックされました。');
                // 避難所情報新規登録画面へ遷移
                if (!this._municipalityCd) {
                    this._municipalityCd = config.municInfo.defaultMunicCd;
                }
                Router.moveTo('shelter/register', {
                    municipalityCd: this._municipalityCd
                });
            },
            //施設追加ボタンを押下したときの挙動
            onRegisterFacilityButtonClick: function () {
                console.debug('[施設追加]ボタンがクリックされました。');
                // 施設新規登録画面へ遷移
                Router.moveTo('facility/register', {
                    type: 'shelter'
                });
            },
            //一括操作ボタンを押下したときの挙動
            onBulkOperatingButtonClick: function (object) {
                console.debug('[一括操作]ボタンがクリックされました。');
                console.debug(object);
                // 避難所情報一括操作画面へ遷移
                Router.moveTo('shelter/operating', {
                    municipalityCd: this._municipalityCd
                });
            },
            showFormDialog: function () {
                this.formDialog.show();
            },
            /**
             * 検索ボタンが押されたときに呼び出される。
             */
            onSubmit: function () {
                try {
                    if (this.form.isValid()) {
                        // 入力値が正常ならグリッドの検索条件を更新
                        this.updateGridQuery();
                    }
                } catch (e) {
                    console.error(e);
                } finally {
                    return false;
                }
            },

            /**
             * グリッドの検索条件を指定された値で更新する。
             * @param {Object} value name属性と値のマッピング
             */
            updateGridQuery: function () {
                var value = this.form.get('value');
                // 入力値を元にグリッド用フィルターを作成
                var filter = new this.store.Filter();
                // 災害ID
                filter = filter.eq('disasterId', this._disasterId);
                //集計日時
                if (value.countTime) {
                    var countTime = new Date(value.countTime).getTime();
                    filter = filter.eq('countTime', countTime);
                }
                // すべての市区町村を表示：
                var openOnlyFlg = '';
                //チェックがついていなければopenOnlyフラグを1にする
                if (value.openAllFlg.length === 0) {
                    openOnlyFlg = '1';
                } else {//チェックがついているときは全件表示のため、openOnlyフラグを0にする。
                    openOnlyFlg = '0';
                }
                filter = filter.eq('confirmFlg', this.confirmFlg.checked ? '1' : '0');

                filter = filter.eq('openOnlyFlg', openOnlyFlg);

                // filterに対応するcollectionを取得
                var collection = this.store.filter(filter);
                // collectionをグリッドにセットする（サーバーにリクエストされる）
                this.shelterGrid.set('collection', collection);
            },

            // 自動承認モードボタンを押下したときの挙動
            onAutoConfirmButtonClick: function () {
                // 確認ダイアログを表示
                var message = '自動承認モードがONの場合は、避難所状況が更新された場合に'
                    + '自動的に外部に公開されます。'
                    + '<br>自動承認モードに切り替えますか？';
                this.chain.confirm(message, lang.hitch(this, function (chain) {
                    // ダイアログを閉じる
                    chain.hide();
                    // TODO: 登録処理～自動承認登録～ボタン制御までのプロミス連鎖化
                    this.showShelterDialog();
                    // 自動承認モードの処理
                    ShelterUtils.startAutoConfirm(chain);
                    // ラベル・ボタンの表示・非表示化
                    domStyle.set(this.confirmLabel, 'display', '');
                    domStyle.set(this.AutoConfirmButton.domNode, 'display', 'none');
                    domStyle.set(this.AutoConfirmCancelButton.domNode, 'display', '');
                }));
            },

            // 自動承認モード解除ボタンを押下したときの挙動
            onAutoConfirmCancelButtonClick: function () {
                // 確認ダイアログを表示
                var message = '自動承認モードを解除しますか？';
                this.chain.confirm(message, lang.hitch(this, function (chain) {
                    // ラベル・ボタンの表示・非表示化
                    domStyle.set(this.confirmLabel, 'display', 'none');
                    domStyle.set(this.AutoConfirmButton.domNode, 'display', '');
                    domStyle.set(this.AutoConfirmCancelButton.domNode, 'display', 'none');
                    // 自動承認モード解除の処理
                    ShelterUtils.endAutoConfirm(chain);
                    // ダイアログを閉じる
                    chain.hide();
                }));
            },

            /**
             * 配信情報(開設避難所情報)ダイアログを表示する。
             */
            showShelterDialog: function () {
                // var deferred = new Deferred();
                // 該当災害・区内の未承認報を一覧取得する
                var promise = Requester.get('/api/shelters/autoConfirm/start/', {
                    query: {
                        disasterId: this._disasterId,
                        municipalityCd: UserInfo.getMunicipalityCd()
                    }
                }).then(lang.hitch(this, function (item) {
                    var isOnlyPrivate = true;
                    var privateShelters = [];
                    var publicShelters = [];
                    var shelterIds = [];
                    if (!item.shelters || item.shelters.length === 0) {
                        // 該当災害・区内に未承認報がない場合は先に進まない
                        return;
                        // return deferred.resolve();
                    }
                    array.forEach(item.shelters, function (data) {
                        // 本部確認用に避難所IDをリスト化して保持する
                        shelterIds.push(data.shelterId);
                        if (data.privateFlg === '1') {
                            // Lアラート配信対象（公開報）
                            isOnlyPrivate = false;
                            publicShelters.push(data);
                        } else {
                            // Lアラート配信対象外（非公開報）
                            privateShelters.push(data);
                        }
                    });
                    // 非公開指定の報のみの場合、配信ダイアログを挟まずに対象避難所を本部確認する
                    if (isOnlyPrivate) {
                        return this.sendAndConfirm(shelterIds, null);
                    }
                    // Lアラート項目の整形
                    var evaqueeDatas = [];
                    array.forEach(publicShelters, function (obj) {
                        // 日付の形式を、Lアラート用にフォーマットする
                        var shelterStartTime = ShelterUtils.formatDate(obj.shelterStartTime);
                        var shelterEndTime = obj.shelterEndTime ? ShelterUtils.formatDate(obj.shelterEndTime) : null;
                        var shelterStartOrEndTime = shelterStartTime;
                        // Lアラート 開設状況
                        var sort = '開設';
                        if (obj.shelterEndTime && obj.shelterEndTime !== null) {
                            sort = '閉鎖';
                            shelterStartOrEndTime = shelterEndTime;
                        }
                        // Lアラート 避難者数・避難世帯数
                        var evaqueeNum4Lalert = isNaN(obj.evaqueeNum) ? 0 : Number(obj.evaqueeNum);
                        var evacHouseholdNum4Lalert = isNaN(obj.evacHouseholdNum) ? 0 : Number(obj.evacHouseholdNum);
                        // 外部公開情報をセットする（0: 公開, 1: 非公開)
                        var publish4Lalert = obj.privateFlg === '1' ? '0' : '1';
                        // 避難所種別・避難所種別で表現しきれない情報をセットする
                        var evacShelterType = '避難所';
                        var evacShelterTypeDetail = '';
                        if (obj.welfareEvacShFlg === '1') {
                            // 福祉避難所の場合、避難所種別で表現しきれない情報を「福祉避難所」にする
                            evacShelterTypeDetail = '福祉避難所';
                        }
                        if (obj.temporaryEvacShFlg === '1') {
                            // 臨時避難所の場合、避難所種別を「臨時避難所」にする
                            // フラグが複数の場合も、臨時避難所が選択されていれば臨時避難所とみなす
                            evacShelterType = '臨時避難所';
                        }
                        // 指定区分をセットする
                        var evacSuitableShelter = '指定なし';
                        if (item.designatedEvacShFlg === '1') {
                            // 災害時避難所の場合、指定区分を「指定避難所」にする
                            evacSuitableShelter = '指定避難所';
                        }

                        var evaqueeData = {
                            facilityId: obj.facilityId,
                            evacShelterName: obj.facilityName,
                            evacShelterNameKana: obj.facilityNameKana,
                            evacShelterType: evacShelterType,
                            evacShelterTypeDetail: evacShelterTypeDetail,
                            evacSuitableShelter: evacSuitableShelter,
                            evacShelterSort: sort,
                            evacShelterLatitude: obj.latitude,
                            evacShelterLongitude: obj.longitude,
                            address: obj.address,
                            evaqueeNum: evaqueeNum4Lalert ? evaqueeNum4Lalert : 0,
                            evacHouseholdNum: evacHouseholdNum4Lalert ? evacHouseholdNum4Lalert : 0,
                            evacShelterDatetime: shelterStartOrEndTime,
                            evacTopical: obj.comment ? obj.comment : '',
                            evacShelterStatus: obj.spaceStatus,
                            publish: publish4Lalert,
                            capacity: obj.capacity,
                            contactInfo: obj.phoneNum,
                            district: obj.districtName
                        };
                        evaqueeDatas.push(evaqueeData);
                    });

                    var args = {};
                    //  Lアラート更新種別をセット。
                    // 該当災害・区内で既にLアラート配信履歴があり、かつ取消(=03)以外の場合は、「更新・訂正(=02)」
                    // 配信履歴がない、または最後の配信種別が取消(=03)の場合は「新規(=01)」
                    var distributionType = '02';
                    if (!item.lalertSendHistId || item.distributionType === '03') {
                        distributionType = '01';
                    }

                    args.shelterArgs = {
                        sendCategory: '02',
                        evacTimestamp: new Date(),
                        evaqueeData: evaqueeDatas,
                        // 更新種別 '01':新規, '02':更新・訂正, '03':取消
                        distributionType: distributionType
                        //    seqNum: this._seqNum // TODO: seqNumが必要か要検討
                    };
                    args.facilityId = publicShelters[0].facilityId;
                    args.evacOrderArgs = null;

                    // ダイアログの最初の子要素が登録画面
                    var dialog = this.shelterRegisterDialog.getChildren()[0];
                    // ダイアログの画面を初期化する。
                    dialog.initDialog(args, false, false, false);
                    // ダイアログのsendイベントを受け取れるようにする
                    dialog.on('send', lang.hitch(this, function (evt) {
                        return this.sendAndConfirm(shelterIds, evt.value);
                    }));
                    // ダイアログを破棄できないように設定する。
                    this.shelterRegisterDialog.set('closable', false);
                    // ダイアログを表示する。
                    this.shelterRegisterDialog.show();
                }));
                // ローダーの表示
                Loader.wait(promise);
                // // プロミスを返却
                // return deferred.promise;
            },

            sendAndConfirm: function (shelterIds, shelterInfo) {
                // ダイアログ連鎖を登録
                this.chain = DialogChain.get(this);
                // var deferred = new Deferred();
                var sendData = {
                    shelterIds: shelterIds,
                    disasterId: this._disasterId
                    // FIXME: versionを含める場合は追加
                };
                if (shelterInfo) {
                    // TODO: 一括配信時のtwitter連携方針の検討
                    //    this._sendData.twitterFlg = shelterInfo.twitterFlg;
                    //    this._sendData.tweetList = shelterInfo.tweetList;
                    sendData.sendPostForm = shelterInfo.lalertForm;
                }
                var jsonStr = json.stringify(sendData);
                var message = '';
                // 大阪府連携対象
                if (shelterInfo) {
                    message = '本部確認及び大阪府への配信を行います。よろしいですか？';
                    // 大阪府連携非対象
                } else {
                    message = '本部確認します。よろしいですか？';
                }
                this.chain.confirm(message, lang.hitch(this, function (chain) {
                    var promise = Requester.put('/api/shelters/autoConfirm/start', {
                        data: jsonStr
                    }).then(lang.hitch(this, function (data) {
                        console.debug('未承認報の一括承認に成功しました。data:' + data);
                        var compMessage = shelterInfo ?
                            '本部確認・配信が完了しました。' : '本部確認が完了しました。';
                        chain.info(compMessage, '完了', lang.hitch(this, function () {
                            chain.hide();
                            if (shelterInfo) {
                                this.shelterRegisterDialog.getChildren()[0].closeDialog();
                            }
                            this.updateGridQuery();
                            // deferred.resolve();
                        }));
                    }), function (error) {
                        // 失敗時
                        console.error(error);
                        chain.info('本部確認に失敗しました。', 'エラー');
                        // deferred.rejected();
                    });
                    Loader.wait(promise);
                }), lang.hitch(this, function (chain) { // キャンセル押下時
                    chain.hide();
                    if (shelterInfo) {
                        this.shelterRegisterDialog.getChildren()[0].closeDialog();
                    }
                }));
                // return deferred.promise;
            }
        });
});
