/**
* 気象情報一覧画面用モジュール。
* @module app/weatherinfo/view/WeatherInfoTimelineGrid
*/
define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
    'dojo/dom-construct',
	'dojo/_base/array',
	'dojox/lang/functional/object',
	'idis/view/grid/IdisGrid',
	'idis/view/grid/helper',
    '../model/kindCodeName',
	// 以下、変数として受け取らないモジュール
	'dojox/lang/functional/array'
], function(module, declare, lang, construct, array, df, IdisGrid, helper, kindCodeName) {
    return declare(module.id.replace(/\//g, '.'), IdisGrid, {
        /**
         * 各列の定義
         * @type {Object[]}
         */
         columns: [
            helper.column('announceTime', '発表日時', {sortable: false}),
            {field: 'kisyoKindCodes', label: '注意報・警報', sortable: false,
                renderCell: function(item) {
                    var div = construct.create('div', {
                        style: 'text-align: left;'
                    });
                    // Fから始まる場合、指定河川 / Lから始まる場合、土砂災害
                    if (typeof (item.kisyoKindCodes) === 'string') {
                        var code = item.kisyoKindCodes;
                        var span = construct.toDom('<span class="' + kindCodeName.getClass(code) +
                             '" style="white-space: nowrap; display: inline-block; margin: 2px 4px; ' +
                             'padding: 8px 12px; border-radius: 4px;">' +
                             kindCodeName.getName(code) + '</span>');
                        construct.place(span, div);
                    }
                    // 配列の場合、気象情報
                    if (typeof (item.kisyoKindCodes) === 'object') {
                        array.forEach(item.kisyoKindCodes, function (code) {
                            var span = construct.toDom('<span class="' + kindCodeName.getClass(code) +
                                '" style="white-space: nowrap; display: inline-block; margin: 2px 4px; ' +
                                'padding: 8px 12px; border-radius: 4px;">' +
                                kindCodeName.getName(code) + '</span>');
                            construct.place(span, div);
                       });
                    }
                    return div;
                }
            }
        ]
	});
});
