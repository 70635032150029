/**
 * 火山詳細画面用地図レイヤー
 * @module app/volcano/layer/VolcanoDetailLayer
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/Deferred',
    'dojo/_base/array',
    'dojo/_base/lang',
    'idis/service/Requester',
    'leaflet'
], function(module, declare, Deferred, array, lang,
    Requester, leaflet) {
    return declare(module.id.replace(/\//g, '.'), null, {
        _layer: null,
        _deferred: null,

        _districtList: [], // 警戒対象自治体リスト

        _popupTitle: '噴火警報・予報',

        // 警報コード毎の色
        // 気象庁資料 "地震火山関連コード表" と
        // https://www.jma.go.jp/jp/volcano/map_0.html
        // の色をマッピングしたもの
        _colorsByVwCode: {
            12: '#FAF501',
            13: '#FAF501',
            14: '#FF2800',
            15: '#C800FF',
            22: '#FAF501',
            23: '#FFAA00',
            24: '#C800FF',
            25: '#C800FF',
            31: '#FAF501',
            36: '#FFAA00',
            41: '#C800FF',
            42: '#FFAA00',
            43: '#FFAA00',
            44: '#FFAA00',
            45: null,
            46: '#C800FF',
            47: '#C800FF',
            48: '#FAF501',
            49: '#FAF501'

        },

        /**
         * コンストラクター
         * @param {*} data
         *              volcanoDetailPage.jsからのlayerData
         */
        constructor: function(data) {
            if (data.area && data.area.length > 0) {
                this._districtList = data.area;
            }

            this._deferred = new Deferred();

            Requester.get('/data/master/municipalities.geojson', {
                preventCache: false

            }).then(lang.hitch(this, function(data) {
                this._layer = leaflet.geoJson(data, {
                    style: lang.hitch(this, this._style),
                    onEachFeature: lang.hitch(this, this._onEachFeature)
                });
                this._deferred.resolve(); // 待ち合わせ解放
            }));
        },

        /**
         * 地図にレイヤーを表示させる
         * @param {leaflet.map}} map
         */
        addTo: function(map) {
            // geojson - layer生成を待ち合わせる
            this._deferred.then(lang.hitch(this, function() {
                // 地図にレイヤーを載せる
                this._layer.addTo(map);
            }));
        },

        /**
         * 警戒対象一覧を更新
         * @param {*} areaList
         *               予報時間選択した際に取得する新たな市区町村リスト
         */
        refreshArea: function(areaList) {
            this._districtList = areaList;
            this._layer.setStyle(lang.hitch(this, this._style));
        },

        /**
         * geoJSONのstyle
         * @param {*} feature
         */
        _style: function(feature) {
            // 警戒対象でない時のstyle
            // 外枠だけ
            var defaultStyle = {
                weight: 1,
                fill: false,
                color: '#0D0000',
                lineWidth: 2
            };

            // 警戒対象の自治体一覧に含まれていたらstyleを適用する
            var warnings = array.filter(this._districtList, function(item) {
                return (item.areaICode === feature.properties.N03_007);
            });

            // districtListには同じ自治体は含まれていない前提で, filterされた最初の要素(警報コード)を使う
            if (warnings[0] && this._colorsByVwCode[warnings[0].vwCode]) {

                return lang.mixin(defaultStyle, {
                    fillColor: this._colorsByVwCode[warnings[0].vwCode],
                    fill: true,
                    fillOpacity: 0.3
                });

            } else {
                return defaultStyle;
            }
        },

        /**
         * 各地物のpopup設定
         * @param {*} feature
         * @param {*} layer
         */
        _onEachFeature: function(feature, layer) {
            var content = '<tr><td style="color:#FF0000"><b>' + this._popupTitle + '</b></td></tr>' +
                // 市町村名
                '<tr><td>' +
                (feature.properties.N03_003 ? feature.properties.N03_003 : '') + ' ' +
                (feature.properties.N03_004 ? feature.properties.N03_004 : '') +
                '</td></tr>';

            // 警戒対象の自治体一覧に含まれていたらポップアップに警報種別を追加
            var warnings = array.filter(this._districtList, function(item) {
                return (item.areaICode === feature.properties.N03_007);
            });

            content += '<tr><td>';
            if (warnings[0]) {
                content += warnings[0].vwName;
            }
            content += '</td></tr>';

            if (content) {
                return layer.bindPopup('<table>' + content + '</table>', { maxWidth: 1000, closeButton: false });
            }
        }
    });
});
