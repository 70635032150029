/**
 * 地区選択用レイヤー
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/topic',
    'leaflet',
    'app/config',
    'idis/view/draw/_OptionConverterMixin'
], function(module, declare, lang, topic, leaflet, config, _OptionConverterMixin){
    // 地図レイヤーのスタイルを設定する。
    return {
        // 避難情報カラー
        _colors:{
            // 避難準備・高齢者等避難開始: 'orange'
            _prepareColor: '#E98E33',
            // 避難勧告: 'red'
            _advisoryColor: '#FC002E',
            // 避難指示（緊急）: 'purple'
            _orderColor: '#68008B',
            // 災害発生情報： 'black'
            _occurColor: '#000000',
            // 自主避難: 'light blue'
            _voluntaryColor: '#B9EBFF',
            // 解除: 'gray'
            _cancelColor: '#808080'
        },

        // 非選択状態
        _defaultStyle : {
            weight: 1,
            opacity: 0.9,
            color: '#808080',
            // fillColor: '#808080',
            dashArray: '',
            fillOpacity: 0.1
        },
        // 非選択状態且つマウスオーバー
        _mouseOnStyle : {
            weight: 2,
            opacity: 0.8,
            color: '#808080',
            // fillColor: '#ff9500',
            dashArray: '',
            fillOpacity: 0.6
        },
        // 選択状態
        _selectedStyle : {
            weight: 1,
            opacity: 1,
            color: '#808080',
            // fillColor: '#ff9500',
            dashArray: '',
            fillOpacity: 0.8
        },
        // 選択状態且つマウスオーバー
        _selectedMouseOnStyle : {
            weight: 2,
            opacity: 1,
            color: '#808080',
            // fillColor: '#ff9500',
            dashArray: '',
            fillOpacity: 0.9
        },

        _filter: function(feature) {
            var result = false;
            // 最新の地区マスタ
            var districtTreeCache = this._districtTreeCache;
            districtTreeCache.some(lang.hitch(this, function(item) {
                // リーフ要素でない場合はスキップ
                if(item.evacOrderDistFlg === '0') {
                    return;
                }
                // マスタに存在する有効地区情報であれば対象にする。
                if (feature.properties.D_CD === item.districtCd) {
                    result = true;
                    return true; //break
                }
            }));
            return result;
        },

        // onEachFeatureを設定する。
        _onEachFeature: function(feature, layer){
            // 地区名をポップアップに設定する(D_NAME: 地区名)
            // if(feature.properties.D_NAME){
            //     layer.bindPopup(
            //         // D_NAME:地区名
            //         feature.properties.D_NAME,
            //         // ポップアップのプロパティ
            //         {
            //             maxWidth:1000,
            //             closeButton: false,
            //             // ポップアップがフィーチャーと重なり選択しづらいので、
            //             // 離れた場所にポップアップを表示する。
            //             offset:leaflet.point(0, -25)
            //         }
            //     );
            // }
            // Polygonはlayer.options、MultiPoligonはlayer._layer[id].optionsとしてoptionsを保持している。
            var options = layer.options || layer._layers[layer._leaflet_id - 1].options; //jshint ignore:line
            // レイヤーにイベントを付与する
            layer.on({
                mouseover: options._mouseOverFeature,
                mouseout: options._mouseOutFeature,
                click: options._selectFeature
            });
        },

        // マウスオーバーをした際のアクションを追加する。
        _mouseOverFeature : function(e) {
            var layer = e.target;
            // Polygonはlayer.options、MultiPoligonはlayer._layer[id].optionsとしてoptionsを保持している。
            var options = layer.options || layer._layers[layer._leaflet_id - 1].options; //jshint ignore:line

            // 画面上で選択されている避難区分の色を設定する。
            // MultiPolygonの場合、複数の子レイヤーに色を設定する必要があるため、配列で保持している全てのレイヤーに色を指定する。
            options.fillColor = options._selectedColor;
            if(layer._layers){
                Object.keys(layer._layers).forEach(function(key){
                    layer._layers[key].options.fillColor = options._selectedColor;
                });
            }

            // 選択の有無に応じて設定するスタイルを変更する。
            if(options._isSelected){
                // マウスオーバー用のスタイルに変わる前の透過度を保存しておく
                options._latestOpacity = options.fillOpacity;
                layer.setStyle(options._selectedMouseOnStyle);
            }else{
                layer.setStyle(options._mouseOnStyle);
            }

            if (!leaflet.Browser.ie && !leaflet.Browser.opera && !leaflet.Browser.edge) {
                layer.bringToFront();
            }

            // ポップアップを開く。
            layer.openPopup();

        },

        // マウスアウトした際のアクションを追加する。
        _mouseOutFeature : function(e) {
            var layer = e.target;
            // Polygonはlayer.options、MultiPoligonはlayer._layer[id].optionsとしてoptionsを保持している。
            var options = layer.options || layer._layers[layer._leaflet_id - 1].options; //jshint ignore:line
            var style;

            if(options._isSelected){
                // 画面上で選択されている避難区分の色を設定する。
                // MultiPolygonの場合、複数の子レイヤーに色を設定する必要があるため、配列で保持している全てのレイヤーに色を指定する。
                options.fillColor = options._selectedColor;
                if(layer._layers){
                    Object.keys(layer._layers).forEach(function(key){
                        layer._layers[key].options.fillColor = options._selectedColor;
                    });
                }

                // 選択されているのが「一部地域」だった場合、見え方を変更する
                style = options._selectedStyle;

                // 保存しておいた透過度に戻す。保存していたものが特になければ関係なく、デフォルトの透過度に戻す
                style.fillOpacity = options._latestOpacity || options.fillOpacity;

                layer.setStyle(options._selectedStyle);

            }else{
                // 画面上で選択されている避難区分の色を設定する。
                // MultiPolygonの場合、複数の子レイヤーに色を設定する必要があるため、配列で保持している全てのレイヤーに色を指定する。
                options.fillColor = options._cancelColor;
                if(layer._layers){
                    Object.keys(layer._layers).forEach(function(key){
                        layer._layers[key].options.fillColor = options._cancelColor;
                    });
                }

                style = options._defaultStyle;
                if(layer.feature.properties.KEY_CODE &&
                    config.municInfo.cities4multiDistShare.indexOf(
                        layer.feature.properties.KEY_CODE.substr(0,5)) !== -1){
                    style.fillOpacity = 0;
                }
                // スタイルを選択する。
                layer.setStyle(style);
            }

            // ポップアップを閉じる。
            if(layer.closePopup){
                layer.closePopup();

            }else{
                // MultiPolygonの場合closePopの対象となるのはイベントのターゲットとなるレイヤー
                // MultiPolygonのlayerはclosePopupのfuntionを保持していないため、子レイヤーのfunctionを利用する。
                layer._layers[layer._leaflet_id - 1].closePopup();;//jshint ignore:line
            }

        },

        // クリックされた時の処理を追加する。
        // 市町が選択された場合、市町ID及び親ID（地域ID）情報をレイヤーから取得する。
        // 該当する項目のチェックボックスを指定する処理を発行する。
        _selectFeature : function(e) {
            // D_CD: 地区コード、M_CD: 市町村コード
            var properties = e.target.feature.properties;
            var item = {id: properties.D_CD, parentId: properties.M_CD};
            topic.publish('app/evacorder/DistrictLayerFactory' + '::selectDistrict', item);
        },

        // レイヤーが選択された時の処理
        // TODO チェックボックスが選択された時に呼ばれる。
        // 自分のレイヤーが呼ばれた時にはtopicでチェックボックスが選択されて、
        // それに伴ってこのメソッドが呼ばれることでスタイルが変更される。
        _selectDistrictLayer: function(layer){
            var style;
            if (this._isSelected){
                this._isSelected = false;
                this.fillColor = this._cancelColor;
                // MultiPolygon対応
                if(layer._layers){
                    Object.keys(layer._layers).forEach(function(key){
                        layer._layers[key].options.fillColor = this._cancelColor;
                    }, this);
                }

                style = this._defaultStyle;
                if(layer.feature.properties.KEY_CODE &&
                    config.municInfo.cities4multiDistShare.indexOf(
                        layer.feature.properties.KEY_CODE.substr(0,5)) !== -1){
                    style.fillOpacity = 0;
                }
                // スタイルを選択する。
                layer.setStyle(style);

            }else{
                this._isSelected = true;
                this.fillColor = this._selectedColor;

                // MultiPolygon対応
                if(layer._layers){
                    Object.keys(layer._layers).forEach(function(key){
                        layer._layers[key].options.fillColor = this._selectedColor;
                    }, this);
                }

                // 選択されているのが「一部地域(=ポリゴンを共有しているが実情としては別の地域)」だった場合、見え方を変更する
                style = this._selectedStyle;
                if(layer.feature.properties.POLYGON_SHARE_NUM &&
                        layer.feature.properties.POLYGON_SHARE_NUM !== '0' &&
                        layer.feature.properties.POLYGON_SHARE_NUM !== 0 ){
                    var opacityFillingRatio = 1 / parseInt(layer.feature.properties.POLYGON_SHARE_NUM, 10);

                    // デフォルトにすべき比率は、地区をいくつ重ねるかにより微妙に異なる。
                    // FIXME: 要チューニング
                    var base = 1.2;
                    switch(layer.feature.properties.POLYGON_SHARE_NUM){
                        case 2:
                            base = 1.1;
                            break;
                        case 3:
                            base = 1.2;
                            break;
                        default:
                            base = 1.2;
                            break;
                    }
                    style.fillOpacity = base * opacityFillingRatio;
                } else {
                    style.fillOpacity = 0.8;
                }

                layer.setStyle(style);
            }
        },

        _toTanJSON: function(){
            var tanJsons  = this.toGeoJSON();
            tanJsons.features = [];
            this.eachLayer(function(layer){
                /* lang.hitch(this,function(){
                    var options = layer.options || layer._layers[layer._leaflet_id - 1].options; //jshint ignore:line
                    options = this.optionConverter(options, 'export');
                }); */
                tanJsons.features.push(layer);
            });
            return {
                type: tanJsons.type,
                features: tanJsons.features
            };
        },

        // GeoJsonレイヤーのFeature Groupを返却する
        getGeoJsonLayers: function(geojson, districtTreeCache, municipalityCd) {
            leaflet.GeoJSON.include(_OptionConverterMixin);
            if(config.municInfo.cities4multiDistShare.indexOf(municipalityCd.substr(0,5)) !== -1){
                this._defaultStyle.fillOpacity = 0;
            } else {
                this._defaultStyle.fillOpacity = 0.1;
            }
            return leaflet.geoJson(geojson, {
                style: this._defaultStyle,
                filter: this._filter,
                _districtTreeCache: districtTreeCache,
                // onEachFeatureとonEachFeatureでアタッチするイベント
                onEachFeature: this._onEachFeature,
                _mouseOverFeature: this._mouseOverFeature,
                _mouseOutFeature: this._mouseOutFeature,
                _selectFeature: this._selectFeature,
                // color
                _colors: this._colors,
                _selectedColor: this._colors._advisoryColor,
                _defaultStyle: this._defaultStyle,
                _mouseOnStyle: this._mouseOnStyle,
                _selectedStyle: this._selectedStyle,
                _selectedMouseOnStyle: this._selectedMouseOnStyle,
                // 外部から利用する機能
                selectDistrictLayer: this._selectDistrictLayer,
                toTanJSON: this._toTanJSON,
                // 選択されいてるfeatureかどうかの判定用フラグ
                _isSelected: false
            });
        }
    };
});
