/**
 * 被害報画面用モジュール。
 * @module app/damage/DamageReportViewPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/DamageReportViewPage.html',
    'idis/consts/USER_TYPE',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'app/damage/DamageReportPage',
    '../config',
    // 以下、変数として受け取らないモジュール
], function (module, declare, lang, template, UserType, Locator, Router, UserInfo, DamageReportPage, config) {

    /**
     * 被害概要 閲覧ページ
     * @class DamageReportViewPage
     * @extends module:app/damage/DamageReportPage~DamageReportPage
     */
    return declare(module.id.replace(/\//g, '.'), DamageReportPage,
        /** @lends module:app/damage/DamageReportViewPage# */ {
            // テンプレート文字列
            templateString: template,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--damage',

            // DOMノードを生成するためのメソッド
            buildRendering: function () {
                this.inherited(arguments);
            },

            // ページ初期表示にmodelがnullだとエラーが出るためmodelを生成
            constructor: function () {
            },

            startup: function () {
                this.inherited(arguments);
            },


            initMunicipalitySelector: function () {

                if (Locator.getQuery().municipalityCd) {
                    // 概況画面から遷移した場合は、選択されていた市町村を設定する。
                    this._municipalityCd = Locator.getQuery().municipalityCd;
                    // URLパラメータに市区町村コードが指定されている場合のみ、区に値を設定する
                    this.municipalityCd.value[0] = this._municipalityCd;
                } else if (UserInfo.getUserType() === UserType.MUNICIPALITY) {
                    // 市町ユーザの場合、自分の市町を設定
                    this._municipalityCd = UserInfo.getMunicipalityCd();
                } else if (UserInfo.getUserType() === UserType.OTHER_ORGAN) {
                    // 市町ユーザの場合、自分の市町を設定
                    this._municipalityCd = UserInfo.getMunicipalityCd();
                }
                if (this._municipalityCd === config.municInfo.cityMunicCd) {
                    this._municipalityCd = '';
                }
                console.log('Init Munic : ' + this._municipalityCd);
                //市町セレクタがある場合、初期表示を設定する。
                //if (this.municipalityCd && this._municipalityCd) {
                //    this.municipalityCd.set('value', this._municipalityCd);
                //}

                // 大阪市：区役所ユーザは区の変更が不可
                if (UserInfo.getApprovalType() !== "1") {
                    this.overviewLabel.style.display = 'none';
                }
            },

            /**
             * 一覧画面に初期表示する所属のコードを取得する。
             *
             */
            initOrganizationSelector: function () {

                if (Locator.getQuery().deptCd) {
                    // 選択されていた所属コードを設定する。
                    this._deptCd = Locator.getQuery().deptCd;
                } if (UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd) {
                    // 市町ユーザの場合、自分の市町を設定
                    this._deptCd = UserInfo.getDeptCd();
                }
                console.log('Init deptCd : ' + this._deptCd);
                //市町セレクタがある場合、初期表示を設定する。
                //if (this._deptCd) {
                //    this.hldOrganization.set('value', ['D' + this._deptCd]);
                //}

            },
            /**
             * 被害状況概況一覧画面へ遷移する。（フィルタの保存が必要なので独自に定義）
             * */
            onDamageReportAdminPageLinkClick: function (evt) {
                //検索条件の保管
                this.setFilterStore();

                this._removeEvents();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('report/admin/view');
            },

            /**
             * 被害情報管理：集計表示に遷移する
             */
            onDamageReportAggregateViewPageBtnClick: function (evt) {
                //検索条件の保管
                this.setFilterStore();

                this._removeEvents();
                // ブラウザーの遷移処理をキャンセル
                evt.preventDefault();
                Router.moveTo('report/aggregate/view', { municipalityCd: this.municipalityCd.value });
            },


            /**
             * 詳細画面を表示
             */
            onDetailButtonClick: function (object) {
                //検索条件の保管
                this.setFilterStore();

                this._removeEvents();
                // 被害状況・報告詳細画面へ遷移
                Router.moveTo('report/detail/view', {
                    damageReportId: object.damageReportId
                });
            },

            /**
             * 定時報告画面を表示
             */
            showScheduledReportPage: function () {
                this._removeEvents();
            },


        });
});

