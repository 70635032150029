/**
 * 災害体制の列定義一覧。
 * @module app/disasterprevention/view/EmployGatheringColumn
 */
define([
    'idis/view/form/DateTimeInput',
    'dijit/form/ValidationTextBox',
    'dojo/when',
    'idis/view/grid/helper',
    'idis/view/LazyNode',
    'app/disasterprevention/view/model/PrefPromotionBureau',
    'app/model/Municipality'
], function(DateTimeInput, ValidationTextBox, when,
        helper, LazyNode, PrefPromotionBureau, Municipality) {
    // 参集状況管理の機能コード
    // var FUNCTION_CODE = 'F05003';
    // 編集対象列用のオプション
    var editorColumn4num = {
        sortable: false,
        className: 'gathringNum',
        editor: ValidationTextBox,
        autoSave: true,
        editorArgs: {
            regExp:'\\d{0,12}',
            invalidMessage: '5桁以内の整数を入力してください',
            style: 'width:auto;',
            maxLength: 5,
            type: 'text'
        },
        formatter: function(data){
            return data.toLocaleString();
        }
    };
    var editorColumn4text = {
        sortable: false,
        editor: ValidationTextBox,
        autoSave: true,
        editorArgs: {
            maxLength: 256,
            style: 'width:30em;'
        },
        formatter: function(data){
                return data.toLocaleString();
        }
    };
    var getMunicipalityLazyNode = function(municipalityCd){
        var itemPromise = Municipality.store.get(municipalityCd);
        return new LazyNode({
            promise: when(itemPromise).then(function(item) {
                return Municipality.getLabel(item);
            })
        });
    };
    return {
        gatherDisaster: helper.column('gatherDisaster', '合計人数', {sortable: false}),
        // member: helper.column('member', '課グループ人数', {
        //     sortable: false
        // }),
        gathringNum: helper.column('gathering', '配置人数', {
            children: [
                helper.column('empGather', '参集職員(所属)', editorColumn4num),
                helper.column('empWork', '勤務職員(所属)', editorColumn4num),
                helper.column('empOther', '参集職員(他所属)', editorColumn4num)
            ],
            sortable: false
        //     children: [
        //         helper.column('gatherDisaster', '災害', editorColumn4num),
        //         helper.column('gatherFlood', '水防', editorColumn4num)
        //     ]
        }),
        municipalityName: {
            field: 'municipalityCd',
            label: '市/行政区',
            sortable: false,
            renderCell: function(item) {
                var lazyNode = getMunicipalityLazyNode(item.municipalityCd);
                return lazyNode.domNode;
            }
        },
        reportTimestamp:  {
            field: 'reportTimestamp',
            label: '報告日時',
            renderCell: function(item) {
                var widget = new DateTimeInput({
                    required: true,
                    now: true,
                    name: 'reportTimestamp',
                    value: item.reportTimestampMS || ' '
                });
                widget._date.on('change', function() {
                    item.reportTimestampMS = widget._date.isValid()||widget._time.isValid() ?
                        Date.parse(widget._date.displayedValue + ' ' + widget._time.displayedValue) : null;
                });
                widget._time.on('change', function() {
                    item.reportTimestampMS = widget._date.isValid()||widget._time.isValid() ?
                        Date.parse(widget._date.displayedValue + ' ' + widget._time.displayedValue) : null;
                });
                widget.startup();
                return widget.domNode;
            },
            sortable: false
        },
        comment: helper.column('comment', '備考', editorColumn4text)
    };
});
