/**
 * 配信情報詳細画面用モジュール。
 * @module app/sending/SendingDertailPage
 */
define([
    'module',
    'dojo',
    'dojo/date/locale',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/_base/array',
    'dojo/dom',
    'dojo/dom-style',
    'dojo/json',
    'dojo/when',
    'dojo/Deferred',
    'dojo/on',
    'dojo/text!./templates/SendingDetailPage.html',
    'dijit/registry',
    'dijit/TooltipDialog',
    'dijit/popup',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/view/dialog/InfoDialog',
    'idis/view/Loader',
    'app/provide/ProvideUtil',
    './_SendingPageBase',
    'app/config',
    // 以下、変数として受け取らないモジュール
    'dijit/form/CheckBox',
    'dijit/form/Select',
    'dijit/form/ValidationTextBox',
    'idis/view/form/RadioGroup',
    'idis/view/form/WordCountTextarea',
    'idis/view/form/DateTimeInput',
    'app/view/form/OrganizationSelector',
    'idis/view/form/AclButton',
    './LalertDisasterPreventionDialog',
    './LalertShelterDialog',
    './LalertEvacOrderDialog',
    './SendingReConfirmDialog',
    './MunicipalitySelector'
], function(module, dojo, locale, declare, lang, array, dom, domStyle, json, when, Deferred,
        on, template, registry, TooltipDialog, popup, Locator, Router, UserInfo, Requester,
        DialogChain, InfoDialog, Loader, ProvideUtil, _SendingPageBase, config) {

    /**
     * 配信情報詳細画面。
     * @class SendingDetailPage
     * @extends module:app/sending/_SendingPageBase~_SendingPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _SendingPageBase,
            /** @lends module:app/sending/SendingDetailPage~SendingDetailPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--sending',

        _sendingId: null,

        _tweetNum: null,

        // サーバからもらう配信情報を持つ
        _sendingItem: null,

        // サーバからもらう配信先市町村の設定を持つ
        _sendingSetting: null,

        // Lアラートの詳細カテゴリーの値を持つ
        // セレクタにセットするための苦肉の策
        _lalertDetailType: null,

        constructor: function() {
            this.inherited(arguments);
            this.chain = DialogChain.get(this);
        },

        buildRendering: function() {
           this.inherited(arguments);
           // URLから配信IDを取得
           this._sendingId = Locator.getQuery().sendingId;
        },

        startup: function() {
            this.inherited(arguments);
            when(this._setMemberVariables(), lang.hitch(this, this._initPage));
        },

        /**
         * 配信情報と、配信先の設定をサーバから取得する
         * メンバ変数_sendingItemと_sendingSettingに値が代入される
         *
         * 呼ぶときは when(this._setMemberVariables(), function())
         */
        _setMemberVariables: function() {
            var deferred = new Deferred();
            Requester.get('/api/sending/' + this._sendingId, {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(lang.hitch(this, function(sendingObj) {
                if (!sendingObj) {
                    deferred.resolve(null);
                }
                this._sendingItem = sendingObj;
                Requester.get('/api/municipalities/sendingsetting/', {
                    query: {
                        municipalityCds: this._sendingItem.municipalityCd
                    }
                }).then(lang.hitch(this, function(sendingSettings) {
                    if (sendingSettings.length === 0) {
                        deferred.resolve(null);
                    }
                    this._sendingSetting = sendingSettings[0];
                    deferred.resolve({_sendingItem:this._sendingItem, sendingSetting:this._sendingSetting});
                }));
            }));
            return deferred.promise;
        },

        /**
         * 詳細画面を初期化する。
         */
        _initPage: function(result) {
            console.debug(result);
            var item = result._sendingItem;
            // ルールの整理
            // 1. 共通項目の値をセットする
            // 2. 情報種別によって分岐（画面左を埋める）
                // if (isお知らせ) {
                //   if (isタイマー設定) {
                //       1. タイマーの文字列表示
                //       2. 存在する配信先の配信状況を全て「配信待ち」にセット（他全部ハイフン）
                //       3. 緊急速報メールのチェックボックスを無効に。
                //   } else {
                //       1. Lアラート以外の配信先について、失敗以外のチェックボックスを無効に。
                //       2. 配信状況と配信日時を埋める
                //       3. 配信日時が過去且つ、県防災情報メール以外に配信待ちがある場合、未配信の文字列表示
                //   }
                //   2.1. Lアラートが取消済みの場合、チェックボックスを無効に。
                //   2.2. 配信先都道府県によりチェックボックスを無効に。
                // } else {
                //     // お知らせ以外
                //     1. 配信内容確認ボタンを無効にする（お知らせ以外は修正や新規配信ができないため）
                //     2. 配信先チェックボックス（新規配信）を全て無効に。（お知らせ以外は新規配信ができないため）
                //     3. 配信状況と配信日時を埋める
                // }
            // 3. チャネル詳細（右側）をひとつずつあれば埋める（初期表示はあるもの全て出す）（デフォで入力不可）
                // 3.1 緊急速報メール
                // 3.2 Lアラート
                    // 3.2.1 お知らせの時は本文だけ
                    // 3.2.2 お知らせ以外は内容確認ボタンを表示
                // 3.3 県防災情報メール
                // 3.4 Twitter
                // 3.5 Yahoo

            // 1. 共通項目の値をセットする
            this.sendOrganizationName.set('value', item.sendOrganizationName);
            this.sendTimestamp.set('value', item.sendTimestamp);
            this.municipalityName.set('value', item.municipalityName);
            this.subject.set('value', item.subject);
            this.bodyText.set('value', item.bodyText);
            this.senderName.set('value', item.senderName);

            // 各オブジェクトにステータスと送信日時を入れ込む（全ての配信先を同様に処理できるように）
            var emergencyMailObj = {
                exist: item.emergencyMailBody ? true : false,
                sendStatus: item.emergencyMailStatus,
                timestamp: item.emergencyMailBody ? locale.format(new Date(item.sendTimestamp)) : '-'
            };

            // lalertの配列の中にLアラート配信があれば、existをtrueにする
            var k = 0;
            for (var j=0; j<item.lalert.length; j++){
                if (item.lalert[j].sendCategory !== '05'){
                    k = j;
                }
            }
            var lalertObj = {
                exist: (item.lalertSubject !== null && item.lalert[k].sendCategory !== '05') ? true : false,
                sendStatus: item.lalertStatus,
                timestamp: (item.lalertSubject !== null &&
                            item.lalert[k].sendCategory !== '05' && item.lalert[k].sendTimestamp)?
                            locale.format(new Date(item.lalert[k].sendTimestamp)) : '-'
            };

            var prefmailObj = {
                exist: item.prefmail ? true : false,
                sendStatus: item.prefectureMailStatus,
                timestamp: ( item.prefmail && item.prefmail.sendTimestamp ) ?
                    locale.format(new Date(item.prefmail.sendTimestamp)) : '-'
            };

            var twitterObj = {
                exist: item.tweetList ? true : false,
                sendStatus: item.twitterStatus,
                timestamp: ( item.tweetList && item.tweetList[0].sendTimestamp ) ?
                    locale.format(new Date(item.tweetList[0].sendTimestamp)) : '-'
            };

            var yahooObj = {
                exist: item.yahoo ? true : false,
                sendStatus: item.yahooStatus,
                timestamp: (item.yahoo && item.yahoo.sendTimestamp ) ?
                    locale.format(new Date(item.yahoo.sendTimestamp)) : '-'
            };

            var emergencyPushObj = {
                exist: item.emergencyInfo ? true : false,
                sendStatus: item.emergencyInfoStatus,
                timestamp: (item.emergencyInfo && item.emergencyInfo.sendTimestamp ) ?
                    locale.format(new Date(item.emergencyInfo.sendTimestamp)) : '-'
            };

            var sendingDestinations = [ {name:'emergency',
                                         obj:emergencyMailObj,
                                         checkBox:this.emergencyMail,
                                         sendStatus:this.emergencyMailStatus,
                                         timestamp:this.emergencySTimestamp},
                                        {name:'lalert',
                                         obj:lalertObj,
                                         checkBox:this.lalert,
                                         sendStatus:this.lalertStatus,
                                         timestamp:this.lalertSTimestamp},
                                        {name:'prefmail',
                                         obj:prefmailObj,
                                         checkBox:this.prefectureMail,
                                         sendStatus:this.prefectureMailStatus,
                                         timestamp:this.prefectureMailSTimestamp},
                                        {name:'tweet',
                                         obj:twitterObj,
                                         checkBox:this.twitter,
                                         sendStatus:this.twitterStatus,
                                         timestamp:this.twitterSTimestamp},
                                        {name:'yahoo',
                                         obj:yahooObj,
                                         checkBox:this.yahoo,
                                         sendStatus:this.yahooStatus,
                                         timestamp:this.yahooSTimestamp},
                                        {name:'emergencyPush',
                                         obj:emergencyPushObj,
                                         checkBox:this.emergencyPush,
                                         sendStatus:this.emergencyPushStatus,
                                         timestamp:this.emergencyPushSTimestamp} ];

            // 2. 情報種別によって分岐（画面左を埋める）
            var i = 0; // ループカウンタ用
            if (item.infoType === 'お知らせ') {
                /* お知らせの場合 */
                if (new Date() < new Date(item.sendTimestamp)) {
                    /* タイマー設定の場合 */
                    //       1. タイマーの文字列表示
                    domStyle.set(this.timer, 'display', 'inline');
                    //       2. 存在する配信先の配信状況を全て「配信待ち」にセット（他全部ハイフン）
                    for (i = 0; i < sendingDestinations.length; i++) {
                        if (sendingDestinations[i].obj.exist) {
                            sendingDestinations[i].sendStatus.innerHTML = '配信待ち';
                        } else {
                            sendingDestinations[i].sendStatus.innerHTML = '-';
                            // sendingDestinations[i].checkBox.set('disabled', true);
                        }
                        // 配信待ちは再配信不可
                        sendingDestinations[i].checkBox.set('disabled', true);
                        sendingDestinations[i].timestamp.innerHTML = '-';
                    }
                    // //       3. 緊急速報メールのチェックボックスを無効に。
                    // this.emergencyMail.set('disabled', true);
                    // 配信削除ボタン表示
                    domStyle.set(this.sendhingDelete, 'display', 'inline');
                } else {
                    /* タイマー設定以外の場合 */
                    // 1. Lアラート以外の配信先について、失敗以外のチェックボックスを無効に。
                    for (i = 0; i < sendingDestinations.length; i++) {
                        // if (sendingDestinations[i].name !== 'lalert' &&
                        //         sendingDestinations[i].sendStatus !== '失敗') {
                        //     sendingDestinations[i].checkBox.set('disabled', true);
                        // }
                        // if (sendingDestinations[i].name === 'lalert' &&
                        //         sendingDestinations[i].obj.exist === false) {
                        //     sendingDestinations[i].checkBox.set('disabled', true);
                        // }
                        // 配信待ち以外は再配信可能
                        if (sendingDestinations[i].obj.exist &&
                            sendingDestinations[i].sendStatus !== '配信待ち') {
                            sendingDestinations[i].checkBox.set('disabled', false);
                        } else {
                            sendingDestinations[i].checkBox.set('disabled', true);
                        }
                        // 2. 配信状況と配信日時を埋める
                        sendingDestinations[i].sendStatus.innerHTML = sendingDestinations[i].obj.sendStatus;
                        if(sendingDestinations[i].obj.timestamp){
                            sendingDestinations[i].timestamp.innerHTML = sendingDestinations[i].obj.timestamp;
                        }

                    }

                    // 3. 配信日時が過去且つ、県防災情報メール以外に配信待ちがある場合、未配信の文字列表示
                    for (i = 0; i < sendingDestinations.length; i++) {
                        if (sendingDestinations[i].name !== 'prefmail' &&
                                sendingDestinations[i].obj.sendStatus === '配信待ち') {
                            domStyle.set(this.undistributedMessage, 'display', 'inline');
                            break;
                        }
                    }
                }
                //   2.1. Lアラートが取消済みの場合、チェックボックスを無効に。
                if (item.lalertChangeType === '03') {
                    this.lalert.set('disabled', true);
                }
                //   2.2. 配信先都道府県によりチェックボックスを無効に。
                if (!this._sendingSetting.twitterFlg) {
                    this.twitter.set('disabled', true);
                }
                if (!this._sendingSetting.yahooFlg) {
                    this.yahoo.set('disabled', true);
                }
            } else {
                /* お知らせ以外の場合 */
                //     1. 配信内容確認ボタンを無効にする（お知らせ以外は修正や新規配信ができないため）
                this.confirmButton.set('disabled', true);
                this.channelSettingButton.set('disabled', true);
                //     2. 配信先チェックボックス（新規配信）を全て無効に。（お知らせ以外は新規配信ができないため）
                for (i = 0; i < sendingDestinations.length; i++) {
                    sendingDestinations[i].checkBox.set('disabled', true);
                    //     3. 配信状況と配信日時を埋める
                    sendingDestinations[i].sendStatus.innerHTML = sendingDestinations[i].obj.sendStatus;
                    if(sendingDestinations[i].obj.timestamp){
                         sendingDestinations[i].timestamp.innerHTML = sendingDestinations[i].obj.timestamp;
                     }
                }
            }

            // 3. チャネル詳細（右側）をひとつずつあれば埋める（初期表示はあるもの全て出す）（デフォで入力不可）
            // 3.1 緊急速報メール
            if (emergencyMailObj.exist) {
                domStyle.set(this.dispEmergencyMail, 'display', 'block');
                var emergencyMailMunicipalityCd = item.emergencyMailMunicipalityCd;
                if (emergencyMailMunicipalityCd === config.municInfo.prefMunicCd) {
                     emergencyMailMunicipalityCd = config.municInfo.prePrefCd;
                }
                this.emergencyMailMunicipalityCd.set('value', emergencyMailMunicipalityCd);
                this.emergencyMailSubject.set('value', item.emergencyMailSubject);
                // this.emergencyMailBody.set('value', item.emergencyMailBody);
                this._emergencyMailNum = item.emergencyMailBodyList.length;
                array.forEach(item.emergencyMailBodyList, function(value, index){
                    this.addEmergencyMail(value, index+1);
                },this);

                var emergencyInputArea = registry.findWidgets(this.inputEmergencyMail);
                array.forEach(emergencyInputArea, function(element){
                    element.set('disabled',true);
                });

            }

            // 3.2 Lアラート
            if (lalertObj.exist) {
                domStyle.set(this.dispLalert, 'display', 'block');
                if (item.infoType === 'お知らせ') {
                    // 3.2.1 お知らせの時は本文だけ
                    domStyle.set(this.lalertBodyText, 'display', 'block');
                    this.lalertPreview.innerHTML = item.lalertBodyText;
                } else {
                    // 3.2.2 お知らせ以外は内容確認ボタンを表示
                    domStyle.set(this.dispLalertDetailButton, 'display', 'block');
                }
            }

            // 3.3 県防災情報メール
            // if (prefmailObj.exist) {
            //     domStyle.set(this.dispPrefectureMail, 'display', 'block');
            //     var prefectureMailMunicipalityCd = item.prefmail.municipalityCd;
            //     if (prefectureMailMunicipalityCd === config.municInfo.prefMunicCd) {
            //         prefectureMailMunicipalityCd = config.municInfo.prefCd;
            //     }
            //     this.prefectureMailMunicipalitySelector.set('value', prefectureMailMunicipalityCd);
            //     this.prefectureMailSubject.set('value', item.prefmail.subject);
            //     this.prefectureMailBody.set('value', item.prefmail.content);
            // }

            // 3.4 Twitter
            if (twitterObj.exist) {
                domStyle.set(this.dispTwitter, 'display', 'block');
                this.twitterPreview.innerHTML = this.getTwitterIdStr();

                this._tweetNum = item.tweetList.length;
                array.forEach(item.tweetList, function(value, index){
                    this.addTweet(value.content, index+1);
                },this);

                var inputArea = registry.findWidgets(this.inputTwitter);
                array.forEach(inputArea, function(element){
                    element.set('disabled',true);
                });
            }

            // 3.5 Yahoo
            if (yahooObj.exist) {
                domStyle.set(this.dispYahoo, 'display', 'block');
                this.yahooBody.set('value', item.yahoo.content);
            }

            // 緊急情報配信（防災アプリ）
            if (emergencyPushObj.exist) {
                domStyle.set(this.dispEmergencyPush, 'display', 'block');
                this.emergencyPushSubject.set('value', item.emergencyPushSubject);
                this.emergencyPushBody.set('value', item.emergencyPushBody);
            }

            this.onChangeLalertCategorySelect();
            // Lアラート変更理由の入力フィールドの表示/非表示切り替え制御
            this.controlLalertReasonContents();

            // Lアラートが取消済みの場合、Tipsの登録
            if (item.lalertChangeType === '03') {
                this._setDeletedLalertTips();
            }
        },

        /**
         * Lアラートが取消済みの場合に更新等が行えない旨を表示するTipsの登録
         */
        _setDeletedLalertTips: function() {
            // ラベルの横に？マークを表示
            var lalertLabel = dom.byId('lalertLabel');
            lalertLabel.classList.add('has-tips');

            var deletedLalertTips = new TooltipDialog({
                id: 'deletedLalertTips',
                style: 'width: 300px;',
                content: '<p>Lアラート情報が既に取消済であるため、更新・訂正・削除ができません。</p>'
            });
            this.own(deletedLalertTips);
            on(dom.byId('lalertLabel'), 'mouseover', function(){
                popup.open({
                    popup: deletedLalertTips,
                    around: dom.byId('lalertLabel')
                });
            });
            on(dom.byId('lalertLabel'), 'mouseleave', function(){
                popup.close(deletedLalertTips);
            });
        },

        /**
         * チャネル別詳細設定を表示
         */
        showChannelDetails: function() {
            // ルールの整理
            // 1. 配信先チェックボックスで選択されているものを取得
            // 2. チャネル詳細（左側）の必要なものをひとつずつ出す
                // お知らせ以外では修正や新規配信は出来ない
                // 2.1 Lアラート
                    // 2.1.1 再配信時の時追加で表示
                    // if (is再配信) {
                    //    1. 変更タイプラジオボタン表示
                    //    2. 変更理由テキストエリア表示
                    // }
                // 2.2 県防災情報メール
                // 2.3 Twitter
                // 2.4 Yahoo

            var isChecked = false;

            // {checked, display}
            var objs = [
                {checked: this.lalert.checked,
                 display: this.dispLalert},
                {checked: this.emergencyMail.checked,
                 display: this.dispEmergencyMail},
                // {checked: this.prefectureMail.checked,
                //  display: this.dispPrefectureMail},
                {checked: this.twitter.checked,
                 display: this.dispTwitter},
                {checked: this.yahoo.checked,
                 display: this.dispYahoo},
                {checked: this.emergencyPush.checked,
                 display: this.dispEmergencyPush}];

            for (var i = 0; i < objs.length; i++) {
                var inputArea = registry.findWidgets(objs[i].display);
                if (objs[i].checked) {
                    array.forEach(inputArea, function(element){
                        element.set('disabled',false);
                    });
                    isChecked = true;
                } else {
                    array.forEach(inputArea, function(element){
                        element.set('disabled',true);
                    });
                }
            }

            // チェックが付いていなければガイドを表示
            if (!isChecked){
                domStyle.set(this.channelSelectWarning, 'display', 'block');
            }else{
                domStyle.set(this.channelSelectWarning, 'display', 'none');
            }

            //Lアラート入力欄
            if (this.lalert.checked){
                domStyle.set(this.lalertBodyText, 'display', 'none');
                domStyle.set(this.dispLalert, 'display', 'block');
                domStyle.set(this.inputLalert, 'display', 'block');

                if (this._sendingItem.lalert.length !== 0) {
                    // 新規以外
                    domStyle.set(this.dispLalertChangeType, 'display', 'block');
                    // 登録されている内容でプリセット
                    // 発行元
                    this.publisher.set('value', this._sendingItem.publisher);
                    // // 緊急度
                    // if (this._sendingItem.lalertUrgency === '01') {
                    //     this.lalertUrgency.set('value', 1);
                    // } else {
                    //     this.lalertUrgency.set('value', 2);
                    // }
                    // 情報識別区分
                    this.lalertUrgency.set('value', this._sendingItem.lalertUrgency);

                    // カテゴリー
                    var _lalertCategory = parseInt(this._sendingItem.lalertCategory, 10);
                    this.lalertCategory.set('value', Math.floor(_lalertCategory / 10));
                    this.onChangeLalertCategorySelect();
                    this._lalertDetailType = _lalertCategory;
                    // 件名
                    this.lalertSubject.set('value', this._sendingItem.lalertSubject);
                    // 本文
                    this.lalertInputBodyText.set('value', this._sendingItem.lalertBodyText);
                }
            }else{
                if (this._sendingItem.lalert.length === 0) {
                    // もともとLアラートがない場合
                    domStyle.set(this.dispLalert, 'display', 'none');
                } else {
                    domStyle.set(this.inputLalert, 'display', 'none');
                    domStyle.set(this.lalertBodyText, 'display', 'block');
                }
            }

            // 緊急速報メール
            if (this.emergencyMail.checked) {
                domStyle.set(this.dispEmergencyMail, 'display', 'block');
            } else {
                if (this._sendingItem.emergencyMail) {
                    // もともと緊急速報メールが無い場合
                    domStyle.set(this.dispEmergencyMail, 'display', 'none');
                }
            }

            // 2.2 県防災情報メール
            // if (this.prefectureMail.checked) {
            //     domStyle.set(this.dispPrefectureMail, 'display', 'block');
            // } else {
            //     if (!this._sendingItem.prefmail) {
            //         // もともと県防災情報メールが無い場合
            //         domStyle.set(this.dispPrefectureMail, 'display', 'none');
            //     }
            // }

            // 2.3 Twitter
            if (this.twitter.checked) {
                domStyle.set(this.dispTwitter, 'display', 'block');
                if (!this._sendingItem.tweetList) {
                    // 新規
                    // tweetを全て削除
                    var element = dojo.byId('twitterOuterFrame');
                    while (element.firstChild) {
                        element.removeChild(element.firstChild);
                    }
                    this.addTweet('', 1);
                }
            } else {
                if (!this._sendingItem.tweetList) {
                    // もともとTwitterが無い場合
                    domStyle.set(this.dispTwitter, 'display', 'none');
                }
            }

            // 2.4 Yahoo
            if (this.yahoo.checked) {
                domStyle.set(this.dispYahoo, 'display', 'block');
            } else {
                if (!this._sendingItem.yahoo) {
                    // もともとYahooが無い場合
                    domStyle.set(this.dispYahoo, 'display', 'none');
                }
            }

            // 緊急情報配信（防災アプリ）
            if (this.emergencyPush.checked) {
                domStyle.set(this.dispEmergencyPush, 'display', 'block');
            } else {
                if (!this._sendingItem.emergencyInfo) {
                    // もともと緊急情報配信（防災アプリ）が無い場合
                    domStyle.set(this.dispEmergencyPush, 'display', 'none');
                }
            }

        },

        addTweet: function(oneTweetText, l){
            // +ボタンで追加した場合
            if (oneTweetText.type === 'click'){
              oneTweetText = '';
              this._tweetNum += 1;
              // 最初ツイート以外に「続き」を入れる
              if (l !== 1){
                oneTweetText = '(続き)' + oneTweetText;
              }
            }
            this.createTweetElements(oneTweetText, l);
        },

        /**
         * Lアラート変更理由の表示/非表示切り替え制御する。
         */
        controlLalertReasonContents: function() {
            // WordCountTextareaがValidationTextBoxを継承しているのでform.validate()よけ
            this.lalertReason.set('value', 'decoy');

            this.lalertChangeType.on('change', lang.hitch(this, function(e){
                if(e.target.value === '0') {
                    domStyle.set(this.lalertReasonArea, 'display', 'none');
                    // WordCountTextareaがValidationTextBoxを継承しているのでform.validate()よけ
                    this.lalertReason.set('value', 'decoy');
                } else {
                    domStyle.set(this.lalertReasonArea, 'display', 'block');
                    this.lalertReason.set('value', '');
                }
            }));
        },

        /**
         * 配信情報一覧画面へ遷移する。
         */
        onSendingPageLinkClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            Router.moveTo('sending');
        },

        /**
         * Lアラート配信内容確認（お知らせ以外）
         */
        showLalertDetail: function(){
            var page = null;
            switch (this._sendingItem.infoType) {
                case '避難情報': // 20
                    this.lalertEvacOrderDialog.show();
                    page = this.lalertEvacOrderDialog.getChildren()[0];
                    page.initDialog(this._sendingItem.lalert[0]);
                    break;
                case '避難所情報': // 21
                    this.lalertShelterDialog.show();
                    page = this.lalertShelterDialog.getChildren()[0];
                    page.initDialog(this._sendingItem.lalert[0]);
                    break;
                case '本部設置情報': // 22「体制」
                    this.lalertDisasterPreventionDialog.show();
                    page = this.lalertDisasterPreventionDialog.getChildren()[0];
                    page.initDialog(this._sendingItem.lalert[0]);
                    break;
            }
        },

        /**
         * 配信情報を再送する。
         */
        _update: function(){
            console.debug('[再送]ボタンがクリックされました。');
            //var sendData = this.form.get('value');
            var sendData = {};

            /* 共通部分 */
            sendData.sendingId = this._sendingItem.sendingId;
            sendData.disasterId = this._sendingItem.disasterId;
            sendData.infoType = this._sendingItem.infoType;
            sendData.deptCd = this._sendingItem.deptCd;
            sendData.sectCd = this._sendingItem.sectCd;
            sendData.unitCd = this._sendingItem.unitCd;
            sendData.sendOrganizationName = this._sendingItem.sendOrganizationName;
            sendData.sendTimestamp = new Date();
            var municipalityCd = this._sendingItem.municipalityCd;
            sendData.municipalityCd = this._convertPrefectureCd(municipalityCd);
            if ( this._sendingItem.subject.slice(0, 4) !== '（再送）') {
                sendData.subject = '（再送）' + this._sendingItem.subject;
                sendData.bodyText = '（再送）' + this._sendingItem.bodyText;
            } else {
                sendData.subject = this._sendingItem.subject;
                sendData.bodyText = this._sendingItem.bodyText;
            }
            sendData.senderName = this._sendingItem.senderName;

            // サーバからのデータにlalertSendHistIdがあったらそのまま送り返す
            if (this._sendingItem.lalertSendHistId) {
                sendData.lalertSendHistId = this._sendingItem.lalertSendHistId;
            } else {
                sendData.lalertSendHistId = null;
            }

            /* チェックされているもののみ */
            // 緊急速報メール
            if (this.emergencyMail.checked) {
                sendData.urgentMailFlg = '1';
                var emergencyMailMunicipalityCd = this.emergencyMailMunicipalityCd.get('value');
                sendData.emergencyMailMunicipalityCd = this._convertPrefectureCd(emergencyMailMunicipalityCd);
                sendData.emergencyMailSubject = this.emergencyMailSubject.get('value');
                // sendData.emergencyMailBody = this.emergencyMailBody.get('value');
                var emergencyMailData = this.emergencyMailOuterFrame.getElementsByTagName('textarea');
                var emergencyMailList = [];
                array.forEach (emergencyMailData, function(data) {
                    emergencyMailList.push(data.value);
                });
                sendData.emergencyMailBodyList = emergencyMailList;

                var emergencyMailLalertHistIdData = this.emergencyMailOuterFrame.getElementsByTagName('input');
                var emergencyMailLalertHistIdList = [];
                array.forEach (emergencyMailLalertHistIdData, function(data) {
                    emergencyMailLalertHistIdList.push(data.value);
                });
                sendData.emergencyMailLalertHistIdList = emergencyMailLalertHistIdList;
            } else {
                sendData.urgentMailFlg = '0';
            }

            // Lアラート
            if (this.lalert.checked) {
                sendData.lalertFlg = '1';
                sendData.publisher = config.municInfo.cityMunicCd;
                sendData.sendCategory = '04'; // '04'「お知らせ」決め打ち
                sendData.lalertUrgency = this.lalertUrgency.get('value');
                sendData.lalertCategory = this.lalertCategory.get('value');
                sendData.sendingDetailType = this.sendingDetailType.get('value');

                // 変更タイプと訂正・取消理由は、新規配信の場合とそれ以外の場合とで分岐
                if (this._sendingItem.lalert.length === 0) {
                    // 新規
                    sendData.lalertChangeType = '01';
                    sendData.lalertReason = null;
                } else {
                    // 新規以外
                  var _lalertChangeType = this.lalertChangeType.get('value');
                    switch (_lalertChangeType) {
                        case '0': // 更新
                        case '1': sendData.lalertChangeType = '02'; break; // 訂正
                        case '2': sendData.lalertChangeType = '03'; break; // 取消
                    }
                    sendData.lalertReason = this.lalertReason.get('value');
                }

                sendData.lalertSubject = this.lalertSubject.get('value');
                sendData.lalertBodyText = this.lalertInputBodyText.get('value');
            } else {
                sendData.lalertFlg = '0';
            }

            // 県防災情報メール
            if (this.prefectureMail.checked) {
                sendData.prefectureMailFlg = '1';
                var prefectureMailMunicipalityCd = this.prefectureMailMunicipalitySelector.get('value');
                sendData.preMailMunicipalityCd = this._convertPrefectureCd(prefectureMailMunicipalityCd);
                sendData.prefectureMailSubject = this.prefectureMailSubject.get('value');
                sendData.prefectureMailBody = this.prefectureMailBody.get('value');
            } else {
                sendData.prefectureMailFlg = '0';
            }

            // Twitter
            if (this.twitter.checked) {
                sendData.twitterFlg = '1';
                var datas = this.twitterOuterFrame.getElementsByTagName('textarea');
                var tweetList = [];
                array.forEach (datas, function(d){
                     tweetList.push(d.value);
                });
                sendData.tweetList = tweetList;
            } else {
                sendData.twitterFlg = '0';
            }

            // Yahoo
            if (this.yahoo.checked) {
                sendData.yahooFlg = '1';
                sendData.yahooBody = this.yahooBody.get('value');
            } else {
                sendData.yahooFlg = '0';
            }

            //  緊急情報配信（防災アプリ）
            if (this.emergencyPush.checked) {
                sendData.emergencyPushFlg = '1';
                sendData.emergencyPushBody = this.emergencyPushBody.get('value');
                sendData.emergencyPushSubject = this.emergencyPushSubject.get('value');
            } else {
                sendData.emergencyPushFlg = '0';
            }

            console.dir(sendData);
            var jsonStr = json.stringify(sendData);

            // 最終確認を行う際のメッセージ文字列
            var lastConfirmMsg = '';
            lastConfirmMsg += this.emergencyMail.checked ? '・緊急速報メール' : '';
            lastConfirmMsg += this.lalert.checked ? '・Lアラート' : '';
            lastConfirmMsg += this.prefectureMail.checked ? '・県防災情報メール' : '';
            lastConfirmMsg += this.twitter.checked ? '・Twitter' : '';
            lastConfirmMsg += this.yahoo.checked ? '・防災アプリ(Yahoo)' : '';
            lastConfirmMsg += this.emergencyPush.checked ? '・緊急情報配信（防災アプリ）' : '';
            lastConfirmMsg += 'に連携します。<br>よろしいですか？';
            lastConfirmMsg = lastConfirmMsg.slice(1);
            lastConfirmMsg = '<div style="text-align:center;">' + lastConfirmMsg + '</div>';

            return this.chain.confirm(lastConfirmMsg, function(chain) {
                var promise = Requester.put('/api/sending/' + this._sendingItem.sendingId, {
                    data: jsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function(data) {
                    console.debug('success register Sending');
                    console.debug(data);
                    chain.infoComplete(function() {
                        this.confirmDialog.hide();
                        // 一覧画面に移動
                        Router.moveTo('sending');
                    });
                }), lang.hitch(this, function(error) {
                    console.log('error register damagereport');
                    chain.infoError(error);
                    this.confirmDialog.hide();
                }));
                //ローダーの表示
                Loader.wait(promise);
            });
        },

        /**
         * 未定義か地域コードの場合はメッセージを表示してtrueを返す
         */
        _invalidMunicipalityCd: function(municipalityCd, targetName) {
            if(!municipalityCd){
                if (targetName) {
                    InfoDialog.show('', targetName + 'の市/行政区を選択してください。');
                } else {
                    InfoDialog.show('', '市/行政区を選択してください。');
                }
                return true;
            }
            if (municipalityCd !== config.municInfo.prefCd &&
                    municipalityCd.slice(0, 4) === config.municInfo.prePrefCd + '00') {
                // 県はxx0000
                // 地域はxx00xx
                if (targetName) {
                    InfoDialog.show('', targetName + 'では地域は選択できません。');
                } else {
                    InfoDialog.show('', '地域は選択できません。');
                }
                return true;
            }
            return false;
        },

        /*
         * 県のコードを変換する。
         *
         * ManageRegionMunicipalitySelectorは県のコードとしてAreaCode.propertiesの
         * PREF_FULL_CODEの値を使用しているが、配信情報が登録される
         * T_SENDING_HEADERテーブルのMUNICIPALITY_CD列はM_MUNICIPALITYテーブルの
         * MUNICIPALITY_CD列との間に参照整合性制約があるため、県は市町村コードでなければ登録できない。
         */
        _convertPrefectureCd: function(municipalityCd) {
            if (municipalityCd === config.municInfo.prefCd) {
                return config.municInfo.prefMunicCd;
            }
            return municipalityCd;
        },

        /**
         * 配信確認ダイアログを表示する
         */
        showConfirmDialog: function() {
            // 配信先を選択しているかチェック
            if (!(this.emergencyMail.checked ||
                  this.lalert.checked ||
                  this.prefectureMail.checked ||
                  this.twitter.checked ||
                  this.yahoo.checked ||
                  this.emergencyPush.checked)) {
                        InfoDialog.show('', '配信先チャンネルを選択してください。');
                return;
            }

            // 緊急速報メールがチェックされているとき
            if (this.emergencyMail.checked) {
                if (this._invalidMunicipalityCd(this.emergencyMailMunicipalityCd.value, '緊急速報メール')) {
                    return;
                }
                if (!ProvideUtil.urgentMailValidate(this.emergencyMailSubject.value)) {
                    InfoDialog.show('', '緊急速報メールの件名に電話番号・URL・メールアドレスが含まれています。');
                    return;
                }
/*                if (!ProvideUtil.urgentMailValidate(this.emergencyMailBody.value)) {
                    InfoDialog.show('', '緊急速報メールの本文に電話番号・URL・メールアドレスが含まれています。');
                    return;
                }*/
                var emergencyMailElement = dojo.byId('emergencyMailOuterFrame');
                var emergencyMailElements = emergencyMailElement.getElementsByTagName('textarea');
                for(var j=0; j < emergencyMailElements.length; j++) {
                    if (!ProvideUtil.urgentMailValidate(emergencyMailElements[j].value)) {
                        InfoDialog.show('', '緊急速報メールの'+ (j+1) + '番目の本文に電話番号・URL・メールアドレスが含まれています。');
                        return;
                    }
                    if (emergencyMailElements[j].value === '') {
                        InfoDialog.show('', '緊急速報メールの'+ (j+1) + '番目の本文が入力されていません');
                        return;
                    }
                }
            }

            // // Lアラート
            // if (this.lalert.checked && !this.publisher.value) {
            //     InfoDialog.show('', '発行元を選択してください。');
            //     return;
            // }
            // 県防災メール
            if (this.prefectureMail.checked) {
                if (this._invalidMunicipalityCd(this.prefectureMailMunicipalitySelector.value, '県防災情報メール')) {
                    return;
                }
            }

            // 緊急情報配信（防災アプリ）
            if (this.emergencyPush.checked) {
                if (this.emergencyPushSubject.value === '') {
                    InfoDialog.show('入力チェック', '緊急情報配信（防災アプリ）の件名が入力されていません');
                    return;
                }
                if (this.emergencyPushBody.value === '') {
                    InfoDialog.show('入力チェック', '緊急情報配信（防災アプリ）の本文が入力されていません');
                    return;
                }
            }

            //入力チェック
            if(this.form.validate()) {
                // プレビューダイアログを表示
                this.confirmDialog.show();
                // ダイアログ中のページウィジェットを取得
                var page = this.confirmDialog.getChildren()[0];
                // ダイアログ側でOKボタンが押された際のイベント
                page.on('update', lang.hitch(this, function() {
                    // 再配信
                    return this._update();
                }));
                // ダイアログの画面を再表示する
                page.refresh(this);
            }
        },

        /**
         * 配信情報を削除する。
         */
        onDelete: function(){
            this.chain.confirmDel(lang.hitch(this, function(chain) {
                var promise = Requester.del('/api/sending/' + this._sendingItem.sendingId, {
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function() {
                    chain.infoComplete(function() {
                        // this.confirmDialog.hide();
                        // 一覧画面に移動
                        Router.moveTo('sending');
                    });
                }), lang.hitch(this, function(error) {
                    console.log('error delete Sending');
                    chain.infoError(error);
                    console.log(error);
                }));
                //ローダーの表示
                Loader.wait(promise);
            }));
        },

        /**
         * メンバ変数_sendingSettingからtwitterIdとして表示する文字列を返す
         */
        getTwitterIdStr: function() {
            return this._sendingSetting.municipalityName + ' @' + this._sendingSetting.twitterId;
        },

        /**
         * 緊急速報メールを追加する
         */
        addEmergencyMail: function(oneEmergencyMailText, i) {
            // +ボタンで追加した場合
            if (oneEmergencyMailText.type === 'click') {
                oneEmergencyMailText = '';
                this._emergencyMailNum += 1;
                i = this._emergencyMailNum;
            }
            this.createEmergencyMailElements(oneEmergencyMailText, i);

            // 緊急速報メールを分割できるのは3つまでとする
            if(i === this.EMERGENCY_MAIL_LIMIT) {
                domStyle.set(this.addEmergencyMailButton.domNode, 'display', 'none');
            }
        }
    });
});
