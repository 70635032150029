/**
 * 凡例一覧。
 * 各凡例グループをcode, name, listを持つオブジェクトとして実際の表示順に配列形式で記載する。
 * path, code はいずれか1つのみを持つ。
 * path: ツリー上の表示位置、文字列か正規表現で指定。ディレクトリー名の間は'/'で区切る。
 * code: 情報種別コード。表示中のレイヤーに当該種別のものがあれば、その凡例グループを表示する。
 * name: 凡例グループ名。path指定時は省略可能。pathが正規表現の場合、マッチ結果をnameとして用いる。
 *       その場合、マッチ結果にグループ指定がある場合は先頭グループ、無ければマッチ結果全体を用いる。
 *       例： /市（避難経路）$/ → '避難経路', /避難経路$/ → '避難経路',
 * list: 凡例グループに紐付く凡例の一覧
 *
 * 各凡例は以下の属性を持つ。
 * src, line, area は表示形式によりいずれか1つのみを持つ。
 * name: 凡例名
 * src: アイコンのURL（画像の場合）
 * line: 表示色（線の場合）
 * area: 表示色（領域の場合）
 *
 * @module app/map/legend/LEGEND_LIST
 */
define([{
    path: /^事前情報\/（土砂災害警戒区域）/,
    list: [
        { name: '特別警戒区域（土石流）', src: '/images/sediment/sedimDisasWarn_1.png' },
        { name: '特別警戒区域（土石流_調査済指定前）', src: '/images/sediment/sedimDisasWarn_2.gif' },
        { name: '警戒区域（土石流）', src: '/images/sediment/sedimDisasWarn_3.png' },
        { name: '警戒区域（土石流_調査済指定前）', src: '/images/sediment/sedimDisasWarn_4.gif' },
        { name: '特別警戒区域（急傾斜）', src: '/images/sediment/sedimDisasWarn_5.png' },
        { name: '特別警戒区域（急傾斜_調査済指定前）', src: '/images/sediment/sedimDisasWarn_6.gif' },
        { name: '警戒区域（急傾斜）', src: '/images/sediment/sedimDisasWarn_7.png' },
        { name: '警戒区域（急傾斜_調査済指定前）', src: '/images/sediment/sedimDisasWarn_8.gif' },
        { name: '警戒区域（地滑り）', src: '/images/sediment/sedimDisasWarn_9.png' }
    ]
}, {
    path: /^事前情報\/（公共施設）/,
    list: [
        { name: '道土整備事務所', src: '/images/facility/3303.gif' },
        { name: '病院', src: '/images/facility/3332.gif' },
        { name: '都道府県の機関', src: '/images/facility/3303.gif' },
        { name: '市役所・役場', src: '/images/facility/3387.gif' },
        { name: '市/行政区の機関', src: '/images/facility/3319.gif' },
        { name: '公企体及び独法東北', src: '/images/draw/icon/001.png' },
        { name: '消防署', src: '/images/facility/3316.gif' },
        { name: '警察署', src: '/images/facility/3314.gif' },
        { name: '小学校', src: '/images/facility/3354.gif' },
        { name: '中学校', src: '/images/facility/3355.gif' },
        { name: '高等学校', src: '/images/facility/3356.gif' },
        { name: '大学校', src: '/images/facility/3360.gif' },
        { name: '鉄道', area: '#0000FF' },
        { name: '駅', src: '/images/draw/icon/020.png' }
    ]
}, /* {
    path: /^事前情報\/（道路）/,
    list: [
        { name: '冬期閉鎖区間', line: '#32CD32'},
        { name: '事前通行規制区間', line: '#32CD32'},
        { name: '特殊通行規制区間', line: '#32CD32'},
        { name: '通行不能区間', line: '#000000'},
        { name: '異常気象時通行規制区間', line: '#32CD32'},
        { name: '緊急輸送路', line: '#FF8C00'},
        { name: '主要地方道', line: '#008000'},
        { name: '一般県道', line: '#008000'},
        { name: '直轄国道', line: '#008000'},
        { name: '補助国道', line: '#008000'},
        { name: '高速道路', line: '#008000'},
        { name: 'IC・JCT', src: '/images/draw/icon/085.png'},
        { name: '有料道路', line: '#008000'},
        { name: '広域農道', line: '#008000'},
        { name: '林道', line: '#008000'},
        { name: 'キロポスト', src: '/images/draw/icon/077.png'},
        { name: '道路情報板', src: '/images/draw/icon/104.png'}
    ]
}, */ {
    path: /^事前情報\/（浸水想定区域）\/（津波浸水想定区域|高潮浸水想定区域）/,
    name: '津波・高潮浸水想定区域',
    list: [
        { name: '浸水深 0.01ｍ以上~0.3m未満', area: '#69FD9B' },
        { name: '浸水深 0.3m以上～1.0ｍ未満', area: '#FFF969' },
        { name: '浸水深 1m以上～2ｍ未満', area: '#FEC082' },
        { name: '浸水深 2m以上～3ｍ未満', area: '#FEE7CE' },
        { name: '浸水深 3m以上～4ｍ未満', area: '#FECEE5' },
        { name: '浸水深 4m以上～5ｍ未満', area: '#FC83BD' },
        { name: '浸水深 5m以上～10ｍ未満', area: '#FB5551' },
        { name: '浸水深 10m以上～20ｍ未満', area: '#C651FB' }
    ]
}, {
    path: /^事前情報\/（浸水想定区域）\/（洪水浸水想定区域）/,
    name: '洪水浸水想定区域',
    list: [
        { name: '浸水深 0.5m未満', area: '#F0F78D' },
        { name: '浸水深 0.5m以上～3.0ｍ未満', area: '#FFD29C' },
        { name: '浸水深 3m以上～5ｍ未満', area: '#FCAB90' },
        { name: '浸水深 5m以上～10ｍ未満', area: '#F08460' },
        { name: '浸水深 10m以上～20ｍ未満', area: '#FF80DF' }
    ]
}, {
    // 避難場所
    name: '避難場所',
    code: 'M005',
    list: [
        { name: '避難所', src: '/images/facility/1107.png' }
    ]
}, {
    path: /^事前情報\/（行政界）/,
    list: [
        { name: '道境界', line: '#0000FF' },
        { name: '事務所境界', line: '#0000FF' },
        { name: '市/行政区境界', line: '#0000FF' }
    ]
}, /* {
    // 雨量観測情報
    name: '10分雨量',
    code: 'O001',
    list: [
        { name: '30mm以上', src: '/images/observation/u06.png' },
        { name: '20mm以上', src: '/images/observation/u05.png' },
        { name: '10mm以上', src: '/images/observation/u04.png' },
        { name: '5mm以上', src: '/images/observation/u03.png' },
        { name: '1mm以上', src: '/images/observation/u01.png' },
        { name: '無降雨', src: '/images/observation/u00.png' },
        { name: '欠測、閉局等', src: '/images/observation/u80_e0.png' },
        { name: '時間観測局等', src: '/images/observation/u80_h1.png' }
        
        // { name: '80mm以上', src: '/images/observation/u06.gif'},
        // { name: '50mm以上', src: '/images/observation/u10.png'},
        // { name: '30mm以上', src: '/images/observation/u04.gif'},
        // { name: '20mm以上', src: '/images/observation/u09.png'},
        // { name: '10mm以上', src: '/images/observation/u08.png'},
        // { name: '1mm以上', src:'/images/observation/u07.png'},
        // { name: '0mm', src:'/images/observation/u11.png'},
        // { name: '無降雨', src: '/images/observation/u00.gif'},
        // { name: '欠測、閉局等', src: '/images/observation/u80_e1.png'}
        // { name: '時間観測局等', src: '/images/observation/u80_h1.gif'}
    ]
}, {
    // 雨量観測情報
    name: '60分雨量',
    code: 'O011',
    list: [
        { name: '80mm以上', src: '/images/observation/u06.png' },
        { name: '50mm以上', src: '/images/observation/u05.png' },
        { name: '30mm以上', src: '/images/observation/u04.png' },
        { name: '20mm以上', src: '/images/observation/u03.png' },
        { name: '10mm以上', src: '/images/observation/u02.png' },
        { name: '1mm以上', src: '/images/observation/u01.png' },
        { name: '無降雨', src: '/images/observation/u00.png' },
        { name: '欠測、閉局等', src: '/images/observation/u80_e0.png' },
        { name: '時間観測局等', src: '/images/observation/u80_h1.png' }
        
        // { name: '80mm以上', src: '/images/observation/u06.gif'},
        // { name: '50mm以上', src: '/images/observation/u10.png'},
        // { name: '30mm以上', src: '/images/observation/u04.gif'},
        // { name: '20mm以上', src: '/images/observation/u09.png'},
        // { name: '10mm以上', src: '/images/observation/u08.png'},
        // { name: '1mm以上', src:'/images/observation/u07.png'},
        // { name: '0mm', src:'/images/observation/u11.png'},
        // { name: '無降雨', src: '/images/observation/u00.gif'},
        // { name: '欠測、閉局等', src: '/images/observation/u80_e1.png'}
        // { name: '時間観測局等', src: '/images/observation/u80_h1.gif'}
    ]
}, {
    // 雨量観測情報
    name: '累計雨量',
    code: 'O012',
    list: [
        { name: '250mm以上', src: '/images/observation/u06.png' },
        { name: '200mm以上', src: '/images/observation/u05.png' },
        { name: '150mm以上', src: '/images/observation/u04.png' },
        { name: '100mm以上', src: '/images/observation/u03.png' },
        { name: '50mm以上', src: '/images/observation/u02.png' },
        { name: '1mm以上', src: '/images/observation/u01.png' },
        { name: '無降雨', src: '/images/observation/u00.png' },
        { name: '欠測、閉局等', src: '/images/observation/u80_e0.png' },
        { name: '時間観測局等', src: '/images/observation/u80_h1.png' }
        
        // { name: '150mm以上', src: '/images/observation/u10.png'},
        // { name: '80mm以上', src: '/images/observation/u09.png'},
        // { name: '50mm以上', src: '/images/observation/u08.png'},
        // { name: '30mm以上', src: '/images/observation/u04.gif'},
        // { name: '20mm以上', src: '/images/observation/u09.gif'},
        // { name: '10mm以上', src: '/images/observation/u08.gif'},
        // { name: '1mm以上', src:'/images/observation/u07.png'},
        // { name: '0mm', src:'/images/observation/u11.png'},
        // { name: '無降雨', src: '/images/observation/u00.gif'},
        // { name: '欠測、閉局等', src: '/images/observation/u80_e1.png'}
        // { name: '時間観測局等', src: '/images/observation/u80_h1.gif'}
    ]
}, */ {
    // 水位観測情報
    name: '水位',
    code: 'O002',
    list: [
        { name: '警戒レベル5発令水位超過', src: '/images/observation/sLv04.png' },
        { name: '警戒レベル4発令水位超過', src: '/images/observation/sLv03.png' },
        { name: '警戒レベル3発令準備水位超過', src: '/images/observation/sLv02.png' },
        { name: '氾濫注意水位超過', src: '/images/observation/sLv01.png' },
        { name: '通常水位', src: '/images/observation/s00.png' },
        { name: '欠測/閉局等', src: '/images/observation/s80_e0.gif' }
        
        // { name: '氾濫危険水位以上', src: '/images/observation/sLv09.png'},
        // { name: '避難判断水位以上', src: '/images/observation/sLv08.png'},
        // { name: '氾濫注意水位以上', src: '/images/observation/sLv07.png'},
        // { name: '水防団待機水位以上', src: '/images/observation/sLv06.png'},
        // { name: '水防団待機水位未満', src: '/images/observation/sLv05.png'},
        // { name: '未設定局', src: '/images/observation/sLv10.png'},
    ]
}, /* {
    // 危機管理水位観測情報
    name: '危機管理型水位計',
    code: 'O002',
    list: [
        { name: '警戒レベル5発令水超過', src: 'images/observation/crLv03.png' },
        { name: '警戒レベル4発令水位超過', src: 'images/observation/crLv02.png' },
        { name: '警戒レベル3発令準備水位超過', src: 'images/observation/crLv01.png' },
        { name: '通常水位', src: 'images/observation/crLv00.png' },
        { name: '欠測/閉局等', src: 'images/observation/crLvN.png' }
    ]
}, {
    // 潮位観測情報
    name: '潮位',
    code: 'O003',
    list: [
        { name: '警戒潮位以上', src: '/images/observation/choi03.png' },
        { name: '通報潮位以上', src: '/images/observation/choi04.png' },
        { name: '警戒潮位未満', src: '/images/observation/choi05.png' },
        { name: '欠測、閉局等', src: '/images/observation/choi06.png' }
    ]
}, {
    // 潮位観測情報
    name: 'ダム',
    code: 'O004',
    list: [
        { name: '洪水調節中', src: '/images/observation/dam_red.png' },
        { name: '洪水調節中以外（利水貯水率:50%以上）', src: '/images/observation/dam_green.png' },
        { name: '洪水調節中以外（利水貯水率:50%未満）', src: '/images/observation/dam_brown.png' },
        { name: '欠測、閉局等', src: '/images/observation/dam_white.png' }
    ]
}, */ {
    // 河川カメラ
    name: '河川カメラ',
    code: 'M008',
    list: [
        { name: 'カメラ', src: '/images/photo.png' }
    ]
}, /* {
    // 道路通行規制
    name: '道路通行規制',
    code: 'D101',
    list: [
        { name: '全面通行止め（現在）', src: '/images/traffic/current_1.png' },
        { name: '車両通行止め（現在）', src: '/images/traffic/current_2.png' },
        { name: '片側交互通行（現在）', src: '/images/traffic/current_4.png' },
        { name: 'チェーン規制（現在）', src: '/images/traffic/current_6.png' },
        { name: 'その他（現在）', src: '/images/traffic/current_9.png' },
        { name: '全面通行止め（今後）', src: '/images/traffic/future_1.png' },
        { name: '車両通行止め（今後）', src: '/images/traffic/future_2.png' },
        { name: '片側交互通行（今後）', src: '/images/traffic/future_4.png' },
        { name: 'チェーン規制（今後）', src: '/images/traffic/future_6.png' },
        { name: 'その他（今後）', src: '/images/traffic/future_9.png' }
    ]
}, {
    // 大阪市道路通行規制情報
    name: '大阪市道路通行規制情報',
    code: 'R007',
    list: [
        { name: '全面通行禁止', src: '/images/draw/icon/traffic/mapinfo_tukodome.gif' },
        { name: '片側通行禁止', src: '/images/draw/icon/traffic/mapinfo_katagawakougo.gif' },
        { name: '大型車通行止め', src: '/images/draw/icon/traffic/mapinfo_oogatatukodome.gif' },
        { name: '車線・路肩規制', src: '/images/draw/icon/traffic/mapinfo_syasenkisei.gif' },
        { name: '歩道規制', src: '/images/draw/icon/traffic/mapinfo_hodoukisei.gif' }
    ]
}, */ {
    // 気象警報・注意報
    name: '気象警報・注意報',
    code: 'D102',
    list: [
        { name: '特別警報', area: '#0C000C' },
        { name: '警報', area: '#FF2800' },
        { name: '注意報', area: '#FAF500' }
    ]
}, {
    // 高解像度降水ナウキャスト
    name: '高解像度降水ナウキャスト/ 降水短時間予報',
    code: 'O014',
    list: [
        { name: '～1mm/h', area: '#F2F2FF' },
        { name: '～5mm/h', area: '#A0D2FF' },
        { name: '～10mm/h', area: '#1C8CFF' },
        { name: '～20mm/h', area: '#0035FF' },
        { name: '～30mm/h', area: '#FFF500' },
        { name: '～50mm/h', area: '#FF9900' },
        { name: '～80mm/h', area: '#FF2000' },
        { name: '80mm/h～', area: '#B40068' }
    ]
}, {
    // 大雨警報（浸水害）危険度分布
    name: '大雨警報（浸水害）危険度分布',
    code: 'O013',
    list: [
        { name: '【警戒レベル５相当】<br>災害切迫', area: '#0C000C' },
        { name: '危険', area: '#AA00AA' },
        { name: '警戒', area: '#FF2800' },
        { name: '注意', area: '#F2E700' }
    ]
}, {
    // 洪水警報危険度分布
    name: '洪水警報危険度分布',
    code: 'O015',
    list: [
        { name: '【警戒レベル５相当】<br>災害切迫', area: '#0C000C' },
        { name: '【警戒レベル４相当】<br>危険', area: '#AA00AA' },
        { name: '【警戒レベル３相当】<br>警戒', area: '#FF2800' },
        { name: '【警戒レベル２相当】<br>注意', area: '#F2E700' }
    ]
}, {
    // 地震情報
    name: '地震情報',
    code: 'D004',
    list: [
        { name: '震源', src: '/images/lalert/455.png' },
        { name: '震度', src: '/images/lalert/401.png' }
    ]
}, {
    // 津波警報・注意報
    name: '津波警報・注意報',
    code: 'D006',
    list: [
        { name: '大津波警報', line: '#C800FF'},
        { name: '津波警報', line: '#FF2800'},
        { name: '津波注意報', line: '#FAF500'}
    ]
}, {
    // 台風解析・予報情報
    name: '台風解析・予報情報',
    code: 'O017',
    list: [
        { name: '暴風域', area: '#FF2800' },
        { name: '強風域', area: '#FAF500' },
        { name: '予報円', src: 'images/lalert/tyhoon_line.png'},
        { name: '予想進路', src: 'images/lalert/tyhoon_line_red.png'}
    ]
}, {
    // 解析積雪深・解析降雪量
    name: '解析積雪深・解析降雪量',
    code: 'O016',
    list: [
        { name: '～5cm', area: '#A0D2FF' },
        { name: '～20cm', area: '#1C8CFF' },
        { name: '～50cm', area: '#0035FF' },
        { name: '～100cm', area: '#FFF500' },
        { name: '～150cm', area: '#FF9900' },
        { name: '～200cm', area: '#FF2000' },
        { name: '200cm～', area: '#B40068' }
    ]
}, /* {
    // 土壌雨量指数（実況）
    name: '土壌雨量指数（実況）',
    code: 'O009',
    list: [
        { name: '0-30mm', area: '#DEFFFF' },
        { name: '30mm以上', area: '#9CFFFF' },
        { name: '50mm以上', area: '#0000FF' },
        { name: '70mm以上', area: '#9CFF00' },
        { name: '80mm以上', area: '#319A9C' },
        { name: '90mm以上', area: '#FFFF00' },
        { name: '100mm以上', area: '#FF9A00' },
        { name: '150mm以上', area: '#FF00FF' },
        { name: '200mm以上', area: '#FF0000' },
        { name: '300mm以上', area: '#9C0000' }
    ]
}, {
    // 土砂災害警戒情報
    name: '土砂災害警戒情報',
    code: 'D105',
    list: [
        { name: '【警戒レベル4相当】発表区域', src: '/images/lalert/dosha_alert.png' }
    ]
}, {
    // 土砂災害危険度情報（地区）
    name: '土砂災害危険度情報（地区）',
    code: 'O005',
    list: [
        { name: '【警戒レベル5相当】大雨特別警報（土砂災害）基準超過', area: '#0c000c' },
        { name: '【警戒レベル4相当】実況で基準値超過', area: '#6c008c' },
        { name: '【警戒レベル4相当】1時間後に基準値超過', area: '#c809ff' },
        { name: '【警戒レベル4相当】2時間後に基準値超過', area: '#cc2800' },
        { name: '【警戒レベル4相当】3時間後に基準値超過', area: '#FF7b00' },
        { name: '【警戒レベル3相当】大雨警報（土砂災害）基準超過', area: '#Fae100' },
        { name: '【警戒レベル2相当】大雨注意報基準超過', area: '#9bff9b' }
    ]
}, {
    // 土砂災害危険度情報
    name: '土砂災害危険度情報',
    code: 'O006',
    list: [
        { name: '災害切迫【警戒レベル5相当】', area: '#0c000c' },
        { name: '危険【警戒レベル4相当】', area: '#aa00aa' },
        { name: '警戒【警戒レベル3相当】', area: '#ff2800' },
        { name: '注意【警戒レベル2相当】', area: '#ff2800' }
    ]
}, {
    // 記録的短時間大雨警報
    name: '記録的短時間大雨警報',
    code: 'D107',
    list: [
        { name: '発表区域', area: 'red' }
    ]
}, {
    // 土壌雨量指数（1時間後の予測）
    name: '土壌雨量指数（1時間後の予測）',
    code: 'O010',
    list: [
        { name: '0-30mm', area: '#DEFFFF' },
        { name: '30mm以上', area: '#9CFFFF' },
        { name: '50mm以上', area: '#0000FF' },
        { name: '70mm以上', area: '#9CFF00' },
        { name: '80mm以上', area: '#319A9C' },
        { name: '90mm以上', area: '#FFFF00' },
        { name: '100mm以上', area: '#FF9A00' },
        { name: '150mm以上', area: '#FF00FF' },
        { name: '200mm以上', area: '#FF0000' },
        { name: '300mm以上', area: '#9C0000' }
    ]
}, */ {
    // 災害・被害シミュレーション（SIP4D）
    name: '推定震度情報（震度）',
    code: 'D208',
    list: [
        { name: '震度1', area: '#96B4D3' },
        { name: '震度2', area: '#447ED3' },
        { name: '震度3', area: '#31ADA8' },
        { name: '震度4', area: '#1E973D' },
        { name: '震度5弱', area: '#96D050' },
        { name: '震度5強', area: '#F7F618' },
        { name: '震度6弱', area: '#FAAA46' },
        { name: '震度6強', area: '#F45178' },
        { name: '震度7', area: '#950D05' }
    ]
}, {
    name: '人的被害推定情報（曝露人口数）',
    code: 'D209',
    list: [
        { name: '～1', area: '#F2F2FF' },
        { name: '～5', area: '#00AAFF' },
        { name: '～10', area: '#0041FF' },
        { name: '～25', area: '#FAE696' },
        { name: '～50', area: '#FFE600' },
        { name: '～100', area: '#FF9900' },
        { name: '～500', area: '#FF2800' },
        { name: '～1000', area: '#A50021' },
        { name: '～5000', area: '#B40068' }
    ]
}, {
    name: '建物被害推定情報（被害棟数）',
    code: 'D210',
    list: [
        { name: '～1', area: '#AFF5FF' },
        { name: '～2', area: '#FFFE47' },
        { name: '～5', area: '#FFEB00' },
        { name: '～10', area: '#FF9000' },
        { name: '～20', area: '#FF0000' },
        { name: '～50', area: '#9E0000' },
        { name: '～100', area: '#570000' }
    ]
}, {
    // 防災マップ情報_震度分布予測図
    name: '震度分布予測図',
    code: 'D040',
    list: [
        { name: '震度4', src: '/images/seismic/intensity_4.png' },
        { name: '震度5弱', src: '/images/seismic/intensity_5high.png' },
        { name: '震度5強', src: '/images/seismic/intensity_5low.png' },
        { name: '震度6弱', src: '/images/seismic/intensity_6high.png' },
        { name: '震度6強', src: '/images/seismic/intensity_6low.png' },
        { name: '震度7', src: '/images/seismic/intensity_7.png' }
    ]
}, {
    // 防災マップ情報_液状化予測図
    name: '液状化予測図',
    code: 'D041',
    list: [
        { name: '極めて発生しにくい', src: '/images/seismic/liquefaction_01.png' },
        { name: '発生しにくい', src: '/images/seismic/liquefaction_02.png' },
        { name: '発生しやすい', src: '/images/seismic/liquefaction_03.png' },
        { name: '極めて発生しやすい', src: '/images/seismic/liquefaction_04.png' }
    ]
}, {
    // 防災マップ情報_浸水想定区域図
    name: '浸水想定区域図',
    code: 'D020',
    list: [
        { name: '0.5m未満', src: '/images/flooding/flooding_01.png' },
        { name: '0.5～3.0m', src: '/images/flooding/flooding_02.png' },
        { name: '3.0～5.0m', src: '/images/flooding/flooding_03.png' },
        { name: '5.0～10.0m', src: '/images/flooding/flooding_04.png' },
        { name: '家屋倒壊等氾濫想定区域', src: '/images/flooding/flooding_house.png' }
    ]
}, {
    name: '浸水想定区域図（寝屋川）',
    code: 'D043',
    list: [
        { name: '0.5m未満', src: '/images/flooding/flooding_neya_01.png' },
        { name: '0.5～1.0m未満', src: '/images/flooding/flooding_neya_02.png' },
        { name: '1.0～2.0m未満', src: '/images/flooding/flooding_neya_03.png' },
        { name: '2.0～3.0m未満', src: '/images/flooding/flooding_neya_04.png' },
        { name: '3.0～4.0m未満', src: '/images/flooding/flooding_neya_05.png' },
        { name: '4.0～5.0m未満', src: '/images/flooding/flooding_neya_06.png' },
        { name: '5.0m以上', src: '/images/flooding/flooding_neya_07.png' }
        // { name: '家屋倒壊等氾濫想定区域', src: '/images/flooding/flooding_neya_house.png' }
    ]
}, {
    name: '浸水想定区域図（東淀川・西淀川）',
    code: 'D044',
    list: [
        { name: '0.5m未満', src: '/images/flooding/flooding_01.png' },
        { name: '0.5～3.0m', src: '/images/flooding/flooding_02.png' },
        { name: '3.0～5.0m', src: '/images/flooding/flooding_03.png' },
        { name: '5.0～10.0m', src: '/images/flooding/flooding_04.png' }
        // { name: '家屋倒壊等氾濫想定区域', src: '/images/flooding/flooding_house.png' }
    ]
}, {
    name: '浸水想定区域図（内水氾濫した場合）',
    code: 'D045',
    list: [
        { name: '0.1～0.3m未満', src: '/images/flooding/inland_flooding_01.png' },
        { name: '0.3～0.5m未満', src: '/images/flooding/inland_flooding_02.png' },
        { name: '0.5～1.0m未満', src: '/images/flooding/inland_flooding_03.png' },
        { name: '1.0～3.0m未満', src: '/images/flooding/inland_flooding_04.png' },
        { name: '3.0～5.0m未満', src: '/images/flooding/inland_flooding_05.png' }
    ]
}, {
    name: '浸水想定区域図（高潮氾濫した場合）',
    code: 'D046',
    list: [
        { name: '0.5m未満', src: '/images/flooding/flooding_01.png' },
        { name: '0.5～3.0m', src: '/images/flooding/flooding_02.png' },
        { name: '3.0～5.0m', src: '/images/flooding/flooding_03.png' },
        { name: '5.0～10.0m', src: '/images/flooding/flooding_04.png' },
        { name: '10.0m以上', src: '/images/flooding/flooding_05.png' }
    ]
}, {
    name: '浸水想定区域図（地震による津波の場合）',
    code: 'D047',
    list: [
        { name: '0.5m未満', src: '/images/flooding/flooding_01.png' },
        { name: '0.5～3.0m', src: '/images/flooding/flooding_02.png' },
        { name: '3.0～5.0m', src: '/images/flooding/flooding_03.png' },
        { name: '5.0～10.0m', src: '/images/flooding/flooding_04.png' }
    ]
}, {
    // 施設情報
    name: '施設情報',
    code: 'M050',
    list: [
        { name: '避難所', src: '/images/facility/shelter_normal_4Legend.png' },
        { name: '庁舎等各種施設', src: '/images/facility/161.png' },
        { name: '賃貸施設', src: '/images/facility/163.png' },
        { name: '学校施設', src: '/images/facility/164.png' },
        { name: '市営住宅', src: '/images/facility/165.png' }
    ]
}, {
    // 施設情報_防災関連施設
    name: '避難場所等情報',
    code: 'D113',
    list: [
        { name: '広域避難場所', src: '/images/facility/safety_evac_area.png' },
        { name: '避難路', src: '/images/road/evac_road.png' },
        { name: '一時避難場所', src: '/images/facility/124.png' },
        { name: '災害時避難所', src: '/images/facility/125.png' },
        { name: '一時避難場所・災害時避難所', src: '/images/facility/126.png' },
        { name: '津波避難ビル・水害時避難ビル', src: '/images/facility/127.png' }
    ]
}, {
    // 施設情報_防災関連施設_道路情報
    name: '道路情報',
    code: 'D212',
    list: [
        { name: '緊急交通路_広域（重点１４路線）', src: '/images/road/emergency_14_road.png' },
        { name: '緊急交通路_広域（重点１４路線以外）', src: '/images/road/emergency_except14_road.png' },
        { name: '緊急交通路_地域', src: '/images/road/emergency_area_road.png' }
    ]
}, {
    // 施設情報_防災関連施設_その他施設
    name: 'その他施設・標高情報',
    code: 'D022',
    list: [
        { name: '防火水槽・プール（指定水利）', src: '/images/facility/141.png' },
        { name: '災害時用ヘリポート', src: '/images/facility/142.png' },
        { name: '防災スピーカー', src: '/images/facility/143.png' },
        { name: '標高の目安（O.P.=T.P.+1.3m）', src: '/images/facility/151.png' }
    ]
}, {
    // 本部設置状況
    name: '本部設置状況',
    code: 'D121',
    list: [
        { name: '市_災害対策本部', src: '/images/draw/icon/076.png' },
        { name: '市_災害警戒本部/情報連絡体制', src: '/images/draw/icon/077.png' },
        { name: '区_災害対策本部', src: '/images/draw/ward_taisaku_honbu.png' },
        { name: '区_災害警戒本部/情報連絡体制', src: '/images/draw/ward_keikai_honbu.png' }
    ]
}, {
    // 市全体の開設中避難所情報
    name: '避難所開設状況',
    code: 'D115',
    list: [
        { name: '閉鎖', src: '/images/facility/shelter_closed_4Legend.png' },
        { name: '開設中（空き）', src: '/images/facility/shelter_normal_4Legend.png' },
        { name: '開設中（混雑）', src: '/images/facility/shelter_crowded_4Legend.png' },
        { name: '開設中（満員）', src: '/images/facility/shelter_full_4Legend.png' },
        { name: '大サイズ（避難者数：201人〜）', src: '/images/facility/shelter_normal_4Legend_big.png' },
        { name: '中サイズ（避難者数：31〜200人）', src: '/images/facility/shelter_normal_4Legend.png' },
        { name: '小サイズ（避難者数：〜30人）', src: '/images/facility/shelter_normal_4Legend_small.png' }
    ]
}, {
    // 市全体の避難情報
    name: '市全体の避難情報',
    code: 'D114',
    list: [
        { name: '【警戒レベル5】緊急安全確保', area: '#0C000C' },
        { name: '【警戒レベル4】避難指示', area: '#AA00AA' },
        { name: '【警戒レベル3】高齢者等避難', area: '#FF2800' }
    ]
}, {
    // 被害情報
    name: '被害情報',
    code: 'D100',
    list: [
        { name: '速報（所属確認待ち）', src: '/images/damage/status_03_legend.png' },
        { name: '速報（区本部確認待ち）', src: '/images/damage/status_02_legend.png' },
        { name: '確定報', src: '/images/damage/status_01_legend.png' },
        { name: '深刻度（高）', area: '#FF0000' },
        { name: '深刻度（中）', area: '#FFE699' },
        { name: '深刻度（低）', src: '/images/damage/urgency_0.png' },
        { name: '住家', src: '/images/damage/type_01_urg_1.png' },
        { name: '学校', src: '/images/damage/type_03_urg_1.png' },
        { name: '病院', src: '/images/damage/type_04_urg_1.png' },
        { name: '非住家（市庁舎等施設）', src: '/images/damage/type_05_urg_1.png' },
        { name: '崖くずれ', src: '/images/damage/type_06_urg_1.png' },
        { name: '火災', src: '/images/damage/type_07_urg_1.png' },
        { name: '河川', src: '/images/damage/type_08_urg_1.png' },
        { name: '道路', src: '/images/damage/type_09_urg_1.png' },
        { name: '田・畑', src: '/images/damage/type_10_urg_1.png' },
        { name: 'その他被害', src: '/images/damage/type_11_urg_1.png' },
        { name: '現地画像', src: '/images/damage/type_13_urg_0.png' },
        { name: '水道（上水）', src: '/images/damage/type_14_urg_1.png' },
        { name: '水道（下水）', src: '/images/damage/type_15_urg_1.png' },
        { name: '港湾', src: '/images/damage/type_18_urg_1.png' },
        { name: '状況報告', src: '/images/damage/type_19_urg_1.png' },
        { name: '避難所', src: '/images/damage/type_20_urg_1.png' },
        { name: '人的', src: '/images/damage/type_21_urg_1.png' },
        { name: '非住家（その他）', src: '/images/damage/type_22_urg_1.png' },
        { name: '橋りょう', src: '/images/damage/type_23_urg_1.png' },
        { name: '砂防', src: '/images/damage/type_24_urg_1.png' },
        { name: '清掃施設', src: '/images/damage/type_25_urg_1.png' },
        { name: '鉄道不通', src: '/images/damage/type_26_urg_1.png' },
        { name: '船舶被害', src: '/images/damage/type_27_urg_1.png' },
        { name: '電話', src: '/images/damage/type_28_urg_1.png' },
        { name: '電気', src: '/images/damage/type_29_urg_1.png' },
        { name: 'ガス', src: '/images/damage/type_30_urg_1.png' },
        { name: 'ブロック塀（市庁舎施設）', src: '/images/damage/type_31_urg_1.png' },
        { name: 'ブロック塀（その他）', src: '/images/damage/type_32_urg_1.png' }
    ]
}, {
    // SIP4D 道路情報
    name: '道路情報',
    code: 'S003',
    list: [
        { name: '通行止', src: 'https://www.sip-bousai.jp/image/road_damage/1_close.png' },
        { name: '規制', src: 'https://www.sip-bousai.jp/image/road_damage/2_restriction.png' },
        { name: 'その他', src: 'https://www.sip-bousai.jp/image/road_damage/99_default.png' }
    ]
}, {
    // SIP4D 医療機関情報
    name: '医療機関情報',
    code: 'S004',
    list: [
        { name: '支援要', src: 'https://www.sip-bousai.jp/image/hospital/hospital_red.png' },
        { name: '支援不要', src: 'https://www.sip-bousai.jp/image/hospital/hospital_blue.png' },
        { name: '支援要否不明', src: 'https://www.sip-bousai.jp/image/hospital/hospital_gray.png' },
        { name: '災害拠点病院_支援要_要手配', src: 'https://www.sip-bousai.jp/image/hospital/hospital_base_red.png' },
        { name: '災害拠点病院_支援要_手配済', src: 'https://www.sip-bousai.jp/image/hospital/hospital_base_orange.png' },
        { name: '災害拠点病院_支援要_その他', src: 'https://www.sip-bousai.jp/image/hospital/hospital_base_yellow.png' },
        { name: '災害拠点病院_支援不要', src: 'https://www.sip-bousai.jp/image/hospital/hospital_base_blue.png' },
        { name: '災害拠点病院_支援要否不明', src: 'https://www.sip-bousai.jp/image/hospital/hospital_base_gray.png' }
    ]
}, /* {
    // 部隊活動情報
    name: '部隊活動情報',
    code: 'D120',
    list: [
        { name: '自衛隊', src: '/images/rescue/closed_1.png' },
        { name: '警察', src: '/images/rescue/closed_2.png' },
        { name: '消防', src: '/images/rescue/closed_3.png' },
        { name: 'その他', src: '/images/rescue/closed_9.png' },
        { name: '活動予定', src: '/images/rescue/future_legend.png' },
        { name: '活動中', src: '/images/rescue/going_legend.png' },
        { name: '活動完了', src: '/images/rescue/closed_legend.png' }
    ]
}, {
    // 現在の規制情報
    name: '規制情報',
    code: 'R001',
    list: [
        { name: '通行止め・閉鎖', src: '/images/road/blocked.gif' },
        { name: '大型車通行止め', src: '/images/road/big_blocked.gif' },
        { name: '片側交互通行', src: '/images/road/one_side.gif' },
        { name: '車線規制', src: '/images/road/narrows.gif' },
        { name: '災害・事故規制', src: '/images/road/saigai.gif' },
        { name: '事前通行規制', src: '/images/road/blocked_before.gif' },
        { name: '歩行者・二輪車通行止め', src: '/images/road/walker_bike.gif' }
    ]
}, {
    // 事前通行規制情報
    name: '事前通行規制区間情報',
    code: 'R002',
    list: [
        { name: '雨による規制区間', src: '/images/road/rain.gif' },
        { name: '風による規制区間', src: '/images/road/wind.gif' },
        { name: '越波による規制区間', src: '/images/road/nami.gif' },
        { name: '冠水による規制区間', src: '/images/road/kansui.gif' },
        { name: '冬季閉鎖通行規制区間', src: '/images/road/snow.gif' }
    ]
}, {
    // 事前通行規制情報
    name: '道路カメラ・冬季通行規制情報',
    code: 'R003',
    list: [
        { name: 'カメラ', src: '/images/road/camera.gif' },
        { name: '路面状況', src: '/images/road/slip.gif' }
    ]
}, {
    // 事前通行規制情報
    name: '走行注意区間',
    code: 'R004',
    list: [
        { name: '落石やがけ崩れの発生するおそれが高い区間', src: '/images/road/rakuseki_04.gif' },
        { name: '落石やがけ崩れの発生するおそれがやや高い区間', src: '/images/road/rakuseki_03.gif' }
    ]
}, {
    // 事前通行規制情報
    name: '緊急輸送道路',
    code: 'R005',
    list: [
        { name: '第一次緊急輸送道路（高規格幹線道路）', src: '/images/road/route_01.gif' },
        { name: '第一次緊急輸送道路（その他）', src: '/images/road/route_02.gif' },
        { name: '第二次緊急輸送道路', src: '/images/road/route_03.gif' },
        { name: '第三次緊急輸送道路', src: '/images/road/route_04.gif' }
    ]
}, {
    // 今後の規制情報
    name: '今後の規制情報',
    code: 'R006',
    list: [
        { name: '通行止め・閉鎖', src: '/images/road/blocked.gif' },
        { name: '大型車通行止め', src: '/images/road/big_blocked.gif' },
        { name: '片側交互通行', src: '/images/road/one_side.gif' },
        { name: '車線規制', src: '/images/road/narrows.gif' },
        { name: '災害・事故規制', src: '/images/road/saigai.gif' },
        { name: '事前通行規制', src: '/images/road/blocked_before.gif' },
        { name: '歩行者・二輪車通行止め', src: '/images/road/walker_bike.gif' }
    ]
}, */ {
    // 災害情報共有システム報告
    name: '災害情報報告',
    code: 'D116',
    list: [
        { name: '管理者からの報告', src: '/images/draw/icon/078.png' },
        { name: '支援団体からの報告', src: '/images/draw/icon/076.png' },
        { name: '支援者からの報告', src: '/images/draw/icon/077.png' }
    ]

}, {
    // Specteeお気に入り情報
    name: 'Spectee登録情報',
    code: 'D117',
    list: [
        { name: '情報発信源の緯度経度が判明している情報', src: '/images/spectee/latlng-comfirmed.png' },
        { name: '情報発信源の緯度経度が判明していない情報', src: '/images/spectee/latlng-uncomfirmed.png' }
    ]
}]);
