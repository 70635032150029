/**
 * 詳細情報表示ダイアログ
 * 「共有情報」「新着情報」「緊急情報」共通で利用する
 * @module app/disasterInfoShare/DisInfoShareDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/text!./templates/DisInfoShareDetailDialog.html',
    'idis/view/page/_PageBase',
    'app/util/DateFormatter',
    'idis/store/IdisRest',
    'idis/service/iframe',
    'idis/view/dialog/DialogChain',
    'idis/view/Loader',
    'idis/model/UserInfo',
    'idis/control/Locator',
    'app/config',
    // 以下、変数で受けないモジュール
    'dojox/form/Uploader',
    'dijit/form/Select',
    'dijit/layout/ContentPane',
    'idis/view/form/AclButton',
    'idis/view/form/WordCountTextarea',
    './DisInfoShareEditDialog',
    'app/bbs/CommentsGrid'
], function(module, declare, lang, template, _PageBase, DateFormatter, IdisRest, iframe, DialogChain,
    Loader, UserInfo, Locator, config) {
    /**
     * 詳細情報表示ダイアログ
     *
     * @class DisInfoShareDetailDialog
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:app/bbs/DisInfoShareDetailDialog~DisInfoShareDetailDialog# */ {
        // テンプレート文字列
        templateString: template,

        attachFileList: [],

        // 未登録コメント添付ファイル
        tempCommentAttachFile: [],

        // 基本クラス
        baseClass: 'news-DetailDailog idis-Page idis-Page--disasterInfoShare',

        /**
        * データ格納用オブジェクト
        * @type {module:dstore/Store}
        */
        store: null,

        /**
         * 本部ID
         * @private
         */
        _deptId: null,

        // 危機管理部の本部ID
        CRISIS_MANAGEMENT_DEPT_ID : config.dept.crisisManagementDeptCd,

        /**
         * constructor
         */
        constructor: function() {
            // データ格納用オブジェクト
            this.store = new IdisRest({
                idProperty: 'messageId',
                target: '/api/bbses',
                rangeStartParam: 'offset',
                rangeCountParam: 'count'
            });

            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);

            // 本部IDを定義
            this._deptId = Locator.getQuery().deptId;
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
            this.own(this.editDialog);
        },

        startup: function() {
            this.inherited(arguments);
            this.initDetailPage();
        },

        /**
         * 詳細ダイアログを初期化する。
         */
        initDetailPage: function() {
            var dialog = this.editDialog;
            var page = dialog.getChildren()[0];

            // 詳細ダイアログのupdateSendイベントを受け取る
            page.on('update', lang.hitch(this, function(evt) {
                this.chain.confirmPut(function(chain) {
                    // データ更新用オブジェクト
                    this.updateStore = new IdisRest({
                        idProperty: 'messageId',
                        target: '/api/bbses/update'
                    });
                    // 更新処理と共にローディング表示
                    Loader.wait(this.updateStore.put(evt.value)).then(lang.hitch(this, function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            var fil = this.storeMessage = new IdisRest({
                                target: '/api/bbses/message/' + evt.value.messageId
                            });
                            fil.fetch().then(lang.hitch(this, function(formItem){
                                this.setContent(formItem);
                                this.updateDialog(formItem);
                            }));
                        }));
                    }), function(err) {
                        // 失敗時
                        chain.infoError(err);
                    });
                });
            }));

            // 詳細ダイアログのremoveイベントを受け取る
            page.on('remove', lang.hitch(this, function(evt) {
                this.chain.confirmDel(function(chain) {
                    // OKが押された場合

                    // データ削除用オブジェクト
                    this.deleteStore = new IdisRest({
                        target: '/api/bbses/delete'
                    });
                    // 削除処理と共にローディング表示
                    Loader.wait(this.deleteStore.remove(evt.value.messageId)).then(lang.hitch(this, function() {
                        // 成功時
                        chain.infoComplete(lang.hitch(this, function() {
                            this._buttonFlg = false;
                            dialog.hide();
                            chain.hide();
                            // 削除時はダイアログに表示させるデータが無くなるので画面ごとリロードさせる
                            location.reload();
                            // var fil = this.storeMessage = new IdisRest({
                            //     target: '/api/bbses/message/' + evt.value.messageId
                            // });
                            // fil.fetch().then(lang.hitch(this, function(formItem){
                            //     this.setContent(formItem);
                            //     this.updateDialog(formItem);
                            // }));
                        }));
                    }, function(err) {
                        // 失敗時
                        chain.infoError(err);
                    }));
                });
            }));
        },

        /**
         * ダイアログの初期表示またはコメント登録後に呼ばれダイアログを更新する
         * @param
         */
        updateDialog: function(item) {
            // フォームリセット
            // 未登録コメント添付ファイル情報の初期化
            this.tempCommentAttachFile.length = 0;
            // テキストエリアの初期化
            // this.commentForm.reset();
            // this.searchForm.reset();
            // this.selectComment.reset();
            // this.searchText.reset();
            // this.addCommentTextArea.hidden = true;
            if(item.crtUserId === UserInfo._userInfo.userId || UserInfo._userInfo.roleCd === 'R01001') {
                this.showDetailPageLink.hidden = false;
            } else {
                this.showDetailPageLink.hidden = true;
            }
            if (item.contents){
                this._detailPage = item;
            }
        },

        // 共有情報の詳細ダイアログの場合、編集ボタンを非表示とする
        hideEditButton: function() {
            this.showEditDialogButton.hidden = true;
        },

        // 共有情報の詳細ダイアログの場合、編集ボタンを非表示とする
        showEditButton: function() {
            this.showEditDialogButton.hidden = false;
        },

        /**
         * ダイアログのテーブルにデータをセット
         */
        setContent: function(item) {
            this._messageId = item.messageId;
            // 情報の種別・レベル
            if (item.emergencyNewsFlg === 1) {
                var notifyTypeMap = {
                    '1': '本部長（市長）指示',
                    '2': '市本部からのお知らせ',
                };
                var html = '<div class="message-type notify-type notify-type-tag">'
                            + notifyTypeMap[item.notifyType] + '</div>';
                this.infoType.innerHTML = html;
            } else {
                var messageTypeMap = {
                    '001': '一般',
                    '002': '通知',
                    '003': '注意',
                    '004': '重要',
                    '005': '緊急',
                };
                var html = '<div class="message-type message-type-'
                            + item.messageType + ' info-type-tag">'
                            + messageTypeMap[item.messageType] + '</div>';
                this.infoType.innerHTML = html;
            }
            this.title.innerHTML = item.title;
            this.time.innerHTML = DateFormatter.jpDateTime(
                new Date(item.pubStartTimestamp), true);


            if(item.contents !== null){
                this.infoDetail.innerHTML = item.contents.replace(/\n/g, '<br>');
            }

            if (!!item.refUrl && item.refUrl !== '') {
                var htmlLink = [];
                var hasScheme = item.refUrl.match(/(http|https):\/\/.+/);
                if (hasScheme) {
                    // URLスキームがあるとき
                    htmlLink.push('<a target="_blank" class="u-external" href="');
                } else {
                    // URLスキームがないとき
                    htmlLink.push('<a target="_blank" class="u-external" href="//');
                }
                htmlLink.push(item.refUrl);
                htmlLink.push('" tabindex="-1">');
                htmlLink.push(item.refUrl);
                htmlLink.push('</a>');
                this.url.innerHTML = htmlLink.join('');
            } else {
                this.url.innerHTML = '&nbsp;';
            }

            if(item.sender !== null){
                this.organization.innerHTML = item.sender.replace(/\n/g, '<br>');
            }

            var attachFile = item.attachFile;
            var attachFileNameList = [];
            var hasAttachFile = false;
            // 添付ファイルを設定
            for (var i=0; i< attachFile.length; i++) {
                if(attachFile[i].delFlg  === 'false'){
                    // 送付ファイルとして表示
                    attachFileNameList.push('<a href="' + attachFile[i].attachFilePath.replace('out/', 'data/') + '"');
                    attachFileNameList.push('download="' + attachFile[i].attachFileName +'" tabindex="-1">');
                    attachFileNameList.push(attachFile[i].attachFileName +'</a><br>');
                    // attachFileListに登録
                    this.attachFileList.push(attachFile[i]);
                    hasAttachFile = true;
                }
            }
            if (hasAttachFile) {
                this.files.innerHTML = attachFileNameList.join('');
            } else {
                this.files.innerHTML = '&nbsp;';
            }

        },

        /**
         * ダイアログを初期化する。
         */
        initDialog: function() {
            //this.form.reset();
            this.selectComment.setValue = null;
        },

        /**
         * キャンセルボタン押下時処理
         * アップロードファイルを削除(未登録時）
         */
        cancel: function() {
            // ダイアログを閉じる
            this.leave();
            this.emit('cancel');
        },

        /**
         * 編集ダイアログへ遷移
         */
        showEditDialog: function(){
            var item = this._detailPage;
            var page = this.editDialog.getChildren()[0];

            // 画面上のフォームをリセット
            page.form.reset();
            page.clearPreview();

            // 添付ファイルをプレビューに反映
            this.innerEditDialog.displayAttachFile(item.attachFile);

            // 内容をフォームに反映
            var value = lang.mixin(null, item);
            delete value.attachFile;
            page.form.set('value', value);

            // 編集ダイアログを表示
            this.innerEditDialog.initDialog(item);
            this.editDialog.show();

            // 危機管理部の場合のみ、緊急情報の公開チェックボックスと通知種別プルダウンを表示する
            if (this._deptId !== this.CRISIS_MANAGEMENT_DEPT_ID) {
                this.innerEditDialog.hideEmergencyNewsSetting();
            }
        }
    });
});
