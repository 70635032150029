/**
 * 避難状況登録画面用モジュール。
 * @module app/view/page/EvacOrderRegisterFromRecommendPage
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/promise/all',
    'dojo/topic',
    'dojo/text!./templates/EvacOrderRegisterPage.html',
    'dojo/json',
    'app/evacorder/_EvacOrderRegisterPageBase',
    'app/model/DisasterInfo',
    'idis/view/Loader',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/model/UserInfo',
    'idis/service/Requester',
    'app/config',
    // 以下、変数で受けないモジュール
    'dijit/layout/TabContainer',
    'app/shelter/ShelterOpeningGrid',
    'app/evacorder/EvacOrderMunicipalitySelector',
    'app/provide/ProvideEvacOrderRegisterDialog',
    'app/evacorder/RiverAreaMultiSelector',
    './EvacOrderPopulationGrid'
], function(module, array, declare, lang, domStyle, all, topic, template,
        json, _EvacOrderRegisterPageBase, DisasterInfo, Loader, Locator, Router,
        UserInfo, Requester, config) {

    /**
     * 避難状況登録画面。
     * @class EvacOrderRegisterPage
     * @extends module:app/evacorder/_EvacOrderRegisterPageBase~_EvacOrderRegisterPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _EvacOrderRegisterPageBase,
    /** @lends module:app/evacorder/EvacOrderRegisterFromRecommendPage~EvacOrderRegisterFromRecommendPage# */ {
        // テンプレート文字列
        templateString: template,

        _evacRecommendIds: null,

        _districtCdList: [],

        constructor: function(){
            console.debug('constructor');

            // URLからクロノロジIDを取得
            this._evacRecommendIds = Locator.getQuery().evacRecommendIds;
        },

        buildRendering: function() {
            console.debug('buildRendering');
            this.inherited(arguments);
        },

        startup: function(){
            this.inherited(arguments);

            // キャンセルボタンを表示
            domStyle.set(this.cancelButtonArea, 'disable', '');

            // 操作されないようにローディング中にしておく
            Loader.show()
            .then(lang.hitch(this, function(){
                //まずはメッシュ・河川のマスタを取得してくる
                return this.initMeshAndRiverTree();
            }))
            .then(lang.hitch(this, function(){
                //発令判断支援の情報を取得してくる
                return this.initRegisterPage();
            }))
            // .then(lang.hitch(this, function(){
            //     //地図の初期値をセット
            //     return this.initLatlng4Page();
            // }))
            // 市町村コードがモジュールにセットされてから処理される。
            .then(lang.hitch(this, function() {
                
                this.setEvacOrderTypeSelector(this._issueReasonType, this._evacOrderType);

                // 避難情報ツリーを初期表示した後にツリーのストア情報に基づいて地区レイヤーを表示する。
                this.initTree().then(lang.hitch(this, 'initDistrictLayer'))
                    .then(lang.hitch(this, function(){
                        // メッシュ・河川の値をセットし、地区ツリーのフィルタもつける。ただし地区にチェックはつけない。
                        if(this._issueReasonType === '01'){
                            this._selectMeshes(this._meshCdList);
                            this.onMeshCheckChange(false);
                        }else if(this._issueReasonType === '02'){
                            this._selectRivers(this._riverCdList);
                            this.onRiverCheckChange(false);
                        }
                         //画面パーツの表示制御
//                         this.setDispContentByEvacOrderType(this._issueReasonType);
                    
                    })).then(lang.hitch(this, function() {
                        // 準備が整ったら選択中の各地区をチェック状態にする
                        this._selectDistricts(this._districtCdList);
                        // 現況状況レイヤーの初期値をONにする。
                        this._latestLayerState = this.LATEST_LAYER_STATE.ON;
                    })).then(lang.hitch(this, function(){
                        //ひととおりの初期値がセットされたのち、メッシュ・河川の変更時トリガーをセット。
                        this.setMeshAndRiverTopicSubscriber();
                    })).then(lang.hitch(this, function(){
                        Loader.hide();
                    }));
            }));
        },

        initTreeAndLayers: function(){
            // 避難情報ツリーを初期表示した後にツリーのストア情報に基づいて地区レイヤーを表示する。
            return this.initTree().then(lang.hitch(this, 'initDistrictLayer'))
            .then(lang.hitch(this, function() {
                // 準備が整ったら選択中の各地区をチェック状態にする
                this._selectDistricts(this._districtCdList);
                // 現況状況レイヤーの初期値をONにする。
                this._latestLayerState = this.LATEST_LAYER_STATE.ON;
                // 避難所一覧グリッドを初期化する。
                //this.initShelterGrid();
            }));
        },

        /**
         * 市町村コードから、対象の市町の緯度経度を取得して、地図を移動させる。
         */
        initLatlng4Page: function() {
            // 広島「県」以外の場合は地図を追加した後で対象の市町の緯度経度を取得して、
            // 地図の中心位置に移動させる。
            // 市町村の緯度経度情報の一覧を取得する。
            return Requester.get('/data/municipality/municipalityList.json', {preventCache: false})
            .then(lang.hitch(this,function(obj) {

                var municipalities = obj.municipalities;
                var latlng = this.INIT_LATLNG;

                // 市町村の緯度経度情報の一覧と画面で指定されている市町村を比較して当該市町村の緯度経度を取得する。
                municipalities.some(lang.hitch(this,function(item) {
                    if (item.municipalityCd===this._municipalityCd) {
                        latlng = item.latlng;
                        return true; //break
                    }
                }));
                this.map.setView(latlng, 12);
            }));
        },

        initRegisterPage: function(){
            var self = this;
            return Requester.get('/api/evacrecommend/' + self._evacRecommendIds, {
                headers: {'Content-Type': 'application/json; charset=utf-8'},
                handleAs: 'json',
                preventCache : true
            }).then(function(data) {
                //画面に設置
                console.log(data);

                // 市町村
                // 大阪市は固定
                self._municipalityCd = config.municInfo.cityMunicCd;
                // self.municipalitySelector.set('value', self._municipalityCd);
                // self.municipalitySelector.set('noAllButton', true);

                // 避難区分
                self.evacOrderType.set('value', data.evacOrderType);
                self.evacOrderType.setDisabled(true);
                self._evacOrderType = data.evacOrderType;

                // 発令理由
                self.issueReasonType.set('value', data.issueReasonType);
                self.issueReasonType.setDisabled(true);
                self._issueReasonType = data.issueReasonType;
                
                // 地区
                self._districtCdList = [];
                var municipalityList = data.municipality;
                if(municipalityList && municipalityList.length > 0){
                    array.forEach(municipalityList, function(municipality){
                        var distList = municipality.districtCdList;
                        if(distList && distList.length > 0){
                            array.forEach(distList, function(dist){
                                self._districtCdList.push(dist);
                            }, this);
                        }
                    }, this);
                }
                
                self._meshCdList = data.meshCdList;
                self._riverCdList = data.riverCdList;

            }, function(error) {
                self.chain.infoError(error);
            });

        },

        onCancelButtonClick: function(){
            Router.moveTo('evacrecommend');
        },

        onChangeRiverArea: function (val) {
            all(
                Object.keys(this.tree._checkMap)
                    .map(lang.hitch(this, function (itemId) {
                        return this.tree.model.store.get(itemId);
                    }))
            ).then(lang.hitch(this, function (itemList) {
                itemList.forEach(lang.hitch(this, function(item){
                    this.tree.setChecked(item, false);
                }));
            }));
            if(val.value.length === 0) {
                return;
            }
            val.value.forEach(lang.hitch(this, function (item) {
                item[0].districtList.split(',').forEach(lang.hitch(this, function(distCd){
                    this.tree.model.store.get(distCd).then(lang.hitch(this, function(item) {
                        if (item) {
                            this.tree.setChecked(item, true);
                        }
                    }));
                }));
            }));

        },

         // 発令理由を選択したときのイベント
        toggleOtherReason: function (value) {
            if (value === "02") {
                this.riverArea.set('style', { display: '' });
            } else {
                this.riverArea.set('style', { display: 'none' });
            }
            // 基底クラスのイベントを呼び出す
            this._toggleOtherReason(value);
        }
    });
});
