/**
 * お知らせ情報新規登録画面用モジュール。
 * @module app/bbs/BbsDetailDialog
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/text!./templates/BbsDetailDialog.html',
    'idis/view/dialog/InfoDialog',
    'idis/view/page/_PageBase',
    'idis/view/Loader',
    'idis/model/UserInfo',
    'idis/store/IdisRest',
    'app/model/DisasterInfo',
    'idis/service/iframe',
    'idis/util/FilesUtils',
    'dojo/dom-class',
    'dojo/dom-construct',
    'exif-js/exif',
    'dijit/Menu',
    'dijit/MenuItem',
    'dijit/popup',
    'dijit/TooltipDialog',
    'dojo/on',
    // 以下、変数から参照されないモジュール
    'dijit/form/ValidationTextBox',
    'dijit/form/Textarea',
    'dijit/form/DateTextBox',
    'dijit/form/Form',
    'dijit/form/NumberTextBox',
    'dijit/form/Select',
    'dijit/form/SimpleTextarea',
    'dijit/form/TextBox',
    'dojox/form/Uploader',
    'idis/view/form/AclButton',
    'idis/view/form/Button',
    'idis/view/form/CheckGroup',
    'idis/view/form/DateTimeInput',
    'idis/view/form/RadioGroup',
    // 'app/bbs/DestinationSelector',
    'dijit/TitlePane'
], function(module, declare, lang, domStyle, template, InfoDialog,
    _PageBase, Loader, UserInfo, IdisRest, DisasterInfo, iframe, FilesUtils, domClass, domConstruct,
    exif, Menu, MenuItem, popup, TooltipDialog, on) {
    /**
     * お知らせ情報詳細画面
     * @class BbsDetailPage
     * @extends module:app/bbs/_BbsPageBase~_BbsPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    /** @lends module:app/bbs/BbsDetailDialog~BbsDetailDialog# */ {
        // テンプレート文字列
        templateString: template,

        /**
         * 添付ファイル取得用オブジェクト
         * @type {module:dstore/Store}
        */
        store: null,

        delStore: [],

        attachFileList: [],
        
        tempAttachFileList: [],

        /**
         * constructor
         */
        constructor: function() {
            //災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
        },

        /**
         * buildRendering
         */
        buildRendering: function() {
            this.inherited(arguments);
        },

        // DOM要素構築後に呼ばれる
        postCreate: function() {
        },

        /**
         * 更新ボタンクリックイベント
         */
        updateBbsButtonClick: function() {
            try {
                // フォームのバリデーションを実施
                if (!this.form.validate()) {
                    return false;
                }

                var value = this.form.get('value');
                // メッセージIDをセット
                value.messageId = this._messageId;
                // 掲載対象が選択中の災害の場合、災害IDをセット
                if(value.allDisasterFlg==='0'){
                    value.disasterId = this._disasterId;
                }
                delete value.allDisasterFlg;

                // 送信者をセット
                value.sender = UserInfo.getSelectedMunicipalityCd();
                // 添付ファイルをセット
                if(this.attachFileList.length > 0) {
                    var attachFileIds = [];
                    for(var i=0; i<this.attachFileList.length; i++) {
                        attachFileIds.push(this.attachFileList[i].messageAtcFileId);
                    }
                    value.attachFileIds = attachFileIds.join(',');
                } else {
                    value.attachFileIds = '';
                }
                delete value.attachFile;

                this.emit('update', {value: value});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 削除ボタンクリックイベント
         */
        deleteBbsButtonClick: function() {
            try {
                var value = this.form.get('value');
                value.messageId = this._messageId;

                this.emit('remove', {value: value});
            } catch (e) {
                console.error(e);
            }
            return false;
        },

        /**
         * 添付ファイルをアップロードする。
         */
        loadAttachFile: function() {
            // ファイルが空の場合は処理を中断＆不正なファイルの場合、メッセージ表示して処理を中断
            if (this.attachFile._files.length === 0 ||
                !FilesUtils.isAttachFile(this.attachFile)) {
                return;
            }

            console.log('file change');
            var self = this;

            this.attachFile.set('disabled', false);

            // ファイルがonloadされたときにサーバーに一時保存する
            var promise = iframe.post('/api/bbses/uploadFile/message/'+this._messageId, {
                messageId: this._messageId,
                form: this.form.id,
                handleAs: 'json'
            }).then(function(data) {
                console.log(data);
                // uploaderをリセット
                self.attachFile.reset();
                self.attachFileList.push(data);
                self.tempAttachFileList.push(data);
                self.showPreview(data, true);
            }, function(error) {
                console.log(error);
                // uploaderをリセット
                self.attachFile.reset();
                self.chain.infoError(error);
            });
            // ローダーの表示
            Loader.wait(promise);
        },
        /**
         * 添付ファイルのプレビューを表示する。
         */
        showPreview: function(data, exifFlg) {

            var dataUri = data.attachFilePath.replace('out/', 'data/');
            var fileName = data.attachFileName;
            var fileId = data.messageAtcFileId;
            var self = this;

            // 画像ファイルの場合
            if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                fileName.indexOf('.gif') !== -1) {
                var image = new Image();

                // JPEGファイルの場合、EXIFデータの取得を実行する
                if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                    fileName.indexOf('.JPG') !== -1 || fileName.indexOf('.JPEG') !== -1) {
                    var img = null;
                    this.own(on(image, 'load', lang.hitch(this, function(e) {
                        console.log(e);
                        img = e.target;

                        if (exifFlg) {
                            this.getExifData(img, this);
                        }
                    })));
                }
                image.src = dataUri;
                domClass.add(image, 'is-showPreview');
                domConstruct.place(image, this.preview);
                // メニューの作成
                this.createMenu(image, dataUri, fileName, fileId, self);
            // Excelファイルの場合
            } else if (fileName.indexOf('.xls') !== -1 || fileName.indexOf('.xlsx') !== -1) {
                var excel = new Image();
                excel.src = 'images/excelicon.png';
                domClass.add(excel, 'is-showPreview');
                domConstruct.place(excel, this.preview);
                // メニューの作成
                this.createMenu(excel, dataUri, fileName, fileId, self);
            // PDFファイルの場合
            } else if (fileName.indexOf('.pdf') !== -1) {
                var pdf = new Image();
                pdf.src = 'images/pdficon.png';
                domClass.add(pdf, 'is-showPreview');
                domConstruct.place(pdf, this.preview);
                // メニューの作成
                this.createMenu(pdf, dataUri, fileName, fileId, self);
            // Wordファイルの場合
            } else if (fileName.indexOf('.doc') !== -1 || fileName.indexOf('.docx') !== -1) {
                var word = new Image();
                word.src = 'images/wordicon.png';
                domClass.add(word, 'is-showPreview');
                domConstruct.place(word, this.preview);
                // メニューの作成
                this.createMenu(word, dataUri, fileName, fileId, self);
            // その他ファイルの場合
            } else {
                var other = new Image();
                other.src = 'images/othericon.png';
                domClass.add(other, 'is-showPreview');
                domConstruct.place(other, this.preview);
                // メニューの作成
                this.createMenu(other, dataUri, fileName, fileId, self);
            }
        },

        /**
         * JPEGファイルの位置情報を取得する
         */
        getExifData: function(img, self) {
            console.log('getting exif data start');
            exif.getData(img, function() {

                var latitude = exif.getTag(this, 'GPSLatitude');
                var longitude = exif.getTag(this, 'GPSLongitude');

                if (typeof latitude === 'undefined' || typeof longitude === 'undefined') {
                    console.log('GPS data is unavailable.');
                } else {
                    console.log('GPS data is available.');
                    var f = function(number) {
                        return number[0].numerator + number[1].numerator / (60 * number[1].denominator) +
                            number[2].numerator / (3600 * number[2].denominator);
                    };
                    self.chain.confirm('この画像の位置情報を使用しますか？', function(chain) {
                        // 位置情報を設定する
                        self.pointLat = f(latitude);
                        self.pointLng = f(longitude);
                        // 地図にマークして中心に移動する
                        self.addMark(self.pointLat, self.pointLng, self);
                        self.map.setView([self.pointLat, self.pointLng], 11);
                        // ダイアログを閉じる
                        chain.hide();
                    });
                }
            });
        },

        /**
         * 添付ファイルのサムネイル上にメニューを作る
         */
        createMenu: function(newNode, uri, fileName, id, self) {
            var menu = new Menu({
                targetNodeId: newNode
            });
            menu.set('style', {
                'border': 'none',
                'box-shadow': 'none'
            });

            // ダウンロード操作用
            var download = null;
            var userAgent = window.navigator.userAgent.toLowerCase();
            if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                var url = location.protocol + '//' + location.hostname + ':' + location.port + '/' + uri;
                // IEの場合、download属性が効かないため、右クリック保存などaタグ機能を無効化.
                // ダウンロード関数により元ファイル名でのダウンロードを可能にする.
                download = domConstruct.create('a', {
                    href: '#'
                });
                // クリックでファイル取得処理に入る
                download.onclick = function() {
                    self.downloadFile(url, fileName);
                };
            } else {
                // FF, Chromeの場合、download属性でファイルダウンロード
                download = domConstruct.create('a', {
                    href: uri,
                    download: fileName
                });
            }

            // ファイル名とメニューとの境界線をセット
            var contentNode = domConstruct.create('div');
            contentNode.innerHTML = fileName;
            domConstruct.place('<hr color=#b0c4de>', contentNode);
            // メニューをセット
            domConstruct.place(menu.domNode, contentNode);
            var tooltip = new TooltipDialog({
                content: contentNode
            });
            //
            tooltip.containerNode.onmouseleave = function() {
                popup.close(tooltip);
            };

            // 画像ファイルの場合のみ'開く'をメニューに追加する
            if (fileName.indexOf('.jpg') !== -1 || fileName.indexOf('.jpeg') !== -1 ||
                fileName.indexOf('.png') !== -1 || fileName.indexOf('.JPG') !== -1 ||
                fileName.indexOf('.JPEG') !== -1 || fileName.indexOf('.PNG') !== -1 ||
                fileName.indexOf('.gif') !== -1) {
                menu.addChild(new MenuItem({
                    label: '開く',
                    iconClass: 'dijitEditorIcon dijitEditorIconInsertImage',
                    onClick: function() {
                        console.log('file open');
                        window.open(uri);
                    }
                }));
            }

            menu.addChild(new MenuItem({
                label: 'ダウンロード',
                iconClass: 'dijitIconSave',
                onClick: function(e) {
                    console.log('file download');
                    console.log(e);
                    // IE対策
                    if (userAgent.match(/(msie|MSIE)/) || userAgent.match(/(T|t)rident/)) {
                        download.onclick();
                    } else {
                        download.click();
                    }
                }
            }));

            menu.addChild(new MenuItem({
                label: '削除',
                iconClass: 'dijitIconDelete',
                onClick: function() {
                    console.log('file delete');

                    // 該当ファイルを削除
                    for (var i = 0; i < self.attachFileList.length; i++) {
                        if (self.attachFileList[i].messageAtcFileId === id) {
                            self.attachFileList.splice(i, 1); // id:3の要素を削除
                        }
                    }

                    // サムネイルとメニューを削除
                    domConstruct.destroy(newNode);
                    popup.close(tooltip);
                }
            }));

            menu.startup();
            // メニュー表示処理
            this.own(on(newNode, 'mouseover', lang.hitch(this, function() {
                popup.open({
                    popup: tooltip,
                    around: newNode,
                    orient: ['below-centered']
                });
            })));
            // 画面破棄時に一緒に破棄する
            this.own(tooltip);
        },

        /**
         * 添付ファイルをダウンロードする。
         */
        downloadFile: function(url, name) {
            // Dojoのrequestor(dojo/request/xhr)のresponseType（handleAsプロパティ）で、
            // バイナリ（arraybuffer, blob）が利用できなかったためネイティブJSを利用している.
            var xhr = new XMLHttpRequest();
            xhr.open('GET', url, true);
            xhr.responseType = 'arraybuffer';
            xhr.onload = function() {

                var arrayBuffer = this.response;

                var blob = new Blob([arrayBuffer], {
                    type: 'application/octet-stream'
                });

                // IE10+
                if (window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob, name);
                }

            };
            xhr.send();

            return false;

        },


        /**
         * 既存添付ファイルを表示し、attachFileListに登録する
         */
        displayAttachFile: function(attachFile) {
            if (attachFile) {
                for (var i=0; i< attachFile.length; i++) {
                    if(attachFile[i].delFlg  === 'false'){
                        // 添付ファイルをプレビューに反映
                        this.showPreview(attachFile[i], true);
                        // attachFileListに登録
                        this.attachFileList.push(attachFile[i]);
                    }
                }
            }
        },

        /**
         * プレビューをクリアする
         */
        clearPreview: function() {
                var length = this.preview.childNodes.length;
                for (var i = 0; i < length; i++) {
                    domConstruct.destroy(this.preview.childNodes[0]);
                }

                this.attachFileList.length = 0;
                this.tempAttachFileList.length = 0;
        },

        /**
         * ダイアログを初期化する。
         */
        initDialog: function(object) {
            this._messageId = object.messageId;
            // 災害IDがnullの場合、掲載対象災害 ＝ 全災害共通
            this.allDisasterFlg.set('value', object.disasterId ? '0' : '1');
        },

        /**
         * キャンセルボタン押下時処理
         * アップロードファイルを削除(未登録時）
         */
        cancel: function() {
            if(this.tempAttachFileList.length > 0) {
                var param = '';
                var firstFlg = true;
                for(var i = 0; i < this.tempAttachFileList.length; i++) {
                    if(firstFlg) {
                        param += '?';
                        firstFlg = false;
                    } else {
                        param += '&';
                    }
                    param += 'array[]=' + this.tempAttachFileList[i].messageAtcFileId;
                }
                console.log('param' + param);

                // データ削除用オブジェクト
                this.delStore = new IdisRest({
                    target: '/api/bbses/deleteAtcFile'
                });
                // 削除処理
                Loader.wait(this.delStore.remove(param).then(function() {},
                    function(err) {
                        console.log(err);
                }));
                // テンポラリファイルリストをリセット
                this.tempAttachFileList.length = 0;
            }
            // ダイアログを閉じる
            this.leave();
        }
    });
});
