/**
 * 避難状況登録用ベースモジュール。
 * @module app/view/page/_EvacOrderRegisterPageBase
 */
define([
    'module',
    'dojo/_base/array',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-style',
    'dojo/promise/all',
    'dojo/topic',
    'dojo/text!./templates/EvacOrderRegisterPage.html',
    'dojo/json',
    'app/evacorder/_EvacOrderPageBase',
    'idis/consts/ACL',
    'idis/view/Loader',
    'idis/control/Locator',
    'idis/control/Router',
    'idis/service/Requester',
    'idis/store/IdisRest',
    'idis/view/dialog/InfoDialog',
    'idis/model/UserInfo',
    './EvacOrderStoreModel',
    './Reason',
    './EvacOrderUtil',
    'idis/consts/USER_TYPE',
    'app/config',
    // 以下、変数で受けないモジュール
    'dijit/layout/TabContainer',
    'dijit/form/CheckBox',
    'app/shelter/ShelterOpeningGrid',
    'app/evacorder/EvacOrderMunicipalitySelector',
    'app/view/form/ManageRegionMunicipalitySelector',
    'app/map/LayerSelector',
    'app/view/form/DistrictSelector',
    'app/provide/ProvideEvacOrderRegisterDialog',
    './EvacOrderPopulationGrid'
], function(module, array, declare, lang, domStyle, all, topic, template,
        json, _EvacOrderPageBase, ACL, Loader, Locator, Router, Requester, IdisRest,
        InfoDialog, UserInfo, EvacOrderStoreModel, Reason, EvacOrderUtil, USER_TYPE, config) {

    /**
     * 避難状況登録画面。
     * @class EvacOrderRegisterPage
     * @extends module:app/evacorder/_EvacOrderPageBase~_EvacOrderPageBase
     */
    return declare(module.id.replace(/\//g, '.'), _EvacOrderPageBase,
    /** @lends module:app/evacorder/_EvacOrderRegisterPageBase~_EvacOrderRegisterPageBase# */ {
        // テンプレート文字列
        templateString: template,

        // 発信元の市町村コード
        _municipalityCdFrom: null,

        changeMunicipalityCd: null,
        /**
         * 各開設フラグ
         */
        _floodShFlg: '0',
        _sedimentDisasterShFlg: '0',
        _stormSurgeShFlg: '0',
        _earthquakeShFlg: '0',
        _tsunamiShFlg: '0',
        _fireShFlg: '0',
        _landsideWaterShFlg: '0',
        _volcaneShFlg: '0',

        _districtArray: [],

        // 緊急速報メール配信の判断に用いる。
        // 避難情報が自主避難より格上であり、格上げまたは範囲拡大の場合trueとする。
        _isUrgentMailTarget: false,

        // 災害シミュレーション用
        // _scenarioId: null,
        // _simulateFlg: false,
        // _eventType: null,
        // _content: null,
        // _timing: null,

        constructor: function() {
            // ベースクラスのコンストラクタを呼ぶ
            // this.inherited(arguments);

            // form用のストアを生成する。
            this.formStore = new IdisRest({
                idProperty: 'evacOrderId',
                target: '/api/evacorders'
            });

            // 避難所一覧用のストアを生成する。
            // this.shelterStore = new IdisRest({
            //     idProperty: 'facilityId',
            //     target: '/api/shelters/opening/districts'
            // });

            // 市町村は大阪市で固定。
            this._municipalityCd = config.municInfo.cityMunicCd;
            this._municipalityCdFrom = config.municInfo.cityMunicCd;
            /* if(UserInfo.getUserType() === USER_TYPE.PREFECTURE){
                this._municipalityCdFrom = this.PREF_MUNICIPALITY_CODE;
            } else {
                var municipalityCds = UserInfo.getMunicipalityCds();
                this._municipalityCdFrom = municipalityCds[0];
            } */
            // FIXME:振興局の場合の措置を記述

//            this._scenarioId = Locator.getQuery().scenarioId;
//            this._simulateFlg = Locator.getQuery().simulateFlg;
//            this._eventType = Locator.getQuery().eventType;
//            this._content = Locator.getQuery().content;
//            this._timing = Locator.getQuery().timing;
        },

        // HTML上にウィジェットが設置されてから呼ばれる
        startup: function() {
            this.inherited(arguments);

            // 市町選択セレクターの初期表示及びボタンの出しわけを設定する。
            // this.initMunicipalitySelector();

            // 組織コードを設定する。
            this.organization.set('value',this._orgCd);


            // 現況情報を最初から表示しておくのでボタンの色を変えておく
            domStyle.set(this.mapCntlBtn, 'backgroundColor', '#F90');

            //初期状態では、避難所検索ボタンは無効化しておく
            //document.getElementById('searchCloseShelterDisabled').style.display = '';
            //document.getElementById('searchCloseShelter').style.display = 'none';

            //domStyle.set(this.detailShelterSearchArea, 'display', 'none');
            //domStyle.set(this.detailSearchOpen, 'display', '');
            //domStyle.set(this.detailSearchClose, 'display', 'none');

            // 市町村変更時の処理
            // 大阪市では市町村変更がない
            /* this.own(topic.subscribe('app/view/form/ManageRegionMunicipalitySelector::selected',
                lang.hitch(this, function(payload) {
                    this._municipalityCd = payload;
            
                    // 県、振興局、政令指定都市だと、処理を進めない。
                    if(this._municipalityCd === config.municInfo.prefMunicCd ||
                        this._municipalityCd === config.municInfo.cityMunicCd ||
                        this._municipalityCd.substr(0,3) === config.municInfo.prefRegCdGudge){
                        return;
                    }
                    // 市町村コードを設定する。
                    this.municipalitySelector.set('value', this._municipalityCd);
            
                    // 地図の初期位置を設定する。
                    this.initLatlng();
            
                    // 地区選択ツリーを再生成して、ツリーの情報に基づいて避難情報選択レイヤー再生成する。
                    this.reCreateTree().then(lang.hitch(this, 'reCreateLayers'));
            
                    // 避難所一覧グリッドを初期化する。
                    //this.initShelterGrid();
                })
            )); */

            // 詳細画面で利用するTipsをセットする
            this.setTipsPopups();
        },

        /**
         * TipsPopupをセットする。
         */
        setTipsPopups: function() {
            // 補足情報
            this.setNoteTips();
            // 発令理由詳細
            this.setIssueReasonTips();
            // 地区情報
            this.setDistrictTips();
        },

        //発令理由が変更された時
        _toggleOtherReason : function(value){
        var form = this.form.get('value');
        if(form.issueReasonType !== null){ //何かが発令理由として選ばれた時
            //domStyle.set('searchCloseShelterDisabled', 'display', 'none');
            //domStyle.set('searchCloseShelter', 'display', '');
            this.issueReason.set('value', Reason.get(value).lalertReason);
        } else { //発令理由が何も指定されなかった時
            //domStyle.set('searchCloseShelterDisabled', 'display', '');
            //domStyle.set('searchCloseShelter', 'display', 'none');
            this.issueReason.set('value', '');
        }
        this.setEvacOrderTypeSelector(form.issueReasonType, this._evacOrderType);
        // 発令理由に応じて、対象世帯数・人数の欄が変わるので、世帯数・人数タブを開く。
        this.tabContainer.selectChild(this.populationTab);
        
        //画面項目の表示制御
//           this.setDispContentByEvacOrderType(form.issueReasonType);
        
        //メッシュ・河川ツリーはクリアしておく。
        this.clearMeshCheck();
        this.clearRiverCheck();
        //地区ツリーも初期化(全地区を表示し、選択状況は変えない)。
        this._preventUpdPopulationStoreFlg = true; //gridの更新は抑止
        this.updateTreeFilter();
        this.updatePopulationStoreAtOnce(); //ここでまとめてgrid更新
        },

        /**
         * 地図上の地区選択レイヤーを初期化する。
         * @returns {Promise} 完了後に解決するPromise
         */
        // 登録画面では現況レイヤーを表示する。
        initDistrictLayer: function(){
            return all({
                // 現況レイヤーを取得して地図上に表示する。
                state: this.showLatestStateLayer().otherwise(function() {
                    // 未登録時は404になるため、失敗も成功扱いにする
                }),
                // 選択レイヤーを取得して地図上に表示する。
                select: this.showSelectLayer()
            });
        },

        /**
         * ツリーを初期化する。
         * TODO ツリー初期化後にexpandAll()メソッドを呼ぶ。
         */
        initTree: function(districtCdList) {
            // ツリーを生成する。
            if (!this.tree) {
                this.tree = new this.treeClass({
                    model: new EvacOrderStoreModel({
                        disasterId: this._disasterId,
                        municipalityCd: this._municipalityCd,
                        filter: function(item){
                            if(districtCdList && districtCdList.length > 0){
                                // 地区に指定がある場合、指定された地区だけを出力
                                return districtCdList.indexOf(item.districtCd) !== -1; 
                            }
                            // 地区に指定がなければ全量を出力
                            return true;
                        }
                    })
                }, this.treeNode);
                this.tree.startup();
            }
            this.own(this.tree);
            this.tree.onLoadDeferred.then(lang.hitch(this, '_updatePopulationStore'));
        // ツリーの作成が終了したら、地図のfilter用に地区ツリーのキャッシュを取得しておく
            this.tree.onLoadDeferred.then(lang.hitch(this, function() {
                this.tree.model.store._getCache().then(lang.hitch(this, function(items) {
                    this._districtTreeCache = items.data;
                    //地区コードからitemに辿れるようにマップを作っておく。
                    this.tree._itemMap = {};
                    array.forEach(this._districtTreeCache, lang.hitch(this, function(item){
                        this.tree._itemMap[item.id] = item;
                    }));
                }));
            }));
            return this.tree.onLoadDeferred;
        },

        /**
         * 地図レイヤーを再作成する。
         * 市町変更時にレイヤーを削除して再作成する。
         */
        reCreateLayers: function() {
            // 地図上のレイヤーを全て削除する。
            var layerControl = this._layerControl;
            Object.keys(layerControl.layers).forEach(lang.hitch(this, function(key){
                layerControl.removeLayerById(key);

            }));
            // 地区レイヤーを追加する。
            this.initDistrictLayer();
        },

        /**
         * 初期表示する市町村のコードを取得する。
         * 市町村システムでは利用しない
         */
        /* initMunicipalitySelector: function(){
            this._municipalityCd = config.municInfo.cityMunicCd;
            if(Locator.getQuery().municipalityCd){
                this._municipalityCd = Locator.getQuery().municipalityCd;
            } else if(UserInfo.getUserType() === USER_TYPE.PREFECTURE) {
                this._municipalityCd = this.DEFAULT_MUNICIPALITY_CODE;
            } else if(UserInfo.getUserType() === USER_TYPE.PREFECTURE) {
                this._municipalityCd = UserInfo.getMunicipalityCds()[0];
            } else if(this._userMunicipalityCds && this._userMunicipalityCds.length > 0){
                this._municipalityCd = this._userMunicipalityCds[0];
            } else {
                this._municipalityCd = this.DEFAULT_MUNICIPALITY_CODE;
            }
            
            if(this._userMunicipalityCds.length === 1) {
                this.municipalitySelector.set('noAllButton', true);
            }
        }, */

        /* onMunicipalityCdChanged: function(municipalitySelector) {

            // 「所属」選択時、かつ前回のセレクタの値が「区」の場合は、地区セレクタの表示を変更しない
            if (this.changeMunicipalityCd && municipalitySelector.value === '') {
                return;
            }
            
            // 前回セレクタの値がない場合、かつ今回「所属」を選択した場合
            // 現状、「所属」は選択しない想定
            if (municipalitySelector.value === '') {
                domStyle.set(this.treeArea, 'display', 'none');
            }else {
                domStyle.set(this.treeArea, 'display', '');
            }
            this.changeMunicipalityCd = municipalitySelector.value;
        }, */

        /**
         * 登録ボタンが押下されてFormのサブミットイベントが発生した際に呼ばれる
         */
        onSubmit: function() {

            // フォームのバリデーションを行う（共通部品）
            if(!this.form.validate()) {
                return false;
            }

            // formデータに災害IDと地区リストをセットする。
            var form = this.form.get('value');

            // 発令理由をセット
            if(!form.issueReasonType){
                form.issueReasonType = this._issueReasonType;
            }
            // 発令内容をセット
            form.evacOrderType = this._evacOrderType;

            // 地区リストをカンマ区切りの文字列としてセットする。
            var districtArray = this._districtArray = this.tree.getCheckedLeafs();
            // 入力チェック
            if(districtArray.length === 0) {
                console.debug('地区が選択されていません');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : '地区が選択されていません'
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return false;
            }

            // 地区のチェック状態を確認する。（ブラウザの処理の都合により、中の子地区が全部チェックされない可能性がある）
            var checkNotCompletedDists = [];
            array.forEach(districtArray, function(district){
                if(!this.tree.isCheckCompleted(district.districtCd)){
                    checkNotCompletedDists.push(district.districtName);
                }
            }, this);
            if( checkNotCompletedDists && checkNotCompletedDists.length > 0){
                // 子地区の処理が終わっていない親地区がある場合、後続処理を実施しないでエラーダイアログを表示する
                var message = '以下の地区について、子地区の設定がされていません。<br>' +
                                '「地区」欄から再度チェックし、全ての子地区がチェックされることを<br>' +
                                '確認してから、登録ボタンを押してください。<br><br>';
                message += '地区：' + checkNotCompletedDists.join('、');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : message
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return false;
            }

            //var selectedIdList = this.shelterGrid.getSelectedIdList();

            // 地区のエラーチェックを行う。
            var nonIssuedDistNameList = this.checkDistricts(form, districtArray, true, null);
            if( nonIssuedDistNameList && nonIssuedDistNameList.length > 0){
                // 避難情報が発令されていない/解除済みの地区に対して解除しようとしている場合
                // エラーダイアログを表示
                var content = '以下の地区には、' + Reason.get(form.issueReasonType).name +
                    'の避難情報が発令されていないか、発令時刻より前の時間が指定されているため、<br>解除できません。<br><br>';
                content += '地区：' + nonIssuedDistNameList.join('、');
                if (!this.infoDialog) {
                    this.infoDialog = new InfoDialog({
                        title : 'エラー',
                        content : content
                    });
                }
                this.infoDialog.show();
                this.infoDialog=null;
                return false;
            }

            // 選択された地区を変換する。
            form.districtList = this.convertDistrictArray(districtArray, form.evacOrderType,
                                                                form.issueReasonType, true);

            // Lアラート連携用の地区リストを作成する。
            form.evacOrderLalertAreaMap =
                this.createLalertAreaList(this._districtTreeCache, districtArray, null, form, false, false, null);



            // 避難情報の登録
            // 災害IDをセットする。
            form.disasterId = this._disasterId;

            // 大阪市の場合は、固定の市町村コードをセットする
            form.municipalityCd = this._municipalityCd;

            if(!form.issueReasonType){
                form.issueReasonType = this._issueReasonType;
            }

            form.evacOrderType = this._evacOrderType;
            // 避難対象人数、避難対象世帯数をセットする。
            form.evaqueeNum = this.evaqueeNumNode.innerHTML;
            form.evacHouseholdNum = this.evacHouseholdNumNode.innerHTML;

            // 避難所情報
            // 避難所の施設IDをカンマ区切りの文字列で設定する。
            //form.facilityIds = selectedIdList.join(',');
            // 「発令理由」を「開設理由」として登録
            // 「その他」が選択されている場合は「開設理由」を登録しない
            //this.convertOpeningReason();
            // 個別の避難所登録情報を格納する
            // form.data = {
            //     items: array.map(selectedIdList, function(facilityId) {
            //         return {
            //             disasterId: this._disasterId,
            //             facilityId: facilityId,
            //             shelterStartTime: form.evacOrderTimestamp
            //             // floodShFlg: this._floodShFlg,
            //             // sedimentDisasterShFlg: this._sedimentDisasterShFlg,
            //             // stormSurgeShFlg: this._stormSurgeShFlg,
            //             // earthquakeShFlg: this._earthquakeShFlg,
            //             // tsunamiShFlg: this._tsunamiShFlg,
            //             // fireShFlg: this._fireShFlg,
            //             // landsideWaterShFlg: this._landsideWaterShFlg,
            //             // volcaneShFlg: this._volcaneShFlg
            //         };
            //     }, this)
            // };

            // 避難所の開設日時は避難情報の発令日時と同じにする。
            form.shelterStartTime = form.evacOrderTimestamp;
            //メッシュ・河川のチェック状況
            form.meshCdList = this._checkedMeshCdList;
            form.riverCdList = this._checkedRiverCdList;

            this._evacOrderForm = form;
            this.showEvacOrderDialog4Register(form);
            // if(this._simulateFlg === 'true'){
            //     this.registerEvent(form);
            // } else {
            //     this.showEvacOrderDialog4Register(form);
            // }
            // onSubmitのデフォルトのイベントをキャンセルする。
            return false;
        },


        /**
         * 避難情報を発令(訂正や取り消しでない)するときに、Lアラート配信(避難情報)ダイアログを表示する。
         */
        showEvacOrderDialog4Register: function(form) {

            all({
                // 情報配信画面に渡す避難所開設情報を構築する
                //shelterArgs: this._getLalertShelterArgs(form.facilityIds),
                shelterArgs: null,
                // 情報配信画面に渡す避難状況情報を取得する
                evacOrderArgs: this._getLalertEvacOrderWholeCheck(form, false),
                // 緊急メール画面に渡す避難状況情報を取得する
                urgentMailArgs: this._getUrgentMailArgs(this._municipalityCdFrom)
            }).then(lang.hitch(this, function(lalertArgs) {

                // 万が一、クライアントのチェックツリーのエラー/全域判定ロジックの失敗で、
                // 地区情報が返ってこなかった場合は、警告ダイアログを出す。
//                if(!lalertArgs.evacOrderArgs.evaqOrderData ||
//                    lalertArgs.evacOrderArgs.evaqOrderData.length === 0){
//                    console.debug('対象地区がありません');
//                    if (!this.infoDialog) {
//                        this.infoDialog = new InfoDialog({
//                            title : 'エラー',
//                            content : '対象地区がありません。「地区」欄の選択内容をご確認ください。<br>' +
//                                    'チェックした地区が子地区を持つ場合、子地区のチェック状態もお確かめください。'
//                        });
//                    }
//                    this.infoDialog.show();
//                    this.infoDialog=null;
//                    return false;
//                }

                // 避難所引数内の施設IDと避難所引数を外に出す
                if (lalertArgs.shelterArgs) {
                    lalertArgs.facilityId = lalertArgs.shelterArgs.facilityId;
                    lalertArgs.shelterArgs = lalertArgs.shelterArgs.shelterArgs;
                }
                // Lアラート用の情報を付与する
                // lalertArgs.evacOrderArgs.evaqOrderData =
                //     this.addLalertProperties(lalertArgs.evacOrderArgs.evaqOrderData);
                // 緊急速報メールなどの文面用にリストを作成する
                lalertArgs.districtList = form.districtList;

                // ダイアログの最初の子要素が登録画面
                var dialog = this.evacOrderRegisterDialog.getChildren()[0];
                // ダイアログのsendAndRegisterイベントを受け取れるようにする
                dialog.on('sendandregister', lang.hitch(this, function(evt) {
                    // 避難情報・避難所情報の一括登録 ＋ 外部連携配信を一気に行う。
                    return this.sendAndRegister(evt);
                }));
                // ダイアログの画面を初期化する。
                var isIssue =
                    EvacOrderUtil.isReleased(form.evacOrderType) ? false : true;
                dialog.initDialog(lalertArgs, isIssue).then(lang.hitch(this,function(){
                    // ダイアログを破棄できないように設定する。
                    this.evacOrderRegisterDialog.set('closable', false);
                    // ダイアログを表示する。
                    this.evacOrderRegisterDialog.show();
                }));

            }), lang.hitch(this, function(error){
                console.error(error);
                this.chain.infoError(error);
            }));
        },


        // 災害シミュレーションイベントの登録
        // registerEvent: function(form) {
        //     delete form.evacOrderLalertAreaMap;
        //     delete form.sendPostForms;
        //     var subSendData = {};
        //     subSendData.municipalityName = this.municipalitySelector.domNode.innerText.replace('選択','');
        //     subSendData.evacOrderTypeName = this.evacOrderType.domNode.innerText;
        //     subSendData.issueReasonTypeName = this.issueReasonType.domNode.innerText;
        //     // 災害シミュレーションのイベントとして登録する場合
        //     var jsonStr = json.stringify({
        //         evacOrderPostForm: form,
        //         evacOrderConversionForm: subSendData,
        //         scenarioId: this._scenarioId,
        //         eventType: this._eventType,
        //         content: this._content,
        //         timing: this._timing
        //     });
        //     var message = 'イベント登録します。';
        //     this.chain.confirm(message, function(chain) {
        //         var promise = Requester.post('/api/simulations/event/', {
        //             data: jsonStr,
        //             headers: {'Content-Type': 'application/json; charset=utf-8'},
        //             handleAs: 'json',
        //             preventCache : true
        //         }).then(lang.hitch(this, function() {
        //             console.debug('success register evacorder event');
        //             chain.infoComplete(function() {
        //                 Router.moveTo('simulation/event', {scenarioId: this._scenarioId});
        //             });
        //         }, function(error) {
        //             console.log('error register evacorder event');
        //             chain.infoError(error);
        //         }));
        //         //ローダーの表示
        //         Loader.wait(promise);
        //     });
        // },

        sendAndRegister: function(info){


            var message = null;
            var sendPostForms = [];

            // 確認ダイアログ内で設定した情報を、避難情報登録フォームに反映させる
            this._evacOrderForm.note = info.evacOrderInfo.lalertForm.complementaryInfo;
            this._evacOrderForm.twitterFlg = info.evacOrderInfo.twitterFlg;
            this._evacOrderForm.prefMailFlg = info.evacOrderInfo.prefMailFlg;
            this._evacOrderForm.emergencyPushFlg = info.evacOrderInfo.emergencyPushFlg;
            this._evacOrderForm.twitterBodyText = info.evacOrderInfo.twitterMessage; //Twitter用本文
            this._evacOrderForm.prefMailBodyText = info.evacOrderInfo.prefMailBodyText; //県防災情報メール用本文
            this._evacOrderForm.subject = info.evacOrderInfo.prefMailSubject; //県防災情報メール用件名
            this._evacOrderForm.emergencyPushSubject = info.evacOrderInfo.emergencyPushSubject; //緊急情報配信(防災アプリ)用件名
            this._evacOrderForm.emergencyPushBodyText = info.evacOrderInfo.emergencyPushBodyText; //緊急情報配信(防災アプリ)用本文
            delete this._evacOrderForm.evacOrderLalertAreaMap;

            array.forEach(this._evacOrderForm.districtList, function(item){
                //削る項目
                delete item.wardMunicipalityName;
            }, this);

            if(info.evacOrderInfo.wholeAreaDistrictName){
                // 全域判定結果
                this._evacOrderForm.wholeAreaDistrictName = info.evacOrderInfo.wholeAreaDistrictName;
            }

            sendPostForms.push(info.evacOrderInfo.lalertForm);
            if(info.shelterInfo && info.shelterInfo.lalertForm){
                this._evacOrderForm.data.twitterFlg = info.shelterInfo.twitterFlg;
                this._evacOrderForm.data.prefMailFlg = info.shelterInfo.prefMailFlg;
                this._evacOrderForm.data.emergencyPushFlg = info.shelterInfo.emergencyPushFlg;
                this._evacOrderForm.data.sendPostForm = info.shelterInfo.lalertForm;
            }
            if(info.urgentMailInfo){
//                sendPostForms.push(info.urgentMailInfo);
                array.forEach(info.urgentMailInfo, function(urgentMailForm) {
                    sendPostForms.push(urgentMailForm);
                });
            }
            this._evacOrderForm.sendPostForms = sendPostForms;
            // 避難所開設情報が設定されているか確認する。
            // 設定されていない場合はダイアログを警告メッセージに変更する。
            message = '選択した避難情報を登録します。<br>' +
                            '最新の世帯数・人口となっているか、また発令日時が正しいか、ご確認ください。<br>' +
                            'よろしければ、OKボタンを押してください。';
            // if(!this._evacOrderForm.facilityIds || this._evacOrderForm.facilityIds === ''){
            //     message = '<font color="red">避難所開設情報が登録されていません。</font> 避難情報のみを登録します。<br>' +
            //             '最新の世帯数・人口となっているか、また発令日時が正しいか、ご確認ください。<br>' +
            //             'よろしければ、OKボタンを押してください。';
            // }
            this.chain.confirm(message, lang.hitch(this, function(chain){
                // 最初に、DBに避難情報・避難所情報を登録する。Lアラート・緊急速報メール以外の外部配信もここで行われる。
                var jsonStr = json.stringify(this._evacOrderForm);
                var promise = Requester.post('/api/evacorders', {
                    data: jsonStr,
                    headers: {'Content-Type': 'application/json; charset=utf-8'},
                    handleAs: 'json',
                    preventCache : true
                }).then(lang.hitch(this, function(data){
                    var message = '登録・配信が完了しました。';
                    if(data.status !== null && data.status !== 200 && data.status !== 201) {
                        if(data.status === 404){
                            message += '<br>大阪府システムとの通信に失敗したため、連携が行われませんでした。';
                        } else if(data.status === 500){
                            // 災害名が存在しない場合
                            if(data.errorCode === '11'){
                                message += '<br>大阪府システムで対応する災害名が存在しないため、連携が行われませんでした。';
                            // 登録不可の災害名の場合
                            } else if(data.errorCode === '12'){
                                message += '<br>大阪府システムでは災害情報が登録不可の災害であるため、連携が行われませんでした。';
                            } else {
                                message += '<br>大阪府システムで情報の連携に失敗しました。';
                            }
                        } else {
                            message += '<br>大阪府システムで情報の連携に失敗しました。';
                        }
                    }

                    chain.info(message, '完了', lang.hitch(this, function(){
                        this.evacOrderRegisterDialog.getChildren()[0].closeDialog();
                        Router.moveTo('evacorder', {
                            municipalityCd: this._municipalityCd
                        });
                    }));
                }), lang.hitch(this, function(error){
                    chain.infoError(error);
                }));
                Loader.wait(promise);
            }));
        },

        // 避難所情報を初期表示する。
        // initShelterGrid: function() {
        //     this.updateGridQuery();
        // },

        // queryShelterSimply: function(){
        // 
        //     // 地区をセットする。避難地区はカンマ区切りに変換してから
        //     var districtArray = this.tree.getCheckedLeafs();
        //     var districtCds = this.parseArray2String(districtArray);
        // 
        //     // 「その他」が選択されている場合は全避難所を表示
        //     // 選択された開設理由からフラグをセット
        //     var shelterType = ( this.issueReasonType.getValue() !== '00') ?
        //                             this.issueReasonType.getValue() : null;
        // 
        //     this.updateGridQuery(districtCds, shelterType, null);
        // },

        // queryShelterAdvanced: function(){
        //     var districtCds = this.districtCd.get('value');
        //     if(!districtCds){
        //         if (!this.infoDialog) {
        //             this.infoDialog = new InfoDialog({
        //                 title : 'エラー',
        //                 content : '避難所検索対象の地区が選択されていません'
        //             });
        //         }
        //         this.infoDialog.show();
        //         this.infoDialog=null;
        //         return false;
        //     }
        // 
        //     //対象災害
        //     var shelterTypeList = [];
        //     if(this.floodShSchFlg.checked){
        //       shelterTypeList.push('02');
        //     }
        //     if(this.sedimentDisasterShSchFlg.checked){
        //       shelterTypeList.push('01');
        //     }
        //     if(this.stormSurgeShSchFlg.checked){
        //       shelterTypeList.push('05');
        //     }
        //     if(this.earthquakeShSchFlg.checked){
        //       shelterTypeList.push('03');
        //     }
        //     if(this.tsunamiShSchFlg.checked){
        //       shelterTypeList.push('04');
        //     }
        //     if(this.fireShSchFlg.checked){
        //       shelterTypeList.push('06');
        //     }
        //     if(this.landsideWaterShSchFlg.checked){
        //       shelterTypeList.push('07');
        //     }
        //     if(this.volcaneShSchFlg.checked){
        //       shelterTypeList.push('08');
        //     }
        //     var shelterTypes = shelterTypeList.join(',');
        // 
        //     //避難所区分
        //     var shelterCategoryList = [];
        //     if(this.eDesignatedEvacShFlg.checked){
        //         shelterCategoryList.push('01');
        //     }
        //     if(this.designatedEvacShFlg.checked){
        //         shelterCategoryList.push('02');
        //     }
        //     if(this.welfareEvacShFlg.checked){
        //         shelterCategoryList.push('03');
        //     }
        //     if(this.temporaryEvacShFlg.checked){
        //         shelterCategoryList.push('04');
        //     }
        //     if(this.otherEvacShFlg.checked){
        //         shelterCategoryList.push('05');
        //     }
        //     var shelterCategories = shelterCategoryList.join(',');
        // 
        //     this.updateGridQuery(districtCds, shelterTypes, shelterCategories);
        // },

        // 避難所グリッドを更新する
        // updateGridQuery: function(districtCds, shelterTypes, shelterCategories) {
        //     // 検索条件を格納するGrid用フィルターを作成
        //     var filter = new this.shelterStore.Filter();
        // 
        //     // 災害IDをセットする。
        //     filter = filter.eq('disasterId', this._disasterId);
        // 
        //     filter = filter.eq('districtCds', districtCds ? districtCds : '');
        //     // 「発令理由」に一致する「対象災害」を持つ避難所を検索
        //     if (shelterTypes) {
        //         filter = filter.eq('shelterTypes', shelterTypes);
        //     }
        // 
        //     //「避難所区分」に一致する「対象災害」を持つ避難所を検索
        //     if (shelterCategories) {
        //         filter = filter.eq('shelterCategories', shelterCategories);
        //     }
        // 
        //    // filterに対応するcollectionを取得
        //    //var collection = this.shelterStore.filter(filter);
        // 
        //    // collectionをグリッドにセットする（サーバーにリクエストされる）
        //    //this.shelterGrid.set('collection', collection);
        // 
        // },

        /**
        /* 選択された開設理由に応じてフラグをセット
        */
        convertOpeningReason: function() {
            // 避難情報の種別に応じて避難所の開設理由をセットする。
            // その他の場合には開設理由をセットしない。
            var openingReason = this.issueReasonType.getValue();
            this._sedimentDisasterShFlg = (openingReason === '01') ? '1' : '0';
            this._floodShFlg = (openingReason === '02') ? '1' : '0';
            this._earthquakeShFlg = (openingReason === '03') ? '1' : '0';
            this._tsunamiShFlg = (openingReason === '04') ? '1' : '0';
            this._stormSurgeShFlg = (openingReason === '05') ? '1' : '0';
        },

        /**
         * 添付ファイル選択時に呼ばれる。
         */
        loadAttachFile: function() {
            //TODO 避難情報を参考に実装する
            console.debug('EvacOrderRegisterPage#loadAttachFile()');
        },



        // 継承先でオーバーライドするため、空のメソッドだけ作っておく。
        onCancelButtonClick: function(){
            console.debug('dummy:onCancelButtonClick');
        },

        mergeProperties: function() {
            // TODO 削除予定 サーバ側に作成ロジックを移動させた。
            // DITの避難帳票読み込み処理の箇所を確認して恒久対応を行う。
            return {};
        },

        //詳細検索を表示ボタンをクリックした時の挙動
        onOpenFilterClick: function() {
            domStyle.set(this.detailShelterSearchArea, 'display', '');
            domStyle.set(this.detailSearchOpen, 'display', 'none');
            domStyle.set(this.detailSearchClose, 'display', '');

            //domStyle.set('searchCloseShelterDisabled', 'display', 'none');
            //domStyle.set('searchCloseShelter', 'display', 'none');
            this.borderContainer.resize();
        },

        //詳細検索を閉じるボタンをクリックした時の挙動
        onCloseFilterClick: function() {
            domStyle.set(this.detailShelterSearchArea, 'display', 'none');
            domStyle.set(this.detailSearchOpen, 'display', '');
            domStyle.set(this.detailSearchClose, 'display', 'none');

            // if(this.issueReasonType.get('value') !== null){
            //     domStyle.set('searchCloseShelterDisabled', 'display', 'none');
            //     domStyle.set('searchCloseShelter', 'display', '');
            // } else {
            //     domStyle.set('searchCloseShelterDisabled', 'display', '');
            //     domStyle.set('searchCloseShelter', 'display', 'none');
            // }
            this.borderContainer.resize();
        },

        // 人口タブを開く。
        showPopulationTab: function(evt){
            evt.preventDefault();
            this.tabContainer.selectChild(this.populationTab);
        }
    });
});
