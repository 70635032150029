/**
 * 招集メールテンプレート選択ダイアログ用モジュール。
 * @module app/convocation/view/mail/ConvoTempInsertDialog
 */
define([
    'module', // モジュールのパスを返す
    'dojo/_base/declare', // Dojoのクラス定義用モジュール
    'dojo/text!./templates/ConvoTempInsertDialog.html', // テンプレート文字列
    'idis/view/dialog/DialogChain',
    'idis/store/IdisRest',
    'idis/view/page/_PageBase', // 共通基底クラス
    'idis/view/Loader',
    'dojo/_base/lang',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'idis/view/form/Button',
    'app/convocation/view/mail/ConvoTempInsertGrid',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/form/Form',
    'idis/view/form/AclButton',
    './ConvoMailTempRegisterDialog',
    './ConvoMailTempDetailDialog',
    './ConvoSendMailDialog'
], function(module, declare, template, DialogChain, IdisRest, _PageBase, Loader, lang) {
    /**
     * 招集メールテンプレート選択ダイアログ。
     * @class 招集メールテンプレート選択ダイアログ
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
        /** @lends module:idis/view/page/ConvoTempInsertDialog~ConvoTempInsertDialog# */
        {
            // テンプレート文字列
            templateString: template,

            store: null,

            // ルート要素に付与されるCSS
            baseClass: 'idis-Page idis-Page--convocation',

            // テンプレートコード
            distributionTmplCd: null,

            /**
             * 変数の初期化。
             */
            constructor: function() {
                // データ格納用オブジェクト
                 this.store = new IdisRest({
                     idProperty: 'distributionTmplCd',
                     target: '/api/convoMails/temps'
                 });
                // ダイアログ連鎖を登録
                // 引数に与えたウィジェットのthis.ownを呼び出し、
                // ウィジェットが破棄された際にダイアログ連鎖が破棄されるようになる
                this.chain = DialogChain.get(this);
            },

            /**
             * DOMノードを生成するためのメソッド。
             */
            buildRendering: function() {
                this.inherited(arguments);
                // 自分が削除された時は関連ダイアログも削除する
                this.initRegisterDialog();
            },

            /**
             * 新規登録ダイアログのformが投稿された際の挙動を設定する。
             */
            initRegisterDialog: function() {
                // 新規登録ダイアログの最初の子要素が画面の情報
                var dialog = this.convoMailTempRegisterDialog;
                var page = dialog.getChildren()[0];

                // 新規登録ダイアログからのregisterイベントを取得
                page.on('register', lang.hitch(this, function(evt) {
                    var self = this;
                    // 登録確認ダイアログの表示
                    this.chain.confirmAdd(function(chain) {
                        // 登録処理完了まではローディング画面を表示
                        Loader.wait(this.store.add(evt.value)).then(function() {
                            // 登録処理成功時
                            self.tempInsertGrid.set('collection', self.store);
                            // 新規登録ダイアログを閉じる
                            dialog.hide();
                            // 完了ダイアログの表示
                            chain.infoComplete();
                            // this.convoSendMailDialog.show();
                        }, function(error) {
                            // 登録処理失敗時
                            // エラーダイアログの表示
                            chain.infoError(error);
                        });
                    });
                }));
            },

            /**
             * 新規登録ダイアログを表示する。
             */
            showConvoMailTempRegisterDialog: function(){
                console.log('[新規登録]ボタンがクリックされました');
                // var page = this.convoMailTempRegisterDialog.getChildren()[0];
                // 画面上のフォームをリセット
                // （ダイアログ内画面のテンプレートHTMLでdata-dojo-attach-point="form"指定済み）
                // page.form.reset();
                // ダイアログを表示
                this.convoMailTempRegisterDialog.show();

                // page.form.set('value',item);
            }
        });
});
