define([
    'module',
    'dojo/_base/declare',
    'dojo/text!./templates/DrawEditDialog.html',
    'idis/view/_IdisWidgetBase',
    'dijit/Dialog',
    'dojox/lang/functional/object',
    'dojo/dom-class',
    'dojo/dom-construct',
    'dojo/_base/lang',
    'dojo/topic',
    'dojo/on',
    'dojo/dom-style',
    'idis/view/form/Button',
    'idis/service/Requester',
    'idis/view/dialog/DialogChain',
    'idis/model/UserInfo',
    'idis/view/Loader',
    'idis/view/draw/HistoricalMap',
    'idis/view/draw/_DrawUtil',
    'idis/util/FilesUtils',
    // 以下、変数で受けないモジュール
    'dojox/form/Uploader',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/Textarea',
    'dijit/TooltipDialog',
    'dijit/ColorPalette',
    'dijit/form/DropDownButton',
    'dijit/form/HorizontalSlider',
    'idis/view/form/DateTimeInput',
    'idis/view/form/ColorSelector',
    'idis/view/form/OpacitySlider',
    // FIXME アプリ設定が混ざっている
    './DrawSelectIconDialog'
], function(module, declare, template, _IdisWidgetBase, Dialog, funcObj, domClass, domConstruct,
    lang, topic, on, domStyle, Button, Requester, DialogChain, UserInfo, Loader, HistoricalMap, Util, FilesUtils) {
    var content = declare('_DrawEditDialogContent', _IdisWidgetBase, {
        templateString : template,
        baseClass: 'drawDialog-Container',
        widgetsInTemplate : true
    });

    // FIXME 付箋とその他オブジェクトでレイヤーのプロパティーが異なるので共通化

    var container = declare(module.id.replace(/\//g, '.'), [Dialog], {

        'class': 'drawEditDialog-NonModal',

        // 編集種別（'polygon', 'polygonRadius', 'polyline', 'note', 'icon'）
        type : null,

        // Pub/SubのURL
        RESET_MODE_ID  : '/app/draw/DrawPanel::resetMode',
        RM_EDIT_EVT    : '/app/draw/DrawPanel::removeEditEvent',
        RADIUS_DIALOG  : '/app/draw/DrawPanel::drawRadius',

        // defaultのOption
        _defaultOptions: {
            color       : '#3388ff',
            opacity     : 1,    // 0 ~ 1
            weight      : 4,
            fillColor   : '#8899ff',
            fillOpacity : 0.2,  // 0 ~ 1
            title       : '',
            description : '',
            fileList    : [],
            radius      : null,
            unit        : null,
            // 以下、付箋用
            height      : '50px',
            width       : '200px',
            fontSize    : 14,
            fontColor   : '#000000',
            // 以下、アイコン用
            iconUrl     : '080.png',
            iconSize    : 1.0
        },

        // 前回のOptionを引き継ぐ
        _currentOptions: {
            color       : null,
            opacity     : null, // 0 ~ 1
            weight      : null,
            fillColor   : null,
            fillOpacity : null, // 0 ~ 1
            title       : '',
            description : '',
            fileList    : [],
            radius      : null,
            unit        : null,
            // 以下、付箋用
            height      : null,
            width       : null,
            fontSize    : null,
            fontColor   : null,
            // 以下、アイコン用
            iconUrl     : null,
            iconSize    : null
        },

        // 編集前のOptionを管理する
        _lastOptions: {
            color       : '',
            opacity     : 0,    // 0 ~ 1
            weight      : 0,
            fillColor   : '',
            fillOpacity : 0,    // 0 ~ 1
            title       : '',
            description : '',
            fileList    : [],
            radius      : null,
            unit        : null,
            // 以下、付箋用
            height      : '50px',
            width       : '200px',
            fontSize    : 14,
            fontColor   : '',
            // 以下、アイコン用
            iconUrl     : '',
            iconSize    : 0
        },

        //イベント破棄用
        _events: null,

        // icon用imageまでのパス
        _defaultIconPath: '/images/draw/icon/',

        // 半径変更フラグ
        _changeRadiusFlg: null,

        /**
         * コンストラクター
         * @param {Object} options - type('polygon', 'polygonRadius', 'polyline', 'note', 'icon') is required
         */
        constructor : function(options){
            lang.mixin(this, options);

            // typeの指定不備の場合は終了
            if (!this._isValidType()) {
                return;
            }

            // 編集種別に応じた表示
            switch (this.type) {
            case 'polygon':
            case 'polygonRadius':
                this.title = '図形の編集';
                break;
            case 'polyline':
                this.title = '線の編集';
                break;
            case 'note':
                this.title = '付箋の編集';
                break;
            case 'icon':
                this.title = 'アイコンの編集';
                break;
            default:
                break;
            }

            this.inherited(arguments);
            this.inner = new content();
            this.chain = DialogChain.get(this);
            this._events = [];
        },

        /**
         * type（編集種別）に応じたinner表示の設定
         * ウィジェットのプロパティ挿入後実行される
         */
        postMixInProperties : function(){
            this.inherited(arguments);

            // 編集種別に応じた表示
            switch (this.type) {
            case 'polygon':
            case 'polygonRadius':
                domStyle.set(this.inner.lineArea, 'display', 'block');
                domStyle.set(this.inner.fillArea, 'display', 'block');
                domStyle.set(this.inner.titleArea, 'display', 'block');
                domStyle.set(this.inner.commentArea, 'display', 'block');
                domStyle.set(this.inner.fileListArea, 'display', 'block');
                break;
            case 'polyline':
                domStyle.set(this.inner.lineArea, 'display', 'block');
                domStyle.set(this.inner.titleArea, 'display', 'block');
                domStyle.set(this.inner.commentArea, 'display', 'block');
                domStyle.set(this.inner.fileListArea, 'display', 'block');
                break;
            case 'note':
                domStyle.set(this.inner.lineArea, 'display', 'block');
                domStyle.set(this.inner.fillArea, 'display', 'block');
                domStyle.set(this.inner.fontArea, 'display', 'block');
                domStyle.set(this.inner.commentArea, 'display', 'block');
                domStyle.set(this.inner.fileListArea, 'display', 'block');
                break;
            case 'icon':
                domStyle.set(this.inner.iconArea, 'display', 'block');
                domStyle.set(this.inner.titleArea, 'display', 'block');
                domStyle.set(this.inner.commentArea, 'display', 'block');
                domStyle.set(this.inner.fileListArea, 'display', 'block');
                break;
            default:
                break;
            }
            // UploaderはUserInfoから権限取得
            if(UserInfo.getAcl().WRITE) {
                domStyle.set(this.inner.attachFileArea, 'display', 'block');
            } else {
                domStyle.set(this.inner.drawAttachCaution, 'display', 'block');
            }
        },

        postCreate : function(){
            this.inherited(arguments);
            this.set('content', this.inner);
        },

        /**
         * type（編集種別）の指定が正しいかチェック
         */
        _isValidType : function(){

            var isValid = false;

            // モードの指定がない
            if (this.type === null) {
                console.error('type is required.');
                return isValid;
            }

            // モード値不正
            var types = ['polyline', 'polygon', 'polygonRadius', 'note', 'icon'];
            for (var i = 0; 0 < types.length; i++) {
                if (types[i] === this.type) {
                    isValid = true;
                    break;
                }
            }
            if (!isValid) {
                console.error('type is invalid.');
            }

            return isValid;
        },

        // Override
        show : function(mode, layerObj){
            this.inherited(arguments);

            // FileList = liのList = HTMLCollectionの初期化
            var liLists = Util._toArray(this.inner.fileListArea.children);
            liLists.forEach(lang.hitch(this, function(li){Util._rmElement(li);}));

            this._changeRadiusFlg = false;
            var regexp = /circle/ig;

            // 作図オブジェクトの情報からダイアログ内の表示を制御する
            if (mode === 'new') {
                // 新規作図の場合
                // 前回の設定値をセット. 未定義であればデフォルトを設定.

                var color = this._currentOptions.color !== null ?
                    this._currentOptions.color : this._defaultOptions.color;
                this.inner.lineColor.setColor(color);

                var opacity = this._currentOptions.opacity !== null ?
                    this._currentOptions.opacity : this._defaultOptions.opacity;
                // OpacitySliderの設定は%なので変換
                this.inner.lineOpacity.setOpacity(Math.floor(100 - opacity * 100));

                var width = this._currentOptions.weight !== null ?
                    this._currentOptions.weight : this._defaultOptions.weight;
                this.inner.lineWidth.set('value', width);

                var fillColor = this._currentOptions.fillColor !== null ?
                    this._currentOptions.fillColor : this._defaultOptions.fillColor;
                this.inner.fillColor.setColor(fillColor);

                var fillOpacity = this._currentOptions.fillOpacity !== null ?
                    this._currentOptions.fillOpacity : this._defaultOptions.fillOpacity;
                // OpacitySliderの設定は%なので変換
                this.inner.fillOpacity.setOpacity(Math.floor(100 - fillOpacity * 100));

                var fontColor = this._currentOptions.fontColor !== null ?
                    this._currentOptions.fontColor : this._defaultOptions.fontColor;
                this.inner.fontColor.setColor(fontColor);

                var fontSize = this._currentOptions.fontSize !== null ?
                    this._currentOptions.fontSize : this._defaultOptions.fontSize;
                this.inner.fontSize.set('value', fontSize);

                var unit = layerObj.unit;
                var radius = null;
                switch(unit) {
                case '0':
                case '1':
                    this.inner.radius.set('value', layerObj.radius);
                    this.inner.unitSwitch.set('value', unit);
                    radius = layerObj.radius;
                    break;
                default:
                    this.inner.radius.set('value', '');
                    this.inner.unitSwitch.set('value', '0');
                    break;
                }

                if(regexp.test(layerObj.options.drawType)) {
                    domStyle.set(this.inner.radiusArea, 'display', 'block');
                } else {
                    domStyle.set(this.inner.radiusArea, 'display', 'none');
                }

                // 作図オブジェクトのスタイルを再設定
                if (this.type === 'icon') {
                    var iconUrl = this._currentOptions.iconUrl !== null ?
                        this._currentOptions.iconUrl : this._defaultIconPath + this._defaultOptions.iconUrl;
                    var iconSize = this._currentOptions.iconSize !== null ?
                        this._currentOptions.iconSize : this._defaultOptions.iconSize;
                    this.inner.selectedIcon.src = iconUrl;
                    this.inner.iconSize.set('value', iconSize);
                    layerObj.setIconStyle({
                        'iconUrl'  : iconUrl,
                        'iconSize' : Util._iconUtil('set', iconUrl, iconSize)
                    });
                } else if (this.type === 'note') {
                    layerObj.setNoteStyle({
                        'borderColor'   : color,
                        'borderOpacity' : opacity,
                        'borderWidth'   : width,
                        'fillColor'     : fillColor,
                        'fillOpacity'   : fillOpacity,
                        'fontColor'     : fontColor,
                        'fontSize'      : fontSize,
                        'fileList'      : [],
                        'comment'       : ''
                    });
                } else {
                    layerObj.setStyle({
                        'color'       : color,
                        'opacity'     : opacity,
                        'weight'      : width,
                        'fillColor'   : fillColor,
                        'fillOpacity' : fillOpacity,
                        'radius'      : radius,
                        'unit'        : unit
                    });
                }

                this.inner.title.set('value', '');
                this.inner.comment.set('value', '');

                // 矢印の場合、レイヤーoptionsに矢印ヘッドの追加、矢印プロパティを変更
                // FIXME 本来はここにあるべきではない。
                if (layerObj.options.drawType === 'arrow') {
                    layerObj.setArrowHead(layerObj.options);
                }

            } else {
                // 編集の場合

                var layerOptions = layerObj.options;

                if(regexp.test(layerOptions.drawType)) {
                    domStyle.set(this.inner.radiusArea, 'display', 'block');
                } else {
                    domStyle.set(this.inner.radiusArea, 'display', 'none');
                }

                // 編集前の設定を保持
                this._lastOptions.fillColor   = layerOptions.fillColor;
                this._lastOptions.fillOpacity = layerOptions.fillOpacity;
                // FIXME 以下のプロパティーは付箋とそれ以外でレイヤーが持つプロパティー名が異なるため、分岐処理している
                if (this.type === 'note') {
                    this._lastOptions.color       = layerOptions.borderColor;
                    this._lastOptions.opacity     = layerOptions.borderOpacity;
                    this._lastOptions.weight      = layerOptions.borderWidth;
                    this._lastOptions.fontSize    = layerOptions.fontSize;
                    this._lastOptions.fontColor   = layerOptions.fontColor;
                    this._lastOptions.description = layerOptions.comment;
                } else if (this.type !== 'icon') {
                    this._lastOptions.color       = layerOptions.color;
                    this._lastOptions.opacity     = layerOptions.opacity;
                    this._lastOptions.weight      = layerOptions.weight;
                    this._lastOptions.title       = layerOptions.title;
                    this._lastOptions.description = layerOptions.description;
                    this._lastOptions.radius      = layerOptions.radius;
                    this._lastOptions.unit        = layerOptions.unit;
                } else {
                    this._lastOptions.iconUrl  = layerOptions.iconUrl;
                    this._lastOptions.iconSize = Util._iconUtil(
                        'get', layerOptions.iconUrl, layerOptions.iconSize);
                    this._lastOptions.title       = layerOptions.title;
                    this._lastOptions.description = layerOptions.description;
                }

                // ダイアログに設定
                this.inner.fillColor.setColor(layerOptions.fillColor);
                this.inner.fillOpacity.setOpacity(Math.floor(100 - layerOptions.fillOpacity * 100));

                if (this.type === 'note') {
                    this.inner.lineColor.setColor(layerOptions.borderColor);
                    this.inner.lineOpacity.setOpacity(Math.floor(100 - layerOptions.borderOpacity * 100));
                    this.inner.lineWidth.set('value', layerOptions.borderWidth);
                    this.inner.fontColor.setColor(layerOptions.fontColor);
                    this.inner.fontSize.set('value', layerOptions.fontSize);
                    this.inner.comment.set('value',
                        layerOptions.comment === undefined ? '' : layerOptions.comment);
                } else if (this.type !== 'icon') {
                    this.inner.lineColor.setColor(layerOptions.color);
                    this.inner.lineOpacity.setOpacity(Math.floor(100 - layerOptions.opacity * 100));
                    this.inner.lineWidth.set('value', layerOptions.weight);
                    this.inner.title.set('value', layerOptions.title === undefined ?
                        '' : Util._replaceContent(layerOptions.title));
                    this.inner.comment.set('value', layerOptions.description === undefined ?
                        '' : Util._replaceContent(layerOptions.description));

                    switch(layerOptions.unit) {
                    case '1':
                        this.inner.radius.set('value', layerOptions.radius / 1000);
                        this.inner.unitSwitch.set('value', layerOptions.unit);
                        break;
                    case '0':
                        this.inner.radius.set('value', layerOptions.radius);
                        this.inner.unitSwitch.set('value', layerOptions.unit);
                        break;
                    default:
                        this.inner.radius.set('value', '');
                        this.inner.unitSwitch.set('value', '0');
                    }
                } else {
                    this.inner.selectedIcon.src = layerOptions.iconUrl;
                    this.inner.iconSize.set('value', Util._iconUtil(
                        'get', layerOptions.iconUrl, layerOptions.iconSize));
                    this.inner.title.set('value', layerOptions.title === undefined ?
                        '' : Util._replaceContent(layerOptions.title));
                    this.inner.comment.set('value', layerOptions.description === undefined ?
                        '' : Util._replaceContent(layerOptions.description));
                }

                // kmlは完全外部とのやり取りを想定しているため、fileListを持たない
                if (layerOptions.fileList) {
                    layerOptions.fileList.forEach(lang.hitch(this, function(fileInfo){
                        this._createFileListDom(fileInfo);
                    }));
                } else {
                    layerOptions.fileList = [];
                }
                this._lastOptions.fileList = layerOptions.fileList;

            }

            // 作図オブジェクトに対して編集中のイベントを定義
            this._setEvents(mode, layerObj);
        },

        // 編集中の作図オブジェクトに対するイベント定義
        _setEvents: function(mode, layerObj){

            // アイコン用のイベント
            var onClickIconSelectButton = null;
            var onChangeIcon = null;
            var onChangeIconSize = null;
            if (this.type === 'icon') {

                // アイコン選択ダイアログの表示
                onClickIconSelectButton = on(this.inner.iconSelectButton, 'click', lang.hitch(this, function(){
                    this.inner.iconSelectDialog.show(layerObj);
                }));

                // アイコンの変更
                onChangeIcon = on(this.inner.iconSelectDialog.inner.iconSelectDone, 'click',
                    lang.hitch(this, function(){
                        // 初回だけデータがUndefinedになる対策
                        if (!!this.inner.iconSelectDialog.selectedIcon) {
                            var iconUrl = this._defaultIconPath + this.inner.iconSelectDialog.selectedIcon.name;
                            this.inner.selectedIcon.src = iconUrl;
                            layerObj.setIconStyle({
                                'iconUrl':  iconUrl
                            });
                            this._currentOptions.iconUrl = iconUrl;
                        }
                }));

                // アイコンサイズの変更
                onChangeIconSize = on(this.inner.iconSize, 'change', lang.hitch(this, function(){
                    var url = this.inner.selectedIcon.src;
                    var mag = this.inner.iconSize.get('value');
                    layerObj.setIconStyle({
                        'iconSize': Util._iconUtil('set', url, mag)
                    });
                    this._currentOptions.iconSize = mag;
                }));

            }

            // ポリライン、ポリゴン、付箋用（アイコン以外）のイベント
            var onChangeLineColor = null;
            var onChangeLineOpacity = null;
            var onChangeLineWidth = null;
            if (this.type !== 'icon') {

                // 線色の変更
                onChangeLineColor = on(this.inner.lineColor.palette, 'change', lang.hitch(this, function(){
                    var color = this.inner.lineColor.getColor();

                    if (this.type === 'note') {
                        layerObj.setNoteStyle({
                            'borderColor': color
                        });
                    } else {
                        layerObj.setStyle({
                            'color': color
                        });
                    }

                    // 矢印の場合、レイヤーoptionsに矢印ヘッドの追加、矢印プロパティを変更
                    if (layerObj.options.drawType === 'arrow') {
                        layerObj.setArrowHead(layerObj.options);
                    }
                    this._currentOptions.color = color;
                }));

                // 線透過度の変更
                onChangeLineOpacity = on(this.inner.lineOpacity.slider, 'change', lang.hitch(this, function(){
                    var opacity = this.inner.lineOpacity.getMapOpacity();

                    if (this.type === 'note') {
                        layerObj.setNoteStyle({
                            'borderOpacity': opacity
                        });
                    } else {
                        layerObj.setStyle({
                            'opacity': opacity
                        });
                    }

                    // 矢印の場合、レイヤーoptionsに矢印ヘッドの追加、矢印プロパティを変更
                    if (layerObj.options.drawType === 'arrow') {
                        layerObj.setArrowHead(layerObj.options);
                    }
                    this._currentOptions.opacity = opacity;
                }));

                // 線幅の変更
                onChangeLineWidth = on(this.inner.lineWidth, 'change', lang.hitch(this, function(){
                    var weight = this.inner.lineWidth.get('value');

                    if (this.type === 'note') {
                        layerObj.setNoteStyle({
                            'borderWidth': weight
                        });
                    } else {
                        layerObj.setStyle({
                            'weight': weight
                        });
                    }

                    // 矢印の場合、レイヤーoptionsに矢印ヘッドの追加、矢印プロパティを変更
                    if (layerObj.options.drawType === 'arrow') {
                        layerObj.setArrowHead(layerObj.options);
                    }
                    this._currentOptions.weight = weight;
                }));

            }

            // ポリゴン、付箋用のイベント
            var onChangeFillColor = null;
            var onChangeFillOpacity = null;
            if (this.type === 'polygon' || this.type === 'polygonRadius' || this.type === 'note') {

                // 塗りつぶしの色の変更
                onChangeFillColor = on(this.inner.fillColor.palette, 'change', lang.hitch(this, function(){
                    var color = this.inner.fillColor.getColor();

                    if (this.type === 'note') {
                        layerObj.setNoteStyle({
                            'fillColor': color
                        });
                    } else {
                        layerObj.setStyle({
                            'fillColor': color
                        });
                    }

                    this._currentOptions.fillColor = color;
                }));

                // 塗りつぶし透過度の変更
                onChangeFillOpacity = on(this.inner.fillOpacity.slider, 'change', lang.hitch(this, function(){
                    var opacity = this.inner.fillOpacity.getMapOpacity();

                    if (this.type === 'note') {
                        layerObj.setNoteStyle({
                            'fillOpacity': opacity
                        });
                    } else {
                        layerObj.setStyle({
                            'fillOpacity': opacity
                        });
                    }

                    this._currentOptions.fillOpacity = opacity;
                }));

            }

            // 付箋用のイベント
            var onChangeFontColor = null;
            var onChangeFontSize = null;
            var onChangeComment = null;
            if (this.type === 'note') {

                // 文字色の変更
                onChangeFontColor = on(this.inner.fontColor.palette, 'change', lang.hitch(this, function(){
                    var color = this.inner.fontColor.getColor();
                    layerObj.setNoteStyle({
                        'fontColor': color
                    });
                    this._currentOptions.fontColor = color;
                }));

                // 文字サイズの変更
                onChangeFontSize = on(this.inner.fontSize, 'change', lang.hitch(this, function(){
                    var size = this.inner.fontSize.get('value');
                    layerObj.setNoteStyle({
                        'fontSize': size
                    });
                    this._currentOptions.fontSize = size;
                }));

                // コメントの即時反映（付箋以外はポップアップ表示）
                onChangeComment = on(this.inner.comment, 'blur', lang.hitch(this, function(){
                    var comment = this.inner.comment.get('value');
                    layerObj.setNoteStyle({
                        'comment': comment
                    });
                    this._currentOptions.description = comment;
                }));

            }

            // 半径用のイベント
            var onChangeRadius = null;
            var strRegexp = /circle/ig;
            if(strRegexp.test(layerObj.options.drawType)) {
                // 半径の変更
                onChangeRadius = on(this.inner.radius, 'blur', lang.hitch(this, function() {
                    var radius = this.inner.radius.value;
                    if(radius === '') {
                        return ;
                    }

                    if(this._inputCheckRadius(radius)) {
                        if(this.inner.unitSwitch.value === '1') {
                            radius = radius * 1000;
                        }
                        if(layerObj.options.radius !== radius) {
                            this._changeRadiusFlg = true;
                        }
                    }
                }));
            }

            // ファイル添付
            var onChangeAttachFile = on(this.inner.attachFile, 'change', lang.hitch(this, function(){
                this._uploadAttachFile();
            }));

            // 「決定」ボタン
            var onClickOk = on(this.inner.ok, 'click', lang.hitch(this, function(){

                if(this.type === 'polygon' || this.type === 'polygonRadius') {
                    var radius = this.inner.radius.value;
                    var unit = this.inner.unitSwitch.value;
                    if(radius !== '') {
                        if(unit === '1') {
                            // 単位：キロメートル
                            radius = radius * 1000;
                        }

                        layerObj.setStyle({
                            'radius': radius,
                            'unit'  : unit
                        });
                    } else {
                        layerObj.setStyle({
                            'radius': layerObj.options.radius,
                            'unit'  : layerObj.options.unit
                        });
                    }
                }

                // 作図ObjのOptionsにFileListを加える
                layerObj.options.fileList = this._setFileOptions();

                if (this.type !== 'note') {
                    Util._setPopup(
                        layerObj, 'edit',
                        Util._replaceContent(this.inner.title.value),
                        Util._replaceContent(this.inner.comment.value)
                    );
                } else {
                    // map上の付箋に画像を展開
                    layerObj.setNoteStyle({
                        'fileList' : layerObj.options.fileList
                    });
                }

                // 円の再描画
                if(this._changeRadiusFlg === true) {
                    topic.publish(this.RADIUS_DIALOG, {
                        'radius': this.inner.radius.value,
                        'unit': this.inner.unit,
                        'edit': true,
                        'latlng': layerObj._latlng
                    });
                }

                // Undo用に状態を保存
                if (layerObj._map instanceof HistoricalMap) {
                    layerObj._map.pushState();
                }

                if (mode === 'new') {
                    // modeがnewならもう一度作図モードに入る
                    topic.publish(this.RESET_MODE_ID, 'rerun');
                }

                layerObj.radius = null;
                this.hide();
            }));

            // 「キャンセル」ボタン
            var onClickCancel = on(this.inner.cancel, 'click', lang.hitch(this, function(){
                if (mode === 'new') {
                    // removeはDrawPanelのFeatureGroupから取り除く
                    topic.publish(this.RESET_MODE_ID, layerObj);
                } else {
                    // 編集前の設定に戻す
                    this._setLayerStyle(layerObj);
                }
                this.hide();
            }));

            // ダイアログの「×」ボタン
            var onClickClose = on(this.closeButtonNode, 'click', lang.hitch(this, function(){
                if (mode === 'new') {
                    // removeはDrawPanelのFeatureGroupから取り除く
                    topic.publish(this.RESET_MODE_ID, layerObj);
                } else {
                    // 編集前の設定に戻す
                    this._setLayerStyle(layerObj);
                }
            }));

            // イベントを保持
            this._events.push(
                onClickIconSelectButton, onChangeIcon, onChangeIconSize,
                onChangeLineColor, onChangeLineOpacity, onChangeLineWidth,
                onChangeFillColor, onChangeFillOpacity,
                onChangeFontColor, onChangeFontSize, onChangeComment,
                onChangeRadius, onChangeAttachFile,
                onClickOk, onClickCancel, onClickClose
            );

            // ダイアログがクローズされたら当該作図オブジェクトに対するイベントを破棄
            on(this, 'hide', lang.hitch(this, function(){
                this._removeEvents();
            }));

            // DrawPanelから呼び出されるイベント削除
            var removeEvents = topic.subscribe(this.RM_EDIT_EVT, lang.hitch(this, function(){
                this._removeEvents();
                removeEvents.remove();
            }));
        },

        /**
         * 作図オブジェクトのオプション設定
         * @param {Object} layerObj
         */
        _setLayerStyle: function(layerObj){

            if (this.type === 'icon') {
                layerObj.setIconStyle({
                    'iconUrl'  : this._lastOptions.iconUrl,
                    'iconSize' : Util._iconUtil('set', this._lastOptions.iconUrl, this._lastOptions.iconSize)
                });

                var iconOnMap = this.inner.selectedIcon;
                iconOnMap.src = this._lastOptions.iconUrl;
                iconOnMap.value = this._lastOptions.iconUrl;

                this.inner.title.set('value', Util._replaceContent(this._lastOptions.title));
                this.inner.comment.set('value', Util._replaceContent(this._lastOptions.description));
                layerObj.options.title = this._lastOptions.title;
                layerObj.options.description = this._lastOptions.description;

            } else if (this.type === 'note') {
                layerObj.setNoteStyle({
                    'borderColor'   : this._lastOptions.color,
                    'borderOpacity' : this._lastOptions.opacity,
                    'borderWidth'   : this._lastOptions.weight,
                    'fillColor'     : this._lastOptions.fillColor,
                    'fillOpacity'   : this._lastOptions.fillOpacity,
                    'fontSize'      : this._lastOptions.fontSize,
                    'fontColor'     : this._lastOptions.fontColor,
                    'comment'       : this._lastOptions.comment
                });

                this.inner.comment.set('value', this._lastOptions.description);
                layerObj.options.comment = this._lastOptions.description;

            } else {
                layerObj.setStyle({
                    'color'       : this._lastOptions.color,
                    'opacity'     : this._lastOptions.opacity,
                    'weight'      : this._lastOptions.weight,
                    'fillColor'   : this._lastOptions.fillColor,
                    'fillOpacity' : this._lastOptions.fillOpacity
                });

                this.inner.title.set('value', Util._replaceContent(this._lastOptions.title));
                this.inner.comment.set('value', Util._replaceContent(this._lastOptions.description));
                layerObj.options.title = this._lastOptions.title;
                layerObj.options.description = this._lastOptions.description;
            }

            // 矢印の場合、レイヤーoptionsに矢印ヘッドの追加、矢印プロパティを変更
            if (layerObj.options.drawType === 'arrow') {
                layerObj.setArrowHead(layerObj.options);
            }

            // 添付ファイルは共通
            layerObj.options.fileList = this._lastOptions.fileList;

        },

        /**
         * 作図オブジェクトのOptionsにFileの情報を設定
         */
        _setFileOptions: function(){
            var fileList = [];
            var files = Util._toArray(this.inner.fileListArea.children);
            files.forEach(function(file){
                var fileData  = {};
                var tmpUrl    = file.children[1].children[0].dataset.drawDownload;
                fileData.name = file.children[1].children[0].textContent;
                fileData.url  = tmpUrl.slice(tmpUrl.indexOf('/data'));
                fileList.push(fileData);
            });
            return fileList;
        },

        /**
         * このウィジェットに対する全イベントを削除
         */
        _removeEvents: function(){
            for (var i = 0; i < this._events.length; i++) {
                if (this._events[i] !== null) {
                    this._events[i].remove();
                }
            }
            this._events = [];
        },

        /**
         * ファイルがloadされたときにサーバー保存する
         */
        _uploadAttachFile: function(){
            // ファイルが空の場合は処理を中断＆不正なファイルの場合、メッセージ表示して処理を中断
            if (this.inner.attachFile._files.length === 0 ||
                !FilesUtils.isAttachFile(this.inner.attachFile)) {
                return;
            }
            var that = this;
            var form = new FormData();
            form.append('file', this.inner.attachFile._files[0]);
            var promise = Requester.post('/api/draw/registerFiles', {
                headers: {'Content-Type': false},
                data: form,
                handleAs: 'json',
                preventCache : false
            }).then(function(data) {
                // pathを変更する。TODO サーバーからそう返せばよろしくね？
                data.url = '/' + data.url.replace('out/', 'data/');
                that._createFileListDom(data);
                that.inner.attachFile.reset();
            }, function(error) {
                // TODO そもそも添付ファイルエリアを表示しない。
                var message = error.response.status === 403 ?
                'ファイル添付の権限がありません。' : '添付ファイルの保存に失敗しました。';
                that.chain.info(message, 'エラー');
            });
            //ローダーの表示
            Loader.wait(promise);
        },

        /**
     * liを生成する
     */
        _createFileListDom: function(fileInfo){
            // base
            var liNode = domConstruct.toDom('<li></li>');
            // div include link for style
            var divForLink = domConstruct.create('div', {'class':'divForLink'});
            // link
            var downLoadNode = Util._createDownLoadNode(fileInfo);
            // icon
            var iconNode = Util._createIconNode(fileInfo, 'draw-showImage');
            // deleteButton
            var deleteButton = new Button({
                label: '削除'
            });
            // liNode set to Dialog
            domConstruct.place(liNode, this.inner.fileListArea, 'last');
            // downloadNode set to liNode
            domConstruct.place(downLoadNode, divForLink);
            // downloadNode set to liNode
            domConstruct.place(divForLink, liNode);
            // iconNode set to liNode
            domConstruct.place(iconNode, liNode, 'first');
            // deleteButton set to liNode
            domConstruct.place(deleteButton.domNode, liNode, 'last');
            // set delete event
            this._addEventToLiNode(liNode);
        },

        /**
         *  Uploaderを空にして、自分自身の属する親ごとRemoveする。
         */
        _addEventToLiNode: function(liNode){
            on(liNode, 'click', lang.hitch(this, function(e){
                if (e.target.type === 'button') {
                    Util._rmElement(e.target.parentNode.parentNode);
                }
            }));
        },

        /**
         * 半径の入力チェック
         */
        _inputCheckRadius: function(radius) {
            var regexp = /^([1-9]\d*|0)$/;
            if(!regexp.test(radius)) {
                this.chain.info('数値以外が入力されています。', 'エラー');
                return false;
            }

            if(Number(radius) === 0) {
                this.chain.info('1以上を入力してください。', 'エラー');
                return false;
            }

            return true;
        }
    });

    return container;

});
