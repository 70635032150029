/**
 * 災害体制の列定義一覧。
 * @module app/disasterprevention/view/DPColumn
 */
define([
    'app/model/Municipality',
    'idis/view/grid/helper',
    'idis/view/LazyNode',
    'dojo/when',
    './model/PrefPromotionBureau'
], function(Municipality, helper, LazyNode,  when, PrefPromotionBureau) {
    var gatheringClmSetting = {
        formatter: function(data){
            return data!==null ? data.toLocaleString() : '-';
    }};
	var datetimeFormatter = function(value){
		return value ? value.replace(/-/g, '/') : '-';
	};
    var getPrefPromotionBureauLazyNode = function(regionCd){

        var itemPromise = PrefPromotionBureau.store.get(regionCd);
        return new LazyNode({
            promise: when(itemPromise).then(function(item) {
                return PrefPromotionBureau.getLabel(item);
            })
        });
    };
    var regionNameCell = function(item, value, node) {
        // 県の場合は、'-'を返す
        if(!item.regionCd){
            node.innerText = '-';
            return;
        }

        var lazyNode = getPrefPromotionBureauLazyNode(item.regionCd);
        return lazyNode.domNode;
    };
        
    return {
        regionName: {
            field: 'regionName', label: '組織',
            renderCell: regionNameCell,
            sortable: false
        },
        regionNameSortable: {
            field: 'regionName', label: '組織',
            renderCell: regionNameCell,
            sortable: true
        },
        // status: helper.column('statusName', '体制状況', {
        status: helper.column('hqName', '本部種別', {
            className: function(item) {
                // ヘッダーの場合はclassNameを返さない
                if (!item) {
                    return;
                }
                if(item.hqType === '99'){
                    // 解除ステータスの場合、本部種別に関わらずセルは無色
                    return 'is-other';
                } else {
                    return {
                        '災害対策本部': 'is-emergency',
                        '災害警戒本部': 'is-alarm',
                        '情報連絡体制': 'is-caution'
                    }[item.hqName] || '';
                }
            },
            formatter: function(value,item) {
                if(item && item.hqType === '99'){
                    value = value + '（解除）';
                }
                return value ? value : '-';
            },
            sortable: false
        }),
        Headquarters: helper.column('headquarter', '本部設置', {
            children: [
                helper.column('hqSetTimestamp', '設置日時', { formatter: datetimeFormatter }),
                helper.column('hqAbolishedTimestamp', '廃止日時', { formatter: datetimeFormatter })
            ],
            sortable: false
        }),
        deploymentMunicipality: helper.column('gathering', '参集職員', {
            children: [
                helper.column('statusName', '動員種別'),
                // helper.column('organizationSum', '課グループ数', {sortable: false}, gatheringClmSetting),
                helper.column('sum', '人数', gatheringClmSetting)
                // helper.column('officials', '市/行政区職員', gatheringClmSetting),
                // helper.column('fireFighter', '消防職員', gatheringClmSetting),
                // helper.column('fireBrigade', '消防団員', gatheringClmSetting),
                // helper.column('other', 'その他', gatheringClmSetting)
            ],
            sortable: false
        }),
        deploymentPrefecture: helper.column('gathering', '配置人員', {
            children: [
                helper.column('disasterStandby', '災害待機', gatheringClmSetting),
                helper.column('floodDefenseStandby', '水防待機', gatheringClmSetting)
            ],
            sortable: false
        }),
        reportTimestamp: helper.column('reportTimestamp', '報告日時', { formatter: datetimeFormatter }),
        lastReportTimestamp: helper.column('reportTimestamp', '最終報告日時', { formatter: datetimeFormatter })
    };
});
