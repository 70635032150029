/**
* 庁舎・事業所被害情報一覧画面用モジュール。
* @module app/waterworks/DansuiGrid
*/
define([
    'module',
    'dojo/_base/declare',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/util/DateUtils',
    'idis/view/form/Button',
    'dojo/on',
    // 以下、変数として受け取らないモジュール
    'dojox/lang/functional/array'
], function(module,  declare, IdisGrid, helper, DateUtils, Button, on) {

    var formatDateTime = function(value) {
        var dateOption = {
            date:'yyyy-MM-dd',
            time:'HH:mm:ss'
        };
        return DateUtils.format(value, dateOption);
    };

    return declare(module.id.replace(/\//g, '.'), IdisGrid, {

        /**
         * 各列の定義
         * @type {Object[]}
         */
        columns: [
            {field: 'detail', label:'詳細', sortable: false,
            renderCell: function(item){
                var gridNode = this.grid.domNode;
                var button = new Button({
                    label: '詳細',
                    onClick: function() {
                        on.emit(gridNode, 'detailButtonClick', {item: item});
                    }
                });
                return button.domNode;
            }},

            {field: 'jgsNm', label: '事業所名', sortable: false,
                formatter: function(jgsNm){
                    return jgsNm ? jgsNm : '-';
                }
            },

            {field: 'ttmNm', label: '建物名', sortable: false,
                formatter: function(ttmNm){
                    return ttmNm ? ttmNm : '-';
                }
            },

            {field: 'higaikibo', label: '被害規模', 
                formatter: function(higaikibo){
                    return higaikibo ? higaikibo === '1' ? '軽微被害なし': higaikibo === '2' ? '小' 
                                    : higaikibo === '3' ? '中' : higaikibo === '4' ? '大' : '-' :  '-';
                }
            },

            {field: 'shinkokudo', label: '深刻度', 
                formatter: function(shinkokudo){
                    return shinkokudo ? shinkokudo === '1' ? '軽微被害なし': shinkokudo === '2' ? '小' 
                                    : shinkokudo === '3' ? '中' : shinkokudo === '4' ? '高' : '-' :  '-';
                }
            },

            {field: 'hasseinichiji', label: '発生日時', 
                formatter: function(hasseinichiji){
                    return hasseinichiji ? formatDateTime(hasseinichiji) : '-';
                }
            },

            {field: 'entryDatetime', label: '登録日時', 
                formatter: function(entryDatetime){
                    return entryDatetime ? formatDateTime(entryDatetime) : '-';
                }
            },

            {field: 'updateDatetime', label: '更新日時', 
                formatter: function(updateDatetime){
                    return updateDatetime ? formatDateTime(updateDatetime) : '-';
                }
            }
        ]
     });
 });
