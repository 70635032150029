/**
 * 施設管理画面用モジュール。
 * @module app/facility/FacilityAdminPage
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/Deferred',
    'dojo/json',
    'dojo/dom-style',
    'dojo/text!./templates/FacilityAdminPage.html',
    'dojo/dom',
    'dojo/request/iframe',
    'dijit/registry',
    'dstore/Memory',
    'idis/control/Router',
    'idis/control/Locator',
    'idis/view/page/_PageBase',
    'idis/consts/ACL',
    'idis/view/dialog/InfoDialog',
    'idis/view/grid/IdisGrid',
    'idis/view/grid/helper',
    'idis/store/IdisRest',
    'idis/view/Loader',
    'idis/view/dialog/DialogChain',
    'idis/service/Requester',
    'app/model/DisasterInfo',
    'idis/model/UserInfo',
    'idis/consts/USER_TYPE',
    '../config',
    // 'idis/util/FilesUtils',
    // 以下、変数で受けないモジュール
    'dijit/Dialog',
    'dijit/form/Form',
    'dijit/form/Select',
    'dijit/form/TextBox',
    'dijit/layout/BorderContainer',
    'dijit/layout/ContentPane',
    'dijit/layout/TabContainer',
    'idis/view/form/Button',
    'idis/view/form/AclButton',
    './FacilityGrid',
    './FacilityFormDialog',
    './FacilityFileUploadDialog',
    'app/view/form/MunicipalitySelector'
], function(module, declare, lang, Deferred, JSON, domStyle, template, dom, iframe,
        registry, Memory, Router, Locator, _PageBase, ACL, InfoDialog, IdisGrid, helper,
        IdisRest, Loader, DialogChain, Requester, DisasterInfo, UserInfo, USER_TYPE, config) {
    /**
     * 検索条件格納用オブジェクト
     * @type {Object}
     */
    var filterStore = {};
    /**
    /**
     * 施設管理画面
     * @class FacilityAdminPage
     * @extends module:idis/view/page/_PageBase~_PageBase
     */
    return declare(module.id.replace(/\//g, '.'), _PageBase,
    	/** @lends module:app/facility/FacilityAdminPage~FacilityAdminPage# */ {
        // テンプレート文字列
        templateString: template,

        // ルート要素に付与されるCSS
        baseClass: 'idis-Page idis-Page--facility',

        // 施設情報用ストア
        facilityStore: null,

        // 施設種別用ストア
        facilityTypeStore: null,

        // グリッド(施設情報)
        facilityGrid: null,

        /**
         * 災害ID
         */
        _disasterId: null,

        // DOMノードを生成するためのメソッド
        buildRendering: function() {
            this.inherited(arguments);
            // 自分が削除された時は関連ダイアログも削除する
            this.own(this.formDialog);
            this.own(this.fileUploadDialog);
            this.initFileUploadDialog();
        },

        startup: function() {
            this.inherited(arguments);
            //パッケージ標準の制御
            // if(UserInfo.getUserType() === USER_TYPE.MUNICIPALITY){
            //     if(UserInfo.getMunicipalityCd() === config.municInfo.cityMunicCd){
            //         this.municipalityCd.set('noAllButton', false);
            //     }
            //     this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
            //     this.updateFacilityGridQuery(this.form.get('value'));
            // }
            // 区所属のユーザの場合、所属区をダイアログにセット
            if(UserInfo.getMunicipalityCd() !== config.municInfo.cityMunicCd){
                this.municipalityCd.set('value', UserInfo.getMunicipalityCd());
            }
            //gridの初期化
            this.updateFacilityGridQuery(this.form.get('value'));
            // 避難所マスタ管理の登録権限を持たない場合、「ファイル読み込み」ボタンを非表示にする。
            if (!UserInfo.hasWriteAuthz('F10001')){
                domStyle.set(this.FileUploadButton.domNode, 'display', 'none');
            }
            // グリッドの詳細ボタンクリック時の動作を設定する
            // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
            this.facilityGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
                // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
                this.onDetailButtonClick(evt.item);
            }));
        },

        constructor: function() {
            this.facilityStore = new IdisRest({
                idProperty: 'facilityId',
                target: '/api/facility'
            });
            this.facilityTypeStore = new IdisRest({
                idProperty: 'facilityTypeId',
                target: '/api/facility/category'
            });
            // 連鎖ダイアログを登録
            this.chain = DialogChain.get(this);
            // 災害IDをDisasterInfoから取得
            this._disasterId = DisasterInfo.getDisasterId();
            if(!this._disasterId) {
                this._disasterId = 1;
                console.warn('災害IDが設定されていません。');
            }
        },

        /**
         * グリッドの検索条件を指定された値で更新する。
         * @param {Object} value name属性と値のマッピング
         */
        updateFacilityGridQuery: function(value) {
            // 入力値を元にグリッド用フィルターを作成
            var filter = new this.facilityStore.Filter();
            // 市区コード
            var munic = value.municipalityCd;
            if(munic && munic !== config.municInfo.prefCd){
                filter = filter.eq('municipalityCd', munic);
            }
            // 災害ID
            if (this._disasterId) {
                filter = filter.eq('disasterId', this._disasterId);
            }
            // 避難所区分
            var shelterCategory = value.shelterCategory;
            if (shelterCategory) {
                if (shelterCategory === '02') {
                    // 災害時避難所
                    filter = filter.eq('designatedEvacShFlg', '1');
                } else if (shelterCategory === '03') {
                    // 福祉避難所
                    filter = filter.eq('welfareEvacShFlg', '1');
                } else if (shelterCategory === '04') {
                    // 臨時避難所
                    filter = filter.eq('temporaryEvacShFlg', '1');
                }
            }
            // 施設名称
            if (value.facilityName) {
                filter = filter.eq('facilityName', value.facilityName);
            }
            // 住所
            if (value.address) {
                filter = filter.eq('address', value.address);
            }

            // filterに対応するcollectionを取得
            var collection = this.facilityStore.filter(filter);
            // collectionをグリッドにセットする（サーバーにリクエストされる）
            this.facilityGrid.set('collection', collection);
            //検索条件の保管
            this.setFilterStore();
        },

        /**
         * 検索条件を保管する
         */
        setFilterStore: function() {
            //初期化する
            filterStore = {};
            var value = this.form.get('value');
            // 市町村
            if (value.municipalityCd && value.municipalityCd !== config.municInfo.prefCd) {
                filterStore.municipalityCd = value.municipalityCd;
            }
            // 施設種別
            if (value.facilityTypeId) {
                filterStore.facilityTypeId = value.facilityTypeId;
            }
            // 施設名
            if (value.facilityName) {
                filterStore.facilityName = value.facilityName;
            }
            // 住所
            if (value.address) {
                filterStore.address = value.address;
            }
            // 災害ID
            if (this._disasterId) {
                filterStore.disasterId = this._disasterId;
            }
        },

        /**
         * 保管した検索条件をフォームにセットする
         */
        setFilterData: function() {
          //保管されていれば値をセット
            // 市町村
            if (filterStore.municipalityCd) {
                this.municipalityCd.set('value', filterStore.municipalityCd);
            }
            // 施設種別
            if(filterStore.facilityTypeId) {
                this.facilityTypeSelectBox.set('value', filterStore.facilityTypeId);
            }
            // 施設名
            if(filterStore.facilityName) {
                this.facilityName.set('value', filterStore.facilityName);
            }
            // 住所
            if(filterStore.address) {
                this.address.set('value', filterStore.address);
            }
        },

        /**
        * 検索ボタンが押されたときに呼び出される。
        * テンプレートHTML内のFormウィジェットに対するdata-dojo-attach-eventでこのイベントを紐付けている。
        * また、同Formウィジェットに対しdata-dojo-attach-pointを指定し、this.formからアクセス出来るようにしている。
        */
        onSubmit: function() {
            try {
                if (this.form.isValid()) {
                   // 入力値が正常ならグリッドの検索条件を更新
                    this.updateFacilityGridQuery(this.form.get('value'));
                }
            } catch (e) {
                console.error(e);
            }
            return false;
        },
        /**
         * ファイルアプロードダイアログ初期化処理
         */
        initFileUploadDialog: function () {
            // 詳細ダイアログの最初の子要素が詳細画面
            var dialog = this.fileUploadDialog;
            var page = dialog.getChildren()[0];
            // 更新ボタンが押された際の動作
            page.on('upload', lang.hitch(this, function () {
                dialog.hide();
                this.updateGridQuery(this.form.get('value'));
            }));
        },

        // 帳票出力ボタン押下時の挙動
        showFormDialog: function() {
            this.formDialog.show();
        },

        // ファイル読み込みボタン押下時の挙動
        showFileUploadDialog: function() {
            this.fileUploadDialog.show();
        },

        // initFacilityGrid: function() {
        //     // グリッドの詳細ボタンクリック時の動作を設定する
        //     // helper.buttonColumnでフィールド名に指定した'detail'と'ButtonClick'の結合がボタンクリック時のイベント名
        //     this.facilityGrid.on('detailButtonClick', lang.hitch(this, function(evt) {
        //         // helper.buttonClickにより、クリックイベントのitemプロパティーに行データが入る
        //         this.onDetailButtonClick(evt.item);
        //     }));
        //     // 新しくグリッド用フィルターを作成
        //     var filter = new this.facilityStore.Filter();
        //     //初期表示のみ、filterStoreからfilterへデータを直接セットする必要がある。(同期処理を防げないため)
        //     if(filterStore.municipalityCd){
        //         filter = filter.eq('municipalityCd', filterStore.municipalityCd);
        //     }
        //     if(filterStore.facilityTypeId){
        //         filter = filter.eq('facilityTypeId', filterStore.facilityTypeId);
        //     }
        //     if(filterStore.facilityName){
        //         filter = filter.eq('facilityName', filterStore.facilityName);
        //     }
        //     if(filterStore.address){
        //         filter = filter.eq('address', filterStore.address);
        //     }
        //     if(filterStore.disasterId){
        //         filter = filter.eq('disasterId', filterStore.disasterId);
        //     }
        //     // filterに対応するcollectionを取得
        //     var collection = this.facilityStore.filter(filter);
        //     // collectionをグリッドにセットする(サーバーにリクエストされる)
        //     this.facilityGrid.set('collection', collection);
        // },

        onRegisterButtonClick: function(evt) {
            // ブラウザーの遷移処理をキャンセル
            evt.preventDefault();
            // 施設登録画面へ遷移
            Router.moveTo('facility/register', {
                type : ''
            });
        },

        onDetailButtonClick: function(object) {
            // 施設情報詳細画面へ遷移
            Router.moveTo('facility/detail', {
                facilityId: object.facilityId
            });
        },

        filterByType: function(value) {
            alert(value);
        }
    });
});
