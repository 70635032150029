/**
 * ツリー選択入力要素を定義する。
 * @module idis/view/form/TreeSelector
 */
define([
    'module',
    'dojo/_base/declare',
    'dojo/_base/lang',
    'dojo/dom-class',
    'dojo/dom-style',
    'idis/model/UserInfo',
    'dojo/text!./templates/DamageReportAction.html',
    'dojo/when',
    'idis/view/_IdisWidgetBase',
    //'idis/view/form/_NamedMixin',
    // 以下、変数で受けないモジュール
    'idis/view/form/Button'
], function (module, declare, lang, domClass, domStyle, UserInfo, template, when, _IdisWidgetBase) {
    /**
     * ツリー選択入力要素。
     * @class TreeSelector
     * @extends module:idis/view/_IdisWidgetBase~_IdisWidgetBase
     * @param {Object} kwArgs
     * @param {module:dijit/tree/model} [kwArgs.title] 選択ダイアログのタイトル
     * @param {module:dijit/tree/model} kwArgs.model ツリー・モデル
     * @param {module:dijit/Tree} kwArgs.treeClass ツリー生成用クラス
     */
    return declare(module.id.replace(/\//g, '.'), _IdisWidgetBase,
        /** @lends module:idis/view/form/TreeSelector~TreeSelector# */ {
            // テンプレート文字列
            templateString: template,

            /**
             * 未選択時の文字列
             * @type {string}
             */
            seqNum: 0,
            content: '',
            hldStatus: '',
            name: '',
            crtDateTime: '',
            updDateTime: '',
            organizationName: '',
            isEdit: false,

            constructor: function (kwArgs) {
                lang.mixin(this, kwArgs);
            },

            // DOMノードを生成する
            buildRendering: function () {
                this.inherited(arguments);
                // 更新済の対応履歴ならば更新時刻を表示
                if (this.crtTimestamp !== this.updTimestamp) {
                    domStyle.set(this.updTimestampDiv, 'display', '');
                }
                // 登録者であれば編集可
                if (!this.crtUserId || UserInfo.isSysAdmin() || this.crtUserId === UserInfo.getId()) {
                    if (this.isEdit) {
                        domStyle.set(this.updateActionButton.domNode, 'display', '');
                    }
                }
            }
        });
});
