/**
* 定時集計 登録/詳細 画面用モジュール。
* @module app/scheduleReport/ScheduledReportDeptDetail
 */
define([
	'module',
	'dojo/_base/declare',
	'dojo/_base/lang',
	'dojo/dom-style',
	'dojo/date/locale',
	'dojo/text!./templates/ScheduledReportDamageDetailPage.html',
	'idis/view/page/_PageBase',
	'idis/control/Locator',
	'idis/control/Router',
	'idis/service/Requester',
	'idis/view/Loader',
	'idis/view/dialog/DialogChain',
	// 以下、変数から参照されないモジュール
	'dijit/form/Form',
	'dijit/form/Select',
	'dijit/form/Textarea',
	'idis/view/form/Button',
	'idis/view/form/RadioGroup',
	'idis/view/form/DateTimeInput'
], function (module, declare, lang, domStyle, locale, template, _PageBase,
	Locator, Router, Requester, Loader, DialogChain) {
	/**
	 * 定時集計 詳細 画面実装。
	 * @class ScheduledReportDeptDetail
	 * @extends module:idis/view/page/_PageBase~_PageBase
	 */
	return declare(module.id.replace(/\//g, '.'), _PageBase, {
		// テンプレート文字列
		templateString: template,
		// 定時状況報告ID
		_damageReportId: null,

		constructor: function () {
			this.chain = DialogChain.get(this);
			// URLから集計IDを取得
			this._scheduledReportId = Locator.getQuery().scheduledReportId;

			// URLから定時状況IDを取得
			if ('damageReportId' in Locator.getQuery()) {
				this._damageReportId = Locator.getQuery().damageReportId;
			}

			if ("isMunic" in Locator.getQuery()) {
				// URLから区市町村コードを取得
				this._isMunic = Locator.getQuery().isMunic;
			}
			if ("admNum" in Locator.getQuery()) {
				this._admNum = Locator.getQuery().admNum;
			}
		},

		/**
		 * DOM生成
		 */
		// DOMノードを生成するためのメソッド
		buildRendering: function () {
			this.inherited(arguments);
		},

		/**
		 * 画面生成完了
		 */
		startup: function () {
			this.inherited(arguments);

			// ヘッダーリンクの表示
			if (this._isMunic) {
				this.municLink.style.display = '';
			} else {
				this.prefLink.style.display = '';
			}

			this.initData();
		},

		initData: function () {
			var promise = Requester.get('/api/scheduledReport/damage/' + this._damageReportId, {
				preventCache: false
			}).then(lang.hitch(this, function (data) {
				console.log(data);
				Object.keys(data).forEach(lang.hitch(this, function (key) {
					if (key in this) {
						this[key].innerText = data[key];
					}
				}));
			}), lang.hitch(this, function (err) {
				console.error(err);
			}));

			Loader.wait(promise);
		},

		/**
		 * 被害一覧を表示
		 */
		onDamageReportPageLinkClick: function (evt) {
			// ブラウザーの遷移処理をキャンセル
			evt.preventDefault();
			// 被害一覧画面へ遷移
			Router.moveTo('report');
		},

		/**
		 * 定時集計一覧を表示
		 */
		onListPageLinkClick: function (evt) {
			// ブラウザーの遷移処理をキャンセル
			evt.preventDefault();
			// 被害一覧画面へ遷移
			Router.moveTo('scheduledReport');
		},

		/**
		 * 定時集計 詳細を表示
		 */
		onDetailPageLinkClick: function (evt) {
			// ブラウザーの遷移処理をキャンセル
			evt.preventDefault();
			// 詳細画面に戻る
			this.leave();

		},
		/**
		 * 定時集計 各区一覧
		 */
		onMunicPageLinkClick: function (evt) {
			// ブラウザーの遷移処理をキャンセル
			evt.preventDefault();

			// 各区の画面へ遷移
			Router.moveTo('scheduledReport/munic', {
				admNum: this._admNum
			});
		},

		/**
		 * 定時集計 各区詳細
		 */
		onMunicDetailLinkClick: function (evt) {
			// ブラウザーの遷移処理をキャンセル
			evt.preventDefault();
			// 詳細画面に戻る

			// 被害一覧画面へ遷移
			Router.moveTo('scheduledReport/munic/detail', {
				scheduledReportId: this._scheduledReportId,
				admNum: this._admNum
			});
		},
		/**
		 * 定時集計 被害一覧を表示
		 */
		onDamagePageLinkClick: function (evt) {
			// ブラウザーの遷移処理をキャンセル
			evt.preventDefault();
			// 被害一覧画面へ遷移
			this.leave();
		},



		/**
		 * 'yyyy-MM-dd HH:mm:ss' 形式に変換
		 */
		formatDateTime: function (val) {
			if (!val) {
				return '';
			}
			var timestamp = new Date(val);
			var dateLabel = locale.format(timestamp, {
				selector: 'date',
				datePattern: 'yyyy/MM/dd'
			});
			var timeLabel = locale.format(timestamp, {
				selector: 'time',
				timePattern: 'HH:mm:ss'
			});
			return dateLabel + '&nbsp;' + timeLabel;
		},
	});
});

